import { PaginationFilter } from "../../pagination-filter";
import { StockMovementType } from "../../stock/stock-movement-type";

export class StockMovementFilter {

  id?: number;
  createAtStart?: Date;
  createAtEnd?: Date;
  competenceDateStart?: Date;
  competenceDateEnd?: Date;
  active?: boolean;
  cancelAtStart?: Date;
  cancelAtEnd?: Date;
  employeeName?: string;
  stockMovementTypes?: StockMovementType[];
  marinaOrderIds?: string;
  buyOrderIds?: string;
  customerId?: number;
  supplierId?: number;
  automatic?: boolean;
  marinaId?: number;

  constructor() {

    this.id= null;
    this.createAtStart= null;
    this.createAtEnd= null;
    this.competenceDateStart= null;
    this.competenceDateEnd= null;
    this.active= null;
    this.cancelAtStart= null;
    this.cancelAtEnd= null;
    this.employeeName= null;
    this.stockMovementTypes= null;
    this.marinaOrderIds= null;
    this.buyOrderIds= null;
    this.customerId= null;
    this.supplierId= null;
    this.automatic= null;
    this.marinaId= null;
  }

}
