import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-boat-wash-engine-turn-history',
  templateUrl: './boat-wash-engine-turn-history.component.html',
  styleUrls: ['./boat-wash-engine-turn-history.component.scss']
})
export class BoatWashEngineTurnHistoryComponent implements OnInit {

  washOrEngineTurnHistory: any[] = [];
  boatName: string;
  constructor(
    private config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.washOrEngineTurnHistory = this.config.data.washOrEngineTurnHistory;
    this.boatName = this.config.data.boatName;
  }

}
