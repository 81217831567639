import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import moment from 'moment';
import {
  DynamicDialogRef,
  DialogService,
  DynamicDialogConfig,
} from 'primeng/dynamicdialog';
import { MarinaCompany } from 'src/app/models/marina-company';
import { FinancesService } from 'src/app/services/finances.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-choose-billet',
  templateUrl: './choose-billet.component.html',
  styleUrls: ['./choose-billet.component.scss'],
})
export class ChooseBilletComponent implements OnInit {
  billets: [] = [];
  selectedBillet: any;
  billingPortifolioList: any[];
  billingPortifolios: any[];
  marinaCompanies: MarinaCompany[];
  marinaCompaniesWithDatabase: MarinaCompany[];
  filteredCompanies: { label: string; value: any }[];
  loggedMarina: any;
  invoice: any;
  noBillet: boolean = false;
  constructor(
    public dialogRef: DynamicDialogRef,
    private messageUtil: MessageUtil,
    private dialog: DialogService,
    private config: DynamicDialogConfig,
    private cdr: ChangeDetectorRef,
    private financesService: FinancesService
  ) {}

  ngOnInit(): void {
    this.loadData();
    this.getCompanies();
    this.getBilingPortifolioList();
    console.log(this.billets);
  }

  loadData(){
    this.billets = this.config.data.billets;
    this.invoice = this.config.data.invoice;
    this.cdr.markForCheck();
  }

  async getCompanies(): Promise<void> {
    const marina = StorageUtil.getMarina();
    this.marinaCompanies = marina.marinaCompanies;
    this.marinaCompaniesWithDatabase =
      await this.financesService.findCompaniesWithDatabase();
    if (this.marinaCompanies.length > 0) {
      this.filteredCompanies = this.marinaCompanies.map((c) => ({
        label: c.companyFederalName,
        value: c,
      }));
      this.filteredCompanies.unshift({
        label: marina.companyName,
        value: marina,
      });
      this.filteredCompanies.unshift({
        label: 'Todas as empresas',
        value: null,
      });
    }
    this.loggedMarina = marina;
  }

  getDateFormatted(date): string {
    return moment(date).format('DD/MM/YY');
  }

  getTooltip(billet): string {
    let texto = '';
    if (!billet.paid && billet.registered) {
      texto = billet.anticipated
        ? 'Boleto Antecipado e Registrado'
        : 'Boleto registrado';
    } else if (!billet.registered) {
      texto = billet.anticipated
        ? 'Boleto Antecipado e Não registrado'
        : 'Boleto não registrado';
    } else if (billet.paid) {
      texto = billet.paymentDate
        ? 'Boleto pago em: ' + this.getDateFormatted(billet.paymentDate)
        : 'Boleto pago';
    }
    return texto;
  }

  checkIfHaveNixBillet(billet: any): boolean {
    const billetBoy = billet?.issueDate;
    if (billetBoy) {
      if (billet.bankNumber === 332) {
          return true;
      }
    }
    return false;
}

  isToday(file): boolean {
    return moment(file.issueDate)
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
      .isSame(moment().hours(0).minutes(0).seconds(0).milliseconds(0));
  }

  billingPortfolio(billet): any {
    let company;
    const c = this.billingPortifolioList.find((carteira) => {
      if (carteira.idCarteiraCobranca === billet.idCarteiraCobranca) {
        company = this.marinaCompanies.find(
          (m) => m.companyFederalName === billet.marinaCompany
        );
        if ((!company || !company.finances) && !carteira.marinaCompany) {
          return carteira;
        } else if (company.finances && carteira.marinaCompany === company.id) {
          return carteira;
        }
      }
    });
    return c ? c : { conta: { nome: '' }, nome: '' };
  }

  async getBilingPortifolioList(): Promise<void> {
    return new Promise<void>(async (res) => {
      this.billingPortifolioList = (
        await this.financesService.getAllBilingPortifolioAsync(null)
      ).map((carteira) => {
        carteira.marinaCompany = null;
        return carteira;
      });
      for (
        let index = 0;
        index < this.marinaCompaniesWithDatabase.length;
        index++
      ) {
        this.billingPortifolioList = this.billingPortifolioList.concat(
          (
            await this.financesService.getAllBilingPortifolioAsync(
              this.marinaCompanies[index]
            )
          ).map((carteira) => {
            carteira.marinaCompany = this.marinaCompanies[index].id;
            return carteira;
          })
        );
      }
      res();
    });
  }

  activeButton(): boolean {
    if ((this.selectedBillet === undefined || this.selectedBillet === null) && (!this.noBillet)) {
      return true;
    }
    return false;
  }

  close(): void {
    if (this.noBillet) {
      this.dialogRef.close(null);
    } else {
      this.dialogRef.close(this.selectedBillet);
    }
  }

  limpaMarcacao(): void {
    console.log(this.noBillet);
    if (!this.noBillet) {
    //  this.messageUtil.generateMessage(MessageUtil.WARNING, 'Alerta', 'Ao realizar o pagamento avulso de um fatura parcelada em boletos, certifique que o boleto referente a esse pagamento está cancelado. Caso contrário, você poderá ter diferença de valores na fatura e incoerência na gestão de pendência.');

    let mensagem ="<span style='color: red; font-style: normal; font-size: large;'>Ao realizar o pagamento avulso de um fatura parcelada em boletos, certifique que o boleto referente a esse pagamento está cancelado. Caso contrário, você poderá ter diferença de valores na fatura e incoerência na gestão de pendência.</span>";

      let confirmText='';
      Swal.fire({
        icon: 'warning',
        title: mensagem,
        showCancelButton: false,
        text: confirmText,
        confirmButtonText: 'Confirmar',
        showLoaderOnConfirm: true,
        reverseButtons: true,
        cancelButtonColor: '#d33',
        allowOutsideClick: false,

      });
    }
  }
}
