import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-log-field',
  templateUrl: './log-field.component.html',
  styleUrls: ['./log-field.component.scss']
})
export class LogFieldComponent implements OnInit {

  @Input() field
  @Input() old
  @Input() existsOld = false;
  @Input() isNew = true;
  @Input() isDate = false;
  @Input() translate = false;
  
  constructor() { }

  ngOnInit(): void {
  
  }

  getText(): string{
    return (this.existsOld && !this.isNew)? this.old:this.field
  }

}
