import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FileUpload } from 'primeng/fileupload';
import { SystemcallAuxiliarTablesService } from 'src/app/services/systemcall-auxiliar-tables.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-write-ticket',
  templateUrl: './write-ticket.component.html',
  styleUrls: ['./write-ticket.component.scss']
})
export class WriteTicketComponent implements OnInit {
  @ViewChild('upload', { static: false }) upload: FileUpload;
  ticketDetForm : UntypedFormGroup;
  buttonClass = 'attachment';
  constructor(
    private systemcallAuxiliarTablesService: SystemcallAuxiliarTablesService,
    private toasty: ToastService
  ) { }


  files : any[] = [];

  @Output()
  ticketForm: EventEmitter<UntypedFormGroup> = new EventEmitter();

  @Output()
  fileList: EventEmitter<any[]> = new EventEmitter();

  ngOnInit(): void {
    this.startForm();
  }

  startForm(): void {
    this.ticketDetForm = new UntypedFormGroup({
      title: new UntypedFormControl('', Validators.required),
      description: new UntypedFormControl('', Validators.required)


    });
  }

  save():void{

    if (this.ticketDetForm.valid) {
      this.ticketForm.emit(this.ticketDetForm);
      this.fileList.emit(this.files);
    }
    else {
      this.ticketDetForm.markAllAsTouched();
      this.toasty.error('Existem campos vazios.');
    }

  }

  formataName(nome :string):string{
        return nome.substring(nome.lastIndexOf('4__')+3);
  }

  uploadFile(files): void {

    this.systemcallAuxiliarTablesService.uploadDocument(files).subscribe(
      (result) => {
        this.buttonClass = 'attachment';
        this.upload.clear();
        this.files.push(result);
        //console.log(this.files);
      },
      (err) => {

        console.log(err);
      }
    );
    this.upload.clear();
  }

  removerItem(n:number): void {
    this.files.splice(n, 1);
  }

}
