<div>
  <p-tabView [activeIndex]="selectTab">
    <p-tabPanel header="{{ 'DATA' | translate }}" [disabled]="!isTabGeneralData">
      <app-customer-log-information *ngIf="isInformationChange" [customerLog]="customerLog" [isNew]="true"></app-customer-log-information>
      <app-customer-log-information *ngIf="isInformationChange" [customerLog]="customerLog" [isNew]="false"></app-customer-log-information>

      <app-customer-log-address *ngIf="isResidentialAddress" [title]="'RESIDENTIAL-ADDRESS'" [addressLog]="customerLog.residentialAddress" [addressLogOldData]="customerLog.oldDatas?.residentialAddress" [isNew]="true"></app-customer-log-address>
      <app-customer-log-address *ngIf="isResidentialAddress" [title]="'RESIDENTIAL-ADDRESS'" [addressLog]="customerLog.residentialAddress" [addressLogOldData]="customerLog.oldDatas?.residentialAddress" [isNew]="false"></app-customer-log-address>
      
      <app-customer-log-address *ngIf="isCommercialAddress" [title]="'COMMERCIAL-ADDRESS'" [addressLog]="customerLog.commercialAddress" [addressLogOldData]="customerLog.oldDatas?.commercialAddress" [isNew]="true"></app-customer-log-address>
      <app-customer-log-address *ngIf="isCommercialAddress" [title]="'COMMERCIAL-ADDRESS'" [addressLog]="customerLog.commercialAddress" [addressLogOldData]="customerLog.oldDatas?.commercialAddress" [isNew]="false"></app-customer-log-address>

      <app-customer-log-emergency-contact *ngIf="isEmergencyContact" [customerLog]="customerLog" [isNew]="true"></app-customer-log-emergency-contact>
      <app-customer-log-emergency-contact *ngIf="isEmergencyContact" [customerLog]="customerLog" [isNew]="false"></app-customer-log-emergency-contact>
    </p-tabPanel>

    <p-tabPanel header="{{ 'LICENCE' | translate }}" [disabled]="!isTabLicence">
        <app-customer-log-licence *ngIf="isLicence" [customerLog]="customerLog" [isNew]="true"></app-customer-log-licence>
        <app-customer-log-licence *ngIf="isLicence" [customerLog]="customerLog" [isNew]="false"></app-customer-log-licence>
    </p-tabPanel>

    <p-tabPanel header="Faturamento" [disabled]="!isTabBilling">
        <app-customer-log-billing *ngIf="isBillingCompanyLog" [customerLog]="customerLog" [isNew]="true"></app-customer-log-billing>
        <app-customer-log-billing *ngIf="isBillingCompanyLog" [customerLog]="customerLog" [isNew]="false"></app-customer-log-billing>

        <app-customer-log-billing-nf *ngIf="isIssRetidoFonte" [customerLog]="customerLog" [isNew]="true"></app-customer-log-billing-nf>
        <app-customer-log-billing-nf *ngIf="isIssRetidoFonte" [customerLog]="customerLog" [isNew]="false"></app-customer-log-billing-nf>

    </p-tabPanel>
  </p-tabView>
  <div style="margin: 15px">
    <div class="content-log">
      <div>
        <label>Data/hora</label>
        <label>{{ customerLog.logTime | date: "dd/MM/yyyy - HH:mm" }}</label>
      </div>
      <div>
        <label>Usuário</label>
        <label>{{ customerLog.user.username }}</label>
      </div>
      <div>
        <label>Origem</label>
        <label>{{ customerLog.origin }}</label>
      </div>
      <div>
        <label>ação</label>
        <label>{{ customerLog.operationLog | translate }}</label>
      </div>
    </div>
    <div class="buttons">
      <button class="easymarine primary" (click)="dialogRef.close()">
        Fechar
      </button>
    </div>
  </div>
</div>
