<div style="position: relative;">

  <button
    (click)="openProcess()"
    pButton
    type="button"
    icon="pi pi-bell"
    class="sling-button-local"
    *ngIf="marinaId==2"
  ></button>
  <!-- <div class="nNotification" (click)="openOrCloseMenu()">
    <span>{{ notRead }}</span>
  </div> -->

  <!-- <div class="divMenu" [ngClass]="{ fadeIn: showMenu === true }">
    <div class="divInterna">
      <div class="divSubTitulo">
        <h2 style="margin: 10px">Notificações</h2>
      </div>

        <div
          *ngFor="let notice of menuItems"
          class="flex-row parcels"
        >
          <div *ngIf="notice.new" class="divSubTitulo sub1">
            <i class="pi pi-calendar-plus">
              {{ notice.item.data | date : "dd/MM/YYYY" }}</i
            >
          </div>
          <i
            *ngIf="!notice.new"
            class="pi pi-file divSubTitulo-item"
            (click)="openNotification(notice.item)"
            (mouseover)="hoverNotification(notice)"
          >
            {{ concatText(notice.item) }}
          </i>
        </div>

    </div>
  </div> -->
</div>
