<div [ngSwitch]="activeIndex">
  <div *ngSwitchCase="0">
    <app-visitor-form
      [isNested]="true"
      [isProvider]="isProvider"
      [visitorForm]="visitorForm"
      [address]="address"
      (nextStep)="receiveVisitor($event)"
    ></app-visitor-form>
  </div>
  <div *ngSwitchDefault>
    <app-visitor-form-permission
      [isNested]="true"
      [accessPermissionForm]="accessPermissionForm"
      (previousStep)="receiveAccessPermission($event)"
      (saveServiceProviderAndPermission)="saveAll($event)"
    ></app-visitor-form-permission>
  </div>
</div>
