<div class="form-group">
  <div class="form-control content-date">
    <label for="requestDate">{{ 'Data de ' + title | translate }}</label>
    <div class="form-group">
        <button pButton class="date-button" label="-" type="button" (click)="moveDay(-1)"
            [disabled]="isMinDay() || started"></button>
        <button pButton class="date-button" label="+" type="button" (click)="moveDay(1)"
            [disabled]="started"></button>
        <div class="day-name" style="margin-right: 5px;">
            <span> {{newDate | date:'EEE':'pt-BR'}}</span>
        </div>
        <p-calendar dateFormat="dd/mm/yy" name="requestDate" [readonlyInput]="true" [minDate]="minDate"
          appendTo="body" [(ngModel)]="newDate" [showIcon]="true" i18n [disabled]="started"
          (onSelect)="changeDateByCalendar(newDate)" required styleClass="forecast-date">
        </p-calendar>
    </div>
  </div>
  <div class="form-control content-time">
    <label>{{ 'Hr ' + title | translate }}</label>
    <p-dropdown placeholder="Selecione..." [options]="availablePeriods"
      [(ngModel)]="selectedPeriods"
      appendTo="body" name="executionForecast" [required]="!manualHour"
      [disabled]="started || !availablePeriods || availablePeriods.length <= 0"
      (onChange)="selectDateTime($event)" *ngIf="!manualHour && availablePeriods">
    </p-dropdown>
    <input class="width-input" pInputText type="time" [style]="{display: 'block'}" name="executionForecast"
      [(ngModel)]="dateManual" [required]="manualHour"
      [disabled]="started"
      (blur)="selectManualDateTime($event, dateManual);"
      *ngIf="manualHour">
  </div>
  <div class="form-control content-manual">
    <label>Manual<span style="color: red;" *ngIf="manualHourRequired">*</span></label>
    <p-inputSwitch [(ngModel)]="manualHour"
      (onChange)="onChangeManual($event)"
      name="manualHour"
      [disabled]="started">
    </p-inputSwitch>
  </div>
</div>
