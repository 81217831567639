<!-- <div class="general-class">
  <div class="text-area">
    <h1>ÁREA DE COBRANÇA</h1>
  </div>
  <div class="email-download">
    <div class="box-info">
      <div class="box-white-area">
        <div class="logo-holder">
          <div class="logo">
            <img src="{{marinaNameAndLogo.logoFile}}" alt="logo">
          </div>
        </div>
        <div class="box-white-info" *ngIf="!invoice; else invoiceDownload">
          <h1>{{'DOWNLOAD-AREA' | translate}}</h1>
          <form (submit)="sendFederalId('Physical')" [formGroup]="emailDownloadForm" class="p-field" *ngIf="federalIdType == 'Physical'; else juridicalTemplate">
            <div class="form-control">
              <i class="icon pi pi-lock"></i>
              <span class="p-label">
                <label for="cpf">{{'FEDERAL-ID.PHYSICAL' | translate}} *</label>
                <input type="cpf" formControlName="userFederalId" id="cpf" name="cpf" pInputText autocomplete="off" mask="000.000.000-00" placeholder="Digite aqui o CPF">
              </span>
            </div>
            <button [disabled]="!emailDownloadForm.valid" type="submit" class="primary easymarine" pButton label="Visualizar Cobrança"></button>
          </form>

          <ng-template #juridicalTemplate>
            <form  (submit)="sendFederalId('Juridical')" [formGroup]="emailDownloadForm" class="p-field">
              <div class="form-control">
                <i class="icon pi pi-lock"></i>
                <span class="p-label">
                  <label for="cnpj">{{'FEDERAL-ID.JURIDICAL' | translate}} *</label>
                  <input type="cnpj" formControlName="userFederalId" id="cnpj" name="cnpj" pInputText autocomplete="off" mask="00.000.000/0000-00" placeholder="Digite aqui o CNPJ">
                </span>
              </div>
              <button [disabled]="!emailDownloadForm.valid" type="submit" class="primary easymarine" pButton label="Visualizar Cobrança"></button>
            </form>
          </ng-template>
        </div>
        <ng-template #invoiceDownload>
          <div class="box-white-info">
            <h1>{{'DOWNLOAD-PDF' | translate}}</h1>

            <div class="download-files">
              <div *ngIf="invoicePdf">
                <div class="invoice-box">
                  <i class="pi pi-sign-in rotate"></i>
                  <div class="invoice-right-box">
                    <label class="invoice-type">
                      <p>Fatura</p>
                    </label>
                    <button type="button" (click)="generateInvoicePdf()" pButton class="p-button-outlined p-button-secondary" label="Baixar Fatura"></button>
                  </div>
                </div>
              </div>

              <div *ngIf="invoiceNfe">
                <div class="invoice-box">
                  <i class="pi pi-sign-in rotate"></i>
                  <div class="invoice-right-box">
                    <label class="invoice-type">
                      <p>Nota Fiscal</p>
                    </label>
                    <button type="button" (click)="downloadPdf(invoiceNfe, 'Nfe')" pButton class="p-button-outlined p-button-secondary" label="Baixar Nota Fiscal"></button>
                  </div>
                </div>
              </div>

              <div *ngIf="invoiceBudget">
                <li *ngFor="let budget of invoiceBudget; let ind = index">
                  <div class="invoice-box">
                    <i class="pi pi-sign-in rotate"></i>
                    <div class="invoice-right-box">
                      <label class="invoice-type">
                        <p>Boleto</p>
                        <p>{{getMonth(budget.dueDate)}}</p>
                      </label>
                      <button type="button" (click)="downloadPdf(budget.documentUrl, 'Boleto-' + (ind + 1))" pButton class="p-button-outlined p-button-secondary" label="Baixar boleto"></button>
                    </div>
                  </div>
                </li>
              </div>

              <div *ngIf="invoiceReceipt">
                <div class="invoice-box">
                  <i class="pi pi-sign-in rotate"></i>
                  <div class="invoice-right-box">
                    <label class="invoice-type">
                      <p>Recibo</p>
                    </label>
                    <a href="{{invoiceReceipt.url}}" target="_blank">
                      <button type="button" (click)="downloadPdf(invoiceReceipt.url, 'Recibo-')" pButton class="p-button-outlined p-button-secondary" label="Baixar Recibo"></button>
                    </a>
                  </div>
                </div>
              </div>

            </div>
            <div class="buttons-allignement">
              <button type="button" (click)="return()" pButton class="p-button-outlined p-button-secondary" label="Voltar"></button>
              <a href="https://easymarine.com.br" target="_blank">
                <button type="button" pButton pRipple label="Ir para Home"></button>
              </a>
            </div>
          </div>
        </ng-template>
      </div>
      <div *ngIf="!invoice" class="box-contact">
        <img src="../../../../assets/images/email/ICON_AMBIENTESEGURO.png" alt="">
      </div>
      <div *ngIf="invoice" class="box-contact">
        <span *ngIf="marinaNameAndLogo.email || marinaNameAndLogo.phone">Caso encontre alguem problema entrar em contato</span>
        <span *ngIf="marinaNameAndLogo.email"> {{marinaNameAndLogo.tradeName}} - {{marinaNameAndLogo.email}}</span>
        <span *ngIf="marinaNameAndLogo.phone"> {{marinaNameAndLogo.phone}}</span>
      </div>
    </div>
  </div>
</div> -->

<div class="general-class">
  <div class="text-area">
    <h1 class="text">ÁREA DE COBRANÇA</h1>
  </div>
  <div class="email-download">
    <div class="box-info">
      <div class="box-white-area">
        <div class="logo-holder">
          <div class="logo">
            <img src="{{marinaNameAndLogo.logoFile}}" alt="logo">
          </div>
        </div>
        <div class="box-white-info" *ngIf="invoices.length === 0; else invoiceDownload">
          <form (submit)="getInvoiceAndBillets('Physical')" [formGroup]="emailDownloadForm" class="p-field" *ngIf="federalIdType == 'Physical'; else juridicalTemplate">
            <div class="form-control">
              <span class="p-label">
                <input type="cpf" formControlName="userFederalId" id="cpf" name="cpf" pInputText autocomplete="off" mask="000.000.000-00" placeholder="CPF">
              </span>
            </div>
            <div class="button-hand">
              <button [disabled]="!emailDownloadForm.valid" type="submit" class="button-random">
                <span>VISUALIZAR COBRANÇA</span>
              </button>
              <img src="../../../../assets/images/email/HAND.svg" alt="">
            </div>
          </form>

          <ng-template #juridicalTemplate>
            <form  (submit)="getInvoiceAndBillets('Juridical')" [formGroup]="emailDownloadForm" class="p-field">
              <div class="form-control">
                <span class="p-label">
                  <input type="cnpj" formControlName="userFederalId" id="cnpj" name="cnpj" pInputText autocomplete="off" mask="00.000.000/0000-00" placeholder="CNPJ">
                </span>
              </div>
              <button [disabled]="!emailDownloadForm.valid" type="submit" class="button-random">
                <span>VISUALIZAR COBRANÇA</span>
              </button>
            </form>
          </ng-template>
        </div>
        <ng-template #invoiceDownload>
          <div class="button-random button-random-second-page">
            <span>{{clientName}}</span>
          </div>
          <div class="button-random button-random-second-page">
            <span>{{federalId}}</span>
          </div>
          <div *ngFor="let budget of invoiceBudget">
            <div class="button-text">
              <div>
                <span>Vencimento: </span><p>{{formatDate(budget?.dueDate)}}</p>
              </div>
              <div>
                <span>Nº Fatura: </span><p>{{invoices[0].invoiceNumber}}</p>
              </div>
              <div>
                <span>Valor Total: </span><p>R${{budget?.value | number:'1.2-2'}}</p>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="box-contact">
        <div *ngIf="invoices.length === 0; else invoicePage" class="content-first-page">
          <img src="../../../../assets/images/email/ICON_AMBIENTESEGURO_BRANCO.png" alt="">
        </div>
        <ng-template #invoicePage>
          <div class="content-seccond-page">
            <img class="secure-img" src="../../../../assets/images/email/ICON_AMBIENTESEGURO_BRANCO.png" alt="">
            <img src="../../../../assets/images/logoBranca.svg" alt="">
          </div>
        </ng-template>
      </div>
    </div>
    <div class="box-result">
      <div *ngIf="invoices.length === 0 && !(tabletResponsive || mobileResponsive); else resultInvoices" class="no-result">
        <img src="../../../../assets/images/logoBranca.svg" alt="">
      </div>
      <ng-template #resultInvoices>
        <div class="result">
          <div *ngIf="invoiceBudget" style="display: flex;">
            <div *ngFor="let budget of invoiceBudget; let ind = index" style="display: flex; padding-left: 50px;">
              <img src="../../../../assets/images/email/ICON_BOLETO.svg" alt="" (click)="downloadPdf(budget.documentUrl, 'Boleto-' + (ind + 1))">
              <h3 class="date-float">{{budget.dueDate | date: "dd/MM"}}</h3>
            </div>
          </div>
          <div *ngIf="invoicePdf">
            <img src="../../../../assets/images/email/ICON_FATURA.svg" alt="" (click)="generateInvoicePdf()">
          </div>
          <div *ngIf="invoiceNfe">
            <img src="../../../../assets/images/email/ICON_NOTAFISCAL.svg" (click)="downloadPdf(invoiceNfe, 'Nfe')" alt="">
          </div>
          <div *ngIf="invoiceReceipt">
            <img src="../../../../assets/images/email/ICON_RECIBO.svg" alt="" (click)="downloadPdf(invoiceReceipt.url, 'Recibo-')">
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
