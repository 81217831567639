import { ServiceBoat } from './../../../../models/service-boat';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { ServiceBoatReadjustmentDTO } from 'src/app/models/dtos/service-boat-readjustmentDTO';
import { servicesReadjustmentRegisterPriceFreeDTO } from 'src/app/models/dtos/services-readjustment-register-price-freeDTO';
import { ReadjustmentType } from 'src/app/models/enums/readjustment-type';
import { BoatService } from 'src/app/services/boat.service';
import { CustomerService } from 'src/app/services/customer.service';
import { ProductService } from 'src/app/services/product.service';
import { ServiceBoatService } from 'src/app/services/service-boat.service';
import { ToastService } from 'src/app/services/toast.service';
import { DeepCloneUtil } from 'src/app/utils/deep-clone.util';
import { FormatUtil } from 'src/app/utils/format.util';
import { MessageUtil } from 'src/app/utils/message.util';
import { ShipType } from 'src/app/models/ship-type';
import Swal from 'sweetalert2';
import { SlingConfig } from 'src/app/models';



import { StorageUtil } from 'src/app/utils/storage.util';

import { SelectItem } from 'primeng/api';
import { ReajustmentHistoricComponent } from './reajustment-historic/reajustment-historic/reajustment-historic.component';
import { BroadcastService } from 'src/app/services/broadcast.service';



@Component({
  selector: 'app-list-price-free',
  templateUrl: './list-price-free.component.html',
  styleUrls: ['./list-price-free.component.scss']
})
export class ListPriceFreeComponent implements OnInit {

  @Input() slingConfig: SlingConfig;
  filteredCustomers: any[] = [];
  filteredBoats: any[] = [];
  filteredServicesInUse: any[] = [];
  selectedServices: any[] = [];
  servicesOriginal: ServiceBoatReadjustmentDTO[] = [];
  services: ServiceBoatReadjustmentDTO[] = [];
  filter: any = {}

  readjustmentTypes: { label: string; value: string; }[] = [];
  adjustmentForm: UntypedFormGroup;

  totalValues = 0;
  totalDiscounts = 0;
  totalDueDateDiscounts = 0;
  totalReadjustmentsValues = 0;
  totalReadjustmentsDiscounts = 0;
  totalReadjustmentsDueDateDiscounts = 0;

  constructor(
    private customerService: CustomerService,
    private boatService: BoatService,
    private productService: ProductService,
    private serviceBoatService: ServiceBoatService,
    public dialog: DialogService,
    private spinner: NgxSpinnerService,
    private toasty: ToastService,
    private messageUtil: MessageUtil,
  ) { }

  ngOnInit(): void {
    this.getTypes();
    this.getCustomers();
    this.getBoats();
    this.getPeriodicalServiceInUse();
    this.findServicesBoat();
    this.startForm();
  }



  startForm() {
    this.adjustmentForm = new UntypedFormGroup({
      percentage: new UntypedFormControl(null, Validators.compose([Validators.required])),
      readjustmentType: new UntypedFormControl(null, Validators.compose([Validators.required])),
      diasReajuste: new UntypedFormControl(12, Validators.compose([Validators.required]))
    });
  }

  getTypes() {
    this.readjustmentTypes = Object.keys(ReadjustmentType).map(item => ({ label: item, value: item }));
  }

  getCustomers(): void {
    this.customerService.getPeriodicalServiceInUse().subscribe((data) => {
      this.filteredCustomers = [{ label: 'Todos', value: null }].concat(data.map((c) => ({ label: c.name + (c.active ? '' : " (inativo)"), value: c.id })));
    });
  }

  getBoats(): void {
    this.boatService.getBoatsWithPeriodicalServicePriceFreeInUse().subscribe((data) => {
      this.filteredBoats = [{ label: 'Todos', value: null }].concat(data.filter(boat => boat.active).map((b) => ({ label: b.name, value: b.id })));
    });
  }

  getPeriodicalServiceInUse(): void {
    this.productService.getPeriodicalServicePriceFreeInUseFilterList().subscribe((data) => {
      this.filteredServicesInUse = [{ label: 'Todos', value: null }].concat(data.map((serviceInUse) => ({ label: serviceInUse.name, value: serviceInUse.id })));
    });
  }

  getImageShipType(shipType: ShipType): string {
    return '/assets/images/'.concat(shipType).concat('.png');
  }

  clear(){
    this.filter = {};
  }

  search(){
    this.findServicesBoat();
  }

  save() {
    const servicesReadjustment: servicesReadjustmentRegisterPriceFreeDTO[] = this.services.filter(s => s.readjustmentValue).map(s => ({
      serviceBoatId: s.serviceBoatId,
      value: s.readjustmentValue,
      discount: s.readjustmentDiscount,
      dueDateDiscount: s.readjustmentDueDateDiscount,
      newEndService: s.newEndService,
      indexUsed: this.adjustmentForm.get("percentage").value,
      indexUsedText: this.slingConfig.indexUsed
    }));
    Swal.fire({
      title: 'Registrar reajuste',
      text: 'Desejas realizar o reajuste de '+servicesReadjustment.length+' mensalidade'+( servicesReadjustment.length > 1 ? 's' : '')+'?',
      icon: 'warning',
      reverseButtons: true,
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar'
    }).then((response) => {
      if (response.value) {
        this.sendReadjustments(servicesReadjustment);
      }
    });

  }

  async sendReadjustments(readjustment: servicesReadjustmentRegisterPriceFreeDTO[]): Promise<void> {
    return new Promise(resolve =>{
        this.spinner.show();
        this.serviceBoatService.registerReadjustmentPriceFree(readjustment)
          .subscribe(data => {
            this.toasty.success('Reajuste realizado com sucesso');
            this.spinner.hide();
            this.findServicesBoat();
            resolve();
          }, error => {
            const exception = error.error.data.exception;
            this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
            this.spinner.hide();
            resolve();
          });
    })
  }

  clearAdjustments() {
    this.services = DeepCloneUtil.clone(this.servicesOriginal);
  }

  readjustment(){
    this.selectedServices.forEach( service => {
      service.readjustmentDiscount = service.discount;
      service.readjustmentDueDateDiscount = service.dueDateDiscount;
      if (service.contractEndDate){
        service.newEndService = this.newEndServiseAjustement(service.contractEndDate,this.adjustmentForm.get('diasReajuste').value);
      }
    });
    this.addReajustmentValue(this.selectedServices, this.adjustmentForm.get('percentage').value);
    if ( this.adjustmentForm.get('readjustmentType').value ===  'VALUEDISCOUNT') {
      this.addReajustmentDiscount(this.selectedServices, this.adjustmentForm.get('percentage').value);
      this.addReajustmentDueDateDiscount(this.selectedServices, this.adjustmentForm.get('percentage').value);
    }
    this.calcTotals();
  }

  showHide(): void {
    BroadcastService.get('showHideSidenav').emit(false);
  }


  removeReajustment(service: ServiceBoatReadjustmentDTO) {
    service.readjustmentValue = undefined;
    service.readjustmentDiscount = undefined;
    service.readjustmentDueDateDiscount = undefined;
    this.calcTotals();
  }

  totalReajustment(service: ServiceBoatReadjustmentDTO): number {
    if(service.readjustmentValue){
      return service.readjustmentValue - service.readjustmentDiscount - service.readjustmentDueDateDiscount
    }
    return
  }

  totalOldValue(service: ServiceBoatReadjustmentDTO): number {
    return service.value - service.discount - service.dueDateDiscount;
  }

  differenceReajustment(service: ServiceBoatReadjustmentDTO): number {
    if(service.readjustmentValue) {
      return this.totalReajustment(service) - this.totalOldValue(service)
    }
    return
  }


  newEndServiseAjustement(dtAtual: Date, meses: number ): Date {
    if(dtAtual) {
      let newDate: Date = new Date (dtAtual);
      newDate.setMonth(new Date(dtAtual).getMonth()+meses);
      return new Date(new Date(newDate).getFullYear(), new Date(newDate).getMonth() + 1, 0);
    }
    return
  }

  private addReajustmentValue(services: ServiceBoatReadjustmentDTO[], percentage: number){
    services.forEach( service => {
      service.readjustmentValue = service.value + FormatUtil.roundTwoDecimal(service.value * (percentage/100))
    });
  }

  private addReajustmentDiscount(services: ServiceBoatReadjustmentDTO[], percentage: number){
    services.forEach( service => {
      service.readjustmentDiscount = FormatUtil.roundTwoDecimal(service.discount + FormatUtil.roundTwoDecimal(service.discount * (percentage/100)))
    });
  }

  private addReajustmentDueDateDiscount(services: ServiceBoatReadjustmentDTO[], percentage: number){
    services.forEach( service => {
      service.readjustmentDueDateDiscount = FormatUtil.roundTwoDecimal(service.dueDateDiscount + FormatUtil.roundTwoDecimal(service.dueDateDiscount * (percentage/100)))
    });
  }

  enableApllyPorcentagemSelectedService(): boolean {
    return this.selectedServices?.length < 1 || this.adjustmentForm.invalid;
  }

  enableSendEmails(): boolean {
    return this.selectedServices?.length < 1 ;
  }

  enableReadjustment(): boolean {
    return this.services.filter(s => s.readjustmentValue).length > 0;
  }

  async findServicesBoat(): Promise<void> {
    return new Promise(resolve =>{
        this.spinner.show();
        this.serviceBoatService.getServicesBoatPriceFree(this.filter)
          .subscribe(data => {
            this.selectedServices = [];
            this.services = data;
            this.servicesOriginal = DeepCloneUtil.clone(data);
            this.calcTotals();
            this.spinner.hide();
            resolve();
          }, error => {
            this.spinner.hide();
            resolve();
          });
    })
  }

  calcTotals() {
    this.totalValues = 0;
    this.totalDiscounts = 0;
    this.totalDueDateDiscounts = 0;
    this.totalReadjustmentsValues = 0;
    this.totalReadjustmentsDiscounts = 0;
    this.totalReadjustmentsDueDateDiscounts = 0;

    if(this.services) {
      this.services.filter( service => service.readjustmentValue ).forEach( service => {
        this.totalValues += service.value;
        this.totalDiscounts += service.discount;
        this.totalDueDateDiscounts += service.dueDateDiscount;
        this.totalReadjustmentsValues += service.readjustmentValue;
        this.totalReadjustmentsDiscounts += service.readjustmentDiscount;
        this.totalReadjustmentsDueDateDiscounts += service.readjustmentDueDateDiscount;
      });
    }
  }

  calcTotalValueTotal(): number {
    const totalValuesTotal = this.totalValues - this.totalDiscounts;
    return totalValuesTotal > 0 ? totalValuesTotal : 0;
  }

  calcReadjustementTotalValueTotal(): number {
    const totalValuesTotal = this.totalReadjustmentsValues - this.totalReadjustmentsDiscounts - this.totalReadjustmentsDueDateDiscounts;
    return totalValuesTotal > 0 ? totalValuesTotal : 0;
  }

  differenceTotals(): number {
    return (this.totalReadjustmentsValues - this.totalValues) - (this.totalReadjustmentsDiscounts - this.totalDiscounts) - (this.totalReadjustmentsDueDateDiscounts - this.totalDueDateDiscounts);
  }

  calcTotalDiscountsReadjustments(): void {
    let total: number = 0;
    if(this.services) {
      total = this.services
        .map(s => s.readjustmentDiscount ? s.readjustmentDiscount : 0)
        .reduce((total, value ) => { return total += value } , 0);
    }
    this.totalReadjustmentsDiscounts = total;
  }

  calcTotalDueDateDiscountsReadjustments(): void {
    let total: number = 0;
    if(this.services) {
      total = this.services
        .map(s => s.readjustmentDueDateDiscount ? s.readjustmentDueDateDiscount : 0)
        .reduce((total, value ) => { return total += value } , 0);
    }
    this.totalReadjustmentsDueDateDiscounts = total;
  }

  confirmSendEmail():void{
    if (this.selectedServices?.length<=0){
      return
    }
    Swal.fire({
      title: 'Envio de Email',
      text: 'Confirma o envio de emails para os proprietários das embarcações selecionadas?',
      icon: 'warning',
      reverseButtons: true,
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar'
    }).then((response) => {
      if (response.value) {
        this.sendEmails(this.selectedServices);
      }
    });

  }

  async sendEmails(servicesSelect: any[]): Promise<void> {
    return new Promise(resolve =>{
        this.spinner.show();
        this.serviceBoatService.sendEmailsReajustment(servicesSelect)
          .subscribe(data => {
            this.spinner.hide();
            const sendError: string[] = data;
            if (sendError.length > 0){
              let mensagem = '';
              sendError.forEach((currentValue) => { mensagem = mensagem + currentValue + ' \n'; });
              this.messageUtil.generateMessage('warning', 'Envio de Email', mensagem);
            }
            else{
              this.messageUtil.generateMessage('success', 'Envio de Email', 'Todos os e-mails foram enviados com sucesso!');
            }
            resolve();
          }, error => {
            const exception = error.error.data.exception;
            this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
            this.spinner.hide();
            resolve();
          });
    })
  }

  openHistoric(serviceBoat: ServiceBoatReadjustmentDTO): void {
    const dialog = this.dialog.open(ReajustmentHistoricComponent, {
      dismissableMask: false,
      width: '75%',
      data: {
        serviceBoat
      },
      header: 'Histórico de Reajustes'
    });

    dialog.onClose.subscribe();
  }

  getBookColor(service: ServiceBoat): string{
    if (service.serviceHistoryReajustment === null || service.serviceHistoryReajustment.length === 0){
      return 'black-icon';
    }
    if (!service.serviceHistoryReajustment[0].sendEmail){
      return 'red-icon';
    }else{
      return 'blue-icon';
    }
   //atencao eu pego a posicao 0 pois ela vem ordenada pelo maior ID com isso nao preciso checar se é o ultimo
  }


}
