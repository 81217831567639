import { Accessory } from './acessory';

export class AccessoryBoat {
    accessory: Accessory;
    quantity: number;

    constructor() {
        this.accessory = new Accessory();
    }
}
