import { UsuarioChamado } from './usuario-chamado';
import { Usuario } from './../finance/usuario';
import { SolicitanteClienteChamado } from './solicitante-cliente-chamado';
import { AtividadeChamado } from './atividade-chamado';
import { CampoId } from './campo-id';
export class AberturaChamado {



  atividades: AtividadeChamado[] = [];

  solicitanteCliente: SolicitanteClienteChamado;

  usuario:UsuarioChamado;  //ok,
  dataHoraRecebido: any; //"2022-08-08T19:11:00.000Z",
  situacaoChamado: string ="ABERTO";
  origemChamado: string = "INTERNO";
  assunto: string;


  //constructor(assunto: string, usuario: any, solicitanteCliente: any, dataHoraRecebido: any, atividades: AtividadeChamado[], situacaoChamado: string, origemChamado: string) {
    constructor(
      assunto: string,
      usuario: UsuarioChamado,

    ) {
    this.assunto = assunto;
    this.usuario = usuario;
    this.solicitanteCliente = new  SolicitanteClienteChamado(0,null,new CampoId(0));

    this.dataHoraRecebido = new Date();
    this.atividades = [];
    this.situacaoChamado = "ABERTO";
    this.origemChamado = "INTERNO";
  }

}
