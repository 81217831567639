import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { RoleLogModalComponent } from '../role-log-modal/role-log-modal.component';

@Component({
  selector: 'app-role-log-table',
  templateUrl: './role-log-table.component.html',
  styleUrls: ['./role-log-table.component.scss']
})
export class RoleLogTableComponent implements OnInit {


  @Input()
  roleLogs = [];

  @Input()
  isDialog = false;

  numberOfRows = 10;

  constructor(
    private slingConfigService: SlingConfigService,
    public dialog: DialogService,
  ) { }

  ngOnInit(): void {
    if ( !this.isDialog) {
      this.loadSlingConfig();
    }
  }

check(a:boolean):string
{
  if(a)
  {
    return "Sim";
  }
  else
  {
    return "Não";
  }
}

  loadSlingConfig(): void {
    this.slingConfigService.getSlingConfig().subscribe(
      (data) => {
        const slingConfig = data[0];
        if (slingConfig != null) {
          this.numberOfRows = slingConfig.numberOfRecordsPerPage;
        } else {
          this.numberOfRows = 10;
        }
      },
      (error) => null);
  }

  openUserLogDialog(roleLog): void {
    this.dialog.open(RoleLogModalComponent, {
      height: '85vh',
      width: '80%',
      dismissableMask: false,
      data: {
        roleLog
      },
      header: 'Log de Perfis de Usuários'
    })
  }


}
