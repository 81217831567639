export class OrderInvoiceFilter {
    referenceStart?: Date;
    referenceEnd?: Date;
    referenceDate?: Date;
    orderOrigin: string[];
    groupIds: number[];
    productTypes: string[];
    productListIds: number[] = [];
    filterBillingCompany = false;
    billingCompany: { id: number };

    constructor() {
        this.referenceDate = new Date();
        this.orderOrigin = [];
    }
}
