import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-boat-log-customer',
  templateUrl: './boat-log-customer.component.html',
  styleUrls: ['./boat-log-customer.component.scss']
})
export class BoatLogCustomerComponent implements OnInit {

  @Input() owners;
  @Input() ownersOld;
  @Input() isNew;

  constructor() { }

  ngOnInit(): void { }

  logsTable(): any[]{
    if(this.isNew){
      return this.owners;
    } else {
      return this.ownersOld;
    }
  }

  isChanged(customer): boolean {
    let customerVerify;
    if(this.isNew){
      customerVerify = this.findOwnerOld(customer.customerId);
    } else {
      customerVerify = this.findOwner(customer.customerId);
    }

    return !customerVerify || (customerVerify.name !== customer.name
      || customerVerify.quota !== customer.quota
      || customerVerify.sharedInvoiceCustomerApp !== customer.sharedInvoiceCustomerApp);
  }


  findOwner(id): any{
    return this.owners.find(c => c.customerId === id);
  }

  findOwnerOld(id): any{
    return this.ownersOld.find(c => c.customerId === id); 
  }

}
