import { Licence } from 'src/app/models/license';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Countries } from 'src/app/utils/location/countries';
import { LocationService } from 'src/app/services/location.service';
import { FileUpload } from 'primeng/fileupload';
import { MessageUtil } from 'src/app/utils/message.util';
import { SailorService } from 'src/app/services/sailor.service';

@Component({
  selector: 'app-sailor-license',
  templateUrl: './sailor-license.component.html',
  styleUrls: ['./sailor-license.component.scss'],
})
export class SailorLicenseComponent implements OnInit {
  @ViewChild('upload', { static: false }) upload: FileUpload;

  @Input()
  licenseForm = new UntypedFormGroup({});
  //license: Licence;

  @Output()
  finishEvent: EventEmitter<any> = new EventEmitter();

  file: File;
  imgPreview: any;

  typesLicense = [
    { label: 'Selecione...' },
    { label: 'Veleiro', value: 'SAILBOAT' },
    { label: 'Motonauta', value: 'MOTONAUTA' },
    { label: 'Arrais-Amador', value: 'ARRAIS_AMATEUR' },
    { label: 'Mestre-Amador', value: 'MASTER_AMATEUR' },
    { label: 'Capitão-Amador', value: 'AMATEUR_CAPTAIN' },
    { label: 'Arrais-Amador e Motonauta', value: 'ARRAIS_AMATEUR_MOTONAUTA' },
    { label: 'Mestre-Amador e Motonauta', value: 'MASTER_AMATEUR_MOTONAUTA' },
    { label: 'Capitão-Amador e Motonauta', value: 'AMATEUR_CAPTAIN_MOTONAUTA' },
  ];

  errorMessage: string;
  countries: { label: string; value: any }[];
  groups: { label: string; value: any }[] = new Array();
  states: { label: string; value: any }[];
  cities: string[];
  filteredCities: any;

  constructor(private locationService: LocationService,
    private messageUtil: MessageUtil,
    private sailorService: SailorService
    ) {}

  ngOnInit(): void {

    if (this.licenseForm.get('id').value == null) {
      this.licenseForm.get('country').setValue('BR');
    }


    this.loadCountries();
    this.loadStates('BR');
    this.licenseForm.valueChanges.subscribe(change=>this.enviar());
    this.imgPreview=this.licenseForm.get("file").value;
    this.enviar();
  }

  enviar(){
    this.finishEvent.emit(this.licenseForm );
  }

  loadCountries(): void {
    this.countries = [{ label: 'Selecione...', value: null }].concat(
      Countries.countryList.map((c) => ({ label: c.name, value: c.code }))
    );
  }

  loadStates(countryId): void {
    this.states = [{ label: 'Selecione...', value: null }].concat(
      this.locationService
        .getStates()
        .map((s) => ({ label: s.name, value: s.id }))
    );
  }

  loadCities(countryId: string, stateId: string): void {
    this.locationService.getCities(countryId, stateId).subscribe(
      (data) => {
        this.cities = data.map((c) => c.name);
      },
      (error) => {
        this.cities = null;
        this.errorMessage = error;
      }
    );
  }
  filterCities(val: any): void {
    this.filteredCities = val
      ? this.cities.filter((c) =>
          c.toUpperCase().includes(val.query.toUpperCase())
        )
      : this.cities;
  }

  receiveUploadUrl($event){
    this.licenseForm.get("file").setValue($event);
    this.enviar();

  }

}
