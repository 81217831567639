<div class="dialog-form">
    <p-header>
        <div>
            <span>{{customers.length == 1 ? 'Cliente: ' : 'Clientes: '}}</span>
            <span *ngFor="let customer of customers">
                {{customers.indexOf(customer) + 1 == customers.length && customers.length > 1 ? ' e ' + customer.name :
                customers.indexOf(customer) + 1 < customers.length && customers.length> 1
                    && customers.indexOf(customer) != 0 ? ', ' + customer.name :
                    customer.name}}
            </span>
        </div>
    </p-header>
    <br>
    <div class="table">
        <p-table [value]="orders" autoLayout="true" [rows]="3" [paginator]="true" styleClass="p-datatable-striped">
            <ng-template pTemplate="header">
                <tr>
                    <th>Ped</th>
                    <th>Orig</th>
                    <th>Embarcação</th>
                    <th>Solicitante</th>
                    <th>Produto</th>
                    <th class="text-right">Preço unitário</th>
                    <th class="text-right">Solicitado</th>
                    <th class="text-right">Atendido</th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-order>
                <tr [ngClass]="{ 'canceled-tr' : order.orderStatus === 'Canceled',
                                    'partially-attempted-tr' : order.orderStatus === 'PartiallyAttempted',
                                    'attempted-tr' : order.orderStatus === 'Attempted',
                                    'ordered-tr': order.orderStatus === 'Ordered' }">
                    <td>
                        <span class="p-column-title">Pedido</span>
                        {{order.orderNumber}}
                    </td>
                    <td class="text-center">
                        <span class="p-column-title">Origem</span>
                        <div [ngSwitch]="order.origin">
                            <div *ngSwitchCase="'DETACHED'">
                                <i [icon]="'shopping-basket'" class="table"></i>
                            </div>
                            <div *ngSwitchCase="'SLING'">
                                <i [icon]="'ship'" class="table"></i>
                            </div>
                            <div *ngSwitchCase="'TUITION'">
                                <i [icon]="'university'" class="table"></i>
                            </div>
                        </div>
                    </td>
                    <td>
                        <span class="p-column-title">Embarcação</span>
                        {{order.boat.name}}
                    </td>
                    <td>
                        <span class="p-column-title">Solicitante</span>
                        {{order.customer.name}}
                    </td>
                    <td colspan="4">
                        <p-table [value]="order.orderItems" [rows]="3" [paginator]="true" [alwaysShowPaginator]="false"
                            styleClass="subtable">
                            <ng-template pTemplate="body" let-item>
                <tr [ngClass]="{ 'canceled-tr' : order.orderStatus === 'Canceled',
                                                    'partially-attempted-tr' : order.orderStatus === 'PartiallyAttempted',
                                                    'attempted-tr' : order.orderStatus === 'Attempted',
                                                    'ordered-tr': order.orderStatus === 'Ordered' }">
                    <td>
                        <span class="p-column-title">Item</span>
                        {{item.product.name}}
                    </td>
                    <td class="text-right">
                        <span class="p-column-title">Valor</span>
                        {{item.itemValue | number:'1.2-2'}}
                    </td>
                    <td class="text-right">
                        <span class="p-column-title">Qtd. Solicitada</span>
                        {{item.fuelMeasurementType == 'FuelFraction' ? (orderUtil.fuelQuantityView(item)) :
                        (item.qtyRequested | number:'1.2-2')}}

                    </td>
                    <td class="text-right">
                        <span class="p-column-title">Qtd. Fornecida</span>
                        {{item.qtySupplied | number:'1.2-2'}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        </td>
        <td class="text-center">
            <i class="pi pi-circle-on" [ngClass]="{ 'canceled' : order.orderStatus === 'Canceled',
                                    'partially-attempted' : order.orderStatus === 'PartiallyAttempted',
                                    'attempted' : order.orderStatus === 'Attempted',
                                    'ordered': order.orderStatus === 'Ordered' }">
            </i>
        </td>
        </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="9">
                    Sem pedidos para a data de hoje
                </td>
            </tr>
        </ng-template>
        </p-table>
    </div>
    <br>
    <div class="subtitle">
        <span class="sub-title">
            <i class="pi pi-circle-on attempted"></i>&nbsp;<span>Atendido</span>
        </span>
        <span class="sub-title">
            <i class="pi pi-circle-on partially-attempted"></i>&nbsp;<span>Parcial</span>
        </span>
        <span class="sub-title">
            <i class="pi pi-circle-on ordered"></i>&nbsp;<span>Solicitado</span>
        </span>
        <span class="sub-title">
            <i class="pi pi-circle-on canceled"></i>&nbsp;<span>Cancelado</span>
        </span>
    </div>
    <div class="buttons">
        <button type="button" class="easymarine primary" (click)="singleOrder()">
            Incluir pedido avulso
        </button>
        <button type="button" class="easymarine danger" style="background-color: gray"
            (click)="dialog.close()">Fechar</button>
    </div>
</div>
