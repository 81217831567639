import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Notice } from '../models/notice';
import { StorageUtil } from '../utils/storage.util';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class NoticeService {

    constructor(
        private http: HttpClient
    ) { }

    getByMarina(): Observable<Notice[]> {
        return this.http.get(environment.apiHost + `/secure/notice/marina/${StorageUtil.getMarinaId()}`)
            .pipe(map((response: any) => response.data.noticies as Notice[]));
    }

    getById(id): Observable<Notice[]> {
        return this.http.get(environment.apiHost + `/secure/notice/${id}`)
            .pipe(map((response: any) => response.data.notice as Notice[]));
    }

    save(notice): Observable<Notice> {
        return this.http.post(environment.apiHost + `/secure/notice`, notice)
            .pipe(map((response: any) => response.data.notice as Notice));
    }

    delete(id): Observable<any> {
        return this.http.delete(environment.apiHost + `/secure/notice/${id}`)
            .pipe(map((response: any) => response.data as any));
    }

    uploadFile(file: any): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('attachment', file);

        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'multipart/form-data');

        return this.http.post(
            environment.apiHost + '/secure/notice/image-file/attachment',
            formData,
            { headers }
        ).pipe(map((response: any) => response.data.attachment));
    }

    getListUser(id): Observable<any[]> {
      return this.http.get(environment.apiHost + `/secure/notice/listuserbynoticie/${id}`)
          .pipe(map((response: any) => response.data.emails as any[]));
  }

}
