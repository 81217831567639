<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{'TITLE-ANTICIPATION' | translate}}</span>
    </p-header>

    <form class="filters active" (submit)="searchBillets()">
        <div class="filter-row" *ngIf="!selectedCompany && marinaCompanies?.length > 0">
            <div class="form-control">
                <label for="selectedCompany">Selecione uma empresa</label>
                <p-dropdown [filter]="true" [options]="filteredCompanies" name="selectedCompany"
                    [(ngModel)]="selectedCompany" (onChange)="changeCompany()" appendTo="body">
                </p-dropdown>
            </div>
        </div>
        <div class="filter-row" *ngIf="selectedCompany || marinaCompanies?.length === 0">
            <div class="form-control" *ngIf="marinaCompanies?.length > 0">
                <label for="selectedCompany">Empresa</label>
                <p-dropdown [filter]="true" [options]="filteredCompanies" name="selectedCompany"
                    [(ngModel)]="selectedCompany" (onChange)="changeCompany()" appendTo="body">
                </p-dropdown>
            </div>
            <div class="form-control">
                <label for="selectedCustomer">{{'CUSTOMER' | translate}}</label>
                <p-dropdown [filter]="true" [options]="filteredCustomers" [(ngModel)]="selectedCustomer"
                    name="selectedCustomer" appendTo="body">
                </p-dropdown>
            </div>

            <div class="form-control">
                <label for="group">{{'GROUPS' | translate}}</label>
                <p-multiSelect defaultLabel="Grupos" [options]="groups" [(ngModel)]="filter.groupsList"
                    selectedItemsLabel="{0} itens selecionados" name="group" appendTo="body">
                </p-multiSelect>
            </div>

            <div class="form-control">
                <label for="minInvoiceNumber">{{'MIN-INVOICE-NUMBER' | translate}}</label>
                <input pInputText type="number" [(ngModel)]="filter.invoiceNumberStart" name="minInvoiceNumber">
            </div>

            <div class="form-control">
                <label for="maxInvoiceNumber">{{'MAX-INVOICE-NUMBER' | translate}}</label>
                <input pInputText type="number" [(ngModel)]="filter.invoiceNumberEnd" name="maxInvoiceNumber">
            </div>

            <div class="form-control">
                <label for="startDueDate">{{'DUE-FROM' | translate}}</label>
                <p-calendar dateFormat="dd/mm/yy" name="startDueDate" [monthNavigator]="true" [yearNavigator]="true"
                    [yearRange]="'2000:2055'" rPCalendarMask [(ngModel)]="filter.startDueDate" [showIcon]="true"
                    [minDate]="today" i18n appendTo="body">
                </p-calendar>
            </div>

            <div class="form-control">
                <label for="maxInvoiceNumber">{{'DUE-TO' | translate}}</label>
                <p-calendar dateFormat="dd/mm/yy" name="endDueDate" [monthNavigator]="true" [yearNavigator]="true"
                    [yearRange]="'2000:2055'" rPCalendarMask [(ngModel)]="filter.endDueDate" [showIcon]="true" i18n
                    appendTo="body">
                </p-calendar>
            </div>
        </div>
        <div class="filter-row" *ngIf="selectedCompany">
            <div class="form-control" *ngIf="accounts?.length > 0" [ngClass]="{'error' : selectAccount}">
                <h5 class="article-title" style="float: left; font-size: 22px;">
                    {{'Seleciona a conta para antecipação' | translate}}</h5>
                <p-dropdown [placeholder]="'ACCOUNT' | translate" [filter]="true" [options]="accounts" appendTo="body"
                    name="selectedAccount" [(ngModel)]="selectedAccount" (onChange)="searchBillets()">
                </p-dropdown>
            </div>
            <div class="buttons">
                <div class="form-button">
                    <button class="easymarine danger" type="button" (click)="clear()">{{ 'CLEAR' | translate }}</button>
                </div>
                <div class="form-button">
                    <button class="easymarine primary" type="submit">{{ 'FIND' | translate }}</button>
                </div>
            </div>
        </div>
    </form>
    <div class="table" *ngIf="billetsTable?.length > 0 && accounts">
        <p-table #billetTable [rows]="paginationFilter.size" [totalRecords]="totalRecords"
            paginator="true" [value]="billetsTable" styleClass="easy-table"
            [globalFilterFields]="['nfeNumber', 'invoiceNumber', 'customer','group']" [(selection)]="selectedBillets"
            autoLayout="true" dataKey="id" [rowsPerPageOptions]="[10,20,30,50,100]" [lazy]="true"
            (onPage)="onChangePage($event)" [alwaysShowPaginator]="true" (onSort)="onSort($event)">

            <!-- Botões legenda topo -->
            <ng-template pTemplate="caption">
                <div class="text-left button-actions">
                    <button *ngIf="selectedBillets?.length > 0" class="primary easymarine" type="button"
                        (click)="anticipation()">Antecipar</button>
                </div>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText size="50" placeholder="Filtro por Fat, Cliente e Grupo"
                        [(ngModel)]="globalFilter" (input)="filterGlobal()" style="width:auto">
                </span>
                <span style="font-weight: bold">{{ 'TOTAL-REGISTERS' | translate }}:
                    {{billetsTable.length}} {{'OF' | translate}}
                    {{billetTable.totalRecords}}</span>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th></th>
                    <th *ngIf="marinaCompanies?.length > 0"></th>
                    <th class="text-right">{{selectedBillets.length}}&nbsp; selecionados</th>
                    <th class="text-right">Total selecionados:
                        R${{totalSelected() | number:'1.2-2'}}&nbsp;</th>
                    <th></th>
                    <th class="text-right">{{ totalBilling | number:'1.2-2' }}</th>
                    <th colspan="2"></th>
                    <th></th>
                </tr>
                <tr>
                    <th class="text-center">
                        <p-tableHeaderCheckbox *ngIf="filter?.accountId"></p-tableHeaderCheckbox>
                    </th>
                    <th class="text-center" [pSortableColumn]="'invoiceNumber'">
                        Fatura
                        <p-sortIcon [field]="'invoiceNumber'"></p-sortIcon>
                    </th>
                    <th class="text-center" *ngIf="marinaCompanies?.length > 0">
                        Empresa
                    </th>
                    <th class="text-center" [pSortableColumn]="'customer.name'">
                        Cliente
                        <p-sortIcon [field]="'customer.name'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'customer.federalId'">
                        CPF
                        <p-sortIcon [field]="'customer.federalId'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'value'">
                        Valor
                        <p-sortIcon [field]="'value'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'dueDate'">
                        Venc
                        <p-sortIcon [field]="'dueDate'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'accountName'">
                        Conta
                        <p-sortIcon [field]="'accountName'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'groupName'">
                        Grupo
                        <p-sortIcon [field]="'groupName'"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-billet>
                <tr [ngClass]="{'canceled': billet.status === 'CANCELED'}">
                    <td class="text-center">
                        <p-tableCheckbox [value]="billet" *ngIf="filter.accountId">
                        </p-tableCheckbox>
                        <p-tableCheckbox *ngIf="!filter.accountId" [disabled]="true" tooltipPosition="bottom"
                            pTooltip="Selecione uma conta para antecipar" (mouseenter)="hover(true)"
                            (mouseleave)="hover(false)">

                        </p-tableCheckbox>
                    </td>
                    <td class="text-center">
                        <span *ngIf="!billet.legacyInvoiceNumber" class="paidOrder"
                            (click)="extract(billet.invoiceId)">{{billet.invoiceNumber}}<span
                                *ngIf="billet?.sequence">/{{billet.sequence | number:'2.0-0'}}</span>
                        </span>
                        <span *ngIf="billet.legacyInvoiceNumber" class="paidOrder"
                            (click)="extract(billet.invoiceId)">{{billet.invoiceNumber}}<br>
                            {{billet.legacyInvoiceNumber}}
                        </span>
                    </td>
                    <td *ngIf="marinaCompanies?.length > 0" class="text-center"
                        [pTooltip]="billet.marinaCompanyId ? billet.marinaCompany : loggedMarina.companyName"
                        tooltipPosition="top">
                        {{ billet.marinaCompanyId ? getCompanyIndex(billet.marinaCompanyId) : 1 }}
                    </td>
                    <td class="text-center">
                        <span [pTooltip]="billet.emailCollectionArray ? billet.emailCollectionArray.join('\n') : ''"
                            tooltipPosition="top">
                            {{billet.customer}}
                        </span>
                    </td>
                    <td class="text-center">
                        <span>{{billet.customerFederalId}}</span>
                    </td>

                    <td class="text-right">
                        {{billet.value | number:'1.2-2'}}
                    </td>

                    <td class="text-center">
                        {{billet.dueDate | date: 'dd/MM/yy'}}
                    </td>

                    <td class="text-center">
                        {{getAccountName(billet)}}
                    </td>

                    <td class="text-center">
                        {{billet.group ? billet.group : 'Sem grupo' }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-fieldset>
