<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{'TITLE-BALANCE-ADJUSTMENT' | translate}}</span>
    </p-header>

    <div class="filters"  [ngClass]="{'active' : statusFilter}">
        <div class="buttons">
            <div class="form-button" >
                <button class="danger easymarine" type="button" (click)="cancel()"
                    [disabled]="!isAdjustment()">{{ 'CLEAR' | translate }}</button>
            </div>
            <div class="form-button">
                <button class="success easymarine" type="button" (click)="save()"
                    [disabled]="!isAdjustment()">{{ 'SAVE' | translate }}</button>
            </div>
        </div>
    </div>
    <div class="subtitles">
        <fieldset>
          <legend>Legenda (Estoque)</legend>
          <div class="badges">
            <i class="btn-success pi pi-circle-on"></i>
            Estoque atual está acima do estoque mínimo
            <i class="btn-danger pi pi-circle-on"></i>
            Estoque atual está igual ou abaixo do estoque mínimo
            <i class="btn-warning pi pi-circle-on"></i>
            Indicar a área clicável para ajuste do estoque
          </div>
        </fieldset>
      </div>

    <div class="table">
        <p-table #tt [value]="productsStockTable" [globalFilterFields]="['name']" [paginator]="true" styleClass="easy-table"
            [rows]="numberOfRows" [pageLinks]="3" [rowsPerPageOptions]="[10,20,30,50,100]">
            <ng-template pTemplate="caption">
                <p-selectButton [ngStyle]="{'float': 'left'}"
                    [options]="[{label: 'Todos', value: null}, {label: 'Ativo', value: true}, {label: 'Inativo', value: false}]"
                    (onChange)="tt.filter($event.value, 'active', 'equals')">
                </p-selectButton>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText size="50" placeholder="Filtro por nome" [(ngModel)]="globalFilter"
                        (input)="filterGlobal()" style="width:auto">
                </span>
                <span style="font-weight: bold; float: right; margin-top: 5px;">{{ 'TOTAL-REGISTERS' | translate }}:
                    {{tt.totalRecords}} {{'OF' | translate}} {{productsStockTable.length}}
                </span>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th>{{totalAdjustment() | number:'1.2-2'}}</th>
                    <th></th>
                </tr>
                <tr>
                    <th [pSortableColumn]="'id'">
                        Id
                        <p-sortIcon [field]="'id'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'name'">
                        Produto
                        <p-sortIcon [field]="'name'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'price.value'">
                        Preço venda
                        <p-sortIcon [field]="'price.value'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'stock'">
                        Estoque
                        <p-sortIcon [field]="'stock'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'reserved'">
                        Reservado
                        <p-sortIcon [field]="'reserved'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'balance'">
                        Saldo
                        <p-sortIcon [field]="'balance'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'minimalStock'">
                        Estoque min
                        <p-sortIcon [field]="'minimalStock'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'appMinimalStock'">
                        Estoque min app
                        <p-sortIcon [field]="'appMinimalStock'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'adjustment'">
                        Ajuste
                        <p-sortIcon [field]="'adjustment'"></p-sortIcon>
                    </th>
                    <th>Valor do ajuste pelo preço de venda</th>
                    <th>Situação</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product>
                <tr>
                    <td>{{product.id}}</td>
                    <td>{{product.name}}</td>
                    <td>{{product.price.value | number:'1.2-2'}}</td>
                    <td>{{ notKiloNorLiters(product) 
                            ? (product.stock | number:'1.0-0')
                                : (product.stock | number:'1.3-3')}}</td>
                    <td>{{ notKiloNorLiters(product) 
                            ? (product.reserved | number:'1.0-0')
                                : (product.reserved | number:'1.3-3')}}</td>
                    <td>{{ notKiloNorLiters(product) 
                        ? (product.balance | number:'1.0-0')
                            : (product.balance | number:'1.3-3')}}</td>
                    <td [ngClass]="isGreaterMinimalStock(product) ? 'danger' : 'success'" pEditableColumn class="auto-width">
                        <p-cellEditor *ngIf="product.active">
                            <ng-template pTemplate="input">
                                <p-inputNumber required mode="decimal" *ngIf="!notKiloNorLiters(product) "
                                    class="input-cell" [ngClass]="isGreaterMinimalStock(product) ? 'danger' : 'success'"
                                    type="text" [(ngModel)]="product.minimalStock" [minFractionDigits]="3"
                                    [maxFractionDigits]="3"></p-inputNumber>
                                <p-inputNumber required *ngIf="notKiloNorLiters(product) " class="input-cell"
                                    type="number" [maxFractionDigits]="0"
                                    [ngClass]="isGreaterMinimalStock(product) ? 'danger' : 'success'"
                                    [(ngModel)]="product.minimalStock">
                                </p-inputNumber>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span *ngIf="notKiloNorLiters(product)">
                                    {{ product.minimalStock | number:'1.0-0' }}
                                </span>
                                <span *ngIf="!notKiloNorLiters(product)">
                                    {{ product.minimalStock | number:'1.3-3' }}
                                </span>
                            </ng-template>
                        </p-cellEditor>
                        <span *ngIf="notKiloNorLiters(product) && !product.active">
                            {{ product.minimalStock | number:'1.0-0' }}
                        </span>
                        <span *ngIf="!notKiloNorLiters(product) && !product.active">
                            {{ product.minimalStock | number:'1.3-3' }}
                        </span>
                    </td>
                    <td [ngClass]="isGreaterMinimalStock(product) ? 'danger' : 'success'" pEditableColumn class="auto-width">
                        <p-cellEditor *ngIf="product.active">
                            <ng-template pTemplate="input">
                                <p-inputNumber required mode="decimal" *ngIf="!notKiloNorLiters(product)"
                                    class="input-cell" [(ngModel)]="product.appMinimalStock" [minFractionDigits]="3"
                                    [maxFractionDigits]="3"
                                    [ngClass]="isGreaterMinimalStock(product) ? 'danger' : 'success'">
                                </p-inputNumber>
                                <p-inputNumber required *ngIf="notKiloNorLiters(product) " class="input-cell"
                                    [ngClass]="isGreaterMinimalStock(product) ? 'danger' : 'success'"
                                    [(ngModel)]="product.appMinimalStock" [maxFractionDigits]="0">
                                </p-inputNumber>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span *ngIf="notKiloNorLiters(product)">
                                    {{ product.appMinimalStock | number:'1.0-0' }}
                                </span>
                                <span *ngIf="!notKiloNorLiters(product)">
                                    {{ product.appMinimalStock | number:'1.3-3' }}
                                </span>
                            </ng-template>
                        </p-cellEditor>
                        <span *ngIf="notKiloNorLiters(product) && !product.active">
                            {{ product.appMinimalStock | number:'1.0-0' }}
                        </span>
                        <span *ngIf="!notKiloNorLiters(product) && !product.active">
                            {{ product.appMinimalStock | number:'1.3-3' }}
                        </span>
                    </td>
                    <td class="warning auto-width" pEditableColumn>
                        <p-cellEditor *ngIf="product.active">
                            <ng-template pTemplate="input">
                                <p-inputNumber required *ngIf="!notKiloNorLiters(product)" mode="decimal"
                                    [(ngModel)]="product.adjustment" class="warning input-cell" [maxFractionDigits]=""
                                    [minFractionDigits]="3"  [readonly]= !product.active>
                                </p-inputNumber>
                                <p-inputNumber required *ngIf="notKiloNorLiters(product)"
                                    [(ngModel)]="product.adjustment" class="warning input-cell" [maxFractionDigits]="0">
                                </p-inputNumber>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <span *ngIf="notKiloNorLiters(product)">
                                    {{ product.adjustment | number:'1.0-0' }}
                                </span>
                                <span *ngIf="!notKiloNorLiters(product)">
                                    {{ product.adjustment | number:'1.3-3' }}
                                </span>
                            </ng-template>
                        </p-cellEditor>
                        <span *ngIf="notKiloNorLiters(product) && !product.active">
                            {{ product.adjustment | number:'1.0-0' }}
                        </span>
                        <span *ngIf="!notKiloNorLiters(product)  && !product.active">
                            {{ product.adjustment | number:'1.3-3' }}
                        </span>
                    </td>
                    <td>{{ (product.adjustment * product.price.value) | number:'1.2-2' }}</td>
                    <td class="text-center">
                        <a href="javascript:;" [pTooltip]="product.active ? 'Desativar' : 'Ativar'"
                            class="badge badge-pill" [ngClass]="product.active ? 'success' : 'danger'"
                            tooltipPosition="bottom" (click)="updateStatus(product)">
                            {{ product.active ? 'Ativo' : 'Inativo' }}
                        </a>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-fieldset>
