<div class="dialog-form">
    <div class="form-group">
        <div class="form-control">
            <label>{{ 'NAME' | translate }}</label>
            <input type="text" pInputText name="accessory.name" [(ngModel)]="accessory.name" />
        </div>
        <div class="form-control">
            <label>{{ 'QUANTITY' | translate }}</label>
            <input type="number" pInputText name="accessory.quantity" [(ngModel)]="accessory.quantity" />
        </div>
    </div>
    <br>
    <div class="form-group full-width">
        <p-panel header="{{ 'IMAGE' | translate }}">
            <div class="form-group image">
                <div class="form-control">
                    <label for="arquivo">{{ 'FILE' | translate }}</label>
                    <p-fileUpload name="attachment" mode="basic" maxFileSize="100000000" chooseLabel="Anexar"
                        accept=".png,.jpg,.jpeg" (uploadHandler)="uploadFile($event.files)" [auto]="true"
                        customUpload="true" #upload>
                    </p-fileUpload>
                    <p-progressSpinner [hidden]="!uploadAccessoryFileInProgress"
                        [style]="{ width: '25px', height: '25px' }">
                    </p-progressSpinner>
                </div>
                <div class="form-control">
                    <img src="{{accessory.urlPhoto}}" width="96">
                </div>
            </div>
        </p-panel>
    </div>
    <div class="form-group">
        <div class="buttons">
            <button (click)="closeDialog()" class="easymarine danger" type="button">Cancelar</button>
            <button (click)="addAccessory()" [disabled]="accessory.name == ''"
                class="easymarine primary" type="button">Adicionar</button>
        </div>
    </div>
</div>