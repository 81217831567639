<div class="dialog-form">
    <div *ngIf="slingUtil.hasCancel(slingOrder)">
        <div>
            <label>{{ 'ADD-CONSUMABLE' | translate }}</label>
            <p-dropdown placeholder="Selecione..." [filter]="true" [options]="filteredConsumables" appendTo="body"
                [(ngModel)]="productSelected" name="productSelected">
                <ng-template let-product pTemplate="item">
                    <div [ngStyle]="{'padding': isToten && '5px 0px'}">{{product.label}}</div>
                </ng-template>
            </p-dropdown>
        </div>
        <div>
            <button class="primary fab-button" tooltipPosition="bottom" pTooltip="{{ 'ADD-PRODUCT' | translate  }}"
                type="button" (click)="addNewConsumable()" name="addConsumableBtn">
                <i class="pi pi-plus"></i>
            </button>
        </div>
    </div>
    <div>
        <div>
            <p-table [value]="consumableOrderItems" [scrollable]="true" scrollHeight="200px" [resizableColumns]="true"
                [autoLayout]="true">
                <ng-template pTemplate="header">
                    <tr>
                        <th>{{ 'PRODUCT' | translate }}</th>
                        <th>{{ 'PRICE' | translate }}</th>
                        <th>Estoque</th>
                        <th>{{ 'QUANTITY' | translate }}</th>
                        <th>Unidade</th>
                        <th class="text-right">{{ 'SUBTOTAL' | translate }}</th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-consumable>
                    <tr>
                        <td>{{consumable.product.name}}</td>
                        <td class="text-right"
                            [ngStyle]="{'background-color':consumable.product.price.priceFree && '#b1d8f7' }">
                            <div style="width:50px" *ngIf="consumable.product.price.priceFree || slingOrder.prepare">
                                <input class="text-right" style="color:black" matInput autocomplete="off"

                                    [(ngModel)]="consumable.itemValue" required>
                            </div>
                            <div *ngIf="!(consumable.product.price.priceFree || slingOrder.prepare)">
                                <span>{{consumable.itemValue | currency : ' ':'1.2-2'}}</span>
                            </div>
                        </td>
                        <td class="text-center">
                            {{consumable.qtyStock}}
                        </td>
                        <td class="text-left" style="background-color: #b1d8f7">
                            <div class="full-width">
                                <input pInput type="number" min="1" [min]="1"
                                    [max]="consumable.qtyStock + consumable.qtyRequested"
                                    name="qtyRequested{{consumable.product.id}}" [disabled]="slingOrder.prepare"
                                    autocomplete="off" [(ngModel)]="consumable.qtyRequested" #qtyRequested="ngModel"
                                    required>
                                <span class="error" *ngIf="qtyRequested.errors?.min">
                                    {{'INVALID-QUANTITY' | translate}}
                                </span>
                                <span class="error" *ngIf="qtyRequested.errors?.max">
                                    {{'OUT-OF-STOCK' | translate}}
                                </span>
                            </div>
                        </td>
                        <td class="text-right">
                            {{consumable.product.measurement.toUpperCase() | translate}}
                        </td>
                        <td class="text-right">
                            {{(consumable.qtyRequested ? (consumable.itemValue * consumable.qtyRequested) : 0 ) |
                            number: '1.2-2' }}
                        </td>
                        <td>
                            <button pTooltip="delete" type="button" tooltipPosition="bottom"
                                (click)="deleteConsumableOrderItem(consumable)" [disabled]="slingOrder.prepare"
                                name="deleteConsumableOrderItemButton" class="text-right">
                                <i class="pi pi-trash"></i>
                            </button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <p-footer>
        <div>
            <div>
                <div class="float-left" *ngIf="slingOrder.id">
                    <p style="font-weight:500; padding-left: 20px">Data do pedido: {{getRequestDate() | date:
                        completeDateFormat}}</p>
                </div>
            </div>
            <div>
                <div style="justify-content: flex-end;">
                    <div>
                        <button class="float-right" class="easymarine danger"
                            (click)="dialog.close()">{{ 'CANCEL' | translate }}</button>
                    </div>
                    <div>
                        <button [disabled]="disableFlag" class="primary easymarine" type="button"
                            (click)="saveConsumablesData(false)">
                            {{ 'NEXT' | translate }}
                        </button>
                    </div>
                    <div>
                        <button disabled=true class="easymarine primary">{{ 'SAVE' | translate }}</button>
                    </div>
                </div>
            </div>
        </div>
    </p-footer>
</div>
