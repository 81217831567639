import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SecuritiesPropertyService } from 'src/app/services/securities-property/securities-property.service';
import { MessageUtil } from 'src/app/utils/message.util';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-securities-property-cad-lot',
  templateUrl: './securities-property-cad-lot.component.html',
  styleUrls: ['./securities-property-cad-lot.component.scss'],
})
export class SecuritiesPropertyCadLotComponent implements OnInit {
  nStart: number;
  nEnd: number;
  nLast: number;

  constructor(
    private securitiesPropertyService: SecuritiesPropertyService,
    private messageUtil: MessageUtil,
    private config: DynamicDialogConfig,
    private spinner: NgxSpinnerService,
    public dialogRef: DynamicDialogRef
  ) {}

  ngOnInit() {
    this.getLat();
  }

  getLat() {
    this.securitiesPropertyService.getLast().subscribe((data) => {
      if (data == null) {
        data = 0;
      }
      this.nStart = data+1;
      this.nLast = data;
    });
  }

  save() {
    if (this.nStart > this.nEnd) {
      this.messageUtil.generateMessage(
        'error',
        'Erro',
        'O número inicial não pode ser maior que o número final'
      );
      return;
    }
    if (this.nEnd < 0 || this.nStart < 0) {
      this.messageUtil.generateMessage(
        'error',
        'Erro',
        'Os números não podem ser negativos'
      );
      return;
    }
    Swal.fire({
      title:
        'Deseja realmente Criar os Tílulos de ' +
        this.nStart +
        ' até ' +
        this.nEnd +
        '?',
      showDenyButton: true,
      confirmButtonColor: '#3085d6',
      confirmButtonText: `Confirmo`,
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.saveData();
      }
    });
  }

  saveData(): void {
    this.spinner.show();
    this.securitiesPropertyService
      .saveInLot(this.nStart, this.nEnd)
      .subscribe((data) => {
        this.spinner.hide();
        this.messageUtil.generateMessage(
          'success',
          'Sucesso',
          'Foram Criados ' + data + ' Títulos com sucesso.'
        );
        this.dialogRef.close(data);
      });
  }

  clear() {
    this.dialogRef.close(null);
  }
}
