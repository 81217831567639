import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-boat-log-service-boat',
  templateUrl: './boat-log-service-boat.component.html',
  styleUrls: ['./boat-log-service-boat.component.scss']
})
export class BoatLogServiceBoatComponent implements OnInit {

  @Input() serviceBoats;
  @Input() serviceBoatsOld;
  @Input() isNew;

  constructor() { }

  ngOnInit(): void { }

  logsTable(): any[]{
    if(this.isNew){
      return this.serviceBoats;
    } else {
      return this.serviceBoatsOld;
    }
  }

  isChanged(serviceBoat): boolean {
    let serviceBoatVerify
    if(this.isNew){
      serviceBoatVerify = this.findServiceBoatOld(serviceBoat.serviceBoatid)
      
    } else {
      serviceBoatVerify = this.findServiceBoat(serviceBoat.serviceBoatid)
    }
    
    return !serviceBoatVerify || (serviceBoatVerify.value !== serviceBoat.value
      || serviceBoatVerify.discount !== serviceBoat.discount
      || serviceBoatVerify.dueDateDiscount !== serviceBoat.dueDateDiscount
      || serviceBoatVerify.contractStartDate !== serviceBoat.contractStartDate
      || serviceBoatVerify.serviceLog.name !== serviceBoat.serviceLog.name
      || serviceBoatVerify.contractEndDate !== serviceBoat.contractEndDate
      || serviceBoatVerify.observation !== serviceBoat.observation
      || serviceBoatVerify.executionPeriodicity !== serviceBoat.executionPeriodicity
      || serviceBoatVerify.billingPeriodicity !== serviceBoat.billingPeriodicity
      || serviceBoatVerify.filePath !== serviceBoat.filePath);
  }


  findServiceBoat(id): any{
    return this.serviceBoats.find(sb => sb.serviceBoatid === id);
  }

  findServiceBoatOld(id): any{
    return this.serviceBoatsOld.find(sb => sb.serviceBoatid === id); 
  }

  calcTotalService(service): number {
    return service.value - service.discount;
  }

}
