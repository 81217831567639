import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DrawerHeaderGroup } from 'src/app/models/dtos/drawer-header-group';
import { DrawerHeaderItem } from 'src/app/models/dtos/drawer-header-item';
import { DrawerService } from 'src/app/services/drawer.service';

@Component({
  selector: 'app-custom-drawer',
  templateUrl: './custom-drawer.component.html',
  styleUrls: ['./custom-drawer.component.scss']
})
export class CustomDrawerComponent implements OnInit {
  @Input() inDrawerHeaderGroups: DrawerHeaderGroup[] = [];
  @Output() closeDrawer: EventEmitter<any> = new EventEmitter();

  baseTable: DrawerHeaderGroup[] = [];
  activeGroup: DrawerHeaderGroup;
  selectedItems: DrawerHeaderItem[] = [];
  constructor(
    private drawerService: DrawerService,
  ) { }

  ngOnInit(): void {
    this.getBaseTableDeepCopy();
    this.getSelectedItems();
  }

  getBaseTableDeepCopy() {
    this.inDrawerHeaderGroups.forEach((group: DrawerHeaderGroup) => {
      let cloneGroup = new DrawerHeaderGroup();
      cloneGroup.label = group.label;
      cloneGroup.active = group.active;
      cloneGroup.activeItems = group.activeItems;
      cloneGroup.description = group.description;
      cloneGroup.items = [];
      group.items.forEach((item: DrawerHeaderItem) => {
        let cloneItem = new DrawerHeaderItem();
        cloneItem.label = item.label;
        cloneItem.name = item.name;
        cloneItem.toggle = item.toggle;
        cloneItem.parent = item.parent;
        cloneItem.required = item.required;
        cloneItem.parent = item.parent;
        cloneItem.type = item.type;
        cloneGroup.items.push(cloneItem);
      });
      this.baseTable.push(cloneGroup);
    });
  }

  getSelectedItems() {
    let isActiveGroup = false;
    this.baseTable.forEach((group: DrawerHeaderGroup, index: number) => {
      if (group.active == true) {
        isActiveGroup = true;
        this.activeGroup = group;
      }
      group.items.forEach((item: DrawerHeaderItem) => {
        if (item.toggle) this.selectedItems.push(item);
      });
    });
    if (!isActiveGroup) {
      this.baseTable[0].active = true;
      this.activeGroup = this.baseTable[0];
    }  
  }

  changeGroup(pGroup: DrawerHeaderGroup) {
    this.baseTable.forEach((group) => {
      if (group.label !== pGroup.label) {
        group.active = false;
      }
    });
    pGroup.active = !pGroup.active;
    this.activeGroup = pGroup;
  }

  changeItem(pItem: DrawerHeaderItem) {
    this.baseTable.filter((group: DrawerHeaderGroup) => {
      if (group.label === pItem.parent) {
        group.items.forEach((item: DrawerHeaderItem) => {
          if (item.label === pItem.label) {
            item.toggle = !item.toggle;
          }
        });
      }
    });
  }

  async close() {
    await this.drawerService.setDrawer(this.baseTable);
    this.closeDrawer.emit();
  }
}
