import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Marina, SlingConfig } from 'src/app/models';
import { FinancialMultipleCompaniesType } from 'src/app/models/enums/finances/financial-multiple-companies-type';
import { MarinaCompany } from 'src/app/models/marina-company';
import { FinancesService } from 'src/app/services/finances.service';
import { GroupService } from 'src/app/services/group.service';
import { ToastService } from 'src/app/services/toast.service';
import { FormUtil } from 'src/app/utils/form.util';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';

@Component({
  selector: 'app-group-form',
  templateUrl: './group-form.component.html',
  styleUrls: ['./group-form.component.scss']
})
export class GroupFormComponent implements OnInit {

  group: any = { description: '', active: true };
  groupForm: UntypedFormGroup;
  paymentMethods: any[];
  paymentMethodsDropdown: any[];
  accountDropdown: any[];
  marinaCompanies: MarinaCompany[];
  marinaCompanyList: Array<{ label: string, value: any }>;
  marinaCompaniesWithDatabase: MarinaCompany[];
  loggedMarina: Marina;

  slingConfig: SlingConfig;

  constructor(
    public dialog: DynamicDialogRef,
    private groupService: GroupService,
    private messageUtil: MessageUtil,
    private spinner: NgxSpinnerService,
    private financesService: FinancesService,
    private toasty: ToastService,
    private config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    if (this.config && this.config.data && this.config.data.slingConfig) {
      this.slingConfig = this.config.data.slingConfig;
      if (this.hasFinancialSettings(this.slingConfig)) {
        this.initializeWithFinacialSettings();
      } else {
        this.initializeWithoutFinacialSettings()
      }
    }
  }

  hasFinancialSettings(slingConfig: SlingConfig): boolean {
    return slingConfig && slingConfig.financialMultipleCompaniesType != FinancialMultipleCompaniesType.ByProduct;
  }

  initializeWithoutFinacialSettings() {
    this.resetFormWithoutFinancialSettings();
    if (this.config && this.config.data) {
      if (this.config.data.group) {
        this.group = this.config.data.group;
        this.groupForm.patchValue(this.group);
      }
    }
  }

  async initializeWithFinacialSettings() {
    this.loggedMarina = StorageUtil.getMarina();
    this.marinaCompanies = this.loggedMarina.marinaCompanies;
    if (this.marinaCompanies && this.marinaCompanies.length > 0) {
      this.marinaCompanyList = (
        [{ label: this.loggedMarina.companyName, value: null }] as Array<{ label: string, value: any }>
      ).concat(
        this.marinaCompanies.map((m) => ({ label: m.companyFederalName, value: m }))
      );
    }
    this.resetFormWithFinancialSettings();
    this.marinaCompaniesWithDatabase = await StorageUtil.getMarinaCompanies();
    if (this.config && this.config.data) {
      if (this.config.data.group) {
        this.group = this.config.data.group;
        if (this.group.marinaCompany && this.marinaCompaniesWithDatabase.find((m) => m.id === this.group.marinaCompany.id)) {
          await this.getPaymentMethods(this.group.marinaCompany);
        } else {
          await this.getPaymentMethods();
        }
        if (this.group.paymentMethodId) {
          await this.selectedPaymentMethods(this.group.paymentMethodId);
          await this.paymentMethodExist(this.group.paymentMethodId);
        }
        this.groupForm.patchValue(this.group);
      } else {
        await this.getPaymentMethods();
      }
    }

  }

  save(): void {
    if (!this.groupForm.valid) {
      this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', 'MSG.FIELD-REQUIRED');
      FormUtil.touchAndSendFormGroup(this.groupForm);
      return;
    }
    this.spinner.show();
    if (!this.group.id) {
      this.group = this.groupForm.value;
    } else {
      Object.keys(this.groupForm.controls).forEach((key) => {
        this.group[key] = this.groupForm.get(key).value;
      });
    }
    if ( this.hasFinancialSettings(this.slingConfig) && this.group.accountId && this.group.paymentMethodId) {
      this.group.accountName = this.accountDropdown.find((account) => account.value === this.group.accountId).label;
      this.group.paymentMethodName = this.paymentMethodsDropdown.find((method) => method.value === this.group.paymentMethodId).label;
    }
    this.group.marina = StorageUtil.getMarina();
    this.groupService.save(this.group).subscribe(
      () => {
        this.toasty.success('Grupo cadastrado com sucesso');
        this.dialog.close();
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      },
      () => this.spinner.hide());
  }

  async getPaymentMethods(marinaCompany?: MarinaCompany): Promise<any> {
    return new Promise<void>(
      async (res) => {
        this.spinner.show();
        this.financesService.findTipoFormasPagamento(marinaCompany).subscribe(
          async (data) => {
            this.spinner.hide();
            this.paymentMethods = data.filter((method) => method.contas.length !== 0);
            this.paymentMethodsDropdown = data.filter((method) => method.contas.length !== 0)
              .map((method) => ({ label: method.descricao, value: method.idTipoFormaPagamento }));
            res();
          },
          async (error) => {
            this.spinner.hide();
            if (error.error.data) {
              const exception = error.error.data.exception;
              this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
            }
            res();
          }
        );
      }
    );
  }

  paymentMethodExist(value): void {
    const methodExist = this.paymentMethods.filter(
      (method) => method.idTipoFormaPagamento === value
    );
    if (methodExist.length === 0) {
      this.accountDropdown = [];
      this.groupForm.get('paymentMethodId').reset();
      this.groupForm.get('accountId').reset();
    }
  }

  selectedPaymentMethods(value): void {
    const methodExist = this.paymentMethods.filter(
      (method) => method.idTipoFormaPagamento === value
    );

    if (methodExist.length > 0) {
      this.accountDropdown = methodExist[0].contas.map(
        (account) => (
          {
            label: account.nome,
            value: account.idConta
          }
        )
      );
    } else {
      this.accountDropdown = [];
      this.groupForm.get('paymentMethodId').reset();
    }
    this.groupForm.get('accountId').reset();
  }

  multiCompany(): boolean {
    return this.marinaCompanies && this.marinaCompanies.length > 0;
  }

  async selectedCompany(value): Promise<void> {
    this.groupForm.get('paymentMethodId').reset();
    this.groupForm.get('accountId').reset();
    if (value && !this.marinaCompaniesWithDatabase.find((m) => m.id === value.id)) {
      await this.getPaymentMethods();
    } else {
      await this.getPaymentMethods(value);
    }
  }

  resetFormWithFinancialSettings(): void {
    this.groupForm = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      marinaCompany: new UntypedFormControl({ value: null, disabled: !this.multiCompany() }),
      paymentMethodId: new UntypedFormControl(null, Validators.required),
      accountId: new UntypedFormControl(null, Validators.required),
      name: new UntypedFormControl(null, Validators.required),
      active: new UntypedFormControl(true, Validators.required),
      description: new UntypedFormControl(null)
    });
  }

  resetFormWithoutFinancialSettings(): void {
    this.groupForm = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      name: new UntypedFormControl(null, Validators.required),
      active: new UntypedFormControl(true, Validators.required),
      description: new UntypedFormControl(null)
    });
  }
}

