export class FormatUtil {

    public static removeSpecialCharacters(sentence: string): string {
        return sentence.replace(/[^\d]+/g, '');
    }

    public static hourFromTime(hour: string): string {
        return hour.substring(0, 2);
    }

    public static minutesFromTime(hour: string): string {
        return hour.substring(3, 5);
    }

    public static formatHourOrMinute(value: number): string | number {
        return value > 9 ? value : '0' + value;
    }

    public static getNotAccents(value): string {
        if (value == null){
          return '';
        }
        let newValue = value;
        const mapAccentsHex = {
            a: /[\xE0-\xE6]/g,
            A: /[\xC0-\xC6]/g,
            e: /[\xE8-\xEB]/g,
            E: /[\xC8-\xCB]/g,
            i: /[\xEC-\xEF]/g,
            I: /[\xCC-\xCF]/g,
            o: /[\xF2-\xF6]/g,
            O: /[\xD2-\xD6]/g,
            u: /[\xF9-\xFC]/g,
            U: /[\xD9-\xDC]/g,
            c: /\xE7/g,
            C: /\xC7/g,
            n: /\xF1/g,
            N: /\xD1/g,
        };

        // tslint:disable-next-line: forin
        for (const letter in mapAccentsHex) {
            const regularExpression = mapAccentsHex[letter];
            newValue = newValue.replace(regularExpression, letter);
        }

        return newValue;
    }

    public static verifyIfStringContainsSubStringWithoutAccents(field: string, substring: string): boolean {
      return FormatUtil.getNotAccents(field).toUpperCase().includes(FormatUtil.getNotAccents(substring).toUpperCase())
    }

    public static verifyIfPhoneStringContainsSubString(field: string, substring: string): boolean {
      return FormatUtil.removeSpecialCharacters(field).toUpperCase().includes(FormatUtil.removeSpecialCharacters(substring).toUpperCase())
    }

    public static groupBy(collection, property): any[] {
        let i = 0;
        let val;
        let index;
        const values = [];
        const result = [];
        for (; i < collection.length; i++) {
            val = collection[i][property];
            index = values.indexOf(val);
            if (index > -1) {
                result[index].push(collection[i]);
            }
            else {
                values.push(val);
                result.push([collection[i]]);
            }
        }
        return result;
    }

    public static groupByReduce(xs, key): any[] {
        return xs.reduce((rv, x) => {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    }

    public static roundTwoDecimal(value: number): number {
        return Math.round(value*100) / 100;
    }

    public static formatCPF(CPF) {
      let federalIdMask = CPF;
      federalIdMask = federalIdMask.replace( /(\d{3})(\d)/ , "$1.$2");
      federalIdMask = federalIdMask.replace( /(\d{3})(\d)/ , "$1.$2");
      //de novo (para o segundo bloco de números)
      federalIdMask = federalIdMask.replace( /(\d{3})(\d{1,2})$/ , "$1-$2");

      return federalIdMask;
    }

    public static formatCNPJ(CNPJ) {
      let federalIdMask = CNPJ;

      federalIdMask = federalIdMask.replace( /(\d{2})(\d)/ , "$1.$2");
      federalIdMask = federalIdMask.replace( /(\d{3})(\d)/ , "$1.$2");
      federalIdMask = federalIdMask.replace( /(\d{3})(\d)/ , "$1/$2");
      federalIdMask = federalIdMask.replace( /(\d{4})(\d)/ , "$1-$2");

      return federalIdMask;
    }

    public static firstLetterUppercase(text: string): string{
      return text.charAt(0).toUpperCase() + text.slice(1);
    }

}
