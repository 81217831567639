import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BoatLocation } from '../models';

@Injectable()
export class BoatLocationService {

    constructor(private http: HttpClient) { }

    findSlingsInOperation(): Observable<BoatLocation[]> {
        return this.http.get(environment.apiHost + '/secure/boat/location/operation')
            .pipe(map((response: any) => response.data.locations as BoatLocation[]));
    }
}
