import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  OrderItem,
  Product,
  ProductCategory,
  SlingConfig,
} from 'src/app/models';
import { OrderControl } from 'src/app/models/enums/order-control';
import { MarinaCompany } from 'src/app/models/marina-company';
import { OperationalConfigService } from 'src/app/services/config/operational-config.service';
import {
  FinancialMultipleCompaniesOption,
  FinancialMultipleCompaniesService,
} from 'src/app/services/financial/financial-multiple-companies.service';
import { FocusFieldService } from 'src/app/services/focus-field.service';
import { ProductCategoryService } from 'src/app/services/product-category.service';
import { ProductService } from 'src/app/services/product.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { FormatUtil } from 'src/app/utils/format.util';
import { MessageUtil } from 'src/app/utils/message.util';
import Swal from 'sweetalert2';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { SingleOrderQuantityComponent } from './single-order-quantity/single-order-quantity.component';
import { SalesCheckoutWizardComponent } from './sales-checkout-wizard/sales-checkout-wizard.component';
import { combineLatest } from 'rxjs';
import { ProductType } from '../../../models/enums';

@Component({
  selector: 'app-single-order',
  templateUrl: './single-order.component.html',
  styleUrls: ['./single-order.component.scss'],
})
export class SingleOrderComponent implements OnInit {
  products: Product[] = [];
  productsFilter: Product[] = [];

  productCategories = [];
  selectedCategory: string = null;

  orderedItems: OrderItem[] = [];

  total = 0;

  slingConfig: SlingConfig;
  isMovement: boolean;
  isWorkshop: boolean;
  params: any;

  showInTablet: boolean;

  financialCompany: MarinaCompany = null;
  financialCompanyTwo: MarinaCompany = null;
  financialMultipleCompanyOptions: FinancialMultipleCompaniesOption[];

  requiresCustomerIdentification: boolean = false;

  constructor(
    private router: Router,
    private productCategoryService: ProductCategoryService,
    private productService: ProductService,
    private messageUtil: MessageUtil,
    public dialog: DialogService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private slingConfigService: SlingConfigService,
    public focusFieldService: FocusFieldService,
    private financialMultipleCompaniesService: FinancialMultipleCompaniesService,
    private operationalConfigService: OperationalConfigService,
    private breakpointObserver: BreakpointObserver
  ) {}

  async ngOnInit(): Promise<void> {
    this.spinner.show();

    this.loadParams();

    this.updateLayoutOnBreakpoint();

    this.slingConfig = await this.loadSlingConfig();

    this.hasMultipleCompanies();

    this.isMovement = await this.operationalConfigService.isMovements();
    this.isWorkshop = await this.operationalConfigService.isWorkshop();

    this.getProducts();
    this.getProductCategories();

    this.spinner.hide();
    window.addEventListener('resize', () => {
      this.updateLayoutOnBreakpoint();
    });
  }

  loadParams(): void {
    this.params = {
      customerId: this.route.snapshot.queryParamMap.get('customerId'),
      boatId: this.route.snapshot.queryParamMap.get('boatId'),
      slingId: this.route.snapshot.queryParamMap.get('slingId'),
    };
  }

  private updateLayoutOnBreakpoint() {
    this.breakpointObserver
      .observe(['(min-width: 1281px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.showInTablet = false;
        } else {
          this.showInTablet = true;
        }
      });
  }

  async loadSlingConfig(): Promise<any> {
    return new Promise<any>(async (resolve) => {
      this.slingConfigService.getSlingConfig().subscribe(async (data) => {
        resolve(data[0]);
      });
    });
  }

  hasMultipleCompanies() {
    if (this.financialMultipleCompaniesService.hasMultipleCompanies()) {
      this.financialMultipleCompanyOptions =
        this.financialMultipleCompaniesService.getBillingCompanyOptions();
      this.financialCompany = this.financialMultipleCompanyOptions[0]
        .value as MarinaCompany;
      this.financialCompanyTwo = this.financialMultipleCompanyOptions[1]
        .value as MarinaCompany;
    }
  }

  getProducts(): void {
    if (this.financialMultipleCompanyOptions?.length > 1) {
      combineLatest([
        this.productService.getAllByOrderControl(
          OrderControl.EASYMARINE,
          this.financialCompany
        ),
        this.productService.getAllByOrderControl(
          OrderControl.EASYMARINE,
          this.financialCompanyTwo
        ),
      ]).subscribe(([data1, data2]) => {
        this.products = [...data1, ...data2];
        this.getProductsByCategory(this.products);
      });
    } else {
      this.productService
        .getAllByOrderControl(OrderControl.EASYMARINE, this.financialCompany)
        .subscribe((data) => {
          this.products = data;
          this.getProductsByCategory(this.products);
        });
    }
  }

  getProductsByCategory(products: Product[]): void {
    let numberOfProductsToLoad = 0;
    if (this.showInTablet) {
      numberOfProductsToLoad = 21;
    } else {
      numberOfProductsToLoad = 28;
    }
    this.productsFilter = products
      .filter((product) => {
        if (this.selectedCategory == null) {
          return (
            product.productType !== 'PeriodicalService' &&
            product.productType !== 'PeriodicalServiceCustomer'
          );
        } else {
          if (product.active) {
            return (
              product.productCategory.name === this.selectedCategory &&
              product.productType !== 'PeriodicalService' &&
              product.productType !== 'PeriodicalServiceCustomer'
            );
          }
        }
      })
      .slice(0, numberOfProductsToLoad);
  }

  getProductCategories(): void {
    this.productCategoryService.getAll().subscribe((data) => {
      const dataWSOFF = [...data];
      dataWSOFF.pop();
      this.isWorkshop
        ? (this.productCategories = data)
        : (this.productCategories = dataWSOFF);
    });
  }

  filterProduct(event): void {
    const value = event.target.value;
    let numberOfProductsToLoad = 0;
    if (this.showInTablet) {
      numberOfProductsToLoad = 21;
    } else {
      numberOfProductsToLoad = 28;
    }
    this.productsFilter = this.products
      .filter(
        (b) =>
          (FormatUtil.getNotAccents(b.name)
            .toUpperCase()
            .includes(FormatUtil.getNotAccents(value).toUpperCase()) ||
            b.name.toUpperCase().includes(value.toUpperCase())) &&
          b.productType !== ProductType.PeriodicalService &&
          b.productType !== ProductType.PeriodicalServiceCustomer
        // && b.active
      )
      .slice(0, numberOfProductsToLoad);
  }

  selectCategory(category: ProductCategory): void {
    this.selectedCategory = category?.name;
    this.getProductsByCategory(this.products);
  }

  async payFinishRequest(): Promise<void> {
    let askForCustomer: boolean = this.params.slingId != null;
    if (askForCustomer) {
      this.requiresCustomerIdentification = true;
    } else {
      askForCustomer = await this.checkSelectCustomer();
    }
    if (this.formValidations() && askForCustomer) {
      const dialogRef: DynamicDialogRef = this.dialog.open(
        SalesCheckoutWizardComponent,
        {
          width: this.showInTablet ? '60%' : '40%',
          showHeader: false,
          data: {
            requiresCustomerIdentification: this.requiresCustomerIdentification,
            total: this.total,
            orderedItems: this.orderedItems,
            slingConfig: this.slingConfig,
            params: this.params,
          },
        }
      );
      dialogRef.onClose.subscribe((data) => {
        if (data != null && data !== undefined) {
          this.exit();
        }
      });
    }
  }

  async checkSelectCustomer(): Promise<boolean> {
    return await Swal.fire({
      title: 'Identificação',
      text: 'Deseja identificar o cliente?',
      icon: 'warning',
      reverseButtons: true,
      showDenyButton: true,
      customClass: {
        confirmButton: ['new-easymarine', 'primary'],
        denyButton: ['new-easymarine', 'disabled'],
      },
      confirmButtonText: 'SIM',
      denyButtonText: 'NÃO',
    }).then((result) => {
      if (result.isConfirmed) {
        this.requiresCustomerIdentification = true;
        return true;
      }
      if (result.isDenied) {
        this.requiresCustomerIdentification = false;
        return true;
      }
      return false;
    });
  }

  cancelRequest(): void {
    Swal.fire({
      title: 'Cancelar pedido?',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonColor: '#3085d6',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim',
      reverseButtons: true,
      preConfirm: () => {
        this.exit();
      },
    });
  }

  exit(): void {
    if (!this.isMovement) {
      this.router.navigate(['/app/operational/checking']);
    } else if (this.isMovement) {
      this.router.navigate(['/app/operational/movement']);
    }
  }

  formValidations(): boolean {
    if (this.orderedItems.length === 0) {
      Swal.fire('Alerta!', 'Selecione um produto!', 'warning');
      return false;
    }
    return true;
  }

  productTotalPrice(price: number, qty: number): number {
    return price * qty;
  }

  orderProduct(product: any): void {
    let orderItem: OrderItem = this.orderedItems.filter(
      (item) => item.product.id === product.id
    )[0];
    if (!orderItem) {
      orderItem = new OrderItem();
      this.setValuesOrderItem(orderItem, product);
      this.orderedItems.push(orderItem);
    } else {
      this.setValuesOrderItem(orderItem, product);
    }
    this.updateTotal();
  }

  updateTotal(): void {
    this.total = 0;
    for (const order of this.orderedItems) {
      this.total += order.product.price.value * order.qtyRequested;
    }
  }

  setValuesOrderItem(orderItem: OrderItem, product: any): void {
    orderItem.product = product;
    orderItem.qtyRequested = product.quantity != 0 ? product.quantity : 1;
    orderItem.qtySupplied = product.quantity != 0 ? product.quantity : 1;
    orderItem.itemStatus = product.active;
    orderItem.itemValue = product.price.value;
    if (product.referenceDate !== null) {
      orderItem.dateReference = product.referenceDate;
    }
  }

  clearItem(order: OrderItem): void {
    Swal.fire({
      title: 'Deletar item da lista?',
      text: order.product.name,
      imageUrl: order.product.file,
      imageHeight: 200,
      imageWidth: 200,
      reverseButtons: true,
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonColor: '#3085d6',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (!result.dismiss) {
        const index = this.orderedItems.indexOf(order);
        this.orderedItems.splice(index, 1);
        this.updateTotal();
      }
    });
  }

  verifyIfCanAddProductToOrder(product: Product): void {
    if (this.orderedItems.length <= 0) {
      return;
    }
    if (
      this.orderedItems[0].product.billingCompany?.id ===
      product.billingCompany?.id
    ) {
      return;
    }
    this.messageUtil.generateMessage(
      MessageUtil.ERROR,
      'Pedido com multiplos finaceiros',
      'Não é possível criar um pedido contendo produtos e serviços com empresas de faturamento diferentes'
    );
    throw new Error(
      'Não é possível add produtos de financeiros diferentes no pedido'
    );
  }

  openDialogSeparatedSale(product: Product): void {
    console.log(product);
    if (product?.avaliable <= 0 && product?.productType != 'Service') {
      this.messageUtil.generateMessage(
        MessageUtil.ERROR,
        'Estoque insuficiente',
        'Produto sem estoque suficiente'
      );
      return;
    }
    if (this.isProductByFinancial()) {
      this.verifyIfCanAddProductToOrder(product);
    }
    if (
      product.price.priceFree === true ||
      (product.price.priceRanges != null &&
        product.price.priceRanges.length > 0)
    ) {
      this.dialog
        .open(SingleOrderQuantityComponent, {
          width: this.showInTablet ? '40%' : '30%',
          height: '50%',
          data: {
            product,
          },
          header: product.name,
        })
        .onClose.subscribe((p: any) => {
          if (p && p.quantity > 0) {
            this.orderProduct(product);
          }
        });
    } else {
      this.orderProduct(product);
    }
  }

  isProductByFinancial() {
    if (this.slingConfig === undefined) {
      return false;
    }
    return this.financialMultipleCompaniesService.byProduct(this.slingConfig);
  }

  addQuantity(order) {
    if (order.product?.productType != 'Service') {
      if (order.product.avaliable <= order.qtySupplied) {
        this.messageUtil.generateMessage(
          MessageUtil.ERROR,
          'Estoque insuficiente',
          'Produto sem estoque suficiente'
        );
        return;
      }
    }
    order.qtyRequested++;
    order.qtySupplied++;
    this.updateTotal();
  }

  removeQuantity(order) {
    if (order.qtySupplied > 1) {
      order.qtyRequested--;
      order.qtySupplied--;
      this.updateTotal();
    }
  }

  updateQtySupplied(order, event: string) {
    if (event != null && event != undefined) {
      let string = event.toString().replace(/[^0-9.]/g, '');
      if (string == '') {
        order.qtyRequested = 1;
        order.qtySupplied = 1;
        return;
      }
      let qty = parseFloat(string);
      qty = parseFloat(qty.toFixed(2));
      if (order.product?.productType != 'Service' && order.qtySupplied != qty) {
        if (qty <= order.product.stock) {
          order.qtyRequested = qty;
          order.qtySupplied = qty;
        } else {
          //Redundancia para segurar um bug do p-inputNumber max
          order.qtySupplied = order.product.stock;
          order.qtyRequested = order.product.stock;
          this.messageUtil.generateMessage(
            MessageUtil.ERROR,
            'Estoque insuficiente',
            'Produto sem estoque suficiente'
          );
        }
      }
      if (order.product?.productType == 'Service') {
        order.qtyRequested = qty;
        order.qtySupplied = qty;
      }
    }
    this.updateTotal();
  }

  getMaxQuantity(order): number {
    let max = order.product.stock;
    if (order.product.productType == 'Service') {
      max = 100000;
    }
    return max;
  }

  getIcons(category: string) {
    switch (category) {
      case 'Bebida':
        if (this.selectedCategory == category) {
          return '/assets/images/wine_bar.svg';
        } else {
          return '/assets/images/blue/blue_wine_bar.svg';
        }
      case 'Comida':
        if (this.selectedCategory == category) {
          return '/assets/images/local_pizza.svg';
        } else {
          return '/assets/images/blue/blue_local_pizza.svg';
        }
      case 'Combustível':
        if (this.selectedCategory == category) {
          return '/assets/images/local_gas_station.svg';
        } else {
          return '/assets/images/blue/blue_local_gas_station.svg';
        }
      case 'Outros':
        if (this.selectedCategory == category) {
          return '/assets/images/pan_tool.svg';
        } else {
          return '/assets/images/blue/blue_pan_tool.svg';
        }
      case 'Oficina':
        if (this.selectedCategory == category) {
          return '/assets/images/build.svg';
        } else {
          return '/assets/images/blue/blue_build.svg';
        }
      default:
        return '/assets/images/wine_bar.svg';
    }
  }

  onScroll() {
    const element = document.querySelector('.products');
    if (element) {
      const atBottom =
        element.scrollTop + element.clientHeight >= element.scrollHeight;
      if (atBottom) {
        this.loadMoreItems();
      }
    }
  }

  loadMoreItems() {
    const start = this.productsFilter.length + 1;
    let end = 0;
    if (this.showInTablet) {
      end = start + 20;
    } else {
      end = start + 30;
    }
    let products = this.products.filter((product) => {
      if (this.selectedCategory == null) {
        return (
          product.productType !== 'PeriodicalService' &&
          product.productType !== 'PeriodicalServiceCustomer'
        );
      } else {
        if (product.active) {
          return (
            product.productCategory.name === this.selectedCategory &&
            product.productType !== 'PeriodicalService' &&
            product.productType !== 'PeriodicalServiceCustomer'
          );
        }
      }
    });
    for (let i = start; i <= end; i++) {
      if (products[i]) this.productsFilter.push(products[i]);
    }
  }

  getProductThumb(product) {
    if (product?.thumb) {
      return product.thumb;
    } else {
      return product.file;
    }
  }
}
