import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OrderControl } from '../models/enums/order-control';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';


@Injectable()
export class PivotTableService {


    constructor(private http: HttpClient) { }   


    getPivotProductSales(): Observable<any[]> {
        return this.http.get(
            environment.apiHost + '/secure/pivot/product-sales/' 
        ).pipe(map((response: any) => response.data.productSales as any[]));
    }



}