import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BoatWash } from '../models/boatWash';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class BoatWashService {

    constructor(private http: HttpClient) {

    }

    create(boatWash: BoatWash): Observable<any> {
        return this.http.post(environment.apiHost + '/secure/boatWash/', boatWash).pipe(map((response: any) => response));
    }

    createFastRegistrarion(boatWash: BoatWash): Observable<any> {
        return this.http.post(environment.apiHost + '/secure/boatWash/createFastRegistrarion/', boatWash)
            .pipe(map((response: any) => response));
    }

    createFastAutoRegistrarion(boatWash: BoatWash): Observable<any> {
        return this.http.post(
            environment.apiHost + '/secure/boatWash/createFastAutoRegistrarion/', boatWash
        ).pipe(map((response: any) => response));
    }

    createFastAutoRegistrarionList(boatsWash: BoatWash[]): Observable<any> {
        return this.http.post(
            environment.apiHost + '/secure/boatWash/createFastAutoRegistrarionList/', boatsWash
        ).pipe(map((response: any) => response));
    }

    saveSchedule(boatsWash: any[]): Observable<any> {
        return this.http.post(environment.apiHost + '/secure/boatsWashSchedule/', boatsWash).pipe(map((response: any) => response));
    }

    getBoatsLastWash(): Observable<any[]> {
        return this.http.get(environment.apiHost + '/secure/boatsLastWash/')
            .pipe(map((response: any) => response.data.boatsLastWash as any[]));
    }

    getScheduledBoatWashes(): Observable<any[]> {
        return this.http.get(
            environment.apiHost + '/secure/scheduledBoatWashes/'
        ).pipe(map((response: any) => response.data.boatsLastWash as any[]));
    }

    getScheduledBoatWashesEngineTurn(): Observable<any[]> {
        return this.http.get(
            environment.apiHost + '/secure/scheduledBoatWashesEngineTurn/'
        ).pipe(map((response: any) => response.data.boatsLastWash as any[]));
    }

    finishWash(boatWash: any): Observable<any> {
        return this.http.put(environment.apiHost + '/secure/finishWash/', boatWash).pipe(map((response: any) => response.data));
    }

    getBoatWashHistory(boatId: number): Observable<any[]> {
        return this.http.get(
            environment.apiHost + '/secure/getBoatWashHistory/' + boatId
        ).pipe(map((response: any) => response.data.washHistory as any[]));
    }

    getWashReport(startDate, endDate): Observable<any[]> {
        return this.http.post(
            environment.apiHost + '/secure/boatWash/report',
        {startDate, endDate}).pipe(map((response: any) => response.data.washHistory as any[]));
    }

    delete(idBoatsWash: number[], deleteEngineTurn: boolean): Observable<any> {
        return this.http.delete(
            environment.apiHost + '/secure/boatsWash/' + idBoatsWash + '/' + deleteEngineTurn
        ).pipe(map((response: any) => response));
    }
}
