
export class BatchAccessGroupTransferDTO {
  sourceAccessGroup: number;
  sourceAccessGroupPerson: number[];
  targetAccessGroup: number;
  targetAccessGroupPerson: number[];


  constructor(sourceAccessGroup: number, sourceAccessGroupPerson: number[], targetAccessGroup: number, targetAccessGroupPerson: number[]) {
    this.sourceAccessGroup = sourceAccessGroup;
    this.sourceAccessGroupPerson = sourceAccessGroupPerson;
    this.targetAccessGroup = targetAccessGroup;
    this.targetAccessGroupPerson = targetAccessGroupPerson;
  }
}
