<section class="content">
  <div *ngIf="invoicesInfo" class="table">
    <p-table [value]="invoicesInfo.amountWith" responsiveLayout="scroll"
      styleClass="p-datatable-gridlines">
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 75%; text-align: center;"><strong>Total de Faturas selecionadas</strong></th>
          <th style="width: 25%; text-align: center;"><strong>{{invoicesInfo.total}}</strong></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-invoiceWith>
        <tr>
          <td style="width: 75%">{{invoiceWith.label}}</td>
          <td style="width: 25%">{{invoiceWith.value}}</td>
        </tr>
      </ng-template>
    </p-table>
    <br>
    <div class="options">
      <label>Anexos:</label>
      <div *ngFor="let attachment of attachmentOptions">
        <p-checkbox [(ngModel)]="attachment.value" binary="true" [inputId]="attachment.label"></p-checkbox>
        <label [for]="attachment.label">{{attachment.label}}</label>
      </div>
    </div>
    <div *ngIf="params && params.isCopy">
      <div class="set-email">
        <label for="email">E-mails
            <span class="subtitle">(separados por ";")</span>
        </label>
        <input pInputText type="text" [(ngModel)]="email" id="email" name="email">
      </div>
    </div>
  </div>
  <div class="buttons">
    <button (click)="close()" class="danger easymarine" type="button">{{ 'CANCEL' | translate }}</button>
    <button (click)="clickSend()" class="primary easymarine" type="button">{{ 'SEND' | translate }}</button>
  </div>
</section>
