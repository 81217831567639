import { MovementLocation } from 'src/app/models/movement/movement-location';
import { OperationalConfigService } from './../../../../services/config/operational-config.service';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Boat, Marina, Vacancy } from 'src/app/models';
import { ToastService } from 'src/app/services/toast.service';
import { VacancyService } from 'src/app/services/vacancy.service';
import { FormUtil } from 'src/app/utils/form.util';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';
import Swal from 'sweetalert2';
import { BoatService } from 'src/app/services/boat.service';

@Component({
  selector: 'app-vacancy-form',
  templateUrl: './vacancy-form.component.html',
  styleUrls: ['./vacancy-form.component.scss'],
})
export class VacancyFormComponent implements OnInit {
  localeOptions;
  isMovements: boolean;
  local: MovementLocation;
  maxLengh = 7;

  vacancy: Vacancy = new Vacancy();
  vacancyForm: UntypedFormGroup;
  arrowOptions = [
    { label: 'Nenhum', value: null },
    { label: 'Para cima', value: 'UP' },
    { label: 'Para baixo', value: 'DOWN' },
  ];

  boats: Boat[] = [];
  boatTable: Boat[] = [];
  selectedBoat: number;
  constructor(
    public dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private vacancyService: VacancyService,
    private spinner: NgxSpinnerService,
    private toastService: ToastService,
    private messageUtil: MessageUtil,
    private boatService: BoatService,
    private operationalConfigService: OperationalConfigService
  ) {}

  ngOnInit(): void {
    this.operationalConfigService.isMovements().then((movements: boolean) => {
      this.isMovements = movements;
      // this.isSlings = !movements;
    });

    if (this.config.data && this.config.data.localeOptions) {
      this.localeOptions = this.config.data.localeOptions;
    }

    if (this.config.data && this.config.data.vacancy) {
      this.vacancy = this.config.data.vacancy;
    }
    this.loadBoats();
    this.startForm();
  }

  loadBoats(): void {
    this.boatService.getAllActive().subscribe(
      (data) => {
        this.boats = data;
        console.log(this.boats, this.vacancyForm.get('boats').value);
        this.boatTable = this.boats.filter((boat) => {
          return this.vacancyForm.get('boats').value.includes(boat.id);
        });
      }
    );
  }

  addBoat(): void {
    if (this.selectedBoat) {
      if(this.boatTable.length >= 1 && !this.vacancyForm.get('shared').value){
        Swal.fire('Erro', 'Vaga individual não pode ter mais de um barco', 'error');
        return;
      }

      if(this.boatTable.indexOf(this.boats.find((b) => b.id === this.selectedBoat)) >= 0){
        Swal.fire('Erro', 'Barco já adicionado', 'error');
        return;
      }

      const boat = this.boats.find((b) => b.id === this.selectedBoat);
      this.boatTable.push(boat);
      this.vacancyForm.get('boats').setValue(this.boatTable.map((b) => b.id));
    }
    else{
      Swal.fire('Erro', 'Selecione um barco', 'error');
    }
  }

  removeBoat(boat: Boat): void {
    console.log(boat);
    const index = this.boatTable.indexOf(boat);
    const indexBoat = this.vacancyForm.get('boats').value.indexOf(boat.id);
    
    this.boatTable.splice(index, 1);
    this.vacancyForm.get('boats').setValue(this.boatTable.map((b) => b.id));
  }

  startForm(): void {
    const marina = new Marina();
    marina.id = StorageUtil.getMarinaId();
    const shared: boolean = this.vacancy.shared ? this.vacancy.shared : false;
    this.local = this.vacancy.movementLocation;
    let active = true;
    if (this.config.data.vacancy) {
      active = this.vacancy.active;
    }

    this.vacancyForm = new UntypedFormGroup({
      id: new UntypedFormControl(this.vacancy.id),
      code: new UntypedFormControl(this.vacancy.code, Validators.required),
      length: new UntypedFormControl(this.vacancy.length, Validators.required),
      arrowDirection: new UntypedFormControl(this.vacancy.arrowDirection),
      shared: new UntypedFormControl(shared, Validators.required),
      active: new UntypedFormControl(active, Validators.required),
      marina: new UntypedFormControl(marina, Validators.required),
      boats:  new UntypedFormControl(this.vacancy.boats),
    });

    (this.isMovements) ? this.vacancyForm.addControl('movementLocation', new UntypedFormControl(this.vacancy.movementLocation, Validators.required))
    :this.vacancyForm.addControl('movementLocation', new UntypedFormControl(this.vacancy.movementLocation));

    if (this.config.data.vacancy && this.local?.active) {
      this.vacancyForm.get('movementLocation').disable();
    }

    if (this.local?.prefixUse) {
      this.vacancyForm
        .get('code')
        .setValue(
          this.vacancyForm
            .get('code')
            .value.replace(this.local.abbreviation, '')
        );
      if (!this.local?.active) {
      }
      this.maxLengh = 4;
    }
  }

  save(): void {
    if (!this.vacancyForm.valid) {
      this.messageUtil.generateMessage(
        'warning',
        'SUMMARY.WARNING',
        'MSG.FIELD-REQUIRED'
      );
      FormUtil.touchAndSendFormGroup(this.vacancyForm);
      return;
    }
    this.spinner.show();
    this.vacancyForm.get('movementLocation').enable();
    if (this.vacancy.id) {
      this.vacancyService.update(this.vacancyForm.value).subscribe(
        (data) => {
          this.dialogRef.close();
          this.toastService.success('A vaga foi alterada com sucesso.');
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          Swal.fire('Falha ao atualizar', error.error.message, 'error');
          this.vacancyForm.get('movementLocation').disable();
        }
      );
    } else {
      this.vacancyService.create(this.vacancyForm.value).subscribe(
        (data) => {
          this.dialogRef.close();
          this.toastService.success('Vaga cadastrada com sucesso.');
          this.spinner.hide();
        },
        (error) => {
          Swal.fire('Falha ao cadastrar', error.error.message, 'error');
          this.spinner.hide();
          this.vacancyForm.get('movementLocation').disable();
        }
      );
    }
  }

  changeLocation(): void {
    this.local = this.vacancyForm.get('movementLocation').value;
    if (this.local.prefixUse) this.maxLengh = 4;
    else this.maxLengh = 7;
  }
}
