<div class="dialog-form align-form-center">
  <form (submit)="save()" class="sizeForm" [formGroup]="stockMovementTypeForm">
    <div class="form-group" style="margin-top: 1em">
      <div class="form-control">
        <label for="name">{{ "NAME" | translate }} *</label>
        <input
          pInputText
          name="name"
          formControlName="name"
          maxlength="100"
          type="text"
        />
      </div>
    </div>
    <div class="form-group" style="margin-top: 5px">
      <div class="form-control">
        <label for="type">{{ "TYPE" | translate }} *</label>
        <p-dropdown
          [options]="typeOptions"
          formControlName="type"
          name="type"
          appendTo="body"
          [readonly]="isUpdate"
        >
        </p-dropdown>
      </div>
      <div class="form-control" style="min-width: 0">
        <label for="active">{{ "Ativo" | translate }}</label>
        <p-inputSwitch
          formControlName="active"
          name="active"
          pTooltip="Movimentação Ativa"
          tooltipPosition="bottom"
        >
        </p-inputSwitch>
      </div>
    </div>

    <div class="form-group align-center align-btn-actions">
      <div class="form-control">
        {{ "REQUIRED-FIELDS" | translate }}
      </div>
      <div class="buttons">
        <button
          class="easymarine danger"
          type="button"
          (click)="dialogRef.close()"
        >
          {{ "CANCEL" | translate }}
        </button>
        <button class="easymarine primary" type="submit">
          {{ "SAVE" | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
