import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APPCONFIG } from '../config';
import { map } from 'rxjs/operators';
import { AnticipationLot } from '../models/anticipation-lot';
import { InvoiceBankingBillet } from '../models';

@Injectable()
export class AnticipationLotService {

    constructor(private http: HttpClient) {
    }

    findByMarinaId(): Observable<AnticipationLot[]> {
        return this.http.get(APPCONFIG.apiHost + '/secure/anticipation/lot').pipe(map((response: any) => response.data.lots));
    }

    findBilletsByAnticipationLodId(id: number): Observable<InvoiceBankingBillet[]> {
        return this.http.get(`${APPCONFIG.apiHost}/secure/anticipation/lot/billets/${id}`)
            .pipe(map((response: any) => response.data.billets));
    }

    cancel(lotId: number): Observable<any> {
        return this.http.delete(`${APPCONFIG.apiHost}/secure/anticipation/lot/${lotId}`).pipe(map((response: any) => response.data));
    }

}
