import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { promise } from 'protractor';
import { map, Observable } from 'rxjs';
import { CustomerCreditInvoice } from 'src/app/models/customercredit/customer-credit-invoice';
import { GetCustomerStatement } from 'src/app/models/dtos/customercredit/GetCustomerStatement';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerCreditService {

  constructor(private http: HttpClient) {}

  private readonly API_URL = environment.apiHost + '/api-orders/secure/customer-credit';

  getCustomersCredit(customerName:string): Observable<any> {
    if(customerName === ''){
     customerName = null;
    }

    return this.http.get(this.API_URL+'/get-customer/'+customerName).pipe(
      map((response: any) => response)
    );
  }

  launch(customerCredit: any): Observable<any> {
    return this.http.post(this.API_URL+'/launch', customerCredit).pipe(
      map((response: any) => response)
    );
  }

  validInvoiceNumber(invoiceNumber: number, customerId:number): Observable<CustomerCreditInvoice> {
    return this.http.get(this.API_URL+'/get-invoice/'+invoiceNumber+'/'+customerId).pipe(
      map((response: CustomerCreditInvoice) => response)
    );
  }

  getHistoric(getCustomerStatement: GetCustomerStatement): Observable<any> {
    return this.http.post(this.API_URL+'/get-statement', getCustomerStatement).pipe(
      map((response: any) => response)
    );
  }

  getHistoricPrint(getCustomerStatement: GetCustomerStatement): Observable<any> {
    return this.http.post(this.API_URL+'/get-statement/print', getCustomerStatement).pipe(
      map((response: any) => response)
    );
  }
}
