<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{ 'TITLE-QRCODES-VACANCY' | translate }}</span>
    </p-header>

    <div class="table">
        <p-table #tt [value]="vacancies" autoLayout="true" [(selection)]="selectedVacancies" styleClass="easy-table">
            <ng-template pTemplate="caption">
                <button class="danger easymarine" type="button" (click)="generatePDF()"
                    [disabled]="!selectedVacancies || selectedVacancies.length === 0">{{ 'EXPORT-PDF' | translate }}</button>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText size="50"
                        placeholder="Filtro por Vaga" [(ngModel)]="globalFilter"
                        (input)="filterGlobal($event)" style="width:auto">
                </span>
                <span class="total-registers">{{ 'TOTAL-REGISTERS' | translate }}:
                    {{vacancies?.length}}
                </span>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th class="text-center">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th [pSortableColumn]="'id'">
                        Id
                        <p-sortIcon [field]="'id'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'code'">
                        Vaga
                        <p-sortIcon [field]="'code'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'arrowImage'">
                        Seta
                        <p-sortIcon [field]="'arrowImage'"></p-sortIcon>
                    </th>
                    <th>QR Code</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-vacancy>
                <tr>
                    <td class="text-center">
                        <p-tableCheckbox [value]="vacancy">
                        </p-tableCheckbox>
                    </td>
                    <td>{{vacancy.id}}</td>
                    <td>{{vacancy.code}}</td>
                    <td>
                        <span *ngIf="vacancy.arrowDirection === 'UP'">
                            <img src="https://s3-sa-east-1.amazonaws.com/prod.em-files/boat/documentation/b05c99ae-1fca-46e5-b110-1f4762e7a67f_EASY_arrow_up.png"
                                alt="" style="height: 23px; width: auto;">
                        </span>
                        <span *ngIf="vacancy.arrowDirection === 'DOWN'">
                            <img src="https://s3-sa-east-1.amazonaws.com/prod.em-files/boat/documentation/51634ad1-eec3-4608-9513-aefccdd9161e_EASY_arrow_down.png"
                                alt="" style="height: 23px; width: auto;">
                        </span>
                    </td>
                    <td>
                        <a (click)="openQrCode(vacancy)" style="color: #0095ff; cursor: pointer;">
                            Abrir
                        </a>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="4">
                        Nenhuma embarcação item encontrada
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

</p-fieldset>
