import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { StockMovementFilter } from 'src/app/models/dtos/stock/stock-movement-filter';
import { PaginationFilter } from 'src/app/models/pagination-filter';
import { StockMovement } from 'src/app/models/stock/stock-movement';
import { StockMovementType } from 'src/app/models/stock/stock-movement-type';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StockMovementService {
  private readonly API_URL = environment.apiHost + '/api-stock/secure/movement';

  constructor(private http: HttpClient) {}

  findByFilter(
    filter: StockMovementFilter,
    paginationFilter: PaginationFilter,
    campos?: string
  ): Observable<any> {
    let params = new HttpParams();

    let sort;
    if (paginationFilter.sort) {
      sort = paginationFilter.sort;
    }
    if (paginationFilter.order) {
      sort = (sort ? sort + ',' : '') + paginationFilter.order;
    }
    if (sort) {
      params = params.set('sort', sort);
    }
    params = params.set('page', paginationFilter.page.toString());
    params = params.set('size', paginationFilter.size.toString());
    if (campos == undefined) {
      campos = '';
    }
    let headers = new HttpHeaders();
    headers = headers.set('campos', campos);
    console.log('filter', filter);
    return this.http
      .post(this.API_URL + '/find/filter', filter, {
        headers: headers,
        params: params,
      })
      .pipe(map((response: any) => response));
  }

  update(formData: StockMovement): Observable<any> {
    throw new Error('Method not implemented.');
  }
  save(formData: StockMovement): Observable<any> {
    return this.http
      .post(this.API_URL + '/save', formData)
      .pipe(map((response: any) => response));
  }

  cancel(
    movementId: number,
    newMovementId: number,
    orderId?: number
  ): Observable<boolean> {
    let params = new HttpParams();
    params = params.set('newMovementId', newMovementId.toString());
    if (orderId) {
      params = params.set('orderId', orderId.toString());
    }
    console.log('params', params);
    return this.http
      .post(this.API_URL + '/cancel/' + movementId,null, { params: params })
      .pipe(map((response: any) => response));
  }
}
