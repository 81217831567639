import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { InvoiceTaxDocumentService } from 'src/app/services/invoice-tax-document.service';
import { MarinaCompany } from 'src/app/models/marina-company';
import { PaginationFilter } from 'src/app/models/pagination-filter';
import { MessageUtil } from 'src/app/utils/message.util';
import { InvoiceTaxDocument } from 'src/app/models/invoice-tax-document';
import { SortEvent } from 'primeng/api';
import { Paginator } from 'primeng/paginator';
import Swal from 'sweetalert2';
import { DialogService } from 'primeng/dynamicdialog';
import { InvoiceTaxFormComponent } from '../invoice-tax-form/invoice-tax-form.component';
import { StorageUtil } from 'src/app/utils/storage.util';
import { CustomerService } from 'src/app/services/customer.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { ExtractInvoiceComponent } from 'src/app/components/extract-invoice/extract-invoice.component';
import { DomSanitizer } from '@angular/platform-browser';
import b64toBlob from 'b64-to-blob';
import { InvoiceTaxDocumentStatus } from 'src/app/models/enums/invoice-tax-document-status';

@Component({
  selector: 'app-invoice-tax-dialog',
  templateUrl: './invoice-tax-dialog.component.html',
  styleUrls: ['./invoice-tax-dialog.component.scss'],
})
export class InvoiceTaxDialogComponent implements OnInit {
  numberOfRows = 10;
  filter: any = {};
  rangeCreationDates: Date[];
  rangeDueDates: Date[];
  invoiceTaxList: InvoiceTaxDocument[] = [];
  selectedinvoiceTaxList: InvoiceTaxDocument[] = [];
  paginationFilter: PaginationFilter = new PaginationFilter();
  loggedMarina: any;
  contentLoaded = false;
  totalRecords = 0;
  totalDocuments: any;
  refresh = false;
  selectedCompany: any = null;
  marinaCompanies: MarinaCompany[];
  filteredCompanies: { label: string; value: any }[];
  filteredCustomers: any[] = [];
  customers: any[] = [];
  selectedCustomer: any = {};
  public statusList: Array<{ label: string; value: any }> = [
    {
      label: this.getStatus(InvoiceTaxDocumentStatus.Autorizada),
      value: InvoiceTaxDocumentStatus.Autorizada,
    },
    {
      label: this.getStatus(InvoiceTaxDocumentStatus.Cancelada),
      value: InvoiceTaxDocumentStatus.Cancelada,
    },
    {
      label: this.getStatus(InvoiceTaxDocumentStatus.AguardandoAutorizacao),
      value: InvoiceTaxDocumentStatus.AguardandoAutorizacao,
    },
    {
      label: this.getStatus(InvoiceTaxDocumentStatus.CancelamentoNegado),
      value: InvoiceTaxDocumentStatus.CancelamentoNegado,
    },
    {
      label: this.getStatus(InvoiceTaxDocumentStatus.EmProcessoDeCancelamento),
      value: InvoiceTaxDocumentStatus.EmProcessoDeCancelamento,
    },
    {
      label: this.getStatus(InvoiceTaxDocumentStatus.SolicitandoCancelamento),
      value: InvoiceTaxDocumentStatus.SolicitandoCancelamento,
    },
    {
      label: this.getStatus(InvoiceTaxDocumentStatus.Importada),
      value: InvoiceTaxDocumentStatus.Importada,
    },
  ];
  constructor(
    private dialog: DialogService,
    private spinner: NgxSpinnerService,
    private messageService: MessageUtil,
    private sanitizer: DomSanitizer,
    private invoiceTaxDocumentService: InvoiceTaxDocumentService,
    private customerService: CustomerService,
    private invoiceService: InvoiceService
  ) {}

  async ngOnInit(): Promise<void> {
    this.filter = {
      statusList: [
        InvoiceTaxDocumentStatus.AguardandoAutorizacao,
        InvoiceTaxDocumentStatus.Autorizada,
        InvoiceTaxDocumentStatus.CancelamentoNegado,
        InvoiceTaxDocumentStatus.EmProcessoDeCancelamento,
        InvoiceTaxDocumentStatus.SolicitandoCancelamento,
        InvoiceTaxDocumentStatus.Importada,
      ],
    };
    this.paginationFilter.order = 'DESC';
    this.paginationFilter.sort = 'createDate';
    await this.getCompanies();
    this.getCustomers();
    this.findAll();

    this.contentLoaded = true;
  }

  findAll(): void {
    this.spinner.show();
    this.onChangeCreationDate();
    this.onChangeDueDate();
    console.log(this.filter);
    this.invoiceTaxDocumentService
      .findByFilter(this.filter, this.paginationFilter)
      .subscribe({
        next: (response) => {
          this.invoiceTaxList = response.content;
          this.totalRecords = response.totalElements;
        },
        error: (error) => {
          this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageService.generateMessage(
            exception.type,
            exception.title,
            exception.message
          );
        },
        complete: () => {
          this.spinner.hide();
        },
      });
  }

  onSort(event: SortEvent): void {
    this.paginationFilter.order = event.order === 1 ? 'ASC' : 'DESC';
    this.paginationFilter.sort = event.field;
    this.findAll();
  }

  onChangePage(event: Paginator): void {
    this.paginationFilter.page = event.first / event.rows;
    this.paginationFilter.size = event.rows;
    this.findAll();
  }

  cantCancel(invTaxDoc: InvoiceTaxDocument): boolean {
    if (invTaxDoc.nfeStatus !== 'Autorizada') {
      return true;
    }
    return false;
  }

  getCompanyName(federalId): string {
    if (this.filteredCompanies && this.filteredCompanies.length >= 0) {
      const company = this.filteredCompanies.find(
        (c) =>
          c.value?.companyFederalId.replace(/[^0-9]+/g, '') ===
          federalId.replace(/[^0-9]+/g),
        ''
      );
      return company.label;
    } else {
      return '';
    }
  }

  async openInvTaxDocumentForm(
    invoiceTaxDocument: InvoiceTaxDocument
  ): Promise<void> {
    this.dialog
      .open(InvoiceTaxFormComponent, {
        data: {
          id: invoiceTaxDocument.id,
          model: invoiceTaxDocument,
        },
      })
      .onClose.subscribe(() => {
        this.findAll();
      });
  }

  cancelInvoiceTaxDocument(invoiceTaxDocument: any): void {
    this.openConfirmation(
      'Cancelar Nota Fiscal',
      'Digite "CANCELAR" para cancelar a nota fiscal',
      'warning'
    )
      .then(() => {
        let params: any = {};
        params.invoiceId = invoiceTaxDocument.invoice.invoiceId;
        params.invoiceTaxId = invoiceTaxDocument.id;
        this.spinner.show();

        this.invoiceTaxDocumentService.cancelInvoiceTax(params).subscribe(
          () => {
            this.messageService.generateMessage(
              'success',
              'SUMMARY.SUCCESS',
              'Solicitado Cancelamento da Nota.'
            );
            this.findAll();
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
            const exception = err.error.data.exception;
            this.messageService.generateMessage(
              exception.type,
              exception.title,
              exception.message
            );
          }
        );
      })
      .catch(() => {
        this.messageService.generateMessage(
          MessageUtil.WARNING,
          'Alerta',
          'Nota Fiscal não foi cancelada'
        );
      });
  }

  async openConfirmation(title, text, icon): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      Swal.fire({
        title,
        text,
        input: 'text',
        icon,
        backdrop: false,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Enviar',
        cancelButtonText: 'Não',
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          if (result.value === 'CANCELAR') {
            resolve();
          } else {
            reject();
          }
        } else if (result.dismiss) {
          reject();
        }
      });
    });
  }

  changeCompany(): void {
    if (!this.selectedCompany) {
      this.filter.federalId = null;
    } else {
      this.filter.federalId = this.selectedCompany.companyFederalId;
    }
  }
  changeCustomer(): void {
    if (!this.selectedCustomer) {
      this.filter.customerId = null;
    } else {
      this.filter.customerId = this.selectedCustomer.id;
    }
  }

  async getCompanies(): Promise<void> {
    const marina = StorageUtil.getMarina();
    this.marinaCompanies = marina.marinaCompanies;
    if (this.marinaCompanies.length > 0) {
      this.filteredCompanies = this.marinaCompanies.map((c) => ({
        label: c.companyFederalName,
        value: c,
      }));
      this.filteredCompanies.unshift({
        label: marina.companyName,
        value: marina,
      });
      this.filteredCompanies.unshift({
        label: 'Todas as empresas',
        value: null,
      });
    }
    this.loggedMarina = marina;
  }

  onChangeCreationDate() {
    if (this.rangeCreationDates) {
      if (this.rangeCreationDates[0] != null) {
        this.filter.startCreateDate = this.rangeCreationDates[0];
        if (this.rangeCreationDates[1] != null) {
          this.filter.endCreateDate = this.rangeCreationDates[1];
        } else {
          this.filter.endCreateDate = this.rangeCreationDates[0];
        }
      } else {
        this.filter.startCreateDate = null;
        this.filter.endCreateDate = null;
      }
    }
  }

  onChangeDueDate() {
    if (this.rangeDueDates) {
      if (this.rangeDueDates[0] != null) {
        this.filter.startDueDate = this.rangeDueDates[0];
        if (this.rangeDueDates[1] != null) {
          this.filter.endDueDate = this.rangeDueDates[1];
        } else {
          this.filter.endDueDate = this.rangeDueDates[0];
        }
      } else {
        this.filter.startDueDate = null;
        this.filter.endDueDate = null;
      }
    }
  }

  async clear(): Promise<void> {
    this.filter = {
      federalId: null,
      invoiceNumberStart: null,
      invoiceNumberEnd: null,
      nfeNumeroStart: null,
      nfeNumeroEndt: null,
      startCreateDate: null,
      endCreateDate: null,
      startDueDate: null,
      endDueDate: null,
      valorTotalStart: null,
      valorTotalEnd: null,
      aliquotaIssStart: null,
      aliquotaIssEnd: null,
      customerId: null,
    };
    this.selectedCompany = null;
    this.selectedCustomer = null;
    this.paginationFilter = {
      order: 'DESC',
      page: 0,
      size: this.numberOfRows,
      sort: 'createDate',
    };

    this.rangeCreationDates = [];
    this.rangeDueDates = [];
  }
  getCustomers(): void {
    this.customerService.getAll().subscribe((data) => {
      this.customers = data;
      this.filteredCustomers = [{ label: 'Todos', value: null }].concat(
        this.customers.map((c) => ({ label: c.name, value: c }))
      );
    });
  }
  extract(idInvoice): void {
    this.spinner.show();
    this.invoiceService.extract(idInvoice).subscribe(
      (data) => {
        this.openExtract(
          this.sanitizer.bypassSecurityTrustResourceUrl(
            URL.createObjectURL((b64toBlob as any)(data, 'application/pdf'))
          )
        );
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageService.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
      () => this.spinner.hide()
    );
  }
  openExtract(path): void {
    this.dialog.open(ExtractInvoiceComponent, {
      width: '100%',
      height: '100%',
      data: { path },
      header: 'Extrato da fatura - PDF',
    });
  }

  getStatus(statusValue): string {
    return statusValue.replace(/([A-Z])/g, ' $1');
  }
}
