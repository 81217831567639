export class OperationSpecialDay {

    id: number;
    date: string;
    operationStart: any;
    operationEnd: any;
    slingDownStart: any;
    intervalStart: any;
    intervalEnd: any;
    slingDownEnd: any;
    slingReturnEnd: any;
    executionTime: number;
    observation;
}