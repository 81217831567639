<form  [formGroup]="form" *ngIf="form && movementPlan?.boat" (submit)="next()">
  <section class="container">
    <div *ngIf="getFirstEdit()"  class="card">
      <div class="header">
        <img class="align-icon"src="/assets/images/Arrow-right.png" alt="">
        <b class="align-title-icon">Saída</b>
      </div>

      <div class="content">
        <div class="date-exit">
          <app-schedule-date-mv
            formControlName="executionDate"
            [minDate]="minDateToExecutionDate"
            [boat]="movementPlan.boat"
            [title]="'Saída'"
            [started]="isAlreadyStarted()">
          </app-schedule-date-mv>
        </div>
      </div>
    </div>

    <div  class="card" *ngIf="this.movementPlan.objectiveCode !== 'SINGLE' && getLastEdit()">
      <div class="header">
        <img class="align-icon" src="/assets/images/Arrow-left.png" alt="">
        <b class="align-title-icon">Retorno</b>
      </div>

      <div class="content">
        <div class="date-exit">
          <app-schedule-date-mv
            formControlName="returnTime"
            [isReturnDate]="true"
            [minDate]="getReturnMinDate()"
            [boat]="movementPlan.boat"
            [title]="'Retorno'"
            [started]="isAlreadyStarted()">
          </app-schedule-date-mv>
          <app-messages *ngIf="form && form.errors?.returnTimeNotBeforeExecutionError"
            [link]=""
            [titleMessage]="' Datas inválidas '"
            [textMessage]="' A data de retorno deve ser maior que a data de saída. '"
            [msgAction]="''">
          </app-messages>
        </div>
      </div>
    </div>

    <div class="card" *ngIf="form.value.navigationPlan" formGroupName="navigationPlan">
      <div class="header">
        <img class="align-icon" src="/assets/images/info.png" alt="">
        <b class="align-title-icon">Detalhes</b>
      </div>

      <div class="content">
        <div class="date-exit">
          <div class=" align-itens">

            <div class="form-control">
              <label>{{ hasChild ? 'Adultos' : 'Passageiros' }}</label>
              <app-passengers formControlName="crewQty" [typePeople]="hasChild ? 'Adult' : 'Person'" [totalPassengers]="totalPassagers - form.value.navigationPlan.childQty"></app-passengers>
            </div>

            <div class="form-control" *ngIf="hasChild">
              <label>{{ 'Crianças' }}</label>
              <app-passengers formControlName="childQty" [typePeople]="'Child'" [totalPassengers]="totalPassagers - form.value.navigationPlan.crewQty"></app-passengers>
            </div>

            <app-destinies-navigation-plan-mv class="width-destination"
              formControlName="destination"
              [frequentDestinations]="frequentDestinations">
            </app-destinies-navigation-plan-mv>

          </div>
        </div>
      </div>
    </div>
      <div class="form-control" style="max-width: 100%;
      max-height: 160px;">
        <label>Observações</label>
        <textarea [rows]="2" pInputTextarea
                            rows="5"
                            name="messageNavigationPlan"
                            formControlName="observation" placeholder="Observações"
                            style="max-height: 160px; height: 160px;"></textarea>
      </div>
  </section>

  <div class="buttons align-bottom">
    <!-- <div [ngClass]="{'align-bottom': !form.value.navigationPlan}" class="buttons"> -->
    <button class="easymarine" *ngIf="isNew" (click)="back()" type="button">{{'BACK'| translate}}</button>
    <button class="easymarine danger" (click)="cancel()" type="button">{{'CANCEL'| translate}}</button>
    <button class="easymarine primary" type="submit" [disabled]="!form.valid">{{'SAVE'| translate}}</button>
  </div>
</form>
