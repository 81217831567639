import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { StockMovementType } from 'src/app/models/stock/stock-movement-type';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MovementTypeService {
  private readonly API_URL =
    environment.apiHost + '/api-stock/secure/movement-type';

  constructor(private http: HttpClient) {}

  getAll(campos?: string): Observable<StockMovementType[]> {
    if (campos == undefined) {
      campos = '';
    }
    let headers = new HttpHeaders();
    headers = headers.set('campos', campos);
    return this.http
      .get(this.API_URL + '/find-by-marinaId', { headers: headers })
      .pipe(map((response: StockMovementType[]) => response));
  }

  update(
    movementType: StockMovementType,
    campos?: string
  ): Observable<StockMovementType> {
    if (campos == undefined) {
      campos = '';
    }
    let headers = new HttpHeaders();
    headers = headers.set('campos', campos);
    return this.http
      .put<StockMovementType>(this.API_URL+'/save', movementType, { headers: headers })
      .pipe(map((response: StockMovementType) => response));
  }

  save(
    movementType: StockMovementType,
    campos?: string
  ): Observable<StockMovementType> {
    if (campos == undefined) {
      campos = '';
    }
    let headers = new HttpHeaders();
    headers = headers.set('campos', campos);
    return this.http
      .post<StockMovementType>(this.API_URL+'/save', movementType, { headers: headers })
      .pipe(map((response: StockMovementType) => response));
  }
}
