import { MarinaService } from './../../services/marina.service';
import { Token } from './../../models/token';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Marina } from 'src/app/models';
import { MarinaCompany } from 'src/app/models/marina-company';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { StorageUtil } from 'src/app/utils/storage.util';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  checked = true;
  user: Token;
  marinas: any[] = [];
  selectedMarina: Marina;
  newSelectedMarina: { id; name };
  company: MarinaCompany;
  language: any;
  finances = false;
  days: number;
  mobile = false;

  @HostListener('window:resize', ['$event']) onScroll($event: Event): void {
    this.adjustWidth();
  }

  constructor(
    private route: ActivatedRoute,
    private invoiceService: InvoiceService,
    private translate: TranslateService,
    public router: Router,
    private marinaService: MarinaService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.adjustWidth(true);
    // await this.findMarinas();
    this.user = StorageUtil.getUser();
    this.selectedMarina = StorageUtil.getMarina();
    if(this.selectedMarina) {
      this.newSelectedMarina = this.marinas.find( marina => marina.id === this.selectedMarina.id);
    }
    const currentParams = Object.assign({}, this.route.snapshot.queryParams);
    if (currentParams && currentParams.company) {
      this.company = this.selectedMarina.marinaCompanies.find(
        (m) => m.id === currentParams.company
      );
    }
    this.language = this.translate.currentLang;
    this.countInvoicesOfBillsNotSent();
    BroadcastService.get('showHideSidenav').subscribe((checked) => {
      this.checked = checked;
    });
    BroadcastService.get('companyChanged').subscribe(
      (companyChanged: { company: number; finances: boolean }) => {
        this.company = null;
        this.finances = companyChanged.finances;
        if (companyChanged.company) {
          this.company = this.selectedMarina.marinaCompanies.find(
            (m) => m.id === companyChanged.company
          );
        }
      }
    );
  }


  findMarinas(): Promise<void> {
    return new Promise(resolve => {
      this.marinaService
      .getMarinas()
      .subscribe((marinaList) => {
        marinaList.forEach((marina) => this.marinas.push(marina));
        resolve();
      },
      error => {
        console.log(error)
      });
    })
  }

  showHide(): void {
    BroadcastService.get('showHideSidenav').emit(this.checked);
  }

  getMarinaName(): string {
    return this.finances
      ? this.company
        ? this.company.companyFederalName
        : StorageUtil.getMarina().companyName
      : StorageUtil.getMarinaName();
  }

  getLoggedUser(): string {
    return StorageUtil.getUserName();
  }

  getLogoMarina(): string {
    return StorageUtil.getLogoMarina();
  }

  countInvoicesOfBillsNotSent(): void {
    this.invoiceService.countInvoicesOfBillsNotSent().subscribe(
      (data) => {
        if (data > 0) {
          Swal.fire({
            title: 'Atenção',
            text: (data > 1 ? 'Existem ' : 'Existe ')
              .concat(data.toString())
              .concat(
                data > 1
                  ? ' boletos que não foram enviados e-mail'
                  : ' boleto que não foi enviado e-mail'
              ),
            icon: 'warning',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Fechar',
          }).then((result) => {
            BroadcastService.get('messageDismissed').emit();
          });
        } else {
          setTimeout(() => {
            BroadcastService.get('messageDismissed').emit();
          }, 1000);
        }
      },
      () => null
    );
  }

  async selectMarina(): Promise<void> {
    await this.findSelectedMarina();
    this.router.navigate(['/app/dashboard']);
    BroadcastService.get('changeMarina').emit();
  }

  async findSelectedMarina(): Promise<void>{
    return new Promise(async resolve => {
      this.marinaService
      .getMarina(this.newSelectedMarina.id)
      .subscribe((marina) => {
        this.selectedMarina = marina;
        StorageUtil.setMarina(this.selectedMarina);
        resolve();
      });
    });
  }

  adjustWidth(init = false): void {
    BroadcastService.get('showHideSidenav').emit(false);
    this.checked = false;
    if (window.outerWidth <= 992) {
      this.mobile = true;
    } else if (!init) {
      this.mobile = false;
    }
  }
}
