import { LogInvoicesActions } from 'src/app/models/enums/logs/log-invoices-actions';

export class EasyBankLogFilter {
  dateStart: Date;
  dateEnd: Date;
  userId: number;
  invoiceId: string;
  customerId: number;
  invoiceAction: LogInvoicesActions;

  constructor() {
    this.dateStart = null;
    this.dateEnd = null;
    this.userId = null;
    this.invoiceId = null;
    this.customerId = null;
    this.invoiceAction = null;
  }
}
