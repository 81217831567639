import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { SecuritiesProperty } from 'src/app/models/securities-property/securities-property';
import { SecuritiesPropertyHistoric } from 'src/app/models/securities-property/securities-property-historic';
import { SecuritiesPropertyService } from 'src/app/services/securities-property/securities-property.service';
import { MessageUtil } from 'src/app/utils/message.util';

@Component({
  selector: 'app-securities-property-hist',
  templateUrl: './securities-property-hist.component.html',
  styleUrls: ['./securities-property-hist.component.scss']
})
export class SecuritiesPropertyHistComponent implements OnInit {

  listHistoric: SecuritiesPropertyHistoric[] = [];
  securitiesProperty: SecuritiesProperty = new SecuritiesProperty();
  constructor(
    private messageUtil: MessageUtil,
    private securitiesPropertyService: SecuritiesPropertyService,
    private config: DynamicDialogConfig,
  ) { }

  ngOnInit() {
    this.securitiesProperty = this.config.data.securitiesProperty;
    this.getHistoric();
  }

  getHistoric(): void {
    this.securitiesPropertyService
      .getHistoric(this.securitiesProperty.id)
      .subscribe({
        next: (data) => {
          this.listHistoric = data;
        },
        error: (error) => {
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(
            exception.type,
            exception.title,
            exception.message
          );
        },
      });
  }
}
