import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SelectLabelValue } from 'src/app/models/select-label-value';
import { BoatService } from 'src/app/services/boat.service';

@Component({
  selector: 'app-boat-wash-engine-turn-form-dialog',
  templateUrl: './boat-wash-engine-turn-form-dialog.component.html',
  styleUrls: ['./boat-wash-engine-turn-form-dialog.component.scss']
})
export class BoatWashEngineTurnFormDialogComponent implements OnInit, AfterViewInit {

  isNew = false;
  boatsFilter: SelectLabelValue[] = [];
  boatSelected: number;
  requestDate: any;
  seaWash = false;
  seaEngineTurn = false;
  washEngineTurnOption: string;
  engineTurn: boolean;
  wash: boolean;

  pt = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  };
  constructor(
    public dialog: DynamicDialogRef,
    private boatService: BoatService,
    private config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.boatSelected = this.config.data.boatSelected;
    this.requestDate = this.config.data.requestDate;
    this.seaWash = this.config.data.seaWash;
    this.seaEngineTurn = this.config.data.seaEngineTurn;
    this.engineTurn = this.config.data.engineTurn;
    this.wash = this.config.data.wash;
    this.isNew = this.config.data.isNew;
    this.getBoats();
  }

  ngAfterViewInit(): void {
    if (this.engineTurn && !this.wash) {
      this.washEngineTurnOption = 'ENGINE_TURN_ONLY';
    } else if (!this.engineTurn && this.wash) {
      this.washEngineTurnOption = 'WASH_ONLY';
    }
  }

  getBoats(): void {
    this.boatService.getAllByActiveForSelect().subscribe((data) => {
      this.boatsFilter = data;
    });
  }

  save(): void {
    const response = {
      boatId: this.boatSelected,
      requestDate: this.requestDate,
      washEngineTurnOption: this.washEngineTurnOption,
      seaWash: this.seaWash,
      seaEngineTurn: this.seaEngineTurn,
    };
    this.dialog.close(response);
  }

}
