<div class="table">
    <p-table #tt [value]="slingLogs" [resizableColumns]="true" [autoLayout]="true" [paginator]="true"
    [rows]="numberOfRows" [pageLinks]="3" [rowsPerPageOptions]="isDialog? null : [10,20,30,50,100]">
        <ng-template pTemplate="header">
            <tr>
                <th>Lingada</th>
                <th>Embarcação</th>
                <th>Usuário</th>
                <th>Origem</th>
                <th>Data/hora</th>
                <th>Status da operação</th>
                <th>Status barco</th>
                <th>Ação</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-slingLog>
            <tr>
                <td>{{slingLog.slingId}}</td>
                <td>{{slingLog.boatName}}</td>
                <td [pTooltip]="slingLog.user ? slingLog.user.fullName : ''" tooltipPosition="top">{{slingLog.user ? slingLog.user.username : ''}}</td>
                <td>{{slingLog.origin}}</td>
                <td>{{slingLog.logTime | date: 'dd/MM/yyyy - HH:mm'}}</td>
                <td>{{slingLog.slingStatus | translate}}</td>
                <td>{{slingLog.boatStatus | translate}}</td>
                <td>{{slingLog.operationLog | translate}}</td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="7">
                    Nenhum log encontrado
                </td>
            </tr>
        </ng-template>

    </p-table>
</div>
