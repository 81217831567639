import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FranchiseGroupService } from 'src/app/services/franchise-group.service';
import { ToastService } from 'src/app/services/toast.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { ProductService } from 'src/app/services/product.service';
import { ProductType } from 'src/app/models/enums';

@Component({
  selector: 'app-franchise-group-form',
  templateUrl: './franchise-group-form.component.html',
  styleUrls: ['./franchise-group-form.component.scss']
})
export class FranchiseGroupFormComponent implements OnInit{
  franchiseGroupForm: FormGroup = new FormGroup({
    id: new FormControl<number | null>(null),
    name: new FormControl<string | null>(null, Validators.required),
    franchise: new FormControl<number | null>(null, Validators.required),
    description: new FormControl<string | null>(''),
    active: new FormControl<boolean | null>(true),
    surplusServiceId: new FormControl<number | null>(null),
    marinaId: new FormControl<number | null>(null),
  });

  products: any[] = [];
  isStandard: boolean = false;

  constructor(
    public dialog: DynamicDialogRef,
    private franchiseGroupService: FranchiseGroupService,
    private messageUtil: MessageUtil,
    private spinner: NgxSpinnerService,
    private toasty: ToastService,
    private config: DynamicDialogConfig,
    private productService: ProductService,

  ) { }

  ngOnInit(): void {
    this.findProducts();
    console.log(this.config)
    if (this.config && this.config.data?.group?.id) {
      this.initialize()
    }
  }

  initialize(): void {
    this.spinner.show();
    this.franchiseGroupService.findById(this.config.data.group.id).subscribe({
      next: (response) => {
        this.franchiseGroupForm.patchValue({
          id: response.id,
          name: response.name,
          franchise: response.franchise,
          description: response.description,
          active: response.active,
          surplusServiceId: response.surplusService.id,
          marinaId: response.marinaId,
        });
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
        this.messageUtil.generateMessage('error','Erro ao buscar grupo de franquia', error);
      },
    });
  }

  save(): void {
    console.log(this.franchiseGroupForm.value)
    this.spinner.show();
    this.franchiseGroupService.save(this.franchiseGroupForm.value).subscribe({
      next: (response) => {
        this.spinner.hide();
        this.toasty.success('Categoria cadastrada com sucesso');
        this.dialog.close();
      },
      error: (error) => {
        this.spinner.hide();
        this.messageUtil.generateMessage('error','Erro ao salvar grupo de franquia', error);
      },
    });
  }

  getPriceType() {
    switch(this.products.find(p => p.id === this.franchiseGroupForm.get('surplusServiceId').value)?.pricingType){
      case 'SimplePrice':
        return this.messageUtil.translateKey('SIMPLE-PRICE');
      case 'FixedPerRange':
        return this.messageUtil.translateKey('FIXED-PER-RANGE');
      case 'PerRangeLength':
        return this.messageUtil.translateKey('PER-RANGE-LENGTH');
      default:
        return this.messageUtil.translateKey('SIMPLE-PRICE');
    }
  }

  findProducts(): void {
    this.spinner.show();
    this.productService.getAll().subscribe({
      next: (response) => {
        this.products = response.filter(p => p.active && (p.productType === ProductType.Service || p.productType === ProductType.PeriodicalService || p.productType === ProductType.PeriodicalServiceCustomer));
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
        this.messageUtil.generateMessage('error','Erro ao buscar produtos', error);
      },
    });
  }

}
