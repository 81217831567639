import { Customer } from './customer';

export class NavigationPlan {
    id: number;
    responsible: Customer;
    onBoardPhone: string;
    secondContact: string;
    secondContactPhone: string;
    destination: string;
    crewQty: number;
    childQty: number;
    returnTime: any;
    returnDateTime: string;

    constructor() {
        this.responsible = new Customer();
    }
}
