import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SlingConfig } from 'src/app/models';


@Component({
  selector: 'app-movement-responsibles',
  templateUrl: './movement-responsibles.component.html',
  styleUrls: ['./movement-responsibles.component.scss']
})
export class MovementResponsiblesComponent implements OnInit {

  @Input()
  readonly isNew: boolean;

  @Input()
  readonly slingConfig: SlingConfig

  @Input()
  form: UntypedFormGroup
  searchByCustomer: boolean = undefined;

  constructor(
  ) { }

  async ngOnInit(): Promise<void> {
    if (this.slingConfig) {
      this.searchByCustomer = this.slingConfig.searchByCustomer;
    }
  }

  enabledSailor(): boolean {
    return this.slingConfig.hasSailor;
  }

  getFederalId() {
    return this.form?.getRawValue()?.customer?.federalId || '';
  }
}
