import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { StorageUtil } from '../utils/storage.util';

@Injectable()
export class AuthGuard  {

  constructor(
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (StorageUtil.getToken() && StorageUtil.getToken() !== null && StorageUtil.getToken() !== undefined) {
      return true;
    }
    this.router.navigate(['login']);
    return false;
  }
}
