
import { Marina } from './marina';
import { MovementLocation } from './movement/movement-location';

export class Vacancy {
    id: number;
    code?: string;
    length?: number;
    marina?: Marina;
    arrowDirection?: string;
    movementLocation?: MovementLocation;
    shared?: boolean;
    active?: boolean;
    boats?: number[];
    boatsName?: string;

    constructor() {
        this.marina = new Marina();
    }
}
