import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { fabric } from 'fabric';
import { Canvas, Group, Object, IText, Point } from 'fabric/fabric-impl';
;

import { MapUtilService } from '../services/map-util.service';
import Swal, { SweetAlertOptions } from 'sweetalert2';

import { StorageUtil } from '../utils/storage.util';
import { MarinaBerthMap } from '../models/marina-berth-map';
import { ToastService } from '../services/toast.service';
import { VacancyService } from '../services/vacancy.service';
import { VacancyMapDTO } from '../models/dtos/vacancy-mapDTO';
import { MovementLocationService } from '../services/movements/movement-location.service';
import { MovementLocation } from '../models/movement/movement-location';
import { Vacancy } from '../models/vacancy';
import { Marina } from '../models';
import { VacancyStatusDTO } from '../models/dtos/vacancy-statusDTO';
import { BoatService } from '../services/boat.service';
import { BoatDTO } from '../models/dtos/boatDTO';
import { DialogService } from 'primeng/dynamicdialog';
import { BerthMapInfoFormComponent } from './info-form/berth-map-info-form.component';

@Component({
  selector: 'app-berth-map',
  templateUrl: './berth-map.component.html',
  styleUrls: ['./berth-map.component.css']
})
export class BerthMapComponent implements OnInit {


  title = 'context-menu';

  isDisplayBerthContextMenu: boolean;
  rightClickMenuPositionX: number;
  rightClickMenuPositionY: number;
  rightClickedFabricObj: Object;
  b_map: MarinaBerthMap;
  absoluteClickedPoint?: Point;
  vacancyStatusDTOList: VacancyStatusDTO[];
  isDisplayMenuMap: boolean;
  _canvas: Canvas;
  refreshButtonDisabled: boolean;
  saveButtonDisabled: boolean;


  public editModes : any[];
  public selectedEditMode: string;

  constructor(private mapUtilService: MapUtilService,              
              private toasty:ToastService,
              private vacancyService: VacancyService,
              private movementLocationService: MovementLocationService,
              private boatService: BoatService,
              private dialog: DialogService) {
    this.editModes = [
      {label: 'Modo Edição',value: 'ED'},
      {label: 'Modo Operação',value: 'OP'},
      {label: 'Leitura', value: 'RO'}

    ]
  }



  ngOnInit() {
     

      this._canvas = new fabric.Canvas("canvas");
      this.mapUtilService.canvas = this._canvas;
      this.mapUtilService.initCanvas();

    this._canvas.on("mouse:down",(evt) => {
       this.absoluteClickedPoint  = evt.absolutePointer;
       if (evt.target) {
           let fabricObj:Object = evt.target;
           console.log(fabricObj);
           if (evt.button == 3 &&  fabricObj && ( (fabricObj.type == 'rect') || (fabricObj.get('type') == 'group' && fabricObj['esy.type'] == 'boat_icon') ) ) {  
              this.displayContextMenu(evt.e,fabricObj);
            
           }
          } else {
            if (evt.button == 3 ) {
              this.displayContextMApMenu(evt.e,evt.target);
            }
          }
        }

    );

    
    this.selectedEditMode = 'RO';
    this.refreshButtonDisabled = false;
    this.saveButtonDisabled = true;
    

    
  }


  displayContextMApMenu(event,fabricObj:Object) {
    this.isDisplayMenuMap = true;
      this.rightClickMenuPositionX = event.clientX;
      this.rightClickMenuPositionY = event.clientY;

  }

  editModeChange(evt:any) {
    if(this.selectedEditMode == 'ED') {
      this.mapUtilService.unlockAllObjects();
      this.mapUtilService.clearOperationInfo();
      this.refreshButtonDisabled = true;
      this.saveButtonDisabled = false;
    }
    if(this.selectedEditMode == 'OP' || this.selectedEditMode == 'RO') {
      this.mapUtilService.lockAllObjects();
      this.reloadStatus();
      this.refreshButtonDisabled = false;
      this.saveButtonDisabled = true;
    } 
  }


  displayContextMenu(event,fabricObj:Object) {
    console.log(fabricObj);  
    this.isDisplayBerthContextMenu = true;
    if (fabricObj['esy.type'] == 'boat_icon' ) {
      this.rightClickedFabricObj = this.mapUtilService.findBerthObjectByVacancyId(fabricObj['esy.berthId']);
    } else {
      this.rightClickedFabricObj = fabricObj;
    }
    
    this.rightClickMenuPositionX = event.clientX;
    this.rightClickMenuPositionY = event.clientY;

  }

  getRightClickMenuStyle() {
    return {
      position: 'fixed',
      left: `${this.rightClickMenuPositionX}px`,
      top: `${this.rightClickMenuPositionY}px`
    }
  }

  async handleBerthContextMenuClick(event) {
   console.log(event.event.altKey); 
   switch (event.data.action) {
      case 'clone-right':
        let pointR = this.mapUtilService.getPointForBerthClone(this.rightClickedFabricObj,'right');
        if (event.event.altKey) {
          this.alocateExistingVacancy(pointR.y,pointR.x,this.rightClickedFabricObj.angle);
        } else {
          this.newVacancy(pointR.y,pointR.x,this.rightClickedFabricObj.angle);
        }
        break;
      case 'clone-left':
        let pointL = this.mapUtilService.getPointForBerthClone(this.rightClickedFabricObj,'left');
        if (event.event.altKey) {
          this.alocateExistingVacancy(pointL.y,pointL.x,this.rightClickedFabricObj.angle);
        } else {
          this.newVacancy(pointL.y,pointL.x,this.rightClickedFabricObj.angle);  
        }
        break;
      case 'delete':
        this.mapUtilService.deleteBerthOnMap(this.rightClickedFabricObj);
        break;
      case 'change':
        let formValues = await  Swal.fire(this.getBerthEditAlertOptions(null,this.rightClickedFabricObj["esy.berthCode"],(this.rightClickedFabricObj.width * this.rightClickedFabricObj.scaleX * this.mapUtilService.pixel_scale_m ).toFixed(2),(this.rightClickedFabricObj.height * this.rightClickedFabricObj.scaleY * this.mapUtilService.pixel_scale_m).toFixed(2)  ));
        if (formValues.value ) {
         
          let berthCode = formValues.value[0];
          let width = parseFloat(formValues.value[1]) / this.mapUtilService.pixel_scale_m;
          let height = parseFloat(formValues.value[2]) / this.mapUtilService.pixel_scale_m;
          this.mapUtilService.changeBerthAtributes(berthCode,width,height,this.rightClickedFabricObj); 
        }
        break;

      case 'info':
        this.openInfoDialog();   
    }
  }

  async handleBerthOperationMenuClick(event) {
    switch (event.data.action) {
      case 'navigation':
        this.mapUtilService.changeBerthColor(this.rightClickedFabricObj,this.mapUtilService.colors.on_navigation);
        break;
      case 'nav-return':
        alert('nav-return');
        break;
      case 'moored':
        this.mapUtilService.addBoatIcon(this.rightClickedFabricObj,"recones/Lancha-4.svg");
        break;
      case 'return-delayed':
        this.mapUtilService.changeBerthColor(this.rightClickedFabricObj,this.mapUtilService.colors.return_delayed);
        break;  
      case 'set-boat':
        let boatNoVacancyList = await this.boatService.getActiveNoVacancy().toPromise();
        let boatFormValue = await Swal.fire(this.getBoatsAlertOption(boatNoVacancyList));
        if (boatFormValue &&  boatFormValue.value && boatFormValue.value[0]) {
          let boatId = parseInt(boatFormValue.value[0]);
          let vacancyId = this.rightClickedFabricObj['esy.berthId'];
          this.vacancyService.setBoat(vacancyId,boatId).subscribe(
            (r) => {},
            (err) => {
              this.toasty.error("Erro ao vincular emnbarcação á vaga.");
            },
            () => { 
              this.rightClickedFabricObj['esy.boatId'] = boatId;
              this.toasty.success("Embarcação vinculada com sucesso!");
              this.reloadStatus();
              this.mapUtilService.saveMap();
            }
          );
        }
        this.mapUtilService.changeBerthColor(this.rightClickedFabricObj,this.mapUtilService.colors.berth_avaiable);
        case 'info':
          this.openInfoDialog();   
        
        
        break;  
      case 'unset-boat':
        if ( this.rightClickedFabricObj['esy.boatId'] &&  this.rightClickedFabricObj['esy.berthId']) {
          let  boatId = parseInt(this.rightClickedFabricObj['esy.boatId']);
          this.vacancyService.unsetBoat(boatId).subscribe(
            (r) => {},
            (err) => {
              this.toasty.error("Erro ao desvincular emnbarcação á vaga.");
            },
            () => { 
              this.rightClickedFabricObj['esy.boatId'] = null;
              this.toasty.success("Embarcação devinculada com sucesso!");
              this.reloadStatus();
              this.mapUtilService.saveMap();
            }
          );
        }
       
        break;                 
      
      

    }



  }





  private openInfoDialog() {
    if (this.vacancyStatusDTOList.length > 0) {
      let vacancyStatus: VacancyStatusDTO = this.getStatusFromRightClicked(this.rightClickedFabricObj);
      if (vacancyStatus) {
        this.openInfoDialogForm(vacancyStatus);
      }

    }
  }

  @HostListener('document:click')
  documentClick(): void {
    this.isDisplayBerthContextMenu = false;
    this.isDisplayMenuMap = false;
  }


  async handleMenuMapClick(event) {
    let _top:number = this.absoluteClickedPoint.y;
    let _left:number = this.absoluteClickedPoint.x;
      switch (event.data.action) {
        case 'new':
          await this.newVacancy(_top, _left,0);
        break;
        case 'import':
            await this.alocateExistingVacancy(_top, _left,0);
        break;
      }
             
}


  private async alocateExistingVacancy(_top: number, _left: number,angle:number) {
    let berthMapList = this.getSimpeBerthMapList();
    let vacancies = await this.getWetVacancies();
    const vacanciesAvaiable: VacancyMapDTO[] = vacancies.filter(v => !berthMapList.some(b => b.id == v.id));
    let selectedVacancy = await Swal.fire(this.getBerthImportAlertOptions(vacanciesAvaiable, '5.5', '10'));
    if (selectedVacancy) {
      let selValue: string = selectedVacancy.value[0].toString();
      let selId = parseInt(selValue.split("|")[0]);
      let code = selValue.split("|")[1];
      this.mapUtilService.createBerthOnMap(selId, code, 5.5, 10, _top, _left,angle);
    }
  }

  private async newVacancy(_top: number, _left: number, angle: number) {
    let locations = await this.getMovmentLocations();
    let formValues = await Swal.fire(this.getBerthEditAlertOptions(locations, "", "", ""));

    if (formValues.value) {
      let width: number; let height: number; let locationId: string; let berthCode: string;
      let newVacancy: Vacancy = new Vacancy();
      if (locations && locations.length > 0) {
        locationId = formValues.value[0];
        berthCode = formValues.value[1];
        width = parseFloat(formValues.value[2]);
        height = parseFloat(formValues.value[3]);
        let location = locations.filter(l => l.id == parseInt(locationId));
        newVacancy.movementLocation = (location !== null && location.length > 0) ? location[0] : null;

      } else {
        let berthCode = formValues.value[0];
        width = parseFloat(formValues.value[1]);
        height = parseFloat(formValues.value[2]);
      }
      newVacancy.code = berthCode;
      newVacancy.marina = StorageUtil.getMarina();
      newVacancy.length = parseFloat((height * 3.28084).toFixed(1));
      newVacancy.shared = false;
      newVacancy.active = true;
      this.vacancyService.create(newVacancy).subscribe(
        (v) => {
          newVacancy = v;
        }, (e) => {

          this.toasty.error(e.error.message);
        }, () => {
          let id = newVacancy.id;
          this.mapUtilService.createBerthOnMap(id, newVacancy.code, width, height, _top, _left, angle);

        });

    }
  }

saveMap() {
  this.mapUtilService.saveMap();

}

addBoatImage(svg_icon:string) {
  this.mapUtilService.addBoatIcon(this.rightClickedFabricObj,  svg_icon);
}


getBerthEditAlertOptions(locations: MovementLocation[],berthCode:string,width:string,height:string):SweetAlertOptions {   //
  if (!berthCode) {
    berthCode = "";
  }  

  let selOption: string = ''; 
  
  if (locations && locations.length > 0) {
    selOption += 
    '<div class="field grid">' +
      '<label for="sel-location" style="font-size: 18px" class="col">Lugar</label>' +
      '<div class="col">' +    
        '<select style="height:22px;font-size: 18px" class="w-full text-base text-color surface-overlay p-0 border-1 border-solid surface-border border-round outline-none focus:border-primary" style="appearance: auto" name="sel-location" id="sel-location">';

    locations.forEach(element => {
      selOption += '<option value="' + element.id + '">' +   element.name + '</option>'
    });
  selOption += '</select>' +
  '</div>' +
  '</div>';

}



  return {
    width: 400,
    title: 'Nova Vaga',
    html:   
      selOption + 
      '<div class="field grid">' +
          '<label for="swal-input1" style="font-size: 18px" class="col">Código</label>' +
          '<div class="col">' +
            '<input value="' + berthCode + '" placeholder="codigo" id="swal-input1" style="height:22px;font-size: 18px" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">' +
          '</div>' +
      '</div>' +
      '<div class="field grid">' +
        '<label for="swal-input2" style="font-size: 18px" class="col">Largura</label>' +
        '<div class="col">' +
          '<input value="' + width + '" placeholder="largura" id="swal-input2" style="height:22px;font-size: 18px" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">' +
        '</div>' +
      '</div>' +  
      '<div class="field grid">' +
        '<label for="swal-input3" style="font-size: 18px" class="col">Largura</label>' +
        '<div class="col">' +              
          '<input value="' + height + '" placeholder="comp" id="swal-input3" style="height:22px;font-size: 18px" class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">' +
        '</div>' +
      '</div>' ,
    focusConfirm: false,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Confirmar',
    cancelButtonText: 'Cancelar',
    reverseButtons: true,
    preConfirm: () => {
      if (locations && locations.length > 0) {          
      return [
        document.getElementById('sel-location')['value'],
        document.getElementById('swal-input1')['value'],
        document.getElementById('swal-input2')['value'],
        document.getElementById('swal-input3')['value']
      ]
    } else {
      return [        
        document.getElementById('swal-input1')['value'],
        document.getElementById('swal-input2')['value'],
        document.getElementById('swal-input3')['value']
      ]
    }

    }
  }
}


getBerthImportAlertOptions(aviableVacancies:VacancyMapDTO[],width:string,height:string):SweetAlertOptions { 
  let selOption: string = '<select class="swal2-input" name="swal-input1" id="swal-input1">';
  aviableVacancies.forEach(element => {
    selOption += '<option value="' + element.id +'|'+ element.code  +  '">' +   element.movmentLocation + ' - ' + element.code  + '</option>'
  });
  selOption += '</select>';

  return {
    width: 350,
    title: 'Importar Vaga',
    html:
    selOption +
      '<input value="' + width + '" placeholder="largura" id="swal-input2" class="swal2-input" size="6" style="height:22px;font-size: 18px">' +
      '<input value="' + height + '" placeholder="comp" id="swal-input3" class="swal2-input" size="6" style="height:22px;font-size: 18px">',
    focusConfirm: false,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Confirmar',
    cancelButtonText: 'Cancelar',
    reverseButtons: true,
    preConfirm: () => {
      return [
        document.getElementById('swal-input1')['value'],
        document.getElementById('swal-input2')['value'],
        document.getElementById('swal-input3')['value']
      ]
    }
  }
}

getBoatsAlertOption(listBoatDTO:BoatDTO[]):SweetAlertOptions { 
  let selOption: string = '<select class="swal2-input" name="swal-input1" id="swal-input1">';
  listBoatDTO.forEach(boat => {
    selOption += '<option value="' + boat.id +  '">' +   boat.name +  ' - ' + boat.shipyardName  + ':' + boat.shipyardModelName + '('  + boat.comercialLength + '")' + '</option>'
  });
  selOption += '</select>';

  return {
    width: 350,
    title: 'Vincular Embarcação',
    html: selOption,
    focusConfirm: false,
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Confirmar',
    cancelButtonText: 'Cancelar',
    reverseButtons: true,
    preConfirm: () => {
      return [
        document.getElementById('swal-input1')['value'],
      ]
    }
  }
}


  changeColor() {
    this.mapUtilService.changeBerthColor(this.rightClickedFabricObj, "#FFCD4B");
  }

  async getWetVacancies(): Promise<VacancyMapDTO[]> {
    let vacancies: VacancyMapDTO[] = await this.vacancyService.getActivesByLocationType('WET_VACANCY').toPromise();
    return vacancies;
  }


  getSimpeBerthMapList(): any[] {
    return this._canvas.getObjects()
      .filter((o) => o['esy.type'] == 'berth' && o['esy.berthId'] != null)
      .map((o) => { return { "id": o['esy.berthId'], "code": o['esy.berthCode'] } });
  }

  

  async getMovmentLocations(): Promise<MovementLocation[]> {
    let locations: MovementLocation[] = await this.movementLocationService.getAll().toPromise();
    return locations.filter( l => l.type == 'WET_VACANCY'  );
    

  } 

  async reloadStatus() {
    this.vacancyStatusDTOList = await this.vacancyService.getWetVacanciesStatus('WET_VACANCY').toPromise();
    this.mapUtilService.updateBerthsStatus(this.vacancyStatusDTOList);
    
  }

  openInfoDialogForm( vacancyStatusDTO: VacancyStatusDTO) {
    this.dialog
    .open(BerthMapInfoFormComponent, {
      width: '900px',
      height: '600px',
      dismissableMask: false,
      data: { vacancyStatusDTO },
      header: "Informações",
    });
    
    
  }

  getStatusFromRightClicked(rightClickedObj:Object): VacancyStatusDTO {
    let filteredStatus:VacancyStatusDTO[];    
    if (this.vacancyStatusDTOList && this.vacancyStatusDTOList.length > 0) {
        filteredStatus  = this.vacancyStatusDTOList.filter(stat => {  return stat.id == rightClickedObj['esy.berthId']});   
    }      
    if (filteredStatus && filteredStatus.length > 0) {
      return filteredStatus[0];
    }  else {
      return null;
    }

  }






}
