<p-fieldset class="dialog-form">
    <p-header class="article-title">
        <span class="article-title">{{ 'TITLE-CONTRACTS-DEFAULT-SIGNER' | translate }}</span>
        <div class="primary fab-button" (click)="openDefaultSignerForm()" *ngIf="defaultSigners.length < 3">
            <i class="pi pi-plus" style="font-size: 21px;"></i>
        </div>
    </p-header>
    <div class="table">
        <p-table #tt [value]="defaultSigners" resetPageOnSort="true" [pageLinks]="3" [paginator]="true"
            [rowsPerPageOptions]="[10,20,30,50,100]" [autoLayout]="true" rows="100">
            <ng-template pTemplate="header">
                <tr>
                    <th [pSortableColumn]="'name'">
                        {{ 'NAME' | translate }}
                        <p-sortIcon [field]="'name'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'email'">
                        {{ 'EMAIL' | translate }}
                        <p-sortIcon [field]="'email'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'type'">
                        {{ 'SIGN-TYPE' | translate }}
                        <p-sortIcon [field]="'type'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'federal_id'">
                        {{ 'FEDERAL_ID' | translate }}
                        <p-sortIcon [field]="'federal_id'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'birthday'">
                        {{ 'DATE-BIRTH' | translate }}
                        <p-sortIcon [field]="'birthday'"></p-sortIcon>
                    </th>
                    <th></th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-signer>
                <tr>
                    <td>{{ signer.name }}</td>
                    <td>{{ signer.email }}</td>
                    <td>{{ signer.signerType | translate}}</td>
                    <td>{{ signer.federalId }}</td>
                    <td class="text-center">{{ signer.birthday | date: 'dd/MM/yyyy' }}</td>
                    <td class="text-center">
                        <div class="table-action" (click)="openDefaultSignerForm(signer)" pTooltip="Editar"
                            tooltipPosition="bottom">
                            <i class="pi pi-pencil"></i>
                        </div>
                    </td>
                    <td class="text-center">
                        <div class="table-action" (click)="delete(signer)" pTooltip="Deletar" tooltipPosition="bottom">
                            <i class="pi pi-trash" style="color:  #EF5350"></i>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-fieldset>
