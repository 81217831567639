import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { InvoiceQuotaDTO } from 'src/app/models/dtos/invoiceQuotaDTO';
import { Invoice } from 'src/app/models/invoice';
import { InvoiceService } from 'src/app/services/invoice.service';
import { MessageUtil } from 'src/app/utils/message.util';
import moment from 'moment';
import { InvoiceQuotaParcelDTO } from 'src/app/models/dtos/invoiceQuotaParcelDTO';
import Swal from 'sweetalert2';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-parcel',
  templateUrl: './parcel.component.html',
  styleUrls: ['./parcel.component.scss']
})
export class ParcelComponent implements OnInit {

  invoice: Invoice;
  parcels = 1;
  invoiceQuotaDTO: InvoiceQuotaDTO;
  billingPortfolioList: any[] = [];
  constructor(
    public dialogRef: DynamicDialogRef,
    private messageUtil: MessageUtil,
    private invoiceService: InvoiceService,
    private spinner: NgxSpinnerService,
    private config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.invoice = this.config.data.invoice;
    this.invoiceQuotaDTO = this.config.data.invoiceQuotaDTO;
    this.billingPortfolioList = this.config.data.billingPortfolioList;
    this.createParcels();
  }

  createParcels(): void {
    if (this.parcels < 1) {
      this.parcels = 1;
    }
    const value = Number.parseFloat((this.invoice.pendingValue / this.parcels).toFixed(2));
    this.invoiceQuotaDTO.parcelas = [];
    const dueDate = moment(this.invoice.dueDateToPay, 'YYYY-MM-DD');
    for (let index = 0; index < this.parcels; index++) {
      this.invoiceQuotaDTO.parcelas.push(
        new InvoiceQuotaParcelDTO(
          value,
          index + 1,
          (
            index > 0 ?
              dueDate.add(1, 'months').toDate() :
              (
                dueDate.isBefore(moment()) ?
                  moment().add(1, 'days').toDate() :
                  dueDate.toDate()
              )
          )
        )
      );
      if (index === 0 && dueDate.isBefore(moment())) {
        dueDate.month(moment().month());
      }
    }
    const totalParcels = this.getTotalParcels();
    if (totalParcels !== this.invoice.pendingValue) {
      this.invoiceQuotaDTO.parcelas[this.parcels - 1].valor +=
        Number.parseFloat((this.invoice.pendingValue - totalParcels).toFixed(2));
    }
  }

  parcelInvoice(): void {
    Swal.fire({
      title: this.messageUtil.translateKey('SUMMARY.WARNING'),
      icon: 'warning',
      text: 'Serão gerados ' + this.parcels + ' boletos para a fatura ' + this.invoice.invoiceNumber + '. Deseja continuar?',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      confirmButtonColor: '#3085d6',
      reverseButtons: true,
      cancelButtonColor: '#d33'
    }).then((r) => {
      if (r.value) {
        this.spinner.show();
        this.invoiceService.parcelInvoice(this.invoiceQuotaDTO).subscribe(
          (data) => {
            Swal.fire('Parcelamento de Fatura', 'Fatura parcelada com sucesso', 'success');
            this.dialogRef.close(true);
            this.spinner.hide();
          },
          (err) => {
            this.spinner.hide();
            const exception = err.error.data.exception;
            this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
          }
        );
      }
    });
  }

  getTotalParcels(): number {
    return Number.parseFloat(this.invoiceQuotaDTO.parcelas.reduce((total, parcela) => total + parcela.valor, 0).toFixed(2));
  }

  getMissingValue(): number {
    return this.getTotalParcels() - this.invoice.pendingValue;
  }

  isValid(): boolean {
    return this.getTotalParcels() === this.invoice.pendingValue && this.invoiceQuotaDTO.billingPortfolioId !== undefined
      && this.invoiceQuotaDTO.billingPortfolioId !== null;
  }
}
