import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { Marina, SlingConfig } from 'src/app/models';
import { MarinaCompany } from 'src/app/models/marina-company';
import { PaginationFilter } from 'src/app/models/pagination-filter';
import { BilletService } from 'src/app/services/billet.service';
import { CustomerService } from 'src/app/services/customer.service';
import { FinancesService } from 'src/app/services/finances.service';
import { GroupService } from 'src/app/services/group.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import moment from 'moment';
import { StorageUtil } from 'src/app/utils/storage.util';
import { FormatUtil } from 'src/app/utils/format.util';
import b64toBlob from 'b64-to-blob';
import { ExtractInvoiceComponent } from 'src/app/components/extract-invoice/extract-invoice.component';
import { AnticipationDialogComponent } from './anticipation-dialog/anticipation-dialog.component';
import { Paginator } from 'primeng/paginator';
import { SortEvent } from 'primeng/api';
import { InvoiceBankingBilletTable } from 'src/app/models/invoice-banking-billet-table';
import { MessageUtil } from 'src/app/utils/message.util';

@Component({
  selector: 'app-anticipation',
  templateUrl: './anticipation.component.html',
  styleUrls: ['./anticipation.component.scss']
})
export class AnticipationComponent implements OnInit {

  numberOfRows = 10;
  marinaCompanies: MarinaCompany[];
  marinaCompaniesWithDatabase: MarinaCompany[];
  loggedMarina: Marina;
  filter: any = {
    origin: ['DETACHED', 'SLING', 'TUITION', 'MIXED', 'LEGACY_IMPORT'],
    status: ['PENDING'],
    startDueDate: moment().utc(true).toDate(),
    withBillets: true,
    anticipated: false,
    active: true,
    paid: false
  };
  billets: any[] = new Array();
  billetsTable: any[] = new Array();
  groups: any[] = [];
  customers: any[] = [];
  filteredCustomers: any[] = [];
  filteredCompanies: any[] = [];
  totalBilling = 0;
  totalPending = 0;
  totalPaid = 0;
  totalDiscount = 0;
  invoiceStatus: any[] = ['PAID', 'PENDING'];
  selectedCustomer: any = { id: null };
  selectedCompany: any = null;
  bilingPortifolioList: any[] = [];
  selectedBillets: any[] = [];
  paginationFilter: PaginationFilter = new PaginationFilter();
  today = new Date();
  selectAccount = false;
  globalFilterForm = new UntypedFormControl();
  loading = false;
  disableFlag = false;
  cols: any[];
  uploadStatus: any[];
  files: any[];
  globalFilter = '';
  accounts: Array<{ label: string, value: number }>;
  selectedAccount: number;
  paymentMethods: { label: string; value: number; }[];
  totalRecords = 0;

  slingConfig: SlingConfig;

  public statusList: Array<{ label: string, value: any }> = [
    { label: 'PAID', value: 'PAID' },
    { label: 'PENDING', value: 'PENDING' },
    { label: 'CANCELED', value: 'CANCELED' }
  ];

  public yesNoOptions: Array<{ label: string, value: any }> = [
    { label: 'BOTH', value: null },
    { label: 'YES', value: true },
    { label: 'NO', value: false }
  ];

  constructor(
    private dialog: DialogService,
    private invoiceService: InvoiceService,
    private customerService: CustomerService,
    private groupsService: GroupService,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private messageService: MessageUtil,
    private financesService: FinancesService,
    private slingConfigService: SlingConfigService,
    private billetService: BilletService
  ) {
    this.mountOptions();

    this.cols = [
      { field: 'nfeNumber', header: 'NF' },
      { field: 'invoiceNumber', header: 'Fatura' },
      { field: 'origin', header: 'Origem' },
      { field: 'customer.name', header: 'Cliente' },
      { field: 'groupName', header: 'Grupo' },
      { field: 'emissionDate', header: 'Data' },
      { field: 'dueDate', header: 'Vencimento' },
      { field: 'value', header: 'Total' },
      { field: 'pendingValue', header: 'Pendente' },
      { field: 'discount', header: 'Desconto' },
      { field: 'totalPaid', header: 'Pago' }
    ];
  }

  async ngOnInit(): Promise<void> {
    this.spinner.show();
    this.billets = [];
    this.billetsTable = [];
    await this.getCustomers();
    await this.loadSlingConfig();
    await this.getCompanies();
    await this.getGroups();
    if (this.marinaCompanies.length === 0) {
      await this.findAccounts();
      await this.findPaymentMethods();
      await this.searchBillets();
    }
    this.spinner.hide();
  }

  async getCompanies(): Promise<void> {
    const marina = StorageUtil.getMarina();
    this.marinaCompanies = marina.marinaCompanies;
    this.marinaCompaniesWithDatabase = await this.financesService.findCompaniesWithDatabase();
    if (this.marinaCompanies.length > 0) {
      this.filteredCompanies = this.marinaCompanies.map((c) => ({ label: c.companyFederalName, value: c }));
      this.filteredCompanies.unshift({ label: marina.companyName, value: marina });
      this.filteredCompanies.unshift({ label: 'Selecione', value: null });
      this.selectedCompany = null;
    } else {
      this.selectedCompany = marina;
    }
    this.loggedMarina = StorageUtil.getMarina();
  }

  filterGlobal(): void {
    this.billetsTable = this.billets.filter(
      (i) =>
        (i.nfeNumber && i.nfeNumber.toString().includes(this.globalFilter)) ||
        (i.invoiceNumber && i.invoiceNumber.toString().includes(this.globalFilter)) ||
        (FormatUtil.getNotAccents(i.customer).toUpperCase()
          .includes(FormatUtil.getNotAccents(this.globalFilter).toUpperCase()) ||
          i.customer.toUpperCase().includes(this.globalFilter.toUpperCase())
        ) ||
        (i.group &&
          (FormatUtil.getNotAccents(i.group).toUpperCase()
            .includes(FormatUtil.getNotAccents(this.globalFilter).toUpperCase()) ||
            i.group.toUpperCase().includes(this.globalFilter.toUpperCase()))
        )
    );
  }

  async loadSlingConfig(): Promise<void> {
    return new Promise<void>(
      async (resolve, reject) => {
        this.slingConfigService.getSlingConfig().subscribe(
          async (data) => {
            this.slingConfig = data[0];
            if (this.slingConfig != null) {
              this.numberOfRows = this.slingConfig.numberOfRecordsPerPage;
            } else {
              this.numberOfRows = 10;
            }
            this.paginationFilter.size = this.numberOfRows;
            resolve();
          },
          async () => {
            reject();
          }
        );
      }
    );
  }

  async getCustomers(): Promise<void> {
    return new Promise<void>(
      async (resolve, reject) => {
        this.customerService.getAll().subscribe(
          async (data) => {
            this.customers = data;
            this.filteredCustomers = [{ label: 'Todos os clientes', value: { id: null } }].concat(
              this.customers.map((c) => ({ label: c.name, value: c }))
            );
            resolve();
          },
          async () => {
            reject();
          }
        );
      }
    );
  }

  async getGroups(): Promise<void> {
    return new Promise<void>(
      async (resolve, reject) => {
        this.groupsService.findAllActive().subscribe(
          async (data) => {
            this.groups = data.map((g) => ({ label: g.name, value: g }));
            this.groups.unshift({ label: 'Sem grupo', value: undefined });
            resolve();
          },
          async () => {
            reject();
          }
        );
      }
    );
  }

  async searchBillets(): Promise<void> {
    return new Promise<void>(
      async (resolve, reject) => {
        this.filter.customerId = this.selectedCustomer.id;
        this.filter.accountId = this.selectedAccount;
        if (this.selectedCompany && this.selectedCompany.marinaCompanies) {
          this.filter.onlyMarina = true;
          this.filter.marinaCompanyId = null;
        } else if (this.selectedCompany) {
          this.filter.onlyMarina = false;
          this.filter.marinaCompanyId = this.selectedCompany.id;
        }
        this.selectedBillets = [];
        this.spinner.show();
        this.billetService.findByFilter(this.filter, this.paginationFilter).subscribe(
          async (response) => {
            if (this.selectedCompany) {
              this.billets = response.pageable.content;
              this.totalRecords = response.pageable.totalElements;
              this.totalBilling = response.total;
              this.totalPaid = response.totalPaid;
            }
            this.filterGlobal();
            resolve();
          },
          async () => {
            reject();
          },
          async () => {
            this.spinner.hide();
          }
        );
      }
    );
  }

  getTotalValues(): void {
    this.totalBilling = 0;
    this.totalPaid = 0;
    this.totalDiscount = 0;
    this.totalPending = 0;
    for (const billet of this.billets) {
      this.totalBilling += billet.value;
      if (billet.totalPaid !== null || billet.totalPaid !== false) {
        this.totalPaid += billet.totalPaid;
      }
      this.totalDiscount += billet.discount;
      this.totalPending += billet.pendingValue;
    }
    this.spinner.hide();
  }

  extract(idInvoice): void {
    this.spinner.show();
    this.invoiceService.extract(idInvoice).subscribe(
      (data) => {
        this.openExtract(
          this.sanitizer.bypassSecurityTrustResourceUrl(
            URL.createObjectURL(
              (b64toBlob as any)(data, 'application/pdf')
            )
          )
        );
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title, exception.message);
      },
      () => this.spinner.hide());
  }

  openExtract(path): void {
    const dialog = this.dialog.open(ExtractInvoiceComponent, {
      width: '100%',
      height: '100%',
      dismissableMask: false,
      data: { path }
    });
  }

  clear(): void {
    this.filter = {
      status: ['PENDING'],
      startDueDate: moment().utc(true).toDate(),
      withBillets: true,
      anticipated: false,
      active: true
    };
    this.paginationFilter = {
      page: 0,
      size: this.numberOfRows,
      order: null,
      sort: null
    };
    this.selectedCompany = this.marinaCompanies.length > 0 ? null : this.loggedMarina;
    this.billets = [];
    this.billetsTable = [];
    this.selectedBillets = [];
    this.selectedCustomer = { id: null };
  }

  mountOptions(): void {
    let options = this.yesNoOptions.map((o) => {
      return {
        label: this.messageService.translateKey(o.label),
        value: o.value
      };
    });
    this.yesNoOptions = [...options];
    options = this.statusList.map((o) => {
      return {
        label: this.messageService.translateKey(o.label),
        value: o.value
      };
    });
    this.statusList = [...options];
  }

  async anticipation(): Promise<void> {
    if (!this.selectedAccount) {
      this.messageService.generateMessage('warning', 'Atenção', 'Só é possível antecipar boletos de uma mesma conta.');
      return;
    }
    if (this.selectedBillets.length > 0) {
      let total = 0.0;
      this.selectedBillets.forEach((billet) => {
        total += billet.value;
      });
      const lote = await this.generateLot();

      this.dialog.open(AnticipationDialogComponent, {
        width: '80%',
        height: '90vh',
        dismissableMask: false,
        data: {
          acctouns: this.accounts,
          paymentMethods: this.paymentMethods,
          billets: this.selectedBillets,
          cols: this.cols,
          accountId: this.selectedAccount,
          totalBillets: Number.parseFloat(total.toFixed(2)),
          lote
        },
        header: 'Antecipar boletos - Lote: ' + lote
      }).onClose.subscribe(() => {
        this.searchBillets();
      });
    } else {
      this.messageService.generateMessage('error', 'Nenhum boleto selecionado', 'Não há itens na seleção.');
    }
  }

  async findAccounts(additionalCompany?: MarinaCompany): Promise<void> {
    return new Promise<void>(
      async (resolve, reject) => {
        this.financesService.findContasAtivas(additionalCompany).subscribe(
          async (accounts) => {
            this.selectedAccount = null;
            this.accounts = [{ label: 'Todas as contas', value: null }].concat(
              accounts.map(
                (a) => ({ label: a.nome, value: a.idConta })
              )
            );
            resolve();
          },
          async (err) => {
            this.messageService.generateMessage('error', 'Erro ao buscar contas', err.error);
            reject();
          }
        );
      }
    );
  }

  async findPaymentMethods(additionalCompany?: MarinaCompany): Promise<void> {
    return new Promise<void>(
      async (resolve, reject) => {
        this.financesService.findTipoFormasPagamento(additionalCompany).subscribe(
          async (paymentMethods) => {
            this.paymentMethods = paymentMethods.map((a) => ({ label: a.descricao, value: a.idTipoFormaPagamento }));
            resolve();
          },
          async () => {
            reject();
          }
        );
      }
    );
  }

  totalSelected(): number {
    if (this.selectedBillets && this.selectedBillets.length > 0) {
      let total = 0.0;
      this.selectedBillets.map((i) => total += i.value);
      return total;
    }
    return 0;
  }

  hover(hover: boolean): void {
    this.selectAccount = hover;
  }

  getCompanyIndex(companyId: number): number {
    const m = this.marinaCompanies.find((c) => c.id === companyId);
    return this.marinaCompanies.indexOf(m) + 2;
  }

  async changeCompany(): Promise<void> {
    if(this.selectedCompany == null){
      this.selectedBillets = [];
      this.billets = [];
      this.totalRecords = null;
      this.totalBilling = null;
      this.totalPaid = null;
      return;
    }
    this.spinner.show();
    this.selectedAccount = null;
    await this.findAccounts(this.getMarinaOrCompany());
    await this.findPaymentMethods(this.getMarinaOrCompany());
    await this.searchBillets();
    this.spinner.hide();
  }

  getMarinaOrCompany(): MarinaCompany {
    if (!this.selectedCompany.marinaCompanies && this.marinaCompaniesWithDatabase.find((c) => c.id === this.selectedCompany.id)) {
      return this.selectedCompany;
    }
    return null;
  }

  onChangePage(event: Paginator): void {
    if(this.slingConfig) {
      this.paginationFilter.page = event.first / event.rows;
      this.paginationFilter.size = event.rows;
      this.searchBillets();
    }
  }

  onSort(event: SortEvent): void {
    this.paginationFilter.order = event.order === 1 ? 'ASC' : 'DESC';
    this.paginationFilter.sort = event.field;
    this.searchBillets();
  }

  getAccountName(billet: InvoiceBankingBilletTable): any {
    const account = this.accounts.find((a) => a.value === billet.accountId);
    if (account) {
      return account.label;
    }
    return billet.account;
  }

  async generateLot(): Promise<string> {
    return new Promise<string>(
      async (resolve) => {
        this.billetService.generateAnticipationLot().subscribe(
          async (lot) => {
            resolve(lot);
          },
          async (err) => {
            resolve('');
          }
        );
      }
    );
  }
}

