<p-fieldset>
  <p-header class="article-title">
      <span class="article-title">{{ 'TITLE-OPERATING-DAY-CONFIG' | translate }}</span>
      <div class="header-buttons"></div>
  </p-header>

  <div class="dialog-form">
    <p-tabView>
      <p-tabPanel>
          <ng-template pTemplate="header">
              {{ 'Horários de funcionamento' | translate }}
          </ng-template>
          <app-operating-day></app-operating-day>
      </p-tabPanel>
      <p-tabPanel>
          <ng-template pTemplate="header">
              {{ 'Filas de descida' | translate }}
          </ng-template>
          <app-schedule-queue></app-schedule-queue>
      </p-tabPanel>
      <p-tabPanel>
          <ng-template pTemplate="header">
              {{ 'Dias especiais' | translate }}
          </ng-template>
          <app-special-day-marina></app-special-day-marina>
      </p-tabPanel>
    </p-tabView>
  </div>
</p-fieldset>
