import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-contract-log-modal',
  templateUrl: './contract-log-modal.component.html',
  styleUrls: ['./contract-log-modal.component.scss']
})
export class ContractLogModalComponent implements OnInit {

  contractLog;

  isGeneralData = false;
  isSigners = false;

  constructor(
    private config: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef
  ) { }

  ngOnInit(): void {
    if(!this.config.data.contractLog || !this.config.data.contractLog.oldDatas){
      this.dialogRef.close();
    }
    this.contractLog = this.config.data.contractLog;
    this.createLogs();
  }

  createLogs() {    
    this.verifyChanges();
  }

  verifyChanges() {
    const oldDatasMap = new Map(Object.entries(this.contractLog.oldDatas));
    this.verifyChangesGeneralData(oldDatasMap);
    this.verifyChangesSigners(oldDatasMap);
  }

  verifyChangesGeneralData(oldDatasMap: Map<string, unknown>) {
    this.isGeneralData = oldDatasMap.has('affected') || oldDatasMap.has('url') || oldDatasMap.has('status');
  }

  verifyChangesSigners(oldDatasMap: Map<string, unknown>) {
    this.isSigners = oldDatasMap.has('signers');
  }

}
