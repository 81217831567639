<div class="dialog-form">
    <form (submit)="formValidations() && save()" [formGroup]="userForm">

        <div class="form-group">
            <div class="form-control">
                <label>Id</label>
                <input pInputText type="text" formControlName="id" readonly name="id">
            </div>
            <div class="form-control">
                <label for="username">{{ 'USERNAME' | translate }}*</label>
                <input pInputText type="text" formControlName="username" [readonly]="model.id" (blur)="usernameInUse()"
                    name="username" autocomplete="off">
            </div>
            <div class="form-control" *ngIf="!model?.id || userForm.get('changePassword').value">
                <label for="password">{{ 'PASSWORD' | translate }}*</label>
                <input pInputText type="password" formControlName="password" name="password" maxlength="50">
            </div>
            <div class="form-control" *ngIf="!model?.id || userForm.get('changePassword').value">
                <label for="confirmPassword">{{ 'CONFIRM-PASSWORD' | translate }}*</label>
                <input pInputText type="password" formControlName="confirmPassword" name="confirmPassword"
                    maxlength="50" (blur)="validPassword()">
            </div>
        </div>
        <br>
        <div class="form-group">
            <div class="form-control">
                <label for="firstName">{{ 'FIRST-NAME' | translate }}*</label>
                <input pInputText type="text" formControlName="firstName" name="firstName" maxlength="50">
            </div>
            <div class="form-control">
                <label for="lastName">{{ 'LAST-NAME' | translate }}*</label>
                <input pInputText type="text" formControlName="lastName" name="lastName" maxlength="50">
            </div>
            <div class="form-control">
                <label for="email">{{ 'EMAIL' | translate }}*</label>
                <input pInputText type="email" formControlName="email" name="email" maxlength="100">
            </div>
            <div class="form-control">
                <label for="bracelet">{{ 'BRACELET' | translate }}</label>
                <input pInputText type="text" formControlName="bracelet" name="bracelet" maxlength="50">
            </div>
        </div>
        <br>
        <div class="form-group">
            <div class="form-control" *ngIf="model.id">
                <label for="changePassword">{{ 'CHANGE-PASSWORD' | translate }}</label>
                <p-inputSwitch formControlName="changePassword" name="changePassword" (onChange)="changePass($event)">
                </p-inputSwitch>
            </div>
            <div class="form-control">
                <label for="active">{{ 'ACTIVE' | translate }}</label>
                <p-inputSwitch formControlName="active" name="active">
                </p-inputSwitch>
            </div>
            <div class="form-control">
                <label for="role">{{ 'Perfil de Usuário' | translate }}</label>
                <p-dropdown placeholder="Selecione..." [filter]="true" [options]="rolesList" appendTo="body"
                    formControlName="roleId" name="roleId">
                </p-dropdown>
            </div>
        </div>
        <br>
        <div class="form-group align-center">
            <div class="form-control">
                {{'REQUIRED-FIELDS' | translate}}
            </div>
            <div class="buttons">
                <button class="easymarine danger" type="button"
                    (click)="dialogRef.close()">{{ 'CANCEL' | translate }}</button>
                <button class="easymarine primary" type="submit">{{ 'SAVE' | translate }}</button>
            </div>
        </div>
    </form>
</div>