<p-fieldset>
    <p-header class="article-title">
      <div class="article-title">{{ "Operador" | translate }}</div>
    </p-header>
    <div class="content-log">
      <div>
        <label>Operador responsável</label>
        <label [ngClass]="{'item-new-log': isNew, 'item-old-log': !isNew}">
            {{ getName() }}
        </label>
      </div>
    </div>
  </p-fieldset>
  <br />