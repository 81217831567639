<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{'TITLE-ACCESS-GROUP' | translate}}</span>
        <div class="primary fab-button" (click)="openDialog(null)">
            <i class="pi pi-plus" style="font-size: 21px;"></i>
        </div>
    </p-header>

    <div class="table">
        <p-table #tt [value]="groupsTable" resetPageOnSort="true"
            [globalFilterFields]="['id','name','active']" styleClass="easy-table">
            <ng-template pTemplate="caption">

                <p-tabView (onChange)="changeGroupStatus($event)">
                    <p-tabPanel>
                        <ng-template pTemplate="header">Todos</ng-template>
                    </p-tabPanel>
                    <p-tabPanel>
                        <ng-template pTemplate="header">Ativo</ng-template>
                    </p-tabPanel>
                    <p-tabPanel>
                        <ng-template pTemplate="header">Inativo</ng-template>
                    </p-tabPanel>
                </p-tabView>

                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText size="50" placeholder="Filtro por nome" [(ngModel)]="globalFilter"
                        (input)="filterGlobal()">
                </span>
                <span style="font-weight: bold; float: right; margin-top: 5px;">{{ 'TOTAL-REGISTERS' | translate }}:
                    {{tt.totalRecords}} {{'OF' | translate}} {{groupsTable.length}}
                </span>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th class="text-center" [pSortableColumn]="'id'">
                        Id
                        <p-sortIcon [field]="'id'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'name'">
                        Nome
                        <p-sortIcon [field]="'name'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'qtyCustomers'">
                        Tipo
                        <p-sortIcon [field]="'qtyCustomers'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'activeQuantity'">
                        Qtd. Ativos
                        <p-sortIcon [field]="'activeQuantity'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'inactiveQuantity'">
                        Qtd. Inativos
                        <p-sortIcon [field]="'inactiveQuantity'"></p-sortIcon>
                    </th>

                    <th class="text-center" [pSortableColumn]="'active'">
                        Status
                        <p-sortIcon [field]="'active'"></p-sortIcon>
                    </th>
                    <th colspan="3"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-group>
                <tr>
                    <td class="text-center">{{ group.id != 0 ? group.id : '' }}</td>
                    <td [pTooltip]="group.description">{{ group.name }}</td>
                    <td class="text-center">{{ getGroupTranslation(group.type) }}</td>
                    <td>{{group.activeQuantity ?? 0}}</td>
                    <td>{{group.inactiveQuantity ?? 0}}</td>
                    <td class="text-center">
                        <span *ngIf="group.active" class="badge badge-pill success" style="cursor: default;">{{ 'ACTIVE' | translate }}</span>
                        <span *ngIf="!group.active && group.id != 0"
                            class="badge badge-pill danger" style="cursor: default;">{{ 'INACTIVE' | translate }}</span>
                    </td>
                    <td class="text-center">
                        <div pTooltip="Editar" (click)="edit(group)" tooltipPosition="bottom"
                            [disabled]="group.id === 0" class="table-action">
                            <i class="pi pi-pencil"></i>
                        </div>
                    </td>
                    <td class="text-center">
                        <div pTooltip="Editar" [routerLink]="['../access-group/batch', group.id]" tooltipPosition="bottom"
                            [disabled]="group.id === 0" class="table-action">
                            <i class="pi pi-users"></i>
                        </div>
                    </td>
                    <td class="text-center">
                        <div pTooltip="Deletar" (click)="deleteConfirm(group)" tooltipPosition="bottom"
                            class="table-action">
                            <i class="pi pi-trash" style="color: #EF5350"></i>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-fieldset>
