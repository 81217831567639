import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-boat-contract',
  templateUrl: './boat-contract.component.html',
  styleUrls: ['./boat-contract.component.scss']
})
export class BoatContractComponent implements OnInit {

  public path: any;
  constructor(
    public dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {
    this.path = this.config.data.path;
  }

  ngOnInit(): void {
  }

}
