import { Marina } from "../marina";
import { User } from "../user";

export class UserAccessBanks {
  id: number;
  user: User;
  marina: Marina;
  bankId: number;
  billetCreate: boolean;
  billetCancel: boolean;
  pixCreate: boolean;

}
