import {
  OnInit,
  Component,
  ElementRef,
  ViewChild, OnDestroy,
} from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import {NgForOf, NgIf} from "@angular/common";

@Component({
  standalone: true,
  selector: 'app-photo-capture',
  templateUrl: './photo-capture.component.html',
  styleUrls: ['./photo-capture.component.scss'],
  imports: [
    NgIf,
    NgForOf
  ]
})
export class PhotoCaptureComponent implements OnInit, OnDestroy {
  constructor(
    public dialog: DialogService,
    public dialogRef: DynamicDialogRef
  ) {}

  ngOnInit(): void {}

  WIDTH = 367;
  HEIGHT = 480;

  @ViewChild('video')
  public video: ElementRef;

  @ViewChild('canvas')
  public canvas: ElementRef;

  captures: string[] = [];
  error: any;
  isCaptured: boolean;

  selectImageUrl: any;
  stream: MediaStream

  async ngAfterViewInit() {
    await this.setupDevices();
  }

  async setupDevices() {
    console.log("Chegou aqui")
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      try {
        this.stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (this.stream) {
          this.video.nativeElement.srcObject = this.stream;
          this.video.nativeElement.play();
          this.error = null;
        } else {
          this.error = 'You have no output video device';
        }
      } catch (e) {
        this.error = e;
      }
    }
  }

  onMetadataLoaded(event: any) {
    // Definir as dimensões após o carregamento dos metadados
    this.video.nativeElement.width = this.WIDTH;
    this.video.nativeElement.height = this.HEIGHT;
  }

  capture() {
    this.drawImageToCanvas(this.video.nativeElement);

    // if (
    //   this.video.nativeElement.videoWidth > 700 ||
    //   this.video.nativeElement.videoHeight > 1200
    // ) {
    //   // Redimensionar a resolução se exceder os limites
    //   const canvas = document.createElement('canvas');
    //   const context = canvas.getContext('2d');

    //   // Defina as dimensões do canvas para a resolução desejada
    //   canvas.width = this.WIDTH; // Largura desejada
    //   canvas.height = this.HEIGHT; // Altura desejada

    //   context.drawImage(
    //     this.video.nativeElement,
    //     0,
    //     0,
    //     canvas.width,
    //     canvas.height
    //   );
    //   const base64Image = canvas.toDataURL('image/png');
    // }

    // if (
    //   this.video.nativeElement.videoHeight >
    //   2 * this.video.nativeElement.videoWidth
    // ) {
    //   alert('A altura não deve exceder duas vezes a largura.');
    //   return;
    // }

    // const imageData = this.canvas.nativeElement.toDataURL('image/jpeg', 0.9); // Qualidade de 80%

    // const fileSize = this.getFileSize(imageData);
    // if (fileSize > 100 * 1024) {
    //   alert('O tamanho do arquivo não deve exceder 100KB.');
    //   return;
    // }
     const imageData = this.canvas.nativeElement.toDataURL('image/jpeg', 0.9); // Qualidade de 80%
    this.captures.push(imageData);
    this.selectImageUrl =  imageData;
    this.isCaptured = true;
  }

  getFileSize(imageData: string): number {
    const base64Data = imageData.split(',')[1]; // Remover cabeçalho
    const cleanedBase64 = base64Data.replace(/[^A-Za-z0-9+/]/g, ''); // Remover caracteres não base64
    const binaryString = atob(cleanedBase64);
    const fileSize = binaryString.length;
    return fileSize;
  }

  removeCurrent() {
    this.isCaptured = false;
  }

  setPhoto(idx: number) {
    this.isCaptured = true;
    var image = new Image();
    image.src = this.captures[idx];
    this.drawImageToCanvasShow(image);
    this.selectImageUrl = image.src;
  }

  drawImageToCanvas(image: any) {
    this.canvas.nativeElement
      .getContext('2d')
      .drawImage(image,-136, 0);
  }

  drawImageToCanvasShow(image: any) {
    this.canvas.nativeElement
      .getContext('2d')
      .drawImage(image,0, 0);
  }

  selecionar() {
    this.dialogRef.close(this.selectImageUrl);
  }
  stopBothVideoAndAudio(stream) {
    stream.getTracks().forEach((track) => {
      if (track.readyState == 'live') {
        track.stop();
      }
    });
  }

  ngOnDestroy(): void {
    console.log("Destruiu")
    this.stopBothVideoAndAudio(this.stream)
  }

}
