import { Component } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { AccessGroupEnum } from 'src/app/models/dtos/access-controller/access-group-enum';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { ServiceProviderFormsContainerComponent } from '../service-provider-forms-container/service-provider-forms-container.component';

@Component({
  selector: 'app-service-provider-center',
  templateUrl: './service-provider-center.component.html',
  styleUrls: ['./service-provider-center.component.scss'],
})
export class ServiceProviderCenterComponent {
  constructor(public dialog: DialogService) {}

  newVisitorAndPermission(): void {
    this.dialog
      .open(ServiceProviderFormsContainerComponent, {
        width: '1050px',
        height: '950px',
        header: 'Cadastro de Prestador de Serviço e Permissão de Acesso',
        data: {
          visitorType: AccessGroupEnum.SERVICE_PROVIDER,
        },
      })
      .onClose.subscribe((status) => {
        if (status) {
          this.reloadTables();
        }
      });
  }

  reloadTables() {
    BroadcastService.get('reloadTables').emit();
  }
}
