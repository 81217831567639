import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-barcode-icon',
  templateUrl: './barcode-icon.component.html',
  styleUrls: ['./barcode-icon.component.scss']
})
export class BarcodeIconComponent implements OnInit{
  @Input() styleBillet: 'registered' | 'refused' | 'pending' | 'waiting' | 'paid' | 'installments'| 'canceled' | 'registeredN' | 'empty' = 'registered';
  @Input() isNix: true | false =  false;
  @Input() isShared: true | false = false;
  @Input() toolTipMsg: string = '';
  iconPath: string = '';
  iconDescription: string = '';

  constructor() { }

  ngOnInit(): void {
    this.iconPath = this.getBarcodeIcon();
    this.iconDescription = this.getBarcodeIconDescription();
  }

  getBarcodeIcon(): string {
    switch (this.styleBillet) {
      case 'registered':
        return this.isNix ? (this.isShared ? '../../../../assets/icons/barcode-icons/barcode-registered-nix-shared.svg' : '../../../../assets/icons/barcode-icons/barcode-registered-nix.svg') : (this.isShared ? '../../../../assets/icons/barcode-icons/barcode-registered-shared.svg' : '../../../../assets/icons/barcode-icons/barcode-registered.svg');
      case 'refused':
        return this.isNix ? (this.isShared ? '../../../../assets/icons/barcode-icons/barcode-refused-nix-shared.svg' : '../../../../assets/icons/barcode-icons/barcode-refused-nix.svg') : (this.isShared ? '../../../../assets/icons/barcode-icons/barcode-refused-shared.svg' : '../../../../assets/icons/barcode-icons/barcode-refused.svg');
      case 'pending':
        return this.isNix ? (this.isShared ? '../../../../assets/icons/barcode-icons/barcode-pending-nix-shared.svg' : '../../../../assets/icons/barcode-icons/barcode-pending-nix.svg') : (this.isShared ? '../../../../assets/icons/barcode-icons/barcode-pending-shared.svg' : '../../../../assets/icons/barcode-icons/barcode-pending.svg');
      case 'waiting':
        return this.isNix ? (this.isShared ? '../../../../assets/icons/barcode-icons/barcode-waiting-nix-shared.svg' : '../../../../assets/icons/barcode-icons/barcode-waiting-nix.svg') : (this.isShared ? '../../../../assets/icons/barcode-icons/barcode-waiting-shared.svg' : '../../../../assets/icons/barcode-icons/barcode-waiting.svg');
      case 'paid':
        return this.isNix ? (this.isShared ? '../../../../assets/icons/barcode-icons/barcode-paid-nix-shared.svg' : '../../../../assets/icons/barcode-icons/barcode-paid-nix.svg') : (this.isShared ? '../../../../assets/icons/barcode-icons/barcode-paid-shared.svg' : '../../../../assets/icons/barcode-icons/barcode-paid.svg');
      case 'installments':
        return this.isNix ? (this.isShared ? '../../../../assets/icons/barcode-icons/barcode-installments-nix-shared.svg' : '../../../../assets/icons/barcode-icons/barcode-installments-nix.svg') : '../../../../assets/icons/barcode-icons/barcode-installments.svg';
      case 'canceled':
        return this.isNix ? (this.isShared ? '../../../../assets/icons/barcode-icons/barcode-canceled-nix-shared.svg' : '../../../../assets/icons/barcode-icons/barcode-canceled-nix.svg') : (this.isShared ? '../../../../assets/icons/barcode-icons/barcode-canceled-shared.svg' : '../../../../assets/icons/barcode-icons/barcode-canceled.svg');
      case 'empty':
        return '../../../../assets/icons/barcode-icons/barcode-empty.svg';
        case 'registeredN':
        return '../../../../assets/icons/barcode-icons/barcode-registered-N-nix.svg';
      default:
        return '';
    }
  }

  getBarcodeIconDescription(): string {
    if(this.toolTipMsg !== '') return this.toolTipMsg;
    switch (this.styleBillet) {
      case 'registered':
        return this.isNix ? (this.isShared ? 'Boleto Nix Agrupado Registrado' : 'Boleto Nix Registrado') : (this.isShared ? 'Boleto Agrupado Registrado' : 'Boleto Registrado');
      case 'refused':
        return this.isNix ? (this.isShared ? 'Boleto Nix Agrupado Recusado' : 'Boleto Nix Recusado') : (this.isShared ? 'Boleto Agrupado Recusado' : 'Boleto Recusado');
      case 'pending':
        return this.isNix ? (this.isShared ? 'Boleto Nix Agrupado Pendente' : 'Boleto Nix Pendente') : (this.isShared ? 'Boleto Agrupado Pendente' : 'Boleto Pendente');
      case 'waiting':
        return this.isNix ? (this.isShared ? 'Boleto Nix Agrupado Aguardando' : 'Boleto Nix Aguardando') : (this.isShared ? 'Boleto Agrupado Aguardando' : 'Boleto Aguardando');
      case 'paid':
        return this.isNix ? (this.isShared ? 'Boleto Nix Agrupado Pago' : 'Boleto Nix Pago') : (this.isShared ? 'Boleto Agrupado Pago' : 'Boleto Pago');
      case 'installments':
        return this.isNix ? (this.isShared ? 'Boleto Nix Agrupado Parcelado' : 'Boleto Nix Parcelado') : 'Boleto Parcelado';
      case 'canceled':
        return this.isNix ? (this.isShared ? 'Boleto Nix Agrupado Cancelado' : 'Boleto Nix Cancelado') : (this.isShared ? 'Boleto Agrupado Cancelado' : 'Boleto Cancelado');
        case 'registeredN':
          return 'Vários Boletos';
      case 'empty':
        return 'Fatura paga sem boleto';
      default:
        return '';
    }
  }
}
