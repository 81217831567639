import { Component, Input, Output, EventEmitter } from "@angular/core";
import { MenuItem } from 'primeng/api';
import { Object } from 'fabric/fabric-impl';
import { MapUtilService } from "src/app/services/map-util.service";

@Component({
    selector: "berth-context-menu",
    templateUrl: "./berth-context-menu.component.html",
    styleUrls: ["./berth-context-menu.component.scss"],
  })
  export class BerthContextMenuComponent {
    
    @Input()
    fabricObj: Object;
    aCoords: any;

    pixel_scale_m?: number;



    @Output()
    onContextMenuItemClick: EventEmitter<any> = new EventEmitter<any>();


    constructor(private mapUtilService:MapUtilService) {}
  
    onContextMenuClick(data): any {
      this.onContextMenuItemClick.emit({
        event,data        
      });
    }

    items: MenuItem[];
    ngOnInit() {   
      this.pixel_scale_m = this.mapUtilService.pixel_scale_m;     
      this.aCoords = JSON.stringify(this.fabricObj.aCoords);
        this.items = [{
            label: 'Editar',
            items: [{
                label: 'Duplicar Direita',
                icon: 'pi pi-angle-double-right',
                command: () => {
                    this.onContextMenuClick({'action': 'clone-right','fabricObj': this.fabricObj});
                }
            },
            {
              label: 'Duplicar Esquerda',
              icon: 'pi pi-angle-double-left',
              command: () => {
                this.onContextMenuClick({'action': 'clone-left','fabricObj': this.fabricObj});
              }
            },
            {
              label: 'Excluir',
              icon: 'pi pi-trash',
              command: () => {
                this.onContextMenuClick({'action': 'delete','fabricObj': this.fabricObj});
              }
            },  
            {
              label: 'Modificar',
              icon: 'pi pi-pencil',
              command: () => {
                this.onContextMenuClick({'action': 'change','fabricObj': this.fabricObj});
              }
            },
            {
              label: 'Info',
              icon: 'pi pi-info-circle',
              command: () => {
                this.onContextMenuClick({'action': 'info','fabricObj': this.fabricObj});
              }
            }              

            ]}
        ];
    }


  }
  