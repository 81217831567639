import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectItem } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Marina } from 'src/app/models';
import { DocumentContractView } from 'src/app/models/dtos/document-contract-view';
import { SignerDocumentResquestDTO } from 'src/app/models/dtos/signer-document-requestDTO';
import { SignerType } from 'src/app/models/enums/signerType';
import { DocumentSignerService } from 'src/app/services/document-signer.service';
import { ValidatorsUtil } from 'src/app/services/validators.util';
import { MessageUtil } from 'src/app/utils/message.util';

@Component({
  selector: 'app-signer-contract-form',
  templateUrl: './signer-contract-form.component.html',
  styleUrls: ['./signer-contract-form.component.scss']
})
export class SignerContractFormComponent implements OnInit {

  signerContractForm: UntypedFormGroup;
  typeList: SelectItem[] = [];

  document: DocumentContractView
  marina: Marina;
  existCPF = false;

  constructor(
    private messageUtil: MessageUtil,
    public dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private validatorsUtil: ValidatorsUtil,
    private spinner: NgxSpinnerService,
    private documentSignerService: DocumentSignerService,
  ) { }

  ngOnInit(): void {
    this.getSignerType();
    this.startForm();
    if (this.config.data.marina && this.config.data.document) {
      this.document = this.config.data.document;
      this.marina = this.config.data.marina;
    } else {
      return;
    }
  }

  startForm(): void {
    this.signerContractForm = new UntypedFormGroup({
      email: new UntypedFormControl('', Validators.required),
      signerType: new UntypedFormControl('', Validators.required),
      name: new UntypedFormControl('', Validators.required),
      federalId: new UntypedFormControl('', Validators.required),
      birthday: new UntypedFormControl('', Validators.required)
    });
  }

  getSignerType(): void {
    this.typeList = [
      { label: this.messageUtil.translateKey(SignerType.SIGNER_EXTERNAL), value: SignerType.SIGNER_EXTERNAL },
      { label: this.messageUtil.translateKey(SignerType.WITNESS), value: SignerType.WITNESS }
    ]
  }

  save() {
    debugger
    this.newSigner();
  }

  async newSigner(): Promise<void> {
    const signer = new SignerDocumentResquestDTO(this.document.id, this.marina, this.signerContractForm.get('email').value, this.signerContractForm.get('name').value, this.signerContractForm.get('federalId').value, this.signerContractForm.get('birthday').value, this.signerContractForm.get('signerType').value);
    await this.spinner.show();
    this.documentSignerService.create(signer)
      .subscribe((data) => {
        this.spinner.hide();
        this.messageUtil.generateMessage('success', 'SUMMARY.SUCCESS', 'Assinante cadastrado com sucesso.');
        this.dialogRef.close(true);
      }, async (error) => {
        this.spinner.hide();
        this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', 'Não foi possivel cadastrar assinante.');
      });
  }

  checkFederalIdField(): void {
    this.validateFederalId();
  }

  validateFederalId(): boolean {
    let validFederalId = this.validatorsUtil.cpfCnpjValidator(this.signerContractForm.get('federalId').value);
    if (!validFederalId) {
      this.signerContractForm.get('federalId').reset();
      this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', 'INVALID-FEDERAL-ID');
    }
    return validFederalId;
  }

  close() {
    this.dialogRef.close();
  }

}
