<p-multiSelect [options]="options"
  appendTo="body" selectedItemsLabel="{0} itens selecionados"
  [(ngModel)]="filterActive" (onChange)="sendChanges($event)">

  <ng-template let-items pTemplate="selectedItems">
    <span *ngFor="let item of items; let i = index;"> <span *ngIf="i !== 0"> , </span>{{ translateKey + getItem(item) | translate }} </span>
  </ng-template>
  <ng-template let-itemStatus pTemplate="item">
    <span>{{ translateKey + itemStatus.label | translate }}</span>
  </ng-template>
</p-multiSelect>
