export class CustomerFilter {
    public idMarina?: number;
	public active?: boolean;    
    public customerIds?: number[];
	public fromServiceStart?: Date | string;
	public toServiceStart?: Date | string;
	public fromServiceEnd?: Date | string;
	public toServiceEnd?: Date | string;
	public serviceStatus?: boolean;
	public serviceInEffect?: boolean;

    constructor() {
        this.serviceInEffect = true;
    }
}