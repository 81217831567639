import { StockMovementService } from 'src/app/services/stock/stock-movement.service';
import { StockMovement } from 'src/app/models/stock/stock-movement';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { StockMovementType } from 'src/app/models/stock/stock-movement-type';
import { SelectItem } from 'primeng/api';
import { CustomerService } from 'src/app/services/customer.service';
import { FinancesService } from 'src/app/services/finances.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { MovementTypeService } from 'src/app/services/stock/movement-type.service';
import { Product } from 'src/app/models';
import { ProductService } from 'src/app/services/product.service';
import { ProductType } from 'src/app/models/enums';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-stock-movement-form',
  templateUrl: './stock-movement-form.component.html',
  styleUrls: ['./stock-movement-form.component.scss'],
})
export class StockMovementFormComponent implements OnInit {
  stockMovement: StockMovement = new StockMovement();
  stockMovementForm: FormGroup;
  supplierList: SelectItem[] = [];
  customerList: SelectItem[] = [];
  stockMovementTypesList: any[] = [];
  selectedProduct: Product = new Product();
  productList: SelectItem[] = [];
  quantity: number = 0;
  price: number = 0;
  discount: number = 0;
  stockNow: number = 0;
  stockNew: number = 0;
  total: number = 0;
  labelProduct: string = 'Produto';

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private config: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
    private financesService: FinancesService,
    private customerService: CustomerService,
    private messageUtil: MessageUtil,
    private movementTypeService: MovementTypeService,
    private stockMovementService: StockMovementService,
    private productService: ProductService
  ) {}

  ngOnInit(): void {
    this.getCustomers();
    this.loadSupplierList();
    this.getmovements();
    this.startform();
    this.loadProduct();
    if (history.state.stockMovement) {
      this.stockMovement = history.state.stockMovement;
      this.fillFormWithStockMovement(this.stockMovement);
      this.stockMovementForm
        .get('createAt')
        .setValue(new Date(this.stockMovement.createAt));
      this.stockMovementForm.disable();
      this.stockMovementItems.disable();
    } else {
      const currentDate = new Date();
      const formattedDate = this.formatDate(currentDate);
      this.stockMovementForm.patchValue({ createAt: new Date() });
      this.stockMovementForm.patchValue({ competenceDate: new Date() });
      this.stockMovementForm.patchValue({ active: true });
      this.stockMovementForm.patchValue({ employeeName: 'Teste' });
    }
    this.stockMovementForm.get('createAt')?.disable();
  }

  formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Mês começa em 0
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
  }

  loadProduct(): void {
    this.productService.getByProductType(ProductType.Product).subscribe({
      next: (data) => {
        this.productList = data
          .filter((s) => s.active)
          .map((p) => ({ label: p.name, value: p }));
      },
      error: (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
    });
  }

  loadSupplierList(): void {
    this.financesService.findFornecedor().subscribe({
      next: (data) => {
        this.supplierList = [{ label: 'Selecione', value: null }].concat(
          data.map((p) => ({ label: p.nomeFantasia, value: p.idFornecedor }))
        );
      },
      error: (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
    });
  }

  getCustomers(): void {
    this.customerService.getAll('id,name').subscribe({
      next: (data) => {
        this.customerList = [{ label: 'Selecione', value: null }].concat(
          data.map((c) => ({ label: c.name, value: c.id }))
        );
      },
      error: (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
    });
  }

  getmovements(): void {
    this.movementTypeService.getAll().subscribe((data) => {
      this.stockMovementTypesList = data
        .filter((x) => x.active === true)
        .map((x) => {
          return { label: x.name, value: x, type: x.type };
        });
    });
  }

  startform() {
    this.stockMovementForm = this.formBuilder.group({
      id: [null as number | null],
      createAt: [null as Date | null, Validators.required],
      competenceDate: [null as Date | null, Validators.required],
      active: [true],
      cancelAt: [null as Date | null],
      employeeName: [null as string | null],
      updateAt: [null as Date | null],
      stockMovementType: [
        null as StockMovementType | null,
        Validators.required,
      ],
      marinaOrderId: [null as number | null],
      buyOrderId: [null as number | null],
      customerId: [null as number | null],
      supplierId: [null as number | null],
      customerName: [null as string | null],
      supplierName: [null as string | null],
      automatic: [false],
      marinaId: [0 as number | null, Validators.required],
      totalItem: [0],
      movementValue: [0],
      stockMovementItems: this.formBuilder.array([]),
    });
  }

  get stockMovementItems(): FormArray {
    return this.stockMovementForm.get('stockMovementItems') as FormArray;
  }

  // Método para adicionar um novo item ao FormArray
  addStockMovementItem() {
    const newItem = this.formBuilder.group({
      id: [null as number | null],
      stockMovement: [null],
      productId: [this.selectedProduct.id as number | null],
      productName: [this.selectedProduct.name as string | null],
      productType: [this.selectedProduct.productType as string | null],
      productValue: [this.price],
      amount: [this.quantity],
      discount: [this.discount],
      total: [this.total],
      stockAfterMovement: [this.stockNew],
      active: [true],
    });

    this.stockMovementItems.push(newItem);
  }

  fillFormWithStockMovement(stockMovement: StockMovement) {
    this.stockMovementForm.patchValue(stockMovement);
    for (let i = 0; i < stockMovement.stockMovementItems.length; i++) {
      const item = stockMovement.stockMovementItems[i];
      this.selectedProduct.id = item.productId;
      this.selectedProduct.name = item.productName;
      this.selectedProduct.productCategory.name = item.productType;
      this.price = item.productValue;
      this.quantity = item.amount;
      this.discount = item.discount;
      this.total = item.total;
      this.stockNow = item.stockAfterMovement;
      this.stockNew = item.stockAfterMovement;
      this.addStockMovementItem();
    }
  }

  save(): void {
    Swal.fire({
      title: 'Após salvar não será possível alterar ou excluir o registro. Deseja continuar?',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      icon: 'warning',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      cancelButtonColor: '#d33',
      allowOutsideClick: false,
      preConfirm: () => {
        this.onTotalChange();
        this.stockMovementForm.get('createAt')?.enable();
        const formData: StockMovement = this.stockMovementForm.value;

        if (formData.stockMovementType.type === 'IN') {
          formData.supplierName = this.supplierList.find(
            (s) => s.value === formData.supplierId
          ).label;
          formData.customerId = null;
        }
        if (formData.stockMovementType.type === 'OUT') {
          formData.customerName = this.customerList.find(
            (c) => c.value === formData.customerId
          ).label;
          formData.supplierId = null;
        }

        if (formData.stockMovementItems.length === 0) {
          this.messageUtil.generateMessage(
            'warning',
            'Atenção',
            'Adicione pelo menos um item'
          );
          return;
        }

        if (formData.customerId === null && formData.supplierId === null) {
          this.messageUtil.generateMessage(
            'warning',
            'Atenção',
            'Selecione um cliente ou fornecedor'
          );
          return;
        }

        if (formData.id === null) {
          this.stockMovementService.save(formData).subscribe({
            next: () => {
              this.return();
            },
            error: (e) => {
              this.messageUtil.generateMessage(
                'warning',
                'Atenção',
                e.error.data.exception.message
              );
            },
          });
        } else {
          this.stockMovementService.update(formData).subscribe({
            next: () => {
              this.return();
            },
            error: () => {
              this.messageUtil.generateMessage(
                'warning',
                'Atenção',
                'Erro ao salvar alteração'
              );
            },
          });
        }
      },
    });
  }

  onChangeProduct(event: any) {
    this.price = event.value.price.value;
    this.stockNow = event.value.stock;
    this.onTotalChange();
    this.getTotal();
  }

  onTotalChange(): void {
    if (this.quantity < 0) {
      this.quantity = 0;
    }
    if (this.price < 0) {
      this.price = 0;
    }
    if (this.discount < 0) {
      this.discount = 0;
    }
    this.total = this.quantity * this.price - this.discount;
    if (this.stockMovementForm.get('stockMovementType')?.value.type === 'IN') {
      this.stockNew = this.stockNow + this.quantity;
    }
    if (this.stockMovementForm.get('stockMovementType')?.value.type === 'OUT') {
      this.stockNew = this.stockNow - this.quantity;
    }
    this.getTotal();
  }

  addItem(): void {
    if (this.productIdExists(this.selectedProduct.id)) {
      this.messageUtil.generateMessage(
        'warning',
        'Atenção',
        'Produto já adicionado'
      );
      return;
    }
    if (this.quantity <= 0) {
      this.messageUtil.generateMessage(
        'warning',
        'Atenção',
        'Quantidade não pode ser 0'
      );
      return;
    }
    this.addStockMovementItem();
    this.getTotal();
  }

  productIdExists(productId: number): boolean {
    const stockMovementItemsArray = this.stockMovementForm.get(
      'stockMovementItems'
    ) as FormArray;

    for (let i = 0; i < stockMovementItemsArray.length; i++) {
      const item = stockMovementItemsArray.at(i) as FormGroup;
      const itemProductId = item.get('productId')?.value;

      if (itemProductId === productId) {
        return true;
      }
    }

    return false;
  }

  removeItem(item: number): void {
    this.stockMovementItems.removeAt(item);
    this.getTotal();
  }

  onLineTotalChange(item): void {
    const product: Product = this.productList.find(
      (p) => p.value.id === item.productId
    ).value;
    item.total = item.amount * item.productValue - item.discount;
    let newBalance: number = 0;
    if (this.stockMovementForm.get('stockMovementType')?.value.type === 'IN') {
      newBalance = product.stock + item.amount;
    }
    if (this.stockMovementForm.get('stockMovementType')?.value.type === 'OUT') {
      newBalance = product.stock - item.amount;
    }
    item.stockAfterMovement = newBalance;
    this.getTotal();
  }

  getTotal(): void {
    let stockm: any[] = this.stockMovementForm.get('stockMovementItems').value;
    let total = 0;
    for (let i = 0; i < stockm.length; i++) {
      const item = stockm[i];
      console.log(item);
      const itemAmount = item.amount;
      const itemTotal = item.total;
      if (itemAmount && itemTotal) {
        total += itemTotal;
      }
    }
    this.stockMovementForm.patchValue({ movementValue: total });
    this.stockMovementForm.patchValue({
      totalItem: this.stockMovementItems.length,
    });
  }

  return(): void {
    this.router.navigate(['/app/stock/stock-movement'], {
      state: {},
    });
  }
}
