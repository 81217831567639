<p-toolbar>
  <div class="p-toolbar-group-left">
    <p-selectButton [style]="{'font-size':'12px'}" [options]="editModes" [multiple]="false" (onChange)="editModeChange($event)"	[(ngModel)]="selectedEditMode" ></p-selectButton>
    &nbsp; &nbsp; &nbsp;
    <i class="pi pi-bars"></i>
    &nbsp; &nbsp; &nbsp;
    <p-button icon="pi pi-sync " (onClick)="reloadStatus()" [disabled]="refreshButtonDisabled" ></p-button><br>
    
  </div>
  <div class="p-toolbar-group-right">
    <!-- p-button label="Veleiro" (onClick)="addBoatImage('recones/Veleiro-1.svg')"></p-button><br>
    <p-button label="Lancha" (onClick)="addBoatImage('recones/Lancha-4.svg')"></p-button><br-->   
    <p-button label="Salvar" (onClick)="saveMap()" [disabled]="saveButtonDisabled"></p-button><br>
    

  </div>  

</p-toolbar>


<canvas id="canvas" #canvas width="1600" height="1017" class="canvas-style" ></canvas>

<!-- div class="grid">
  <div class="col-12"></div>
</div-->

  <div (contextmenu)="false">
    <map-context-menu
      *ngIf="isDisplayMenuMap && selectedEditMode == 'ED'"
      [ngStyle]="getRightClickMenuStyle()"     
      (onContextMenuItemClick)="handleMenuMapClick($event)"
    ></map-context-menu>
  </div>


  <div (contextmenu)="false">
    <berth-context-menu
      *ngIf="isDisplayBerthContextMenu && selectedEditMode == 'ED'"
      [ngStyle]="getRightClickMenuStyle()" 
      [fabricObj]="rightClickedFabricObj"    
      (onContextMenuItemClick)="handleBerthContextMenuClick($event)"
    ></berth-context-menu>
  </div>


  <div (contextmenu)="false">
    <berth-operation-menu
      *ngIf="isDisplayBerthContextMenu && selectedEditMode == 'OP'"
      [ngStyle]="getRightClickMenuStyle()" 
      [fabricObj]="rightClickedFabricObj"    
      (onContextMenuItemClick)="handleBerthOperationMenuClick($event)"
    ></berth-operation-menu>
  </div>




    
