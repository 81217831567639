import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent, SelectItem } from 'primeng/api';
import { StockMovementFilter } from 'src/app/models/dtos/stock/stock-movement-filter';
import { StockMovementService } from 'src/app/services/stock/stock-movement.service';

import { NgxSpinnerService } from 'ngx-spinner';

import { CustomerService } from 'src/app/services/customer.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { Router } from '@angular/router';
import { MovementTypeService } from 'src/app/services/stock/movement-type.service';
import { StockMovement } from 'src/app/models/stock/stock-movement';
import { FinancesService } from 'src/app/services/finances.service';
import { PaginationFilter } from 'src/app/models/pagination-filter';
import Swal from 'sweetalert2';
import { StockMovementType } from 'src/app/models/stock/stock-movement-type';
import { DialogService } from 'primeng/dynamicdialog';
import { CustomReportExportComponent } from 'src/app/components/extract-custom-report/custom-report-export.component';

interface StockMovementReportData {
  active: string,
  automatic: string,
  cancelAt: string, 
  competenceDate: string,
  createAt: string,
  updateAt: string,
  customerName: string,
  employeeName: string,
  supplierName: string,
  movementValue: number,
  totalItem: number,
}

@Component({
  templateUrl: './stock-movement.component.html',
  styleUrls: ['./stock-movement.component.scss'],
})
export class StockMovementComponent implements OnInit {
  pageable: PaginationFilter = new PaginationFilter();
  filter: StockMovementFilter = new StockMovementFilter();
  customerList: SelectItem[] = [];
  supplierList: SelectItem[] = [];
  stockMovementTypesList: SelectItem[] = [];
  rangeCreateDates: Date[];
  rangeCancelDates: Date[];
  stockMovementTypesListAll:StockMovementType[] = [];

  stockMovements: any[] = [];

  totalItems: number;
  totalValue: number;

  loggedMarina: any;
  contentLoaded = false;
  totalRecords = 0;
  totalDocuments: any;
  numberOfRows = 100;
  cols = [];

  constructor(
    private stockMovementService: StockMovementService,
    private spinner: NgxSpinnerService,
    private messageUtil: MessageUtil,
    private movementTypeService: MovementTypeService,
    private router: Router,
    private financesService: FinancesService,
    private customerService: CustomerService,
    private dialog:  DialogService
  ) {}

  ngOnInit(): void {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDayOfMonth = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    );

    this.pageable.size = this.numberOfRows;
    this.pageable.page = 0;
    this.pageable.sort = 'id';
    this.pageable.order = 'DESC';
    this.getMovementsByFilter();
    this.getmovements();
    this.getCustomers();
    this.loadSupplierList();
  }
  
  exportTable() {
    const data = {table: this.formatExportData(), type: "STOCK_MOVEMENT"};
    
    this.dialog.open(CustomReportExportComponent, {
      width: '70%',
      height: '90vh',
      dismissableMask: false,
      data,
      header: 'Exportar Relatório',
    });
  }

  formatExportData(): StockMovementReportData[]{
    return this.stockMovements.map(x => {
      return {
        active: x.active ? 'Sim' : 'Não',
        automatic: x.automatic ? 'Sim' : 'Não',
        cancelAt: x.cancelAt ? x.cancelAt : '',
        competenceDate: x.competenceDate,
        createAt: x.createAt,
        updateAt: x.updateAt,
        customerName: x.customerName,
        employeeName: x.employeeName,
        supplierName: x.supplierName,
        movementValue: x.movementValue,
        totalItem: x.totalItem,
      }
    });
  }

  loadSupplierList(): void {
    this.financesService.findFornecedor().subscribe({
      next: (data) => {
        this.supplierList = [{ label: 'Todos', value: null }].concat(
          data.map((p) => ({ label: p.nomeFantasia, value: p.idFornecedor }))
        );
      },
      error: (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
    });
  }

  getCustomers(): void {
    this.customerService.getAll('id,name').subscribe({
      next: (data) => {
        this.customerList = [{ label: 'Todos', value: null }].concat(
          data.map((c) => ({ label: c.name, value: c.id }))
        );
      },
      error: (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
    });
  }

  getmovements(): void {
    this.spinner.show();
    this.movementTypeService.getAll().subscribe((data) => {
      this.stockMovementTypesListAll = data;
      this.stockMovementTypesList = data
        .filter((x) => x.active === true)
        .map((x) => {
          return { label: x.name, value: x.id };
        });
      this.spinner.hide();
    });
  }

  getMovementsByFilter(): void {
    this.onChangeCadastro();
    this.onChangeCancel();
    this.stockMovementService
      .findByFilter(this.filter, this.pageable)
      .subscribe({
        next: (data) => {
          if (data.content.length === 0) {
            this.stockMovements = [];
          }
          this.stockMovements = data.content;
          
          this.totalItems = 0;
          this.totalValue = 0;
          this.stockMovements.forEach((x) => {
            this.totalItems += x.totalItem;
            this.totalValue += x.movementValue;
          });
          this.totalRecords = data.totalElements;
          this.totalDocuments = data.totalPages;
          this.contentLoaded = true;
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  openMovementForm(movement?: StockMovement): void {
    this.router.navigate(['/app/stock/stock-movement-form'], {
      state: { stockMovement: movement },
    });
  }

  onChangeCadastro() {
    if (this.rangeCreateDates) {
      if (this.rangeCreateDates[0] != null) {
        this.filter.createAtStart = this.rangeCreateDates[0];
        if (this.rangeCreateDates[1] != null) {
          this.filter.createAtEnd = this.rangeCreateDates[1];
        } else {
          this.filter.createAtEnd = this.rangeCreateDates[0];
        }
      } else {
        this.filter.createAtStart = null;
        this.filter.createAtEnd = null;
      }
    }
  }

  onChangeCancel() {
    if (this.rangeCancelDates) {
      if (this.rangeCancelDates[0] != null) {
        this.filter.createAtStart = this.rangeCancelDates[0];
        if (this.rangeCancelDates[1] != null) {
          this.filter.createAtEnd = this.rangeCancelDates[1];
        } else {
          this.filter.createAtEnd = this.rangeCancelDates[0];
        }
      } else {
        this.filter.cancelAtStart = null;
        this.filter.cancelAtEnd = null;
      }
    }
  }

  clear() {
    this.filter = new StockMovementFilter();
    this.rangeCreateDates = null;
    this.rangeCancelDates = null;
  }

  onChangePage(event: LazyLoadEvent): void {
    const page = event.first / event.rows;
    if (event.multiSortMeta) {
      this.pageable.sort = event.multiSortMeta[0].field;
      this.pageable.order = event.multiSortMeta[0].order === 1 ? 'ASC' : 'DESC';
    }
    this.pageable.page = page;
    this.pageable.size = event.rows;
    this.getMovementsByFilter();
  }

  cancelMovement(movent:StockMovement): void {
    const movementTypeUse : StockMovementType = this.stockMovementTypesListAll.find(x=> x.id === movent.stockMovementType.id);
    const lists: any[] = this.stockMovementTypesListAll
    .filter(x => x.type !== movementTypeUse.type);
    let objetoDinamico: { [key: string]: string } = {};
    lists.forEach(x => {
      objetoDinamico[x.id] = x.name.toString();
    });

    Swal.fire(
      {
        title: 'Cancelar Movimentação',
        text:'O processo de cancelamento gera uma movimentação de estoque reversa. Selecione o tipo de movimentação reversa que deseja realizar.',
        input: 'select',
        inputOptions: objetoDinamico,
        icon: 'warning',
        backdrop: false,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Enviar',
        cancelButtonText: 'Não',
        reverseButtons: true
      }).
      then(async (result) => {
        if (result.value) {
          if (result.value !=null) {
            this.stockMovementService.cancel(movent.id, result.value).subscribe({
              next: (data) => {
                this.messageUtil.generateMessage('success', 'SUMMARY.SUCCESS', 'MSG.SENT-EMAILS');
                this.getMovementsByFilter();
              }
            });
          }
        }
      });


  }

  formatName(customerName , supplierName) : string {
    if (customerName != null) {
      return customerName;
    } else {
      return supplierName;
    }
  }
}
