import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { SlingConfig } from 'src/app/models';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { OperationalConfigService } from 'src/app/services/config/operational-config.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { MovementObjectiveFormComponent } from '../../movements/movement-objective-form/movement-objective-form.component';
import { SlingFormComponent } from '../../sling/sling-form/sling-form.component';

@Component({
  selector: 'app-header-button-new-navigation',
  templateUrl: './header-button-new-navigation.component.html',
  styleUrls: ['./header-button-new-navigation.component.scss']
})
export class HeaderButtonNewNavigationComponent implements OnInit {

  isMovements: boolean;
  isSlings: boolean;

  constructor(
    private slingConfigService: SlingConfigService,
    public router: Router,
    private dialog: DialogService,
    private messageUtil: MessageUtil,
    private operationalConfigService: OperationalConfigService
  ) { }

  ngOnInit(): void {
    this.operationalConfigService.isMovements().then((movements: boolean) => {
      this.isMovements = movements;
      this.isSlings = !movements;
    })
  }

  async openSlingFormDialog(): Promise<void> {
    const slingConfig = await this.findSlingConfig();
    this.dialog
      .open(SlingFormComponent, {
        width: '70%',
        height: '80vh',
        data: {
          slingConfig,
        },
        header: 'Lingada',
      })
      .onClose.subscribe((data) => {
        if (data) {
          BroadcastService.get('reloadSlings').emit();
          this.messageUtil.generateMessage(
            'success',
            'SUMMARY.SUCCESS',
            'Lingada criada com sucesso.'
          );
        }
      });
  }

  async openMovementPlanObjectivesFormDialog(): Promise<void> {
    const slingConfig = await this.findSlingConfig();
    this.dialog
      .open(MovementObjectiveFormComponent, {
        data: {
          slingConfig,
          isNew: true
        },
        header: 'Plano de Movimentações',
        styleClass: 'modal-res-movements'
      })

      .onClose.subscribe((data) => {
        if (data) {
          BroadcastService.get('reloadMovements').emit();
          this.messageUtil.generateMessage(
            'success',
            'SUMMARY.SUCCESS',
            'Plano de movimentação criado com sucesso.'
          );
        }
      });
  }

  async findSlingConfig(): Promise<SlingConfig> {
    return new Promise<SlingConfig>(async (resolve) => {
      this.slingConfigService.getSlingConfig().subscribe(
        async (slingConfig: SlingConfig[]) => {
          resolve(slingConfig[0]);
        },
        async () => {
          resolve(null);
        }
      );
    });
  }
}
