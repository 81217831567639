import { CustomerCreditCustomer } from "./customer-credit-customer";
import { CustomerCreditInvoice } from "./customer-credit-invoice";
import { CustomerCreditUser } from "./customer-credit-user";

export class CustomerCredit {

  id: number;
  customer: CustomerCreditCustomer;
  credit: boolean;
  creditDate: Date;
  value: number;
  createAt: Date;
  createFrom: CustomerCreditUser;
  active: boolean;
  invoice?: CustomerCreditInvoice;
  description?: string;

  constructor() {
    this.id = null;
    this.customer = new CustomerCreditCustomer();
    this.credit = true;
    this.creditDate = new Date();
    this.value = 0;
    this.active = true;
    this.invoice = new CustomerCreditInvoice();
  }
}
