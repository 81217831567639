export class CustomerTableDTO {
    id: number;
    name: string;
    email: string;
    phone: string;
    federalId: string;
    groupId: number;
    groupName: string;
    residentialCity: string;
    residentialstate: string;
    commercialCity: string;
    commercialstate: string;
    reviewed: boolean;
    active: boolean;
    onlyAssociated: boolean;
    ownerAssociated: boolean;
    userApp: boolean;
    expiration: Date;

    constructor(id?: number) {
        if (id) {
            this.id = id;
        }
    }
}
