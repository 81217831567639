import { TimezoneUtil } from 'src/app/utils/timezone.util';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import b64toBlob from 'b64-to-blob';
import { NgxSpinnerService } from 'ngx-spinner';
import { LazyLoadEvent } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { ExtractInvoiceComponent } from 'src/app/components/extract-invoice/extract-invoice.component';
import { MovementObjectiveFormComponent } from 'src/app/components/movements/movement-objective-form/movement-objective-form.component';
import { SlingConfig } from 'src/app/models';
import { BoatFilterListWithCustomerDTO } from 'src/app/models/dtos/boat-filter-list-with-customerDTO';
import { FilterMovementPlan } from 'src/app/models/dtos/movements/filter-movement-plan';
import { MovementPlanByObjectiveCreateDTO } from 'src/app/models/dtos/movements/movement-plan-by-objective-createDTO';
import { MovementPlanDTO } from 'src/app/models/dtos/movements/movement-plan-dto';
import { MovementPlanStatus } from 'src/app/models/enums/movements/movement-plan-status';
import { PaginationFilter } from 'src/app/models/pagination-filter';
import { BoatService } from 'src/app/services/boat.service';
import { CustomerService } from 'src/app/services/customer.service';
import { MovementPlanService } from 'src/app/services/movements/movement-plan.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { FormatUtil } from 'src/app/utils/format.util';
import { ImageUtil } from 'src/app/utils/image.util';
import { MessageUtil } from 'src/app/utils/message.util';
import Swal from 'sweetalert2';
import {MovementObjectiveCode} from '../../../../models/enums/movements/movement-objective-code';
import { CustomReportExportComponent } from 'src/app/components/extract-custom-report/custom-report-export.component';

@Component({
  selector: 'app-movement-plan',
  templateUrl: './movement-plan.component.html',
  styleUrls: ['./movement-plan.component.scss'],
})
export class MovementPlanComponent implements OnInit {
  filteredCustomers: any;
  selectedCustomerId: number;
  selectedMovements = [];
  boats: BoatFilterListWithCustomerDTO[] = [];
  movements: MovementPlanDTO[] = [];
  movementsTable: any[] = [];
  boatsFilter: any[] = new Array();
  numberOfRows = 10;
  slingConfig: SlingConfig;
  globalFilter = '';
  statusOptions: any[] = [];
  objectiveOptions: any[] = [];
  filter: FilterMovementPlan = {};

  paginationFilter: PaginationFilter;

  movementPlanSubscription: Subscription;

  pt = {
    firstDayOfWeek: 0,
    dayNames: [
      'Domingo',
      'Segunda',
      'Terça',
      'Quarta',
      'Quinta',
      'Sexta',
      'Sábado',
    ],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    monthNamesShort: [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ],
    today: 'Hoje',
    clear: 'Limpar',
  };

  constructor(
    private timezoneUtil: TimezoneUtil,
    private customerService: CustomerService,
    private boatService: BoatService,
    private slingConfigService: SlingConfigService,
    private movementPlanService: MovementPlanService,
    private spinner: NgxSpinnerService,
    private messageUtil: MessageUtil,
    private webSocketService: WebSocketService,
    private dialog: DialogService,
    private imageUtil: ImageUtil,
    private sanitizer: DomSanitizer,
  ) {}


  timezone(data: any):any{
    return this.timezoneUtil.formatTimezone(data);
  }

  async ngOnInit(): Promise<void> {
    this.slingConfig = await this.loadSlingConfig();
    this.numberOfRows =
      this.slingConfig != null ? this.slingConfig.numberOfRecordsPerPage : 10;
    this.statusOptions = Object.keys(MovementPlanStatus).map((item) => ({
      label: this.messageUtil.translateKey(item),
      value: item,
    }));
    this.objectiveOptions = Object.keys(MovementObjectiveCode).map((item) => ({
      label: this.messageUtil.translateKey(item),
      value: item,
    }));
    this.clear();
    this.loadCustomers();
    this.loadBoat();
    this.find();
    this.movementPlanSubscription = this.webSocketService
      .getMessageMovement()
      .subscribe(async (message) => {
        await this.find();
      });
  }

  find(): void {
    this.spinner.show();
    this.movementPlanService
      .getMovementPlans(this.filter, this.paginationFilter)
      .subscribe(
        (data) => {
          this.movements = data;
          console.log(data)
          this.filterGlobal();
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(
            exception.type,
            exception.title,
            exception.message
          );
        }
      );
  }

  async loadSlingConfig(): Promise<SlingConfig> {
    return new Promise<SlingConfig>(async (resolve) => {
      this.slingConfigService.getSlingConfig().subscribe(async (data) => {
        resolve(data[0]);
      });
    });
  }

  exportTable() {
    const data = { table: this.movementsTable, type: 'MOVEMENT_PLAN' };
    this.dialog.open(CustomReportExportComponent, {
      width: '70%',
      height: '90vh',
      dismissableMask: false,
      data,
      header: this.messageUtil.translateKey('MOVEMENT.EXPORT_MOVEMENT_PLAN_TABLE'),
    });
  }

  onChangePage(event: LazyLoadEvent): void {
    if (this.slingConfig) {
      const page = event.first / event.rows;
      this.paginationFilter.sort = event.sortField;
      this.paginationFilter.page = page;
      this.paginationFilter.size = event.rows;
      this.paginationFilter.order = event.sortOrder === 1 ? 'ASC' : 'DESC';
      this.movements = [];
      this.find();
    }
  }

  filterGlobal(): void {
    this.movementsTable = this.movements.filter((movementPlan) =>
      FormatUtil.getNotAccents(movementPlan.boatName)
        .toUpperCase()
        .includes(FormatUtil.getNotAccents(this.globalFilter).toUpperCase())
    );
  }

  filterBoat(): void {
    let filteredBoat;
    if (this.filter.customerId) {
      filteredBoat = this.boats
        .filter((boat) =>
          boat.customerIds.some(
            (customerId) => customerId === this.filter.customerId
          )
        )
        .sort((b1, b2) => {
          if (b1.name < b2.name) {
            return -1;
          }
          if (b1.name > b2.name) {
            return 1;
          }
          return 0;
        });
    } else {
      filteredBoat = this.boats.sort((b1, b2) => {
        if (b1.name < b2.name) {
          return -1;
        }
        if (b1.name > b2.name) {
          return 1;
        }
        return 0;
      });
    }
    this.boatsFilter = filteredBoat.map((b) => ({
      label: b.name,
      value: b.id,
    }));
    this.boatsFilter.unshift({ label: 'Todos', value: null });
  }

  loadCustomers(): void {
    this.customerService.getCustomerFilterList().subscribe((data) => {
      this.filteredCustomers = data.map((c) => ({
        label: c.name,
        value: c.id,
      }));
      this.filteredCustomers.unshift({ label: 'Todos', value: null });
    });
  }

  loadBoat(): void {
    this.boatService.getBoatFilterListWithCustomer().subscribe((data) => {
      this.boats = data.filter((boat) => boat.active);
      this.boatsFilter = this.boats.map((b) => ({
        label: b.name,
        value: b.id,
      }));
      this.boatsFilter.unshift({ label: 'Todos', value: null });
    });
  }

  clear(): void {
    this.filter = {
      movementPlanStatus: [
        MovementPlanStatus.ACTIVE,
        MovementPlanStatus.STARTED,
      ],
    };
    this.filterBoat();
    this.paginationFilter = {
      order: 'DESC',
      page: 0,
      size: this.numberOfRows,
      sort: 'id',
    };
    this.movements = [];
    this.movementsTable = [];
  }

  getImageOrigin(movementPlan: MovementPlanDTO): string {
    return this.imageUtil.getImageOrigin(movementPlan.origin);
  }

  hasCancel(movementPlan: MovementPlanDTO): boolean {
    return true;
  }

  async openMovementPlanFormDialog(id: number): Promise<void> {
    const data: any = {
      slingConfig: this.slingConfig,
    };
    if (id) {
      data.isNew = false;
      data.movementPlan = await this.getMovementPlanEdit(id);
    }

    this.dialog.open(MovementObjectiveFormComponent, {
      data,
      header: 'Editar plano de movimentação',
      styleClass: 'modal-res-movements'
    });
  }

  getMovementPlanEdit(id: number): Promise<MovementPlanByObjectiveCreateDTO> {
    this.spinner.show();
    return new Promise((resolve, reject) => {
      this.movementPlanService.findEditByObjective(id).subscribe(
        (data) => {
          resolve(data);
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(
            exception.type,
            exception.title,
            exception.message
          );
          reject();
        }
      );
    });
  }

  openMovementCancelConfirmDialog(movementPlan: MovementPlanDTO): void {
    const types = 'warning';
    const titleMessage = this.messageUtil.translateKey('CANCEL-CONFIRMATION');
    const message = this.messageUtil.translateKey(
      'MOVEMENT-PLAN-CANCEL-PROMPT'
    );

    Swal.fire({
      title: titleMessage,
      text: message + ' ' + movementPlan.boatName + '?',
      icon: types,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        await this.cancelMovementPlan(movementPlan);
      }
    });
  }

  private async cancelMovementPlan(
    movementPlan: MovementPlanDTO
  ): Promise<void> {
    return new Promise((resolve) => {
      this.movementPlanService.cancel(movementPlan.id).subscribe(
        () => {
          this.messageUtil.generateMessage(
            'success',
            'SUMMARY.SUCCESS',
            'MSG.CANCEL-SUCCESS'
          );
          resolve();
        },
        (error) => {
          resolve();
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(
            exception.type,
            exception.title,
            exception.message
          );
        }
      );
    });
  }

  openPrintMovementPlanDialog(id: number) {
    const types = 'question';
    const titleMessage = this.messageUtil.translateKey(
      'PRINT-NAVIGATION-PLAN-CONFIRMATION'
    );
    Swal.fire({
      title: titleMessage,
      icon: types,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        this.generateMovementPlanPDF(id);
      }
    });
  }

  async generateMovementPlanPDF(data): Promise<any> {
    return new Promise<any>(async (resolve) => {
      this.spinner.show();
      await this.movementPlanService
        .getMovementPlanPDF(data)
        .subscribe(async (data) => {
          resolve(
            this.openExtract(
              this.sanitizer.bypassSecurityTrustResourceUrl(
                URL.createObjectURL((b64toBlob as any)(data, 'application/pdf'))
              )
            )
          );
          this.spinner.hide();
        });
    });
  }
  async generateMultipleMovementPlanPDF(): Promise<any> {
    const movementsIds: number[] = this.selectedMovements.map((movement) => movement.id);
    return new Promise<any>(async (resolve) => {
      this.spinner.show();
      await this.movementPlanService
        .getMultipleMovementPlanPDF(movementsIds)
        .subscribe(async (data) => {
          resolve(
            this.openExtract(
              this.sanitizer.bypassSecurityTrustResourceUrl(
                URL.createObjectURL((b64toBlob as any)(data, 'application/pdf'))
              )
            )
          );
          this.spinner.hide();
        });
    });
  }
  openExtract(path): void {
    this.dialog.open(ExtractInvoiceComponent, {
      width: '100%',
      height: '100%',
      data: { path },
      header: 'Plano de Movimentação - PDF',
    });
  }
}
