import { Component, OnInit } from '@angular/core';
import dayjs from 'dayjs';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CustomReportExportComponent } from 'src/app/components/extract-custom-report/custom-report-export.component';
import { BoatWashService } from 'src/app/services/boat-wash.service';

@Component({
  selector: 'app-select-dates',
  templateUrl: './select-dates.component.html',
  styleUrls: ['./select-dates.component.scss']
})
export class SelectDatesComponent implements OnInit {
  dateRange: Date[];

  constructor(
    private dialogRef: DynamicDialogRef,
    private dialog: DialogService,
    private washEngineService: BoatWashService,
  ) { }
  ngOnInit(): void {   
    this.dateRange = [dayjs().subtract(1, 'month').toDate(), dayjs().toDate()];
  }

  exportTable(): void {
    this.washEngineService.getWashReport(this.dateRange[0], this.dateRange[1]).subscribe((res: any) => {
      const data = { table: res, type: 'WASH_ENGINE_TURN' };
      this.dialog.open(CustomReportExportComponent, {
        width: '70%',
        height: '90vh',
        dismissableMask: false,
        data,
        header: 'Exportar Relatório',
      })
    });
  }

  closeDialog(){
    this.dialogRef.close();
    this.dialogRef.destroy();
  }
}
