<form (submit)="save()" class="dialog-form" #f="ngForm">
    <div class="form-group">
        <div class="form-control">
            <label>{{ 'BOAT' | translate }}</label>
            <p-dropdown placeholder="Selecione..." [filter]="true" [options]="boatsFilter" [(ngModel)]="boatSelected"
                appendTo="body" name="boatSelected" required>
            </p-dropdown>
        </div>
        <div class="form-control">
            <label>Data</label>
            <p-calendar dateFormat="dd/mm/yy" name="requestDate" [(ngModel)]="requestDate" [showIcon]="true"
                autocomplete="off" i18n [monthNavigator]="true" [yearNavigator]="true" appendTo="body"
                [yearRange]="'2000:2055'" rPCalendarMask>
            </p-calendar>
        </div>
    </div>
    <div class="form-group radios">
        <p-radioButton name="groupWashEngineTurnAction" value="WASH_ONLY" label="Lavação"
            [(ngModel)]="washEngineTurnOption" inputId="groupWashEngineTurnAction1" required>
        </p-radioButton>
        <p-radioButton name="groupWashEngineTurnAction" value="ENGINE_TURN_ONLY" label="Giro de motor"
            [(ngModel)]="washEngineTurnOption" inputId="groupWashEngineTurnAction2" required>
        </p-radioButton>
        <p-radioButton name="groupWashEngineTurnAction" value="WASH_AND_ENGINE_TURN" label="Lavação e giro de motor"
            [(ngModel)]="washEngineTurnOption" inputId="groupWashEngineTurnAction3" required>
        </p-radioButton>
    </div>
    <div class="buttons">
        <button class="easymarine primary" type="submit" [disabled]="f.invalid">Salvar
        </button>
    </div>
</form>