import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {EmailDownloadService} from 'src/app/services/email-download/email-download.service';
import {MessageUtil} from 'src/app/utils/message.util';
import {InvoiceEmailDownloadDTO} from '../../../models/dtos/email-download/invoice-email-download-DTO';
import {invoiceEmailReceiptDTO} from '../../../models/dtos/email-download/invoice-email-receipt-DTO';
import {InvoiceEmailBankingBilletsDTO} from '../../../models/dtos/email-download/invoiceEmailBankingBilletsDTO';
import {NgxSpinnerService} from 'ngx-spinner';
import {MarinaService} from '../../../services/marina.service';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import moment from 'moment';
import {Subscription} from 'rxjs';
import {WebSocketService} from '../../../services/web-socket.service';

interface MarinaNameAndLogo {
  tradeName: string;
  logoFile: string;
}

@Component({
  selector: 'app-email-download',
  templateUrl: './email-download.component.html',
  styleUrls: ['./email-download.component.scss']
})
export class EmailDownloadComponent implements OnInit {
  marinaId: number;

  invoiceId: number;

  emailDownloadForm: UntypedFormGroup;

  invoices: InvoiceEmailDownloadDTO[] = [];

  invoiceBudget: InvoiceEmailBankingBilletsDTO[];

  invoiceReceipt: invoiceEmailReceiptDTO;

  invoiceNfe: string;

  invoicePdf: string;

  federalIdType: string;

  federalId: string;

  clientName: string;

  mobileResponsive = false;
  tabletResponsive = false;

  marinaNameAndLogo: MarinaNameAndLogo = {
    tradeName: '',
    logoFile: '',
  };

  monthNames = {
    0: 'Janeiro',
    1: 'Fevereiro',
    2: 'Março',
    3: 'Abril',
    4: 'Maio',
    5: 'Junho',
    6: 'Julho',
    7: 'Agosto',
    8: 'Setembro',
    9: 'Outubro',
    10: 'Novembro',
    11: 'Dezembro',
  };

  nixBilletPaidSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private emailDownloadService: EmailDownloadService,
    private messageUtil: MessageUtil,
    private spinner: NgxSpinnerService,
    private marinaService: MarinaService,
    private webSocketService: WebSocketService,
    private responsive: BreakpointObserver
  ) {
  }


  ngOnInit(): void {
    this.startQueryParams();
    this.startForm();
    this.getMarinaLogo();
    this.responsive.observe([Breakpoints.HandsetPortrait, Breakpoints.TabletPortrait]).subscribe(result => {
      const breakpoints = result.breakpoints;
      this.mobileResponsive = breakpoints[Breakpoints.HandsetPortrait];
      this.tabletResponsive = breakpoints[Breakpoints.TabletPortrait];
    });
  }

  startQueryParams() {
    this.route.queryParams.subscribe(params => {
      this.marinaId = params['marinaId'];
      this.invoiceId = params['invoiceId'];
      this.federalIdType = params['federalIdType'];
    });
  }

  getMarinaLogo() {
    this.marinaService.getMarinaFieldsLogo(this.marinaId).subscribe({
      next: (marina) => {
        this.marinaNameAndLogo = marina;
      },
      error: (e) => {
        console.log(e);
      }
    });
  }

  startForm(): void {
    this.emailDownloadForm = new UntypedFormGroup({
      userFederalId: new UntypedFormControl('', Validators.compose([Validators.required, Validators.minLength(11)]))
    });
  }

  getMonth(date: Date): string {
    const budgetMonth: number = new Date(date).getMonth();
    return this.monthNames[budgetMonth];
  }

  getInvoiceAndBillets(federalIdType: string): void {
    this.sendFederalId(federalIdType);
    this.nixBilletPaidSubscription = this.webSocketService.getMessageNixBillet(this.invoiceId).subscribe({
      next: (message) => {
        if (message.headers !== null && message.headers.destination !== null && message.headers.destination !== '') {
          const queryParts = message.headers.destination.split('/');
          if (queryParts.length >= 3) {
            const invoiceId = queryParts[2];
            if (invoiceId === this.invoiceId) {
              this.messageUtil.generateMessage('success', 'SUMMARY.SUCCESS', 'O pagamento da fatura foi reconhecido!');
            }
          }
        }
      },
      error: (e) => console.log(e),
    });
  }

  sendFederalId(federalIdType: string): void {
    this.spinner.show();
    this.federalId = this.emailDownloadForm.value.userFederalId;
    this.emailDownloadService.getInvoiceForEmailDownload(this.marinaId, this.invoiceId, federalIdType, this.federalId).subscribe({
      next: (invoice: InvoiceEmailDownloadDTO[]) => {
        this.spinner.hide();
        this.invoices = invoice;
        this.invoiceBudget = invoice[0]?.invoiceEmailBankingBilletsDTO;
        this.invoiceReceipt = invoice[0]?.invoiceEmailReceiptDTO;
        this.invoiceNfe = invoice[0]?.serviceDocument;
        this.invoicePdf = invoice[0]?.invoicePdf;
        this.federalId = invoice[0]?.federalId;
        this.clientName = invoice[0]?.customerName;

      },
      error: (e) => {
        this.spinner.hide();
        const exception = e.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    });
  }

  downloadPdf(url: string, name: string) {
    const link = document.createElement('a');
    link.href = url;
    link.download = `${name}-Fatura-${this.invoices[0].invoiceNumber}.pdf`;
    link.target = '_blank';
    link.click();
  }

  return() {
    this.invoices = [];
  }

  // generateInvoicePdf() {
  //   const source = `data:application/pdf;base64,${this.invoicePdf}`;
  //   const link = document.createElement("a");
  //   link.setAttribute("type", "application/pdf");
  //   link.href = source;
  //   link.download = `Fatura-${this.invoice.invoiceNumber}.pdf`;
  //   link.target = "_blank";
  //   link.click();
  // }
  generateInvoicePdf() {
    const byteCharacters = atob(this.invoicePdf);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {type: 'application/pdf'});

    // Cria um URL para o blob
    const url = URL.createObjectURL(blob);

    // Cria um link âncora
    const link = document.createElement('a');
    link.href = url;
    link.download = `Fatura-${this.invoices[0].invoiceNumber}.pdf`;

    // Adiciona o link ao corpo do documento e simula o clique
    document.body.appendChild(link);
    link.click();

    // Remove o link do corpo do documento
    document.body.removeChild(link);
  }

  formatDate(date: string) {
    return moment(date).format('DD/MM/YYYY');
  }
}
