import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-log-emergency-contact',
  templateUrl: './customer-log-emergency-contact.component.html',
  styleUrls: ['./customer-log-emergency-contact.component.scss']
})
export class CustomerLogEmergencyContactComponent implements OnInit {

  @Input() customerLog;
  @Input() isNew;

  oldDatasMap

  constructor() { }

  ngOnInit(): void {
    this.oldDatasMap = new Map(Object.entries(this.customerLog.oldDatas));
  }

}
