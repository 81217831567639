export class CustomerCreditCustomer {

id:number;
name: string;
email: string;
phone: string;
balanceNow:number;

constructor() {
  this.id = null;
  this.name = '';
  this.email = '';
  this.phone = '';
  this.balanceNow = 0;
}

}
