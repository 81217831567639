<section>
  <div class="dialog-form">
    <p-table [value]="serviceHistoryReajustments" responsiveLayout="scroll"
    #documentTable
    class="primeTable"
    autoLayout="true"
    styleClass="easy-table"
    [lazy]="true">

      <ng-template pTemplate="header">
        <tr class="ajust-header">
          <th>Dt. Reajus.</th>
          <th>Índice</th>
          <th>Índice valor</th>
          <th>Desconto Antigo</th>
          <th>Desconto Novo</th>
          <th>Desc Venc. Antigo</th>
          <th>Desc Venc. Novo</th>
          <th>Valor Antigo</th>
          <th>Novo Valor</th>
          <th>Final Contrato</th>
          <th>Novo Final Contrato</th>
          <th>Data do Email</th>
          <th></th>

        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-register>
        <tr class="ajust-body">
          <th>{{register.reajustmentDate | date: "dd/MM/yyyy"}}</th>
          <th>{{register.indexUsedText}}</th>
          <th>{{register.indexUsed | number: "1.2-2"}}</th>
          <th>{{register.discountOld | currency : 'BRL':true:'1.2-2'}}</th>
          <th>{{register.discountNew | currency : 'BRL':true:'1.2-2'}}</th>
          <th>{{register.dueDateDiscountOld | currency : 'BRL':true:'1.2-2'}}</th>
          <th>{{register.dueDateDiscountNew | currency : 'BRL':true:'1.2-2'}}</th>
          <th>{{register.valueOld | currency : 'BRL':true:'1.2-2'}}</th>
          <th>{{register.valueNew | currency : 'BRL':true:'1.2-2'}}</th>
          <th>{{register.endContractDateNew| date: "dd/MM/yyyy"}}</th>
          <th>{{register.endContractDateOld | date: "dd/MM/yyyy"}}</th>
          <th>{{register.sendEmailDate | date: "dd/MM/yyyy"}}</th>
          <th></th>
        </tr>
      </ng-template>
    </p-table>
    <br>
    <div class="buttons">
      <button (click)="close()" class="danger easymarine" type="button">{{ 'CANCEL' | translate }}</button>
      <button (click)="clickSend()" class="primary easymarine" type="button"> Enviar E-mail do Último Reajuste </button>
    </div>
  </div>

</section>
