import { Component, HostListener, OnInit } from '@angular/core';
import { Header } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { environment } from 'src/environments/environment';
import { FaqDialogComponent } from '../faq-dialog/faq-dialog.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  today: number;
  env: any;
  showSideNav = true;
  mobile = false;

  @HostListener('window:resize', ['$event']) onScroll($event: Event): void {
    this.adjustWidth();
  }

  constructor( public dialog: DialogService,
    ) {
    this.env = environment;
    this.today = Date.now();
  }

  ngOnInit(): void {
    this.adjustWidth();
    BroadcastService.get('showHideSidenav').subscribe((event) => {
      this.showSideNav = event;
    });
  }

  adjustWidth(): void {
    this.showSideNav = false;
    if (window.innerWidth <= 992) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  openFaq():void{

    this.dialog.open(FaqDialogComponent, {
      height: '85vh',
      width: '25vw',
      dismissableMask: false,
      style:{  'right': '30px', 'top': '30px', 'position': 'fixed'},
      data: {
      },
      header: 'Perguntas Frequentes'
          }).onClose.subscribe(
      () => {

      });


  }

}
