import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { MovementGroup } from 'src/app/models/movement/movement-group';
import { OperatingDayConfigService } from 'src/app/services/config/operating-day-config.service';
import { MovementGroupService } from 'src/app/services/movements/movement-group.service';
import { MovementImageService } from 'src/app/services/movements/movement-image.service';
import { MovementMeanService } from 'src/app/services/movements/movement-mean.service';
import { ToastService } from 'src/app/services/toast.service';
import { MessageUtil } from 'src/app/utils/message.util';
import Swal from 'sweetalert2';
import { MovementGroupModalComponent } from './movement-group-modal/movement-group-modal.component';

@Component({
  selector: 'app-movement-group',
  templateUrl: './movement-group.component.html',
  styleUrls: ['./movement-group.component.scss']
})
export class MovementGroupComponent implements OnInit {

  movementGroups: MovementGroup[] = [];
  scheduleQueueOptions: { label: string, value: { id: number, name: string, active: boolean } }[] = [];
  movementMeanOptions: { label: string, value: { id: number, name: string, imageUrl: string } }[] = [];
  movementImageOptions: any[] = [];

  constructor(
    private movementGroupService: MovementGroupService,
    private movementMeanService: MovementMeanService,
    private movementImageService: MovementImageService,
    private operatingDayConfigService: OperatingDayConfigService,
    private messageUtil: MessageUtil,
    private spinner: NgxSpinnerService,
    private dialog: DialogService,
    private toasty: ToastService,
  ) { }

  ngOnInit(): void {
    this.getMovementMeans();
    this.getMovementImages();
    this.getScheduleQueues();
    this.getMovementGroup();
  }

  listMovementMeansByGroup(group: MovementGroup): string{
    return group.movementGroupMeans.map( groupMeans => groupMeans.movementMean.name).join(", ");
  }

  OpenMovementGroupModal(movementGroup?: MovementGroup) {
    this.dialog.open(MovementGroupModalComponent, {
      width: '500px',
      data: {
        scheduleQueueOptions: this.scheduleQueueOptions,
        movementMeanOptions: this.movementMeanOptions,
        movementImageOptions: this.movementImageOptions,
        movementGroupId: movementGroup?.id
      },
      header: this.messageUtil.translateKey('GROUPS')
    }).onClose.subscribe((data) => {
      if(data) this.getMovementGroup();
    });
  }

  getMovementGroup(){
    this.spinner.show();
    this.movementGroupService.getAll().subscribe(
    data => {
      this.movementGroups = data;
      this.spinner.hide();
    },
    error => {
      this.spinner.hide();
      const exception = error.error.data.exception;
      this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
    })
  }

  getMovementMeans() {
    this.movementMeanService.getAll().subscribe(
      data => {
        this.movementMeanOptions = data.map(mean => ({label: mean.name, value: { id: mean.id, name: mean.name, imageUrl: mean.imageUrl }}))
      },
      error => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    )
  }

  getScheduleQueues() {
    this.operatingDayConfigService.getAllScheduleQueueDTO().subscribe(
      (data) => {
        this.scheduleQueueOptions = data.map(sq => ({label: sq.name, value: {id: sq.id, name: sq.name, active: sq.active}}))
      },
      (err) => {}
    )
  }

  getMovementImages() {
    this.movementImageService.getAll().subscribe(
      data => {
        this.movementImageOptions = data.map(image => ({label: image.url, value: image.url}))
      },
      error => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    )
  }

  openDeleteConfirmDialog(id: number): void {
    Swal.fire({
      title: this.messageUtil.translateKey('DELETE-CONFIRMATION'),
      text: this.messageUtil.translateKey('DELETE-CONFIRMATION-MESSAGE'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).
      then((result) => {
        if (result.value) {
          this.movementGroupService.delete(id).subscribe(
            () => {
              this.getMovementGroup()
              this.toasty.success('Grupo de movimentação removido com sucesso');
            },
            (error) => {
              this.spinner.hide();
              const exception = error.error.data.exception;
              this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
            },
            () => this.spinner.hide());
        }
      }
      );
  }
}
