import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SirenAlert } from 'src/app/models/siren/siren-alert';
import { SirenAlertConfig } from 'src/app/models/siren/siren-alert-config';
import { SirenAlertConfigService } from 'src/app/services/siren/siren-alert-config.service';
import { SirenAlertService } from 'src/app/services/siren/siren-alert.service';
import { MessageUtil } from 'src/app/utils/message.util';

@Component({
  selector: 'app-sling-siren-alert-config',
  templateUrl: './sling-siren-alert-config.component.html',
  styleUrls: ['./sling-siren-alert-config.component.scss']
})
export class SlingSirenAlertConfigComponent implements OnInit {

  @ViewChild('volumeNewSlingCustomer') volumeNewSlingCustomer: ElementRef;
  @ViewChild('audioSourceNewSlingCustomer') audioSourceNewSlingCustomer: ElementRef;

  @ViewChild('volumeNewSlingMarina') volumeNewSlingMarina: ElementRef;
  @ViewChild('audioSourceNewSlingMarina') audioSourceNewSlingMarina: ElementRef;

  @ViewChild('volumeNewSlingTotem') volumeNewSlingTotem: ElementRef;
  @ViewChild('audioSourceNewSlingTotem') audioSourceNewSlingTotem: ElementRef;

  @ViewChild('volumeNewSlingGestor') volumeNewSlingGestor: ElementRef;
  @ViewChild('audioSourceNewSlingGestor') audioSourceNewSlingGestor: ElementRef;

  @ViewChild('volumeUpSlingCustomer') volumeUpSlingCustomer: ElementRef;
  @ViewChild('audioSourceUpSlingCustomer') audioSourceUpSlingCustomer: ElementRef;

  @ViewChild('volumeCancelSling') volumeCancelSling: ElementRef;
  @ViewChild('audioSourceCancelSlingCustomer') audioSourceCancelSlingCustomer: ElementRef;

  sirenAlertConfig: SirenAlertConfig = new SirenAlertConfig();
  filteredSirenAlerts: any[];

  constructor(
    private sirenAlertConfigService: SirenAlertConfigService,
    private sirenAlertService: SirenAlertService,
    private spinner: NgxSpinnerService,
    private messageUtil: MessageUtil
    ) {}

  ngOnInit(): void {
    this.findSirenAlerts();
  }

  changeSirenAlert(sirenAlertType: string): void {
    switch (sirenAlertType) {
      case 'newSlingCustomer':
        this.audioSourceNewSlingCustomer.nativeElement.src = this.sirenAlertConfig.sirenAlertNewSlingCustomer.pathUrl;
        this.volumeNewSlingCustomer.nativeElement.load();
        break;
      case 'newSlingMarina':
        this.audioSourceNewSlingMarina.nativeElement.src = this.sirenAlertConfig.sirenAlertNewSlingMarina.pathUrl;
        this.volumeNewSlingMarina.nativeElement.load();
        break;
      case 'newSlingTotem':
        this.audioSourceNewSlingTotem.nativeElement.src = this.sirenAlertConfig.sirenAlertNewSlingTotem.pathUrl;
        this.volumeNewSlingTotem.nativeElement.load();
        break;
      case 'newSlingGestor':
        this.audioSourceNewSlingGestor.nativeElement.src = this.sirenAlertConfig.sirenAlertNewSlingGestor.pathUrl;
        this.volumeNewSlingGestor.nativeElement.load();
        break;
      case 'upSlingCustomer':
        this.audioSourceUpSlingCustomer.nativeElement.src = this.sirenAlertConfig.sirenAlertUpSlingCustomer.pathUrl;
        this.volumeUpSlingCustomer.nativeElement.load();
        break;
      case 'cancelSling':
        this.audioSourceCancelSlingCustomer.nativeElement.src = this.sirenAlertConfig.sirenAlertCancelSling.pathUrl;
        this.volumeCancelSling.nativeElement.load();
        break;
      default:
        break;
    }
  }

  save(): void {
    if (this.validForm()) {
      this.sirenAlertConfigService.save(this.sirenAlertConfig).subscribe(
        (data) => {
          this.findSirenAlerts();
          this.messageUtil.generateMessage('success', 'SUMMARY.SUCCESS', 'Configuração de alerta de sirene salvo com sucesso!');
        },
        (error) => {
          this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
        }
      );
    }
  }

  private findOne(): void {
    this.sirenAlertConfigService.getOne().subscribe(
      (sirenAlertConfig: SirenAlertConfig) => {
        if (sirenAlertConfig) {
          this.sirenAlertConfig = sirenAlertConfig;
          this.loadAudio();
        }
        this.spinner.hide();
      }, () => this.spinner.hide()
    );
  }

  private findSirenAlerts(): void {
    this.spinner.show();
    this.sirenAlertService.getAll().subscribe(
      (sirenAlerts: SirenAlert[]) => {
        this.filteredSirenAlerts = sirenAlerts.map((sa) => ({ label: sa.name, value: sa }));
        this.findOne();
      }, () => this.spinner.hide()
    );
  }

  private validForm(): boolean {
    return this.validNewSlingMarina() &&
      this.validNewSlingCustomer() &&
      this.validNewSlingTotem() &&
      this.validNewSlingGestor() &&
      this.validUpSlingCustomer() &&
      this.validUptCancelSlingCustomer();
  }

  private validNewSlingCustomer(): boolean {
    if (!this.sirenAlertConfig.newSlingCustomer) {
      this.sirenAlertConfig.sirenAlertNewSlingCustomer = null;
      this.sirenAlertConfig.volumeNewSlingCustomer = null;
    } else if (this.sirenAlertConfig.sirenAlertNewSlingCustomer == null) {
      this.messageUtil.generateMessage('error', 'SUMMARY.ERROR', 'Esolha o audio de nova lingada cliente');
      return false;
    } else {
      this.sirenAlertConfig.volumeNewSlingCustomer = this.volumeNewSlingCustomer.nativeElement.volume;
    }
    return true;
  }

  private validNewSlingMarina(): boolean {
    if (!this.sirenAlertConfig.newSlingMarina) {
      this.sirenAlertConfig.sirenAlertNewSlingMarina = null;
      this.sirenAlertConfig.volumeNewSlingMarina = null;
    } else if (this.sirenAlertConfig.sirenAlertNewSlingMarina == null) {
      this.messageUtil.generateMessage('error', 'SUMMARY.ERROR', 'Esolha o audio de nova lingada marina');
      return false;
    } else {
      this.sirenAlertConfig.volumeNewSlingMarina = this.volumeNewSlingMarina.nativeElement.volume;
    }
    return true;
  }

  private validNewSlingTotem(): boolean {
    if (!this.sirenAlertConfig.newSlingTotem) {
      this.sirenAlertConfig.sirenAlertNewSlingTotem = null;
      this.sirenAlertConfig.volumeNewSlingTotem = null;
    } else if (this.sirenAlertConfig.sirenAlertNewSlingTotem == null) {
      this.messageUtil.generateMessage('error', 'SUMMARY.ERROR', 'Esolha o audio de nova lingada totem');
      return false;
    } else {
      this.sirenAlertConfig.volumeNewSlingTotem = this.volumeNewSlingTotem.nativeElement.volume;
    }
    return true;
  }

  private validNewSlingGestor(): boolean {
    if (!this.sirenAlertConfig.newSlingGestor) {
      this.sirenAlertConfig.sirenAlertNewSlingGestor = null;
      this.sirenAlertConfig.volumeNewSlingGestor = null;
    } else if (this.sirenAlertConfig.sirenAlertNewSlingGestor == null) {
      this.messageUtil.generateMessage('error', 'SUMMARY.ERROR', 'Esolha o audio de nova lingada gestor');
      return false;
    } else {
      this.sirenAlertConfig.volumeNewSlingGestor = this.volumeNewSlingGestor.nativeElement.volume;
    }
    return true;
  }

  private validUpSlingCustomer(): boolean {
    if (!this.sirenAlertConfig.upSlingCustomer) {
      this.sirenAlertConfig.sirenAlertUpSlingCustomer = null;
      this.sirenAlertConfig.volumeUpSlingCustomer = null;
    } else if (this.sirenAlertConfig.sirenAlertUpSlingCustomer == null) {
      this.messageUtil.generateMessage('error', 'SUMMARY.ERROR', 'Esolha o audio de subida lingada cliente');
      return false;
    } else {
      this.sirenAlertConfig.volumeUpSlingCustomer = this.volumeUpSlingCustomer.nativeElement.volume;
    }
    return true;
  }

  private validUptCancelSlingCustomer(): boolean {
    if (!this.sirenAlertConfig.cancelSling) {
      this.sirenAlertConfig.sirenAlertCancelSling = null;
      this.sirenAlertConfig.volumeCancelSling = null;
    } else if (this.sirenAlertConfig.sirenAlertCancelSling == null) {
      this.messageUtil.generateMessage('error', 'SUMMARY.ERROR', 'Esolha o audio de cancelamento lingada cliente');
      return false;
    } else {
      this.sirenAlertConfig.volumeCancelSling = this.volumeCancelSling.nativeElement.volume;
    }
    return true;
  }

  private loadAudio(): void {
    this.loadAudioNewSlingMarina();
    this.loadAudioNewSlingApp();
    this.loadAudioNewSlingTotem();
    this.loadAudioNewSlingGestor();
    this.loadAudioUpSlingCustomer();
    this.loadAudioCancelSlingCustomer();
  }

  private loadAudioNewSlingMarina(): void {
    if (this.sirenAlertConfig.newSlingMarina) {
      this.audioSourceNewSlingMarina.nativeElement.src = this.sirenAlertConfig.sirenAlertNewSlingMarina.pathUrl;
      this.volumeNewSlingMarina.nativeElement.volume = this.sirenAlertConfig.volumeNewSlingMarina;
      this.volumeNewSlingMarina.nativeElement.load();
    }
  }

  private loadAudioNewSlingApp(): void {
    if (this.sirenAlertConfig.newSlingCustomer) {
      this.audioSourceNewSlingCustomer.nativeElement.src = this.sirenAlertConfig.sirenAlertNewSlingCustomer.pathUrl;
      this.volumeNewSlingCustomer.nativeElement.volume = this.sirenAlertConfig.volumeNewSlingCustomer;
      this.volumeNewSlingCustomer.nativeElement.load();
    }
  }

  private loadAudioNewSlingTotem(): void {
    if (this.sirenAlertConfig.newSlingTotem) {
      this.audioSourceNewSlingTotem.nativeElement.src = this.sirenAlertConfig.sirenAlertNewSlingTotem.pathUrl;
      this.volumeNewSlingTotem.nativeElement.volume = this.sirenAlertConfig.volumeNewSlingTotem;
      this.volumeNewSlingTotem.nativeElement.load();
    }
  }

  private loadAudioNewSlingGestor(): void {
    if (this.sirenAlertConfig.newSlingGestor) {
      this.audioSourceNewSlingGestor.nativeElement.src = this.sirenAlertConfig.sirenAlertNewSlingGestor.pathUrl;
      this.volumeNewSlingGestor.nativeElement.volume = this.sirenAlertConfig.volumeNewSlingGestor;
      this.volumeNewSlingGestor.nativeElement.load();
    }
  }

  private loadAudioUpSlingCustomer(): void {
    if (this.sirenAlertConfig.upSlingCustomer) {
      this.audioSourceUpSlingCustomer.nativeElement.src = this.sirenAlertConfig.sirenAlertUpSlingCustomer.pathUrl;
      this.volumeUpSlingCustomer.nativeElement.volume = this.sirenAlertConfig.volumeUpSlingCustomer;
      this.volumeUpSlingCustomer.nativeElement.load();
    }
  }

  private loadAudioCancelSlingCustomer(): void {
    if (this.sirenAlertConfig.cancelSling) {
      this.audioSourceCancelSlingCustomer.nativeElement.src = this.sirenAlertConfig.sirenAlertCancelSling.pathUrl;
      this.volumeCancelSling.nativeElement.volume = this.sirenAlertConfig.volumeCancelSling;
      this.volumeCancelSling.nativeElement.load();
    }
  }

}
