import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import {Tuition} from '../models/tuition';

@Injectable()
export class TuitionService {

    constructor(private http: HttpClient) {
    }

    viewLaunch(referenceDateMilis): Observable<any> {
        return this.http.get(environment.apiHost + '/secure/tuition/' + referenceDateMilis)
            .pipe(map((response: any) => response.data.tuitions));
    }

    viewLaunchByBoat(referenceDateMilis): Observable<any> {
        return this.http.get(
            environment.apiHost + '/secure/tuition/boat/' + referenceDateMilis).pipe(map((response: any) => response.data.tuitions));
    }
    viewLaunchByCustomer(referenceDateMilis): Observable<any> {
        return this.http.get(
            environment.apiHost + '/secure/tuition/customer/' + referenceDateMilis).pipe(map((response: any) => response.data.tuitions));
    }

    generate(boatsTution): Observable<any> {
        return this.http.post(environment.apiHost + '/secure/tuition/', boatsTution).pipe(map((response: any) => response.data));
    }

    generateByBoat(boatsTution): Observable<Tuition[]> {
        return this.http.post(environment.apiHost + '/secure/tuition/boat', boatsTution)
          .pipe(map((response: any) => response.data.tuitions));
    }
    generateByCustomer(customerTutions): Observable<Tuition[]> {
        return this.http.post(environment.apiHost + '/secure/tuition/customer', customerTutions)
          .pipe(map((response: any) => response.data.tuitions));
    }

    preview(boatsTution): Observable<any> {
        return this.http.post(
            environment.apiHost + '/secure/tuition/boat/preview', boatsTution).pipe(map((response: any) => response.data.tuitions));
    }
}
