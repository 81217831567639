import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LocationFinishDTO } from 'src/app/models/dtos/movements/location-finishDTO';
import { BoatMovementDTO } from 'src/app/models/dtos/movements/boat-movement-dto';
import { MessageUtil } from 'src/app/utils/message.util';
import { MovementExecutionService } from 'src/app/services/movements/movement-execution.service';
import { MovementExecutionDestinyDTO, MovementExecutionDestinyDTOLocation, MovementExecutionDestinyDTOVacacy } from 'src/app/models/dtos/movements/execution-destiny/movement-execution-destiny-dto';

@Component({
  selector: 'app-movement-execution-finish-modal',
  templateUrl: './movement-execution-finish-modal.component.html',
  styleUrls: ['./movement-execution-finish-modal.component.scss']
})
export class MovementExecutionFinishModalComponent implements OnInit {

  vacancyOptions: {label: string, value: MovementExecutionDestinyDTOVacacy}[];
  locationOptions: {label: string, value: MovementExecutionDestinyDTOLocation}[];

  destinies: MovementExecutionDestinyDTO;

  location: MovementExecutionDestinyDTOLocation;
  choseLocation: MovementExecutionDestinyDTOLocation;
  vacancy: MovementExecutionDestinyDTOVacacy;

  movement: BoatMovementDTO;

  constructor(
    private messageUtil: MessageUtil,
    public dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    public dialog: DialogService,
    private movementExecutionService: MovementExecutionService
  ) { }

  async ngOnInit(): Promise<void> {
    if (this.config.data.movement) {
      this.movement = this.config.data.movement;

      this.loadMovementObjectiveFinish(this.movement.movement.movementPlanId, this.movement.movement.movementId)
      .then((data) => {
        this.destinies = data;
        if (data.chooseDestiny) {
          let allVacancy;
          if (data.locations.length === 1) {
            this.location = data.locations[0];
            allVacancy = data.locations[0].vacancies;
          } else if(data.locations.length > 1) {
              this.location = data.locations[0];
              this.locationOptions = data.locations.map(location=>({label:location.name,value:location}));
              allVacancy = data.locations[0].vacancies;
            }else  {
            allVacancy = data.locations.filter(l => l.vacancies).map(l => l.vacancies).reduce((acc, v) => [...acc, ...v]);
          }
          this.vacancyOptions = this.mountVacancyOptions(allVacancy);
        }
          else{
            this.location = data.locations[0];
            if (data.locations[0].vacancies && data.locations[0].vacancies.length >= 1) {
              this.vacancy = data.locations[0].vacancies[0]
          }
        }
      })
      .catch((error) => this.destinies = null)

    }
  }

  private mountVacancyOptions(vacancies: MovementExecutionDestinyDTOVacacy[]): {label: string, value: MovementExecutionDestinyDTOVacacy}[] {
    if (!vacancies || vacancies.length <= 0) {
      return null;
    }
    const res = vacancies.map(vacancy =>
      ({
        label: vacancy.code,
        value: vacancy
      })
    );
    res.unshift(
        ({
          label: 'Selecione',
          value: null
        })
    )
    return res;
  }

  save() {
    if ((this.location.vacancyControl && this.vacancy) || (!this.location.vacancyControl && this.location.id) ){
      const vacancy = this.vacancy;
      const movementLocation = { id: this.location.id, type: this.location.type };
      const vacancyRes: LocationFinishDTO = {
        movementLocation,
        vacancy
      };
      this.dialogRef.close(vacancyRes);
    }
    else{
      this.messageUtil.generateMessage('error','Error', 'Esse local controla vaga.');
    }
  }

  loadMovementObjectiveFinish(movementPlanId: number, movementId: number): Promise<MovementExecutionDestinyDTO> {
    return new Promise((resolve, reject) => {
      this.movementExecutionService.getDestinies(movementPlanId, movementId)
      .subscribe(data => {
        resolve(data)
      },
      (error) => {
        console.log(error);
        reject(error)
      })
    });
  }

  changeVacancy(event: {originalEvent: PointerEvent, value: MovementExecutionDestinyDTOVacacy}) {
    if (event.originalEvent.isTrusted) {
      this.vacancy = event.value;
    }
  }

  changeLocation(event: {originalEvent: PointerEvent, value: MovementExecutionDestinyDTOLocation}) {
    if (event.originalEvent.isTrusted) {
      this.location=event.value;
      this.vacancyOptions = this.mountVacancyOptions(event.value.vacancies);
    }
  }

}
