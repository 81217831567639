<div class="totem">
    <div class="header">
        <h1 class="title">
            Lavação e giro de motor
        </h1>
        <div class="buttons">
            <a [routerLink]="'/app/operational/checking'" class="close-button">
                <i class="pi pi-times-circle"></i>
            </a>
        </div>
    </div>
    <div class="body">
        <p-table #tt [value]="washes" styleClass="prime-table" autoLayout="true">
            <ng-template pTemplate="header">
                <tr class="dark-header">
                    <th>Data</th>
                    <th class="text-center">Tipo/Pé</th>
                    <th>Embarcação</th>
                    <th class="text-center flex-header">
                        <img src="assets/images/seaWhite.png" width="75px;"
                            style="border-radius: 10px; padding: 7px 3px; height: 40px; width: 50px;" />
                        &nbsp;/
                        <i class="pi pi-clock"></i>

                    </th>
                    <th class="text-center">Lav.</th>
                    <th class="text-center">Giro</th>
                    <th class="text-right">
                        <button class="easymarine success" type="button" (click)="openDialogFastRegistrarion(null)">
                            Cadastro rápido
                        </button>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-washEngineTurn>
                <tr [ngClass]="{'tr-color-1': i % 2 == 0,
                'tr-color-2': i % 2 != 0,
                'col-danger': isOverdueTask(washEngineTurn),
                'col-warning': isEqualdueTask(washEngineTurn)}">
                    <td>{{washEngineTurn.scheduleDate | date: 'dd/MM'}}</td>
                    <td class="table-text text-center">
                        <img [src]="getImageShipType(washEngineTurn.boatModel)" width="50px;" />
                        <span style="font-size: 14px; background-color: #002640"
                            class="badge badge-pill">{{washEngineTurn.comercialLenght}}'</span>
                    </td>
                    <td>{{washEngineTurn.boatName}}</td>
                    <td class="text-center">
                        <i *ngIf="!isLastSlingUpGreaterThan(washEngineTurn)" class="pi pi-clock" aria-hidden="true"
                            style="color: #002640; font-size: 45px;"></i>
                        <img *ngIf="isLastSlingUpGreaterThan(washEngineTurn)" src="assets/images/sea.png"
                            style="height: 40px; width: 50px; padding: 7px 3px;" />
                    </td>
                    <td class="text-center">
                        <fa-icon *ngIf="washEngineTurn.washDate" [icon]="'tint'"
                            style="color: #002640; font-size: 40px;"></fa-icon>
                    </td>
                    <td class="text-center">
                        <img *ngIf="washEngineTurn.engineTurnDate" src="assets/images/elice.png" width="45px;" />
                    </td>
                    <td class="text-right">
                        <button type="button" (click)="washEngineTurnConfirm(washEngineTurn)"
                            class="easymarine primary">
                            <span> Finalizar</span>
                        </button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
