import { SystemcallAuxiliarTablesService } from 'src/app/services/systemcall-auxiliar-tables.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SlingConfig } from 'src/app/models';
import { AberturaChamado } from 'src/app/models/integracaochamados/abertura-chamado';
import { AtividadeChamado } from 'src/app/models/integracaochamados/atividade-chamado';
import { UsuarioChamado } from 'src/app/models/integracaochamados/usuario-chamado';
import { GrupoSistemaChamado } from 'src/app/models/integracaochamados/grupo-sistema-chamado';
import { environment } from 'src/environments/environment';
import { UploadChamado } from 'src/app/models/integracaochamados/upload-chamado';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-ticket-open',
  templateUrl: './ticket-open.component.html',
  styleUrls: ['./ticket-open.component.scss'],
})
export class TicketOpenComponent implements OnInit {
  constructor(
    public dialog: DynamicDialogRef,
    private toasty: ToastService,
    private config: DynamicDialogConfig,
    private slingConfigService: SlingConfigService,
    private systemcallAuxiliarTablesService: SystemcallAuxiliarTablesService
  ) {}

  slingConfig: SlingConfig;;
  myHeader: string = 'Criar chamado';
  page: number = -1;
  ticketForm: UntypedFormGroup;
  ticketFormDet: UntypedFormGroup;
  listFiles:any[] = [];
  marinaCall:number;
  tela:string = "";

  ngOnInit(): void {
    this.config.header = this.myHeader;
    this.loadSlingConfig();
  }

  newHeader(header: string): void {
    this.myHeader = header;
    this.config.header = this.myHeader;
  }
  onTicketForm(ticketForm: any): void {
    this.ticketForm = ticketForm;
    this.incrementaPage(1);
  }

  incrementaPage(numero: any): void {
    this.page += numero;
    if (this.page == 0) {
      this.newHeader('Criar chamado');
    }
    if (this.page == 1) {
      this.newHeader('Ajuda personalizada');
    }
    if (this.page > 1) {
      this.newHeader('Escrever chamado');
    }
  }

  onTicketFormDet(ticketFormDet: any): void {

    this.ticketFormDet = ticketFormDet;
  }

  getMarina(event:number){
    this.marinaCall=event;
  }

  getScreen(event:any){
      this.tela=event;
  }

  saveAs(files: any[]): void {

    let form2: AberturaChamado = new AberturaChamado(this.ticketFormDet.get("title").value, new UsuarioChamado( environment.userChamadoIntegracao, null));

    form2.solicitanteCliente.id= this.ticketForm.get("requester").value;
    form2.solicitanteCliente.cliente.id=this.marinaCall;

    let atividadecall = new AtividadeChamado(this.ticketFormDet.get("title").value,this.ticketFormDet.get("description").value)
    files.forEach(file => {
      atividadecall.anexosAtividadeChamado.push( new UploadChamado(file.name));
    });
    atividadecall.usuario=new UsuarioChamado( environment.userChamadoIntegracao, null);
    atividadecall.usuarioLogado=new UsuarioChamado( environment.userChamadoIntegracao, null);
    atividadecall.subGrupoSistemaChamado= new GrupoSistemaChamado(this.ticketForm.get("screen").value);



    form2.atividades.push(atividadecall);

    this.systemcallAuxiliarTablesService.postCall(form2).subscribe(
      (result) => {
        this.toasty.success('Chamado criado com sucesso.');
        this.dialog.close();
      },
      (error) => { this.toasty.error('Erro ao gerar chamado.'); }
    );
  }

  async loadSlingConfig(): Promise<void> {
    return new Promise((resolve) => {
      this.slingConfigService.getSlingConfig().subscribe(
        async (data) => {
          this.slingConfig = data[0];
          this.page = 0;
          resolve();
        },
        async (error) => resolve()
      );
    });
  }
}
