import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Customer, SlingConfig } from 'src/app/models';
import { SelectorBoatDTO } from 'src/app/models/dtos/selector-boat-DTO';
import { InvoiceData, OrderData, SalesConfig } from './sales-interfaces';

@Injectable({
  providedIn: 'root'
})
export class SalesStatesService {
  
  constructor() { }
  
  orderData: OrderData = {
    orderedItems: null,
    selectedCustomer: null,
    serviceDeskCustomer: null,
    selectedBoat: null,
    hasInvoice: false,
    total: 0,
    discountedValue: 0,
  };

  invoiceData: InvoiceData = {
    hasPayment: false,
  
  };

  salesConfig: SalesConfig = {
    searchByCustomer: false,
    financialMultipleCompaniesType: null,
  };
  
  payments: any[] = [];
  
  private paymentsSubject = new BehaviorSubject<any[]>(this.payments);
  payments$: Observable<any[]> = this.paymentsSubject.asObservable();
  
  private orderInfoSubject = new BehaviorSubject<OrderData>(this.orderData);
  orderData$: Observable<OrderData> = this.orderInfoSubject.asObservable();

  private invoiceSubject = new BehaviorSubject<InvoiceData>(this.invoiceData);
  invoice$: Observable<InvoiceData> = this.invoiceSubject.asObservable();

  //SLING CONFIG

  setSalesConfig(slingConfig: SlingConfig) {
    this.salesConfig = {
      searchByCustomer: slingConfig.searchByCustomer,
      financialMultipleCompaniesType: slingConfig.financialMultipleCompaniesType,
    };
  }

  getSalesConfig() {
    return this.salesConfig;
  }

  getSearchByCustomer() {
    return this.salesConfig.searchByCustomer;
  }

  getFinancialMultipleCompaniesType() {
    return this.salesConfig.financialMultipleCompaniesType;
  }

  //BOAT AND CUSTOMER SELECTION

  getOrderData() {
    return this.orderData;
  }

  setOrderData(orderData: any) {
    this.orderData = orderData;
    this.orderInfoSubject.next(this.orderData);
  }

  setBoatAndCustomer(selectedBoat: SelectorBoatDTO, selectedCustomer: Customer) {
    this.orderData.selectedBoat = selectedBoat;
    this.orderData.selectedCustomer = selectedCustomer;
    this.orderInfoSubject.next(this.orderData);
  }

  


  setPayments(payments: any) {
    this.payments = payments;
  }

  getPayments() {
    return this.payments;
  }

  setInvoice(invoiceData: any) {
    this.invoiceData = invoiceData;
  }

  getInvoice() {
    return this.invoiceData;
  }

  updatePayment(changedPayment: any) {    
    let difference = 0;
    let totalSplited = 0;
    if(this.invoiceData.hasPayment) {
      this.payments.forEach((payment: any) => {
        payment.disabled = true;
      });
    } else {
      this.payments.forEach((payment: any) => {
        if (payment.disabled) {
          this.invoiceData.hasPayment = true;
        }
        if (payment.id == changedPayment.id) {
          payment.split = this.formatNumber(changedPayment.split);
          payment.total = this.formatNumber(changedPayment.total);
          totalSplited += payment.split;
        } else {
          payment.split = this.formatNumber((payment.totalSplited - changedPayment.split)/(payment.numberOfCustomers - 1));
          payment.total = this.formatNumber(payment.split);
          totalSplited += payment.split;
        }
        if (payment.id == 1) {
          payment.total = payment.split + payment.notSplitedPart;
        }
      });
      difference = this.formatNumber(this.payments[0].totalSplited - totalSplited);
      this.payments[0].total += difference;
    }

    this.invoiceSubject.next(this.invoiceData);
    this.paymentsSubject.next(this.payments);
  }

  reSplit(numberOfCustomers: number) {
    let totalSplited = this.payments[0].totalSplited;
    let notSplitedPart = this.payments[0].notSplitedPart;
    let equalSplit =  this.formatNumber(totalSplited / numberOfCustomers);
    let difference = this.formatNumber(totalSplited - (equalSplit * numberOfCustomers));
   
    while (this.payments.length > numberOfCustomers) {
      this.payments.pop();
    }

    while (this.payments.length < numberOfCustomers) {
      this.payments.push({
        id: this.payments.length + 1,
        customer: `Customer ${this.payments.length + 1}`,
        notSplitedPart: notSplitedPart,
        numberOfCustomers: numberOfCustomers,
        split: equalSplit,
        total: equalSplit,
        totalSplited: totalSplited,
      });
    }

    this.payments.forEach((payment: any) => {
      if (payment.id == 1) {
        payment.split = equalSplit;
        payment.total = payment.split + notSplitedPart + difference;
        payment.numberOfCustomers = numberOfCustomers;
      } else {
        payment.split = equalSplit;
        payment.total = payment.split;
        payment.numberOfCustomers = numberOfCustomers;
      }
    });
    this.paymentsSubject.next(this.payments);
  }

  //erro aqi
  formatNumber(value: number): number {
    return Math.round(value * 100) / 100;
  }

}
