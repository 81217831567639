import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { FilterMovementReport } from "src/app/models/dtos/movements/reports/filter-movement-report";
import { MovementReportDTO } from "src/app/models/dtos/movements/reports/movement-reportDTO";
import { PaginationFilter } from "src/app/models/pagination-filter";
import { environment } from "src/environments/environment";

@Injectable()
export class MovementReportService {

    constructor(private http: HttpClient) { }

    getPage(filter: FilterMovementReport, paginationFilter: PaginationFilter): Observable<any> {

        let params = new HttpParams();

        if (!paginationFilter.size) {
            paginationFilter.size = 0;
        }

        params = params.set('page', paginationFilter.page.toString());
        params = params.set('size', paginationFilter.size.toString());

        return this.http.post(environment.apiHost + '/secure/movement/movement-report', filter, {params})
            .pipe(map((response: any) => response.data.movements as any));
    }

    getReportPDF(filter: FilterMovementReport): Observable<any> {
        return this.http.post(environment.apiHost + '/secure/movement/movement-report/pdf', filter)
            .pipe(map((response: any) => response.data.extract));
    }

    getAllList(filter: FilterMovementReport): Observable<MovementReportDTO[]> {
        return this.http.post(environment.apiHost + '/secure/movement/movement-report/all', filter)
            .pipe(map((response: any) => response.data.movements as MovementReportDTO[]));
    }

}