import { StockMovementItem } from "./stock-movement-item";
import { StockMovementType } from "./stock-movement-type";

export class StockMovement {

  id: number;
  createAt: Date;
  competenceDate: Date;
  active: boolean = true;
  cancelAt: Date | null;
  employeeName: string | null;
  updateAt: Date | null;
  stockMovementType: StockMovementType;
  marinaOrderId: number | null;
  buyOrderId: number | null;
  customerId: number | null;
  supplierId: number | null;
  customerName: string | null;
  supplierName: string | null;
  automatic: boolean = false;
  marinaId: number;
  totalItem: number = 0;
  movementValue: number = 0;
  stockMovementItems: StockMovementItem[] = [];
}
