import { Price } from './price';

export class PriceRange {
    id: number;
    price: Price;
    value: number;
    startLengthRange: number;
    endLengthRange: number;

    constructor() {
        this.price = new Price();
    }
}
