<div class="back-principal">
  <span style="color: red; font-size: 12px"> >Links que podem ajudar</span>
  <br />

  <div class="form-group" style="margin-top: 20px" *ngIf="!links[0]">
    <div class="form-control">
      <span class="sub-titulo">Acesse nosso Manual</span><br />
      <a href='https://wiki.easymarine.com.br/' target="_blank"
        >https://wiki.easymarine.com.br/</a
      >
    </div>
  </div>

  <div class="form-group" style="margin-top: 20px" *ngIf="links[0]">
    <div class="form-control">
      <span class="sub-titulo">Acesse nosso Manual</span><br />
      <a [attr.href]='links[0].link' target="_blank"
        >{{links[0].link}}</a
      >
    </div>
  </div>

  <div class="form-group" style="margin-top: 20px" *ngIf="links[1]">
    <div class="form-control">
      <span class="sub-titulo">Acesse nosso Manual</span><br />
      <a [attr.href]='links[1].link' target="_blank"
        >{{links[1].link}}</a
      >
    </div>
  </div>

  <div
    class="form-group"
    style="text-align: center; margin-top: 20px; position: fixed; bottom: 30px; width: 100%;
    right: 10px;"
  >
    <div>
      <span class="text-final">Sua dúvida foi resolvida?</span>
    </div>
    <div style="margin-top: 10px; margin-bottom: 10px;">
      <button
        class="easymarine primary"
        style="border-radius: 10px; width: 191px"
        (click)="close()"
      >
        Sim
      </button>
    </div>
    <div>
      <button
        class="easymarine primary"
        (click)="proximo()"
        style="border-radius: 10px; width: 191px; background-color: #ffffff !important;
        color: #2196f3 !important;"
      >
        Não
      </button>
    </div>
  </div>
</div>
