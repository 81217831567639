import { Customer } from './customer';
import { CustomerType } from './customer-type';

export class CustomerBoat {
    customer: Customer;
    associatedOwner: Customer;
    type: CustomerType;
    quota: number;
    sharedInvoiceCustomerApp: boolean;
    viewHistory = true;
    constructor() {
        this.customer = new Customer();
        this.associatedOwner = new Customer();
        this.type = CustomerType.Owner;
    }
}
