import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EasyMarineHttpApiLog } from './easy-marine-http-api-log';
import { HttpParams } from '@angular/common/http';

import moment from 'moment';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class MarinaOrderLogService {

    constructor(private http: EasyMarineHttpApiLog) {
    }

    findOrders(filter: any): Observable<any> {
        let params = new HttpParams();
        if (filter.dateStart) {
            params = params.append('dateStart', moment(filter.dateStart).format());
        }
        if (filter.dateEnd) {
            params = params.append('dateEnd', moment(filter.dateEnd).format());
        }
        if (filter.userId) {
            params = params.append('userId', filter.userId);
        }
        if (filter.customerId) {
            params = params.append('customerId', filter.customerId);
        }
        if (filter.slingId) {
            params = params.append('slingId', filter.slingId);
        }
        if (filter.lastEmployeeName) {
            params = params.append('lastEmployeeName', filter.lastEmployeeName);
        }
        if (filter.orderNumber) {
            params = params.append('orderNumber', filter.orderNumber);
        }
        return this.http.get(environment.apiLog + `/orders-log/${filter.marinaId}`, { params })
            .pipe(map((response: any) => response));
    }
}
