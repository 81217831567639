import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import {Observable} from "rxjs";
import {FranchiseGroupOutput} from "../models/dtos/franchise/franchise-group-output";
import {BoatFranchiseDTO} from "../models/dtos/franchise/boat-franchiseDTO";

@Injectable({
    providedIn: 'root'
  })
  export class FranchiseGroupService {
    env:string = environment.apiHost + '/api-movement/secure/franchise/group';
    constructor(private http: HttpClient) { }

    findAll(): Observable<FranchiseGroupOutput[]> {
      return this.http.get(this.env + '/list').pipe(map((response: FranchiseGroupOutput[]) => response));
    }

    findById(id) {
      return this.http.get(this.env + '/' + id).pipe(map((response: any) => response));
    }

    save(data) {
      return this.http.post(this.env + '/', data).pipe(map((response: any) => response));
    }

    delete(id) {
      return this.http.delete(this.env + '/' + id).pipe(map((response: any) => response));
    }

    findAllBoatsByFranchiseGroup(franchiseGroupId: number): Observable<BoatFranchiseDTO[]> {
      return this.http.get(this.env + '/find-boats-by-franchise/' + franchiseGroupId.toString()).pipe(map((response: any) => response));
    }

    updateBoatToFranchiseInBatch(body: any): Observable<void>{
      return this.http.post(this.env + '/batch-update-boats-to-franchise', body).pipe(map((response: any) => response));
    }
  }
