import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormatUtil } from 'src/app/utils/format.util';

@Component({
  selector: 'app-config-price-ranges',
  templateUrl: './config-price-ranges.component.html',
  styleUrls: ['./config-price-ranges.component.scss']
})
export class ConfigPriceRangesComponent implements OnInit, OnChanges {

  @Input() enableReadjustment;
  @Input() service;
  @Output() change: EventEmitter<number> = new EventEmitter();
  @Output() isValidChange: EventEmitter<boolean> = new EventEmitter();

  constructor() { }
  
  ngOnInit(): void {
    this.verifyReadjustmentValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.verifyReadjustmentValues();
  }

  calcPorcentage(price): number {
    if(!price.readjustmentValue) {
      return
    }
    return (price.readjustmentValue - price.value)/price.value;
  }

  onChange() {
    this.change.emit(this.calcPercentageRanges());
    this.verifyReadjustmentValues();
  }

  private calcPercentageRanges(): number {
    let count = 0;
    let percentage = 0;
    this.service.price.priceRanges.forEach(range => {
      const value = this.calcPorcentage(range);
      if(value) {
        count ++;
        percentage += value*100;
      }
    });
    if(count > 0){
      return  FormatUtil.roundTwoDecimal(percentage/count);
    }
  }

  verifyReadjustmentValues() {
    const invalid = this.service.price.priceRanges.find(range => !range.readjustmentValue || range.readjustmentValue <= 0);
    this.isValidChange.emit(!invalid);
  }

}
