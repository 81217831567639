<div class="container-fluid">
  <p-table [value]="boats" autoLayout="true" responsiveLayout="scroll" styleClass="p-datatable-striped">
    <ng-template pTemplate="header">
      <tr>
        <th class="text-center">Embarcações Associadas</th>
        <th class="text-center">Tipo cliente</th>
        <th class="text-center">Bloquear acesso</th>
      </tr>
    </ng-template>
    <ng-template let-boat pTemplate="body">
      <tr>
        <td class="text-center">{{boat.boatName}}</td>
        <td class="text-center">{{boat.costumerType.toUpperCase() | translate}}</td>
        <td class="text-center">
          <button (click)="updateBoatBlock(boat.boatId, false)" *ngIf="boat.blocked" class="p-button-outlined p-button-danger p-2" icon="pi pi-unlock" label="Desbloq."
                  pButton pRipple></button>
          <button (click)="updateBoatBlock(boat.boatId, true)" *ngIf="!boat.blocked" class="p-button-danger p-2" icon="pi pi-lock" label="Bloquear" pButton
                  pRipple></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
