<ng-container>
    <p-card>
        <p-menu [model]="items" [style]="{'border': '0px','font-size': '11px', 'padding': '0px' }"></p-menu>   
        <!-- div>Largura: {{(fabricObj.width * fabricObj.scaleX * pixel_scale_m).toFixed(2)}}</div> 
        <div>Comprimento: {{(fabricObj.height * fabricObj.scaleY * pixel_scale_m).toFixed(2)}}</div>
        <div>Vaga: {{(fabricObj['esy.berthCode'])}}</div>
        <div>Top: {{fabricObj.top}} Left: {{fabricObj.left}}</div> 
        <div>Angule: {{fabricObj.angle}} </div>     
        <div>aCoords:  </div>
        <div>tl: X:{{fabricObj.aCoords.tl.x}} / Y:{{fabricObj.aCoords.tl.y}} </div>
        <div>tr: X:{{fabricObj.aCoords.tr.x}} / Y:{{fabricObj.aCoords.tr.y}} </div>
        <div>bl: X:{{fabricObj.aCoords.bl.x}} / Y:{{fabricObj.aCoords.bl.y}} </div>
        <div>br: X:{{fabricObj.aCoords.br.x}} / Y:{{fabricObj.aCoords.br.y}} </div-->
    </p-card>
</ng-container>