import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import b64toBlob from 'b64-to-blob';
import { NgxSpinnerService } from 'ngx-spinner';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ExtractInvoiceComponent } from 'src/app/components/extract-invoice/extract-invoice.component';
import { Boat, MarinaOrder, SlingConfig } from 'src/app/models';
import { OrderStatus } from 'src/app/models/enums';
import { PaginationFilter } from 'src/app/models/pagination-filter';
import { BoatService } from 'src/app/services/boat.service';
import { CustomerService } from 'src/app/services/customer.service';
import { FinancialMultipleCompaniesService } from 'src/app/services/financial/financial-multiple-companies.service';
import { GroupService } from 'src/app/services/group.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { MarinaOrderService } from 'src/app/services/marina-order.service';
import { ProductService } from 'src/app/services/product.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { MessageUtil } from 'src/app/utils/message.util';
import Swal from 'sweetalert2';
import { OrderFormComponent } from './order-form/order-form.component';
import { OperationalConfigService } from 'src/app/services/config/operational-config.service';
import { CustomReportExportComponent } from 'src/app/components/extract-custom-report/custom-report-export.component';
import { PrintingService } from 'src/app/services/printing-service';
import { PrintType } from 'src/app/models/enums/print-type';
import { OrderHistoricComponent } from './order-historic/order-historic.component';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  totalOrders = 0;
  totalValueOrders = 0;
  orderStatus = new UntypedFormControl();
  orderProduct = new UntypedFormControl();
  selectedCustomerId: number;
  selectedBoatId: number;
  boatsFilter: any[] = new Array();
  filter: any = {
    origim: '',
    hasInvoice: 'false',
    orderStatusList: [
      OrderStatus.Attempted,
      OrderStatus.Ordered,
      OrderStatus.PartiallyAttempted
    ]
  };
  formGroup = new UntypedFormControl();
  orders: any[] = [];
  ordersTable: any[] = [];
  totalRecords = 0;
  boats = [];
  boatFilter: Boat = new Boat();
  filteredCustomers: any;
  filteredBoat: any;
  filteredGroup: any;
  orderNumber: number;
  paginationFilter: PaginationFilter = new PaginationFilter();
  orderStatusList: any[] = [{ label: 'Solicitado', value: 'Ordered' }, { label: 'Parcial', value: 'PartiallyAttempted' },
  { label: 'Atendido', value: 'Attempted' }, { label: 'Cancelado', value: 'Canceled' }];
  orderProductsList: any[]=[];
  orderProductFiltered: any[];
  globalFilter = '';
  isWorkshop: Boolean;

  slingConfig: SlingConfig;

  pt = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  };

  originOptions: any[];
  menuItems: MenuItem[] = [];
  selectedOrder: any;

  constructor(
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private marinaOrderService: MarinaOrderService,
    private invoiceService: InvoiceService,
    private boatService: BoatService,
    private messageService: MessageUtil,
    private sanitizer: DomSanitizer,
    private dialog: DialogService,
    private spinner: NgxSpinnerService,
    private productService: ProductService,
    private groupsService: GroupService,
    private slingConfigService: SlingConfigService,
    private financialMultipleCompaniesService: FinancialMultipleCompaniesService,
    private operationalConfigService: OperationalConfigService,
    private printingService: PrintingService
  ) {

    this.boats = new Array();
    this.loadCustomers();
    this.loadBoat();
    this.loadGroups();
  }

  async ngOnInit(): Promise<void> {
    await Promise.all([this.getWorkshopConfig()]);
    this.getOriginOptions();
    await this.loadSlingConfig();
    this.orderNumber = this.route.snapshot.params.orderNumber;
    this.findAllProducts();
    this.filter.hasInvoice = '';
    if (this.orderNumber !== undefined) {
      this.filter.orderNumber = this.orderNumber;
    }
    this.find();
    this.loadMenuItems();
  }

  async getWorkshopConfig() {
    this.isWorkshop =
      await this.operationalConfigService.isWorkshop();
  }

  getOriginOptions() {
    this.originOptions = [
      { label: 'Todos', value: '' },
      { label: 'Mensalidade', value: 'TUITION' },
      { label: 'Lingada', value: 'SLING' },
      { label: 'Avulso', value: 'DETACHED' },
      { label: 'Franquia', value: 'FRANCHISE'}
    ];
    if (this.isWorkshop) {
      this.originOptions.push({ label: 'Oficina', value: 'WORKSHOP' });
    }
  }
  async loadSlingConfig(): Promise<void> {
    return new Promise<void>(
      async (resolve, reject) => {
        this.slingConfigService.getSlingConfig().subscribe(
          async (data) => {
            this.slingConfig = data[0];
            this.paginationFilter.size = this.slingConfig != null ? this.slingConfig.numberOfRecordsPerPage : 10;
            resolve();
          },
          async (error) => reject()
        );
      }
    );
  }

  clearCustomer(): void {
    this.selectedCustomerId = null;
    this.filterBoat();
  }

  getTotalProduct():number{
    let n:number =0;
    if (this.filter.orderProductsList?.length==1){
      this.ordersTable.forEach(item =>
        n = n + +this.getSupplier(item)
      )
    }
    return n;
  }

  find(): void {
    if (this.slingConfig == undefined) return;

    this.spinner.show();
    this.filter.idBoat = this.filter.boat ? this.filter.boat.id : null;
    this.filter.idCustomer = this.selectedCustomerId;

    this.marinaOrderService.getOrderMarinaWithPagination(this.filter, this.paginationFilter).subscribe({
      next: (data) => {
        this.totalOrders = data.totalvalue;

        data = data.orders;

        this.orders = data.content;
        this.ordersTable = data.content;
        this.totalRecords = data.totalElements;
      },
      error: (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title, exception.message);
      },
      complete: () => this.spinner.hide()
    });
  }

  onChangePage(event: LazyLoadEvent): void {
    if (this.slingConfig) {
      const page = event.first / event.rows;
      this.paginationFilter.sort = event.sortField;
      this.paginationFilter.page = page;
      this.paginationFilter.order = event.sortOrder === 1 ? 'ASC' : 'DESC';
      this.find()
    };
  }

  calculateQuotaValue(orderValue: any, quota): number {
    let totalValue = (orderValue * quota) / 100;
    return totalValue;
  }

  hasInvoice(orderQuotas): boolean {
    if (orderQuotas === undefined) {
      return false;
    }
    return orderQuotas.find((orderQuota) => orderQuota.idInvoice !== null) !== undefined;
  }

  loadCustomers(): void {
    this.customerService.getCustomerFilterList().subscribe(
      (data) => {
        this.filteredCustomers = data.map((c) => ({ label: c.name, value: c.id }));
        this.filteredCustomers.unshift({ label: 'Todos', value: null });
      }
    );
  }

  filterBoat(): void {
    this.filter.boat = null;
    this.selectedBoatId = null;
    if (this.selectedCustomerId) {
      this.filteredBoat = this.boats.filter((boat) => boat.boatCustomers.some((customerBoat) =>
        customerBoat.customer.id === this.selectedCustomerId)).sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          } else if (a.name > b.name) {
            return 1;
          } else {
            return 0;
          }
        });
    } else {
      this.filteredBoat = this.boats.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        } else {
          return 0;
        }
      });
    }
    this.boatsFilter = this.filteredBoat.map((b) => ({ label: b.name, value: b.id }));
    this.boatsFilter.unshift({ label: 'Todos', value: null });
  }

  loadBoat(): void {
    this.boatService.getAllActive().subscribe(
      (data) => {
        this.filteredBoat = this.boats = data;
        this.boatsFilter = this.filteredBoat.map((b) => ({ label: b.name, value: b.id }));
        this.boatsFilter.unshift({ label: 'Todos', value: null });
      }
    );
  }

  loadGroups(): void {
    this.groupsService.findAllActive().subscribe((data) => {
      this.filteredGroup = data.map((g) => ({ label: g.name, value: g }));
      this.filteredGroup.unshift({ label: 'Sem grupo', value: undefined });
    });
  }

  findAllProducts(): void {
    this.productService.getAll().subscribe(
      (data) => {
        this.orderProductsList = data;
        this.orderProductFiltered = this.orderProductsList.map((product) => ({ label: product.name, value: product }));
      }
    );
  }

  selectCustomer(): void {
    this.filterBoat();
  }

  selectBoat(): void {
    this.filter.boat = null;
    if (this.selectedBoatId != null) {
      this.filter.boat = this.boats.find((b) => b.id === this.selectedBoatId);
    }
  }

  cancel(order): void {
    Swal.fire({
      title: 'Atenção',
      text: 'Deseja realmente cancelar o pedido Nº: ' + order.orderNumber,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.cancelOrder(order);
      }
    });
  }

  cancelOrder(order): void {
    this.spinner.show();
    this.marinaOrderService.cancel(order.id).subscribe(
      (data) => {
        this.find();
        this.messageService.generateMessage('success', 'SUMMARY.SUCCESS', 'MSG.CANCEL-SUCCESS');
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }

  clear(): void {
    this.clearCustomer();
    this.filter = {};
    this.ordersTable = [];
    this.formGroup = new UntypedFormControl();
    this.orderStatus = new UntypedFormControl();
    this.orderProduct = new UntypedFormControl();
  }

  openOrderDetail(marinaOrder): void {
    const dialog = this.dialog.open(OrderFormComponent, {
      dismissableMask: false,
      width: '85%',
      data: {
        marinaOrder
      },
      header: 'Editar Pedido ' + marinaOrder.orderNumber
    });
    this.spinner.hide();
    dialog.onClose.subscribe(() => this.find());
  }

  openOrderhistoric(marinaOrder): void {
    this.dialog.open(OrderHistoricComponent, {
      dismissableMask: false,
      width: '85%',
      data: {
        marinaOrder
      },
      header: 'Histórico do Pedido ' + marinaOrder.orderNumber
    });
    this.spinner.hide();


  }

  getSupplier(order:MarinaOrder): string {
    let totalForSupplier = 0;
    let texto:string = '';
    this.filter.orderProductsList.forEach(product => {
      totalForSupplier = 0;
     order.orderItems.forEach(item => {
       if (item.product.id === product.id) {
          totalForSupplier += item.qtySupplied;;
       }
       });
      if (totalForSupplier > 0){
       texto +=  totalForSupplier.toString() ;
      }
    });
    return texto;
  }

  getMarinaOrdersById(idMarinaOrder): void {
    this.spinner.show();
    this.marinaOrderService.getById(idMarinaOrder).subscribe(
      (data: MarinaOrder) => {
        this.openOrderDetail(data);
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }

  loadMenuItems() {
    this.menuItems = [{ label: 'Editar', icon: 'pi pi-pencil', iconClass: 'red-menu-icon', command: () => this.getMarinaOrdersById(this.selectedOrder.id) }]
    this.menuItems.push({ label: 'Reimprimir', icon: 'pi pi-print', iconClass: 'red-menu-icon', command: () => this.reprint(this.selectedOrder.id) });
    this.menuItems.push({ label: 'Histórico', icon: 'pi pi-eye', iconClass: 'red-menu-icon', command: () => this.openOrderhistoric(this.selectedOrder) });
    this.menuItems.push({ label: 'Excluir', icon: 'pi pi-trash', iconClass: 'red-menu-icon', disabled: this.isDisabled(this.selectedOrder), command: () => { !this.isDisabled(this.selectedOrder) && this.cancel(this.selectedOrder) } });
  }

  reprint(selectedOrderId: number): void {
    this.spinner.show();
    this.printingService.generatePdfById(selectedOrderId, PrintType.ORDER_CHECK).subscribe((data) => {
      this.spinner.hide();
      const url = window.URL.createObjectURL(data);
      window.open(url);
    });
  }

  changeSelectedItem(order): void {
    this.selectedOrder = order;
    this.loadMenuItems();
  }

  isDisabled(order): boolean {
    if (order === undefined || order.orderStatus === undefined) {
      return false;
    }
    return (
      order.orderStatus.toUpperCase() === 'CANCELED' ||
      (order.orderQuotas.find((oq) => oq.idInvoice != null) !== undefined) ||
      order.origin === 'SLING'
    );
  }

  extract(idInvoice): void {
    this.spinner.show();
    this.invoiceService.extract(idInvoice).subscribe(
      (data) => {
        this.openExtract(
          this.sanitizer.bypassSecurityTrustResourceUrl(
            URL.createObjectURL(
              (b64toBlob as any)(data, 'application/pdf')
            )
          )
        );
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title, exception.message);
      },
      () => this.spinner.hide());
  }

  openExtract(path): void {
    this.dialog.open(ExtractInvoiceComponent, {
      width: '100%',
      height: '100%',
      dismissableMask: false,
      data: {
        path
      }
    });
  }

  hasFinancialMultipleByProductFilter(slingConfig: SlingConfig): boolean {
    return this.financialMultipleCompaniesService.hasFinancialMultipleCompaniesByProduct(slingConfig);
  }

  async exportTableExcel() {
    const orders = await this.findDataExport();
    const data = { table: orders, type: 'ORDER' };

    this.dialog.open(CustomReportExportComponent, {
      width: '70%',
      height: '90vh',
      dismissableMask: false,
      data,
      header: 'Exportar Faturas',
    });
  }

  findDataExport(): Promise<any[]> {
    return new Promise(async (resolve) => {
      this.spinner.show();
      const paginationFilter = { ...this.paginationFilter };
      paginationFilter.page = 0;
      paginationFilter.size = 100000;
      this.marinaOrderService.getOrderMarinaWithPagination(this.filter, paginationFilter).subscribe({
        next: (data: any) => {
          console.log(data.orders.content);
          const orders = [].concat(...data.orders.content.map(order => {
            return this.formatDataExport(order);
          }));
          this.spinner.hide();
          console.log(orders);
          resolve(orders);
        },
        error: (err) => {
          this.spinner.hide();
        },
        complete: () => {
          this.spinner.hide();
          resolve([]);
        }
      });
    })
  }

  formatDataExport(order: any): any {
    let flattenOrders = [];
    order.orderQuotas.forEach(orderQuota => {
      let flattenedOrder = {
        orderNumber: order.orderNumber,
        orderRequestDate: order.orderDate,
        orderUpdateDate: order.updateAt,
        orderOrigin: order.origin,
        orderValue: order.value,
        orderStatus: order.orderStatus,
        boat: order.boat.name,
        customer: orderQuota.customer.name,
        quota: order.orderquota,
        invoiceNumber: orderQuota.invoiceNumber,
        invoiceStatus: orderQuota.statusInvoice,
        lastDateAttempt: order.lastDateAttempt,
      };
      flattenOrders.push(flattenedOrder);
    });
    return flattenOrders;
  }

}
