import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-invoice-interest-details',
  templateUrl: './invoice-interest-details.component.html',
  styleUrls: ['./invoice-interest-details.component.scss']
})
export class InvoiceInterestDetailsComponent implements OnInit {

  taxaMulta: number;
  taxaMora: number;
  toleranceDays: number;
  constructor(
    private config: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
  ) { }

  ngOnInit(): void {
    if (this.config && this.config.data) {
      this.taxaMora = this.config.data.taxaMora;
      this.taxaMulta = this.config.data.taxaMulta;
      this.toleranceDays = this.config.data.toleranceDays;
    }
  }

}
