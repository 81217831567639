import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-boat-log-accessory-optional',
  templateUrl: './boat-log-accessory-optional.component.html',
  styleUrls: ['./boat-log-accessory-optional.component.scss']
})
export class BoatLogAccessoryOptionalComponent implements OnInit {

  @Input() accessoryOptional;
  @Input() accessoryOptionalOld;
  @Input() isNew;

  constructor() { }

  ngOnInit(): void { }

  logsTable(): any[]{
    if(this.isNew){
      return this.accessoryOptional;
    } else {
      return this.accessoryOptionalOld;
    }
  }

  isChanged(boatAccessory): boolean {
    let boatAccessoryVerify;
    if(this.isNew){
      boatAccessoryVerify = this.findAccessoryOptionalOld(boatAccessory.accessory.id);
    } else {
      boatAccessoryVerify = this.findAccessoryOptional(boatAccessory.accessory.id);
    }

    return !boatAccessoryVerify || (boatAccessoryVerify.quantity !== boatAccessory.quantity);
  }

  findAccessoryOptional(id): any{
    return this.accessoryOptional.find(a => a.accessory.id === id);
  }

  findAccessoryOptionalOld(id): any{
    return this.accessoryOptionalOld.find(a => a.accessory.id === id); 
  }



}
