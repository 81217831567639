<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{ 'TITLE-MARINA-ORDER-LOG' | translate }}</span>
        <button class="fab-button primary" appFiltersButton pTooltip="Exibir/Ocultar Filtros" tooltipPosition="left">
            <i class="pi pi-filter"></i>
        </button>
    </p-header>

    <form class="filters" (submit)="find()" appFilters>
        <div class="filter-row">
            <div class="form-control">
                <label>Data inicial</label>
                <p-calendar dateFormat="dd/mm/yy" name="dateStart" [monthNavigator]="true" [yearNavigator]="true"
                    appendTo="body" [yearRange]="'2000:2055'" rPCalendarMask [(ngModel)]="filter.dateStart"
                    [showIcon]="true" i18n>
                </p-calendar>
            </div>
            <div class="form-control">
                <label>Data final</label>
                <p-calendar dateFormat="dd/mm/yy" name="dateEnd" [monthNavigator]="true" [yearNavigator]="true"
                    appendTo="body" [yearRange]="'2000:2055'" rPCalendarMask [(ngModel)]="filter.dateEnd"
                    [showIcon]="true" i18n>
                </p-calendar>
            </div>
            <div class="form-control">
                <label>{{ 'USER' | translate }}</label>
                <p-dropdown placeholder="Selecione..." [filter]="true" [options]="filteredUsers" appendTo="body"
                    [(ngModel)]="filter.userId" name="selectedUserId">
                </p-dropdown>
            </div>
            <div class="form-control">
                <label>{{ 'CUSTOMER' | translate }}</label>
                <p-dropdown placeholder="Selecione..." [filter]="true" [options]="customersFilter" appendTo="body"
                    [(ngModel)]="filter.customerId" name="selectedCustomerId">
                </p-dropdown>
            </div>
            <div class="form-control">
                <label>{{ 'SLING-NUMBER' | translate }}</label>
                <p-inputNumber [useGrouping]="false" appendTo="body" [(ngModel)]="filter.slingId"
                    name="selectedSlingId">
                </p-inputNumber>
            </div>
            <div class="form-control">
                <label>{{ 'MARINA-ORDER-NUMBER' | translate }}</label>
                <p-inputNumber [useGrouping]="false" appendTo="body" [(ngModel)]="filter.orderNumber"
                    name="selectedOrderNumber">
                </p-inputNumber>
            </div>
            <div class="form-control">
                <label>{{ 'RESPONSIBLE' | translate }}</label>
                <input type="text" pInputText [(ngModel)]="filter.lastEmployeeName" name="selectedLastEmployeeName">
            </div>
            <div class="buttons">
                <div class="form-button">
                    <button type="submit" class="primary easymarine">{{ 'FIND' | translate }}</button>
                </div>
            </div>
        </div>
    </form>
    <app-marina-order-log-table [ordersLog]="ordersLog"></app-marina-order-log-table>
</p-fieldset>