import {MarinaService} from '../../services/marina.service';
import {Component, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, Validators,} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from 'src/app/services/authentication.service';
import {StorageUtil} from 'src/app/utils/storage.util';
import {faKey} from '@fortawesome/free-solid-svg-icons';
import {NgxSpinnerService} from 'ngx-spinner';
import {DialogService} from 'primeng/dynamicdialog';
import {LoginMarinaOptionsComponent} from './login-marina-options/login-marina-options.component';
import {MessageUtil} from 'src/app/utils/message.util';
import {Marina} from 'src/app/models';
import Swal from 'sweetalert2';
import {RoleService} from 'src/app/services/role.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm = new UntypedFormGroup({
    username: new UntypedFormControl('', Validators.compose([Validators.required])),
    password: new UntypedFormControl(
      '',
      Validators.compose([Validators.required, Validators.minLength(6)])
    ),
  });
  returnUrl: any;
  messageLogin: any;
  loading: boolean;
  faKey;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private spinner: NgxSpinnerService,
    private dialog: DialogService,
    private messageUtil: MessageUtil,
    private marinaService: MarinaService,
    private roleService: RoleService
  ) { }

  ngOnInit(): void {
    this.faKey = faKey;
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
    StorageUtil.logout();
  }

  login(): void {
    StorageUtil.logout();
    this.messageLogin = null;
    this.spinner.show();
    this.authenticate();
  }

  private authenticate() {
    this.authenticationService.login(this.loginForm.value).subscribe(
      {
        next: (token) => {
          localStorage.setItem('token', token.token);
          localStorage.setItem('refreshToken', token.refreshToken);

          const marinas = StorageUtil.getMarinasIds();
          const userId = StorageUtil.getUser().userId;

          marinas.length > 1 ? this.openLoginMarinaOptions(userId, token) : this.getMarina(marinas[0], token);
        },
        error: (error) => {
          this.spinner.hide();
          this.messageLogin = error;
          this.loading = false;
        }
      }
    );
  }

  getErrorMessage() {
    if (this.messageLogin.status === 401) {
      return this.messageUtil.translateKey('INVALID-USER-PASS');
    }
    if (this.messageLogin.status === 403) {
      return this.messageUtil.translateKey('INACTIVE-USER');
    }
  }

  async openLoginMarinaOptions(userId: Number, token: any): Promise<void> {
    this.dialog.open(LoginMarinaOptionsComponent, {
      width: '50vh',
      height: '80vh',
      data: userId,
      header: this.messageUtil.translateKey('SELECT-MARINA'),
    }).onClose.subscribe(marinaId => {
      this.getMarina(marinaId, token)
    })
  }

  private getMarina(marinaId: number, token: any) {
    this.marinaService.getMarina(marinaId).subscribe({
      next: (marina: Marina) => {
        if (marina.disableWebOperation) {
          this.disableWebOperation(marina, token);
        } else {
          StorageUtil.login(token.token, token.refreshToken, marina);
          this.router.navigate([this.returnUrl]);
          this.spinner.hide();
        }
      }, error: (error) => {
        console.log(error);
      }
    });
  }

  private disableWebOperation(marina: Marina, token: any) {
    let userId = StorageUtil.getUser().userId;
    this.roleService.isCollaboratorOrAdmin(userId, marina.id).subscribe({
      next: (resp) => {
        if (resp) {
          StorageUtil.login(token.token, token.refreshToken, marina);
          this.router.navigate([this.returnUrl]);
          this.spinner.hide();
        } else {
          Swal.fire(
            this.messageUtil.translateKey('SUMMARY.WARNING'),
            'Entre em contato com o administrador do sistema.',
            'warning'
          );
          this.spinner.hide();
          return;
        }
      },
      error: (e) => console.log(e)
    });
  }

  isInvalid(control: AbstractControl): boolean {
    return control.touched && control.dirty && !control.valid;
  }
}
