import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-boat-log-accessory-accessory',
  templateUrl: './boat-log-accessory-accessory.component.html',
  styleUrls: ['./boat-log-accessory-accessory.component.scss']
})
export class BoatLogAccessoryAccessoryComponent implements OnInit {

  @Input() accessoryAccessory;
  @Input() accessoryAccessoryOld;
  @Input() isNew;

  constructor() { }

  ngOnInit(): void {
  }

  logsTable(): any[]{
    if(this.isNew){
      return this.accessoryAccessory;
    } else {
      return this.accessoryAccessoryOld;
    }
  }

  isChanged(boatAccessory): boolean {
    let boatAccessoryVerify;
    if(this.isNew){
      boatAccessoryVerify = this.findAccessoryOld(boatAccessory.accessory.id);
    } else {
      boatAccessoryVerify = this.findAccessory(boatAccessory.accessory.id)
    }

    return !boatAccessoryVerify || (boatAccessoryVerify.name !== boatAccessory.name
      || boatAccessoryVerify.urlPhoto !== boatAccessory.urlPhoto);
  }

  findAccessory(id): any{
    return this.accessoryAccessory.find(a => a.accessory.id === id);
  }

  findAccessoryOld(id): any{
    return this.accessoryAccessoryOld.find(a => a.accessory.id === id); 
  }

}
