import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Role, User } from 'src/app/models';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';
import { FormUtil } from 'src/app/utils/form.util';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {

  loading = false;
  changePassword = false;
  model: User = new User();
  origin: string;
  userForm: UntypedFormGroup;
  rolesList: { label: string; value: Role; }[];

  constructor(
    public dialogRef: DynamicDialogRef,
    private userService: UserService,
    private messageUtil: MessageUtil,
    private spinner: NgxSpinnerService,
    private toasty: ToastService,
    private config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.resetForm();
    if (this.config.data.model) {
      this.model = this.config.data.model;
      this.origin = this.config.data.origin;
      this.userForm.patchValue(this.model);
      this.changePass();
    }
    this.findRoles();
  }

  passwordMatch(c: AbstractControl): any {
    return c.get('password').value === c.get('confirmPassword').value ? null : { nomatch: true };
  }

  usernameInUse(): void {
    this.userService.getByUsername(this.userForm.get('username').value)
      .subscribe(
        (data) => {
          if (data !== null) {
            Swal.fire('Alerta!', `Usuário ${this.userForm.get('username').value} já cadastrado`, 'warning');
            this.userForm.get('username').reset();
          }
        });
  }

  validPassword(): void {
    if (this.userForm.get('password').value !== this.userForm.get('confirmPassword').value) {
      Swal.fire('Alerta!', 'Senhas não conferem', 'warning');
      this.userForm.get('password').reset();
      this.userForm.get('confirmPassword').reset();
    }
  }

  formValidations(): boolean {
    if (this.userForm.valid) {
      return true;
    } else {
      FormUtil.touchAndSendFormGroup(this.userForm);
      Swal.fire('Alerta!', 'Por favor, preencha os campos obrigatórios', 'warning');
      return false;
    }
  }

  save(): void {
    this.loading = true;
    this.spinner.show();
    if (this.model && this.model.id) {
      Object.keys(this.userForm.controls).forEach((key) => {
        this.model[key] = this.userForm.get(key).value;
      });
      this.userService.update(this.model).subscribe(
        (data) => {
          this.spinner.hide();
          this.toasty.success('Usuário alterado com sucesso!');
          this.dialogRef.close(data);
        },
        (error) => {
          this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
        }
      );
    } else {
      this.model = this.userForm.value;
      this.model.marina = StorageUtil.getMarina();
      this.model.marinas = [];
      this.model.marinas.push(StorageUtil.getMarina());
      this.userService.create(this.model).subscribe(
        (data) => {
          this.spinner.hide();
          this.toasty.success('Usuário salvo com sucesso!');
          this.dialogRef.close(data);
        },
        (error) => {
          this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
        }
      );
    }
  }

  isGestor(): boolean {
    return this.model && this.model.marinas && this.model.marinas.length > 0;
  }

  resetForm(): void {
    this.userForm = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      username: new UntypedFormControl(null, Validators.required),
      password: new UntypedFormControl(null, Validators.required),
      confirmPassword: new UntypedFormControl(null, Validators.required),
      firstName: new UntypedFormControl(null, Validators.required),
      lastName: new UntypedFormControl(null, Validators.required),
      email: new UntypedFormControl(null, Validators.required),
      bracelet: new UntypedFormControl(null),
      changePassword: new UntypedFormControl(false),
      active: new UntypedFormControl(true),
      operator: new UntypedFormControl(false),
      roleId: new UntypedFormControl(null, Validators.required)
    });
  }

  changePass($event?: any): void {
    if ($event && $event.checked) {
      this.userForm.get('password').enable();
      this.userForm.get('confirmPassword').enable();
      this.userForm.get('password').reset();
      this.userForm.get('confirmPassword').reset();
    } else {
      this.userForm.get('password').disable();
      this.userForm.get('confirmPassword').disable();
    }
  }

  async findRoles(): Promise<void> {
    return new Promise<void>(
      async (res) => {
        this.userService.getRolesByHierarchy().subscribe(
          (data) => {
            this.rolesList = data.map((m) => ({ label: m.name, value: m }));
            res();
          },
          (error) => { }

        );

      }
    );
  }

}

