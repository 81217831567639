<div class="table">
    <p-table #documentTable [value]="[service.price]" class="primeTable"
         autoLayout="true" styleClass="easy-table" [lazy]="true">
        <ng-template pTemplate="caption">
            <div></div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th>Valor</th>
                <th>Novo Preço com reajuste</th>
                <th>Porcentagem de reajuste</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-price>
            <tr>
                <td >{{ price.value | currency : 'BRL'}}</td>
                <td >
                    <input name="readjustmentValue" type="number" pInputText
                        *ngIf="enableReadjustment" [(ngModel)]="price.readjustmentValue" (input)="onChange()"
                        >
                </td>
                <td ><span *ngIf="price.readjustmentValue">{{calcPorcentage(price) | percent:'.2' }}</span></td>
            </tr>
        </ng-template>
    </p-table>
</div>
