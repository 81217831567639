import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuItem, SelectItem } from 'primeng/api';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ExtractInvoiceComponent } from 'src/app/components/extract-invoice/extract-invoice.component';
import { SlingConfig } from 'src/app/models';
import { AccessGroupEnum } from 'src/app/models/dtos/access-controller/access-group-enum';
import { AccessPermission } from 'src/app/models/dtos/access-controller/accessPermission';
import { LiberationStatusEnum } from 'src/app/models/dtos/access-controller/liberation-status-enum';
import { AccessGroupService } from 'src/app/services/access-controler/access-group.service';
import { VisitorService } from 'src/app/services/access-controler/visitor.service';
import { BoatService } from 'src/app/services/boat.service';
import { OperationalConfigService } from 'src/app/services/config/operational-config.service';
import { CustomerService } from 'src/app/services/customer.service';
import { FinancesService } from 'src/app/services/finances.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { MessageUtil } from 'src/app/utils/message.util';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-visitor-form-permission',
  templateUrl: './visitor-form-permission.component.html',
  styleUrls: ['./visitor-form-permission.component.scss'],
})
export class VisitorFormPermissionComponent implements OnInit {
  @Input() isNested: boolean = false;
  @Input() accessPermissionForm: FormGroup;

  @Output() previousStep = new EventEmitter<any>();
  @Output() saveServiceProviderAndPermission = new EventEmitter<any>();

  superSave: boolean = false;
  visitor: any;

  accessGroupList: any[] = [];
  visitorType: AccessGroupEnum = null;
  customerList: SelectItem[] = [];
  customerListProperties: any[] = [];
  selectedGroup: any;
  permissionList: AccessPermission[] = [];
  boatList: SelectItem[] = [];
  menuItems: MenuItem[] = [];
  selectedPermission: any;
  supplierList: SelectItem[] = [];
  slingConfig: SlingConfig;
  useInvites: boolean = false;

  constructor(
    private visitorService: VisitorService,
    private customerService: CustomerService,
    private messageUtil: MessageUtil,
    private financesService: FinancesService,
    private config: DynamicDialogConfig,
    private spinner: NgxSpinnerService,
    private accessGroupService: AccessGroupService,
    private slingConfigService: SlingConfigService,
    private boatService: BoatService,
    private operationalConfigService: OperationalConfigService,
    private dialog: DialogService
  ) {}

  ngOnInit() {
    if (this.accessPermissionForm) {
      this.superSave = true;
    }
    if (this.config.data) {
      this.visitor = this.config.data.visitor;
      this.accessGroupList = this.config.data.accessGroupList;
      this.visitorType = this.config.data.visitorType;
      console.log(this.visitorType);
      if (!this.accessPermissionForm) {
        this.loadAccessPermissionForm();
      }
    }

    this.getOperationalConfig();

    if (
      !this.accessGroupList ||
      this.accessGroupList?.length == 0 ||
      this.visitorType != null
    ) {
      this.loadGroupList();
    } else {
      this.accessPermissionForm
        .get('accessGroupId')
        .setValue(this.accessGroupList[0].value);
      const accessGroup = {
        id: this.accessPermissionForm.get('accessGroupId').value,
      };
      this.accessPermissionForm.get('accessGroup').setValue(accessGroup);
    }

    this.getCustomers();
    this.getCustomersProperties();
    this.loadSupplierList();
    this.loadSlingConfig();

    if (this.visitor?.colectId) {
      this.accessPermissionForm.get('colectId').setValue(this.visitor.colectId);
      this.loadPermissionList();
    }
    this.loadMenuItems();
  }

  loadAccessPermissionForm() {
    this.accessPermissionForm = new FormGroup({
      id: new FormControl<number | null>(null),
      visitor: new FormGroup({ id: new FormControl<number | null>(null) }),
      colectId: new FormControl<number | null>(null),
      type: new FormControl<number | null>(0),
      accessGroup: new FormControl<any | null>(null),
      accessGroupId: new FormControl<number | null>(null, Validators.required),
      customerResponsibleId: new FormControl<number | null>(
        null,
        Validators.required
      ),
      customerResponsibleName: new FormControl<string | null>(null),
      supplierId: new FormControl<number | null>(null),
      supplierName: new FormControl<string | null>(null),
      active: new FormControl<boolean | null>(true),
      objective: new FormControl<string | null>(null),
      createDate: new FormControl<Date | null>(null),
      updateAt: new FormControl<Date | null>(null),
      starDate: new FormControl<Date | null>(null, Validators.required),
      endDate: new FormControl<Date | null>(null, Validators.required),
      statusColector: new FormControl<number | null>(null),
      carPlate: new FormControl<string | null>(null),
      carColor: new FormControl<string | null>(null),
      carModel: new FormControl<string | null>(null),
      ocurrences: new FormControl<string | null>(null),
      userUpdateId: new FormControl<number | null>(null),
      userUpdateName: new FormControl<string | null>(null),
      boatId: new FormControl<number | null>(null),
      boatName: new FormControl<string | null>(null),
      liberationStatus: new FormControl<string | null>(null),
    });
  }
  async loadSlingConfig(): Promise<void> {
    return await new Promise<void>(async (resolve) => {
      await this.slingConfigService.getSlingConfig().subscribe(async (data) => {
        this.slingConfig = data[0];
        resolve();
      });
    });
  }

  changeSelectedItem(accessPermission: AccessPermission): void {
    this.selectedPermission = accessPermission;
  }

  loadMenuItems() {
    this.menuItems = [
      {
        label: 'Editar',
        icon: 'pi pi-pencil',
        iconClass: 'red-menu-icon',
        command: () => this.editPermission(this.selectedPermission),
      },
    ];
    this.menuItems.push({
      label: 'Imprimir',
      icon: 'pi pi-print',
      iconClass: 'red-menu-icon',
      command: () => this.printPermission(this.selectedPermission),
    });
  }

  isAllowedToEdit(liberationStatus: any): boolean {
    if (liberationStatus === 'DENIED' || liberationStatus === 'EXPIRED') {
      return false;
    }
    return true;
  }

  editPermission(permission: any): void {
    permission.visitor = this.visitor;
    this.accessPermissionForm.enable();
    this.accessPermissionForm.get('id')?.setValue(permission.id);
    this.accessPermissionForm.get('boatId')?.setValue(permission.boatId);
    this.accessPermissionForm
      .get('accessGroup')
      ?.setValue(permission.accessGroup);
    this.onChangeAccessGroup({ value: permission.accessGroup.id });
    this.accessPermissionForm
      .get('customerId')
      ?.setValue(permission.customerId);
    this.accessPermissionForm
      .get('customerResponsibleId')
      ?.setValue(permission.customerResponsibleId);
    this.accessPermissionForm
      .get('supplierId')
      ?.setValue(permission.supplierId);
    this.getBoats();
    this.accessPermissionForm.get('active')?.setValue(permission.active);
    this.accessPermissionForm.get('objective')?.setValue(permission.objective);
    this.accessPermissionForm
      .get('statusColector')
      ?.setValue(permission.statusColector);
    this.accessPermissionForm.get('carPlate')?.setValue(permission.carPlate);
    this.accessPermissionForm.get('carColor')?.setValue(permission.carColor);
    this.accessPermissionForm.get('carModel')?.setValue(permission.carModel);
    this.accessPermissionForm
      .get('ocurrences')
      ?.setValue(permission.ocurrences);
    this.accessPermissionForm
      .get('userUpdateId')
      ?.setValue(permission.userUpdateId);
    this.accessPermissionForm
      .get('userUpdateName')
      ?.setValue(permission.userUpdateName);
    this.accessPermissionForm
      .get('accessGroupId')
      ?.setValue(permission.accessGroup.id);
    this.accessPermissionForm
      .get('starDate')
      ?.setValue(new Date(permission.starDate));
    this.accessPermissionForm
      .get('endDate')
      ?.setValue(new Date(permission.endDate));
    this.accessPermissionForm
      .get('createDate')
      ?.setValue(new Date(permission.createDate));
    this.accessPermissionForm
      .get('updateAt')
      ?.setValue(new Date(permission.updateAt));
    this.accessPermissionForm.get('customerResponsibleId').disable();
    this.accessPermissionForm.get('accessGroupId').disable();

    if (this.accessPermissionForm.get('starDate')?.value < new Date()) {
      this.accessPermissionForm.get('starDate')?.disable();
    }
    if (this.accessPermissionForm.get('endDate')?.value < new Date()) {
      this.accessPermissionForm.disable();
    }
    if (this.accessPermissionForm.get('active')?.value === false) {
      this.accessPermissionForm.disable();
    }
  }

  printPermission(permission: AccessPermission): void {
    this.spinner.show();
    this.accessGroupService.extract(permission.id).subscribe(
      (data) => {
        if (data.path) {
          window.open(data.path, '_blank');
        } else {
          console.error('O caminho do arquivo não foi fornecido.');
        }
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
      () => this.spinner.hide()
    );
  }

  openReportPermission(path): void {
    this.dialog.open(ExtractInvoiceComponent, {
      width: '100%',
      height: '100%',
      data: { path },
      header: 'Permissão de Acesso - PDF',
    });
  }

  async getOperationalConfig() {
    this.useInvites =
      await this.operationalConfigService.invitesControllerEnabled();
  }

  onChangeAccessGroup(select: any): void {
    this.selectedGroup = this.accessGroupList.find(
      (ag) => ag.value == select.value
    );
  }

  getCustomersProperties(): void {
    this.customerService.getCustomerPropertiesToInvite().subscribe({
      next: (data) => {
        this.customerListProperties = [
          { label: 'Selecione', value: null },
        ].concat(
          data.map((c) => ({
            label: this.generateNameToTitle(c),
            value: c.customerId,
            secutiesPropertiesId: c.secutiesPropertiesId,
            invitesAvailable: c.invitesAvailable,
            invitesAfterRecharge: c.invitesAfterRecharge,
          }))
        );
      },
    });
  }

  generateNameToTitle(item: any): string {
    let name = 'Tit.' + item.secutiesPropertiesNumber;
    name += ' ' + item.customerName;
    name += ' (' + item.invitesAvailable + ')';
    return name;
  }

  listCustomerList(): any[] {
    if (this.selectedGroup?.useInvitation) {
      return this.customerListProperties;
    }
    return this.customerList;
  }

  loadGroupList(): void {
    this.accessGroupService.findAll().subscribe({
      next: (data) => {
        this.accessGroupList = data.map((group) => ({
          label: group.name,
          value: group.id,
          showSuplier: group.showSuplier,
          useInvitation: group.useInvitation,
          type: group.type,
        }));

        if (this.visitorType != null) {
          this.accessGroupList = this.accessGroupList.filter(
            (a) => a.type == this.visitorType
          );
        }

        this.selectedGroup = this.accessGroupList[0];

        this.accessPermissionForm
          .get('accessGroupId')
          .setValue(this.accessGroupList[0].value);
        const accessGroup = {
          id: this.accessPermissionForm.get('accessGroupId').value,
        };
        this.accessPermissionForm.get('accessGroup').setValue(accessGroup);
      },
    });
  }

  loadPermissionList(): void {
    let id;
    if (this.config.data) {
      id = this.visitor.colectId;
    } else {
      id = this.visitor.visitorId;
    }
    if (!id) {
      return;
    }
    this.visitorService
      .getAccessPermissionByVisitor(id, this.visitorType)
      .subscribe({
        next: (data) => {
          this.accessPermissionForm.get('accessGroupId').enable();
          this.permissionList = data;
          if (this.config?.data?.permission?.permissionId) {
            let permission = this.permissionList.find(
              (p) => p.id === this.config.data.permission.permissionId
            );
            this.editPermission(permission);
            this.selectedPermission = permission;
          }
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  getBoats(): void {
    this.boatService
      .getBoatByCustomerAndActive(
        this.accessPermissionForm.get('customerResponsibleId')?.value,
        true
      )
      .subscribe({
        next: (data) => {
          this.boatList = [{ label: 'Selecione', value: null }].concat(
            data.map((p) => ({ label: p.name, value: p.id }))
          );
        },
        error: (error) => {
          console.log(error);
        },
      });
  }

  startNewForm(): void {
    this.accessPermissionForm.reset();
    this.accessPermissionForm.enable();
    this.accessPermissionForm
      .get('visitor')
      ?.get('id')
      ?.setValue(this.visitor.id);
    this.accessPermissionForm.get('active')?.setValue(true);
    this.accessPermissionForm.get('colectId').setValue(this.visitor.colectId);
    this.loadGroupList();
  }

  loadSupplierList(): void {
    this.financesService.findFornecedor().subscribe({
      next: (data) => {
        this.supplierList = [{ label: 'Selecione', value: null }].concat(
          data.map((p) => ({ label: p.nomeFantasia, value: p.idFornecedor }))
        );
      },
      error: (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
    });
  }

  getCustomers(): void {
    this.customerService.getAll('id,name').subscribe({
      next: (data) => {
        this.customerList = [{ label: 'Selecione', value: null }].concat(
          data.map((c) => ({ label: c.name, value: c.id }))
        );
      },
      error: (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
    });
  }

  close(): void {
    window.history.back();
  }

  save(): void {
    if (this.visitorType == 'GUEST') {
      let inviteDate = this.accessPermissionForm.get('starDate').value;
      this.accessPermissionForm.get('endDate').setValue(inviteDate);
    }
    if (!this.accessPermissionForm.valid) {
      this.messageUtil.generateMessage(
        'warning',
        'SUMMARY.WARNING',
        'Preencha todos os campos obrigatórios.'
      );
      return;
    }
    if (this.accessPermissionForm.get('customerResponsibleId')?.value) {
      this.accessPermissionForm
        .get('customerResponsibleName')
        ?.setValue(
          this.customerList.find(
            (c) =>
              c.value ===
              this.accessPermissionForm.get('customerResponsibleId')?.value
          )?.label
        );
    }
    if (this.accessPermissionForm.get('supplierId')?.value) {
      this.accessPermissionForm
        .get('supplierName')
        ?.setValue(
          this.supplierList.find(
            (c) =>
              c.value === this.accessPermissionForm.get('supplierId')?.value
          )?.label
        );
    }

    if (this.accessPermissionForm.get('boatId')?.value) {
      this.accessPermissionForm
        .get('boatName')
        ?.setValue(
          this.boatList.find(
            (c) => c.value === this.accessPermissionForm.get('boatId')?.value
          )?.label
        );
    }

    if (
      this.accessPermissionForm.get('starDate')?.value >
      this.accessPermissionForm.get('endDate')?.value
    ) {
      this.messageUtil.generateMessage(
        'warning',
        'SUMMARY.WARNING',
        'Data de inicio não pode ser maior que a data de fim.'
      );
      return;
    }

    if (this.selectedGroup?.useInvitation) {
      let selectedCustomer = this.customerListProperties.find(
        (c) =>
          c.value ===
          this.accessPermissionForm.get('customerResponsibleId')?.value
      );
      if (
        selectedCustomer.invitesAvailable <= 0 &&
        this.slingConfig?.inviteMaxBuy <= selectedCustomer.invitesAvailable * -1
      ) {
        this.messageUtil.generateMessage(
          'warning',
          'SUMMARY.WARNING',
          'Não é possivel comprar mais convites.'
        );
        return;
      }
      if (selectedCustomer.invitesAvailable <= 0) {
        Swal.fire({
          title: 'Convites',
          text: 'Saldo de convites insuficientes. Confirma a cobrança de um convite excedente?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.isConfirmed) {
            this.sendSave(selectedCustomer.secutiesPropertiesId);
          } else {
            return;
          }
        });
      } else {
        this.sendSave(selectedCustomer.secutiesPropertiesId);
      }
    } else {
      this.sendSave();
    }
  }

  sendSave(tituloId?: number): void {
    console.log(tituloId);

    // this.spinner.show();
    const accessGroup = {
      id: this.accessPermissionForm.get('accessGroupId').value,
    };
    this.accessPermissionForm.get('accessGroup').setValue(accessGroup);
    this.accessPermissionForm.get('accessGroupId').setValue(null);
    this.accessPermissionForm.get('accessGroupId').disable();
    this.accessPermissionForm.get('type').setValue(0);
    let liberation: LiberationStatusEnum =
      this.accessPermissionForm.get('liberationStatus')?.value;
    if (liberation == null) {
      this.accessPermissionForm
        .get('liberationStatus')
        .setValue(LiberationStatusEnum.ALLOWED);
    }

    if (this.superSave) {
      this.saveServiceProviderAndPermission.emit({
        form: this.accessPermissionForm.value,
        titleId: tituloId,
      });
    } else {
      this.accessPermissionForm.get('visitor').patchValue(this.visitor);
      this.visitorService
        .saveAccessPermission(this.accessPermissionForm.value, tituloId)
        .subscribe({
          next: (data) => {
            this.loadPermissionList();
            this.startNewForm();
            this.getCustomersProperties();
            this.spinner.hide();
          },
          error: (error) => {
            console.log(error);
            this.spinner.hide();
            this.messageUtil.generateMessage(
              'warning',
              'SUMMARY.WARNING',
              'Acesso Gravado. A inclusão nas catracas entrou na fila de processo.'
            );
            this.loadPermissionList();
            this.startNewForm();
            this.getCustomersProperties();
          },
        });
    }
  }

  previous(): void {
    let data = { accessPermissionForm: this.accessPermissionForm, index: 0 };
    this.previousStep.emit(data);
  }

  getStatus(status: LiberationStatusEnum): string {
    switch (status) {
      case LiberationStatusEnum.WAITING:
        return 'Aguardando';
      case LiberationStatusEnum.ALLOWED:
        return 'Liberado';
      case LiberationStatusEnum.DENIED:
        return 'Negado';
      case LiberationStatusEnum.EXPIRED:
        return 'Expirado';
      default:
        return 'Aguardando';
    }
  }
}
