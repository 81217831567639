import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PaginationFilter } from '../models/pagination-filter';


@Injectable({
    providedIn: 'root'
})
export class InvoiceTaxDocumentService {


    constructor(private http: HttpClient) { }

    findByFilter(filter: any, paginationFilter: PaginationFilter): Observable<any> {
        let params = new HttpParams();

        let sort;
        if (paginationFilter.sort) {
            sort = paginationFilter.sort;
        }
        if (paginationFilter.order) {
            sort = (sort ? sort + ',' : '') + paginationFilter.order;
        }
        if (sort) {
            params = params.set('sort', sort);
        }
        params = params.set('page', paginationFilter.page.toString());
        params = params.set('size', paginationFilter.size.toString());

        return this.http.post(
            environment.apiHost + '/secure/invoice-tax-document/find-all', filter, { params }
        ).pipe(map((response: any) => response.data.content));
    }

    findById(invoiceTaxDocumentId): Observable<any> {
        return this.http.get(environment.apiHost + '/secure/invoice-tax-document/' + invoiceTaxDocumentId)
            .pipe(map((response: any) => response.data.orderItem));
    }

    cancelInvoiceTax(invoiceAndinvTaxId: any): Observable<any> {
        return this.http.put(environment.apiHost + '/secure/invoice-tax-document/cancel', invoiceAndinvTaxId)
            .pipe(map((response: any) => response.data));
    }
}
