<p-fieldset>
  <p-header class="article-title">
    <div class="article-title">{{ "SAILOR" | translate }}</div>
  </p-header>
  <div class="table">
    <p-table
      [value]="logsTable()"
      [rows]="5"
      [paginator]="true"
      autoLayout="true"
      alwaysShowPaginator="false"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>{{ "NAME" | translate }}</th>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-boatSailorLogs
        let-rowIndex="rowIndex"
      >
        <tr [ngClass]="{'item-new-log': isChanged(boatSailorLogs) && isNew, 'item-old-log': isChanged(boatSailorLogs) && !isNew}">
          <td>
            <span>
              {{ boatSailorLogs.fullName }}
            </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-fieldset>
<br />
