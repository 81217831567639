import { Injectable } from '@angular/core';
import { Cep, City } from '../models';
import { Observable } from 'rxjs';
import { APPCONFIG } from '../config';
import { BrStates } from '../utils/location/br_states';
import { Countries } from '../utils/location/countries';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class LocationService {
    constructor(
        private http: HttpClient
    ) {
    }

    getCountries(): any[] {
        return Countries.countryList;
    }

    getStates(): any[] {
        return BrStates.statesList;
    }

    getCities(countryId, stateId): Observable<City[]> {
        return this.http.get(
            APPCONFIG.apiHost + '/secure/location' + '/country/' + countryId + '/state/' + stateId + '/city'
        ).pipe(map(
            // tslint:disable-next-line: deprecation
            (response: any) => response.data.cities
        ));
    }

    getCep(cep): Observable<Cep> {
        return this.http.get(
            APPCONFIG.apiHost + '/secure/location/cep/' + cep
        ).pipe(map(
            // tslint:disable-next-line: deprecation
            (response: any) => response.data.cep
        ));
    }

}
