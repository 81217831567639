<div class="dialog-form" *ngIf="sling">
    <div class="boat-details">
        <div class="card card-white">
            <div class="card-image">
                <img src="{{imageUtil.getBoatImage(sling?.boat)}}">
            </div>
        </div>
        <div class="card-content">
            <div class="boat-info">
                <div class="name">{{sling?.boat?.name}}</div>
                <div *ngIf="sling?.boat?.vacancy">
                    {{sling?.boat?.vacancy ? sling?.boat?.vacancy?.code : ""}}
                </div>
                <div>
                    {{sling?.boat?.shipyardModel?.shipType.toUpperCase() | translate}}&nbsp;
                    {{sling?.boat?.commercialLength}}'
                </div>
            </div>
        </div>
    </div>

    <div class="accessories" *ngIf="sling">
        <div class="card-box" *ngFor="let slingAccessory of sling.slingAccessories">
            <div class="image-box">
                <div class="image">
                    <img src="{{slingAccessory.accessory.urlPhoto}}" />
                </div>
                <div class="description">
                    {{slingAccessory.accessory.name}}
                </div>
            </div>
            <div class="checkbox">
                <p-checkbox [name]="'slingAccessory' + slingAccessory.accessory.id"
                    [(ngModel)]="slingAccessory.attended" [id]="'slingAccessory' + slingAccessory.accessory.id"
                    binary="true">
                </p-checkbox>
            </div>
        </div>
    </div>

    <div class="buttons">
        <button class="easymarine danger" (click)="dialog.close()">{{ 'CANCEL' | translate }}</button>
        <button class="primary easymarine" (click)="confirmAccessoryCheck()">{{ 'CONFIRM' | translate }}</button>
    </div>
</div>