import { User, Address } from 'src/app/models';
import { Boat } from './boat';
import { BoatSailor } from './boat-sailor';
import { Licence } from "./license";
import { BoatCustomerTooltipDTO } from './dtos/boatCustomerTooltipDTO';

export class Sailor {
    id: number;
    name: string;
    lastName: string;
    user_id: string;
    federalId: string;
    federalIdType: string;
    phone: number;
    birthday: Date;
    docUrl: string;
    docUrlBack: string;
    federalIdUrl: string;
    photoUrl: string;
    status: string;
    email: string;
    licence: Licence
    typesSailor: string[];
    active: boolean;
    boatSailor: BoatSailor[];
    userApp: boolean;
    residentialAddress: Address;
    boats: Boat[];
    nameBoats?: string[];
    nameCustomer?: string[];
    boatCustomerTooltipDTOList?: BoatCustomerTooltipDTO[];

    //comeca a tirar
    licenceRegister: number;
    licenceExpiration: Date;
    typeLicense: string;
    licenceCountry: string;
    licenceState: string;
    licenceCity: string;
    residentialZIP: number;
    residentialCountry: string;
    residentialState: string;
    residentialCity: string;
    residentialStreet: string;
    residentialDistrict: string;
    residentialNumber: number;
    residentialComplement: string;
// termina

    emergencyContact: string;
    emergencyPhone: string;
    observation:string;
    user:User;
    countryRegistration: string;


constructor() {
  this.id= null;
  this.name=null;
  this.lastName=null;
  this.user_id=null;
  this.federalId=null;
  this.federalIdType=null;
  this.phone=null;
  this.birthday=null;
  this.docUrl=null;
  this.docUrlBack=null;
  this.federalIdUrl=null;
  this.photoUrl=null;
  this.status=null;
  this.licence= new Licence;
  this.typesSailor= null;
  this.active=true;
  this.boatSailor=null;
  this.userApp=null;
  this.boats= null;
  this.nameBoats= null;
  this.nameCustomer=null;
  this.boatCustomerTooltipDTOList=null;
  this.emergencyContact=null;
  this.emergencyPhone=null;
  this.observation=null;
  this.countryRegistration=null;
  this.user = new User();
  this.residentialAddress = new Address();

}


}
