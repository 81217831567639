import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccessGroupLogService } from 'src/app/services/access-controler/access-group-log.service';
import { AccessGroupService } from 'src/app/services/access-controler/access-group.service';
import { UserService } from 'src/app/services/user.service';
import { StorageUtil } from 'src/app/utils/storage.util';

@Component({
  selector: 'app-access-group-log',
  templateUrl: './access-group-log.component.html',
  styleUrls: ['./access-group-log.component.scss']
})
export class AccessGroupLogComponent {
  
  filter: any = {
    dateStart: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
    dateEnd: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
    marinaId: null,
  };
  accessGroupLogs: any;
  filteredGroups: any;  
  filteredUsers = [];


  constructor(
    private spinner: NgxSpinnerService,
    private accessGroupService: AccessGroupService,
    private logService: AccessGroupLogService,
    private userService: UserService,
  ) { };

  ngOnInit(): void {    
    this.filter.marinaId = StorageUtil.getMarina().id;
    this.findGroups();
    this.findLogs();
    this.loadUsers();
  }

  private findGroups(): void {
    this.spinner.show();  
    this.accessGroupService.findGroups(this.filter).subscribe({
      next: (data) => {
        this.accessGroupLogs = data;
      },
      error: (error) => {
        this.spinner.hide();
        console.error(error);
      },
      complete: () => {
        this.spinner.hide();
      }
    });
  }

  findLogs(): void {
    this.spinner.show();  
    this.logService.findGroupLogs(this.filter).subscribe({
      next: (data) => {
        this.accessGroupLogs = data;
      },
      error: (error) => {
        this.spinner.hide();
        console.error(error);
      },
      complete: () => {
        this.spinner.hide();
      }
    });
  }

  private loadUsers(): void {
    this.userService.getMarinaUsers().subscribe(
      (data) => {
        this.filteredUsers = data.map((c) => ({ label: c.firstName.concat(' ').concat(c.lastName), value: c.id }));
        this.filteredUsers.unshift({ label: 'Todos', value: null });
      }
    );
  }
}
