import { Component, OnInit } from '@angular/core';
import {
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-boat-log-modal',
  templateUrl: './boat-log-modal.component.html',
  styleUrls: ['./boat-log-modal.component.scss'],
})
export class BoatLogModalComponent implements OnInit {
  boatLog;
  selectTab = 0;

  owners;
  authorizeds;
  ownersOld;
  authorizedsOld;
  accessoryAccessory;
  accessoryAccessoryOld;
  accessoryOptional;
  accessoryOptionalOld;
  serviceBoats;
  serviceBoatsOld;
  sailors;
  sailorsOld;

  isGeneralChange = false;
  isCustomerOwnerChange =false;
  isCustomerAuthorizedChange =false;
  isHullChange = false;
  isTieChange = false;
  isInsurerChange = false;
  isOperatorChange = false;
  isAccessoryOptionalChange = false;
  isAccessoryAccessoryChange = false;
  isServiceChange = false;
  isSailorChange = false;

  isGeneralData = false;
  isInventory = false;
  isCustomers = false;
  isServices = false;

  constructor(
    private config: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    if(!this.config.data.boatLog || !this.config.data.boatLog.oldDatas){
      this.dialogRef.close();
    }
    this.boatLog = this.config.data.boatLog;
    this.createLogs();
    this.setSelectTab();
  }

  createLogs() {
    this.verifyChanges()
    this.createLogCustomer();
    this.createLogAccessory();
    this.createLogServiceBoat();
  }

  setSelectTab() {
    this.isGeneralData = this.isGeneralChange
      || this.isCustomerOwnerChange
      || this.isCustomerAuthorizedChange
      || this.isHullChange
      || this.isTieChange
      || this.isInsurerChange
      || this.isOperatorChange
      || this.isSailorChange;

    this.isServices = this.isServiceChange;

    if(this.isGeneralData){
      this.selectTab = 0;
    } else if(this.isInventory){
      this.selectTab = 1;
    } else {
      this.selectTab = 2;
    }
  }

  createLogServiceBoat() {
    this.serviceBoats = this.boatLog.boatServices;
    this.serviceBoatsOld = this.boatLog.oldDatas.boatServices;
  }

  createLogAccessory() {
    const oldDatas = this.boatLog.oldDatas;
    if(this.isInventory){
      this.accessoryAccessory = this.boatLog.boatAccessories.filter(
        (ac) => ac.accessory.accessoryType === 'Accessory'
      );
      this.accessoryOptional = this.boatLog.boatAccessories.filter(
        (ac) => ac.accessory.accessoryType === 'Optional'
      );
      this.accessoryAccessoryOld = oldDatas.boatAccessories.filter(
        (ac) => ac.accessory.accessoryType === 'Accessory'
      );
      this.accessoryOptionalOld = oldDatas.boatAccessories.filter(
        (ac) => ac.accessory.accessoryType === 'Optional'
      );

      this.isAccessoryAccessoryChange = this.accessoryAccessory.length > 0 || this.accessoryAccessoryOld.length > 0;
      this.isAccessoryOptionalChange = this.accessoryOptional.length > 0 || this.accessoryOptionalOld.length > 0;
    }
  }

  createLogCustomer() {
    if(this.isCustomers){
      this.owners = this.boatLog.boatCustomers.filter(
        (bc) => bc.type === 'Owner'
      );
      this.authorizeds = this.boatLog.boatCustomers.filter(
        (bc) => bc.type !== 'Owner'
      );
      this.ownersOld = this.boatLog.oldDatas.boatCustomers.filter(
        (bc) => bc.type === 'Owner'
      );
      this.authorizedsOld = this.boatLog.oldDatas.boatCustomers.filter(
        (bc) => bc.type !== 'Owner'
      );

      this.isCustomerOwnerChange = this.owners.length > 0 || this.ownersOld.length > 0;
      this.isCustomerAuthorizedChange = this.authorizeds.length > 0 || this.authorizedsOld.length > 0;
    }
  }

  createLogSailor() {
    if(this.isSailorChange) {
      this.sailors = this.boatLog.boatSailorLogs.filter()
    }
  }

  verifyChanges() {
    const oldDatasMap = new Map(Object.entries(this.boatLog.oldDatas));
    this.verifyChangesGeneral(oldDatasMap);
    this.verifyChangesCustomer(oldDatasMap);
    this.verifyChangesAcessory(oldDatasMap)
    this.verifyChangesHull(oldDatasMap);
    this.verifyChangesTie(oldDatasMap);
    this.verifyChangesInsurer(oldDatasMap);
    this.verifyChangesOperator(oldDatasMap);
    this.verifyChangesServiceBoat(oldDatasMap);
    this.verifyChangesSailor(oldDatasMap);
  }

  verifyChangesAcessory(oldDatasMap: Map<string, unknown>) {
    this.isInventory = oldDatasMap.has('boatAccessories');
  }

  verifyChangesGeneral(oldDatasMap: Map<string, unknown>) {
    const changeVacancy = oldDatasMap.has('vacancy');
    this.isGeneralChange = this.boatLog.oldDatas && (oldDatasMap.has('name')
      || changeVacancy || oldDatasMap.has('maintenance') || oldDatasMap.has('active'))
      || oldDatasMap.has('boatProfile')
      || oldDatasMap.has('movementLocation')
      || oldDatasMap.has('movementGroup')
    }

  verifyChangesServiceBoat(oldDatasMap: Map<string, unknown>) {
    this.isServiceChange = oldDatasMap.has('boatServices');
  }
  verifyChangesOperator(oldDatasMap: Map<string, unknown>) {
    this.isOperatorChange = oldDatasMap.has('operator');
  }
  verifyChangesInsurer(oldDatasMap: Map<string, unknown>) {
    this.isTieChange = oldDatasMap.has('tie');
  }
  verifyChangesTie(oldDatasMap: Map<string, unknown>) {
    this.isInsurerChange = oldDatasMap.has('boatInsurer');
  }

  verifyChangesHull( oldDatasMap: Map<string, unknown>){
    this.isHullChange = oldDatasMap.has('beam') || oldDatasMap.has('draft') || oldDatasMap.has('draft') || oldDatasMap.has('length') || oldDatasMap.has('commercialLength') || oldDatasMap.has('manufactureYear')
    || oldDatasMap.has('modelYear') || oldDatasMap.has('mainColor') || oldDatasMap.has('crew') || oldDatasMap.has('passengersDay') || oldDatasMap.has('passengersNight') || oldDatasMap.has('cabins')
    || oldDatasMap.has('bathrooms') || oldDatasMap.has('bowType') || oldDatasMap.has('fuel')
    || oldDatasMap.has('flyBridge') || oldDatasMap.has('twoTimeEngine') || oldDatasMap.has('chassiHull');
  }

  verifyChangesCustomer(oldDatasMap: Map<string, unknown>){
    this.isCustomers = oldDatasMap.has('boatCustomers');
  }

  verifyChangesSailor(oldDatasMap) {
    const sailorLogs = this.boatLog.boatSailorLogs;
    const oldSailorLogs = this.boatLog.oldDatas?.boatSailorLogsOld;

    if (oldDatasMap.has('boatSailorLogsOld') && (oldSailorLogs.length > 0 || sailorLogs.length > 0)) {
      this.sailors = sailorLogs;
      this.sailorsOld = oldSailorLogs;
      this.isSailorChange = sailorLogs.length > 0 || oldSailorLogs > 0 ;
    }
  }
}
