import {AfterViewInit, ChangeDetectorRef, Component, HostListener, NgZone, OnInit} from '@angular/core';
import { BroadcastService } from 'src/app/services/broadcast.service';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {

  sidebar = true;
  hover = false;
  animate = false;
  dock = true;
  close = false;
  animated = false;

  @HostListener('window:resize', ['$event']) onScroll($event: Event): void {
    this.adjustWidth();
  }

  constructor(private cdr: ChangeDetectorRef) {

  }

  ngOnInit(): void {
    this.adjustWidth();
    this.sidebar = false;
    BroadcastService.get('showHideSidenav').subscribe(
      (event) => {
        this.sidebar = event;
        if (event) {
          this.animate = true;
          setTimeout(() => {
            this.animate = false;
          }, 1000);
        } else {
          this.close = true;
          setTimeout(() => {
            this.close = false;
          }, 1000);
        }
      }
    );
  }

  adjustWidth(): void {
    if (window.outerWidth <= 992) {
      this.sidebar = false;
      this.dock = false;
      this.animated = true;
    } else {
      this.sidebar = true;
      this.animated = false;
      this.dock = true;
    }
  }
}
