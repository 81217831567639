import { Component, Input, OnInit } from '@angular/core';
import { MovementLocationType } from 'src/app/models/enums/movements/movement-location-type';
import { MessageUtil } from 'src/app/utils/message.util';

export interface MovementLocationParams {
  name?: string;
  type: MovementLocationType;
  vacancyCode?: string;
  abbreviation: string;
}

@Component({
  selector: 'app-movement-location',
  templateUrl: './movement-location.component.html',
  styleUrls: ['./movement-location.component.scss']
})
export class MovementLocationComponent implements OnInit {

  @Input()
  params: MovementLocationParams

  title: string = '';

  constructor(
    private messageUtil: MessageUtil
  ) { }

  ngOnInit() {
    this.title = this.getTitle(this.params)
  }

  getTitle(params: MovementLocationParams): string {
    if (params) {
      if (!params.name || params.type === MovementLocationType.NAVIGATION) {
        return this.title = this.messageUtil.translateKey('MOVEMENT.LOCALES.' + params.type);
      }
      if (!params.vacancyCode) {
        return params.name
      }
      return params.name + ' | ' + params.vacancyCode
    }
  }
}
