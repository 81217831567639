import { Component, Input, OnInit } from '@angular/core';
import { MovementLocationType } from 'src/app/models/enums/movements/movement-location-type';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { MessageUtil } from 'src/app/utils/message.util';

@Component({
  selector: 'app-movement-execution-operation-log-table',
  templateUrl: './movement-execution-operation-log-table.component.html',
  styleUrls: ['./movement-execution-operation-log-table.component.scss']
})
export class MovementExecutionOperationLogTableComponent implements OnInit {

  @Input()
  movementLogs = [];

  @Input()
  isDialog = false;

  numberOfRows = 10;

  constructor(
    private slingConfigService: SlingConfigService,
    private messageUtil: MessageUtil
  ) { }

  ngOnInit(): void {
    if ( !this.isDialog) {
      this.loadSlingConfig();
    }
  }

  loadSlingConfig(): void {
    this.slingConfigService.getSlingConfig().subscribe(
      (data) => {
        const slingConfig = data[0];
        if (slingConfig != null) {
          this.numberOfRows = slingConfig.numberOfRecordsPerPage;
        } else {
          this.numberOfRows = 10;
        }
      },
      (error) => null);
  }

  getLocationDestiny(log): string {
    if (!log?.destinyLocationName || log.destinyType === MovementLocationType.NAVIGATION) {
      return this.messageUtil.translateKey('MOVEMENT.LOCALES.' + log.destinyType);
    }
    return log.destinyLocationName + ' | ' + (log.destinyVacancyCode ? log.destinyVacancyCode : '---');
  }

  getLocationOrigin(log): string {
    if (!log.originLocationName || log.originType === MovementLocationType.NAVIGATION) {
      return this.messageUtil.translateKey('MOVEMENT.LOCALES.' + log.originType);
    }
    return log.originLocationName + ' | ' + (log.originVacancyCode ? log.originVacancyCode : '---');
  }

}
