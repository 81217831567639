<div class="dialog-form" styleClass="no-border">
    <form class="form-group" [formGroup]="duedateForm" (submit)="submit()">
        <div class="form-control">
            <label>Tipo de vencimento</label>
            <p-dropdown placeholder="Selecione..." [options]="[
                            {label: 'Data fixa', value: 'FIXED_DATE'},
                            {label: 'Vencimento cliente', value: 'CLIENT_EXPIRATION'}]" formControlName="maturityType"
                appendTo="body" name="maturityType" (onChange)="updateForm()">
            </p-dropdown>
        </div>
        <div class="form-control" *ngIf="duedateForm?.value?.maturityType === 'CLIENT_EXPIRATION'">
            <label>Mês de cobrança</label>
            <p-dropdown placeholder="Selecione..." [options]="monthsReference" formControlName="referenceMonth"
                appendTo="body" name="referenceMonth">
            </p-dropdown>
        </div>
        <div class="form-control" *ngIf="duedateForm?.value?.maturityType === 'CLIENT_EXPIRATION'">
            <label>Ano de cobrança</label>
            <p-dropdown placeholder="Selecione..." [options]="yearOptions" formControlName="referenceYear" name="referenceYear"
                appendTo="body">
            </p-dropdown>
        </div>
        <div class="form-control" *ngIf="duedateForm?.value?.maturityType == 'FIXED_DATE'">
            <label>Gerar faturas para</label>
            <p-calendar dateFormat="dd/mm/yy" [minDate]="minDueDate" name="dueDate" [monthNavigator]="true"
                appendTo="body" [yearNavigator]="true" [yearRange]="'2000:2055'" rPCalendarMask
                formControlName="dueDate" [showIcon]="true" i18n>
            </p-calendar>
        </div>
        <div class="form-control" *ngIf="isCompetence()">
            <label>Data de competência</label>
            <p-calendar dateFormat="dd/mm/yy" name="competenceDate" [monthNavigator]="true"
                appendTo="body" [yearNavigator]="true" [yearRange]="'2000:2055'" rPCalendarMask
                formControlName="competenceDate" [showIcon]="true" i18n>
            </p-calendar>
        </div>
        <div class="buttons">
            <button type="submit" *ngIf="duedateForm.valid" class="easymarine primary">Gerar faturas</button>
        </div>
    </form>
</div>
