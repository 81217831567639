import { OrderStatus } from './order-status';
import { Customer } from './customer';
import { Boat } from './boat';
import { Marina } from './marina';
import { OrderItem } from './order-item';
import { OrderQuota } from './order-quota';
import { Sling } from '.';
import { SelectorBoatDTO } from './dtos/selector-boat-DTO';

export class MarinaOrder {
    id: number;
    value: number;
    orderDate: any;
    orderObs: string;
    orderStatus: OrderStatus;
    customer: Customer;
    boat: Boat | SelectorBoatDTO;
    marina: Marina;
    orderItems: OrderItem[];
    orderQuotas: OrderQuota[];
    sling: Sling;
    paymentMethodId: number;
    paymentMethodName: string;
    accountId: number;
    accountName: string;
    discount?: number;
    origin: string;
    pay?: boolean;

    orderNumber?: number;

    cashDown: number;
    lastEmployeeName?: string;
    constructor() {
        this.orderStatus = OrderStatus.Ordered;
        this.customer = new Customer();
        this.boat = new Boat();
        this.marina = new Marina();
        this.orderItems = [];
        this.orderQuotas = [];
        this.cashDown = 0;
        // this.sling = new Sling();
    }
}
