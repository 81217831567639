import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-tuition-summary',
  templateUrl: './tuition-summary.component.html',
  styleUrls: ['./tuition-summary.component.scss']
})
export class TuitionSummaryComponent implements OnInit {

  monthlysSummary: any = new Array();
  monthlysSummaryByGroup: any = new Array();
  monthlysSummaryByOrigin: any = new Array();
  origin: string;

  constructor(
    public dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.monthlysSummary = this.config.data.monthlysSummary;
    this.monthlysSummaryByGroup = this.config.data.monthlysSummaryByGroup;
    this.monthlysSummaryByOrigin = this.config.data.monthlysSummaryByOrigin;
    this.origin = this.config.data.origin;
  }

  getTotalValue(monthlysSummary): number {
    return monthlysSummary.reduce((total, monthlySummary) => total + monthlySummary.total, 0);
  }

  getTotalCustomer(monthlysSummary): number {
    return monthlysSummary.reduce((total, monthlySummary) => total + monthlySummary.customerQty, 0);
  }

  getTotalOrder(monthlysSummary): number {
    return monthlysSummary.reduce((total, monthlySummary) => total + monthlySummary.orderQty, 0);
  }

}
