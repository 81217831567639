import { MovementPlanViewDTO } from './../../models/dtos/movements/view-location/movment-plan-view-dto';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterMovementPlan } from 'src/app/models/dtos/movements/filter-movement-plan';
import { MovementPlanByObjectiveCreateDTO } from 'src/app/models/dtos/movements/movement-plan-by-objective-createDTO';
import { MovementPlanDTO } from 'src/app/models/dtos/movements/movement-plan-dto';
import { MovementPlanViewLocationDTO } from 'src/app/models/dtos/movements/view-location/movement-plan-view-location-dto';
import { MovementPlan } from 'src/app/models/movement/movement-plan';
import { PaginationFilter } from 'src/app/models/pagination-filter';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MovementPlanService {
  private readonly apiUrl = environment.apiHost + '/secure/movement-plan/'

  constructor(
    private readonly http: HttpClient
  ) { }

  saveByObjective(movementPlan: MovementPlanByObjectiveCreateDTO): Observable<any> {
    return this.http.post(this.apiUrl + 'objective', movementPlan)
    .pipe(map((res: any) => {
      return res.data;
    }))
  }

  get(movementPlanId: number): Observable<MovementPlan> {
    return this.http.get(this.apiUrl + movementPlanId)
    .pipe(map((res: any) => {
      return res.data.movementPlan as MovementPlan;
    }))
  }

  findEditByObjective(movementPlanId: number): Observable<MovementPlanByObjectiveCreateDTO> {
    return this.http.get(`${this.apiUrl}/${movementPlanId}/edit`)
    .pipe(
      map((res: any) => {
        return res.data.movementPlanEdit as MovementPlanByObjectiveCreateDTO;
      }),
      map((dto: MovementPlanByObjectiveCreateDTO) => {
        dto.executionDate = new Date(dto.executionDate);
        dto.returnTime = new Date(dto.returnTime);
        return dto;
      })
    )
  }

  getMovementPlans(filter: FilterMovementPlan, paginationFilter: PaginationFilter): Observable<MovementPlanDTO[]> {

    let params = new HttpParams();

    if (!paginationFilter.sort || !paginationFilter.order) {
      paginationFilter.sort = 'id';
      paginationFilter.order = 'DESC';
    }

    params = params.set('sort', paginationFilter.sort.concat(',').concat(paginationFilter.order));

    if (!paginationFilter.size) {
      paginationFilter.size = 0;
    }

    params = params.set('page', paginationFilter.page.toString());
    params = params.set('size', paginationFilter.size.toString());

    return this.http.post(this.apiUrl + 'movement-plan-by-filter', filter, { params })
    .pipe(map((res: any) => {
      return res.data.movementPlans as MovementPlanDTO[];
    }))
  }

  getMovementPlanLocations(movementPlanId: number): Observable<MovementPlanViewDTO> {
    return this.http.get(this.apiUrl + movementPlanId + '/view-locations')
    .pipe(map((res: any) => {
      return res.data.view as MovementPlanViewDTO;
    }))
  }

  cancel(id: number): Observable<void> {
    return this.http.delete(this.apiUrl + 'cancel/' + id).pipe(map((response: any) => response));
  }

  getMovementPlanPDF(movementId: number): Observable<any> {
    return this.http.get(`${this.apiUrl}${movementId}/create-pdf`).pipe(map((response: any) => response.data.printPDF));
  }

  getMultipleMovementPlanPDF(movementIds: number[]): Observable<any> {
    return this.http.get(`${this.apiUrl}${movementIds.toString()}/create-multiple-pdf`)
      .pipe(map((response: any) => response.data.printPDF));
  }

  getMovementInCurse(boatId: number): Observable<boolean> {
    return this.http.get(this.apiUrl +  'movementplanincurse/' + boatId)
    .pipe(map((res: any) => {
      return res.data.movementPlan as boolean;
    }));
  }

}
