<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{'TITLE-OPERATIONAL-SLING-CHECK' | translate}}</span>
        <div class="header-buttons">
            <button class="fab-button primary" pTooltip="Saída por Terra" tooltipPosition="bottom" appHideMobile
                (click)="openLandExitModal()">
                <i class="pi pi-arrow-up rotate-45"></i>
            </button>
            <button class="fab-button danger" (click)="openModalUserInfo()" pTooltip="Informações"
                tooltipPosition="bottom" appHideMobile>
                <fa-icon [icon]="'address-card'" style="font-size: 18px;"></fa-icon>
            </button>
            <button class="fab-button primary" [routerLink]="['/sling-totem']" pTooltip="Lingadas"
                tooltipPosition="bottom" appHideMobile>
                <fa-icon [icon]="'ship'" style="font-size: 18px;"></fa-icon>
            </button>
            <button class="fab-button primary" [routerLink]="['/debits-totem']" pTooltip="Débitos"
                tooltipPosition="bottom" appHideMobile>
                <i class="pi pi-money-bill"></i>
            </button>
            <button class="fab-button primary" [routerLink]="['/navigation-plan-panel']" pTooltip="Plano de navegação"
                tooltipPosition="bottom" appHideMobile>
                <i class="pi pi-map-marker"></i>
            </button>
            <button class="fab-button primary" [routerLink]="['/boat-maintenance']" pTooltip="Lavação/Giro de motor"
                tooltipPosition="bottom" appHideMobile>
                <i class="pi pi-cog"></i>
            </button>
            <button class="fab-button primary" [routerLink]="['/single-order']" pTooltip="Pedido avulso"
                tooltipPosition="bottom" appHideMobile>
                <i class="pi pi-shopping-cart"></i>
            </button>
            <button class="fab-button primary" (click)="reload()" pTooltip="Atualizar lista" tooltipPosition="bottom">
                <i class="pi pi-refresh"></i>
            </button>
        </div>
    </p-header>

    <p-tabView styleClass="sling-check" (onChange)="onChangeSelectTab($event)" *ngIf="slingConfig"
        [activeIndex]="activeIndex">
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="pi pi-arrow-down"></i>
                {{ 'SLINGDOWNQUEUE' | translate }}
            </ng-template>
            <app-sling-down-tab [slingConfig]="slingConfig" [isMobile]="isMobile"
                *ngIf="enableSlingDown()"></app-sling-down-tab>
        </p-tabPanel>
        <p-tabPanel *ngIf="slingConfig?.queueControl">
            <ng-template pTemplate="header">
                <img src="assets/images/sea.png" alt="" style="height: 16px; width: auto;margin-right: 7px;">
                {{ 'WATER' | translate }}
            </ng-template>
            <app-sling-water-tab [isMobile]="isMobile" [slingConfig]="slingConfig"
                *ngIf="enableSlingWater()"></app-sling-water-tab>
        </p-tabPanel>
        <p-tabPanel>
            <ng-template pTemplate="header">
                <i class="pi pi-arrow-up"></i>
                {{ 'SLINGUPQUEUE' | translate }}
            </ng-template>
            <app-sling-up-tab [isMobile]="isMobile" [slingConfig]="slingConfig"
                *ngIf="enableSlingUp()"></app-sling-up-tab>
        </p-tabPanel>
    </p-tabView>
</p-fieldset>