import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { InviteUse } from 'src/app/models/securities-property/invite-use';
import { SecuritiesProperty } from 'src/app/models/securities-property/securities-property';
import { SecuritiesPropertyService } from 'src/app/services/securities-property/securities-property.service';
import { MessageUtil } from 'src/app/utils/message.util';

@Component({
  selector: 'app-securities-property-invite-use',
  templateUrl: './securities-property-invite-use.component.html',
  styleUrls: ['./securities-property-invite-use.component.scss'],
})
export class SecuritiesPropertyInviteUseComponent implements OnInit {
  startDate: any;
  endDate: any;
  listHistoricOrigin: InviteUse[] = [];
  listHistoric: InviteUse[] = [];
  securitiesProperty: SecuritiesProperty = new SecuritiesProperty();
  constructor(
    private messageUtil: MessageUtil,
    private securitiesPropertyService: SecuritiesPropertyService,
    private config: DynamicDialogConfig
  ) {}

  ngOnInit() {
    this.securitiesProperty = this.config.data.securitiesProperty;
    this.getHistoric();
  }

  getHistoric(): void {
    this.securitiesPropertyService
      .getInviteUsed(this.securitiesProperty.id)
      .subscribe({
        next: (data) => {
          this.listHistoricOrigin = data;
          this.listHistoric = data;
        },
        error: (error) => {
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(
            exception.type,
            exception.title,
            exception.message
          );
        },
      });
  }

  filtrar(): void {
    if (this.startDate == null && this.endDate == null) {
      this.listHistoric = this.listHistoricOrigin;
      return;
    }
    if (this.startDate != null && this.endDate != null) {
      const startDateISO = this.startDate.toISOString().split('T')[0];
      const endDateISO = this.endDate.toISOString().split('T')[0];
      this.listHistoric = this.listHistoricOrigin.filter((x) => {
        return x.emissionDate >= startDateISO && x.emissionDate <= endDateISO;
      });
      return;
    }

    if (this.startDate != null) {
      const startDateISO = this.startDate.toISOString().split('T')[0];
      this.listHistoric = this.listHistoricOrigin.filter((x) => {
        return x.emissionDate >= startDateISO;
      });
    }

    if (this.endDate != null) {
      const endDateISO = this.endDate.toISOString().split('T')[0];
      this.listHistoric = this.listHistoricOrigin.filter((x) => {
        return x.emissionDate <= endDateISO;
      });
    }
  }
}
