import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { CustomReportExportComponent } from 'src/app/components/extract-custom-report/custom-report-export.component';
import { Product, SlingConfig } from 'src/app/models';
import { ProductFilter } from 'src/app/models/dtos/productFilter';
import { ProductType } from 'src/app/models/enums';
import { OrderControl } from 'src/app/models/enums/order-control';
import { MarinaCompany } from 'src/app/models/marina-company';
import { FinancesService } from 'src/app/services/finances.service';
import { FinancialMultipleCompaniesService } from 'src/app/services/financial/financial-multiple-companies.service';
import { ProductCategoryService } from 'src/app/services/product-category.service';
import { ProductService } from 'src/app/services/product.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';
import Swal from 'sweetalert2';
import { ProductFormComponent } from './product-form/product-form.component';
import { ServiceLinkedBoats } from 'src/app/models/dtos/service-linked-boats';
import { OperationalConfigService } from 'src/app/services/config/operational-config.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  numberOfRows = 10;
  products: any[] = [];
  productsTable: any[] = [];
  showInactive = false;
  listaPlanoContasFlat: any[] = [];
  listaCentroCustoFlat: any[] = [];
  productTypeOptions : any[] = [];
  categoryOptions: any[] = [];
  globalFilter = '';

  optionsFilter: any = [
    {label: 'Ativo', value: "true"},
    {label: 'Inativo', value: "false"}
  ];


  slingConfig: SlingConfig;
  hasMultipleCompanyByProduct: boolean = false;
  hasCostCenterByProduct: boolean = false;
  marinaCompanies: MarinaCompany[] = [];
  productFilter: ProductFilter = new ProductFilter();

  orderControlOptions = [
    { label: 'Easymarine', value: OrderControl.EASYMARINE },
    { label: 'Terceiro', value: OrderControl.THIRD_PARTY },
    { label: 'Terceiro importação', value: OrderControl.THIRD_PARTY_IMPORT },
  ];
  activateTuitionByCustomer: any;

  constructor(
    public dialog: DialogService,
    private productService: ProductService,
    private messageService: MessageUtil,
    private spinner: NgxSpinnerService,
    private financesService: FinancesService,
    private messageUtil: MessageUtil,
    private slingConfigService: SlingConfigService,
    private productCategoryService: ProductCategoryService,
    private financialMultipleCompaniesService: FinancialMultipleCompaniesService,
    private operationalConfigService: OperationalConfigService
  ) { }

  async ngOnInit(): Promise<void> {
    this.getTuitionConfig();

    this.productTypeOptions = [
      { label: "Produto", value: ProductType.Product },
      { label: "Serviço", value: ProductType.Service },
      { label: "Serviço periódico - Embarcação", value: ProductType.PeriodicalService },
    ];

    await this.loadSlingConfig();
    await this.getProductCategories();
    this.enableMultipleCompany(this.slingConfig);
    this.hasCostCenterByProduct = this.slingConfig.costCenterInStock;
    this.listaPlanoContasFlat = await this.getPlanoContasReceita();
    this.listaCentroCustoFlat = await this.getCentroCusto();
    await this.find();
    this.checkMarinaHasPeriodicalServiceByCustomer();
  }
  
  async getTuitionConfig() {
    this.activateTuitionByCustomer = await this.operationalConfigService.hasCustomerTuition();
  }

  async getProductCategories(): Promise<void> {
    this.productCategoryService.getAll().subscribe((data) => {
      this.categoryOptions = data.map((category) => {
              return ({ label: category.name, value: category.id })
        })
      })
  }

  async loadSlingConfig(): Promise<void> {
    return this.slingConfigService.getSlingConfig().toPromise()
      .then((data) => {
        const slingConfig = data[0];
        this.numberOfRows = slingConfig != null ? slingConfig.numberOfRecordsPerPage : 10;
        this.slingConfig = slingConfig;
      })
      .catch((err) => {
        this.numberOfRows = 10;
      }
    );
  }



  exportTable(): void {
    const data = { table: this.productsTable, type: 'PRODUCT' };
    this.dialog.open(CustomReportExportComponent, {
      width: '70%',
      height: '90vh',
      dismissableMask: false,
      data,
      header: this.messageUtil.translateKey('EXPORT-PRODUCT-TABLE'),
      });
  }

  getTipoControle(orderControl: OrderControl): string {
    switch (orderControl) {
      case OrderControl.EASYMARINE:
        return 'Easymarine';
      case OrderControl.THIRD_PARTY:
        return 'Terceiro';
      case OrderControl.THIRD_PARTY_IMPORT:
        return 'Terceiro Import.';
      default:
        return '';
    }
  }

  async openProductFormDialog(id?: number): Promise<void> {
    let product: Product = null;
    this.spinner.show();
    if (id) {
      product = await this.getProductById(id);
    }
    this.dialog.open(ProductFormComponent, {
      width: '70%',
      dismissableMask: false,
      data: {
        chartAccountsFlatList: this.listaPlanoContasFlat
          .map((plano) => ({ label: plano.treeLabel, value: plano.idPlanoConta, disabled: plano.disabled })),
        product,
        costCenters: this.listaCentroCustoFlat
          .map((centro) => ({ label: centro.treeLabel, value: centro.idCentroCusto, disabled: centro.disabled }))
      },
      header: 'Cadastro de produto / serviço'
    }).onClose.subscribe(() => this.find());
    this.spinner.hide();
  }

  getPriceLabel(product: any): string | number {
    if (product.pricingType === 'SimplePrice') {
      if (product.price.priceFree) {
        return 'Livre';
      }
      return product.price.value;
    } else if (product.pricingType === 'PerRangeLength') {
      return 'Por Range';
    }
    return '';
  }

  openProductDeleteConfirmDialog(product): void {
    this.spinner.show();
    let serviceLinkedBoats: ServiceLinkedBoats[] = [];

    if(product.productType == ProductType.Service || product.productType == ProductType.PeriodicalService){
      this.productService.getBoatsFromService(product.id).subscribe({
        next: (res) => {
          serviceLinkedBoats = res;

          if(serviceLinkedBoats.length > 0){
            Swal.fire({
              title: `Serviço vinculado`,
              html: (
                `O Serviço não pode ser excluido pois possui vínculo com <b>${serviceLinkedBoats.length}</b> embarcações.`
              ),
              icon: 'warning',
              showCancelButton: false,
              backdrop: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok',
              cancelButtonText: 'Não',
              reverseButtons: true
            }).then((result) => {
              this.spinner.hide();

            })
          }
          else{
            this.spinner.hide();
            Swal.fire({
              title: 'Confirmação de Exclusão',
              text: 'Deseja realmente deletar o produto :' + product.name,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Confirmar',
              cancelButtonText: 'Cancelar',
              reverseButtons: true
            }).then((result) => {
              if (result.value) {
                this.deleteProduct(product);
              }
            });
          }
        }
      });
    }
    else{
        this.spinner.hide();
        Swal.fire({
          title: 'Confirmação de Exclusão',
          text: 'Deseja realmente deletar o produto :' + product.name,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true
        }).then((result) => {
          if (result.value) {
            this.deleteProduct(product);
          }
        });
      }
  }

  deleteProduct(product): void {
    this.productService.delete(product.id).subscribe(
      (data) => {
        this.find();
      },
      (error) => {
        console.log(error);
        this.messageService.generateMessage('error', 'Erro ao excluir produto', error.error.message );
      }
    );
  }

  // fillProductTable(): void {
  //   this.spinner.show();
  //   let produtos: Product[] = [];
  //   this.productService.getAll().subscribe(
  //     (data) => {
  //       produtos = data;
  //     },
  //     (error) => {
  //       this.spinner.hide();
  //       const exception = error.error.data.exception;
  //       this.messageService.generateMessage(exception.type, exception.title, exception.message);
  //     },
  //     async () => {
  //       this.listaPlanoContasFlat = await this.getPlanoContasReceita();
  //       this.listaCentroCustoFlat = await this.getCentroCusto();
  //       this.products = produtos;
  //       this.filterGlobal();
  //       this.spinner.hide();
  //     });
  // }

  async getPlanoContasReceita(): Promise<any[]> {
    return this.financesService.findPlanoContaReceitaFlat();
  }

  async getCentroCusto(): Promise<any[]> {
    return this.financesService.findCentroCustoFlat();
  }

  getLabelChartAccount(prod: Product): string {
    let label = '';
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.listaPlanoContasFlat.length; ++i) {
      if (this.listaPlanoContasFlat[i].idPlanoConta === prod.chartAccountId) {
        label = this.listaPlanoContasFlat[i].label;
      }
    }
    return label;
  }

  getLabelCostCenter(prod: Product): string {
    let label = '';
    for (let i = 0; i < this.listaCentroCustoFlat.length; ++i) {
      if (this.listaCentroCustoFlat[i].idCentroCusto === prod.costCenterId) {
        label = this.listaCentroCustoFlat[i].label;
      }
    }
    return label;
  }

  validateSaveStatus(product): void {
    this.spinner.show();

    let serviceLinkedBoats: ServiceLinkedBoats[] = [];

    if(product.productType == ProductType.Service || product.productType == ProductType.PeriodicalService){
      this.productService.getBoatsFromService(product.id).subscribe({
        next: (res) => {
          serviceLinkedBoats = res;

          if(serviceLinkedBoats.length > 0){
            Swal.fire({
              title: `Serviço vinculado`,
              html: (
                `O Serviço possui vínculo com <b>${serviceLinkedBoats.length}</b> embarcações. Continuar mesmo assim ?`
              ),
              icon: 'warning',
              showCancelButton: true,
              backdrop: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Ok',
              cancelButtonText: 'Não',
              reverseButtons: true
            }).then((result) => {
              this.spinner.hide();
              if(result.isConfirmed){
                this.updateStatus(product);
              }
            })
          }
          else{
            this.updateStatus(product);
          }
        }
      });
    }
    else{
      this.updateStatus(product);
    }
  }

  updateStatus(product: any): void {
    this.spinner.show();
    const prod = JSON.parse(JSON.stringify(product));
    prod.active = !product.active;
    this.productService.changeStatus(product.id,!product.active).subscribe(
      (data) => {
        product.active = prod.active;
        this.messageUtil.generateMessage('success', 'SUMMARY.SUCCESS', 'Situação atualizada com sucesso');
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      },
      () => this.spinner.hide()
    );
  }

  async getProductById(id): Promise<Product> {
    return new Promise<Product>(async (res) => {
      this.productService.getById(id).subscribe(async (p) => (res(p)));
    });
  }

  enableMultipleCompany(slingConfig: SlingConfig) {
    const hasMultipleByProduct = this.financialMultipleCompaniesService.hasFinancialMultipleCompaniesByProduct(slingConfig);
    if (hasMultipleByProduct) {
      this.marinaCompanies = StorageUtil.getMarinaCompanies();
    }
    this.hasMultipleCompanyByProduct = hasMultipleByProduct;
  }

  getCompanyIndex(company: MarinaCompany): number {
    const m = this.marinaCompanies.find((c) => c.id === company.id);
    return this.marinaCompanies.indexOf(m) + 2;
  }

  openImportDialog(){}

  downloadForm(){}

  async find(){
    this.spinner.show();
    this.productService.getAllByFilter(this.productFilter).subscribe(
      (data) => {
        this.products = data;
        this.productsTable = this.products
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title, exception.message);
      }
     );
    this.spinner.hide();
  }

  clear(){}

  private checkMarinaHasPeriodicalServiceByCustomer() {
    if (this.activateTuitionByCustomer){
      this.productTypeOptions.push({ label: "Serviço periódico - Cliente", value: ProductType.PeriodicalServiceCustomer })
    }
  }
}

