import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SlingConfig } from 'src/app/models';

@Component({
  selector: 'app-duedate-selection',
  templateUrl: './duedate-selection.component.html',
  styleUrls: ['./duedate-selection.component.scss']
})
export class DuedateSelectionComponent implements OnInit {

  monthsReference: any[] = [{ label: 'Janeiro', value: 0 }, { label: 'Fevereiro', value: 1 }, { label: 'Março', value: 2 },
  { label: 'Abril', value: 3 }, { label: 'Maio', value: 4 }, { label: 'Junho', value: 5 },
  { label: 'Julho', value: 6 }, { label: 'Agosto', value: 7 }, { label: 'Setembro', value: 8 },
  { label: 'Outubro', value: 9 }, { label: 'Novembro', value: 10 }, { label: 'Dezembro', value: 11 }];

  yearOptions: any[] =
  [
    {label: '2018', value: 2018},
    {label: '2019', value: 2019},
    {label: '2020', value: 2020},
    {label: '2021', value: 2021},
    {label: '2022', value: 2022},
    {label: '2023', value: 2023},
    {label: '2024', value: 2024},
    {label: '2025', value: 2025}
  ];

  pt = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  };

  slingConfig: SlingConfig;
  minDueDate: any;

  duedateForm;
  constructor(
    public dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.minDueDate = this.config.data?.minDueDate;
    this.slingConfig = this.config.data?.slingConfig;

    this.duedateForm = new UntypedFormGroup({
      maturityType: new UntypedFormControl({value: null, disabled: false}, Validators.required),
      referenceMonth: new UntypedFormControl({value: null, disabled: true}, Validators.required),
      referenceYear: new UntypedFormControl({value: new Date().getFullYear(), disabled: true}, Validators.required),
      dueDate: new UntypedFormControl({value: null, disabled: true}, Validators.required),
      competenceDate: new UntypedFormControl({value: null, disabled: true}, Validators.required),
    });
  }

  submit(): void{
    this.dialogRef.close(this.duedateForm.value);
  }

  updateForm(): void{
    if (this.duedateForm.get('maturityType').value === 'CLIENT_EXPIRATION'){
      this.duedateForm.get('referenceMonth').enable();
      this.duedateForm.get('referenceYear').enable();
      this.duedateForm.get('dueDate').disable();
    } else {
      this.duedateForm.get('referenceMonth').disable();
      this.duedateForm.get('referenceYear').disable();
      this.duedateForm.get('dueDate').enable();
    }
    if(this.slingConfig?.dynamicCompetenceDate){
      this.duedateForm.get('competenceDate').enable();
    }
  }

  isCompetence(): boolean {
    return this.duedateForm?.value?.maturityType && this.slingConfig?.dynamicCompetenceDate
  }
}
