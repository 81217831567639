<div class="form-control">
  <label for="boatSelect">{{ 'BOAT' | translate }}</label>
  <p-dropdown id="boatSelect" placeholder="Selecione..."
    *ngIf="!disabled; else ifBoatDisabled"
    name="selectedBoatId" emptyFilterMessage=""
    [options]="boatsFilter" [(ngModel)]="selectedBoatId"
    (onChange)="changeSelectedBoat($event)"
    (onBlur)="onTouched($event)"
    [filter]="true" appendTo="body"
    [disabled]="disabled" required>
      <ng-template let-boat pTemplate="selectedItem">
          <span [ngStyle]="{'color': boat.disabled ? 'red' : '#222222' }">{{boat.label}}</span>
      </ng-template>
      <ng-template let-boat pTemplate="item">
          <div [ngStyle]="{'padding': isToten && '5px 0px'}">{{boat.label}}</div>
      </ng-template>
  </p-dropdown>
  <ng-template #ifBoatDisabled>
    <input pInputText type="text" name="selectedBoatId" [value]="boat?.name" disabled="true">
  </ng-template>
</div>
