import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StorageUtil } from '../utils/storage.util';
import { Observable, of, retry, Subject, Subscription} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {WebSocketSubject} from "rxjs/internal/observable/dom/WebSocketSubject";
import {webSocket} from "rxjs/webSocket";

@Injectable({
    providedIn: 'root'
})
export class WebSocketService {
    private serverUrl = environment.apiNotification + '/v2/websocket-easy-marine';
    private interval: any;
    private connected: boolean = false;
    private socket$: WebSocketSubject<any>

    private SlingSocket: WebSocketSubject<any>;
    private WashEngineChangedStompSubscription: WebSocketSubject<any>;
    private ContractChangedStompSubscription: WebSocketSubject<any>;
    private MovementChangedStompSubscription: WebSocketSubject<any>;
    private NixBilletStompSubscription: WebSocketSubject<any>;
    private NixPixStompSubscription: WebSocketSubject<any>;

    private subjectSling = new Subject<any>();
    private subjectWashEngine = new Subject<any>();
    private subjectContract = new Subject<any>();
    private subjectMovement = new Subject<any>();
    private subjectNixBillet = new Subject<any>();
    private subjectNixPix = new Subject<any>();

    constructor( ) { }

    initializeWebSocketConnection() {
        this.socket$ = new WebSocketSubject<any>({
          url: this.serverUrl+"/",
          openObserver: {
            next: value => {
              this.connected = true;
              console.log(value)
              if (this.interval) {
                clearInterval(this.interval);
                this.interval = undefined;
              }
              this.checkSubscription();
            }
          },
          closeObserver: {
            next: value => {
              this.connected = false;
              console.log("WebSocket connecting closed")
              this.reconnect();
            }
          }
        }).pipe(
          retry({
            delay: 10000,
          }),
          catchError(err => {
            console.log("WebSocket connection error: ", err)
            return of(err)
          })
        ) as WebSocketSubject<any>
        this.socket$.subscribe({
          next: message => {
            console.log("Conectou websocket")
          },
          error: err => {
            console.log("Errou WS")
            console.log(err)
            this.connected = false;
          },complete: () => {
            console.log("Completou WS")
            this.cleanup();
            this.reconnect();
          }
        })
    }
    private cleanup(): void {
      this.unsubscribeWashEngineChanged(null);
      this.unsubscribeSling(null);
      this.unsubscribeContract(null);
      this.unsubscribeMovement(null);
    }
    private checkSubscription(){
        if(this.subjectSling.observers.length > 0) this.subscribeSlings();
        if(this.subjectWashEngine.observers.length > 0) this.subscribeWashEngineChanged();
        if(this.subjectContract.observers.length > 0) this.subscribeContractChanged();
        if(this.subjectMovement.observers.length > 0) this.subscribeMovementChanged();
    }
    private reconnect(): void {
        clearInterval(this.interval);
        this.interval = setInterval(() => {
          if (!this.connected) {
            console.log("Attempting to reconnect...");
            this.initializeWebSocketConnection();
          } else {
            clearInterval(this.interval);
            this.interval = null;
          }
        }, 15000);
    }
    private canUnsubscribeWebSocket(webSocketSubject:  WebSocketSubject<any>, subject: Subject<any>): boolean {
      return webSocketSubject && !webSocketSubject.closed && subject.observers.length <= 0;
    }
    private isWSConnected(socket: WebSocketSubject<any>): boolean{
      return socket !== undefined && socket !== null && !socket.closed
    }

    private subscribeSlings(): void {
        if(this.connected && !this.isWSConnected(this.SlingSocket)){
          console.log("Entrou Subscribe Sling")
          this.SlingSocket = webSocket(this.serverUrl+ '/sling-changed/' + StorageUtil.getMarinaId());
          this.SlingSocket.subscribe({
            next: () => {
              this.sendMessageSling(true);
            },error: err => {
              console.log(err)
            }
          })
        }
    }

    private subscribeWashEngineChanged(): void {
        if (this.connected && !this.isWSConnected(this.WashEngineChangedStompSubscription)) {
          this.WashEngineChangedStompSubscription = webSocket(this.serverUrl + '/wash-engine-changed/' + StorageUtil.getMarinaId())
          this.WashEngineChangedStompSubscription.subscribe({
            next: () =>{
                this.sendMessageWashEngine(true);
            },error: err => {
              console.log(err)
            }
          })
        }
    }

    private subscribeContractChanged(): void {
        if(this.connected && !this.isWSConnected(this.ContractChangedStompSubscription)){
          this.ContractChangedStompSubscription = webSocket(this.serverUrl + '/contract-changed/' + StorageUtil.getMarinaId())
          this.WashEngineChangedStompSubscription.subscribe({
            next: () =>{
                this.sendMessageContract(true);
            },error: err => {
              console.log(err)
            }
          })
        }
    }

    private subscribeMovementChanged(): void {
      if(this.connected && !this.isWSConnected(this.MovementChangedStompSubscription)){
        this.MovementChangedStompSubscription = webSocket(this.serverUrl + '/movement-changed/' + StorageUtil.getMarinaId())
        this.MovementChangedStompSubscription.subscribe({
          next: () =>{
            this.sendMessageMovement(true);
          },error: err => {
            console.log(err)
          }
        })
      }
    }

    private subscribeNixBilletChanged(invoiceId: number): void{
        if(this.connected && !this.isWSConnected(this.NixBilletStompSubscription)){
          this.NixBilletStompSubscription = webSocket(`${this.serverUrl}/nix-billet-changed/${invoiceId}`)
          this.NixBilletStompSubscription.subscribe({
            next: (message) =>{
                this.sendMessageNixBillet(message);
            },error: err => {
              console.log(err)
            }
          })
        }
    }

    private subscribeNixPixChanged(invoiceId: number): void{
        if(this.connected && !this.isWSConnected(this.NixPixStompSubscription)){
          this.NixPixStompSubscription = webSocket(`${this.serverUrl}/nix-pix-changed/${invoiceId}`)
          this.NixPixStompSubscription.subscribe({
            next: (message) =>{
              this.sendMessageNixPix(message);
            },error: err => {
              console.log(err)
            }
          })
        }
    }

    private sendMessageSling(object: any): void {
        this.subjectSling.next(object);
    }

    private sendMessageWashEngine(object: any): void {
        this.subjectWashEngine.next(object);
    }

    private sendMessageContract(object: any): void {
        this.subjectContract.next(object);
    }

    private sendMessageMovement(object: any): void {
        this.subjectMovement.next(object);
    }

    private sendMessageNixBillet(object: any): void{
        this.subjectNixBillet.next(object);
    }

    private sendMessageNixPix(object: any): void{
        this.subjectNixPix.next(object);
    }

    unsubscribeWashEngineChanged(sub: Subscription): void {
        if (sub) {
          sub.unsubscribe();
        }
        if (this.canUnsubscribeWebSocket( this.WashEngineChangedStompSubscription, this.subjectWashEngine)) {
            this.WashEngineChangedStompSubscription.unsubscribe();
            this.WashEngineChangedStompSubscription.complete();
            this.WashEngineChangedStompSubscription = null;
        }
    }

    unsubscribeSling(sub: Subscription): void {
        if (sub) {
          sub.unsubscribe();
        }
        if(this.canUnsubscribeWebSocket(this.SlingSocket, this.subjectSling)){
          this.SlingSocket.unsubscribe();
          this.SlingSocket.complete();
          this.SlingSocket = null;
        }
    }

    unsubscribeContract(sub: Subscription): void {
        if (sub) {
          sub.unsubscribe();
        }
        if (this.canUnsubscribeWebSocket( this.ContractChangedStompSubscription, this.subjectContract)) {
            this.ContractChangedStompSubscription.unsubscribe();
            this.ContractChangedStompSubscription.complete();
            this.ContractChangedStompSubscription = null;
        }
    }

    unsubscribeMovement(sub: Subscription): void {
        if (sub) {
          sub.unsubscribe();
        }
        if (this.canUnsubscribeWebSocket(this.MovementChangedStompSubscription, this.subjectMovement)) {
            this.MovementChangedStompSubscription.unsubscribe();
            this.MovementChangedStompSubscription.complete();
            this.MovementChangedStompSubscription = null;
        }
    }

    getMessageSling(): Observable<any> {
        const obs = this.subjectSling.asObservable();
        this.subscribeSlings();
        return obs;
    }

    getMessageWashEngine(): Observable<any> {
        const obs = this.subjectWashEngine.asObservable();
        this.subscribeWashEngineChanged();
        return obs;
    }

    getMessageContract(): Observable<any> {
        const obs = this.subjectContract.asObservable();
        this.subscribeContractChanged();
        return obs;
    }

    getMessageMovement(): Observable<any> {
      const obs = this.subjectMovement.asObservable();
      this.subscribeMovementChanged();
      return obs;
    }

    getMessageNixBillet(invoiceId: number): Observable<any>{
      const obs = this.subjectNixBillet.asObservable();
      this.subscribeNixBilletChanged(invoiceId);
      return obs;
    }

    getMessageNixPix(invoiceId: number): Observable<any>{
      const obs = this.subjectNixPix.asObservable();
      this.subscribeNixPixChanged(invoiceId);
      return obs;
    }
}



