import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Sling } from 'src/app/models';
import { SlingService } from 'src/app/services/sling.service';
import { ToastService } from 'src/app/services/toast.service';
import { MessageUtil } from 'src/app/utils/message.util';

@Component({
  selector: 'app-sling-own-fuel-confirm',
  templateUrl: './sling-own-fuel-confirm.component.html',
  styleUrls: ['./sling-own-fuel-confirm.component.scss']
})
export class SlingOwnFuelConfirmComponent implements OnInit {

  sling: Sling;
  availablePeriods: any[];

  constructor(
    private slingService: SlingService,
    private messageUtil: MessageUtil,
    private toasty: ToastService,
    public dialog: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.sling = this.config.data.sling;
  }

  confirmOwnFuelSupply(): void {
    this.slingService.confirmOwnFuelSupply(this.sling).subscribe(
      (data) => {
        this.toasty.success('Abastecimento próprio fornecido com sucesso');
        this.dialog.close();
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }

  cancelSlingCheck(): void {
    this.slingService.cancelSling(this.sling.id).subscribe(
      (data) => {
        this.toasty.success('Lingada cancelada com sucesso');
        this.dialog.close();
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }


}
