<div class="dialog-form">
    <form (submit)="save()" [formGroup]="noticeForm">
        <div class="form-group">
            <div class="form-control">
                <label for="title">{{ 'TITLE' | translate }} *</label>
                <input pInputText type="text" name="title" formControlName="title">
            </div>
            <div class="form-control">
                <label for="initialDateTime">{{ 'VIEW-FROM' | translate }} *</label>
                <p-calendar dateFormat="dd/mm/yy" name="initialDateTime" [monthNavigator]="true" [yearNavigator]="true"
                    [yearRange]="'2000:2055'" rPCalendarMask formControlName="initialDateTime" [showIcon]="true" appendTo="body"
                    i18n>
                </p-calendar>
            </div>
            <div class="form-control">
                <label for="finalDateTime">{{ 'VIEW-TO' | translate }} *</label>
                <p-calendar dateFormat="dd/mm/yy" name="finalDateTime" [monthNavigator]="true" [yearNavigator]="true"
                    [yearRange]="'2000:2055'" rPCalendarMask formControlName="finalDateTime" [showIcon]="true"
                    i18n appendTo="body">
                </p-calendar>
            </div>
            <div class="form-control">
                <label for="noticeTargetType">{{ 'NOTICE-TARGET-TYPE' | translate }} *</label>
                <p-dropdown [options]="noticeTargetTypes" formControlName="noticeTargetType" name="noticeTargetType"
                    appendTo="body" (onChange)="changeTargetType()" placeholder="Selecione...">
                </p-dropdown>
            </div>
        </div>
        <br>
        <div class="form-group">
            <div class="form-control" *ngIf="noticeForm && noticeForm.get('targets').enabled">
                <label for="targets">{{ 'ACTIVE-CUSTOMERS' | translate }}</label>
                <p-multiSelect name="targets" [options]="customersList" appendTo="body" formControlName="targets" optionLabel="label" optionValue="value"
                    selectedItemsLabel="{0} itens selecionados" placeholder="Selecione..." [maxSelectedLabels]="2">
                </p-multiSelect>
            </div>
            <div class="form-control">
                <label for="noticeType">{{ 'NOTICE-TYPE' | translate }}</label>
                <p-dropdown [options]="noticeTypes" formControlName="noticeType" name="noticeType" appendTo="body"
                    placeholder="Selecione...">
                </p-dropdown>
            </div>
            <div class="form-control">
                <label for="linkNotice">{{ 'LINK' | translate }}</label>
                <input pInputText type="text" name="linkNotice" formControlName="linkNotice">
            </div>
            <div class="form-control">
                <label for="destination">{{'DESTINATION' | translate}}</label>
                <p-dropdown name="destination" [options]="destinationList" appendTo="body" formControlName="destinationType"
                placeholder="Selecione..."></p-dropdown>
            </div>
        </div>
        <div class="form-group" style="color: red;">
          Atenção o sistema não gera agendamentos de email. A função (Exibir de - até) faz referência ao intevalo de tempo que a notícia ficará exibida no app do cliente.
        </div>
        <br>
        <div class="form-group" style="height: 100px;">
            <div class="form-control" style="flex-direction: row; align-items: center;">
                <p-fileUpload name="attachment" mode="basic" maxFileSize="100000000" chooseLabel="Anexar" fileLimit=1
                    accept=".png,.jpg" (uploadHandler)="setFile($event.files)" [auto]="true" customUpload="true" showCancelButton="true"
                    #upload>
                </p-fileUpload>
                <span style="margin-left: 10px;"> {{file?.name}}</span>
            </div>
            <img [src]="imgPreview" height="100" *ngIf="imgPreview">
        </div>
        <br>
        <div class="form-group">
            <div class="form-control full">
                <label for="description">{{ 'DESCRIPTION' | translate}} *</label>
                <p-editor name="description" formControlName="description" [style]="{'height':'320px'}"
                    [ngClass]="{'required': noticeForm && noticeForm.get('description').touched && noticeForm.get('description').dirty && !noticeForm.get('description').valid}">
                    <p-header>
                        <span class="ql-formats">
                            <button class="ql-list" value="ordered"></button>
                            <button class="ql-list" value="bullet"></button>
                        </span>
                    </p-header>
                </p-editor>
            </div>
        </div>
        <div class="form-group align-center">
            <div class="form-control">
                {{'REQUIRED-FIELDS' | translate}}
            </div>
            <div class="buttons">
                <button class="easymarine danger" type="button"
                    (click)="dialogRef.close()">{{ 'CANCEL' | translate }}</button>
                <button class="easymarine primary" type="submit">{{ 'SAVE' | translate }}</button>
            </div>
        </div>
    </form>
</div>
