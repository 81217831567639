<div class="totem">
    <div class="header">
        <h1 class="title">
            Lingadas
        </h1>
        <div class="buttons">
            <a (click)="openSlingFormDialog()" class="sling-button">
                <i class="pi pi-plus"></i>
            </a>
            <a [routerLink]="'/app/operational/checking'" class="close-button">
                <i class="pi pi-times-circle"></i>
            </a>
        </div>
    </div>
    <div class="body">
        <p-table #tt [value]="slings" [globalFilterFields]="['boat.name']" [resizableColumns]="true" [autoLayout]="true" styleClass="easy-table">
            <ng-template pTemplate="caption">
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText size="50" placeholder="Nome da embarcação"
                        (input)="tt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                </span>
                <span style="font-weight: bold; float: right; margin-top: 5px;">{{ 'TOTAL-REGISTERS' | translate }}:
                    {{tt.totalRecords}} {{'OF' | translate}} {{slings?.length}}
                </span>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th>Id</th>
                    <th>{{ 'EXECUTION-FORECAST' | translate }}</th>
                    <th>{{ 'BOAT' | translate }}</th>
                    <th>{{ 'SLING-STATUS' | translate }}</th>
                    <th>{{ 'BOAT-STATUS' | translate }}</th>
                    <th></th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-sling>
                <tr>
                    <td>{{sling.id}}</td>
                    <td>{{sling.executionForecast | date: 'dd/MM/yy - HH:mm'}}</td>
                    <td>{{sling.boat.name}} -
                        {{sling.boat.shipyardModel.shipType.toUpperCase() | translate}} -
                        {{sling.boat.commercialLength}}'
                    </td>
                    <td>{{sling.slingStatus.toUpperCase() | translate}}</td>
                    <td>{{sling.boatStatus.toUpperCase() | translate}}</td>
                    <td class="text-center">
                        <a (click)="openSlingFormDialog(sling.id)" class="button"
                            [pTooltip]="hasEdit(sling) ? messageUtil.translateKey('EDIT') : messageUtil.translateKey('VISUALIZE')"
                            tooltipPosition="bottom">
                            <i class="pi pi-pencil" *ngIf="hasEdit(sling)"></i>
                            <i class="pi pi-eye" *ngIf="!hasEdit(sling)"></i>
                        </a>
                    </td>
                    <td class="text-center">
                        <a class="button" (click)="openSlingCancelConfirmDialog(sling)"
                            pTooltip="{{ 'CANCEL' | translate }}" [disabled]="!hasCancel(sling)"
                            tooltipPosition="bottom">
                            <i class="pi pi-times"></i>
                        </a>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="11">
                        Nenhuma lingada encontrada
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
