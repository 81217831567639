<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{ 'TITLE-MY-MARINE' | translate }}</span>
        <button class="fab-button primary">
            <i class="pi pi-refresh"></i>
        </button>
    </p-header>

    <div>

        <p-card header="Dados da Marina">
            <ng-template  pTemplate="header">
                <div *ngIf="marina.imageApp1 != null" class="header-img">
                <img src="{{ marina.imageApp1 }}" style="
                max-height: 100%;
                max-width: 100%;
                object-fit: cover;
              ">
                </div> 
                
            </ng-template>
            
            <div class="row">
                <div class="col">
                    <p>
                    <b>CNPJ:</b> {{marina.companyFederalId}}
                    <br>
                    <b>Razão Social:</b> {{marina.companyName}}
                    <br>
                    <b>Nome Fantasia:</b> {{marina.tradeName}}
                    <br>
                    <b>Telefone:</b> {{marina.phone}}
                    <br>
                    <b>E-mail:</b> {{marina.email}}
                    <br>
                    <b>Logradouro:</b> {{marina.street}}
                </p>
                </div>
                <div class="col">
                <p>
                    <b>Número:</b> 
                    <br>
                    <b>Bairro:</b> {{marina.district}}
                    <br>
                    <b>Cidade:</b> {{marina.city}}
                    <br>
                    <b>Estado:</b> {{marina.state}}
                    <br>
                    <b>País:</b> Brasil
                    <br>
                    <b>CEP:</b> {{marina.zipcode}}
                </p>
                </div>
            </div>
            
        </p-card>
        <!-- <p-card header="Contratos da Marina">
            Nome do Contrato
            Produto Contratado
            Data de Criação
            <ng-template pTemplate="footer">
                <p-button label="Visualizar Contrato" icon="pi pi-check"></p-button>
                <p-button label="Assinar" icon="pi pi-times" styleClass="p-button-secondary" [style]="{ 'margin-left': '.5em' }"></p-button>
            </ng-template>
        </p-card> -->
        
    </div>
</p-fieldset>
