<p-fieldset class="operation-date">
    <p-header class="article-title">
        <span class="article-title">{{ 'SPECIAL-DAYS' | translate }}</span>
        <div class="primary fab-button" (click)="newSpecialDay()">
            <i class="pi pi-plus" style="font-size: 21px;"></i>
        </div>
    </p-header>

    <div class="table">
        <p-table [value]="specialDays" autoLayout="true" [lazy]="true" >
            <ng-template pTemplate="header">
                <tr>
                    <th rowspan="2" style="width: 140px;">
                        Data
                    </th>
                    <th colspan="2" class="text-center">Horário Marina</th>
                    <th colspan="5" class="text-center">Rampa</th>
                    <th rowspan="2" style="width: 80px;">Tempo Lingada</th>
                    <th rowspan="2" style="min-width: 250px;">Observação</th>
                    <th rowspan="2"></th>
                </tr>
                <tr>
                    <th>Abertura</th>
                    <th>Fechamento</th>
                    <th>Início</th>
                    <th>Intervalo Início</th>
                    <th>Intervalo Fim</th>
                    <th>Fim</th>
                    <th>Retorno</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-specialDay>
                <tr>
                    <td style="min-width: 125px;">
                        <p-calendar dateFormat="dd/mm/y" [name]="'dateSpecialDay' + specialDays.indexOf(specialDay)"
                            [monthNavigator]="true" [yearNavigator]="true" [yearRange]="'2000:2055'" rPCalendarMask
                            [(ngModel)]="specialDay.date" [showIcon]="true" [minDate]="today" i18n appendTo="body">
                        </p-calendar>
                    </td>
                    <td>
                        <input pInputText type="time" tooltipPosition="bottom"
                            [ngClass]="{'danger': ((!specialDay.operationStart || specialDay.operationStart === '') && (specialDay.inOperation))}"
                            pTooltip="Horário de abertura da marina"
                            [name]="'operationStart' + specialDays.indexOf(specialDay)"
                            [(ngModel)]="specialDay.operationStart" />
                    </td>
                    <td>
                        <input pInputText type="time" tooltipPosition="bottom"
                            pTooltip="Horário de fechamento da marina" [ngClass]="{'danger': ((!specialDay.operationEnd || specialDay.operationEnd === '') &&
                        (specialDay.inOperation))}" [name]="'operationEnd' + specialDays.indexOf(specialDay)"
                            [(ngModel)]="specialDay.operationEnd" />
                    </td>
                    <td>
                        <input pInputText type="time" tooltipPosition="bottom"
                            [ngClass]="{'danger': ((!specialDay.slingDownStart || specialDay.slingDownStart === '') && (specialDay.inOperation))}"
                            pTooltip="Horário de início dos serviços da rampa"
                            [name]="'slingDownStart' + specialDays.indexOf(specialDay)"
                            [(ngModel)]="specialDay.slingDownStart" />
                    </td>
                    <td>
                        <input pInputText type="time" tooltipPosition="bottom"
                            pTooltip="Horário de início do intervalo dos serviços da rampa"
                            [name]="'intervalStart' + specialDays.indexOf(specialDay)"
                            [(ngModel)]="specialDay.intervalStart" />
                    </td>
                    <td>
                        <input pInputText type="time" tooltipPosition="bottom"
                            pTooltip="Horário de fim do intervalo dos serviços da rampa"
                            [name]="'intervalEnd' + specialDays.indexOf(specialDay)"
                            [(ngModel)]="specialDay.intervalEnd" />
                    </td>
                    <td>
                        <input pInputText type="time" tooltipPosition="bottom"
                            [ngClass]="{'danger': ((!specialDay.slingDownEnd || specialDay.slingDownEnd === '') && (specialDay.inOperation))}"
                            pTooltip="Horário de fechamento dos serviços da rampa"
                            [name]="'slingDownEnd' + specialDays.indexOf(specialDay)"
                            [(ngModel)]="specialDay.slingDownEnd" />
                    </td>
                    <td>
                        <input pInputText type="time" tooltipPosition="bottom"
                            [ngClass]="{'danger': ((!specialDay.slingReturnEnd || specialDay.slingReturnEnd === '') && (specialDay.inOperation))}"
                            pTooltip="Horário máximo de retorno para os barcos"
                            [name]="'slingReturnEnd' + specialDays.indexOf(specialDay)"
                            [(ngModel)]="specialDay.slingReturnEnd" />
                    </td>
                    <td style="width: 80px;">
                        <input pInputText type="number" tooltipPosition="bottom" style="width: 80px;"
                            [ngClass]="{'danger': ((!specialDay.executionTime || specialDay.executionTime === '') && (specialDay.inOperation))}"
                            pTooltip="Tempo entre horários de lingadas"
                            [name]="'executionTime' + specialDays.indexOf(specialDay)"
                            [(ngModel)]="specialDay.executionTime" />
                    </td>
                    <td style="min-width: 250px;">
                        <input pInputText type="text" tooltipPosition="bottom" style="width: 100%;"
                            pTooltip="Observação que será apresentada para os usuários" maxlength="255"
                            [name]="'observation' + specialDays.indexOf(specialDay)"
                            [(ngModel)]="specialDay.observation" />
                    </td>
                    <td>
                        <div pTooltip="Remover" (click)="delete(specialDay)" class="table-action">
                            <i class="pi pi-trash" style="color: #EF5350"></i>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

</p-fieldset>
