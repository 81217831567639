import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Role, User } from '../models';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserMarina } from '../models/user-marina';

@Injectable()
export class UserService {


    constructor(private http: HttpClient) { }



    getMarinaUsers(campos?:string): Observable<User[]> {
       if (campos==undefined){ campos="";}
      let headers = new HttpHeaders();
      headers = headers.set('campos', campos);
        return this.http.get(environment.apiHost + '/secure/users/marina', {headers: headers}).pipe(map((response: any) => response.data.users as User[]));
    }

    getMarinaResponsibleUsers(campos?:string): Observable<User[]> {
      if (campos==undefined){ campos="";}
     let headers = new HttpHeaders();
     headers = headers.set('campos', campos);
       return this.http.get(environment.apiHost + '/secure/responsible-users/marina', {headers: headers}).pipe(map((response: any) => response.data.users as User[]));
   }

    getMarinaUsersRoleFiltered(): Observable<User[]> {
        return this.http.get(environment.apiHost + '/secure/users/marina/role-filtered')
            .pipe(map((response: any) => response.data.users as User[]));
    }

    getAppUsersRoleFiltered(): Observable<User[]> {
        return this.http.get(environment.apiHost + '/secure/users/app/role-filtered')
            .pipe(map((response: any) => response.data.users as User[]));
    }

    getById(id: number): Observable<User> {
        return this.http.get(environment.apiHost + '/secure/user/' + id).pipe(map((response: any) => response.data.user as User));
    }

    getRoleById(id: number): Observable<User> {
        return this.http.get(environment.apiHost + '/secure/user/role/' + id).pipe(map((response: any) => response.data.user as User));
    }

    getByUsername(username: string): Observable<User> {
        return this.http.get(environment.apiHost + '/secure/user/username/' + username).pipe(
            map((response: any) => response.data.user as User)
        );
    }

    bracelet(code: string): Observable<User> {
        return this.http.get(environment.apiHost + '/secure/user/bracelet/' + code)
            .pipe(map((response: any) => response.data.user as User));
    }

    verifyPassword(usernameP: string, passwordP: string): Observable<User> {
        const params = new HttpParams({
            fromObject: {
                username: usernameP,
                password: passwordP
            }
        });
        return this.http.get(`${environment.apiHost}/secure/user/verify-password`, { params }).pipe(map(() => null));
    }

    create(user: User): Observable<User> {
        return this.http.post(environment.apiHost + '/secure/user/', user).pipe(map((response: any) => response));
    }

    update(user: User): Observable<User> {
        return this.http.put(environment.apiHost + '/secure/user/', user).pipe(map((response: any) => response));
    }

    delete(id: number): Observable<User> {
        return this.http.delete(environment.apiHost + '/secure/user/' + id).pipe(map((response: any) => response));
    }

    getRolesByHierarchy(): Observable<Role[]> {
        return this.http.get(environment.apiHost + '/secure/roles/by-hierarchy')
            .pipe(map((response: any) => response.data.roles as Role[]));
    }

    getUserMarina(): Observable<UserMarina> {
        return this.http.get(environment.apiHost + '/secure/user/user-marina')
            .pipe(map((response: any) => response.data.userMarina as UserMarina));
    }

    getNameLike(name: any): Observable<User[]>{
      return this.http.get(environment.apiHost + '/secure/users/getlikename/'+ name)
      .pipe(map((response: any) => response.data.userMarina as User[]));
    }
}
