<div [formGroup]="addressForm">
  <div class="form-group flex flex-column md:flex-row">
      <div class="form-control w-full">
          <label for="AddressZip">{{ 'ZIP' | translate }} *</label>
          <p-inputMask type="text" mask="99999-999" name="AddressZip" formControlName="zip"
                       styleClass="w-full"
              (onBlur)="getCep()">
          </p-inputMask>
      </div>
      <div class="form-control flex flex-column w-full md:mx-3">
          <label for="country">{{ 'COUNTRY' | translate }} *</label>
          <p-dropdown [options]="countries" name="country" appendTo="body" styleClass="w-full"
              (onChange)="loadStates(address.country)" formControlName="country">
          </p-dropdown>
      </div>
      <div class="form-control flex flex-column w-full">
          <label for="state">{{ 'FS' | translate }} *</label>
          <p-dropdown [options]="states" name="state" appendTo="body" styleClass="w-full"
              (onChange)="loadCities(address.country, address.state)"
              formControlName="state">
          </p-dropdown>
      </div>
  </div>
  <div class="form-group jump flex flex-column md:flex-row">
      <div class="form-control flex flex-column w-full md:mr-3">
          <label for="city">{{ 'CITY' | translate }} *</label>
          <input pInputText formControlName="city"  name="city" appendTo="body">
      </div>
      <div class="form-control flex flex-column w-full md:ml-3">
          <label for="residentialAddressDistrict">{{ 'DISTRICT' | translate }} *</label>
          <input pInputText name="residentialAddressDistrict" formControlName="district"
              maxlength="50">
      </div>

  </div>
  <div class="form-group jump flex flex-column md:flex-row">
      <div class="form-control flex flex-column w-full">
          <label for="residentialAddressStreet">{{ 'STREET' | translate }} *</label>
          <input pInputText name="residentialAddressStreet" formControlName="street" maxlength="50">
      </div>
      <div class="form-control flex flex-column w-full md:mx-3">
          <label for="residentialAddressNumber">{{ 'NUMBER' | translate }} *</label>
          <input pInputText name="residentialAddressNumber" formControlName="number" maxlength="10">
      </div>
      <div class="form-control flex flex-column w-full">
          <label for="residentialAddressComplement">{{ 'COMPLEMENT' | translate }}</label>
          <input pInputText name="residentialAddressComplement" formControlName="complement"
              maxlength="50">
      </div>
  </div>
</div>
