import { FederalIdType } from './federal-id-type';
import { Licence } from './license';
import { Gender } from './gender';
import { Address } from './address';
import { Marina } from './marina';
import { MaritalStatus } from './enums/customer/marital-status';
import { CustomerProfile } from './customer-profile';
import { AccessGroup } from './dtos/access-controller/access-group';
import { BlockType } from './enums/customer/block-type';
import { ServiceCustomer } from './ServiceCustomer';

export class Customer {
  id: number;
  name: string;
  federalIdType: FederalIdType;
  federalId: string;
  registration: string;
  profession: string;
  licence: Licence;
  email: string;
  emailCollection: string;
  phone: string;
  phone2: string;
  residentialPhone: string;
  commercialPhone: string;
  birthday: any;
  gender: Gender;
  commercialAddress: Address;
  residentialAddress: Address;
  emergencyContact: string;
  emergencyPhone: string;
  marina: Marina;
  active: boolean;
  dueDate?: any;
  billingDay: any;
  observation: string;
  group: any;
  customerProfile: CustomerProfile;
  idInvoice: number;
  updateDate: any;
  onlyAssociated: boolean;
  accessGroup: AccessGroup;
  boatCustomersDTO?: any[];
  photoUrl: string;
  billingCompany?: any;
  financialBlock = false;
  blockType: BlockType;
  blockHour: Date;
  emailCollectionArray: string[];
  location?: string;
  residentialCity?: string;
  residentialState?: string;
  commercialCity?: string;
  commercialState?: string;
  customerType?: string;
  associatedBoats?: number;
  reviewed: boolean;
  reviewedCustomer?: string;
  activeCustomer?: string;
  companyName?: string;
  marinaCompanyId?: number;
  issRetidoFonte: boolean;
  spouse: string;
  maritalStatus: MaritalStatus | null;
  dependentsCustomers: Customer[];
  mainDependentCustomer: Customer;
  dependentType?: string;
  serviceCustomer: ServiceCustomer[];

  constructor() {
    this.gender = Gender.M;
    this.federalIdType = FederalIdType.Physical;
    this.licence = new Licence();
    this.residentialAddress = new Address();
    this.commercialAddress = new Address();
    this.marina = new Marina();
    this.active = true;
    this.group = { id: -1 };
    this.onlyAssociated = false;
    this.name = null;
    this.issRetidoFonte = false;
    this.maritalStatus = null;
  }
}
