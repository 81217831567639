import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InvoicePaid } from 'src/app/models/invoice-paid';
import { InvoicePaidService } from 'src/app/services/invoice-paid.service';
import { ToastService } from 'src/app/services/toast.service';
import { StorageUtil } from 'src/app/utils/storage.util';
import Swal from 'sweetalert2';
import { RoleService } from '../../../services/role.service';
import { MessageUtil } from '../../../utils/message.util';

@Component({
  selector: 'app-invoice-paids',
  templateUrl: './invoice-paids.component.html',
  styleUrls: ['./invoice-paids.component.scss']
})
export class InvoicePaidsComponent implements OnInit {

  invoice: any;
  constructor(
    public dialog: DynamicDialogRef,
    private invoicePaidService: InvoicePaidService,
    private toasty: ToastService,
    private config: DynamicDialogConfig,
    private roleService: RoleService,
    private messageUtil: MessageUtil
  ) {
    this.invoice = this.config.data.invoice;
  }

  ngOnInit(): void {
  }

  async cancelPayment(payment: InvoicePaid): Promise<void> {
    const credentialsAdm = await this.isCurrentUserAdministrator();
    credentialsAdm ?
    Swal.fire(
      {
        title: 'Cancelamento de pagamento',
        text: 'Você deseja cancelar o pagamento selecionado?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',

        cancelButtonText: 'Não',
        reverseButtons: true
      }).
      then((result2) => {
        if (result2.value) {
          this.invoicePaidService.cancelPayment(payment).subscribe((data) => {
            payment.canceled = true;
            let user = StorageUtil.getUser();
            payment.employeeUser = user.firstName+" "+user.lastName;
            this.toasty.success('Pagamento cancelado com sucesso!.');
          });
        }
      })
      : this.messageUtil.generateMessage(MessageUtil.ERROR, 'Erro', "Você não possui permissão para executar essa ação");
  }

  async isCurrentUserAdministrator(): Promise<Boolean> {
    const currentUserID = StorageUtil.getUserId();
    return new Promise<Boolean> (async (resolve) => {
      this.roleService.currentUserIsAdministrator(currentUserID).subscribe({
        next: (administratorRole: Boolean) => resolve(administratorRole),
        error: (e) => console.log(e),
      });
    });
  }

  getEmployeeName(payment: InvoicePaid): string {
    let employeeName = payment.employeeUser;
    if (payment.paymentMethodName === 'Boleto') {
    return 'Imp.: ' + employeeName.toString();
    }
    return employeeName;
  }
}
