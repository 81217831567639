import { Component, OnInit } from '@angular/core';
import { Customer } from 'src/app/models';
import { SelectLabelValue } from 'src/app/models/select-label-value';
import { ServiceCustomer } from 'src/app/models/ServiceCustomer';
import { CustomerService } from 'src/app/services/customer.service';
import dayjs from 'dayjs';
import { CustomerFilter } from 'src/app/models/dtos/customer/customerFilter';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { CustomReportExportComponent } from 'src/app/components/extract-custom-report/custom-report-export.component';

@Component({
  selector: 'app-customer-services-details',
  templateUrl: './customer-services-details.component.html',
  styleUrls: ['./customer-services-details.component.scss']
})
export class CustomerServicesDetailsComponent implements OnInit {
  customers: Customer[];
  customerList: SelectLabelValue[] = [];

  customerFilter: CustomerFilter = new CustomerFilter();

  totalRecords = 0;
  totalCommercialLength = 0;
  averageCommercialLength = 0;
  totalVlPe = 0;
  totalValue = 0;
  totalDesc = 0;
  totalDescVenc = 0;
  total = 0;

  rangeDatesStart: Date[];
  rangeDatesEnd: Date[];

  cols = [
    { field: 'name', header: 'Embarcação' },
    { field: 'vacancyCode', header: 'Vaga' },
    { field: 'shipTypeBoat', header: 'Tipo' },
    { field: 'customerName', header: 'Cliente' },
    { field: 'customerGroup', header: 'Grupo' },
    { field: 'customerQuotaCSV', header: 'Rateio' },
    { field: 'serviceName', header: 'Serviço' },
    { field: 'commercialLength', header: 'Tamanho comercial' },
    { field: 'rangeValue', header: 'Valor pés' },
    { field: 'valueService', header: 'Valor' },
    { field: 'dueDateDiscount', header: 'Desc. Venc.' },
    { field: 'discount', header: 'Desconto' },
    { field: 'discountPERC', header: '% Desconto' },
    { field: 'totalValue', header: 'Total' }
  ];
  
  constructor(
    private readonly customerService: CustomerService,
    private spinner: NgxSpinnerService,
    private dialog: DialogService
  ) {}

  ngOnInit(): void {
    this.getCustomerList();
    this.getCustomers();
  }
  
  clear(){
    this.customerFilter = new CustomerFilter();
  }

  exportTable(): void {
    const customerDetailExport: CustomerDetailExport[] = [];

    this.customers.forEach(customer => {
      customerDetailExport.push({
        status: customer.active ? 'Ativo' : 'Inativo',
        customerName: customer.name,
        customerFederalId: customer.federalId,
        befeficiaryName: customer.serviceCustomer.map(service => service.beneficiaryName).join(',\n'),
        serviceContractStart: customer.serviceCustomer.map(service => dayjs(service.contractStartDate).format('DD/MM/YYYY')).join(',\n'),
        serviceContractEnd: customer.serviceCustomer.map(service => service.contractEndDate ? dayjs(service.contractEndDate).format('DD/MM/YYYY'):'').join(',\n'),
        serviceStatus: customer.serviceCustomer.map(service => service.active ? 'Ativo' : 'Inativo').join(',\n'),
        serviceName: customer.serviceCustomer.map(service => service.service.name).join(',\n'),
        customerServiceValue: customer.serviceCustomer.map(service => service.value).join(',\n'),
        customerServiceDueDateDiscount: customer.serviceCustomer.map(service => service.dueDateDiscount.toFixed(2)).join(',\n'),
        customerServiceDiscount: customer.serviceCustomer.map(service => service.discount.toFixed(2)).join(',\n'),
        customerServiceDiscountPercent: customer.serviceCustomer.map(service => this.getPercentDiscountOfTotalService(service).toFixed(2)).join(',\n'),
        customerServiceTotal: customer.serviceCustomer.map(service => this.getTotalService(service).toFixed(2)).join(',\n')
      });
    });

    const data = {table: customerDetailExport, type: 'DETAIL_CUSTOMER'}
    this.dialog.open(CustomReportExportComponent, {
      width: '70%',
      height: '90vh',
      dismissableMask: false,
      data,
      header: 'Exportar Relatório'
    });
  }

  getCustomers(): void {
    this.spinner.show();

    this.totalValue = 0;
    this.totalDesc = 0;
    this.totalDescVenc = 0;
    this.total = 0;

    this.customerFilter.fromServiceStart = this.rangeDatesStart ? dayjs(this.rangeDatesStart[0]).format('YYYY-MM-DD') : null;
    this.customerFilter.toServiceStart = this.rangeDatesStart ? dayjs(this.rangeDatesStart[1]).format('YYYY-MM-DD') : null;
    this.customerFilter.fromServiceEnd = this.rangeDatesEnd ? dayjs(this.rangeDatesEnd[0]).format('YYYY-MM-DD') : null;
    this.customerFilter.toServiceEnd = this.rangeDatesEnd ? dayjs(this.rangeDatesEnd[1]).format('YYYY-MM-DD') : null;

    this.customerService.getServiceReportCustomers(this.customerFilter).subscribe({
      next: (customers: Customer[]) => {
        this.customers = customers;

        customers.forEach(customer => {
          customer.serviceCustomer.forEach((c) => {
            this.totalValue += c.value;
            this.totalDesc += c.discount;
            this.totalDescVenc += c.dueDateDiscount;
            this.total += (c.value - c.discount - c.dueDateDiscount);

            c.beneficiaryId == null ? c.beneficiaryName = customer.name : null;
          });

        });

        this.totalRecords = customers.length;
      },
      error: (error: any) => {
        console.error('Error fetching customers', error);
      },
      complete: () => {
        this.spinner.hide();
      }
    });
  }

  getCustomerList(): void {
    this.customerList = [{ label: 'Todos', value: null }];
    this.customerService.getAll().subscribe({
      next: (customers: Customer[]) => {
        customers.forEach((customer) => {
          this.customerList.push({ label: customer.name, value: customer.id });
        });
      },
      error: (error: any) => {
        console.error('Error fetching customers', error);
      }
    });
  }

  getPercentDiscountOfTotalService(serviceCustomer: ServiceCustomer): number{
    return ((serviceCustomer.discount + serviceCustomer.dueDateDiscount) / serviceCustomer.value);
  }

  getTotalService(serviceCustomer: ServiceCustomer): number{
    return serviceCustomer.value - ( serviceCustomer.discount + serviceCustomer.dueDateDiscount);
  }
}

export class CustomerDetailExport{
  status: string;
  customerName: string;
  customerFederalId: string;
  befeficiaryName: string;
  serviceContractStart: string;
  serviceContractEnd: string;
  serviceStatus: string;
  serviceName: string;
  customerServiceValue: string;
  customerServiceDueDateDiscount: string;
  customerServiceDiscount: string;
  customerServiceDiscountPercent: string;
  customerServiceTotal: string;
}