import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {

  @Input()
  textMessage: string = '';
  @Input()
  titleMessage: string = '';
  @Input()
  link: string = '';
  @Input()
  msgAction: string = '';


  @Output()
  close: EventEmitter<any> = new EventEmitter;

  constructor() { }

  ngOnInit(): void {
  }

  closeModal() {
    this.close.emit();
  }

}
