import { NavigationArea } from './navigation-area';

export class TIE {
    id: number;
    register: string = null;
    country: string = null;
    state: string = null;
    city: string = null;
    file: string = null;
    navigationArea: NavigationArea;
    expiration: any = null;

    constructor() {

    }
}
