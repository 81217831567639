<div class="order-item blue">
    <div class="item">
        <span>Cliente {{payment.id}}</span>    
    </div>
    <div style="width: 120px;">
        <p-slider [disabled]="hasPayment" [(ngModel)]="selectedAmount" (onChange)="distributeSplittedValueSlider()"></p-slider>
    </div>
    <div class="item text-center nowrap">
        <p-inputNumber [disabled]="hasPayment" class="input-small" prefix="%"  [(ngModel)]="selectedAmount" (onBlur)="distributeSplittedValueSlider()"/>                
        <span>&nbsp;&nbsp;Parte Dividida:&nbsp;
            <p-inputNumber [disabled]="hasPayment" mode="decimal" prefix="R$ "  
            min="0,01" [max]="payment.totalSplited"
            [minFractionDigits]="2" [maxFractionDigits]="2" 
            [(ngModel)]="payment.split" (onBlur)="distributeSplittedValue()"/>
        </span>
    </div>
    <div class="item">
        <span>Total: R${{payment.total | number:'1.2-2'}}</span>        
    </div>
</div>
<app-payment-form-select (onPaymentSelected)="selectPayment($event)"></app-payment-form-select>
<div class="flex-end pr-2">
    <button *ngIf="!isPayed" (click)="paymentFinish()" [disabled]="!account" class="success easymarine" type="button" >Pagar</button>
    <button *ngIf="isPayed" [disabled]="isPayed" class="disabeld easymarine" type="button" >Pago</button>
</div>
<br>
    
