<div class="dialog-form">
    <p-table #billetTable *ngIf="billets" [columns]="cols" [value]="billets" [paginator]="true"
        [rows]="rows" [pageLinks]="3" [alwaysShowPaginator]="false" class="primeTable" dataKey="id" width="100%"
        autoLayout="true">

        <ng-template pTemplate="header">
            <tr>
                <th>
                    <span style="font-weight: bold">
                        {{ 'TOTAL-REGISTERS' | translate }}:
                        {{billetTable.totalRecords}}
                    </span>
                </th>
                <th></th>
                <th></th>
                <th class="text-right">{{ totalBillets | number:'1.2-2' }}</th>
                <th></th>
                <th></th>
                <th></th>
            </tr>
            <tr>
                <th class="text-center" [pSortableColumn]="'invoiceNumber'">
                    Fatura
                    <p-sortIcon [field]="'invoiceNumber'"></p-sortIcon>
                </th>
                <th class="text-center" [pSortableColumn]="'customer.name'">
                    Cliente
                    <p-sortIcon [field]="'customer.name'"></p-sortIcon>
                </th>
                <th class="text-center" [pSortableColumn]="'customer.federalId'">
                    CPF
                    <p-sortIcon [field]="'customer.federalId'"></p-sortIcon>
                </th>
                <th class="text-center" [pSortableColumn]="'value'">
                    Valor
                    <p-sortIcon [field]="'value'"></p-sortIcon>
                </th>
                <th class="text-center" [pSortableColumn]="'dueDate'">
                    Venc
                    <p-sortIcon [field]="'dueDate'"></p-sortIcon>
                </th>
                <th class="text-center" [pSortableColumn]="'accountName'">
                    Conta
                    <p-sortIcon [field]="'accountName'"></p-sortIcon>
                </th>
                <th class="text-center" [pSortableColumn]="'groupName'">
                    Grupo
                    <p-sortIcon [field]="'groupName'"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-billet>
            <tr>
                <td class="text-center">
                    <span *ngIf="!billet.legacyInvoiceNumber" class="paidOrder"
                        (click)="extract(billet.invoiceId)">{{billet.invoiceNumber}}</span>
                    <span *ngIf="billet.legacyInvoiceNumber" class="paidOrder"
                        (click)="extract(billet.invoiceId)">{{billet.invoiceNumber}} <br>
                        {{billet.legacyInvoiceNumber}}</span>
                </td>
                <td class="text-center">
                    <span [matTooltip]="billet.emailCollectionArray ? billet.emailCollectionArray.join('\n') : ''"
                        matTooltipPosition="above">
                        {{billet.customer}}
                    </span>
                </td>
                <td class="text-center">
                    <span>{{billet.customerFederalId}}</span>
                </td>

                <td class="text-right" [ngStyle]="{'color': billet.value !== 0 ? 'rgb(83, 83, 244)' : 'black'}">
                    {{billet.value | number:'1.2-2'}}
                </td>

                <td class="text-center">
                    {{billet.dueDate | date: 'dd/MM/yy'}}
                </td>

                <td class="text-center">
                    {{billet.account}}
                </td>

                <td class="text-center">
                    {{billet.group ? billet.group : 'Sem Grupo'}}
                </td>

            </tr>
        </ng-template>
    </p-table>
    <br>
    <div style="display: flex; justify-content: center;">
        <form [formGroup]="anticipationForm" (submit)="anticipate()" class="flex-row"
            style="flex: 2; display: flex; align-items: center; justify-content: center;">
            <div style="display: flex; flex-direction: column; align-items: center; max-width: 50%; width: 100%;">
                <div style="display: flex;width: 100%; justify-content: space-between;">
                    <div class="form-control"
                        [ngClass]="{'error': invalidFormControl(anticipationForm.get('favorecido'))}">
                        <label for="taxes" style="flex:1; display: flex; align-items: center;">
                            Favorecido
                        </label>
                        <div style="display: flex; flex: 4;">
                            <span style="flex:3;" [ngClass]="{'error': anticipationForm.get('favorecido').invalid}">
                                <p-dropdown [options]="favorecidos" formControlName="favorecido" [filter]="true"
                                    appendTo="body">
                                </p-dropdown>
                            </span>
                        </div>
                    </div>
                    <div class="form-control">
                        <label for="date" style="flex:1; display: flex; align-items: center;">
                            Data do lançamento
                        </label>
                        <div style="display: flex; flex: 4;">
                            <span style="flex:3;" [ngClass]="{'danger': anticipationForm.get('date').invalid}">
                                <p-calendar dateFormat="dd/mm/yy" styleClass="modal-calendar" name="date"
                                    [monthNavigator]="true" [yearNavigator]="true" [yearRange]="'2000:2055'"
                                    rPCalendarMask formControlName="date" [showIcon]="true" i18n appendTo="body">
                                </p-calendar>
                            </span>
                        </div>
                    </div>
                </div>
                <br>
                <div style="display: flex; justify-content: space-between; align-items: center; width: 100%;">
                    <div class="form-control" [ngClass]="{'error': invalidFormControl(anticipationForm.get('taxes'))}">
                        <label for="taxes">
                            Juros
                        </label>
                        <p-inputNumber mode="decimal" formControlName="taxes" name="taxes" [minFractionDigits]="2"
                            [maxFractionDigits]="2">
                        </p-inputNumber>
                        <span class="percentage">
                            <span>
                                {{(anticipationForm.value.taxes / totalBillets)* 100 | number:'1.2-2'}}%
                            </span>
                        </span>
                    </div>
                    <div class="form-control"
                        [ngClass]="{'error': invalidFormControl(anticipationForm.get('interest'))}">
                        <label for="interest">
                            IOF
                        </label>
                        <p-inputNumber mode="decimal" formControlName="interest" name="interest" [minFractionDigits]="2"
                            [maxFractionDigits]="2">
                        </p-inputNumber>
                        <span class="percentage">
                            <span>
                                {{(anticipationForm.value.interest / totalBillets)* 100 | number:'1.2-2'}}%
                            </span>
                        </span>
                    </div>
                    <div class="form-control"
                        [ngClass]="{'error': invalidFormControl(anticipationForm.get('balance'))}">
                        <label for="balance">
                            Saldo
                        </label>
                        <p-inputNumber mode="decimal" formControlName="balance" name="balance" [minFractionDigits]="2"
                            [maxFractionDigits]="2">
                        </p-inputNumber>
                        <span class="percentage">
                            <span>
                                {{(anticipationForm.value.balance / totalBillets)* 100 | number:'1.2-2'}}%
                            </span>
                        </span>
                    </div>
                    <br>
                </div>
                <span style="color: red;" *ngIf="valueNotMatch()">
                    Valor não corresponde à soma dos boletos
                </span>
                <br>
                <button [disabled]="(!anticipationForm.valid) || valueNotMatch()" class="easymarine primary"
                    type="submit">Antecipar</button>
            </div>
        </form>
    </div>
</div>
