<div class="table">
  <p-table
    #tt
    [value]="listEmails"
    resetPageOnSort="true"
    [pageLinks]="3"
    [paginator]="true"
    [rowsPerPageOptions]="[10, 20, 30, 50, 100]"
    [autoLayout]="true"
    [rows]="numberOfRows"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>Data/Hora</th>
        <th>
          {{ "EMAIL" | translate }}
        </th>
        <th>Anexo</th>
        <th>Error</th>
        <th>Lido</th>
        <th>Único</th>
        <th>App</th>
        <th>Email</th>
        <th>Servidor</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-email>
      <tr>
        <td class="text-center">{{ email.registerDate | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
        <td class="text-center">{{ email.email}}</td>
        <td class="text-center">
          <i *ngIf="email.attachments !=null" class="pi pi-file-o" style="color: navy"></i>
        </td >
        <td class="text-center">
          <i *ngIf="email!.emailError" class="pi pi-check" style="color: #ef5350"></i>
        </td>
        <td class="text-center">
          <i *ngIf="email!.emailRead" class="pi pi-check" style="color: green"></i>
        </td>
        <td class="text-center">
          <i *ngIf="email!.single" class="pi pi-check" style="color:green"></i>
        </td>

        <td class="text-center">
          <i class="pi pi-mobile" style="color:navy"></i>
        </td>

        <td class="text-center">
          <i *ngIf="noticie.destinationType==='APP_CLIENT_EMAIL'" class="pi pi-envelope" style="color: navy"></i>
        </td>

        <td class="text-center">
          <div *ngIf="email!.serverOwn" > Próprio</div>
          <div *ngIf="email!.serverOwn != true" > EasyMarine</div>
        </td>




      </tr>
    </ng-template>
  </p-table>
</div>
