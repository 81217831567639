import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { BoatLogModalComponent } from 'src/app/pages/logs/boat-log/boat-log-modal/boat-log-modal.component';
import { BoatLogService } from 'src/app/services/boat-log.service';
import { BoatService } from 'src/app/services/boat.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';
import {ServiceCustomer} from '../../../../../models/ServiceCustomer';

@Component({
  selector: 'app-service-customer-history',
  templateUrl: './service-customer-history.component.html',
  styleUrls: ['./service-customer-history.component.scss']
})
export class ServiceCustomerHistoryComponent implements OnInit {

  @Input() serviceCustomers: ServiceCustomer[];

  constructor(
    private messageUtil: MessageUtil,
    public dialog: DialogService,
  ) { }

  ngOnInit(): void {
  }

  getLogServiceBoat(boatService){

  }

  openBoatLogDialog(boatLog): void {
    this.dialog.open(BoatLogModalComponent, {
      height: '85vh',
      width: '80%',
      dismissableMask: false,
      data: {
        boatLog
      },
      header: 'Log de Embarcação'
    })
  }

  showMessageNotFound(){
    this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', 'Não foi possivel encontrar o log da exclusão deste serviço.');
  }

  calcTotalService(service): number {
    return service.value - service.discount;
  }

}
