import { Component, HostListener, Input, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OrderItem, Product, Sling, SlingConfig } from 'src/app/models';
import { ProductCategoryType } from 'src/app/models/product-category-type';
import { ProductService } from 'src/app/services/product.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { SlingUtil } from 'src/app/utils/sling.util';

@Component({
  selector: 'app-sling-product-form',
  templateUrl: './sling-product-form.component.html',
  styleUrls: ['./sling-product-form.component.scss']
})
export class SlingProductFormComponent implements OnInit {

  slingConfig: SlingConfig;
  slingOrder: Sling = new Sling();
  consumableProducts: Product[];
  productSelected: number;
  loading = false;
  disableFlag = false;
  completeDateFormat = 'dd/MM/yyyy - HH:mm';
  filteredConsumables: any;
  consumableOrderItems: OrderItem[] = [];
  isToten: boolean;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?): void {
    if (window.innerWidth < 1200) {
      this.isToten = true;
    }
  }

  constructor(
    public slingUtil: SlingUtil,
    private messageUtil: MessageUtil,
    private productService: ProductService,
    public dialog: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.getScreenSize();
    this.consumableProducts = [];
    this.loadConsumableProducts();
    this.slingOrder = this.config.data.slingOrder;
    if (this.slingOrder) {
      this.loadConsumableOrderItems();
    }
  }


  filterConsumables(val: string): Product[] {
    return val ? this.consumableProducts.filter((c) => new RegExp(`^${val}`, 'gi').test(c.name))
      : this.consumableProducts;
  }

  loadConsumableOrderItems(): void {
    if (this.slingOrder.marinaOrder.id) {
      this.consumableOrderItems = this.slingOrder.marinaOrder.orderItems
        .filter((item) => item.product.productCategory.productCategoryType === ProductCategoryType.Consumable);
      this.consumableOrderItems.forEach((consumable) => {
        consumable.qtyStock = consumable.product.stock - consumable.product.reserved;
      });
    }
  }

  loadConsumableProducts(): void {
    this.productService.getByProductCategoryType(ProductCategoryType.Consumable).subscribe(
      (data) => {
        this.consumableProducts = data.filter((p) => p.active);
        this.filteredConsumables = this.consumableProducts.map(
          (product) => (
            {
              label: product.name.concat(' - (Estoque: ').concat(String(product.stock).concat(')')),
              value: product.id
            }
          )
        );
      },
      () => {
        this.consumableProducts = null;
      },
    );
  }

  sumItemValues(orderItems: OrderItem[]): number {
    let sum = 0;
    orderItems.forEach((item) => {
      if (item.qtyRequested) {
        sum += (item.qtyRequested * item.itemValue);
      }
    });
    return sum;
  }

  addNewConsumable(): void {
    if (this.productSelected) {
      const orderItem: OrderItem = new OrderItem();
      const product = this.consumableProducts.find((p) => p.id === this.productSelected);
      orderItem.product = product;
      orderItem.itemValue = product.price.value;
      orderItem.itemStatus = false;
      orderItem.qtyStock = orderItem.product.stock - orderItem.product.reserved;
      this.consumableOrderItems.push(orderItem);
      this.productSelected = undefined;
    }
  }

  saveConsumablesData(closeDialog: boolean): void {

    if (!this.validateItems()) {
      this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', 'Informe o preço e/ou quantidade solicitada.');
      return;
    }

    this.loading = false;
    this.disableFlag = false;

    this.consumableOrderItems.forEach((consumable) => {
      if (!this.slingOrder.marinaOrder.orderItems.find((item) => item === consumable)) {
        this.slingOrder.marinaOrder.orderItems.push(consumable);
      }
    });
    this.dialog.close(true);

    // if (this.slingFormComponent) {
    //   if (closeDialog) {
    //     this.slingFormComponent.closeDialog();
    //   } else {
    //     if (!this.slingFormComponent.sling.id && this.slingFormComponent.slingQuotaFormComponent.quotas.length === 0) {
    //       this.slingFormComponent.slingQuotaFormComponent.loadQuotas();
    //     }
    //     if (this.slingConfig.hasAccessories) {
    //       this.slingFormComponent.changeSelectedTab(3);
    //     } else {
    //       this.slingFormComponent.changeSelectedTab(4);
    //     }
    //   }
    // } else {
    // }
  }

  validateItems(): boolean {
    let valid = true;
    this.consumableOrderItems.forEach((consumable) => {
      if (!consumable.qtyRequested || consumable.qtyRequested === 0 || !consumable.itemValue || consumable.itemValue === 0) {
        valid = false;
      }
    });
    return valid;
  }

  deleteConsumableOrderItem(orderItem: OrderItem): void {
    const index = this.consumableOrderItems.indexOf(orderItem, 0);
    if (index > - 1) {
      this.consumableOrderItems.splice(index, 1);

      const index2 = this.slingOrder.marinaOrder.orderItems.indexOf(orderItem, 0);
      if (index2 > - 1) {
        this.slingOrder.marinaOrder.orderItems.splice(index2, 1);
      }
    }
  }

  displayFn(object: any): string {
    return object ? object.name : '';
  }

  isAttempted(): boolean {
    return this.slingOrder.marinaOrder.orderStatus !== 'Ordered' || this.slingOrder.slingStatus !== 'PENDING_DOWN';
  }

  getRequestDate(): any {
    return this.slingOrder.marinaOrder.orderDate;
  }
}
