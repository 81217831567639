<div class="dialog-form">
    <div class="buttons">
        <button class="easymarine primary" (click)="confirmOwnFuelSupply()">
            Confirmar abastecimento
        </button>
        <button class="easymarine danger" (click)="cancelSlingCheck()">
            Cancelar lingada
        </button>
        <button class="easymarine danger" (click)="dialog.close()">
            Voltar
        </button>
    </div>
</div>