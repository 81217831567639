<div class="table">
  <p-table
    #tt
    [value]="nixxBankLogs"
    [resizableColumns]="true"
    [autoLayout]="true"
    [paginator]="true"
    [rows]="numberOfRows"
    [pageLinks]="3"
    [rowsPerPageOptions]="isDialog ? null : [10, 20, 30, 50, 100]"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>{{ "DATE/HOUR" | translate }}</th>
        <th>{{ "USER" | translate }}</th>
        <th>{{ "INVOICE" | translate }}</th>
        <th>{{ "CUSTOMER" | translate }}</th>
        <th>{{ "TYPE" | translate }}</th>
        <th>{{ "ACTION" | translate }}</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-nixxLog>
      <tr>
        <td>{{ (nixxLog.dateEnd ? nixxLog.dateEnd : nixxLog.dateStart) | date : "dd/MM/yyyy - HH:mm" }}</td>
        <td>{{ nixxLog.user.name ? nixxLog.user.name : "" }}</td>
        <td>{{ nixxLog.billet.alias ? nixxLog.billet.alias : "" }}</td>
        <td>{{ nixxLog.billet.payer.name ? nixxLog.billet.payer.name : "" }}</td>
        <td>{{ nixxLog.operationStatus | translate }}</td>
        <td>{{ nixxLog.operationAction | translate }}</td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="7">Nenhum log encontrado</td>
      </tr>
    </ng-template>
  </p-table>
</div>
