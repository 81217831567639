import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import b64toBlob from 'b64-to-blob';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { ExtractInvoiceComponent } from 'src/app/components/extract-invoice/extract-invoice.component';
import { InvoiceTaxDocumentStatus } from 'src/app/models/enums/invoice-tax-document-status';
import { MarinaCompany } from 'src/app/models/marina-company';
import { PaginationFilter } from 'src/app/models/pagination-filter';
import { CustomerService } from 'src/app/services/customer.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';
import { InvoiceTaxProductService } from 'src/app/services/financial/invoice-Tax-Product.service';
import { InvoiceTaxDocumentProduct } from 'src/app/models/invoice-Tax-Document-Product';
import { CustomReportExportComponent } from 'src/app/components/extract-custom-report/custom-report-export.component';
import { Observable } from 'rxjs';
import moment from 'moment';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { InvoiceTaxDocumentProductDTO } from 'src/app/models/invoice-Tax-Document-Product-Dto';

@Component({
  templateUrl: './invoice-tax-product.component.html',
  styleUrls: ['./invoice-tax-product.component.scss'],
})
export class InvoiceTaxProductComponent implements OnInit {
  numberOfRows = 10;
  filter: any = {};
  typeList: Array<{ label: string; value: string }> = [
    { label: 'Entrada', value: 'entry' },
    { label: 'Saída', value: 'exit' },
  ];
  filteredCompanies: { label: string; value: any }[];
  filteredCustomers: any[] = [];
  filteredSupplier: any[] = [];
  selectedSupplier: any;
  selectedCompany: any = null;
  selectedCustomer: any = {};
  rangeCreationDates: Date[];
  rangeCadastros: Date[];
  invoiceNumberFilter: string;
  invoiceTaxNumberFilter: string;

  invoiceTaxList: InvoiceTaxDocumentProductDTO[] = [];
  selectedinvoiceTaxList: InvoiceTaxDocumentProduct[] = [];
  paginationFilter: PaginationFilter = new PaginationFilter();
  loggedMarina: any;
  contentLoaded = false;
  totalRecords = 0;
  totalDocuments: any;
  refresh = false;
  marinaCompanies: MarinaCompany[];

  customers: any[] = [];

  public statusList: Array<{ label: string; value: any }> = [
    {
      label: this.getStatus(InvoiceTaxDocumentStatus.Autorizada),
      value: InvoiceTaxDocumentStatus.Autorizada,
    },
    {
      label: this.getStatus(InvoiceTaxDocumentStatus.Cancelada),
      value: InvoiceTaxDocumentStatus.Cancelada,
    },
    {
      label: this.getStatus(InvoiceTaxDocumentStatus.AguardandoAutorizacao),
      value: InvoiceTaxDocumentStatus.AguardandoAutorizacao,
    },
    {
      label: this.getStatus(InvoiceTaxDocumentStatus.CancelamentoNegado),
      value: InvoiceTaxDocumentStatus.CancelamentoNegado,
    },
    {
      label: this.getStatus(InvoiceTaxDocumentStatus.EmProcessoDeCancelamento),
      value: InvoiceTaxDocumentStatus.EmProcessoDeCancelamento,
    },
    {
      label: this.getStatus(InvoiceTaxDocumentStatus.SolicitandoCancelamento),
      value: InvoiceTaxDocumentStatus.SolicitandoCancelamento,
    },
    {
      label: this.getStatus(InvoiceTaxDocumentStatus.ErroAutorizacao),
      value: InvoiceTaxDocumentStatus.ErroAutorizacao,
    },
    {
      label: this.getStatus(InvoiceTaxDocumentStatus.Importada),
      value: InvoiceTaxDocumentStatus.Importada,
    },
  ];

  public statusImpList: Array<{ label: string; value: string }> = [
    { label: 'Pendente', value: 'Pendente' },
    { label: 'Concluido', value: 'Concluido' },
    { label: 'Parcelado', value: 'Parcelado' },
  ];

  constructor(
    private dialog: DialogService,
    private spinner: NgxSpinnerService,
    private messageService: MessageUtil,
    private sanitizer: DomSanitizer,
    private invoiceTaxDocumentProductService: InvoiceTaxProductService,
    private customerService: CustomerService,
    private invoiceService: InvoiceService,
    private router: Router,
    private messageUtil: MessageUtil
  ) {}

  async ngOnInit(): Promise<void> {
    this.filter = {
      statusList: [
        InvoiceTaxDocumentStatus.AguardandoAutorizacao,
        InvoiceTaxDocumentStatus.Autorizada,
        InvoiceTaxDocumentStatus.CancelamentoNegado,
        InvoiceTaxDocumentStatus.EmProcessoDeCancelamento,
        InvoiceTaxDocumentStatus.SolicitandoCancelamento,
        InvoiceTaxDocumentStatus.ErroAutorizacao,
        InvoiceTaxDocumentStatus.Importada,
      ],
      typeList: ['entry', 'exit'],
      statusImpList: ['Pendente', 'Concluido', 'Parcelado'],
    };
    this.paginationFilter.order = 'DESC';
    this.paginationFilter.sort = 'createDate';
    await this.getCompanies();
    this.getCustomers();
    this.findAll();

    this.contentLoaded = true;
  }

  changeSupplier(): void {
    if (!this.selectedSupplier) {
      this.filter.supplierId = null;
    } else {
      this.filter.supplierId = this.selectedSupplier.id;
    }
  }

  onChangeCreationDate() {
    if (this.rangeCreationDates) {
      if (this.rangeCreationDates[0] != null) {
        this.filter.startCreateDate = this.rangeCreationDates[0];
        if (this.rangeCreationDates[1] != null) {
          this.filter.endCreateDate = this.rangeCreationDates[1];
        } else {
          this.filter.endCreateDate = this.rangeCreationDates[0];
        }
      } else {
        this.filter.startCreateDate = null;
        this.filter.endCreateDate = null;
      }
    }
  }

  onChangeCadastro() {
    if (this.rangeCadastros) {
      if (this.rangeCadastros[0] != null) {
        this.filter.startCadastroDate = this.rangeCadastros[0];
        if (this.rangeCadastros[1] != null) {
          this.filter.endCadastroDate = this.rangeCadastros[1];
        } else {
          this.filter.endCadastroDate = this.rangeCadastros[0];
        }
      } else {
        this.filter.startCadastroDate = null;
        this.filter.endCadastroDate = null;
      }
    }
  }

  exportTable(): void {
    this.findDataExport().subscribe({
      next: (resp) => {
        const dataTable = resp.map((invoiceTax) => {
          return {
            id: invoiceTax.id,
            nfeStatus: invoiceTax.nfeStatus,
            itens: invoiceTax.itemsQty,
            nfeNumero: invoiceTax.nfeNumero,
            serie: invoiceTax.serie,
            type: invoiceTax?.type === 'exit' ? 'Saída' : 'Entrada',
            invoiceNumber: invoiceTax?.invoiceNumber,
            customerName: invoiceTax.customerName,
            createDate:
              invoiceTax.createDate !== null
                ? moment(invoiceTax.createDate).format('DD/MM/YYYY')
                : null,
            updateDate:
              invoiceTax.updateDate !== null
                ? moment(invoiceTax.updateDate).format('DD/MM/YYYY')
                : null,
            valorTotal: invoiceTax.valorTotal?.toFixed(2),
            taxValue: invoiceTax.taxValue,
            status: invoiceTax.status,
          };
        });
        const data = { table: dataTable, type: 'TAX_PRODUCT' };
        this.dialog.open(CustomReportExportComponent, {
          width: '70%',
          height: '90vh',
          dismissableMask: false,
          data,
          header: this.messageUtil.translateKey('EXPORT-TAX-PRODUCT-TABLE'),
        });
      },
      error: (error) => {
        const exception = error.error.data.exception;
        this.messageService.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
    });
  }

  findDataExport(): Observable<InvoiceTaxDocumentProductDTO[]> {
    return new Observable((observer) => {
      this.spinner.show();
      const filter = { ...this.filter };
      filter.size = 1000000;
      this.onChangeCreationDate();
      this.onChangeCadastro();
      this.preparateNumber();

      this.invoiceTaxDocumentProductService
        .findByFilter(this.filter, this.paginationFilter)
        .subscribe({
          next: (response) => {
            this.spinner.hide();
            observer.next(response);
          },
          error: (error) => {
            this.spinner.hide();
            observer.error(error);
          },
        });
    });
  }

  importarXml() {
    this.router.navigate(['/app/billing/invoice-tax-management/import-nfp'], {
      state: {},
    });
  }

  preparateNumber() {
    if (
      this.invoiceNumberFilter != null &&
      this.invoiceNumberFilter != '' &&
      this.invoiceNumberFilter != undefined
    ) {
      if (this.invoiceNumberFilter.includes('-')) {
        const partes = this.invoiceNumberFilter.split('-');
        this.filter.invoiceNumberStart = parseInt(partes[0].trim(), 10);
        this.filter.invoiceNumberEnd = parseInt(partes[1].trim(), 10);
      } else {
        this.filter.invoiceNumberStart = this.invoiceNumberFilter;
        this.filter.invoiceNumberEnd = this.invoiceNumberFilter;
      }
    }
    if (
      this.invoiceTaxNumberFilter != null &&
      this.invoiceTaxNumberFilter != '' &&
      this.invoiceTaxNumberFilter != undefined
    ) {
      if (this.invoiceTaxNumberFilter.includes('-')) {
        const partes = this.invoiceTaxNumberFilter.split('-');
        this.filter.nfeNumeroStart = parseInt(partes[0].trim(), 10);
        this.filter.nfeNumeroEnd = parseInt(partes[1].trim(), 10);
      } else {
        this.filter.nfeNumeroStart = this.invoiceTaxNumberFilter;
        this.filter.nfeNumeroEnd = this.invoiceTaxNumberFilter;
      }
    }
  }

  findAll(): void {
    this.spinner.show();
    this.onChangeCreationDate();
    this.onChangeCadastro();
    this.preparateNumber();

    this.invoiceTaxDocumentProductService
      .findByFilter(this.filter, this.paginationFilter)
      .subscribe({
        next: (response) => {
          this.invoiceTaxList = response;
          this.totalRecords = response.length;
        },
        error: (error) => {
          this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageService.generateMessage(
            exception.type,
            exception.title,
            exception.message
          );
        },
        complete: () => {
          this.spinner.hide();
        },
      });
  }

  cantCancel(invTaxDoc: InvoiceTaxDocumentProduct): boolean {
    const agora = new Date();
    const depois = new Date(invTaxDoc.createDate);
    const diferencaEmMilissegundos = agora.getTime() - depois.getTime();
    const diferencaEmHoras = diferencaEmMilissegundos / (1000 * 60 * 60);
    if (invTaxDoc.type === 'exit' && diferencaEmHoras > 24) {
      return true;
    }
    return false;
  }

  getCompanyName(federalId): string {
    if (this.filteredCompanies && this.filteredCompanies.length >= 0) {
      const company = this.filteredCompanies.find(
        (c) =>
          c.value?.companyFederalId.replace(/[^0-9]+/g, '') ===
          federalId.replace(/[^0-9]+/g),
        ''
      );
      return company.label;
    } else {
      return '';
    }
  }

  cancelInvoiceTaxDocument(invoiceTaxDocument: any): void {
    this.openConfirmation(
      'Cancelar Nota Fiscal e Movimentação de Estoque',
      'Digite a "JUSTIFICATIVA" para cancelar a nota fiscal. O campo deverá ter de 15 a 255 caracteres.',
      'warning'
    )
      .then((resolve) => {
        let params: any = {};
        params = {
          nfpIds: [invoiceTaxDocument.id],
          justificativa: resolve,
        };
        this.spinner.show();
        this.invoiceTaxDocumentProductService
          .cancelInvoiceTax(params)
          .subscribe({
            next: (value) => {
              console.log(value);
              this.messageService.generateMessage(
                'success',
                'SUMMARY.SUCCESS',
                'Solicitado Cancelamento da Nota.'
              );
              this.findAll();
              this.spinner.hide();
            },
            error: (error) => {
              console.log(error);

              this.spinner.hide();
              const exception = error.error.data.exception;
              this.messageService.generateMessage(
                exception.type,
                exception.title,
                exception.message
              );
            },
            complete: () => {
              this.spinner.hide();
            },
          });
      })
      .catch(() => {
        this.messageService.generateMessage(
          MessageUtil.WARNING,
          'Alerta',
          'Nota Fiscal não foi cancelada'
        );
      });
  }

  async openConfirmation(title, text, icon): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      Swal.fire({
        title,
        text,
        input: 'text',
        icon,
        backdrop: false,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Enviar',
        cancelButtonText: 'Não',
        reverseButtons: true,
      }).then(async (result) => {
        if (result.value) {
          if (result.value.length >= 15 && result.value.length <= 255) {
            resolve(result.value);
          } else {
            reject();
          }
        } else if (result.dismiss) {
          reject();
        }
      });
    });
  }

  changeCustomer(): void {
    if (!this.selectedCustomer) {
      this.filter.customerId = null;
    } else {
      this.filter.customerId = this.selectedCustomer.id;
    }
  }

  async getCompanies(): Promise<void> {
    const marina = StorageUtil.getMarina();
    this.marinaCompanies = marina.marinaCompanies;
    if (this.marinaCompanies.length > 0) {
      this.filteredCompanies = this.marinaCompanies.map((c) => ({
        label: c.companyFederalName,
        value: c,
      }));
      this.filteredCompanies.unshift({
        label: marina.companyName,
        value: marina,
      });
      this.filteredCompanies.unshift({
        label: 'Todas as empresas',
        value: null,
      });
    }
    this.loggedMarina = marina;
  }

  async clear(): Promise<void> {
    this.filter = {
      federalId: null,
      invoiceNumberStart: null,
      invoiceNumberEnd: null,
      nfeNumeroStart: null,
      nfeNumeroEndt: null,
      startCreateDate: null,
      endCreateDate: null,
      startCadastroDate: null,
      endCadastroDate: null,
      valorTotalStart: null,
      valorTotalEnd: null,
      aliquotaIssStart: null,
      aliquotaIssEnd: null,
      customerId: null,
    };
    this.selectedCompany = null;
    this.selectedCustomer = null;
    this.invoiceNumberFilter = null;
    this.invoiceTaxNumberFilter = null;
    this.rangeCreationDates = [];
    this.rangeCadastros = [];

    this.paginationFilter = {
      order: 'DESC',
      page: 0,
      size: this.numberOfRows,
      sort: 'createDate',
    };
  }

  getCustomers(): void {
    this.customerService.getAll().subscribe((data) => {
      this.customers = data;
      this.filteredCustomers = [{ label: 'Todos', value: null }].concat(
        this.customers.map((c) => ({ label: c.name, value: c }))
      );
    });
  }

  baixarXml() {
    if (this.selectedinvoiceTaxList.length > 0) {
      const listUrl: string[] = this.selectedinvoiceTaxList.map(
        (invoiceTax) => {
          return invoiceTax.serviceDocumentXml;
        }
      );
      this.invoiceTaxDocumentProductService.downloadXml(listUrl).subscribe({
        next: (response) => {
          const blob = new Blob([response], { type: 'application/xml' });
          if (blob.size === 0) {
            this.messageService.generateMessage(
              'error',
              'SUMMARY.ERROR',
              'Não foi possível gerar o arquivo.'
            );
          }
          const objectUrl = URL.createObjectURL(blob);
          const downloadLink = document.createElement('a');
          downloadLink.href = objectUrl;
          downloadLink.download = 'arquivo.zip';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          URL.revokeObjectURL(objectUrl);
        },
        error: (error) => {
          this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageService.generateMessage(
            exception.type,
            exception.title,
            exception.message
          );
        },
        complete: () => {
          this.spinner.hide();
        },
      });
    }
  }

  extract(invoiceId): void {
    this.spinner.show();
    this.invoiceService.extract(invoiceId).subscribe({
      next: (data) => {
        this.openExtract(
          this.sanitizer.bypassSecurityTrustResourceUrl(
            URL.createObjectURL((b64toBlob as any)(data, 'application/pdf'))
          )
        );
      },
      error: (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageService.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
      complete: () => this.spinner.hide(),
    });
  }

  openExtract(path): void {
    this.dialog.open(ExtractInvoiceComponent, {
      width: '100%',
      height: '100%',
      data: { path },
      header: 'Extrato da fatura - PDF',
    });
  }

  getStatus(statusValue): string {
    if (statusValue !== null && statusValue !== undefined) {
      return statusValue.replace(/([A-Z])/g, ' $1');
    }
    return '';
  }

  openInvoiceTaxDocument(invTaxDoc): void {
    this.router.navigate(['/app/billing/invoice-tax-management/import-nfp'], {
      state: { taxdoc: invTaxDoc },
    });
  }
}
