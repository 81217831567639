<div *ngIf="showDashboard else welcome">
<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{ 'DASHBOARD' | translate }}</span>
        <button class="fab-button primary" (click)="refresh()">
            <i class="pi pi-refresh"></i>
        </button>
    </p-header>
    <div class="notifications">
        <app-notifications *ngIf="days > 5" [routerLink]="'/app/my-account/my-invoice'"
          [isError]="true">
                Você possui fatura(s) vencida(s) há {{days}} dia(s).
        </app-notifications>
        <app-notifications *ngIf="days < 0" [routerLink]="'/app/my-account/my-invoice'">
                Sua fatura vence em {{days * (-1)}} dia(s).
        </app-notifications>
        <app-notifications *ngIf="days === 0" [routerLink]="'/app/my-account/my-invoice'">
            Sua fatura vence(m) hoje.
        </app-notifications>
        <app-notifications [isError]="true" *ngIf="numberContractsInAnalysis$ | async as numberContractsInAnalysis" [routerLink]="'/app/billing/contracts'">
            Você possui {{numberContractsInAnalysis}} contrato(s) em análise.
        </app-notifications>
        <app-notifications [isError]="true" *ngIf="numberExpiredPeriodicalService$ | async as numberExpiredPeriodicalService" [routerLink]="'/app/billing/tuition/overdue'">
            Você possui {{numberExpiredPeriodicalService}} serviço(s) de embarcação vencido.
        </app-notifications>
        <app-notifications *ngIf="bankingBillet$ | async as pendingBillet" [routerLink]="'/app/billing/billets-management/billet'"
            [queryParams]="{filter: 'pendingBank'}"
            [isError]="true">
                Você possui {{pendingBillet}} boleto(s) não cancelado(s) no banco.
        </app-notifications>
        <app-notifications [isError]="true" *ngIf="refusedBillets$ | async as refusedBillets" [routerLink]="'/app/billing/billets-management/billet'"
            [queryParams]="{filter: 'refused'}">
                Você possui {{refusedBillets.length}} boleto(s) recusado(s) ativo(s) no sistema.
        </app-notifications>
        <app-notifications *ngIf="stashNotifications == 1" [isError]="true">
          <a style="text-decoration: none; color: #a94442" href="https://chamados.easymarine.com.br/dashboard">
            Houve {{stashNotifications}} interação em seu chamado aperte aqui e veja.
          </a>
        </app-notifications>
        <app-notifications *ngIf="stashNotifications > 1" [isError]="true">
          <a style="text-decoration: none; color: #a94442" href="https://chamados.easymarine.com.br/dashboard">
            Houveram {{stashNotifications}} interações em seus chamados aperte aqui e as veja.
          </a>
        </app-notifications>
        <app-notifications *ngIf="marinaContracPrice?.contractPriceFutureStartDate"
        [routerLink]="'/app/billing/contracts'">
                Os Contratos gerados a partir de
                {{marinaContracPrice.contractPriceFutureStartDate |  date: 'dd/MM/yy' }} custarão
                {{marinaContracPrice.contractPriceFuturePrice | currency : ' '}}.
        </app-notifications>
        <app-notifications *ngIf="numberNeverWashOrEngineTurn$ | async" [routerLink]="'/app/operational/wash-engine-turn'"
            [queryParams]="{filter: 'never'}">
                Existem embarcações ativas que nunca tiveram lavação ou giro de motor.
        </app-notifications>
    </div>

    <div class="graphs-container">
        <div *ngIf="isSlings" class="graph box">
            <h3>Lingadas de hoje</h3>
            <p-chart [responsive]="true" type="doughnut" [data]="statusToday" height="220px" [options]="doughnutOptions"
                *ngIf="slingStatusToday.length !== 0"></p-chart>
            <div *ngIf="slingStatusToday.length == 0" style="text-align: center">
                <h4>{{ 'NO-SLINGS-TODAY' | translate }}</h4>
            </div>
        </div>
        <div *ngIf="isSlings" class="graph box">
            <h3>Origens da lingada</h3>
            <p-chart [responsive]="true" type="doughnut" [data]="origins" height="220px" [options]="doughnutOptions"
                *ngIf="slingOrigin.length !== 0"></p-chart>
            <div *ngIf="slingOrigin.length == 0" style="text-align: center">
                <h4>{{ 'NO-SLINGS-TODAY' | translate }}</h4>
            </div>
        </div>
        <div *ngIf="isSlings" class="graph box">
            <h3>Resumo de lingadas</h3>
            <p-chart [responsive]="true" type="bar" [data]="quantityChart" height="220px" width="500px"
                [options]="barOptions" *ngIf="slingQuantity.length !== 0"></p-chart>
            <div *ngIf="slingQuantity.length == 0" style="text-align: center">
                <h4>{{ 'NO-SLINGS-TODAY' | translate }}</h4>
            </div>
        </div>

        <div class="graph box">
            <h3>Cliente App</h3>
            <p-chart [responsive]="true" type="doughnut" [data]="customerApp" height="220px" [options]="doughnutOptions">
            </p-chart>
        </div>
        <div class="graph box">
            <h3>Documentos das embarcações</h3>
            <p-chart [responsive]="true" type="doughnut" [data]="boatDocuments" height="220px" [options]="doughnutOptions">
            </p-chart>
        </div>
        <div class="graph box">
            <h3>Seguro das embarcações</h3>
            <p-chart [responsive]="true" type="doughnut" [data]="boatInsurers" height="220px" [options]="doughnutOptions">
            </p-chart>
        </div>
        <div class="graph box">
            <h3>Vigência das apólices</h3>
            <p-chart (onDataSelect)="redirectToSelectionFiltered($event, 'insuranceValidity')" [responsive]="true" type="doughnut" [data]="insuranceValidation" height="220px"
                [options]="doughnutOptions"></p-chart>
        </div>
        <div class="graph box">
            <h3>Tipos de Cliente</h3>
            <p-chart [responsive]="true" type="doughnut" [data]="customerTypes" height="220px" [options]="doughnutOptions">
            </p-chart>
        </div>
        <div class="graph box">
            <h3>Habilitação dos clientes</h3>
            <p-chart [responsive]="true" type="doughnut" [data]="licenceConditions" height="220px" [options]="doughnutOptions"></p-chart>
        </div>
        <div class="graph box space-around">
            <span [ngStyle]="{'color': qtyBoatMaintenance > 0 ? 'red' : '#222222' }" pTooltip="{{boatsMaintenance}}"
                tooltipPosition="bottom" style="font-size: 70px;">
                {{qtyBoatMaintenance}}
            </span>
            <div class="box-info">
                <span>
                    Barcos em manutenção
                </span>
            </div>
            <img style="width: 80px;height: 80px;" src="assets/images/boat-maintenance.png" alt="">
        </div>
        <div class="graph box">
            <h3>Alerta de estoque de produtos</h3>
            <p-table [value]="stockReport" class="primeTable" autoLayout="true">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Produto</th>
                        <th class="text-center">Estoque</th>
                        <th class="text-center">Reservado</th>
                        <th class="text-center">Saldo</th>
                        <th class="text-center">Mínimo</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-product>
                    <tr>
                        <td>{{product.name}}</td>
                        <td class="text-right">{{product.stock | number:'1.0-0'}}</td>
                        <td class="text-right">{{product.reserved | number:'1.0-0'}}</td>
                        <td class="text-right">{{product.balance | number:'1.0-0'}}</td>
                        <td class="text-right">{{product.minimalStock | number:'1.0-0'}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</p-fieldset>
</div>
<ng-template #welcome>
  <div class="dashalt">
    <img src="../../../assets/images/EASY_MARINE_MARCA_OFICIAL.png" alt="EasyMarine">
    <span>Bem-vindo a Easymarine</span>
  </div>
 </ng-template>
