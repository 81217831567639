<form [formGroup]="formGroup">
  <div class="table" formArrayName="boatEngines">
    <p-table
      [value]="getEngines()"
      [rows]="5"
      [paginator]="true"
      autoLayout="true"
      alwaysShowPaginator="false"
    >
      <ng-template pTemplate="header">
        <tr>
          <th class="text-center">{{ "MARK" | translate }}</th>
          <th class="text-center">{{ "MODEL" | translate }}</th>
          <th class="text-center">{{ "POWER" | translate }}</th>
          <th class="text-center">{{ "SERIAL-NUMBER" | translate }}</th>
          <th class="text-center">{{ "YEAR" | translate }}</th>
          <th class="text-right">
            <button
              class="fab-button primary"
              type="button"
              (click)="addEngine()"
              pTooltip="Adicionar um motor no barco"
              tooltipPosition="bottom"
            >
              <i class="pi pi-plus"></i>
            </button>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-engine let-rowIndex="rowIndex">
        <tr [formGroupName]="rowIndex">
          <td class="text-center">
            <div
              class="form-control"
              *ngIf="!showInput[rowIndex]; else othersTemplate"
            >
              <p-dropdown
                appendTo="body"
                [filter]="true"
                [options]="engineMarkOptions"
                formControlName="mark"
                name="mark"
                (onChange)="onMarkChange($event, rowIndex)"
              >
              </p-dropdown>
            </div>
            <ng-template #othersTemplate>
              <div class="form-control">
                <input
                  pInputText
                  name="mark"
                  formControlName="mark"
                  pTooltip="Marca do motor"
                  tooltipPosition="bottom"
                  (blur)="send()"
                />
              </div>
            </ng-template>
          </td>
          <td class="text-center">
            <div class="form-control">
              <input
                pInputText
                name="model"
                formControlName="model"
                pTooltip="Modelo do motor"
                tooltipPosition="bottom"
                (blur)="send()"
              />
            </div>
          </td>
          <td class="text-center">
            <div class="form-control">
              <input
                pInputText
                name="power"
                type="number"
                formControlName="power"
                pTooltip="Potência do motor"
                tooltipPosition="bottom"
                (blur)="send()"
              />
            </div>
          </td>
          <td class="text-center">
            <div class="form-control">
              <input
                pInputText
                name="serialNumber"
                formControlName="serialNumber"
                pTooltip="Número de série do motor"
                tooltipPosition="bottom"
                (blur)="send()"
              />
            </div>
          </td>
          <td class="text-center">
            <div class="form-control">
              <input
                pInputText
                name="manufactureYear"
                formControlName="manufactureYear"
                pTooltip="Ano do motor"
                tooltipPosition="bottom"
                (blur)="send()"
              />
            </div>
          </td>

          <td class="text-right">
            <div
              class="table-action"
              (click)="deleteEngine(rowIndex)"
              pTooltip="Remover cliente proprietário da embarcação"
            >
              <i class="pi pi-trash"></i>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</form>
