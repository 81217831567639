<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{ 'TITLE-GENERAL-CONFIG' | translate }}</span>
        <button class="primary fab-button" (click)="slingConfigDialog()" [disabled]="configs.length !== 0"
            pTooltip="Somente uma configuração permitida" tooltipPosition="bottom">
            <i class="pi pi-plus" style="font-size: 21px;"></i>
        </button>
    </p-header>

    <div class="table">
        <p-table [value]="configs" class="primeTable" [resizableColumns]="true" [autoLayout]="true">
            <ng-template pTemplate="header">
                <tr>
                    <th class="text-center">Data/Hora de Operação</th>
                    <th class="text-center">Máximo de agendamentos</th>
                    <th class="text-center">Tem abastecimento</th>
                    <th class="text-center">Tem consumível</th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-config>
                <tr>
                    <td class="text-center">{{ config.operationStart | date: "dd/MM/yyyy" }}
                        até
                        {{ config.operationEnd | date: "dd/MM/yyyy" }}
                    </td>
                    <td class="text-center">{{ config.numberSling }}</td>
                    <td class="text-center">{{ config.hasFuelSupply ? 'Sim' : 'Não' }}</td>
                    <td class="text-center">{{ config.hasConsumables ? 'Sim' : 'Não' }}</td>
                    <td class="text-center">
                        <div class="table-action" (click)="slingConfigDialog(config)" pTooltip="Editar"
                            tooltipPosition="bottom">
                            <i class="pi pi-pencil"></i>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>

    </div>
</p-fieldset>
