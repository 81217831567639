import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import moment from 'moment';
import { EasyMarineHttpApiLog } from './easy-marine-http-api-log';

@Injectable()
export class BoatLogService {

    constructor(private httpClient: EasyMarineHttpApiLog) {
    }

    findBoats(filter: any): Observable<any> {
        let params = new HttpParams();
        if (filter.dateStart) {
            params = params.append('dateStart', moment(filter.dateStart).format());
        }
        if (filter.dateEnd) {
            params = params.append('dateEnd', moment(filter.dateEnd).format());
        }
        if (filter.userId) {
            params = params.append('userId', filter.userId);
        }
        if (filter.boatId) {
            params = params.append('boatId', filter.boatId);
        }
        return this.httpClient.get(environment.apiLog + `/boats-log/${filter.marinaId}`, { params })
            .pipe(map((response: any) => response));
    }

    findExclusionServiceBoatId(serviceBoatId: number, marinaId): Observable<any> {
        return this.httpClient.get(environment.apiLog + `/boats-log/${marinaId}/serviceBoat/${serviceBoatId}`)
            .pipe(map((response: any) => response));
    }
}
