import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from 'src/app/models';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { UserService } from 'src/app/services/user.service';
import { FormatUtil } from 'src/app/utils/format.util';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';

@Component({
  selector: 'app-user-app-tab',
  templateUrl: './user-app-tab.component.html',
  styleUrls: ['./user-app-tab.component.scss']
})
export class UserAppTabComponent implements OnInit {

  @Output()
  eventsUser = new EventEmitter();

  numberOfRows = 10;
  users: User[] = [];
  usersTable: User[] = [];
  userStatus: boolean = null;

  constructor(
    private slingConfigService: SlingConfigService,
    private spinner: NgxSpinnerService,
    private userService: UserService,
    private messageUtil: MessageUtil
  ) { }

  ngOnInit(): void {
    this.loadSlingConfig();
    this.fillUsersTable();
  }

  private loadSlingConfig(): void {
    this.slingConfigService.getSlingConfig().subscribe(
      (data) => {
        const slingConfig = data[0];
        this.numberOfRows = slingConfig != null ? slingConfig.numberOfRecordsPerPage : 10;
      },
      (error) => null
    );
  }

  filterGlobal(value: string): void {
    this.usersTable = this.users.filter(
      (u) =>
        (FormatUtil.getNotAccents(u.firstName).toUpperCase().includes(FormatUtil.getNotAccents(value).toUpperCase()) ||
          u.firstName.toUpperCase().includes(value.toUpperCase())
        ) ||
        (FormatUtil.getNotAccents(u.lastName).toUpperCase().includes(FormatUtil.getNotAccents(value).toUpperCase()) ||
          u.lastName.toUpperCase().includes(value.toUpperCase())
        ) ||
        (u.email.toUpperCase().includes(value.toUpperCase())) ||
        (u.username.toUpperCase().includes(value.toUpperCase()))
    );
  }

  fillUsersTable(): void {
    this.spinner.show();
    this.userService.getAppUsersRoleFiltered().subscribe(
      (u) => {
        this.users = u;
        this.usersTable = this.users;
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      },
      () => this.spinner.hide());
  }

  openUserFormDialog(idUser?: number): void {
    this.eventsUser.emit({ isDelete: false, id: idUser, origin: 'TabApp' });
  }

  openUserDeleteConfirmDialog(idUser: number): void {
    this.eventsUser.emit({ isDelete: true, id: idUser, origin: 'TabApp' });
  }

  getCustomerId(user: User): string {
    const customers: number[] = user.customers.filter((x) => x.marina.id === StorageUtil.getMarinaId()).map((x) => x.id);
    return customers.toString();
  }
}

