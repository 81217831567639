import { Component, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { SpecialOperatingDayResponseDTO } from 'src/app/models/dtos/config/special-operating-day-response-dto';
import { OperatingDayConfigService } from 'src/app/services/config/operating-day-config.service';
import { SpecialOperatingDayClosedModalComponent } from '../modals/special-operating-day-closed-modal/special-operating-day-closed-modal.component';
import { SpecialOperatingDayOpenModalComponent } from '../modals/special-operating-day-open-modal/special-operating-day-open-modal.component';

@Component({
  selector: 'app-special-day-marina',
  templateUrl: './special-day-marina.component.html',
  styleUrls: ['./special-day-marina.component.scss']
})
export class SpecialDayMarinaComponent implements OnInit {

  days: SpecialOperatingDayResponseDTO[] = []

  constructor(
    private readonly operatingDayConfigService: OperatingDayConfigService,
    private readonly dialog: DialogService
  ) {
    this.loadSpecialOperatingDays();
  }

  loadSpecialOperatingDays() {
    this.operatingDayConfigService.getAllSpecialOperatingDay().subscribe(
      (data) => {
        this.days = data;
      },
      (err) => { }
    );
  }

  ngOnInit(): void {
  }

  createSpecialDay() {
    this.openSpecialOperatingDayOpenModal();
  }

  createDayClosed() {
    this.openSpecialOperatingDayClosedModal();
  }

  edit(day: SpecialOperatingDayResponseDTO): void {
    if (day.inOperation) {
      this.openSpecialOperatingDayOpenModal(day.id);
    } else {
      this.openSpecialOperatingDayClosedModal(day.id);
    }
  }

  openSpecialOperatingDayClosedModal(specialOperatingDayId: number = null) {
    const dialogRef = this.dialog.open(SpecialOperatingDayClosedModalComponent, {
      header: 'Dia Especial: Marina Fechada',
      data: {
        specialOperatingDayId
      }
    })
    dialogRef.onClose.subscribe((result) => {
      if (result) {
        this.loadSpecialOperatingDays();
      }
    })
  }

  openSpecialOperatingDayOpenModal(specialOperatingDayId: number = null) {
    const dialogRef = this.dialog.open(SpecialOperatingDayOpenModalComponent, {
      header: 'Dia Especial: Marina Aberta',
      data: {
        specialOperatingDayId
      }
    })
    dialogRef.onClose.subscribe((result) => {
      if (result) {
        this.loadSpecialOperatingDays();
      }
    })
  }
}
