import { NoticiesEmailsComponent } from './noticies-emails/noticies-emails.component';
import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { Notice } from 'src/app/models/notice';
import { NoticeService } from 'src/app/services/notice.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { MessageUtil } from 'src/app/utils/message.util';
import Swal from 'sweetalert2';
import { NoticiesFormComponent } from './noticies-form/noticies-form.component';

@Component({
  selector: 'app-noticies',
  templateUrl: './noticies.component.html',
  styleUrls: ['./noticies.component.scss']
})
export class NoticiesComponent implements OnInit {

  noticies: Notice[] = [];

  noticeTargetTypes: any;
  noticeTypes: any;
  numberOfRows = 10;

  constructor(
    public dialog: DialogService,
    private noticeService: NoticeService,
    private spinner: NgxSpinnerService,
    private messageUtil: MessageUtil,
    private slingConfigService: SlingConfigService
  ) {
    this.noticeTargetTypes = new Array(
      { label: 'Todos alvos', value: null },
      { label: 'Todos os clientes', value: 'MARINA' },
      { label: 'Cliente', value: 'CUSTOMER' },
    );
    this.noticeTypes = new Array(
      { label: 'Todos tipos', value: null },
      { label: 'Regular', value: 'REGULAR' },
      { label: 'Importante', value: 'IMPORTANT' },
      { label: 'Urgente', value: 'URGENT' },
      { label: 'Aviso de mau tempo', value: 'WEATHER_WARNING'}
    );
  }

  ngOnInit(): void {
    this.loadSlingConfig();
    this.fillNoticiesTable();
  }

  fillNoticiesTable(): void {
    this.spinner.show();
    this.noticeService.getByMarina()
      .subscribe((data) => {
        this.noticies = data;
        this.spinner.hide();
      }, (error) => {
        console.log(error);
        this.spinner.hide();
      });
  }

  openNoticeFormById(id: number): void {
    this.noticeService.getById(id)
      .subscribe((data) => {
        this.openNoticeForm(data);
      });
  }

  openNoticeEmail(noticie: any): void {
    this.dialog.open(NoticiesEmailsComponent, {
      header: this.messageUtil.translateKey('Log de envio de emails da notícia ' + noticie.title),
      height: '500px',
      width: '950px',

      data: {
        noticie
      }
    }).onClose.subscribe(() => {

    });
  }



  openNoticeForm(notice?): void {
    this.dialog.open(NoticiesFormComponent, {
      header: this.messageUtil.translateKey('NOTICE-REGISTRATION'),
      data: {
        notice
      }
    }).onClose.subscribe(() => {
      this.fillNoticiesTable();
    });
  }

  openNoticeDeleteConfirmDialog(id: number): void {
    Swal.fire({
      text: 'Deseja realmente excluir a notícia?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((isConfirm) => {
      if (isConfirm.value) {
        this.noticeService.delete(id)
          .subscribe(() => {
            this.fillNoticiesTable();
            this.messageUtil.generateMessage('success', 'SUMMARY.SUCCESS', 'MSG.DELETE-SUCCESS');
          }, () => {
            this.messageUtil.generateMessage('error', 'SUMMARY.ERROR', 'MSG.DELETE-FAILURE');
          });
      }
    });
  }

  onDisplay(initialDateTime, finalDateTime): string {
    if (moment().isBefore(moment(initialDateTime).set('hour', 0).set('minute', 0))) {
      return 'AWAITING-DATE';
    } else if (moment().isBefore(moment(finalDateTime).set('hour', 23).set('minute', 59))) {
      return 'SHOWING';
    } else {
      return 'FINALIZED';
    }
  }

  loadSlingConfig(): void {
    this.slingConfigService.getSlingConfig().subscribe(
      (data) => {
        const slingConfig = data[0];
        if (slingConfig != null) {
          this.numberOfRows = slingConfig.numberOfRecordsPerPage;
        } else {
          this.numberOfRows = 10;
        }
      },
      (error) => null);
  }

  getTargetType(notice: Notice): string{
    if(notice.noticeTargetType.toUpperCase() === 'CUSTOMER') {
      return this.messageUtil.translateKey(notice.noticeTargetType.toUpperCase());
    } else {
      return 'Todos os clientes';
    }
  }

}
