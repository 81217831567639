<p-fieldset>
  <p-header class="article-title">
    <div class="article-title">{{ "OPTIONALS" | translate }}</div>
  </p-header>
  <ul class="accessories">
    <li *ngFor="let accessory of logsTable()">
      <div
        style="padding: 5px; border-radius: 5px;"
        [ngClass]="{
          'item-new-log': isChanged(accessory) && isNew,
          'item-old-log': isChanged(accessory) && !isNew
        }"
      >
        {{ accessory.accessory.name }} - {{ accessory.quantity }}
      </div>
    </li>
  </ul>
</p-fieldset>
<br />
