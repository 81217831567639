import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ShortUrlDTO } from '../models/dtos/short-url.dto';
import { APPCONFIG } from '../config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class ShortUrlService {

    constructor(private http: HttpClient) {

    }

    findTable(): Observable<ShortUrlDTO[]> {
        return this.http.get(APPCONFIG.apiHost + '/secure/url/table')
            .pipe(map((response: any) => response.data.urls as ShortUrlDTO[]));
    }

    delete(id: number): Observable<number> {
        return this.http.delete(APPCONFIG.apiHost + '/secure/url/' + id)
            .pipe(map((response: any) => response.data.id as number));
    }

}
