import { Injectable } from '@angular/core';
import { Boat } from '../models/boat';
import { RequestOrigin } from '../models/enums';
import { ShipType } from '../models/enums/ship-type';

@Injectable()
export class ImageUtil {

    public getBoatImage(boat: Boat): string {

        let imagePath = '';
        switch (boat.shipyardModel.shipType) {
            case ShipType.InflatableBoat: {
                imagePath = 'assets/images/boats/inflatable-boat.png';
                break;
            }
            case ShipType.JetSki: {
                imagePath = 'assets/images/boats/jetski.png';
                break;
            }
            case ShipType.Motorboat: {
                imagePath = 'assets/images/boats/motor-boat.png';
                break;
            }
            case ShipType.Sailboat: {
                imagePath = 'assets/images/boats/sail-boat.png';
                break;
            }

            case ShipType.Yatch: {
                imagePath = 'assets/images/boats/yatch.png';
                break;
            }
            case ShipType.Catamaran: {
                imagePath = 'assets/images/boats/catamaran.png';
                break;
            }
            case ShipType.Schooner: {
                imagePath = 'assets/images/boats/schooner.png';
                break;
            }
            case ShipType.Trawler: {
                imagePath = 'assets/images/boats/trawler.png';
                break;
            }
            case ShipType.Kayak: {
                imagePath = 'assets/images/boats/kayak.png';
                break;
            }
            case ShipType.Canoe: {
                imagePath = 'assets/images/boats/canoe.png';
                break;
            }

        }
        return imagePath;
    }

    public getImageOrigin(origin: RequestOrigin): string {
        switch (origin) {
            case RequestOrigin.CustomerApp:
                return 'assets/images/mobile.png';
            case RequestOrigin.EasyWeb:
                return 'assets/images/web.png';
            case RequestOrigin.GestorApp:
                return 'assets/images/gestor.png';
            case RequestOrigin.TotemApp:
                return 'assets/images/totem.png';
            case RequestOrigin.MarinheiroApp:
                return 'assets/images/sailor.png';
            default:
                return 'assets/images/web.png';
        }
    }
}
