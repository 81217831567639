
export class ProductMovementFilter{
    dataInicial: Date;
    dataFinal: Date;
    productId: number;
    marinaId: number;
    boatId: number;
    customerId: number;
    type: string;

    constructor(){
        this.dataInicial = null;
        this.dataFinal = null;
        this.productId = null;
        this.marinaId = null;
        this.boatId = null;
        this.customerId = null;
    }
}
