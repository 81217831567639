<div class="buttons">
  <button class="success easymarine" (click)="createSpecialDay()">
    {{'Adicionar horário especial' | translate}}
    <i class="pi pi-clock"></i>
  </button>
  <button class="primary easymarine" (click)="createDayClosed()">
    {{'Adicionar dia fechada' | translate}}
    <i class="pi pi-ban"></i>
  </button>
</div>
<br>
<p-table [value]="days" [paginator]="true" [rows]="10" [showCurrentPageReport]="true" autoLayout="true">
  <ng-template pTemplate="header">
    <tr>
      <th class="text-center">
        {{'Data' | translate}}
      </th>
      <th class="text-center">
        {{'Tipo alteração' | translate}}
      </th>
      <th class="text-center">
        {{'Obs' | translate}}
      </th>
      <th class="text-center">
        {{'Filas' | translate}}
      </th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-day>
    <tr>
      <td class="text-center">
        {{day.date | date:'dd/MM/yyyy'}}
      </td>
      <td class="text-center">
        {{(day.inOperation ? 'Horário Especial' : 'Marina Fechada') | translate}}
      </td>
      <td class="text-center">
        {{day.observation}}
      </td>
      <td class="text-center">
        {{day.allQueues ? ('Todas' | translate) : day.queueNames}}
      </td>

      <td>
        <div (click)="edit(day)" class="table-action">
          <i class="pi pi-pencil"></i>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
