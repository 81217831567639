<div>
  <p-tabView [activeIndex]="selectTab">
    <p-tabPanel header="{{ 'GENERAL-DATA' | translate }}" [disabled]="!isGeneralData">
      <app-boat-log-general *ngIf="isGeneralChange" [boatLog]="boatLog" [isNew]="true"></app-boat-log-general>
      <app-boat-log-general *ngIf="isGeneralChange" [boatLog]="boatLog" [isNew]="false"></app-boat-log-general>
      <app-boat-log-customer *ngIf="isCustomerOwnerChange" [owners]="owners" [ownersOld]="ownersOld" [isNew]="true"></app-boat-log-customer>
      <app-boat-log-customer *ngIf="isCustomerOwnerChange" [owners]="owners" [ownersOld]="ownersOld" [isNew]="false"></app-boat-log-customer>
      <app-boat-log-authorized *ngIf="isCustomerAuthorizedChange" [authorizeds]="authorizeds" [owners]="owners" [authorizedsOld]="authorizedsOld" [ownersOld]="ownersOld" [isNew]="true"></app-boat-log-authorized>
      <app-boat-log-authorized *ngIf="isCustomerAuthorizedChange" [authorizeds]="authorizeds" [owners]="owners" [authorizedsOld]="authorizedsOld" [ownersOld]="ownersOld" [isNew]="false"></app-boat-log-authorized>
      <app-boat-log-sailor *ngIf="isSailorChange" [sailors]="sailors" [sailorsOld]="sailorsOld" [isNew]="true"></app-boat-log-sailor>
      <app-boat-log-sailor *ngIf="isSailorChange" [sailors]="sailors" [sailorsOld]="sailorsOld" [isNew]="false"></app-boat-log-sailor>
      <app-boat-log-hull *ngIf="isHullChange" [boatLog]="boatLog" [isNew]="true"></app-boat-log-hull>
      <app-boat-log-hull *ngIf="isHullChange" [boatLog]="boatLog" [isNew]="false"></app-boat-log-hull>
      <app-boat-log-tie *ngIf="isTieChange" [boatLog]="boatLog" [isNew]="true"></app-boat-log-tie>
      <app-boat-log-tie *ngIf="isTieChange" [boatLog]="boatLog" [isNew]="false"></app-boat-log-tie>
      <app-boat-log-insurer *ngIf="isInsurerChange" [boatLog]="boatLog" [isNew]="true"></app-boat-log-insurer>
      <app-boat-log-insurer *ngIf="isInsurerChange" [boatLog]="boatLog" [isNew]="false"></app-boat-log-insurer>
      <app-boat-log-operator *ngIf="isOperatorChange" [boatLog]="boatLog" [isNew]="true"></app-boat-log-operator>
      <app-boat-log-operator *ngIf="isOperatorChange" [boatLog]="boatLog" [isNew]="false"></app-boat-log-operator>
    </p-tabPanel>
    <p-tabPanel header="{{ 'INVENTORY' | translate }}" [disabled]="!isInventory">
        <app-boat-log-accessory-optional *ngIf="isAccessoryOptionalChange" [accessoryOptional]="accessoryOptional" [accessoryOptionalOld]="accessoryOptionalOld" [isNew]="true"></app-boat-log-accessory-optional>
        <app-boat-log-accessory-optional *ngIf="isAccessoryOptionalChange" [accessoryOptional]="accessoryOptional" [accessoryOptionalOld]="accessoryOptionalOld" [isNew]="false"></app-boat-log-accessory-optional>
        <app-boat-log-accessory-accessory *ngIf="isAccessoryAccessoryChange" [accessoryAccessory]="accessoryAccessory" [accessoryAccessoryOld]="accessoryAccessoryOld" [isNew]="true"></app-boat-log-accessory-accessory>
        <app-boat-log-accessory-accessory *ngIf="isAccessoryAccessoryChange" [accessoryAccessory]="accessoryAccessory" [accessoryAccessoryOld]="accessoryAccessoryOld" [isNew]="false"></app-boat-log-accessory-accessory>
    </p-tabPanel>
    <p-tabPanel header="{{ 'SERVICES' | translate }}" [disabled]="!isServices">
      <app-boat-log-service-boat *ngIf="isServiceChange" [serviceBoats]="serviceBoats" [serviceBoatsOld]="serviceBoatsOld" [isNew]="true"></app-boat-log-service-boat>
      <app-boat-log-service-boat *ngIf="isServiceChange" [serviceBoats]="serviceBoats" [serviceBoatsOld]="serviceBoatsOld" [isNew]="false"></app-boat-log-service-boat>
    </p-tabPanel>
  </p-tabView>
  <div style="margin: 15px;">
    <div class="content-log">
      <div>
        <label>Data/hora</label>
        <label>{{ boatLog.logTime | date: "dd/MM/yyyy - HH:mm" }}</label>
      </div>
      <div>
        <label>Usuário</label>
        <label>{{ boatLog.user.username }}</label>
      </div>
      <div>
        <label>Origem</label>
        <label>{{ boatLog.origin }}</label>
      </div>
      <div>
        <label>ação</label>
        <label>{{ boatLog.operationLog | translate }}</label>
      </div>
    </div>
    <div class="buttons">
      <button class="easymarine primary" (click)="dialogRef.close()">
        Fechar
      </button>
    </div>
  </div>
</div>
