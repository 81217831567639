import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { CustomerService } from 'src/app/services/customer.service';
import { InvoiceLogService } from 'src/app/services/invoice-log.service';
import { InvoiceTaxLogService } from 'src/app/services/invoice-tax-log.service';
import { UserService } from 'src/app/services/user.service';
import { StorageUtil } from 'src/app/utils/storage.util';

@Component({
  selector: 'app-invoice-log',
  templateUrl: './invoice-log.component.html',
  styleUrls: ['./invoice-log.component.scss']
})
export class InvoiceLogComponent {

  logs;
  rangeLogDate: Date[];

  filter = { marinaId: 0, dateStart: new Date(), dateEnd: new Date(), userId: null, customerId: null };
  
  private unsubscriber: Subscription = new Subscription();
  
  constructor(
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private customerService: CustomerService,
    private invoicelogService: InvoiceLogService
  ) {}
  
  async ngOnInit(): Promise<void> {
    const marina = StorageUtil.getMarina();
    this.filter.marinaId = marina.id;
    this.spinner.show();
    await this.find();
    //this.loadCustomers();
    this.spinner.hide();
  }

  find(){
    this.onChangeLogDate();
    this.unsubscriber.add(
      this.invoicelogService.findLogs(this.filter).subscribe(
        (res: any) => {
          this.logs = res;
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      )
    );
  }

  onChangeLogDate(){
    if(this.rangeLogDate){
      if (this.rangeLogDate[0] != null) {
        this.filter.dateStart = this.rangeLogDate[0];
        if (this.rangeLogDate[1] != null) {
          this.filter.dateEnd = this.rangeLogDate[1];
        } else{
          this.filter.dateEnd = this.rangeLogDate[0];
        }
      }else{
        this.filter.dateStart = null;
        this.filter.dateEnd = null;
      }
    }
  }
}
