import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { SalesStatesService } from '../sales-states.service';
import { StorageUtil } from 'src/app/utils/storage.util';
import { InvoicePaid } from 'src/app/models/invoice-paid';
import { InvoicePaidService } from 'src/app/services/invoice-paid.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageUtil } from 'src/app/utils/message.util';

@Component({
  selector: 'app-splitted-payments',
  templateUrl: './splitted-payments.component.html',
  styleUrls: ['./splitted-payments.component.scss']
})
export class SplittedPaymentsComponent implements OnInit, OnDestroy {
  
  @Input()
  payment: any;

  @Input()
  orderInfo: any;
 
  selectedAmount: number = 50;
  subscription: Subscription;
  account: any;
  invoice: any;
  paymentMethod: any;
  isPayed: boolean = false;
  hasPayment: boolean = false;
   
  constructor(
    private messageUtil: MessageUtil,
    private spinner: NgxSpinnerService,
    private invoicePaidService: InvoicePaidService,
    private salesStatesService: SalesStatesService,
    private cdr: ChangeDetectorRef,
  ) { }
  
  ngOnInit(): void {
    this.invoice = this.salesStatesService.getInvoice();
    this.subscription = this.salesStatesService.payments$.subscribe((_) => {
      this.selectedAmount = Math.round(this.payment.split / this.payment.totalSplited * 100);
      this.hasPayment = this.salesStatesService.invoiceData.hasPayment;
    });
  }
  
  distributeSplittedValueSlider() {
    if (this.selectedAmount > 0 && this.selectedAmount < 100) {
      this.payment.split = Number((this.payment.totalSplited*(this.selectedAmount/100)).toFixed(2));
      if (this.payment.id == 1) {
        this.payment.total = this.payment.split + this.payment.notSplitedPart;
      } else {
        this.payment.total = this.payment.split;
      }
      this.salesStatesService.updatePayment(this.payment);
    }
  }  

  distributeSplittedValue() {
    if (this.payment.split > 0 && this.payment.split < this.payment.totalSplited) {
      this.selectedAmount = Math.round(this.payment.split/this.payment.totalSplited*100);
      if (this.payment.id == 1) {
        this.payment.total = this.payment.split + this.payment.notSplitedPart;
      } else {
        this.payment.total = this.payment.split;
      }
      this.cdr.detectChanges();
      this.salesStatesService.updatePayment(this.payment);
    }
  }
  
  paymentFinish() {
    this.spinner.show();
    let employeeUser = StorageUtil.getUser();
    const invoicePaid: InvoicePaid = new InvoicePaid();

    invoicePaid.discount = this.invoice.discount;
    invoicePaid.interest = this.invoice.interest;
    invoicePaid.value = this.invoice.value;
    invoicePaid.username = this.invoice.customer.name;
    invoicePaid.employeeUser = employeeUser.firstName+" "+employeeUser.lastName;
    invoicePaid.invoiceId = this.invoice.id;
    invoicePaid.dateProcessing = this.invoice.processingDate;
    invoicePaid.datepaid = new Date();
    
    invoicePaid.totalPaid = this.payment.total;
    invoicePaid.accountId = this.account.idConta;
    invoicePaid.accountName = this.account.nome;
    invoicePaid.paymentMethodId = this.paymentMethod.idTipoFormaPagamento;
    invoicePaid.paymentMethodName = this.paymentMethod.descricao;
    
    this.invoicePaidService.payInvoice(invoicePaid).subscribe({
      next: (data) => {
        this.messageUtil.generateMessage(MessageUtil.SUCCESS, 'Faturamento', 'Pagamento de R$'+this.payment.total+' realizado com sucesso!');
        this.payment.disabled = true;
        this.payment.payed = true;
        this.isPayed = true;
        this.salesStatesService.updatePayment(this.payment);
        this.spinner.hide();        
      },
      error: (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(MessageUtil.ERROR, exception.title, exception.message);
        this.spinner.hide();
      }}
    );
  }
  
  selectPayment(event) {
    this.account = event.account;
    this.paymentMethod = event.paymentMethod;
  }
  
  ngOnDestroy(): void {
    this.subscription.unsubscribe();  
  }     
}
