<p-table [value]="slingsCheck" styleClass="sling-table" autoLayout="true">
    <ng-template pTemplate="header">
        <tr>
            <th class="text-center">Origem</th>
            <th class="text-left">Data/Hora</th>
            <th class="text-center">Vaga</th>
            <th class="text-left">Embarcação</th>
            <th class="text-left">Modelo</th>
            <th class="text-center" *ngIf="hasSailorConfig()">Possui Marinheiro</th>
            <th class="text-center">Pedido(s) avulso(s)</th>
            <th class="text-center"></th>
            <th class="text-center"></th>
            <th class="text-center"></th>
            <th class="text-center"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-slingCheck>
        <tr [ngClass]="{'em-attempted': isReadyToSling(slingCheck), 'easy-warning-tr': slingCheck.toConfirm}">

            <!-- Origem -->
            <td class="text-center">
                <img [src]="slingUtil.getImageOriginSling(slingCheck)" [pTooltip]="slingUtil.getOriginName(slingCheck)"
                    tooltipPosition="bottom">
            </td>

            <!-- Data saída -->
            <td>
                <span [ngClass]="{'em-alert': isNotToday(slingCheck)}" class="sling-time">
                    {{slingCheck.executionForecast | date: isNotToday(slingCheck) ? 'dd/MM - HH:mm':'HH:mm'}}
                </span>
                <span [ngClass]="{'em-alert': isNotToday(slingCheck)}" class="sling-time-mobile">
                    <span *ngIf="isNotToday(slingCheck)">
                        {{slingCheck.executionForecast | date:'dd/MM'}}
                        <br>
                    </span>
                    {{slingCheck.executionForecast | date: 'HH:mm'}}
                </span>
            </td>

            <!-- Vaga -->
            <td class="text-center" *ngIf="slingCheck.boat.vacancy || !isMobile">
                <span>
                    {{slingCheck.boat.vacancy ? slingCheck.boat.vacancy.code : ""}}
                </span>
            </td>

            <!-- Embarcação -->
            <td>
                <span pTooltip="ID: {{slingCheck.id}}" tooltipPosition="bottom">
                    {{slingCheck.boat.name}}
                </span>
            </td>

            <!-- Modelo -->
            <td>
                <span>
                    {{slingCheck.boat.shipyardModel.shipType.toUpperCase() | translate}}&nbsp;
                    {{slingCheck.boat.commercialLength}}'
                </span>
            </td>

            <!-- Possui marinheiro-->
            <td *ngIf="hasSailorConfig()">
              <span *ngIf=" hasBoatSailor(slingCheck)">
                <img class="size-icon-sling-check" [src]="getImageSailorResponsable()" />
              </span>
            </td>

            <!-- Pedido avulso -->
            <td>
                <div class="table-action" (click)="loadMarinaOrders(slingCheck)" pTooltip="Incluir pedido"
                    tooltipPosition="bottom">
                    <img src="assets/images/pedido.png">
                </div>
            </td>

            <!-- Combustível -->
            <td *ngIf="(hasFuel(slingCheck) || slingCheck.ownFuelSupply === false) || !isMobile">
                <div class="icon-button" *ngIf="hasFuel(slingCheck) || slingCheck.ownFuelSupply === false"
                    (click)="checkPendingFuel(slingCheck.marinaOrder.orderItems) && openDialogFuelOrder(slingCheck)">
                    <div *ngIf="hasFuel(slingCheck)" tooltipPosition="bottom" class="fuel-image"
                        [pTooltip]="slingUtil.getStatusFuel(slingCheck) === 'primary' ? 'Fornecer combustível': 'Pedido atendido'">
                        <div class="container">
                          <img [src]="slingUtil.getImageIconFuel(slingCheck)">
                            <label class="centered" *ngIf="slingCheck.boat.independentTanks;">2T</label>
                        </div>
                    </div>
                    <div *ngIf="!hasFuel(slingCheck) && slingCheck.ownFuelSupply === false" class="fuel-image"
                        pTooltip="Abastecimento próprio" tooltipPosition="bottom">
                        <div class="container">
                          <img [src]="slingUtil.getImageIconFuel(slingCheck)">
                            <label class="centered" *ngIf="slingCheck.boat.independentTanks;">2T</label>
                        </div>
                    </div>
                    <div class="flex-badge" *ngIf="hasFuel(slingCheck)">
                        <div class="badge"
                            [ngClass]="{'attempted': !checkPendingFuel(slingCheck.marinaOrder.orderItems)}">
                            {{orderUtil.fuelQuantityView(getFuel(slingCheck.marinaOrder.orderItems))}}
                        </div>
                        <div class="badge fuel-type"
                            [pTooltip]="getFuel(slingCheck.marinaOrder.orderItems).product.name"
                            tooltipPosition="bottom"
                            [ngClass]="{'attempted': !checkPendingFuel(slingCheck.marinaOrder.orderItems)}">
                            {{orderUtil.abbreviatedProductName(getFuel(slingCheck.marinaOrder.orderItems))}}
                        </div>
                    </div>
                </div>
            </td>

            <!-- Consumíveis -->
            <td *ngIf="hasConsumables(slingCheck) || !isMobile">
                <div class="icon-button" *ngIf="hasConsumables(slingCheck)"
                    (click)="checkPendingConsumables(slingCheck.marinaOrder.orderItems) && openDialogConsumableOrder(slingCheck)">
                    <div class="flex1" tooltipPosition="bottom"
                        [pTooltip]="slingUtil.getStatusConsumable(slingCheck) === 'primary' ? 'Fornecer consumíveis': 'Pedido atendido'">
                        <img [src]="slingUtil.getImageIconConsumable(slingCheck)">
                    </div>
                    <div class="flex1 start flex-badge">
                        <div class="badge consumable"
                            [ngClass]="{'attempted': !checkPendingConsumables(slingCheck.marinaOrder.orderItems)}">
                            {{countConsumables(slingCheck.marinaOrder.orderItems)}}
                        </div>
                    </div>
                </div>
            </td>

            <!-- Acessórios -->
            <td *ngIf="hasAcessories(slingCheck) || !isMobile">
                <div class="icon-button" *ngIf="hasAcessories(slingCheck)"
                    (click)="openDialogSlingAccessories(slingCheck)">
                    <div class="flex1" tooltipPosition="bottom"
                        [pTooltip]="slingUtil.getStatusAcessories(slingCheck) === 'primary' ? 'Fornecer acessórios': 'Pedido atendido'">
                        <img [src]="slingUtil.getImageIconAcessories(slingCheck)">
                    </div>
                    <div class="flex1 start flex-badge">
                        <div class="badge consumable" [ngClass]="{'attempted': !checkPendingAcessories(slingCheck)}">
                            {{slingCheck.slingAccessories.length}}
                        </div>
                    </div>
                </div>
            </td>

            <!-- Ação -->
            <td class="actions">
                <!-- Baixar -->
                <button *ngIf="slingCheck.prepare && !slingCheck.ownFuelSupply"
                    (click)="confirmationNotAttemptedItens(slingCheck)" pTooltip="Baixar barco" class="sling-button"
                    tooltipPosition="bottom">
                    <span
                        style="display: flex; align-items: center; justify-content: center; width: 100%; height: 100%;">
                        <img src="assets/images/baixar.png" style="height: 60px;">
                    </span>
                </button>

                <!-- Preparar -->
                <button
                    *ngIf="!slingCheck.prepare && !slingCheck.toConfirm && (!slingConfig.slingDownOnPreparation || slingConfig.slingDownOnPreparation && !slingCheck.ownFuelSupply)"
                    (click)="prepareConfirmation(slingCheck)" class="sling-button" pTooltip="Iniciar preparo"
                    tooltipPosition="bottom">
                    <span
                        style="display: flex; align-items: center; justify-content: center; width: 100%; height: 100%; flex-direction: column;"
                        *ngIf="slingCheck.hasSlingFinishedOnWater">
                        <img src="assets/images/engrenagemAgua.png" style="height: 60px;">
                    </span>
                    <span
                        style="display: flex; align-items: center; justify-content: center; width: 100%; height: 100%; flex-direction: column;"
                        *ngIf="!slingCheck.hasSlingFinishedOnWater">
                        <img src="assets/images/engrenagem.png" style="height: 60px;">
                    </span>
                </button>

                <!-- Confirmar -->
                <button *ngIf="slingCheck.toConfirm" (click)="toConfirmation(slingCheck)" pTooltip="Confirmar lingada"
                    tooltipPosition="bottom" class="sling-button">
                    <span
                        style="display: flex; align-items: center; justify-content: center; width: 100%; height: 100%; flex-direction: column;">
                        <img src="assets/images/phone.png" style="height: 40px;">
                    </span>
                </button>

                <!-- Fornecer abastecimento próprio -->
                <button (click)="confirmOwnFuelSupply(slingCheck)" class="sling-button"
                    pTooltip="Fornecer abastecimento próprio" tooltipPosition="bottom"
                    *ngIf="slingCheck.ownFuelSupply && ((!slingCheck.prepare && slingConfig.slingDownOnPreparation) || (slingCheck.prepare))">
                    <img src="assets/images/fuelOwn.png">
                </button>

            </td>
        </tr>
    </ng-template>
</p-table>
