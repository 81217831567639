import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CustomerCredit } from 'src/app/models/customercredit/customer-credit';
import { CustomerCreditCustomer } from 'src/app/models/customercredit/customer-credit-customer';
import { CustomerCreditService } from 'src/app/services/customer-credit/customer-credit.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-customer-credit-launch',
  templateUrl: './customer-credit-launch.component.html',
  styleUrls: ['./customer-credit-launch.component.scss']
})
export class CustomerCreditLaunchComponent implements OnInit {


 customerCredit: CustomerCredit = new CustomerCredit();

  constructor(
    private dialog: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private customerCreditService: CustomerCreditService,
  ) { }

  ngOnInit() {

    this.customerCredit.customer = this.config.data.customer;
    this.customerCredit.credit = this.config.data.credit;;
    this.customerCredit.description = '';
    this.customerCredit.value = 0;
    this.customerCredit.creditDate = new Date();


  }

  creditText() {
    return  this.customerCredit.credit ? 'Crédito' : 'Débito';
  }

  save() {
    if (this.customerCredit.credit) {
      this.customerCredit.value = Math.abs(this.customerCredit.value);
    }
    this.customerCreditService.launch(this.customerCredit).subscribe({
      next: (response) => {
        Swal.fire('Sucesso', 'Lançamento efetuado com sucesso', 'success');
        this.dialog.close(true);
      },
      error: (error: any) => {
        console.error(error);
      },
    });
  }

  clear() {
    this.customerCredit.description = '';
    this.customerCredit.value = 0;
    this.customerCredit.creditDate = new Date();
    this.customerCredit.invoice.invoiceNumber = null;
  }

  validInvoiceNumber() {
    if(this.customerCredit.invoice.invoiceNumber===null || this.customerCredit.invoice.invoiceNumber===0 ||
      this.customerCredit.invoice.invoiceNumber === undefined){
      return;
    }
    this.customerCreditService.validInvoiceNumber(this.customerCredit.invoice.invoiceNumber,this.customerCredit.customer.id).subscribe({
      next: (response) => {
        if(response===null){
          Swal.fire('Atenção', 'Número de fatura inválido', 'warning');
          this.customerCredit.invoice.invoiceNumber = null;
        }
      },
      error: (error: any) => {
        Swal.fire('Atenção', 'Número de fatura inválido', 'warning');
        this.customerCredit.invoice.invoiceNumber = null;
        console.error(error);
      },
    });
  }

}
