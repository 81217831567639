<div class="dialog-form" id="dialog-template">
  <form (submit)="save()" [formGroup]="templateForm">
    <div class="form-group">
      <div class="form-control">
        <label for="title">{{ "TITLE" | translate }} *</label>
        <input pInputText type="text" name="title" formControlName="title" />
      </div>
      <div style="align-items: center; display: flex;">
        <div class="form-control" style="flex: 0 0 200px !important">
          <label for="attachmentBody">Corpo do contratos</label>
          <p-fileUpload
            name="attachmentBody"
            mode="basic"
            maxFileSize="100000000"
            chooseLabel="Anexar"
            fileLimit="1"
            accept=".pdf"
            (uploadHandler)="setFileBody($event.files)"
            [auto]="true"
            customUpload="true"
            showCancelButton="true"
            #upload
          >
          </p-fileUpload>
        </div>
        <div class="form-control" style="flex: 1 !important">
          <span style="margin-left: 10px"> {{ getFileName() }}</span>
        </div>
      </div>
    </div>
    <div class="content-sheet">
      <div class="form-control" style="min-width:670px;">
        <label for="description"> Folha de rosto *</label>
        <ckeditor id="txtid" [editor]="editor" formControlName="text" (ready)="onReady($event)" (change)="changeText($event)" style=" max-width: 650px; border:var(--ck-focus-ring); border-color:#ced4da;"></ckeditor>
      </div>
      <div style="height: 100%">
        <label for="description"> Variáveis</label>
        <div class="content-variables">
            <span class="p-input-icon-left search">
                <i class="pi pi-search"></i>
                <input type="text" pInputText
                       placeholder="Filtrar variável" style="width: 250px;" (input)="filterVariable($event)">
            </span>
          <div class="variables">
            <div *ngFor="let variable of variblesTemplateSearch">
              <div class="variable-item" (click)="addVariable(variable.code)">
                <i class="pi pi-plus" style="font-size: 18px"></i>
                <span>{{ variable.name }}</span>
              </div>
            </div>
          </div>
        </div>
       <div class="form-control">
        {{ "REQUIRED-FIELDS" | translate }}
       </div>
       <div class="buttons" style="bottom: 50px; position: absolute;">
        <button
          class="easymarine danger"
          type="button"
          [routerLink]="['/app/contracts/templates']"
        >
          {{ "VOLTAR" | translate }}
        </button>
        <button
          class="easymarine primary"
          [disabled]="!validTemplate()"
          type="submit"
        >
          {{ "SAVE" | translate }}
        </button>
      </div>

      </div>
    </div>

    <div class="form-group align-center">


    </div>
  </form>
</div>
