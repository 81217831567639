import { Component, OnInit } from '@angular/core';
import { Marina } from 'src/app/models/marina';
import { FormatUtil } from 'src/app/utils/format.util';
import { StorageUtil } from 'src/app/utils/storage.util';

@Component({
  selector: 'app-my-marine',
  templateUrl: './my-marine.component.html',
  styleUrls: ['./my-marine.component.scss']
})
export class MyMarineComponent implements OnInit {
  marina: Marina;
  constructor() { }

  ngOnInit(): void {
    this.marina = StorageUtil.getMarina();
    this.marina.companyFederalId = FormatUtil.formatCNPJ(this.marina.companyFederalId.replace(/[^a-zA-Z0-9]/g, ''))
  }

}
