import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class BoatAccessorieService {

    constructor(private http: HttpClient) { }

    getAllByIdBoat(idBoat: number): Observable<any[]> {
        return this.http.get(environment.apiHost + '/secure/accessories-boat-by-idBoat/' + idBoat)
            .pipe(map((response: any) => response.data.boatAccessories));
    }
}
