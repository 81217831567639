import { ListNotBlockComponent } from './list-not-block/list-not-block.component';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { Customer, Marina, SlingConfig } from 'src/app/models';
import { InvoiceDTO } from 'src/app/models/dtos/invoiceDTO';
import { CustomerService } from 'src/app/services/customer.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { ToastService } from 'src/app/services/toast.service';
import { MessageUtil } from 'src/app/utils/message.util';
import moment from 'moment';
import b64toBlob from 'b64-to-blob';
import { ExtractInvoiceComponent } from 'src/app/components/extract-invoice/extract-invoice.component';
import { FormatUtil } from 'src/app/utils/format.util';
import { DialogService } from 'primeng/dynamicdialog';
import { MailsCustomersDebitsDTO } from 'src/app/models/dtos/mailsCustomersDebitsDTO';
import { StorageUtil } from 'src/app/utils/storage.util';
import { ImportResult } from 'src/app/models/import-result';
import { ImportResultService } from 'src/app/services/import-result.service';
import Swal from 'sweetalert2';
import { FinancialMultipleCompaniesService } from 'src/app/services/financial/financial-multiple-companies.service';
import { FilterBillingCompanySelected } from 'src/app/components/filter/filter-billing-company/filter-billing-company.component';
import { BlockBoatDialogComponent } from './block-boat-dialog/block-boat-dialog.component';
import { BoatService } from '../../../services/boat.service';
import { CustomReportExportComponent } from 'src/app/components/extract-custom-report/custom-report-export.component';
import { InvoiceEmailsComponent } from 'src/app/components/invoice/invoice-emails/invoice-emails.component';
import { FinancesService } from 'src/app/services/finances.service';
import {translateBlockType} from "../../../models/enums/customer/block-type";

@Component({
  selector: 'app-pending-customers',
  templateUrl: './pending-customers.component.html',
  styleUrls: ['./pending-customers.component.scss']
})
export class PendingCustomersComponent implements OnInit {
  private readonly oneDay: number = (1000 * 60 * 60 * 24);
  private carteiras: any[] = [];

  numberOfRows = 10;
  orders: any[] = [];
  selectedCustomers: any[] = [];
  ordersTable = [];
  globalFilter = '';
  customers = [];
  selectedCustomer: Customer;
  loggedMarina: Marina;
  lastStatus = 'READY';
  interval;
  financialBlockedCount = 0;
  financialUnblockedCount = 0;
  toleranceDays = 0;
  taxaMulta = 0.0;
  taxaMora = 0.0;

  totalPending = 0;
  pt = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  };
  slingConfig: SlingConfig;
  filterBillingCompanyByProduct: FilterBillingCompanySelected = { filterBillingCompany: false, billingCompany: undefined };

  constructor(
    private messageService: MessageUtil,
    private invoiceService: InvoiceService,
    private slingConfigService: SlingConfigService,
    private dialog: DialogService,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private toasty: ToastService,
    private customerService: CustomerService,
    private importResultService: ImportResultService,
    private messageUtil: MessageUtil,
    private financialMultipleCompaniesService: FinancialMultipleCompaniesService,
    private boatService: BoatService,
    private financesService: FinancesService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.loadSlingConfig();
    this.configureNumberOfRows(this.slingConfig);
    this.find();
    this.verifyStatusSendEmail();
    this.loadAllBillingPortifolio();
    this.loggedMarina = StorageUtil.getMarina();
  }

  loadSlingConfig(): Promise<void> {
    return this.slingConfigService.getSlingConfig()
      .toPromise()
      .then((data) => {
        this.slingConfig = data[0];
      });
  }

  getTotals(invoices: InvoiceDTO[]): void {
    this.totalPending = invoices.reduce((total, invoice) => total + invoice.value, 0);

  }

  getTotalInterest(invoices) {
    let totalInterest = 0;
    if (invoices) {
      invoices.forEach(invoice => {
        totalInterest += this.calcInterest(invoice);
      });
    }
    return totalInterest;
  }

  getTotal(invoices, totalCustomer) {
    return this.getTotalInterest(invoices) + totalCustomer;
  }

  configureNumberOfRows(slingConfig: SlingConfig): void {
    this.numberOfRows = slingConfig != null ? slingConfig.numberOfRecordsPerPage : 10;
  }

  public calcInterest(invoice): number {
    const daysPastDue = this.daysPastDueDate(invoice);

    let allInterest = 0;

    if (daysPastDue <= 0) {
      return allInterest;
    }

    allInterest += this.calcMulta(daysPastDue, invoice);

    if (daysPastDue > this.getToleranceDays(invoice)) {
      const daysMora = daysPastDue - this.getToleranceDays(invoice);
      allInterest += this.calcMora(daysMora, invoice);
    }

    return allInterest;
  }

  private getMulta(invoice) {
    let conta = this.carteiras.find((d) => d.conta.idConta === invoice.accountId);
    if (!conta) {
      conta = this.carteiras.find((d) => d.conta.idConta === this.slingConfig.accountId);
    }
    if (conta) {
      return conta?.taxaMulta;
    }
    return 0;
  }

  private getMora(invoice) {
    let conta = this.carteiras.find((d) => d.conta.idConta === invoice.accountId);
    if (!conta) {
      conta = this.carteiras.find((d) => d.conta.idConta === this.slingConfig.accountId);
    }
    if (conta) {
      return conta?.taxaMora;
    }
    return 0;
  }

  private getToleranceDays(invoice) {
    let conta = this.carteiras.find((d) => d.conta.idConta === invoice.accountId);
    if (!conta) {
      conta = this.carteiras.find((d) => d.conta.idConta === this.slingConfig.accountId);
    }
    if (conta) {
      return conta?.diasMulta;
    }
    return 0;
  }

  private calcMulta(daysPastDue, invoice): number {
    return parseFloat(((parseFloat(invoice.value) * this.getMulta(invoice)) / 100.0).toFixed(2));
  }

  private calcMora(daysMora: number, invoice): number {
    const taxaDay = parseFloat((this.getMora(invoice) / 30).toFixed(2));
    const moraDays = parseFloat((daysMora * taxaDay).toFixed(2));
    return parseFloat(((moraDays * invoice.value) / 100).toFixed(2));
  }

  private daysPastDueDate(invoice): number {
    const dueDate = moment(invoice.dueDate);
    const paymentDate = moment();
    const days = moment.duration(paymentDate.diff(dueDate)).asDays();

    if (days < 0 || invoice.paymentStatus == "Late") {
      return 0;
    }

    return Math.floor(days);
  }

  loadAllBillingPortifolio(): Promise<any[]> {

    return new Promise<any[]>(async (resolve) => {
      await this.financesService.getAllBilingPortifolio().subscribe(
        (data) => {
          // const conta = data.find((d) => d.conta.idConta === this.slingConfig.accountId);
          this.carteiras = data;
          resolve(data);
        },
        (error) => {
          console.log(error);
        }
      );
    });
  }

  find(): void {
    this.spinner.show();
    this.invoiceService.getCustomersDebitsToDate(new Date().getTime(), this.filterBillingCompanyByProduct).subscribe(
      (data) => {
        this.modifyListDateOrders(data);
        this.filterCustomersNoDebitAndUpdateOrders();
        this.getTotals(data);
        this.spinner.hide();
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title, exception.message);
        this.spinner.hide();
      });
  }

  modifyListDateOrders(invoices: InvoiceDTO[]): void {
    this.orders = [];
    const now: number = moment().toDate().getTime();
    invoices.forEach((i) => {
      const customerOrderExist = this.orders.find((d) => d.idCustomer === i.customerId);
      i.interest = this.calcInterest(i);
      i.interest !== 0 ? i.interest = (Math.round((i.interest + Number.EPSILON) * 100) / 100 ): i.interest = 0;
      if (customerOrderExist !== undefined) {
        customerOrderExist.totalCustomer += i.value;
        customerOrderExist.totalInterest += i.interest;
        customerOrderExist.fullValue += i.value + i.interest;
        customerOrderExist.invoices.push(i);
      } else {
        const customerOrder = {
          idCustomer: i.customerId,
          nameCustomer: i.customerName,
          totalCustomer: i.value,
          totalInterest: i.interest,
          fullValue: i.value + i.interest,
          financialBlock: i.financialBlock,
          blockType: translateBlockType(i.blockType),
          blockHour: i.blockHour,
          days: i.dueDate < i.dueDateToPay ? this.getDaysDueDate(now, moment(i.dueDateToPay, 'YYYY-MM-DD').toDate().getTime())
            : this.getDaysDueDate(now, moment(i.dueDate, 'YYYY-MM-DD').toDate().getTime()),
          showInvoices: false,
          invoices: [i]
        };
        this.orders.push(customerOrder);
      }
    });
    this.ordersTable = this.orders;
  }
  getDaysDueDate(now: number, dueDate: number): number {
    const calc: number = (now - dueDate) / this.oneDay;
    return Number(calc.toFixed(0));
  }

  blockCustomers(): void {
    const customerIds = this.selectedCustomers.map((x) => x.idCustomer);
    const block: boolean = this.isToBlock();
    this.spinner.show();
    this.customerService.resolveFinancialBlock({ customerIds, financialBlock: block }).subscribe(() => {
      this.spinner.hide();
      this.toasty.success('Clientes ' + (!block ? 'desbloqueados' : 'bloqueados') + ' com sucesso.');
      this.find();
      this.cleanSelections();
    }, error => {
      this.spinner.hide();
      this.toasty.error('Erro ao tentar ' + (!block ? 'desbloquear' : 'bloquear') + '. Entre em contato com a EasyMarine.');
      this.find();
    });
  }

  isToBlock(): boolean {
    return this.financialUnblockedCount > 0;
  }

  blockCustomer(): void {
    this.customerService.resolveFinancialBlock({ customerIds: [this.selectedCustomer.id], financialBlock: true }).subscribe(() => {
      this.toasty.success('Cliente bloqueado com sucesso.');
      const idx = this.customers.indexOf(this.selectedCustomer);
      this.selectedCustomer = null;
      this.customers.slice(idx, 1);
      this.find();
    });
  }

  onRowSelect(event: any): void {
    if (event.data.financialBlock) {
      this.financialBlockedCount++;
    } else {
      this.financialUnblockedCount++;
    }
  }

  onRowUnselect(event: any): void {
    if (event.data.financialBlock) {
      if (this.financialBlockedCount > 0) {
        this.financialBlockedCount--;
      }
    } else {
      if (this.financialUnblockedCount > 0) {
        this.financialUnblockedCount--;
      }
    }
  }

  onTableHeaderCheckboxToggle(event: any): void {
    if (event.checked) {
      const blocked = this.orders.filter((order) => order.financialBlock).length;
      const unblocked = this.orders.length - blocked;
      this.financialBlockedCount = blocked;
      this.financialUnblockedCount = unblocked;
    } else {
      this.financialBlockedCount = 0;
      this.financialUnblockedCount = 0;
    }
  }

  showInvoices(order): void {
    order.showInvoices = !order.showInvoices;
  }

  extract(idInvoice): void {
    this.spinner.show();
    this.invoiceService.extract(idInvoice).subscribe(
      (data) => {
        this.openExtract(
          this.sanitizer.bypassSecurityTrustResourceUrl(
            URL.createObjectURL(
              (b64toBlob as any)(data, 'application/pdf')
            )
          )
        );
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title, exception.message);
      },
      () => this.spinner.hide());
  }

  openExtract(path): void {
    this.dialog.open(ExtractInvoiceComponent, {
      width: '100%',
      height: '100%',
      dismissableMask: false,
      data: { path },
      header: 'Extrato da fatura - PDF'
    });
  }

  openNotBlock(): void {
    this.dialog.open(ListNotBlockComponent, {
      width: '50%',
      height: '700px',
      dismissableMask: false,
      data: {},
      header: 'Lista de clientes isentos de bloqueio'
    });
  }

  filterCustomersNoDebitAndUpdateOrders(): void {
    const campos = "id,name,phone,emailCollectionArray,financialBlock,blockType,blockHour,"
    this.customerService.getAll(campos).subscribe(
      (data) => {
        this.customers = [];
        data.forEach((c: Customer) => {
          const order = this.orders.find((x) => x.idCustomer === c.id);
          if (order !== undefined) {
            order.phone = c.phone;
            order.emailCollectionArray = c.emailCollectionArray;
            order.firstEmail = this.formatEmailCollection(c.emailCollectionArray);
          } else {
            if (c.financialBlock) {
              this.orders.push({
                idCustomer: c.id,
                nameCustomer: c.name,
                totalCustomer: 0,
                financialBlock: c.financialBlock,
                blockType: translateBlockType(c.blockType),
                blockHour: c.blockHour,
                days: 0,
                emailCollectionArray: c.emailCollectionArray,
                firstEmail: this.formatEmailCollection(c.emailCollectionArray),
                phone: c.phone,
                showInvoices: false,
                invoices: []
              });
            } else {
              this.customers.push(c);
            }

          }
        });
        this.ordersTable = [...this.orders];
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title, exception.message);
      });
  }

  filterGlobal(): void {
    const globalFilter = this.globalFilter;
    this.ordersTable = this.orders.filter((o) =>
      (o.days.toString().includes(globalFilter)) ||
      FormatUtil.verifyIfStringContainsSubStringWithoutAccents(o.nameCustomer, globalFilter) ||
      o?.phone.includes(globalFilter) ||
      (o.totalCustomer && o.totalCustomer.toString().includes(globalFilter))
    );
  }

  showEmailPreview(): void {
    this.invoiceService.getTemplateMailsCustomersDebitsToDate().subscribe(
      (template) => {
        this.spinner.hide();
        Swal.fire({
          title: 'Exemplificação de email',
          html: `<div style="overflow: scroll; padding:20px; max-height: 60vh;">${template}</div>`,
          width: '800px',
          heightAuto: false,
          showCancelButton: false,
          showLoaderOnConfirm: false,
          reverseButtons: true,
          confirmButtonText: this.messageUtil.translateKey('OK'),
        });
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title, exception.message);
      });
  }

  sendMailsCustomersDebits(serverOwn: boolean = false): void {
    Swal.fire({
      title: `Será enviado e-mail para <br><br><strong>${this.selectedCustomers.length} cliente(s)</strong><br> Deseja continuar?`,
      showCancelButton: true,
      confirmButtonText: this.messageUtil.translateKey('CONFIRM'),
      showLoaderOnConfirm: true,
      reverseButtons: true,
      cancelButtonColor: '#d33',
      allowOutsideClick: false,
      icon: 'warning',
      backdrop: false,
      confirmButtonColor: '#3085d6',
      cancelButtonText: this.messageUtil.translateKey('CANCEL'),
      preConfirm: () => this.sendEmail(serverOwn)
    });
  }

  sendEmail(serverOwn: boolean = false): void {
    const ids = this.selectedCustomers.map(c => c.idCustomer);
    this.spinner.show();
    const mailsCustomersDebitsDTO: MailsCustomersDebitsDTO = {
      dueDate: moment().format('YYYY-MM-DD'),
      serverOwn,
      ids
    };
    this.invoiceService.sendMailsCustomersDebitsToDate(mailsCustomersDebitsDTO).subscribe(
      (data) => {
        this.cleanSelections();
        this.messageUtil.generateMessage('success', 'SUMMARY.SUCCESS', 'MSG.SENT-EMAILS');
        this.lastStatus = 'PROCESSING';
        this.startInterval();
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title, exception.message);
      });
  }

  isActiveSendEmail(): boolean {
    return this.lastStatus === 'READY';
  }

  async verifyStatusSendEmail(): Promise<void> {
    const res = await this.findLastResult();
    if (res && (res.result || res.status === 'READY')) {
      this.lastStatus = res.status;
    } else if (res) {
      this.lastStatus = res.status;
      this.startInterval();
    }
  }

  startInterval(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
    const THIS_ = this;
    this.interval = setInterval(async () => {
      const result = await this.findLastResult();
      if (result && result.status === 'READY') {
        this.lastStatus = result.status;
        this.find();
        clearInterval(THIS_.interval);
      }
    }, 30000);
  }

  async findLastResult(): Promise<ImportResult> {
    return new Promise<ImportResult>(
      async (res) => {
        this.importResultService.findBySource('EMAIL').subscribe(
          async (result: ImportResult) => {
            res(result);
          },
          async (err) => {
            res(null);
          }
        );
      }
    );
  }

  formatEmailCollection(emailCollectionArray: string[]): string {
    if (emailCollectionArray) {
      return emailCollectionArray[0] + (emailCollectionArray.length > 1 ? ';...' : '');
    }
    return '';
  }

  formatEmailCollectionTooltip(emailCollectionArray: string[]): string {
    if (emailCollectionArray) {
      return emailCollectionArray.join('\n');
    }
    return '';
  }

  sendToWhatsapp(order: any): string {
    if (order.phone) {
      const re = /(\(|\)|-)/gi;
      const phoneNumber = '55' + order.phone.replace(re, '').replace(' ', '');
      const marina = StorageUtil.getMarinaName();
      const msg = `Olá ${order.nameCustomer}, tudo bem? Você possui faturas atrasadas há ${order.days} dia${order.days > 1 ? 's' : ''}. Atenciosamente, ${marina}.`;
      const path = `https://api.whatsapp.com/send/?phone=${phoneNumber}&text=${msg}&app_absent=0`;
      return path;
    } else {
      return '';
    }
  }

  cleanSelections(): void {
    this.selectedCustomers = [];
    this.financialBlockedCount = 0;
    this.financialUnblockedCount = 0;
  }

  showFinancialUnblockButton(): boolean {
    return this.financialBlockedCount > 0 && !(this.financialUnblockedCount > 0);
  }

  showFinancialBlockButton(): boolean {
    return !(this.financialUnblockedCount <= 0 && this.financialBlockedCount > 0);
  }

  disableFinancialUnblockButton(): boolean {
    return this.selectedCustomers.length === 0 || this.financialUnblockedCount > 0;
  }

  disableFinancialBlockButton(): boolean {
    return this.selectedCustomers.length === 0 || (this.financialBlockedCount > 0);
  }

  hasFinancialMultipleByProductFilter(slingConfig: SlingConfig): boolean {
    return this.financialMultipleCompaniesService.hasFinancialMultipleCompaniesByProduct(slingConfig);
  }

  filterBillingCompanyByProductChange(event: any): void {
    this.filterBillingCompanyByProduct = event;
    this.find();
  }

  startManualBlock(): void {
    //confirme se deseja bloquear faturas
    Swal.fire({
      title: `Deseja iniciar o bloqueio automático dos clientes?`,
      showCancelButton: true,
      confirmButtonText: this.messageUtil.translateKey('CONFIRM'),
      showLoaderOnConfirm: true,
      reverseButtons: true,
      cancelButtonColor: '#d33',
      allowOutsideClick: false,
      icon: 'warning',
      backdrop: false,
      confirmButtonColor: '#3085d6',
      cancelButtonText: this.messageUtil.translateKey('CANCEL'),
      preConfirm: () => {
        this.customerService.startAutomaticNotBlock().subscribe(
          (data) => {
            if (data) {
              this.messageUtil.generateMessage('success', 'SUMMARY.SUCCESS', 'Bloqueio Realizado com sucesso!');
              this.find();
            }
            else {
              this.messageUtil.generateMessage('error', 'SUMMARY.ERROR', 'Erro ao realizar o bloqueio!');
            }
          },
          (error) => {
            this.messageUtil.generateMessage('error', 'SUMMARY.ERROR', 'Erro ao realizar o bloqueio!');
          }
        );
      }
    });

  }


  openUnblockBoatDialog(idCostumer: number): void {
    let data = { boats: [], customerId: idCostumer };
    this.spinner.show();
    this.boatService.getBoatsByIdCustomer(idCostumer).subscribe({
      next: (resp) => {
        this.spinner.hide();
        if (resp.length <= 0) {
          this.messageUtil.generateMessage('warning', 'Atenção', 'Não foram encontrados barcos associados');
          return;
        } else {
          data.boats = resp;
          this.dialog.open(BlockBoatDialogComponent, {
            width: '40%',
            height: '60%',
            dismissableMask: false,
            data,
            header: 'Detalhes Embarcações'
          });
        }
      },
      error: (err) => {
        this.spinner.hide();
        this.messageUtil.generateMessage('error', 'Atenção', 'Erro no sistema');
      }
    });
  }

  exportTable(): void {
    const cleanTables = this.ordersTable.map(mess => {
      return {
        "nameCustomer": mess.nameCustomer,
        "firstEmail": mess.firstEmail,
        "phone": mess.phone,
        "days": mess.days,
        "totalCustomer": mess.totalCustomer,
        "fullValue": mess.fullValue,
        "financialBlock": mess.financialBlock
      };
    });
    const data = { table: cleanTables, type: 'PENDING' };
    this.dialog.open(CustomReportExportComponent, {
      width: '70%',
      height: '90vh',
      dismissableMask: false,
      data,
      header: this.messageUtil.translateKey('EXPORT-PENDING-TABLE'),
    });
  }

  openInvoiceEmailDetailsDialog(invoiceDto: InvoiceDTO): void {
    this.dialog.open(InvoiceEmailsComponent, {
      dismissableMask: false,
      data: {
        debitLogs: invoiceDto.invoiceEmailLog
      },
      header: 'Log de e-mail de Pendências'
    }).onClose.subscribe(() => {
      invoiceDto.invoiceEmailLog.map(log => log.logRead = true);
      this.invoiceService.updateEmailLogByUserId(parseInt(invoiceDto.customerId)).subscribe();
    });
  }

  hasNewLog(invoice: InvoiceDTO): boolean {
    return invoice.invoiceEmailLog &&
      invoice.invoiceEmailLog.length > 0 &&
      invoice.invoiceEmailLog.find((log) => !log.logRead) !== undefined;
  }
}
