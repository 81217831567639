<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{ 'TITLE-PANEL-LIST' | translate }}</span>
        <div class="header-buttons">
            <button class="primary fab-button" (click)="openPanelConfigFormDialog()">
                <i class="pi pi-plus" style="font-size: 21px;"></i>
            </button>
            <button class="primary fab-button" (click)="fillPanelConfigTable()">
                <i class="pi pi-refresh" style="font-size: 21px;"></i>
            </button>
        </div>
    </p-header>

    <div class="table" style="margin-bottom: 1.5em;">
        <p-table #ttVertical [value]="panelConfigsVertical" [paginator]="true" [rows]="50" resetPageOnSort="true"
            [pageLinks]="3" [autoLayout]="true" [rowsPerPageOptions]="[10,20,30,50,100]" styleClass="easy-table"
            [globalFilterFields]="['observation','raspberry.ipHamachi','raspberry.mac', 'raspberry.panelVersion', 'raspberry.raspberryOsVersion']">
            <ng-template pTemplate="caption">
                <div>{{ 'PANEL-VERTICAL' | translate }}</div>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText placeholder="Filtro"
                        (input)="ttVertical.filterGlobal($event.target.value, 'contains')">
                </span>
                <span style="font-weight: bold; float: right; margin-top: 5px;">{{ 'TOTAL-REGISTERS' | translate }}:
                    {{ttVertical.totalRecords}} {{'OF' | translate}} {{panelConfigsVertical.length}}
                </span>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th>N° de série</th>
                    <th>Observação</th>
                    <th>Hamachi</th>
                    <th>IP Local</th>
                    <th>Firebase</th>
                    <th>V. Painel</th>
                    <th>V. Rasp. OS</th>
                    <th>Conteúdo</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-panelConfigVer>
                <tr>
                    <td>{{panelConfigVer.raspberry?.mac}}</td>
                    <td>{{panelConfigVer.observation}}</td>
                    <td>{{panelConfigVer.raspberry?.ipHamachi}}</td>
                    <td>{{panelConfigVer.raspberry?.ip}}</td>
                    <td>{{panelConfigVer.raspberry?.firebase ? 'Ativo' : 'Inativo'}}</td>
                    <td>{{panelConfigVer.raspberry?.panelVersion}}</td>
                    <td>{{panelConfigVer.raspberry?.raspberryOsVersion}}</td>
                    <td *ngIf="isMovement">{{ panelConfigVer.panelFunctionality.toUpperCase() | translate}} </td>
                    <td *ngIf="!isMovement">
                        <p-dropdown placeholder="Selecione..." [autoWidth]="true" [options]="panelFunctionalitiesTypes"
                            [(ngModel)]="panelConfigVer.panelFunctionality"
                            [name]="panelFunctionality + panelConfigsVertical.indexOf(panelConfigVer)"
                            (onChange)="changePanelLink(panelConfigVer)" appendTo="body"
                            [disabled]="isDisabled(panelConfigVer.panelFunctionality)">
                        </p-dropdown>
                    </td>
                    <td class="text-center">
                        <i class="table-action" pTooltip="Visualizar" (click)="openViewPanel(panelConfigVer)"
                            tooltipPosition="bottom">
                            <i class="pi pi-eye"></i>
                        </i>
                    </td>
                    <td class="text-center">
                        <div class="table-action" (click)="openPanelConfigFormDialog(panelConfigVer)" pTooltip="Editar"
                            tooltipPosition="bottom">
                            <i class="pi pi-pencil"></i>
                        </div>
                    </td>
                    <td class="text-center">
                        <div class="table-action" tooltipPosition="bottom"
                            [pTooltip]="panelConfigVer.raspberry.rebootRequested ? 'Aguarde, reiniciando...' : 'Reiniciar rasp'"
                            (click)=" !panelConfigVer.raspberry.rebootRequested && rebootRaspberry(panelConfigVer)">
                            <i class="pi"
                                [ngClass]="{'pi-replay': !panelConfigVer.raspberry.rebootRequested, 'pi-spin pi-spinner': panelConfigVer.raspberry.rebootRequested}"></i>
                        </div>
                    </td>
                    <td class="text-center">
                        <div class="table-action" (click)="refreshRaspberry(panelConfigVer.raspberry)"
                            pTooltip="Atualizar painel" tooltipPosition="bottom">
                            <i class="pi pi-refresh"></i>
                        </div>
                    </td>
                    <td class="text-center">
                        <div class="table-action" (click)="openPanelConfigDeleteConfirmDialog(panelConfigVer.raspberry)"
                            pTooltip="Deletar" tooltipPosition="bottom">
                            <i class="pi pi-trash" style="color:  #EF5350"></i>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="table">
        <p-table #ttHorizontal [value]="panelConfigsHorizontal" [paginator]="true" [rows]="50" resetPageOnSort="true"
            [pageLinks]="3" [autoLayout]="true" [rowsPerPageOptions]="[10,20,30,50,100]" styleClass="easy-table"
            [globalFilterFields]="['observation','raspberry.ipHamachi','raspberry.mac', 'raspberry.panelVersion', 'raspberry.raspberryOsVersion']">
            <ng-template pTemplate="caption">
                <div>{{ 'PANEL-HORIZONTAL' | translate }}</div>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText placeholder="Filtro"
                        (input)="ttHorizontal.filterGlobal($event.target.value, 'contains')">
                </span>
                <span style="font-weight: bold; float: right; margin-top: 5px;">{{ 'TOTAL-REGISTERS' | translate }}:
                    {{ttHorizontal.totalRecords}} {{'OF' | translate}} {{panelConfigsHorizontal.length}}
                </span>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th>N° de série</th>
                    <th>Observação</th>
                    <th>Hamachi</th>
                    <th>IP Local</th>
                    <th>Firebase</th>
                    <th>V. Painel</th>
                    <th>V. Rasp. OS</th>
                    <th>Conteúdo</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-panelConfigHor>
                <tr>
                    <td>{{panelConfigHor.raspberry?.mac}}</td>
                    <td>{{panelConfigHor.observation}}</td>
                    <td>{{panelConfigHor.raspberry?.ipHamachi}}</td>
                    <td>{{panelConfigHor.raspberry?.ip}}</td>
                    <td>{{panelConfigHor.raspberry?.firebase ? 'Ativo' : 'Inativo'}}</td>
                    <td>{{panelConfigHor.raspberry?.panelVersion}}</td>
                    <td>{{panelConfigHor.raspberry?.raspberryOsVersion}}</td>
                    <td *ngIf="isMovement">{{ panelConfigHor.panelFunctionality.toUpperCase() | translate}} </td>
                    <td *ngIf="!isMovement">
                        <p-dropdown placeholder="Selecione..." [autoWidth]="true" [options]="panelFunctionalitiesTypes"
                            [(ngModel)]="panelConfigHor.panelFunctionality" appendTo="body"
                            [name]="panelFunctionality + panelConfigsHorizontal.indexOf(panelConfigHor)"
                            (onChange)="changePanelLink(panelConfigHor)"
                            [disabled]="isDisabled(panelConfigHor.panelFunctionality)" appendTo="body">
                        </p-dropdown>
                    </td>
                    <td class="text-center">
                        <i class="table-action" pTooltip="Visualizar" (click)="openViewPanel(panelConfigHor)"
                            tooltipPosition="bottom">
                            <i class="pi pi-eye"></i>
                        </i>
                    </td>
                    <td class="text-center">
                        <div class="table-action" (click)="openPanelConfigFormDialog(panelConfigHor)" pTooltip="Editar"
                            tooltipPosition="bottom">
                            <i class="pi pi-pencil"></i>
                        </div>
                    </td>
                    <td class="text-center">
                        <div class="table-action" tooltipPosition="bottom"
                            [pTooltip]="panelConfigHor.raspberry.rebootRequested ? 'Aguarde, reiniciando...' : 'Reiniciar rasp'"
                            (click)=" !panelConfigHor.raspberry.rebootRequested && rebootRaspberry(panelConfigHor)">
                            <i class="pi"
                                [ngClass]="{'pi-replay': !panelConfigHor.raspberry.rebootRequested, 'pi-spin pi-spinner': panelConfigHor.raspberry.rebootRequested}"></i>
                        </div>
                    </td>
                    <td class="text-center">
                        <div class="table-action" (click)="refreshRaspberry(panelConfigHor.raspberry)"
                            pTooltip="Atualizar painel" tooltipPosition="bottom">
                            <i class="pi pi-refresh"></i>
                        </div>
                    </td>
                    <td class="text-center">
                        <div class="table-action" (click)="openPanelConfigDeleteConfirmDialog(panelConfigHor.raspberry)"
                            pTooltip="Deletar" tooltipPosition="bottom">
                            <i class="pi pi-trash" style="color:  #EF5350"></i>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

</p-fieldset>