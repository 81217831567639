import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

constructor() { }

getLinks(): any[] {
let links: any[] = [
{ aba:"Painel de Controle", tela: "Dashboard", link:"https://wiki.easymarine.com.br/books/easy-web/page/dashboard", key:"app/dashboard"},
{ aba:"Operacional", tela: "lingadas", link:"https://wiki.easymarine.com.br/books/easy-web/page/lingadas", key:"operational/sling" },
{ aba:"Operacional", tela: "Apontamentos", link:"https://wiki.easymarine.com.br/books/easy-web/page/apontamentos", key:"operational/checking" },
{ aba:"Operacional", tela: "Lavação/Giro", link:"https://wiki.easymarine.com.br/books/easy-web/page/lava%C3%A7%C3%A3o-giro-de-motor", key:"operational/wash-engine-turn" },
{ aba:"Operacional", tela: "Plano Navegação", link:"https://wiki.easymarine.com.br/books/easy-web/page/plano-de-navega%C3%A7%C3%A3o", key:"operational/navigation-plan" },
{ aba:"Faturamento", tela: "Mensalidades", link:"https://wiki.easymarine.com.br/books/easy-web/page/mensalidade", key:"billing/tuition" },
{ aba:"Faturamento", tela: "Pedidos", link:"https://wiki.easymarine.com.br/books/easy-web/page/pedidos", key:"billing/order" },
{ aba:"Faturamento", tela: "Gerar Faturas", link:"https://wiki.easymarine.com.br/books/easy-web/page/gerar-faturas", key:"billing/order-invoice" },
{ aba:"Faturamento", tela: "Faturas", link:"https://wiki.easymarine.com.br/books/easy-web/page/faturas", key:"billing/invoice" },
{ aba:"Faturamento", tela: "Débitos", link:"https://wiki.easymarine.com.br/books/easy-web/page/d%C3%A9bitos", key:"billing/debits" },
{ aba:"Faturamento", tela: "Débitos Diários", link:"https://wiki.easymarine.com.br/books/easy-web/page/d%C3%A9bitos-di%C3%A1rios", key:"billing/daily-debits" },
{ aba:"Faturamento", tela: "Pendência", link:"https://wiki.easymarine.com.br/books/easy-web/page/pend%C3%AAncias", key:"billing/pending-customers" },
{ aba:"Faturamento", tela: "Contas Recebimento", link:"https://wiki.easymarine.com.br/books/easy-web/page/contas-recebimento", key:"app/billing/receiving-accounts" },
{ aba:"Faturamento", tela: "Boletos", link:"https://wiki.easymarine.com.br/books/easy-web/page/boletos", key:"billing/billets-management/billet" },
{ aba:"Faturamento", tela: "Arquivo de Remessa", link:"https://wiki.easymarine.com.br/books/easy-web/page/arquivos-de-remessa", key:"billing/billets-management/outfile" },
{ aba:"Faturamento", tela: "Contratos", link:"https://wiki.easymarine.com.br/books/easy-web/page/contratos", key:"app/billing/contracts" },
{ aba:"Faturamento", tela: "Gestão de Notas", link:"https://wiki.easymarine.com.br/books/easy-web/page/gest%C3%A3o-de-notas", key:"billing/invoice-tax-management/invoice-tax" },
{ aba:"Faturamento", tela: "Antecipação", link:"https://wiki.easymarine.com.br/books/easy-web/page/antecipa%C3%A7%C3%A3o", key:"billing/anticipation/anticipate" },
{ aba:"Faturamento", tela: "Resumo", link:"https://wiki.easymarine.com.br/books/easy-web/page/resumo", key:"billing/anticipation/summary" },
{ aba:"Faturamento", tela: "Gestão de Antecipação", link:"https://wiki.easymarine.com.br/books/easy-web/page/gest%C3%A3o-de-antecipa%C3%A7%C3%A3o", key:"billing/anticipation/management" },
{ aba:"Faturamento", tela: "Inicio", link:"https://wiki.easymarine.com.br/books/easy-web/page/in%C3%ADcio", key:"finances/dashboard" },
{ aba:"Financeiro", tela: "Lançamentos", link:"https://wiki.easymarine.com.br/books/easy-web/page/lan%C3%A7amentos", key:"finances/transaction" },
{ aba:"Financeiro", tela: "Programações", link:"https://wiki.easymarine.com.br/books/easy-web/page/programa%C3%A7%C3%B5es", key:"finances/planning" },
{ aba:"Financeiro", tela: "Demonstratico Financeiro", link:"https://wiki.easymarine.com.br/books/easy-web/page/demonstrativo-financeiro", key:"finances/demonstrative" },
{ aba:"Financeiro", tela: "Fluxo de Caixa", link:"https://wiki.easymarine.com.br/books/easy-web/page/fluxo-de-caixa", key:"finances/cash-flow" },
//{ aba:"Financeiro", tela: "Extrato de Conta", link:"https://wiki.easymarine.com.br/books/easy-web/page/extrato-de-conta", key:"" },
{ aba:"Financeiro", tela: "Saldo Diário", link:"https://wiki.easymarine.com.br/books/easy-web/page/saldo-di%C3%A1rio", key:"finances/diary-balance" },
{ aba:"Financeiro", tela: "Saldo Programação", link:"https://wiki.easymarine.com.br/books/easy-web/page/saldo-programa%C3%A7%C3%A3o", key:"finances/planning-balance" },
{ aba:"Financeiro", tela: "Relatório Geral", link:"https://wiki.easymarine.com.br/books/easy-web/page/relat%C3%B3rio-geral", key:"finances/general-report" },
{ aba:"Financeiro", tela: "Resumo Favorecido", link:"https://wiki.easymarine.com.br/books/easy-web/page/resumo-favorecido", key:"finances/general-report" },
{ aba:"Financeiro", tela: "Contas", link:"https://wiki.easymarine.com.br/books/easy-web/page/conta", key:"finances/account-crud" },
{ aba:"Financeiro", tela: "Plano de Contas", link:"https://wiki.easymarine.com.br/books/easy-web/page/plano-de-conta", key:"finances/chart-accounts-crud" },
{ aba:"Financeiro", tela: "Centro de Custo", link:"https://wiki.easymarine.com.br/books/easy-web/page/centro-custo", key:"app/finances/cost-center-crud" },
{ aba:"Financeiro", tela: "Conta Corrente", link:"https://wiki.easymarine.com.br/books/easy-web/page/conta-corrente", key:"finances/checking-account-crud" },
{ aba:"Financeiro", tela: "Cartão de Crédito", link:"https://wiki.easymarine.com.br/books/easy-web/page/cart%C3%A3o-de-cr%C3%A9dito", key:"finances/credit-card-crud" },
{ aba:"Financeiro", tela: "Carteira de Cobrança", link:"https://wiki.easymarine.com.br/books/easy-web/page/carteira-cobran%C3%A7a", key:"finances/billing-portifolio-crud" },
{ aba:"Financeiro", tela: "Orçamento", link:"https://wiki.easymarine.com.br/books/easy-web/page/or%C3%A7amento", key:"app/finances/budget" },
{ aba:"Financeiro", tela: "Forma de Pagamento", link:"https://wiki.easymarine.com.br/books/easy-web/page/formas-de-pagamento", key:"finances/paymentMethods" },
{ aba:"Financeiro", tela: "Configuração", link:"https://wiki.easymarine.com.br/books/easy-web/page/configura%C3%A7%C3%A3o", key:"finances/configuration" },
{ aba:"Cadastro", tela: "Grupos", link:"https://wiki.easymarine.com.br/books/easy-web/page/grupos", key:"forms/group" },
{ aba:"Cadastro", tela: "Usuários", link:"https://wiki.easymarine.com.br/books/easy-web/page/usu%C3%A1rios", key:"forms/user" },
{ aba:"Cadastro", tela: "Perfil de Usuários", link:"https://wiki.easymarine.com.br/books/easy-web/page/perfil-de-usuarios", key:"forms/user-role" },
{ aba:"Cadastro", tela: "Clientes", link:"https://wiki.easymarine.com.br/books/easy-web/page/clientes", key:"forms/customer" },
{ aba:"Cadastro", tela: "Embarcações", link:"https://wiki.easymarine.com.br/books/easy-web/page/embarca%C3%A7%C3%B5es", key:"forms/boat" },
{ aba:"Cadastro", tela: "Vagas", link:"https://wiki.easymarine.com.br/books/easy-web/page/vagas", key:"forms/vacancy" },
{ aba:"Cadastro", tela: "Colaborador", link:"https://wiki.easymarine.com.br/books/easy-web/page/colaborador", key:"finances/employee" },
{ aba:"Cadastro", tela: "Fornecedor", link:"https://wiki.easymarine.com.br/books/easy-web/page/fornecedor", key:"finances/provider" },
{ aba:"Estoque", tela: "Produto / Serviços", link:"https://wiki.easymarine.com.br/books/easy-web/page/produtos-servi%C3%A7os", key:"stock/product" },
{ aba:"Estoque", tela: "Ajuste de Saldo", link:"https://wiki.easymarine.com.br/books/easy-web/page/ajuste-de-saldo", key:"stock/balance-adjustment" },
{ aba:"Estoque", tela: "Reajuste de Preço", link:"https://wiki.easymarine.com.br/books/easy-web/page/reajuste-de-servi%C3%A7o", key:"stock/service-readjustment" },
{ aba:"Configurações", tela: "Configuração Gerais", link:"https://wiki.easymarine.com.br/books/easy-web/page/configura%C3%A7%C3%B5es-gerais", key:"configs/sling-config" },
{ aba:"Configurações", tela: "Exibição de Painéis", link:"https://wiki.easymarine.com.br/books/easy-web/page/exibi%C3%A7%C3%A3o-de-pain%C3%A9is", key:"configs/panel-config" },
{ aba:"Configurações", tela: "Configurações de Sirene", link:"https://wiki.easymarine.com.br/books/easy-web/page/configura%C3%A7%C3%B5es-de-sirene", key:"configs/siren-alert-config" },
{ aba:"Configurações", tela: "Mural / Informações", link:"https://wiki.easymarine.com.br/books/easy-web/page/muralinforma%C3%A7%C3%B5es", key:"configs/noticies" },
{ aba:"Configurações", tela: "Nota Fiscal", link:"https://wiki.easymarine.com.br/books/easy-web/page/nota-fiscal", key:"configs/nota-fiscal" },
{ aba:"Configurações", tela: "Contratos > Templates", link:"https://wiki.easymarine.com.br/books/easy-web/page/contratos-%3E-templates", key:"contracts/templates" },
{ aba:"Configurações", tela: "Contratos > Assinantes Padrão", link:"https://wiki.easymarine.com.br/books/easy-web/page/contratos-%3E-assinantes-padr%C3%A3o", key:"contracts/default-signer" },
{ aba:"Logs", tela: "Lingadas", link:"https://wiki.easymarine.com.br/books/easy-web/page/lingadas-138", key:"logs/order-log" },
{ aba:"Logs", tela: "Pedidos", link:"https://wiki.easymarine.com.br/books/easy-web/page/pedidos-987", key:"logs/order-log" },
{ aba:"Logs", tela: "Compartilhamentos", link:"https://wiki.easymarine.com.br/books/easy-web/page/compartilhamento", key:"logs/share" },
{ aba:"Logs", tela: "SMS - Usuário APP", link:"https://wiki.easymarine.com.br/books/easy-web/page/sms---usu%C3%A1rios-app", key:"logs/user-sms-log" },
{ aba:"Logs", tela: "Embarcações", link:"https://wiki.easymarine.com.br/books/easy-web/page/embarca%C3%A7%C3%B5es-218", key:"logs/boat-log" },
{ aba:"Logs", tela: "Clientes", link:"https://wiki.easymarine.com.br/books/easy-web/page/clientes-65b", key:"logs/customer-log" },
{ aba:"Logs", tela: "Logs Produtos / Serviços", link:"https://wiki.easymarine.com.br/books/easy-web/page/logs-produtos-servi%C3%A7os", key:"logs/product-log" },
{ aba:"Minha Conta", tela: "Minhas Faturas", link:"https://wiki.easymarine.com.br/books/easy-web/page/minhas-faturas", key:"my-account/my-invoice" }];

return links;

}

}
