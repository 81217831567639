import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Customer, Sling, SlingConfig } from 'src/app/models';
import { NavigationPlanService } from 'src/app/services/navigation-plan.service';
import { SlingService } from 'src/app/services/sling.service';
import { MessageUtil } from 'src/app/utils/message.util';
import moment from 'moment';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { BoatService } from 'src/app/services/boat.service';
import { SelectLabelValue } from 'src/app/models/select-label-value';
import { DialogService } from 'primeng/dynamicdialog';
import { CustomReportExportComponent } from 'src/app/components/extract-custom-report/custom-report-export.component';

@Component({
  selector: 'app-navigation-plan',
  templateUrl: './navigation-plan.component.html',
  styleUrls: ['./navigation-plan.component.scss']
})
export class NavigationPlanComponent implements OnInit {

  slings: Sling[] = [];
  navigationPlanFrom = new Date();
  navigationPlanTo = new Date();
  idBoat;
  selectedSlings = [];
  boatsFilter: SelectLabelValue[] = [];

  pt = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  };
  slingConfig: SlingConfig;

  constructor(
    private slingService: SlingService,
    private boatService: BoatService,
    private navigationPlanService: NavigationPlanService,
    private messageUtil: MessageUtil,
    private spinner: NgxSpinnerService,
    private slingConfigService: SlingConfigService,
    private dialog: DialogService
  ) { }

  async ngOnInit(): Promise<void> {
    this.loadBoat();
    this.slingConfig = await this.findSlingConfig();
    this.findSlingByFilter();
  }

  findSlingByFilter(): void {
    this.spinner.show();
    const filter = {
      executionForecastStart: moment(this.navigationPlanFrom.setHours(0, 0, 0, 0)).format('YYYY-MM-DDTHH:mm'),
      executionForecastEnd: moment(this.navigationPlanTo.setHours(23, 59, 59, 59)).format('YYYY-MM-DDTHH:mm'),
      idBoat: this.idBoat
    };

    this.slingService.findSlingByFilter(filter).subscribe({
      next: (slings: Sling[]) => {
        this.slings = slings;
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    });
  }

  exportTable(): void {
    const tableData: {
      responsible: string,
      name: string,
      federalId: string,
      boatName: string,
      executionForecast: string,
      returnTime: string,
      crewQtd: number,
      destiny: string,
    }[] = this.slings.map((sling) => {
      return {
        responsible: sling.navigationPlan.responsible.name,
        name: this.getBoatOwner(sling).name,
        federalId: this.getBoatOwner(sling).federalId,
        boatName: sling.boat.name,
        executionForecast: sling.executionForecast,
        returnTime: sling.navigationPlan.returnTime,
        crewQtd: sling.navigationPlan.crewQty,
        destiny: sling.navigationPlan.destination
      }
    });

    const data = { table: tableData, type: 'NAVIGATION_PLAN' };

    this.dialog.open(CustomReportExportComponent, {
      width: '70%',
      height: '90vh',
      dismissableMask: false,
      data,
      header: 'Exportar Planos de Navegação',
    });
  }

  getBoatOwner(sling: Sling): Customer {
    if (sling.boat.boatCustomers.length > 1) {
      const customerBoat = sling.boat.boatCustomers.find((bc) =>
        bc.customer.id === sling.navigationPlan.responsible.id && bc.associatedOwner != null);
      if (customerBoat) {
        return customerBoat.associatedOwner;
      }
    }
    return sling.navigationPlan.responsible;
  }

  printNavitagionPlan(): void {
    this.spinner.show();
    const idsSling: number[] = this.selectedSlings.map((sling) => sling.id);
    this.navigationPlanService.generatedPdfByIdsSling(idsSling).subscribe((data) => {
      this.spinner.hide();
      const url = window.URL.createObjectURL(data);
      window.open(url);
    });
  }

  async findSlingConfig(): Promise<SlingConfig> {
    return new Promise<SlingConfig>(
      async (resolve) => {
        this.slingConfigService.getSlingConfig().subscribe(async (slingConfigs: SlingConfig[]) => (resolve(slingConfigs[0])));
      }
    );
  }

  loadBoat(): void {
    this.boatService.getAllByActiveForSelect().subscribe(
      (data) => {
        this.boatsFilter = data
        this.boatsFilter.unshift({ label: 'Todos', value: null });
      }
    );
  }
}
