import { Component, Input, OnInit } from '@angular/core';
import { LazyLoadEvent, SelectItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { ServiceBoatContractsDialogComponent } from 'src/app/components/contracts/service-boat-contracts-dialog/service-boat-contracts-dialog.component';
import {Boat, SlingConfig} from 'src/app/models';
import { ServiceBoatContractView } from 'src/app/models/dtos/service-boat-contract-view';
import { ServiceBoatWithoutContractDTO } from 'src/app/models/dtos/service-boat-without-contractDTO';
import { ContractType } from 'src/app/models/enums/contract-type.';
import { DocumentStatus } from 'src/app/models/enums/document-status';
import { PaginationFilter } from 'src/app/models/pagination-filter';
import { ContractDocumentService } from 'src/app/services/contract-document.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { NewContractDialogComponent } from '../new-contract-dialog/new-contract-dialog.component';

@Component({
  selector: 'app-service-boat-with-contracts',
  templateUrl: './service-boat-with-contracts.component.html',
  styleUrls: ['./service-boat-with-contracts.component.scss']
})
export class ServiceBoatWithContractsComponent implements OnInit {

  @Input() slingConfig: SlingConfig;
  @Input() boatListFilter: SelectItem[] = [];
  @Input() serviceListFilter: SelectItem[] = [];
  @Input() boatList: Boat[] = [];
  paginationFilter: PaginationFilter = new PaginationFilter();
  serviceBoats: ServiceBoatContractView[]= [];

  numberOfRows: number;
  totalRecords: any;
  filter: any;

  statusListFilter = [];
  contractTypeListFilter = [];

  constructor(
    private contractDocumentService: ContractDocumentService,
    private dialog: DialogService,
    private messageUtil: MessageUtil,
  ) { }

  ngOnInit(): void {
    if (this.slingConfig) {
      this.numberOfRows = this.slingConfig != null ? this.slingConfig.numberOfRecordsPerPage : 10;
      this.paginationFilter.size = this.numberOfRows;
    }
    this.clear();
    this.statusListFilter = Object.keys(DocumentStatus).map(item => ({ label: this.messageUtil.translateKey(item), value: item }));
    this.contractTypeListFilter = Object.keys(ContractType).map(item => ({ label: this.messageUtil.translateKey(item), value: item }));
    this.findServiceBoats();
  }

  findServiceBoats(): void {
    this.contractDocumentService.getServiceBoatsContract(this.filter, this.paginationFilter).subscribe(
       (data) => {
        this.serviceBoats = data.content;
        this.totalRecords = data.totalElements;
      },
       (error) => {
        console.log(error)
      });
  }

  openNewContractModal(service: ServiceBoatContractView, contractType: ContractType): void {
    const contractData: ServiceBoatWithoutContractDTO = {
      serviceBoatId: service.id,
      boatName: service.boatName,
      serviceName: service.serviceName,
      contractType
    };

    this.dialog.open(NewContractDialogComponent, {
      width: '50%',
      dismissableMask: false,
      data: {
        contractData,
        boats: this.boatList,
      },
      header: 'Novo contrato'
    }).onClose.subscribe(
      (data) => {
        if (data) {
          this.findServiceBoats();
        }
      });
  }

  openContractsModal(serviceBoat) {
    this.dialog.open(ServiceBoatContractsDialogComponent, {
      height: '60%',
      dismissableMask: false,
      data: {
        serviceBoatId: serviceBoat.id,
      },
      header: 'Contratos'
    }).onClose.subscribe(() => {
      this.findServiceBoats();
    });

  }

  clear(): void {
    this.filter = {
      active: true
    };
  }

  onChangePage(event: LazyLoadEvent): void {
    if(this.slingConfig) {
      const page = event.first / event.rows;
      this.paginationFilter.sort = event.sortField;
      this.paginationFilter.page = page;
      this.paginationFilter.size = event.rows;
      this.paginationFilter.order = event.sortOrder === 1 ? 'ASC' : 'DESC';
      this.findServiceBoats();
    }
  }

  isEnabledCreateAdditive(serviceBoat): boolean {
    return serviceBoat.active && serviceBoat.contractType && serviceBoat.contractType !== 'TERMINATION' && serviceBoat.status ==='SIGNED';
  }

  isEnabledCreateMain(serviceBoat): boolean {
    return serviceBoat.active && !serviceBoat.contractType;
  }

  isEnabledCreateTermination(serviceBoat): boolean {
    return serviceBoat.status && serviceBoat.status ==='SIGNED' && serviceBoat.contractType && serviceBoat.contractType !== 'TERMINATION';
  }
}
