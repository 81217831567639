import { HttpClient, HttpHeaders, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { DocumentTemplate } from "../models/document-template";
import { DocumentWithDataRequestDTO } from "../models/dtos/document-with-data-requestDTO";
import { TemplateVariablesRequestDTO } from "../models/dtos/template-variables-requestDTO";

@Injectable()
export class DocumentTemplateService {

    private templateUrl = environment.apiHost + '/secure/document-template';

    constructor(
        private http: HttpClient
    ) { }

    getTemplates(): Observable<DocumentTemplate[]> {
        return this.http.get(this.templateUrl)
            .pipe(map((response: any) => response.data.templates as DocumentTemplate[]));
    }

    getTemplateById(id:number): Observable<DocumentTemplate> {
      return this.http.get(`${this.templateUrl}/${id}` )
          .pipe(map((response: any) => response.data.template as DocumentTemplate));
  }

    save(signer: DocumentTemplate): Observable<DocumentTemplate> {
        return this.http.post(this.templateUrl, signer)
            .pipe(map((response: any) => response.data.template as DocumentTemplate));
    }

    delete(id: number): Observable<any[]> {
        return this.http.delete(`${this.templateUrl}/${id}`)
            .pipe(map((response: any) => response));
    }

    uploadFileBody(file: any): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('attachment', file);

        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'multipart/form-data');

        return this.http.post(
            this.templateUrl + '/attachment/template-body',
            formData,
            { headers }
        ).pipe(map((response: any) => response.data.attachment));
    }

    uploadFileDatasheet(file: any): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('attachment', file);

        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'multipart/form-data');

        return this.http.post(
            this.templateUrl + '/attachment/template-datasheet',
            formData,
            { headers }
        ).pipe(map((response: any) => response.data.attachment));
    }

    getTextDatasheet(url: string): Observable<string> {
        return new Observable((observer) => {
            const request = new XMLHttpRequest();
            request.onloadend = function() {
                let text = '';
                if (this.status == 200) {
                    text = this.responseText;
                }
                observer.next(text);
                observer.complete();
            };
            request.open("GET",url);
            request.setRequestHeader('Content-Type','plain/text; charset=utf-8');
            request.responseType = 'text';
            request.send();
        });
    }

    getVariablesDocument(templateVariablesRequest: TemplateVariablesRequestDTO): Observable<Map<string, string>> {
        return this.http.post(this.templateUrl + '/variables-from-template', templateVariablesRequest)
            .pipe(map((response: any) => response.data.variables as Map<string, string>));
    }

    generateContractFile(templateVariablesWithData: DocumentWithDataRequestDTO): Observable< string> {
        return this.http.post(this.templateUrl + '/generate-document', templateVariablesWithData)
            .pipe(map((response: any) => response.data.url as  string));
    }

}
