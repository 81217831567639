<div class="dialog-form">
    <form (submit)="save()" [formGroup]="signerContractForm">
        <div class="form-group">
            <div class="form-control">
                <label>{{'SIGN-TYPE' | translate}}</label>
                <p-dropdown [placeholder]="'SIGN-TYPE' | translate" [options]="typeList" name="signerType"
                    [title]="'SIGN-TYPE' | translate" formControlName="signerType">
                </p-dropdown>
            </div>
        </div>
        <div class="form-group">
            <div class="form-control">
                <label for="name">{{'FEDERAL-ID.PHYSICAL' | translate}} *</label>
                <p-inputMask type="text" mask="999.999.999-99" name="federalId" formControlName="federalId"
                    (onBlur)="checkFederalIdField()">
                </p-inputMask>
            </div>
        </div>
        <div class="form-group">
            <div class="form-control">
                <label for="name">{{ 'NAME' | translate }} *</label>
                <input pInputText type="text" name="name" formControlName="name">
            </div>
        </div>
        <div class="form-group">
            <div class="form-control">
                <label for="title">{{ 'EMAIL' | translate }} *</label>
                <input pInputText type="text" name="title" formControlName="email">
            </div>
        </div>
        <div class="form-group">
            <div class="form-control">
                <label for="birthday">{{ 'DATE-BIRTH' | translate }}</label>
                <p-calendar dateFormat="dd/mm/yy" name="birthday" [monthNavigator]="true" [yearNavigator]="true"
                    [yearRange]="'1900:2055'" rPCalendarMask appendTo="body" formControlName="birthday"
                    [showIcon]="true" i18n>
                </p-calendar>
            </div>
        </div>
        <div class="form-group">
            <div class="form-control">
                {{'REQUIRED-FIELDS' | translate}}
            </div>
        </div>
        <div class="form-group">
            <div class="buttons">
                <button class="easymarine danger" type="button" (click)="close()">{{ 'CANCEL' | translate
                    }}</button>
                <button class="easymarine primary" type="submit">{{ 'SAVE' | translate }}</button>
            </div>
        </div>
    </form>
</div>