import { StorageUtil } from 'src/app/utils/storage.util';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Router, ActivatedRoute } from '@angular/router';
import { Empresa, Usuario } from '../models';
import { MarinaCompany } from '../models/marina-company';

export interface IRequestOptions {
  headers?: HttpHeaders;
  observe?: 'body';
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  body?: any;
}

export function easyMarineHttpFinancesCreator(
  http: HttpClient,
  router: Router,
  route: ActivatedRoute
): EasyMarineHttpFinances {
  // tslint:disable-next-line: no-use-before-declare
  return new EasyMarineHttpFinances(http, router, route);
}

@Injectable()
export class EasyMarineHttpFinances {
  // Extending the HttpClient through the Angular DI.
  public constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  /**
   * GET request
   * @param endPoint it doesn't need / in front of the end point
   * @param options options of the request like headers, body, etc.
   * @returns Observable<T>
   */
  public get<T>(
    endPoint: string,
    options?: IRequestOptions,
    additionalCompany?: MarinaCompany
  ): Observable<T> {
    return this.http.get<T>(
      endPoint,
      this.createRequestOptions(options, additionalCompany)
    );
  }

  /**
   * POST request
   * @param endPoint end point of the api
   * @param params body of the request.
   * @param options options of the request like headers, body, etc.
   * @returns Observable<T>
   */
  public post<T>(
    endPoint: string,
    params: object,
    options?: IRequestOptions,
    additionalCompany?: MarinaCompany
  ): Observable<T> {
    return this.http.post<T>(
      endPoint,
      params,
      this.createRequestOptions(options, additionalCompany)
    );
  }

  /**
   * PUT request
   * @param endPoint end point of the api
   * @param params body of the request.
   * @param options options of the request like headers, body, etc.
   * @returns Observable<T>
   */
  public put<T>(
    endPoint: string,
    params: object,
    options?: IRequestOptions,
    additionalCompany?: MarinaCompany
  ): Observable<T> {
    return this.http.put<T>(
      endPoint,
      params,
      this.createRequestOptions(options, additionalCompany)
    );
  }

  /**
   * DELETE request
   * @param endPoint end point of the api
   * @param options options of the request like headers, body, etc.
   * @returns Observable<T>
   */
  public delete<T>(
    endPoint: string,
    options?: IRequestOptions,
    additionalCompany?: MarinaCompany
  ): Observable<T> {
    return this.http.delete<T>(
      endPoint,
      this.createRequestOptions(options, additionalCompany)
    );
  }

  private createRequestOptions(
    options: IRequestOptions,
    additionalCompany?: MarinaCompany
  ): IRequestOptions {
    if (options === undefined) {
      options = {};
    }
    const tokenObj = StorageUtil.getDecodeToken();
    if (tokenObj) {
      const httpOptions = new HttpHeaders({
        Authorization:
          'Basic ' + this.getTokenFinanceiro(null, additionalCompany),
        'Access-Control-Allow-Origin': '*',
      });
      options.headers = httpOptions;
    } else {
      this.router.navigate(['login']);
    }
    return options;
  }

  private getTokenFinanceiro(currentUser, company?: MarinaCompany): string {
    const currentMarina = StorageUtil.getMarina();

    const empresaUser: Empresa = {
      codigo: 1000 + currentMarina.id,
      nome: currentMarina.companyName,
      fantasia: currentMarina.companyName,
      cnpj: currentMarina.companyFederalId.replace(/[^a-zA-Z0-9]/g, ''),
      tipoSistema: 'PORTALPOSTAL',
    };

    let nomeDB = '';

    if (
      currentMarina.operationalUnit &&
      currentMarina.operationalUnit.length > 0
    ) {
      nomeDB = empresaUser.cnpj + '_' + currentMarina.operationalUnit;
    } else {
      nomeDB = empresaUser.cnpj;
    }

    if (company && company.finances) {
      empresaUser.nome = company.companyName;
      empresaUser.fantasia = company.companyFederalName;
      empresaUser.cnpj = company.companyFederalId.replace(/[^a-zA-Z0-9]/g, '');
      if (company.operationalUnit && company.operationalUnit.length > 0) {
        nomeDB = empresaUser.cnpj + '_' + company.operationalUnit;
      } else {
        nomeDB = empresaUser.cnpj;
      }
    } else {
      let c;
      if (
        this.router &&
        this.router.url &&
        this.router.url.includes('company=')
      ) {
        c = this.route.snapshot.queryParams.get('company');
      }
      if (c) {
        const marinaCompany: any = currentMarina.marinaCompanies.filter(
          (m) => m.companyFederalId === c &&  m.finances
        );

        if (marinaCompany) {
          empresaUser.nome = marinaCompany.companyName;
          empresaUser.fantasia = marinaCompany.companyFederalName;
          empresaUser.cnpj = marinaCompany.companyFederalId.replace(
            /[^a-zA-Z0-9]/g,
            ''
          );
          if (
            marinaCompany.operationalUnit &&
            marinaCompany.operationalUnit.length > 0
          ) {
            nomeDB = empresaUser.cnpj + '_' + marinaCompany.operationalUnit;
          } else {
            nomeDB = empresaUser.cnpj;
          }
        }
      }
    }

    const tokenUser = StorageUtil.getDecodeToken();
    const usuario: Usuario = {
      codigo: tokenUser.userId,
      nome: tokenUser.firstName,
      login: tokenUser.username,
      perfil: '0',
      empresa: empresaUser,
      bancoDados: nomeDB,
    };

    return btoa(JSON.stringify(usuario));
  }
}
