import { MovementLocation } from './../../../../../models/movement/movement-location';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, forwardRef, Input } from '@angular/core';

interface Locations {
  value: MovementLocation
  label: string
}

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss'],
  providers:  [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationComponent),
      multi: true
    }
  ]
})
export class LocationComponent implements ControlValueAccessor {

  @Input()
  localeOptions: Locations[];

  locationSelected: MovementLocation;

  private onChanges: any = () => {}
  private onTouched: any = () => {}
  disabled: boolean = false;

  constructor(
  ) { }

  writeValue(obj: MovementLocation): void {
    if (obj && obj.id) {
      const currentLocation = this.localeOptions.filter( item => item?.value?.id === obj.id );
      if (currentLocation.length > 0) {
        this.locationSelected = currentLocation[0].value;
      }
    }
  }
  registerOnChange(fn: any): void { this.onChanges = fn }
  registerOnTouched(fn: any): void { this.onTouched = fn }
  setDisabledState?(isDisabled: boolean): void { this.disabled = isDisabled }

  select(event: any) {
    this.onChanges(event.value);
  }

}
