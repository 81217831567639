import { MenuItem } from '../models/menu-item';
export class RoleUtil {

    static roleRoutes = [];
    static lastIdx = 69;
    constructor(
    ) { }

    static async findIdByUrl(url: string, menu: MenuItem[]): Promise<number> {
        return new Promise<number>(
            async (resolve) => {
                let index = 0;
                let result = 0;
                while (index < menu.length && result === 0) {
                    const element = menu[index];
                    if (element.routerLink && url.includes(element.routerLink)) {
                        result = element.id;
                    } else if (element.children && element.children.length > 0) {
                        result = await this.findIdByUrl(url, element.children);
                    }
                    index++;
                }
                resolve(result);
            }
        );
    }

    static async removeItemsByIds(ids: number[], menu: MenuItem[]): Promise<MenuItem[]> {
        return new Promise<MenuItem[]>(
            async (res) => {
                let index = 0;
                while (index < menu.length) {
                    if (ids.find((id) => id === menu[index].id)) {
                        menu.splice(index);
                    } else if (menu[index].children && menu[index].children.length > 0) {
                        menu[index].children = await this.removeItemsByIds(ids, menu[index].children);
                        index++;
                    } else {
                        index++;
                    }
                }
                res(menu);
            }
        );
    }
}
