import { Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import moment from 'moment';
import { SlingConfig } from 'src/app/models';
import { MessageUtil } from 'src/app/utils/message.util';
import { FormatUtil } from 'src/app/utils/format.util';
import { EngineTurn } from 'src/app/models/engine-turn';
import { EngineTurnService } from 'src/app/services/engine-turn.service';
import { ToastService } from 'src/app/services/toast.service';
import { BoatWashEngineTurnFormComponent } from 'src/app/components/boat-wash-engine-turn/boat-wash-engine-turn-form/boat-wash-engine-turn-form.component';
import { BoatWashEngineTurnHistoryComponent } from 'src/app/components/boat-wash-engine-turn/boat-wash-engine-turn-history/boat-wash-engine-turn-history.component';
import { DialogService } from 'primeng/dynamicdialog';
import Swal from 'sweetalert2';
import { WashOrEngineTurnDetails } from '../wash-engine-turn.component';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'engine-turn-tab',
    styleUrls: ['engine-turn-tab.component.scss'],
    templateUrl: 'engine-turn-tab.component.html'

})

export class EngineTurnTabComponent implements OnInit, OnChanges{

    @Input() slingConfig: SlingConfig;
    @Input() boatWashes: any[];

    @Input()
    boatsEngineTurn: any[] = new Array();

    @Input()
    readonly filterStatusoptions: any[] = [];

    engineTurnDetails: WashOrEngineTurnDetails = {
        boatAll: 0,
        boatScheduled: 0,
        boatNoSchedule: 0,
        boatNeverSchedule: 0,
    };

    filter = { status: [] };
    boatsEngineTurnTable: any[] = new Array();
    currentDate: any = new Date();
    boatSelected = false;
    boatScheduleSelected = false;
    schedulingDate: any = null;
    globalFilter = '';

    pt = {
        firstDayOfWeek: 0,
        dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
        dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
        dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
        monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
            'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        today: 'Hoje',
        clear: 'Limpar'
    };

    constructor(
        private engineTurnService: EngineTurnService,
        private messageUtil: MessageUtil,
        private toasty: ToastService,
        private dialog: DialogService
    ) { }

    ngOnInit(): void {
        this.totalizer();
        this.filterGlobal();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.filterGlobal();
    }

    filterGlobal(): void {
        let engineTurns = this.filterSearch(this.boatsEngineTurn);
        this.boatsEngineTurnTable = this.filterStatus(engineTurns);
    }

    filterSearch(engineTurns: any[]): any[] {
        engineTurns = engineTurns.filter(item => item.marineRunMyEnginer);
        return engineTurns.filter(
            (ebt) =>
            (FormatUtil.getNotAccents(ebt.boatName).toUpperCase().includes(FormatUtil.getNotAccents(this.globalFilter).toUpperCase()) ||
                ebt.boatName.toUpperCase().includes(this.globalFilter.toUpperCase())
            ) || (FormatUtil.getNotAccents(ebt.userName).toUpperCase().includes(FormatUtil.getNotAccents(this.globalFilter).toUpperCase()) ||
            ebt.userName?.toUpperCase().includes(this.globalFilter.toUpperCase())
        )
        );
    }

    filterStatus(engineTurns: any[]): any[] {
        if (this.filter.status !== undefined && this.filter.status.length > 0) {
            return engineTurns.filter(obj => {
                if ( this.filter.status.includes('Agendados') && this.scheduledVerify(obj)) {
                  return true;
                } else if ( this.filter.status.includes('Nunca Agendados') && this.neverScheduleVerify(obj)) {
                  return true;
                } else if ( this.filter.status.includes('Removidos da fila') && this.noScheduleVerify(obj)) {
                  return true;
                }
                return false;
            });
        } else {
            return engineTurns;
        }
    }

    isOverdueTask(washEngineTurn): boolean {
        if (!washEngineTurn.engineTurnDate || washEngineTurn.engineTurnDate.substring(0, 10) < moment().format('YYYY-MM-DD')) {
            return true;
        }
        return false;
    }

    isEqualdueTask(washEngineTurn): boolean {
        if (washEngineTurn.engineTurnDate && washEngineTurn.engineTurnDate.substring(0, 10) === moment().format('YYYY-MM-DD')) {
            return true;
        }
        return false;
    }

    openEngineTurnHistoryDialog(boatEngineTurn): void {
        this.engineTurnService.getBoatEngineTurnHistory(boatEngineTurn.boatId).subscribe((data) => {
            this.dialog.open(BoatWashEngineTurnHistoryComponent, {
                header: 'Histórico de giros de motor',
                width: '25%',
                data: {
                    washOrEngineTurnHistory: data,
                    boatName: boatEngineTurn.boatName
                }
            });
        });
    }

    dialogOptionSchedule(): void {
        Swal.fire({
            title: 'Agendar giro de motor!',
            icon: 'warning',
            reverseButtons: true,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar',
        }).then((result) => {
            if (result.isConfirmed) {
                this.confirmSchedule();
            }
        });
    }

    confirmSchedule(): void {
        const schedulesEngineTurn: EngineTurn[] = new Array();
        this.boatsEngineTurnTable.forEach((bet) => {
            if (bet.boatEngineTurnSelected) {
                bet.engineTurnDate = moment(this.schedulingDate).format('YYYY-MM-DDTHH:mm');
                bet.isSea = false;
                schedulesEngineTurn.push(bet);
            }
        });
        this.engineTurnService.saveSchedule(schedulesEngineTurn).subscribe(() => {
            this.toasty.success('Giro do motor agendado com sucesso');
            this.boatSelected = false;
        }, (error) => {
            const exception = error.error.data.exception;
            this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
        }
        );
    }

    cancelSchedule(): void {
        const idCancelEngineTurn: number[] = new Array();
        this.boatsEngineTurnTable.forEach((bet) => {
            if (bet.boatEngineTurnSelected && bet.engineTurnDate != null) {
                idCancelEngineTurn.push(bet.id);
            }
        });
        this.engineTurnService.delete(idCancelEngineTurn).subscribe(
            () => {
                this.toasty.success('Giro de motor cancelado com sucesso');
                this.boatSelected = false;
                this.boatScheduleSelected = false;
            }, (error) => {
                const exception = error.error.data.exception;
                this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
            }
        );
    }

    onBoatSelect(): void {
        this.boatSelected = this.boatsEngineTurnTable.find((b) => b.boatEngineTurnSelected) !== undefined;
        this.boatScheduleSelected = this.boatsEngineTurnTable.find(
            (b) => b.boatEngineTurnSelected && b.engineTurnDate !== null
        ) !== undefined;
    }

    registerManual(): void {
        const enginesTurn = this.boatsEngineTurnTable.filter((b) => b.boatEngineTurnSelected);
        this.dialog.open(BoatWashEngineTurnFormComponent, {
            header: 'Registro manual',
            width: '60%',
            height: '80%',
            data: {
                boatsWash: this.boatWashes.filter((bw) => enginesTurn.find((et) => et.boatId === bw.boatId)),
                enginesTurn
            }
        });
    }

    totalizer(): void {
        this.engineTurnDetails.boatAll = this.boatsEngineTurn.length;
        this.boatsEngineTurn.forEach(obj => {
          if (this.scheduledVerify(obj)) { this.engineTurnDetails.boatScheduled++; }
          else if (this.neverScheduleVerify(obj)) { this.engineTurnDetails.boatNeverSchedule++; }
          else if (this.noScheduleVerify(obj)) { this.engineTurnDetails.boatNoSchedule++; }
        });
    }

    scheduledVerify(engineTurn): boolean {
        return engineTurn.engineTurnDate;
    }

    noScheduleVerify(engineTurn): boolean {
        return engineTurn.lastEngineTurnDate && (engineTurn.engineTurnDate === undefined || engineTurn.engineTurnDate == null)
    }

    neverScheduleVerify(engineTurn): boolean {
        return (engineTurn.lastEngineTurnDate === undefined || engineTurn.lastEngineTurnDate === null)
        && (engineTurn.engineTurnDate === undefined || engineTurn.engineTurnDate == null);
    }
}
