<p-fieldset>
  <p-header class="article-title">
    <span class="article-title">{{ "EDIÇÃO DE CLIENTE" | translate }}</span>
    <div class="header-buttons">
      <button
        class="fab-button primary"
        appFiltersButton
        pTooltip="Exibir/Ocultar Filtros"
        tooltipPosition="left"
      >
        <i class="pi pi-filter"></i>
      </button>
    </div>
  </p-header>

  <form class="filters" (submit)="find()" appFilters>
    <div class="filter-row" class="find-bar">
      <div class="form-control">
        <label for="search" style="margin-left: 32px">{{
          "FIND" | translate
        }}</label>
        <input
          placeholder="ex:nome, título e cpf"
          [ngModelOptions]="{ standalone: true }"
          id="search"
          type="text"
          style="margin-left: 32px; margin-right: 32px"
          pInputText
          pKeyFilter="alphanum"
          [(ngModel)]="search"
        />
        <button class="easymarine primary" type="submit">
          {{ "FIND" | translate }}
        </button>
        <button
          style="margin-left: 32px"
          (click)="clear()"
          class="easymarine danger"
          type="button"
        >
          {{ "CLEAR" | translate }}
        </button>
      </div>
    </div>

    <div *ngFor="let item of customerList">
      <div class="filter-row find-bar" style="align-items: flex-start;">
        <img [src]="item.photoUrl === null || item.photoUrl === '' ? '../../../../assets/images/selectuser.png' : item.photoUrl" alt="Avatar" class="avatar" />
        <div class="info-group">
          <span>{{ item.name }}</span>
          <span>{{ item.federalId }}</span>
        </div>
        <div class="info-group">
          <span class="bold-text">Matrícula:</span>
          <span>{{ item.codRegistration }}</span>
        </div>
        <div class="info-group">
          <span class="bold-text">Título:</span>
          <span>{{ item.titleNumber}}</span>
        </div>
        <div class="info-group">
          <span class="bold-text">Tipo:</span>
          <span>{{ getType(item)}}</span>
        </div>
        <div class="info-group">
          <span class="bold-text">Embarcações:</span>
          <span>{{ item.boatsNames }}</span>
        </div>
        <button class="easymarine primary edit-button" type="button" style="margin-top: 8px;" (click)="openForm(item)" >
          {{ "EDIT" | translate }}
        </button>
      </div>

      <div  *ngFor="let item1 of item.dependents">

        <div class="filter-row find-bar" style="align-items: flex-start; margin-left: 30px;">
          <img [src]="item1.photoUrl === null || item1.photoUrl === '' ? '../../../../assets/images/selectuser.png' : item1.photoUrl" alt="Avatar" class="avatar" />
          <div class="info-group">
            <span>{{ item1.name }}</span>
            <span>{{ item1.federalId }}</span>
          </div>
          <div class="info-group">
            <span class="bold-text">Matrícula:</span>
            <span>{{ item1.codRegistration }}</span>
          </div>
          <div class="info-group">
            <span class="bold-text">Título:</span>
            <span>{{ item1.titleNumber}}</span>
          </div>
          <div class="info-group">
            <span class="bold-text">Tipo:</span>
            <span>{{ getType(item1)}}</span>
          </div>
          <div class="info-group">
            <span class="bold-text">Embarcações:</span>
            <span>{{ item1.boatsNames }}</span>
          </div>
          <button class="easymarine primary edit-button" type="button" style="margin-top: 8px;" (click)="openForm(item1)" >
            {{ "EDIT" | translate }}
          </button>
        </div>

      </div>
    </div>
  </form>
</p-fieldset>
