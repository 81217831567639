<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{ 'Contratos > Contratos Gerados' | translate }}</span>
    </p-header>

    <p-panel>
        <p-header class="header">
            <span class="article-title">Contratos não faturados</span>
        </p-header>
        <div class="table">
            <p-table [value]="documentsContractedNotBilling" class="primeTable" autoLayout="true"
                 [alwaysShowPaginator]="false" styleClass="easy-table" [lazy]="true" >
                <ng-template pTemplate="header">
                    <tr>
                        <th class="text-center" pSortableColumn="allSignatures">Qtd. Total de Asssinaturas enviadas
                        </th>
                        <th class="text-center" pSortableColumn="cancelSignatures">Qtd. Assinaturas Canceladas
                        </th>
                        <th class="text-center" pSortableColumn="value">Valor
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-documentContractedNotBilling >
                    <tr>
                        <td class="text-center">{{documentContractedNotBilling.allSignatures}}</td>
                        <td class="text-center">{{documentContractedNotBilling.cancelSignatures}}</td>
                        <td class="text-center">{{documentContractedNotBilling.value| number : '1.2-2'}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </p-panel>
    <p-panel>
        <p-header class="header">
            <span class="article-title">Histórico de contratos faturados</span>


        </p-header>
        <div class="table">
            <p-table [value]="documentsContracted" class="primeTable" autoLayout="true"
                (onLazyLoad)="onChangePage($event)" [paginator]="true" [alwaysShowPaginator]="false"
                [rowsPerPageOptions]="[10,20,30,50,100]" [pageLinks]="3" styleClass="easy-table" [lazy]="true"
                [rows]="paginationFilter.size">
                <ng-template pTemplate="header">
                    <tr>
                        <th class="text-center">Fatura</th>
                        <th class="text-center" pSortableColumn="allSignatures">Qtd. Total de Asssinaturas enviadas
                            <p-sortIcon field="allSignatures"></p-sortIcon>
                        </th>
                        <th class="text-center" pSortableColumn="cancelSignatures">Qtd. Assinaturas Canceladas
                            <p-sortIcon field="cancelSignatures"></p-sortIcon>
                        </th>
                        <th class="text-center" pSortableColumn="referenceDate">Mês / Ano

                            <p-sortIcon field="referenceDate"></p-sortIcon>
                        </th>
                        <th class="text-center" pSortableColumn="value">Valor
                            <p-sortIcon field="value"></p-sortIcon>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-documentContracted >
                    <tr>
                        <td class="text-center pointer" (click)="openInvoice(documentContracted)">
                            {{documentContracted.codInvoice}}</td>
                        <td class="text-center">{{documentContracted.allSignatures}}</td>
                        <td class="text-center">{{documentContracted.cancelSignatures}}</td>
                        <td class="text-center">{{documentContracted.referenceDate | date: 'MM/yyyy'}} </td>
                        <td class="text-center">{{documentContracted.value| number : '1.2-2'}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </p-panel>
</p-fieldset>
