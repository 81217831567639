import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-extract-pdf-generic',
  templateUrl: './extract-pdf-generic.component.html',
  styleUrls: ['./extract-pdf-generic.component.scss']
})
export class ExtractPdfGenericComponent implements OnInit {

  path: any;

  constructor(
    public dialog: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.path = this.sanitizer.bypassSecurityTrustResourceUrl(this.config.data.path);    
  }

}
