import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'primeng/dynamicdialog';
import { UserAppTabComponent } from 'src/app/components/user/user-app-tab/user-app-tab.component';
import { UserMarinaTabComponent } from 'src/app/components/user/user-marina-tab/user-marina-tab.component';
import { User } from 'src/app/models';
import { UserService } from 'src/app/services/user.service';
import { MessageUtil } from 'src/app/utils/message.util';
import Swal from 'sweetalert2';
import { UserFormComponent } from './user-form/user-form.component';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  @ViewChild(UserMarinaTabComponent) userMarinaTabComponent: UserMarinaTabComponent;
  @ViewChild(UserAppTabComponent) userAppTabComponent: UserAppTabComponent;
  activeIndex = 0;
  constructor(
    public dialog: DialogService,
    private userService: UserService,
    private translate: TranslateService,
    private messageUtil: MessageUtil
  ) { }

  ngOnInit(): void {
  }

  async openUserFormDialog(objEvent?: any): Promise<void> {
    const data = {
      model: null,
      origin: null
    };
    let user: User = null;
    if (objEvent) {
      user = objEvent.user;
      data.model = user;
      data.origin = objEvent.origin;
    }
    this.dialog.open(UserFormComponent, {
      width: '50%',
      dismissableMask: false,
      data,
      header: this.messageUtil.translateKey('USER-REGISTRATION')
    }).onClose.subscribe(
      (data) => {
        if(data){
          this.userMarinaTabComponent.fillUsersTable();
          this.userAppTabComponent.fillUsersTable();
        }
      });
  }

  openUserDeleteConfirmDialog(objEvent: any): void {
    let titleInput = null;
    let message = null;
    this.translate.get('DELETE-CONFIRMATION').subscribe((result: string) => { titleInput = result; });
    this.translate.get('DELETE-CONFIRMATION-MESSAGE').subscribe((result: string) => { message = result; });
    Swal.fire({
      title: titleInput,
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.userService.delete(objEvent.user.id).subscribe(
          () => {
            this.userMarinaTabComponent.fillUsersTable();
            this.userAppTabComponent.fillUsersTable();
            Swal.fire('Exclusão de usuário', 'Usuário excluído com sucesso.', 'success');
          },
          (error) => {
            Swal.fire('Falha ao remover!', error.error.message, 'error');
          },
          () => {
            if (objEvent.origin === 'TabMarina') {
              this.userMarinaTabComponent.fillUsersTable();
            } else {
              this.userAppTabComponent.fillUsersTable();
            }
          }
        );
      }
    });
  }

  tabViewChange(index: number): void {
    this.activeIndex = index;
    switch (index) {
      case 0:
        this.userMarinaTabComponent.fillUsersTable();
        break;
      case 1:
        this.userAppTabComponent.fillUsersTable();
        break;
      default:
        this.userMarinaTabComponent.fillUsersTable();
        break;
    }
  }

  eventTabUser(objEvent: any): void {
    if (objEvent.isDelete) {
      this.openUserDeleteConfirmDialog(objEvent);
    } else {
      this.openUserFormDialog(objEvent);
    }
  }
}
