<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">Consumíveis</span>
    </p-header>

    <form (submit)="saveConsumablesData()">

        <div class="form-group consumable">
            <div class="form-control">
                <label>{{ 'ADD-CONSUMABLE' | translate }}</label>
                <p-dropdown placeholder="Selecione..." [filter]="true" [options]="filteredConsumables" appendTo="body" [disabled]="isASlingEdition"
                    [(ngModel)]="productSelected" name="productSelected">
                </p-dropdown>
            </div>
            <button class="fab-button primary" pTooltip="{{ 'ADD-PRODUCT' | translate  }}" type="button" [disabled]="isASlingEdition"
                (click)="addNewConsumable()" name="addConsumableBtn">
                <i class="pi pi-plus"></i>
            </button>
        </div>
        <br>
        <div class="table">
            <p-table [value]="consumableOrderItems" [autoLayout]="true">
                <ng-template pTemplate="header">
                    <tr>
                        <th>{{ 'PRODUCT' | translate }}</th>
                        <th>{{ 'PRICE' | translate }}</th>
                        <th>Estoque</th>
                        <th>{{ 'QUANTITY' | translate }}</th>
                        <th>Unidade</th>
                        <th class="text-right">{{ 'SUBTOTAL' | translate }}</th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-consumable>
                    <tr>
                        <td>{{consumable.product.name}}</td>
                        <td class="text-right"
                            [ngStyle]="{'background-color':consumable.product.price.priceFree && '#b1d8f7' }">
                            <p-inputNumber [(ngModel)]="consumable.itemValue" required
                                name="itemValue{{consumable.product.id}}" [disabled]="isASlingEdition"
                                *ngIf="consumable.product.price.priceFree || slingOrder.prepare" mode="decimal"
                                [minFractionDigits]="2" [maxFractionDigits]="2">
                            </p-inputNumber>
                            <div *ngIf="!(consumable.product.price.priceFree || (slingOrder.prepare))">
                                <span>{{consumable.itemValue | number :'1.2-2'}}</span>
                            </div>
                        </td>
                        <td class="text-center">
                            {{consumable.qtyStock | number}}
                        </td>
                        <td class="text-left" style="background-color: #b1d8f7">
                            <ng-container class="full-width">
                                <p-inputNumber *ngIf="consumable?.product?.measurement == 'Kilograms' || consumable?.product?.measurement == 'Liters'; else otherMeasurements"
                                    [max]="consumable.qtyStock + consumable.qtyRequested" min="0.001" [maxFractionDigits]="3"
                                    name="qtyRequested{{consumable.product.id}}" [disabled]="slingOrder.prepare || isASlingEdition"
                                    [(ngModel)]="consumable.qtyRequested" #qtyRequested="ngModel" required>
                                </p-inputNumber>
                                <ng-template #otherMeasurements>
                                    <p-inputNumber [max]="consumable.qtyStock + consumable.qtyRequested" min="1"
                                        name="qtyRequested{{consumable.product.id}}" [disabled]="slingOrder.prepare" [disabled]="isASlingEdition"
                                        [(ngModel)]="consumable.qtyRequested" #qtyRequested="ngModel" required>
                                    </p-inputNumber>
                                </ng-template>
                                <span *ngIf="qtyRequested?.errors?.min">
                                    {{'INVALID-QUANTITY' | translate}}
                                </span>
                                <span *ngIf="qtyRequested?.errors?.max">
                                    {{'OUT-OF-STOCK' | translate}}
                                </span>



                            </ng-container>
                        </td>
                        <td class="text-right">
                            {{consumable.product.measurement.toUpperCase() | translate}}
                        </td>
                        <td class="text-right">
                            {{(consumable.qtyRequested ? (consumable.itemValue * consumable.qtyRequested) : 0 ) |
                            number: '1.2-2' }}
                        </td>
                        <td>
                            <div pTooltip="Remover item" tooltipPosition="bottom"
                                [ngClass]="{'disabled': isASlingEdition}"
                                (click)="!isASlingEdition && deleteConsumableOrderItem(consumable)"
                                name="deleteConsumableOrderItemButton" class="table-action">
                                <i class="pi pi-trash"></i>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

        <p-footer>
            <div class="form-group">
                <div class="form-control">
                    <div class="float-left" *ngIf="slingOrder?.id">
                        <p style="font-weight:500; padding-left: 20px">Data do pedido: {{getRequestDate() | date:
                            completeDateFormat}}</p>
                    </div>
                </div>
                <div class="buttons">
                    <button class="easymarine danger" type="button" (click)="back()">{{
                        'Voltar' | translate }}</button>
                    <button [disabled]="disableFlag" class="easymarine" type="submit">
                        {{ 'NEXT' | translate }}
                    </button>
                </div>
            </div>
        </p-footer>
    </form>
</p-fieldset>
