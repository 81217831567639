import { Component, Input, OnInit } from '@angular/core';
import { SlingConfigService } from 'src/app/services/sling-config.service';

@Component({
  selector: 'app-sling-log-table',
  templateUrl: './sling-log-table.component.html',
  styleUrls: ['./sling-log-table.component.scss']
})
export class SlingLogTableComponent implements OnInit {

  @Input()
  slingLogs = [];

  @Input()
  isDialog = false;

  numberOfRows = 10;

  constructor(
    private slingConfigService: SlingConfigService
  ) { }

  ngOnInit(): void {
    if ( !this.isDialog) {
      this.loadSlingConfig();
    }
  }

  loadSlingConfig(): void {
    this.slingConfigService.getSlingConfig().subscribe(
      (data) => {
        const slingConfig = data[0];
        if (slingConfig != null) {
          this.numberOfRows = slingConfig.numberOfRecordsPerPage;
        } else {
          this.numberOfRows = 10;
        }
      },
      (error) => null);
  }

}
