<form [formGroup]="formData" *ngIf="formData" (submit)="next()" #f="ngForm" autocomplete="off">
  <app-movement-responsibles [form]="formData" [isNew]="isNew" [slingConfig]="slingConfig"></app-movement-responsibles>

  <div class="marginTop30">
    
    <app-messages *ngIf="verifyIfboatHasNotMovementGroup()" [link]="'/app/configs/movements'" (close)=" cancel()"
      [titleMessage]="' Embarcação sem Fila '"
      [textMessage]="' Por favor adicione uma fila de movimentações no cadastro da embarcação. '"
      [msgAction]="' Adicionar fila '">
    </app-messages>
    <app-messages *ngIf="verifyIfVacancyFormGroupHasError()" [titleMessage]="' Local de Movimentação sem Vaga '"
      [textMessage]="' Por favor verifique a disponibilidade das vagas para esse local '" (close)="cancel()"
      [msgAction]="' Adicionar vaga '"
      [link]="'/app/configs/movement-locations'">
    </app-messages>
    
    <app-messages *ngIf="verifyIfboatHasNotCurrentLocation()"
      [link]="'/app/forms/boat'"
      (close)="cancel()"
      [titleMessage]="' Embarcação sem refência de local '"
      [textMessage]="' Por favor atualize o cadastro da embarcação. '"
      [msgAction]="' Atualizar cadastro '">
    </app-messages>
    <app-messages *ngIf="verifyIfboatHaveLocationAndProfile()"
      [link]="'/app/forms/boat'"
      (close)="cancel()"
      [titleMessage]="' Embarcação sem local cadastrado '"
      [textMessage]="' Por favor adicione um local no cadastro da embarcação. '"
      [msgAction]="' Cadastrar local '">
    </app-messages>
  </div>

  <div class="buttons align-bottom">
    <button class="easymarine danger" (click)="cancel()" type="button">{{'CANCEL'| translate}}</button>
    <button class="easymarine primary" type="submit" [disabled]="!formData.valid">{{'NEXT'| translate}}</button>
  </div>
</form>