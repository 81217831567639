import { FormatUtil } from './../../../../../utils/format.util';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import b64toBlob from 'b64-to-blob';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortEvent } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Paginator } from 'primeng/paginator';
import { ExtractInvoiceComponent } from 'src/app/components/extract-invoice/extract-invoice.component';
import { SlingConfig } from 'src/app/models';
import { Invoice } from 'src/app/models/invoice';
import { InvoiceBankingBilletTable } from 'src/app/models/invoice-banking-billet-table';
import { MarinaCompany } from 'src/app/models/marina-company';
import { PaginationFilter } from 'src/app/models/pagination-filter';
import { BilletService } from 'src/app/services/billet.service';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { CustomerService } from 'src/app/services/customer.service';
import { FinancesService } from 'src/app/services/finances.service';
import { GroupService } from 'src/app/services/group.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';
import Swal from 'sweetalert2';
import { RoleService } from 'src/app/services/role.service';

@Component({
  selector: 'app-billet-dialog',
  templateUrl: './billet-dialog.component.html',
  styleUrls: ['./billet-dialog.component.scss']
})
export class BilletDialogComponent implements OnInit {

  rangeDates: Date[];
  rangeDatesEmissao: Date[];
  rangeDatesVencimento: Date[];
  rangeDatesPagamento: Date[];

  billetsFiltered: InvoiceBankingBilletTable[] = [];
  billets: InvoiceBankingBilletTable[] = [];
  selectedBillets: InvoiceBankingBilletTable[] = [];
  numberOfRows = 10;
  slingConfig: SlingConfig;
  marinaCompanies: MarinaCompany[];
  marinaCompaniesWithDatabase: MarinaCompany[];
  filteredCompanies: { label: string; value: any; }[];
  selectedCompany: any = null;
  loggedMarina: any;
  billingPortifolioList: any;
  billingPortifolios: any[];
  paginationFilter: PaginationFilter = new PaginationFilter();
  filter: any;
  invoice: any[];
  public yesNoOptions: Array<{ label: string, value: any }> = [
    { label: 'BOTH', value: null },
    { label: 'YES', value: true },
    { label: 'NO', value: false }
  ];
  yesNoList: { label: string; value: any; }[] = [];
  contentLoaded = false;
  customers: any;
  filteredCustomers: any;
  groups: { label: string; value: any; }[];
  groupsList: any;
  origins = [
    { label: 'Pedido avulso', value: 'DETACHED' },
    { label: 'Lingada', value: 'SLING' },
    { label: 'Mensalidade', value: 'TUITION' },
    { label: 'Importado DANFE', value: 'LEGACY_IMPORT' }
  ];
  totalRecords = 0;
  totalBillets: any;
  totalPaid: any;
  refresh = false;
  extraFilter: string;
  allSelected = false;

  globalFilter = '';
  orderObj: any;


  constructor(
    private slingConfigService: SlingConfigService,
    private spinner: NgxSpinnerService,
    private billetService: BilletService,
    private financesService: FinancesService,
    private messageService: MessageUtil,
    private customerService: CustomerService,
    private dialog: DialogService,
    private groupService: GroupService,
    private invoiceService: InvoiceService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private roleService: RoleService,
    private dialogRef?: DynamicDialogRef,
    private config?: DynamicDialogConfig
  ) {
  }

  async ngOnInit(): Promise<void> {
    const params = Object.assign({}, this.route.snapshot.queryParams);
    if (params && params.filter) {
      this.extraFilter = params.filter;
    }
    if (this.config.data) {
      await this.initializeApp();
      this.filter = this.config.data.filter;
      this.numberOfRows = this.config.data.numberOfRows;
      this.paginationFilter.size = this.config.data.paginationFilter.size;
      this.invoice = this.config.data.invoice;
      this.receiveNoticiesParams();
      this.findBillets();
      BroadcastService.get('showFilters').emit(false);
    }
    this.spinner.show();
    if (!this.config.data) {
      await this.initializeApp();
      this.receiveNoticiesParams();
      this.findBillets();
    }
    this.contentLoaded = true;
    this.spinner.hide();
  }

  receiveNoticiesParams(): void {
    this.route.queryParamMap
    .subscribe((params) => {
      this.orderObj = { ...params.keys, ...params };
      }
    );
    switch (this.orderObj.params.operation) {
      case '1':
        this.filter.bankCanceled= true;
        this.rangeDates= [moment(this.orderObj.params.date).toDate(), moment(this.orderObj.params.date).toDate()];
        break;
      case '2':
        this.rangeDatesEmissao= [moment(this.orderObj.params.date).toDate(), moment(this.orderObj.params.date).toDate()];
        break;
      case '3':
        this.filter.paid= true;
        this.rangeDatesPagamento= [moment(this.orderObj.params.date).toDate(), moment(this.orderObj.params.date).toDate()];
        break;
      default:
        break;
    }
  }

  async getCompanies(): Promise<void> {
    const marina = StorageUtil.getMarina();
    this.marinaCompanies = marina.marinaCompanies;
    this.marinaCompaniesWithDatabase = await this.financesService.findCompaniesWithDatabase();
    if (this.marinaCompanies.length > 0) {
      this.filteredCompanies = this.marinaCompanies.map((c) => ({ label: c.companyFederalName, value: c }));
      this.filteredCompanies.unshift({ label: marina.companyName, value: marina });
      this.filteredCompanies.unshift({ label: 'Todas as empresas', value: null });
    }
    this.loggedMarina = marina;
  }

  async loadSlingConfig(): Promise<void> {
    return new Promise<void>(
      async (res) => {
        this.slingConfigService.getSlingConfig().subscribe(
          async (data) => {
            this.slingConfig = data[0];
            this.numberOfRows = this.slingConfig != null ? this.slingConfig.numberOfRecordsPerPage : 10;
            this.paginationFilter.size = this.numberOfRows;
            res();
          },
          async () => res()
        );
      }
    );
  }

  findBillets(): void {
    this.spinner.show();
    if (this.rangeDates != undefined) {
      this.changeDate();
    }
    if (this.rangeDatesEmissao != undefined) {
      this.changeDateEmissao();
    }
    if (this.rangeDatesVencimento != undefined) {
      this.changeDateVencimento();
    }
    if (this.rangeDatesPagamento != undefined) {
      this.changeDatePagamento();
    }

    this.billetService.findByFilter(this.filter, this.paginationFilter).subscribe(
      (response) => {
        this.billetsFiltered = response.pageable.content;
        this.billets = response.pageable.content;
        this.totalRecords = response.pageable.totalElements;
        this.totalBillets = response.total;
        this.totalPaid = response.totalPaid;
      }, (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title, exception.message);
      }, () => {
        this.spinner.hide();

      }
    );
  }

  billingPortfolio(billet): any {
    let company;
    const c = this.billingPortifolioList.find(
      (carteira) => {
        if (carteira.idCarteiraCobranca === billet.idCarteiraCobranca) {
          company = this.marinaCompanies.find((m) => m.companyFederalName === billet.marinaCompany);
          if ((!company || !company.finances) && !carteira.marinaCompany) {
            return carteira;
          } else if (company.finances && carteira.marinaCompany === company.id) {
            return carteira;
          }
        }
      }
    );
    return c ? c : { conta: { nome: '' }, nome: '' };
  }

  async getBilingPortifolioList(): Promise<void> {
    return new Promise<void>(
      async (res) => {
        this.billingPortifolioList = (await this.financesService.getAllBilingPortifolioAsync(null))
          .map((carteira) => {
            carteira.marinaCompany = null;
            return carteira;
          });
        for (let index = 0; index < this.marinaCompaniesWithDatabase.length; index++) {
          this.billingPortifolioList = this.billingPortifolioList.concat(
            (await this.financesService.getAllBilingPortifolioAsync(this.marinaCompanies[index]))
              .map((carteira) => {
                carteira.marinaCompany = this.marinaCompanies[index].id;
                return carteira;
              })
          );
        }
        res();
      }
    );
  }


  async filterCompanyPortfolios(): Promise<void> {
    let carteiras = [...this.billingPortifolioList];
    if (this.selectedCompany &&
      (
        this.selectedCompany.marinaCompanies ||
        !this.marinaCompaniesWithDatabase.find((m) => m.id === this.selectedCompany.id)
      )
    ) {
      carteiras = carteiras.filter((g) => !g.marinaCompany)
        .filter((g) => this.compareFederalIds(g.beneficiarioDocumento, this.selectedCompany.companyFederalId));
    } else if (this.selectedCompany) {
      carteiras = carteiras.filter((g) => g.marinaCompany && g.marinaCompany === this.selectedCompany.id)
        .filter((g) => this.compareFederalIds(g.beneficiarioDocumento, this.selectedCompany.companyFederalId));
    }
    carteiras = carteiras.map((g) => ({ label: g.nome, value: g.idCarteiraCobranca }));
    carteiras.unshift({ label: 'Todas', value: null });
    this.billingPortifolios = [...carteiras];
    this.filter.idCarteiraCobranca = null;
  }

  async mountOptions(): Promise<void> {
    return new Promise<void>(
      async (resolve) => {
        const list = [];
        // tslint:disable-next-line: prefer-for-of
        for (let index = 0; index < this.yesNoOptions.length; index++) {
          list.push({
            label: await this.messageService.translateKeyAsync(this.yesNoOptions[index].label),
            value: this.yesNoOptions[index].value
          });
        }
        this.yesNoList = [...list];
        resolve();
      }
    );
  }

  changeCompany(): void {
    if (!this.selectedCompany) {
      this.filter.marinaCompanyId = null;
      this.filter.onlyMarina = false;
    } else if (this.selectedCompany.marinaCompanies) {
      this.filter.marinaCompanyId = null;
      this.filter.onlyMarina = true;
    } else {
      this.filter.marinaCompanyId = this.selectedCompany.id;
      this.filter.onlyMarina = false;
    }
    this.filterCompanyGroups();
    this.filterCompanyPortfolios();
  }

  getCompanyIndex(company: number): number {
    const m = this.marinaCompanies.find((c) => c.id === company);
    return this.marinaCompanies.indexOf(m) + 2;
  }


  async getCustomers(): Promise<void> {
    return new Promise<void>(
      async (resolve, reject) => {
        this.customerService.getAll().subscribe(
          async (data) => {
            this.customers = data;
            this.filteredCustomers = [{ label: 'Todos os clientes', value: null }].concat(
              this.customers.map(
                (c) => ({ label: c.name + ' ' + c.federalId, value: c.id })
              )
            );
            resolve();
          },
          async () => {
            reject();
          }
        );
      }
    );
  }

  async getGroups(): Promise<void> {
    return new Promise<void>(
      async (res) => {
        this.groupService.findAllActive().subscribe(
          async (data) => {
            this.groupsList = data;
            res();
          }
        );
      }
    );
  }

  async filterCompanyGroups(): Promise<void> {
    let groups = [...this.groupsList];
    if (this.selectedCompany && this.selectedCompany.marinaCompanies) {
      groups = groups.filter((g) => !g.marinaCompany);
    } else if (this.selectedCompany) {
      groups = groups.filter((g) => g.marinaCompany && g.marinaCompany.id === this.selectedCompany.id);
    }
    groups = groups.map((g) => ({ label: g.name, value: g.id }));
    groups.unshift({ label: 'Sem grupo', value: null });
    this.groups = [...groups];
    this.filter.groupIds = [];
  }

  async clear(button?: boolean): Promise<void> {
    this.filter = {
      active: true,
      startDueDate: null,
      endDueDate: null,
      endPayment: null,
      startPayment: null,
      endRepurchaseDate: null,
      startRepurchaseDate: null,
      groupIds: [],
      origin: this.origins.map((o) => o.value)
    };
    this.rangeDates = undefined;
    this.rangeDatesPagamento = undefined;
    this.rangeDatesVencimento = undefined;
    this.rangeDatesEmissao = undefined;
    if (this.extraFilter && !button) {
      switch (this.extraFilter) {
        case 'pendingBank':
          this.filter.active = false;
          this.filter.bankCanceled = false;
          break;
        case 'refused':
          this.filter.refused = true;
          this.filter.active = true;
          break;
        default:
          break;
      }
    }
    this.selectedCompany = null;
    this.paginationFilter = {
      order: 'DESC',
      page: 0,
      size: this.numberOfRows,
      sort: null
    };
  }

  getDateFormatted(date): string {
    return moment(date).format('DD/MM/YY');
  }

  extract(idInvoice): void {
    this.spinner.show();
    this.invoiceService.extract(idInvoice).subscribe(
      (data) => {

        this.openExtract(
          this.sanitizer.bypassSecurityTrustResourceUrl(
            URL.createObjectURL(
              (b64toBlob as any)(data, 'application/pdf')
            )
          )
        );
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title, exception.message);
      },
      () => this.spinner.hide());
  }

  openExtract(path): void {
    this.dialog.open(ExtractInvoiceComponent, {
      width: '100%',
      height: '100%',
      dismissableMask: false,
      data: { path }
    });
  }

  compareFederalIds(federalId, federalIdCompare): boolean {
    return federalId.replace(/[^a-zA-Z0-9]/g, '') === federalIdCompare.replace(/[^a-zA-Z0-9]/g, '');
  }

  filterGlobal(): void {
    const globalFilter = this.globalFilter;
    this.billetsFiltered = this.billets.filter((b) =>
      FormatUtil.verifyIfStringContainsSubStringWithoutAccents(b.customer, globalFilter) ||
        (b.invoiceNumber.toString().includes(globalFilter)) ||
        (this.globalFilterHasContaName(b, globalFilter)) ||
        (this.globalFilterHasCarteira(b, globalFilter))
    );
  }

  globalFilterHasContaName(billet: InvoiceBankingBilletTable, globalFilter: string) {
    const carteira = this.billingPortfolio(billet);
    return carteira?.conta?.nome && FormatUtil.verifyIfStringContainsSubStringWithoutAccents(carteira.conta.nome, globalFilter);
  }

  globalFilterHasCarteira(billet: InvoiceBankingBilletTable, globalFilter: string) {
    const carteira = this.billingPortfolio(billet);
    return carteira?.nome && FormatUtil.verifyIfStringContainsSubStringWithoutAccents(carteira.nome, globalFilter);
  }


  async cancelBillets(): Promise<void> {
    const credentialsAdm = await this.isCurrentUserAdministrator();
    if (!this.selectedBillets || this.selectedBillets.length === 0) {
      this.messageService.generateMessage('warning', 'SUMMARY.WARNING', 'Selecione pelo menos um boleto para cancelar.');
      return;
    }
    credentialsAdm ?
    this.openConfirmation(
      'Cancelar boletos',
      'Os boletos serão cancelados. Esta operação não pode ser desfeita. Deseja continuar?',
      'warning'
    ).then(
      () => {
        this.spinner.show();
        this.billetService.cancelBillets(this.selectedBillets.map((b) => b.id)).subscribe(
          () => {
            this.messageService.generateMessage(
              'success',
              'SUMMARY.SUCCESS',
              'Boletos cancelados com sucesso. Lembre-se de cancelar os boletos no banco.'
            );
            this.refresh = true;
            this.findBillets();
            this.spinner.hide();
          },
          err => {
            this.spinner.hide();
            console.log(err);
            const exception = err.error;

            this.messageService.generateMessage(MessageUtil.ERROR, "Sem permissão", exception.message);
          }
        );
      }
    )
      : this.messageService.generateMessage(MessageUtil.ERROR, "Erro", "Você não possui permissão para executar essa ação");
  }

  cancelBilletBank(billet): void {
    this.openConfirmation(
      'Alterar boleto',
      billet.bankCanceled ?
        'O boleto será alterado para \"Não cancelado no banco\". Deseja continuar?' :
        'O boleto será alterado para \"Cancelado no banco\". Deseja continuar?',
      'warning'
    ).then(
      () => {
        this.spinner.show();
        this.billetService.cancelBilletBank(billet.id, !billet.bankCanceled).subscribe(
          () => {
            this.messageService.generateMessage(
              'success',
              'SUMMARY.SUCCESS',
              'Boleto alterado com banco.'
            );
            this.findBillets();
            this.spinner.hide();
          },
          err => {
            this.spinner.hide();
            const exception = err.error.data.exception;
            this.messageService.generateMessage(exception.type, exception.title, exception.message);
          }
        );
      }
    );
  }

  async openConfirmation(title, text, icon): Promise<void> {
    return new Promise<void>(
      async (resolve, reject) => {
        Swal.fire(
          {
            title,
            text,
            icon,
            backdrop: false,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            reverseButtons: true
          }).
          then(async (result) => {
            if (result.value) {
              resolve();
            } else if (result.dismiss) {
              reject();
            }
          });
      }
    );
  }


  checkIfHaveNixBillet(billet: any): boolean {
    const billetBoy = billet?.issueDate;
    if (billetBoy) {
      if (billet.bankNumber === 332) {
          return true;
      }
    }
    return false;
}


    
  onChangePage(event: Paginator): void {
    if(this.slingConfig) {
      this.paginationFilter.page = event.first / event.rows;
      this.paginationFilter.size = event.rows;
      this.findBillets();
    }
  }

  onSort(event: SortEvent): void {
    this.paginationFilter.order = event.order === 1 ? 'ASC' : 'DESC';
    this.paginationFilter.sort = event.field;
    this.findBillets();
  }

  getTooltip(billet): string {
    let texto = '';
    if (!billet.paid && billet.registered) {
      texto = (billet.anticipated ? 'Boleto Antecipado e Registrado' : 'Boleto registrado');
    } else if (!billet.registered) {
      texto = (billet.anticipated ? 'Boleto Antecipado e Não registrado' : 'Boleto não registrado');
    } else if (billet.paid) {
      texto = billet.paymentDate ? 'Boleto pago em: ' + this.getDateFormatted(billet.paymentDate) : 'Boleto pago';
    }
    return texto;
  }

  async initializeApp(): Promise<void> {
    await this.mountOptions();
    await this.clear();
    await this.loadSlingConfig();
    await this.getCompanies();
    await this.getBilingPortifolioList();
    await this.filterCompanyPortfolios();
    await this.getCustomers();
    await this.getGroups();
    await this.filterCompanyGroups();
  }

  close(): void {
    if (this.dialogRef) {
      this.dialogRef.close(this.refresh);
    }
  }

  canCancel(billet): boolean {
    return billet && billet.active && !billet.paid && !billet.valuePaid && (!billet.anticipated || billet.repurchaseDate);
  }

  selectAll(): void {
    if (!this.allSelected) {
      this.selectedBillets = this.billetsFiltered.filter((b) => this.canCancel(b));
    } else {
      this.selectedBillets = [];
    }
    this.allSelected = !this.allSelected;
  }

  isToday(file): boolean {
    return moment(file.issueDate).hours(0).minutes(0).seconds(0).milliseconds(0).isSame(
      moment().hours(0).minutes(0).seconds(0).milliseconds(0)
    );
  }

  openBillet(url): void {
    window.open(url);
  }

  async isCurrentUserAdministrator(): Promise<Boolean> {
    const currentUserID = StorageUtil.getUserId();
    return new Promise<Boolean> (async (resolve) => {
      this.roleService.currentUserIsAdministrator(currentUserID).subscribe({
        next: (administratorRole: Boolean) => resolve(administratorRole),
        error: (e) => console.log(e),
      });
    });
  }

  processingBilletWarn() {
    Swal.fire(
      {
        title: 'Boleto com registro pendente',
        text: 'Importe o arquivo de retorno para visualizar o boleto.',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK',
      });
  }

  changeDate(): void {
    if (this.rangeDates[0] != null) {
      this.filter.startCancelDate = this.rangeDates[0];
      if (this.rangeDates[1] != null) {
        this.filter.endCancelDate = this.rangeDates[1];
      } else {
        this.filter.endCancelDate = this.rangeDates[0];
      }
    } else {
      this.filter.startCancelDate = null;
      this.filter.endCancelDate = null;
    }
  }

  changeDateEmissao(): void {
    if (this.rangeDatesEmissao[0] != null) {
      this.filter.startIssueDate = this.rangeDatesEmissao[0];
      if (this.rangeDatesEmissao[1] != null) {
        this.filter.endIssueDate = this.rangeDatesEmissao[1];
      } else {
        this.filter.endIssueDate = this.rangeDatesEmissao[0];
      }
    } else {
      this.filter.startIssueDate = null;
      this.filter.endIssueDate = null;
    }
  }

  changeDateVencimento(): void {
    if (this.rangeDatesVencimento[0] != null) {
      this.filter.startDueDate = this.rangeDatesVencimento[0];
      if (this.rangeDatesVencimento[1] != null) {
        this.filter.endDueDate = this.rangeDatesVencimento[1];
      } else {
        this.filter.endDueDate = this.rangeDatesVencimento[0];
      }
    } else {
      this.filter.startDueDate = null;
      this.filter.endDueDate = null;
    }
  }

  changeDatePagamento(): void {
    if (this.rangeDatesPagamento[0] != null) {
      this.filter.startPayment = this.rangeDatesPagamento[0];
      if (this.rangeDatesPagamento[1] != null) {
        this.filter.endPayment = this.rangeDatesPagamento[1];
      } else {
        this.filter.endPayment = this.rangeDatesPagamento[0];
      }
    } else {
      this.filter.startPayment = null;
      this.filter.endPayment = null;
    }
  }
}

