import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MovementLocationType } from 'src/app/models/enums/movements/movement-location-type';
import { MovementGroup } from 'src/app/models/movement/movement-group';
import { MovementGroupService } from 'src/app/services/movements/movement-group.service';
import { ToastService } from 'src/app/services/toast.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { getCustomValidatorsMovementGroupToMovementGroupMeansByLocationType_MovementMean } from 'src/app/validators/movement/movement-group';

@Component({
  selector: 'app-movement-group-modal',
  templateUrl: './movement-group-modal.component.html',
  styleUrls: ['./movement-group-modal.component.scss']
})
export class MovementGroupModalComponent implements OnInit {

  movementGroupForm: UntypedFormGroup;

  get movementGroupMeansByLocationTypeArray(): UntypedFormArray {
    return this.movementGroupForm.controls.movementGroupMeansByLocationType as UntypedFormArray;
  }

  movementGroup: MovementGroup;
  scheduleQueueOptions: { label: string, value: { id: number, name: string }}[] = [];
  movementMeanOptions: any[];
  movementImageOptions: any[];

  constructor(
    public dialog: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private messageUtil: MessageUtil,
    private spinner: NgxSpinnerService,
    private movementGroupService: MovementGroupService,
    private toasty: ToastService,
  ) { }

  ngOnInit(): void {
    if(this.config.data.scheduleQueueOptions) {
      this.scheduleQueueOptions = this.config.data.scheduleQueueOptions
        .filter(({ value: sq }) => sq.active)
    }
    if(this.config.data.movementMeanOptions) {
      this.movementMeanOptions = this.config.data.movementMeanOptions;
    }
    if (this.config.data.movementGroupId) {
      const movementGroupId = this.config.data.movementGroupId as number;
      this.movementGroupService.getById(movementGroupId).subscribe(
        (movementGroup) => {
          this.movementGroup = movementGroup;
          this.startForm();
        },
        (err) => {
          this.toasty.error('Ops, não foi possível buscar o grupo!');
        }
      )
    } else {
      this.startForm();
    }
  }

  startForm() {
    let movementGroupMeans = [];
    const movementGroup = this.movementGroup;


    if(movementGroup?.id){
      const movementMeansIds = movementGroup.movementGroupMeans.map( groupMean => groupMean.movementMean.id);
      movementGroupMeans = this.movementMeanOptions.map((opt) => opt.value)
        .filter((mMean) => movementMeansIds.includes(mMean.id));
    }

    const scheduleQueue = this.scheduleQueueOptions.filter(({value}) => value.id === movementGroup?.scheduleQueue?.id).length > 0
      ? movementGroup?.scheduleQueue
      : undefined;

    this.movementGroupForm = new UntypedFormGroup({
      name: new UntypedFormControl(movementGroup?.name, Validators.required),
      code: new UntypedFormControl(movementGroup?.code, Validators.required),
      scheduleQueue: new UntypedFormControl(scheduleQueue, Validators.required),
      active: new UntypedFormControl(movementGroup?.active ? movementGroup.active : true, Validators.required),
      movementGroupMeans: new UntypedFormControl(movementGroupMeans, Validators.required),
      movementGroupMeansByLocationType: this.createMovementGroupMeansByLocationTypeFormArray(movementGroup)
    });
    this.movementGroupForm.controls.movementGroupMeans.valueChanges.subscribe(
      (data) => {
        for(let movementGroupMeansByLocationTypeFormGroup of this.movementGroupMeansByLocationTypeArray.controls) {
          (movementGroupMeansByLocationTypeFormGroup as UntypedFormGroup).controls.movementMean.updateValueAndValidity();
        }
       }
    )


    for(let movementGroupMeansByLocationTypeFormGroup of this.movementGroupMeansByLocationTypeArray.controls) {
      (movementGroupMeansByLocationTypeFormGroup as UntypedFormGroup).controls.movementMean.setValidators([
        getCustomValidatorsMovementGroupToMovementGroupMeansByLocationType_MovementMean(this.movementGroupForm),
        Validators.required
      ])
    }
  }

  createMovementGroupMeansByLocationTypeFormArray(movementGroup: MovementGroup) {
    const movementGroupMeansByLocationType = movementGroup?.movementGroupMeansByLocationType || [];
    const loadedLocationsType = movementGroupMeansByLocationType.map(m => m.locationType);
    const allLocationTypes = Object.values(MovementLocationType);
    allLocationTypes.forEach(locationType => {
      if (!loadedLocationsType.includes(locationType)) {
        movementGroupMeansByLocationType.push({ id: null, locationType, movementMean: null });
      }
    })

    const movementGroupMeansByLocationTypeControls = movementGroupMeansByLocationType
      .map(item => {
        const idForm = new UntypedFormControl(item.id);
        const locationTypeForm = new UntypedFormControl(item.locationType, Validators.required);
        const movementMeanForm = new UntypedFormControl(item.movementMean);
        return new UntypedFormGroup({
          id: idForm,
          locationType: locationTypeForm,
          movementMean: movementMeanForm
        })
      });
    return new UntypedFormArray(movementGroupMeansByLocationTypeControls? movementGroupMeansByLocationTypeControls: []);
  }

  save() {
    if(this.movementGroup?.id){
      this.update();
    } else {
      this.create();
    }
  }


  create() {
    this.spinner.show();
    const movementGroup = this.getMovementGroupValue();
    this.movementGroupService.create(movementGroup).subscribe(
      (data) => {
        this.toasty.success('Grupo de movimentação cadastrado com sucesso');
        this.dialog.close(data);
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      },
      () => this.spinner.hide());
  }

  update() {
    this.spinner.show();
    const movementGroup = this.getMovementGroupValue();
    movementGroup.id = this.movementGroup.id;
    this.movementGroupService.update(movementGroup).subscribe(
      (data) => {
        this.toasty.success('Grupo de movimentação salvo com sucesso');
        this.dialog.close(data);
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      },
      () => this.spinner.hide());
  }

  private getMovementGroupValue(): MovementGroup {
    const movementGroup = this.movementGroupForm.value;
    movementGroup.movementMeanIds = movementGroup.movementGroupMeans.map(mm => mm.id);
    return movementGroup;
  }
}
