import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-boat-log-sailor',
  templateUrl: './boat-log-sailor.component.html',
  styleUrls: ['./boat-log-sailor.component.scss']
})
export class BoatLogSailorComponent implements OnInit {

  @Input()
  sailors;

  @Input()
  sailorsOld;

  @Input()
  isNew: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  logsTable(): any[]{
    if(this.isNew){
      return this.sailors;
    } else {
      return this.sailorsOld;
    }
  }

  isChanged(sailor): boolean {
    let boatSailorVerify;
    if(this.isNew){
      boatSailorVerify = this.findSailorOld(sailor.id);
    } else {
      boatSailorVerify = this.findSailor(sailor.id);
    }

    return !boatSailorVerify || (boatSailorVerify.fullName !== sailor.fullName);
  }

  findSailor(id): any{
    return this.sailors.find(sailor => sailor.id === id);
  }

  findSailorOld(id): any{
    return this.sailorsOld.find(sailor => sailor.id === id);
  }

}
