<div class="filters active">
  <div class="filter-row">
    <section style="display: flex; flex-direction: column; font-weight: 600;">
      <span>Total de embarcações: {{washDetails.boatAll}}</span>
      <span>Embarcações agendadas: {{washDetails.boatScheduled}}</span>
      <span>Embarcações sem agendamento: {{washDetails.boatNoSchedule}}</span>
      <span>Embarcações nunca agendadas: {{washDetails.boatNeverSchedule}}</span>
    </section>
  </div>
  <div class="filter-row">
      <div class="filter-row">
        <div class="form-control">
          <label>Data de agendamento</label>
          <p-calendar dateFormat="dd/mm/yy" [minDate]="currentDate" name="schedulingDate" [readonlyInput]="true"
              [(ngModel)]="schedulingDate" [showIcon]="true" i18n appendTo="body">
          </p-calendar>
        </div>

        <div class="buttons flex-start">
            <div class="form-button">
                <button class="success easymarine" (click)="dialogOptionSchedule(schedulingDate)"
                    [disabled]="!schedulingDate || !boatSelected">{{ 'CONFIRM' | translate }}</button>
            </div>
            <div class="form-button">
                <button class="danger easymarine" [disabled]="!boatScheduleSelected"
                    (click)="dialogOptionSchedule(null)">{{ 'CANCEL-SCHEDULE' | translate }}</button>
            </div>
        </div>
        <div class="buttons">
            <div class="form-button">
                <button class="primary easymarine" (click)="registerManual()"
                    [disabled]="!boatSelected">{{'Registro manual' | translate}}</button>
            </div>
        </div>
      </div>
      <div class="filter-row" style="margin-left: auto;">
        <div class="form-control" style="width: 200px;">
            <label for="wash-status">{{'STATUS' | translate}}</label>
            <p-multiSelect [defaultLabel]="''" [options]="filterStatusoptions" [(ngModel)]="filter.status"
                appendTo="body" selectedItemsLabel="{0} itens selecionados" name="wash-status" (onChange)="filterGlobal()">
            </p-multiSelect>
        </div>
      </div>
  </div>
</div>
<br>
<div>
    <div class="table">
        <p-table [value]="boatWashesTable" #tt [globalFilterFields]="['boatName']" [scrollable]="true" scrollHeight="500px"
            styleClass="easy-table boat-engine-wash-table" autoLayout="true">
            <ng-template pTemplate="caption">
                <td appHideMobile></td>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText size="50" placeholder="Nome da Embarcação ou Usuário" [(ngModel)]="globalFilter"
                        (input)="filterGlobal()" name="search">
                </span>
                <td appHideMobile></td>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th></th>
                    <th>Última Lavação</th>
                    <th>Orig.</th>
                    <th>Marinheiro </th>
                    <th>Nome da Embarcação</th>

                    <th>Modelo</th>
                    <th>Usuário (Lavação)</th>
                    <th>Agendamento</th>
                    <th>Orig.</th>
                    <th>Histórico</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-boatWash>
                <tr [ngClass]="{'tr-color-1': i % 2 == 0,
                                'tr-color-2': i % 2 != 0,
                                'col-danger': isOverdueTask(boatWash),
                                'col-warning': isEqualdueTask(boatWash)}">
                    <td class="text-center">
                        <p-checkbox [(ngModel)]="boatWash.boatWashSelected" binary="true" (click)="onBoatSelect()"
                            name="boatWashSelected">
                        </p-checkbox>
                    </td>
                    <td class="text-center">{{boatWash.lastWashDate | date:'dd/MM/yyyy'}}</td>
                    <td class="text-center">
                        <i *ngIf="boatWash.lastWashDate && !boatWash.isSeaLast" class="table-icon pi pi-clock"
                            aria-hidden="true"></i>
                        <img *ngIf="boatWash.lastWashDate && boatWash.isSeaLast" src="assets/images/sea.png"
                            class="table-image" />
                    </td>
                    <td class="text-center">
                        <img src="assets/images/sailor.png" alt="" *ngIf="boatWash.isSailor" class="table-image">
                    </td>
                    <td>
                        {{boatWash.boatName}}
                        <span *ngIf="boatWash.maintenance">(Em manutenção)</span>
                    </td>
                    <td>{{boatWash.boatModel.toUpperCase() | translate}}</td>
                    <td>{{boatWash.userName}}</td>
                    <td class="text-center">{{boatWash.washDate | date:'dd/MM/yyyy'}}</td>
                    <td class="text-center">
                        <i *ngIf="boatWash.washDate && !boatWash.isSea" class="table-icon pi pi-clock"></i>
                        <img *ngIf="boatWash.washDate && boatWash.isSea" src="assets/images/sea.png"
                            class="table-image" />
                    </td>
                    <td class="text-center" [ngStyle]="{'cursor': boatWash.lastWashDate ? 'pointer' : 'auto'}"
                        (click)="boatWash.lastWashDate && openWashHistoryDialog(boatWash)">
                        <fa-icon class="table-icon" [ngClass]="{'no-last-wash': !boatWash.lastWashDate }"
                            [icon]="'history'"></fa-icon>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
