import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DaySettingForQueueDto } from 'src/app/models/dtos/config/day-setting-for-queue-dto';
import { SpecialOperatingDayClosedDTO, SpecialOperatingDayDTO } from 'src/app/models/dtos/config/special-operating-day-dto';
import { SpecialOperatingDayResponseDTO } from 'src/app/models/dtos/config/special-operating-day-response-dto';
import { OperatingDay } from 'src/app/pages/configs/operating-day-config/operating-day-config.component';
import { ScheduleQueueDto } from 'src/app/pages/configs/operating-day-config/schedule-queue/schedule-queue.component';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OperatingDayConfigService {
  private readonly apiUrl = environment.apiHost + '/secure/';

  constructor(private http: HttpClient) {

  }

  getAll(): Observable<OperatingDay[]> {
    return this.http.get(this.apiUrl + 'operating-day')
        .pipe(map((response: any) => response.data.operatingDays as OperatingDay[]));
  }

  saveAll(days: OperatingDay[]): Observable<OperatingDay[]> {
    return this.http.put(this.apiUrl + 'operating-day', days)
        .pipe(map((response: any) => response.data.operatingDays as OperatingDay[]));
  }

  getAllScheduleQueueDTO(): Observable<ScheduleQueueDto[]> {
    return this.http.get(this.apiUrl + 'schedule-queue')
        .pipe(map((response: any) => response.data.scheduleQueues as ScheduleQueueDto[]));
  }

  createScheduleQueue(createDto: { name: string }): Observable<ScheduleQueueDto> {
    return this.http.post(this.apiUrl + 'schedule-queue', createDto)
        .pipe(map((response: any) => response.data.scheduleQueue as ScheduleQueueDto));
  }

  updateScheduleQueueActiveStatus(updateActiveDto: { id: number, active: boolean }): Observable<ScheduleQueueDto> {
    return this.http.put(this.apiUrl + 'schedule-queue/active', updateActiveDto)
        .pipe(map((response: any) => response.data.scheduleQueue as ScheduleQueueDto));
  }

  getAllDaySettingsForScheduleQueueDTO(scheduleQueueId: number): Observable<DaySettingForQueueDto[]> {
    return this.http.get(this.apiUrl + 'day-setting/' + scheduleQueueId.toString())
        .pipe(map((response: any) => response.data.daysSetting as DaySettingForQueueDto[]));
  }

  updateDaySettingsForScheduleQueueDTO(daySettings: DaySettingForQueueDto[]): Observable<DaySettingForQueueDto[]> {
    return this.http.put(this.apiUrl + 'day-setting', daySettings)
        .pipe(map((response: any) => response.data.daysSetting as DaySettingForQueueDto[]));
  }

  getAllSpecialOperatingDay(): Observable<SpecialOperatingDayResponseDTO[]> {
    return this.http.get(this.apiUrl + 'special-operating-day')
        .pipe(map((response: any) => response.data.specialOperatingDays as SpecialOperatingDayResponseDTO[]));
  }

  getSpecialOperatingDayClosedById(specialOperatingDayId: number): Observable<SpecialOperatingDayClosedDTO> {
    return this.getSpecialOperatingDayById(specialOperatingDayId)
      .pipe(map(({ id, date, observation }: SpecialOperatingDayDTO) => ({ id, date, observation})));
  }

  getSpecialOperatingDayById(specialOperatingDayId: number): Observable<SpecialOperatingDayDTO> {
    return this.http.get(this.apiUrl + 'special-operating-day/' + specialOperatingDayId.toString())
        .pipe(map((response: any) => response.data.specialOperatingDay as SpecialOperatingDayDTO));
  }

  saveSpecialOperatingDayClosed(specialOperatingDayClosed: SpecialOperatingDayClosedDTO): Observable<SpecialOperatingDayClosedDTO> {
    const specialOperatingDay: SpecialOperatingDayDTO = {
      id: specialOperatingDayClosed?.id,
      inOperation: false,
      changeOperationDay: false,
      date: specialOperatingDayClosed.date,
      observation: specialOperatingDayClosed.observation,
      specialDayForQueues: []
    }
    return this.saveSpecialOperatingDay(specialOperatingDay)
      .pipe(map(({ id, date, observation }: SpecialOperatingDayDTO) => ({ id, date, observation})));
  }

  saveSpecialOperatingDay(specialOperatingDay: SpecialOperatingDayDTO): Observable<SpecialOperatingDayDTO> {
    return this.http.put(this.apiUrl + 'special-operating-day', specialOperatingDay)
        .pipe(map((response: any) => response.data.specialOperatingDay as SpecialOperatingDayDTO));
  }
}
