import { environment } from 'src/environments/environment';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DialogService } from 'primeng/dynamicdialog';
import { CallUser } from 'src/app/models/integracaochamados/call-user';
import { SystemcallAuxiliarTablesService } from 'src/app/services/systemcall-auxiliar-tables.service';
import { TicketOpenComponent } from './ticket-open/ticket-open.component';
import { StorageUtil } from 'src/app/utils/storage.util';

@Component({
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
})
export class TicketComponent implements OnInit {
  path: any;
  marinaData: {contactEmail: string};
  urlMarina: SafeResourceUrl = '';
  logoMarina: string | null = null;
  logoMarinaUrl: string = '';

  constructor(
    private dialog: DialogService,
    private sanitizer: DomSanitizer,
    private systemCallAuxService: SystemcallAuxiliarTablesService
  ) {}

  slingConfig: any;

  ngOnInit(): void {
    this.getUrlChamados();
  }

  openTicket(): void {
    window.open('https://easymarine.movidesk.com/Account/Login?ReturnUrl=%2f', '_blank');
    // this.dialog
    //   .open(TicketOpenComponent, {
    //     height: '85vh',
    //     width: '25vw',
    //     dismissableMask: false,
    //     style: { right: '30px', top: '30px', position: 'fixed' },
    //     data: {},
    //     header: 'Criar chamado',
    //   })
    //   .onClose.subscribe(() => {});
  }

  getUrlChamados(){
    this.marinaData = StorageUtil.getMarina();
    this.logoMarina = StorageUtil.getLogoMarina();
    // console.log('logo: ', this.logoMarina);

    if (this.logoMarina === null) {
      this.logoMarinaUrl = 'notHaveLogo';
    } else {
      this.logoMarinaUrl = this.logoMarina;
    }
    const dynamicUrl = `https://tickets.easymarine.com.br/${encodeURIComponent(this.marinaData.contactEmail)}/web2?logoMarina=${encodeURIComponent(this.logoMarinaUrl)}`;

    // passe o logo como parametro
    // const dynamicUrl = `http://localhost:51097/web2/?logoMarina=${encodeURIComponent(this.logoMarinaUrl)}&email=${encodeURIComponent(this.marinaData.contactEmail)}`;

    this.urlMarina = this.sanitizer.bypassSecurityTrustResourceUrl(dynamicUrl);
    // this.systemCallAuxService.getCallUser().subscribe({
    //   next: (callUser: CallUser) => {
    //     this.path = this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.chamadosApi}/chamados?token=${callUser.access_token}`);
    //   },
    //   error: (err) => console.log(err),
    // });

  }
}
