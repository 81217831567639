import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { RoleLogService } from 'src/app/services/role-log.service';
import { RoleService } from 'src/app/services/role.service';
import { StorageUtil } from 'src/app/utils/storage.util';

@Component({
  selector: 'app-role-log',
  templateUrl: './role-log.component.html',
  styleUrls: ['./role-log.component.scss']
})
export class RoleLogComponent implements OnInit {



  roleLogs = [];
  filteredRoles = [];
  //productsFilter = [];

  filter = { marinaId: 0, dateStart: new Date(), dateEnd: new Date(), userId: null, targetId: null };

  pt = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  };

  constructor(
        private spinner: NgxSpinnerService,
        private roleService: RoleService,
        private roleLogService: RoleLogService,
  ) { }

  async ngOnInit(): Promise<void> {
    const marina = StorageUtil.getMarina();
    this.filter.marinaId = marina.id;
    this.loadUsers();

    this.spinner.show();

    await this.find();
    this.spinner.hide();
  }

  async find(): Promise<void> {
    return new Promise<void>(async resolve => {
      this.roleLogService.findRoles(this.filter).subscribe(
        async (data) => {
          this.roleLogs = data;
          resolve();
        },
        async (error) => {
          console.error(error);
          resolve();
        }
      );
    });
  }

  private loadUsers(): void {
    this.roleService.getRolesEditablesByHierarchy().subscribe(
      (data) => {
        this.filteredRoles = data.map((c) => ({ label: c.name, value: c.id }));
        this.filteredRoles.unshift({ label: 'Todos', value: null });
      }
    );
  }

}
