import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-log-address',
  templateUrl: './customer-log-address.component.html',
  styleUrls: ['./customer-log-address.component.scss']
})
export class CustomerLogAddressComponent implements OnInit {

  @Input() addressLog;
  @Input() addressLogOldData;
  @Input() isNew;
  @Input() title;

  oldDatasMap

  constructor() { }

  ngOnInit(): void {
    this.oldDatasMap = new Map(Object.entries(this.addressLogOldData));
  }

  isChangedZip(): boolean{
    return this.oldDatasMap.has('zip') && this.addressLog.zip != this.addressLogOldData.zip;
  }

  isChangedCountry(): boolean{
    return this.oldDatasMap.has('country') && this.addressLog.country != this.addressLogOldData.country;
  }

  isChangedState(): boolean{
    return this.oldDatasMap.has('state') && this.addressLog.state != this.addressLogOldData.state;
  }

  isChangedCity(): boolean{
    return this.oldDatasMap.has('city') && this.addressLog.city != this.addressLogOldData.city;
  }

  isChangedStreet(): boolean{
    return this.oldDatasMap.has('street') && this.addressLog.street != this.addressLogOldData.street;
  }

  isChangedDistrict(): boolean{
    return this.oldDatasMap.has('district') && this.addressLog.district != this.addressLogOldData.district;
  }

  isChangedNumber(): boolean{
    return this.oldDatasMap.has('number') && this.addressLog.number != this.addressLogOldData.number;
  }

  isChangedComplement(): boolean{
    return this.oldDatasMap.has('complement') && this.addressLog.complement != this.addressLogOldData.complement;
  }

}
