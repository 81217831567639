<div class="dialog-form">
  <p-table [value]="tuitionToInvoiceList" [paginator]="false" autoLayout="true">
    <ng-template pTemplate="header">
      <tr>
        <th class="text-center">Referencia da Mensalidade</th>
        <th class="text-center">Valor</th>
        <th class="text-center">Data de Vencimento</th>
        <th class="text-center">Data de Competencia</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-invoice>
      <tr>
        <td class="text-center">{{invoice.referenceDate | date:'MM/yy'}}</td>
        <td class="text-center">R${{invoice.tuition.marinaOrder.value  | number:'1.2-2'}}</td>
        <td class="text-center w-3" >
          <p-calendar
            dateFormat="dd/mm/yy"
            name="birthday"
            [monthNavigator]="true"
            [yearNavigator]="true"
            [yearRange]="'1950:2055'"
            rPCalendarMask
            appendTo="body"
            [(ngModel)]="invoice.dueDate"
            [showIcon]="true"
            i18n
          >
          </p-calendar>
        </td>
        <td class="text-center w-3" >
          <p-calendar
            dateFormat="dd/mm/yy"
            name="birthday"
            [monthNavigator]="true"
            [yearNavigator]="true"
            [yearRange]="'1950:2055'"
            rPCalendarMask
            appendTo="body"
            [(ngModel)]="invoice.competenceDate"
            [showIcon]="true"
            i18n
          >
          </p-calendar>
         </td>
      </tr>
    </ng-template>
  </p-table>
  <div class="buttons py-5">
    <button type="button" (click)="cancel()"
            class="easymarine danger">Cancelar
    </button>
    <button type="button" (click)="submit()"
            class="primary easymarine ">Gerar
    </button>
  </div>
</div>
