<div class="table container-fluid">
    <p-table [value]="documents" class="primeTable" [paginator]="true" rows=10 autoLayout="true"
        [alwaysShowPaginator]="false"  [pageLinks]="3" [lazy]="true"
        [totalRecords]="documents.length">
        <ng-template pTemplate="header">
            <tr>
                <th [pSortableColumn]="'nameDocument'">
                    {{ 'NAME' | translate }}
                </th>
                <th [pSortableColumn]="'boatName'">
                    {{ 'BOAT' | translate }}
                </th>
                <th [pSortableColumn]="'serviceName'">
                    {{ 'SERVICE' | translate }}
                </th>
                <th [pSortableColumn]="'status'">
                    {{ 'STATUS' | translate }}
                </th>
                <th pSortableColumn="documentType">
                    {{ 'DOCUMENT_TYPE' | translate }}
                    <p-sortIcon field="type"></p-sortIcon>
                </th>
                <th pSortableColumn="documentType">
                    {{ 'CONTRACT_TYPE' | translate }}
                    <p-sortIcon field="contractType"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'createdAt'">
                    Data de criação
                </th>
                <th>
                    {{ 'UNDER-REVIEW' | translate }}
                </th>
                <th colspan="5">Opções</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-document>
            <tr>
                <td [pTooltip]="getEmails(document)" tooltipPosition="bottom">{{ document.nameDocument }}</td>
                <td>{{ document.boatName }}</td>
                <td>{{ document.serviceName }}</td>
                <td>
                    <div style="display: flex;">
                        <div [ngClass]="{
                        'document-success': document.status === 'SIGNED',
                        'document-danger': document.status === 'CANCELED' || document.status === 'ERROR',
                        'document-warning': document.status === 'WAITING_SIGNATORIES' || document.status === 'WAITING_SIGNATURES',
                        'document-processing': document.status === 'PROCESSING_SIGNATURES' || document.status === 'PROCESSING_FILE'}">
                            <span>
                                {{ document.status | translate}}
                            </span>
                        </div>
                    </div>
                </td>
                <td>
                    <span> {{ document.documentType | translate}}</span>
                </td>
                <td>
                    <span> {{ document.contractType | translate}}</span>
                </td>
                <td>{{ document.createdAt | date: 'dd/MM/yyyy'}}</td>
                <td>
                    <a href="javascript:;" [pTooltip]="document.affected ? 'Retirar de análise' :'Colocar em análise'"
                        *ngIf="document.status === 'SIGNED'" class="badge badge-pill"
                        [ngClass]="{'success': !document.affected, 'danger': document.affected}"
                        tooltipPosition="bottom" (click)="updateAffected(document)">
                        {{ (document.affected ? 'YES' : 'NO') | translate }}
                    </a>
                </td>
                <td class="text-center">
                    <i class="table-action" pTooltip="Visualizar documento"
                        (click)="document.status != 'CANCELED' && downloadDocument(document)"
                        *ngIf="document.status !== 'CANCELED' && document.status !== 'ERROR'" tooltipPosition="bottom">
                        <i class="pi pi-eye"></i>
                    </i>
                </td>
                <td class="text-center">
                    <i class="table-action" pTooltip="Cancelar documento" tooltipPosition="bottom"
                        (click)="document.status != 'CANCELED' && cancelDocument(document)"
                        *ngIf="document.status !== 'CANCELED' && document.status !== 'ERROR' && document.status !== 'SIGNED'">
                        <i class="pi pi-times"></i>
                    </i>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
