import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaginationFilter } from '../models/pagination-filter';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ServiceBoatWithoutContractFilter } from '../models/dtos/service-boat-without-contract-filter';

@Injectable()
export class ContractDocumentService {

    private d4SignUrl = environment.apiHost + '/secure/contract';

    constructor(
        private http: HttpClient
    ) { }

    getDocuments(filter, paginationFilter: PaginationFilter): Observable<any> {
        let params = new HttpParams();
        if (!paginationFilter.sort || !paginationFilter.order) {
            paginationFilter.sort = 'createdAt';
            paginationFilter.order = 'DESC';
        }
        params = params.set('sort', paginationFilter.sort.concat(',').concat(paginationFilter.order));

        if (!paginationFilter.size) {
            paginationFilter.size = 0;
        }
        params = params.set('page', paginationFilter.page.toString());
        params = params.set('size', paginationFilter.size.toString());
        return this.http.post(this.d4SignUrl + '/document-list-on-bd', filter, { params })
            .pipe(map((response: any) => response as any));
    }

    getServiceBoatsContract(filter, paginationFilter: PaginationFilter): Observable<any> {
        let params = new HttpParams();
        if (!paginationFilter.sort || !paginationFilter.order) {
            paginationFilter.sort = 'boatName';
            paginationFilter.order = 'ASC';
        }
        params = params.set('sort', paginationFilter.sort.concat(',').concat(paginationFilter.order));

        if (!paginationFilter.size) {
            paginationFilter.size = 0;
        }
        params = params.set('page', paginationFilter.page.toString());
        params = params.set('size', paginationFilter.size.toString());
        return this.http.post(this.d4SignUrl + '/service-boat-list-on-bd', filter, { params })
            .pipe(map((response: any) => response as any));
    }

    sendDocumentForSignature(documentId: number): Observable<void> {
        return this.http.patch(`${this.d4SignUrl}/send-document-for-signature/${documentId}`, {})
            .pipe(map((response: any) => response.data as any));
    }

    reSendDocumentForSignature(documentId: number): Observable<void> {
      return this.http.put(`${this.d4SignUrl}/re-send-documents-to-signature/${documentId}`, {})
          .pipe(map((response: any) => response.data as any));
  }

    sendDocumentWithFile(file, serviceBoatId: number[], contractType: string): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('serviceBoat', serviceBoatId.toString());
        formData.append('contractType',contractType);

        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'multipart/form-data');

        return this.http.post(this.d4SignUrl + '/send-document-from-file', formData, { headers })
            .pipe(map((response: any) => response.data.documentUuid));
    }

    sendDocumentFinish(file, serviceBoatId: number[], contractType: string): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('serviceBoat', serviceBoatId.toString());
        formData.append('contractType', contractType);

        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'multipart/form-data');

        return this.http.post(this.d4SignUrl + '/send-document-finish-from-file', formData, { headers })
            .pipe(map((response: any) => response.data.documentUuid));
    }


    createBatche(uuidsDocument: string[]): Observable<string> {
        return this.http.post(this.d4SignUrl + '/batches-document', uuidsDocument)
            .pipe(map((response: any) => response.data.batchKey as string));
    }

    downloadDocument(documentId: number): Observable<string> {
        return this.http.get(`${this.d4SignUrl}/download-document/${documentId}`, {})
            .pipe(map((response: any) => response.data.url as string));
    }

    cancelDocument(documentId: number): Observable<void> {
        return this.http.post(`${this.d4SignUrl}/cancel-document/${documentId}`, {})
            .pipe(map((response: any) => response.data as any));
    }

    updateStatusAffected(documentId: number, status: boolean): Observable<void> {
        return this.http.put(this.d4SignUrl + `/update-status/${documentId}`, status)
            .pipe(map((response: any) => response));
    }

    getWithoutContracts( filter: ServiceBoatWithoutContractFilter, paginationFilter: PaginationFilter): Observable<any> {
        let params = new HttpParams();
        if (!paginationFilter.sort || !paginationFilter.order) {
            paginationFilter.sort = 'contractStartDate';
            paginationFilter.order = 'DESC';
        }
        params = params.set('sort', paginationFilter.sort.concat(',').concat(paginationFilter.order));

        if (!paginationFilter.size) {
            paginationFilter.size = 0;
        }
        params = params.set('page', paginationFilter.page.toString());
        params = params.set('size', paginationFilter.size.toString());
        return this.http.post(this.d4SignUrl + '/without-contract', filter, { params })
            .pipe(map((response: any) => response as any));
    }

    getNumberContractsInAnalysis(): Observable<number> {
        return this.http.get(this.d4SignUrl + '/in-analysis')
            .pipe(map((response: any) => response.data.count as number));
    }
}
