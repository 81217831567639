import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { environment } from "src/environments/environment";
import { PrintType } from "../models/enums/print-type";

@Injectable()
export class PrintingService {

    constructor(private http: HttpClient) { }

    generatePdfById(id: number, type: PrintType): Observable<any> {
        return this.http.get(environment.apiHost + '/secure/printing/pdf/' + type + "/" + id.toString(), { responseType: 'blob' })
            .pipe(map((response: any) => response));
    }
}