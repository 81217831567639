<p-fieldset>
  <p-header class="article-title">
    <div class="article-title">Informações</div>
  </p-header>
  <div class="content-log">
    <div style="border-radius: 5px;" [ngClass]="{'item-new-log': isChangedFederalIdType() && isNew, 'item-old-log': isChangedFederalIdType() && !isNew}">
      <p>
        <i class="pi" [ngClass]="isFederalIdType('PHYSICAL')?'pi-circle-on':'pi-circle-off'"></i>
        {{'FEDERAL-ID.PHYSICAL' | translate}}
      </p>
      <p>
        <i class="pi" [ngClass]="isFederalIdType('JURIDICAL')?'pi-circle-on':'pi-circle-off'"></i>
        {{'FEDERAL-ID.JURIDICAL' | translate}}
      </p>
    </div>
    <div style="border-radius: 5px;" [ngClass]="{'item-new-log': isChangedOnlyAssociated() && isNew, 'item-old-log': isChangedOnlyAssociated() && !isNew}">
      <p>
        <i class="pi" [ngClass]="isOnlyAssociated()?'pi-circle-on':'pi-circle-off'"></i>
        Autorizado
      </p>
      <p>
        <i class="pi" [ngClass]="isOnlyAssociated()?'pi-circle-off':'pi-circle-on'"></i>
        Proprietário
      </p>
    </div>
    <div>
      <label>{{ "NAME" | translate }}</label>
      <app-log-field [old]="customerLog.oldDatas.name" [field]="customerLog.name" [existsOld]="oldDatasMap.has('name')" [isNew]="isNew"></app-log-field>
    </div>
    <div>
      <label>{{'FEDERAL-ID.GENERAL' | translate}}</label>
      <app-log-field [old]="customerLog.oldDatas.federalId" [field]="customerLog.federalId" [existsOld]="oldDatasMap.has('federalId')" [isNew]="isNew"></app-log-field>
    </div>
    <div style="border-radius: 5px;" [ngClass]="{'item-new-log': isChangedGender() && isNew, 'item-old-log': isChangedGender() && !isNew}">
      <p >
        <i class="pi" [ngClass]="isGender('M')?'pi-circle-on':'pi-circle-off'"></i>
        {{'MALE' | translate}}
      </p>
      <p>
        <i class="pi" [ngClass]="isGender('F')?'pi-circle-on':'pi-circle-off'"></i>
        {{'FEMALE' | translate}}
      </p>
    </div>
    <div>
      <label>{{ "PHYSICAL-REGISTRATION" | translate }}</label>
      <app-log-field [old]="customerLog.oldDatas.registration" [field]="customerLog.registration" [existsOld]="oldDatasMap.has('registration')" [isNew]="isNew"></app-log-field>
    </div>
    <div>
      <label>E-mail pessoal (Login app)</label>
      <app-log-field [old]="customerLog.oldDatas.email" [field]="customerLog.email" [existsOld]="oldDatasMap.has('email')" [isNew]="isNew"></app-log-field>
    </div>
    <div>
      <label>{{ "CELLPHONE" | translate }} 1</label>
      <app-log-field [old]="customerLog.oldDatas.phone" [field]="customerLog.phone" [existsOld]="oldDatasMap.has('phone')" [isNew]="isNew"></app-log-field>
    </div>
    <div>
      <label>{{ "CELLPHONE" | translate }} 2</label>
      <app-log-field [old]="customerLog.oldDatas.phone2" [field]="customerLog.phone2" [existsOld]="oldDatasMap.has('phone2')" [isNew]="isNew"></app-log-field>
    </div>
    <div>
      <label>{{"RESIDENTIAL-PHONE" | translate }}</label>
      <app-log-field [old]="customerLog.oldDatas.residentialPhone" [field]="customerLog.residentialPhone" [existsOld]="oldDatasMap.has('residentialPhone')" [isNew]="isNew"></app-log-field>
    </div>
    <div>
      <label>{{ "COMMERCIAL-PHONE" | translate }}</label>
      <app-log-field [old]="customerLog.oldDatas.commercialPhone" [field]="customerLog.commercialPhone" [existsOld]="oldDatasMap.has('commercialPhone')" [isNew]="isNew"></app-log-field>
    </div>
    <div>
      <label>{{ "DATE-BIRTH" | translate }}</label>
      <app-log-field [isDate]="true" [old]="customerLog.oldDatas.birthday" [field]="customerLog.birthday" [existsOld]="oldDatasMap.has('birthday')" [isNew]="isNew"></app-log-field>
    </div>
    <div>
      <label>E-mail de cobrança</label>
      <app-log-field [old]="preparEmailCollection(customerLog.oldDatas.emailCollection)" [field]="preparEmailCollection(customerLog.emailCollection)" [existsOld]="oldDatasMap.has('emailCollection')" [isNew]="isNew"></app-log-field>
    </div>
    <div>
      <label>Início Faturamento</label>
      <app-log-field [isDate]="true" [old]="customerLog.oldDatas.billingDay" [field]="customerLog.billingDay" [existsOld]="oldDatasMap.has('billingDay')" [isNew]="isNew"></app-log-field>
    </div>
    <div>
      <label>Dia do vencimento</label>
      <app-log-field [old]="customerLog.oldDatas.dueDate" [field]="customerLog.dueDate" [existsOld]="oldDatasMap.has('dueDate')" [isNew]="isNew"></app-log-field>
    </div>
    <div>
      <label>{{ "Grupo" | translate }}</label>
      <label [ngClass]="{'item-new-log': isChangedCustomerGroup() && isNew, 'item-old-log': isChangedCustomerGroup() && !isNew}">
        {{ isChangedCustomerGroup() && !isNew ? customerLog.oldDatas.customerGroupLog?.name: customerLog.customerGroupLog?.name }}
      </label>
    </div>
    <div>
      <label>{{ "STATUS" | translate }}</label>
      <label [ngClass]="{'item-new-log': isChangedStatus() && isNew, 'item-old-log': isChangedStatus() && !isNew}">
        {{ (getStatus()? 'ACTIVE':'INACTIVE') | translate}}
      </label>
    </div>
    <div>
      <label>{{ "MARITAL STATUS" | translate }}</label>
      <label [ngClass]="{'item-new-log': isChangedMaritalStatus() && isNew, 'item-old-log': isChangedMaritalStatus() && !isNew}">
        {{ isChangedMaritalStatus() && !isNew ? customerLog.oldDatas?.maritalStatus: customerLog?.maritalStatus }}
      </label>
    </div>
    <div>
      <label>{{ "SPOUSE" | translate }}</label>
      <label [ngClass]="{'item-new-log': isChangedSpouse() && isNew, 'item-old-log': isChangedSpouse() && !isNew}">
        {{ isChangedSpouse() && !isNew ? customerLog.oldDatas?.spouse: customerLog?.spouse }}
      </label>
    </div>
  </div>
</p-fieldset>
<br />
