import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EasyMarineHttpApiLog } from './easy-marine-http-api-log';

@Injectable({
  providedIn: 'root'
})
export class RoleLogService {

  constructor(private httpClient: EasyMarineHttpApiLog) {
  }

  findRoles(filter: any): Observable<any> {
      let params = new HttpParams();
      if (filter.dateStart) {
          params = params.append('dateStart', moment(filter.dateStart).format());
      }
      if (filter.dateEnd) {
          params = params.append('dateEnd', moment(filter.dateEnd).format());
      }
      if (filter.targetId) {
          params = params.append('targetId', filter.targetId);
      }
      return this.httpClient.get(environment.apiLog + `/role-log/${filter.marinaId}`, { params })
          .pipe(map((response: any) => response));
  }
}
