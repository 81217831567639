<p-fieldset>
    <p-header class="article-title">
        <div class="article-title">{{'GENERAL-DATA' | translate}}</div>
    </p-header>
    <div class="content-log">
        <div>
            <label>{{ 'NAME' | translate }}</label>
            <app-log-field [old]="contractLog.oldDatas.name" [field]="contractLog.name" [existsOld]="oldDatasMap.has('name')" [isNew]="isNew"></app-log-field>
        </div>
        <div>
            <label>{{ 'DOCUMENT_TYPE' | translate }}</label>
            <app-log-field [translate]="true" [old]="contractLog.oldDatas.documentType" [field]="contractLog.documentType" [existsOld]="oldDatasMap.has('documentType')" [isNew]="isNew"></app-log-field>
        </div>
        <div>
            <label>{{ 'CONTRACT_TYPE' | translate }}</label>
            <app-log-field [translate]="true" [old]="contractLog.oldDatas.contractType" [field]="contractLog.contractType" [existsOld]="oldDatasMap.has('contractType')" [isNew]="isNew"></app-log-field>
        </div>
        <div>
            <label>Inicio do serviço</label>
            <app-log-field [isDate]="true" [old]="contractLog.oldDatas.contractStartDate" [field]="contractLog.contractStartDate" [existsOld]="oldDatasMap.has('contractStartDate')" [isNew]="isNew"></app-log-field>
        </div>
        <div>
            <label>Termino do serviço</label>
            <app-log-field [isDate]="true" [old]="contractLog.oldDatas.contractEndDate" [field]="contractLog.contractEndDate" [existsOld]="oldDatasMap.has('contractEndDate')" [isNew]="isNew"></app-log-field>
        </div>
        <div>
            <label>{{ 'STATUS' | translate }}</label>
            <app-log-field [translate]="true" [old]="contractLog.oldDatas.status" [field]="contractLog.status" [existsOld]="oldDatasMap.has('status')" [isNew]="isNew"></app-log-field>
        </div>
        <div>
            <label>{{ 'UNDER-REVIEW' | translate }}</label>
            <label [ngClass]="{'item-new-log': isChangedAffected() && isNew, 'item-old-log': isChangedAffected() && !isNew}">
                {{ (getAffected()? 'YES' :'NO') | translate}}
            </label>
        </div>
        <div>
            <label>{{ 'FILE' | translate }}</label>
            <button class="easymarine button-log" [ngClass]="{'primary': !isChangedFileUrl(),'success': isChangedFileUrl() && isNew, 'danger': isChangedFileUrl() && !isNew}" 
                    *ngIf="contractLog.url" (click)="openUrl(contractLog)" [disabled]="!hasFileUrl()">
                <i class="pi pi-file-o"> Contrato </i>
            </button>
        </div>
        
    </div>
</p-fieldset>
<br>