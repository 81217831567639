import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { MovementStatus } from 'src/app/models/enums/movements/movement-status';
import { BoatMovementDTO, MovementDTO } from 'src/app/models/dtos/movements/boat-movement-dto';

@Directive({
  selector: '[appBoatMovementRow]'
})
export class BoatMovementRowDirective implements OnInit {
  @Input('boatMovementRow')
  boatMovementRow: BoatMovementDTO


  constructor(private elRef: ElementRef, private renderer: Renderer2) {}
  ngOnInit(): void {
    this.getCssClass(this.boatMovementRow).forEach((clazz: string) => this.renderer.addClass(this.elRef.nativeElement, clazz));
  }

  getCssClass(boatMovement: BoatMovementDTO): string[] {
    const classes: string[] = [];
    if (!boatMovement.movement) {
      return classes;
    }
    this.getCssMovementStatus(boatMovement.movement).forEach((c: string) => {
      classes.push(c);
    })
    return classes;
  }

  private getCssMovementStatus(movement: MovementDTO): string[] {
    const movementStatus = movement.movementStatus;
    const movementQuantity = movement.sequence;
    const classes: string[] = [];
    if (MovementStatus.PREPARED === movementStatus) {
      classes.push('movement-prepared');
    } else if (MovementStatus.STARTED === movementStatus) {
      classes.push('movement-started');
    } else if (movementQuantity === 0) {
      classes.push('movement-few-slings');
    }

    return classes;
  }

}
