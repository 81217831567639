<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{'TITLE-VACANCIES' | translate}}</span>
        <div class="primary fab-button" (click)="openVacancyFormDialog(null)">
            <i class="pi pi-plus" style="font-size: 21px;"></i>
        </div>
    </p-header>

    <div class="table">
        <p-table #tt [value]="vacancies" [paginator]="true" [rows]="numberOfRows" resetPageOnSort="true" [pageLinks]="3"
            [rowsPerPageOptions]="[10,20,30,50,100]" [resizableColumns]="true" [autoLayout]="true"
            [globalFilterFields]="['code','length']" styleClass="easy-table">
            <ng-template pTemplate="caption">
                <div class="fill-empty"></div>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText size="50" placeholder="Filtro global"
                        (input)="tt.filterGlobal($event.target.value, 'contains')" style="width:auto">
                </span>
                <span style="font-weight: bold; float: right; margin-top: 5px;">{{ 'TOTAL-REGISTERS' | translate }}:
                    {{tt.totalRecords}} {{'OF' | translate}} {{vacancies.length}}
                </span>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th [pSortableColumn]="'code'">
                        {{ 'CODE' | translate }}
                        <p-sortIcon [field]="'code'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'length'">
                        {{ 'LENGTH' | translate }}
                        <p-sortIcon [field]="'length'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'movementLocation.name'">
                      {{ 'LOCAL' | translate }}
                      <p-sortIcon [field]="'movementLocation.name'"></p-sortIcon>
                    </th>
                    <th>Embarcações</th>
                    <th>Seta</th>
                    <th class="text-center" width="6%" [pSortableColumn]="'active'">
                      {{ 'STATUS' | translate }}
                      <p-sortIcon [field]="'active'"></p-sortIcon>
                    </th>
                    <th></th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-vacancy>
                <tr style="max-height: 30px; height: 30px; min-height: 30px;">
                    <td>{{vacancy.code}}</td>
                    <td>{{vacancy.length}}</td>
                    <td>{{vacancy?.movementLocation?.name}} </td>
                    <td *ngIf="vacancy?.boats == null || vacancy?.boats.length <= 1" >{{vacancy?.boatsName}}</td>
                    <td *ngIf="vacancy?.boats != null && vacancy?.boats.length > 1" [pTooltip]="vacancy?.boatsName">Diversos</td>
                    <td>
                        <span *ngIf="vacancy.arrowDirection === 'UP'">
                            <img src="https://s3-sa-east-1.amazonaws.com/prod.em-files/boat/documentation/b05c99ae-1fca-46e5-b110-1f4762e7a67f_EASY_arrow_up.png"
                                alt="" style="height: 23px; width: auto;">
                        </span>
                        <span *ngIf="vacancy.arrowDirection === 'DOWN'">
                            <img src="https://s3-sa-east-1.amazonaws.com/prod.em-files/boat/documentation/51634ad1-eec3-4608-9513-aefccdd9161e_EASY_arrow_down.png"
                                alt="" style="height: 23px; width: auto;">
                        </span>
                    </td>

                    <td class="text-center">
                      <a href="javascript:;" [pTooltip]="vacancy.active ? 'Desativar' : 'Ativar'"
                          class="badge badge-pill" [ngClass]="{'success':vacancy.active, 'danger': !vacancy.active}"
                          tooltipPosition="top" (click)="updateStatus(vacancy)">
                          {{ vacancy.active ? 'Ativo' : 'Inativo' }}
                      </a>
                    </td>

                    <td class="text-center">
                        <div class="table-action" (click)="openVacancyFormDialog(vacancy.id)"
                            pTooltip="Editar {{vacancy.code}}" tooltipPosition="bottom">
                            <i class="pi pi-pencil"></i>
                        </div>
                    </td>
                    <td class="text-center">
                        <div class="table-action" (click)="openVacancyDeleteConfirmDialog(vacancy.id)"
                            pTooltip="Remover {{vacancy.code}}" tooltipPosition="bottom">
                            <i class="pi pi-trash"></i>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-fieldset>
