import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Sling } from 'src/app/models';
import { ProductCategoryType } from 'src/app/models/product-category-type';
import { SlingService } from 'src/app/services/sling.service';
import { ImageUtil } from 'src/app/utils/image.util';
import { SlingProductFormComponent } from '../sling-product-form/sling-product-form.component';

@Component({
  selector: 'app-sling-consumable-check',
  templateUrl: './sling-consumable-check.component.html',
  styleUrls: ['./sling-consumable-check.component.scss']
})
export class SlingConsumableCheckComponent implements OnInit {

  sling: Sling;
  referenceTime: any;
  hourInMilliseconds: number = 1000 * 3600 * 1;

  constructor(
    public dialogRef: DynamicDialogRef,
    public imageUtil: ImageUtil,
    private slingService: SlingService,
    private config: DynamicDialogConfig
  ) {
    this.referenceTime = new Date(Date.now() + this.hourInMilliseconds);
  }

  async ngOnInit(): Promise<void> {
    this.sling = await this.findSlingById(this.config.data.slingId);
    this.sling.marinaOrder.orderItems = this.sling.marinaOrder.orderItems.filter(
      (x) => x.product.productCategory.productCategoryType !== ProductCategoryType.Fuel
    );
    this.referenceTime = this.config.data.referenceTime;
  }

  save(): void {
    this.dialogRef.close(this.sling);
  }

  async findSlingById(id: number): Promise<Sling> {
    return new Promise<Sling>(
      async (resolve) => {
        this.slingService.getById(id).subscribe(
          async (sling: Sling) => {
            resolve(sling);
          },
          async err => {
            resolve(null);
          }
        );
      }
    );
  }
}
