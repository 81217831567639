<div class="dialog-form">
    <form [formGroup]="formGroup" style="margin-bottom: 20px;">
        <div class="form-group">

            <div class="form-group">
                <input type="text" pInputText  formControlName="destination" id="destination" name="destination" placeholder="Destino"
                    class="input-destination">
                    <p-dropdown placeholder="Favoritos" [filter]="true" [options]="frequentFestinations"
                        styleClass="destinations-dropdown" name="frequentFestinations" emptyFilterMessage=""
                        dropdownIcon="pi pi-star" id="dropdown-icon-fav" appendTo="body"  (onChange)="addFrequentDestinations($event)">
                    </p-dropdown>
                    <button type="button" pButton label="Adicionar" class="primary button-add" (click)="addDestinations()" [disabled]="!hasTextDestination()"></button>
            </div>
        </div>
    </form>
    <div class="table">
        <p-table #tt [value]="destinations" [resizableColumns]="true" [autoLayout]="true" [alwaysShowPaginator]="false"
        [paginator]="true" [rows]="5" [pageLinks]="3" [totalRecords]="destinations.length">
            <ng-template pTemplate="header">
                <tr>
                    <th>Destino</th>
                    <th style="display: flex; flex-direction: row-reverse;">
                        <span (click)="cleanDestinatios()" style="color: red; cursor: pointer;">{{ 'CLEAR' | translate | lowercase }}</span>
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-destination>
                <tr>
                    <td>{{destination}}</td>
                    <td>
                        <div pTooltip="Remover" (click)="delete(destination)" style="display: flex; flex-direction: row-reverse;">
                            <i class="pi pi-trash" style="color: #EF5350;"></i>
                        </div>
                    </td>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="2">
                        Nenhum destino inserido
                    </td>
                </tr>
            </ng-template>

        </p-table>
    </div>

    <div class="buttons">
        <button class="easymarine danger" (click)="close()">
            {{ 'CANCEL' | translate }}
        </button>
        <button class="easymarine primary" (click)="save()">
            {{ 'SAVE' | translate }}
        </button>
    </div>
</div>
