import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {BoatService} from '../../../../services/boat.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastService} from '../../../../services/toast.service';

@Component({
  selector: 'app-block-boat-dialog',
  templateUrl: './block-boat-dialog.component.html',
  styleUrls: ['./block-boat-dialog.component.scss']
})
export class BlockBoatDialogComponent implements OnInit {
  boats: any[] = [];
  customerId: number;

  constructor(private config: DynamicDialogConfig,
              private boatService: BoatService,
              private toasty: ToastService,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.boats = this.config.data.boats;
    this.customerId = this.config.data.customerId;
  }

  updateBoatBlock(boatId: number, blocked: boolean): void {
    this.spinner.show();
    this.boatService.blockBoat(this.customerId, blocked, boatId).subscribe({
      next: (resp) => {
        if (blocked) {
          this.toasty.success('Barco bloqueado com sucesso');
        } else {
          this.toasty.success('Barco desbloqueado com sucesso');
        }
        this.spinner.hide();
        this.updateBoats(this.customerId);
      }, error: (err) => console.log(err)
    });
  }

  updateBoats(idCostumer: number): void {
    this.boatService.getBoatsByIdCustomer(idCostumer).subscribe({
      next: (resp) => {
        this.boats = resp;
      }, error: (err) => {
        console.log(err);
      }
    });
  }
}
