import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Output } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Customer } from 'src/app/models/customer';
import { FinancialMultipleCompaniesType } from 'src/app/models/enums/finances/financial-multiple-companies-type';
import { Conta } from 'src/app/models/finance/conta';
import { FormaPagamentoPorConta } from 'src/app/models/finance/forma-pagamento-por-conta';
import { TipoFormaPagamento } from 'src/app/models/finance/tipo-forma-pagamento';
import { MarinaCompany } from 'src/app/models/marina-company';
import { NixIntegrationConfig } from 'src/app/models/nix-integration-config.model';
import { NixIntegrationConfigService } from 'src/app/services/easy_bank/nix-integration-config.service';
import { FinancesService } from 'src/app/services/finances.service';
import { ToastService } from 'src/app/services/toast.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { SalesStatesService } from '../split-bill/sales-states.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-payment-form-select',
  templateUrl: './payment-form-select.component.html',
  styleUrls: ['./payment-form-select.component.scss']
})
export class PaymentFormSelectComponent {

  @Output()
  onPaymentSelected:EventEmitter<any> = new EventEmitter<any>();
  @Output()
  isPayment:EventEmitter<any> = new EventEmitter<any>();
    
  orderInfo: any;

  aditionalCompanyList: MarinaCompany[];
  billingCompany: MarinaCompany;

  paymentMethod: TipoFormaPagamento;
  
  paymentMethods: TipoFormaPagamento[];
  
  accountList: SelectItem[] = [];
  selectedAccount: SelectItem;
  paymentMethodList: SelectItem[] = [];
  selectedPayment: SelectItem;

  accountListOptions: Conta[] = [];
  accountListPaymentMethods: FormaPagamentoPorConta[];
  onlyReceivingAccounts: boolean = true;
  serviceDeskCustomer: Customer;
  nixIntegrationConfig: NixIntegrationConfig;

  financialMultipleCompaniesType: FinancialMultipleCompaniesType;

  subscription: Subscription;

  constructor(
    private salesStatesService: SalesStatesService,
    public dialog: DynamicDialogRef,
    private messageUtil: MessageUtil,
    private toasty: ToastService,
    private financesService: FinancesService,
    private nixIntegrationConfigService: NixIntegrationConfigService
  ) { }

  ngOnInit() {
    this.financialMultipleCompaniesType = this.salesStatesService.getFinancialMultipleCompaniesType();
    
    this.subscription = this.salesStatesService.orderData$.subscribe((_) => {
      this.orderInfo = this.salesStatesService.orderData;
      this.loadPaymentData(this.orderInfo.selectedCustomer != null ?
        this.orderInfo.selectedCustomer : this.orderInfo.serviceDeskCustomer);
      });
    this.getNixIntegrationConfig();
  }

  async resetOptions(event?: boolean) {
    this.paymentMethod = undefined;
    this.accountListOptions = undefined;
    this.getPaymentMethods(event);
  }

  async loadPaymentData(customer?: Customer) {
      if (this.financialMultipleCompaniesType === FinancialMultipleCompaniesType.ByCustomer) {
        this.aditionalCompanyList = await this.financesService.findCompaniesWithDatabase();
        if (customer.billingCompany) {
          await this.valitadeAndAddAditionalCompaniesByCustomer(customer, this.aditionalCompanyList);
        }
      } else if (this.financialMultipleCompaniesType === FinancialMultipleCompaniesType.ByProduct) {
        if (this.orderInfo.orderedItems && this.orderInfo.orderedItems.length > 0) {
          this.billingCompany = this.orderInfo.orderedItems[0].product.billingCompany;
        } else {
          console.error('Gerar: Sem produtos');
        }
      } else {
        throw new Error(`Multiplo Financeiro com tipo inválido = '${this.financialMultipleCompaniesType}'`);
      }
      await this.findAllAccountPaymentMethods();
      this.getPaymentMethods();
  }

  getPaymentMethods(firstTime?: boolean): void {
    this.financesService.findTipoFormasPagamento(this.billingCompany ? this.billingCompany : null).subscribe({
      next: (data) => {
        this.paymentMethodList = (data.filter((method) => method.contas.length !== 0 && method.tipo.id !== 1)).map((payment) => {
          return { label: payment.descricao, value: payment };
        });
        if (this.accountListPaymentMethods && this.onlyReceivingAccounts) {
          this.paymentMethodList = this.filterPaymentList(this.paymentMethodList);
        }
        if (!this.accountListPaymentMethods) {
          this.onlyReceivingAccounts = false;
          if (firstTime) {
            this.toasty.warning('Não existem contas somente recebimento configuradas');
          }
        }
      },
        error: (error) => {
          if (error instanceof HttpErrorResponse) {
            this.messageUtil.generateMessage('error', 'SUMMARY.ERROR', "Servidor Financeiro Indisponível. Tente novamente mais tarde");
          }
      }});
  }

  onAccountSelected() {
    let isNixPix: boolean = this.checkIfNixPixSelected();
    this.isPayment.emit(true);
    this.onPaymentSelected.emit({
      account: this.selectedAccount?.value,
      paymentMethod: this.selectedPayment?.value,
      isNixPixSelected: isNixPix
    });
  }

  async loadAccountListOptions(): Promise<void> {
    if (this.onlyReceivingAccounts) {
      this.accountListOptions = await this.loadReceivingAcounts();
      this.accountList = this.accountListOptions.map((account) => {
        return { label: account.nome, value: account };
      });
    } else {
      this.accountListOptions = this.selectedPayment.value.contas;
      this.accountList = this.accountListOptions.map((account) => {
        return { label: account.nome, value: account };
      });
      this.selectedAccount = null;
      this.isPayment.emit(false);
    }
    if (this.accountList.length === 1) {
      let selected: SelectItem = {label: this.accountList[0].label, value: this.accountList[0].value};
      this.selectedAccount = selected;
    }
    this.onAccountSelected();
  }

  async valitadeAndAddAditionalCompaniesByCustomer(customer: Customer, aditionalCompanyList): Promise<void> {
    return new Promise<void>(
      async (resolve) => {

        if (customer.billingCompany && aditionalCompanyList.length > 0) {
          this.billingCompany = aditionalCompanyList.find((company) => company.id === customer.billingCompany.id);
        }
        resolve();
      }
    );
  }

  async findAllAccountPaymentMethods(): Promise<void> {

    let id: number;
    if (this.billingCompany) {
      id = this.billingCompany.id;
    } else {
      id = 0;
    }
    this.financesService.findAllAccountPaymentMethodsByCompanyId(id).subscribe(
      (data) => {
        this.accountListPaymentMethods = data.list;
      });
  }

  filterPaymentList(paymentList): any[] {
    let listOptions: SelectItem[] = [];
    let ids: number[] = [];
    if (this.accountListPaymentMethods) {
      this.accountListPaymentMethods.forEach(element => {
        ids.push(element.formaPagamentoId);
      });
      ids.forEach(element => {
        const paymentExist: SelectItem = paymentList.find((d) => d.value.idTipoFormaPagamento === element);
        if (paymentExist) {
          listOptions.push({
            label: paymentExist.label,
            value: paymentExist.value
          });
        }
      });
    } else {
      this.onlyReceivingAccounts = false;
    }
    return listOptions;
  }

  async loadReceivingAcounts(): Promise<any[]> {
    return new Promise<any[]>(
      async (resolve) => {
        let listOptions: any = [];
        const accountListOptions: any = [];
        listOptions = (this.accountListPaymentMethods.filter((method) =>
          method.formaPagamentoId === this.selectedPayment.value.idTipoFormaPagamento));

        if (listOptions[0]) {
          listOptions[0].contaId.forEach(element => {
            const accountExist = this.selectedPayment.value.contas.find((d) => d.idConta === element);
            if (accountExist) {
              accountListOptions.push(accountExist);
            }
          });
        }
        resolve(accountListOptions);
      }
    );
  }

  private checkIfNixPixSelected() {
    let isNixPix: boolean = false;
    if (this.nixIntegrationConfig && this.nixIntegrationConfig?.pixAccount && this.nixIntegrationConfig?.pixPaymentForm) {
      let isNixPixAccount: boolean = this.selectedAccount?.value?.idConta === this.nixIntegrationConfig?.pixAccount;
      let isNixPixPaymentMethod: boolean = this.selectedPayment?.value?.idTipoFormaPagamento === this.nixIntegrationConfig?.pixPaymentForm;
      isNixPix = isNixPixAccount && isNixPixPaymentMethod;
    }
    return isNixPix;
  }

  getNixIntegrationConfig(): void {
    this.nixIntegrationConfigService.getNixIntegrationConfigByMarinaId().subscribe({
      next: (resp) => {
        if(resp && resp.id){
          this.nixIntegrationConfig = resp;        
        }
      },
      error: (err) => {
        this.messageUtil.generateMessage('warning', 'Atenção', 'Erro ao consultar banco de dados');
      }
    });
  }
}
