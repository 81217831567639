import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import {Accessory} from '../models';

@Injectable()
export class AccessoryService {

    constructor(private http: HttpClient) { }

    getAll(): Observable<Accessory[]> {
        return this.http.get(environment.apiHost + '/secure/accessories/').pipe(map((response: any) => response.data.accessories));
    }

    uploadFile(files): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('attachment', files[0]);

        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'multipart/form-data');

        return this.http.post(
            environment.apiHost + '/secure/boats/accessory-file/attachment',
            formData,
            { headers }
        ).pipe(map((response: any) => response.data.attachment));
    }
}
