import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Address } from 'src/app/models/address';
import { NewVisitorPermissionDTO } from '../../../../../models/dtos/access-controller/newVisitorPermissionDTO';
import { VisitorService } from 'src/app/services/access-controler/visitor.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { BroadcastService } from 'src/app/services/broadcast.service';
import Swal from 'sweetalert2';
import { AccessGroupEnum } from 'src/app/models/dtos/access-controller/access-group-enum';

@Component({
  selector: 'app-service-provider-forms-container',
  templateUrl: './service-provider-forms-container.component.html',
  styleUrls: ['./service-provider-forms-container.component.scss'],
})
export class ServiceProviderFormsContainerComponent {
  address: Address = new Address();

  activeIndex = 0;
  visitorType: AccessGroupEnum = null;

  accessPermissionForm: FormGroup = new FormGroup({
    id: new FormControl<number | null>(null),
    colectId: new FormControl<number | null>(null),
    type: new FormControl<number | null>(0),
    accessGroup: new FormControl<any | null>(null),
    accessGroupId: new FormControl<number | null>(null, Validators.required),
    customerResponsibleId: new FormControl<number | null>(
      null,
      Validators.required
    ),
    customerResponsibleName: new FormControl<string | null>(null),
    supplierId: new FormControl<number | null>(null),
    supplierName: new FormControl<string | null>(null),
    active: new FormControl<boolean | null>(true),
    objective: new FormControl<string | null>(null),
    createDate: new FormControl<Date | null>(null),
    updateAt: new FormControl<Date | null>(null),
    starDate: new FormControl<Date | null>(null, Validators.required),
    endDate: new FormControl<Date | null>(null, Validators.required),
    statusColector: new FormControl<number | null>(null),
    carPlate: new FormControl<string | null>(null),
    carColor: new FormControl<string | null>(null),
    carModel: new FormControl<string | null>(null),
    ocurrences: new FormControl<string | null>(null),
    userUpdateId: new FormControl<number | null>(null),
    userUpdateName: new FormControl<string | null>(null),
    boatId: new FormControl<number | null>(null),
    boatName: new FormControl<string | null>(null),
    liberationStatus: new FormControl<number | null>(null),
  });

  titleId: number = 0;

  visitorForm: FormGroup = new FormGroup({});
  isProvider: boolean = false;

  constructor(
    private visitorService: VisitorService,
    public dialogRef: DynamicDialogRef,
    private spinner: NgxSpinnerService,
    private config: DynamicDialogConfig
  ) {}

  ngOnInit() {
    if (this.config.data) {
      this.isProvider =
        this.config.data.visitorType === AccessGroupEnum.SERVICE_PROVIDER;
    }

    this.visitorForm = new FormGroup({
      id: new FormControl<number | null>(null),
      name: new FormControl<string | null>(null, Validators.required),
      active: new FormControl<boolean | null>(true),
      marinaId: new FormControl<number | null>(null),
      federalId: new FormControl<string | null>(null, Validators.required),
      phone: new FormControl<string | null>(null, Validators.required),
      birthday: new FormControl<Date | null>(null, Validators.required),
      updatedAt: new FormControl<Date | null>(null),
      docUrl: new FormControl<string | null>(null),
      photoUrl: new FormControl<string | null>(null),
      createdAt: new FormControl<Date | null>(null),
      docUrlBack: new FormControl<string | null>(null),
      accessPermissions: new FormControl<[] | null>([]),
      colectId: new FormControl<number | null>(null),
      serviceProvider: new FormControl<string | null>(null),
    });
    if (this.isProvider) {
      this.visitorForm.addControl(
        'residentialAddress',
        new FormGroup({
          id: new FormControl(null),
          zip: new FormControl(null, Validators.required),
          country: new FormControl(null, Validators.required),
          state: new FormControl(null, Validators.required),
          city: new FormControl(null, Validators.required),
          district: new FormControl(null, Validators.required),
          street: new FormControl(null, Validators.required),
          number: new FormControl(null, Validators.required),
          complement: new FormControl(null),
        })
      );
      this.visitorForm.addControl(
        'observation',
        new FormControl<string | null>(null)
      );
    }
  }

  receiveVisitor(event: any) {
    this.visitorForm.patchValue(event.visitorForm.getRawValue());
    if (this.isProvider) {
      this.address = event.visitorForm.get('residentialAddress').value;
    }
    this.activeIndex = event.index;
  }

  receiveAccessPermission(event: any) {
    this.accessPermissionForm.patchValue(
      event.accessPermissionForm.getRawValue()
    );
    this.titleId = event.titleId;
    this.activeIndex = event.index;
  }

  saveAll(event: any) {
    console.log('Achou!', event);
    if (event.titleId != null && event.titleId != undefined) {
      this.titleId = event.titleId;
    }
    this.spinner.show();
    let dto: NewVisitorPermissionDTO = {
      visitorId: this.visitorForm.get('id').value,
      federalId: this.visitorForm.get('federalId').value,
      name: this.visitorForm.get('name').value,
      observation: this.isProvider
        ? this.visitorForm.get('observation').value
        : null,
      phone: this.visitorForm.get('phone').value,
      photoUrl: this.visitorForm.get('photoUrl').value,
      residentialAddress: this.address,
      active: this.visitorForm.get('active').value,
      birthday: this.visitorForm.get('birthday').value,
      accessGroupId: this.accessPermissionForm.get('accessGroupId').value,
      carColor: this.accessPermissionForm.get('carColor').value,
      carModel: this.accessPermissionForm.get('carModel').value,
      carPlate: this.accessPermissionForm.get('carPlate').value,
      customerResponsableId: this.accessPermissionForm.get(
        'customerResponsibleId'
      ).value,
      customerResponsableName: this.accessPermissionForm.get(
        'customerResponsibleName'
      ).value,
      endDate: this.accessPermissionForm.get('endDate').value,
      startDate: this.accessPermissionForm.get('starDate').value,
      objective: this.accessPermissionForm.get('objective').value,
      serviceProvider: this.isProvider,
    };
    let partyName = this.isProvider ? 'Prestador de serviço' : 'Convidado';
    if (!this.titleId) {
      this.titleId = 0;
    }
    this.visitorService.saveNewVisitorPermission(dto, this.titleId).subscribe({
      next: () => {
        this.spinner.hide();
        Swal.fire('Sucesso', partyName + ' cadastrado com sucesso', 'success');
        BroadcastService.get('reloadTables').emit();
        this.dialogRef.close(true);
      },
      error: (error) => {
        this.spinner.hide();
        Swal.fire('Erro', 'Erro ao cadastrar ' + partyName, 'error');
        console.log('error', error);
      },
    });
  }
}
