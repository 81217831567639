import { ProcessControl } from './../../models/processcontrol/processControl';
import { Component, OnInit } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { ProcessControlService } from 'src/app/services/process-control.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-process-control-form',
  templateUrl: './process-control-form.component.html',
  styleUrls: ['./process-control-form.component.scss']
})
export class ProcessControlFormComponent implements OnInit {

    listaProcessos: ProcessControl[] = [];

    private updateSubscription: Subscription;


    constructor(
        private processControlService: ProcessControlService,
    ) {}

    ngOnInit() {

        this.getProcessos();
    this.updateSubscription = interval(30000).subscribe(() => {
      this.getProcessos();
    });
    }

    getProcessos() {
        this.processControlService.getAll().subscribe(
            (resp) => {
                this.listaProcessos = resp;
            },
            (error) => {
             // Swal.fire('Atenção', 'Erro ao Recuperar imformações', 'warning');
            }
        );
    }

    download(file:string){
        window
        .open(`${file}`, '_blank')
        .focus();
    }

}
