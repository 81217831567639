import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import moment from 'moment';
import { DocumentDefaultSigner } from 'src/app/models/document-default-signer';
import Swal from 'sweetalert2';
declare var d4sign: any;

@Component({
  selector: 'app-sign-contract-dialog',
  templateUrl: './sign-contract-dialog.component.html',
  styleUrls: ['./sign-contract-dialog.component.scss']
})
export class SignContractDialogComponent implements OnInit {

  key: string;
  signer: DocumentDefaultSigner;
  host: string;
  protocol: string;

  constructor(
    private dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    if (this.config.data) {
      this.key = this.config.data.key;
      this.signer = this.config.data.signer;
      this.host = this.config.data.host;
      this.protocol = this.config.data.protocol;
    }
    this.signDocuments();
  }

  signDocuments(): void {
    const _this = this;
    d4sign.configure({
      container: 'signature-div',
      key: this.key,
      protocol: this.protocol,
      host: this.host,
      signer: {
        display_name: this.signer.name,
        email: this.signer.email,
        birthday: moment(this.signer.birthday).format('DD/MM/YYYY'),
        federalId: this.signer.federalId,
        disable_preview: '1',
      },
      width: '100%',
      height: window.outerHeight,
      callback: function (event) {
        if (event.data === 'signed') {
          _this.success();
        }
        if (event.data === 'wrong-data') {
          _this.wrongData();
        }
      }
    });
  }

  success(): void {
    this.dialogRef.close(true);
  }

  wrongData(): void {
    Swal.fire('Alerta!', 'Para corrigir os dados é necessario alteralos no sistema, e refazer os contratos!', 'warning');
    this.dialogRef.close();
  }
}