export class Address {
    id: number;
    zip: string;
    country: string;
    state: string;
    city: string;
    district: string;
    street: string;
    number: number;
    complement: string;
    constructor(){}
}
