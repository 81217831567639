<div class="table" style="margin: 20px;">
  <p-table #tt [value]="customerNotBlockTable" styleClass="easy-table">
    <ng-template pTemplate="caption">
      <div class="fill-empty"></div>
      <span style="font-weight: bold; float: right; margin-top: 5px"
        >{{ "TOTAL-REGISTERS" | translate }}: {{ tt.totalRecords }}
        {{ "OF" | translate }} {{ customerNotBlockTable?.length }}
      </span>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th>Cliente</th>
        <th>
          {{ "EMAIL" | translate }}
        </th>
        <th>Data Liberação</th>
        <th style=" width: 100px;">Remover</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-cust>
      <tr>
        <td>
          {{ cust.customer.name }}
        </td>
        <td>
          {{ cust.customer.email }}
        </td>

        <td>
          {{cust.date | date: 'dd/MM/yyyy'}}
        </td>
        <td style="width: 100px; text-align: center;">
          <div
            class="table-action"
            style="cursor: pointer; max-width: 30px; width: 30px;"
            (click)="openBoatDeleteConfirmDialog(cust.id)"
            pTooltip="Remover da lista"
            tooltipPosition="bottom"
          >
            <i class="pi pi-trash" style="color: #ef5350"></i>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
