<div class="dialog-form">
  <form [formGroup]="landExitForm" (submit)="save()">
    <div class="form-group ">
      <div class="form-control">
        <label for="executionDate">{{ 'CHOOSE-DATE' | translate }}*</label>
        <p-calendar dateFormat="dd/mm/yy" name="birthday" [monthNavigator]="false" [showTime]="true"
                    [yearNavigator]="false" [yearRange]="'1940:2055'" rPCalendarMask appendTo="body"
                    formControlName="executionDate" [showIcon]="true" i18n
        >
        </p-calendar>
      </div>
      <div class="form-control ">
        <label for="returnDate">{{ 'RETURN-DATE' | translate }}*</label>
        <p-calendar dateFormat="dd/mm/yy" name="returnDate" [monthNavigator]="false" [showTime]="true"
                    [yearNavigator]="false" [yearRange]="'1940:2055'" rPCalendarMask appendTo="body"
                    formControlName="returnDate" [showIcon]="true" i18n>
        </p-calendar>
      </div>
      <div class="form-control">
        <label for="searchByBoat">Busca por cliente:</label>
        <p-inputSwitch [(ngModel)]="searchByCustomer" id="searchByCustomer" [ngModelOptions]="{standalone: true}"
                       name="searchByCustomer" (onChange)="changeSearch()"></p-inputSwitch>
      </div>
    </div>
    <div class="form-group mt-5">
      <div class="form-control" *ngIf="searchByCustomer">
        <label for="customer">{{ 'CUSTOMER' | translate }}*</label>
        <p-dropdown placeholder="Selecione..." required [filter]="true" name="customer"
                    appendTo="body" [options]="customerList" formControlName="customer"
                    emptyFilterMessage="" (onChange)="selectCustomer($event)" optionLabel="name">
          <ng-template let-customer pTemplate="item">
            <div>{{customer.name}}</div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="form-control" *ngIf="searchByCustomer">
        <label for="boat">{{ 'BOAT' | translate }}*</label>
        <p-dropdown placeholder="Selecione..." required [filter]="true"
                    name="boat" appendTo="body" [options]="boatList" formControlName="boat"
                    emptyFilterMessage="" (onChange)="checkSailor()" optionLabel="name">
          <ng-template let-boat pTemplate="item">
            <div>{{boat.name}}</div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="form-control" *ngIf="!searchByCustomer">
        <label for="boat">{{ 'BOAT' | translate }}*</label>
        <p-dropdown placeholder="Selecione..." required [filter]="true"
                    name="boat" appendTo="body" [options]="boatList" formControlName="boat"
                    emptyFilterMessage="" (onChange)="selectBoat($event)" optionLabel="name">
          <ng-template let-boat pTemplate="item">
            <div>{{boat.name}}</div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="form-control" *ngIf="!searchByCustomer">
        <label for="customer">{{ 'CUSTOMER' | translate }}*</label>
        <p-dropdown placeholder="Selecione..." required [filter]="true" name="customer"
                    appendTo="body" [options]="customerList" formControlName="customer"
                    (onChange)="checkSailor()" optionLabel="name">
          <ng-template let-customer pTemplate="item">
            <div>{{customer.name}}</div>
          </ng-template>
        </p-dropdown>
      </div>
      <div class="form-control " *ngIf="sailorList.length > 0">
        <label for="sailor">{{ 'SAILOR' | translate }}*</label>
        <p-dropdown placeholder="Selecione..." appendTo="body" [options]="sailorList" formControlName="sailor"
                    name="sailor"
                    emptyFilterMessage="" optionLabel="name">
          <ng-template let-sailor pTemplate="item">
            <div>{{sailor.name}}</div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
    <div class="buttons">
      <button class="easymarine danger" type="button" (click)="close()">
        {{ 'CANCEL' | translate }}
      </button>
      <button [disabled]="isFormValid()" class="easymarine primary" type="submit">
        {{ 'SAVE' | translate }}
      </button>
    </div>
  </form>
</div>
