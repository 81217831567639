<p-fieldset>
  <p-header class="article-title">
      <span class="article-title">{{ 'TITLE-SIREN-ALERT' | translate }}</span>
  </p-header>
  <form (submit)="save()">
      <div class="form-group">
          <div class="form-control">
              <div class="switch">
                  <label> Nova lingada Marina</label>
                  <p-inputSwitch [(ngModel)]="sirenAlertConfig.newSlingMarina" name="sirenAlertConfig.newSlingMarina">
                  </p-inputSwitch>
              </div>
              <p-dropdown placeholder="Selecione..." [hidden]="!sirenAlertConfig.newSlingMarina" [filter]="true"
                  [options]="filteredSirenAlerts" [(ngModel)]="sirenAlertConfig.sirenAlertNewSlingMarina"
                  appendTo="body" name="sirenAlertNewSlingMarina" (onChange)="changeSirenAlert('newSlingMarina')">
              </p-dropdown>
              <audio controls #volumeNewSlingMarina
                  [hidden]="!sirenAlertConfig.newSlingMarina || !sirenAlertConfig.sirenAlertNewSlingMarina?.pathUrl">
                  <source #audioSourceNewSlingMarina [src]="sirenAlertConfig.sirenAlertNewSlingMarina?.pathUrl"
                      type="audio/mpeg">
                  {{ 'MSG.AUDIO-NOT-SUPPORTED' | translate }}
              </audio>
          </div>
          <div class="form-control">
              <div class="switch">
                  <label>Nova lingada Cliente</label>
                  <p-inputSwitch [(ngModel)]="sirenAlertConfig.newSlingCustomer"
                      name="sirenAlertConfig.newSlingCustomer"></p-inputSwitch>
              </div>
              <p-dropdown placeholder="Selecione..." [hidden]="!sirenAlertConfig.newSlingCustomer" [filter]="true"
                  [options]="filteredSirenAlerts" [(ngModel)]="sirenAlertConfig.sirenAlertNewSlingCustomer"
                  appendTo="body" name="sirenAlertNewSlingCustomer" (onChange)="changeSirenAlert('newSlingCustomer')">
              </p-dropdown>
              <audio controls #volumeNewSlingCustomer
                  [hidden]="!sirenAlertConfig.newSlingCustomer || !sirenAlertConfig.sirenAlertNewSlingCustomer?.pathUrl">
                  <source #audioSourceNewSlingCustomer [src]="sirenAlertConfig.sirenAlertNewSlingCustomer?.pathUrl"
                      type="audio/mpeg">
                  {{ 'MSG.AUDIO-NOT-SUPPORTED' | translate }}
              </audio>
          </div>
          <div class="form-control">

              <div class="switch">
                  <label>Nova lingada Totem</label>
                  <p-inputSwitch [(ngModel)]="sirenAlertConfig.newSlingTotem" name="sirenAlertConfig.newSlingTotem">
                  </p-inputSwitch>
              </div>
              <p-dropdown placeholder="Selecione..." [hidden]="!sirenAlertConfig.newSlingTotem" [filter]="true"
                  [options]="filteredSirenAlerts" [(ngModel)]="sirenAlertConfig.sirenAlertNewSlingTotem"
                  appendTo="body" name="sirenAlertNewSlingTotem" (onChange)="changeSirenAlert('newSlingTotem')">
              </p-dropdown>
              <audio controls #volumeNewSlingTotem
                  [hidden]="!sirenAlertConfig.newSlingTotem || !sirenAlertConfig.sirenAlertNewSlingTotem?.pathUrl">
                  <source #audioSourceNewSlingTotem [src]="sirenAlertConfig.sirenAlertNewSlingTotem?.pathUrl"
                      type="audio/mpeg">
                  {{ 'MSG.AUDIO-NOT-SUPPORTED' | translate }}
              </audio>
          </div>
          <div class="form-control">
              <div class="switch">
                  <label>Subida lingada cliente</label>
                  <p-inputSwitch [(ngModel)]="sirenAlertConfig.upSlingCustomer"
                      name="sirenAlertConfig.upSlingCustomer">
                  </p-inputSwitch>
              </div>
              <p-dropdown placeholder="Selecione..." [hidden]="!sirenAlertConfig.upSlingCustomer" [filter]="true"
                  [options]="filteredSirenAlerts" [(ngModel)]="sirenAlertConfig.sirenAlertUpSlingCustomer"
                  appendTo="body" name="sirenAlertUpSlingCustomer" (onChange)="changeSirenAlert('upSlingCustomer')">
              </p-dropdown>
              <audio controls #volumeUpSlingCustomer
                  [hidden]="!sirenAlertConfig.upSlingCustomer || !sirenAlertConfig.sirenAlertUpSlingCustomer?.pathUrl">
                  <source #audioSourceUpSlingCustomer [src]="sirenAlertConfig.sirenAlertUpSlingCustomer?.pathUrl"
                      type="audio/mpeg">
                  {{ 'MSG.AUDIO-NOT-SUPPORTED' | translate }}
              </audio>
          </div>
      </div>
      <div class="form-group">
          <div class="form-control">

              <div class="switch">
                  <label>Cancelamento lingada</label>
                  <p-inputSwitch [(ngModel)]="sirenAlertConfig.cancelSling" name="sirenAlertConfig.cancelSling">
                  </p-inputSwitch>
              </div>
              <p-dropdown placeholder="Selecione..." [hidden]="!sirenAlertConfig.cancelSling" [filter]="true"
                  [options]="filteredSirenAlerts" [(ngModel)]="sirenAlertConfig.sirenAlertCancelSling" appendTo="body"
                  name="sirenAlertCancelSling" (onChange)="changeSirenAlert('cancelSling')">
              </p-dropdown>
              <audio controls #volumeCancelSling
                  [hidden]="!sirenAlertConfig.cancelSling || !sirenAlertConfig.sirenAlertCancelSling?.pathUrl">
                  <source #audioSourceCancelSlingCustomer [src]="sirenAlertConfig.sirenAlertCancelSling?.pathUrl"
                      type="audio/mpeg">
                  {{ 'MSG.AUDIO-NOT-SUPPORTED' | translate }}
              </audio>
          </div>
          <div class="form-control">
              <div class="switch">
                  <label>Nova lingada Gestor</label>
                  <p-inputSwitch [(ngModel)]="sirenAlertConfig.newSlingGestor" name="sirenAlertConfig.newSlingGestor">
                  </p-inputSwitch>
              </div>
              <p-dropdown placeholder="Selecione..." [hidden]="!sirenAlertConfig.newSlingGestor" [filter]="true"
                  [options]="filteredSirenAlerts" [(ngModel)]="sirenAlertConfig.sirenAlertNewSlingGestor"
                  appendTo="body" name="sirenAlertNewSlingGestor" (onChange)="changeSirenAlert('newSlingGestor')">
              </p-dropdown>
              <audio controls #volumeNewSlingGestor
                  [hidden]="!sirenAlertConfig.newSlingGestor || !sirenAlertConfig.sirenAlertNewSlingGestor?.pathUrl">
                  <source #audioSourceNewSlingGestor [src]="sirenAlertConfig.sirenAlertNewSlingGestor?.pathUrl"
                      type="audio/mpeg">
                  {{ 'MSG.AUDIO-NOT-SUPPORTED' | translate }}
              </audio>
          </div>
      </div>
      <div class="form-group">
          <div class="buttons">
              <button class="easymarine primary" type="submit">{{ 'SAVE' | translate }}</button>
          </div>
      </div>
  </form>
</p-fieldset>
