<p-fieldset>
    <p-header class="article-title">
        <div class="article-title">Nota Fiscal</div>
    </p-header>

    <div class="content-log">
        <div>
            <label>{{ 'ISS-RETIDO' | translate }}</label>
            <label [ngClass]="{'item-new-log': isChangedIssRetidoFonte() && isNew, 'item-old-log': isChangedIssRetidoFonte() && !isNew}">
                {{ (getIssRetidoFonte()? 'YES':'NO') | translate}}
            </label>
        </div>
    </div>
</p-fieldset>
<br>