import { NgxSpinnerService } from 'ngx-spinner';
import { MovementLocationService } from './../../../services/movements/movement-location.service';
import { Component, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Vacancy } from 'src/app/models';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { VacancyService } from 'src/app/services/vacancy.service';
import { MessageUtil } from 'src/app/utils/message.util';
import Swal from 'sweetalert2';
import { VacancyFormComponent } from './vacancy-form/vacancy-form.component';
import { BoatService } from 'src/app/services/boat.service';
import { isThisTypeNode } from 'typescript';


@Component({
  selector: 'app-vacancy',
  templateUrl: './vacancy.component.html',
  styleUrls: ['./vacancy.component.scss']
})
export class VacancyComponent implements OnInit {

  vacancies: Vacancy[] = [];
  numberOfRows = 10;

  localeOptions: any[];

  constructor(
    public dialog: DialogService,
    private vacancyService: VacancyService,
    private slingConfigService: SlingConfigService,
    private messageUtil: MessageUtil,
    private movementLocationService: MovementLocationService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.loadSlingConfig();
    this.fillVacanciesTable();
    this.getLocationOptions();
  }

  loadSlingConfig(): void {
    this.slingConfigService.getSlingConfig().subscribe(
      (data) => {
        const slingConfig = data[0];
        this.numberOfRows = slingConfig != null ? slingConfig.numberOfRecordsPerPage : 10;
      }
    );
  }

  async openVacancyFormDialog(id?: number): Promise<void> {
    let vacancy: Vacancy = null;
    if (id) {
      vacancy = await this.getVacancyById(id);
    }

    this.dialog.open(VacancyFormComponent, {
      width: '750px',
      height: '770px',
      header: this.messageUtil.translateKey('VACANCY'),
      data: {
        vacancy,
        localeOptions: this.localeOptions
      }
    }).onClose.subscribe(
      () => {
        this.fillVacanciesTable();
      }
    );
  }

  openVacancyDeleteConfirmDialog(id: number): void {
    Swal.fire({
      title: this.messageUtil.translateKey('DELETE-CONFIRMATION'),
      text: this.messageUtil.translateKey('DELETE-CONFIRMATION-MESSAGE'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).
      then((result) => {
        if (result.value) {
          this.vacancyService.delete(id).subscribe(
            () => {
              Swal.fire('Exclusão de vaga', 'Vaga excluída com sucesso.', 'success');
            },
            (error) => {
              Swal.fire({
                title: this.messageUtil.translateKey('DELETE-CONFIRMATION'),
                text: error.error.message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                reverseButtons: true
              }).
                then((r) => {
                  if (r.value) {
                    this.vacancyService.forceDelete(id).subscribe(
                      () => {
                        Swal.fire('Exclusão de vaga', 'Vaga excluída com sucesso.', 'success');
                      },
                      (err) => {
                        Swal.fire('Falha ao remover!', err.error.message, 'error');
                      },
                      () => {
                        this.fillVacanciesTable();
                      });
                  }
                });
            },
            () => {
              this.fillVacanciesTable();
            });
        }
      }
      );
  }

  fillVacanciesTable(): void {
    this.vacancyService.getAll().subscribe((v) => this.vacancies = v);
  }

  getLocationOptions() {
    this.movementLocationService.getAll().subscribe( (data) => {
      this.localeOptions  = data.map(location =>
        ({
          label: location.name,
          value: location,
          disabled: !location.active
        })
      );
    },
    (err) => {
      console.log("erro ", err);
    }
    );

  }

  updateStatus(vacancy: any): void {
    this.spinner.show();
    const newStatus = !vacancy.active;
    this.vacancyService.updateStatus(vacancy.id, newStatus).subscribe(
      (data) => {
        vacancy.active = newStatus;
        this.messageUtil.generateMessage('success', 'SUMMARY.SUCCESS', 'Situação atualizada com sucesso');
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception.message;
        Swal.fire('Falha ao Atualizar!', error.error.data.exception.message, 'error');
      },
      () => this.spinner.hide()
    );
  }

  async getVacancyById(id: number): Promise<Vacancy> {
    return new Promise<Vacancy>(
      async (resolve) => {
        this.vacancyService.getById(id).subscribe(
          async (v) => {
            resolve(v);
          },
          err => {
            resolve(null);
          }
        );
      }
    );
  }
}
