<div class="table">
    <p-table [value]="logsTable()" [paginator]="true"
    [rows]="5" [alwaysShowPaginator]="false">
    <ng-template pTemplate="header">
        <tr>
            <th class="text-center">{{ 'START-LENGTH-RANGE' | translate }}</th>
            <th class="text-center">{{ 'END-LENGTH-RANGE' | translate }}</th>
            <th class="text-center">{{ getTitleRangeType() | translate }}</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-priceRange>
        <tr  [ngClass]="{'item-new-log': isChanged(priceRange) && isNew, 'item-old-log': isChanged(priceRange) && !isNew}">
            <td class="text-right">
                <span>
                    {{priceRange.startLengthRange | number:'1.2-2'}}
                </span>
            </td>
            <td class="text-right">
                <span>
                    {{priceRange.endLengthRange }}
                </span>
            </td>
            <td class="text-right">
                <span>
                    {{priceRange.value }}
                </span>
            </td>
        </tr>
    </ng-template>
</p-table>
</div>