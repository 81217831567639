<div style="padding: 16px">
  <app-role-log-geral
    *ngIf="isGeneralData"
    [roleLog]="roleLog"
    [isNew]="true"
  ></app-role-log-geral>

  <app-role-log-geral
    *ngIf="isGeneralData"
    [roleLog]="roleLog"
    [isNew]="false"
  ></app-role-log-geral>

  <div *ngIf="roleLog.oldDatas.newItens || roleLog.oldDatas.deletedItens" class="div-tabela">

    <div *ngIf="roleLog.oldDatas.newItens" class="col" >
      <label class="label-tabela">Novos itens</label>
      <table class="table table-striped table-hover" style="width: 80%;">
        <thead>
          <tr>
            <th style="background-color: rgba(0, 129, 0, 0.336);">Código</th>
            <th style="background-color: rgba(0, 129, 0, 0.336);">Nome</th>
            <th style="background-color: rgba(0, 129, 0, 0.336);">Aplicação</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of roleLog.oldDatas.newItens">
            <td class="tb-format" >{{ item.id }}</td>
            <td class="tb-format" >
              {{ item.label | translate }}
            </td>
            <td class="tb-format" >{{ item.application }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="roleLog.oldDatas.deletedItens" class="col">
      <label>Itens excluídos</label>
      <table class="table table-striped table-hover" style="width: 80%;">
        <thead>
          <tr style="background-color: rgba(255, 0, 0, 0.336);">
            <th>Código</th>
            <th>Nome</th>
            <th>Aplicação</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of roleLog.oldDatas.deletedItens">
            <td class="tb-format" >{{ item.id }}</td>
            <td  class="tb-format" >
              {{ item.label | translate }}
            </td>
            <td  class="tb-format" >{{ item.application }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div style="margin: 15px">
    <div class="content-log">
      <div>
        <label>Data/hora</label>
        <label>{{ roleLog.logTime | date: "dd/MM/yyyy - HH:mm" }}</label>
      </div>
      <div>
        <label>Usuário</label>
        <label>{{ roleLog.user.username }}</label>
      </div>
      <div>
        <label>Origem</label>
        <label>{{ roleLog.origin }}</label>
      </div>
      <div>
        <label>ação</label>
        <label>{{ roleLog.operationLog | translate }}</label>
      </div>
    </div>
    <div class="buttons">
      <button class="easymarine primary" (click)="dialogRef.close()">
        Fechar
      </button>
    </div>
  </div>
</div>
