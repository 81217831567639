import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FocusService {


constructor(private http: HttpClient) { }

getNCM(codigo: string): Observable<any[]> {
    return this.http.get(environment.apiHost + '/secure/ncm/codigo/'+ codigo).pipe(map((response: any) => response.data.ncms as any[]));
}



}
