import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OperationSpecialDay } from 'src/app/models/operation-special-day';

@Component({
  selector: 'app-operation-special-day',
  templateUrl: './operation-special-day.component.html',
  styleUrls: ['./operation-special-day.component.scss']
})
export class OperationSpecialDayComponent implements OnInit {

  @Input() specialDays: OperationSpecialDay[] = [];
  @Output() deleteEvent: EventEmitter<OperationSpecialDay> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {

  }

  newSpecialDay() {
    this.specialDays.push(new OperationSpecialDay);
  }

  delete(specialDay){
    this.deleteEvent.emit(specialDay);
  }

}
