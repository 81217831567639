<div class="dialog-form align-form-center">
  <form (submit)="saveChangeLocation()" class="sizeForm">
    <section class="container">
      <div *ngIf="movement"  class="row-item">

        <div class="item">
          <div class="quarter">
            <label>{{'BOAT' | translate}}:</label>
          </div>
            <span class="form-group item-title">{{movement.boat.name}}</span>
        </div>
      </div>

      <div class="divider"></div>

      <div *ngIf="movement" class="row-item" >

        <div class="item">
          <div class="quarter">
            <label>{{'ORIGIN' | translate}}:</label>
          </div>
          <div class="form-group item-title">
            <app-movement-location
              [params]="{
                type: movement?.currentLocation?.type,
                name: movement?.currentLocation?.name,
                abbreviation: movement?.currentLocation?.abbreviation,
                vacancyCode: movement?.currentLocation?.vacancyCode
              }">
            </app-movement-location>
          </div>

        </div>

        <div style="display: flex; align-items: center; width: 20%;">
          <img src="assets/images/Arrow 24.png" alt="">
        </div>

        <div class="item">
          <label for="movementLocation">{{'DESTINATION' | translate}}:</label>
          <p-dropdown  class="form-group" [options]="locationsOptions"
            [(ngModel)]="location"
            (onChange)="changeLocation($event)"
            name="movementLocation" appendTo="body">
            <ng-template let-location pTemplate="selectedItem">
              <span>{{location.label}}</span>
            </ng-template>
            <ng-template let-location pTemplate="item">
              <span>{{location.label}}</span>
            </ng-template>
          </p-dropdown>
        </div>

      </div>

      <div class="btn-align-bottom">
        <div class="buttons btn-align-between">
          <button class="easymarine danger" type="button"
            (click)="finishMovementPla()">
            {{ 'Finalizar Plano' | translate }}
          </button>
          <div>
            <button class="easymarine danger" type="button" style="margin: 0px 7px;"
              (click)="dialogRef.close()">{{ 'CANCEL' | translate }}
            </button>
            <button class="easymarine primary" type="submit">{{ 'SAVE' | translate }}
            </button>
          </div>
        </div>
      </div>
    </section>
  </form>
</div>
