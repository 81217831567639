import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { FileUpload } from 'primeng/fileupload';
import { Marina } from 'src/app/models';
import { MarinaCompany } from 'src/app/models/marina-company';
import { NotaFiscalService } from 'src/app/services/nota-fiscal.service';
import { FormUtil } from 'src/app/utils/form.util';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-nota-fiscal',
  templateUrl: './nota-fiscal.component.html',
  styleUrls: ['./nota-fiscal.component.scss']
})
export class NotaFiscalComponent implements OnInit {

  @ViewChild('upload', { static: false }) upload: FileUpload;

  marinaCompanies: MarinaCompany[];
  filteredCompanies: Array<{ label: string, value: any, marinaCompany: boolean }> = [];
  loggedMarina: Marina;
  certificateForm: UntypedFormGroup;
  constructor(
    private spinner: NgxSpinnerService,
    private messageUtil: MessageUtil,
    private notaFiscalService: NotaFiscalService
  ) { }

  ngOnInit(): void {
    this.getCompanies();
    this.startForm();
  }

  getCompanies(): void {
    const marina = StorageUtil.getMarina();
    this.marinaCompanies = marina.marinaCompanies;
    this.filteredCompanies = this.marinaCompanies.map((c) => ({ label: c.companyFederalName, value: c.id, marinaCompany: true }));
    this.filteredCompanies.unshift({ label: marina.companyName, value: marina.id, marinaCompany: false });
    this.loggedMarina = StorageUtil.getMarina();
  }

  startForm(): void {
    this.certificateForm = new UntypedFormGroup({
      id: new UntypedFormControl(this.loggedMarina.id, Validators.required),
      certificate: new UntypedFormControl(null, Validators.required),
      password: new UntypedFormControl(null, Validators.required),
      marinaCompany: new UntypedFormControl(this.marinaCompany, Validators.required)
    });
  }

  get marinaCompany(): boolean {
    if (this.certificateForm?.value?.id && this.loggedMarina.id !== this.certificateForm?.value?.id) {
      return true;
    }
    return false;
  }

  submit(): void {
    FormUtil.touchAndSendFormGroup(this.certificateForm);
    if (this.certificateForm.valid) {
      this.spinner.show();
      this.notaFiscalService.updloadDigitalCertificate(this.certificateForm.value).subscribe(
        (arquivo: string) => {
          this.spinner.hide();
          this.messageUtil.generateMessage(
            'success',
            'SUMMARY.SUCCESS',
            `Certificado ${arquivo} atualizado com sucesso.`
          );
          this.startForm();
        },
        (err) => {
          this.spinner.hide();
          const exception = err?.error?.data?.exception || err.error;
          this.messageUtil.generateMessage(exception.type || 'error', exception.title || 'SUMMARY.ERROR', exception.message);
        }
      );
    } else {
      Swal.fire('Alerta!', 'Existem campos inválidos ou não informados, verifique os dados.', 'warning');
    }
  }

  handle($event): void {
    this.certificateForm.get('certificate').setValue($event.files[0]);
    this.upload.clear();
  }

  changeCompany(): void {
    this.certificateForm.get('marinaCompany').setValue(this.marinaCompany);
  }
}
