export class Empresa {
      codigo: number;
      nome: string;
      fantasia: string;
      cnpj: string;
      tipoSistema: string;
      endereco?: string;
      complemento?: string;
      telefone?: string;
      bairro?: string;
      cidade?: string;
      uf?: string;
      cep?: string;
      email?: string;
      status?: string;
}
