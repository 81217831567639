<p-fieldset>
  <p-header class="article-title">
    <span class="article-title">{{ 'TITLE-FRANCHISE-GROUP-BATCH' | translate }}</span>
  </p-header>

  <div class="max-width flex flex-column align-items-center" *ngIf="showFranchiseLists; else showPickList">
    <h1>Escolha a franquia de origem e a franquia de destino</h1>
    <div class="flex flex-row justify-content-around mt-4">
      <div class="w-18rem h-10rem mr-5">
        <label for="sourceFranchiseList">Franquia de Origem</label>
        <p-dropdown [options]="sourceFranchiseList" [(ngModel)]="sourceFranchiseSelected" optionLabel="name"
                    (onChange)="populateTargetFranchiseList()" name="sourceFranchiseList"></p-dropdown>
      </div>
      <div class="w-18rem h-10rem">
        <label for="targetFranchiseList">Franquia de Destino</label>
        <p-dropdown [options]="targetFranchiseList" placeholder="Selecione..." [(ngModel)]="targetFranchiseSelected" optionLabel="name"
                    name="targetFranchiseList" ></p-dropdown>
      </div>
    </div>
    <div class="flex flex-row justify-content-center">
      <button class="easymarine danger mr-3" type="button"
              routerLink="../">{{ 'CANCEL' | translate }}
      </button>
      <button class="easymarine primary" (click)="invertShowFranchiseList()" [disabled]="!targetFranchiseSelected">{{ 'PRÓXIMO' | translate }}</button>
    </div>
  </div>
  <ng-template #showPickList>
    <p-pickList [source]="sourceAccessBoatObjList" [target]="targetAccessBoatObjList" sourceHeader="Origem - {{sourceFranchiseSelected.name}}"
                targetHeader="Destino - {{targetFranchiseSelected.name}}"
                [responsive]="true" [sourceStyle]="{ height: '30rem' }"
                [keepSelection]="true"
                [showSourceControls]="false"
                [showTargetControls]="false" [dragdrop]="true"
                [targetStyle]="{ height: '30rem' }" filterBy="name" sourceFilterPlaceholder="Buscar pelo nome"
                targetFilterPlaceholder="Buscar pelo nome" breakpoint="1400px">
      <ng-template let-selectItem pTemplate="item">
        <div class="flex flex-wrap p-2 align-items-center gap-3">
          <div class="flex-1 flex flex-column gap-2">
            <span class="font-bold">{{ selectItem.name }}</span>
          </div>
        </div>
      </ng-template>
    </p-pickList>
    <div class="flex flex-row justify-content-end mt-4">
      <button class="easymarine danger mr-3" type="button" (click)="invertShowFranchiseList()" >{{ 'CANCEL' | translate }}
      </button>
      <button class="easymarine primary" (click)="save()">{{ 'SAVE' | translate }}</button>
    </div>
  </ng-template>
</p-fieldset>
