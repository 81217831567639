import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SirenAlert } from 'src/app/models/siren/siren-alert';
import { SirenAlertSimpleConfig } from 'src/app/models/siren/siren-alert-simple-config';

@Component({
  selector: 'app-siren-alert',
  templateUrl: './siren-alert.component.html',
  styleUrls: ['./siren-alert.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SirenAlertComponent),
      multi: true
    }
  ]
})
export class SirenAlertComponent implements OnInit, ControlValueAccessor {

  @ViewChild('volume', { static: false}) volume: ElementRef;
  @ViewChild('audioSource', { static: false}) audioSource: ElementRef;

  @Input()
  filteredSirenAlerts: any[]

  data: SirenAlertSimpleConfig
  disabled: boolean

  private onChange: any = () => {};
  private onTouched: any = () => {};

  constructor() {}

  ngOnInit(): void {}

  writeValue(obj: SirenAlertSimpleConfig): void {
    this.data = obj;
    this.loadAudio(this.data);
  }
  registerOnChange(fn: any): void { this.onChange = fn }
  registerOnTouched(fn: any): void { this.onTouched = fn }
  setDisabledState?(isDisabled: boolean): void { this.disabled = isDisabled; }


  changeEnabled(event: {originalEvent: any, checked: boolean}) {
    if (event.checked) {
      this.loadAudio(this.data);
    }
    this.change(this.data);
  }

  changeSirenAlert() {
    this.audioSource.nativeElement.src = this.data.sirenAlert.pathUrl;
    this.volume.nativeElement.load();
    this.change(this.data);
    this.onTouched();
  }

  change(data: SirenAlertSimpleConfig): void {
    this.onChange(data);
  }

  private loadAudio(data: SirenAlertSimpleConfig): void {
    if (data.enabled && data.sirenAlert && this.audioSource?.nativeElement) {
      this.audioSource.nativeElement.src = data.sirenAlert.pathUrl;
      this.volume.nativeElement.volume = data.volume;
      this.volume.nativeElement.load();
    }
  }

}
