import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-finances',
  templateUrl: './finances.component.html',
  styleUrls: ['./finances.component.scss']
})
export class FinancesComponent implements OnInit, OnDestroy {

  sub: any;
  financeUrl: any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private authService: AuthenticationService
  ) { }

  async ngOnInit(): Promise<void> {
    const currentParams = Object.assign({}, this.route.snapshot.queryParams);
    const url = await this.findUrl();
    let token;
    let companyId = null;
    if (currentParams && currentParams.company) {
      companyId = Number.parseInt(currentParams.company, 10);
      token = this.authService.getTokenFinanceiro(companyId);
      BroadcastService.get('companyChanged').emit({ company: companyId, finances: true });
    } else {
      token = this.authService.getTokenFinanceiro();
      BroadcastService.get('companyChanged').emit({ company: null, finances: true });
    }
    const currentUrl = this.router.url;
    let environmentTrustedUrl = '';
    if (currentUrl.includes('finances2')) {
      environmentTrustedUrl = environment.finHost2;
    } else {
      environmentTrustedUrl = environment.finHost;
    }
    this.financeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      environmentTrustedUrl + url + '?token=' + token + (companyId ? '&company=' + companyId : '')
      );
    this.sub.unsubscribe();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    BroadcastService.get('companyChanged').emit({ company: null, finances: false });
  }

  async findUrl(): Promise<string> {
    return new Promise<string>(
      async (resolve) => {
        this.sub = this.route.data
          .subscribe(
            async (v) => {
              resolve(v.url);
            }
          );
      }
    );
  }
}

