<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{ 'TITLE-DYN-TABLES' | translate }}</span>
        <div class="header-buttons">
            <button class="fab-button primary" (click)="exportExcel()" label="Exportar valores"
                pTooltip="Exportar Valores" tooltipPosition="left">
                <i class="pi pi-file"></i>
            </button>
            <button class="fab-button primary" appFiltersButton pTooltip="Exibir/Ocultar Filtros"
                tooltipPosition="left">
                <i class="pi pi-filter"></i>
            </button>
        </div>
    </p-header>
    <form class="filters" (submit)="loadPivotTable()" appFilters>
        <div class="filter-row">
            <div class="form-control">
                <label for="origin">Origem</label>
                <p-dropdown [(ngModel)]="pivotTableOrigin" name="origin" appendTo="body"
                    [options]="[{label: 'Venda de Produtos', value: 'product-sales'}]">
                </p-dropdown>
            </div>
            <div class="buttons">
                <div class="form-button">
                    <button type="submit" class="easymarine primary" >Pesqusiar</button>
                </div>
                <!-- <div class="form-button">
                    <button type="button" class="easymarine btn-csv-xls" (click)="exportExcel()">Excel
                        <i class="pi pi-file-excel"></i>
                    </button>
                </div> -->
            </div>


        </div>
    </form>

</p-fieldset>


<div id="output"></div>

