import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ExtractPdfGenericComponent } from 'src/app/components/extract-pdf-generic/extract-pdf-generic.component';
import { BoatService } from 'src/app/services/boat.service';
import { MessageUtil } from 'src/app/utils/message.util';

@Component({
  selector: 'app-boat-history-dialog',
  templateUrl: './boat-history-dialog.component.html',
  styleUrls: ['./boat-history-dialog.component.scss']
})
export class BoatHistoryDialogComponent implements OnInit {

  exportForm: UntypedFormGroup;
  boatId

  constructor(
    private boatService: BoatService,
    private spinner: NgxSpinnerService,
    private messageUtil: MessageUtil,
    private sanitizer: DomSanitizer,
    private dialog: DialogService,
    public dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.exportForm = new UntypedFormGroup({
      start: new UntypedFormControl(new Date(), Validators.required),
      end: new UntypedFormControl(new Date(), Validators.required)
    })

    this.boatId = this.config.data.boatId;
  }

  export(): void{
    this.spinner.show();
    const filter = {
      start: this.exportForm.get("start").value,
      end: this.exportForm.get("end").value,
      boatId: this.boatId
    }

    this.boatService.extractBoatHistory(filter).subscribe(
      (data) => {
        this.openExtract(
          "data:application/pdf;base64," + data
        );
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      },
      () => this.spinner.hide());
}

openExtract(path): void {
  const dialog = this.dialog.open(ExtractPdfGenericComponent, {
    width: '100%',
    height: '100%',
    data: {
      path
    },
    header: 'Histórico'
  });
}

}
