<div class="filters active">
    <div class="filter-row">
        <div class="form-control">
            <label>{{'BOAT' | translate}}</label>
            <p-dropdown [placeholder]="'BOAT' | translate" [filter]="true" [options]="boatListFilter" appendTo="body"
                name="selectedBoatIdFilter" [title]="'BOAT' | translate" [(ngModel)]="filter.boatId">
            </p-dropdown>
        </div>
        <div class="form-control">
            <label>{{'SERVICES' | translate}}</label>
            <p-dropdown [placeholder]="'SERVICES' | translate" [filter]="true" [options]="serviceListFilter"
                appendTo="body" [(ngModel)]="filter.serviceId" name="selectedService" [title]="'SERVICES' | translate">
            </p-dropdown>
        </div>
        <div class="form-control">
            <label>{{'CUSTOMER' | translate}}</label>
            <input pInputText type="text" [(ngModel)]="filter.customerName" [placeholder]="'CUSTOMER' | translate">
        </div>
        <div class="form-control">
            <label>{{'STATUS' | translate}} do contrato</label>
            <p-multiSelect [defaultLabel]="'STATUS' | translate" [options]="statusListFilter" appendTo="body"
                [(ngModel)]="filter.statusList" [title]="'STATUS' | translate" [filter]=false
                selectedItemsLabel="{0} itens selecionados">
            </p-multiSelect>
        </div>
        <div class="form-control">
            <label>{{'CONTRACT_TYPE' | translate}}</label>
            <p-multiSelect [defaultLabel]="'CONTRACT_TYPE' | translate" [options]="contractTypeListFilter"
                appendTo="body" [(ngModel)]="filter.contractTypeList" [title]="'CONTRACT_TYPE' | translate"
                [filter]=false selectedItemsLabel="{0} itens selecionados">
            </p-multiSelect>
        </div>
        <div class="form-control">
            <label for="active">{{'SERVICE-STATUS' | translate}}</label>
            <p-dropdown [(ngModel)]="filter.active" name="active" appendTo="body"
                [options]="[{label: 'Ambos', value: null}, {label: 'Ativo', value: true}, {label: 'Inativo', value: false}]">
            </p-dropdown>
        </div>
        <div class="buttons">
            <div class="form-button">
                <button (click)="clear()" class="easymarine danger" type="button">{{ 'CLEAR' | translate
                    }}</button>
            </div>
            <div class="form-button">
                <button class="easymarine primary" (click)="findServiceBoats()" type="button">{{ 'FIND' |
                    translate
                    }}</button>
            </div>
        </div>
    </div>
</div>

<div class="table">
    <p-table #serviceBoatTable [value]="serviceBoats" class="primeTable" [paginator]="true" autoLayout="true"
        [alwaysShowPaginator]="false"  [rowsPerPageOptions]="[10,20,30,50,100]" [pageLinks]="3"
        styleClass="easy-table" [globalFilterFields]="['serviceName','boatName']" [lazy]="true"
        [rows]="paginationFilter.size" [totalRecords]="totalRecords" (onLazyLoad)="onChangePage($event)">
        <ng-template pTemplate="caption">
            <div></div>
            <span style="font-weight: bold">{{ 'TOTAL-REGISTERS' | translate }}:
                {{serviceBoats.length}} {{'OF' | translate}}
                {{serviceBoatTable.totalRecords}}</span>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="boatName">
                    Embarcação
                    <p-sortIcon field="boatName"></p-sortIcon>
                </th>
                <th pSortableColumn="serviceName">
                    Serviço
                    <p-sortIcon field="serviceName"></p-sortIcon>
                </th>
                <th pSortableColumn="contractStartDate">
                    Inicio do serviço
                    <p-sortIcon field="contractStartDate"></p-sortIcon>
                </th>
                <th pSortableColumn="contractEndDate">
                    Termino do serviço
                    <p-sortIcon field="contractEndDate"></p-sortIcon>
                </th>
                <th pSortableColumn="status">
                    Status
                    <p-sortIcon field="status"></p-sortIcon>
                </th>
                <th pSortableColumn="contractType">
                    {{ 'CONTRACT_TYPE' | translate }}
                    <p-sortIcon field="contractType"></p-sortIcon>
                </th>
                <th>
                    Visualizar
                </th>
                <th>
                    Principal/Aditivo
                </th>
                <th>
                    Distrato
                </th>
                <th>Serviço</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-serviceBoat>
            <tr>
                <td>{{serviceBoat.boatName}}</td>
                <td>{{serviceBoat.serviceName}}</td>
                <td>{{ serviceBoat.contractStartDate | date: 'dd/MM/yyyy'}}</td>
                <td>{{ serviceBoat.contractEndDate | date: 'dd/MM/yyyy'}}</td>
                <td>
                    <div style="display: flex;">
                        <div
                            [ngClass]="{
                'document-success': serviceBoat.status === 'SIGNED' || serviceBoat.status ==='INVALID',
                'document-danger': serviceBoat.status === 'CANCELED',
                'document-warning': serviceBoat.status === 'WAITING_SIGNATORIES' || serviceBoat.status === 'WAITING_SIGNATURES',
                'document-processing': serviceBoat.status === 'PROCESSING_SIGNATURES' || serviceBoat.status === 'PROCESSING_FILE'}">
                            <span>
                                {{ serviceBoat.status | translate}}
                            </span>
                        </div>
                    </div>
                </td>
                <td>
                    <span> {{ serviceBoat.contractType | translate}}</span>
                </td>
                <td class="text-center">
                    <i class="table-action" pTooltip="Visualizar contratos" (click)="openContractsModal(serviceBoat)"
                        *ngIf="serviceBoat.status" tooltipPosition="bottom">
                        <i class="pi pi-eye"></i>
                    </i>
                </td>
                <td class="text-center">
                    <i class="table-action" pTooltip="Adicionar aditivo"
                        (click)="openNewContractModal(serviceBoat, 'ADDITIVE')" tooltipPosition="bottom"
                        *ngIf="isEnabledCreateAdditive(serviceBoat)">
                        <i class="pi pi-paperclip"></i>
                    </i>
                    <i class="table-action" pTooltip="Adicionar contrato"
                        (click)="openNewContractModal(serviceBoat, 'MAIN')" tooltipPosition="bottom"
                        *ngIf="isEnabledCreateMain(serviceBoat)">
                        <i class="pi pi-file"></i>
                    </i>
                </td>
                <td class="text-center">
                    <i class="table-action" pTooltip="Adicionar distrato"
                        (click)="openNewContractModal(serviceBoat, 'TERMINATION')"
                        *ngIf="isEnabledCreateTermination(serviceBoat)"
                        tooltipPosition="bottom">
                        <i class="pi pi-times"></i>
                    </i>
                </td>
                <td class="text-center">
                    <span *ngIf="serviceBoat.active" class="badge badge-pill success" style="cursor: default;">{{ 'ACTIVE' |
                    translate }}</span>
                    <span *ngIf="!serviceBoat.active" class="badge badge-pill danger" style="cursor: default;">{{ 'INACTIVE' |
                    translate }}</span>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
