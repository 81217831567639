import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProductCategory } from '../models';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class ProductCategoryService {

    constructor(private http: HttpClient) {
    }

    getAll(): Observable<ProductCategory[]> {
        return this.http.get(
            environment.apiHost + '/secure/productCategories/'
        ).pipe(map((response: any) => response.data.productCategories as ProductCategory[]));
    }


}
