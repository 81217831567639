export class SecuritiesPropertyFilter {
  currentCustomerId:number;
  marinaId:number;
  id:number;
  numberId:number;
  active:boolean;
  lastAssociationStart:Date;
  lastAssociationEnd:Date;
  currentCustomerIdOld: number;

  constructor() {
    this.currentCustomerId = null;
    this.marinaId = null;
    this.id = null;
    this.numberId = null;
    this.active = null;
    this.lastAssociationStart = null;
    this.lastAssociationEnd = null;
    this.currentCustomerIdOld=null;
  }

}


