import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BoatSailorSimples, BoatSimples } from 'src/app/models/dtos/operational-options/responsibles';
import { BoatSailorPermission } from 'src/app/models/enums/boat-sailor-permission';

@Component({
  selector: 'app-movement-select-sailor',
  templateUrl: './movement-select-sailor.component.html',
  styleUrls: ['./movement-select-sailor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MovementSelectSailorComponent),
      multi: true
    }
  ]
})
export class MovementSelectSailorComponent implements OnChanges, ControlValueAccessor {
  sailor: any;

  private onChange: any = () => {};
  onTouched: any = () => {};
  disabled: boolean;

  sailorsFilter: { label: string, value: any, disabled?: boolean }[];

  @Input()
  boat: BoatSimples = null;

  constructor() { }

  writeValue(obj: any): void {
    this.sailor = obj;
    if (this.sailor) {
      if (!this.sailorsFilter || this.sailorsFilter.filter(({label, value}) => value && value.id === this.sailor.id).length <= 0) {
        this.emitChanges(null);
      }
    }
  }

  registerOnChange(fn: any): void { this.onChange = fn }
  registerOnTouched(fn: any): void { this.onTouched = fn }
  setDisabledState?(isDisabled: boolean): void { this.disabled = isDisabled }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.boat && changes.boat.currentValue !== changes.boat.previousValue) {
      this.emitChanges(null);
      this.populateSailorFilter(this.boat?.boatSailors ? this.boat.boatSailors : []);
    }
  }

  populateSailorFilter(boatSailors: BoatSailorSimples[]) {
    const sailorOptions = boatSailors
    .filter(({ permission }: BoatSailorSimples) =>
        permission.includes(BoatSailorPermission.SlingCreate))
    .map(({ sailor }: BoatSailorSimples) => ({ label: sailor.name, value: sailor }));
    if (sailorOptions.length > 0) {
      sailorOptions.unshift({label: 'Selecione...', value: null})
      this.sailorsFilter = sailorOptions;
    } else {
      this.sailorsFilter = null;
    }
  }

  selectSailor(event: { value: { id: number, nane: string }}): void {
    this.emitChanges(event.value);
  }

  emitChanges(sailor: any) {
    this.sailor = sailor;
    this.onChange(sailor);
  }
}
