import { FuelTankFraction } from './fuel-tank-fraction';
import { Product } from './product';
import { MarinaOrder } from './marina-order';

export class OrderItem {
    id: number;
    qtyRequested: number;
    qtySupplied: number;
    itemValue: number;
    totalValue: number;
    itemStatus: boolean;
    itemObs: string;
    fuelTankFraction: FuelTankFraction;
    fuelMeasurementType: string;
    product: Product;
    qtyStock: number;
    marinaOrder: MarinaOrder;
    dateSupply?: any;
    createdAt?: any;
    dateReference: Date;
    duedateDiscount: number;
    split?: boolean;
    constructor() {
        this.product = new Product();
    }
}
