import { Component, Input, OnInit, OnChanges, forwardRef } from '@angular/core';
import { Vacancy } from 'src/app/models';
import { VacancyService } from 'src/app/services/vacancy.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MovementLocation } from '../../../../../models/movement/movement-location';
import { VacancyBoatDTO } from '../../../../../models/dtos/vacancy-boat-dto';

@Component({
  selector: 'app-movement-select-origin-vacancy',
  templateUrl: './movement-select-origin-vacancy.component.html',
  styleUrls: ['./movement-select-origin-vacancy.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MovementSelectOriginVacancyComponent),
      multi: true,
    },
  ],
})
export class MovementSelectOriginVacancyComponent
  implements OnInit, OnChanges, ControlValueAccessor
{
  constructor(private vacancyService: VacancyService) {}
  @Input() movementLocation: MovementLocation;
  @Input() boat;
  @Input() form;
  vacancies: { label: string; value: Vacancy }[];
  vacanciesListTotal: { label: string; value: Vacancy }[];
  vacancy: Vacancy;
  disabled: boolean;

  private onChange: any = () => {};
  onTouched: any = () => {};

  writeValue(obj: any): void {
    this.vacancy = obj;
    this.emitChanges(obj);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    this.loadVacancies();
  }
  emitChanges(vacancy: any) {
    this.vacancy = vacancy;
    this.onChange(vacancy);
  }

  selectVacancyOrigin(event): any{
    this.emitChanges(event.value)
  }

  ngOnChanges() {
    if(this.movementLocation && this.vacanciesListTotal){
      this.filterVacanciesByLocalMovment();
    }
    if(this.vacancies?.length === 1){
      this.emitChanges(null);
      this.vacancy = null;
    }
  }
  private loadVacancies(): void {
    this.vacancyService.getAllToBoats().subscribe(
      (data) => {
        this.vacanciesListTotal = data.map((d) => ({
          label: d.code,
          value: d,
        }));
        this.filterVacanciesByLocalMovment();
        this.changeActualVacancy();
      },
      (err) => {
        this.vacancies = null;
      }
    );
  }
  filterVacanciesByLocalMovment() {
    this.vacancies = null;
    if (this.movementLocation && this.vacanciesListTotal) {
      this.vacancies = this.vacanciesListTotal.filter(
        (vacancy) =>
          vacancy.value.movementLocation.name === this.movementLocation.name
      );
      this.addWithoutVacancyOption(this.vacancies);
    }
  }

  addWithoutVacancyOption(options: { label: string; value: Vacancy }[]) {
    options.unshift({ label: 'Sem vaga', value: null });
  }

  changeActualVacancy() {
    if (this.boat?.currentLocation?.vacancy != null) {
      const vacancy = this.vacanciesListTotal.find(
        (vacancy) => vacancy.value.id === this.boat.currentLocation.vacancy.id
      );
      if (vacancy){
        this.vacancy = vacancy.value;
      }else{
        this.vacancy = this.vacanciesListTotal[0].value;
      }
      this.emitChanges(this.vacancy);
    }
  }
}
