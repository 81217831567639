<p-fieldset>
  <p-header class="article-title">
    <span class="article-title"
      >{{ "TITLE-MOVEMENTS" | translate }} > Cadastro</span
    >
    <div class="header-buttons"></div>
  </p-header>

  <form
    [formGroup]="stockMovementForm"
    class="filter-row dialog-form"
    style="padding: 0px"
  >
    <p-fieldset class="newfield">
      <p-header class="article-title">
        <div class="article-title headerNew">Dados da Movimentação</div>
      </p-header>
      <div class="filter-row d-flex" style="display: flex">
        <div class="form-control" style="min-width: 0px !important">
          <label>{{ "TYPE" | translate }}</label>
          <p-dropdown
            placeholder="Selecione..."
            required
            [filter]="true"
            [options]="stockMovementTypesList"
            formControlName="stockMovementType"
            optionValue="value"
            optionLabel="label"
            appendTo="body"
            name="stockMovementTypesLiId"
          >
          </p-dropdown>
        </div>

        <div
          class="form-control"
          style="min-width: 0px !important"
          *ngIf="
            stockMovementForm.get('stockMovementType').value?.type === 'OUT'
          "
        >
          <label>{{ "CUSTOMER" | translate }}</label>
          <p-dropdown
            placeholder="Selecione..."
            [filter]="true"
            [options]="customerList"
            optionValue="value"
            optionLabel="label"
            appendTo="body"
            formControlName="customerId"
            name="selectedCustomerId"
          >
          </p-dropdown>
        </div>

        <div
          class="form-control"
          style="min-width: 0px !important"
          *ngIf="
            stockMovementForm.get('stockMovementType').value?.type === 'IN'
          "
        >
          <label>{{ "SUPPLIER" | translate }}</label>
          <p-dropdown
            placeholder="Selecione..."
            [filter]="true"
            [options]="supplierList"
            optionValue="value"
            optionLabel="label"
            appendTo="body"
            formControlName="supplierId"
            name="selectedSupplierId"
          >
          </p-dropdown>
        </div>

        <div class="form-control" style="min-width: 0px !important">
          <label for="date">{{ "Emissão" | translate }}</label>
          <p-calendar
            dateFormat="dd/mm/yy"
            name="birthday"
            [monthNavigator]="true"
            [yearNavigator]="true"
            [yearRange]="'1940:2055'"
            rPCalendarMask
            appendTo="body"
            formControlName="createAt"
            [showIcon]="true"
            i18n
            [readonlyInput]="true"
            [disabled]="true"
          >
          </p-calendar>
        </div>

        <div
          class="form-control"
          style="min-width: 0px !important"
          *ngIf="stockMovementForm.get('active').value === false"
        >
          <label for="cancelAt">{{ "Cancelamento" | translate }}</label>
          <p-calendar
            dateFormat="dd/mm/yy"
            name="birthday"
            [monthNavigator]="true"
            [yearNavigator]="true"
            [yearRange]="'1940:2055'"
            rPCalendarMask
            appendTo="body"
            formControlName="cancelAt"
            [showIcon]="true"
            i18n
            [readonlyInput]="true"
          >
          </p-calendar>
        </div>
      </div>
    </p-fieldset>
    <br />
    <p-fieldset class="newfield">
      <p-header class="article-title headerNew">
        <div class="article-title headerNew">Itens Movimentação</div>
      </p-header>

      <div class="form-group">
        <div class="form-control" style="min-width: 0px !important">
          <label>
            <span>{{ "PRODUCT" | translate }}</span>
          </label>
          <p-dropdown
            placeholder="Selecione..."
            [filter]="true"
            [options]="productList"
            optionValue="value"
            optionLabel="label"
            appendTo="body"
            [(ngModel)]="selectedProduct"
            [ngModelOptions]="{ standalone: true }"
            (onChange)="onChangeProduct($event)"
            name="selectedSupplierId"
          >
          </p-dropdown>
        </div>

        <div class="form-control" style="min-width: 0px !important">
          <label
            >{{ "QUANTITY" | translate }}
            <span
              style="margin-left: 8px !important; color: deepskyblue"
              *ngIf="selectedProduct"
              >Est:</span
            >
            <span
              style="margin-left: 2px !important; color: deepskyblue"
              *ngIf="selectedProduct"
              >{{ selectedProduct.stock | number }}</span
            >
          </label>
          <input
            type="number"
            pInputText
            [(ngModel)]="quantity"
            [ngModelOptions]="{ standalone: true }"
            name="quantity"
            [readonly]="false"
            min="0"
            (ngModelChange)="onTotalChange()"
          />
        </div>

        <div class="form-control" style="min-width: 0px !important">
          <label
            >{{ "PRICE" | translate }}
            <span
              *ngIf="selectedProduct"
              style="margin-left: 8px !important; color: red"
              >Custo:</span
            >
            <span
              style="margin-left: 4px !important; color: red"
              *ngIf="selectedProduct"
              >{{ selectedProduct.priceCust | currency : "BRL" }}</span
            >
            <span
              *ngIf="selectedProduct"
              style="margin-left: 8px !important; color: darkseagreen"
              >Venda:</span
            >
            <span
              style="margin-left: 4px !important; color: darkseagreen"
              *ngIf="selectedProduct"
              >{{ selectedProduct.price.value | currency : "BRL" }}</span
            ></label
          >
          <input
            type="number"
            pInputText
            [(ngModel)]="price"
            [ngModelOptions]="{ standalone: true }"
            name="price"
            [readonly]="false"
            min="0"
            (ngModelChange)="onTotalChange()"
          />
        </div>

        <div class="form-control form-menor">
          <label>{{ "DISCOUNT" | translate }} </label>
          <input
            type="number"
            pInputText
            [(ngModel)]="discount"
            [ngModelOptions]="{ standalone: true }"
            name="discount"
            [readonly]="false"
            min="0"
            (ngModelChange)="onTotalChange()"
          />
        </div>

        <div class="form-control form-menor">
          <label>{{ "TOTAL" | translate }}</label>
          <input
            type="number"
            pInputText
            [(ngModel)]="total"
            [ngModelOptions]="{ standalone: true }"
            name="total"
            [readonly]="true"
            min="0"
          />
        </div>

        <div class="form-control form-menor">
          <label>{{ "Novo Saldo" | translate }}</label>
          <input
            type="number"
            pInputText
            [(ngModel)]="stockNew"
            [ngModelOptions]="{ standalone: true }"
            name="stockNew"
            [readonly]="true"
            min="0"
          />
        </div>

        <div
          class="form-control form-menor"
          *ngIf="stockMovementForm.get('id').value === null"
        >
          <label> &nbsp;</label>
          <button
            class="easymarine primary"
            (click)="addItem()"
            style="margin-top: 3px"
          >
            {{ "Adicionar" | translate }}
          </button>
        </div>
      </div>
      <br />
      <div class="table">
        <p-table
          #itensTable
          [value]="stockMovementForm.get('stockMovementItems').value"
          [paginator]="false"
          [lazy]="false"
          dataKey="productId"
          styleClass="easy-table"
          autoLayout="true"
        >
          <ng-template pTemplate="caption">
            <span style="font-weight: bold; margin-top: 5px">
              {{ "Total de Itens" | translate }}:
              {{ stockMovementForm.get("totalItem").value }}
              {{ "Valor Total" | translate }}
              {{
                stockMovementForm.get("movementValue").value | currency : "BRL"
              }}
            </span>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th class="text-center">{{ "PRODUCT" | translate }}</th>
              <th class="text-center">{{ "QUANTITY" | translate }}</th>
              <th class="text-center">{{ "PRICE" | translate }}</th>
              <th class="text-center">{{ "DISCOUNT" | translate }}</th>
              <th class="text-center">{{ "TOTAL" | translate }}</th>
              <th class="text-center">{{ "BALANCE" | translate }}</th>
              <th class="text-center">{{ "DELETE" | translate }}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item let-i="rowIndex">
            <tr>
              <td>{{ item.productName }}</td>
              <td class="text-center">
                <input
                  type="number"
                  pInputText
                  [(ngModel)]="item.amount"
                  [ngModelOptions]="{ standalone: true }"
                  name="amount"
                  [readonly]="false"
                  min="0"
                  (ngModelChange)="onLineTotalChange(item)"
                  *ngIf="stockMovementForm.get('id').value === null"
                />
                <span *ngIf="stockMovementForm.get('id').value !== null">{{
                  item.amount
                }}</span>
              </td>
              <td class="text-center">
                <input
                  type="number"
                  pInputText
                  [(ngModel)]="item.productValue"
                  [ngModelOptions]="{ standalone: true }"
                  name="productValue"
                  [readonly]="false"
                  min="0"
                  (ngModelChange)="onLineTotalChange(item)"
                  *ngIf="stockMovementForm.get('id').value === null"
                />
                <span *ngIf="stockMovementForm.get('id').value !== null">{{
                  item.productValue | currency : "BRL"
                }}</span>
              </td>
              <td class="text-center">
                <input
                  type="number"
                  pInputText
                  [(ngModel)]="item.discount"
                  [ngModelOptions]="{ standalone: true }"
                  name="discount"
                  [readonly]="false"
                  min="0"
                  (ngModelChange)="onLineTotalChange(item)"
                  *ngIf="stockMovementForm.get('id').value === null"
                />
                <span *ngIf="stockMovementForm.get('id').value !== null">{{
                  item.discount | currency : "BRL"
                }}</span>
              </td>
              <td class="text-center">{{ item.total | currency : "BRL" }}</td>
              <td class="text-center">{{ item.stockAfterMovement }}</td>
              <td class="text-center">
                <div
                  *ngIf="stockMovementForm.get('id').value === null"
                  class="table-action"
                  (click)="removeItem(i)"
                  pTooltip="Cancelar"
                  tooltipPosition="bottom"
                >
                  <i class="pi pi-trash"></i>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <br />
      <div class="buttons">
        <div class="form-button">
          <button (click)="return()" class="easymarine danger" type="button">
            {{ "Voltar" | translate }}
          </button>
        </div>
        <div
          class="form-button"
          *ngIf="stockMovementForm.get('id').value === null"
        >
          <button class="easymarine primary" (click)="save()">
            {{ "Salvar" | translate }}
          </button>
        </div>
      </div>
    </p-fieldset>
  </form>
</p-fieldset>
