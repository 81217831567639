import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OperationCloseDay } from 'src/app/models/operation-close-day';

@Component({
  selector: 'app-operation-close-day',
  templateUrl: './operation-close-day.component.html',
  styleUrls: ['./operation-close-day.component.scss']
})
export class OperationCloseDayComponent implements OnInit {

  @Input() closeDays: OperationCloseDay[] = [];
  @Output() deleteEvent: EventEmitter<OperationCloseDay> = new EventEmitter();

  constructor( ) { }

  ngOnInit(): void {
    
  }

  newCloseDay() {
    this.closeDays.push(new OperationCloseDay());
  }

  delete(closeDay){
    this.deleteEvent.emit(closeDay);
  }
}
