import { map } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import {
  Component,
  OnInit,
} from '@angular/core';
import { UntypedFormControl, NgForm } from '@angular/forms';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
  Marina,
  PlanoConta,
  SlingConfig,
  SlingTime,
  User,
} from 'src/app/models';
import { EmailCopy } from 'src/app/models/email-copy';
import { FinancialMultipleCompaniesType } from 'src/app/models/enums/finances/financial-multiple-companies-type';
import { TipoFormaPagamento } from 'src/app/models/finance/tipo-forma-pagamento';
import { FrequentDestination } from 'src/app/models/frequent-destination';
import { MarinaCompany } from 'src/app/models/marina-company';
import { StockMovementType } from 'src/app/models/stock/stock-movement-type';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { OperationalConfigService } from 'src/app/services/config/operational-config.service';
import { FinancesService } from 'src/app/services/finances.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';
import { DeepCloneUtil } from 'src/app/utils/deep-clone.util';
import { FormUtil } from 'src/app/utils/form.util';
import { FormatUtil } from 'src/app/utils/format.util';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';
import Swal from 'sweetalert2';
import { SelectItem } from 'primeng/api';
import { MovementTypeService } from 'src/app/services/stock/movement-type.service';
import { ProductService } from 'src/app/services/product.service';
import { ProductType } from 'src/app/models/enums';
import { PrincingType } from 'src/app/models/pricing-type';

declare var google: any;

@Component({
  selector: 'app-sling-config-form',
  templateUrl: './sling-config-form.component.html',
  styleUrls: ['./sling-config-form.component.scss'],
})
export class SlingConfigFormComponent implements OnInit {

  users = new Array();
  selectUser: User;
  usersFilter: any[] = new Array();
  daysDueList = new Array();
  daysDue = new UntypedFormControl();
  daysDueForm;
  numberOfRecordsPerPage = new UntypedFormControl();
  generalSMS = '';
  fuelTankFractions = [];
  useiInvites = false;

  optionsfranchiseProduct: SelectItem[] = [];

  public daysWeek: any[] = [
    'MONDAY',
    'TUESDAY',
    'WEDNESDAY',
    'THURSDAY',
    'FRIDAY',
    'SATURDAY',
    'SUNDAY',
  ];
  public slingConfig: SlingConfig;
  selectedWeek: any[] = [];

  cstDropdown = [
    { value: '00', label: '00 - Tributada integralmente' },
    { value: '01', label: '01 - Operação Tributável com Alíquota Básica' },
    { value: '02', label: '02 - Operação Tributável com Alíquota Diferenciada' },
    { value: '03', label: '03 - Operação Tributável com Alíquota por Unidade de Medida de Produto' },
    { value: '04', label: '04 - Operação Tributável Monofásica - Revenda a Alíquota Zero' },
    { value: '05', label: '05 - Operação Tributável por Substituição Tributária' },
    { value: '06', label: '06 - Operação Tributável a Alíquota Zero' },
    { value: '07', label: '07 - Operação Isenta da Contribuição' },
    { value: '08', label: '08 - Operação sem Incidência da Contribuição' },
    { value: '09', label: '09 - Operação com Suspensão da Contribuição' },
    { value: '49', label: '49 - Outras Operações de Saída' },
    { value: '50', label: '50 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita Tributada no Mercado Interno' },

    { value: '51', label: '51 - Operação com Direito a Crédito – Vinculada Exclusivamente a Receita Não Tributada no Mercado Interno' },
    { value: '52', label: '52 - Operação com Direito a Crédito - Vinculada Exclusivamente a Receita de Exportação' },
    { value: '53', label: '53 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno' },
    { value: '54', label: '54 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas no Mercado Interno e de Exportação' },
    { value: '55', label: '55 - Operação com Direito a Crédito - Vinculada a Receitas Não-Tributadas no Mercado Interno e de Exportação' },
    { value: '56', label: '56 - Operação com Direito a Crédito - Vinculada a Receitas Tributadas e Não-Tributadas no Mercado Interno, e de Exportação' },
  ];
  optionWashAndTurnEngine = [
    { value: 'NoAction', label: 'Sem ação' },
    { value: 'AutoRegistration', label: 'Registro automático' },
    { value: 'AutomaticScheduling', label: 'Agendamento automático' },
  ];

  optionsPrintQuestionTotem = [
    { value: 'NoAction', label: 'Sem ação' },
    { value: 'Question', label: 'Questionar' },
    { value: 'AutoPrint', label: 'Impressão automática' },
  ];

  optionsTypeBlockCustomer = [
    {value: 'customer', label: 'Por Cliente'},
    {value: 'boat', label: 'Por Embarcação'}
  ];

  numbersOfRecordsPerPage = [
    { value: 10, label: '10' },
    { value: 20, label: '20' },
    { value: 30, label: '30' },
    { value: 50, label: '50' },
    { value: 100, label: '100' },
    { value: 200, label: '200' },
    { value: 500, label: '500' },
  ];

  timeZones = [
    { value: 2, label: 'BRT +1	Fernando de Noronha Time	Fernando de Noronha' },
    { value: 3, label: 'BRT	Brasília Time	São Paulo' },
    { value: 4, label: 'BRT -1 Amazon Time	Manaus' },
    { value: 5, label: 'BRT -2 Acre Time	Rio Branco' },
  ];

  competencyDropdown=[
    { value: 0 , label:'Selecione uma competência'},
    { value: 1 , label:'Data de emissâo da fatura'},
    { value: 2, label: 'Data de vencimento da fatura' },
    { value: 3, label: 'Data de competência da fatura' },
    { value: 4, label: 'Data de emissão da nota fiscal' },
  ]

  competencyJurosDropdown=[
    { value: 0 , label:'Data de emissão da fatura'},
    { value: 1 , label:'Data de competência da fatura'},
    { value: 2 , label:'Data de vencimento da fatura '},
    { value: 3, label: 'Data de pagamento da fatura' },
  ];
  competencyDiscountDropdown=[
    { value: 0 , label:'Data de emissão da fatura'},
    { value: 1 , label:'Data de competência da fatura'},
    { value: 2 , label:'Data de vencimento da fatura '},
    { value: 3, label: 'Data de pagamento da fatura' },
  ]

  pt = {
    firstDayOfWeek: 0,
    dayNames: [
      'Domingo',
      'Segunda',
      'Terça',
      'Quarta',
      'Quinta',
      'Sexta',
      'Sábado',
    ],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    monthNamesShort: [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ],
    today: 'Hoje',
    clear: 'Limpar',
  };

  paymentMethods: { method: any; company?: MarinaCompany }[];
  paymentMethodsDropdown: any[];
  accountDropdown: any[];
  marinaCompanies: MarinaCompany[] = [];
  marina: Marina;
  currentMarina: Marina;
  accountPlans: PlanoConta[];
  accountPlansDropdown: Array<{ label: string; value: number; }>;
  anticipationOldStatus: boolean;
  hasContractsOldStatus: boolean;

  stockMovementInOptions: SelectItem[] = [];
  stockMovementOutOptions: SelectItem[] = [];

  userHierarchy: number = undefined

  isSlingMarina: boolean = true;
  isMovements: boolean;
  productNF: boolean = true;

  constructor(
    private slingConfigService: SlingConfigService,
    private messageUtil: MessageUtil,
    public dialogRef: DynamicDialogRef,
    private spinner: NgxSpinnerService,
    private financesService: FinancesService,
    private toasty: ToastService,
    private userService: UserService,
    private config: DynamicDialogConfig,
    private datePipe: DatePipe,
    private readonly operationalConfigService: OperationalConfigService,
    private movementTypeService : MovementTypeService,
    private productService: ProductService
  ) {
    this.datePipe = new DatePipe('pt-BR');
    this.currentMarina = StorageUtil.getMarina();
  }

  async getmovements(): Promise<void> {
    this.movementTypeService.getAll().subscribe((data) => {
      this.stockMovementInOptions = data
        .filter((movement) => movement.active && movement.type === 'IN')
        .map((movement) => ({ label: movement.name, value: movement.id }));
      this.stockMovementOutOptions = data
        .filter((movement) => movement.active && movement.type === 'OUT')
        .map((movement) => ({ label: movement.name, value: movement.id }));
    });
  }


  async ngOnInit(): Promise<void> {
    this.getOperationalConfig();

    await this.getmovements();
    this.isSlingMarina = !this.isMovements;

    this.userService.getById(StorageUtil.getUserId()).toPromise()
      .then((user) => this.userHierarchy = user.roleId.hierarchy)
      .catch((err) => this.userHierarchy = undefined);
    this.generateDaysDue();
    if (this.config.data) {
      // if (this.config.data.slingConfig && this.config.data.slingConfig.id){
      // this.slingConfig = this.config.data.slingConfig;
      this.slingConfig = this.prepareEdit({ ...this.config.data.slingConfig });
      // }
      this.marinaCompanies = this.config.data.marinaCompanies;
      await this.configurePaymentMethods();
      this.getAccountPlans();
    }
    this.findUsersMarina();

    // fills the days of the week in a new period
    if (
      !this.slingConfig.slingTimes ||
      this.slingConfig.slingTimes.length === 0
    ) {
      this.daysWeek.forEach((day) => {
        const slingTime = new SlingTime();
        slingTime.dayWeek = day;
        this.slingConfig.slingTimes.push(slingTime);
      });
    }

    this.loadProduct();
  }

  loadProduct(): void {
    this.productService.getByProductType(ProductType.Service).subscribe((data) => {
      const result = data.filter((product) => product.active
                                            && product.pricingType===PrincingType.SimplePrice
                                            && product.price.priceFree===false );
      this.optionsfranchiseProduct = data.map((result) => ({ label: result.name, value: result.id }));
    });
  }

  generateDaysDue(): void {
    for (let day = 1; day <= 31; day++) {
      this.daysDueList.push({ label: day.toString(), value: day.toString() });
    }
  }

  addselectUser(): void {
    if (
      this.slingConfig.users.find((u) => u.id === this.selectUser.id) ===
      undefined
    ) {
      this.slingConfig.users.push(this.selectUser);
    }
  }

  removeUser(user: User): void {
    this.slingConfig.users = this.slingConfig.users.filter(
      (u) => u.id !== user.id
    );
  }

  save(slingConfigsForm: NgForm): void {
    if (this.validations(slingConfigsForm)) {
      if (this.slingConfig.sendEmailCopy) {
        slingConfigsForm.control.controls['slingConfig.emailCopy'].setErrors(
          null
        );
      } else {
        this.slingConfig.emailsCopy = new Array();
      }
      const config = DeepCloneUtil.clone(this.slingConfig);
      if (
        !slingConfigsForm.valid ||
        this.daysDueForm === undefined ||
        this.daysDueForm.length === 0 ||
        (this.slingConfig.sendEmailCopy &&
          this.slingConfig.emailsCopy.length < 1)
      ) {
        this.messageUtil.generateMessage(
          'warning',
          'SUMMARY.WARNING',
          'MSG.FIELD-REQUIRED'
        );
        return;
      }
      this.preperaSlingConfiguration(config);
      this.convertDateFormats(config);
      this.spinner.show();
      this.slingConfigService.save(config).subscribe(
        (data) => {
          this.toasty.success('Configurações salva com sucesso.');
          this.dialogRef.close();
          if (this.anticipationOldStatus !== data.anticipation) {
            BroadcastService.get('changeAnticipation').emit(data.anticipation);
          }

          if(this.hasContractsOldStatus != data.hasContracts){
            BroadcastService.get('changeHasContracts').emit(data.hasContracts);
          }
        },
        (error) => {
          this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(
            exception.type,
            exception.title,
            exception.message
          );
        },
        () => {
          this.saveCompanyAccount();
          this.spinner.hide();
        }
      );
    }
  }

  saveCompanyAccount(): void {
    const companyDtoList: any[] = this.marinaCompanies.map((company) => {
      const marinaCompanyAccountdto = {
        id: company.id,
        accountId: company.accountId,
        accountName: company.accountDropdown.find(
          (account) => account.value === company.accountId
        ).label,
        paymentMethodId: company.paymentMethodId,
        cityTax: company.cityTax,
        paymentMethodName: company.paymentMethodsDropdown.find(
          (method) => method.value === company.paymentMethodId
        ).label,
      };
      return marinaCompanyAccountdto;
    });

    let updateAccountRet: any[];
    this.slingConfigService
      .updateMarinaCompaniesAccount(companyDtoList)
      .subscribe(
        (data) => {
          updateAccountRet = data;
        },
        (error) => {},
        () => {
          const marina: any = StorageUtil.getMarina();
          marina.marinaCompanies = updateAccountRet;
          StorageUtil.setMarina(marina);
        }
      );
  }

  convertDateFormats(config): void {
    this.convertDateSlingConfig(config);
    this.convertDateSpecialDay(config);
    this.convertDateCloseDay(config);
  }

  convertDateSlingConfig(config): void {
    config.operationEnd = this.convertToFormatDate(config.operationEnd);
    config.operationStart = this.convertToFormatDate(config.operationStart);
    config.slingTimes.forEach((st) => {
      st.operationEnd = this.convertToFormatDate(st.operationEnd);
      st.operationStart = this.convertToFormatDate(st.operationStart);
      st.slingDownEnd = this.convertToFormatDate(st.slingDownEnd);
      st.slingDownStart = this.convertToFormatDate(st.slingDownStart);
      st.intervalEnd = this.convertToFormatDate(st.intervalEnd);
      st.intervalStart = this.convertToFormatDate(st.intervalStart);
      st.slingReturnEnd = this.convertToFormatDate(st.slingReturnEnd);
    });
  }

  convertDateSpecialDay(config): void {
    config.operationSpecialDays.forEach((time) => {
      time.date = this.convertToFormatDate(time.date);

      if (time.operationStart) {
        time.operationStart = this.convertToFormatDate(time.operationStart);
      }
      if (time.operationEnd) {
        time.operationEnd = this.convertToFormatDate(time.operationEnd);
      }
      if (time.slingDownStart) {
        time.slingDownStart = this.convertToFormatDate(time.slingDownStart);
      }
      if (time.intervalStart) {
        time.intervalStart = this.convertToFormatDate(time.intervalStart);
      }
      if (time.intervalEnd) {
        time.intervalEnd = this.convertToFormatDate(time.intervalEnd);
      }
      if (time.slingDownEnd) {
        time.slingDownEnd = this.convertToFormatDate(time.slingDownEnd);
      }
      if (time.slingReturnEnd) {
        time.slingReturnEnd = this.convertToFormatDate(time.slingReturnEnd);
      }
    });
  }

  convertDateCloseDay(config): void {
    config.operationCloseDays.forEach((time) => {
      time.date = this.convertToFormatDate(time.date);
    });
  }

  convertToFormatDate(date): string {
    return this.datePipe.transform(date, 'yyyy-MM-ddTHH:mm');
    // return moment(date).format('yyyy-MM-ddTHH:mm');
  }

  validations(form: NgForm): boolean {
    let rr = true;
    FormUtil.touchAndSendForm(form);
    this.slingConfig.slingTimes.forEach((time) => {
      if (time.inOperation) {
        if (
          !time.operationStart ||
          !time.operationEnd ||
          !time.slingDownStart ||
          !time.slingDownEnd ||
          !time.executionTime ||
          !time.slingReturnEnd
        ) {
          Swal.fire(
            'Campos obrigatórios',
            'Os campos abaixo são obrigatórios no cadastro de horários no período para o dia selecionado!' +
              '<br><br>' +
              '* Abertura da Marina<br>' +
              '* Fechamento da Marina<br>' +
              '* Início da lingada<br>' +
              '* Fim da lingada<br>' +
              '* Horário de retorno<br>' +
              '* Tempo da lingada'
          );
          rr = false;
        } else if (
          (time.intervalStart && !time.intervalEnd) ||
          (!time.intervalStart && time.intervalEnd)
        ) {
          Swal.fire(
            'Campos obrigatórios',
            'Caso tenha intervalo no horário selecionado, os campos abaixo são obrigatórios!' +
              '<br><br>' +
              '* Intervalo Início<br>' +
              '* Intervalo Fim'
          );
          rr = false;
        }
      }
    });

    this.slingConfig.operationCloseDays.forEach((time) => {
      if (!time.date) {
        Swal.fire('Campos obrigatórios', 'Selecione a data do dia fechado.');
        rr = false;
      }
    });

    this.slingConfig.operationSpecialDays.forEach((time) => {
      if (!time.date) {
        Swal.fire(
          'Campos obrigatórios',
          'Selecione a data do dia com horário especial.'
        );
        rr = false;
      }
    });

    if (
      this.slingConfig.hasFractionalSupply &&
      this.fuelTankFractions.length === 0
    ) {
      Swal.fire(
        'Campos obrigatórios',
        'Selecione pelo menos uma opção de fração.'
      );
      rr = false;
    }

    if (!this.slingConfig.operationStart || !this.slingConfig.operationEnd) {
      Swal.fire(
        'Campos obrigatórios',
        'Selecione o início e fim do período de horários.'
      );
      rr = false;
    }

    if (
      !this.slingConfig.idPlanoContaInterest ||
      this.slingConfig.idPlanoContaInterest === 0
    ) {
      Swal.fire(
        'Campos obrigatórios',
        'Selecione o Plano de Conta de Juros/Multa.'
      );
      rr = false;
    }

    if (
      !this.slingConfig.idPlanoContaDiscount ||
      this.slingConfig.idPlanoContaDiscount === 0
    ) {
      Swal.fire(
        'Campos obrigatórios',
        'Selecione o Plano de Conta de Desconto.'
      );
      rr = false;
    }

    if (this.slingConfig.additionalInfo && this.slingConfig.additionalInfoValue === null){
      Swal.fire('Campos obrigatórios', 'Insira o valor em porcentagem da Informação Adicional');
      rr = false;
    }

    if (this.daysDueForm && (this.daysDueForm ===null|| this.daysDueForm === undefined)){
      Swal.fire('Campos obrigatórios', 'Selecione o dia de vencimento da fatura');
      rr = false;
    }

    if (this.numberOfRecordsPerPage && (this.numberOfRecordsPerPage ===null|| this.numberOfRecordsPerPage === undefined)){
      Swal.fire('Campos obrigatórios', 'Selecione o número de registros por página');
      rr = false;
    }

    return rr;
  }

  changeFractionSupply(): void {
    this.fuelTankFractions = [];
    this.slingConfig.fuelTankFractions = '';
  }

  preperaSlingConfiguration(config): void {
    if (!config.marina) {
      config.marina = new Marina();
      config.marina.id = StorageUtil.getMarinaId();
    }

    config.daysDue = this.daysDueForm.join(',');

    if (
      this.slingConfig.smsGeneralNotices != null &&
      typeof this.slingConfig.smsGeneralNotices === 'object'
    ) {
      config.smsGeneralNotices = this.slingConfig.smsGeneralNotices.join(',');
    }
    config.fuelTankFractions = this.fuelTankFractions.join();

    config.slingTimes.forEach((time) => {
      if (!time.inOperation) {
        time.inOperation = false;
      }

      if (time.operationStart) {
        time.operationStart = this.configureHour(
          config.operationStart,
          time.operationStart.toString()
        );
      }

      if (time.operationEnd) {
        time.operationEnd = this.configureHour(
          config.operationEnd,
          time.operationEnd.toString()
        );
      }

      if (time.slingDownStart) {
        time.slingDownStart = this.configureHour(
          config.operationStart,
          time.slingDownStart.toString()
        );
      }

      if (time.intervalStart) {
        time.intervalStart = this.configureHour(
          config.operationEnd,
          time.intervalStart.toString()
        );
      }

      if (time.intervalEnd) {
        time.intervalEnd = this.configureHour(
          config.operationStart,
          time.intervalEnd.toString()
        );
      }

      if (time.slingDownEnd) {
        time.slingDownEnd = this.configureHour(
          config.operationEnd,
          time.slingDownEnd.toString()
        );
      }

      if (time.slingReturnEnd) {
        time.slingReturnEnd = this.configureHour(
          config.operationEnd,
          time.slingReturnEnd.toString()
        );
      }
    });

    config.operationSpecialDays.forEach((time) => {
      time.date = new Date(
        time.date.setHours(
          FormatUtil.hourFromTime('12'),
          FormatUtil.minutesFromTime('00')
        )
      );

      if (time.operationStart) {
        time.operationStart = this.configureHour(
          time.date,
          time.operationStart.toString()
        );
      }

      if (time.operationEnd) {
        time.operationEnd = this.configureHour(
          time.date,
          time.operationEnd.toString()
        );
      }

      if (time.slingDownStart) {
        time.slingDownStart = this.configureHour(
          time.date,
          time.slingDownStart.toString()
        );
      }

      if (time.intervalStart) {
        time.intervalStart = this.configureHour(
          time.date,
          time.intervalStart.toString()
        );
      }

      if (time.intervalEnd) {
        time.intervalEnd = this.configureHour(
          time.date,
          time.intervalEnd.toString()
        );
      }

      if (time.slingDownEnd) {
        time.slingDownEnd = this.configureHour(
          time.date,
          time.slingDownEnd.toString()
        );
      }

      if (time.slingReturnEnd) {
        time.slingReturnEnd = this.configureHour(
          time.date,
          time.slingReturnEnd.toString()
        );
      }
    });

    config.operationCloseDays.forEach((time) => {
      time.date = new Date(
        time.date.setHours(
          FormatUtil.hourFromTime('12'),
          FormatUtil.minutesFromTime('00')
        )
      );
    });

    config.accountName = this.accountDropdown?.find(
      (account) => account.value === config.accountId
    ).label;
    config.paymentMethodName = this.paymentMethodsDropdown?.find(
      (method) => method.value === config.paymentMethodId
    ).label;
  }

  prepareEdit(slingConfig): SlingConfig {
    // fills the days of the week in a new period
    if (slingConfig.slingTimes.length === 0) {
      this.daysWeek.forEach((day) => {
        const slingTime = new SlingTime();
        slingTime.dayWeek = day;
        slingConfig.slingTimes.push(slingTime);
      });
    }

    this.anticipationOldStatus = slingConfig.anticipation;
    this.hasContractsOldStatus = slingConfig.hasContracts;


    this.fuelTankFractions = slingConfig.fuelTankFractions
      ? slingConfig.fuelTankFractions.split(',')
      : [];

    slingConfig.operationStart = slingConfig.operationStart
      ? new Date(slingConfig.operationStart)
      : null;
    slingConfig.operationEnd = slingConfig.operationEnd
      ? new Date(slingConfig.operationEnd)
      : null;
    // slingConfig.operationStart = slingConfig.operationStart ? moment(slingConfig.operationStart).format('DD/MM/YYYY') : null;
    // slingConfig.operationEnd = slingConfig.operationEnd ? moment(slingConfig.operationEnd).format('DD/MM/YYYY') : null;
    slingConfig.historyBeginDate = slingConfig.historyBeginDate
      ? new Date(slingConfig.historyBeginDate)
      : null;
    slingConfig.invoiceBeginDate = slingConfig.invoiceBeginDate
      ? new Date(slingConfig.invoiceBeginDate)
      : null;
    slingConfig.slingTimes.forEach((time) => {
      if (time.operationStart) {
        time.operationStart = moment(new Date(time.operationStart)).format(
          'HH:mm'
        );
      }

      if (time.operationEnd) {
        time.operationEnd = moment(new Date(time.operationEnd)).format('HH:mm');
      }

      if (time.slingDownStart) {
        time.slingDownStart = moment(new Date(time.slingDownStart)).format(
          'HH:mm'
        );
      }

      if (time.intervalStart) {
        time.intervalStart = moment(new Date(time.intervalStart)).format(
          'HH:mm'
        );
      }

      if (time.intervalEnd) {
        time.intervalEnd = moment(new Date(time.intervalEnd)).format('HH:mm');
      }

      if (time.slingDownEnd) {
        time.slingDownEnd = moment(new Date(time.slingDownEnd)).format('HH:mm');
      }

      if (time.slingReturnEnd) {
        time.slingReturnEnd = moment(new Date(time.slingReturnEnd)).format(
          'HH:mm'
        );
      }
    });

    slingConfig.operationSpecialDays.forEach((time) => {
      time.date = new Date(time.date);

      if (time.operationStart) {
        time.operationStart = moment(new Date(time.operationStart)).format(
          'HH:mm'
        );
      }

      if (time.operationEnd) {
        time.operationEnd = moment(new Date(time.operationEnd)).format('HH:mm');
      }

      if (time.slingDownStart) {
        time.slingDownStart = moment(new Date(time.slingDownStart)).format(
          'HH:mm'
        );
      }

      if (time.intervalStart) {
        time.intervalStart = moment(new Date(time.intervalStart)).format(
          'HH:mm'
        );
      }

      if (time.intervalEnd) {
        time.intervalEnd = moment(new Date(time.intervalEnd)).format('HH:mm');
      }

      if (time.slingDownEnd) {
        time.slingDownEnd = moment(new Date(time.slingDownEnd)).format('HH:mm');
      }

      if (time.slingReturnEnd) {
        time.slingReturnEnd = moment(new Date(time.slingReturnEnd)).format(
          'HH:mm'
        );
      }
    });

    slingConfig.operationCloseDays.forEach((time) => {
      time.date = new Date(time.date);
    });

    this.daysDueForm = slingConfig.daysDue
      ? slingConfig.daysDue.split(',')
      : [];

    if (
      slingConfig.smsGeneralNotices != null &&
      slingConfig.smsGeneralNotices.trim() !== ''
    ) {
      slingConfig.smsGeneralNotices = slingConfig.smsGeneralNotices.split(',');
    }
    return slingConfig;
  }

  private findUsersMarina(): void {
    this.spinner.show();
    this.userService.getMarinaUsers().subscribe(
      (users) => {
        this.users = users;
        this.usersFilter = this.users.map((u) => ({
          label: u.firstName,
          value: u,
        }));
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
      () => this.spinner.hide()
    );
  }

  configureHour(valueDate: any, hour: string): Date {
    return new Date(
      valueDate.setHours(
        FormatUtil.hourFromTime(hour),
        FormatUtil.minutesFromTime(hour)
      )
    );
  }

  hourFromDate(date: string): string {
    const newDate: any = new Date(date);
    return (
      FormatUtil.formatHourOrMinute(newDate.getHours()) +
      ':' +
      FormatUtil.formatHourOrMinute(newDate.getMinutes())
    );
  }

  convertForSelect(
    list: any[],
    labelName: any,
    labelValue: any
  ): { label: string; value: any }[] {
    return list.map((l) => ({ label: l[labelName], value: l[labelValue] }));
  }

  addGeneralSMS(): void {
    if (
      !this.slingConfig.smsGeneralNotices ||
      this.slingConfig.smsGeneralNotices.length === 0
    ) {
      this.slingConfig.smsGeneralNotices = new Array();
    }

    this.slingConfig.smsGeneralNotices.push(this.generalSMS);
    this.generalSMS = '';
  }

  isDisabledGeneralSMS(): boolean {
    return this.generalSMS.replace(/\D+/g, '') === '';
  }

  addFrequentDestination(field): void {
    if (!this.slingConfig.frequentFestinations) {
      this.slingConfig.frequentFestinations = new Array();
    }
    const frequentDestination = new FrequentDestination();
    frequentDestination.name = field.value;
    this.slingConfig.frequentFestinations.push(frequentDestination);
    field.value = '';
  }

  addEmailCopy(): void {
    if (!this.slingConfig.emailsCopy) {
      this.slingConfig.emailsCopy = new Array();
    }

    if (
      !this.slingConfig.emailsCopy.find(
        (ec) => ec.name === this.slingConfig.emailCopy
      )
    ) {
      const emailCopy = new EmailCopy();
      emailCopy.name = this.slingConfig.emailCopy;
      this.slingConfig.emailsCopy.push(emailCopy);
    }
    this.slingConfig.emailCopy = '';
  }

  removeGeneralSMS(generalSMS): void {
    const index = this.slingConfig.smsGeneralNotices.indexOf(generalSMS, 0);
    this.slingConfig.smsGeneralNotices.splice(index, 1);
  }

  removeFrequentDestination(frequentDestination: FrequentDestination): void {
    this.slingConfig.frequentFestinations =
      this.slingConfig.frequentFestinations.filter(
        (ft) => ft !== frequentDestination
      );
  }

  removeEmailCopy(emailCopy: EmailCopy): void {
    this.slingConfig.emailsCopy = this.slingConfig.emailsCopy.filter(
      (ft) => ft !== emailCopy
    );
  }

  async getPaymentMethods(): Promise<TipoFormaPagamento[]> {
    return new Promise<TipoFormaPagamento[]>(async (res) => {
      this.spinner.show();
      this.financesService.findTipoFormasPagamento().subscribe(
        async (data) => {
          res(data);
        },
        async (error) => {
          this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(
            exception.type,
            exception.title,
            exception.message
          );
          res([]);
        }
      );
    });
  }

  async selectedPaymentMethods(value): Promise<void> {
    return new Promise<void>(async (res) => {
      this.accountDropdown = this.paymentMethods
        .filter((m) => m.method.idTipoFormaPagamento === value && !m.company)[0]
        .method.contas.map((account) => ({
          label: account.nome,
          value: account.idConta,
        }));
      res();
    });
  }

  async selectedCompanyPaymentMethods(): Promise<void> {
    return new Promise<void>(async (res) => {
      // tslint:disable-next-line: prefer-for-of
      for (let index = 0; index < this.marinaCompanies.length; index++) {
        const comp = this.marinaCompanies[index];
        let accountList: any[] = [];
        if (comp.paymentMethodId) {
          if (
            this.marinaCompanies.find((m) => m.id === comp.id && m.finances)
          ) {
            const filter = this.paymentMethods.filter(
              (method) =>
                method.method.idTipoFormaPagamento === comp.paymentMethodId &&
                method.company &&
                method.company.id === comp.id
            );
            if (filter.length > 0) {
              accountList = filter[0].method.contas.map((a) => ({
                label: a.nome,
                value: a.idConta,
              }));
            }
          } else {
            accountList = this.paymentMethods
              .filter(
                (method) =>
                  method.method.idTipoFormaPagamento === comp.paymentMethodId &&
                  !method.company
              )[0]
              .method.contas.map((a) => ({ label: a.nome, value: a.idConta }));
          }
        }
        comp.accountDropdown = [...accountList];
        // const id = JSON.parse(JSON.stringify(comp.accountId));
        // comp.accountId = id;
      }
      res();
    });
  }

  sortDaysDue(): void {
    const copy = [...this.daysDueForm];
    this.daysDueForm = [
      ...copy.sort((a, b) => {
        return a - b;
      }),
    ];
  }

  getAccountPlans(): void {
    this.spinner.show();
    this.financesService.findPlanoContas().subscribe(
      (data) => {
        this.accountPlans = data;
        this.accountPlansDropdown = this.flatten(data, []).map((element) => ({
          label: element.nome,
          value: element.idPlanoConta,
        }));
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  flatten(estruturas, estruturasLista): PlanoConta[] {
    estruturas.forEach((estrutura: PlanoConta) => {
      if (!estrutura.idPlanoConta) {
        estrutura.status = {
          id: 0,
          codigo: 'ativo',
          descricao: 'Ativo',
        };
      }
      const keys = Object.keys(estrutura.estrutura);
      let code = '';
      keys.forEach((key, index) => {
        code += estrutura.estrutura[key];
        if (index < keys.length - 1) {
          code += '.';
        }
      });
      estruturasLista.push({
        idPlanoConta: estrutura.idPlanoConta,
        id: estrutura.idPlanoConta,
        tipo: estrutura.tipo,
        nivel: estrutura.nivel,
        ehGrupo: estrutura.contas ? true : false,
        idGrupo: estrutura.grupo ? estrutura.grupo.idPlanoConta : null,
        codigo: estrutura.estrutura,
        keep: estrutura.contas ? true : false,
        descricao: estrutura.descricao,
        parent: estrutura.parent,
        contas: estrutura.contas,
        nome: code + ' ' + estrutura.nome,
        disabled: estrutura.status && estrutura.status.id === 0 ? false : true,
      });
      if (estrutura.contas) {
        this.flatten(estrutura.contas, estruturasLista);
      }
    });
    return estruturasLista;
  }

  clearAnticipationFields(): void {
    this.slingConfig.anticipationTaxAccountPlan = null;
    this.slingConfig.anticipationIpiAccountPlan = null;
    this.slingConfig.anticipationCreditAccountPlan = null;
    this.slingConfig.anticipationRepurchaseAccountPlan = null;
    this.slingConfig.anticipationDebitAccountPlan = null;
  }

  async findFormasPagamentos(
    marinaCompany?: MarinaCompany
  ): Promise<TipoFormaPagamento[]> {
    return new Promise<TipoFormaPagamento[]>(async (res) => {
      this.financesService.findTipoFormasPagamento(marinaCompany).subscribe(
        async (data) => {
          res(data);
        },
        async (err) => {
          console.error(err);
          res([]);
        }
      );
    });
  }

  async configurePaymentMethods(): Promise<void> {
    return new Promise<void>(async (res) => {
      const data = await this.getPaymentMethods();
      this.paymentMethods = data
        .filter((method) => method.contas.length !== 0)
        .map((method) => ({ method }));
      this.paymentMethodsDropdown = data
        .filter((method) => method.contas.length !== 0)
        .map((method) => ({
          label: method.descricao,
          value: method.idTipoFormaPagamento,
        }));
      // tslint:disable-next-line: prefer-for-of
      for (let index = 0; index < this.marinaCompanies.length; index++) {
        const comp = this.marinaCompanies[index];
        if (comp.finances) {
          const methods = await this.findFormasPagamentos(comp);
          comp.paymentMethodsDropdown = methods.map((method) => ({
            label: method.descricao,
            value: method.idTipoFormaPagamento,
          }));
          this.paymentMethods = this.paymentMethods.concat(
            methods.map((method) => ({ method, company: comp }))
          );
        } else {
          comp.paymentMethodsDropdown = [...this.paymentMethodsDropdown];
        }
      }
      if (this.slingConfig.accountId) {
        await this.selectedPaymentMethods(this.slingConfig.paymentMethodId);
      }
      if (this.marinaCompanies && this.marinaCompanies.length > 0) {
        await this.selectedCompanyPaymentMethods();
      }
      res();
    });
  }

  deleteSpecialDayEvent(specialDay) {
    if (specialDay) {
      this.slingConfig.operationSpecialDays =
        this.slingConfig.operationSpecialDays.filter((sd) => sd !== specialDay);
    }
  }

  deleteCloseDayEvent(closeDay) {
    if (closeDay) {
      this.slingConfig.operationCloseDays =
        this.slingConfig.operationCloseDays.filter((cd) => cd !== closeDay);
    }
  }

  cloneObj(slingConfig: SlingConfig) {
    return DeepCloneUtil.clone(slingConfig);
  }

  canEnableConsumablesFinancialMultipleCompanies(): boolean {
    return this.slingConfig.financialMultipleCompaniesType !== FinancialMultipleCompaniesType.ByProduct;
  }

  tooltipMessageBlockedConsumablesByFinancialMultipleCompanies(): string {
    return this.canEnableConsumablesFinancialMultipleCompanies() ? '' : ' (bloqueio: Financeiro >> '+ this.messageUtil.translateKey('MULTIPLE-BILLING-COMPANY') + ')';
  }

  async getOperationalConfig() {
    this.isMovements = await this.operationalConfigService.isMovements();
    this.productNF = await this.operationalConfigService.hasNFProduct();
    this.useiInvites = await this.operationalConfigService.invitesControllerEnabled();
 }
}

