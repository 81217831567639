import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { SlingConfig, Sling, Product, OrderItem } from 'src/app/models';
import { ProductCategoryType, ProductType } from 'src/app/models/enums';
import { ProductService } from 'src/app/services/product.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { SlingUtil } from 'src/app/utils/sling.util';

@Component({
  selector: 'app-sling-product',
  templateUrl: './sling-product.component.html',
  styleUrls: ['./sling-product.component.scss']
})
export class SlingProductComponent implements OnInit {

  @Input() slingConfig: SlingConfig;
  @Input() slingOrder: Sling = new Sling();
  @Input() isASlingEdition: boolean;
  @Output() eventSling: EventEmitter<number> = new EventEmitter();
  loading = false;
  productSelected: number;

  disableFlag = false;

  consumableProducts: Product[];

  completeDateFormat = 'dd/MM/yyyy - HH:mm';

  filteredConsumables: any;

  consumableOrderItems: OrderItem[] = [];
  isToten: boolean;
  constructor(
    private messageUtil: MessageUtil,
    private productService: ProductService,
    public slingUtil: SlingUtil
  ) { }

  ngOnInit(): void {
    this.consumableProducts = [];
    this.loadConsumableProducts();
    this.loadConsumableOrderItems();
  }

  filterConsumables(val: string): Product[] {
    return val ? this.consumableProducts.filter((c) => new RegExp(`^${val}`, 'gi').test(c.name))
      : this.consumableProducts;
  }

  loadConsumableOrderItems(): void {
    this.consumableOrderItems = this.slingOrder.marinaOrder.orderItems
      .filter((item) => item.product.productCategory.productCategoryType !== ProductCategoryType.Fuel);
    this.consumableOrderItems.forEach((consumable) => {
      consumable.qtyStock = consumable.product.stock - consumable.product.reserved;
    });
  }

  loadConsumableProducts(): void {
    this.productService.getByProductTypeAndProductCategoryTypeNot(ProductType.Product, ProductCategoryType.Fuel).subscribe(
      (data) => {
        this.consumableProducts = data.filter((p) => p.active);
        this.filteredConsumables = this.consumableProducts.map(
          (product) => (
            {
              label: product.name.concat(' - (Estoque: ').concat(String(product?.avaliable).concat(')')),
              value: product.id
            }
          )
        );
      },
      () => {
        this.consumableProducts = null;
      },
    );
  }

  sumItemValues(orderItems: OrderItem[]): number {
    let sum = 0;
    orderItems.forEach((item) => {
      if (item.qtyRequested) {
        sum += (item.qtyRequested * item.itemValue);
      }
    });
    return sum;
  }

  addNewConsumable(): void {
    if (this.productSelected) {
      const orderItem: OrderItem = new OrderItem();
      const product = this.consumableProducts.find((p) => p.id === this.productSelected);
      orderItem.product = product;
      orderItem.itemValue = product.price.value;
      orderItem.itemStatus = false;
      orderItem.qtyStock = orderItem.product.stock - orderItem.product.reserved;
      let isItemAlreadyAdded = false;
      this.consumableOrderItems.forEach((item) =>
          item.product.id === orderItem.product.id ? isItemAlreadyAdded = true : false);
      if (!isItemAlreadyAdded) {
        this.consumableOrderItems.push(orderItem);
        this.productSelected = undefined;
      }
    }
  }

  saveConsumablesData(): void {
    if (!this.validateItems(this.consumableOrderItems)) {
      return;
    }

    this.loading = false;
    this.disableFlag = false;

    this.consumableOrderItems.forEach((consumable) => {
      if (!this.slingOrder.marinaOrder.orderItems.find((item) => item === consumable)) {
        this.slingOrder.marinaOrder.orderItems.push(consumable);
      }
    });

    if (this.slingOrder) {
      if (this.slingConfig.hasAccessories) {
        this.eventSling.emit(3);
      } else {
        this.eventSling.emit(4);
      }
    } else {
    }
  }

  validateItems(consumableOrderItems: OrderItem[]): boolean {
    let valid = true;
    for(let consumable of consumableOrderItems) {
      valid &&= consumable?.qtyStock >= consumable?.qtyRequested;
      if(!valid) {
        this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', 'MSG.STOCK-LOWER-THEN-REQUEST');
        break;
      }
      if (!consumable.qtyRequested || consumable.qtyRequested === 0
        || !consumable.itemValue || consumable.itemValue === 0) {
        valid = false;
        this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', 'Informe o preço e/ou quantidade solicitada.');
        break;
      }
    }
    return valid;
  }

  deleteConsumableOrderItem(orderItem: OrderItem): void {
    const index = this.consumableOrderItems.indexOf(orderItem, 0);
    if (index > - 1) {
      this.consumableOrderItems.splice(index, 1);
      const index2 = this.slingOrder.marinaOrder.orderItems.indexOf(orderItem, 0);
      if (index2 > - 1) {
        this.slingOrder.marinaOrder.orderItems.splice(index2, 1);
      }
    }
  }

  displayFn(object: any): string {
    return object ? object.name : '';
  }

  isAttempted(): boolean {
    return this.slingOrder.marinaOrder.orderStatus !== 'Ordered' || this.slingOrder.slingStatus !== 'PENDING_DOWN';
  }

  getRequestDate(): Date {
    if (this.slingOrder?.marinaOrder?.orderDate) {
      return moment(this.slingOrder.marinaOrder.orderDate).utc(true).toDate();
    }
    return null;
  }

  back(): void {
    if (this.slingConfig.hasFuelSupply) {
      this.eventSling.emit(1);
    } else {
      this.eventSling.emit(0);
    }
  }
}

