<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{ 'Faturamento > Débitos' | translate }}</span>
    </p-header>

    <div class="subtitles">
        <fieldset>
            <legend>Legenda (Pedido)</legend>
            <div class="badges">
                <i class="pi pi-circle-on attempted margin-left"></i>&nbsp;
                Atendido&nbsp;&nbsp;
                <i class="pi pi-circle-on partially-attempted margin-left"></i>&nbsp;
                Parcial&nbsp;&nbsp;
                <i class="pi pi-circle-on ordered margin-left"></i>&nbsp;
                Solicitado&nbsp;&nbsp;
            </div>
        </fieldset>
    </div>

    <div class="filters active">
        <div class="filter-row">
            <div class="form-control">
                <label>{{ 'CUSTOMER' | translate  }}</label>
                <p-dropdown [options]="filteredCustomers" [(ngModel)]="idCliente" [filter]="true" appendTo="body"
                    (onChange)="selectCustomer($event.value)" name="idCliente"></p-dropdown>
            </div>
            <app-filter-billing-company
              class="form-control" *ngIf="slingConfig && hasFinancialMultipleByProductFilter(slingConfig)"
              [slingConfig]="slingConfig"
              (filterChange)="filterBillingCompanyByProductChange($event)"
              [filter]="filterBillingCompanyByProduct"
              >
            </app-filter-billing-company>
        </div>
        <div class="filter-row">
            <div class="form-control">
                <div class="debitsBoats" *ngIf="idCliente && customerFilter.boats.length > 0">
                    Embarcações:&nbsp;
                    <span *ngFor="let boat of customerFilter.boats">
                        {{customerFilter.boats.indexOf(boat) == customerFilter.boats.length -1 ? boat.name : boat.name + ', '}}
                    </span>
                </div>
            </div>
        </div>
    </div>

    <p-panel>
        <p-header class="header">
            <span class="article-title">Pedidos não faturados</span>
            <span *ngIf="orders.length>0" style="font-size:14px;color:red">
                Total do cliente não faturado: {{getTotalOrderCustomer() | number:'1.2-2'}}
            </span>
            <span *ngIf="orders.length>0" style="font-size:14px;color:red">
                Total de pedidos não faturados: {{getTotalOrders() | number:'1.2-2'}}
            </span>
            <div class="buttons" *ngIf="orders.length > 0">
                <button class="easymarine primary" [disabled]="!hasSelected()" (click)="confirmGenerateInvoice(false)"
                    type="button">
                    Faturar
                </button>
                <button class="easymarine danger" [ngStyle]="{'background-color': hasSelected() ? '#5cb85c' : '#d1d3d5',
                                                'color': hasSelected() ? 'white' : '#828384'}"
                    [disabled]="!hasSelected()" (click)="confirmGenerateInvoice(true)">
                    Pagar
                </button>
            </div>
        </p-header>
        <br>
        <div class="table">
            <p-table [value]="orders" [paginator]="true" [rows]="numberOfRows" resetPageOnSort="true" [pageLinks]="3"
                [rowsPerPageOptions]="[10,20,30,50,100]">
                <ng-template pTemplate="header">
                    <tr>
                        <th class="text-center">
                            <section>
                                <p-checkbox [(ngModel)]="orderSelected" (onChange)="selectAllOrders()">
                                </p-checkbox>
                            </section>
                        </th>
                        <th [pSortableColumn]="'id'">
                            Id
                            <p-sortIcon [field]="'id'"></p-sortIcon>
                        </th>
                        <th class="text-center" [pSortableColumn]="'origin'">
                            Origem
                            <p-sortIcon [field]="'origin'"></p-sortIcon>
                        </th>
                        <th class="text-center" [pSortableColumn]="'origin'">
                            Status
                            <p-sortIcon [field]="'status'"></p-sortIcon>
                        </th>
                        <th class="text-center" [pSortableColumn]="'date'">
                            Data/Hora
                            <p-sortIcon [field]="'date'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'value'" class="text-center">
                            Total
                            <p-sortIcon [field]="'valueCurrency'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'boatName'">
                            Embarcação
                            <p-sortIcon [field]="'boatName'"></p-sortIcon>
                        </th>
                        <th>Cliente</th>
                        <th>Rateio</th>
                        <th class="text-center">Valor</th>
                        <th class="text-center">Itens</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-order>
                    <tr>
                        <td>
                            <section>
                                <p-checkbox [(ngModel)]="order.selected" binary="true"></p-checkbox>
                            </section>
                        </td>
                        <td (click)="getMarinaOrdersById(order.id)" style="list-style: none; cursor: pointer;">
                            {{order.orderNumber}}
                        </td>
                        <td class="text-center" [pTooltip]="getTextToolTipRefDateTuitionOrder(order)">
                            <ng-container [ngSwitch]="order.origin" tooltipPosition="bottom">
                                <ng-container *ngSwitchCase="'DETACHED'">
                                  <fa-icon [icon]="'shopping-cart'" style="font-size: 21px"></fa-icon>
                                </ng-container>
                                <ng-container *ngSwitchCase="'SLING'">
                                  <fa-icon [icon]="'ship'" style="font-size: 21px;"></fa-icon>
                                </ng-container>
                                <ng-container *ngSwitchCase="'TUITION'">
                                  <fa-icon [icon]="'university'" style="font-size: 21px;"></fa-icon>
                                </ng-container>
                                <ng-container *ngSwitchCase="'WORKSHOP'">
                                  <fa-icon [icon]="'tools'" style="font-size: 21px"></fa-icon>
                                </ng-container>
                            </ng-container>
                        </td>
                        <td>
                            <div class="text-center" style="padding-top: 10px">
                                <p>
                                    <i class="pi pi-circle-on"
                                        [ngClass]="{ 'canceled' : order.status === 'Canceled',
                                                                                'partially-attempted' : order.status === 'PartiallyAttempted',
                                                                                'attempted' : order.status === 'Attempted',
                                                                                'ordered': order.status === 'Ordered' }">
                                    </i>
                                </p>
                            </div>
                        </td>
                        <td class="text-center">
                            <div>
                                {{order.date | date: 'dd/MM/yyyy - HH:mm' }}
                            </div>
                        </td>
                        <td class="text-right colorValue">{{order.value | number: '1.2-2'}}</td>
                        <td>{{order.boatName}}</td>
                        <td colspan="3" style="padding: 0">
                            <p-table class="primeSubTable" [value]=order.customers>
                                <ng-template pTemplate="body" let-customer>
                    <tr>
                        <td>{{customer.name}}</td>
                        <td class="text-right colorValue">{{customer.quota}}%</td>
                        <td class="text-right colorValue">{{customer.value | number: '1.2-2'}}</td>
                    </tr>
                </ng-template>
            </p-table>
            </td>
            <td class="text-center">
                <div class="table-action" (click)="openDialogItensRequest(order)">
                    <i class="pi pi-bars primary"></i>
                </div>
            </td>
            </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="11">
                        Nenhum pedido encontrado
                    </td>
                </tr>
            </ng-template>
            </p-table>
        </div>
    </p-panel>


    <p-panel>
        <p-header class="header">
            <span class="article-title flex2">Faturas pendentes</span>
            <div class="text-right flex1" style="display: flex; flex-direction: column;">
                <span
                    *ngIf="invoices.length > 0 && (!marinaCompaniesWithDatabase || marinaCompaniesWithDatabase?.length === 0)"
                    style="font-size:14px;color:red">
                    Total devido: {{getTotalInvoices() | number:'1.2-2'}}
                </span>
                <span *ngIf="invoices.length  >0 && marinaCompaniesWithDatabase?.length > 0" style="font-size:14px">
                    <div style="text-align: center;font-size: 18px;font-weight: bold;color: red;">
                        Total devido
                    </div>
                    <br>
                    <div style="display: flex;justify-content: space-between;">
                        {{loggedMarina.companyName}}:
                        <span style="color:red">
                            {{getTotalInvoices() | number:'1.2-2'}}
                        </span>
                    </div>
                    <ng-container *ngFor="let company of marinaCompaniesWithDatabase">
                        <div *ngIf="getTotalInvoices(company) > 0"
                            style="display: flex;justify-content: space-between;">
                            {{company.companyFederalName}}:
                            <span style="color:red">
                                {{getTotalInvoices(company) | number:'1.2-2'}}
                            </span>
                        </div>
                    </ng-container>
                </span>
            </div>
        </p-header>

        <div class="table">
            <p-table [value]="invoices" [paginator]="true" [rows]="numberOfRows" resetPageOnSort="true" [pageLinks]="3"
                [rowsPerPageOptions]="[10,20,30,50,100]">
                <ng-template pTemplate="header">
                    <tr>
                        <th [pSortableColumn]="'invoiceNumber'">
                            Fatura
                            <p-sortIcon [field]="'invoiceNumber'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'marinaCompany.companyFederalName'" *ngIf="marinaCompanies?.length > 0">
                            Empresa
                            <p-sortIcon [field]="'marinaCompany.companyFederalName'"></p-sortIcon>
                        </th>
                        <th [pSortableColumn]="'customer.name'">
                            Cliente
                            <p-sortIcon [field]="'customer.name'"></p-sortIcon>
                        </th>
                        <th class="text-center" [pSortableColumn]="'origin'">
                            Origem
                            <p-sortIcon [field]="'origin'"></p-sortIcon>
                        </th>
                        <th class="text-center" [pSortableColumn]="'emissionDate'">
                            Emissão
                            <p-sortIcon [field]="'emissionDate'"></p-sortIcon>
                        </th>
                        <th class="text-center" [pSortableColumn]="'dueDate'">
                            Vencimento
                            <p-sortIcon [field]="'dueDate'"></p-sortIcon>
                        </th>
                        <th class="text-center" [pSortableColumn]="'value'">
                            Total
                            <p-sortIcon [field]="'value'"></p-sortIcon>
                        </th>
                        <th class="text-center" [pSortableColumn]="'pendingValue'">
                            Pendente
                            <p-sortIcon [field]="'pendingValue'"></p-sortIcon>
                        </th>
                        <th class="text-center" [pSortableColumn]="'totalPaid'">
                            Pago
                            <p-sortIcon [field]="'totalPaid'"></p-sortIcon>
                        </th>
                        <th class="text-center" [pSortableColumn]="'hasBankingBillet'">
                            Boleto
                            <p-sortIcon [field]="'hasBankingBillet'"></p-sortIcon>
                        </th>
                        <th class="text-center"></th>
                        <th class="text-center"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-invoice>
                    <tr>
                        <td class="text-center">{{invoice.invoiceNumber}}</td>
                        <td *ngIf="marinaCompanies?.length > 0" class="text-center"
                            [pTooltip]="invoice.marinaCompany ? getCompanyName(invoice.marinaCompany) : loggedMarina.companyName"
                            tooltipPosition="above">
                            {{invoice.marinaCompany ? getCompanyIndex(invoice.marinaCompany) : 1 }}
                        </td>
                        <td>{{invoice.customer.name}}</td>
                        <td class="text-center" [pTooltip]="getTextToolTipRefDateTuitionOrigin(invoice)">
                            <ng-container [ngSwitch]="getIconOrigin(invoice)" tooltipPosition="bottom">
                                <ng-container *ngSwitchCase="'DETACHED'">
                                  <fa-icon [icon]="'shopping-cart'" style="font-size: 21px"></fa-icon>
                                </ng-container>
                                <ng-container *ngSwitchCase="'SLING'">
                                  <fa-icon [icon]="'ship'" style="font-size: 21px;"></fa-icon>
                                </ng-container>
                                <ng-container *ngSwitchCase="'TUITION'">
                                  <fa-icon [icon]="'university'" style="font-size: 21px;"></fa-icon>
                                </ng-container>
                                <ng-container *ngSwitchCase="'WORKSHOP'">
                                  <fa-icon [icon]="'tools'" style="font-size: 21px"></fa-icon>
                                </ng-container>
                                <ng-container *ngSwitchCase="'MULTIPLE'">
                                    <fa-icon [icon]="'chart-pie'" style="font-size: 21px;"></fa-icon>
                                </ng-container>
                            </ng-container>
                        </td>
                        <td class="text-center">{{invoice.emissionDate | date: 'dd/MM/yyyy'}}</td>
                        <td class="text-center">{{invoice.dueDate | date: 'dd/MM/yyyy'}} </td>
                        <td class="text-right" style="color:#7575F6">{{invoice.value | number:'1.2-2'}}
                        </td>
                        <td class="text-right" style="color:#FF6501">
                            {{invoice.pendingValue | number:'1.2-2'}}</td>
                        <td class="text-right" style="color:#40CE40">
                            {{invoice.totalPaid | number:'1.2-2'}}</td>
                        <td class="text-center">
                            <i class="pi pi-check" style="color: #155724" *ngIf="invoice.hasBankingBillet">

                            </i>
                        </td>
                        <td class="text-center">
                            <div class="table-action" (click)="invoicePaidDialog(invoice)" pTooltip="Pagar fatura"
                                tooltipPosition="bottom">
                                <i class="pi pi-dollar" style="color: #2196F3;"> </i>
                            </div>
                        </td>
                        <td class="text-center">
                            <div class="table-action" (click)="extract(invoice.id)" pTooltip="Visualizar fatura"
                                tooltipPosition="bottom">
                                <i class="pi pi-print" style="color: #2196F3;"></i>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="9">
                            Nenhuma fatura encontrada
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </p-panel>
</p-fieldset>
