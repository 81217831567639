<div class="w100">
  <div *ngIf="m1 == true && !blockProduct" class="messageContainer">
    <span class="textMessage"
      >Se desejar emitir nota fiscal de produto, &nbsp;</span
    >
    <span class="textMessage" style="font-weight: bold">
      entre em contato com o suporte.</span
    >
    <div
      (click)="closeM1()"
      pTooltip="Fechar"
      tooltipPosition="bottom"
      class="ml-6"
    >
      <i
        class="pi pi-times"
        style="font-size: 21px; cursor: pointer; color: red"
      ></i>
    </div>
  </div>
  <div *ngIf="m2 == true" class="messageContainer mt-2 mb-2">
    <span class="textMessage"
      >Competência padrão para emissão de notas fiscais: &nbsp;</span
    >
    <span class="textMessage" style="font-weight: bold">
      {{ competencia }}</span
    >
    <div
      (click)="closeM2()"
      pTooltip="Fechar"
      tooltipPosition="bottom"
      class="ml-6"
    >
      <i
        class="pi pi-times"
        style="font-size: 21px; cursor: pointer; color: red"
      ></i>
    </div>
  </div>
</div>
<div *ngIf="m2"></div>

<div style="width: 100%; position: relative; clear: both">
  <p-fieldset>
    <p-header class="article-title">
      <span class="article-title">Resumo Emissão de Notas Fiscais</span>
      <div class="header-buttons"></div>
    </p-header>
    <div style="display: flex">
      <div style="width: 40%" class="mr-2">
        <h3>FATURA</h3>

        <p-table
          #invoiceTable
          *ngIf="invoiceList"
          [responsive]="true"
          [columns]="cols"
          [value]="invoiceList"
          [paginator]="false"
          styleClass="easy-table"
          autoLayout="true"
          [(selection)]="selectedInvoices"
          dataKey="id"
          [lazy]="true"
          [totalRecords]="invoiceList.length"
        >
          <ng-template pTemplate="header">
            <tr style="height: 53px">
              <th class="text-center">Status</th>
              <th>Fat.</th>
              <th class="text-center">Comp.</th>
              <th class="text-center">Valor</th>
              <th class="text-center">Cliente</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-invoice>
            <tr style="height: 53px">
              <td class="text-center">
                <span
                  class="icons"
                  [pTooltip]="
                    invoice.paymentStatus === 'InDay'
                      ? 'À vencer'
                      : invoice.paymentStatus === 'Paid'
                      ? 'Pago'
                      : 'Fatura vencida'
                  "
                  tooltipPosition="bottom"
                  (click)="
                    invoice.pendingValue !== 0 &&
                      invoice.orders.length !== 0 &&
                      openInvoiceDetailDialog(invoice)
                  "
                  *ngIf="
                    invoice.orders.length !== 0 && invoice.status !== 'CANCELED'
                  "
                >
                  <fa-icon
                    *ngIf="invoice.paymentStatus === 'InDay'"
                    [icon]="['far', 'calendar-alt']"
                    class="blue-icon"
                  >
                  </fa-icon>
                  <fa-icon
                    *ngIf="invoice.paymentStatus === 'Paid'"
                    [icon]="['far', 'calendar-check']"
                    class="green-icon"
                  ></fa-icon>
                  <fa-icon
                    *ngIf="invoice.paymentStatus === 'Late'"
                    [icon]="['far', 'calendar-times']"
                    class="red-icon"
                  >
                  </fa-icon>
                </span>
              </td>

              <td>
                <span
                  *ngIf="!invoice.legacyInvoiceNumber"
                  class="paidOrder"
                  (click)="extract(invoice.id)"
                  >{{ invoice.invoiceNumber }}</span
                >
                <span
                  *ngIf="invoice.legacyInvoiceNumber"
                  class="paidOrder"
                  (click)="extract(invoice.id)"
                  >{{ invoice.invoiceNumber }} <br />
                  {{ invoice.legacyInvoiceNumber }}</span
                >
              </td>
              <td class="text-center">
                {{ invoice.competenceDate | date : "dd/MM/yy" }}
              </td>

              <td
                class="text-center"
                [ngStyle]="{
                  color: invoice.value !== 0 ? 'rgb(83, 83, 244)' : 'black'
                }"
              >
                {{ invoice.value | currency : "BRL" }}
              </td>
              <td>
                {{ invoice.customer.name }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div style="width: 25%" class="mr-2">
        <h3>NOTA FISCAL DE SERVIÇO</h3>

        <p-table
          #invoiceTable
          *ngIf="invoiceList"
          [responsive]="true"
          [columns]="cols"
          [value]="invoiceList"
          [paginator]="false"
          styleClass="easy-table"
          autoLayout="true"
          [(selection)]="selectedInvoices"
          dataKey="id"
          [lazy]="true"
          [totalRecords]="invoiceList.length"
        >
          <ng-template pTemplate="header">
            <tr style="height: 53px">
              <!-- <th class="text-center">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th> -->
              <th class="text-center" *ngIf="!blockService">
                <p-checkbox
                  (click)="addOrRemoveAllService()"
                  binary="true"
                  [(ngModel)]="allService"
                  [pTooltip]="'Marcar ou desmarcar todos'"
                  tooltipPosition="bottom"
                >
                </p-checkbox>
              </th>

              <th>ISS {{ cityTax }}%</th>
              <th>Total de Serviços</th>
              <th class="text-center">Comp.</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-invoice>
            <tr style="height: 53px">
              <td class="text-center"  *ngIf="!blockService">
                <p-checkbox
                  [(ngModel)]="invoice.emitNfs"
                  binary="true"
                  *ngIf="
                    invoice.orders.length !== 0 &&
                    invoice.status !== 'CANCELED' &&
                    (invoice.nfeNumber === null || freeToIssue(invoice)===true )&&
                    invoice.totalService > 0
                  "
                  [pTooltip]="'Gerar Nota de serviço'"
                  tooltipPosition="bottom"
                >
                </p-checkbox>
              </td>
              <td class="text-center">
                {{ calcTotal(invoice)*(cityTax / 100) | currency : "BRL" }}
              </td>
              <td class="text-center">
                {{ calcTotal(invoice)| currency : "BRL" }}
              </td>
              <td class="text-center">{{getCompetence(invoice)}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div style="width: 25%" class="mr-2">
        <h3>NOTA FISCAL DE PRODUTO</h3>
        <p-table
          #invoiceTable
          *ngIf="invoiceList"
          [responsive]="true"
          [columns]="cols"
          [value]="invoiceList"
          [paginator]="false"
          styleClass="easy-table"
          [(selection)]="selectedInvoices"
          dataKey="id"
          [lazy]="true"
          [totalRecords]="invoiceList.length"
        >
          <ng-template pTemplate="header">
            <tr style="height: 53px">
              <th class="text-center" *ngIf="!blockProduct">
                <p-checkbox
                  *ngIf="!blockProduct"
                  (click)="addOrRemoveAllProduct()"
                  binary="true"
                  [(ngModel)]="allProduct"
                  [pTooltip]="'Marcar ou desmarcar todos'"
                  tooltipPosition="bottom"
                >
                </p-checkbox>
              </th>
              <th>Tributos</th>
              <th>Total de Produtos</th>
              <th class="text-center" >Comp.</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-invoice>
            <tr style="height: 53px">
              <td class="text-center" *ngIf="!blockProduct">
                <p-checkbox
                  [(ngModel)]="invoice.emitNfp"
                  binary="true"
                  *ngIf="
                    invoice.orders.length !== 0 &&
                    invoice.status !== 'CANCELED' &&
                    invoice?.nfeNumberProduct === null &&
                    invoice.totalProduct > 0 &&
                    !blockProduct
                  "
                  [pTooltip]="'Gerar Nota de Produto'"
                  tooltipPosition="bottom"
                >
                </p-checkbox>
              </td>
              <td>{{ invoice.totalProductTax | currency : "BRL" }}</td>
              <td>{{ invoice.totalProduct | currency : "BRL" }}</td>
              <td class="text-center">{{getCompetence(invoice)}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
<!--      <div style="width: 25%" class="mr-2" *ngIf="enableNFConsumidor">-->
<!--        <h3>NOTA FISCAL DE CONSUMIDOR</h3>-->
<!--        <p-table-->
<!--          #invoiceTable-->
<!--          *ngIf="invoiceList"-->
<!--          [responsive]="true"-->
<!--          [columns]="cols"-->
<!--          [value]="invoiceList"-->
<!--          [paginator]="false"-->
<!--          styleClass="easy-table"-->
<!--          [(selection)]="selectedInvoices"-->
<!--          dataKey="id"-->
<!--          [lazy]="true"-->
<!--          [totalRecords]="invoiceList.length"-->
<!--        >-->
<!--          <ng-template pTemplate="header">-->
<!--            <tr style="height: 53px">-->
<!--              <th class="text-center">-->
<!--                <p-checkbox-->
<!--                  (click)="addOrRemoveAllProduct()"-->
<!--                  binary="true"-->
<!--                  [(ngModel)]="allProduct"-->
<!--                  [pTooltip]="'Marcar ou desmarcar todos'"-->
<!--                  tooltipPosition="bottom"-->
<!--                >-->
<!--                </p-checkbox>-->
<!--              </th>-->
<!--              <th>Tributos</th>-->
<!--              <th>Total de Produtos</th>-->
<!--              <th class="text-center" >Comp.</th>-->
<!--            </tr>-->
<!--          </ng-template>-->
<!--          <ng-template pTemplate="body" let-invoice>-->
<!--            <tr style="height: 53px">-->
<!--              <td class="text-center" >-->
<!--                <p-checkbox-->
<!--                  [(ngModel)]="invoice.emitNfc"-->
<!--                  binary="true"-->
<!--                  *ngIf="-->
<!--                    invoice.orders.length !== 0 &&-->
<!--                    invoice.status !== 'CANCELED' &&-->
<!--                    invoice?.nfcNumber === null &&-->
<!--                    invoice.totalProduct > 0-->
<!--                  "-->
<!--                  [pTooltip]="'Gerar Nota de Consumidor'"-->
<!--                  tooltipPosition="bottom"-->
<!--                >-->
<!--                </p-checkbox>-->
<!--              </td>-->
<!--              <td>{{ invoice.totalProductTax | currency : "BRL" }}</td>-->
<!--              <td>{{ invoice.totalProduct | currency : "BRL" }}</td>-->
<!--              <td class="text-center">{{getCompetence(invoice)}}</td>-->
<!--            </tr>-->
<!--          </ng-template>-->
<!--        </p-table>-->
<!--      </div>-->
      <div style="width: 10%">
        <h3>&nbsp;</h3>
        <p-table
          #invoiceTable
          *ngIf="invoiceList"
          [responsive]="true"
          [columns]="cols"
          [value]="invoiceList"
          resetPageOnSort="true"
          [paginator]="false"
          styleClass="easy-table"
          autoLayout="true"
          [(selection)]="selectedInvoices"
          dataKey="id"
          [lazy]="true"
          [totalRecords]="invoiceList.length"
        >
          <ng-template pTemplate="header">
            <tr style="height: 53px; background-color: #2573b8; color: white">
              <th class="text-center" style="background-color: #2573b8; color: white">
                Total de Tributos
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-invoice>
            <tr style="height: 53px">
              <td class="text-center">
                {{
                  invoice.totalProductTax + calcTotal(invoice)*(cityTax / 100)
                    | currency : "BRL"
                }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

    <br />
    <div class="form-group text-right">
      <div class="buttons">
        <button class="easymarine danger" type="button" (click)="closeForm()">
          {{ "CANCEL" | translate }}
        </button>
        &nbsp; &nbsp;
        <button class="easymarine primary" type="submit" (click)="gerarNf()">
          {{ "Gerar Nota" | translate }}
        </button>
      </div>
    </div>
  </p-fieldset>
</div>
