<p-fieldset>
    <p-header class="article-title">
      <div class="article-title">{{ "SERVICES" | translate }}</div>
    </p-header>
    <div class="table">
      <p-table
        [value]="logsTable()"
        autoLayout="true"
      >
        <ng-template pTemplate="header">
          <tr>
            <th>{{ "SERVICE" | translate }}</th>
            <th class="text-center" style="min-width: 115px">Inicio serviço</th>
            <th class="text-center" style="min-width: 115px">Fim serviço</th>
            <th>{{ "OBSERVATION" | translate }}</th>
            <th>{{ "VALUE" | translate }}</th>
            <th>{{ "DISCOUNT" | translate }}</th>
            <th>{{ "DUE-DATE-DISCOUNT" | translate }}</th>
            <th>{{ "TOTAL" | translate }}</th>
          </tr>
        </ng-template>
        <ng-template
          pTemplate="body"
          let-boatService
          let-rowIndex="rowIndex"
        >
          <tr [ngClass]="{'item-new': isChanged(boatService) && isNew, 'item-old': isChanged(boatService) && !isNew}">
            <td>{{ boatService.serviceLog.name }}</td>
            <td class="text-center">
              {{ boatService.contractStartDate | date: "dd/MM/yyyy" }}
            </td>
            <td class="text-center">
              {{ boatService.contractEndDate | date: "dd/MM/yyyy" }}
            </td>
            <td>
              {{ boatService.observation }}
            </td>
            <td class="text-right">
              {{ boatService.value | number: "1.2-2" }}
            </td>
            <td class="text-right">
              {{ boatService.discount | number: "1.2-2" }}
            </td>
            <td class="text-right">
              {{ boatService.dueDateDiscount | number: "1.2-2" }}
            </td>
            <td class="text-right">
              {{ calcTotalService(boatService) | number: "1.2-2" }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </p-fieldset>
  <br />
