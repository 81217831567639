<div>
  <div style="display: flex; padding-left: 2em">
    <div class="form-control col-4">
      <label class="block mb-2 pt-1">Data de inicial</label>
      <p-calendar
        dateFormat="dd/mm/yy"
        name="creditDate"
        [monthNavigator]="true"
        [yearNavigator]="true"
        [yearRange]="'1900:2055'"
        rPCalendarMask
        appendTo="body"
        [showIcon]="true"
        i18n
        [(ngModel)]="getCustomerStatement.startDate"
        class="w-100"
      >
      </p-calendar>
    </div>
    <div class="form-control col-4">
      <label class="block mb-2 pt-1">Data final</label>
      <p-calendar
        dateFormat="dd/mm/yy"
        name="creditDate"
        [monthNavigator]="true"
        [yearNavigator]="true"
        [yearRange]="'1900:2055'"
        rPCalendarMask
        appendTo="body"
        [showIcon]="true"
        i18n
        [(ngModel)]="getCustomerStatement.endDate"
        class="w-100"
      >
      </p-calendar>
    </div>
    <div class="form-control col-2">
      <button
        class="easymarine primary"
        style="margin-top: 2rem !important"
        (click)="getHistoric()"
      >
        {{ "FIND" | translate }}
      </button>
    </div>
  </div>
  <div
    *ngFor="let registers of registerdates"
    style="padding-left: 2em; padding-right: 2em"
  >
    <div style="padding-top: 8px">
      <p-fieldset>
        <p-header
          class="article-title"
          style="width: 100%; display: flex; justify-content: space-between"
        >
          <div class="header-buttons">
            <span class="article-title"
              >Data {{ registers.creditDate | date : "dd/MM/yyyy" }}</span
            >
          </div>
          <div class="header-buttons">
            <span class="article-title"
              >Saldo {{ registers?.balance | currency : "BRL" }}</span
            >
          </div>
        </p-header>
        <div *ngFor="let item of historic.credits">
          <div *ngIf="show(registers, item)">
            <div
              style="width: 100%; display: flex; justify-content: space-between"
            >
              <div style="display: flex; align-items: center">
                <legend style="font-weight: bold; margin-right: 5px">
                  Valor:
                </legend>
                <span>{{ item.value | currency : "BRL" }}</span>
              </div>
              <div style="display: flex; align-items: center">
                <legend style="font-weight: bold; margin-right: 5px">
                  Responsável:
                </legend>
                <span>{{ item.createFrom.fullName }}</span>
              </div>
            </div>
            <div style="display: flex; align-items: center; padding-top: 5px">
              <legend style="font-weight: bold; margin-right: 5px">
                Fatura:
              </legend>
              <span>{{ item.invoice?.invoiceNumber }}</span>
            </div>
            <div style="display: flex; align-items: center; padding-top: 5px">
              <legend style="font-weight: bold; margin-right: 5px">
                Descrição:
              </legend>
              <span>{{ item?.description }}</span>
            </div>
            <hr style="border: 1px solid #f8f9fa" />
          </div>
        </div>
      </p-fieldset>
    </div>
  </div>
  <div
    class="d-flex justify-content-end mb-2 pt-3"
    style="display: flex; padding-left: 2em; padding-right: 2em"
  >
    <span style="font-weight: bold"> Saldo após o período: </span>

    <span class="pl-1">
      {{ this.historic?.balanceAfterPeriod | currency : "BRL" }}
    </span>
  </div>
  <div
    class="d-flex justify-content-end pt-2"
    style="display: flex; padding-left: 2em; padding-right: 2em"
  >
    <span style="font-weight: bold"> Saldo Atual do Cliente: </span>

    <span class="pl-1">
      {{ this.historic?.currentCredit | currency : "BRL" }}
    </span>
  </div>

  <div
    class="d-flex justify-content-end mb-4 "
    style="display: flex; padding-left: 2em; padding-right: 2em"
  >
    <button
      pButton
      class="p-button-outlined p-button-success "
      icon="pi pi-whatsapp"
      style="margin-top: 2rem !important"
      iconPos="left"
      (click)="shared()"
    >
       <span class="pl-1"> Compartilhar </span>
    </button>

    <button
      class="easymarine primary"
      style="margin-top: 2rem !important; margin-left: 2rem !important;"
      (click)="close()"
    >
      Fechar
    </button>
  </div>
</div>
