import { CampoId } from './campo-id';
export class SolicitanteClienteChamado {

  id: number ;
  nome:string;
  cliente: CampoId;

  constructor(id: number, nome: string, cliente: CampoId) {
    this.id = id;
    this.nome = nome;
    this.cliente = cliente;
  }

}
