import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { EasyMarineHttpApiLog } from './easy-marine-http-api-log';

@Injectable()
export class InvoiceTaxLogService {

  constructor(private httpClient: EasyMarineHttpApiLog) {
  }

  findLogs(filter: any): Observable<any> {
    let params = new HttpParams();
    if (filter.dateStart) {
        params = params.append('dateStart', moment(filter.dateStart).format());
    }
    if (filter.dateEnd) {
        params = params.append('dateEnd', moment(filter.dateEnd).format());
    }
    if (filter.referenceDateStart) {
        params = params.append('referenceDateStart', moment(filter.referenceDateStart).format());
    }
    if (filter.referenceDateEnd) {
        params = params.append('referenceDateEnd', moment(filter.referenceDateEnd).format());
    }
    if (filter.userId) {
        params = params.append('userId', filter.userId);
    }
    if (filter.customerId) {
        params = params.append('customerId', filter.customerId);
    }
    if (filter.referenceId) {
        params = params.append('idExterno', filter.referenceId);
    }
    return this.httpClient.get(environment.apiLog + `/invoice-tax-log/${filter.marinaId}`, { params })
       .pipe(map((response: any) => response));
    }
}
