import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PlanoConta } from '../models/finance/plano-conta';
import { CentroCusto } from '../models/finance/centro-custo'; //importado novo centro de custo
import { Conta, Favorecido } from '../models';
import { TipoFormaPagamento } from '../models/finance/tipo-forma-pagamento';
import { EasyMarineHttpFinances } from '../utils/easy-marine-http-finances';
import { MarinaCompany } from '../models/marina-company';
import { map } from 'rxjs/operators';
import { StorageUtil } from '../utils/storage.util';
import { environment } from 'src/environments/environment';

@Injectable()
export class FinancesService {

    constructor(
        private http: EasyMarineHttpFinances
    ) {
    }

    findFornecedor(): Observable<any[]> {
      return this.http.get(environment.finApiIntegracao + 'fornecedor/', {}).pipe(
          map((response: any) => response)
      );
  }

    findCentroCusto():Observable<CentroCusto[]>{
        return this.http.get(environment.finApi+'centrocusto/structure').pipe(map((Response:any)=>Response as CentroCusto[]));
    }

    findPlanoContaReceita(marinaCompany?: MarinaCompany): Observable<PlanoConta[]> {
        return this.http.get(environment.finApi + 'planoconta/tipo/0/structure', {}, marinaCompany).pipe(map((response: any) => response as PlanoConta[]));
    }

    findPlanoContaDespesa(marinaCompany?: MarinaCompany): Observable<PlanoConta[]> {
        return this.http.get(environment.finApi + 'planoconta/tipo/1/structure', {}, marinaCompany).pipe(map((response: any) => response as PlanoConta[]));
    }


    getPlanoContaById(id: number): Observable<PlanoConta> {
        return this.http.get(environment.finApi + 'planoconta/' + id).pipe(map((response: any) => response));
    }

    findContasAtivas(additionalCompany?: MarinaCompany): Observable<Conta[]> {
        return this.http.get(environment.finApi + 'conta/ativo', {}, additionalCompany).pipe(map((response: any) => response));
    }

    findTipoFormasPagamento(marinaCompany?: MarinaCompany): Observable<TipoFormaPagamento[]> {
        return this.http.get(environment.finApiIntegracao + 'tipo/formapagamento/contas', {}, marinaCompany).pipe(
            map((response: any) => response)
        );
    }

    findAllTipoFormasPagamentoAtivos(): Observable<TipoFormaPagamento[]> {
        return this.http.get(environment.finApi + 'tipo/formapagamento/ativo').pipe(map((response: any) => response));
    }

    findTipoFormasPagamentoBoleto(): Observable<TipoFormaPagamento[]> {
        return this.http.get(environment.finApiIntegracao + 'tipo/formapagamento/identificador/1').pipe(map((response: any) => response));
    }

    findTipoFormasPagamentoBoletoPorCarteira(idCarteira: number): Observable<TipoFormaPagamento[]> {
        return this.http.get(environment.finApiIntegracao + 'tipo/formapagamento/carteira/' + idCarteira).pipe(
            map((response: any) => response)
        );
    }

    createFavorecido(favorecido: Favorecido): Observable<Favorecido> {
        return this.http.post(environment.finApi + 'favorecido', favorecido).pipe(map((response: any) => response));
    }

    addAccountToPaymentMethod(vinculo: any): Observable<any> {
        return this.http.post(environment.apiHost + '/secure/contaformapagamento', vinculo).pipe(map((response: any) => response));
    }
    removeAccountToPaymentMethod(vinculo: any): Observable<Favorecido> {
        return this.http.put(environment.apiHost + '/secure/contaformapagamento/remove', vinculo).pipe(map((response: any) => response));
    }

    removeAccountToPaymentMethodByContaAndMarinaId(id: number, companyId: number): Observable<Favorecido> {
        return this.http.delete(environment.apiHost + '/secure/contaformapagamento/remove/' + id + '/'
            + companyId).pipe(map((response: any) => response));
    }

    findAllAccountPaymentMethodsByPaymentId(paymentId: number): Observable<any> {
        return this.http.get(environment.apiHost + '/secure/contaformapagamento/' + paymentId).pipe(map((response: any) => response.data));
    }

    findAllAccountPaymentMethods(): Observable<any> {
        return this.http.get(environment.apiHost + '/secure/contaformapagamento').pipe(map((response: any) => response.data));
    }
    findAllAccountPaymentMethodsByCompanyId(companyId: number): Observable<any> {
        return this.http.get(environment.apiHost + '/secure/contaformapagamento/company/' + companyId).pipe(
            map((response: any) => response.data)
        );
    }

    async findCentroCustoFlat(marinaCompany?: MarinaCompany): Promise<any[]> {
      let listaCentroCusto: CentroCusto[] = [];
      const listaCentroCustoFlat: any[] = [];
      return this.findCentroCusto().toPromise()
        .then((data) => {
          listaCentroCusto = data;
          this.flattenCentroCusto(listaCentroCusto, listaCentroCustoFlat);
          return listaCentroCustoFlat;
        })
        .catch((error) => { console.log(error); return listaCentroCustoFlat })
  }


  private flattenCentroCusto(listaEstrutura: CentroCusto[], estruturasLista: any[]): void {

    listaEstrutura.forEach((estrutura) => {

        estruturasLista.push({
            idCentroCusto: estrutura.idCentroCusto,
            id: estrutura.idCentroCusto,
            nivel: estrutura.nivel,
            ehGrupo: (estrutura.centros ? true : false),
            idGrupo: (estrutura.grupo ? estrutura.grupo.idCentroCusto : null),
            codigo: estrutura.estrutura,
            keep: (estrutura.centros ? true : false),
            descricao: estrutura.descricao,
            contas: estrutura.centros,
            disabled: (estrutura.centros && estrutura.centros.length > 0) ? true : false,
            nome: estrutura.nome,
            estrutura: estrutura.estrutura,
            label: this.montaLabelEstrutura(estrutura, false), //aproveitado
            treeLabel: this.montaLabelEstrutura(estrutura, true)  //aproveitado
        });
        if (estrutura.centros) { this.flattenCentroCusto(estrutura.centros, estruturasLista); }
    });
}


    async findPlanoContaReceitaFlat(marinaCompany?: MarinaCompany): Promise<any[]> {
        let listaPlanocontas: PlanoConta[] = [];
        const listaPlanoContaFlat: any[] = [];
        return this.findPlanoContaReceita(marinaCompany).toPromise()
          .then((data) => {
            listaPlanocontas = data;
            this.flattenPlanoConta(listaPlanocontas, listaPlanoContaFlat);
            return listaPlanoContaFlat;
          })
          .catch((error) => { console.log(error); return listaPlanoContaFlat })
    }

    async findPlanoContaDespesaFlat(marinaCompany?: MarinaCompany): Promise<any[]> {
        let listaPlanocontas: PlanoConta[] = [];
        const listaPlanoContaFlat: any[] = [];
        return this.findPlanoContaDespesa(marinaCompany).toPromise()
          .then((data) => {
            listaPlanocontas = data;
            this.flattenPlanoConta(listaPlanocontas, listaPlanoContaFlat);
            return listaPlanoContaFlat;
          })
          .catch((error) => { console.log(error); return listaPlanoContaFlat })
    }

    getAllBilingPortifolio(additionalCompany?: MarinaCompany): Observable<any[]> {
        return this.http.get(environment.finApi + 'carteiracobranca/', {}, additionalCompany).pipe(map((response: any) => response));
    }

    private flattenPlanoConta(listaEstrutura: PlanoConta[], estruturasLista: any[]): void {

        listaEstrutura.forEach((estrutura) => {

            estruturasLista.push({
                idPlanoConta: estrutura.idPlanoConta,
                id: estrutura.idPlanoConta,
                tipo: estrutura.tipo,
                nivel: estrutura.nivel,
                ehGrupo: (estrutura.contas ? true : false),
                idGrupo: (estrutura.grupo ? estrutura.grupo.idPlanoConta : null),
                codigo: estrutura.estrutura,
                keep: (estrutura.contas ? true : false),
                descricao: estrutura.descricao,
                contas: estrutura.contas,
                disabled: (estrutura.contas && estrutura.contas.length > 0) ? true : false,
                nome: estrutura.nome,
                estrutura: estrutura.estrutura,
                label: this.montaLabelEstrutura(estrutura, false),
                treeLabel: this.montaLabelEstrutura(estrutura, true)
            });
            if (estrutura.contas) { this.flattenPlanoConta(estrutura.contas, estruturasLista); }
        });
    }

    private montaLabelEstrutura(chartAccount: any, isTreeLabel: boolean): string {
        let charAccountIdx: string = chartAccount.estrutura[1];
        if (chartAccount.estrutura[2]) {
            charAccountIdx = charAccountIdx + '.' + chartAccount.estrutura[2];
        }
        if (chartAccount.estrutura[3]) {
            charAccountIdx = charAccountIdx + '.' + chartAccount.estrutura[3];
        }
        if (chartAccount.estrutura[4]) {
            charAccountIdx = charAccountIdx + '.' + chartAccount.estrutura[4];
        }
        if (chartAccount.estrutura[5]) {
            charAccountIdx = charAccountIdx + '.' + chartAccount.estrutura[5];
        }

        if (isTreeLabel) {
            charAccountIdx = ' ' + charAccountIdx;

            for (let i = 1; i < chartAccount.nivel; ++i) {
                charAccountIdx = '-- ' + charAccountIdx;
            }
        }

        return charAccountIdx + ' ' + chartAccount.nome;
    }

    findPlanoContas(): Observable<PlanoConta[]> {
        return this.http.get(environment.finApi + 'planoconta/structure').pipe(
            map((response: any) => response as PlanoConta[])
        );
    }

    findFavorecidos(): Observable<Favorecido[]> {
        return this.http.get(environment.finApiIntegracao + 'favorecido').pipe(map((response: any) => response as Favorecido[]));
    }

    async findCompaniesWithDatabase(): Promise<MarinaCompany[]> {
        return new Promise<MarinaCompany[]>(
            async (res) => {
                const marinaCompanies = StorageUtil.getMarinaCompanies();
                if (marinaCompanies) {
                    res(marinaCompanies.filter((m) => m.finances));
                } else {
                    res([]);
                }
            }
        );
    }

    async findTipoFormasPagamentoAsync(marinaCompany?: MarinaCompany): Promise<MarinaCompany> {
        return new Promise<MarinaCompany>(
            async (res) => {
                this.findTipoFormasPagamento(marinaCompany).subscribe(
                    async (formas) => res((formas && formas.length > 0) ? marinaCompany : new MarinaCompany()),
                    async err => res(new MarinaCompany())
                );
            }
        );
    }

    async getAllBilingPortifolioAsync(additionalCompany?: MarinaCompany): Promise<any[]> {
        return new Promise<any[]>(
            async (res) => {
                this.getAllBilingPortifolio(additionalCompany).subscribe(
                    async (response) => res(response),
                    async (err) => res([])
                );
            }
        );
    }
}
