
<div class="options-box">
    <div class="inputs">    
        <label>Filtrar &nbsp;</label><i class="pi pi-info-circle" pTooltip="Filtra por contas somente recebimento"> </i>&nbsp;
        <p-checkbox [(ngModel)]="onlyReceivingAccounts" [binary]="true" (onChange)="resetOptions($event)"
            name="receivingAccounts">
        </p-checkbox>
    </div>
    <div class="inputs">
        <label>
            {{ 'PAYMENT-METHODS' | translate }}
        </label>&nbsp;&nbsp;

        <p-dropdown #dd placeholder="Selecione..." [options]="paymentMethodList" [(ngModel)]="selectedPayment"
            appendTo="body" name="paymentMethodId" (onChange)="loadAccountListOptions()"
            dataKey="idTipoFormaPagamento" optionLabel="label">
        </p-dropdown>
    </div>
    <div class="inputs">
        <label>
                {{ 'ACCOUNT' | translate }}
        </label>&nbsp;&nbsp;

        <p-dropdown placeholder="Selecione..." [options]="accountList" [(ngModel)]="selectedAccount" (onChange)="onAccountSelected()"
            appendTo="body" name="account" dataKey="idConta" optionLabel="label">
        </p-dropdown>
    </div>
</div>