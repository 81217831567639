export class FranchiseFilterSimple {
  boatId:number;
  customerId:number;
  status:string;
  marinaId:number;
  franchiseGroupId: number;
  serviceId: number;

  constructor() {
    this.boatId = null;
    this.customerId = null;
    this.status = null;
    this.franchiseGroupId = null;
    this.serviceId = null;
  }
}
