import { Component, Input, OnInit } from '@angular/core';
import { SlingConfigService } from '../../../../../services/sling-config.service';
import { DialogService } from 'primeng/dynamicdialog';
import { SlingConfig } from '../../../../../models/sling-config';
import { MessageUtil } from 'src/app/utils/message.util';
import { EasyBankLogDTO } from 'src/app/models/dtos/logs/easyBankLogDTO';

@Component({
  selector: 'app-nixx-bank-logs-table',
  templateUrl: './nixx-bank-logs-table.component.html',
  styleUrls: ['./nixx-bank-logs-table.component.scss']
})
export class NixxBankLogsTableComponent implements OnInit {

  @Input()
  nixxBankLogs: EasyBankLogDTO[];

  @Input()
  isDialog = false;

  numberOfRows = 10;

  constructor(
    private slingConfigService: SlingConfigService,
    private messageUtil: MessageUtil,
    public dialog: DialogService,
  ) { }

  ngOnInit(): void {
    if ( !this.isDialog) {
      this.loadSlingConfig();
    }
  }

  loadSlingConfig(): void {
    this.slingConfigService.getSlingConfig().subscribe({
      next: (slingConfig: SlingConfig[]) => {
        if(slingConfig[0]) {
          this.numberOfRows = slingConfig[0].numberOfRecordsPerPage;
        } else {
          this.numberOfRows = 10;
        }
      },
      error: () => this.messageUtil.generateMessage('warning', 'Atenção', 'Erro ao consultar os Usuários')
    })
  }
}
