import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductType } from 'src/app/models/enums';
import { ProductService } from 'src/app/services/product.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { FormatUtil } from 'src/app/utils/format.util';
import { MessageUtil } from 'src/app/utils/message.util';

@Component({
  selector: 'app-balance-adjustment',
  templateUrl: './balance-adjustment.component.html',
  styleUrls: ['./balance-adjustment.component.scss']
})
export class BalanceAdjustmentComponent implements OnInit {

  editable = false;
  productsStock = [];
  productsStockTable: any[] = [];
  productsStockTable2: any[] = [];
  globalFilter = '';
  numberOfRows = 10;
  statusFilter = true;

  constructor(
    private productService: ProductService,
    private spinner: NgxSpinnerService,
    private messageUtil: MessageUtil,
    private slingConfigService: SlingConfigService
  ) { }

  ngOnInit(): void {
    this.loadSlingConfig();
    this.loadProducts();
  }

  loadSlingConfig(): void {
    this.slingConfigService.getSlingConfig().subscribe(
      (data) => {
        const slingConfig = data[0];
        this.numberOfRows = slingConfig != null ? slingConfig.numberOfRecordsPerPage : 10;
      }
    );
  }

  filterGlobal(): void {
    this.productsStockTable = this.productsStockTable2.filter(
      (p) =>
        (FormatUtil.getNotAccents(p.name).toUpperCase()
          .includes(FormatUtil.getNotAccents(this.globalFilter).toUpperCase()))
    );
  }

  isAdjustment(): boolean {
    if (this.productsStockTable.find((p) => p.adjustment && p.adjustment !== 0) !== undefined) {
      return true;
    }
    for (const product of this.productsStock) {
      if (this.productsStockTable.find((p) => p.id === product.id &&
        (p.minimalStock !== product.minimalStock || p.appMinimalStock !== product.appMinimalStock)) !== undefined) {
        return true;
      }
    }
    return false;
  }

  totalAdjustment(): any {
    return this.productsStockTable.reduce((total, product) => total + (product.adjustment * product.price.value), 0);
  }

  cancel(): void {
    this.productsStockTable.forEach((p) => p.adjustment = 0);
  }

  save(): void {
    this.spinner.show();
    const productsAdjustment = this.getProductsToSave();
    if (productsAdjustment.length > 0) {
      this.productService.balanceAdjustment(productsAdjustment).subscribe(
        () => {
          this.spinner.hide();
          this.loadProducts();
          this.messageUtil.generateMessage('success', 'SUMMARY.SUCCESS', 'Produto salvo com sucesso');
        },
        (error) => {
          this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
        }
      );
    } else {
      this.spinner.hide();
    }
  }

  getProductsToSave(): any[] {
    const productsAdjustment = [];
    const productsError = [];
    for (const product of this.productsStock) {
      const pDifferent = this.productsStockTable.find(
        (p) => p.id === product.id && (p.minimalStock !== product.minimalStock ||
          p.appMinimalStock !== product.appMinimalStock ||
          p.adjustment !== product.adjustment
        )
      );
      if (pDifferent) {
        if (pDifferent.minimalStock == null || pDifferent.appMinimalStock == null || pDifferent.adjustment == null) {
          this.verifyNullValue(pDifferent);
          productsError.push(pDifferent);
        } else {
          productsAdjustment.push(
            {
              id: pDifferent.id,
              minimalStock: pDifferent.minimalStock,
              appMinimalStock: pDifferent.appMinimalStock,
              adjustment: pDifferent.adjustment
            }
          );
        }
      }
    }
    if (productsError.length > 0) {
      let idProduct = '';
      productsError.forEach((p) => idProduct += idProduct === '' ? p.id : ', '.concat(p.id));
      const message = 'O(s) Produto(s) de id '.concat(idProduct)
        .concat(' possuem valores inválidos, verifique as colunas Estoque min, Estoque min app e Ajuste');
      this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', message);
      return [];
    } else {
      return productsAdjustment;
    }
  }

  updateStatus(product: any): void {
    this.productService.updateStatus(product.id, !product.active).subscribe(
      (data) => {
        this.spinner.hide();
        product.active = !product.active;
        this.messageUtil.generateMessage('success', 'SUMMARY.SUCCESS', 'Situação atualizada com sucesso');
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }

  loadProducts(): void {
    this.productService.getByProductType(ProductType.Product).subscribe(
      (data) => {
        this.productsStock = data;
        this.productsStock.forEach((p) => {
          this.verifyNullValue(p);
          p.balance = (p.stock - p.reserved);
          p.adjustment = 0;
        });
        this.productsStockTable = [];
        this.productsStock.forEach((p) => this.productsStockTable.push(Object.assign({}, p)));
        this.productsStockTable2 = this.productsStockTable;
        this.filterGlobal();
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }

  verifyNullValue(product): void {
    if (product.minimalStock == null) {
      product.minimalStock = 0;
    }
    if (product.appMinimalStock == null) {
      product.appMinimalStock = 0;
    }
    if (product.adjustment == null) {
      product.adjustment = 0;
    }
  }

  isGreaterMinimalStock(product: any): boolean {
    return product.balance <= product.minimalStock;
  }

  isCategoryFuel(product: any): boolean {
    return product.productCategory.productCategoryType === 'Fuel';
  }

  notKiloNorLiters(product: any): boolean {
    return product.initialsMeasurement != 'LT' && product.initialsMeasurement != 'KG';
  }

}
