<div class="totem">
    <div class="header">
        <form #f="ngForm" class="flex-form" autocomplete="off" (ngSubmit)="search()">
            <div class="form-control">
                <p for="type">Reimprimir: </p>
                <p-radioButton name="type" value="INVOICE" [(ngModel)]="selectedReprint" required
                    (onClick)="changeSelectedReprint()" label="Fatura (Recibo)"></p-radioButton>
                <p-radioButton name="type" value="ORDER" [(ngModel)]="selectedReprint" required
                    (onClick)="changeSelectedReprint()" label="Pedido (Comanda)"></p-radioButton>
            </div>
            <div class="form-control">
                <label for="orderOrInvoiceNumber">Número:</label>
                <input type="number" required name="orderOrInvoiceNumber" [(ngModel)]="orderOrInvoiceNumber">
                <button type="submit" [disabled]="f.invalid" class="easymarine"
                    [ngClass]="{'success' : f.valid, 'invalid': f.invalid}">Pesquisar</button>
            </div>
        </form>
        <div class="buttons">
            <div class="icon">
                <a [routerLink]="'/app/operational/checking'" class="close-button">
                    <i class="pi pi-times-circle"></i>
                </a>
            </div>
        </div>
    </div>
    <div class="body" *ngIf="selectedReprint != undefined && (invoicesPaid?.length > 0 || orders?.length > 0)">
        <div *ngIf="selectedReprint == 'ORDER'">
            <p-table [value]="orders" autoLayout="true">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Data</th>
                        <th>Embarcação</th>
                        <th>Cliente</th>
                        <th>Total</th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-order>
                    <tr>
                        <td>{{order.orderDate | date: 'dd/MM/yy'}}</td>
                        <td>{{order.boat.name}}</td>
                        <td>
                            <ul>
                                <li *ngFor="let orderQuota of order.orderQuotas">
                                    {{orderQuota.customer.name}}
                                </li>
                            </ul>
                        </td>
                        <td class="text-right colorValue">{{order.value | number:'1.2-2'}}</td>
                        <td class="text-right">
                            <button class="primary easymarine" (click)="printOrder()">
                                <i class="pi pi-print"></i>
                            </button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div *ngIf="selectedReprint == 'INVOICE'">
            <p-table [value]="invoicesPaid" autoLayout="true">
                <ng-template pTemplate="header">
                    <tr>
                        <th class="text-center">Fatura</th>
                        <th class="text-center">Dt. pago</th>
                        <th class="text-center">Vl. pago</th>
                        <th class="text-center">Total pago</th>
                        <th class="text-center">Total pend.</th>
                        <th class="text-center">Valor fatura</th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-invoicePaid>
                    <tr>
                        <td class="text-center">{{invoicePaid.invoiceNumber}}</td>
                        <td class="text-center">{{invoicePaid.datepaid | date: 'dd/MM/yyyy'}}</td>
                        <td class="text-right">{{invoicePaid.value | number:'1.2-2'}}</td>
                        <td class="text-right">{{invoicePaid.totalPaid | number:'1.2-2'}}</td>
                        <td class="text-right">{{(invoicePaid.totalInvoice - invoicePaid.totalPaid) | number:'1.2-2'}}
                        </td>
                        <td class="text-right">{{invoicePaid.totalInvoice | number:'1.2-2'}}</td>
                        <td class="text-right">
                            <button class="easymarine primary" (click)="printInvoice(invoicePaid.id)">
                                <i class="pi pi-print"></i>
                            </button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>