<div class="ml-2">
  <div class="form-group">
    <div class="form-control" >
      <h2>Importação de Nota Fiscal</h2>

      <div class="div-item">
        <p-fileUpload
          name="attachment"
          mode="basic"
          maxFileSize="100000000"
          chooseLabel="Selecione o arquivo XML"
          fileLimit="1"
          accept=".XML"
          invalidFileTypeMessageDetail="Formato aceito: {0}"
          invalidFileTypeMessageSummary="{0}: tipo de arquivo inválido,"
          (uploadHandler)="setFileXml($event.files)"
          [auto]="true"
          customUpload="true"
          #upload
        >
        </p-fileUpload>
        {{ fileXml?.name }}
      </div>
      <br />
      <div class="div-item">
      <p-fileUpload
        name="attachment"
        mode="basic"
        maxFileSize="100000000"
        chooseLabel="Selecione o arquivo PDF (Opcional)"
        fileLimit="1"
        accept=".PDF"
        invalidFileTypeMessageDetail="Formato aceito: {0}"
        invalidFileTypeMessageSummary="{0}: tipo de arquivo inválido,"
        (uploadHandler)="setFilePdf($event.files)"
        [auto]="true"
        customUpload="true"
        #upload
      >
      </p-fileUpload>
      {{ filePdf?.name }}
      </div>
    </div>
  </div>
<br>

  <div class="form-group mt-6 ">
    <div class="form-control w-100 text-right mr-2" >
      <button class=" primary easymarine" type="button" (click)="generateDocument()"
        style="width: 200px; height: 32px;"
        [disabled]="fileXml?.name === null || fileXml?.name===undefined ">Importar
        Documento</button>

        <button class="danger easymarine ml-3" type="button" (click)="cancel()"
        style="width: 200px; height: 32px;"
        >Cancelar
        </button>
    </div>
  </div>
</div>
