<div class="dialog-form">
    <div class="flex-row column">
        <h4>Totais por vencimento</h4>
        <p-table *ngIf="monthlysSummary" [value]="monthlysSummary" class="primeTable" dataKey="id"
            width="100%">
            <ng-template pTemplate="header">
                <tr>
                    <th>Vencimento</th>
                    <th class="text-center">Clientes</th>
                    <th class="text-right">Total</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-monthlySummary>
                <tr class="no-padding">
                    <td>{{monthlySummary.dueDate | date:'dd/MM/yy'}}</td>
                    <td class="text-center">{{monthlySummary.customerQty}}</td>
                    <td class="text-right">{{monthlySummary.total | number:'1.2-2'}}</td>
                </tr>
                <tr *ngIf="monthlysSummary[monthlysSummary.length -1].dueDate == monthlySummary.dueDate" class="no-padding"
                    style="background-color: #ebedf0; color: #1b1d1f; font-weight: bold">
                    <td>Total</td>
                    <td class="text-center">{{getTotalCustomer(monthlysSummary)}}</td>
                    <td class="text-right">{{getTotalValue(monthlysSummary) | number:'1.2-2'}}</td>
                </tr>
            </ng-template>
        </p-table>
        <br>
        <h4>Totais por grupo</h4>
        <p-table *ngIf="monthlysSummaryByGroup" [value]="monthlysSummaryByGroup" class="primeTable"
            dataKey="id" width="100%">
            <ng-template pTemplate="header">
                <tr>
                    <th>Grupo</th>
                    <th class="text-center">Clientes</th>
                    <th class="text-right">Total</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-monthlySummary>
                <tr class="no-padding">
                    <td>{{monthlySummary.groupName}}</td>
                    <td class="text-center">{{monthlySummary.customerQty}}</td>
                    <td class="text-right">{{monthlySummary.total | number:'1.2-2'}}</td>
                </tr>
                <tr class="no-padding" *ngIf="monthlysSummaryByGroup[monthlysSummaryByGroup.length -1].groupName == monthlySummary.groupName"
                    style="background-color: #ebedf0; color: #1b1d1f; font-weight: bold">
                    <td>Total</td>
                    <td class="text-center">{{getTotalCustomer(monthlysSummaryByGroup)}}</td>
                    <td class="text-right">{{getTotalValue(monthlysSummaryByGroup) | number:'1.2-2'}}</td>
                </tr>
            </ng-template>
        </p-table>
        <br>
        <h4 *ngIf="origin == 'MONTHLY_INVOICE'">Totais por origem</h4>
        <p-table *ngIf="origin == 'MONTHLY_INVOICE'" [value]="monthlysSummaryByOrigin"
            class="primeTable" dataKey="id" width="100%">
            <ng-template pTemplate="header">
                <tr>
                    <th>Origem</th>
                    <th class="text-center">Pedidos</th>
                    <th class="text-right">Total</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-monthlySummary>
                <tr>
                    <td class="text-center">
                        <div [ngSwitch]="monthlySummary.origin">
                            <div *ngSwitchCase="'DETACHED'">
                                <mat-icon class="material-icons">
                                    shopping_basket
                                </mat-icon>
                            </div>
                            <div *ngSwitchCase="'SLING'">
                                <mat-icon class="material-icons">
                                    directions_boat
                                </mat-icon>
                            </div>
                            <div *ngSwitchCase="'TUITION'">
                                <mat-icon class="material-icons">
                                    account_balance
                                </mat-icon>
                            </div>
                        </div>
                    </td>
                    <td class="text-center">{{monthlySummary.orderQty}}</td>
                    <td class="text-right">{{monthlySummary.total | number:'1.2-2'}}</td>
                </tr>
                <tr *ngIf="monthlysSummaryByOrigin[monthlysSummaryByOrigin.length -1].origin == monthlySummary.origin"
                    style="background-color: #ebedf0; color: #1b1d1f; font-weight: bold">
                    <td>Total</td>
                    <td class="text-center">{{getTotalOrder(monthlysSummaryByOrigin)}}</td>
                    <td class="text-right">{{getTotalValue(monthlysSummaryByOrigin) | number:'1.2-2'}}</td>
                </tr>
            </ng-template>
        </p-table>
        <br>
        <div style="margin-bottom: 15px;" *ngIf="origin != 'MONTHLY_INVOICE'">
            <div class="col-12">
                <span style="color: red; font-size: 15px;">*Clientes em vermelho não totalizam</span>
            </div>
        </div>
    </div>
</div>
