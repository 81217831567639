<p-fieldset>

    <p-header class="article-title">
        <span class="article-title">{{ 'Operacional > Planos de movimentações' | translate }}</span>
        <button class="fab-button primary" appFiltersButton pTooltip="Exibir/Ocultar Filtros" tooltipPosition="left">
            <i class="pi pi-filter"></i>
        </button>
    </p-header>

    <form class="filters" (submit)="find()" appFilters>
        <div class="filter-row">
            <div class="form-control">
                <label>Data solicitada inicial</label>
                <p-calendar class="field" dateFormat="dd/mm/yy" name="createdAtStart" [monthNavigator]="true"
                    [yearNavigator]="true" [yearRange]="'2000:2055'" rPCalendarMask appendTo="body"
                    [(ngModel)]="filter.createdAtStart" [showIcon]="true" i18n>
                </p-calendar>
            </div>
            <div class="form-control">
                <label>Data solicitada final</label>
                <p-calendar class="field" dateFormat="dd/mm/yy" name="createdAtEnd" [monthNavigator]="true"
                    appendTo="body" [yearNavigator]="true" [yearRange]="'2000:2055'" rPCalendarMask
                    [(ngModel)]="filter.createdAtEnd" [showIcon]="true" i18n>
                </p-calendar>
            </div>

            <div class="form-control">
                <label>Previsão execução inicial</label>
                <p-calendar class="field" dateFormat="dd/mm/yy" name="executionDateStart" [monthNavigator]="true"
                    appendTo="body" [yearNavigator]="true" [yearRange]="'2000:2055'" rPCalendarMask
                    [(ngModel)]="filter.executionDateStart" [showIcon]="true" i18n>
                </p-calendar>
            </div>

            <div class="form-control">
                <label>Previsão execução final</label>
                <p-calendar class="field" dateFormat="dd/mm/yy" name="executionDateEnd" [monthNavigator]="true"
                    appendTo="body" [yearNavigator]="true" [yearRange]="'2000:2055'" rPCalendarMask
                    [(ngModel)]="filter.executionDateEnd" [showIcon]="true" i18n>
                </p-calendar>
            </div>

            <div class="form-control">
                <label>Origem</label>
                <p-dropdown class="field" placeholder="Selecione..." appendTo="body" [options]="[{label: 'Todos', value: null},
                                    {label: 'App Cliente', value: 'CustomerApp'},
                                    {label: 'App Gestor', value: 'GestorApp'},
                                    {label: 'App Marinheiro', value: 'MarinheiroApp'},
                                    {label: 'Totem', value: 'TotemApp'},
                                    {label: 'Web', value: 'EasyWeb'}
                                    ]" [(ngModel)]="filter.origin" name="filter.origin">
                </p-dropdown>
            </div>

            <div class="form-control">
                <label>{{ 'CUSTOMER' | translate }}</label>
                <p-dropdown class="field" placeholder="Selecione..." [filter]="true" [options]="filteredCustomers"
                    appendTo="body" [(ngModel)]="filter.customerId" name="filter.customerId" (onChange)="filterBoat()">
                </p-dropdown>
            </div>

            <div class="form-control">
                <label>{{ 'BOAT' | translate }}</label>
                <p-dropdown class="field" placeholder="Selecione..." [filter]="true" [options]="boatsFilter"
                    appendTo="body" [(ngModel)]="filter.boatId" name="filter.boatId">
                </p-dropdown>
            </div>

            <div class="form-control">
                <label>{{'STATUS' | translate}}</label>
                <p-multiSelect [defaultLabel]="'STATUS' | translate" [options]="statusOptions" appendTo="body"
                    [(ngModel)]="filter.movementPlanStatus" [title]="'STATUS' | translate" [filter]=false
                    name="filter.movementPlanStatus" selectedItemsLabel="{0} itens selecionados">
                </p-multiSelect>
            </div>
            <div class="form-control">
                <label>{{'OBJECTIVE' | translate}}</label>
                <p-multiSelect [defaultLabel]="'OBJECTIVE' | translate" [options]="objectiveOptions" appendTo="body"
                    [(ngModel)]="filter.movementObjectiveCode" name="filter.movementObjectiveCode" [title]="'OBJECTIVE' | translate" [filter]=false
                    selectedItemsLabel="{0} itens selecionados">
                </p-multiSelect>
            </div>
            <div class="form-control">
              <label for="navigationPlan">{{'NAVIGATION-PLAN' | translate}}</label>
              <p-dropdown [(ngModel)]="filter.navigationPlan" id="navigationPlan" name="navigationPlan" appendTo="body"
                          [options]="[{label: 'Ambos', value: ''}, {label: 'Sim', value: true}, {label: 'Não', value: false}]">
              </p-dropdown>
            </div>

            <div class="form-control">
              <label for="destinatio">{{'DESTINATION' | translate}}</label>
              <input pInputText id="destination" name="destination" appendTo="body" [(ngModel)]="filter.destination">


            </div>

            <div class="buttons">
                <div class="form-button">
                    <button class="danger easymarine" type="button" (click)="clear()">{{ 'CLEAR' | translate }}</button>
                </div>
                <div class="form-button">
                    <button class="primary easymarine" type="submit">{{ 'FIND' | translate }}</button>
                </div>
                <div class="form-button">
                  <button *ngIf="selectedMovements.length > 0" class="success easymarine" type="button"
                          (click)="generateMultipleMovementPlanPDF()">{{ 'Gerar PDF para Impressão' | translate }}</button>
                </div>
                <div class="form-button">
                  <button class="success easymarine" type="button"
                          (click)="exportTable()">{{ 'MOVEMENT.EXPORT_MOVEMENT_PLAN_TABLE' | translate }}</button>
                </div>
            </div>
        </div>

    </form>
    <div class="table">
        <p-table #tt [value]="movementsTable" [paginator]="true" [rows]="numberOfRows" resetPageOnSort="true"
            [globalFilterFields]="['boatName']" [autoLayout]="true" [(selection)]="selectedMovements"
            styleClass="easy-table p-datatable-striped" [lazy]="true" (onLazyLoad)="onChangePage($event)">
            <ng-template pTemplate="caption">
                <div class="fill-empty"></div>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText size="50" placeholder="Nome da embarcação" [(ngModel)]="globalFilter"
                        (input)="filterGlobal()" style="width:auto">
                </span>
                <span style="font-weight: bold; float: right; margin-top: 5px;">{{ 'TOTAL-REGISTERS' | translate }}:
                    {{movementsTable.length}} {{'OF' | translate}} {{tt.totalRecords}}
                </span>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th class="text-center">
                      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th class="text-center" [pSortableColumn]="'movementPlanMarinaId'">
                        Id
                        <p-sortIcon [field]="'movementPlanMarinaId'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'origin'">
                        {{ 'ORIGIN' | translate }}
                        <p-sortIcon [field]="'origin'"></p-sortIcon>
                    </th>
                    <th class="text-center" >
                        {{ 'REQUESTER' | translate }}
                    </th>
                    <th class="text-center" >
                        {{ 'SAILOR' | translate }}
                    </th>
                    <th class="text-center" [pSortableColumn]="'boatName'">
                      {{ 'BOAT' | translate }}
                      <p-sortIcon [field]="'boatName'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'createdAt'">
                        {{ 'REQUEST-DATE' | translate }}
                        <p-sortIcon [field]="'createdAt'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'executionDate'">
                        {{ 'EXECUTION-FORECAST' | translate }}
                        <p-sortIcon [field]="'executionDate'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'returnTime'">
                        {{ 'RETURN-FORECAST' | translate }}
                        <p-sortIcon [field]="'returnTime'"></p-sortIcon>
                    </th>
                    <th class="text-center">
                        {{ 'PEOPLE-BORD' | translate }}
                    </th>
                    <th class="text-center">
                        {{ 'DESTINY' | translate }}
                    </th>
                    <th class="text-center">
                        {{ 'OBJECTIVE' | translate }}
                    </th>
                    <th class="text-center">
                        {{ 'NAVIGATION-PLAN' | translate }}
                    </th>
                    <th class="text-center" [pSortableColumn]="'movementPlanStatus'">
                        {{ 'STATUS' | translate }}
                        <p-sortIcon [field]="'movementPlanStatus'"></p-sortIcon>
                    </th>
                    <th class="text-center" colspan="1"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-movementPlan>
                <tr>
                    <td class="text-center">
                      <p-tableCheckbox [value]="movementPlan"></p-tableCheckbox>
                    </td>
                    <td class="text-center">
                        <span class="p-column-title">Plano de Movimentação</span>
                        <div class="table-action">
                            {{movementPlan.movementPlanMarinaId}}
                        </div>
                    </td>
                    <td class="text-center" [ngClass]="{'text-center' : window?.innerWidth > 640}">
                        <span class="p-column-title"> {{ 'ORIGIN' | translate }}</span>
                        <img [src]="getImageOrigin(movementPlan)" class="img-origin" tooltipPosition="bottom">
                    </td>
                    <td class="text-center">
                        <span class="p-column-title"> {{ 'REQUESTER' | translate }}</span>
                        {{movementPlan.requester}}
                    </td>
                    <td class="text-center" >
                        <span class="p-column-title"> {{ 'SAILOR' | translate }}</span>
                        {{movementPlan.sailor}}
                    </td>
                    <td class="text-center">
                      <span class="p-column-title"> {{ 'BOAT' | translate }}</span>
                      <span class="text-right">
                              {{movementPlan.boatName}} -
                        {{movementPlan.shipType.toUpperCase() | translate}} -
                        {{movementPlan.commercialLength}}'
                          </span>
                    </td>
                    <td class="text-center">
                        <span class="p-column-title"> {{ 'REQUEST-DATE' | translate }}</span>
                        {{timezone(movementPlan.createdAt) | date: 'dd/MM/yy - HH:mm'}}
                    </td>
                    <td class="text-center">
                        <span class="p-column-title"> {{ 'EXECUTION-FORECAST' | translate }}</span>
                        {{movementPlan.executionDate | date: 'dd/MM/yy - HH:mm'}}
                    </td>
                    <td class="text-center">
                        <span class="p-column-title"> {{ 'RETURN-FORECAST' | translate }}</span>
                        {{movementPlan.returnTime | date: 'dd/MM/yy - HH:mm'}}
                    </td>
                    <td class="text-center">
                      <span class="p-column-title">{{ 'PEOPLE-BORD' | translate }}</span>
                      <span class="text-right">
                        {{movementPlan.peopleBoard}}
                      </span>
                    </td>
                    <td class="text-center">
                      <span class="p-column-title">{{ 'DESTINY' | translate }}</span>
                      <span class="text-right">
                        {{movementPlan.destiny}}
                      </span>
                    </td>
                    <td class="text-center">
                      <span class="p-column-title">{{ 'OBJECTIVE' | translate }}</span>
                      <div class="inline-content">
                        <span class="text-right">
                          {{movementPlan.objective | translate}}
                        </span>
                        <i *ngIf="movementPlan.observation !== null && movementPlan?.observation!=''"
                          class="pi pi-info-circle inline-icon" style="color: blue;" [pTooltip]="movementPlan.observation"></i>
                      </div>

                    </td>
                    <td class="text-center">
                        <i *ngIf="movementPlan.navigationPlanId !== null" class="pi pi-map-marker" [ngClass]="{'success': true}"></i>
                    </td>
                    <td class="text-center">
                        {{movementPlan.movementPlanStatus | translate}}
                    </td>
                    <td [ngClass]="{'text-center' : window?.innerWidth > 640, 'actions': window?.innerWidth <= 640}">
                        <div (click)="openPrintMovementPlanDialog(movementPlan.id)" tooltipPosition="bottom" class="table-action" pTooltip="{{ 'PRINT' | translate }}"
                            *ngIf="hasCancel(movementPlan)">
                            <i class="pi pi-print"></i>
                        </div>
                        <div (click)="openMovementPlanFormDialog(movementPlan.id)" tooltipPosition="bottom"
                            class="table-action" pTooltip="{{ 'EDIT' | translate }}" *ngIf="hasCancel(movementPlan)">
                            <i class="pi pi-pencil"></i>
                        </div>
                        <div (click)="openMovementPlanFormDialog(movementPlan.id)" tooltipPosition="bottom"
                            class="table-action" pTooltip="{{ 'VISUALIZE' | translate }}"
                            *ngIf="!hasCancel(movementPlan)">
                            <i class="pi pi-eye"></i>
                        </div>
                        <!--<div (click)="openMovementCancelConfirmDialog(movementPlan)" tooltipPosition="bottom"
                            pTooltip="{{ 'CANCEL' | translate }}" [disabled]="hasCancel(movementPlan)"
                            class="table-action">
                            <i class="danger pi pi-times-circle"></i>
                        </div>-->
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="7">
                        Nenhum plano de movimentação encontrado
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-fieldset>
