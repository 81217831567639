<p-fieldset *ngIf="boatLog.insurerType">
    <p-header class="article-title">
      <div class="article-title">{{ "Seguro" | translate }}</div>
    </p-header>
    <div class="content-log">
      <div>
        <label>Segurado</label>
        <label [ngClass]="{'item-new-log': isChangedInsurerType() && isNew, 'item-old-log': isChangedInsurerType() && !isNew}">
            {{ isChangedInsurerType() && !isNew ? boatLog.oldDatas.insurerType: boatLog.insurerType | translate }}
        </label>
      </div>
      <ng-container *ngIf="isTypeInsured()">
        <div>
          <label for="policyNumber">N° Apólice</label>
          <label [ngClass]="{'item-new-log': isChangedPolicyNumber() && isNew, 'item-old-log': isChangedPolicyNumber() && !isNew}">
            {{ isChangedPolicyNumber() && isNew ?boatLog.boatInsurer.policyNumber: boatLog.oldDatas.boatInsurer.policyNumber }}
          </label>
        </div>
        <div>
          <label for="policyExpirationDate">Vencimento da Apólice</label>
          <label [ngClass]="{'item-new-log': isChangedPolicyExpirationDate() && isNew, 'item-old-log': isChangedPolicyExpirationDate() && !isNew}">
            {{ isChangedPolicyExpirationDate() && isNew ?boatLog.boatInsurer.policyExpirationDate: boatLog.oldDatas.boatInsurer.policyExpirationDate | date:'dd/MM/yyyy' }}
          </label>
        </div>
        <div>
          <label for="insurer">Seguradora</label>
          <label [ngClass]="{'item-new-log': isChangedInsurerName() && isNew, 'item-old-log': isChangedInsurerName() && !isNew}">
            {{ isChangedInsurerName() && isNew ?boatLog.boatInsurer.insurerName: boatLog.oldDatas.boatInsurer.insurerName }}
          </label>
        </div>
      </ng-container>
    </div>
  </p-fieldset>
  <br />