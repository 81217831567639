import { MessageUtil } from './../../utils/message.util';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, forwardRef, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

interface Passengers {
  code: number
  label: string
}

type PassengersType  = 'Person' | 'Adult' | 'Child'


@Component({
  selector: 'app-passengers',
  templateUrl: './passengers.component.html',
  styleUrls: ['./passengers.component.scss'],
  providers:  [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PassengersComponent),
      multi: true
    }
  ]
})
export class PassengersComponent implements OnInit, OnChanges, ControlValueAccessor {

  passengersNumber: Passengers[] = [];
  selectedPassengers: Passengers;

  @Input()
  typePeople: PassengersType;
  @Input()
  totalPassengers;

  descriptionSigle: string = '';
  descriptionMult: string = '';

  private onChanges: any = () => {}
  private onTouched: any = () => {}
  disabled: boolean = false;

  constructor(
    public messageUtil: MessageUtil,
  ) { }

  writeValue(obj: number): void {
    this.selectedPassengers = obj !== null && obj !== undefined && obj >= 0  ? {code: obj, label: obj.toString()} : null;
  }
  registerOnChange(fn: any): void { this.onChanges = fn }
  registerOnTouched(fn: any): void { this.onTouched = fn }
  setDisabledState?(isDisabled: boolean): void { this.disabled = isDisabled }

  setDescription(type: PassengersType) {
    if (type === 'Adult') {
      this.descriptionSigle = this.messageUtil.translateKey('ADULT');
      this.descriptionMult = this.messageUtil.translateKey('ADULTS');
    } else if (type === 'Child') {
      this.descriptionSigle = this.messageUtil.translateKey('CHILD');
      this.descriptionMult = this.messageUtil.translateKey('CHILDREN');
    } else if (type === 'Person') {
      this.descriptionSigle = this.messageUtil.translateKey('PERSON');
      this.descriptionMult = this.messageUtil.translateKey('PEOPLE');
    }
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.typePeople && changes.typePeople.currentValue !== changes.typePeople.previousValue) {
      this.setDescription(this.typePeople);
    }
    if(changes.totalPassengers && changes.totalPassengers.currentValue !== changes.totalPassengers.previousValue) {
      this.loadPassengersNumber(this.typePeople);
    }
  }

  select(event: any) {
    this.onChanges(event.value.code)
  }

  loadPassengersNumber(type: PassengersType): void {
    this.passengersNumber = [];
    let numberPassengers = 1;
    if (type === 'Child') {
      this.passengersNumber.push({ code: 0, label: this.messageUtil.translateKey('NOT-CHILD')});
    }
    while (numberPassengers <= this.totalPassengers) {
      this.passengersNumber.push({ code: numberPassengers, label: numberPassengers.toString() + ' ' + (numberPassengers <= 1 ? this.descriptionSigle : this.descriptionMult)});
      numberPassengers++;
    }

  }

}
