import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-multiple-destinations',
  templateUrl: './multiple-destinations.component.html',
  styleUrls: ['./multiple-destinations.component.scss']
})
export class MultipleDestinationsComponent implements OnInit {

  frequentFestinations: string[];
  frequentFestination: string;
  newDestination: string;
  destinations: string[] = [];
  formGroup: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {
    this.formGroup = this.formBuilder.group({
      destination: ['']
    });
  }

  ngOnInit(): void {
    this.frequentFestinations = this.config.data.frequentFestinations;
    this.frequentFestination = this.config.data.frequentFestination;
    if(this.frequentFestination && this.frequentFestination != '') {
      this.frequentFestination.split(',').forEach( destination => {
        this.addDestination(destination);
      })
    }
  }

  save(): void {
    this.dialogRef.close(this.destinations.join(", "));
  }

  addDestinations() {
    const newDestinations: string[] = this.formGroup.get('destination').value.split(',');
    newDestinations.forEach( destination => {
      this.addDestination(destination);
    })
    this.formGroup.get('destination').setValue('');
  }

  hasTextDestination(): boolean{
    return this.formGroup.get('destination').value?.trim() != '';
  }

  addFrequentDestinations( destination) {
    this.addDestination(destination.value);
  }

  addDestination(destination: string){
    if(!this.destinations.find(d => d == destination) && destination && destination.trim() != "") {
      this.destinations.unshift(destination.trim());
    }
  }

  delete(destination: string){
    this.destinations = this.destinations.filter(d => d != destination);
  }

  cleanDestinatios(){
    this.destinations = [];
  }

  close(): void {
    this.dialogRef.close();
  }

}
