<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{'TITLE-BOAT-DETAIL-SERVICES' | translate}}</span>
        <div class="header-buttons">
            <button class="fab-button primary"  label="Exportar Excel" (click)="exportTable()"
              pTooltip="A exportação será de acordo com os filtros selecionados" tooltipPosition="left">
                    <i class="pi pi-file"></i>
                </button> 
            <button class="fab-button primary " appFiltersButton pTooltip="Exibir/Ocultar Filtros"
                tooltipPosition="left">
                <i class="pi pi-filter"></i>
            </button>
        </div>
    </p-header>

    <form class="filters active" (submit)="this.getCustomers()" (keydown.enter)="$event.preventDefault()" (keyup.enter)="getCustomers()" appFilters>
        <div class="filter-row">
            <div class="form-control">
                <label for="customers">{{'CUSTOMER' | translate}}</label>
                <p-multiSelect [options]="customerList" [filter]="true" [(ngModel)]="customerFilter.customerIds"  name="customers" defaultLabel="Selecione..." selectedItemsLabel="{0} itens selecionados" appendTo="body" >
                </p-multiSelect>
            </div>

            <div class="form-control">
                <label for="rangeDateStart">{{'SERVICE-START' | translate}}</label>
                <p-calendar
                dateFormat="dd/mm/yy"
                name="rangeDateStart"
                [monthNavigator]="true"
                [yearNavigator]="true"
                appendTo="body"
                [yearRange]="'2000:2055'"
                rPCalendarMask
                selectionMode="range"
                [(ngModel)]="this.rangeDatesStart"
                [showIcon]="true"
                i18n
              >
              </p-calendar>
            </div>
            <div class="form-control">
                <label for="rangeDateEnd">{{'SERVICE-END' | translate}}</label>
                <p-calendar
                dateFormat="dd/mm/yy"
                name="rangeDateEnd"
                [monthNavigator]="true"
                [yearNavigator]="true"
                appendTo="body"
                [yearRange]="'2000:2055'"
                rPCalendarMask
                selectionMode="range"
                [(ngModel)]="this.rangeDatesEnd"
                [showIcon]="true"
                i18n
              >
              </p-calendar>
            </div>

            <div class="form-control">
                <label for="active">{{'Status Cliente' | translate}}</label>
                <p-dropdown [(ngModel)]="customerFilter.active" name="active" appendTo="body"
                    [options]="[{label: 'Ambos', value: null}, {label: 'Ativo', value: true}, {label: 'Inativo', value: false}]">
                </p-dropdown>
            </div>

            <div class="form-control">
                <label for="active">{{'SERVICE-STATUS' | translate}}</label>
                <p-dropdown [(ngModel)]="customerFilter.serviceStatus" name="active" appendTo="body"
                    [options]="[{label: 'Ambos', value: null}, {label: 'Ativo', value: true}, {label: 'Inativo', value: false}]">
                </p-dropdown>
            </div>

            <div class="form-control">
                <label for="grouped">{{'Serviços Vigentes' | translate}}</label>
                <p-checkbox [ngModelOptions]="{standalone: true}" [binary]="true" [(ngModel)]="this.customerFilter.serviceInEffect" inputId="binary">
                </p-checkbox>
            </div>

            <div class="buttons">
                <div class="form-button">
                    <button (click)="clear()" class="easymarine danger" type="button">{{ 'CLEAR' | translate }}</button>
                </div>
                <div class="form-button">
                    <button class="easymarine primary" type="submit">{{ 'FIND' | translate }}</button>
                </div>
            </div>
        </div>
    </form> 


    <div class="table">
        <p-table #tt [value]="customers" [autoLayout]="true" [columns]="cols">
            <ng-template pTemplate="header">
                <tr>
                    <th class="th-border-left">
                        <span>Totais projetados</span>
                    </th>
                    <th class="text-center th-border-left">Serviços</th>
                    <th class="text-center th-border-left">%</th>
                    <th class="text-center th-border-left">Descontos</th>
                    <th class="text-center th-border-left">%</th>
                    <th class="text-center th-border-left">Descontos Vencimento</th>
                    <th class="text-center th-border-left">%</th>
                    <th class="text-center th-border-left">Serviços com Descontos</th>
                    <th class="text-center th-border-left">%</th>
                    <th colspan="6" class="th-border-left"> </th>
                </tr>
                <tr>
                    <th class="th-border-left">Anual</th>
                    <th class="text-right th-border-left">{{(totalValue * 12) | currency : 'BRL'}}</th>
                    <th rowspan="2" class="text-center th-border-left">{{1 | percent: '1.0-2'}}</th>
                    <th class="text-center th-border-left">{{(totalDesc * 12) | currency : 'BRL'}}</th>
                    <th rowspan="2" class="text-center th-border-left">{{((totalDesc) / totalValue) | percent: '1.0-2'}}</th>
                    <th class="text-center th-border-left">{{(totalDescVenc * 12) | currency : 'BRL'}}</th>
                    <th rowspan="2" class="text-center th-border-left">{{((totalDescVenc) / totalValue) | percent: '1.0-2'}}</th>
                    <th class="text-right th-border-left">{{(total * 12) | currency : 'BRL'}}</th>
                    <th rowspan="2" class="text-center th-border-left">{{((total) / totalValue) | percent: '1.0-2'}}</th>
                </tr>
                <tr>
                    <th class="th-border-left">Mensal</th>
                    <th class="text-right th-border-left">{{totalValue | currency : 'BRL'}}</th>
                    <th class="text-center th-border-left">{{totalDesc | currency : 'BRL'}}</th>
                    <th class="text-center th-border-left">{{totalDescVenc | currency : 'BRL'}}</th>
                    <th class="text-right th-border-left">{{total | currency : 'BRL'}}</th>
                    <th colspan="9"> </th>
                </tr>
                <tr>
                    <th colspan="18"></th>
                </tr>
                <tr>
                    <th>Status</th>
                    <th>Cliente</th>
                    <th>CPF/CNPJ</th>
                    <th>Beneficiário</th>
                    <th>Início Serviço</th>
                    <th>Fim Serviço</th>
                    <th>Status Serviço</th>
                    <th>Serviço</th>
                    <th>Valor</th>
                    <th>Desc. Venc.</th>
                    <th>Desc.</th>
                    <th>%Desc.</th>
                    <th>Total</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer>
                 <tr>
                    <td class="text-center">
                        <span [pTooltip]="customer.active ? 'Cliente Ativa' : 'Cliente Inativa'" class="badge badge-pill size-badge"
                         [ngClass]="{'success':customer.active, 'danger': !customer.active}" tooltipPosition="top">
                        </span>
                    </td>
                    <td>{{customer.name}}</td>
                    <td>{{customer.federalId}}</td>
                    <td>
                        <ul *ngFor="let service of customer.serviceCustomer" class="ul-list">
                            <li>
                                {{service.beneficiaryName}}
                            </li>
                        </ul>
                    </td>

                    <td class="text-center">
                        <ul *ngFor="let service of customer.serviceCustomer" class="ul-list">
                            <li>
                                {{ service.contractStartDate | date:'dd/MM/yy'}}
                            </li>
                        </ul>
                    </td>

                    <td class="text-center">
                        <ul *ngFor="let service of customer.serviceCustomer" class="ul-list">
                            <li>
                                {{ service.contractEndDate != null ? ( service.contractEndDate| date:'dd/MM/yy') : '----'}}
                            </li>
                        </ul>
                    </td>


                    <td class="text-center">
                        <ul class="ul-list">
                            <li *ngFor="let service of customer.serviceCustomer">
                                <span *ngIf="service" [pTooltip]="service.active ? 'Serviço Ativo' : 'Serviço Inativo'" class="badge badge-pill size-badge"
                                 [ngClass]="{'success':service.active, 'danger': !service.active}" tooltipPosition="top">
                                </span>
                                <span *ngIf="!service">----</span>
                            </li>
                        </ul>
                    </td>

                    <td>
                        <ul class="ul-list">
                            <li *ngFor="let service of customer.serviceCustomer">
                                {{service.service != null ? service.service.name : '----'}}
                            </li>
                        </ul>
                    </td>
                    
                    <td>
                        <ul class="ul-list ul-list-right">
                            <li *ngFor="let service of customer.serviceCustomer" >
                                {{service.value | currency : 'BRL'}}
                            </li>
                        </ul>
                    </td>
                    <td>
                        <ul class="ul-list ul-list-right">
                            <li *ngFor="let service of customer.serviceCustomer" >
                                {{service.dueDateDiscount | currency : 'BRL'}}
                            </li>
                        </ul>
                    </td>
                    <td>
                        <ul class="ul-list ul-list-right">
                            <li *ngFor="let service of customer.serviceCustomer" >
                                {{service.discount | currency : 'BRL'}}
                            </li>
                        </ul>
                    </td>
                    <td>
                        <ul class="ul-list ul-list-right">
                            <li *ngFor="let service of customer.serviceCustomer">
                                {{getPercentDiscountOfTotalService(service) | percent: '1.0-2'}}
                            </li>
                        </ul>
                    </td>
                    <td>
                        <ul class="ul-list ul-list-right">
                            <li *ngFor="let service of customer.serviceCustomer">
                                {{getTotalService(service) | currency : 'BRL'}}
                            </li>
                        </ul>
                    </td>
                </tr> 
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="11">
                        Nenhum cliente encontrado
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-fieldset>