import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { OperationalConfigService } from 'src/app/services/config/operational-config.service';
import { WorkshopService } from 'src/app/services/workshop.service';
import { MessageUtil } from 'src/app/utils/message.util';

@Component({
  selector: 'app-workshop',
  templateUrl: './workshop.component.html',
  styleUrls: ['./workshop.component.scss'],
})
export class WorkshopComponent implements OnInit, OnDestroy {
  public workshopUrl: SafeResourceUrl;

  private unsubscriber: Subscription = new Subscription();
  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private workshopService: WorkshopService,
    private operationalConfigService: OperationalConfigService,
    private messageUtil: MessageUtil
  ) {}

  async ngOnInit() {
    this.operationalConfigService.getWorkshopIntegrationKey().then((t) => {
      this.getWorkshopUrl(t);
    });
  }

  async getWorkshopUrl(workshopIntegrationKey: string) {
    const url = await this.findUrl();

    this.workshopService.authenticate(workshopIntegrationKey).subscribe(
      (res: string) => {
        if(res != null){
          this.workshopUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            environment.workshopHost + url + '?token=' + res
          );
        }
        else{
          this.messageUtil.generateMessage('error','Não foi possível autenticar no módulo de oficina.', '');
        }
      },
      (err) => {
        this.workshopUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          environment.workshopHost + url
        );
      }
    );
  }

  async findUrl(): Promise<string> {
    return new Promise<string>(async (resolve) => {
      this.unsubscriber = this.route.data.subscribe(async (v) => {
        resolve(v.url);
      });
    });
  }

  ngOnDestroy(): void {
    this.unsubscriber.unsubscribe();
  }
}
