<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{'TITLE-SUMMARY' | translate}}</span>
    </p-header>

    <div class="table">
        <p-table #lotsTable *ngIf="anticipationLotsTable" [columns]="cols" styleClass="easy-table"
            [value]="anticipationLotsTable" resetPageOnSort="true" [paginator]="true" [rows]="numberOfRows"
            [pageLinks]="3" [rowsPerPageOptions]="[10,20,30,50,100]" [globalFilterFields]="['lot', 'id']" dataKey="id"
            autoLayout="true">

            <!-- Botões legenda topo -->
            <ng-template pTemplate="caption">
                <div>

                </div>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText size="50" placeholder="Filtro por Lote" [(ngModel)]="globalFilter"
                        (input)="filterGlobal()" style="width:auto">
                </span>
                <div class="text-right" style="margin: 1px;">
                    <span style="font-weight: bold">{{ 'TOTAL-REGISTERS' | translate }}:
                        {{lotsTable.totalRecords}} {{'OF' | translate}}
                        {{anticipationLots?.length}}</span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th class="text-center" [pSortableColumn]="'lot'">
                        Operação
                        <p-sortIcon [field]="'lot'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'createdAt'">
                        Data
                        <p-sortIcon [field]="'createdAt'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'id'">
                        Nº
                        <p-sortIcon [field]="'id'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'total'">
                        Total
                        <p-sortIcon [field]="'total'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'taxes'">
                        Juros
                        <p-sortIcon [field]="'taxes'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'taxespercent'">
                        % Juros
                        <p-sortIcon [field]="'taxespercent'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'interest'">
                        IOF
                        <p-sortIcon [field]="'interest'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'interestpercent'">
                        % IOF
                        <p-sortIcon [field]="'interestpercent'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'credit'">
                        Crédito
                        <p-sortIcon [field]="'credit'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'paid'">
                        Pagos
                        <p-sortIcon [field]="'paid'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'discount'">
                        Decontos
                        <p-sortIcon [field]="'discount'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'interests'">
                        Juros/Multa
                        <p-sortIcon [field]="'discount'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'interests'">
                        Pendentes
                        <p-sortIcon [field]="'pending'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'repurchased'">
                        Recomprados
                        <p-sortIcon [field]="'repurchased'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'repurchased_tax'">
                        Juros Recompra
                        <p-sortIcon [field]="'repurchased_tax'"></p-sortIcon>
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-lot>
                <tr>
                    <td (click)="showInvoices(lot)" style="cursor: pointer;">
                        <i class="pi"
                            [ngClass]="{'pi-angle-down': !lot.showInvoices, 'pi-angle-up': lot.showInvoices}"></i>
                        <span>{{lot.lot}}</span>
                    </td>
                    <td class="text-center">
                        <span>{{lot.createdAt | date:'dd/MM/yyyy'}}</span>
                    </td>
                    <td class="text-center">
                        <span>
                            {{lot.qty}}
                        </span>
                    </td>
                    <td class="text-right">
                        <span>{{lot.total | number:'1.2-2'}}</span>
                    </td>

                    <td class="text-right">
                        {{lot.taxes | number:'1.2-2'}}
                    </td>

                    <td class="text-right">
                        {{(lot.taxes/lot.total) * 100 | number:'1.2-2'}} %
                    </td>

                    <td class="text-right">
                        {{lot.interest | number:'1.2-2'}}
                    </td>

                    <td class="text-right">
                        {{(lot.interest/lot.total) * 100 | number:'1.2-2'}} %
                    </td>

                    <td class="text-center">
                        {{(lot.total - lot.interest - lot.taxes) | number:'1.2-2'}}
                    </td>

                    <td class="text-center">
                        {{lot.paid | number:'1.2-2'}}
                    </td>

                    <td class="text-center">
                        {{lot.discount | number:'1.2-2'}}
                    </td>

                    <td class="text-center">
                        {{lot.interests | number:'1.2-2'}}
                    </td>

                    <td class="text-center">
                        {{lot.pending | number:'1.2-2'}}
                    </td>

                    <td class="text-center">
                        {{lot.repurchase | number:'1.2-2'}}
                    </td>

                    <td class="text-center">
                        {{lot.repurchaseTax | number:'1.2-2'}}
                    </td>

                    <td>
                        <button type="button" class="table-icon" pTooltip="Cancelar lote de antecipação"
                            tooltipPosition="bottom" style="color: red;" (click)="cancel(lot)">
                            <i class="pi pi-times"></i>
                        </button>
                    </td>
                </tr>
                <tr *ngIf="lot?.showInvoices">
                    <td colspan="16">
                        <p-table [value]="lot.billets" autoLayout="true">
                            <ng-template pTemplate="header">
                <tr>
                    <th>Fatura</th>
                    <th *ngIf="marina?.marinaCompanies?.length > 0">Empresa</th>
                    <th>Cliente</th>
                    <th>CPF</th>
                    <th>Valor</th>
                    <th>Vencimento</th>
                    <th>Conta</th>
                    <th>Forma Pgto</th>
                    <th>Data Pgto</th>
                    <th>Valor Pago</th>
                    <th>Desconto</th>
                    <th>Juros/Multa</th>
                    <th>Data Recompra</th>
                    <th>Valor Recompra</th>
                    <th>Juros Recompra</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-billet>
                <tr [ngClass]="{'multiple' : (hasMultiplePayments(billet.paymentDates) || hasIncorretPaymentMethod(billet.paymentMethodId))}"
                    [title]="hasIncorretPaymentMethod(billet.paymentMethodId) ? 'Método de pagamento diferente de boleto' : (hasMultiplePayments(billet.paymentDates) ? 'Pagamentos múltiplos' : '')">
                    <td style="cursor: pointer; color: #185992" (click)="extract(billet.invoiceId)">
                        {{billet?.invoiceNumber}}
                    </td>
                    <td class="text-center" *ngIf="marina?.marinaCompanies?.length > 0" tooltipPosition="top"
                        [pTooltip]="getCompanyName(billet.companyId)">{{getCompanyIndex(billet?.companyId)}}</td>
                    <td>{{billet?.customerName}}</td>
                    <td>{{billet?.customerFederalId}}</td>
                    <td class="text-right">
                        {{billet?.total | number: '1.2-2'}}
                    </td>
                    <td class="text-center">{{billet?.dueDate | date: 'dd/MM/yyyy'}}</td>
                    <td>{{getAccountName(billet)}}</td>
                    <td>{{getPaymentMethodName(billet)}}</td>
                    <td class="text-right" [ngClass]="{'multiple' : hasMultiplePayments(billet.paymentDates)}">
                        {{getPaymentDates(billet.paymentDates)}}</td>
                    <td class="text-right" [ngClass]="{'multiple' : hasMultiplePayments(billet.paymentDates)}">
                        {{billet.valuePaid | number: '1.2-2'}}</td>
                    <td class="text-right" [ngClass]="{'multiple' : hasMultiplePayments(billet.paymentDates)}">
                        {{billet.discount | number: '1.2-2'}}</td>
                    <td class="text-right" [ngClass]="{'multiple' : hasMultiplePayments(billet.paymentDates)}">
                        {{billet.interest | number: '1.2-2'}}</td>
                    <td class="text-right">
                        <span *ngIf="billet.repurchaseDate">
                            {{ billet.repurchaseDate | date: 'dd/MM/yy'}}
                        </span>
                    </td>
                    <td class="text-right">{{ billet.repurchaseValue | number: '1.2-2'}}</td>
                    <td class="text-right">{{ billet.repurchaseTax | number: '1.2-2'}}</td>
                </tr>
            </ng-template>
        </p-table>
        </td>
        </tr>
        </ng-template>
        </p-table>
    </div>
</p-fieldset>
