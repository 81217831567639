import {Address} from "../../address";

export class Visitor {

  id:number;
  name:string;
  federalId:string;
  phone:string;
  residentialAddress:Address
  birthday:Date;
  updatedAt:Date;
  docUrl:string;
  photoUrl:string;
  createdAt:Date;
  active:boolean;
  observation:string;
  docUrlBack:string;
  marinaId:number;
  accessPermissions:any[];
  colectId:number;
}