import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {ServiceCustomerDTO} from '../models/dtos/ServiceCustomerDTO';
import {ServiceCustomer} from '../models/ServiceCustomer';
import {ServiceReadjustmentSimulationDTO} from "../models/dtos/service-readjustment-simulationDTO";
import {ServiceCustomerReadjustmentDTO} from "../models/dtos/service-customer-readjustmentDTO";
import {servicesReadjustmentRegisterDTO} from "../models/dtos/services-readjustment-registerDTO";

@Injectable({
  providedIn: 'root'
})
export class ServiceCustomerService {

  constructor(private http: HttpClient) {
  }

  getServiceCustomersListByCustomerID(customerId: number): Observable<ServiceCustomer[]> {
    return this.http.get(environment.apiHost + '/secure/service-customer/customer-id/' + customerId)
      .pipe(map((response: any) => response.data.serviceCustomerList as ServiceCustomer[]));
  }

  createNewServiceCustomer(serviceCustomerDTO: ServiceCustomerDTO): Observable<void> {
    return this.http.post(environment.apiHost + '/secure/service-customer/', serviceCustomerDTO)
      .pipe(map((response: any) => response));
  }

  updateServiceCustomers(serviceCustomersDTOList: ServiceCustomerDTO[]): Observable<void> {
    return this.http.put(environment.apiHost + '/secure/service-customer/', serviceCustomersDTOList)
      .pipe(map((response: any) => response));
  }

  deleteServiceCustomer(customerServiceId: number): Observable<void> {
    return this.http.delete(environment.apiHost + `/secure/service-customer/${customerServiceId}`)
      .pipe(map((response: any) => response));
  }

  getServiceCustomersHistoryListByCustomerID(customerId: number): Observable<ServiceCustomer[]> {
    return this.http.get(environment.apiHost + '/secure/service-customer/customer-id-history/' + customerId)
      .pipe(map((response: any) => response.data.serviceCustomerHistoryList as ServiceCustomer[]));
  }
  getSimulationServicesReadjustmentByServiceCustomer(simulatePriceReadjustment: ServiceReadjustmentSimulationDTO): Observable<ServiceCustomerReadjustmentDTO[]>{
    return this.http.post(environment.apiHost + '/secure/service-customer/readjustment/simulation', simulatePriceReadjustment)
      .pipe(map((response: any) => response.data.simulationReadjustmentsService as ServiceCustomerReadjustmentDTO[]));
  }

  registerServicesReadjustment(serviceCustomerReadjustment: servicesReadjustmentRegisterDTO) : Observable<any>{
    return this.http.post(environment.apiHost + "/secure/service-customer/readjustment/register", serviceCustomerReadjustment)
      .pipe(map((response: any) => response.data as any))
  }
}
