import { Customer } from './customer';

export class OrderQuota {
    customer: Customer;
    quota: number;
    idInvoice: number;
    singleOrderQuota?: number;

    constructor() {
        this.customer = new Customer();
    }
}
