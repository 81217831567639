import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {LandExitDTO} from '../models/dtos/land-exit-dto';

@Injectable()
export class LandExitService {

  apiPath: string = environment.apiHost + '/secure/land_exit';

  constructor(private http: HttpClient) {
  }

  registerLandExit(body: LandExitDTO): Observable<any>{
    return this.http.post(`${this.apiPath}/`, body).pipe(map((response: any) => response));
  }
}
