import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-config-price-simples',
  templateUrl: './config-price-simples.component.html',
  styleUrls: ['./config-price-simples.component.scss']
})
export class ConfigPriceSimplesComponent implements OnInit, OnChanges {
  
  @Input() enableReadjustment;
  @Input() service;
  @Output() change: EventEmitter<number> = new EventEmitter();
  @Output() isValidChange: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.verifyReadjustmentValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.verifyReadjustmentValues();
  }

  calcPorcentage(price): number {
    if(!price.readjustmentValue) {
      return
    }
    return (price.readjustmentValue - price.value)/price.value;
  }

  onChange() {
    this.change.emit(this.calcPercentagePriceSimple());
  }

  private calcPercentagePriceSimple(): number {
    if(this.service.price.readjustmentValue) {
      return  this.calcPorcentage(this.service.price)*100;
    }
  }

  verifyReadjustmentValues() {
    const valid = this.service?.price?.readjustmentValue > 0;
    this.isValidChange.emit(valid);
  }
}
