import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MarinaOrderLogService } from 'src/app/services/marina-order-log.service';
import { SlingLogService } from 'src/app/services/sling-log.service';
import { StorageUtil } from 'src/app/utils/storage.util';

@Component({
  selector: 'app-sling-log-dialog',
  templateUrl: './sling-log-dialog.component.html',
  styleUrls: ['./sling-log-dialog.component.scss']
})
export class SlingLogDialogComponent implements OnInit {

  slingId: number;
  slingLogs = [];
  ordersLog = [];
  filteredUsers = [];
  filter = { marinaId: 0, targetId: null, userId: null };

  constructor(
    private slingLogService: SlingLogService,
    private marinaOrderLogService: MarinaOrderLogService,
    private dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private spinner: NgxSpinnerService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.slingId = this.config.data.slingId;
    const marina = StorageUtil.getMarina();
    this.filter.marinaId = marina.id;
    this.spinner.show();
    await this.findSlings();
    await this.findOrders();
    this.spinner.hide();
  }

  async findSlings(): Promise<void> {
    return new Promise<void>(async resolve => {
      this.slingLogService.findSlings(this.filter).subscribe(
        async (data) => {
          this.slingLogs = data;
          resolve();
        },
        async (error) => {
          console.error(error);
          resolve();
        }
      );
    });
  }

  async findOrders(): Promise<void> {
    return new Promise<void>(async resolve => {
      this.marinaOrderLogService.findOrders(this.filter).subscribe(
        async (data) => {
          this.ordersLog = data;
          resolve();
        },
        async (error) => {
          console.error(error);
          resolve();
        }
      );
    });
  }

  close(): void {
    this.dialogRef.close();
  }

}
