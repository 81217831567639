import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { InvoiceBankingBillet, Marina, SlingConfig } from 'src/app/models';
import { AnticipationLot } from 'src/app/models/anticipation-lot';
import { MarinaCompany } from 'src/app/models/marina-company';
import { AnticipationLotService } from 'src/app/services/anticipation-lot.service';
import { FinancesService } from 'src/app/services/finances.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { UserService } from 'src/app/services/user.service';
import { FormatUtil } from 'src/app/utils/format.util';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';
import moment from 'moment';
import b64toBlob from 'b64-to-blob';
import { ExtractInvoiceComponent } from 'src/app/components/extract-invoice/extract-invoice.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss']
})
export class SummaryComponent implements OnInit {

  globalFilter = '';
  anticipationLots: any[];
  anticipationLotsTable: any[];
  slingConfig: SlingConfig;
  numberOfRows = 10;
  accounts: Array<{ company?: number; value: any; }>;
  paymentMethods: Array<{ company?: number; value: any; }>;
  marina: Marina;
  marinaCompanies: MarinaCompany[];
  constructor(
    private anticipationLotService: AnticipationLotService,
    private sanitizer: DomSanitizer,
    private messageUtil: MessageUtil,
    private dialog: DialogService,
    private spinner: NgxSpinnerService,
    private slingConfigService: SlingConfigService,
    private invoiceService: InvoiceService,
    private financesService: FinancesService,
    private userService: UserService
  ) { }

  async ngOnInit(): Promise<void> {
    this.spinner.show();
    this.marina = StorageUtil.getMarina();
    this.slingConfig = await this.findSlingConfig();
    if (this.slingConfig) {
      this.numberOfRows = this.slingConfig.numberOfRecordsPerPage;
    }
    this.accounts = await this.findAccounts();
    this.paymentMethods = await this.findPaymentMethods();
    this.marinaCompanies = await this.financesService.findCompaniesWithDatabase();
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < this.marinaCompanies.length; index++) {
      const element = this.marinaCompanies[index];
      let list = await this.findAccounts(element);
      if (list.length > 0) {
        this.accounts = this.accounts.concat(list);
      }
      list = await this.findPaymentMethods(element);
      if (list.length > 0) {
        this.paymentMethods = this.paymentMethods.concat(list);
      }
    }
    await this.fillTable();
    this.spinner.hide();
  }

  async findAnticipationLots(): Promise<AnticipationLot[]> {
    return new Promise<AnticipationLot[]>(
      async (resolve, reject) => {
        this.anticipationLotService.findByMarinaId().subscribe(
          async (lots) => {
            resolve(lots);
          },
          async (err) => {
            reject([]);
          }
        );
      }
    );
  }

  async findSlingConfig(): Promise<SlingConfig> {
    return new Promise<SlingConfig>(
      async (resolve, reject) => {
        this.slingConfigService.getSlingConfigToday().subscribe(
          async (slingConfig) => {
            resolve(slingConfig);
          },
          async (err) => {
            reject(new SlingConfig());
          }
        );
      }
    );
  }

  filterGlobal(): void {
    this.anticipationLotsTable = this.anticipationLots.filter(
      (g) => {
        if (g.lot.includes(FormatUtil.getNotAccents(this.globalFilter).toUpperCase())) {
          return g;
        }
      }
    );
  }

  async showInvoices(lot): Promise<void> {
    if (!lot.showInvoices) {
      lot.billets = await this.findBilletsByLot(lot.id);
    }
    lot.showInvoices = !lot.showInvoices;
  }

  async findBilletsByLot(id: number): Promise<InvoiceBankingBillet[]> {
    return new Promise<InvoiceBankingBillet[]>(
      async (resolve, reject) => {
        this.anticipationLotService.findBilletsByAnticipationLodId(id).subscribe(
          async (billets: InvoiceBankingBillet[]) => {
            resolve(billets);
          },
          async (err) => {
            reject([]);
          }
        );
      }
    );
  }

  getPaymentDates(paymentDates: string): string {
    let date = '';
    const dates = paymentDates ? paymentDates.split(',') : [];
    for (let index = 0; index < dates.length; index++) {
      const element = dates[index];
      if (index > 0) {
        date += ' ';
      }
      date += moment(element, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YY');
    }
    return date;
  }

  hasMultiplePayments(paymentDates: string): boolean {
    return paymentDates && paymentDates.split(',').length > 1 || false;
  }

  extract(idInvoice): void {
    this.spinner.show();
    this.invoiceService.extract(idInvoice).subscribe(
      (data) => {
        this.openExtract(
          this.sanitizer.bypassSecurityTrustResourceUrl(
            URL.createObjectURL(
              (b64toBlob as any)(data, 'application/pdf')
            )
          )
        );
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      },
      () => this.spinner.hide());
  }

  openExtract(path): void {
    const dialog = this.dialog.open(ExtractInvoiceComponent, {
      width: '100%',
      height: '100%',
      dismissableMask: false,
      data: { path }
    });
  }

  async findAccounts(additionalCompany?: MarinaCompany): Promise<{ value: any, company?: number }[]> {
    return new Promise<{ value: any, company?: number }[]>(
      async (resolve, reject) => {
        this.financesService.findContasAtivas(additionalCompany).subscribe(
          async (accounts) => {
            const accs = accounts.map((a) => {
              const element: { value: any, company?: number } = { value: a };
              if (additionalCompany) {
                element.company = additionalCompany.id;
              }
              return element;
            });
            resolve(accs);
          },
          async (err) => {
            this.messageUtil.generateMessage('error', 'Erro ao buscar contas', err.error);
            reject([]);
          }
        );
      }
    );
  }

  async findPaymentMethods(additionalCompany?: MarinaCompany): Promise<{ company?: number, value: any }[]> {
    return new Promise<{ company?: number, value: any }[]>(
      async (resolve) => {
        this.financesService.findTipoFormasPagamento(additionalCompany).subscribe(
          async (paymentMethods) => {
            const methods = paymentMethods.map((a) => {
              const element: { value: any, company?: number } = { value: a };
              if (additionalCompany) {
                element.company = additionalCompany.id;
              }
              return element;
            });
            resolve(methods);
          },
          async (err) => {
            resolve([]);
          }
        );
      }
    );
  }

  getAccountName(billet: any): void {
    let filter = [];
    if (billet.companyId && this.marinaCompanies.find((m) => m.id === billet.companyId)) {
      if (!billet.accountIdsPaid || billet.accountIdsPaid === '') {
        filter = this.accounts.filter((a) => a.value.idConta === billet.accountId && a.company === billet.companyId);
      } else {
        filter = this.accounts.filter((a) => billet.accountIdsPaid.includes(a.value.idConta) && a.company === billet.companyId);
      }
    } else {
      if (!billet.accountIdsPaid || billet.accountIdsPaid === '') {
        filter = this.accounts.filter((a) => a.value.idConta === billet.accountId && !a.company);
      } else {
        filter = this.accounts.filter((a) => billet.accountIdsPaid.includes(a.value.idConta) && !a.company);
      }
    }
    if (filter.length > 0) {
      return filter[0].value.nome;
    }
  }

  getPaymentMethodName(billet: any): void {
    let filter = [];
    if (billet.companyId && this.marinaCompanies.find((m) => m.id === billet.companyId)) {
      filter = this.paymentMethods.filter(
        (a) => billet.paymentMethodIds.includes(a.value.idTipoFormaPagamento) && a.company === billet.companyId
      );
    } else {
      filter = this.paymentMethods.filter((a) => billet.paymentMethodIds.includes(a.value.idTipoFormaPagamento) && !a.company);
    }
    if (filter.length > 0) {
      return filter[0].value.descricao;
    }
  }

  cancel(lot: AnticipationLot): void {
    Swal.fire({
      title: 'Cancelamento de lote de antecipação',
      text: 'Todos os lançamentos e programações gerados por esse lote serão cancelados, deseja continuar?',
      icon: 'warning',
      backdrop: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then(async (result) => {
      if (result.value) {
          this.spinner.show();
          this.anticipationLotService.cancel(lot.id).subscribe(
            async (res) => {
              this.spinner.hide();
              this.messageUtil.generateMessage('success', 'Sucesso', 'Lote de antecipação removido com sucesso');
              await this.fillTable();
            },
            err => {
              const exception = err.error.data.exception;
              this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
              this.spinner.hide();
            }
          );
      }
    });
  }

  async fillTable(): Promise<void> {
    this.spinner.show();
    this.anticipationLots = (await this.findAnticipationLots()).map((x) => {
      const lot: any = { ...x };
      lot.showInvoices = false;
      lot.invoices = [];
      lot.pending = lot.total - (lot.paid + lot.discount - lot.interests);
      return lot;
    });
    this.filterGlobal();
    this.spinner.hide();
  }

  hasIncorretPaymentMethod(paymentMethodId): boolean {
    const filter = this.paymentMethods.filter((a) => a.value.idTipoFormaPagamento === paymentMethodId);
    if (filter.length > 0) {
      return (filter.length > 0) && (filter[0].value.tipo.id !== 1);
    }
  }

  getCompanyIndex(company: number): number {
    const m = this.marina.marinaCompanies.find((c) => c.id === company);
    return this.marina.marinaCompanies.indexOf(m) + 2;
  }

  getCompanyName(company: number): string {
    const c = this.marina.marinaCompanies.find((m) => m.id === company);
    return c ? ((c.companyFederalName) ? c.companyFederalName : '') : this.marina.companyName;
  }
}
