import { ContractType } from "../enums/contract-type.";

export class ServiceBoatIdentifierDTO{
    serviceBoatId: number;
    boatId: number;
    serviceId: number[];
    boatName: string;
    serviceName: string[];
    contractType: ContractType
}
