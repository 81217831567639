import { ShipType } from '../enums';

export class BoatFilter {
    boatName: string;
    shipType: ShipType;
    shipyardName: string;
    shipyardModelName: string;
    expirationStart: any;
    expirationEnd: any;
    reviewed: any;
    active: any;
    tieRegister: string;
    size: number;
    page: number;
    sort: string;
    order: string;
    vacanciesList: any[];
    boatProfile: string;
    movementGroup: any[];
    franchiseGroup: number[];
    boatOwner: number;
    
    serviceStatus: any;
    fromServiceStart: any;
    fromServiceEnd: any;
    toServiceStart: any;
    toServiceEnd: any;
    serviceInEffect: boolean;

    insuranceExpiration?: any;

    constructor() {
        this.size = 10;
        this.page = 0;
        this.active = null;
        this.order = 'ASC';
        this.vacanciesList = [];
        this.franchiseGroup = [];
        this.movementGroup = [];
        this.serviceInEffect = true;
    }
}
