<div class="form-group">
  <div class="form-control" [ngClass]="{'danger': !radiusOfGyration}"
       tooltipPosition="bottom"
       [pTooltip]="customTooltipMessage">
    <label>{{customTitle}}</label>
    <p-dropdown placeholder="Selecione..." [options]="radiusOfGyrations"
                (onChange)="setRadius(radiusOfGyration)" appendTo="body"
                [(ngModel)]="radiusOfGyration" name="radiusOfGyration" required>
    </p-dropdown>
  </div>
</div>
<br>
<div class="form-group" >
    <div [id]="mapNumber" #map style="height:400px; width:600px"></div>
</div>
