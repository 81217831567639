import { Product } from './product';
import { Periods } from './periods';
import { ServiceHistoryReajustmentDTO } from './dtos/service-history-reajustmentDTO';
import {ContractDocument} from "./contract-document";

export class ServiceBoat {
    id: number;
    service: Product;
    executionPeriodicity: Periods;
    billingPeriodicity: Periods;
    value: number;
    useDiscountOnPercentage: boolean;
    discount: number;
    discountPercentage?: number;
    observation: string;
    contractStartDate: any;
    contractEndDate: any;
    lastReadjustment: any;
    dueDateDiscount: number;
    dueDateDiscountPercentage?: number;
    filePath: string;
    active: boolean;
    contract: ContractDocument;
    serviceHistoryReajustment: ServiceHistoryReajustmentDTO[];

    constructor() {
        this.service = new Product();
        this.executionPeriodicity = Periods.Monthly;
        this.billingPeriodicity = Periods.Monthly;
        this.value = 0;
        this.useDiscountOnPercentage = false;
        this.discount = 0;
        this.discountPercentage = null;
        this.contractStartDate = null;
        this.contractEndDate = null;
        this.dueDateDiscount = 0;
        this.dueDateDiscountPercentage = null;
        this.active = true;
    }
}
