<p-fieldset>
  <p-header class="article-title">
    <span class="article-title">{{'INVOICING' | translate}}</span>
    <div class="header-buttons">
      <button class="fab-button primary" (click)="exportTable()" label="Exportar Excel"
      pTooltip="A exportação será de acordo com os filtros selecionados" tooltipPosition="left">
            <i class="pi pi-file"></i>
        </button> 
      <button appFiltersButton class="fab-button primary" pTooltip="Exibir/Ocultar Filtros" tooltipPosition="left">
        <i class="pi pi-filter"></i>
      </button>
    </div>
  </p-header>
  <form (submit)="loadSlingConfig()" appFilters class="filters">
    <div class="filter-row">
      <div class="form-control">
        <label>N° Fatura</label>
        <input
          pInputText
          type="number"
          [(ngModel)]="filter.invoiceNumber"
          name="invoiceNumber"
        />
      </div>
      <div class="form-control">
        <label>N° NFe</label>
        <input
          pInputText
          type="number"
          [(ngModel)]="filter.nfeNumber"
          name="orderNumber"
        />
      </div>
      <div class="form-control">
        <label>
          {{'CUSTOMER' | translate}}
        </label>
        <p-multiSelect [(ngModel)]="filter.customerId" [filter]="true" [options]="filteredCustomers"
                       appendTo="body" name="selectedCustomerId" defaultLabel="Selecione..." selectedItemsLabel="{0} itens selecionados">
        </p-multiSelect>
      </div>
      <div class="form-control">
        <label>
          {{'BOAT' | translate}}
        </label>
        <p-multiSelect [(ngModel)]="filter.boatId" [filter]="true" [options]="filteredBoats"
                       appendTo="body" name="selectedCustomerId" defaultLabel="Selecione..."
                       selectedItemsLabel="{0} itens selecionados">
        </p-multiSelect>
      </div>
      <div class="form-control">
        <label>
          {{'PRODUCT-TYPE' | translate}}
        </label>
        <p-multiSelect (onPanelHide)="hideProductTypeListSelect()" (onPanelShow)="showProductTypeListSelect()"
                       [(ngModel)]="filter.productTypes" selectedItemsLabel="{0} itens selecionados"
                       [filter]="true" [maxSelectedLabels]="1" defaultLabel="Selecione..."
                       [options]="productTypesList" [style]="{height: '30px'}"
                       appendTo="body" name="selectedCustomerId" >
        </p-multiSelect>
      </div>
      <div class="form-control">
        <label>
          {{'PRODUCTS' | translate}}
        </label>
        <p-multiSelect [(ngModel)]="filter.productId" [filter]="true" [maxSelectedLabels]="1"
                       [options]="productList" [style]="{height: '30px'}" appendTo="body"
                       appendTo="body" defaultLabel="Selecione..." name="productsList"
                       selectedItemsLabel="{0} itens selecionados">
        </p-multiSelect>
      </div>
      <div class="form-control">
        <label>
          {{'EMISSION-FROM' | translate}}
        </label>
        <p-calendar [(ngModel)]="filter.issueFrom" [monthNavigator]="true" [ngModelOptions]="{standalone: true}"
                    [showIcon]="true"
                    [yearNavigator]="true" [yearRange]="'2019:2030'" appendTo="body" dateFormat="dd/mm/yy"
                    i18n name="contractStartDateFrom" placeholder="Selecione..." rPCalendarMask>
        </p-calendar>
      </div>
      <div class="form-control">
        <label>
          {{'EMISSION-TO' | translate}}
        </label>
        <p-calendar [(ngModel)]="filter.issueTo" [monthNavigator]="true" [ngModelOptions]="{standalone: true}"
                    [showIcon]="true"
                    [yearNavigator]="true" [yearRange]="'2019:2030'" appendTo="body" dateFormat="dd/mm/yy"
                    i18n name="contractStartDateFrom" placeholder="Selecione..." rPCalendarMask>
        </p-calendar>
      </div>
      <div class="form-control">
        <label>
          {{'DUE-FROM' | translate}}
        </label>
        <p-calendar [(ngModel)]="filter.dueFrom" [monthNavigator]="true" [ngModelOptions]="{standalone: true}"
                    [showIcon]="true"
                    [yearNavigator]="true" [yearRange]="'2019:2030'" appendTo="body" dateFormat="dd/mm/yy"
                    i18n name="contractStartDateFrom" placeholder="Selecione..." rPCalendarMask>
        </p-calendar>
      </div>
      <div class="form-control">
        <label>
          {{'DUE-TO' | translate}}
        </label>
        <p-calendar [(ngModel)]="filter.dueTo" [monthNavigator]="true" [ngModelOptions]="{standalone: true}"
                    [showIcon]="true"
                    [yearNavigator]="true" [yearRange]="'2019:2030'" appendTo="body" dateFormat="dd/mm/yy"
                    i18n name="contractStartDateFrom" placeholder="Selecione..." rPCalendarMask>
        </p-calendar>
      </div>
      <div class="form-control">
        <label>
          {{'PAYMENT-FROM' | translate}}
        </label>
        <p-calendar [(ngModel)]="filter.paymentFrom" [monthNavigator]="true" [ngModelOptions]="{standalone: true}"
                    [showIcon]="true"
                    [yearNavigator]="true" [yearRange]="'2019:2030'" appendTo="body" dateFormat="dd/mm/yy"
                    i18n name="contractStartDateFrom" placeholder="Selecione..." rPCalendarMask>
        </p-calendar>
      </div>
      <div class="form-control">
        <label>
          {{'PAYMENT-TO' | translate}}
        </label>
        <p-calendar [(ngModel)]="filter.paymentTo" [monthNavigator]="true" [ngModelOptions]="{standalone: true}"
                    [showIcon]="true"
                    [yearNavigator]="true" [yearRange]="'2019:2030'" appendTo="body" dateFormat="dd/mm/yy"
                    i18n name="contractStartDateFrom" placeholder="Selecione..." rPCalendarMask>
        </p-calendar>
      </div>
      <div class="form-control">
        <label>
          {{'COMPETENCE-FROM' | translate}}
        </label>
        <p-calendar [(ngModel)]="filter.competenceFrom" [monthNavigator]="true" [ngModelOptions]="{standalone: true}"
                    [showIcon]="true"
                    [yearNavigator]="true" [yearRange]="'2019:2030'" appendTo="body" dateFormat="dd/mm/yy"
                    i18n
                    name="contractStartDateFrom" placeholder="Selecione..." rPCalendarMask>
        </p-calendar>
      </div>
      <div class="form-control">
        <label>
          {{'COMPETENCE-TO' | translate}}
        </label>
        <p-calendar [(ngModel)]="filter.competenceTo" [monthNavigator]="true" [ngModelOptions]="{standalone: true}"
                    [showIcon]="true"
                    [yearNavigator]="true" [yearRange]="'2019:2030'" appendTo="body" dateFormat="dd/mm/yy"
                    i18n
                    name="contractStartDateFrom" placeholder="Selecione..." rPCalendarMask>
        </p-calendar>
      </div>
      <div class="form-control">
        <label>
          {{'INVOICE-STATUS' | translate}}
        </label>
        <p-multiSelect [(ngModel)]="filter.invoiceStatus" [filter]="true" [maxSelectedLabels]="1"
                       [options]="invoiceStatusList" [style]="{height: '30px'}" appendTo="body"
                       name="selectedCustomerId" placeholder="Selecione...">
        </p-multiSelect>
      </div>
      <div class="form-control">
        <label>
          {{'NOTA-FISCAL' | translate}}
        </label>
        <p-dropdown [(ngModel)]="filter.invoiceOptions" [filter]="true" [options]="invoiceOptions"
                    [style]="{height: '30px'}" appendTo="body"
                    name="selectedCustomerId" placeholder="Selecione...">
        </p-dropdown>
      </div>
      <div class="buttons">
        <div class="form-button">
          <button (click)="clear()" class="easymarine danger" type="button">{{ 'CLEAR' | translate }}</button>
        </div>
        <div class="form-button">
          <button class="easymarine primary" type="submit">{{ 'FIND' | translate }}</button>
        </div>
      </div>
    </div>
  </form>
  <div class="table">
    <p-table #invoicingReportTable (onLazyLoad)="onChangePage($event)"
             [alwaysShowPaginator]="false"
             [globalFilterFields]="['dueDate', 'competencyDate', 'datePaid', 'customer', 'federalId', 'invoice', 'note', 'noteValue', 'receiptValue', 'totalValue']"
             [lazy]="true" [paginator]="true" [tableStyle]="{ 'min-height': '50rem' }"
             [rowsPerPageOptions]="[10,20,30,50,100]" [rows]="paginationFilter.size"
             [totalRecords]="totalRecords" [value]="invoicingReportsTable"
             autoLayout="true" csvSeparator=";" dataKey="id"  sortMode="multiple"
             styleClass="easy-table"
             width="100%">
      <ng-template pTemplate="caption">
        <!-- <div class="export-buttons">
          <button (click)="exportTable()" class="easymarine btn-csv-xls" label="Exportar CSV"
                  pTooltip="A exportação será de acordo com os filtros selecionados" tooltipPosition="top">
            <span>Exportar tabela</span>
          </button>
        </div> -->
        <span style="font-weight: bold; margin-top: 5px">
                    {{ "TOTAL-REGISTERS" | translate }}: {{invoicingReportsTable?.length}}
          {{ "OF" | translate }} {{invoicingReportTable?.totalRecords}}
                </span>
      </ng-template>
      <ng-template pTemplate="header">
        <tr class="thead">
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th class="text-right">
            {{totalNotas | number: '1.2-2'}}
          </th>
          <th class="text-right">
            {{totalRecibos | number: '1.2-2'}}
          </th>
          <th class="text-right">
            {{totalValue | number: '1.2-2'}}
          </th>
          <th></th>
        </tr>
        <tr class="thead">
          <th [pSortableColumn]="'i.duedate'" class="text-center">
            Data de vencimento
            <p-sortIcon [field]="'dueDate'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'i.competence_date'" class="text-center">
            Data de competência
            <p-sortIcon [field]="'competence_date'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'pay.datepaid'" class="text-center">
            Data de pagamento
            <p-sortIcon [field]="'datePaid'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'c.name'" class="text-center">
            Cliente
            <p-sortIcon [field]="'customer'"></p-sortIcon>
          </th>
          <th class="text-center">
            Embarcações
          </th>
          <th class="text-center">
            CPF
          </th>
          <th [pSortableColumn]="'i.invoice_number'" class="text-center">
            Fatura
            <p-sortIcon [field]="'invoice_number'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'nfe_number'" class="text-center">
            Nota
            <p-sortIcon [field]="'nfe_number'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'nfs_value'" class="text-center">
            Valor da Nota
            <p-sortIcon [field]="'noteValue'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'receipt_value'" class="text-center">
            Valor Recibo
            <p-sortIcon [field]="'receiptValue'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'i.invoice_value'" class="text-center">
            Valor Total
            <p-sortIcon [field]="'totalValue'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'y.products'">
            Descrição
            <p-sortIcon [field]="'description'"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template let-invoiceReport pTemplate="body">
        <tr>
          <td class="text-center">{{invoiceReport.dueDate | date: 'dd/MM/yyyy'}}</td>
          <td class="text-center">{{invoiceReport.competenceDate | date: 'dd/MM/yyyy'}}</td>
          <td class="text-center">{{formatDate(invoiceReport.datePaid)}}</td>
          <td class="text-center">{{invoiceReport.customerName}}</td>
          <td class="text-center" *ngIf="invoiceReport.qtdBoats <= 1">{{invoiceReport.boats}}</td>
          <td class="text-center" [pTooltip]="invoiceReport.boats" *ngIf="invoiceReport.qtdBoats > 1">Diversos</td>
          <td class="text-center">{{invoiceReport.federalId}}</td>
          <td class="text-center">{{invoiceReport.invoiceNumber}}</td>
          <td class="text-center">{{invoiceReport.nfsNumber}}</td>
          <td class="text-center">{{invoiceReport.nfsValue | number: '1.2-2'}}</td>
          <td class="text-center">{{invoiceReport.receiptValue | number: '1.2-2'}}</td>
          <td class="text-center">{{invoiceReport.invoiceValue | number: '1.2-2'}}</td>
          <td class="text-center">{{invoiceReport.products}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-fieldset>
