<div class="dialog-form">
    <div class="confirm-operation">
        <!-- Atracado -->
        <div class="flex1" *ngIf="manageDeck && (origin == 'SlingCheckDown' || origin == 'SlingSailing')">
            <p-radioButton [(ngModel)]="boatStatusModel" name="groupBoatStatus" value="MOORED" inputId="groupBoatStatus1"
                (click)="changeBoatStatus('MOORED')">
            </p-radioButton>
            <label for="groupBoatStatus1">
                &nbsp;
                <img src="assets/images/deck.png" alt="" style="width: 25px; height: 25px;">
                &nbsp;
                Deck
            </label>
        </div>
    
        <!-- Navegando -->
        <div class="flex1" *ngIf="origin == 'SlingCheckDown' || origin == 'SlingCheckMoored'">
            <p-radioButton [(ngModel)]="boatStatusModel" name="groupBoatStatus" value="SAILING" inputId="groupBoatStatus2"
                (click)="changeBoatStatus('SAILING')">
            </p-radioButton>
            <label for="groupBoatStatus2">
                &nbsp;
                <img src="assets/images/boat.png" alt="" style="width: 25px; height: 25px;">
                &nbsp;
                Navegar
            </label>
        </div>
    
        <!-- Finalizar na água -->
        <div class="flex1"
            *ngIf="(origin == 'SlingCheckMoored' || origin == 'SlingSailing' || origin == 'SlingUpQueue') && isFinishOnWater && !sling.finishedOnWater">
            <p-radioButton [(ngModel)]="boatStatusModel" name="groupBoatStatus" value="FINISHED_ON_WATER" inputId="groupSlingStatus1"
                (click)="finishOnWater()">
            </p-radioButton>
            <label for="groupSlingStatus1">
                &nbsp;
                <img src="assets/images/check.png" alt="" style="width: 25px; height: 25px;">
                &nbsp;
                Finalizar na água
            </label>
        </div>
    
        <!-- Fila de subida -->
        <div class="flex1"
            *ngIf="origin == 'SlingCheckMoored' || origin == 'SlingSailing' || origin == 'SlingFinishedOnWater'">
            <p-radioButton [(ngModel)]="boatStatusModel" name="groupBoatStatus" value="PENDING_UP" inputId="groupSlingStatus"
                (click)="setSlingStatus('PENDING_UP')">
            </p-radioButton>
            <label for="groupSlingStatus">
                &nbsp;
                <img src="assets/images/boat_up_dark.png" alt="" style="width: 40px; height: 25px;">
                &nbsp;
                Fila de subida
            </label>
        </div>
    
        <!-- Retirar da fila -->
        <div class="flex1" *ngIf="origin == 'SlingUpQueue'">
            <p-radioButton [(ngModel)]="boatStatusModel" name="groupBoatStatus" value="WATER" inputId="groupSlingStatus2"
                (click)="setSlingStatus('WATER')">
            </p-radioButton>
            <label for="groupSlingStatus2">
                &nbsp;
                <img src="assets/images/boat_down_dark.png" alt="" style="width: 40px; height: 25px;">
                &nbsp;
                Retirar da fila
            </label>
        </div>
    
        <!-- Subir -->
        <div class="flex1"
            *ngIf="origin == 'SlingCheckMoored' || origin == 'SlingSailing' || origin == 'SlingFinishedOnWater' || origin == 'SlingUpQueue'">
            <p-radioButton [(ngModel)]="boatStatusModel" name="groupBoatStatus" value="STORED" inputId="groupBoatStatus3"
                (click)="changeBoatStatus('STORED')">
            </p-radioButton>
            <label for="groupBoatStatus3">
                &nbsp;
                <img src="assets/images/boat_stored.png" alt="" style="width: 35px; height: 25px;">
                &nbsp;
                Subir
            </label>
        </div>
    </div>
    
    <div class="buttons">
        <button class="easymarine danger" (click)="dialogRef.close()">{{ 'CANCEL' | translate }}</button>
        <button class="primary easymarine" (click)="save()">{{ 'SAVE' | translate }}</button>
    </div>
</div>