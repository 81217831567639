import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {AbstractControl, FormArray, FormGroup, UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Address, Customer } from 'src/app/models';
import { FederalIdType } from 'src/app/models/federal-id-type';
import { Gender } from 'src/app/models/gender';
import { LicenseFiles } from 'src/app/models/license-files';
import { CustomerService } from 'src/app/services/customer.service';
import { GroupService } from 'src/app/services/group.service';
import { LocationService } from 'src/app/services/location.service';
import { ToastService } from 'src/app/services/toast.service';
import { ValidatorsUtil } from 'src/app/services/validators.util';
import { FormUtil } from 'src/app/utils/form.util';
import { Countries } from 'src/app/utils/location/countries';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-simple-cad-customer',
  templateUrl: './simple-cad-customer.component.html',
  styleUrls: ['./simple-cad-customer.component.scss']
})
export class SimpleCadCustomerComponent implements OnInit {

  customerForm = new UntypedFormGroup({});
  customer: Customer = new Customer();
  federalIdTypeRadioData = [
    { label: 'FEDERAL-ID.PHYSICAL', value: FederalIdType.Physical },
    { label: 'FEDERAL-ID.JURIDICAL', value: FederalIdType.Juridical },
    { label: 'FEDERAL-ID.FOREIGNER', value: FederalIdType.Foreigner }
  ];


  loading:boolean = false;

  validFederalId = true;

  isFederalIdInUse = false;

  isFederalTypePhysical = true;
  nameLabel = 'NAME';
  registrationLabel = 'PHYSICAL-REGISTRATION';
  isFederalTypeForeigner: boolean = false;

  federalIdType = 'Physical';

  errorMessage: string;
  countries: { label: string, value: any }[];
  groups: { label: string, value: any }[] = new Array();
  states: { label: string, value: any }[];
  cities: string[];
  filteredCities: any;

  @Output()
  onSaveSucess = new EventEmitter<Customer>();

  constructor(
    private messageUtil: MessageUtil,
    private spinner: NgxSpinnerService,
    private toasty: ToastService,
    private validatorsUtil: ValidatorsUtil,
    private locationService: LocationService,
    private customerService: CustomerService,
    private groupService: GroupService,
  ) { }

  ngOnInit(): void {
    this.startForm(this.customer);
    this.loadCountries();
    this.loadGroupActive();
  }

  loadGroupActive(): void {
    this.groupService.findAllActive().subscribe(
      (data) => {
        this.groups = [{ label: 'Selecione...', value: null }].concat(data.map((g) => ({ label: g.name, value: g.id })));
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      });
  }

  async checkFederalIdField(): Promise<void> {
    await this.federalIdInUse();
    this.validateFederalId();
  }

  validateFederalId(): void {
    this.validFederalId = this.validatorsUtil.cpfCnpjValidator(this.customerForm.value['federalId']);
    if (!this.validFederalId) {
      this.customerForm.get('federalId').setErrors({ invalidFederalId: true });
      this.customerForm.get('federalId').markAsTouched();
      this.customerForm.get('federalId').markAsDirty();
    }
  }

  async federalIdInUse(): Promise<void> {
    return new Promise<void>(
      async (resolve) => {
        this.isFederalIdInUse = false;
        const federalIdClean = this.customerForm.get('federalId').value.replace("/", "_");
        this.customerService.getByFederalId(federalIdClean).subscribe(
          async (data) => {
            if (data !== null && data.id !== this.customerForm.get('id').value) {
              this.isFederalIdInUse = true;
              this.customerForm.get('federalId').setErrors({ federalIdInUse: true });
              this.customerForm.get('federalId').markAsTouched();
              this.customerForm.get('federalId').markAsDirty();
              resolve();
            }
          },
          async (err) => {
            resolve();
          }
        );
      }
    );
  }

  save(){
   // this.valiteCommercialAddress();
    FormUtil.touchAndSendFormGroup(this.customerForm);
    this.customerForm.get('onlyAssociated').patchValue(true);
    if (this.federalIdType === FederalIdType.Juridical) {
      this.customerForm.get('gender').setValue(Gender.M);
      this.customerForm.get('commercialAddress').setValue(this.customerForm.get('residentialAddress').value);
    }

    if (!this.customerForm.valid) {
      this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING',
        'Por favor, preencha os campos obrigatórios');
      return;
    } else {
      Object.keys(this.customerForm.controls).forEach((key) => {
        this.customer[key] = this.customerForm.get(key).value;
      });
      this.loading = true;
      this.spinner.show();

        this.customer.billingCompany = null;

      if (this.customer.id) {
      } else {
        this.customer.marina.id = StorageUtil.getMarinaId();
        let customerInserted: Customer;
        this.customerService.create(this.customer).subscribe(
          (data) => {
            customerInserted = data;
            this.toasty.success('Cliente cadastrado com sucesso!');
            this.loading = false;
            this.spinner.hide();
            this.onSaveSucess.emit(customerInserted);
          },
          (error ) => {
            if (error.error.data.exception.message === 'MSG.EMAIL-EXISTS') {
              this.customerService.getByEmailAndMarinaId(this.customer.email).subscribe((value) => {
                Swal.fire('Falha ao cadastrar!', this.messageUtil.translateKey(error.error.data.exception.message)+' no cliente '+value.name, 'error');
                this.spinner.hide();
              }, (error) => {
                Swal.fire('Falha ao cadastrar!', '\nEmail reservado.', 'error');
                this.spinner.hide();
              });
            } else {
            Swal.fire('Falha ao cadastrar!', this.messageUtil.translateKey(error.error.data.exception.message), 'error');
            this.spinner.hide();
          }
          },
          // integracao financeiro
          () => {
            this.spinner.hide();
          }
        );
      }
    }
  };

  validateFederalIdType(): void {
    if (this.federalIdType === FederalIdType.Physical) {
      this.isFederalTypePhysical = true;
      this.isFederalTypeForeigner = false;
      this.nameLabel = 'NAME';
      this.registrationLabel = 'PHYSICAL-REGISTRATION';
    } else if ( this.federalIdType === FederalIdType.Foreigner) {
      this.isFederalTypePhysical = false;
      this.isFederalTypeForeigner = true;
      this.nameLabel = 'NAME';
      this.registrationLabel = 'FOREIGNER-REGISTRATION';
    
    } else {
      this.isFederalTypePhysical = false;
      this.isFederalTypeForeigner = false;
      this.nameLabel = 'COMPANY-NAME';
      this.registrationLabel = 'JURIDICAL-REGISTRATION';
    }
    if (!this.customer.id) {
      this.customerForm.get('federalId').setValue('');
      this.customerForm.get('federalId').setErrors([]);
    }
  }

  getErrorTooltip(control: AbstractControl): string {
    let tooltip = '';
    if (control && control.touched && control.dirty && control.errors) {
      Object.keys(control.errors).forEach((key) => {
        switch (key) {
          case 'email':
            tooltip += this.messageUtil.translateKey('NOT-FORMATTED-EMAIL');
            tooltip += '\n';
            break;
          case 'required':
            tooltip += this.messageUtil.translateKey('REQUIRED-FIELD');
            tooltip += '\n';
            break;
          case 'federalIdInUse':
            tooltip += this.messageUtil.translateKey('FEDERAL-ID-ALREADY-EXISTS');
            tooltip += '\n';
            break;
          case 'invalidFederalId':
            tooltip += this.messageUtil.translateKey('INVALID-FEDERAL-ID');
            tooltip += '\n';
            break;
          default:
            break;
        }
      });
    }

    return tooltip;
  }

  filterCities(val: any): void {
    this.filteredCities = val ? this.cities.filter((c) => c.toUpperCase().includes(val.query.toUpperCase())) : this.cities;
  }


  startForm(customer: Customer): void {
    this.customerForm = new UntypedFormGroup({
      id: new UntypedFormControl(customer.id),
      federalIdType: new UntypedFormControl({ value: customer.federalIdType,  }, Validators.required),
      onlyAssociated: new UntypedFormControl(customer.onlyAssociated, Validators.required),
      name: new UntypedFormControl(customer.name, Validators.compose([Validators.required, Validators.maxLength(50)])),
      federalId: new UntypedFormControl(customer.federalId, Validators.compose([Validators.required, Validators.maxLength(20)])),
      gender: new UntypedFormControl(customer.gender ? customer.gender : Gender.M, Validators.required),
      registration: new UntypedFormControl(customer.registration),
      email: new UntypedFormControl(customer.email, Validators.compose([Validators.required, Validators.email])),
      maritalStatus: new UntypedFormControl(customer.maritalStatus),
      spouse: new UntypedFormControl(customer.spouse),
      phone: new UntypedFormControl(customer.phone, ),
      phone2: new UntypedFormControl(customer.phone2),
      residentialPhone: new UntypedFormControl(customer.residentialPhone),
      commercialPhone: new UntypedFormControl(customer.commercialPhone),
      birthday: new UntypedFormControl(customer.birthday),
      emailCollection: new UntypedFormControl(customer.emailCollection),
      billingDay: new UntypedFormControl(customer.billingDay),
      dueDate: new UntypedFormControl(customer.dueDate),
      group: new FormGroup({
        id: new UntypedFormControl( null, ),
      }),
      residentialAddress: new FormGroup({
        zip: new UntypedFormControl(customer.residentialAddress.zip, this.isResidentialAddressRequired(customer)),
        country: new UntypedFormControl(customer.residentialAddress.country, this.isResidentialAddressRequired(customer)),
        state: new UntypedFormControl(customer.residentialAddress.state, this.isResidentialAddressRequired(customer)),
        city: new UntypedFormControl(customer.residentialAddress.city, this.isResidentialAddressRequired(customer)),
        street: new UntypedFormControl(customer.residentialAddress.street, this.isResidentialAddressRequired(customer)),
        district: new UntypedFormControl(customer.residentialAddress.district),
        number: new UntypedFormControl(customer.residentialAddress.number, this.isResidentialAddressRequired(customer)),
        complement: new UntypedFormControl(customer.residentialAddress.complement)
      }),
      commercialAddress: new FormGroup({
        zip: new UntypedFormControl(customer.commercialAddress.zip),
        country: new UntypedFormControl(customer.commercialAddress.country),
        state: new UntypedFormControl(customer.commercialAddress.state),
        city: new UntypedFormControl(customer.commercialAddress.city),
        street: new UntypedFormControl(customer.commercialAddress.street),
        district: new UntypedFormControl(customer.commercialAddress.district),
        number: new UntypedFormControl(customer.commercialAddress.number),
        complement: new UntypedFormControl(customer.commercialAddress.complement)
      }),
      emergencyContact: new UntypedFormControl(customer.emergencyContact),
      emergencyPhone: new UntypedFormControl(customer.emergencyPhone),
      observation: new UntypedFormControl(customer.observation),
      licence: new FormGroup({
        register: new UntypedFormControl(customer.licence.register),
        expiration: new UntypedFormControl(customer.licence.expiration),
        typeLicense: new UntypedFormControl(customer.licence.typeLicense),
        country: new UntypedFormControl(customer.licence.country),
        state: new UntypedFormControl(customer.licence.state),
        city: new UntypedFormControl(customer.licence.city),
        files: new FormArray<FormGroup>([]),
      }),
      billingCompanyOptionId: new UntypedFormControl(customer.billingCompany),
      issRetidoFonte: new UntypedFormControl(customer.issRetidoFonte),
      customerProfile: new UntypedFormControl(customer.customerProfile),
    });

    if (customer.licence.files && customer.licence.files.length > 0) {
      customer.licence.files.forEach((f) => {
        this.addFile(f);
      });
    }
    console.log(this.customerForm.get('licence.files'));
  }

  addFile(f?: LicenseFiles): void {
    if(!f){
      f = new LicenseFiles();
    }
    const controls = [...(this.customerForm.get('licence.files') as UntypedFormArray).controls];
    const formGroup = new UntypedFormGroup({
      id: new UntypedFormControl(f.id),
      name: new UntypedFormControl(f.name),
      path: new UntypedFormControl(f.path)
    });
    
    controls.push(formGroup);

    (this.customerForm.get('licence.files') as UntypedFormArray).push(formGroup);
  }

  isResidentialAddressRequired(customer: Customer): ValidatorFn {

    return (this.isFederalTypePhysical && !customer.onlyAssociated) ? Validators.required : null;
  }

  loadCountries(): void {
    this.countries = [{ label: 'Selecione...', value: null }].concat(Countries.countryList.map((c) => ({ label: c.name, value: c.code })));
  }

  loadStates(countryId): void {
    this.states = [{ label: 'Selecione...', value: null }]
      .concat(this.locationService.getStates().map((s) => ({ label: s.name, value: s.id })));
  }

  loadCities(countryId: string, stateId: string): void {
    this.locationService.getCities(countryId, stateId).subscribe(
      (data) => {
        this.cities = data.map((c) => c.name);
      },
      (error) => {
        this.cities = null;
        this.errorMessage = error;
      }
    );
  }

  getCep(addressType: string): void {
    this.spinner.show();
    let address: Address = new Address();
    let zipCode = '';
    if (addressType === ('residential')) {
      zipCode = this.customerForm.get('residentialAddress.zip').value;
      address = this.customerForm.get('residentialAddress').value;
    } else if ('commercial') {
      zipCode = this.customerForm.get('commercialAddress.zip').value;
      address = this.customerForm.get('commercialAddress').value;
    }

    if (address.zip) {
      address.zip = address.zip.replace(/\D+/g, '');
    }

    this.locationService.getCep(address.zip).subscribe(
      (data) => {
        this.spinner.hide();
        if (data !== null) {
          this.resetAddressFields(address);
          const cep = data;
          address.zip = zipCode;
          address.country = 'BR';
          this.loadStates(address.country);
          address.state = cep.uf;
          address.city = this.firstLetterToUpperCase(cep.cidade);
          address.district = this.firstLetterToUpperCase(cep.bairro);
          address.street = this.firstLetterToUpperCase(cep.logradouro);
          if (addressType === 'residential') {
            this.customerForm.get('residentialAddress').setValue(address);
          } else {
            this.customerForm.get('commercialAddress').setValue(address);
          }
        } else {
          this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', 'MSG.CEP-INVALID');
          address.zip = null;
        }
      },
      (error) => {
        this.spinner.hide();
        this.resetAddressFields(address);
        this.errorMessage = error;
      }
    );
  }

  resetAddressFields(address: Address): void {
    address.zip = null;
    address.country = null;
    address.state = null;
    address.city = null;
    address.district = null;
    address.street = null;
    address.number = null;
    address.complement = null;
  }

  private firstLetterToUpperCase(text: string): string {
    const words = text.toLowerCase().split(' ');
    for (let a = 0; a < words.length; a++) {
      const w = words[a];
      if (w[0] !== undefined) {
        words[a] = w[0].toUpperCase() + w.slice(1);
      }
    }
    return words.join(' ');
  }
}
