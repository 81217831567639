import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { Marina, SlingConfig } from 'src/app/models';
import { MarinaCompany } from 'src/app/models/marina-company';
import { CustomerService } from 'src/app/services/customer.service';
import { FinancialMultipleCompaniesService } from 'src/app/services/financial/financial-multiple-companies.service';
import { GroupService } from 'src/app/services/group.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { FormatUtil } from 'src/app/utils/format.util';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';
import Swal from 'sweetalert2';
import { GroupFormComponent } from './group-form/group-form.component';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit {

  numberOfRows: number;
  private groups: any[] = new Array();
  groupsTable: any[] = new Array();
  globalFilter = '';
  noGroup: number;
  loggedMarina: Marina;
  marinaCompanies: MarinaCompany[];
  marinaCompaniesWithDatabase: MarinaCompany[];
  groupStatus: boolean = null;
  activeCustomers: boolean = null;

  slingConfig: SlingConfig;
  showFinancialCompany: boolean = false;

  constructor(
    private dialog: DialogService,
    private groupService: GroupService,
    private customerService: CustomerService,
    private messageUtil: MessageUtil,
    private spinner: NgxSpinnerService,
    private slingConfigService: SlingConfigService,
    private financialMultipleCompaniesService: FinancialMultipleCompaniesService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.loggedMarina = StorageUtil.getMarina();
    this.marinaCompanies = this.loggedMarina.marinaCompanies;
    await this.loadSlingConfig();
    this.configureNumberOfRows(this.slingConfig);
    this.findAll();
  }

  private loadSlingConfig(): Promise<void> {
    return new Promise<void>(
      async (res) => {
        this.slingConfigService.getSlingConfig().subscribe(
          async (data) => {
            this.slingConfig = data[0];
            res();
          },
          (error) => res()
        );
      }
    );
  }

  private configureNumberOfRows(slingConfig: SlingConfig) {
    this.numberOfRows = slingConfig != null && slingConfig ? slingConfig.numberOfRecordsPerPage : 10;
  }

  changeCustomerFilter(value){
    this.activeCustomers = value;
  }

  filterGlobal(): void {
    this.groupsTable = this.groups.filter(
      (g) =>
        (FormatUtil.getNotAccents(g.name).toUpperCase()
          .includes(FormatUtil.getNotAccents(this.globalFilter).toUpperCase()) ||
          g.name.toUpperCase().includes(this.globalFilter.toUpperCase())
        )
    );
  }

  async openDialog(group): Promise<void> {
    const data = {
      group: (group || null),
      slingConfig: this.slingConfig
    };
    const dialogRef = this.dialog.open(GroupFormComponent, {
      width: '50%',
      dismissableMask: false,
      data,
      header: this.messageUtil.translateKey('GROUPS')
    }).onClose.subscribe(() => { this.findAll(); });
  }

  edit(groupForm): void {
    this.openDialog(groupForm);
  }

  deleteConfirm(groupForm): void {
    Swal.fire({
      text: 'Deseja realmente deletar o grupo ' + groupForm.name + '.',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((isConfirm) => {
      if (isConfirm.value) {
        this.delete(groupForm);
      }
    });
  }

  delete(groupForm): void {
    this.groupService.delete(groupForm).subscribe(
      () => {
        this.findAll();
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }

  async findAll(): Promise<void> {
    return new Promise<void>(
      async (res) => {
        this.spinner.show();
        this.groupService.findAll().subscribe(
          async (groups) => {
            this.groups = groups;
            await this.countCustomerWithNoGroup();
            this.filterGlobal();
            this.spinner.hide();
            res();
          },
          async (error) => {
            this.spinner.hide();
            const exception = error.error.data.exception;
            this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
            res();
          }
        );
      }
    );
  }

  countCustomerWithNoGroup(): Promise<void> {
    return new Promise<void>(
      async (res) => {
        this.customerService.countCustomerWithNoGroup().subscribe(
          async (data) => {
            this.noGroup = data;
            res();
          }
        );
      }
    );
  }

  multiCompanyByCustomer(slingConfig: SlingConfig): boolean {
    return this.financialMultipleCompaniesService.hasFinancialMultipleCompaniesByCustomer(slingConfig);
  }

  getCompanyIndex(company: MarinaCompany): number {
    const m = this.marinaCompanies.find((c) => c.id === company.id);
    return this.marinaCompanies.indexOf(m) + 2;
  }

  getCompanyName(company: MarinaCompany): string {
    const c = this.marinaCompanies.find((m) => m.id === company.id);
    return c.companyFederalName ? c.companyFederalName : '';
  }
}
