import { StorageUtil } from "src/app/utils/storage.util";
import { Conta } from "./conta";
import { Favorecido } from "./favorecido";
import { PlanoConta } from "./plano-conta";
import { TipoFormaPagamento } from "./tipo-forma-pagamento";

export class LancamentoProgramado {
    centroCusto : any;
    conta : Conta;
    dataCadastro : Date;
    dataCompetencia : Date;
    dataEmissao : Date;
    dataVencimento : Date;
    documento? : {idTipoDocumento: 2};
    favorecido : Favorecido;
    formaPagamento : TipoFormaPagamento;
    frequencia? : number;
    historico : string;
    idLancamentoProgramado? : number;
    identificador? : any;
    lancamentos? : any[];
    marinaCompany? : any;
    modelo? : number;
    numeroParcela? : number;
    observacao? : any;
    parcelas? : any;
    planoConta : PlanoConta;
    rateios? : any;
    situacao? : number;
    tipo? : number;
    usuario : any;
    valor : number;
    
    adaptProgramacao(programacao: any, data:any) : LancamentoProgramado {
        let userName = StorageUtil.getUserName();
        let company = data.marinaCompany
        let hasCompany = !!company;
        let adapted = new LancamentoProgramado();
        adapted.centroCusto = {
                idCentroCusto: programacao?.centroCusto?.idCentroCusto 
                ? programacao?.centroCusto?.idCentroCusto : null,
        };
        adapted.conta = {
                idConta: programacao.conta,
        };
        adapted.dataCadastro = new Date();
        adapted.dataCompetencia = new Date(programacao.dataCompetencia);
        adapted.dataEmissao = new Date(data.createDate);
        adapted.dataVencimento = new Date(programacao.dataVencimento);
        adapted.favorecido = {
            idFavorecido: data.supplierId,  
            nome: data.supplierName,
        };
        adapted.formaPagamento = {
                idTipoFormaPagamento: programacao.formaPagamento,
        }; 
        adapted.historico = 'Importação de Nota Fiscal Nº: ' + data.nfeNumero,
        adapted.marinaCompany = hasCompany ? {
                id: company.id,
                companyName: company.companyName,
                companyFederalId: company.companyFederalId,
                finances: company.finances,
            } : null;
        adapted.planoConta = {
                idPlanoConta: programacao.planoConta.idPlanoConta,
        };
        adapted.usuario = userName;
        adapted.valor = programacao.valor;
        console.log(adapted);
        
        return adapted;
    }
}
