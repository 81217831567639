<div class="dialog-form">
  <h3 class="form-title">Selecione qual data você deseja alterar</h3>
  <div style="display: flex; flex-direction: row;">
    <p-radioButton
      name="dateOption"
      value="due"
      label="Vencimento"
      [(ngModel)]="selectedValue"
      [disabled]="!canChangeDueDate">
    </p-radioButton>
    <p-radioButton
      name="dateOption"
      value="competence"
      label="Compêtencia"
      [(ngModel)]="selectedValue"
      [disabled]="changeToPayment">
    </p-radioButton>
  </div>
  <h3 class="form-title">Selecione a nova data</h3>
  <div  class="form-control">
      <p-calendar #calendarDate class="invoice-change-date" [inline]="true" dateFormat="dd/mm/yy" name="dueDate" (onSelect)="selectDate(calendarDate)"
          [showIcon]="true" i18n [monthNavigator]="true" [yearNavigator]="true" appendTo="body"
          [yearRange]="'2000:2055'" [minDate]="getMinDate()" [readonlyInput]="true" [disabled]="selectedValue === undefined">
      </p-calendar>
  </div>
  <div class="buttons">
    <button class="easymarine danger" (click)="close()">{{ 'CANCEL' | translate }}</button>
    <button class="primary easymarine" (click)="save()" [disabled]="selectedValue === undefined || date ===  undefined">{{ 'SAVE' | translate }}</button>
  </div>
</div>
