<p-fieldset>
  <p-header class="article-title">
    <span class="article-title"
      >{{ "BILLING" | translate }} > Gestão de Crédito</span
    >
    <div class="header-buttons">
      <button
      class="fab-button primary"
      (click)="exportTable()"
      label="Exportar valores"
      pTooltip="Exportar Valores"
      tooltipPosition="left"
    >
      <i class="pi pi-file"></i>
    </button>

  </div>
  </p-header>

  <div class="filter-row" style="display: flex; justify-content: space-between">
    <div class="form-control col-3">
      <label class="block mb-2 pt-1">Pesquise pelo nome do cliente</label>
      <input
        pInputText
        type="text"
        class="form-control"
        [(ngModel)]="customerName"
        (keyup.enter)="getCustomers()"
        style="width: 100%; max-height: none"
      />
    </div>
    <div class="form-control">
      <button
        class="easymarine primary"
        style="margin-top: 2rem !important"
        (click)="getCustomers()"
      >
        {{ "FIND" | translate }}
      </button>
    </div>
  </div>
</p-fieldset>
<div class="pt-3">
  <p-table
    #tt
    [value]="customers"
    [paginator]="true"
    [rows]="50"
    [rowsPerPageOptions]="[50, 100, 200]"
    [responsive]="true"
    [loading]="loading"
    [scrollable]="true"
    [resizableColumns]="true"
    [reorderableColumns]="true"
    [autoLayout]="true"
    (onPage)="updatePageInfo($event)"
  >
    <ng-template pTemplate="header">
      <tr>
        <th colspan="2" style="padding-top: 0px; padding-bottom: 0px; padding-left: 0px;">
          <div class="pt-2 pb-1 pl-2" style="display: flex; padding-left: 0px;">
            <div style="padding-left: 0px;">
              <p-inputSwitch
                class="pt-3"
                [(ngModel)]="show"
                onLabel="Sim"
                offLabel="Não"
                style="margin-left: 1rem"
                (onChange)="getCustomers()"
              ></p-inputSwitch>
            </div>
            <div style="padding-top: 5px; padding-left: 8px">
              Mostrar todos os clientes
            </div>
          </div>
        </th>
        <th style="padding-top: 0px; padding-bottom: 0px">
          {{ totalValue | currency : "BRL" }}
        </th>
        <th style="padding-top: 0px; padding-bottom: 0px">
          <div class="d-flex justify-content-end mb-2" style="display: flex">
            <span
              >Registros {{ first }} de
              {{ totalRecords }}</span
            >
          </div>
        </th>
      </tr>
      <tr>
        <th pSortableColumn="name">
          Nome
          <p-sortIcon field="name"></p-sortIcon>
        </th>
        <th pSortableColumn="phone">
          Telefone
          <p-sortIcon field="phone"></p-sortIcon>
        </th>
        <th pSortableColumn="balanceNow">
          Crédito
          <p-sortIcon field="balanceNow"></p-sortIcon>
        </th>
        <th>Ações</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-customer>
      <tr>
        <td
          [ngClass]="{
            'bg-green': customer.balanceNow > 0,
            'bg-red': customer.balanceNow < 0
          }"
        >
          {{ customer.name }}
        </td>
        <td
          [ngClass]="{
            'bg-green': customer.balanceNow > 0,
            'bg-red': customer.balanceNow < 0
          }"
        >
          {{ customer.phone }}
        </td>
        <td
          [ngClass]="{
            'bg-green': customer.balanceNow > 0,
            'bg-red': customer.balanceNow < 0
          }"
        >
          {{ customer.balanceNow | currency : "BRL" }}
        </td>
        <td
          [ngClass]="{
            'bg-green': customer.balanceNow > 0,
            'bg-red': customer.balanceNow < 0
          }"
        >
          <p-menu
            appendTo="body"
            #menu
            [model]="menuItems"
            [popup]="true"
            [showTransitionOptions]="'150ms'"
            [hideTransitionOptions]="'150ms'"
          >
          </p-menu>
          <button
            (click)="menu.toggle($event); changeSelectedCustomer(customer)"
            pButton
            type="button"
            icon="pi pi-ellipsis-v"
            class="p-button-text p-button-rounded p-button-lg"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
