import {Component, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {LazyLoadEvent, MenuItem, SelectItem} from 'primeng/api';
import {DialogService} from 'primeng/dynamicdialog';
import {Subscription} from 'rxjs';
import {
  SignersContractDialogComponent
} from 'src/app/components/contracts/signers-contract-dialog/signers-contract-dialog.component';
import {Boat, SlingConfig} from 'src/app/models';
import {DocumentDefaultSigner} from 'src/app/models/document-default-signer';
import {DocumentContractView} from 'src/app/models/dtos/document-contract-view';
import {MarinaContractPriceDTO} from 'src/app/models/dtos/marina-contract-price-dto';
import {ProductType} from 'src/app/models/enums';
import {ContractType} from 'src/app/models/enums/contract-type.';
import {DocumentStatus} from 'src/app/models/enums/document-status';
import {DocumentType} from 'src/app/models/enums/document-type';
import {TermUseType} from 'src/app/models/enums/term-use-type';
import {MarinaTermUse} from 'src/app/models/marina-term-use';
import {PaginationFilter} from 'src/app/models/pagination-filter';
import {BoatService} from 'src/app/services/boat.service';
import {ContractDocumentService} from 'src/app/services/contract-document.service';
import {DocumentDefaultSignerService} from 'src/app/services/document-default-signer.service';
import {MarinaContractPriceService} from 'src/app/services/marina-contract-price.service';
import {MarinaTermUseService} from 'src/app/services/marina-term-use.service';
import {ProductService} from 'src/app/services/product.service';
import {SlingConfigService} from 'src/app/services/sling-config.service';
import {ToastService} from 'src/app/services/toast.service';
import {WebSocketService} from 'src/app/services/web-socket.service';
import {ContractUtil} from 'src/app/utils/contract.util';
import {MessageUtil} from 'src/app/utils/message.util';
import {environment} from 'src/environments/environment';
import Swal from 'sweetalert2';
import {NewContractDialogComponent} from './new-contract-dialog/new-contract-dialog.component';
import {SignContractDialogComponent} from './sign-contract-dialog/sign-contract-dialog.component';
import {DocumentContractedMarinaService} from '../../../services/document-contracted-marina.service';
import {FormatUtil} from '../../../utils/format.util';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent implements OnInit {

  boats: Boat[] = [];
  selectTab = 0;

  boatListFilter: SelectItem[] = [];
  serviceListFilter: SelectItem[] = [];
  selectedBoatIdFilter: any;

  templates: any[] = [];

  services: any[] = [];
  selectedServiceIdFilter: any;

  documents: DocumentContractView[] = [];

  selectedDocuments: string[] = [];

  paginationFilter: PaginationFilter = new PaginationFilter();

  slingConfig: SlingConfig;
  numberOfRows: number;

  statusListFilter = [];
  contractTypeListFilter = [];
  documentTypeListFilter = [];
  filter: any = {};

  totalRecords: any;

  termUse: MarinaTermUse;
  marinaContracPrice: MarinaContractPriceDTO;
  signerMarina: DocumentDefaultSigner;

  loadingPage = true;

  contractSubscription: Subscription

  pt = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  };
  menuItems: MenuItem[] = [];
  selectedDocument: DocumentContractView = null;
  constructor(
    private boatService: BoatService,
    private productService: ProductService,
    private spinner: NgxSpinnerService,
    private contractDocumentService: ContractDocumentService,
    private messageUtil: MessageUtil,
    private slingConfigService: SlingConfigService,
    private marinaTermUseService: MarinaTermUseService,
    private marinaContractPriceService: MarinaContractPriceService,
    private documentDefaultSignerService: DocumentDefaultSignerService,
    public dialog: DialogService,
    private contractUtil: ContractUtil,
    private toasty: ToastService,
    private webSocketService: WebSocketService,
    private documentContractedService: DocumentContractedMarinaService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.spinner.show()
    this.clear();
    this.findSlingConfig();
    await this.loadTermUse();
    this.spinner.hide();
  }

  async init(): Promise<void> {
  await this.spinner.show();
    await Promise.all([
      this.findSignerMarina(),
      this.loadContracPrice(),
      this.loadBoats(),
      this.loadProducts()
    ]);
    await this.spinner.hide()
    this.numberOfRows = this.slingConfig != null ? this.slingConfig.numberOfRecordsPerPage : 10;
    this.paginationFilter.size = this.numberOfRows;
    this.statusListFilter = Object.keys(DocumentStatus).map(item => ({ label: this.messageUtil.translateKey(item), value: item }));
    this.documentTypeListFilter = Object.keys(DocumentType).map(item => ({ label: this.messageUtil.translateKey(item), value: item }));
    this.contractTypeListFilter = Object.keys(ContractType).map(item => ({ label: this.messageUtil.translateKey(item), value: item }));
    // this.findDocuments();
    this.contractSubscription = this.webSocketService.getMessageContract().subscribe(async (message) => {
      this.spinner.show();
      await this.findDocuments();
    });
    this.getContractDocumentGeneratedThisMonth();
  }

  ngOnDestroy(): void {
    this.webSocketService.unsubscribeContract(this.contractSubscription);
  }


  async findSlingConfig(): Promise<void> {
    return new Promise<void>(
      async (resolve, reject) => {
        this.slingConfigService.getSlingConfigToday().subscribe(
          async (slingConfig) => {
            this.slingConfig = slingConfig;
                        resolve();
          },
          async () => {

          }
        );
      }
    );
  }

  async findSignerMarina(): Promise<void> {
    return new Promise<void>(
      async (resolve, reject) => {
        this.documentDefaultSignerService.getDefaultSignersTypeMarina().subscribe(
          async (defaultSigner) => {
            this.signerMarina = defaultSigner[0];
            resolve();
          },
          async () => {
            reject(new SlingConfig());
          }
        );
      }
    );
  }

  async loadBoats(): Promise<void> {
    return new Promise<void>(
      async (resolve) => {
        await this.spinner.show();
        const campos = 'id,name,boatServices{id,contractStartDate,service{id,name},contract{id,contractType,status}},maintenance,boatCustomers{type,customer{id,name}}';
        this.boatService.getAllActive(campos).subscribe(
          async (data) => {
            this.boats = [...data];
            this.boatListFilter = data
              .map((boat) => ({
                label: boat.name.concat((<any>boat).maintenance ? ' (Em manutenção)' : ''),
                value: boat.id,
                disabled: (<any>boat).maintenance
              }));
            this.boatListFilter.unshift({ label: 'Todos', value: null });
            await this.spinner.hide()
            resolve();
          },
          async (error) => {
            this.showError(error);
            await this.spinner.hide()
            resolve();
          });
      })
  }

  async loadProducts(): Promise<void> {
    return new Promise<void>(
      async (resolve) => {
        this.productService.getByProductType(ProductType.PeriodicalService).subscribe(
          async (data) => {
            this.serviceListFilter = data.map(product => ({ label: product.name, value: product.id }));
            this.serviceListFilter.unshift({ label: 'Todos', value: null });
            resolve();
          },
          async (error) => {
            this.showError(error);
            resolve();
          });
      })
  }

  signContract(document: DocumentContractView): void {
    if (document.status !== DocumentStatus.WAITING_SIGNATURES || document.marinaSigned) {
      return;
    }

    this.dialog.open(SignContractDialogComponent, {
      height: '90vh',
      width: '80%',
      dismissableMask: false,
      data: {
        signer: this.signerMarina,
        key: document.uuidDocument,
        protocol: 'https',
        host: environment.d4signApi + '/embed/viewblob'
      },
      header: 'Assinar Contrato'
    }).onClose.subscribe(
      (data) => {
        if (data) {
          this.toasty.success('Assinado com Sucesso!');
        }
      });

  }


  getdocumentsSeleted(): void {
    this.contractDocumentService.createBatche(this.selectedDocuments).subscribe((data) => {
      this.signBatcheContract(data);
    }, (error) => this.showError(error));
  }


  signBatcheContract(key: string): void {

    this.dialog.open(SignContractDialogComponent, {
      height: '90vh',
      width: '80%',
      dismissableMask: false,
      data: {
        signer: this.signerMarina,
        key,
        protocol: 'https',
        host: environment.d4signApi + '/embedlote/viewblob'
      },
      header: 'Assinar Lote de contratos'
    }).onClose.subscribe(
      (data) => {
        if (data) {
          this.toasty.success('Assinado com Sucesso!');
        }
      });

  }

  isSelectable(document: DocumentContractView): boolean {
    return this.isSignable(document) && (this.selectedDocuments.length < 25 || this.selectedDocuments.find(d => d === document.uuidDocument) !== undefined);
  }

  isSignable(document: DocumentContractView): boolean {
    return document.status === DocumentStatus.WAITING_SIGNATURES && !document.marinaSigned && document.marinaSignerEmail === this.signerMarina?.email;
  }

  showError(error): void {
    this.spinner.hide();
    console.error(error)
    const exception = error.error.exception ? error.error.exception : error.error.data.exception;
    this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
  }

  sendDocumentForSignature(document: DocumentContractView): void {
    if (document.status === "WAITING_SIGNATORIES"){
    this.showWarningAlertGenerics(
      'Envio para assinatura',
      `Deseja enviar para assinatura o contrato da embarcação ${document.boatName} para o serviço ${document.serviceName}
      <br>
      <br>
      A partir deste momento, a assinatura do contrato será cobrada no valor de ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.marinaContracPrice.contractPriceNowPrice)}`,
      () => {
        this.spinner.show();
        this.contractDocumentService.sendDocumentForSignature(document.id)
          .subscribe(() => {
            this.toasty.success('Documento enviado para assinatura!');
            this.spinner.hide();
          }, (error) => this.showError(error))
      })
    }else{
    this.showWarningAlertGenerics(
      'Envio para assinatura',
      `Deseja re-enviar para assinatura o contrato da embarcação ${document.boatName} para o serviço ${document.serviceName}
      `,
      () => {
        this.spinner.show();
        this.contractDocumentService.reSendDocumentForSignature(document.id)
          .subscribe(() => {
            this.toasty.success('Documento enviado para assinatura!');
            this.spinner.hide();
          }, (error) => this.showError(error))
      })

    }
  }

  downloadDocument(document: DocumentContractView): void {
    this.contractUtil.downloadDocument(document.id, document.url);
  }

  cancelDocument(document: DocumentContractView): void {
    this.contractUtil.cancelDocument(document.id, document.nameDocument, document.boatName).then((data) => {
      if (data) {
      }
    });
  }

  showWarningAlertGenerics(title: string, html: string, actionFunction): void {
    Swal.fire({
      title: title,
      html: html,
      icon: 'warning',
      backdrop: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',

      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        actionFunction();
      }
    });
  }

  onChangePage(event: LazyLoadEvent): void {
    if(this.slingConfig) {
      const page = event.first / event.rows;
      this.paginationFilter.sort = event.sortField;
      this.paginationFilter.page = page;
      this.paginationFilter.size = event.rows;
      this.paginationFilter.order = event.sortOrder === 1 ? 'ASC' : 'DESC';
      this.selectedDocuments = [];
      this.findDocuments();
    }
  }

  clear(): void {
    this.filter = {
      serviceActive: true
    };
  }

  findDocuments(): void {
    this.contractDocumentService.getDocuments(this.filter, this.paginationFilter)
      .subscribe((data) => {
        this.documents = data.content;
        this.totalRecords = data.totalElements;
        this.spinner.hide();
      }, (error) => this.showError(error));
  }

  getEmails(document: DocumentContractView): string {
    return this.contractUtil.getEmails(document.emails);
  }

  signersDocument(document: DocumentContractView): void {

    this.dialog.open(SignersContractDialogComponent, {
      height: '500px',
      width: 'auto',
      dismissableMask: false,
      data: {
        document
      },
      header: this.messageUtil.translateKey('SIGNERS-CONTRACT')
    })
  }

  newContractDialog(): void {
    const boatListContract = this.boatListFilter.filter(boat => boat.value !== null);
    this.dialog.open(NewContractDialogComponent, {
      width: '50%',
      dismissableMask: false,
      data: {
        boats: this.boats,
        boatListContract
      },
      header: 'Novo contrato'
    }).onClose.subscribe(
      (data) => {
        if (data) {
          this.selectTab = 0;
        }
      });
  }

  activeContracts(): void {
    window.open('assets/documents/TermoUsoContrato.pdf');
  }


  async loadTermUse(): Promise<void> {
    return new Promise<void>(async (resolve) => {
      this.marinaTermUseService.get().subscribe({
        next: (data) => {
          this.spinner.hide();
          this.loadingPage = false;
          this.termUse = null;
          if (data) {
            if (data.type === TermUseType.SERVICE_BOAT_CONTRACT) {
              this.termUse = data;
            }
            if (this.termUse !== null) {
              this.init();
            }
          } else {
            this.messageUtil.generateMessage('error', 'SUMMARY.WARNING', 'Não existe contratos ativos com essa marina. Faça um novo contrato');
          }
        }, error: (error) => {
          this.spinner.hide();
          this.termUse = null;
          this.loadingPage = false;
          this.showError(error);
        }
      });
    });
  }

  async loadContracPrice(): Promise<void> {
    return new Promise<void>(async resolve => {
      this.marinaContractPriceService.get().subscribe(async (data) => {
        if (data) {
          this.marinaContracPrice = data[0];
          resolve();
        }
      },
        async (error) => {
          this.showError(error);
          resolve();
        });
    })
  }

  agreeTermUseContracts() {
    const termUse: MarinaTermUse = {
      type: TermUseType.SERVICE_BOAT_CONTRACT,
    }
    this.spinner.show();

    this.marinaTermUseService.save(termUse).subscribe({
      next: (data) => {
        this.spinner.hide();
        if (data) {
          this.termUse = data;
          this.init();
        }
      }, error: (error) => {
        this.spinner.hide();
        this.showError(error);
      }
    });
  }

  updateAffected(document: DocumentContractView): void {
    this.contractUtil.updateAffected(document.id, !document.affected).then((data) => {
      if (data) {
      }
    });
  }

  onChangeSelectTab($event) {
    this.selectTab = $event.index;
  }

  getContractDocumentGeneratedThisMonth(): void{
    this.toasty.clearAllToasts();
    this.documentContractedService.findDocumentContractedInvoiceDelayed().subscribe({
      next: (resp) => {
        if (resp != null){
          const referenceDate = new Date(resp.referenceDate);
          const mesEmPortugues = referenceDate.toLocaleDateString("pt-BR", {
            month: "long",
          });
          this.toasty.stickyWarning(`A fatura referente ao mês de ${FormatUtil.firstLetterUppercase(mesEmPortugues)}, já foi gerada. Favor regularize com a EasyMarine`)
        }
      }, error: (error) => {
        console.log(error);
      }
    })
  }

  changeSelectedItem(document: DocumentContractView) {
    this.selectedDocument = document;
    this.loadMenuItems();
  }
  loadMenuItems() {
    this.menuItems = [];
    if (this.selectedDocument.status === 'WAITING_SIGNATORIES' ||
      this.selectedDocument.status === 'PROCESSING_SIGNATURES' ||
      this.selectedDocument.status === 'WAITING_SIGNATURES') {
      this.menuItems.push({
        label: 'Enviar para assinatura', icon: 'pi pi-play', command: () => {
          this.sendDocumentForSignature(this.selectedDocument)
        }
      })
    }
    if (this.selectedDocument.status === 'WAITING_SIGNATORIES') {
      this.menuItems.push({
        label: 'Incluir assinantes',
        icon: 'pi pi-user-plus',
        command: () => this.signersDocument(this.selectedDocument)
      })
    }
    if (this.selectedDocument.status !== 'WAITING_SIGNATORIES' && this.selectedDocument.documentType !== DocumentType.NORMAL_FILE) {
      this.menuItems.push({
        label: 'Assinantes',
        icon: 'pi pi-users',
        command: () => this.signersDocument(this.selectedDocument)
      })
    }
    if (this.selectedDocument.status !== 'CANCELED' && this.selectedDocument.status !== 'ERROR') {
      this.menuItems.push({
        label: 'Visualizar documento',
        icon: 'pi pi-eye',
        command: () => this.downloadDocument(this.selectedDocument)
      })
    }
    if (this.selectedDocument.status !== 'CANCELED' && this.selectedDocument.status !== 'ERROR' && this.selectedDocument.status !== 'SIGNED') {
      this.menuItems.push({
        label: 'Cancelar documento',
        icon: 'pi pi-times',
        command: () => this.cancelDocument(this.selectedDocument)
      })
    }
    if (this.isSignable(this.selectedDocument)) {
      this.menuItems.push({
        label: 'Assinar Documento',
        icon: 'pi pi-check-square',
        command: () => this.signContract(this.selectedDocument)
      })
    }
    if(this.selectedDocument.contractType === ContractType.MAIN){
      this.menuItems.push({
        label: 'Aditivo',
        command: () => this.newAdditiveToContract(this.selectedDocument, ContractType.ADDITIVE),
      })
    }
    if(this.selectedDocument.contractType != ContractType.TERMINATION){
      this.menuItems.push({
        label: 'Distrato',
        command: () => this.newAdditiveToContract(this.selectedDocument, ContractType.TERMINATION),
      })
    }
  }

  newAdditiveToContract(document: DocumentContractView, contractType: ContractType){
    const boatListContract = this.boatListFilter.filter(boat => boat.value !== null && boat.value == document.boatId);
    const boats = this.boats.filter(boat => boat.id !== null && boat.id == document.boatId);
    this.dialog.open(NewContractDialogComponent, {
      width: '50%',
      dismissableMask: false,
      data: {
        boats,
        boatListContract,
        additive: true,
        contractDocument: document,
        contractType
      },
      header: 'Contrato Aditivo'
    }).onClose.subscribe(
      (data) => {
        if (data) {
          this.selectTab = 0;
        }
      });
  }
}
