import { SailorStatus } from './../models/enums/sailor-status';
import { Sailor } from 'src/app/models/sailor';
import { SailorFilters } from './../models/dtos/sailor-filters';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { User } from '../models';
import { PaginationFilter } from '../models/pagination-filter';
import { SailorFilterListDTO } from '../models/dtos/sailorFilterListDTO';

@Injectable({
  providedIn: 'root',
})
export class SailorService {
  constructor(private http: HttpClient) { }

  getSailorFilterList(sailorFilter: SailorFilters, paginationFilter: PaginationFilter): Observable<any> {
    let params = new HttpParams();

    if (!paginationFilter.sort || !paginationFilter.order) {
      paginationFilter.sort = 'status';
      paginationFilter.order = 'DESC';
    }
    params = params.set('sort', paginationFilter.sort.concat(',').concat(paginationFilter.order));
    params = params.set('page', paginationFilter.page.toString());
    params = params.set('size', paginationFilter.size.toString());

    return this.http
      .post(environment.apiHost + '/secure/sailor/table/', sailorFilter, { params })
      .pipe(map((response: any) => response.data));
  }

  getSailorForMarineAndStatus(status: string): Observable<Sailor[]> {
    return this.http
      .get(environment.apiHost + '/secure/sailor/all/' + status)
      .pipe(map((response: any) => response.data.sailors as Sailor[]));
  }

  getSailorByFederalId(federalid: string): Observable<Sailor> {
    return this.http
      .post(environment.apiHost + '/secure/sailor/federalid', federalid)
      .pipe(map((response: any) => response.data.sailor as Sailor));
  }

  getSailorById(id: string): Observable<Sailor> {
    return this.http
      .get(environment.apiHost + '/secure/boat/sailor/' + id)
      .pipe(map((response: any) => response.data.sailor as Sailor));
  }

  saveWeb(dados: any): Observable<Sailor> {
    return this.http
      .post(environment.apiHost + '/secure/sailor/saveweb', dados)
      .pipe(map((response: any) => response.data.sailor as any));
  }

  uploadLicence(files: any[]): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('attachment', files[0]);
    let hd = new HttpHeaders();
    hd = hd.set('Content-Type', 'multipart/form-data');
    const options = {
      headers: hd
    };
    return this.http.post(environment.apiHost + '/sailor/doc-file/attachment', formData, options)
      .pipe(map((response: any) => response.data.attachment));
  }


  getUserBySailorFederalId(federalid: string): Observable<User> {
    return this.http.post(environment.apiHost + '/secure/sailordoc/federalid', federalid).pipe(map((response: any) => response.data.user as User));
  }

  getSailorFilterListByMarinaId(): Observable<SailorFilterListDTO[]> {
    return this.http.get(environment.apiHost + '/secure/sailor/filter-list/')
      .pipe(map((response: any) => response.data.sailors as SailorFilterListDTO[]));
  }

  deactivateSailor(id: number) {
    return this.http.put(environment.apiHost + '/secure/sailor/deactivate/' + id, null).pipe(map((res: any) => res));
  }

  activateSailor(id: number) {
    return this.http.put(environment.apiHost + '/secure/sailor/activate/' + id, null).pipe(map((res: any) => res.data.sailor as Sailor));
  }

  deleteSailor(id: number) {
    return this.http.delete(environment.apiHost + '/secure/sailor/' + id).pipe(map((res: any) => res.data.sailor as Sailor));
  }

}
