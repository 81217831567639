import { Customer } from '../customer';

export class CustomerFileDTO {
    file: Blob;
    customersSuccess: Array<Customer>;
    customersExists: Array<Customer>;
    createdAt: Date;

    constructor(file: Blob, customersSuccess: Array<Customer>, customersExists: Array<Customer>) {
        this.file = file;
        this.customersSuccess = customersSuccess;
        this.customersExists = customersExists;
        this.createdAt = new Date();
    }
}
