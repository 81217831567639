import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DocumentDefaultSigner } from 'src/app/models/document-default-signer';
import { SignerType } from 'src/app/models/enums/signerType';
import { DocumentDefaultSignerService } from 'src/app/services/document-default-signer.service';
import { ToastService } from 'src/app/services/toast.service';
import { ValidatorsUtil } from 'src/app/services/validators.util';
import { MessageUtil } from 'src/app/utils/message.util';

@Component({
  selector: 'app-default-signer-form',
  templateUrl: './default-signer-form.component.html',
  styleUrls: ['./default-signer-form.component.scss']
})
export class DefaultSignerFormComponent implements OnInit {

  defaultSignerForm: UntypedFormGroup;
  typeList: SelectItem[] = [];

  existCPF = false;

  defaultSigner: DocumentDefaultSigner = new DocumentDefaultSigner();
  qtyMarina: number;
  qtyWitness: number;
  oldSignerType: SignerType;

  constructor(
    private messageUtil: MessageUtil,
    public dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private validatorsUtil: ValidatorsUtil,
    private documentDefaultSignerService: DocumentDefaultSignerService,
    private toasty: ToastService
  ) { }

  ngOnInit(): void {
    if (this.config.data.signer) {
      this.defaultSigner = this.config.data.signer;
      this.oldSignerType = this.config.data.signer.signerType;
    }
    this.qtyMarina = this.config.data.qtyMarina;
    this.qtyWitness = this.config.data.qtyWitness;

    this.getSignerType();
    this.startForm();
  }

  startForm(): void {
    this.defaultSignerForm = new UntypedFormGroup({
      email: new UntypedFormControl(this.defaultSigner?.email, Validators.required),
      signerType: new UntypedFormControl(this.defaultSigner?.signerType, Validators.required),
      name: new UntypedFormControl(this.defaultSigner?.name, Validators.required),
      federalId: new UntypedFormControl(this.defaultSigner?.federalId, Validators.required),
      birthday: new UntypedFormControl(this.defaultSigner?.birthday ? new Date(this.defaultSigner?.birthday) : '', Validators.required)
    });
  }

  getSignerType(): void {
    this.typeList = [];
    if (this.qtyMarina === 0 || this.qtyMarina === 1 && this.oldSignerType && this.oldSignerType === SignerType.SIGNER_MARINA) {
      this.typeList.push({ label: this.messageUtil.translateKey(SignerType.SIGNER_MARINA), value: SignerType.SIGNER_MARINA });
    }
    if (this.qtyWitness < 2 || this.qtyWitness === 2 && this.oldSignerType && this.oldSignerType === SignerType.WITNESS) {
      this.typeList.push({ label: this.messageUtil.translateKey(SignerType.WITNESS), value: SignerType.WITNESS });
    }
  }

  save() {
    this.prepareToSave();
    this.documentDefaultSignerService.save(this.defaultSigner).subscribe(() => {
      this.toasty.success('Salvo com sucesso.');
      this.dialogRef.close(true);
    },
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(MessageUtil.ERROR, exception.title, exception.message);
      }
    );
  }

  checkFederalIdField(): void {
    this.validateFederalId();
  }

  validateFederalId(): boolean {
    let validFederalId = this.validatorsUtil.cpfCnpjValidator(this.defaultSignerForm.get('federalId').value);
    if (!validFederalId) {
      this.defaultSignerForm.get('federalId').reset();
      this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', 'INVALID-FEDERAL-ID');
    }
    return validFederalId;
  }

  prepareToSave(): void {
    if (!this.defaultSigner.id) {
      this.defaultSigner = this.defaultSignerForm.value;
    } else {
      Object.keys(this.defaultSignerForm.controls).forEach((key) => {
        this.defaultSigner[key] = this.defaultSignerForm.get(key).value;
      });
    }
  }

  close() {
    this.dialogRef.close();
  }

}
