<p-fieldset>
  <p-header class="article-title">
      <span class="article-title">{{ 'TITLE-USER-SMS' | translate }}</span>
      <button class="primary fab-button" (click)="loadAll()">
          <i class="pi pi-refresh" style="font-size: 21px;"></i>
      </button>
  </p-header>
  <div class="table">
    <p-table #tt [value]="usersTable" [paginator]="true" [rows]="numberOfRows" resetPageOnSort="true"
        [pageLinks]="3" [rowsPerPageOptions]="[10,20,30,50,100]" styleClass="easy-table"
        [globalFilterFields]="['name','email','phone','smsCode','active']">
        <ng-template pTemplate="caption">
            <p-selectButton [ngStyle]="{'float': 'left'}" [(ngModel)]="userStatus"
                [options]="[{label: 'Todos', value: null}, {label: 'Ativo', value: true}, {label: 'Inativo', value: false}]"
                (onChange)="tt.filter($event.value, 'active', 'equals')">
            </p-selectButton>
            <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input type="text" pInputText size="50" placeholder="Filtro global"
                    (input)="filterGlobal($event.target.value)" style="width:auto">
            </span>
            <span style="font-weight: bold; float: right; margin-top: 5px;">{{ 'TOTAL-REGISTERS' | translate }}:
                {{tt.totalRecords}} {{'OF' | translate}} {{usersTable.length}}
            </span>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th width="19%" [pSortableColumn]="'name'">
                    {{ 'NAME' | translate }}
                    <p-sortIcon [field]="'name'"></p-sortIcon>
                </th>
                <th width="17%" [pSortableColumn]="'email'">
                    Email pessoal
                    <p-sortIcon [field]="'email'"></p-sortIcon>
                </th>
                <th width="10%" [pSortableColumn]="'type'">
                    Tipo de usuário
                    <p-sortIcon [field]="'type'"></p-sortIcon>
                </th>
                <th class="text-center" width="10%" [pSortableColumn]="'phone'">
                    {{ 'CELLPHONE' | translate }}
                    <p-sortIcon [field]="'phone'"></p-sortIcon>
                </th>
                <th class="text-center" width="8%" [pSortableColumn]="'smsCode'">
                    {{ 'SMSCODE' | translate }}
                    <p-sortIcon [field]="'smsCode'"></p-sortIcon>
                </th>
                <th class="text-center" width="12%" [pSortableColumn]="'smsCodeCreatedAt'">
                    {{ 'LAST-SMSCODE-CREATED' | translate }}
                    <p-sortIcon [field]="'smsCodeCreatedAt'"></p-sortIcon>
                </th>
                <th class="text-center" width="6%" [pSortableColumn]="'active'">
                    {{ 'STATUS' | translate }}
                    <p-sortIcon [field]="'active'"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user>
            <tr>
                <td>
                  {{user.name}}
                  <i class="pi pi-circle-on" style="font-size: 0.5rem; color: #185992;" *ngIf="hasRequestedButNotConfirmed(user)" [pTooltip]="'App solicitado, mas não confirmado'"></i>
                </td>
                <td>{{user.email}}</td>
                <td>{{user.type}}</td>
                <td class="text-center">{{getUserPhoneFormatted(user)}}</td>
                <td class="text-center">{{user.smsCode}}</td>
                <td class="text-center">{{user.smsCodeCreatedAt | date:'dd/MM/YYYY HH:mm' }}</td>
                <td class="text-center">
                    <span *ngIf="user.active" class="badge badge-pill success">{{ 'ACTIVE' |
                    translate }}</span>
                    <span *ngIf="!user.active" class="badge badge-pill danger">{{ 'INACTIVE' |
                    translate }}</span>
                </td>
            </tr>
        </ng-template>
    </p-table>
  </div>
</p-fieldset>
