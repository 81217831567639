<div class="grid m-4">
    <div class="col-11" [ngStyle]="{ 'margin-bottom': '1rem' }">
      <p-radioButton
        class="mr-3"
        name="Excel"
        label="Excel"
        value="XLS"
        [(ngModel)]="selectExportType"
      ></p-radioButton>
      <p-radioButton
        name="CSV"
        label="CSV"
        value="CSV"
        [(ngModel)]="selectExportType"
      ></p-radioButton>
      <p-radioButton
      class="PDF-check-button"
        name="PDF"
        *ngIf="config.data.type !== 'BOAT' && config.data.type !== 'CUSTOMER' && config.data.type !== 'SAILOR'"
        label="PDF"
        value="PDF"
        [(ngModel)]="selectExportType"
      ></p-radioButton>
    </div>
    <div class="col-12">
      <p-pickList
        [source]="sourceList"
        sourceHeader="Não Exportar"
        [dragdrop]="true"
        [responsive]="true"
        [target]="targetList"
        targetHeader="Exportar"
        [showSourceControls]="false"
        [showTargetControls]="false"
        [sourceStyle]="{ height: '24vw' }"
        [targetStyle]="{ height: '24vw' }"
        >
        <ng-template let-customer pTemplate="item">
          <h4>{{ customer.text }}</h4>
        </ng-template>
      </p-pickList>
    </div>
    <div class="makeDefault">
      <p-checkbox
        name="pattern"
        [binary]="true"
        label="Tornar Padrão"
        [(ngModel)]="patternReport"
      ></p-checkbox>
    </div>
    <div class="makeDefault bottomButtons">
      <button class="easymarine danger firstButton" (click)="closeDialog()">Cancelar</button>
      <button class="easymarine primary" (click)="exportTable()">Exportar</button>
    </div>
  </div>
