import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileUpload } from 'primeng/fileupload';
import { Notice } from 'src/app/models/notice';
import { CustomerService } from 'src/app/services/customer.service';
import { NoticeService } from 'src/app/services/notice.service';
import { FormUtil } from 'src/app/utils/form.util';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';
import Swal from 'sweetalert2';
import { SelectItem } from 'primeng/api/selectitem';

@Component({
  selector: 'app-noticies-form',
  templateUrl: './noticies-form.component.html',
  styleUrls: ['./noticies-form.component.scss'],
})
export class NoticiesFormComponent implements OnInit {
  @ViewChild('upload', { static: false }) upload: FileUpload;
  notice: Notice = new Notice();
  noticeForm: UntypedFormGroup;
  file: File;
  imgPreview: any;

  pt = {
    firstDayOfWeek: 0,
    dayNames: [
      'Domingo',
      'Segunda',
      'Terça',
      'Quarta',
      'Quinta',
      'Sexta',
      'Sábado',
    ],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    monthNamesShort: [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ],
    today: 'Hoje',
    clear: 'Limpar',
  };

  noticeTargetTypes: any;
  noticeTypes: any;

  customersList: any[] = [];
  targets: any[] = [];
  destinationList: SelectItem[] = [
    { label: 'App Cliente', value: 'APP_CLIENT' },
    { label: 'App Cliente e E-Mail', value: 'APP_CLIENT_EMAIL' }
  ];

  constructor(
    public dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private noticeService: NoticeService,
    private spinner: NgxSpinnerService,
    private customerService: CustomerService,
    private messageUtil: MessageUtil
  ) {
    this.noticeTargetTypes = new Array(
      { label: 'Todos os clientes', value: 'MARINA' },
      { label: this.messageUtil.translateKey('CUSTOMER'), value: 'CUSTOMER' }
    );
    this.noticeTypes = new Array(
      { label: this.messageUtil.translateKey('REGULAR'), value: 'REGULAR' },
      { label: this.messageUtil.translateKey('IMPORTANT'), value: 'IMPORTANT' },
      { label: this.messageUtil.translateKey('URGENT'), value: 'URGENT' },
      { label: this.messageUtil.translateKey('BAD-WEATHER'), value: 'WEATHER_WARNING'}
    );
  }

  async ngOnInit(): Promise<void> {
    this.startForm();
    if (this.config.data.notice) {
      this.notice = this.config.data.notice;
      if (this.notice.urlImage) {
        this.imgPreview = this.notice.urlImage;
      }
    }
    this.spinner.show();
    await this.getCustomers();
    this.startForm();
    this.prepareDate();
    this.spinner.hide();
  }

  getCustomers(): Promise<void> {
    return new Promise(resolve =>{
      this.customerService.getAllSimpleOnlyActive()
        .subscribe(data => {
          this.customersList = data.map((customer) => ({ label: customer.name, value: customer.id }));
          resolve();
        }, error => {
          resolve();
        });
    })

  }

  startForm(): void {
    this.noticeForm = new UntypedFormGroup({
      title: new UntypedFormControl(this.notice.title, Validators.required),
      initialDateTime: new UntypedFormControl(this.notice.initialDateTime, Validators.required),
      finalDateTime: new UntypedFormControl(this.notice.finalDateTime, Validators.required),
      noticeTargetType: new UntypedFormControl(this.notice.noticeTargetType, Validators.required),
      noticeType: new UntypedFormControl(this.notice.noticeType),
      linkNotice: new UntypedFormControl(this.notice.linkNotice),
      marina: new UntypedFormControl(StorageUtil.getMarina(), Validators.required),
      description: new UntypedFormControl(this.notice.description, Validators.required),
      noticeOrigin: new UntypedFormControl(this.notice.noticeOrigin, Validators.required),
      targets: new UntypedFormControl({ value: this.notice.targets, disabled: (!this.notice.noticeTargetType || this.notice.noticeTargetType === 'MARINA') }, Validators.required),
      destinationType: new UntypedFormControl(this.notice.destinationType, Validators.required),
      imageBase64: new UntypedFormControl(this.notice.imageBase64),
      imageName: new UntypedFormControl(this.notice.imageName),
      imageType: new UntypedFormControl(this.notice.imageType)
    });
  }
  blobToBase64(blob: Blob){
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };
  async save(): Promise<void> {
    if (!this.noticeForm.valid) {
      FormUtil.touchAndSendFormGroup(this.noticeForm);
      this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', 'MSG.FIELD-REQUIRED');
      return;
    }
    if (this.file){
      await this.blobToBase64(this.file).then(res => {
        const imgBase64Index = res.toString().indexOf(',') + 1;
        const imgBase64String = res.toString().substring(imgBase64Index);
        this.noticeForm.get('imageBase64').setValue(imgBase64String);
      });
      this.noticeForm.get('imageName').setValue(this.file.name);
      this.noticeForm.get('imageType').setValue(this.file.type);
    }
    const totalCustomerEmail = this.getTotalCustomers();
    Swal.fire({
      title:
        'Serão enviado e-mails para ' + totalCustomerEmail +  ' cliente(s) <br> Deseja Continuar?',
      icon: 'warning',
      backdrop: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    }).then(async (result) => {
      if(result.isConfirmed){
        this.prepareToSave();
        await this.uploadImage();
        this.spinner.show();
        this.noticeService.save(this.notice).subscribe(
          () => {
            Swal.fire('Sucesso!', 'Notícia salva com sucesso', 'success');
            this.dialogRef.close();
          },
          (error) => {
            this.spinner.hide();
            Swal.fire(
              'Erro ao salvar!',
              this.messageUtil.translateKey(error.error.message),
              'error'
            );
          },
          () => this.spinner.hide()
        );
      }
    });
  }

  isTypeCustomer(): boolean {
    return this.noticeForm && this.noticeForm.get('noticeTargetType').value === 'CUSTOMER';
  }

  prepareToSave(): void {
    if (!this.notice.id) {
      this.notice = this.noticeForm.getRawValue();
    } else {
      Object.keys(this.noticeForm.controls).forEach((key) => {
        this.notice[key] = this.noticeForm.get(key).value;
      });
    }

    if (this.notice.noticeTargetType === 'MARINA') {
      this.notice.targets = this.customersList.map((item) => item.value);
    }

    this.notice.initialDateTime = new Date(this.notice.initialDateTime);
    this.notice.finalDateTime = new Date(this.notice.finalDateTime);
    this.notice.noticeOrigin = 'MARINA';

    if (this.notice.linkNotice && !this.notice.linkNotice.startsWith('http')) {
      this.notice.linkNotice = `http://${this.notice.linkNotice}`;
    }
  }

  prepareDate(): void {
    this.noticeForm.get('initialDateTime').setValue(this.notice.initialDateTime ? new Date(this.notice.initialDateTime) : null);
    this.noticeForm.get('finalDateTime').setValue(this.notice.finalDateTime ? new Date(this.notice.finalDateTime) : null);
  }

  setFile(file): void {
    this.upload.clear();
    this.file = file[0];

    const reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onload = (evt) => {
      this.imgPreview = reader.result;
    };
  }
  getTotalCustomers(){
    if (this.noticeForm.get('noticeTargetType').value === 'MARINA'){
      return this.customersList.length;
    }else{
      return this.noticeForm.get('targets').value.length;
    }
  }

  async uploadImage(): Promise<void> {
    if (!this.file) {
      return;
    }
    return new Promise<void>(
      async (resolve, reject) => {
        this.noticeService.uploadFile(this.file).subscribe(
          async (result) => {
            this.notice.urlImage = result.url;
            resolve();
          },
          async (err) => {
            reject();
          }
        );
      }
    );
  }

  changeTargetType(): void {
    if (this.noticeForm.get('noticeTargetType').value && this.noticeForm.get('noticeTargetType').value === 'CUSTOMER') {
      this.noticeForm.get('targets').enable();
    } else {
      this.noticeForm.get('targets').disable();
    }
  }
}
