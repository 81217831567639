import { Component, EventEmitter, Input, OnInit, Output, } from '@angular/core';
import { SalesStatesService } from './sales-states.service';
import { Subscription } from 'rxjs';
import { CustomerService } from 'src/app/services/customer.service';
import { Customer } from 'src/app/models';

@Component({
  selector: 'app-split-bill',
  templateUrl: './split-bill.component.html',
  styleUrls: ['./split-bill.component.scss']
})
export class SplitBillComponent implements OnInit {

  @Output()
  onPaymentCompleted: EventEmitter<any> = new EventEmitter;

  payments: any[];
  subscription: Subscription;
  serviceDeskCustomer: Customer;
  hasPayment: boolean = false;

  constructor(
    private salesStatesService: SalesStatesService,
    private customerService: CustomerService
  ) { }

  ngOnInit(): void {
    this.findServiceDeskCustomer()
    this.subscription = this.salesStatesService.payments$.subscribe((_) => {
      this.payments = this.salesStatesService.payments;
      let pendingValue = 0;
      this.payments.forEach((payment: any) => {
        if(payment.payed) return;
        pendingValue += payment.total;
      });
      if (pendingValue <= 0) {
        this.onPaymentCompleted.emit();
      }
      if (pendingValue < this.salesStatesService.orderData.total) {
        this.hasPayment = true;
      }
    });
  }

  plusPayments() {
    this.payments[0].numberOfCustomers++;
    this.reSplit();
  }

  minusPayments() {
    if (this.payments[0].numberOfCustomers > 2) {
      this.payments[0].numberOfCustomers--;
    }
    this.reSplit();
  }

  reSplit() {
    this.salesStatesService.reSplit(this.payments[0].numberOfCustomers);
  }

  findServiceDeskCustomer(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.customerService.getByServiceDeskCustomer().subscribe((data) => {
        this.serviceDeskCustomer = data;
        resolve();
      });
    });
  }

}
