
<div class="btn-position-end">
    <div class="form-button">
        <button class="primary easymarine" type="button" (click)="OpenMovementGroupModal()">{{ 'Novo' | translate }}</button>
    </div>
</div>
<div class="table container-fluid">
    <p-table [value]="movementGroups" class="primeTable" [paginator]="true" rows=10 autoLayout="true"
        [alwaysShowPaginator]="false" [pageLinks]="3" [lazy]="true"
        [totalRecords]="movementGroups.length">
        <ng-template pTemplate="header">
            <tr>
                <th [pSortableColumn]="'name'">
                    {{ 'NAME' | translate }}
                </th>
                <th>Meios de descida</th>
                <th [pSortableColumn]="'createAt'">
                    Data de criação
                </th>
                <th [pSortableColumn]="'active'">
                    {{ 'STATUS' | translate }}
                </th>
                <th></th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-group>
            <tr>
                <td>{{ group.name }}</td>
                <td>{{ listMovementMeansByGroup(group)}}</td>
                <td>{{ group.createdAt | date: 'dd/MM/yyyy' }}</td>
                <td>{{ (group.active ? 'ACTIVE' : 'INACTIVE') | translate }}</td>
                <td class="text-center">
                    <div class="table-action" (click)="OpenMovementGroupModal(group)"
                        pTooltip="Editar {{group.name}}" tooltipPosition="bottom">
                        <i class="pi pi-pencil"></i>
                    </div>
                </td>
                <td class="text-center">
                    <div class="table-action" (click)="openDeleteConfirmDialog(group.id)"
                        pTooltip="Remover {{group.name}}" tooltipPosition="bottom">
                        <i class="pi pi-trash"></i>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
