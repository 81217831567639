import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { ProductLogModalComponent } from '../../product-log/product-log-modal/product-log-modal.component';
import { UserLogModalComponent } from '../user-log-modal/user-log-modal.component';

@Component({
  selector: 'app-user-log-table',
  templateUrl: './user-log-table.component.html',
  styleUrls: ['./user-log-table.component.scss']
})
export class UserLogTableComponent implements OnInit {

  @Input()
  userLogs = [];

  @Input()
  isDialog = false;

  numberOfRows = 10;

  constructor(
    private slingConfigService: SlingConfigService,
    public dialog: DialogService,
  ) { }

  ngOnInit(): void {
    if ( !this.isDialog) {
      this.loadSlingConfig();
    }
  }

check(a:boolean):string
{
  if(a)
  {
    return "Ativo";
  }
  else
  {
    return "Cancelado";
  }
}

  loadSlingConfig(): void {
    this.slingConfigService.getSlingConfig().subscribe(
      (data) => {
        const slingConfig = data[0];
        if (slingConfig != null) {
          this.numberOfRows = slingConfig.numberOfRecordsPerPage;
        } else {
          this.numberOfRows = 10;
        }
      },
      (error) => null);
  }

  openUserLogDialog(userLog): void {
    this.dialog.open(UserLogModalComponent, {
      height: '85vh',
      width: '80%',
      dismissableMask: false,
      data: {
        userLog
      },
      header: 'Log de Usuários'
    })
  }


}
