<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{'USERS-PROFILE-LIST' | translate}}</span>
        <div class="primary fab-button" (click)="openUserRoleFormDialog()">
            <i class="pi pi-plus" style="font-size: 21px;"></i>
        </div>
    </p-header>
    <div class="table">
        <p-table #tt [value]="rolesTable" [paginator]="true" [rows]="numberOfRows" resetPageOnSort="true"
            [rowsPerPageOptions]="[10,20,30,50,100]" [resizableColumns]="true" [autoLayout]="true"
            [globalFilterFields]="['name']">
            <ng-template pTemplate="caption">
                <div></div>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText size="50" placeholder="Filtro por nome" [(ngModel)]="globalFilter"
                        (input)="filterGlobal()" style="width:auto">
                </span>
                <span style="font-weight: bold; float: right; margin-top: 5px;">{{ 'TOTAL-REGISTERS' | translate }}:
                    {{tt.totalRecords}} {{'OF' | translate}} {{rolesTable.length}}
                </span>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th [pSortableColumn]="'Id'">
                        {{'Id' | translate}}
                        <p-sortIcon [field]="'Id'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'name'">
                        {{'NAME' | translate}}
                        <p-sortIcon [field]="'name'"></p-sortIcon>
                    </th>
                    <th></th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-role>
                <tr>
                    <td>{{role.id}}</td>
                    <td>{{role.name}}</td>
                    <td>
                        <div class="table-action" (click)="openUserRoleFormDialog(role)"
                            pTooltip="Editar {{role.name}}">
                            <i class="pi pi-pencil"></i>
                        </div>
                    </td>
                    <td>
                        <div class="table-action danger" (click)="openUserRoleDeleteConfirmDialog(role)"
                            pTooltip="Remover {{role.name}}">
                            <i class="pi pi-trash"></i>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-fieldset>
