import { Component, Input } from '@angular/core';
import { MovementMean } from 'src/app/models/movement/movement-mean';

@Component({
  selector: 'app-movement-mean-label',
  templateUrl: './movement-mean-label.component.html',
  styleUrls: ['./movement-mean-label.component.scss']
})
export class MovementMeanLabelComponent {

  @Input()
  movementMean: MovementMean;

  constructor() { }
}
