import { MarinaCompany } from './marina-company';
import { MarinaTermUse } from './marina-term-use';
import { ServerEmailOwn } from './server-email-own';
import { SlingConfig } from './sling-config';

export class Marina {

    id: number;
    tradeName: string;
    companyName: string;
    companyFederalId: string;
    country: string;
    state: string;
    city: string;
    district: string;
    street: string;
    website: string;
    email: string;
    phone: string;
    longitude: string;
    titleDiscountEnabled?: boolean;
    marinaTermUse?: MarinaTermUse[];
    latitude: string;
    contactName: string;
    contactJobTitle: string;
    contactEmail: string;
    contactPhone: string;
    secondPhone: string;
    faceBook: string;
    marinaBillingEmail: string;
    customerBillingEmail: string;
    passwordEmailCustomerBilling: string;
    logoFile: string;
    logoAppFile: string;
    descriptionApp: string;
    zipcode: string;
    federalRegistration: string;
    instagram: string;

    acceptMotorBoat: boolean;
    acceptSailBoat: boolean;
    acceptJetSki: boolean;
    acceptInflatable: boolean;
    enableInvoice: boolean;

    maxDraugth: number;
    acceptFromLength: number;
    acceptToLength: number;

    tractorQty: number;
    forkLiftQty: number;
    travelLiftQty: number;
    wetSlipQty: number;
    drySlipQty: number;
    marinaType: number;

    expirationDigitalCertificate: Date;

    folderCredencialsCustomerBillingEmail: string;

    invoiceGatewayId: string;

    contractsModelMarina: any[];

    slingConfigs: SlingConfig[];

    optionImageApp: number;
    imageApp1: string;
    imageApp2: string;
    imageApp3: string;
    imageApp4: string;

    marinaCompanies: MarinaCompany[];

    operationalUnit:string;

    hasServerEmailOwn?: boolean;
    serverEmailOwn?: ServerEmailOwn;

    disableWebOperation:boolean;
    disablePainelsOperation:boolean;

    invoiceSplit: boolean;
}
