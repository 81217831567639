<div class="dialog-form">
    <div style="padding-top: 15px;" *ngIf="customersSuccess?.length == 0">Nenhum cliente cadastrado</div>
    <div class="form-group">
        <button (click)="downloadFile()" class="easymarine primary" type="button">Baixar não cadastrados</button>
    </div>
    <br>
    <div *ngIf="customersSuccess?.length > 0" style="padding-top: 15px;">
        <p-fieldset>
            <p-header>
                <div class="article-title">Clientes Importados com sucesso</div>
            </p-header>

            <div class="table">
                <p-table [value]="customersSuccess" [alwaysShowPaginator]="false" autoLayout="true"
                    [paginator]="true" [rows]="rows" styleClass="easy-table">
                    <ng-template pTemplate="caption">
                        <div style="font-weight: bold; margin-top: 5px; text-align: right;">
                            {{ 'TOTAL-REGISTERS' | translate }}:&nbsp;{{customersSuccess.length}}
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th [pSortableColumn]="'name'">
                                Cliente
                                <p-sortIcon [field]="'name'"></p-sortIcon>
                            </th>
                            <th [pSortableColumn]="'federalId'">
                                CPF/CNPJ
                                <p-sortIcon [field]="'federalId'"></p-sortIcon>
                            </th>
                            <th [pSortableColumn]="'email'">
                                E-mail
                                <p-sortIcon [field]="'email'"></p-sortIcon>
                            </th>
                            <th [pSortableColumn]="'phone'">
                                Telefone
                                <p-sortIcon [field]="'phone'"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-customer>
                        <tr>
                            <td>
                                {{customer.name}}
                            </td>
                            <td>
                                {{customer.federalId}}
                            </td>
                            <td>
                                {{customer.email}}
                            </td>
                            <td>
                                {{customer.phone}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </p-fieldset>
    </div>
    <div *ngIf="customersExists?.length > 0" style="padding-top: 15px;">
        <p-fieldset>
            <p-header>
                <div class="article-title">Clientes Já Cadastrados</div>
            </p-header>

            <div class="table">
                <p-table [value]="customersExists" [alwaysShowPaginator]="false" autoLayout="true"
                    [paginator]="true" [rows]="rows" styleClass="easy-table">
                    <ng-template pTemplate="caption">
                        <div style="font-weight: bold; margin-top: 5px; text-align: right;">
                            {{ 'TOTAL-REGISTERS' | translate }}:&nbsp;{{customersExists.length}}
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header">
                        <tr>
                            <th [pSortableColumn]="'name'">
                                Cliente
                                <p-sortIcon [field]="'name'"></p-sortIcon>
                            </th>
                            <th [pSortableColumn]="'federalId'">
                                CPF/CNPJ
                                <p-sortIcon [field]="'federalId'"></p-sortIcon>
                            </th>
                            <th [pSortableColumn]="'email'">
                                E-mail
                                <p-sortIcon [field]="'email'"></p-sortIcon>
                            </th>
                            <th [pSortableColumn]="'phone'">
                                Telefone
                                <p-sortIcon [field]="'phone'"></p-sortIcon>
                            </th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-customer>
                        <tr>
                            <td>
                                {{customer.name}}
                            </td>
                            <td>
                                {{customer.federalId}}
                            </td>
                            <td>
                                {{customer.email}}
                            </td>
                            <td>
                                {{customer.phone}}
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </p-fieldset>
    </div>
</div>
