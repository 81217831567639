export class LandExitDTO{
  id: number;
  executionDate: any;
  returnDate: any;
  customerId: number;
  boatId: number;
  sailorId: number;

  constructor(executionDate: any, returnDate: any, customerId: number, boatId: number) {
    this.executionDate = executionDate;
    this.returnDate = returnDate;
    this.customerId = customerId;
    this.boatId = boatId;
  }
}
