<p-table [value]="movements" styleClass="sling-table" autoLayout="true" [lazy]="true">
    <ng-template pTemplate="header">
        <tr>
            <th class="text-center">Prev.</th>
            <th class="text-center">Mov.</th>
            <th class="text-center">Grupo</th>
            <th class="text-center">Objetivo</th>
            <th class="text-center">Embarcação</th>
            <th class="text-center">Modelo</th>
            <th class="text-center">Local Atual</th>
            <th class="text-center">Destino</th>
            <th class="text-center">Sentido</th>
            <th class="width-operation text-center">Operação</th>
            <th class="width-btn-actions text-center"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-movement>
        <tr class="border-align-opacity" [ngClass]="{highlighted: isHighlighted(movement)}" appBoatMovementRow [boatMovementRow]="movement" >
            <td  class="text-center">
              <app-movement-expected-date [params]="{ date: movement.date}"></app-movement-expected-date>
            </td>
            <td class="text-center">
              <app-movement-means-icon-url [params]="{ iconUrls: movement?.movementMeanIconUrlList }">
              </app-movement-means-icon-url>
            </td>
            <td class="text-center">
              <span>{{movement?.movementGroupCode}}</span>
            </td>
            <td class="text-center">
              <span>{{movement?.movement?.objective | translate}}</span>
            </td>
            <td class="text-center">
              <span class="boat-name-width" [pTooltip]="movement.boat.name" tooltipPosition="bottom" >
                {{ movement.boat.name }}
              </span>
            </td>
            <td class="text-center">
                {{movement.boat.shipType.toUpperCase() | translate}}&nbsp;
                {{movement.boat.commercialLength}}'
            </td>
            <td class="text-center">
              <app-movement-location
                [params]="getOrigin(movement)"
              >
              </app-movement-location>
            </td>
            <td class="text-center">
              <app-movement-location *ngIf="movement?.movement"
                [params]="getDestiny(movement)">
              </app-movement-location>
            </td>
            <td class="text-center">
              <app-movement-arrow-direction *ngIf="movement?.arrowDirection" [darkMode]="false" [arrowDirection]="movement.arrowDirection"></app-movement-arrow-direction>
            </td>
            <td class="width-operation">
              <button class="easymarine primary" [ngClass]="{'btn-align': !isEnableCancel(movement)}" type="button" (click)="executeOperation(movement)">
                {{'MOVEMENT.' + movement.nextOperation | translate}}
              </button>
              <button type="button" class="fab-button danger spacing icon-button"
                *ngIf="isEnableCancel(movement)"
                (click)="cancelLastOperation(movement)">
                <i class="pi pi-replay"></i>
              </button>
            </td>
            <td class="width-btn-actions text-center">
              <button type="button" class="fab-button btn-white icon-button spacing"
                (click)="movementPlainView(movement)"pTooltip="Visualizar documento" tooltipPosition="bottom">
                <i class="pi pi-eye"></i>
              </button>
              <button type="button" class="fab-button btn-white  icon-button"
                (click)="openEditDialog(movement)" pTooltip="Editar" tooltipPosition="bottom">
                <i class="pi pi-pencil"></i>
              </button>

            </td>
        </tr>
    </ng-template>
</p-table>
