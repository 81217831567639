import {Component, OnInit, ViewChild} from '@angular/core';
import moment from 'moment';
import {NgxSpinnerService} from 'ngx-spinner';
import {SelectItem} from 'primeng/api';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Boat, Customer} from 'src/app/models';
import {ContractDocumentService} from 'src/app/services/contract-document.service';
import {MessageUtil} from 'src/app/utils/message.util';
import {BoatFormComponent} from '../../../forms/boat/boat-form/boat-form.component';
import Swal from 'sweetalert2';
import {VariablesContractDialogComponent} from './variables-contract-dialog/variables-contract-dialog.component';
import {FormControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ToastService} from 'src/app/services/toast.service';
import {FileUpload} from 'primeng/fileupload';
import {DocumentTemplateService} from 'src/app/services/document-template.service';
import {TemplateVariablesRequestDTO} from 'src/app/models/dtos/template-variables-requestDTO';
import {DocumentTemplate} from 'src/app/models/document-template';
import {DocumentWithDataRequestDTO} from 'src/app/models/dtos/document-with-data-requestDTO';
import {FileService} from 'src/app/services/file.service';
import {ServiceBoatWithoutContractDTO} from 'src/app/models/dtos/service-boat-without-contractDTO';
import {ServiceBoatIdentifierDTO} from 'src/app/models/dtos/service-boat-identifierDTO';
import {ContractType} from 'src/app/models/enums/contract-type.';
import {BoatService} from 'src/app/services/boat.service';
import {DocumentDefaultSignerService} from 'src/app/services/document-default-signer.service';
import {DocumentDefaultSigner} from 'src/app/models/document-default-signer';
import {CustomerType} from '../../../../models/customer-type';

@Component({
  selector: 'app-new-contract-dialog',
  templateUrl: './new-contract-dialog.component.html',
  styleUrls: ['./new-contract-dialog.component.scss']
})
export class NewContractDialogComponent implements OnInit {

  @ViewChild('upload', { static: false }) upload: FileUpload;
  templates: DocumentTemplate[];
  boats: any[];
  boatServices: any[];
  contractData : ServiceBoatWithoutContractDTO;
  boat: Boat;
  activeIndex: number = 0;

  contractSelectedTypeOptionBoats: Boat[] = [];
  contractSelectedTypeAndBoat: any[] = [];
  contractBoatsId: SelectItem[] = [];
  contractTypeListMain: Boat[] = [];
  contractTypeListAdditiveTermination: Boat[] = [];
  contractList: any[] = [];
  isThereATemplate: boolean;
  isThereSigners: boolean;
  defaultSigners: DocumentDefaultSigner[] = []
  selectedServices: any;

  boatListContract: SelectItem[] = [];
  templateListContract: SelectItem[] = [];
  contractTypeList: SelectItem[] = [];

  contractForm: UntypedFormGroup;
  file: File;
  type = 'template';

  customersBoat : Customer[] = [];
  disableForm = false;
  constructor(
    public dialogRef: DynamicDialogRef,
    private dialog: DialogService,
    private config: DynamicDialogConfig,
    private spinner: NgxSpinnerService,
    private contractDocumentService: ContractDocumentService,
    private documentTemplateService: DocumentTemplateService,
    private messageUtil: MessageUtil,
    private toasty: ToastService,
    private boatService: BoatService,
    private documentDefaultSignerService: DocumentDefaultSignerService,
  ) { }

  ngOnInit(): void {
    this.loadTemplates();
    this.loadSigners();
    this.startForm();
    if (this.config.data.boatListContract) {
      this.boatListContract = this.config.data.boatListContract;
      this.loadBoats();
    }

    if (this.config.data.contractData) {
      this.contractData = this.config.data.contractData;
      this.WithoutContractForm();
    } else {
      this.loadOptionsContractType();
    }
    if(this.config.data.additive){
      this.loadDataToAdditiveOrDistractContract()
    }
  }

  loadBoats(): void {
    this.boats = this.config.data.boats;

    this.loadContractMainBoats(this.boats);
    this.loadAdditiveTerminationBoats(this.boats);

    this.boatListContract = this.boats
      .map((boat) => ({
        label: boat.name.concat((<any> boat).maintenance ? ' (Em manutenção)' : ''),
        value: boat.id,
        disabled: (<any> boat).maintenance,
      }));
  }

  loadContractMainBoats (data) {
    this.spinner.show();
    const boatsWithBoatServices = data.filter(boat => boat.boatServices.length > 0);
    boatsWithBoatServices.forEach((boat) => boat.boatServices.forEach(boatService => {
      if ((boatService.contract == null) || (boatService.contract.contractType == ContractType.TERMINATION))  {
        boat.boatServices = boat.boatServices.reduce((acc, curr) =>{
          if (curr.contract == null) {
            acc.push(curr);
          } return acc
        }, [])
        if (!this.contractTypeListMain.includes(boat)) {
          this.contractTypeListMain.push(boat);
        }
      }
    }));
    this.spinner.hide();
  }

  loadAdditiveTerminationBoats(data) {
    this.spinner.show();
    const boatsWithBoatServices = data.filter(boat => boat.boatServices.length > 0);
    boatsWithBoatServices.forEach((boat) => boat.boatServices.forEach(boatService => {
      if (boatService.contract != null) {
        boat.boatServices = boat.boatServices.reduce((acc, curr) => {
          if (curr.contract !== null) {
            acc.push(curr);
        } return acc
        },[])
        if (!this.contractTypeListAdditiveTermination.includes(boat)) {
          this.contractTypeListAdditiveTermination.push(boat);
        }
      }
    }));
    this.spinner.hide();
  }

  loadOptionsContractType() {
    this.contractTypeList = Object.keys(ContractType).map(item => ({ label: this.messageUtil.translateKey(item), value: item }));
  }

  startForm(): void {
    this.contractForm = new UntypedFormGroup({
      boat: new UntypedFormControl(null, Validators.required),
      contractType: new UntypedFormControl(null, Validators.required),
      template: new UntypedFormControl(null),
      type: new UntypedFormControl('template'),
      customerIds: new FormControl<number[] | null>(null, Validators.required)
    });
    this.contractForm.get('boat').disable();
  }

  WithoutContractForm(): void {
    this.contractForm = new UntypedFormGroup({
      template: new UntypedFormControl(null),
      type: new UntypedFormControl('template')
    });
  }

  isType(type: string): boolean {
    return this.contractForm.get('type').value === type;
  }

  loadSigners(): Promise<void> {
    return new Promise<void>(
      async (resolve, reject) => {
        this.documentDefaultSignerService.getDefaultSigners().subscribe(
          async (data) => {
            this.defaultSigners = data;
            this.isThereSigners = data.length > 0
            resolve();
          }, async (error) => {
            console.log(error);
            reject();
          });
      }
    );
  }

  loadTemplates(): void {
    this.spinner.show();

    this.documentTemplateService.getTemplates()
      .subscribe((data) => {
        this.templates = data;

        this.isThereATemplate = data.length > 0

        this.templateListContract = data
          .map((template) => ({
            label: decodeURI(template.title),
            value: template.id
          }));
        this.spinner.hide();
      }, (error) => this.showError(error));
  }

  enableDigitalSignature() {
    return !(this.isThereATemplate && this.isThereSigners);
  }

  getServiceBoat(): ServiceBoatIdentifierDTO[] {
    let selectedBoatServicesList = [];

    if (this.contractData) {
      const serviceBoatIdentifier = new ServiceBoatIdentifierDTO();

      serviceBoatIdentifier.serviceBoatId = this.contractData.serviceBoatId;
      serviceBoatIdentifier.boatName = this.contractData.boatName;
      serviceBoatIdentifier.serviceName.push(this.contractData.serviceName);
      serviceBoatIdentifier.contractType = this.contractData.contractType;

      selectedBoatServicesList.push(serviceBoatIdentifier)
    } else {
      const selectedContractsServices = this.contractList;
      const selectedServicesWhitElements = this.selectedServices
        .map((service: SelectItem) => selectedContractsServices.find((list) => list.id == service));
      selectedServicesWhitElements.forEach(selectedBoatService => {
        const serviceBoatIdentifier = new ServiceBoatIdentifierDTO();
        serviceBoatIdentifier.serviceBoatId = selectedBoatService.id;
        serviceBoatIdentifier.boatId = this.contractForm.value.boat.id;
        serviceBoatIdentifier.serviceId = selectedBoatService.service.id;
        serviceBoatIdentifier.boatName = this.contractForm.value.boat.name;
        serviceBoatIdentifier.serviceName = selectedBoatService.service.name;
        serviceBoatIdentifier.contractType = this.contractForm.value.contractType;
        selectedBoatServicesList.push(serviceBoatIdentifier);
      });
      return selectedBoatServicesList;
    }
  }

  sendDocumentWithFile(): void {
    const serviceBoatIdentifier = this.getServiceBoat();

    const title = 'Envio de contrato para a D4Sign';
    const text = `Contrato:<br><b>${this.file.name}</b><br><br>Embarcação:<br><b>${serviceBoatIdentifier[0].boatName}</b>
    <br><br>Serviço:${serviceBoatIdentifier.map((service) => {
      return '<br><b>' + service.serviceName + '</b>'
    })}`;

    Swal.fire({
      title,
      html: text,
      icon: 'warning',
      backdrop: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then(async (result) => {
      if (result.value) {
        this.sendToCreateDocumentD4sign(this.file, serviceBoatIdentifier);
      }
    });
  }

  sendToCreateDocumentD4sign(file, serviceBoatIdentifier: ServiceBoatIdentifierDTO[]) {
    this.spinner.show();

    let servicesId = serviceBoatIdentifier.map(service => service.serviceBoatId);

    this.contractDocumentService.sendDocumentWithFile(file, servicesId, serviceBoatIdentifier[0].contractType)
      .subscribe((data) => {
        this.spinner.hide();
        this.toasty.success('Documento enviado com sucesso!');
        this.dialogRef.close();
      }, (error) => this.showError(error));
  }

  sendDocumentFinish(): void {
    const serviceBoatIdentifier = this.getServiceBoat();

    let servicesId = serviceBoatIdentifier.map(service => service.serviceBoatId);

    const title = 'Envio de contrato para a D4Sign';
    const text = `Contrato:<br><b>${this.file.name}</b><br><br>Embarcação:<br><b>${serviceBoatIdentifier[0].boatName}</b>
    <br><br>Serviço:${serviceBoatIdentifier.map((service) => {
      return '<br><b>' + service.serviceName + '</b>'
    })}`;

    Swal.fire({
      title,
      html: text,
      icon: 'warning',
      backdrop: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then(async (result) => {
      if (result.value) {
        this.spinner.show();
        this.contractDocumentService.sendDocumentFinish(this.file, servicesId, serviceBoatIdentifier[0].contractType)
          .subscribe(async (data) => {
            this.spinner.hide();
            this.toasty.success('Documento enviado com sucesso!');
            this.dialogRef.close("success");
          }, (error) => this.showError(error));
      }
    });
  }

  async sendDocument(template): Promise<void> {
    const newTemplate = {
      ...template,
      serviceBoatId: template.serviceBoatId
    }
    const urlDocument = await this.generateDocumentPDF(newTemplate);
    const serviceBoatIdentifier = this.getServiceBoat();

    let serviceNameMapping = serviceBoatIdentifier.map((service) => ` ${service.serviceName}`)

    const fileName = `Contrato - ${serviceBoatIdentifier[0].boatName} - Serviço(s): ${serviceNameMapping}`;
    const blob = await FileService.base64toBlob('data:application/pdf;base64,' + urlDocument, fileName);
    const fileCreated: File = new File([blob], fileName+".pdf");

    const file = FileService.createBlobDataPDF(urlDocument);
    const urlblob = URL.createObjectURL(file);
    const title = 'Envio de contrato para a D4Sign';
    const text = `Contrato:<br><b> Contrato - ${serviceBoatIdentifier[0].boatName} - Serviço: ${serviceNameMapping}</b><br><br>Embarcação:<br><b>${serviceBoatIdentifier[0].boatName}</b>
    <br><br>Serviço:<br><b>${serviceNameMapping}</b><br><br><span style="color:blue;cursor: pointer;" onclick="window.open('${urlblob}')"> Visualizar Contrato</span>`;

    Swal.fire({
      title,
      html: text,
      icon: 'warning',
      backdrop: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then(async (result) => {
      if (result.value) {
        this.spinner.show();
        this.sendToCreateDocumentD4sign(fileCreated, serviceBoatIdentifier);
      }
    });
  }

  openFileDataPDF(urlDocument: string): void {
    window.open(URL.createObjectURL(FileService.createBlobDataPDF(urlDocument)));
  }

  loadBoatsWithContractTypeSelected() {
    this.spinner.show();

    this.contractSelectedTypeOptionBoats = [];
    this.boatServices = [];

    this.resetAndDisableSelectors();
    if (this.contractForm.value.contractType == 'MAIN') {
      this.contractTypeListMain.forEach((boat) => {
        this.contractSelectedTypeOptionBoats.push(boat);
        this.boatServices.push(...boat.boatServices);
      })
    } if (this.contractForm.value.contractType != 'MAIN') {
      this.contractTypeListAdditiveTermination.forEach((boat) => {
        this.contractSelectedTypeOptionBoats.push(boat);
        this.boatServices.push(...boat.boatServices);
    })}
    this.spinner.hide();
    this.contractForm.get('boat').enable();
  }

  async loadServicesWhitSelectedBoat() {
    this.contractSelectedTypeAndBoat = [];
    const boatId = this.contractForm.value.boat.id;

    let selectedBoatServices = this.contractForm.value.contractType == 'MAIN'
      ? await this.getServicesMain(boatId)
      : await this.getServicesNotMain(boatId);

      const serviceFormatted = selectedBoatServices.map(boatService => (
          { label: boatService.service.name+" - "+moment(boatService.contractStartDate).format('DD/MM/YY'), value: boatService.id }
        )
      );
      this.contractList = selectedBoatServices;
      this.contractSelectedTypeAndBoat = serviceFormatted;
      const boat: Boat = this.contractForm.get('boat').value;
      this.customersBoat = [];
      if (boat.boatCustomers.length === 0 ){
        this.toasty.warning('Embarcação não possui clientes cadastrados');
        this.contractForm.get('boat').setValue(null);
        return;
      }
      boat.boatCustomers
        .filter((bc) => bc.type === CustomerType.Owner)
        .forEach((boatCustomer) => this.customersBoat.push(boatCustomer.customer));
      this.contractForm.controls.customerIds.setValue(null);
  }

  async getServicesMain(id): Promise<any> {
    return new Promise((resolve) =>
      this.boatService.getServicesWithoutContracts(id).subscribe({
      next: (data) => resolve(data),
      error: (e) => {
        console.log(e);
      }})
    )
  }

  async getServicesNotMain(id): Promise<any> {
    return new Promise((resolve) =>
    this.boatService.getServicesWithContracts(id).subscribe({
      next: (data) => resolve(data),
      error: (e) => {
        console.log(e);
      }
    })
    )
  }

  resetAndDisableSelectors() {
    this.contractForm.get('boat').reset();
    this.contractForm.get('boat').disable();
    this.selectedServices = [];
  }

  isValid(): boolean {
    return this.contractForm.valid && this.confirmTemplateOrFile();
  }

  validSignedDocument(): boolean {
    return (!!this.file && this.isType('file'))
      || (this.contractForm.get('template').value && this.isType('template'));
  }

    async save(): Promise<void> {
    if (this.isType('template') && this.file && this.activeIndex === 0) {
      this.sendDocumentFinish();
    } else {
      if (this.isType('file') || this.file) {
        this.sendDocumentWithFile();
      } else {
        const documentWithData = await this.getVariablesDocument();
        if (!documentWithData) {
          return;
        }
        this.sendDocument(documentWithData);
      }
    }
  }

  confirmTemplateOrFile(): boolean {
    if (this.activeIndex === 0) {
      return (!!this.file);
    } if (this.activeIndex === 1) {
      return (this.contractForm.get('template').value && this.isType('template')) || (!!this.file && this.isType('file'));
    }
  }

  async getVariablesDocument(): Promise<DocumentWithDataRequestDTO> {
    return new Promise<DocumentWithDataRequestDTO>(async (resolve) => {
      this.spinner.show();
      const selectedTemplate = this.templates.find(template => template.id === this.contractForm.value.template);
      const variables = await this.getDocumentVariables(selectedTemplate);

      if (!variables) {
        resolve(null);
        return
      }
      const size = Object.keys(variables).length;
      if (size < 1) {
        let serviceBoatId = [];
        if (this.contractData){
          serviceBoatId.push(this.contractData.serviceBoatId)
        }else{
          serviceBoatId = [...this.contractForm.value.service];
        }
        const template: DocumentWithDataRequestDTO = {
          serviceBoatId,
          templateId: selectedTemplate.id,
          variablesWithData: new Map()
        };
        resolve(template);
      } else {
        this.dialog.open(VariablesContractDialogComponent, {
          width: '50%',
          dismissableMask: false,
          data: {
            variables
          },
          header: 'Variáveis do contrato'
        }).onClose.subscribe(
          (variablesData) => {
            if (variablesData) {
              let serviceBoatId = [];
              if (this.contractData){
                serviceBoatId.push(this.contractData.serviceBoatId)
              }else{
                serviceBoatId = [...this.selectedServices];
              }
              const template: DocumentWithDataRequestDTO = {
                serviceBoatId,
                templateId: selectedTemplate.id,
                variablesWithData: variablesData
              };
              resolve(template);
            } else {
              resolve(null);
            }
          });
      }
    })
  }

  async openBoatFormDialog(): Promise<void> {
    const boatId = await this.findBoatId(this.contractData.serviceBoatId);
    this.dialog.open(BoatFormComponent, {
      height: '85vh',
      width: '80%',
      dismissableMask: false,
      data: {
        tabIndex: 2,
        id: boatId
      },
      header: 'Cadastro de embarcação'
    });
  }

  async findBoatId(serviceBoatId: number): Promise<number> {
    return new Promise<number>((resolve) => {
      this.boatService.findBoatIdByServiceBoatId(serviceBoatId).subscribe(async boatId => {
        resolve(boatId);
      })
    })
  }

  showError(error): void {
    this.spinner.hide();
    console.error(error)
    const exception = error.error.exception ? error.error.exception : error.error.data.exception;
    this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
  }

  showErrorVariables(error): void {
    const exception = error.error.exception ? error.error.exception : error.error.data.exception;
    this.spinner.hide();
    Swal.fire({
      title: this.messageUtil.translateKey(exception.title),
      icon: exception.type,
      text: exception.message,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Gerenciar Barco',
      cancelButtonText: 'Fechar'
    }).then((r) => {
      if (r.value) {
        this.openBoatFormDialog();
      }
    });
  }

  async generateDocument() {
    const documentWithData = await this.getVariablesDocument();
    if (!documentWithData) {
      return;
    }
    const urlDocument = await this.generateDocumentPDF(documentWithData);
    this.openFileDataPDF(urlDocument);
  }

  async getDocumentVariables(selectedTemplate: any): Promise<Map<string, string>> {
    return new Promise<Map<string, string>>(
      async (resolve) => {
        const serviceBoatIds: number[] = [];
        if (this.contractData){
          serviceBoatIds.push(this.contractData.serviceBoatId)
        }else{
          for (let serviceElement of this.selectedServices) {
            serviceBoatIds.push(serviceElement);
          }
        }
        const customerIds = this.contractForm.get('customerIds').value;
        const templateVariablesRequest: TemplateVariablesRequestDTO = {
          serviceBoatId: serviceBoatIds,
          templateId: selectedTemplate.id,
          customerIds: customerIds
        }

        this.documentTemplateService.getVariablesDocument(templateVariablesRequest).subscribe(
          (data) => {
            this.spinner.hide();
            resolve(data);
          }, (error) => {
            console.log(error);
            resolve(null);
            this.showErrorVariables(error);
          });
      }
    );
  }

  async generateDocumentPDF(template: DocumentWithDataRequestDTO): Promise<any> {
    return new Promise<any>(
      async (resolve) => {
        this.spinner.show();
        await this.documentTemplateService.generateContractFile(template).subscribe(
          (data) => {
            this.spinner.hide();
            resolve(data);
          }, (error) => {
            resolve(null);
            this.showErrorVariables(error);
          });
      }
    );
  }

  setFile(file): void {
    this.file = file[0];
    this.file.name;
    this.upload.clear();
  }

  messageEmptyService(): string {
    if (this.contractForm.get('boat') != null){
      return this.contractForm.get('boat').valid? 'Embarcação não possui serviço': 'Selecione a embarcação';
    }
  }

  handlerDocumentType(eventIndex){
    if(eventIndex != this.activeIndex){
      this.file = undefined;
      this.contractForm.get('template').setValue(null);
      this.contractForm.get('type').setValue('template')
    }
    this.activeIndex = eventIndex;
  }

  messageEmptyBoatCustomer() {
    const boat: Boat = this.contractForm.get('boat').value;
    if (boat !== null){
      return  boat.boatCustomers.length === 0 ? 'Embarcação não possui cliente' : 'Selecione o cliente'
    }
    return 'Embarcação não possui cliente'
  }

  private loadDataToAdditiveOrDistractContract() {
    this.contractTypeList = [];
    const contractType = this.config.data.contractType;
    this.contractTypeList.push({ label: this.messageUtil.translateKey(contractType), value: contractType });
    this.contractForm.get('contractType').setValue(this.contractTypeList[0].value);
    this.spinner.show()
    this.boatService.getById(this.boats[0].id).subscribe({
      next: async boat => {
        this.spinner.hide()
        this.boat = boat
        this.contractSelectedTypeOptionBoats.push(boat)
        this.contractForm.get('boat').setValue(boat);
        this.contractForm.get('boat').enable()
        await this.loadServicesWhitSelectedBoat();
        this.getContractServices();
        this.getCustomersDocumentSigners()
        this.disableForm = true
      }, error: err => {
        this.spinner.hide()
        console.log(err)
      }
    })
  }

  private getContractServices() {
    let array = this.getArrayListFromString(this.config.data.contractDocument.serviceBoatIds);
    const contractedDocuments: SelectItem[] = this.contractSelectedTypeAndBoat.filter(sb => array.includes(sb.value)).map(sb => sb.value);
    this.selectedServices = contractedDocuments;
    return contractedDocuments;
  }
  private getCustomersDocumentSigners(){
    let arrayCustomer = this.getArrayListFromString(this.config.data.contractDocument.customerIds);
    const customerIds  = this.customersBoat.filter(cb => arrayCustomer.includes(cb.id)).map(cb => cb.id)
    if(customerIds.length == 0){
      this.toasty.warning('Os clientes que assinaram esse contrato não estão mais associados à essa embarcação. Selecione os novos clientes do contrato')
    }else{
      this.contractForm.controls.customerIds.setValue(customerIds);
    }
  }
  private getArrayListFromString(arrayString: string) {
    let idArray = arrayString.split(',');
    let array: number[] = idArray.map((id: string) => parseInt(id.trim()))
    return array;
  }
}
