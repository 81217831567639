export enum BlockType {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL',
  NON_BLOCKED = 'NON_BLOCKED',
}

export const translateBlockType = (blockType: BlockType) => {
  if (blockType === 'AUTOMATIC') {
    return 'Automático'
  }
  if (blockType === 'MANUAL') {
    return 'Manual'
  }
  if (blockType === 'NON_BLOCKED') {
    return 'Sem bloqueio'
  }
}
