import { Injectable } from '@angular/core';
import { Sling } from '../models';
import { BoatStatus, RequestOrigin } from '../models/enums';
import { ImageUtil } from './image.util';

@Injectable()
export class SlingUtil {

    constructor(
        private imageUtil: ImageUtil
    ){}

    hasCancel(sling: Sling): boolean {
        return sling && sling.slingStatus !== 'OK' && sling.slingStatus !== 'CANCELED' &&
            (
                sling.boatStatus === BoatStatus.WAITING_PREPARATION ||
                sling.marinaOrder.orderItems.filter(
                    (item) => item.qtySupplied === null ||
                        item.qtySupplied === undefined
                ).length !== 0
            );
    }

    getImageIconFuel(sling: Sling): string {
        let image = 'assets/images/';
        const statusFuel = this.getStatusFuel(sling);
        switch (statusFuel) {
            case 'default':
                image = image.concat('fuel.png');
                break;
            case 'primary':
                image = image.concat('fuelPrimary.png');
                break;
            case 'warning':
                image = image.concat('fuelWarning.png');
                break;
            case 'success':
                image = image.concat('fuelSuccess.png');
                break;
            case 'own_success':
                image = image.concat('fuelOwnSuccess.png');
                break;
            default:
                break;
        }
        return image;
    }

    getImageIconConsumable(sling: Sling): string {
        let image = 'assets/images/';
        const stautsConsumable = this.getStatusConsumable(sling);
        switch (stautsConsumable) {
            case 'default':
                image = image.concat('consumable.png');
                break;
            case 'primary':
                image = image.concat('consumablePrimary.png');
                break;
            case 'warning':
                image = image.concat('consumableWarning.png');
                break;
            case 'success':
                image = image.concat('consumableSuccess.png');
                break;
            default:
                break;
        }
        return image;
    }

    getImageIconAcessories(sling: Sling): string {
        let image = 'assets/images/';
        const stautsConsumable = this.getStatusAcessories(sling);
        switch (stautsConsumable) {
            case 'default':
                image = image.concat('accessory.png');
                break;
            case 'primary':
                image = image.concat('accessoryPrimary.png');
                break;
            case 'warning':
                image = image.concat('accessoryWarning.png');
                break;
            case 'success':
                image = image.concat('accessorySuccess.png');
                break;
            default:
                break;
        }
        return image;
    }

    getImageOriginSling(sling: Sling): string {
        return this.imageUtil.getImageOrigin(sling.slingOrigin);
    }

    getOriginName(sling: Sling): string {
        switch (sling.slingOrigin) {
            case RequestOrigin.CustomerApp:
                return 'Easymarine Cliente';
            case RequestOrigin.EasyWeb:
                return 'Easymarine Web';
            case RequestOrigin.GestorApp:
                return 'Easymarine Gestor';
            case RequestOrigin.TotemApp:
                return 'Easymarine Totem';
            case RequestOrigin.MarinheiroApp:
                return 'Easymarine MarinheiroApp';
            default:
                return 'Easymarine Web';
        }
    }

    private getStatusFuel(sling: Sling): string {
        const orderItem = sling.marinaOrder.orderItems.find((oi) => oi.product.fuel != null);
        if (!orderItem || (!orderItem.qtyRequested && !orderItem.fuelTankFraction)) {
            if (sling.ownFuelSupply === false) {
                return 'own_success';
            }
            return 'default';
        } else if ((orderItem.qtyRequested || orderItem.fuelTankFraction) && !orderItem.qtySupplied) {
            return 'primary';
        } else if (orderItem.qtySupplied < orderItem.qtyRequested) {
            return 'warning';
        } else {
            return 'success';
        }
    }

    private getStatusConsumable(sling: Sling): string {
        const noItemAttended = sling.marinaOrder.orderItems
            .find((oi) => oi.product.fuel == null && !oi.qtySupplied);

        const itemPartiallyAttended = sling.marinaOrder.orderItems
            .find((oi) => oi.product.fuel == null && oi.qtySupplied && oi.qtySupplied < oi.qtyRequested);

        const allItemAttended = sling.marinaOrder.orderItems
            .find((oi) => oi.product.fuel == null && oi.qtyRequested <= oi.qtySupplied);

        if (noItemAttended && !itemPartiallyAttended) {
            return 'primary';
        } else if (itemPartiallyAttended) {
            return 'warning';
        } else if (allItemAttended) {
            return 'success';
        } else {
            return 'default';
        }
    }

    private getStatusAcessories(sling: Sling): string {
        const acessories = sling.slingAccessories;
        const attended = sling.slingAccessories.filter((x) => x.attended);
        if (acessories.length === 0) {
            return 'default';
        } else if (acessories.length > 0 && attended.length === 0) {
            return 'primary';
        } else if (acessories.length > 0 && (attended.length < acessories.length)) {
            return 'warning';
        } else {
            return 'success';
        }
    }
}
