import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SlingConfig } from 'src/app/models';
import { ShipType } from 'src/app/models/ship-type';
import { BoatWashService } from 'src/app/services/boat-wash.service';
import { EngineTurnService } from 'src/app/services/engine-turn.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';
import { MessageUtil } from 'src/app/utils/message.util';
import moment from 'moment';
import { BoatWashEngineTurnFormDialogComponent } from './boat-wash-engine-turn-form-dialog/boat-wash-engine-turn-form-dialog.component';
import { BoatWash } from 'src/app/models/boatWash';
import { EngineTurn } from 'src/app/models/engine-turn';
import { DialogService } from 'primeng/dynamicdialog';
import { StorageUtil } from 'src/app/utils/storage.util';

@Component({
  selector: 'app-boat-wash-engine-turn-panel',
  templateUrl: './boat-wash-engine-turn-panel.component.html',
  styleUrls: ['./boat-wash-engine-turn-panel.component.scss']
})
export class BoatWashEngineTurnPanelComponent implements OnInit, OnDestroy {

  slingConfig = new SlingConfig();
  washes: any[] = [];
  interval: any;

  constructor(
    private slingConfigService: SlingConfigService,
    private messageUtil: MessageUtil,
    private spinner: NgxSpinnerService,
    private boatWashService: BoatWashService,
    private engineTurnService: EngineTurnService,
    private toast: ToastService,
    private dialog: DialogService
  ) { }

  ngOnInit(): void {
    this.loadSlingConfig();
    this.getScheduledWashes();
    this.interval = setInterval(() => {
      this.getScheduledWashes();
    }, 30000);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  loadSlingConfig(): void {
    this.slingConfigService.getSlingConfig().subscribe(
      (data) => {
        this.slingConfig = data[0];
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }

  isLastSlingUpGreaterThan(washEngineTurn): boolean {
    if (washEngineTurn.washDate) {
      return washEngineTurn.seaWash;
    } else {
      return washEngineTurn.seaEngineTurn;
    }
  }

  isOverdueTask(washEngineTurn): boolean {
    if (washEngineTurn.scheduleDate.substring(0, 10) < moment().format('YYYY-MM-DD')) {
      return true;
    }
    return false;
  }

  isEqualdueTask(washEngineTurn): boolean {
    if (washEngineTurn.scheduleDate.substring(0, 10) === moment().format('YYYY-MM-DD')) {
      return true;
    }
    return false;
  }

  getImageShipType(shipType: ShipType): string {
    return '/assets/images/'.concat(shipType).concat('.png');
  }

  getScheduledWashes(): void {
    this.boatWashService.getScheduledBoatWashesEngineTurn().subscribe((washes) => this.washes = washes);
  }

  openDialogFastRegistrarion(washEngineTurn: any): void {
    const options = {
      width: '40%',
      height: '400px',
      data: {},
      header: '',
      dismissableMask: false
    };
    if (washEngineTurn) {
      options.data = {
        boatSelected: washEngineTurn.id,
        requestDate: new Date(),
        seaWash: washEngineTurn.seaWash,
        seaEngineTurn: washEngineTurn.seaEngineTurn,
        engineTurn: washEngineTurn.engineTurnDate != null,
        wash: washEngineTurn.washDate != null,
        isNew: true
      };
      options.header = 'Escolha uma das opções';
    } else {
      options.data = { isNew: true };
      options.header = 'Cadastro rápido';
    }
    this.dialog.open(BoatWashEngineTurnFormDialogComponent, options).onClose.subscribe(
      (result) => {
        if (result) {
          if (result.washEngineTurnOption === 'WASH_AND_ENGINE_TURN') {
            this.createBoatWashFastRegistration(result.boatId, result.requestDate, result.seaWash);
            this.createEngineTurnFastRegistration(result.boatId, result.requestDate, result.seaEngineTurn);
          } else if (result.washEngineTurnOption === 'WASH_ONLY') {
            this.createBoatWashFastRegistration(result.boatId, result.requestDate, result.seaWash);
          } else {
            this.createEngineTurnFastRegistration(result.boatId, result.requestDate, result.seaEngineTurn);
          }
        }
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }

  washEngineTurnConfirm(washEngineTurn): void {
    this.openDialogFastRegistrarion(washEngineTurn);
  }

  private createBoatWashFastRegistration(boatId, washDate: any, isSea: boolean): void {
    const boatWash = new BoatWash();
    boatWash.boatId = boatId;
    boatWash.isActive = false;
    boatWash.washDate = washDate;
    boatWash.isSea = isSea;
    this.boatWashService.createFastAutoRegistrarion(boatWash).subscribe(
      () => {
        this.toast.success('Lavação finalizada com sucesso');
        this.getScheduledWashes();
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      },
      () => this.spinner.hide()
    );
  }

  private createEngineTurnFastRegistration(boatId, engineTurnDate: any, isSea: boolean): void {
    const engineTurn = new EngineTurn();
    engineTurn.boatId = boatId;
    engineTurn.isActive = false;
    engineTurn.engineTurnDate = engineTurnDate;
    engineTurn.isSea = isSea;
    this.engineTurnService.createFastAutoRegistrarion(engineTurn).subscribe(
      () => this.getScheduledWashes(),
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      },
      () => this.spinner.hide()
    );
  }
}
