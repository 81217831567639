import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LazyLoadEvent, SelectItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { Boat, SlingConfig, Vacancy } from 'src/app/models';
import { BoatFilter } from 'src/app/models/dtos/boat-filter';
import { ShipType } from 'src/app/models/enums';
import { BoatService } from 'src/app/services/boat.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { ToastService } from 'src/app/services/toast.service';
import { VacancyService } from 'src/app/services/vacancy.service';
import { MessageUtil } from 'src/app/utils/message.util';
import Swal from 'sweetalert2';
import { BoatFormComponent } from './boat-form/boat-form.component';
import moment from 'moment';
import { WorkSheet } from 'xlsx';
import { MovementGroupService } from 'src/app/services/movements/movement-group.service';
import { OperationalConfigService } from 'src/app/services/config/operational-config.service';
import { BoatProfile } from 'src/app/models/boat-profile';
import { CustomReportExportComponent } from 'src/app/components/extract-custom-report/custom-report-export.component';
import { saveAs } from 'file-saver';
import {MarinaImplantationService} from '../../../services/marina-implantation.service';
import {MarinaImplantation} from '../../../models/marina-implantation';
import { CustomerService } from 'src/app/services/customer.service';
import { FranchiseGroupService } from 'src/app/services/franchise-group.service';

@Component({
  selector: 'app-boat',
  templateUrl: './boat.component.html',
  styleUrls: ['./boat.component.scss']
})
export class BoatComponent implements OnInit {

  @ViewChild('tt') tt: Table;

  boatFilter = new BoatFilter();
  totalRecords = 0;
  slingConfig: SlingConfig;
  boats: any[] = [];
  selectedBoats: any[] = [];
  showInactive = true;

  vacanciesToBoat: Array<{ label: string, value: Vacancy, disabled:boolean }> = [];
  vacancies: Array<{ label: string, value: number }> = [];
  globalFilter = '';
  cols = [];
  movementGroupOptions: { label: string; value: any }[];

  isSlings: boolean;
  isMovements: boolean;
  isFranchise: boolean;
  isWorkshop: Boolean;

  shipTypeOptions = [
    { label: 'Todos', value: null },
    { label: 'Lancha', value: ShipType.Motorboat },
    { label: 'Veleiro', value: ShipType.Sailboat },
    { label: 'Bote', value: ShipType.InflatableBoat },
    { label: 'Jet ski', value: ShipType.JetSki },
    { label: 'Iate', value: ShipType.Yatch },
    { label: 'Catamarã', value: ShipType.Catamaran },
    { label: 'Escuna', value: ShipType.Schooner },
    { label: 'Traineira', value: ShipType.Trawler },
    { label: 'Caiaque', value: ShipType.Kayak },
    { label: 'Canoa', value: ShipType.Canoe },


  ];

  pt = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  };

  boatProfiles: Array<SelectItem>;
  filteredCustomers: Array<SelectItem> = [];

  movementGroupFormOptions: Array<SelectItem> = [];

  insuranceExpirationOptions = [
    { label: 'Todos', value: null },
    { label: 'Sem Seguro', value: 'NO_INSURANCE' },
    { label: 'Vencido', value: 'EXPIRED' },
    { label: 'A Vencer', value: 'TO_EXPIRE' },
    { label: 'Vigente', value: 'VALID' }
  ];

  listBoats: Boat[];
  franchiseGroupOptions: SelectItem[] = [];
  marinaImplantation: MarinaImplantation;
  constructor(
    public dialog: DialogService,
    private boatService: BoatService,
    private translate: TranslateService,
    private spinner: NgxSpinnerService,
    private messageUtil: MessageUtil,
    private route: ActivatedRoute,
    private franchiseGroupService: FranchiseGroupService,
    private slingConfigService: SlingConfigService,
    private vacancyService: VacancyService,
    private movementGroupService: MovementGroupService,
    private operationalConfigService: OperationalConfigService,
    private toasty: ToastService,
    private marinaImplantationService: MarinaImplantationService,
    private router: Router,
    private customerService: CustomerService
  ) { }

  async ngOnInit(): Promise<void> {
    if (this.router.url.includes('insuranceValidity')) {
      this.boatFilter.insuranceExpiration = this.route.snapshot.queryParams.insuranceValidity;
    }

    await Promise.all([this.getWorkshopConfig()]);
    this.getBoatProfiles();
    this.getFranchiseGroupOptions();
    this.getCustomers();
    this.boatFilter.active=true;
    this.operationalConfigService.isMovements().then((movements: boolean) => {
      this.isMovements = movements;
      this.isSlings = !movements;
    });

    this.operationalConfigService.isFranchise().then((franchise: boolean) => {
      this.isFranchise = franchise;
    });

    const promises: Promise<any>[] = [
      this.loadVacanciesBoats(),
      this.loadVacancies(),
      this.getMovementGroups(),
      this.loadSlingConfig(),
    ]

    // if(await this.operationalConfigService.isMovements()){
    //   promises.push();
    // }

    await Promise.all(promises);

    this.defineNameColumnsExport();

    const boatId = + this.route.snapshot.queryParamMap.get('boatId');
    if (boatId) {
      setTimeout(() => {
        this.openBoatFormDialog(boatId);
      }, 2000);
    }
    this.getMarinaImplantation();
  }

  getFranchiseGroupOptions(){
    this.franchiseGroupService.findAll().subscribe({
      next: (data) => {
        this.franchiseGroupOptions = data.map((group) => ({ label: group.name, value: group.id }));
      },
      error: (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    });
  }

  getCustomers(): void {
    this.customerService.getCustomerFilterList().subscribe((data) => {
      this.filteredCustomers = [{ label: 'Todos', value: null }].concat(data.map((c) => ({ label: c.name, value: c.id })));
    });
  }

  async getWorkshopConfig() {
    this.isWorkshop =
      await this.operationalConfigService.isWorkshop();
  }

  getBoatProfiles(){
    this.boatProfiles = [
      { label: 'Todos', value: null },
      { label: 'Recorrente', value: BoatProfile.Recurrent },
      { label: 'Eventual', value: BoatProfile.Eventual },

    ];
    if (this.isWorkshop){
      this.boatProfiles.push({ label: 'Oficina', value: BoatProfile.Workshop });
    }
  }

  loadVacanciesBoats(): Promise<void> {
    return new Promise(resolve => {
      if (this.isMovements){ //Entender por que tem essa diferença entre metodos. Existe algum motivo especifico?
        this.vacancyService.getAllVacanciesFree().subscribe(
          (data) => {
            this.vacanciesToBoat = data.map((v) => ({ label: v.code, value: v, disabled: false }));
            resolve()
          },
          (error) => {
            this.vacanciesToBoat = [];
            resolve()
          }
        );
      }
      else{
        this.vacancyService.getAllToBoats().subscribe({
          next: (data) => {
            this.vacanciesToBoat = data.filter((v) => v.active).map((v) => ({ label: v.code, value: v, disabled: false }));
            resolve()
          },
          error: (error) => {
            this.vacanciesToBoat = [];
            resolve()
          }
        });
      }
    })
  }

  getMovementGroups(): Promise<void> {
    return new Promise(resolve => {
      this.movementGroupService.getAll().subscribe({
        next: data => {
          this.movementGroupOptions = data.filter( group => group.active).map(group => ({label: group.name, value: group}));
          resolve();
        },
        error: error => {
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
          resolve();
        },
        complete: () => this.movementGroupFormOptions = this.movementGroupOptions.map(group => ({label: group.label, value: group.value.id}))
      }
      )
    })
  }

  async loadVacancies(): Promise<void> {
    return new Promise(resolve => {
      this.vacancyService.getAll().subscribe(
        async (data) => {
          this.vacancies = data.map((v) => ({ label: v.code, value: v.id }));
          resolve();
        },
        async (error) => {
          this.vacancies = [];
          resolve();
        }
      );
    })
  }

  async loadSlingConfig(): Promise<void> {
    return new Promise(resolve => {
      this.slingConfigService.getSlingConfig().subscribe(
        async (data) => {
          this.slingConfig = data[0];
          this.boatFilter.size = this.slingConfig != null ? this.slingConfig.numberOfRecordsPerPage : 10;
          this.find();
          resolve();
        },
        async (error) => resolve());
    })
  }

  isDocumentExpired(boat: any): boolean {
    if (!boat.tieExpiration) {
      return true;
    }
    return new Date().getTime() >= new Date(boat.tieExpiration).getTime();
  }

  openBoatFormDialog(id?: number, tabIndex?: number): void {
    this.dialog.open(BoatFormComponent, {
      height: '85vh',
      width: '80%',
      dismissableMask: false,
      data: {
        movementGroupOptions: this.movementGroupOptions,
        tabIndex,
        id
      },
      header: 'Cadastro de embarcação'
    }).onClose.subscribe(
      () => {
        this.find();
      });
  }

  openBoatDeleteConfirmDialog(id: number): void {
    let titleMessage = null;
    let message = null;
    this.translate.get('DELETE-CONFIRMATION').subscribe((result: string) => { titleMessage = result; });
    this.translate.get('DELETE-CONFIRMATION-MESSAGE').subscribe((result: string) => { message = result; });
    Swal.fire({
      title: titleMessage,
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).
      then((result) => {
        if (result.value) {
          this.boatService.delete(id).subscribe(
            () => {
              Swal.fire('Embarcação removida', 'A embarcação foi removida do sistema.', 'success');
            },
            (error) => {
              Swal.fire('Falha ao remover!', error.error.message, 'error');
            },
            () => {
              this.find();
            });
        }
      });
  }

  find(): void {
    this.spinner.show();
    this.boatService.findByMarinaIdWithPagination(this.boatFilter).subscribe({
      next: (data: any) => {
        this.totalRecords = data.totalElements;
        this.boats = data.content.map((boat) => {
          boat.shipTypeBoat = this.messageUtil.translateKey(String(boat.shipType).toUpperCase());
          boat.tieExpirationBoat = boat.tieExpiration ? moment(boat.tieExpiration).format('DD/MM/YY') : '';
          boat.reviewedBoat = boat.reviewed ? 'Sim' : 'Não';
          boat.activeBoat = boat.active ? 'Ativo' : 'Inativo';
          boat.vacancyCode = boat.vacancy?.code;
          boat.boatProfile = boat.boatProfile === 'RECURRENT' ? 'Recorrente' :
          boat.boatProfile === 'EVENTUAL' ? 'Eventual' : 'Oficina';
          return boat;
        });
      },
      error: (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      },
      complete: () => this.spinner.hide()
    }
    );
  }

  clear(): void {
    this.boatFilter = new BoatFilter();
    this.boatFilter.size = this.slingConfig != null ? this.slingConfig.numberOfRecordsPerPage : 10;
    this.find();
  }

  onChangePage(event: LazyLoadEvent): void {
    if (this.slingConfig) {
      const page = event.first / event.rows;
      this.boatFilter.sort = event.sortField;
      this.boatFilter.page = page;
      this.boatFilter.order = event.sortOrder === 1 ? 'ASC' : 'DESC';
      this.find();
    }
  }


  getOptionsVacancies(boat: any): any[] {
    let vacanciesToBoat = [];
    if (this.isMovements){
      if (boat.movementLocation){
        vacanciesToBoat=this.vacanciesToBoat;
        vacanciesToBoat= this.vacanciesToBoat.filter((b) => b.value.movementLocation.id === boat.movementLocation.id);

        if (boat.vacancy && boat.vacancy.id && !vacanciesToBoat.find((v) => v.value.id === boat.vacancy.id) && boat.vacancy.movementLocation.id===boat.movementLocation.id) {
          vacanciesToBoat.unshift({ label: boat.vacancy.code, value: boat.vacancy });
        }

        if (!boat.movementLocation.vacancyControl){
          vacanciesToBoat.unshift({ label: 'Sem vaga', value: null, disabled:false });
        }

        if ((!boat.vacancy && boat.movementLocation.vacancyControl) || (boat?.vacancy?.id===null && boat.movementLocation.vacancyControl)){
          vacanciesToBoat.unshift({ label: 'Selecione Vaga', value: null , disabled: true});
        }

        if (boat.vacancy && boat.vacancy.active===false){
          vacanciesToBoat.unshift({ label:'Vaga Inativa' , value: boat.vacancy , disabled: true});
        }


      } else {
        vacanciesToBoat.unshift({ label: 'Adicione Local', value: null, disabled: true });
      }
    } else {
      vacanciesToBoat = [...this.vacanciesToBoat];
      if (this.slingConfig && !this.slingConfig.sharedVacancy) {
        vacanciesToBoat = this.vacanciesToBoat.filter((v) => !this.boats.find((b) => b.vacancy != null && b.vacancy.id === v.value.id));
      }
      if (boat.vacancy && boat.vacancy.id &&
        !vacanciesToBoat.find((v) => v.value.id === boat.vacancy.id)) {
        vacanciesToBoat.unshift({ label: boat.vacancy.code, value: boat.vacancy });
      }
      vacanciesToBoat.unshift({ label: 'Sem vaga', value: null });
    }
    return vacanciesToBoat;
  }

  changeVacancy(boat: any): void {
    const idVacancy = boat.vacancy ? boat.vacancy.id : null;
    this.boatService.updateBoatVancancy(boat.id, idVacancy).subscribe(
      () => {
        this.loadVacanciesBoats();
        this.toasty.success('Vaga alterada com sucesso.');
      },
      (error) => {
        console.log(error);
        this.toasty.error(error.error.data.exception.message);
      }
    );
  }

  updateStatus(boat: any): void {
    Swal.fire({
      title: 'Confirmar operação?',
      text: 'Deseja realmente alterar o status da embarcação?',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      cancelButtonColor: '#d33',
      allowOutsideClick: false,
      preConfirm: () => {
        this.spinner.show();
        const newStatus = !boat.active;
        this.boatService.updateStatus(boat.id, newStatus).subscribe(
          (data) => {
            boat.active = newStatus;
            this.find();
            this.messageUtil.generateMessage('success', 'SUMMARY.SUCCESS', 'Situação atualizada com sucesso');
          },
          (error) => {
            this.spinner.hide();
            const exception = error.error.data.exception;
            Swal.fire({
              title: this.messageUtil.translateKey(exception.title),
              text: exception.message,
              icon: exception.type
            }).then(() => {
              this.openBoatFormDialog(boat.id, 2);
            });
          },
          () => this.spinner.hide()
        );
     }
    });
  }

  updateReviewed(boat: any): void {
    this.spinner.show();
    const newReviewed = !boat.reviewed;
    this.boatService.updateReviewed(boat.id, newReviewed).subscribe(
      (data) => {
        boat.reviewed = newReviewed;
        this.messageUtil.generateMessage('success', 'SUMMARY.SUCCESS', 'Revisão atualizada com sucesso');
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      },
      () => this.spinner.hide()
    );
  }

  async exportTable(): Promise<void> {
    this.boatService.findByMarinaIdWithPaginationBoat(this.boatFilter).subscribe((data) => {
      this.listBoats = data.filter((boat) => boat.active);
      const exportList = this.listBoats.map((boat: Boat) => ({
      boatName: boat.name,
      boatProfile: this.messageUtil.translateKey(boat.boatProfile),
      boatCustomers: boat.boatCustomers.filter((owner) => owner.type == "Owner").map((owner) => owner.customer.name).toString(),
      associatedOwner: boat.boatCustomers.filter((owner) => owner.type == "User").map((owner) => owner.customer.name).toString(),
      contacts: boat.contact,
      boatSailors: boat.boatSailors.map((sailor) => sailor.sailor.name.concat(' '+sailor.sailor.lastName)).toString(),
      boatShipyard: boat.shipyardModel.shipyard.name,
      boatModel: boat.shipyardModel.name,
      boatType: this.messageUtil.translateKey(boat.shipyardModel.shipType.toUpperCase()),
      beam: boat.beam,
      draft: boat.draft,
      length: boat.length,
      commercialLength: boat.commercialLength,
      manufactureYear: boat.manufactureYear,
      modelYear: boat.modelYear,
      mainColor: boat.mainColor,
      crew: boat.crew,
      passengersDay: boat.passengersDay,
      passengersNight: boat.passengersNight,
      cabins: boat.cabins,
      chassiHull: boat.chassiHull,
      bathrooms: boat.bathrooms,
      bowType: boat.bowType ? this.messageUtil.translateKey(boat.bowType.toUpperCase()) : '',
      fuel: boat.fuel.name,
      tieRegister: boat.tie?.register,
      tieExpiration: moment(boat.tie?.expiration).format('DD/MM/YYYY'),
      tieState: boat.tie?.state,
      tieCity: boat.tie?.city,
      vacancy: boat.vacancy?.code,
      tieNavArea: boat.tie?.navigationArea ? this.messageUtil.translateKey(boat.tie?.navigationArea.toUpperCase()) : '',
      operator: boat.operator?.firstName ? boat.operator?.firstName + ' ' + boat.operator?.lastName: '',
    }));
    const dataExport = {table: exportList, type: 'BOAT'};
    this.dialog.open(CustomReportExportComponent, {
      width: '70%',
      height: '90vh',
      dismissableMask: false,
      data: dataExport,
      header: this.messageUtil.translateKey('EXPORT-BOAT-TABLE')
    });
  });
}

  async exportTableExcel(): Promise<void> {
    await this.findDataExport();
    setTimeout(() => {
      this.exportExcel();
    }, 500);
  }

  async exportExcel(): Promise<void>  {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.selectedBoats);
      this.changeHeaderWorksheet(worksheet);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data']};
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });

      this.saveAsExcelFile(excelBuffer, "embarcações");
    });
  }

  changeHeaderWorksheet(worksheet: WorkSheet) {
    worksheet.A1.v = this.cols[0].header
    worksheet.B1.v = this.cols[1].header
    worksheet.C1.v = this.cols[2].header
    worksheet.D1.v = this.cols[3].header
    worksheet.E1.v = this.cols[4].header
    worksheet.F1.v = this.cols[5].header
    worksheet.G1.v = this.cols[6].header
    worksheet.H1.v = this.cols[7].header
    // worksheet.I1.v = this.cols[8].header
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
          let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          let EXCEL_EXTENSION = '.xlsx';
          const data: Blob = new Blob([buffer], {
              type: EXCEL_TYPE
          });
          saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  findDataExport(): Promise<void> {
    return new Promise(async (resolve) =>{
      this.spinner.show();
      const filter = { ...this.boatFilter };
      filter.size = 1000000;
      this.boatService.findByMarinaIdWithPagination(filter).subscribe(
        async (data: any) => {
          this.selectedBoats = data.content.map(boat => {
            return this.formatDataExport(boat);
          });
          resolve();
        },
        async (error) => {
          this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
          resolve();
        },
        () => this.spinner.hide()
      );
    })
  }

  async defineNameColumnsExport(){
    this.cols = [
      { field: 'name', header: await this.messageUtil.translateKeyAsync('NAME') },
      { field: 'shipTypeBoat', header: await this.messageUtil.translateKeyAsync('TYPE') },
      { field: 'shipyardName', header: await this.messageUtil.translateKeyAsync('SHIPYARD') },
      { field: 'shipyardModelName', header: await this.messageUtil.translateKeyAsync('MODEL') },
      // { field: 'tieRegister', header: await this.messageUtil.translateKeyAsync('REGISTER') },
      { field: 'tieExpirationBoat', header: await this.messageUtil.translateKeyAsync('EXPIRATION') },
      { field: 'vacancyCode', header: await this.messageUtil.translateKeyAsync('VACANCY') },
      { field: 'reviewedBoat', header: await this.messageUtil.translateKeyAsync('REVIEWED') },
      { field: 'activeBoat', header: await this.messageUtil.translateKeyAsync('STATUS') }
    ];
  }

  formatDataExport(boat): any{
    boat.shipTypeBoat = this.messageUtil.translateKey(String(boat.shipType).toUpperCase());
    boat.tieExpirationBoat = boat.tieExpiration ? moment(boat.tieExpiration).format('DD/MM/YY') : '';
    boat.reviewedBoat = boat.reviewed ? 'Sim' : 'Não';
    boat.activeBoat = boat.active ? 'Ativo' : 'Inativo';
    boat.vacancyCode = boat.vacancy && boat.vacancy.code;
    // boat.tieRegister = boat.tieRegister ? boat.tieRegister : ''

    return {
      name: boat.name,
      shipTypeBoat: boat.shipTypeBoat,
      shipyardName: boat.shipyardName,
      shipyardModelName: boat.shipyardModelName,
      // tieRegister: boat.tieRegister,
      tieExpirationBoat: boat.tieExpirationBoat,
      vacancyCode: boat.vacancyCode,
      reviewedBoat: boat.reviewedBoat,
      activeBoat: boat.activeBoat
    }
  }
  private getMarinaImplantation() {
    this.marinaImplantationService.getMarinaImplantationByMarinaId().subscribe({
      next: (resp) => this.marinaImplantation = resp,
      error: (err) => console.log(err)
    })
  }
}


