<div class="form-image-local">
  {{title}}
  <img [src]="imgPreview" class="local-image" />
  <div class="form-group" style="max-width: 150px; margin-top: 0;">
    <div class="form-control  " style="min-width:0px;">
      <div
        class="table-action"
        style="cursor:pointer;"
        (click)="photoCam()"
        pTooltip="Tirar Foto"
      >
        <i class="pi pi-camera"></i>
      </div>
    </div>
    <div class="form-control " style="min-width:0px; padding-left: 0;" pTooltip="Carregar Imagem">
      <p-fileUpload
        name="attachment"
        mode="basic"
        auto="true"
        maxFileSize="100000000"
        chooseLabel=" "
        accept=".png,.jpg,.jpeg"
        #upload
        [auto]="true"
        customUpload="true"
        chooseIcon="pi pi-cloud-upload"
        class="p-fileupload-mini"
        (uploadHandler)="setFile($event.files)"
      >
      </p-fileUpload>
    </div>
    <div class="form-control " style="min-width:0px;">
      <div
        style="cursor:pointer;"
        class="table-action"
        (click)="photoRemove()"
        pTooltip="Remover a foto"
      >
        <i class="pi pi-trash"></i>
      </div>
    </div>
  </div>
</div>
