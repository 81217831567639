import {Token} from './../../models/token';
import {Component, OnInit} from '@angular/core';
import {MenuItem} from 'src/app/models/menu-item';
import {BroadcastService} from 'src/app/services/broadcast.service';
import {MessageUtil} from 'src/app/utils/message.util';
import {MyAccountService} from 'src/app/services/my-account.service';
import moment from 'moment';
import {StorageUtil} from 'src/app/utils/storage.util';
import {SlingConfig, User} from 'src/app/models';
import {SlingConfigService} from 'src/app/services/sling-config.service';
import {UserService} from 'src/app/services/user.service';
import {EmailAccountService} from 'src/app/services/email-account.service';
import {SlingService} from 'src/app/services/sling.service';
import {environment} from '../../../environments/environment';
import {MenuItemService} from 'src/app/services/menu-item.service';
import {CustomerSmsLogService} from 'src/app/services/customer-sms-log.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  showSideNav = false;
  env: any;
  menu: Array<MenuItem>;
  userLogged: User;
  token: Token;
  userToken: string;
  marinaLogged: any;
  slingConfig: SlingConfig;
  menuItens: MenuItem[];
  numberOfMessages = 0;
  numberOfUserSms = 0;
  userSmsMessageTooltipValue = 'Clientes que receberam o sms e não cadastraram no app';
  numberOfSlingDifferentSlip = 0;
  infoUserCustomerBillingEmail = 'E-mail não cadastrado';
  lastIdx = 67;
  days: string;
  daysMessage: string = '';
  messaged = false;
  flattenedMenu: MenuItem[] = [];

  constructor(
    public messageUtil: MessageUtil,
    public emailAcoount: EmailAccountService,
    public slingService: SlingService,
    private slingConfigService: SlingConfigService,
    private userService: UserService,
    private myAccountService: MyAccountService,
    public menuItemService: MenuItemService,
    private customerSmsLogService: CustomerSmsLogService
  ) {
    this.env = environment;
  }

  logFunction(context: any): void {
    const menuItensArr: MenuItem[] = context.menuItens;
    const menuItem = menuItensArr.find(item => item.callFunction === 'logFunction');
    menuItem.value = '';
    const containUserSmsPermission = menuItem.children.find(menu => menu.label === 'USER-SMS') !== undefined;
    if (containUserSmsPermission && this.numberOfUserSms > 0) {
      menuItem.value = menuItem.value.concat('<i class="pi pi-circle-on"></i>');
    }
  }

  setNumberOfUserSms(context): void {
    this.customerSmsLogService.getCountCustomerSmsLogWithoutUser().subscribe(
      (value: number) => {
        context.numberOfUserSms = value;
        context.logFunction(context);
      },
      (error) => {
        const exception = error.error.data.exception;
        context.messageService.generateMessage(exception.type, exception.title, exception.message);
        context.numberOfUserSms = 0;
      });
  }

  async ngOnInit(): Promise<void> {
    BroadcastService.get('showHideSidenav').subscribe((event) => {
      this.showSideNav = event;
    });
    BroadcastService.get('messageDismissed').subscribe(() => {
      if (this.daysMessage && !this.messaged) {
        this.messaged = true;
        this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', this.daysMessage);
      }
    });
    this.getLatestPayment();
    this.token = StorageUtil.getDecodeToken();
    BroadcastService.get('changeMarina').subscribe(
      async () => {
        this.menuItens = [];
        this.getNumberOfMessages(this);
        this.setNumberOfUserSms(this);
        this.getMenuScrutrure();
      }
    );
    BroadcastService.get('changeAnticipation').subscribe(
      async (anticipation: boolean) => {
        this.slingConfig.anticipation = anticipation;
        this.getMenuScrutrure();
      }
    );
    BroadcastService.get('changeHasContracts').subscribe(
      async (hasContracts: boolean) => {
        this.slingConfig.hasContracts = hasContracts;
        this.getMenuScrutrure();
      }
    );
    this.marinaLogged = StorageUtil.getMarina();
    this.menuItens = await this.getMenuList();
    this.slingConfig = await this.findSlingConfig();
    this.setNumberOfUserSms(this);
  }

  getLatestPayment(): void {
    this.myAccountService.getLatestPayment().subscribe({
        next: (vencimento) => {
          if (vencimento) {
            const days = moment().diff(moment(vencimento, 'YYYY-MM-DD'), 'days', false);
            if (days > 5) {
              this.days = days.toFixed(0);
              this.daysMessage = 'Fatura(s) vencida(s) há ' + this.days + ' dia(s).';
              StorageUtil.setLastPayment(moment(vencimento).format('YYYY-MM-DD'));
            }
          }
        },
        error: err => console.log(err)
      }
    );
  }

  getMenuScrutrure(): void {
    this.menuItemService.getAllStructured().subscribe(
      {
        next: (menuList) => this.menuItens = menuList
        ,
        error: () => null
      });
  }

  async getMenuList(): Promise<MenuItem[]> {
    return new Promise<MenuItem[]>((res) => {
      this.menuItemService.getAllStructured().subscribe(
        async (menuList) => {
          res(menuList);
        },
        async () => {
          res(null);
        }
      );
    });
  }


  getNumberOfMessages(context): void {
    context.emailAcoount.getnumberOfMessages().subscribe(
      (data) => {
        if (data !== null) {
          context.numberOfMessages = data.numberOfMessages;
          context.infoUserCustomerBillingEmail = 'E-mail: '
            .concat(data.customerBillingEmail)
            .concat('\n')
            .concat('Senha: ')
            .concat(data.passwordEmailCustomerBilling);
        } else {
          context.numberOfMessages = 'Erro';
        }
      },
      (error) => {
        const exception = error.error.data.exception;
        context.messageService.generateMessage(exception.type, exception.title, exception.message);
        context.numberOfMessages = 'Erro';
      });
  }

  getNumberOfSlingPending(context: any): void {
    context.slingService.getNumberOfSlingDifferentSlip().subscribe(
      (data) => {
        if (data !== null) {
          context.numberOfSlingDifferentSlip = data.numberOfSlingDifferentSlip;
        }
      },
      (error) => {
        const exception = error.error.data.exception;
        context.messageService.generateMessage(exception.type, exception.title, exception.message);
      });
  }

  openEmailBilling(): void {
    window.open('https://mail.google.com/mail/u/0/#inbox', '_blank');
  }

  async findSlingConfig(): Promise<SlingConfig> {
    return new Promise<SlingConfig>((res) => {
      this.slingConfigService.getSlingConfigToday().subscribe(
        async (slingConfig) => {
          res(slingConfig);
        },
        async () => {
          res(null);
        }
      );
    });
  }

  async configureAnticipation(reload?: boolean): Promise<void> {
    return new Promise<void>(
      async (res) => {
        if (this.slingConfig && this.slingConfig.anticipation) {
          const copy = [...this.menu];
          this.menuItens = [];
          const finances = copy.filter((x) => x.label === 'BILLING');
          const idx = copy.indexOf(finances[0]);
          copy[idx].children.push({
            id: 63,
            label: 'Antecipação',
            routerLink: '#/app/anticipation',
            children: [
              {id: 64, label: 'Antecipar', routerLink: '/app/billing/anticipation/anticipate'},
              {id: 65, label: 'Resumo', routerLink: '/app/billing/anticipation/summary'},
              {id: 66, label: 'Gestão', routerLink: '/app/billing/anticipation/management'}
            ]
          });
          if (reload) {
            //StorageUtil.setMenu(copy);
            setTimeout(async () => {
              this.menu = [...copy];
              this.menuItens = [...copy];
            }, 300);
          }
        }
        res();
      }
    );
  }

  async findUserById(): Promise<User> {
    return new Promise<User>(
      async (res) => {
        this.userService.getById(this.token.userId).subscribe(
          async (user) => {
            res(user);
          },
          async (err) => {
            res(null);
          }
        );
      });
  }

  async updateUser(user: User): Promise<User> {
    return new Promise<any>(
      async (res) => {
        if (user) {
          localStorage.setItem('currentUser', JSON.stringify(user));
          res(user);
        } else {
          localStorage.clear();
          res(null);
        }
      }
    );
  }

  getValue(context, value): any {
    if (context[value]) {
      return context[value];
    }
    return null;
  }

  getFunction(context, value): any {
    const valor = value;
    if (context[valor]) {
      this[valor](context);
    }
  }

  openCloseMenus(option: MenuItem): void {
    if (!option.showChildren) {
      this.menuItens.filter((o) => o.showChildren && !o.children.find((opt) => opt.id === option.id))
        .forEach((o) => {
          o.showChildren = false;
          o.close = true;
          setTimeout(() => {
            o.close = false;
          }, 1000);
        });
    } else {
      option.close = true;
      setTimeout(() => {
        option.close = false;
      }, 1000);
    }
    option.showChildren = !option.showChildren;
  }

  getOptionTooltip(option: MenuItem): string {
    if (option?.tooltip == 'this.daysMessage') {
      return this.daysMessage;
    }
    if (option?.tooltip) {
      return option.tooltip;
    }
    if (option?.tooltipValue) {
      return option.tooltipValue;
    }
    return '';
  }

  getChildTooltip(context: SidenavComponent, child: MenuItem): string {
    return child.tooltip ? child.tooltip : this.getValue(context, child.tooltipValue);
  }

  isSelected(option: MenuItem): boolean {
    return option.showChildren;
  }

  isRoute(option: MenuItem): boolean {
    return window.location.pathname.toUpperCase().includes(option.routerLink?.toUpperCase());

  }

  isFontsAwesome(option: MenuItem): boolean {
    return option.icon?.includes('fa');
  }

  getFaIcon(icon: String): string {
    return icon.replace('fa ', '');
  }
}
