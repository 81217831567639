import { Component } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { AccessGroupEnum } from 'src/app/models/dtos/access-controller/access-group-enum';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { ServiceProviderFormsContainerComponent } from '../service-provider-forms-container/service-provider-forms-container.component';

@Component({
  selector: 'app-guest-center',
  templateUrl: './guest-center.component.html',
  styleUrls: ['./guest-center.component.scss'],
})
export class GuestCenterComponent {
  // constructor(public dialog: DialogService) {} need it dynamic
  constructor(public dialog: DialogService) {}

  newVisitorAndPermission(): void {
    this.dialog
      .open(ServiceProviderFormsContainerComponent, {
        header: 'Cadastro de Convidado e Permissão de Acesso',
        data: {
          visitorType: AccessGroupEnum.GUEST,
        },
      })
      .onClose.subscribe((status) => {
        if (status) {
          this.reloadTables();
        }
      });
  }

  reloadTables() {
    BroadcastService.get('reloadTables').emit();
  }
}
