import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { AccessGroup } from '../../../../../models/dtos/access-controller/access-group';
import { AccessGroupService } from '../../../../../services/access-controler/access-group.service';
import { ToastService } from '../../../../../services/toast.service';
import { MessageUtil } from '../../../../../utils/message.util';
import { BatchAccessGroupTransferDTO } from '../../../../../models/dtos/access-controller/batchAccessGroupTransferDTO';
import { AccessGroupEnum } from '../../../../../models/dtos/access-controller/access-group-enum';

@Component({
  selector: 'app-access-categories-batch',
  templateUrl: './access-categories-batch.component.html',
  styleUrls: ['./access-categories-batch.component.scss'],
})
export class AccessCategoriesBatchComponent implements OnInit {
  categoriesTotalList: AccessGroup[] = [];
  targetCategoriesList: AccessGroup[] = [];
  sourceCategoriesList: AccessGroup[] = [];
  targetCategorySelected: AccessGroup;
  sourceCategorySelected: AccessGroup;
  sourceAccessUserObjList: GenericObjc[];
  targetAccessUserObjList: GenericObjc[];
  showCategoriesLists = true;
  isReady: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private accessGroupService: AccessGroupService,
    private toasty: ToastService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.findAllAccessCategories();
  }

  findAllAccessCategories() {
    this.spinner.show();
    this.accessGroupService.findAll().subscribe({
      next: (categories) => {
        this.categoriesTotalList = categories;
        this.sourceCategoriesList = this.categoriesTotalList;
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
        this.toasty.error(error.message ?? 'Erro ao iniciar a tela');
      },
      complete: () => {
        this.getSelectedCategoryInitialValue();
      },
    });
  }

  private getSelectedCategoryInitialValue() {
    const cattegoryID = this.route.snapshot.paramMap.get('id');
    const index = this.sourceCategoriesList.findIndex(
      (item) => item.id === Number(cattegoryID)
    );
    this.sourceCategorySelected = { ...this.sourceCategoriesList[index] };
    this.isReady = true;
    this.findSourceAccessGenericUserList();
  }

  save() {
    Swal.fire({
      title: 'Confirmação',
      text: `Tem certeza que deseja transferir os usuarios selecionados para a categoria de acesso ${this.targetCategorySelected.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.spinner.show().then(() => {
          const sourcePersonsIds: number[] = this.sourceAccessUserObjList.map(
            (item) => item.id
          );
          const targetPersonsIDs: number[] = this.targetAccessUserObjList.map(
            (item) => item.id
          );
          const batchBody = new BatchAccessGroupTransferDTO(
            this.sourceCategorySelected.id,
            sourcePersonsIds,
            this.targetCategorySelected.id,
            targetPersonsIDs
          );
          this.accessGroupService
            .batchTransferAccessCategoryOfPersons(batchBody)
            .subscribe({
              next: () => {
                this.spinner.hide().then(() => {
                  this.toasty.success('Transferencia realizada com sucesso!');
                  this.router.navigate([
                    'app/forms/access-controller/access-group',
                  ]);
                });
              },
              error: (error) => {
                this.spinner.hide().then(() => {
                  console.log(error);
                  this.toasty.error(error.error.message);
                });
              },
            });
        });
      }
    });
  }

  findSourceAccessGenericUserList() {
    if (this.sourceCategorySelected == null) {
      return;
    }
    this.sourceAccessUserObjList = [];
    if (this.sourceCategorySelected.type === AccessGroupEnum.SAILOR) {
      this.targetCategoriesList = this.categoriesTotalList.filter(
        (item) =>
          item.id !== this.sourceCategorySelected.id &&
          item.type === AccessGroupEnum.SAILOR
      );
      this.findSailorListByAccessCategory(
        this.sourceCategorySelected
      ).subscribe({
        next: (value) => (this.sourceAccessUserObjList = value),
        error: (error) => console.log(error),
      });
    } else {
      this.targetCategoriesList = this.categoriesTotalList.filter(
        (item) =>
          item.id !== this.sourceCategorySelected.id &&
          item.type !== AccessGroupEnum.SAILOR
      );

      this.findCustomerListByAccessCategory(
        this.sourceCategorySelected
      ).subscribe({
        next: (value) => (this.sourceAccessUserObjList = value),
        error: (error) => console.log(error),
      });
    }
  }

  findTargetAccessGenericUserList() {
    if (this.targetCategorySelected == null) {
      return;
    }
    this.targetAccessUserObjList = [];
    if (this.targetCategorySelected.type === AccessGroupEnum.SAILOR) {
      this.findSailorListByAccessCategory(
        this.targetCategorySelected
      ).subscribe({
        next: (value) => (this.targetAccessUserObjList = value),
        error: (error) => console.log(error),
      });
    } else {
      this.findCustomerListByAccessCategory(
        this.targetCategorySelected
      ).subscribe({
        next: (value) => (this.targetAccessUserObjList = value),
        error: (error) => console.log(error),
      });
    }
  }

  findCustomerListByAccessCategory(
    accessCategorySelected
  ): Observable<GenericObjc[]> {
    return new Observable<GenericObjc[]>((observer) => {
      this.accessGroupService
        .findCustomersByAccessCategoryID(accessCategorySelected.id)
        .subscribe({
          next: (value) => {
            observer.next(
              value.map(
                (item) =>
                  ({
                    id: item.id,
                    name: item.name,
                    type: accessCategorySelected.type,
                  } as GenericObjc)
              )
            );
          },
          error: (err) => observer.error(err),
        });
    });
  }

  findSailorListByAccessCategory(accessCategorySelected) {
    return new Observable<GenericObjc[]>((observer) => {
      this.accessGroupService
        .findSailorsByAccessCategoryID(accessCategorySelected.id)
        .subscribe({
          next: (value) => {
            observer.next(
              value.map(
                (item) =>
                  ({
                    id: item.id,
                    name: item.sailorName,
                    type: accessCategorySelected.type,
                  } as GenericObjc)
              )
            );
          },
          error: (err) => observer.error(err),
        });
    });
  }

  invertShowCategoriesList() {
    this.showCategoriesLists = !this.showCategoriesLists;
  }
}

export class GenericObjc {
  id: number;
  name: string;
  type: AccessGroupEnum;
}
