<form *ngIf="form" [formGroup]="form" (submit)="save()" class="dialog-form">
  <div class="form-group">
    <div class="form-control">
      <label for="date">{{ 'Data' | translate }}</label>
      <input pInputText type="date" id="date" name="date" formControlName="date">
    </div>
    <div class="form-control">
      <label for="observation">{{ 'Observação' | translate }}</label>
      <input pInputText type="text" id="observation" name="observation" formControlName="observation">
    </div>
  </div>
  <div class="buttons">
    <button class="danger easymarine" type="button" (click)="close()" >{{'CANCEL' | translate}}</button>
    <button class="primary easymarine" type="submit" [disabled]="!form.valid">{{'SAVE' | translate}}</button>
  </div>
</form>
