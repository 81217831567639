import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ReactiveFormsModule, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicDialogRef, DialogService } from 'primeng/dynamicdialog';
import { Address } from 'src/app/models/address';
import { LocationService } from 'src/app/services/location.service';
import { Countries } from 'src/app/utils/location/countries';
import { MessageUtil } from 'src/app/utils/message.util';
import {InputMaskModule} from "primeng/inputmask";
import {DropdownModule} from "primeng/dropdown";
import {TranslateModule} from "@ngx-translate/core";
import {AutoCompleteModule} from "primeng/autocomplete";
import {InputTextModule} from "primeng/inputtext";

@Component({
  standalone: true,
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  imports: [
    ReactiveFormsModule,
    InputMaskModule,
    DropdownModule,
    TranslateModule,
    AutoCompleteModule,
    InputTextModule
  ]
})
export class AddressComponent implements OnInit, OnChanges {
  @Input()
  address: Address;

  @Output() finishEvent: EventEmitter<any> = new EventEmitter();

  addressForm = new UntypedFormGroup({});
  errorMessage: string;
  countries: { label: string; value: any }[];
  groups: { label: string; value: any }[] = new Array();
  states: { label: string; value: any }[];
  cities: string[];
  filteredCities: any;

  constructor(
    public dialogRef: DynamicDialogRef,
    private locationService: LocationService,
    private messageUtil: MessageUtil,
    private spinner: NgxSpinnerService
  ) {}

  enviar() {
    console.log("Chamou")
    this.finishEvent.emit({ addressForm: this.addressForm });
  }

  ngOnInit(): void {
    this.loadCountries();
    this.loadStates('BR');
    this.startForm(this.address);
    this.addressForm.valueChanges.subscribe((change) => this.enviar());
  }

  startForm(address?: Address): void {
    if (address.zip == null) {
      address = new Address();
    }

    this.addressForm = new UntypedFormGroup({
      id: new UntypedFormControl(address.id),
      zip: new UntypedFormControl(address.zip),
      country: new UntypedFormControl(address.country),
      state: new UntypedFormControl(address.state),
      city: new UntypedFormControl(address.city),
      district: new UntypedFormControl(address.district),
      street: new UntypedFormControl(address.street),
      number: new UntypedFormControl(address.number),
      complement: new UntypedFormControl(address.complement),
    });
    if (this.address?.id) {
      this.addressForm.get('id').setValue(this.address.id);
    }
  }

  getCep(): void {
    let address: Address = new Address();
    let zipCode = '';
    zipCode = this.addressForm.get('zip').value;
    address = this.addressForm.value;

    if (zipCode != null && zipCode !== '') {
      address.zip = zipCode.replace(/\D+/g, '');
    }
    this.spinner.show();

    this.locationService.getCep(address.zip).subscribe(
      (data) => {
        this.spinner.hide();
        if (data !== null) {
          this.resetAddressFields(address);
          const cep = data;
          address.zip = zipCode;
          address.country = 'BR';
          this.loadStates(address.country);
          address.state = cep.uf;
          address.city = this.firstLetterToUpperCase(cep.cidade);
          address.district = this.firstLetterToUpperCase(cep.bairro);
          address.street = this.firstLetterToUpperCase(cep.logradouro);
          //this.address=address;
          this.addressForm.setValue(address);
        } else {
          this.messageUtil.generateMessage(
            'warning',
            'SUMMARY.WARNING',
            'MSG.CEP-INVALID'
          );
          address.zip = null;
        }
      },
      (error) => {
        this.spinner.hide();
        this.resetAddressFields(address);
        this.errorMessage = error;
      }
    );
  }

  resetAddressFields(address: Address): void {
    address.zip = null;
    address.country = null;
    address.state = null;
    address.city = null;
    address.district = null;
    address.street = null;
    address.number = null;
    address.complement = null;
  }

  private firstLetterToUpperCase(text: string): string {
    const words = text.toLowerCase().split(' ');
    for (let a = 0; a < words.length; a++) {
      const w = words[a];
      if (w[0] !== undefined) {
        words[a] = w[0].toUpperCase() + w.slice(1);
      }
    }
    return words.join(' ');
  }

  loadCountries(): void {
    this.countries = [{ label: 'Selecione...', value: null }].concat(
      Countries.countryList.map((c) => ({ label: c.name, value: c.code }))
    );
  }

  loadStates(countryId): void {
    this.states = [{ label: 'Selecione...', value: null }].concat(
      this.locationService
        .getStates()
        .map((s) => ({ label: s.name, value: s.id }))
    );
  }

  loadCities(countryId: string, stateId: string): void {
    this.locationService.getCities(countryId, stateId).subscribe(
      (data) => {
        this.cities = data.map((c) => c.name);
      },
      (error) => {
        this.cities = null;
        this.errorMessage = error;
      }
    );
  }
  filterCities(val: any): void {
    this.filteredCities = val
      ? this.cities.filter((c) =>
          c.toUpperCase().includes(val.query.toUpperCase())
        )
      : this.cities;
  }

  ngOnChanges(): void {
    if(this.address.id !== null){
      this.startForm(this.address);
    }
  }
}
