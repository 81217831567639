import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Notice } from '../models/notice';
import { NotificationGroup } from '../models/notification/notificationGroup';
import { PaginationFilter } from '../models/pagination-filter';
import { StorageUtil } from '../utils/storage.util';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {



constructor(
  private http: HttpClient
) { }

getByUserfromGroup(paginationFilter: PaginationFilter): Observable<NotificationGroup[]> {
  let params = new HttpParams();

  let sort;
  if (paginationFilter.sort) {
      sort = paginationFilter.sort;
  }
  if (paginationFilter.order) {
      sort = (sort ? sort + ',' : '') + paginationFilter.order;
  }
  if (sort) {
      params = params.set('sort', sort);
  }
  params = params.set('page', paginationFilter.page.toString());
  params = params.set('size', paginationFilter.size.toString());
  return this.http.get(environment.apiHost + `/secure/notification/notificationbygroup`, { params })
      .pipe(map((response: any) => response.data.noticesByUser as NotificationGroup[]));
}

updateNotificationGroup(group: String, date:String): Observable<any> {
  return this.http.put(environment.apiHost + `/secure/notification/readnotificationgroup/`+group+`/`+date, null);
}

}
