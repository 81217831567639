import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { CustomerSmsLogDTO } from '../models/dtos/customer-sms-log-dto';

@Injectable({
  providedIn: 'root'
})
export class CustomerSmsLogService {

  constructor(
    public http: HttpClient,
  ) { }

  getCountCustomerSmsLogWithoutUser(): Observable<number> {
    return this.http.get(`${environment.apiHost}/secure/customers/sms-log/number-without-user`)
      .pipe(
        map((response: any) => response.data.number as number),
      );
  }

  getCustomersSmsLog(): Observable<CustomerSmsLogDTO[]> {
    return this.http.get(`${environment.apiHost}/secure/customers/sms-log`)
      .pipe(
        map((response: any) => response.data.customers as CustomerSmsLogDTO[])
      );
  }
}
