import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-boat-log-operator',
  templateUrl: './boat-log-operator.component.html',
  styleUrls: ['./boat-log-operator.component.scss']
})
export class BoatLogOperatorComponent implements OnInit {

  @Input() boatLog;
  @Input() isNew;

  constructor() { }

  ngOnInit(): void {
  }

  getName(): string{
    if(this.isNew){
      return this.boatLog.operator? this.boatLog.operator.fullName: '';
    }
    return this.boatLog.oldDatas.operator? this.boatLog.oldDatas.operator.fullName: '';
  }

}
