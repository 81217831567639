import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { SlingConfig } from 'src/app/models';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';
import Swal from 'sweetalert2';
import { SlingConfigFormComponent } from './sling-config-form/sling-config-form.component';

@Component({
  selector: 'app-sling-config',
  templateUrl: './sling-config.component.html',
  styleUrls: ['./sling-config.component.scss']
})
export class SlingConfigComponent implements OnInit {

  private datePipe: DatePipe;
  configs: any[] = new Array();

  constructor(
    private slingConfigService: SlingConfigService,
    private messageUtil: MessageUtil,
    private dialog: DialogService,
    private spinner: NgxSpinnerService
  ) {

    this.datePipe = new DatePipe('pt-BR');
  }

  ngOnInit(): void {
    this.findSlingsConfiguration();
  }

  async slingConfigDialog(config?): Promise<void> {
    this.dialog.open(SlingConfigFormComponent, {
      width: '80%',
      dismissableMask: false,
      height: '80vh',
      data: {
        slingConfig: config ? config : new SlingConfig(),
        marinaCompanies: StorageUtil.getMarina().marinaCompanies
      },
      header: 'Configuração geral'
    }).onClose.subscribe(
      () => {
        this.findSlingsConfiguration();
      }
    );
  }

  findSlingsConfiguration(): void {
    this.spinner.show();
    this.slingConfigService.getSlingConfig().subscribe(
      (data) => {
        this.configs = data;
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      },
      () => this.spinner.hide()
    );
  }

}
