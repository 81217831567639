<p-fieldset>
    <p-header class="article-title">
      <div class="article-title">{{ "HULL" | translate }}</div>
    </p-header>
    <div class="content-log">
      <div *ngIf="shipyardModel">
        <label>{{ "SHIPYARD" | translate }}</label>
        <label [ngClass]="{'item-new-log': isChangedShipyard() && isNew, 'item-old-log': isChangedShipyard() && !isNew}">
          {{ isChangedShipyard() && !isNew ? shipyardModelOld.shipyard.name : shipyardModel.shipyard.name }}
        </label>
      </div>
      <div *ngIf="shipyardModel">
        <label>{{ "MODEL" | translate }}</label>
        <label [ngClass]="{'item-new-log': isChangedShipyardModel() && isNew, 'item-old-log': isChangedShipyardModel() && !isNew}">
          {{ isChangedShipyardModel() && !isNew ? shipyardModelOld.name : shipyardModel.name }}
        </label>
      </div>
      <div *ngIf="shipyardModel">
        <label>{{ "TYPE" | translate }}</label>
        <label [ngClass]="{'item-new-log': isChangedShipyardType() && isNew, 'item-old-log': isChangedShipyardType() && !isNew}">
          {{ isChangedShipyardType() && !isNew ? shipyardModelOld.shipType : shipyardModel.shipType | uppercase | translate}}
        </label>
      </div>
      <div>
        <label>{{ "SHIP-ATT.BEAM" | translate }}</label>
        <app-log-field [old]="boatLog.oldDatas.beam" [field]="boatLog.beam" [existsOld]="oldDatasMap.has('beam')" [isNew]="isNew"></app-log-field>
      </div>
      <div>
        <label>{{ "SHIP-ATT.DRAFT" | translate }}</label>
        <app-log-field [old]="boatLog.oldDatas.draft" [field]="boatLog.draft" [existsOld]="oldDatasMap.has('draft')" [isNew]="isNew"></app-log-field>
      </div>
      <div>
        <label>{{ "SHIP-ATT.LENGTH" | translate }}</label>
        <app-log-field [old]="boatLog.oldDatas.length" [field]="boatLog.length" [existsOld]="oldDatasMap.has('length')" [isNew]="isNew"></app-log-field>
      </div>
      <div>
        <label>{{ "SHIP-ATT.COMMERCIAL-LENGTH" | translate }}</label>
        <app-log-field [old]="boatLog.oldDatas.commercialLength" [field]="boatLog.commercialLength" [existsOld]="oldDatasMap.has('commercialLength')" [isNew]="isNew"></app-log-field>
      </div>
      <div>
        <label>{{ "SHIP-ATT.MANUFACTURE-YEAR" | translate }}</label>
        <app-log-field [old]="boatLog.oldDatas.manufactureYear" [field]="boatLog.manufactureYear" [existsOld]="oldDatasMap.has('manufactureYear')" [isNew]="isNew"></app-log-field>
      </div>
      <div>
        <label>{{ "SHIP-ATT.MODEL-YEAR" | translate }}</label>
        <app-log-field [old]="boatLog.oldDatas.modelYear" [field]="boatLog.modelYear" [existsOld]="oldDatasMap.has('modelYear')" [isNew]="isNew"></app-log-field>
      </div>
      <div>
        <label>{{ "SHIP-ATT.MAIN-COLOR" | translate }}</label>
        <app-log-field [old]="boatLog.oldDatas.mainColor" [field]="boatLog.mainColor" [existsOld]="oldDatasMap.has('mainColor')" [isNew]="isNew"></app-log-field>
      </div>
      <div>
        <label>{{ "Nº Tripulantes" | translate }}</label>
        <app-log-field [old]="boatLog.oldDatas.crew" [field]="boatLog.crew" [existsOld]="oldDatasMap.has('crew')" [isNew]="isNew"></app-log-field>
      </div>
      <div>
        <label>{{ "Nº Passageiros" | translate }}</label>
        <app-log-field [old]="boatLog.oldDatas.passengersDay" [field]="boatLog.passengersDay" [existsOld]="oldDatasMap.has('passengersDay')" [isNew]="isNew"></app-log-field>
      </div>
      <div>
        <label>{{ "SHIP-ATT.PASSENGERS-NIGHT" | translate }}</label>
        <app-log-field [old]="boatLog.oldDatas.passengersNight" [field]="boatLog.passengersNight" [existsOld]="oldDatasMap.has('passengersNight')" [isNew]="isNew"></app-log-field>
      </div>
      <div>
        <label>{{ "SHIP-ATT.CABINS" | translate }}</label>
        <app-log-field [old]="boatLog.oldDatas.cabins" [field]="boatLog.cabins" [existsOld]="oldDatasMap.has('cabins')" [isNew]="isNew"></app-log-field>
      </div>
      <div>
        <label>{{ "CHASSIS" | translate }}</label>
        <app-log-field [old]="boatLog.oldDatas.chassiHull" [field]="boatLog.chassiHull" [existsOld]="oldDatasMap.has('chassiHull')" [isNew]="isNew"></app-log-field>
      </div>
      <div>
        <label>{{ "SHIP-ATT.BATHROOMS" | translate }}</label>
        <app-log-field [old]="boatLog.oldDatas.bathrooms" [field]="boatLog.bathrooms" [existsOld]="oldDatasMap.has('bathrooms')" [isNew]="isNew"></app-log-field>
      </div>
      <div>
        <label>{{ "SHIP-ATT.BOW-TYPE" | translate }}</label>
        <app-log-field [translate]="true" [old]="boatLog.oldDatas.bowType" [field]="boatLog.bowType" [existsOld]="oldDatasMap.has('bowType')" [isNew]="isNew"></app-log-field>
      </div>
      <div>
        <label>{{ "FUEL" | translate }}</label>
        <app-log-field [old]="boatLog.oldDatas.fuel?.name" [field]="boatLog.fuel.name" [existsOld]="oldDatasMap.has('fuel')" [isNew]="isNew"></app-log-field>
      </div>
      <div>
        <label>{{ "SHIP-ATT.FLY-BRIDGE" | translate }}</label>
        <label [ngClass]="{'item-new-log': isChangedFlyBridge() && isNew, 'item-old-log': isChangedFlyBridge() && !isNew}">
          {{ (getFlyBridge()? 'YES':'NO') | translate}}
        </label>
      </div>
      <div>
        <label>{{ "Motor dois tempos" | translate }}</label>
        <label [ngClass]="{'item-new-log': isChangedTwoTimeEngine() && isNew, 'item-old-log': isChangedTwoTimeEngine() && !isNew}">
          {{ (getTwoTimeEngine()? 'YES':'NO') | translate}}
        </label>
      </div>
    </div>
  </p-fieldset>
  <br />