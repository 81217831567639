import { SignerType } from "./enums/signerType";
import { Marina } from "./marina";

export class DocumentDefaultSigner {
    id: number;
    signerType: SignerType;
    email: string;
    name: string;
    federalId: string;
    birthday: Date;
    Marina: Marina;
}