import { Component, Input, OnInit } from '@angular/core';
import { ContractUtil } from 'src/app/utils/contract.util';
import { DocumentStatus } from 'src/app/models/enums/document-status';
import { DocumentContractView } from 'src/app/models/dtos/document-contract-view';

@Component({
  selector: 'app-table-verify-contracts',
  templateUrl: './table-verify-contracts.component.html',
  styleUrls: ['./table-verify-contracts.component.scss']
})
export class TableVerifyContractsComponent implements OnInit {

  @Input() documents: DocumentContractView[];

  constructor(
    private contractUtil: ContractUtil
  ) { }

  ngOnInit(): void {
  }

  getEmails(document: DocumentContractView): string {
    return this.contractUtil.getEmails(document.emails);
  }


  downloadDocument(document: DocumentContractView): void {
    this.contractUtil.downloadDocument(document.id, document.url);
  }

  cancelDocument(document: DocumentContractView): void {
    this.contractUtil.cancelDocument(document.id, document.nameDocument, document.boatName).then((data) => {
      if (data) {
        document.status = DocumentStatus.CANCELED;
      }
    });
  }

  updateAffected(document: DocumentContractView): void {
    this.contractUtil.updateAffected(document.id, !document.affected).then((data) => {
      if (data) {
        document.affected = !document.affected;
      }
    });
  }
}
