export class BoatWash {
    id: number;
    boatId: number;
    boatName: string;
    boatModel: string;
    comercialLenght: string;
    userId: number;
    userName: string;
    washDate: any;
    isActive: boolean;
    isSea: boolean;

    constructor() {
        this.washDate = new Date();
    }
}
