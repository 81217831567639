import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Fuel } from 'src/app/models/fuel';
import { map } from 'rxjs/operators';


@Injectable()
export class FuelService {

    constructor(private http: HttpClient) {}

    findAll(): Observable<Fuel[]> {
        return this.http.get(environment.apiHost + '/secure/fuels/').pipe(map((response: any) => response.data.fuels as Fuel[]));
    }

}
