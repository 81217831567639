import { Component, Input, OnInit } from '@angular/core';
import { FinancesService } from 'src/app/services/finances.service';

@Component({
  selector: 'app-product-log-general-data',
  templateUrl: './product-log-general-data.component.html',
  styleUrls: ['./product-log-general-data.component.scss']
})
export class ProductLogGeneralDataComponent implements OnInit {

  @Input() productLog;
  @Input() isNew;

  oldDatasMap;
  listaPlanoContasFlat = [];

  constructor(
    private financesService: FinancesService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.getPlanoContasReceita();
    this.oldDatasMap = new Map(Object.entries(this.productLog.oldDatas));
  }

  isFuel(): boolean{
    return this.productLog.oldDatas.productCategory?.id == 3 || this.productLog.productCategory?.id == 3;
  }

  isTypeProduct(): boolean{
    return this.productLog.oldDatas.productType == 'Product' || this.productLog.productType == 'Product';
  }

  getAccountName(id): string{
    let name = '';
    if(id){
      const account = this.listaPlanoContasFlat.find(account => account.idPlanoConta == id);

      if(account){
        name = account.treeLabel;
      } else {
        name = id +' - plano inexistente';
      }
    }
    return name;
  }

  async getPlanoContasReceita(): Promise<void> {
    this.listaPlanoContasFlat = await this.financesService.findPlanoContaReceitaFlat();
  }


  isChangedShowApp() {
    return this.oldDatasMap.has('showApp');
  }

  getShowApp() {
    let showApp = this.productLog.showApp;
    if(this.isChangedShowApp() && !this.isNew) {
      showApp = this.productLog.oldDatas.showApp;
    }
    return showApp;
  }

  isChangedIssueReceipt() {
    return this.oldDatasMap.has('issueReceipt');
  }

  getIssueReceipt() {
    let issueReceipt = this.productLog.issueReceipt;
    if(this.isChangedIssueReceipt() && !this.isNew) {
      issueReceipt = this.productLog.oldDatas.issueReceipt;
    }
    return issueReceipt;
  }

  isChangedIssueNotaFiscal() {
    return this.oldDatasMap.has('issueNotaFiscal');
  }

  getIssueNotaFiscal() {
    let issueNotaFiscal = this.productLog.issueNotaFiscal;
    if(this.isChangedIssueNotaFiscal() && !this.isNew) {
      issueNotaFiscal = this.productLog.oldDatas.issueNotaFiscal;
    }
    return issueNotaFiscal;
  }

  isChangedStatus() {
    return this.oldDatasMap.has('active');
  }

  getStatus(): boolean {
    let status = this.productLog.active;
    if(this.isChangedStatus() && !this.isNew) {
      status = this.productLog.oldDatas.active;
    }
    return status;
  }

}
