export class servicesReadjustmentRegisterPriceFreeDTO {
  serviceBoatId: number;
  value: number;
  discount: number;
  dueDateDiscount: number;
  newEndService: Date;
  indexUsed: number;
  indexUsedText: string;

   constructor(
    serviceBoatId: number,
    value: number,
    discount: number,
    dueDateDiscount: number,
    newEndService: Date,
    indexUsed: number,
    indexUsedText: string
  ) {
    this.serviceBoatId = serviceBoatId;
    this.value = value;
    this.discount = discount;
    this.dueDateDiscount = dueDateDiscount;
    this.newEndService = newEndService;
    this.indexUsed = indexUsed;
    this.indexUsedText = indexUsedText;
  }
}
