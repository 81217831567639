<p-fieldset>
  <p-header class="article-title">
      <span class="article-title">{{'TITLE-PRODUCTS' | translate}}</span>


      <div class="header-buttons">
        <button class="primary fab-button" (click)="openProductFormDialog()" pTooltip="Adicionar novo produto"
            tooltipPosition="bottom">
            <i class="pi pi-plus" style="font-size: 21px;"></i>
        </button>
        
        <!-- <button class="primary fab-button" (click)="downloadForm()"
            pTooltip="{{ 'PRINT-PRODUCT-FORM' | translate }}" tooltipPosition="bottom">
            <i class="pi pi-print" style="font-size: 21px;"></i>
        </button>
        <button class="primary fab-button" (click)="openImportDialog()" pTooltip="Gerenciar importação de planilhas"
            tooltipPosition="bottom">
            <i class="pi pi-file-excel" style="font-size: 21px;"></i>
        </button> -->
        <button class="fab-button primary" (click)="exportTable()" label="Exportar Excel"
              pTooltip="A exportação será de acordo com os filtros selecionados" tooltipPosition="left">
                    <i class="pi pi-file"></i>
                </button> 
        <button class="fab-button primary" appFiltersButton pTooltip="Exibir/Ocultar Filtros" tooltipPosition="left">
            <i class="pi pi-filter"></i>
        </button>
    </div>

  </p-header>

  <form class="filters" (submit)="find()" appFilters>
    <div class="filter-row">
        <!-- <div class="form-control" *ngIf="marinaCompanies?.length">
            <label for="marinaCompanyId">{{'COMPANY' | translate}}</label>
            <p-dropdown [options]="marinaCompanies" name="marinaCompanyId" [placeholder]="'COMPANY' | translate"
                appendTo="body" [title]="'COMPANY' | translate" (onChange)="changeCompany($event)">
            </p-dropdown>
        </div> -->
        <div class="form-control">
            <label for="nome">{{'NAME' | translate}}</label>
            <input pInputText type="text" [(ngModel)]="productFilter.name" name="nome">
        </div>
        <div class="form-control">
            <label for="productType">{{'TYPE' | translate}}</label>
            <p-multiSelect [options]="productTypeOptions" optionValue="value" [(ngModel)]="productFilter.productType" name="productType"
            appendTo="body" selectedItemsLabel="{0} itens selecionados">
        </p-multiSelect>
        </div>
        <div class="form-control">
            <label for="category">{{'CATEGORY' | translate}}</label>
            <p-multiSelect [options]="categoryOptions" optionValue="value" [(ngModel)]="productFilter.productCategory" name="category"
            appendTo="body" selectedItemsLabel="{0} itens selecionados">
            </p-multiSelect>
        </div>
        <div class="form-control">
            <label for="controle">{{'Controle' | translate}}</label>
            <p-multiSelect [options]="orderControlOptions" [(ngModel)]="productFilter.orderControl" name="control"
                appendTo="body" selectedItemsLabel="{0} itens selecionados">
            </p-multiSelect>
        </div>
        <div class="form-control">
            <label for="chartAccountId">{{'Plano de Conta' | translate}}</label>
            <p-multiSelect [options]="listaPlanoContasFlat" optionValue="id" [(ngModel)]="productFilter.chartAccountId" name="chartAccountId"
                appendTo="body" selectedItemsLabel="{0} itens selecionados">
            </p-multiSelect>
        </div>

        <div class="form-control">
          <label for="costCenterId">{{'Centro de Custos' | translate}}</label>
          <p-multiSelect [options]="listaCentroCustoFlat" optionValue="id"  [(ngModel)]="productFilter.costCenterId" name="costCenterId"
              appendTo="body" selectedItemsLabel="{0} itens selecionados">
          </p-multiSelect>
       </div>


       <div class="form-control">
        <label for="status">{{'STATUS' | translate}}</label>
        <p-multiSelect [options]="optionsFilter" [(ngModel)]="productFilter.active" name="status"
            appendTo="body" selectedItemsLabel="{0} itens selecionados">
        </p-multiSelect>
     </div>

        <div class="buttons">
            <div class="form-button">
                <button (click)="clear()" class="easymarine danger" type="button">{{ 'CLEAR' | translate }}</button>
            </div>
            <div class="form-button">
                <button class="easymarine primary" type="submit">{{ 'FIND' | translate }}</button>
            </div>
        </div>
    </div>
</form>

  <div class="table">
      <p-table #tt [value]="productsTable" [paginator]="true" [rows]="numberOfRows" resetPageOnSort="true"
          [pageLinks]="3" [rowsPerPageOptions]="[10,20,30,50,100]" styleClass="easy-table"
          [globalFilterFields]="['id','name','productCategory.name']" autoLayout="true" >
          <ng-template pTemplate="caption">
            <div class="flex">
                <!-- <p-selectButton [ngStyle]="{'float': 'left'}" [(ngModel)]="filterActive"
                [options]="optionsFilter" optionValue="value" optionLabel="label"
                (onChange)="filterGlobal()">
            </p-selectButton> -->
            <!-- <button class="easymarine btn-csv-xls btn-margin" type="button" (click)="exportTable()"
                [pTooltip]="'Exporta todos os registros'" tooltipPosition="bottom">
                Exportar Tabela</button> -->
            </div>
              <span style="font-weight: bold; margin-top: 5px;">{{ 'TOTAL-REGISTERS' | translate }}:
                  {{tt.totalRecords}} {{'OF' | translate}} {{productsTable.length}}
              </span>
          </ng-template>
          <ng-template pTemplate="header">
              <tr>
                  <th [pSortableColumn]="'id'">
                      Id
                      <p-sortIcon [field]="'id'"></p-sortIcon>
                  </th>
                  <th [pSortableColumn]="'name'">
                      {{ 'NAME' | translate }}
                      <p-sortIcon [field]="'name'"></p-sortIcon>
                  </th>
                  <th>{{ 'TYPE' | translate }}</th>
                  <th>{{ 'PRICE' | translate}}</th>
                  <th [pSortableColumn]="'productCategory.name'">
                      {{ 'CATEGORY' | translate }}
                      <p-sortIcon [field]="'productCategory.name'"></p-sortIcon>
                  </th>
                  <th>{{ 'MEASUREMENT' | translate }}</th>
                  <th [pSortableColumn]="'orderControl'">
                      Controle
                      <p-sortIcon [field]="'orderControl'"></p-sortIcon>
                  </th>
                  <th [pSortableColumn]="'billingCompany'" *ngIf="hasMultipleCompanyByProduct">
                    Empresa
                    <p-sortIcon [field]="'billingCompany'"></p-sortIcon>
                  </th>
                  <th>{{ 'CHART-ACCOUNTS' | translate }}</th>
                  <th *ngIf="hasCostCenterByProduct">{{ 'COST-CENTER' | translate }}</th>
                  <th class="text-center">{{ 'STATUS' | translate }}</th>
                  <th></th>
                  <th></th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-product>
              <tr>
                  <td>{{product.id}}</td>
                  <td>{{product.name}}</td>
                  <td>{{product.productType?.toUpperCase() | translate}}</td>
                  <td>
                    <ng-container *ngIf="getPriceLabel(product) === 'Livre'; else checkRange">
                      Livre
                    </ng-container>
                  
                    <ng-template #checkRange>
                      <ng-container *ngIf="getPriceLabel(product) === 'Por Range'; else showPrice">
                        Por Range
                      </ng-container>
                    </ng-template>
                  
                    <ng-template #showPrice>
                      {{ getPriceLabel(product) | currency: 'BRL':'symbol':'1.2-2' }}
                    </ng-template>
                  </td>
                  <td>{{product.productCategory?.name}}</td>
                  <td>{{product.measurement?.toUpperCase() | translate}}</td>
                  <td>{{getTipoControle(product.orderControl)}}</td>
                  <td *ngIf="hasMultipleCompanyByProduct">{{product.billingCompany ? getCompanyIndex(product.billingCompany) : 1 }}</td>
                  <td>{{getLabelChartAccount(product)}}</td>
                  <td *ngIf="hasCostCenterByProduct">{{getLabelCostCenter(product)}}</td>

                  <td class="text-center">
                      <a href="javascript:;" [pTooltip]="product.active ? 'Desativar' : 'Ativar'"
                          class="badge badge-pill" [ngClass]="product.active ? 'success' : 'danger'"
                          tooltipPosition="top" (click)="validateSaveStatus(product)">
                          {{ product.active ? 'Ativo' : 'Inativo' }}
                      </a>
                  </td>
                  <td>
                      <div class="table-icon cursor-pointer" (click)="openProductFormDialog(product.id)" pTooltip="Editar produto">
                          <i class="pi pi-pencil"></i>
                      </div>
                  </td>
                  <td>
                      <div class="table-icon" (click)="product.active && openProductDeleteConfirmDialog(product)"
                          pTooltip="Excluir produto" [ngClass]="{'disabled' : !product.active, danger : product.active}">
                          <i class="pi pi-trash"></i>
                      </div>
                  </td>
              </tr>
          </ng-template>
      </p-table>
  </div>
</p-fieldset>
