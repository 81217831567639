import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OrderInvoiceFilter } from '../models/dtos/order-invoice-filter';
import { InvoiceMailDTO } from '../models/dtos/invoiceMail.DTO';
import { InvoiceQuotaDTO } from '../models/dtos/invoiceQuotaDTO';
import { PaginationFilter } from '../models/pagination-filter';
import { environment } from 'src/environments/environment';
import { Invoice } from '../models/invoice';
import { map } from 'rxjs/operators';
import { InvoiceDTO } from '../models/dtos/invoiceDTO';
import { GenerateDocumentResponse } from '../models/dtos/generate-document-response';
import { MailsCustomersDebitsDTO } from '../models/dtos/mailsCustomersDebitsDTO';
import { GenerateOrders } from '../models/dtos/generate-ordersDTO';
import { UploadDanfeRequestDTO } from '../models/dtos/upload-danfe-request-dto';
import { FilterBillingCompanySelected } from '../components/filter/filter-billing-company/filter-billing-company.component';
import { InvoiceEmailAttachmentType } from '../models/enums/invoice/invoice-email-attachment-type';
import {InvoicingReportFilter} from '../models/dtos/invoicingReportFilter';
import {InvoiceCustomerDTO} from '../models/dtos/invoice-customerDTO';

@Injectable()
export class InvoiceService {

  getSuperReport(filter: InvoicingReportFilter, paginationFilter: PaginationFilter): Observable<any> {
    let params = new HttpParams();
    if (!paginationFilter.sort || !paginationFilter.order) {
      paginationFilter.sort = 'invoiceNumber';
      paginationFilter.order = 'DESC';
    }

        params = params.set('sort', paginationFilter.sort.concat(',').concat(paginationFilter.order));
        params = params.set('page', paginationFilter.page.toString());
        params = params.set('size', paginationFilter.size.toString());
    return this.http.post(
      environment.apiHost + '/secure/invoice/rel/faturamento-completo', filter, { params }
        ).pipe(map((response: any) => response.data.invoices));
  }

  generateDocumentsProduct(invoicesIds: string): Observable<GenerateDocumentResponse> {
    return this.http.get(
        environment.apiHost + '/secure/invoice/document/nfp/generate?invoiceIds=' + invoicesIds
    ).pipe(map((response: any) => response.data.invoiceDocument));
  }

  getInvoiceList(invoiceIds: number[]):Observable<any> {
    return this.http.post(
      environment.apiHost + '/secure/invoice/list-from-ids', invoiceIds).pipe(map((response: any) => response.data.invoices));

  }


    getValorIssRetidoFromInvoice(id: number): Observable<any> {
        return this.http.get(
            environment.apiHost + '/secure/invoice/whitheld-iss-tax/' + id).pipe(map((response: any) => response.data));
    }

    constructor(private http: HttpClient) {
    }

    viewLaunch(referenceDateMilis: number): Observable<Invoice> {
        return this.http.get(
            environment.apiHost + '/secure/invoice/' + referenceDateMilis).pipe(map((response: any) => response.data.invoice));
    }

    viewLaunchByOrigin(filter: OrderInvoiceFilter): Observable<Invoice> {
        return this.http.post(environment.apiHost + '/secure/invoice/order', filter).pipe(map((response: any) => response.data.invoice));
    }

    generateInvoiceCustomers(invoiceCustomerDTO: {}): Observable<any> {
        return this.http.post(
            environment.apiHost + '/secure/invoice-customers/', invoiceCustomerDTO).pipe(map((response: any) => response.data));
    }
    generateInvoiceCustomersByList(invoiceCustomerDTOList: InvoiceCustomerDTO[]): Observable<any> {
        return this.http.post(
            environment.apiHost + '/secure/invoice-customers/by-list/', invoiceCustomerDTOList).pipe(map((response: any) => response.data));
    }
    generateInvoiceFranchiseToList(invoiceCustomerDTOList: InvoiceCustomerDTO[]): Observable<any> {
      return this.http.post(
          environment.apiHost + '/secure/invoice-customers/franchise/by-list/', invoiceCustomerDTOList).pipe(map((response: any) => response.data));
  }

  getAll(filter: {}, paginationFilter: PaginationFilter): Observable<any> {
    let params = new HttpParams();

    if (!paginationFilter.sort || !paginationFilter.order) {
      paginationFilter.sort = 'invoiceNumber';
      paginationFilter.order = 'DESC';
    }

        params = params.set('sort', paginationFilter.sort.concat(',').concat(paginationFilter.order));
        params = params.set('page', paginationFilter.page.toString());
        params = params.set('size', paginationFilter.size.toString());
        return this.http.post(
            environment.apiHost + '/secure/invoice/find-by-filter/', filter, { params })
            .pipe(map((response: any) => response.data.invoiceTable as any));
    }

    extract(idInvoice): Observable<any> {
        return this.http.get(
            environment.apiHost + '/secure/invoice/extract/' + idInvoice).pipe(map((response: any) => response.data.extract));
    }

    findByOrder(idOrder): Observable<Invoice[]> {
        return this.http.get(
            environment.apiHost + '/secure/invoice/find-by-order/' + idOrder).pipe(map((response: any) => response.data.invoices));
    }

    getDebits(customerId, filter: FilterBillingCompanySelected = undefined): Observable<Invoice[]> {
      if(filter !== undefined && filter.filterBillingCompany ) {
        let params = new HttpParams();
        if (filter.billingCompany !== null) {
          params = params.set('billingCompanyId', filter.billingCompany.id.toString());
        }
        return this.http.get(
          environment.apiHost +
          '/secure/invoice/debits/' + customerId + '/billingCompany', { params }).pipe(map((response: any) => response.data.invoices));
      } else {
        return this.http.get(
            environment.apiHost + '/secure/invoice/debits/' + customerId).pipe(map((response: any) => response.data.invoices));
      }
    }

    generateSelected(orders: GenerateOrders): Observable<Invoice[]> {
        return this.http.post(
            environment.apiHost + '/secure/invoice/selecteds/v1', orders).pipe(map((response: any) => response.data.invoices));
    }

    cancel(invoiceId, createCopy): Observable<any> {
        return this.http.delete(
            environment.apiHost + '/secure/invoice/cancel?invoiceId=' + invoiceId + '&createCopy=' + createCopy
        ).pipe(map((response: any) => response.status));
    }

    generateBulkBankingBillets(bilingPortfolioId: string, invoicesIds: string, group?:boolean): Observable<any> {
      if(group==null || group==undefined){
        group = false;
      }
      return this.http.get(
            environment.apiHost +
            '/secure/invoice/bankingbillet/generate?bililngPortifolioId=' +
            bilingPortfolioId +
            '&invoiceIds=' +
            invoicesIds +
            '&group=' +
            group
        ).pipe(map((response: any) => response.data.bankingbillets));
    }

    generateDocuments(invoicesIds: string): Observable<GenerateDocumentResponse> {
        return this.http.get(
            environment.apiHost + '/invoice/document/webhook/generate?invoiceIds=' + invoicesIds
        ).pipe(map((response: any) => response.data.invoiceDocument));
    }

    sendEmails(invoices: InvoiceMailDTO[], attachments: InvoiceEmailAttachmentType[] = null): Observable<any> {
        let params = new HttpParams();
        if (attachments) {
          params = params.set('attachments', attachments.join(','));
        }
        return this.http.post(
            environment.apiHost + '/secure/invoice/sendMails', invoices, { params })
            .pipe(map((response: any) => response.data.bankingbillets));
    }

  getEmailByInvoice(invoicesId: string): Observable<any> {
    return this.http.get(
      environment.apiHost + '/secure/invoice/emailLogs?invoiceId=' + invoicesId).pipe(
      map((response: any) => response.data.emailLogs)
    );
  }

    changeDueDate(invoiceId, dueDate): Observable<any> {
        return this.http.post(
            environment.apiHost + `/secure/invoice/${invoiceId}/change-due-date`, dueDate).pipe(
                map((response: any) => response.data.invoice)
            );
    }

    changeCompetenceDate(invoiceId, competenceDate): Observable<any> {
        return this.http.post(
            environment.apiHost + `/secure/invoice/${invoiceId}/change-competence-date`, competenceDate).pipe(
                map((response: any) => response.data.invoice)
            );
    }

    findCustomerNameByInvoiceNumberAndIdentifier(invoiceNumber: number, identifier: string): Observable<string> {
        return this.http.get(
            environment.apiHost + '/secure/invoice/customer-name/' +
            invoiceNumber + '/' + identifier).pipe(map((response: any) => response.data.customerName));
    }

    countInvoicesOfBillsNotSent(): Observable<number> {
        return this.http.get(
            environment.apiHost + '/secure/invoice/count-email-not-sent').pipe(map((response: any) => response.data.count as number));
    }

    importNfToInvoice(fileXml, filePdf, invoiceId): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('fileXml', fileXml);
        formData.append('filePdf', filePdf);
        formData.append('invoiceId', invoiceId);
        let hd = new HttpHeaders();
        hd = hd.set('Content-Type', 'multipart/form-data');
        const options = {
            headers: hd
        };
        return this.http.post(
            environment.apiHost + '/secure/invoice/import-nf', formData, options).pipe(
                map((response: any) => response.data.invoice)
            );
    }


    uploadDanfe(uploadDanfe: UploadDanfeRequestDTO): Observable<any[]> {
        const formData: FormData = new FormData();
        for (const file of uploadDanfe.files) {
            formData.append('files', file);
        }
        if(uploadDanfe.dueDate){
            formData.append('dueDate', uploadDanfe.dueDate);
        }
        if(uploadDanfe.referenceMonth){
            formData.append('referenceMonth', uploadDanfe.referenceMonth);
        }
        if(uploadDanfe.referenceYear){
            formData.append('referenceYear', uploadDanfe.referenceYear);
        }

        let hd = new HttpHeaders();
        hd = hd.set('Content-Type', 'multipart/form-data');
        const options = {
            headers: hd
        };

        return this.http.post(
            environment.apiHost + '/secure/invoice/upload-danfe', formData, options).pipe(
                map((response: any) => response.data.uploadStatus)
            );
    }

    getCustomersDebitsToDate(requestDateMilis: number,
      filter: FilterBillingCompanySelected = undefined
      ): Observable<InvoiceDTO[]> {
        if(filter != undefined && filter.filterBillingCompany ) {
          let params = new HttpParams();
          if (filter.billingCompany !== null) {
            params = params.set('billingCompanyId', filter.billingCompany.id.toString());
          }
          return this.http.get(
            environment.apiHost +
            '/secure/invoice/debits-to-date/' + requestDateMilis + '/billingCompany', { params }).pipe(map((response: any) => response.data.invoices));
        } else {
          return this.http.get(
              environment.apiHost +
              '/secure/invoice/debits-to-date/' + requestDateMilis).pipe(map((response: any) => response.data.invoices));
        }
    }

    sendMailsCustomersDebitsToDate(mailsCustomersDebits: MailsCustomersDebitsDTO): Observable<any> {
        return this.http.post(
            environment.apiHost +
            '/secure/invoice/debits-to-date/send-mails' , mailsCustomersDebits).pipe(map((response: any) => response));
    }

    getTemplateMailsCustomersDebitsToDate(): Observable<string> {
        return this.http.get(
            environment.apiHost +
            '/secure/invoice/debits-to-date/template-mails').pipe(map((response: any) => response.data.template));
    }

    findForAnticipation(filter: {}): Observable<any> {
        return this.http.post(
            environment.apiHost + '/secure/invoice/find-for-anticipation/', filter)
            .pipe(map((response: any) => response.data.filteredInvoices as any));
    }

    updateEmailLog(invoiceId: number): Observable<any> {
        return this.http.get(environment.apiHost + '/secure/invoice/updateLog/' + invoiceId).pipe(map((response: any) => response));
    }

  parcelInvoice(invoiceQuotaDTO: InvoiceQuotaDTO): Observable<any> {
    return this.http.post(environment.apiHost + '/secure/invoice/parcel', invoiceQuotaDTO).pipe(map((response: any) => response));
  }

  getInvoicingReports(invoicingReportFilter: InvoicingReportFilter, paginationFilter: PaginationFilter): Observable<any> {
    let params = new HttpParams();

    if (!paginationFilter.sort || !paginationFilter.order) {
      paginationFilter.sort = 'dueDate';
      paginationFilter.order = 'DESC';
    }
    params = params.set('sort', paginationFilter.sort.concat(',').concat(paginationFilter.order));
    params = params.set('page', paginationFilter.page.toString());
    params = params.set('size', paginationFilter.size.toString());
    return this.http.post(
      environment.apiHost + '/secure/invoice/invoicing-report', invoicingReportFilter, {params})
      .pipe(map((resp: any) => resp.data));
  }

  getInvoicingReportsV2(invoicingReportFilter: InvoicingReportFilter, paginationFilter: PaginationFilter): Observable<any> {
    let params = new HttpParams();

    if (!paginationFilter.sort || !paginationFilter.order) {
      paginationFilter.sort = 'dueDate';
      paginationFilter.order = 'DESC';
    }
    params = params.set('sort', paginationFilter.sort.concat(',').concat(paginationFilter.order));
    params = params.set('page', paginationFilter.page.toString());
    params = params.set('size', paginationFilter.size.toString());
    return this.http.post(
      environment.apiHost + '/secure/invoice/invoicing-report/v2', invoicingReportFilter, {params})
      .pipe(map((resp: any) => resp.data));
  }

  getInvoiceIdByMarina(): Observable<any[]> {
    return this.http
      .get(environment.apiHost + '/secure/invoice/marina').pipe(map((resp: any) => resp.data.invoiceByMarinaId));
  }

  updateEmailLogByUserId(customerId: number): Observable<any> {
    return this.http
      .put(`${environment.apiHost}/secure/invoice/updateLog/customer/${customerId}`, [])
      .pipe(map((resp: any) => resp.data));
  }
}
