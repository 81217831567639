<div class="app-header" [ngClass]="{'checked': checked, 'unchecked' : !checked, 'mobile': mobile}">
    <div class="app-header-left">
        <div class="docker">
            <label for="docker">
                <i class="pi pi-bars"></i>
            </label>

        </div>
        <div class="marina">
            <p-dropdown [options]="marinas" optionLabel="tradeName" class="primeDropdown" [(ngModel)]="newSelectedMarina"
                (onChange)="selectMarina()" *ngIf="marinas.length > 1" appendTo="body">
            </p-dropdown>
            <div *ngIf="marinas.length <= 1" class="font-small">{{getMarinaName()}}</div>
        </div>
    </div>

    <div class="app-header-right">

        <span class="user-name">
            {{ getLoggedUser() }}
        </span>
        <app-header-button-new-navigation></app-header-button-new-navigation>
        <app-header-button-notification></app-header-button-notification>
    </div>
</div>
