import { TooltipModule } from 'primeng/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSelectWithTranslateComponent } from './multi-select-with-translate/multi-select-with-translate.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { CardButtonComponent } from './card-button/card-button.component';
import { BoatMovementGroupComponent } from './boat-movement-group/boat-movement-group.component';
import { MovementMeanLabelComponent } from './movement-mean-label/movement-mean-label.component';

@NgModule({
  declarations: [
    MultiSelectWithTranslateComponent,
    CardButtonComponent,
    BoatMovementGroupComponent,
    MovementMeanLabelComponent,
    MovementMeanLabelComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    MultiSelectModule,
    TranslateModule,
    ReactiveFormsModule,
    TooltipModule
  ],
  exports: [
    MultiSelectWithTranslateComponent,
    CardButtonComponent,
    BoatMovementGroupComponent,
    MovementMeanLabelComponent,
  ]
})
export class AtomModule { }
