import { UploadChamado } from './upload-chamado';
import { GrupoSistemaChamado } from "./grupo-sistema-chamado";
import { UsuarioChamado } from "./usuario-chamado"

export class AtividadeChamado {


    anexosAtividadeChamado: UploadChamado[];
    anexosRemover:UploadChamado[];
    usuario: UsuarioChamado;
    usuarioLogado: UsuarioChamado;
    subGrupoSistemaChamado: GrupoSistemaChamado;
    itemContrato: any
    titulo:string;
    descricao:string;
    situacao:string;
    tipoAtividadeChamado:string;
    prioridade:string;
    pontoSprint:string;
    usuarioCliente:string;

    constructor( titulo: string, descricao: string) {
        this.anexosAtividadeChamado = [];
        this.itemContrato = null;
        this.titulo = titulo;
        this.descricao = descricao;
        this.situacao = "ABERTURA_WEB";
        this.tipoAtividadeChamado ="ABERTURA_WEB";
        this.prioridade = "3";
        this.pontoSprint = "0";
        this.usuarioCliente = "";
    }



}
