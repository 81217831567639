import { Invoice } from "./invoice";
import { Marina } from "./marina";
import { StockMovementType } from "./stock/stock-movement-type";

export class InvoiceTaxDocumentProduct {
  id: number;
  nfeId: string;
  empresaId: string;
  nfeNumero: string;
  nfeStatus: string;
  status: string;
  marina: Marina;

  invoice: Invoice;
  aliquota: number;
  valorTotal: number;
  createDate: Date;
  updateDate: Date;
  federalId: string;
  serie: string;
  customerName: string;
  supplierId: number;
  supplierName: string;
  type: string;
  serviceDocument: string;
  serviceDocumentXml: string;
  taxValue: number;

  stockMovementType: StockMovementType;

  messageSefaz: string;

  items: any [] = [];


  constructor() {

  }
}
