<p-fieldset class="remove-padding">
  <p-header class="article-title">
    <span class="article-title">{{ "TITLE-SUPER-INVOICING" | translate }}</span>
    <div class="new-header-buttons">
      <button
        class="fab-button primary"
        (click)="exportTableFixedColumns()"
        label="Exportar Relatório"
        pTooltip="A exportação será de acordo com os filtros selecionados"
        tooltipPosition="left"
      >
        <i class="pi pi-print"></i>
      </button>
      <button
        class="fab-button primary"
        (click)="exportTable()"
        label="Exportar Relatório"
        pTooltip="A exportação será de acordo com os filtros selecionados"
        tooltipPosition="left"
      >
        <i class="pi pi-file"></i>
      </button>
      <button
        appFiltersButton
        class="fab-button primary"
        pTooltip="Exibir/Ocultar Filtros"
        tooltipPosition="left"
      >
        <i class="pi pi-filter"></i>
      </button>
      <button
        class="fab-button primary"
        (click)="openSuperDrawer()"
        label="Escolher Colunas"
        pTooltip="Escolha as colunas a serem apresentadas"
        tooltipPosition="left"
      >
        <i class="pi pi-list"></i>
      </button>
    </div>
  </p-header>
  <app-custom-drawer
    [inDrawerHeaderGroups]="drawerHeaderGroups"
    (closeDrawer)="closeSuperDrawer()"
    *ngIf="isDrawerExpanded"
    class="custom-drawer"
  >
  </app-custom-drawer>
  <form appFilters class="new-filters">
    <div class="notifications">
      <app-notifications
        class="clickable"
        *ngIf="wrongCompetenceQty == 1"
        (clickEvent)="filterByNfeNotification()"
        [isError]="true"
        [clickable]="true"
      >
        Competência de {{ wrongCompetenceQty }} fatura está diferente de sua
        nota fiscal.
      </app-notifications>

      <app-notifications
        *ngIf="wrongCompetenceQty > 1"
        (clickEvent)="filterByNfeNotification()"
        [isError]="true"
        [clickable]="true"
      >
        Competência de {{ wrongCompetenceQty }} faturas estão diferentes de suas
        notas fiscais.
      </app-notifications>
    </div>
    <div class="new-filter-row">
      <div class="new-form-control-sm">
        <label>
          {{ "GENERAL-SEARCH" | translate }}
        </label>
        <span class="p-input-icon-right">
          <input
            type="text"
            class="new-input"
            [(ngModel)]="globalFilter"
            (input)="filterGlobal()"
            name="invoiceNumber"
            placeholder="Pesquise pelo número da fatura, nfe e cliente"
          />
          <i class="pi pi-search"></i>
        </span>
      </div>
      <div class="new-form-control-sm">
        <label>
          {{ "INVOICE-STATUS" | translate }}
        </label>
        <p-multiSelect
          [options]="invoiceStatusList"
          [(ngModel)]="filter.status"
          appendTo="body"
          name="status"
          selectedItemsLabel="{0} itens selecionados"
        >
        </p-multiSelect>
      </div>
      <div class="new-form-control-sm">
        <label>
          {{ "TAX-SERVICE" | translate }}
        </label>
        <p-dropdown
          [(ngModel)]="filter.withDocumentsNF"
          [filter]="true"
          [options]="nfeStatusList"
          appendTo="body"
          name="withDocumentsNF"
        >
        </p-dropdown>
      </div>
      <div class="new-form-control-sm">
        <label>
          {{ "TAX-PRODUCT" | translate }}
        </label>
        <p-dropdown
          [(ngModel)]="filter.withDocumentsNFP"
          [filter]="true"
          [options]="nfeStatusList"
          appendTo="body"
          name="withDocumentsNFP"
        >
        </p-dropdown>
      </div>
    </div>
    <div class="new-filter-row">
      <div class="new-form-control-sm">
        <label>
          {{ "INVOICE EMISSION" | translate }}&nbsp;{{ "FROM-TO" | translate }}
        </label>
        <p-calendar
          dateFormat="dd/mm/yy"
          name="rangeEmissionDates"
          [monthNavigator]="true"
          [yearNavigator]="true"
          appendTo="body"
          [yearRange]="'2000:2055'"
          rPCalendarMask
          selectionMode="range"
          [(ngModel)]="this.rangeEmissionDates"
          [showIcon]="true"
          (onClose)="onRangeChange('EMISSION')"
          icon="pi pi-calendar enlarged-icon"
          i18n
        >
        </p-calendar>
      </div>
      <div class="new-form-control-sm">
        <label>
          {{ "INVOICE DUE" | translate }}&nbsp;{{ "FROM-TO" | translate }}
        </label>
        <p-calendar
          dateFormat="dd/mm/yy"
          name="rangeDueDates"
          [monthNavigator]="true"
          [yearNavigator]="true"
          appendTo="body"
          [yearRange]="'2000:2055'"
          rPCalendarMask
          selectionMode="range"
          [(ngModel)]="this.rangeDueDates"
          [showIcon]="true"
          (onClose)="onRangeChange('DUE')"
          icon="pi pi-calendar enlarged-icon"
          i18n
        >
        </p-calendar>
      </div>
      <div class="new-form-control-sm">
        <label>
          {{ "INVOICE PAYMENT" | translate }}&nbsp;{{ "FROM-TO" | translate }}
        </label>
        <p-calendar
          dateFormat="dd/mm/yy"
          name="rangePaymentDates"
          [monthNavigator]="true"
          [yearNavigator]="true"
          appendTo="body"
          [yearRange]="'2000:2055'"
          rPCalendarMask
          selectionMode="range"
          [(ngModel)]="this.rangePaymentDates"
          [showIcon]="true"
          (onClose)="onRangeChange('PAYMENT')"
          icon="pi pi-calendar enlarged-icon"
          i18n
        >
        </p-calendar>
      </div>
      <div class="new-form-control-sm">
        <label>
          {{ "INVOICE COMPETENCE" | translate }}&nbsp;{{
            "FROM-TO" | translate
          }}
        </label>
        <p-calendar
          dateFormat="dd/mm/yy"
          name="rangeCompetenceDates"
          [monthNavigator]="true"
          [yearNavigator]="true"
          appendTo="body"
          [yearRange]="'2000:2055'"
          rPCalendarMask
          selectionMode="range"
          [(ngModel)]="this.rangeCompetenceDates"
          [showIcon]="true"
          (onClose)="onRangeChange('COMPETENCE')"
          icon="pi pi-calendar enlarged-icon"
          i18n
        >
        </p-calendar>
      </div>
    </div>
    <!-- <div class="buttons"> -->
    <div class="subtitles">
      <fieldset class="side-by-side">
        <legend>Legenda (Cores de fundo pela geração das notas)</legend>
        <div class="purple">
          <p-checkbox
            (onChange)="filterInvoiceByColor()"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="selectedColors"
            value="purple"
            class="clickable"
            type="checkbox"
            class="checkbox"
          ></p-checkbox>
          Nf emitida sem pgto
        </div>
        <div class="red">
          <p-checkbox
            (onChange)="filterInvoiceByColor()"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="selectedColors"
            value="red"
            class="clickable"
            type="checkbox"
            class="checkbox"
          ></p-checkbox>
          Nf emitida antes pgto
        </div>
        <div class="yellow">
          <p-checkbox
            (onChange)="filterInvoiceByColor()"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="selectedColors"
            value="yellow"
            class="clickable"
            type="checkbox"
            class="checkbox"
          ></p-checkbox>
          NF não emitida
        </div>
        <div class="green">
          <p-checkbox
            (onChange)="filterInvoiceByColor()"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="selectedColors"
            value="green"
            class="clickable"
            type="checkbox"
            class="checkbox"
          ></p-checkbox>
          Fatura zerada
        </div>
      </fieldset>
      <div class="new-form-button">
        <button
          (click)="search(false)"
          class="new-easymarine primary"
          type="submit"
        >
          {{ "FIND" | translate }}
          &nbsp;
          <i class="pi pi-search"></i>
        </button>
      </div>
      <div class="new-form-button">
        <button (click)="clear()" class="new-easymarine danger" type="button">
          {{ "CLEAR" | translate }}
        </button>
      </div>
      <div class="new-form-button">
        <button
          (click)="changeMoreFilterVisibility()"
          class="new-easymarine success"
          type="button"
        >
          <i *ngIf="!isMoreFiltersExpanded" class="pi pi-plus"></i>
          <i *ngIf="isMoreFiltersExpanded" class="pi pi-minus"></i>
          &nbsp;{{ "FILTERS" | translate }}
        </button>
      </div>
    </div>
    <!-- </div> -->
    <div *ngIf="isMoreFiltersExpanded" class="new-filter-row">
      <div class="new-form-control-md">
        <label>
          {{ "CUSTOMER" | translate }}
        </label>
        <p-dropdown
          [filter]="true"
          [options]="filteredCustomers"
          [(ngModel)]="this.filter.customerId"
          appendTo="body"
          name="selectedCustomerId"
        >
        </p-dropdown>
      </div>
      <div class="new-form-control-md">
        <label>
          <label>{{ "GROUPS" | translate }}</label>
        </label>
        <p-multiSelect
          [options]="customerGroups"
          [(ngModel)]="filter.groupsList"
          appendTo="body"
          selectedItemsLabel="{0} itens selecionados"
          name="groupsList"
        >
        </p-multiSelect>
      </div>
      <div class="new-form-control-md">
        <label> Serviço/Produto(s) </label>
        <p-multiSelect
          defaultLabel="Selecione..."
          [options]="orderProductFiltered"
          name="orderProductsList"
          appendTo="body"
          [(ngModel)]="filter.products"
          selectedItemsLabel="{0} itens selecionados"
        >
        </p-multiSelect>
      </div>
    </div>
  </form>
</p-fieldset>
<div>
  <p-table
    [alwaysShowPaginator]="false"
    [paginator]="false"
    resetPageOnSort="true"
    [rowsPerPageOptions]="[10, 20, 30, 50, 100]"
    [totalRecords]="totalRecords"
    [scrollable]="true"
    [scrollHeight]="getTableSize()"
    [value]="invoicingReportsTable"
    [lazy]="true"
    (onLazyLoad)="onChangePage($event)"
    styleClass="new-easy-table p-datatable-striped"
  >
    <ng-template pTemplate="header">
      <tr class="thead">
        <ng-container
          *ngFor="
            let group of drawerHeaderGroups;
            let even = even;
            let odd = odd
          "
        >
          <th
            *ngIf="group.activeItems > 0"
            class="group-header"
            [ngClass]="{ odd: odd, even: even }"
          >
            <i class="pi pi-file"></i> {{ group.label }}
          </th>
          <th
            *ngFor="let items of getSpaces(group)"
            class="group-header"
            [ngClass]="{ odd: odd, even: even }"
          ></th>
        </ng-container>
      </tr>
      <tr class="group-header-items labels">
        <ng-container *ngFor="let items of drawerHeaderItems; let i = index">
          <th
            [pSortableColumn]="items.name"
            *ngIf="items.toggle"
            class="text-center"
            [ngClass]="{
              'blue-border': getBlueSideBorderClass(i),
              'green-border': getGreenSideBorderClass(i)
            }"
          >
            {{ items.label }}
            <p-sortIcon *ngIf="items.toggle" [field]="items.name"></p-sortIcon>
          </th>
        </ng-container>
      </tr>
      <tr class="group-header-items totals">
        <ng-container *ngFor="let item of drawerHeaderItems; let i = index">
          <th
            *ngIf="item.toggle"
            class="totals text-center"
            [ngClass]="{
              'blue-border': getBlueSideBorderClass(i),
              'green-border': getGreenSideBorderClass(i)
            }"
            [pTooltip]="
              item.name.includes('Description')
                ? 'Quantidade de Registros'
                : null
            "
          >
            <ng-container
              *ngIf="
                (item.name == 'taxablePeriodicValidation' ||
                  item.name == 'receiptPeriodicValidation') &&
                  isAlert;
                else calculate
              "
            >
              <i
                class="pi pi-exclamation-triangle yellow-alert"
                pTooltip="Valor % com diferença"
              ></i>
            </ng-container>
            <ng-template #calculate>{{
              calculateTotals(item.name, null)
            }}</ng-template>
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template let-invoiceReport pTemplate="body">
      <tr class="column-items">
        <td
          class="text-center link blue-border"
          [ngClass]="{ 'zero-value': isZeroValue(invoiceReport) }"
          *ngIf="cols['invoiceNumber']"
          (click)="extract(invoiceReport.invoiceId)"
        >
          {{ invoiceReport.invoiceNumber }}
        </td>
        <td
          class="text-center"
          [ngClass]="{ 'zero-value': isZeroValue(invoiceReport) }"
          *ngIf="cols['invoiceGeneratedBy']"
        >
          {{ invoiceReport.invoiceGeneratedBy }}
        </td>
        <td
          class="text-center"
          [ngClass]="{ 'zero-value': isZeroValue(invoiceReport) }"
          *ngIf="cols['invoiceValue']"
        >
          {{ invoiceReport.invoiceValue | number : "1.2-2" }}
        </td>
        <td
          class="text-center"
          [ngClass]="{ 'zero-value': isZeroValue(invoiceReport) }"
          *ngIf="cols['invoiceEmissionDate']"
        >
          {{ invoiceReport.invoiceEmissionDate | date : "dd/MM/yy" }}
        </td>
        <td
          class="text-center"
          [ngClass]="{ 'zero-value': isZeroValue(invoiceReport) }"
          *ngIf="cols['invoiceCompetenceDate']"
        >
          {{ invoiceReport.invoiceCompetenceDate | date : "dd/MM/yy" }}
        </td>
        <td
          class="text-center"
          [ngClass]="{ 'zero-value': isZeroValue(invoiceReport) }"
          *ngIf="cols['invoiceDueDate']"
        >
          {{ invoiceReport.invoiceDueDate | date : "dd/MM/yy" }}
        </td>

        <td class="text-center green-border" *ngIf="cols['paymentValue']">
          {{ invoiceReport.paymentValue | number : "1.2-2" }}
        </td>
        <td class="text-center no-wrap" *ngIf="cols['paymentDate']">
          {{ invoiceReport.paymentDate | date : "dd/MM/yy" }}&nbsp;
          <i
            *ngIf="cols['paymentMultiples']"
            class="pi pi-exclamation-circle"
            pTooltip="Múltiplos pagamentos"
          ></i>
        </td>
        <td class="text-center" *ngIf="cols['paymentResponsibleEmployee']">
          {{ invoiceReport.paymentResponsibleEmployee }}
        </td>
        <td class="text-center" *ngIf="cols['paymentDiscount']">
          {{ invoiceReport.paymentDiscount | number : "1.2-2" }}
        </td>
        <td class="text-center" *ngIf="cols['paymentDueDateDiscount']">
          {{ invoiceReport.paymentDueDateDiscount | number : "1.2-2" }}
        </td>
        <td class="text-center" *ngIf="cols['paymentInterestAndFine']">
          {{ invoiceReport.paymentInterestAndFine | number : "1.2-2" }}
        </td>

        <td class="text-center blue-border" *ngIf="cols['customerName']">
          {{ invoiceReport.customerName }}
        </td>
        <td class="text-center" *ngIf="cols['customerGroup']">
          {{ invoiceReport.customerGroup }}
        </td>
        <td class="text-center" *ngIf="cols['customerFederalId']">
          {{ invoiceReport.customerFederalId }}
        </td>

        <td
          class="text-center green-border"
          [ngClass]="{
            'not-generated': isGeneratedNFE(invoiceReport),
            'not-payed': isNotPayed(invoiceReport),
            'prior-to-payment': isPriorToPaymentNFE(invoiceReport)
          }"
          *ngIf="cols['nfeServiceNumber']"
        >
          <a
            [href]="invoiceReport.nfeServiceDocument"
            target="_blank"
            tooltipPosition="bottom"
            tooltipPosition="top"
          >
            {{ invoiceReport.nfeServiceNumber }}
          </a>
        </td>
        <td
          class="text-center"
          [ngClass]="{
            'not-generated': isGeneratedNFE(invoiceReport),
            'not-payed': isNotPayed(invoiceReport),
            'prior-to-payment': isPriorToPaymentNFE(invoiceReport)
          }"
          *ngIf="cols['nfeServiceGeneratedBy']"
        >
          {{ invoiceReport.nfeServiceGeneratedBy }}
        </td>
        <td
          class="text-center"
          [ngClass]="{
            'not-generated': isGeneratedNFE(invoiceReport),
            'not-payed': isNotPayed(invoiceReport),
            'prior-to-payment': isPriorToPaymentNFE(invoiceReport)
          }"
          *ngIf="cols['nfeServiceValue']"
        >
          {{ invoiceReport.nfeServiceValue | number : "1.2-2" }}
        </td>
        <td
          class="text-center"
          [ngClass]="{
            'not-generated': isGeneratedNFE(invoiceReport),
            'not-payed': isNotPayed(invoiceReport),
            'prior-to-payment': isPriorToPaymentNFE(invoiceReport)
          }"
          *ngIf="cols['nfeServiceEmissionDate']"
        >
          {{ invoiceReport.nfeServiceEmissionDate | date : "dd/MM/yy" }}
        </td>
        <td
          class="text-center"
          [ngClass]="{
            'not-generated': isGeneratedNFE(invoiceReport),
            'not-payed': isNotPayed(invoiceReport),
            'prior-to-payment': isPriorToPaymentNFE(invoiceReport)
          }"
          *ngIf="cols['nfeServiceCompetenceDate']"
        >
          {{ invoiceReport.nfeServiceCompetenceDate | date : "dd/MM/yy" }}
        </td>

        <td class="text-center blue-border" *ngIf="cols['nfeProductNumber']">
          <a
            class="link"
            *ngIf="cols['nfeProductNumber']"
            [href]="invoiceReport.nfeProductDocument"
            target="_blank"
            tooltipPosition="bottom"
            tooltipPosition="top"
          >
            {{ invoiceReport.nfeProductNumber }}
          </a>
        </td>
        <td class="text-center" *ngIf="cols['nfeProductGeneratedBy']">
          {{ invoiceReport.nfeProductGeneratedBy }}
        </td>
        <td class="text-center" *ngIf="cols['nfeProductValue']">
          {{ invoiceReport.nfeProductValue | number : "1.2-2" }}
        </td>
        <td class="text-center" *ngIf="cols['nfeProductEmissionDate']">
          {{ invoiceReport.nfeProductEmissionDate | date : "dd/MM/yy" }}
        </td>
        <td class="text-center" *ngIf="cols['nfeProductCompetenceDate']">
          {{ invoiceReport.nfeProductCompetenceDate | date : "dd/MM/yy" }}
        </td>

        <td
          class="text-center green-border"
          [ngClass]="{ 'not-generated': isGeneratedPeriodic(invoiceReport) }"
          *ngIf="cols['taxablePeriodicQuantity']"
        >
          {{ invoiceReport.taxablePeriodicQuantity }}
        </td>
        <td
          class="text-center"
          [ngClass]="{ 'not-generated': isGeneratedPeriodic(invoiceReport) }"
          *ngIf="cols['taxablePeriodicValue']"
        >
          {{ invoiceReport.taxablePeriodicValue | number : "1.2-2" }}
        </td>
        <td
          class="text-center"
          [ngClass]="{ 'not-generated': isGeneratedPeriodic(invoiceReport) }"
          *ngIf="cols['taxablePeriodicDueDateDiscount']"
        >
          {{ invoiceReport.taxablePeriodicDueDateDiscount | number : "1.2-2" }}
        </td>
        <td
          class="text-center"
          [ngClass]="{ 'not-generated': isGeneratedPeriodic(invoiceReport) }"
          *ngIf="cols['taxablePeriodicDueDateDiscountPercentage']"
        >
          {{ invoiceReport.taxablePeriodicDueDateDiscountPercentage }}
        </td>
        <td
          class="text-center"
          [ngClass]="{ 'not-generated': isGeneratedPeriodic(invoiceReport) }"
          *ngIf="cols['taxablePeriodicDescription']"
        >
          {{ invoiceReport.taxablePeriodicDescription }}
        </td>
        <td
          class="text-center"
          [ngClass]="{ 'not-generated': isGeneratedPeriodic(invoiceReport) }"
          *ngIf="cols['taxablePeriodicPlanoConta']"
        >
          {{ invoiceReport.taxablePeriodicPlanoConta }}
        </td>
        <td
          class="text-center"
          [ngClass]="{ 'not-generated': isGeneratedPeriodic(invoiceReport) }"
          *ngIf="cols['taxablePeriodicValidation']"
        >
          {{ invoiceReport.taxablePeriodicValidation }}
        </td>

        <td
          class="text-center blue-border"
          *ngIf="cols['receiptPeriodicQuantity']"
        >
          {{ invoiceReport.receiptPeriodicQuantity }}
        </td>
        <td class="text-center" *ngIf="cols['receiptPeriodicValue']">
          {{ invoiceReport.receiptPeriodicValue | number : "1.2-2" }}
        </td>
        <td class="text-center" *ngIf="cols['receiptPeriodicDueDateDiscount']">
          {{ invoiceReport.receiptPeriodicDueDateDiscount | number : "1.2-2" }}
        </td>
        <td
          class="text-center"
          *ngIf="cols['receiptPeriodicDueDateDiscountPercentage']"
        >
          {{ invoiceReport.receiptPeriodicDueDateDiscountPercentage }}
        </td>
        <td class="text-center" *ngIf="cols['receiptPeriodicDescription']">
          {{ invoiceReport.receiptPeriodicDescription }}
        </td>
        <td class="text-center" *ngIf="cols['receiptPeriodicPlanoConta']">
          {{ invoiceReport.receiptPeriodicPlanoConta }}
        </td>
        <td class="text-center" *ngIf="cols['receiptPeriodicValidation']">
          {{ invoiceReport.receiptPeriodicValidation }}
        </td>

        <td class="text-center green-border" *ngIf="cols['periodicTotal']">
          {{ invoiceReport.periodicTotal | number : "1.2-2" }}
        </td>
        <td class="text-center" *ngIf="cols['periodicTotalDueDateDiscount']">
          {{ invoiceReport.periodicTotalDueDateDiscount | number : "1.2-2" }}
        </td>
        <td class="text-center" *ngIf="cols['periodicDescription']">
          {{ invoiceReport.periodicDescription }}
        </td>

        <td
          class="text-center blue-border"
          [ngClass]="{ 'not-generated': isGeneratedSporadic(invoiceReport) }"
          *ngIf="cols['taxableSporadicQuantity']"
        >
          {{ invoiceReport.taxableSporadicQuantity }}
        </td>
        <td
          class="text-center"
          [ngClass]="{ 'not-generated': isGeneratedSporadic(invoiceReport) }"
          *ngIf="cols['taxableSporadicValue']"
        >
          {{ invoiceReport.taxableSporadicValue | number : "1.2-2" }}
        </td>
        <td
          class="text-center"
          [ngClass]="{ 'not-generated': isGeneratedSporadic(invoiceReport) }"
          *ngIf="cols['taxableSporadicDescription']"
        >
          {{ invoiceReport.taxableSporadicDescription }}
        </td>
        <td
          class="text-center"
          [ngClass]="{ 'not-generated': isGeneratedSporadic(invoiceReport) }"
          *ngIf="cols['taxableSporadicPlanoConta']"
        >
          {{ invoiceReport.taxableSporadicPlanoConta }}
        </td>

        <td
          class="text-center green-border"
          *ngIf="cols['receiptSporadicQuantity']"
        >
          {{ invoiceReport.receiptSporadicQuantity }}
        </td>
        <td class="text-center" *ngIf="cols['receiptSporadicValue']">
          {{ invoiceReport.receiptSporadicValue | number : "1.2-2" }}
        </td>
        <td class="text-center" *ngIf="cols['receiptSporadicDescription']">
          {{ invoiceReport.receiptSporadicDescription }}
        </td>
        <td class="text-center" *ngIf="cols['receiptSporadicPlanoConta']">
          {{ invoiceReport.receiptSporadicPlanoConta }}
        </td>

        <td
          class="text-center blue-border"
          [ngClass]="{ 'not-generated': isGeneratedPeriodic(invoiceReport) }"
          *ngIf="cols['taxablePeriodicClientQuantity']"
        >
          {{ invoiceReport.taxablePeriodicClientQuantity }}
        </td>
        <td
          class="text-center"
          [ngClass]="{ 'not-generated': isGeneratedPeriodic(invoiceReport) }"
          *ngIf="cols['taxablePeriodicClientValue']"
        >
          {{ invoiceReport.taxablePeriodicClientValue | number : "1.2-2" }}
        </td>
        <td
          class="text-center"
          [ngClass]="{ 'not-generated': isGeneratedPeriodic(invoiceReport) }"
          *ngIf="cols['taxablePeriodicClientDueDateDiscount']"
        >
          {{
            invoiceReport.taxablePeriodicClientDueDateDiscount
              | number : "1.2-2"
          }}
        </td>
        <td
          class="text-center"
          [ngClass]="{ 'not-generated': isGeneratedPeriodic(invoiceReport) }"
          *ngIf="cols['taxablePeriodicClientDueDateDiscountPercentage']"
        >
          {{ invoiceReport.taxablePeriodicClientDueDateDiscountPercentage }}
        </td>
        <td
          class="text-center"
          [ngClass]="{ 'not-generated': isGeneratedPeriodic(invoiceReport) }"
          *ngIf="cols['taxablePeriodicClientDescription']"
        >
          {{ invoiceReport.taxablePeriodicClientDescription }}
        </td>
        <td
          class="text-center"
          [ngClass]="{ 'not-generated': isGeneratedPeriodic(invoiceReport) }"
          *ngIf="cols['taxablePeriodicClientPlanoConta']"
        >
          {{ invoiceReport.taxablePeriodicClientPlanoConta }}
        </td>

        <td
          class="text-center green-border"
          *ngIf="cols['receiptPeriodicClientQuantity']"
        >
          {{ invoiceReport.receiptPeriodicClientQuantity }}
        </td>
        <td class="text-center" *ngIf="cols['receiptPeriodicClientValue']">
          {{ invoiceReport.receiptPeriodicClientValue | number : "1.2-2" }}
        </td>
        <td
          class="text-center"
          *ngIf="cols['receiptPeriodicClientDueDateDiscount']"
        >
          {{
            invoiceReport.receiptPeriodicClientDueDateDiscount
              | number : "1.2-2"
          }}
        </td>
        <td
          class="text-center"
          *ngIf="cols['receiptPeriodicClientDueDateDiscountPercentage']"
        >
          {{ invoiceReport.receiptPeriodicClientDueDateDiscountPercentage }}
        </td>
        <td
          class="text-center"
          *ngIf="cols['receiptPeriodicClientDescription']"
        >
          {{ invoiceReport.receiptPeriodicClientDescription }}
        </td>
        <td class="text-center" *ngIf="cols['receiptPeriodicClientPlanoConta']">
          {{ invoiceReport.receiptPeriodicClientPlanoConta }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
