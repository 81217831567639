import { MovementLocationParams } from './../../../../../components/movements/movements-operational/movement-location/movement-location.component';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { MovementLocationType } from 'src/app/models/enums/movements/movement-location-type';
import { BoatMovementDTO } from 'src/app/models/dtos/movements/boat-movement-dto';
import { MovementExecutionService } from 'src/app/services/movements/movement-execution.service';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { FilterBoatMovement } from 'src/app/models/dtos/movements/filter-boat-movement';
import { BoatService } from 'src/app/services/boat.service';
import { Boat } from 'src/app/models';

@Component({
  selector: 'app-movement-location-navigation',
  templateUrl: './movement-location-navigation.component.html',
  styleUrls: ['./movement-location-navigation.component.scss']
})
export class MovementLocationNavigationComponent implements OnInit {

  movementExecutionLocations: BoatMovementDTO[] = [];

  MovementSubscription: Subscription;

  filter: FilterBoatMovement = {};

  constructor(
    private movementExecutionService: MovementExecutionService,
    private messageUtil: MessageUtil,
    private spinner: NgxSpinnerService,
    private webSocketService: WebSocketService,
    private boatService: BoatService
  ) { }

  ngOnInit(): void {
    this.getMovements();
    this.MovementSubscription = this.webSocketService.getMessageMovement().subscribe(async (message) => {
      await this.getMovements();
    });
  }

  getDestiny(movement : BoatMovementDTO): MovementLocationParams {
    return {
      type: movement?.movement.destinyLocationType,
      name: movement?.movement?.destinyLocationName,
      abbreviation: movement?.movement?.destinyLocationAbbreviation,
      vacancyCode: movement?.movement?.destinyVacancyCode
    }
  }

  getOrigin({ currentLocation } : BoatMovementDTO): MovementLocationParams {
    return {
      type: currentLocation.type,
      name: currentLocation.name,
      abbreviation: currentLocation.abbreviation,
      vacancyCode: currentLocation.vacancyCode
    }
  }

  async getInactiveBoats(): Promise<Boat[]> {
    return new Promise<Boat[]>(async (resolve) => {
      this.boatService.getInactiveBoats().subscribe({
        next: (inactiveBoats: Boat[]) => {
           resolve(inactiveBoats);
        },
        error: (e) => {
          console.log(e);
        }
      })
    })
  }

  async getMovements(){
    this.spinner.show();
    const inactiveBoatsList = await this.getInactiveBoats();
    this.movementExecutionService.getAllByLocation(MovementLocationType.NAVIGATION, this.filter).subscribe(
      (data) => {
        const customerWithoutInactive = data.filter((b) =>
          !inactiveBoatsList.some(i => i.id == b.boat.id && !b.movement)
        )

        this.movementExecutionLocations = customerWithoutInactive;
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    )
  }
}
