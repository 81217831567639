import { Component, Input } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-invoice-log-modal',
  templateUrl: './invoice-log-modal.component.html',
  styleUrls: ['./invoice-log-modal.component.scss']
})
export class InvoiceLogModalComponent {

    invoiceLog;
    oldDatasMap: Map<string, unknown> = new Map();
    
    constructor(
      private config: DynamicDialogConfig,
      public dialogRef: DynamicDialogRef
    ) { }

    ngOnInit(): void {
      if(!this.config.data.invoiceLog || !this.config.data.invoiceLog.oldDatas){
        this.dialogRef.close();
      }
      this.invoiceLog = this.config.data.invoiceLog;
      console.log(this.invoiceLog);
      if(this.invoiceLog.oldDatas){ 
        this.oldDatasMap = new Map(Object.entries(this.invoiceLog.oldDatas));
    
      }
      this.createLogs();
    }

    createLogs() {    
      this.verifyChanges();
    }
  
    verifyChanges() {
      

    }
}
