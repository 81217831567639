import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SlingConfig } from 'src/app/models';
import { MarinaCompany } from 'src/app/models/marina-company';
import { FinancialMultipleCompaniesOption, FinancialMultipleCompaniesService } from 'src/app/services/financial/financial-multiple-companies.service';

export interface FilterBillingCompanySelected {
  filterBillingCompany: boolean,
  billingCompany: { id: number }
}

@Component({
  selector: 'app-filter-billing-company',
  templateUrl: './filter-billing-company.component.html',
  styleUrls: ['./filter-billing-company.component.scss']
})
export class FilterBillingCompanyComponent implements OnInit {

  @Input()
  filter;

  @Output()
  filterChange = new EventEmitter();

  @Input()
  slingConfig: SlingConfig;

  billingCompanyOptions: FinancialMultipleCompaniesOption[] = undefined
  billingCompanySelected: MarinaCompany | {} = undefined

  constructor(
    private financialMultipleCompaniesService: FinancialMultipleCompaniesService
  ) { }

  ngOnInit(): void {
    this.filter.billingCompany = undefined;
    this.loadBillingCompanyOption();
  }

  change(event: any): void {
    const option = event.value;
    if (option !== null && option !== undefined) {
      this.filter.filterBillingCompany = true;
      this.filter.billingCompany = option.id ? option : null;
    } else {
      this.filter.filterBillingCompany = false;
      this.filter.billingCompany = undefined;
    }
    this.filterChange.emit(this.filter);
  }

  loadBillingCompanyOption(): void {
    this.billingCompanyOptions = this.financialMultipleCompaniesService.getFilterBillingCompanyOptions(false);
  }
}
