import { Licence } from './../../../../models/license';
import { BoatSailorService } from './../../../../services/boat-sailor.service';
import { BoatSailor } from 'src/app/models/boat-sailor';
import { SailorService } from './../../../../services/sailor.service';
import { Sailor } from 'src/app/models/sailor';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  DynamicDialogRef,
  DynamicDialogConfig,
} from 'primeng/dynamicdialog';
import { MessageUtil } from 'src/app/utils/message.util';
import moment from 'moment';
import { FileUpload } from 'primeng/fileupload';
import { ToastService } from 'src/app/services/toast.service';
import { ValidatorsUtil } from 'src/app/services/validators.util';

@Component({
  selector: 'app-sailor-form',
  templateUrl: './sailor-form.component.html',
  styleUrls: ['./sailor-form.component.scss'],
})
export class SailorFormComponent implements OnInit {
  @ViewChild('upload', { static: false }) upload: FileUpload;
  selectedTab: number = 0;
  sailor = new Sailor();

  boatSailors: BoatSailor[] = [];

  isNew: boolean;

  sailorForm = new UntypedFormGroup({});
  boatsTable: BoatSailor[] = [];
  errorMessage: string;
  dadosPrincipais: any;
  licenseForm = new UntypedFormGroup({});

  readonlyForm=false;
  accessCategoryId: number;

  constructor(
    public dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private messageUtil: MessageUtil,
    private spinner: NgxSpinnerService,
    private sailorService: SailorService,
    private boatSailorService: BoatSailorService,
    private toastService: ToastService,
    private validatorsUtil: ValidatorsUtil
  ) {}

  ngOnInit(): void {
    if (this.config.data?.user?.id) {
      const sailor: Sailor = this.config.data.user.sailor;
      Object.keys(sailor).map((key) => {
        if (sailor[key] === undefined || sailor[key] === null) {
          this.sailor[key] = '';
        } else {
          this.sailor[key] = sailor[key]; //}
        }
      });
      this.sailor.user.id = this.config.data.user.id;
      this.sailor.user.email = this.config.data.user.email;
      if (this.sailor.status!=="CADWEB"){
        this.readonlyForm=true;
      }
      this.startFormLicense(this.sailor?.licence);
      this.getListBoatSailor();
      this.accessCategoryId = this.config.data.accessCategoryId
    } else {
      this.sailor = new Sailor();
      this.sailor.user.email = '';
      this.startFormLicense();
    }
  }

  getListBoatSailor(): void {
    this.spinner.show();
    this.boatSailorService
      .getListBoatSailorByUserId(this.sailor.user.id)
      .subscribe(
        (data: any) => {
          this.boatsTable = data as BoatSailor[];
        },
        (error) => {
          this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(
            exception.type,
            exception.title,
            exception.message
          );
        },
        () => this.spinner.hide()
      );
  }

  receiveDados($event): void {
    this.dadosPrincipais = $event;
  }

  receiveLicense($event): void {
    this.licenseForm = $event;
  }

  receiveBoatSailor($event): void {
    this.boatsTable = $event;
  }

  async save() {
    if (!this.validaForm()){
      return
    }

    try {
      this.dadosPrincipais.boatsDados
        .get('phone')
        .setValue(
          this.dadosPrincipais.boatsDados
            .get('phone')
            .value.replace(/([^\d])+/gim, '')
        );
    } catch (e) {}

    let type = this.dadosPrincipais.boatsDados.get('typesSailor').value;
    this.dadosPrincipais.boatsDados.get('typesSailor').setValue([]);
    const accessCategory = this.dadosPrincipais.boatsDados.get('accessCategory').value;
    this.dadosPrincipais.boatsDados.removeControl("accessCategory")
    const sendObj = {
      sailorDados: this.dadosPrincipais.boatsDados.value,
      license: this.licenseForm.value,
      boatSailor: this.boatsTable,
      typesSailor: type,
      accessCategory: accessCategory,
      accessGroup: accessCategory
    };
    this.dadosPrincipais.boatsDados.get('typesSailor').setValue(type);

    this.saveDados(sendObj);
  }

  saveDados(dados: any): void {
    this.spinner.show();
    this.sailorService.saveWeb(dados).subscribe(
      (data: any) => {
        this.dialogRef.close(data);
        this.toastService.success('Marinheiro alterado com sucesso.');
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
      () => this.spinner.hide()
    );
  }

  startFormLicense(license?: Licence) {

    if (!license?.id){
      license = new Licence();
    }
    if (license?.expiration && license?.expiration!=null){
      license.expiration = moment(license.expiration).utc(true).toDate();
    }
    this.licenseForm = new UntypedFormGroup({
      id: new UntypedFormControl(license.id),
      register: new UntypedFormControl(license.register),
      country: new UntypedFormControl(license.country),
      state: new UntypedFormControl(license.state),
      city: new UntypedFormControl(license.city),
      file: new UntypedFormControl(license.file),
      expiration: new UntypedFormControl(license.expiration),
      typeLicense: new UntypedFormControl(license.typeLicense),
    });
  }

  validaForm(): boolean {
    let federalIdNumber = this.dadosPrincipais.boatsDados.get('federalId').value;
    let isValidFederalId = this.validatorsUtil.cpfCnpjValidator(federalIdNumber);
    if (isValidFederalId) {
      if (this.dadosPrincipais.boatsDados.status==="VALID"){
        return true;
      } else {
        this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', 'Existem campos obrigatórios vazios.');
        return false;
      }
    } else {
      this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', 'CPF/CNPJ inválido.');
    }
  }


}
