import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  private readonly API_URL = environment.apiHost + '/secure/'

  constructor(private http: HttpClient) { }


  public static base64toBlob(dataURI, name?): Promise<Blob> {
    return new Promise<Blob>((resolve) => {
      // convert base64/URLEncoded data component to raw binary data held in a string
      let byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0) {
        byteString = atob(dataURI.split(',')[1]);
      } else {
        byteString = unescape(dataURI.split(',')[1]);
      }

      // separate out the mime component
      let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      if (name) {
        const parts = name.split('.');
        mimeString = parts[parts.length - 1];
      }
      // write the bytes of the string to a typed array
      const ia = new Uint8Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([ia], { type: mimeString });
      resolve(blob);
    });

  }

  public getPdfFromUrl(url: string){
    return this.http.get(url, { responseType: 'blob' });
  }

  public static createBlobDataPDF(urlData: string): Blob {
    var byteCharacters = atob(urlData);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: 'application/pdf;base64' });
  }

  createQRCode(texto: string, width: string, heigth: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.post(this.API_URL + 'utils/qrcode/'+ width + '/' + heigth, texto, {headers, responseType: 'blob'})
        .pipe(map((response: any) => response));
  }

}
