<div class="dialog-form">
  <form [formGroup]="customerFastEditForm">
    <ng-template pTemplate="header">
      {{ "DATA" | translate }}
    </ng-template>

    <p-fieldset class="operation-date">
      <p-header>
        <div>Informações</div>
      </p-header>
      <div class="form-group">
        <div>
          <div class="form-control">
            <app-sailor-upload
              [path]="imgPreviewPhoto"
              title="Foto"
              (finishEvent)="receiveUploadUrlPhoto($event)"
            >
            </app-sailor-upload>
          </div>
        </div>
        <div style="width: 100% !important;">
          <div class="form-group" style="width: 100% !important;">
            <div
              class="form-control"
              style="min-width: 100px !important;"
            >
              <label for="name">{{ "CPF" | translate }}*</label>
              <p-inputMask
                type="text"
                mask="999.999.999-99"
                name="federalId"
                formControlName="federalId"
                [readonly]="notEditCpf"
              >
              </p-inputMask>
            </div>

            <div class="form-control" style="max-width: 100%">
              <label for="name">{{ "NAME" | translate }} *</label>
              <input
                pInputText
                name="name"
                formControlName="name"
                maxlength="50"
              />
            </div>
          </div>
          <div class="form-group mt-3">
            <div class="form-control">
              <label for="phone">{{ "CELLPHONE" | translate }}*</label>
              <p-inputMask
                type="text"
                mask="+99 (99) 99999-9999"
                name="phone"
                formControlName="phone"
              ></p-inputMask>
            </div>

            <div class="form-control"  style="max-width: 100%">
              <label for="email">{{ "EMAIL" | translate }}*</label>
              <input
                pInputText
                type="email"
                name="email"
                formControlName="email"
              />
            </div>
          </div>

          <div class="form-group mt-3">
            <div class="form-control">
              <label for="maritalStatus">{{
                "Estado civil" | translate
              }}*</label>
              <p-dropdown
                [options]="maritalStatusList"
                [style]="{ width: '100%' }"
                appendTo="body"
                name="maritalStatus"
                formControlName="maritalStatus"
              >
              </p-dropdown>
            </div>

            <div
              class="form-control"
              style="min-width: 100px !important;"
            >
              <label for="name">{{ "Matrícula" | translate }}</label>
              <input
              pInputText
                type="text"
                name="codRegistration"
                formControlName="codRegistration"
                [readonly]="true"
              >
            </div>

            <div
            class="form-control"
            style="min-width: 100px !important;"
          >
            <label for="name">{{ "Grupo de Acesso" | translate }}</label>
            <input
              pInputText
              type="text"
              name="accessGroupName"
              formControlName="accessGroupName"
              [readonly]="true"
            >
          </div>

          </div>
        </div>
      </div>
    </p-fieldset>
    <br />
    <p-fieldset class="operation-date" >
      <p-header>
        <div>{{ "ADDRESS" | translate }}</div>
      </p-header>
      <app-address [address]="address" (finishEvent)="receiveAddress($event)">
      </app-address>
    </p-fieldset>
    <br />

    <div class="form-group align-center">
      <div class="buttons">
        <button type="button" (click)="close()" class="easymarine danger">
          {{ "CANCEL" | translate }}
        </button>
        <button class="easymarine primary" (click)="save()">
          {{ "SAVE" | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
