import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SlingConfig } from 'src/app/models';
import { MovementPlanByObjectiveCreateDTO } from 'src/app/models/dtos/movements/movement-plan-by-objective-createDTO';
import { BoatProfile } from '../../../../models/boat-profile';

@Component({
  selector: 'app-movement-data',
  templateUrl: './movement-data.component.html',
  styleUrls: ['./movement-data.component.scss'],
})
export class MovementDataComponent implements OnInit {
  @Input()
  readonly isNew: boolean;

  @Input()
  readonly movementPlan: MovementPlanByObjectiveCreateDTO;

  @Input()
  readonly slingConfig: SlingConfig;

  @Output()
  finishEvent: EventEmitter<any> = new EventEmitter();
  @Output()
  cancelPageEvent: EventEmitter<void> = new EventEmitter();

  public formData: UntypedFormGroup;

  constructor() {}

  ngOnInit(): void {
    const customerValue = this.movementPlan?.customer
      ? this.movementPlan.customer
      : null;
    const boatValue = this.movementPlan?.boat ? this.movementPlan.boat : null;
    const sailorValue = this.movementPlan?.sailor
      ? this.movementPlan.sailor
      : null;
    const movementLocationOrigin = this.movementPlan?.movementLocationOrigin
      ? this.movementPlan.movementLocationOrigin
      : null;
    const vacancyOrigin = this.movementPlan?.vacancyOrigin
      ? this.movementPlan.vacancyOrigin
      : null;
    this.formData = new UntypedFormGroup({
      customer: new UntypedFormControl(
        { value: customerValue, disabled: !this.isNew },
        [Validators.required]
      ),
      boat: new UntypedFormControl({ value: boatValue, disabled: !this.isNew }, [
        Validators.required,
        this.boatCurrentLocalizationValidators,
        this.boatMovementGroupValidators,
        this.boatMomentLocationAndProfileValidators ]),
      sailor: new UntypedFormControl(sailorValue),
      movementLocationOrigin: new UntypedFormControl(movementLocationOrigin, [
        Validators.required,
      ]),
      vacancyOrigin: new UntypedFormControl(vacancyOrigin, [
        this.verifyIfMovementLocationHasVacancy,
      ]),
    });
    this.formData?.controls?.boat.valueChanges.subscribe((item) => {
      if (item?.boatProfile === 'EVENTUAL' || item?.boatProfile ==='WORKSHOP') {
        this.formData?.controls?.movementLocationOrigin.enable();
        this.formData?.controls?.vacancyOrigin.enable();
      } else {
        this.formData?.controls?.movementLocationOrigin.disable();
        this.formData?.controls?.vacancyOrigin.disable();
      }
    });
  }
  verifyIfMovementLocationHasVacancyControl(): boolean{
    const vacancyControl =
      this.formData?.controls?.movementLocationOrigin?.value?.vacancyControl;
    if (vacancyControl) { return true; }
    else { return false; }
  };
  verifyIfMovementLocationHasVacancy = (control: UntypedFormControl) => {
    const vacancy = control?.value;
    if (this.verifyIfMovementLocationHasVacancyControl()) {
      if (!vacancy){
        return { boatVacancyGroupEmpty: true };
      }
    }
    return null;
  };
  verifyIfVacancyFormGroupHasError(){
    return (
      this.formData &&
      this.formData.controls?.vacancyOrigin?.errors?.boatVacancyGroupEmpty
    );
  }
  verifyIfboatHasNotCurrentLocation() {
    if (this.formData?.controls?.boat?.value?.boatProfile === 'RECURRENT') {
      return (
        this.formData &&
        this.formData.controls?.boat?.errors?.boatCurrentLocationEmpty
      );
    }
  }
  boatCurrentLocalizationValidators = (control: UntypedFormControl) => {
    const boat = control?.value;
    if (boat) {
      if (!boat.currentLocation && boat?.boatProfile === 'RECURRENT') {
        return { boatCurrentLocationEmpty: true };
      }
    }
    return null;
  };

  verifyIfboatHaveLocationAndProfile() {
    return this.formData && this.formData.controls?.boat?.errors?.boatMomentLocationAndProfileEmpty;
  }

  boatMomentLocationAndProfileValidators = (control: UntypedFormControl) => {
    const boat = control.value
    if (boat) {
      if (!boat.movementLocation && boat?.boatProfile =="RECURRENT") {
        return { boatMomentLocationAndProfileEmpty: true }
      }
    }
    return null;
  }

  verifyIfboatHasNotMovementGroup() {
    return (
      this.formData &&
      this.formData.controls?.boat?.errors?.boatMovementGroupEmpty
    );
  }

  boatMovementGroupValidators = (control: UntypedFormControl) => {
    const boat = control.value;
    if (boat) {
      if (!boat.movementGroup) {
        return { boatMovementGroupEmpty: true };
      }
    }
    return null;
  };
  next() {
    this.finishEvent.emit(this.formData.value);
  }

  cancel() {
    this.cancelPageEvent.emit();
  }

  aguarda(){}
}
