import { Marina } from '../marina';
import { SignerType } from '../enums/signerType';


export class SignerDocumentResquestDTO {
    documentId: number;
    marina: Marina;
    email: string;
    name: string;
    federalId: string;
    birthday: Date;
    signerType: SignerType;

    constructor(documentId: number, marina: Marina, email: string, name: string,
        federalId: string, birthday: Date, signerType: SignerType) {
        this.documentId = documentId;
        this.marina = marina;
        this.email = email;
        this.name = name;
        this.federalId = federalId;
        this.birthday = birthday;
        this.signerType = signerType;
    }
}
