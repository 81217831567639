<p-panel class="dialog-form">
    <p-header>
        <h3>Detalhes do erro</h3>
        <i (click)="dialog.close()" class="pi pi-times"></i>
    </p-header>

    <p-table *ngIf="messages" [value]="messages" class="primeTable" width="100%" autoLayout="true">
        <ng-template pTemplate="header">
            <tr>
                <th class="text-center">Mensagem</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-message>
            <tr>
                <td class="text-left">
                    {{ message }}
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-panel>
