<p-fieldset>
    <p-header class="article-title">
        <div class="article-title">{{'GENERAL-DATA' | translate}}</div>
    </p-header>
    <div class="content-log">
        <div>
            <label for="barCode">{{ 'BAR-CODE' | translate }}</label>
            <app-log-field [old]="productLog.oldDatas.barCode" [field]="productLog.barCode" [existsOld]="oldDatasMap.has('barCode')" [isNew]="isNew"></app-log-field>
        </div>
        <div>
            <label for="name">{{ 'NAME' | translate }}</label>
            <app-log-field [old]="productLog.oldDatas.name" [field]="productLog.name" [existsOld]="oldDatasMap.has('name')" [isNew]="isNew"></app-log-field>
            
        </div>
        <div>
            <label for="productType">{{ 'TYPE' | translate }}</label>
            <app-log-field [translate]="true" [old]="productLog.oldDatas.productType" [field]="productLog.productType" [existsOld]="oldDatasMap.has('productType')" [isNew]="isNew"></app-log-field>
        </div>
        <div>
            <label for="productCategory">{{ 'CATEGORY' | translate }}</label>
            <label [ngClass]="{'item-new-log': oldDatasMap.has('productCategory') && isNew, 'item-old-log': oldDatasMap.has('productCategory') && !isNew}">
                {{ oldDatasMap.has('productCategory') && !isNew ? productLog.oldDatas.productCategory.name: productLog.productCategory.name }}
            </label>
        </div>
        <div *ngIf="isFuel()">
            <label for="fuel">{{ 'FUEL' | translate }}</label>
            <app-log-field [old]="productLog.oldDatas.fuel" [field]="productLog.fuel" [existsOld]="oldDatasMap.has('fuel')" [isNew]="isNew"></app-log-field>
        </div>
        <div>
            <label for="measurement">{{ 'MEASUREMENT' | translate }}*</label>
            <app-log-field [translate]="true" [old]="productLog.oldDatas.measurement" [field]="productLog.measurement" [existsOld]="oldDatasMap.has('measurement')" [isNew]="isNew"></app-log-field>
        </div>
        <div>
            <label for="chartAccountId">{{ 'CHART-ACCOUNTS' | translate }}</label>
            <app-log-field [old]="getAccountName(productLog.oldDatas.chartAccountId)" [field]="getAccountName(productLog.chartAccountId)" [existsOld]="oldDatasMap.has('chartAccountId')" [isNew]="isNew"></app-log-field>
        </div>
        <div *ngIf="isTypeProduct()">
            <label for="orderControl">{{ 'Controle' | translate }}</label>
            <app-log-field [translate]="true" [old]="productLog.oldDatas.orderControl" [field]="productLog.orderControl" [existsOld]="oldDatasMap.has('orderControl')" [isNew]="isNew"></app-log-field>
        </div>
        <div *ngIf="isTypeProduct()">
            <label>{{'Mostrar no App' | translate }}</label>
            <label [ngClass]="{'item-new-log': isChangedShowApp() && isNew, 'item-old-log': isChangedShowApp() && !isNew}">
                {{ (getShowApp()?'YES':'NO') | translate}}
              </label>
        </div>
        <div *ngIf="!isTypeProduct()">
            <label> Emite recibo </label>
            <label [ngClass]="{'item-new-log': isChangedIssueReceipt() && isNew, 'item-old-log': isChangedIssueReceipt() && !isNew}">
                {{ (getIssueReceipt()?'YES':'NO') | translate}}
              </label>
        </div>
        <div *ngIf="!isTypeProduct()">
            <label> Emite nota fiscal </label>
            <label [ngClass]="{'item-new-log': isChangedIssueNotaFiscal() && isNew, 'item-old-log': isChangedIssueNotaFiscal() && !isNew}">
                {{ (getIssueNotaFiscal()?'YES':'NO') | translate}}
              </label>
        </div>
        <div>
            <label>{{ "STATUS" | translate }}</label>
            <label [ngClass]="{'item-new-log': isChangedStatus() && isNew, 'item-old-log': isChangedStatus() && !isNew}">
              {{ (getStatus()? 'ACTIVE':'INACTIVE') | translate}}
            </label>
          </div>
    </div>
</p-fieldset>
<br>