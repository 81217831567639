import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable()
export class InvoiceReceiptService {

    constructor(private http: HttpClient) {
    }

    generate(invoiceIds: number[]): Observable<any> {
        return this.http.post(
            environment.apiHost + '/secure/invoice-receipt/generate/', invoiceIds).pipe(map((response: any) => response.data));
    }

}