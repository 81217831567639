import { map } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MovementLocation } from 'src/app/models/movement/movement-location';
import { MovementLocationFilterListDTO } from 'src/app/models/dtos/movements/movement-location-filter-listDTO';

@Injectable({
  providedIn: 'root'
})
export class MovementLocationService {
  private readonly API_URL = environment.apiHost + '/secure/movement/movement-location'

  constructor(private http: HttpClient) { }

  getAll(campos?:string): Observable<MovementLocation[]> {
    if (campos==undefined){ campos="";}
    let headers = new HttpHeaders();
    headers = headers.set('campos', campos);
    return this.http.get( this.API_URL, ({headers: headers}))
     .pipe(map((response: any) => response.data.movementLocations as MovementLocation[]));
  }

  updateVacancyControl(locationId: number, vacancyControl): Observable<void> {
    return this.http.put( this.API_URL + '/vacancy-control', { id: locationId, vacancyControl})
      .pipe(map((response: any) => {}));
  }

  getMovementLocationFilterList(): Observable<MovementLocationFilterListDTO[]> {
    return this.http.get(this.API_URL + '/filter-list/').pipe(map((response: any) => response.data.movementLocations as MovementLocationFilterListDTO[]));
  }

  updatePrefixUse(locationId: number, prefixUse): Observable<void> {
    return this.http.put( this.API_URL + '/prefix-use', { id: locationId, prefixUse})
      .pipe(map((response: any) => {}));
  }
}
