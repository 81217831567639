  <p-fieldset class="full">
    <p-header class="article-title">
      <div class="article-title">{{ "ACCESSORIES" | translate }}</div>
    </p-header>
    <div class="acessories-list">
      <div
        class="accessoryBoat-card"
        *ngFor="let accessory of logsTable()"
        [ngClass]="{'item-new-log': isChanged(accessory) && isNew, 'item-old-log': isChanged(accessory) && !isNew}"
      >
        <img src="{{ accessory?.accessory?.urlPhoto }}" class="imagem" />
        <div class="accessoryBoat-card-description">
          {{ accessory?.accessory?.name }}
        </div>
      </div>
    </div>
  </p-fieldset>
  <br />