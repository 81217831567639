import { Invoice } from './invoice';
import { Marina } from './marina';
import { MarinaCompany } from './marina-company';

export class InvoiceTaxDocument {
  id: number;
  nfeId: string;
  empresaId: string;
  nfeNumero: string;
  nfeStatus: string;
  status: string;
  marina: Marina;
  marinaCompany: MarinaCompany;
  invoice: Invoice;
  aliquotaIss: number;
  valorTotal: number;
  createDate: any;
  updateDate: any;
  federalId: string;
  type: string;
  serviceDocument: string;
  serviceDocumentXml: string;

  constructor() {

  }
}
