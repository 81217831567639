<p-fieldset *ngIf="marina?.marinaCompanies?.length > 0">
    <p-header class="article-title">
        <div class="article-title">Empresa Faturamento</div>
    </p-header>
    <div class="content-log">
        <div>
            <label>{{ 'Empresa Faturamento' }}</label>
            <label [ngClass]="{'item-new-log': isChangedBillingCompany() && isNew, 'item-old-log': isChangedBillingCompany() && !isNew}">
                {{ isChangedBillingCompany() && isNew ? getBillingCompany(customerLog.billingCompanyLog): getBillingCompany(customerLog.oldDatas.billingCompanyLog) }}
            </label>
        </div>
    </div>
</p-fieldset>
<br>