import { Marina } from './marina';

export class Notice {
    id: number;
    title: string;
    description: string;
    initialDateTime: any;
    finalDateTime: any;
    noticeTargetType: string;
    targets: string[] = [];
    linkNotice: string;
    marina: Marina = new Marina();
    noticeType: string;
    noticeOrigin: string;
    urlImage: string;
    destinationType: string;
    imageBase64: string;
    imageType: string;
    imageName: string;

    constructor() {
        this.marina = new Marina();
        this.noticeOrigin = 'MARINA';
    }
}
