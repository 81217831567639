import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileUpload } from 'primeng/fileupload';
import { Invoice } from 'src/app/models/invoice';
import { InvoiceService } from 'src/app/services/invoice.service';
import { MessageUtil } from 'src/app/utils/message.util';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-invoice-upload-nf',
  templateUrl: './invoice-upload-nf.component.html',
  styleUrls: ['./invoice-upload-nf.component.scss'],
})
export class InvoiceUploadNfComponent implements OnInit {
  @ViewChild('upload', { static: false }) upload: FileUpload;

  fileXml: File;
  filePdf: File;
  files: any[];

  invoice: Invoice;

  constructor(
    private dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private spinner: NgxSpinnerService,
    private invoiceService: InvoiceService,
    private messageUtil: MessageUtil
  ) {}

  ngOnInit(): void {
    this.invoice = new Invoice();
    this.invoice = this.config.data.invoice;
  }

  setFileXml(file): void {
    this.fileXml = file[0];
    this.fileXml.name;
    this.upload.clear();
  }

  setFilePdf(file): void {
    this.filePdf = file[0];
    this.filePdf.name;
    this.upload.clear();
  }

  generateDocument() {
    if (this.fileXml) {
      Swal.fire({
        title: 'Danfe',
        text: 'Confirma a Importação da nota.',
        showCancelButton: true,
        width: 600,
        icon: 'question',
        showConfirmButton: true,
        focusCancel: true,
        reverseButtons: true,
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.sendFiles();
        }
      });
    }
  }

  sendFiles(): void {
    this.spinner.show();
    this.invoiceService
      .importNfToInvoice(this.fileXml, this.filePdf, this.invoice.id)
      .subscribe({
        next: (_) => {
          Swal.fire({
            title: 'Danfe',
            text: 'Importação realizada.',
            width: 600,
            icon: 'success',
            showConfirmButton: true,
            reverseButtons: true,
            confirmButtonText: 'Fechar',
          }).then();
          this.dialogRef.close();
        },
        error: (error) => {
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(
            exception.type,
            exception.title,
            exception.message
          );
          this.spinner.hide();
        },
        complete: () => {
          this.spinner.hide();
        },
      });
  }

  cancel() {
    this.dialogRef.close();
  }
}
