<div class="table">
  <form *ngIf="form" [formGroup]="form" (submit)="saveAll()">
    <div formArrayName="days">
      <p-table *ngIf="days" [value]="days.controls" autoLayout="true">
          <ng-template pTemplate="header">
            <tr>
              <th colspan="2" rowspan="2">
                  Dia Semana
              </th>
              <th colspan="2" rowspan="2" class="text-center">Horário Marina</th>
              <th colspan="5" class="text-center">Horário Fila</th>
              <th rowspan="2">Intervalo</th>
              <th rowspan="2"></th>
            </tr>
            <tr>
              <th>Início</th>
              <th>Intervalo Início</th>
              <th>Intervalo Fim</th>
              <th>Fim</th>
              <th>Retorno</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-time let-i="rowIndex">
              <tr [formGroupName]="i">
                  <td colspan="2">
                      <p-checkbox formControlName="inOperation"
                        [binary]="true"
                        [name]="'inOperation' + time.id">
                      </p-checkbox>
                      {{ time.value.operatingDay.dayWeek | translate }}
                  </td>
                  <td colspan="2">
                    <div *ngIf="time.value.operatingDay.inOperation;
                      then marinaOperatingDay
                      else marinaClosed"
                    ></div>
                    <ng-template #marinaOperatingDay>
                      {{time.value.operatingDay.operationStart}} - {{time.value.operatingDay.operationEnd}}
                    </ng-template>
                    <ng-template #marinaClosed>
                      <span>Marina fechada</span>
                    </ng-template>
                  </td>
                  <td>
                      <input pInputText type="time" tooltipPosition="bottom"
                          [name]="'start' + time.id"
                          formControlName="start"/>
                  </td>
                  <td>
                      <input pInputText type="time" tooltipPosition="bottom"
                          [name]="'intervalStart' + time.id"
                          formControlName="intervalStart"/>
                  </td>
                  <td>
                      <input pInputText type="time" tooltipPosition="bottom"
                          [name]="'intervalEnd' + time.id"
                          formControlName="intervalEnd"/>
                  </td>
                  <td>
                      <input pInputText type="time" tooltipPosition="bottom"
                          [name]="'end' + time.id"
                          formControlName="end"/>
                  </td>
                  <td>
                      <input pInputText type="time" tooltipPosition="bottom"
                          [name]="'returnEnd' + time.id"
                          formControlName="returnEnd"/>
                  </td>
                  <td>
                      <input pInputText type="number" style="width: 100%; max-width: 100px;" tooltipPosition="bottom"
                          [name]="'executionTime' + time.id"
                          formControlName="executionTime"/>
                  </td>
                  <td>
                    <button class="fab-button primary"
                      type="button"
                      (click)="openModalCopy(time)"
                      pTooltip="Copiar configuração para outros dias"
                      tooltipPosition="bottom"
                      [disabled]="!time.value.operatingDay.inOperation"
                    >
                      <i class="pi pi-copy"></i>
                    </button>
                  </td>
                </tr>
                <tr *ngIf="time?.errors">
                  <td colspan="11">
                    <app-messages *ngIf="time?.errors?.NotIsBeforeTime"
                      [titleMessage]="'Erro(' + (time.value.dayWeek | translate) + '):'"
                      [textMessage]="'A hora de abertura deve ser maior que a de fechamento!'">
                    </app-messages>
                  </td>
                </tr>
          </ng-template>
      </p-table>
      <div class="buttons">
        <button class="danger easymarine" type="button" (click)="close()" >{{'CANCEL' | translate}}</button>
        <button class="primary easymarine" type="submit" [disabled]="!form.valid">{{'SAVE' | translate}}</button>
      </div>
    </div>
  </form>
</div>
