import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-danfe-upload',
  templateUrl: './danfe-upload.component.html',
  styleUrls: ['./danfe-upload.component.scss']
})
export class DanfeUploadComponent implements OnInit, OnChanges {

  @Input()
  uploads: any[];

  constructor(
    public dialog: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {
    this.uploads = this.config.data.uploads;
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.uploads) {
      this.uploads = changes.files.currentValue;
    }
  }

}
