<p-fieldset>
    <p-header class="article-title">
        <div class="article-title">Habilitação</div>
    </p-header>
    <div class="content-log">
        <div>
            <label>{{ 'REGISTER' | translate }}</label>
            <label [ngClass]="{'item-new-log': isChangedRegister() && isNew, 'item-old-log': isChangedRegister() && !isNew}">
                {{ isChangedRegister() && isNew ? customerLog.licence.register: customerLog.oldDatas.licence.register }}
            </label>
        </div>
        <div>
            <label>{{ 'Validade' | translate }}</label>
            <label [ngClass]="{'item-new-log': isChangedExpiration() && isNew, 'item-old-log': isChangedExpiration() && !isNew}">
                {{ isChangedExpiration() && isNew ?customerLog.licence.expiration: customerLog.oldDatas.licence.expiration }}
            </label>
        </div>
        <div>
            <label>{{ 'Tipo de habilitação' | translate }}</label>
            <label [ngClass]="{'item-new-log': isChangedTypeLicense() && isNew, 'item-old-log': isChangedTypeLicense() && !isNew}">
                {{ isChangedTypeLicense() && isNew ?customerLog.licence.typeLicense: customerLog.oldDatas.licence.typeLicense }}
            </label>
        </div>
        <div>
            <label>{{ 'COUNTRY' | translate }}</label>
            <label [ngClass]="{'item-new-log': isChangedCountry() && isNew, 'item-old-log': isChangedCountry() && !isNew}">
                {{ isChangedCountry() && isNew ?customerLog.licence.country: customerLog.oldDatas.licence.country }}
            </label>
        </div>
        <div>
            <label>{{ 'FS' | translate }}</label>
            <label [ngClass]="{'item-new-log': isChangedState() && isNew, 'item-old-log': isChangedState() && !isNew}">
                {{ isChangedState() && isNew ?customerLog.licence.state: customerLog.oldDatas.licence.state }}
            </label>
        </div>
        <div>
            <label>{{ 'CITY' | translate }}</label>
            <label [ngClass]="{'item-new-log': isChangedCity() && isNew, 'item-old-log': isChangedCity() && !isNew}">
                {{ isChangedCity() && isNew ?customerLog.licence.city: customerLog.oldDatas.licence.city }}
            </label>
        </div>
    </div>
</p-fieldset>
<br>
<p-fieldset>
    <p-header class="article-title">
        <div class="article-title">{{'IMAGE' | translate}}</div>
    </p-header>
    <div class="content-log">
        <div [ngClass]="{'item-new-log': isChangedFile() && isNew, 'item-old-log': isChangedFile() && !isNew}">
            <img src="{{ isChangedFile() && isNew ?customerLog.licence.file: customerLog.oldDatas.licence.file }}" width="128">
        </div>
    </div>
</p-fieldset>
<br>