import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LocationMovementAdjustmentDTO } from 'src/app/models/dtos/movements/movement-management/location-movement-adjustmentDTO';
import { VerificationMovementAdjustmentRspDTO } from 'src/app/models/dtos/movements/movement-management/verification-movement-adjustment-rspDTO';

@Component({
  selector: 'app-verification-movement-adjustment-dialog',
  templateUrl: './verification-movement-adjustment-dialog.component.html',
  styleUrls: ['./verification-movement-adjustment-dialog.component.scss']
})
export class VerificationMovementAdjustmentDialogComponent implements OnInit {

  changeBoatLocation: boolean;
  
  changeMovementPlanId: number;
  verificationAdjustmentRspDTO: VerificationMovementAdjustmentRspDTO;
  vancacies: any[] = []
  vacancyId: number;
  location: LocationMovementAdjustmentDTO;
  totalMovements: number;

  messageConfirm: string;

  constructor(
    public dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
  ) { }

  ngOnInit(): void {
    if (this.config.data) {

      this.verificationAdjustmentRspDTO = this.config.data.verificationAdjustmentRspDTO;
      this.totalMovements = this.config.data.totalMovements;

      this.generateMessageConfirm()
      if(!this.ajustmentEnableChangeLocation()) {
        this.changeBoatLocation = false;
        return;
      }

      this.location = this.verificationAdjustmentRspDTO.locationAdjustmentDTO

      if(this.verificationAdjustmentRspDTO.vacancies?.length > 0) {
        this.vancacies = this.verificationAdjustmentRspDTO.vacancies.map(vacancy => ({ label: vacancy.code, value: vacancy.id }));
        this.vancacies.unshift({label: "Sem vaga", value: null});
      }

    }
  }

  isValid(): boolean {
    return !this.ajustmentEnableChangeLocation() || this.changeBoatLocation != undefined && (!this.requiredVacancy() || (this.requiredVacancy() && !!this.vacancyId));
  }

  save() {
    this.dialogRef.close({
      changeBoatLocation: this.changeBoatLocation,
      vacancyId: this.vacancyId,
      changeMovementPlanId: this.changeMovementPlanId
    })

  }

  changeLocation() {
    this.changeBoatLocation = true;
    if(this.verificationAdjustmentRspDTO.boatVacancy) {
      this.vacancyId = this.verificationAdjustmentRspDTO.boatVacancy.id
    }
    this.changeMovementPlanId = this.verificationAdjustmentRspDTO.movementPlanId;
  }

  notChangeLocation() {
    this.changeBoatLocation = false;
    this.vacancyId = null;
    this.changeMovementPlanId = null;
  }

  ajustmentEnableChangeLocation(): boolean {
    return this.verificationAdjustmentRspDTO?.enableChangeCurrentLocation;
  }

  requiredVacancy():boolean {
    return this.verificationAdjustmentRspDTO?.locationAdjustmentDTO?.vacancyControl;
  }

  generateMessageConfirm() {
    let textMovementSize;
    if(this.totalMovements > 1) {
      textMovementSize = " movimentações"
    } else {
      textMovementSize = " movimentação"
    }

    this.messageConfirm = `Deseja adicionar ${this.totalMovements}${textMovementSize} de ajuste?`;
  }

}
