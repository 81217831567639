import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { MessageUtil } from '../utils/message.util';

@Injectable()
export class ToastService {

    constructor(
        private messageService: MessageService,
        private messageUtil: MessageUtil
    ) {

    }

    public success(detail: string): void {
        this.messageService.add({ severity: 'success', summary: this.messageUtil.translateKey('SUMMARY.SUCCESS'), detail });
    }

    public warning(detail: string): void {
        this.messageService.add({ severity: 'warn', summary: this.messageUtil.translateKey('SUMMARY.WARNING'), detail });
    }

    public error(detail: string): void {
        this.messageService.add({ severity: 'error', summary: this.messageUtil.translateKey('SUMMARY.ERROR'), detail });
    }

    public stickyWarning(detail: string): void {
      this.messageService.add({severity: 'warn', summary: this.messageUtil.translateKey('SUMMARY.WARNING'), detail, sticky: true})
    }
    public clearAllToasts(): void{
      this.messageService.clear();
    }
}
