import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { BroadcastService } from '../services/broadcast.service';

@Directive({
  selector: '[appFiltersButton]'
})
export class FiltersButtonDirective implements OnInit {

  innerWidth: number;
  originalDisplay: string;
  showFilters = true;

  @HostListener('click', ['$event']) onClick(): void {
    BroadcastService.get('showFilters').emit(!this.showFilters);
  }

  constructor(
    private elRef: ElementRef
  ) {
  }

  ngOnInit(): void {
    BroadcastService.get('showFilters').subscribe((showFilters) => {
      this.showFilters = showFilters;
      this.elRef.nativeElement.children[0].className = showFilters ? 'pi pi-filter-slash' : 'pi pi-filter';
    });
    setTimeout(() => {
      if (window.innerWidth <= 770) {
        BroadcastService.get('showFilters').emit(false);
      } else {
        BroadcastService.get('showFilters').emit(true);
      }
    }, 1500);
  }

}
