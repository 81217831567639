<div class="table">
  <p-table #tt [value]="userLogs" [resizableColumns]="true" [autoLayout]="true" [paginator]="true"
  [rows]="numberOfRows" [pageLinks]="3" [rowsPerPageOptions]="isDialog? null : [10,20,30,50,100]">
      <ng-template pTemplate="header">
          <tr>
              <th>Data/hora</th>
              <th>Usuário</th>
              <th>Origem</th>
              <th>Nome</th>
              <th>Sobrenome</th>
              <th>Permissão</th>
              <th>Status</th>
              <th>Ação</th>
              <th>Visualizar</th>

          </tr>
      </ng-template>

      <ng-template pTemplate="body" let-userLog>
          <tr>
              <td>{{userLog.logTime | date: 'dd/MM/yyyy - HH:mm'}}</td>
              <td [pTooltip]="userLog.user ? userLog.user.fullName : ''" tooltipPosition="top">{{userLog.user ? userLog.user.username : ''}}</td>
              <td>{{userLog.origin}}</td>
              <td>{{userLog.firstName}}</td>
              <td>{{userLog.lastName}}</td>
               <td>{{userLog.roleId?.name}}</td>
               <td> {{check(userLog.active)}} </td>
              <td>{{userLog.operationLog | translate}}</td>
              <td>
                  <div class="table-action" (click)="openUserLogDialog(userLog)" pTooltip="Visualizar" *ngIf="userLog.oldDatas"
                      tooltipPosition="bottom">
                      <i class="pi pi-eye"></i>
                  </div>
              </td>
              <!-- -->
          </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
          <tr>
              <td colspan="7">
                  Nenhum log encontrado
              </td>
          </tr>
      </ng-template>

  </p-table>
</div>
