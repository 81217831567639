<p-table [value]="movementExecutionLocations" styleClass="sling-table" autoLayout="true">
    <ng-template pTemplate="header">
        <tr>
          <th class="text-center">Prev.</th>
          <th class="text-center">Mov.</th>
          <th class="text-center">Grupo</th>
          <th class="text-center">Objetivo</th>
          <th class="text-center">Embarcação</th>
          <th class="text-center">Modelo</th>
          <th class="text-center">Local Atual</th>
          <th class="text-center">Destino</th>
          <th class="text-center">Sent.</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-movement>
        <tr appBoatMovementRow [boatMovementRow]="movement">
            <td class="text-center">
              <app-movement-expected-date [params]="{ date: movement.date}"></app-movement-expected-date>
            </td>
            <td class="text-center">
              <app-movement-means-icon-url [params]="{ iconUrls: movement?.movementMeanIconUrlList }">
              </app-movement-means-icon-url>
            </td>
            <td class="text-center">
              <span>{{movement.movementGroupCode}}</span>
            </td>
            <td class="text-center">
              <span>{{movement?.movement?.objective | translate}}</span>
            </td>
            <td class="text-center">
                {{movement.boat.name }}
            </td>
            <td class="text-center">
                {{movement.boat.shipType.toUpperCase() | translate}}&nbsp;
                {{movement.boat.commercialLength}}'
            </td>
            <td>
              <app-movement-location [params]="getOrigin(movement)">
              </app-movement-location>
            </td>
            <td class="text-center">
              <app-movement-location *ngIf="movement?.movement" [params]="getDestiny(movement)"></app-movement-location>
            </td>
            <td class="text-center">
              <app-movement-arrow-direction *ngIf="movement?.arrowDirection" [darkMode]="false" [arrowDirection]="movement.arrowDirection"></app-movement-arrow-direction>
            </td>
        </tr>
    </ng-template>
</p-table>
