import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  @Input() isError = false;
  @Input() routerLink;
  @Input() clickable = false;

  @Output() clickEvent = new EventEmitter();

  styleClass;

  constructor() { }

  ngOnInit(): void {
    this.styleClass = this.isError ? ['error'] : ['warn'];
    if (this.routerLink === undefined && this.clickable === false) {
      this.styleClass.push('not-clickable');
    }
  }

  onClick(): void {
    this.clickEvent.emit();
  }
}
