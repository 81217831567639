import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-variables-contract-dialog',
  templateUrl: './variables-contract-dialog.component.html',
  styleUrls: ['./variables-contract-dialog.component.scss']
})
export class VariablesContractDialogComponent implements OnInit {

  variablesName;

  variables;

  editables;


  constructor(
    public dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
  ) { }

  ngOnInit(): void {
    if (this.config.data) {
      this.variables = this.config.data.variables;
    }

    this.variablesName = Object.keys(this.variables);
    this.editables = this.variablesName
      .filter((atribute) => this.isEmpty(atribute));
    this.editables.map(atribute => this.variables[atribute] = '');
  }

  isEmpty(atribute): boolean {
    return !this.variables[atribute] || this.variables[atribute] === '';
  }

  getValue(atributeName: string): string {
    return this.variables[atributeName];
  }

  isEditable(atribute: string): boolean {
    return !this.editables.includes(atribute);
  }

  sendDocument(): void {
    this.dialogRef.close(this.variables);
  }

}
