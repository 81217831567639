import { NoticeService } from 'src/app/services/notice.service';
import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-noticies-emails',
  templateUrl: './noticies-emails.component.html',
  styleUrls: ['./noticies-emails.component.scss']
})
export class NoticiesEmailsComponent implements OnInit {
  numberOfRows: number = 10;
  listEmails: any[] = [];
  noticie:any;
  constructor(private dialog: DialogService,
              private noticeService: NoticeService,
              private config: DynamicDialogConfig,) { }

  ngOnInit(): void {
    this.noticie = this.config.data.noticie;
    this.getUserList(this.noticie.id);
  }

  getUserList(id:number): void {
    this.noticeService.getListUser(id)
    .subscribe((data) => {
      this.listEmails = data;
    });
  }
}
