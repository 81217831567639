import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MarinaTermUse } from "../models/marina-term-use";

@Injectable()
export class MarinaTermUseService {

    constructor(
        private http: HttpClient
    ) { }

    save(termUse): Observable<MarinaTermUse> {
        return this.http.post(environment.apiHost + '/secure/term-use', termUse)
            .pipe(map((response: any) => response.data.termUse as MarinaTermUse));
    }

    get(): Observable<MarinaTermUse> {
        return this.http.get(environment.apiHost + '/secure/term-use')
            .pipe(map((response: any) => response.data.termUse as MarinaTermUse));
    }

}
