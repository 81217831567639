import { Empresa } from './empresa';

export class Usuario {
codigo: number;
nome: string;
login: string;
perfil: string;
empresa: Empresa;
bancoDados: string;
quantidadeColetaSolicitada?: number;
quantidadeTelegramaPendente?: number;
quantidadeFaturaPendente?: number;
quantidadeNotificacoesVesoesPortalPostal?: number;
quantidadeNotificacoesVesoesBoxCube?: number;
quantidadeNotificacoesVesoesConsolidador?: number;
acessosPortalPostal?: number[];
}
