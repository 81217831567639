import { BoatSailorPermission } from './../../../../../models/enums/boat-sailor-permission';
import { SailorService } from './../../../../../services/sailor.service';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Boat } from 'src/app/models';
import { BoatSailor } from 'src/app/models/boat-sailor';
import { MessageUtil } from 'src/app/utils/message.util';
import { Sailor } from 'src/app/models/sailor';

@Component({
  selector: 'app-boat-sailors-table',
  templateUrl: './boat-sailors-table.component.html',
  styleUrls: ['./boat-sailors-table.component.scss'],
})
export class BoatSailorsTableComponent implements OnChanges {
  private readonly permissionPrefixTranslate: string =
    'SAILOR-INFO.SAILOR-PERMISSION.';

  @Input("boat")boat: Boat;

  @Output()
  finishEvent: EventEmitter<any> = new EventEmitter();

  formulario: UntypedFormGroup;
  boatSailors: BoatSailor[];
  marinaSailor: any[] = [];
  marinaOrigin: any[] = [];
  permissionsOptions = [
    {
      label: this.messageUtil.translateKey(
        this.permissionPrefixTranslate + 'FUEL_REQUEST'
      ),
      value: BoatSailorPermission.FuelRequest,
    },
    {
      label: this.messageUtil.translateKey(
        this.permissionPrefixTranslate + 'PREPARE_BOAT'
      ),
      value: BoatSailorPermission.PrepareBoat,
    },
    {
      label: this.messageUtil.translateKey(
        this.permissionPrefixTranslate + 'SHOW_CONTACTS'
      ),
      value: BoatSailorPermission.ShowContacts,
    },
    {
      label: this.messageUtil.translateKey(
        this.permissionPrefixTranslate + 'SLING_CREATE'
      ),
      value: BoatSailorPermission.SlingCreate,
    },
    {
      label: this.messageUtil.translateKey(
        this.permissionPrefixTranslate + 'VIEW_BROWSING_HISTORY'
      ),
      value: BoatSailorPermission.ViewBrowsingHistory,
    },
    {
      label: this.messageUtil.translateKey(
        this.permissionPrefixTranslate + 'WASHING_AND_ENGINE_TURNING'
      ),
      value: BoatSailorPermission.WashingAndEngineTurn,
    },
    {
      label: this.messageUtil.translateKey(
        this.permissionPrefixTranslate + 'FRANCHISE_USE'
      ),
      value: BoatSailorPermission.Franchise_use,
    },
  ];

  constructor(
    private sailorService: SailorService,
    private formBuilder: UntypedFormBuilder,
    private messageUtil: MessageUtil
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.boat?.boatSailors) {
      this.boatSailors = this.boat.boatSailors.filter(
        (bSailor: BoatSailor) => bSailor.active !== false
      );
    } else {
      this.boatSailors = [];
    }
    this.formulario = this.formBuilder.group({
      boatSailors: this.buildSailor(),
    });
    this.getMarinaSailor();
    this.send();
  }

  getMarinaSailor(): void {
    this.sailorService.getSailorForMarineAndStatus('CADWEB,ACTIVE').subscribe(
      (data: any) => {
        this.loadOptionsSailors(data);
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      }
    );
  }

  loadOptionsSailors(data: any[]): void {
    this.fortmataCombo(data);
    this.marinaOrigin = data;
    this.mapearObjetos();
  }

  fortmataCombo(data: any[]): void {
    this.marinaSailor = data.map((obj) => ({
      label: obj.name + ' ' + obj.lastName,
      value: obj,
    }));
  }

  getBoatSailors(): BoatSailor[] {
    return this.boatSailors;
  }

  getPermissionsTooltip(boatSailor: BoatSailor): string {
    return boatSailor?.permission
      ?.map((p) =>
        this.messageUtil.translateKey(this.permissionPrefixTranslate + p)
      )
      .join(', ');
  }


  buildSailor() {
    const values = this.boatSailors.map((v) => new UntypedFormControl(v));
    return this.formBuilder.array(values);
  }

  getSailorsControls() {
    return this.formulario.get('boatSailors')
      ? (<UntypedFormArray>this.formulario.get('boatSailors')).controls
      : null;
  }


  mapearObjetos(): void {
    let remover: Sailor[] = (
      this.formulario.get('boatSailors') as UntypedFormArray
    ).value.map((x: { sailor: any }) => x.sailor);
    this.fortmataCombo(
      this.marinaOrigin.filter((s) => !remover.find((s2) => s.id === s2.id))
    );
  }

  selectSailor(rowIndex, dropSailor) {
    (this.formulario.get('boatSailors') as UntypedFormArray).controls[rowIndex]
      .get('sailor')
      .setValue(dropSailor.value);

    (this.formulario.get('boatSailors') as UntypedFormArray).controls[rowIndex]
      .get('beginAt')
      .setValue(new Date());

    (this.formulario.get('boatSailors') as UntypedFormArray).controls[rowIndex]
      .get('active')
      .setValue(true);

    (this.formulario.get('boatSailors') as UntypedFormArray).controls[rowIndex]
      .get('permission')
      .setValue(null);
    this.send();
    this.mapearObjetos();
  }

  send() {
    this.finishEvent.emit({ sailorList: this.formulario });
  }
}
