import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InvoicePaid } from '../models/invoice-paid';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class InvoicePaidService {

    constructor(private http: HttpClient) {
    }

    payInvoice(invoicePaid: InvoicePaid): Observable<any> {
        return this.http.post(
            environment.apiHost + '/secure/invoice-paid/pay-invoice/', invoicePaid).pipe(map((response: any) => response.data as any));
    }

    findByInvoiceNumber(invoiceNumber): Observable<InvoicePaid[]> {
        return this.http.get(environment.apiHost + '/secure/invoice-paid/find-by-invoice-number/' +
            invoiceNumber).pipe(map((response: any) => response.data.invoicesPaid));
    }

    cancelPayment(invoicePaid: InvoicePaid): Observable<any> {
        return this.http.delete(
            environment.apiHost + `/secure/invoice-paid/cancel-pay/${invoicePaid.id}`).pipe(map((response: any) => response.data as any));
    }

    payInvoiceFromBillet(invoicePaids: InvoicePaid[]): Observable<any[]> {
        return this.http.post(
            environment.apiHost + '/secure/invoice-paid/pay-invoice-from-billet/', invoicePaids
        ).pipe(map((response: any) => response.data.invoices as any[]));
    }
}
