import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MovementArrowDirectionComponent } from './movement-arrow-direction/movement-arrow-direction.component';
import { BrowserModule } from '@angular/platform-browser';
import { MovementDurationComponent } from './movement-duration/movement-duration.component';
import { MovementExpectedDateComponent } from './movement-expected-date/movement-expected-date.component';
import { MovementLocationComponent } from './movement-location/movement-location.component';
import { MovementTableUtil } from 'src/app/utils/movements/movement-table-util';
import { MovementMeansIconUrlComponent } from './movement-means-icon-url/movement-means-icon-url.component';



@NgModule({
  declarations: [
    MovementArrowDirectionComponent,
    MovementDurationComponent,
    MovementExpectedDateComponent,
    MovementLocationComponent,
    MovementMeansIconUrlComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule
  ],
  providers: [
    MovementTableUtil
  ],
  exports: [
    MovementArrowDirectionComponent,
    MovementDurationComponent,
    MovementExpectedDateComponent,
    MovementLocationComponent,
    MovementMeansIconUrlComponent,
  ]
})
export class MovementsOperationalModule { }
