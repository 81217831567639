import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-movements-config',
  templateUrl: './movements-config.component.html',
  styleUrls: ['./movements-config.component.scss']
})
export class MovementsConfigComponent implements OnInit {

  selectedTab = 0;

  constructor(

  ) { }

  ngOnInit(): void {

  }

  changeSelectedTab(index: number): void {
    this.selectedTab = index;
  }

}
