import { SailorService } from 'src/app/services/sailor.service';
import {FormControl, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Sailor } from 'src/app/models/sailor';
import { FederalIdType } from 'src/app/models/federal-id-type';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageUtil } from 'src/app/utils/message.util';
import moment from 'moment';
import { ValidatorsUtil } from 'src/app/services/validators.util';
import { AccessGroup } from 'src/app/models/dtos/access-controller/access-group';
import { AccessGroupService } from 'src/app/services/access-controler/access-group.service';
import { AccessGroupEnum } from 'src/app/models/dtos/access-controller/access-group-enum';

@Component({
  selector: 'app-sailor-dados',
  templateUrl: './sailor-dados.component.html',
  styleUrls: ['./sailor-dados.component.scss']
})
export class SailorDadosComponent implements OnInit {

  @Input()
  sailor:Sailor = new Sailor();

  @Input()
  readonlyForm:boolean;

  @Output()
  finishEvent: EventEmitter<any> = new EventEmitter();

  @Input()
  accessCategoryId: number;

  imgPreviewPhoto:string;
  imgPreviewDoc:string;
  imgPreviewDocVerso:string;

  private readonly permissionPrefixTranslate: string =
    'SAILOR-INFO.SAILOR-TYPE.';

  isNew:boolean = true;
  nameLabel = 'NAME';
  dateLabel = 'DATE-BIRTH';
  sailorForm = new UntypedFormGroup({});
  isFederalTypePhysical = true;
  registrationLabel = 'CPF'
  federalIdType = 'Physical';

  federalIdTypeRadioData = [
    { label: 'FEDERAL-ID.PHYSICAL', value: FederalIdType.Physical },
    { label: 'FEDERAL-ID.JURIDICAL', value: FederalIdType.Juridical }
  ];

  permissionsOptions = [
    {
      label: this.messageUtil.translateKey(
        this.permissionPrefixTranslate + 'PRIVATE_SAILOR'
      ),
      value: 'PRIVATE_SAILOR'
    },
    {
      label: this.messageUtil.translateKey(
        this.permissionPrefixTranslate + 'DIRT'
      ),
      value: 'DIRT'
    },
    {
      label: this.messageUtil.translateKey(
        this.permissionPrefixTranslate + 'AUXILIARY_SAILOR'
      ),
      value: 'AUXILIARY_SAILOR'
    },
    {
      label: this.messageUtil.translateKey(
        this.permissionPrefixTranslate + 'WATER'
      ),
      value: 'WATER'
    }
  ];
  accessCategoriesOptions: AccessGroup[] = [];
  accessCategorySailor: AccessGroup = new AccessGroup();
  constructor(
    private validatorsUtil: ValidatorsUtil,
    public dialogRef: DynamicDialogRef,
    private messageUtil: MessageUtil,
    private sailorService: SailorService,
    private accessCategoryService: AccessGroupService,
  ) { }

  ngOnInit(): void {
      if (this.sailor.id){
        this.isNew=false;
      }
      this.getAccessCategoriesOptions();
      this.startForm(this.sailor);
      this.imgPreviewPhoto = this.sailorForm.get('photoUrl').value;
      this.imgPreviewDoc = this.sailorForm.get('docUrl').value;
      this.imgPreviewDocVerso = this.sailorForm.get('docUrlBack').value;
      this.sailorForm.valueChanges.subscribe(change=>this.enviar());
      this.enviar();
  }

  enviar(){
    this.finishEvent.emit({ boatsDados: this.sailorForm });
  }

  validateFederalIdType(): void {
    if (this.federalIdType === FederalIdType.Physical) {
      this.isFederalTypePhysical = true;
      this.nameLabel = 'NAME';
      this.registrationLabel = 'PHYSICAL-REGISTRATION';
      this.dateLabel = 'DATE-BIRTH';
      this.sailorForm.get('birthday').setValidators(Validators.compose([Validators.required, this.majorityVerification]))
    } else {
      this.isFederalTypePhysical = false;
      this.nameLabel = 'COMPANY-NAME';
      this.registrationLabel = 'JURIDICAL-REGISTRATION';
      this.dateLabel = 'OPENING-DATE';
      this.sailorForm.get('birthday').setValidators(Validators.compose([Validators.required, null]))
    }
  }

  startForm(sailor?: Sailor): void {
    if (sailor?.id==null){
      sailor= new Sailor();
      this.isNew=true;
      this.federalIdType="Physical"
    }
    else{
      this.federalIdType=sailor?.federalIdType;
    }

    sailor.birthday = sailor?.birthday ? moment(sailor?.birthday).utc(true).toDate() : null;
    let nameFull: string =""
    if (sailor?.name!=null){
      nameFull=sailor.name;
    }
    if (sailor?.lastName){
      nameFull += " " +sailor.lastName;
    }

    this.sailorForm = new UntypedFormGroup({
      id: new UntypedFormControl(sailor?.id),
      federalIdType: new UntypedFormControl(sailor?.federalIdType, Validators.required),
      name: new UntypedFormControl(nameFull, Validators.required),
      federalId: new UntypedFormControl(sailor?.federalId, Validators.required),
      accessCategory: new FormControl<AccessGroup>(null, Validators.required),
      email: new UntypedFormControl(sailor?.user?.email, Validators.compose([Validators.required, Validators.email])),
      phone: new UntypedFormControl(sailor?.phone, Validators.required),
      birthday: new UntypedFormControl((sailor.birthday) as any, Validators.compose([Validators.required, (this.federalIdType === FederalIdType.Physical) ? this.majorityVerification : null])),
      typesSailor :  new UntypedFormControl(sailor?.typesSailor, Validators.required),
      residentialAddress:new UntypedFormGroup({
        id: new UntypedFormControl(sailor?.residentialAddress.id),
        zip: new UntypedFormControl(sailor?.residentialAddress.zip, Validators.required),
        country: new UntypedFormControl(sailor?.residentialAddress.country, Validators.required),
        state: new UntypedFormControl(sailor?.residentialAddress.state, Validators.required),
        city: new UntypedFormControl(sailor?.residentialAddress.city, Validators.required),
        district: new UntypedFormControl(sailor?.residentialAddress.district, Validators.required),
        street: new UntypedFormControl(sailor?.residentialAddress.street, Validators.required),
        number: new UntypedFormControl(sailor?.residentialAddress.number, Validators.required),
        complement: new UntypedFormControl(sailor?.residentialAddress.complement),
      }),
      emergencyContact: new UntypedFormControl(sailor?.emergencyContact),
      emergencyPhone: new UntypedFormControl(sailor?.emergencyPhone),
      observation: new UntypedFormControl(sailor?.observation),
      countryRegistration: new UntypedFormControl(sailor?.countryRegistration),
      photoUrl: new UntypedFormControl(sailor?.photoUrl),
      docUrl: new UntypedFormControl(sailor?.docUrl),
      docUrlBack: new UntypedFormControl(sailor?.docUrlBack),
      federalIdUrl: new UntypedFormControl(sailor?.federalIdUrl)
    });
    this.sailorForm.get('phone').setValue(sailor?.phone);
    this.sailorForm.get('federalId').setValue(sailor?.federalId);
    this.validateFederalIdType()
  }

  receiveAddress(receive: any){
      this.sailorForm.get('residentialAddress').setValue(receive.addressForm.value);
  }

  checkFederalIdField(isCPF: boolean): boolean {
    let numbers = this.sailorForm.value?.federalId?.replace(/[^\d]+/g, '');
    let validFederalId = false;
    if (isCPF && numbers?.length == 11) {
      validFederalId = this.validatorsUtil.cpfCnpjValidator(numbers);
    }
    if (!isCPF && numbers?.length == 14) {
      validFederalId = this.validatorsUtil.cpfCnpjValidator(numbers);
    }
    let federalIdControl = this.sailorForm.controls['federalId'] as UntypedFormControl;
    if (!validFederalId) {
      federalIdControl.setErrors({ invalidFederalId: true });
      federalIdControl.markAsTouched();
      federalIdControl.markAsDirty();
    }
      return validFederalId;
  }

  async federalIdInUse(isCPF: boolean): Promise<void> {
    if (this.checkFederalIdField(isCPF)) {
    return new Promise<void>(
      async (resolve) => {
        this.sailorService.getUserBySailorFederalId(this.sailorForm.get('federalId').value).subscribe(
          async (data) => {
            if (data !== null && data.id !== this.sailorForm.get('id').value) {
                this.dialogRef.close(data);
              resolve();
            }
          },
          async (err) => {
            resolve();
          }
        );
      });
    }
  }

  receiveUploadUrlPhoto($event){
    this.sailorForm.get("photoUrl").setValue($event);
    this.enviar();
  }

  receiveUploadUrlDoc($event){
    this.sailorForm.get("docUrl").setValue($event);
    this.enviar();
  }

  receiveUploadUrlDocVerso($event){
    this.sailorForm.get("docUrlBack").setValue($event);
    this.enviar();
  }

  majorityVerification(formControl: UntypedFormControl) {
    if (formControl.value) {
    let date: Date = formControl.value;
    let minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() -18);
      if (date>minDate) {
        return { date: true };
      }
    }
    return null;
  }
  private getAccessCategoriesOptions() {
    this.accessCategoryService.findAll().subscribe({
      next: value => {
        this.accessCategoriesOptions = value.filter(aC => aC.active === true && aC.type === AccessGroupEnum.SAILOR);
        if (this.accessCategoryId !== null){
          this.accessCategorySailor = this.accessCategoriesOptions.find(aC => aC.id == this.accessCategoryId);
        }else {
          this.accessCategorySailor = this.accessCategoriesOptions[0];
        }
        this.populateAccessCategoryForm()
      },error: (error) => {
        console.log(error);
      }
    })
  }

  populateAccessCategoryForm(){
    this.sailorForm.get("accessCategory").patchValue( this.accessCategorySailor)
  }
}
