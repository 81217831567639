import {ActivatedRoute} from '@angular/router';
import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {NgxSpinnerService} from 'ngx-spinner';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {FileUpload} from 'primeng/fileupload';
import {DocumentTemplate} from 'src/app/models/document-template';
import {VariableTemplate} from 'src/app/models/varible-template';
import {DocumentTemplateService} from 'src/app/services/document-template.service';
import {ToastService} from 'src/app/services/toast.service';
import {VariableTemplateService} from 'src/app/services/variable-template';
import {FormatUtil} from 'src/app/utils/format.util';
import {MessageUtil} from 'src/app/utils/message.util';
import DecoupledEditor from 'src/assets/javascripts/vendors/ckeditor';
@Component({
  selector: 'app-template-form',
  templateUrl: './template-form.component.html',
  styleUrls: ['./template-form.component.scss'],

  encapsulation: ViewEncapsulation.None
})
export class TemplateFormComponent implements OnInit {


  @ViewChild('upload', {static: false}) uploadBody: FileUpload;
  fileBody: File;

  editor = DecoupledEditor


  editTemp: any;
  readonly PPI_EQUAL_ONE_PXEL_RATIO = 96;
  readonly A4_WIDTH_PPI_WITHOUT_MARGIN = 7;

  template: DocumentTemplate = new DocumentTemplate();
  templateForm: UntypedFormGroup = new UntypedFormGroup({
    title: new UntypedFormControl('', Validators.required),
    text: new UntypedFormControl('', Validators.required),
  });
  text: string = '';
  isChangedText = true;

  positionCursor = 0;
  variblesTemplate: VariableTemplate[] = [];
  variblesTemplateSearch: VariableTemplate[] = [];
  texto: string = '';
  title: string = '';

  model;

  templateId: number;

  constructor(
    public dialogRef: DynamicDialogRef,
    private route: ActivatedRoute,
    private documentTemplateService: DocumentTemplateService,
    private toasty: ToastService,
    private variableTemplateService: VariableTemplateService,
    private spinner: NgxSpinnerService,
    private messageUtil: MessageUtil
  ) {

  }


  ngOnInit(): void {
    if (this.route.snapshot.params.id != 'undefined') {
      this.getTemplateById(this.route.snapshot.params.id);
    } else {
      this.template = new DocumentTemplate();
      this.findVariables();
      this.startForm();
    }
  }

  getTemplateById(id): void {
    this.documentTemplateService.getTemplateById(id).subscribe(
      async (data) => {
        this.template = data;
        this.findVariables();
        this.startForm();
      },
      async (err) => {
        this.spinner.hide();
        console.error(err);
      }
    );
  }

  startForm(): void {
    if (this.template) {
      this.title = this.template.title;
    }
    this.templateForm.get('title').setValue(this.title);
    this.templateForm.get('text').setValue(this.text);
    this.getTextDatasheet();
  }

  async getTextDatasheet() {
    if (this.template?.urlDatasheet) {
      try {
        this.templateForm.get('text').setValue(await this.getTextByUrl());
        this.templateForm.get('title').setValue(this.title);
        this.texto = this.templateForm.get('text').value;
        this.changeText('');
        this.isChangedText = false;
      } catch (error) {
        console.error(error);
      }
    }
  }


  async getTextByUrl(): Promise<string> {
    return new Promise<string>((resolve => {
      this.documentTemplateService.getTextDatasheet(this.template.urlDatasheet).subscribe(async (text) => {
          resolve(text);
        },
        (error) => {
          console.log('Não foi posivel recuperar a folha de rosto');
          resolve('');
        });
    }));
  }

  changeText(editor) {
    this.editTemp = editor.editor;
    this.isChangedText = true;
  }

  getWidthPaper(): number {
    return parseInt(((window.devicePixelRatio * this.PPI_EQUAL_ONE_PXEL_RATIO) * this.A4_WIDTH_PPI_WITHOUT_MARGIN).toString(), 0);
  }


  findVariables(): void {
    this.variableTemplateService.findAll().subscribe(
      (data) => {
        this.variblesTemplate = data;
        this.variblesTemplateSearch = data;
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      },
    );
  }

  filterVariable(event) {
    const value = FormatUtil.getNotAccents(event.target.value.toString()).toUpperCase();
    this.variblesTemplateSearch = this.variblesTemplate.filter(variable => FormatUtil.getNotAccents(variable.name).toUpperCase().includes(value));
  }

  onReady(editor: any) {
    this.editTemp= editor;
    this.model = editor.model
    editor.ui
      .getEditableElement()
      .parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }

  addVariable(texto) {
    this.model.change(writer => {
      const insertPosition = this.model.document.selection.getFirstPosition();
      writer.insertText(`[${texto}]`, insertPosition);
    });
  }

  async save() {
    await this.spinner.show();

    await Promise.all([
      this.uploadFileDatasheet(),
      this.uploadFileBody()
    ]);
    this.template.title = this.templateForm.get('title').value;

    this.documentTemplateService.save(this.template).subscribe(
      async (result) => {
        this.toasty.success('Salvo com sucesso.');
        this.spinner.hide();
      },
      async (err) => {
        this.spinner.hide();
        console.error(err);
      }
    );

  }

  validTemplate(): boolean {
    return this.templateForm.valid;
  }


  setFileBody(file): void {
    this.uploadBody.clear();
    this.fileBody = file[0];
  }

  async uploadFileDatasheet(): Promise<void> {
    if (!this.isChangedText) {
      return;
    }

    this.text = this.templateForm.get('text').value;
    const fileDatasheet = new File([this.text], 'template.txt', {type: 'text/html'});

    return new Promise<void>(
      async (resolve, reject) => {
        this.documentTemplateService.uploadFileDatasheet(fileDatasheet).subscribe(
          async (result) => {
            this.template.urlDatasheet = result.url;
            console.log(result);
            resolve();
          },
          async (err) => {
            console.log(err);
            reject();
          }
        );
      }
    );
  }

  async uploadFileBody(): Promise<void> {
    if (!this.fileBody) {
      return;
    }
    return new Promise<void>(
      async (resolve, reject) => {
        this.documentTemplateService.uploadFileBody(this.fileBody).subscribe(
          async (result) => {
            this.template.urlBody = result.url;
            console.log(result);
            resolve();
          },
          async (err) => {
            console.log(err);
            reject();
          }
        );
      }
    );
  }

  getFileName(): string {
    let name = '';
    if (this.fileBody?.name) {
      name = this.fileBody?.name;
    } else if (this.template?.urlBody) {
      name = this.template.urlBody.replace(/[^]*\//g, '');
    }

    return name;
  }

}
