<p-fieldset>
    <p-header class="article-title">
        <div class="article-title">{{ "SIGNERS-CONTRACT" | translate }}</div>
    </p-header>
    <div class="table">
        <p-table
          [value]="logsTable()"
          [rows]="5"
          [paginator]="true"
          autoLayout="true"
          alwaysShowPaginator="false"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>{{ "SIGNER" | translate }}</th>
              <th>{{'FEDERAL-ID.PHYSICAL' | translate}}</th>
              <th>{{ 'NAME' | translate }}</th>
              <th>{{ 'EMAIL' | translate }}</th>
              <th>{{ 'DATE-BIRTH' | translate }}</th>
            </tr>
          </ng-template>
          <ng-template
            pTemplate="body"
            let-signer
            let-rowIndex="rowIndex"
          >
            <tr [ngClass]="{'item-new-log': isChanged(signer) && isNew, 'item-old-log': isChanged(signer) && !isNew}">
              <td>
                  <span>
                      {{ signer.signerType | translate }}
                  </span>
              </td>
              <td>
                  <span>
                      {{ signer.federalId }}
                  </span>
              </td>
              <td>
                  <span>
                      {{ signer.name }}
                  </span>
              </td>
              <td>
                  <span>
                      {{ signer.email }}
                  </span>
              </td>
              <td>
                  <span>
                      {{signer.birthday | date:'dd/MM/yyyy'}}
                  </span>
              </td>
            </tr>
          </ng-template>
        </p-table>
    </div>
</p-fieldset>
<br>