<p-fieldset>
    <p-header class="article-title">
        <div class="article-title">{{'STOCK' | translate}}</div>
    </p-header>
    <div class="content-log">
        <div>
            <label>{{ 'STOCK' | translate }}</label>
            <app-log-field [old]="productLog.oldDatas.stock" [field]="productLog.stock" [existsOld]="oldDatasMap.has('stock')" [isNew]="isNew"></app-log-field>
        </div>
        <div>
            <label>{{ 'MINIMAL-STOCK' | translate }}</label>
            <app-log-field [old]="productLog.oldDatas.minimalStock" [field]="productLog.minimalStock" [existsOld]="oldDatasMap.has('minimalStock')" [isNew]="isNew"></app-log-field>
        </div>
        <div>
            <label>{{ 'APP-MINIMAL-STOCK' | translate }}</label>
            <app-log-field [old]="productLog.oldDatas.appMinimalStock" [field]="productLog.appMinimalStock" [existsOld]="oldDatasMap.has('appMinimalStock')" [isNew]="isNew"></app-log-field>
        </div>
    </div>
</p-fieldset>
<br>