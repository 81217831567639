import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Customer } from 'src/app/models';
import Swal from 'sweetalert2';
import { SalesStatesService } from '../split-bill/sales-states.service';
import { Subscription } from 'rxjs';
import { OrderData } from '../split-bill/sales-interfaces';
import { SelectorBoatDTO } from 'src/app/models/dtos/selector-boat-DTO';

@Component({
  selector: 'app-order-quota-select',
  templateUrl: './order-quota-select.component.html',
  styleUrls: ['./order-quota-select.component.scss']
})
export class OrderQuotaSelectComponent implements OnInit {
  @Output()
  onQuotaSelected:EventEmitter<any> = new EventEmitter<any>();
  
  canSave: boolean = false;
  invalidQuota: boolean;

  boat: SelectorBoatDTO;

  ownersSource: any[] = new Array();
  ownersTarget: any[] = new Array();
  
  customerSelected: Customer;
  
  orderData: OrderData;
  subscription: Subscription;

  constructor(
    private salesStatesService: SalesStatesService,
    public dialogRef: DynamicDialogRef,
  ) { }

  ngOnInit(): void {
    this.subscription = this.salesStatesService.orderData$.subscribe((_) => {
      this.orderData = this.salesStatesService.getOrderData();
    });
    this.boat = this.orderData.selectedBoat;
    this.customerSelected = this.orderData.selectedCustomer;
    this.getOwners();
  }

  getQuota(event, owner): void {
    const value = event.target.value;
    const index = this.ownersTarget.indexOf(owner);
    this.ownersTarget[index].singleOrderQuota = +value;
  }

  calculateQuota(): void {
    let sum = 0;
    for (const quota of this.ownersTarget) {
      sum += quota.singleOrderQuota;
    }
    sum === 100 ? this.invalidQuota = false : this.invalidQuota = true;
  }

  validateQuota(): void {
    for (const quota of this.ownersTarget) {
      if (quota.singleOrderQuota > 100) {
        Swal.fire('Rateio não pode ser maior que 100%', '', 'error');
        this.invalidQuota = true;
        break;
      }
      if (quota.singleOrderQuota < 0) {
        Swal.fire('Rateio não pode ser menor que 0', '', 'error');
        this.invalidQuota = true;
        break;
      }
    }
  }

  getOwners(): void {
    for (const customerB of this.boat.boatCustomers) {
      if (customerB.type === 'Owner' && customerB.customer.id !== this.customerSelected.id) {
        this.ownersSource.push(customerB);
      }
    }
    const customerBoat = this.boat.boatCustomers.find((os) => os.customer.id === this.customerSelected.id);
    this.ownersTarget.push(customerBoat);
    this.moveToSource();
    this.moveToTarget();
  }

  getOwnersQuota(): any[] {
    return this.ownersTarget;
  }

  moveToSource(): void {
    for (const owner of this.ownersSource) {
      owner.hasInput = false;
    }
    const ownersTarget = [...this.ownersTarget];
    for (const [index, owner] of ownersTarget.entries()) {
      owner.singleOrderQuota = Number((100 / ownersTarget.length).toFixed(2));
      if (index === ownersTarget.length - 1) {
        owner.singleOrderQuota = 100 - Number((100 / ownersTarget.length).toFixed(2)) * (ownersTarget.length - 1);
      }
    }
    this.ownersTarget = [...ownersTarget];
    this.save();
  }

  moveToTarget(): void {
    const ownersTarget = [...this.ownersTarget];
    for (const [index, owner] of ownersTarget.entries()) {
      owner.hasInput = true;
      owner.singleOrderQuota = Number((100 / ownersTarget.length).toFixed(2));
      if (index === ownersTarget.length - 1) {
        owner.singleOrderQuota = 100 - Number((100 / ownersTarget.length).toFixed(2)) * (ownersTarget.length - 1);
      }
    }
    this.ownersTarget = [...ownersTarget];    
    this.save();
  }

  save(): void {
    this.calculateQuota();
    this.validateQuota();

    if (!this.invalidQuota) {
      this.canSave = true;
      this.onQuotaSelected.emit(this.ownersTarget);
    }
  }
}
