<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{'GROUPS-LIST' | translate}}</span>
        <div class="primary fab-button" (click)="openDialog(null)">
            <i class="pi pi-plus" style="font-size: 21px;"></i>
        </div>
    </p-header>

    <!-- <div class="filters active">
        <div class="filter-row">
            <div class="form-control">
                <label>Status clientes</label>
                <p-dropdown [options]="[{label: 'Todos os clientes', value: null}, {label: 'Clientes Ativos', value: true}, {label: 'Clientes Inativos', value: false}]" [(ngModel)]="activeCustomers" appendTo="body" ></p-dropdown>
                <!-- <p-selectButton [ngStyle]="{'float': 'left'}" [(ngModel)]="groupStatus"
                        [options]="[{label: 'Todos os clientes', value: null}, {label: 'Clientes Ativos', value: true}, {label: 'Clientes Inativos', value: false}]"
                        (onChange)="changeCustomerFilter($event.value)">
                </p-selectButton> 
            </div>
        </div>
    </div> -->


    <div class="table">
        <p-table #tt [value]="groupsTable" [paginator]="true" [rows]="numberOfRows" resetPageOnSort="true"
            [pageLinks]="3" [rowsPerPageOptions]="[10,20,30,50,100]" [globalFilterFields]="['id','name','active']"
            autoLayout="true" styleClass="easy-table">
            <ng-template pTemplate="caption">
                <div class="column">

                    <p-selectButton [ngStyle]="{'float': 'left'}" [(ngModel)]="groupStatus"
                        [options]="[{label: 'Todos', value: null}, {label: 'Ativo', value: true}, {label: 'Inativo', value: false}]"
                        (onChange)="tt.filter($event.value, 'active', 'equals'); changeCustomerFilter($event.value)">
                    </p-selectButton>
                </div>
                
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText size="50" placeholder="Filtro por nome" [(ngModel)]="globalFilter"
                        (input)="filterGlobal()">
                </span>
                    
                <span style="font-weight: bold; float: right; margin-top: 5px;">{{ 'TOTAL-REGISTERS' | translate }}:
                    {{tt.totalRecords}} {{'OF' | translate}} {{groupsTable.length}}
                </span>
            </ng-template>
            <ng-template pTemplate="header">
                
                <tr>
                    <th class="text-center" [pSortableColumn]="'id'">
                        Id
                        <p-sortIcon [field]="'id'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'name'">
                        Nome
                        <p-sortIcon [field]="'name'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'marinaCompany.companFederalName'"
                        *ngIf="slingConfig && multiCompanyByCustomer(slingConfig)">
                        Empresa
                        <p-sortIcon [field]="'marinaCompany.companFederalName'"></p-sortIcon>
                    </th>

                    <th class="text-center" [pSortableColumn]="'qtyCustomers'" *ngIf="activeCustomers == null || activeCustomers == true">
                        Quantidade de clientes ativos
                        <p-sortIcon [field]="'qtyCustomers'"></p-sortIcon>
                    </th>

                    <th class="text-center" [pSortableColumn]="'qtyInactiveCustomers'" *ngIf="activeCustomers == null || activeCustomers == false">
                        Quantidade de clientes inativos
                        <p-sortIcon [field]="'qtyInactiveCustomers'"></p-sortIcon>
                    </th>

                    <th class="text-center" [pSortableColumn]="'active'">
                        Status
                        <p-sortIcon [field]="'active'"></p-sortIcon>
                    </th>
                    <th colspan="2"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-group>
                <tr>
                    <td class="text-center">{{ group.id != 0 ? group.id : '' }}</td>
                    <td>{{ group.name }}</td>
                    <td class="text-center" *ngIf="slingConfig && multiCompanyByCustomer(slingConfig)"
                        [pTooltip]="group.marinaCompany ? getCompanyName(group.marinaCompany) : loggedMarina.companyName"
                        tooltipPosition="bottom">
                        {{ group.marinaCompany ? getCompanyIndex(group.marinaCompany) : 1 }}</td>
                    <td class="text-center" *ngIf="activeCustomers == null || activeCustomers == true">{{group.qtyCustomers}}</td>
                    <td class="text-center" *ngIf="activeCustomers == null || activeCustomers == false">{{group.qtyInactiveCustomers}}</td>
                    <td class="text-center">
                        <span *ngIf="group.active" class="badge badge-pill success" style="cursor: default;">{{ 'ACTIVE' | translate }}</span>
                        <span *ngIf="!group.active && group.id != 0"
                            class="badge badge-pill danger" style="cursor: default;">{{ 'INACTIVE' | translate }}</span>
                    </td>
                    <td class="text-center">
                        <div pTooltip="Editar" (click)="edit(group)" tooltipPosition="bottom"
                            [disabled]="group.id === 0" class="table-action">
                            <i class="pi pi-pencil"></i>
                        </div>
                    </td>
                    <td class="text-center">
                        <div pTooltip="Deletar" (click)="deleteConfirm(group)" tooltipPosition="bottom"
                            class="table-action" [disabled]="group.id === 0">
                            <i class="pi pi-trash" style="color: #EF5350"></i>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <tr>
                    <td colspan="4"></td>
                    <td *ngIf="slingConfig && multiCompanyByCustomer(slingConfig)"></td>
                    <td colspan="2" class="text-right">
                        Cientes sem grupo: {{noGroup}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-fieldset>
