import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BoatMovementDTO } from 'src/app/models/dtos/movements/boat-movement-dto';
import { MovementExecutionService } from 'src/app/services/movements/movement-execution.service';
import { MovementExecutionChangeDestinyLocationDTO } from 'src/app/models/dtos/movements/execution-change-destiny/movement-execution-change-destiny-location-dto';
import Swal from 'sweetalert2';
import { MessageUtil } from 'src/app/utils/message.util';

@Component({
  selector: 'app-movement-execution-edit-modal',
  templateUrl: './movement-execution-edit-modal.component.html',
  styleUrls: ['./movement-execution-edit-modal.component.scss']
})
export class MovementExecutionEditModalComponent implements OnInit {

  locationsOptions: {label: string, value: MovementExecutionChangeDestinyLocationDTO}[] = [];

  movement: BoatMovementDTO;

  location: MovementExecutionChangeDestinyLocationDTO = null;

  constructor(
    public dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    public dialog: DialogService,
    public messageUtil: MessageUtil,
    private movementExecutionService: MovementExecutionService,
  ) { }

  async ngOnInit(): Promise<void> {

    if (this.config.data.movement) {
      this.movement = this.config.data.movement;
      this.loadMovementObjectiveFinish(this.movement.movement.movementPlanId, this.movement.movement.movementId)
      .then((data) => {
        this.locationsOptions = this.mountLocationOptions(data);
        if (this.movement.movement.destinyLocationId) {
          const locationCurrentDestiny = this.locationsOptions.filter(({label, value}) => value && value.id === this.movement.movement.destinyLocationId)
          if (locationCurrentDestiny.length > 0) {
            this.location = locationCurrentDestiny[0].value;
          }
        }
        if (!this.location || this.movement.movement.destinyLocationType) {
          const locationCurrentDestiny = this.locationsOptions.filter(({label, value}) => value && !value.id && value.type === this.movement.movement.destinyLocationType)
          if (locationCurrentDestiny.length > 0) {
            this.location = locationCurrentDestiny[0].value;
          }
        }
      })
    }
  }

  private mountLocationOptions(locations: MovementExecutionChangeDestinyLocationDTO[]): {label: string, value: MovementExecutionChangeDestinyLocationDTO}[] {
    const res = locations.map(location =>
      ({
        label: location.name ? location.name : this.messageUtil.translateKey('MOVEMENT.LOCALES.'+location.type),
        value: location
      })
    );
    res.unshift(
        ({
          label: 'Selecione',
          value: null
        })
    )
    return res;
  }

  changeLocation(event: {
    originalEvent: PointerEvent,
    value: MovementExecutionChangeDestinyLocationDTO
  }) {
    this.location = event.value;
  }

  saveChangeLocation() {
    this.movementExecutionService.changeDestiny(
      this.movement.movement.movementPlanId,
      this.movement.movement.movementId,
      this.location
    ).subscribe(() => this.dialogRef.close());
  }

  finishMovementPla() {
    const boatName = this.movement.boat.name;
    const locationName = this.movement.currentLocation.name;
    const vacancyCode = this.movement.currentLocation.vacancyCode;
    const msgDescription = 'A embarcação ' + boatName + ' ficará no local ' + locationName
      + (vacancyCode ? ' | ' + vacancyCode: '') + '.'
    Swal.fire({
      title: 'Deseja finalizar o plano de movimentação no local atual?',
      text: msgDescription,
      icon: 'warning',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      confirmButtonText: this.messageUtil.translateKey('CONFIRM'),
      cancelButtonText: this.messageUtil.translateKey('CANCEL'),
      reverseButtons: true,
      cancelButtonColor: '#d33',
      allowOutsideClick: false,
      preConfirm: () => {
        this.saveFinishMovementPlan();
      }
    });
  }

  private saveFinishMovementPlan() {
    this.movementExecutionService.finishInCurrentOrigin(
      this.movement.movement.movementPlanId,
      this.movement.movement.movementId
    )
    .subscribe(() => this.dialogRef.close());
  }

  loadMovementObjectiveFinish(movementPlanId: number, movementId: number): Promise<MovementExecutionChangeDestinyLocationDTO[]> {
    return new Promise((resolve, reject) => {
      this.movementExecutionService.getChangeDestiny(movementPlanId, movementId)
      .subscribe(data => {
        resolve(data)
      },
      (error) => {
        console.log(error);
        reject(error)
      })
    });
  }
}

