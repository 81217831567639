import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-process-element',
  templateUrl: './process-element.component.html',
  styleUrls: ['./process-element.component.scss']
})
export class ProcessElementComponent {
  @Input()
  public activeIndex: number;

  @Input()
  public stepIndex: number;

  @Input()
  public title: string;

  @Input()
  public isLast: boolean = false;

  @Input()
  public isError: boolean = false;

}
