<div class="dialog-form">
    <section>
        <p-tabView>
            <p-tabPanel header="Lingadas">
                <app-sling-log-table [isDialog]="true" [slingLogs]="slingLogs"></app-sling-log-table>
            </p-tabPanel>
            <p-tabPanel header="Pedidos">
                <app-marina-order-log-table [isDialog]="true" [ordersLog]="ordersLog"></app-marina-order-log-table>
            </p-tabPanel>
        </p-tabView>
    </section>
</div>