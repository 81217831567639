import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Boat, Vacancy } from "src/app/models";

type BoatVacancyFormControlWithMovementLocationValidatorType = (formGroup: UntypedFormControl) => (
  null | { needVacancyToMovementLocation: boolean } | { vacancyNotInMovementLocation: boolean }
)

export const getVacancyByMovementLocationValidator = (form: UntypedFormGroup): BoatVacancyFormControlWithMovementLocationValidatorType => {
  return (vacancyControl: UntypedFormControl) => {
    const vacancy: Vacancy = vacancyControl.value;
    const { movementLocation } = form.value as Boat;

    if (!movementLocation) return null;

    if (movementLocation?.vacancyControl && !vacancy) {
      return { needVacancyToMovementLocation: true };
    }
    if (movementLocation?.id && vacancy?.movementLocation?.id && movementLocation?.id !== vacancy?.movementLocation?.id) {
      return { vacancyNotInMovementLocation: true };
    }
    return null;
  }
}
