import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { Role } from 'src/app/models';
import { RoleService } from 'src/app/services/role.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { FormatUtil } from 'src/app/utils/format.util';
import { MessageUtil } from 'src/app/utils/message.util';
import Swal from 'sweetalert2';
import { UserRoleFormComponent } from './user-role-form/user-role-form.component';

@Component({
  selector: 'app-user-role',
  templateUrl: './user-role.component.html',
  styleUrls: ['./user-role.component.scss']
})
export class UserRoleComponent implements OnInit {

  numberOfRows = 10;

  roles: Role[] = [];
  rolesTable: Role[] = [];
  globalFilter = '';
  constructor(
    public dialog: DialogService,
    private slingConfigService: SlingConfigService,
    private spinner: NgxSpinnerService,
    private roleService: RoleService,
    private messageUtil: MessageUtil
  ) { }

  ngOnInit(): void {
    this.loadSlingConfig();
    this.fillRolesTable();
  }

  loadSlingConfig(): void {
    this.slingConfigService.getSlingConfig().subscribe(
      (data) => {
        const slingConfig = data[0];
        this.numberOfRows = slingConfig != null ? slingConfig.numberOfRecordsPerPage : 10;
      },
      () => null
    );
  }


  filterGlobal(): void {
    this.rolesTable = this.roles.filter(
      (r) =>
      (FormatUtil.getNotAccents(r.name).toUpperCase()
        .includes(FormatUtil.getNotAccents(this.globalFilter).toUpperCase()) ||
        r.name.toUpperCase().includes(this.globalFilter.toUpperCase())
      )
    );
  }
  fillRolesTable(): void {
    this.spinner.show();
    this.roleService.getRolesEditablesByHierarchy().subscribe(
      (r) => {
        this.roles = r;
        this.rolesTable = this.roles;
        this.filterGlobal();
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      },
      () => this.spinner.hide());
  }

  async openUserRoleFormDialog(objEvent?: any): Promise<void> {
    let role = null;
    if (objEvent) {
      role = await this.getRoleById(objEvent.id);
    }
    console.log(role)
    this.dialog.open(UserRoleFormComponent, {
      width: '60%',
      data: { role },
      header: 'Cadastro de Perfil de Usuario'
    }).onClose.subscribe(
      () => {
        this.fillRolesTable();
      }
    );
  }

  async openUserRoleDeleteConfirmDialog(element: any): Promise<void> {
    const titleInput = await this.messageUtil.translateKeyAsync('DELETE-CONFIRMATION');
    const message = await this.messageUtil.translateKeyAsync('DELETE-CONFIRMATION-MESSAGE');
    Swal.fire({
      title: titleInput,
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.roleService.delete(element.id).subscribe(
          () => {
            this.fillRolesTable();
            Swal.fire('Exclusão de perfil de usuário', 'Perfil excluído com sucesso.', 'success');
          },
          (error) => {
            Swal.fire('Falha ao remover!', error.error.message, 'error');
          }
        );
      }
    });
  }

  async getRoleById(id: number): Promise<Role> {
    return new Promise<Role>(
      async (resolve) => {
        this.roleService.getById(id).subscribe(
          async (u) => {
            resolve(u);
          });
      }
    );
  }
}

