import { Component, Input, OnInit } from '@angular/core';
import { Marina } from 'src/app/models';
import { StorageUtil } from 'src/app/utils/storage.util';

@Component({
  selector: 'app-customer-log-billing',
  templateUrl: './customer-log-billing.component.html',
  styleUrls: ['./customer-log-billing.component.scss']
})
export class CustomerLogBillingComponent implements OnInit {

  @Input() customerLog;
  @Input() isNew;

  marina: Marina;
  oldDatasMap;

  constructor() { }

  ngOnInit(): void {
    this.marina = StorageUtil.getMarina();
    this.oldDatasMap = new Map(Object.entries(this.customerLog.oldDatas));
  }

  isChangedBillingCompany(){
    return this.oldDatasMap.has('billingCompanyLog') && this.customerLog.oldDatas.billingCompanyLog != this.customerLog.billingCompanyLog;
  }

  getBillingCompany(billingCompany): string{
    return this.getBilingCompany(billingCompany);
  }

  getBilingCompany(billingCompany): string {
    let company
    if(billingCompany){
      company = this.marina.marinaCompanies.find((o) => o.id === billingCompany.companyId);
    }
    return !billingCompany? this.marina.companyName + ' (principal)': company.companyName + ' - ' + company.companyFederalId;
  }
}
