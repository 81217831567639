import { Injectable } from '@angular/core';

@Injectable()
export class ValidatorsUtil {

    public cpfCnpjValidator(cpfCnpj: string): boolean {

        let isValid = false;

        if (cpfCnpj) {
            cpfCnpj = cpfCnpj.replace(/[^\d]+/g, '');
            if (cpfCnpj.length > 11) {
                isValid = this.cnpjTeste(cpfCnpj);
            } else {
                isValid = this.cpfTeste(cpfCnpj);
            }
        }

        if (!cpfCnpj || cpfCnpj.trim().length === 0 || isValid) {
            return true;
        } else {
            return false;
        }
    }

    private cpfTeste(strCPF: string): boolean {
        let soma;
        let resto;
        soma = 0;
        if (strCPF === '00000000000' ||
            strCPF === '11111111111' ||
            strCPF === '22222222222' ||
            strCPF === '33333333333' ||
            strCPF === '44444444444' ||
            strCPF === '55555555555' ||
            strCPF === '66666666666' ||
            strCPF === '77777777777' ||
            strCPF === '88888888888' ||
            strCPF === '99999999999') {
            return false;
        }

        let i: number;

        // tslint:disable-next-line:radix
        for (i = 1; i <= 9; i++) { soma = soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i); }
        resto = (soma * 10) % 11;

        if ((resto === 10) || (resto === 11)) { resto = 0; }

        // tslint:disable-next-line:radix
        if (resto !== parseInt(strCPF.substring(9, 10))) { return false; }

        soma = 0;

        // tslint:disable-next-line:radix
        for (i = 1; i <= 10; i++) { soma = soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i); }
        resto = (soma * 10) % 11;

        if ((resto === 10) || (resto === 11)) { resto = 0; }

        // tslint:disable-next-line:radix
        if (resto !== parseInt(strCPF.substring(10, 11))) { return false; }
        return true;
    }

    private cnpjTeste(cnpj: string): boolean {

        // tslint:disable-next-line:one-variable-per-declaration
        let numeros, tamanho, digitos, soma, pos, resultado, i: number;
        cnpj = cnpj.replace(/[^\d]+/g, '');

        if (cnpj === '') { return false; }

        if (cnpj.length !== 14) {
            return false;
        }

        // Elimina CNPJs invalidos conhecidos
        if (cnpj === '00000000000000' ||
            cnpj === '11111111111111' ||
            cnpj === '22222222222222' ||
            cnpj === '33333333333333' ||
            cnpj === '44444444444444' ||
            cnpj === '55555555555555' ||
            cnpj === '66666666666666' ||
            cnpj === '77777777777777' ||
            cnpj === '88888888888888' ||
            cnpj === '99999999999999') {
            return false;
        }

        // Valida DVs
        tamanho = cnpj.length - 2;
        numeros = cnpj.substring(0, tamanho);
        digitos = cnpj.substring(tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2) {
                pos = 9;
            }
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        // tslint:disable-next-line:radix
        if (resultado !== parseInt(digitos.charAt(0))) {
            return false;
        }

        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2) {
                pos = 9;
            }
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        // tslint:disable-next-line:radix
        if (resultado !== parseInt(digitos.charAt(1))) {
            return false;
        }

        return true;

    }
}
