<div *ngIf="params?.iconUrls?.length > 0; then hasImages else checked"></div>
<ng-template #hasImages>
  <section>
    <img
      *ngFor="let iconUrl of params?.iconUrls; let i = index"
      [src]="iconUrl"
    >
  </section>
</ng-template>
<ng-template #checked>
  <section>
    <img src="assets/images/check.png">
  </section>
</ng-template>
