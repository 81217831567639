import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-extract-invoice',
  templateUrl: './extract-invoice.component.html',
  styleUrls: ['./extract-invoice.component.scss']
})
export class ExtractInvoiceComponent implements OnInit {

  public path: any;
  constructor(
    public dialog: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    this.path = this.config.data.path;
  }

  ngOnInit(): void {
  }

}
