import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaginationFilter } from 'src/app/models/pagination-filter';
import { environment } from 'src/environments/environment';
import { InvoiceTaxDocumentProduct } from '../../models/invoice-Tax-Document-Product';
import { Fornecedor } from 'src/app/models/finance/fornecedor';
import { LancamentoProgramado } from 'src/app/models/finance/lancamento-programado';
import { InvoiceTaxDocumentProductDTO } from 'src/app/models/invoice-Tax-Document-Product-Dto';

@Injectable({
  providedIn: 'root',
})
export class InvoiceTaxProductService {
  constructor(private http: HttpClient) {}

  findByFilter(
    filter: any,
    paginationFilter: PaginationFilter
  ): Observable<InvoiceTaxDocumentProductDTO[]> {
    let params = new HttpParams();

    let sort;
    if (paginationFilter.sort) {
      sort = paginationFilter.sort;
    }
    if (paginationFilter.order) {
      sort = (sort ? sort + ',' : '') + paginationFilter.order;
    }
    if (sort) {
      params = params.set('sort', sort);
    }
    params = params.set('page', paginationFilter.page.toString());
    params = params.set('size', paginationFilter.size.toString());

    return this.http
      .post(
        environment.apiHost + '/secure/invoice/document/nfp/find-all',
        filter,
        { params }
      )
      .pipe(map((response: any) => response.data.invoiceDocuments));
  }

  //ainda nao foi implementado
  findById(invoiceTaxDocumentId): Observable<any> {
    return this.http
      .get(
        environment.apiHost +
          '/secure/invoice/document/nfp/' +
          invoiceTaxDocumentId
      )
      .pipe(map((response: any) => response.data.orderItem));
  }

  //ainda nao foi implementado
  cancelInvoiceTax(parametros: any): Observable<any> {
    let params = new HttpParams().set('nfpIds', parametros.nfpIds.join(','));
    params = params.set('justificativa', parametros.justificativa);
    return this.http
      .post(
        environment.apiHost + '/secure/invoice/document/nfp/cancel',
        null, // Passando null no corpo da solicitação
        { params: params } // Passando os parâmetros na URL
      )
      .pipe(map((response: any) => response.data));
  }

  returnProcess(files: any): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('attachment', files);
    let hd = new HttpHeaders();
    hd = hd.set('Content-Type', 'multipart/form-data');
    const options = {
      headers: hd,
    };
    console.log(formData);
    return this.http
      .post(
        environment.apiHost + '/secure/invoice/document/nfp/process-xml',
        formData,
        options
      )
      .pipe(map((response: any) => response.data.processXml));
  }

  saveImport(invoiceTaxDocumentProduct: any): Observable<any> {
    return this.http
      .post(
        environment.apiHost + '/secure/invoice/document/nfp/save-import',
        invoiceTaxDocumentProduct
      )
      .pipe(map((response: any) => response.data));
  }

  endImport(
    arquivoProcessado: InvoiceTaxDocumentProduct,
    uploadedFile: any
  ): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('attachment', uploadedFile);
    formData.append('arquivoProcessado', JSON.stringify(arquivoProcessado));
    let hd = new HttpHeaders();
    hd = hd.set('Content-Type', 'multipart/form-data');
    const options = {
      headers: hd,
    };
    console.log(formData);
    return this.http
      .post(
        environment.apiHost + '/secure/invoice/document/nfp/end-import',
        formData,
        options
      )
      .pipe(map((response: any) => response.data));
  }

  downloadXml(invoiceUrlList: String[]): Observable<any> {
    const fileUrls: String[] = invoiceUrlList;
    return this.http.post(
      environment.apiHost + '/secure/invoice/document/nfp/download-xmls',
      fileUrls,
      {
        responseType: 'blob', // Defina o tipo de resposta para Blob
      }
    );
  }

  saveFile(response: any): void {
    const blob = new Blob([response], { type: 'application/zip' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'arquivo.zip';
    link.click();
  }

  createFornecedor(fornecedor: any): Observable<Fornecedor> {
    return this.http
      .post(
        environment.apiHost + '/secure/invoice/document/nfp/save-fornecedor',
        fornecedor
      )
      .pipe(map((response: any) => response));
  }

  createProgramacao(programacao: LancamentoProgramado): Observable<any> {
    return this.http
      .post(
        environment.apiHost + '/secure/invoice/document/nfp/save-programacao',
        programacao
      )
      .pipe(map((response: any) => response));
  }

  createProduct(produto: any): Observable<any> {
    return this.http
      .post(
        environment.apiHost + '/secure/invoice/document/nfp/save-produto',
        produto
      )
      .pipe(map((response: any) => response));
  }
}
