import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpHandler,
  HttpRequest,
  HttpResponse, HttpClient, HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MessageUtil } from '../utils/message.util';
import { NgxSpinnerService } from 'ngx-spinner';
import { SweetAlertResult } from 'sweetalert2';
import Swal from 'sweetalert2';
import { catchError, filter, flatMap } from 'rxjs/operators';
import { StorageUtil } from '../utils/storage.util';
import { environment } from '../../environments/environment';

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {
  private token;
  swal: Promise<SweetAlertResult>;
  constructor(
    private router: Router,
    private messageUtil: MessageUtil,
    private spinner: NgxSpinnerService,
    private http: HttpClient
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.token = localStorage.getItem('token');

    if (this.token && this.token !== '') {
      if (
        request.url.indexOf('EasyFinanceiro') > 0 ||
        request.url.indexOf('financeiro') > 0 ||
        request.url.indexOf('refresh') > 0
      ) {
      } else if (
        (request.url.indexOf('easymarine') > 0 ||
          request.url.indexOf('localhost') > 0 ||
          request.url.includes('192.168.25')) &&
        !request.url.includes('slings-log') &&
        !request.url.includes('orders-log') &&
        !request.url.includes('boats-log') &&
        !request.url.includes('contracts-log') &&
        !request.url.includes('customers-log') &&
        !request.url.includes('products-log') &&
        !request.url.includes('invoice-tax-log') &&
        !request.url.includes('invoice-log') &&
        !request.url.includes('user-log') &&
        !request.url.includes('role-log') &&
        !request.url.includes('nixx-bank-log') &&
        !request.url.includes('movement-execution-operation-log')
      ) {
        // tslint:disable-next-line: no-string-literal
        const header = request.headers['lazyUpdate'];
        let headers = new HttpHeaders({
          Authorization: 'Bearer ' + this.token,
          MarinaId: '' + StorageUtil.getMarinaId(),
          UserId: '' + StorageUtil.getUserId(),
          OriginEasy: 'EasyWeb',
          'Access-Control-Allow-Origin': '*',
          'Content-type': 'application/json',
        });
        if (header) {
          header.forEach((value) => {
            headers = headers.append(value.name, value.value);
          });
        }
        if (header && header.length > 0) {
          const contentTypes = header.filter(
            (x) => x.name === 'Content-Type' || x.name === 'Accept'
          );
          if (contentTypes.length > 0) {
            headers = new HttpHeaders({
              Authorization: 'Bearer ' + this.token,
              MarinaId: '' + StorageUtil.getMarinaId(),
              UserId: '' + StorageUtil.getUserId(),
              refreshToken: '' + StorageUtil.getRefreshToken(),
              OriginEasy: 'EasyWeb',
              'Access-Control-Allow-Origin': '*',
            });
          }
        }
        request = request.clone({ headers });
      }
    }

    return next.handle(request).pipe(
      filter(
        (event: any) => {
          if (event instanceof HttpResponse) {
            const newToken = event.headers.get('X-NewToken');
            if (newToken && newToken.length > 0) {
              StorageUtil.saveToken(newToken);
            }
          }
          return event;
        },
        async (err: any) => {
          if (err.status === 0) {
            if (
              `sling-down-panel sling-down-panel-portrait sling-up-panel sling-up
                                -panel-portrait boat-wash-panel boat-wash-panel-portrait`.includes(
                this.router.url.split('/')[1]
              )
            ) {
              this.messageUtil.generateMessage(
                'error',
                'SUMMARY.ERROR',
                'Ocorreu uma falha de comunicação com o servidor.',
                3000
              );
              return;
            }
            this.messageUtil.generateMessage(
              'error',
              'SUMMARY.ERROR',
              'Ocorreu uma falha de comunicação com o servidor.'
            );
            return;
          }

          if (err.error.message === 'Missing or invalid Authorization header') {
            await this.router.navigate(['/extra/login']);
          }
          throw err;
        }
      ),
      // tslint:disable-next-line:no-shadowed-variable
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          if (
            error.url.includes('authenticate') ||
            error.url.includes('login')
          ) {
            throw error;
          }
          this.swal = Swal.fire(
            this.messageUtil.translateKey('SUMMARY.WARNING'),
            error.error.message,
            'warning'
          );
          this.spinner.hide();
          this.returnToLogin();
          return;
        }
        // && !this.swal
        if (error.status === 401) {
          if (
            error.url.includes('authenticate') ||
            error.url.includes('login')
          ) {
            throw error;
          }

          const token = StorageUtil.getToken();
          const refreshToken = StorageUtil.getRefreshToken();

          if (token && refreshToken) {
            const httpOptions = {
              headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Client: 'EasyWeb',
              }),
            };
            return this.http
              .post(
                environment.apiHost + '/user/refresh',
                { refreshToken },
                httpOptions
              )
              .pipe(
                flatMap((response: any) => {
                  try {
                    const user = JSON.parse(JSON.stringify(response.data.user));
                    if (user && user.token) {
                      const newToken = response.data.user.token;
                      StorageUtil.saveToken(newToken);
                      const req = request.clone({
                        setHeaders: {
                          Authorization: 'Bearer ' + user.token,
                        },
                      });
                      //this.verifyWebVersion();
                      return next.handle(req);
                    } else {
                      this.returnToLogin();
                    }
                  } catch (error) {
                    this.returnToLogin();
                  }
                })
              );
          } else {
            this.swal = Swal.fire(
              this.messageUtil.translateKey('SUMMARY.WARNING'),
              'Sua sessão expirou.',
              'warning'
            );
            this.returnToLogin();
            return;
          }
        }
        throw error;
      })
    );
  }

  verifyMarinaContext(): boolean {
    if (StorageUtil.getMarinaId() !== undefined) {
      if (StorageUtil.getMarinasIds().includes(StorageUtil.getMarinaId())) {
        return true;
      }
    } else if (StorageUtil.getMarinasIds().length === 0) {
      return true;
    }
    StorageUtil.clearStorage();
    return false;
  }

  returnToLogin(): void {
    this.router.navigate(['login']);
  }

  // verifyWebVersion(): void {
  //   const serverVersion = StorageUtil.getServerVersion();
  //   const webVersion = StorageUtil.getWebVersion();
  //   console.log(
  //     'Browser Version: ',
  //     webVersion,
  //     'Back-End Version: ',
  //     serverVersion
  //   );
  //   if (webVersion !== serverVersion) {
  //     StorageUtil.saveWebVersion(serverVersion)
  //     Swal.fire({
  //       title: this.messageUtil.translateKey('WEB-VERSION-WARNING'),
  //       text: 'Para atualizar aperte Ctrl + F5',
  //       icon: 'warning',
  //       showCancelButton: false,
  //       allowOutsideClick: false,
  //       allowEscapeKey: false,
  //       allowEnterKey: false,
  //       showConfirmButton: false
  //     }).then((result) => {
  //       if (result.value) {
  //         document.location.reload();
  //       }
  //     });
  //   }
  // }
}
