<div [formGroup]="licenseForm">
   <p-fieldset>
    <p-header class="article-title">
      <div class="article-title">Foto da licença</div>
    </p-header>
    <div class="form-group" style="border-bottom: 4em; align-items: flex-start; margin-top: 0">
      <app-sailor-upload
      [path]="imgPreview"
      title="Imagem da licença"
      (finishEvent)="receiveUploadUrl($event)"
      >
      </app-sailor-upload>
    </div>
  </p-fieldset>
  <br />
  <p-fieldset>
    <p-header class="article-title">
      <div class="article-title">Dados da licença</div>
    </p-header>
    <div class="form-group">
      <div class="form-control field" style="width: 33%">
        <label>{{ "Tipo de habilitação" | translate }}</label>
        <p-dropdown
          placeholder="Selecione..."
          [disabled]="false"
          required
          [filter]="true"
          [options]="typesLicense"
          formControlName="typeLicense"
          appendTo="body"
          name="selectedBoatId"
        >
        </p-dropdown>
      </div>
      <div class="form-control field" style="width: 33%">
        <label for="register" tyle="margin-bottom: 0px; padding-bottom:0px ; "
          >{{ "REGISTER" | translate }} *</label
        >
        <input
          pInputText
          name="register"
          formControlName="register"
          maxlength="50"
        />
      </div>
      <div class="form-control">
        <label for="expiration">{{ "Validade" | translate }}</label>
        <p-calendar
          dateFormat="dd/mm/yy"
          name="expiration"
          formControlName="expiration"
          appendTo="body"
          [showIcon]="true"
          i18n
          [monthNavigator]="true"
          [yearNavigator]="true"
          [yearRange]="'2000:2055'"
          rPCalendarMask
        >
        </p-calendar>
      </div>
    </div>
    <div class="form-group">
      <div class="form-control">
        <label for="licenceCountry">{{ "COUNTRY" | translate }}</label>
        <p-dropdown
          #country
          [options]="countries"
          name="licenceCountry"
          appendTo="body"
          (onChange)="loadStates(country)"
          formControlName="country"
        >
        </p-dropdown>
      </div>
      <div class="form-control">
        <label for="licenceState">{{ "FS" | translate }}</label>
        <p-dropdown
          [options]="states"
          #state
          name="licenceState"
          appendTo="body"
          (onChange)="loadCities('BR', state)"
          formControlName="state"
        >
        </p-dropdown>
      </div>
      <div class="form-control">
        <label for="licenceCity">{{ "CITY" | translate }}</label>
        <p-autoComplete
          formControlName="city"
          autoHighlight="true"
          appendTo="body"
          name="licenceCity"
        >
        </p-autoComplete>
      </div>
    </div>
  </p-fieldset>
</div>
