import { AbstractControl, UntypedFormGroup, NgForm } from '@angular/forms';

export class FormUtil {
    public static touchAndSendFormGroup(group: any): void {
        Object.keys(group.controls).forEach((key) => {
            if (group.get(key).controls) {
                const s: UntypedFormGroup = group.get(key);
                this.touchAndSendFormGroup(group.get(key));
            }
            group.get(key).markAsTouched();
            group.get(key).markAsDirty();
        });
    }

    public static touchAndSendForm(group: NgForm): void {
        Object.keys(group.controls).forEach((key) => {
            if (!group.controls[key].valid) {
                group.controls[key].markAsTouched();
                group.controls[key].markAsDirty();
            }
        });
    }
}
