import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { ContractDocumentService } from '../services/contract-document.service';
import { ToastService } from '../services/toast.service';
import { MessageUtil } from './message.util';

@Injectable()
export class ContractUtil {

    constructor(
        private spinner: NgxSpinnerService,
        private contractDocumentService: ContractDocumentService,
        private messageUtil: MessageUtil,
        private toasty: ToastService
    ) { }

    getEmails(contractEmails: string): string {
      if (contractEmails != null) {
        const reg = /\,/gi;
        return contractEmails.replace(reg, '\n');
      } return ''
    }

    updateAffected(contractId: number, affected: boolean): Promise<boolean> {
        return new Promise((resolve) => {
            this.contractDocumentService.updateStatusAffected(contractId, affected)
                .subscribe(url => {
                    resolve(true);
                }, (error) => {
                    this.showError(error);
                    resolve(false);
                });
        });
    }

    downloadDocument(contractId: number, contractUrl): void {
        this.spinner.show();
        if (contractUrl) {
            this.spinner.hide();
            window.open(contractUrl, '_blank');
        } else {
            this.contractDocumentService.downloadDocument(contractId)
                .subscribe(url => {
                    this.spinner.hide();
                    window.open(url, '_blank');
                }, (error) => this.showError(error))
        }
    }

    cancelDocument(contractId: number, contractName: string, contractBoatName: string): Promise<boolean> {
        return new Promise((resolve) => {
            this.showWarningAlertGenerics(
                'Cancelamento de contrato',
                `Contrato<br><b>${contractName}</b><br>Embarcação:<br><b>${contractBoatName}</b>`,
                () => {
                    this.spinner.show();
                    this.contractDocumentService.cancelDocument(contractId)
                        .subscribe(() => {
                            this.toasty.success('Documento cancelado!');
                            resolve(true);
                        }, (error) => {
                            this.showError(error);
                            resolve(false);
                        },
                            () => this.spinner.hide());
                });
        });
    }

    showWarningAlertGenerics(title: string, html: string, actionFunction): void {
        Swal.fire({
            title,
            html,
            icon: 'warning',
            backdrop: false,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                actionFunction();
            }
        });
    }

    showError(error): void {
        this.spinner.hide();
        console.error(error)
        const exception = error.error.exception ? error.error.exception : error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
    }
}
