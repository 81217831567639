export interface CustomReportInterface {
  id?: number;
  attribute: string;
  text: string;
  reportType: string;
}

export let CustomerExport: CustomReportInterface[] = [
  { attribute: 'id', text: 'ID', reportType: 'CUSTOMER' },
  { attribute: 'federalId', text: 'CPF', reportType: 'CUSTOMER' },
  { attribute: 'customerType', text: 'Tipo de Cliente', reportType: 'CUSTOMER' },
  { attribute: 'userAppBool', text: 'Usuário App', reportType: 'CUSTOMER' },
  { attribute: 'name', text: 'Nome', reportType: 'CUSTOMER' },
  { attribute: 'gender', text: 'Sexo', reportType: 'CUSTOMER' },
  { attribute: 'email', text: 'Email Pessoal', reportType: 'CUSTOMER' },
  { attribute: 'phone', text: 'Celular', reportType: 'CUSTOMER' },
  { attribute: 'phone2', text: 'Celular 2', reportType: 'CUSTOMER' },
  { attribute: 'residentialPhone', text: 'Fone Residencial', reportType: 'CUSTOMER' },
  { attribute: 'commercialPhone', text: 'Fone Comercial', reportType: 'CUSTOMER' },
  { attribute: 'birthday', text: 'Data de Nascimento', reportType: 'CUSTOMER' },
  { attribute: 'chargeEmail', text: 'Email de Cobrança', reportType: 'CUSTOMER' },
  { attribute: 'expiration', text: 'Dia do Vencimento', reportType: 'CUSTOMER' },
  { attribute: 'groupName', text: 'Grupo', reportType: 'CUSTOMER' },
  { attribute: 'residentialZipCode', text: 'CEP Residencial', reportType: 'CUSTOMER' },
  { attribute: 'residentialCountry', text: 'País Residencial', reportType: 'CUSTOMER' },
  { attribute: 'residentialState', text: 'UF Residencial', reportType: 'CUSTOMER' },
  { attribute: 'residentialCity', text: 'Cidade Residencial', reportType: 'CUSTOMER' },
  { attribute: 'residentialAddress', text: 'Rua Residencial', reportType: 'CUSTOMER' },
  { attribute: 'residentialDistrict', text: 'Bairro Residencial', reportType: 'CUSTOMER' },
  { attribute: 'residentialNumber', text: 'Número Residencial', reportType: 'CUSTOMER' },
  { attribute: 'residentialSupplement', text: 'Complemento Residencial', reportType: 'CUSTOMER' },
  { attribute: 'commercialZipCode', text: 'CEP Comercial', reportType: 'CUSTOMER' },
  { attribute: 'commercialCountry', text: 'País Comercial', reportType: 'CUSTOMER' },
  { attribute: 'commercialState', text: 'UF Comercial', reportType: 'CUSTOMER' },
  { attribute: 'commercialCity', text: 'Cidade Comercial', reportType: 'CUSTOMER' },
  { attribute: 'commercialAddress', text: 'Rua Comercial', reportType: 'CUSTOMER' },
  { attribute: 'commercialDistrict', text: 'Bairro Comercial', reportType: 'CUSTOMER' },
  { attribute: 'commercialNumber', text: 'Número Comercial', reportType: 'CUSTOMER' },
  { attribute: 'commercialSupplement', text: 'Complemento Comercial', reportType: 'CUSTOMER' },
  { attribute: 'boatNames', text: 'Embarcações', reportType: 'CUSTOMER' },
  { attribute: 'maritalStatus', text: 'Estado Civil', reportType: 'CUSTOMER' },
  { attribute: 'spouse', text: 'Cônjuge', reportType: 'CUSTOMER' },
  { attribute: 'customerProfile', text: 'Perfil de Cliente', reportType: 'CUSTOMER' },
  { attribute: 'billingDay', text: 'Início de Faturamento', reportType: 'CUSTOMER' },
  { attribute: 'licenceExpiration', text: 'Expiração da Habilitação', reportType: 'CUSTOMER' },
];

export const SailorReport: CustomReportInterface[] = [
  // {attribute: 'cnpj', text: 'CNPJ', reportType: 'SAILOR'},
  { attribute: 'id', text: 'ID', reportType: 'SAILOR' },
  { attribute: 'federalId', text: 'CPF', reportType: 'SAILOR' },
  { attribute: 'name', text: 'Nome', reportType: 'SAILOR' },
  { attribute: 'phone', text: 'Celular', reportType: 'SAILOR' },
  { attribute: 'email', text: 'E-mail', reportType: 'SAILOR' },
  { attribute: 'birthday', text: 'Data de Nascimento', reportType: 'SAILOR' },
  { attribute: 'typesSailor', text: 'Tipo de Marinheiro', reportType: 'SAILOR' },
  // {attribute: 'cnh', text: 'CNH ou Identidade + CPF', reportType: 'SAILOR'},
  { attribute: 'residentialZIP', text: 'CEP', reportType: 'SAILOR' },
  { attribute: 'residentialCountry', text: 'País', reportType: 'SAILOR' },
  { attribute: 'residentialState', text: 'UF', reportType: 'SAILOR' },
  { attribute: 'residentialCity', text: 'Cidade', reportType: 'SAILOR' },
  { attribute: 'residentialStreet', text: 'Rua', reportType: 'SAILOR' },
  { attribute: 'residentialDistrict', text: 'Bairro', reportType: 'SAILOR' },
  { attribute: 'residentialNumber', text: 'Número', reportType: 'SAILOR' },
  { attribute: 'residentialComplement', text: 'Complemento', reportType: 'SAILOR' },
  // {attribute: 'emergencyContact', text: 'Contato Emergencial', reportType: 'SAILOR'},
  // {attribute: 'emergencyPhone', text: 'Telefone Emergencial', reportType: 'SAILOR'},
  // {attribute: 'obs', text: 'Observações', reportType: 'SAILOR'},
  { attribute: 'licenceRegister', text: 'Registro', reportType: 'SAILOR' },
  { attribute: 'licenceExpiration', text: 'Validade', reportType: 'SAILOR' },
  { attribute: 'typeLicense', text: 'Tipo de Habilitação', reportType: 'SAILOR' },
  { attribute: 'licenceCountry', text: 'País da Habilitação', reportType: 'SAILOR' },
  { attribute: 'licenceState', text: 'Estado da Habilitação', reportType: 'SAILOR' },
  { attribute: 'licenceCity', text: 'Cidade da Habilitação', reportType: 'SAILOR' },
  { attribute: 'nameBoats', text: 'Embarcaçõe(s)', reportType: 'SAILOR' },
  { attribute: 'nameCustomer', text: 'Proprietário(s)', reportType: 'SAILOR' },
];

export const BoatReport: CustomReportInterface[] = [
  { attribute: 'boatName', text: 'Nome', reportType: 'BOAT' },
  { attribute: 'boatProfile', text: 'Perfil', reportType: 'BOAT' },
  { attribute: 'boatCustomers', text: 'Proprietários', reportType: 'BOAT' },
  { attribute: 'contacts', text: 'Contatos dos proprietarios', reportType: 'BOAT' },
  { attribute: 'associatedOwner', text: 'Autorizados', reportType: 'BOAT' },
  { attribute: 'boatSailors', text: 'Marinheiros', reportType: 'BOAT' },
  { attribute: 'boatShipyard', text: 'Estaleiro', reportType: 'BOAT' },
  { attribute: 'boatModel', text: 'Modelo', reportType: 'BOAT' },
  { attribute: 'boatType', text: 'Tipo', reportType: 'BOAT' },
  { attribute: 'beam', text: 'Boca', reportType: 'BOAT' },
  { attribute: 'draft', text: 'Calado', reportType: 'BOAT' },
  { attribute: 'length', text: 'Comprimento', reportType: 'BOAT' },
  { attribute: 'commercialLength', text: 'Pés', reportType: 'BOAT' },
  { attribute: 'manufactureYear', text: 'Ano de Fabricação', reportType: 'BOAT' },
  { attribute: 'modelYear', text: 'Ano do Modelo', reportType: 'BOAT' },
  { attribute: 'mainColor', text: 'Cor Principal', reportType: 'BOAT' },
  { attribute: 'crew', text: 'Nº Tripulantes', reportType: 'BOAT' },
  { attribute: 'passengersDay', text: 'Nº Passageiros', reportType: 'BOAT' },
  { attribute: 'passengersNight', text: 'Passageiros Noite', reportType: 'BOAT' },
  { attribute: 'cabins', text: 'Cabines', reportType: 'BOAT' },
  { attribute: 'chassiHull', text: 'Chassi', reportType: 'BOAT' },
  { attribute: 'bathrooms', text: 'Banheiro', reportType: 'BOAT' },
  { attribute: 'bowType', text: 'Tipo de proa', reportType: 'BOAT' },
  { attribute: 'fuel', text: 'Abastecimento', reportType: 'BOAT' },
  { attribute: 'tieRegister', text: 'Registro', reportType: 'BOAT' },
  { attribute: 'tieExpiration', text: 'Validade', reportType: 'BOAT' },
  { attribute: 'tieState', text: 'Estado', reportType: 'BOAT' },
  { attribute: 'tieCity', text: 'Cidade', reportType: 'BOAT' },
  { attribute: 'tieNavArea', text: 'Área Navegação', reportType: 'BOAT' },
  { attribute: 'operator', text: 'Operador', reportType: 'BOAT' },
  { attribute: 'vacancy', text: 'Vaga', reportType: 'BOAT' },
];

export const LocationInvoiceReport: CustomReportInterface[] = [
  { attribute: 'id', text: 'ID', reportType: 'LOCATION_INVOICE' },
  { attribute: 'movementLocation', text: 'Local', reportType: 'LOCATION_INVOICE' },
  { attribute: 'totalVacancies', text: 'Vagas Totais', reportType: 'LOCATION_INVOICE' },
  { attribute: 'occupiedVacancies', text: 'Vagas Ocupadas', reportType: 'LOCATION_INVOICE' },
  { attribute: 'availableVacancies', text: 'Vagas Disponíveis', reportType: 'LOCATION_INVOICE' },
  { attribute: 'totalFeet', text: 'Total de pés', reportType: 'LOCATION_INVOICE' },
  { attribute: 'averageFeet', text: 'Média de pés', reportType: 'LOCATION_INVOICE' },
  { attribute: 'totalEntryFeet', text: 'Entrada de pés', reportType: 'LOCATION_INVOICE' },
  { attribute: 'totalExitFeet', text: 'Saída de pés', reportType: 'LOCATION_INVOICE' },
  { attribute: 'averageFootValue', text: 'Valor médio pé', reportType: 'LOCATION_INVOICE' },
  { attribute: 'averageBoatValue', text: 'Valor médio embarcação', reportType: 'LOCATION_INVOICE' },
  { attribute: 'totalInvoicing', text: 'Faturamento', reportType: 'LOCATION_INVOICE' },
];

export const InvoicingReportColumns: CustomReportInterface[] = [
  { attribute: 'dueDate', text: 'Data de Vencimento', reportType: 'INVOICING' },
  { attribute: 'competenceDate', text: 'Data de Competência', reportType: 'INVOICING' },
  { attribute: 'datePaid', text: 'Data de Pagamento', reportType: 'INVOICING' },
  { attribute: 'customerName', text: 'Cliente', reportType: 'INVOICING' },
  { attribute: 'federalId', text: 'CPF', reportType: 'INVOICING' },
  { attribute: 'invoiceNumber', text: 'Fatura', reportType: 'INVOICING' },
  { attribute: 'nfsNumber', text: 'Nota', reportType: 'INVOICING' },
  { attribute: 'nfsValue', text: 'Valor da Nota', reportType: 'INVOICING' },
  { attribute: 'receiptValue', text: 'Valor Recibo', reportType: 'INVOICING' },
  { attribute: 'invoiceValue', text: 'Valor Total', reportType: 'INVOICING' },
  { attribute: 'products', text: 'Descrição', reportType: 'INVOICING' },
  { attribute: 'boats', text: 'Embarcações', reportType: 'INVOICING' }
]

export const PendingReport: CustomReportInterface[] = [
  { attribute: 'nameCustomer', text: 'Cliente', reportType: 'PENDING' },
  { attribute: 'firstEmail', text: 'Email', reportType: 'PENDING' },
  { attribute: 'phone', text: 'Celular', reportType: 'PENDING' },
  { attribute: 'days', text: 'Dias', reportType: 'PENDING' },
  { attribute: 'totalCustomer', text: 'Valor', reportType: 'PENDING' },
  { attribute: 'fullValue', text: 'Valor com juros', reportType: 'PENDING' },
  { attribute: 'financialBlock', text: 'Bloqueado', reportType: 'PENDING' },
];

export const MovementManagementReport: CustomReportInterface[] = [
  { attribute: 'systemAdjustment', text: 'Ajuste', reportType: 'MOVEMENT_MANAGEMENT' },
  { attribute: 'finishDateTime', text: 'Data realização', reportType: 'MOVEMENT_MANAGEMENT' },
  { attribute: 'boatName', text: 'Embarcação', reportType: 'MOVEMENT_MANAGEMENT' },
  { attribute: 'customerName', text: 'Cliente', reportType: 'MOVEMENT_MANAGEMENT' },
  { attribute: 'requestOrigin', text: 'Origem do pedido', reportType: 'MOVEMENT_MANAGEMENT' },
  { attribute: 'objective', text: 'Objetivo', reportType: 'MOVEMENT_MANAGEMENT' },
  { attribute: 'originName', text: 'Origem', reportType: 'MOVEMENT_MANAGEMENT' },
  { attribute: 'destinyName', text: 'Destino', reportType: 'MOVEMENT_MANAGEMENT' },
  { attribute: 'isCounted', text: 'Contabiliza', reportType: 'MOVEMENT_MANAGEMENT' },
  { attribute: 'exempt', text: 'Isento', reportType: 'MOVEMENT_MANAGEMENT' },
  { attribute: 'movementStatus', text: 'Status', reportType: 'MOVEMENT_MANAGEMENT' }
];

export const StockMovementReport: CustomReportInterface[] = [
  { attribute: 'active', text: 'Ativo', reportType: 'STOCK_MOVEMENT' },
  { attribute: 'automatic', text: 'Automático', reportType: 'STOCK_MOVEMENT' },
  { attribute: 'cancelAt', text: 'Cancelado em', reportType: 'STOCK_MOVEMENT' },
  { attribute: 'competenceDate', text: 'Data de Competência', reportType: 'STOCK_MOVEMENT' },
  { attribute: 'createAt', text: 'Criado em', reportType: 'STOCK_MOVEMENT' },
  { attribute: 'updateAt', text: 'Atualizado em', reportType: 'STOCK_MOVEMENT' },
  { attribute: 'customerName', text: 'Cliente', reportType: 'STOCK_MOVEMENT' },
  { attribute: 'employeeName', text: 'Usuário', reportType: 'STOCK_MOVEMENT' },
  { attribute: 'supplierName', text: 'Fornecedor', reportType: 'STOCK_MOVEMENT' },
  { attribute: 'movementValue', text: 'Valor', reportType: 'STOCK_MOVEMENT' },
  { attribute: 'totalItem', text: 'Total de Itens', reportType: 'STOCK_MOVEMENT' },
];

export const MovementPlanReport: CustomReportInterface[] = [
  { attribute: 'movementPlanMarinaId', text: 'Id', reportType: 'MOVEMENT_PLAN' },
  { attribute: 'origin', text: 'Origem', reportType: 'MOVEMENT_PLAN' },
  { attribute: 'requester', text: 'Solicitante', reportType: 'MOVEMENT_PLAN' },
  { attribute: 'sailor', text: 'Marinheiro', reportType: 'MOVEMENT_PLAN' },
  { attribute: 'boatName', text: 'Embarcação', reportType: 'MOVEMENT_PLAN' },
  { attribute: 'createdAt', text: 'Data de Solicitação', reportType: 'MOVEMENT_PLAN' },
  { attribute: 'executionDate', text: 'Previsão de saída', reportType: 'MOVEMENT_PLAN' },
  { attribute: 'returnTime', text: 'Previsão de retorno', reportType: 'MOVEMENT_PLAN' },
  { attribute: 'peopleBoard', text: 'Pessoas a bordo', reportType: 'MOVEMENT_PLAN' },
  { attribute: 'destiny', text: 'Destino', reportType: 'MOVEMENT_PLAN' },
  { attribute: 'objective', text: 'Objetivo', reportType: 'MOVEMENT_PLAN' },
  { attribute: 'navigationPlanId', text: 'Plano de Navegação', reportType: 'MOVEMENT_PLAN' },
  { attribute: 'movementPlanStatus', text: 'Status do Plano de Movimentação', reportType: 'MOVEMENT_PLAN' },
];

export const ProductMovementReport: CustomReportInterface[] = [
  { attribute: 'id', text: 'Id', reportType: 'PRODUCT_MOVEMENT' },
  { attribute: 'orderNumber', text: 'Numero do Pedido', reportType: 'PRODUCT_MOVEMENT' },
  { attribute: 'dateOperation', text: 'Data', reportType: 'PRODUCT_MOVEMENT' },
  { attribute: 'origin', text: 'Origem', reportType: 'PRODUCT_MOVEMENT' },
  { attribute: 'type', text: 'Tipo', reportType: 'PRODUCT_MOVEMENT' },
  { attribute: 'boatName', text: 'Nome da Embarcação', reportType: 'PRODUCT_MOVEMENT' },
  { attribute: 'customerName', text: 'Nome do Cliente', reportType: 'PRODUCT_MOVEMENT' },
  { attribute: 'name', text: 'Nome do Produto', reportType: 'PRODUCT_MOVEMENT' },
  { attribute: 'qtdOperation', text: 'Quantidade', reportType: 'PRODUCT_MOVEMENT' },
  { attribute: 'itemValue', text: 'Valor do Item', reportType: 'PRODUCT_MOVEMENT' },
  { attribute: 'totalValue', text: 'Valor Total', reportType: 'PRODUCT_MOVEMENT' },
  { attribute: 'stockValue', text: 'Valor do Estoque', reportType: 'PRODUCT_MOVEMENT' },
  { attribute: 'stock', text: 'Estoque', reportType: 'PRODUCT_MOVEMENT' }
];

export const ProductMovementReportNfe: CustomReportInterface[] = [
  ...ProductMovementReport,
  { attribute: 'nfeNumberProduct', text: 'Nota Fiscal', reportType: 'PRODUCT_MOVEMENT_NFE' },
]

export const ProductsReport: CustomReportInterface[] = [
  { attribute: 'id', text: 'Id', reportType: 'PRODUCT' },
  { attribute: 'name', text: 'Nome', reportType: 'PRODUCT' },
  { attribute: 'barCode', text: 'Código de Barras', reportType: 'PRODUCT' },
  { attribute: 'productType', text: 'Tipo', reportType: 'PRODUCT' },
  { attribute: 'productCategory', text: 'Categoria', reportType: 'PRODUCT' },
  { attribute: 'orderControl', text: 'Controle', reportType: 'PRODUCT' },
  { attribute: 'chartAccountId', text: 'Plano Conta', reportType: 'PRODUCT' },
  { attribute: 'costCenterId', text: 'Centro de custo', reportType: 'PRODUCT' },
  { attribute: 'active', text: 'Status', reportType: 'PRODUCT' },
  { attribute: 'stock', text: 'Estoque', reportType: 'PRODUCT' },
  { attribute: 'price', text: 'Preço', reportType: 'PRODUCT' },
];


export const DetailBoatsReport: CustomReportInterface[] = [
  { attribute: 'boatStatus', text: 'Status', reportType: 'DETAIL_BOATS' },
  { attribute: 'boatName', text: 'Nome', reportType: 'DETAIL_BOATS' },
  { attribute: 'boatSailors', text: 'Marinheiros', reportType: 'DETAIL_BOATS' },
  { attribute: 'vacancy', text: 'Vaga', reportType: 'DETAIL_BOATS' },
  { attribute: 'boatType', text: 'Tipo', reportType: 'DETAIL_BOATS' },
  { attribute: 'boatShipyard', text: 'Estaleiro', reportType: 'DETAIL_BOATS' },
  { attribute: 'boatModel', text: 'Modelo', reportType: 'DETAIL_BOATS' },
  { attribute: 'commercialLength', text: 'Pés', reportType: 'DETAIL_BOATS' },
  { attribute: 'boatCustomers', text: 'Proprietários', reportType: 'DETAIL_BOATS' },
  { attribute: 'boatServices', text: 'Serviços', reportType: 'DETAIL_BOATS' },
  { attribute: 'boatServicesStartDate', text: 'Início Serviço', reportType: 'DETAIL_BOATS' },
  { attribute: 'boatServicesEndDate', text: 'Fim Serviço', reportType: 'DETAIL_BOATS' },
  { attribute: 'boatServicesStatus', text: 'Status Serviço', reportType: 'DETAIL_BOATS' },
  { attribute: 'boatServicesValue', text: 'Valor Serviço', reportType: 'DETAIL_BOATS' },
  { attribute: 'boatServicesFeetValue', text: 'Valor Pé', reportType: 'DETAIL_BOATS' },
  { attribute: 'boatServicesDueDateDiscount', text: 'Desconto Vencimento', reportType: 'DETAIL_BOATS' },
  { attribute: 'boatServicesDiscount', text: 'Desconto', reportType: 'DETAIL_BOATS' },
  { attribute: 'boatServicesPercentageDiscount', text: 'Desconto %', reportType: 'DETAIL_BOATS' },
  { attribute: 'boatServicesTotalValue', text: 'Valor Total', reportType: 'DETAIL_BOATS' },
];

export const DetailCustomerReport: CustomReportInterface[] = [
  { attribute: 'status', text: 'Status', reportType: 'DETAIL_CUSTOMER' },
  { attribute: 'customerName', text: 'Cliente', reportType: 'DETAIL_CUSTOMER' },
  { attribute: 'customerFederalId', text: 'CPF/CNPJ', reportType: 'DETAIL_CUSTOMER' },
  { attribute: 'befeficiaryName', text: 'Beneficiário', reportType: 'DETAIL_CUSTOMER' },
  { attribute: 'serviceContractStart', text: 'Início Serviço', reportType: 'DETAIL_CUSTOMER' },
  { attribute: 'serviceContractEnd', text: 'Fim Serviço', reportType: 'DETAIL_CUSTOMER' },
  { attribute: 'serviceStatus', text: 'Status Serviço', reportType: 'DETAIL_CUSTOMER' },
  { attribute: 'serviceName', text: 'Serviço', reportType: 'DETAIL_CUSTOMER' },
  { attribute: 'customerServiceValue', text: 'Valor Serviço', reportType: 'DETAIL_CUSTOMER' },
  { attribute: 'customerServiceDueDateDiscount', text: 'Desconto Vencimento', reportType: 'DETAIL_CUSTOMER' },
  { attribute: 'customerServiceDiscount', text: 'Desconto', reportType: 'DETAIL_CUSTOMER' },
  { attribute: 'customerServiceDiscountPercent', text: 'Desconto %', reportType: 'DETAIL_CUSTOMER' },
  { attribute: 'customerServiceTotal', text: 'Valor Total', reportType: 'DETAIL_CUSTOMER' },
];


export const InvoiceTaxProduc: CustomReportInterface[] = [
  { attribute: 'id', text: 'Id', reportType: 'TAX_PRODUCT' },
  { attribute: 'nfeStatus', text: 'Status I.', reportType: 'TAX_PRODUCT' },
  { attribute: 'itens', text: 'Itens', reportType: 'TAX_PRODUCT' },
  { attribute: 'nfeNumero', text: 'NFE Numero', reportType: 'TAX_PRODUCT' },
  { attribute: 'serie', text: 'Série', reportType: 'TAX_PRODUCT' },
  { attribute: 'type', text: 'Tipo', reportType: 'TAX_PRODUCT' },
  { attribute: 'invoiceNumber', text: 'Número Fatura', reportType: 'TAX_PRODUCT' },
  { attribute: 'customerName', text: 'Fornecedor/Cliente', reportType: 'TAX_PRODUCT' },
  { attribute: 'createDate', text: 'Dt. Cadastro', reportType: 'TAX_PRODUCT' },
  { attribute: 'updateDate', text: 'Dt. Emissão', reportType: 'TAX_PRODUCT' },
  { attribute: 'valorTotal', text: 'Valor', reportType: 'TAX_PRODUCT' },
  { attribute: 'taxValue', text: 'Tributos', reportType: 'TAX_PRODUCT' },
  { attribute: 'status', text: 'Situação', reportType: 'TAX_PRODUCT' },
];

export const SuperInvoicingReportColumns: CustomReportInterface[] = [
  { attribute: 'invoiceNumber', text: 'Fatura Número', reportType: 'SUPER_INVOICING' },
  // { attribute: 'invoiceGeneratedBy', text: 'Fatura Gerada por', reportType: 'SUPER_INVOICING'},
  { attribute: 'invoiceValue', text: 'Fatura Valor', reportType: 'SUPER_INVOICING' },
  { attribute: 'invoiceEmissionDate', text: 'Fatura Data Emissão ', reportType: 'SUPER_INVOICING' },
  { attribute: 'invoiceCompetenceDate', text: 'Fatura Data Competência', reportType: 'SUPER_INVOICING' },
  { attribute: 'invoiceDueDate', text: 'Fatura Data Vencimento', reportType: 'SUPER_INVOICING' },
  { attribute: 'paymentValue', text: 'Pagamento Valor', reportType: 'SUPER_INVOICING' },
  { attribute: 'paymentDate', text: 'Pagamento Data', reportType: 'SUPER_INVOICING' },
  { attribute: 'paymentResponsibleEmployee', text: 'Pagamento Usuário Responsável ', reportType: 'SUPER_INVOICING' },
  { attribute: 'paymentDiscount', text: 'Pagamento Desconto', reportType: 'SUPER_INVOICING' },
  { attribute: 'paymentDueDateDiscount', text: 'Pagamento Desconto Vencimento', reportType: 'SUPER_INVOICING' },
  { attribute: 'paymentInterestAndFine', text: 'Pagamento Juros e Multa', reportType: 'SUPER_INVOICING' },
  { attribute: 'customerName', text: 'Cliente Nome', reportType: 'SUPER_INVOICING' },
  { attribute: 'customerGroup', text: 'Cliente Grupo', reportType: 'SUPER_INVOICING' },
  { attribute: 'customerFederalId', text: 'Cliente CPF/CNPJ', reportType: 'SUPER_INVOICING' },
  { attribute: 'nfeServiceNumber', text: 'Nota Serviço Número', reportType: 'SUPER_INVOICING' },
  // { attribute: 'nfeServiceGeneratedBy', text: 'Nota Serviço Gerado por', reportType: 'SUPER_INVOICING'},
  { attribute: 'nfeServiceValue', text: 'Nota Serviço Valor', reportType: 'SUPER_INVOICING' },
  { attribute: 'nfeServiceEmissionDate', text: 'Nota Serviço Data Emissão', reportType: 'SUPER_INVOICING' },
  { attribute: 'nfeServiceCompetenceDate', text: 'Nota Serviço Data Competência', reportType: 'SUPER_INVOICING' },
  { attribute: 'nfeProductNumber', text: 'Nota Produto Número da ', reportType: 'SUPER_INVOICING' },
  // { attribute: 'nfeProductGeneratedBy', text: 'Nota Produto Gerado por', reportType: 'SUPER_INVOICING'},
  { attribute: 'nfeProductValue', text: 'Nota Produto Valor', reportType: 'SUPER_INVOICING' },
  { attribute: 'nfeProductEmissionDate', text: 'Nota Produto Data Emissão', reportType: 'SUPER_INVOICING' },
  { attribute: 'nfeProductCompetenceDate', text: 'Nota Produto Data Competência', reportType: 'SUPER_INVOICING' },
  { attribute: 'taxablePeriodicQuantity', text: '30% Quantidade Itens', reportType: 'SUPER_INVOICING' },
  { attribute: 'taxablePeriodicValue', text: '30% Valor', reportType: 'SUPER_INVOICING' },
  { attribute: 'taxablePeriodicDueDateDiscount', text: '30% Desc Vencimento', reportType: 'SUPER_INVOICING' },
  { attribute: 'taxablePeriodicDueDateDiscountPercentage', text: '30% Desc Vencimento %', reportType: 'SUPER_INVOICING' },
  { attribute: 'taxablePeriodicDescription', text: '30% Descrição', reportType: 'SUPER_INVOICING' },
  { attribute: 'taxablePeriodicPlanoConta', text: '30% Plano Conta', reportType: 'SUPER_INVOICING' },
  { attribute: 'taxablePeriodicValidation', text: '30% Validação', reportType: 'SUPER_INVOICING' },
  { attribute: 'receiptPeriodicQuantity', text: '70% Quantidade de itens', reportType: 'SUPER_INVOICING' },
  { attribute: 'receiptPeriodicValue', text: '70% Valor', reportType: 'SUPER_INVOICING' },
  { attribute: 'receiptPeriodicDueDateDiscount', text: '70% Desc Vencimento', reportType: 'SUPER_INVOICING' },
  { attribute: 'receiptPeriodicDueDateDiscountPercentage', text: '70% Desc Vencimento %', reportType: 'SUPER_INVOICING' },
  { attribute: 'receiptPeriodicDescription', text: '70% Descrição', reportType: 'SUPER_INVOICING' },
  { attribute: 'receiptPeriodicPlanoConta', text: '70% Plano Conta', reportType: 'SUPER_INVOICING' },
  { attribute: 'receiptPeriodicValidation', text: '70% Validação', reportType: 'SUPER_INVOICING' },
  { attribute: 'periodicTotal', text: '100%', reportType: 'SUPER_INVOICING' },
  { attribute: 'periodicTotalDueDateDiscount', text: '100% Desc Vencimento', reportType: 'SUPER_INVOICING' },
  { attribute: 'periodicDescription', text: '100% Descrição', reportType: 'SUPER_INVOICING' },
  { attribute: 'taxableSporadicQuantity', text: 'NF Avulso Quantidade Itens', reportType: 'SUPER_INVOICING' },
  { attribute: 'taxableSporadicValue', text: 'NF Avulso Valor', reportType: 'SUPER_INVOICING' },
  { attribute: 'taxableSporadicDescription', text: 'NF Avulso Descrição', reportType: 'SUPER_INVOICING' },
  { attribute: 'taxableSporadicPlanoConta', text: 'NF Avulso Plano Conta', reportType: 'SUPER_INVOICING' },
  { attribute: 'receiptSporadicQuantity', text: 'Recibo Avulso Quantidade Itens', reportType: 'SUPER_INVOICING' },
  { attribute: 'receiptSporadicValue', text: 'Recibo Avulso Valor', reportType: 'SUPER_INVOICING' },
  { attribute: 'receiptSporadicDescription', text: 'Recibo Avulso Descrição', reportType: 'SUPER_INVOICING' },
  { attribute: 'receiptSporadicPlanoConta', text: 'Recibo Avulso Plano Conta', reportType: 'SUPER_INVOICING' },
  { attribute: 'taxablePeriodicClientQuantity', text: 'PC Cliente Quantidade Itens', reportType: 'SUPER_INVOICING' },
  { attribute: 'taxablePeriodicClientValue', text: 'PC Cliente Valor', reportType: 'SUPER_INVOICING' },
  { attribute: 'taxablePeriodicClientDueDateDiscount', text: 'PC Cliente Desc Vencimento', reportType: 'SUPER_INVOICING' },
  { attribute: 'taxablePeriodicClientDueDateDiscountPercentage', text: 'PC Cliente Desc Vencimento %', reportType: 'SUPER_INVOICING' },
  { attribute: 'taxablePeriodicClientDescription', text: 'PC Cliente Descrição', reportType: 'SUPER_INVOICING' },
  { attribute: 'taxablePeriodicClientPlanoConta', text: 'PC Cliente Plano Conta', reportType: 'SUPER_INVOICING' },
  { attribute: 'receiptPeriodicClientQuantity', text: 'PC Cliente Quantidade de itens', reportType: 'SUPER_INVOICING' },
  { attribute: 'receiptPeriodicClientValue', text: 'PC Cliente Valor', reportType: 'SUPER_INVOICING' },
  { attribute: 'receiptPeriodicClientDueDateDiscount', text: 'PC Cliente Desc Vencimento', reportType: 'SUPER_INVOICING' },
  { attribute: 'receiptPeriodicClientDueDateDiscountPercentage', text: 'PC Cliente Desc Vencimento %', reportType: 'SUPER_INVOICING' },
  { attribute: 'receiptPeriodicClientDescription', text: 'PC Cliente Descrição', reportType: 'SUPER_INVOICING' },
  { attribute: 'receiptPeriodicClientPlanoConta', text: 'PC Cliente Plano Conta', reportType: 'SUPER_INVOICING' },
]

export const SlingReportColumns: CustomReportInterface[] = [
  { attribute: 'id', text: 'Id', reportType: 'SLING' },
  { attribute: 'slingOrigin', text: 'Origem', reportType: 'SLING' },
  { attribute: 'requestTime', text: 'Data Solicitada', reportType: 'SLING' },
  { attribute: 'executionForecast', text: 'Previsão de Saída', reportType: 'SLING' },
  { attribute: 'boatName', text: 'Nome da Embarcação', reportType: 'SLING' },
  { attribute: 'slingStatus', text: 'Status Lingada', reportType: 'SLING' },
  { attribute: 'boatStatus', text: 'Status Embarcação', reportType: 'SLING' },
  { attribute: 'hasSailor', text: 'Possui Marinheiro', reportType: 'SLING' },
]

export const InvoiceReportColumns: CustomReportInterface[] = [
  { attribute: 'NF', text: 'NF', reportType: 'INVOICE' },
  { attribute: 'invoiceNumber', text: 'Fatura', reportType: 'INVOICE' },
  { attribute: 'origin', text: 'Origem', reportType: 'INVOICE' },
  { attribute: 'customer', text: 'Cliente', reportType: 'INVOICE' },
  { attribute: 'group', text: 'Grupo', reportType: 'INVOICE' },
  { attribute: 'issueDate', text: 'Data', reportType: 'INVOICE' },
  { attribute: 'dueDateToPay', text: 'Vencimento', reportType: 'INVOICE' },
  { attribute: 'competenceDate', text: 'Competência', reportType: 'INVOICE' },
  { attribute: 'value', text: 'Valor', reportType: 'INVOICE' },
  { attribute: 'discount', text: 'Desconto', reportType: 'INVOICE' },
  { attribute: 'interest', text: 'Juros', reportType: 'INVOICE' },
  { attribute: 'pendingValue', text: 'Pendente', reportType: 'INVOICE' },
  { attribute: 'totalPaid', text: 'Pago', reportType: 'INVOICE' },
  { attribute: 'status', text: 'Status', reportType: 'INVOICE' },
];

export const WashEngineTurnColumns: CustomReportInterface[] = [
  { attribute: 'boatName', text: 'Barco', reportType: 'WASH_ENGINE_TURN' },
  { attribute: 'washDate', text: 'Data', reportType: 'WASH_ENGINE_TURN' },
  { attribute: 'userName', text: 'Operador', reportType: 'WASH_ENGINE_TURN' },
  { attribute: 'isActive', text: 'Ativo', reportType: 'WASH_ENGINE_TURN' },
  { attribute: 'operation', text: 'Tipo de Operação', reportType: 'WASH_ENGINE_TURN' },
];

export const NavigationPlanReportColumns: CustomReportInterface[] = [
  { attribute: 'responsible', text: 'Solicitante', reportType: 'NAVIGATION_PLAN' },
  { attribute: 'name', text: 'Proprietário', reportType: 'NAVIGATION_PLAN' },
  { attribute: 'federalId', text: 'CPF/CNPJ', reportType: 'NAVIGATION_PLAN' },
  { attribute: 'boatName', text: 'Embarcação', reportType: 'NAVIGATION_PLAN' },
  { attribute: 'executionForecast', text: 'Prev Saída', reportType: 'NAVIGATION_PLAN' },
  { attribute: 'returnTime', text: 'Prev Retorno', reportType: 'NAVIGATION_PLAN' },
  { attribute: 'crewQtd', text: 'Total a Bordo', reportType: 'NAVIGATION_PLAN' },
  { attribute: 'destiny', text: 'Destino', reportType: 'NAVIGATION_PLAN' },
];

export const VisitorsColumns: CustomReportInterface[] = [
  { attribute: 'id', text: 'Id', reportType: 'VISITOR' },
  { attribute: 'name', text: 'Nome', reportType: 'VISITOR' },
  { attribute: 'federalId', text: 'Cpf', reportType: 'VISITOR' },
  { attribute: 'birthday', text: 'Nascimento', reportType: 'VISITOR' },
  { attribute: 'createdAt', text: 'Dt. Cadastro', reportType: 'VISITOR' },
  { attribute: 'phone', text: 'Telefone', reportType: 'VISITOR' },
  { attribute: 'status', text: 'Status', reportType: 'VISITOR' },
  { attribute: 'residentialZipCode', text: 'Cep', reportType: 'VISITOR' },
  { attribute: 'residentialCountry', text: 'País', reportType: 'VISITOR' },
  { attribute: 'residentialDistrict', text: 'Bairro', reportType: 'VISITOR' },
  { attribute: 'residentialCity', text: 'Cidade', reportType: 'VISITOR' },
  { attribute: 'residentialState', text: 'Estado', reportType: 'VISITOR' },
  { attribute: 'residentialAddress', text: 'Endereço', reportType: 'VISITOR' },
  { attribute: 'residentialNumber', text: 'Número', reportType: 'VISITOR' },
  { attribute: 'residentialComplement', text: 'Complemento', reportType: 'VISITOR' }
];

export const PropertiesColumns: CustomReportInterface[] = [
  { attribute: 'status', text: 'Status', reportType: 'PROPERTY' },
  { attribute: 'numberId', text: 'Número', reportType: 'PROPERTY' },
  { attribute: 'currentCustomerName', text: 'Nome Cliente', reportType: 'PROPERTY' },
  { attribute: 'lastAssociation', text: 'Data de Associação', reportType: 'PROPERTY' },
  { attribute: 'currentCustomerPhone', text: 'Telefone', reportType: 'PROPERTY' },
];

export const OrdersColumns: CustomReportInterface[] = [
  { attribute: 'orderNumber', text: 'Número', reportType: 'ORDER' },
  { attribute: 'orderRequestDate', text: 'Data Solicitação', reportType: 'ORDER' },
  { attribute: 'orderUpdateDate', text: 'Última Atualização', reportType: 'ORDER' },
  { attribute: 'orderOrigin', text: 'Origem', reportType: 'ORDER' },
  { attribute: 'orderValue', text: 'Valor', reportType: 'ORDER' },
  { attribute: 'orderStatus', text: 'Status', reportType: 'ORDER' },
  { attribute: 'boat', text: 'Embarcação', reportType: 'ORDER' },
  { attribute: 'customer', text: 'Cliente', reportType: 'ORDER' },
  { attribute: 'quota', text: 'Cota', reportType: 'ORDER' },
  { attribute: 'invoiceNumber', text: 'Número Fatura', reportType: 'ORDER' },
  { attribute: 'invoiceStatus', text: 'Status Fatura', reportType: 'ORDER' },
  { attribute: 'lastDateAttempt', text: 'Último Atendimento', reportType: 'ORDER' },
];

export const ServiceHistoricReport: CustomReportInterface[] = [
  { attribute: 'marinaOrderNumber', text: 'Nº do Pedido', reportType: 'SERVICE_HISTORIC' },
  { attribute: 'orderDate', text: 'Data do pedido', reportType: 'SERVICE_HISTORIC' },
  { attribute: 'origin', text: 'Origem', reportType: 'SERVICE_HISTORIC' },
  { attribute: 'invoiceNumber', text: 'Nº da Fatura', reportType: 'SERVICE_HISTORIC' },
  { attribute: 'boatName', text: 'Nome da Embarcação', reportType: 'SERVICE_HISTORIC' },
  { attribute: 'customerName', text: 'Nome do Cliente', reportType: 'SERVICE_HISTORIC' },
  { attribute: 'productName', text: 'Nome do Produto', reportType: 'SERVICE_HISTORIC' },
  { attribute: 'quantity', text: 'Quantidade', reportType: 'SERVICE_HISTORIC' },
  { attribute: 'value', text: 'Valor do Item', reportType: 'SERVICE_HISTORIC' },
  { attribute: 'totalValue', text: 'Valor Total', reportType: 'SERVICE_HISTORIC' },
  { attribute: 'discount', text: 'Desconto', reportType: 'SERVICE_HISTORIC' },
  { attribute: 'slingId', text: 'Nº da Lingada', reportType: 'SERVICE_HISTORIC' }
];

export const ServiceHistoricReportNfe: CustomReportInterface[] = [
  ...ServiceHistoricReport,
  { attribute: 'nfeNumber', text: 'Nota Fiscal', reportType: 'SERVICE_HISTORIC_NFE' },
]

export const FranchiseControllerReport: CustomReportInterface[] = [
  {attribute: 'boatName', text: 'Embarcação', reportType: 'FRANCHISE_CONTROLLER'},
  {attribute: 'commercialLength', text: 'Pes', reportType: 'FRANCHISE_CONTROLLER'},
  {attribute: 'owners', text: 'Proprietários', reportType: 'FRANCHISE_CONTROLLER'},
  {attribute: 'franchiseGroupName', text: 'Grupo de Franquia', reportType: 'FRANCHISE_CONTROLLER'},
  {attribute: 'franchiseNumber', text: 'Franquias', reportType: 'FRANCHISE_CONTROLLER'},
  {attribute: 'ammountFranchise', text: 'Franquias utilizadas', reportType: 'FRANCHISE_CONTROLLER'},
  {attribute: 'totalValue', text: 'Valor', reportType: 'FRANCHISE_CONTROLLER'},
  {attribute: 'ammountOrdered', text: 'Qtd. Cobrada', reportType: 'FRANCHISE_CONTROLLER'},
  {attribute: 'surplusAmount', text: 'Qtd. Excedida', reportType: 'FRANCHISE_CONTROLLER'},
  {attribute: 'serviceName', text: 'Serviço excedente', reportType: 'FRANCHISE_CONTROLLER'},
  {attribute: 'unitPrice', text: 'Valor unitário', reportType: 'FRANCHISE_CONTROLLER'},
  {attribute: 'status', text: 'Status', reportType: 'FRANCHISE_CONTROLLER'},
  {attribute: 'orderNumber', text: 'Numero do Pedido', reportType: 'FRANCHISE_CONTROLLER'},
  {attribute: 'invoiceNumber', text: 'Numero da fatura', reportType: 'FRANCHISE_CONTROLLER'},
  {attribute: 'exempt', text: 'Isento', reportType: 'FRANCHISE_CONTROLLER'},
];


export const CustomerCreditExport: CustomReportInterface[] = [
  { attribute: 'name', text: 'Nome', reportType: 'CUSTOMER-CREDIT' },
  { attribute: 'email', text: 'Email', reportType: 'CUSTOMER-CREDIT' },
  { attribute: 'phone', text: 'Telefone', reportType: 'CUSTOMER-CREDIT' },
  { attribute: 'balanceNow', text: 'Saldo Atual', reportType: 'CUSTOMER-CREDIT' },
]
