<p-fieldset>
  <p-header class="article-title">
    <span class="article-title">{{'TITLE-NIX-CONFIG' | translate}}</span>
  </p-header>

  <p-tabView>
    <p-tabPanel header="Integrações">
      <p-card class="card-bg">
        <div class="row row-div">
          <div class="form-control mr-4">
            <div clas="mb-1"><label for="Login">Login de acesso</label></div>
            <input pInputText [(ngModel)]="nixIntegrationConfig.nixLogin" name="Login"
            type="text"
            >
          </div>
          <div class="form-control mr-4">
            <div clas="mb-1"><label for="token">Token de acesso</label></div>
            <input pInputText [(ngModel)]="nixIntegrationConfig.nixToken" name="token"
            type="text"
            >
          </div>
          <div class="form-control mr-4">
            <div clas="mb-1"><label for="token">Senha de acesso</label></div>
            <input pInputText [(ngModel)]="nixIntegrationConfig.nixPassword" name="token"
            type="password"
            >
          </div>
          <p-inputSwitch class="margin-input" [(ngModel)]="nixIntegrationConfig.enableBillet"></p-inputSwitch>
          <span class="margin-span">Boleto Cobrança</span>

          <p-inputSwitch class="margin-input" [(ngModel)]="nixIntegrationConfig.enablePix"></p-inputSwitch>
          <span class="margin-span">Adicionar Pix na fatura</span>

          <p-inputSwitch class="margin-input" [(ngModel)]="nixIntegrationConfig.enableHybridBillet"></p-inputSwitch>
          <span class="margin-span">Adicionar Pix no boleto</span>
        </div>
      </p-card>
      <div >
            <p-card [style]="{'background-color': '#FAFAFA'}">
              <ng-template pTemplate="header">
                <h3 class="pt-4 pl-4">Configurar Pix de Cobrança</h3>
              </ng-template>
              <ng-template pTemplate="content" class="p-0">
                <div class="form-group flex pt-0 mr-0">

                  <div class="form-control mr-4">
                    <div clas="mb-1"><label for="social">Código NIX</label></div>
                    <input pInputText [(ngModel)]="nixIntegrationConfig.socialNumber" name="social"
                    (blur)="getNixPix()"
                    >
                  </div>
                  <!-- <div class="flex align-items-center flex-row pt-0 col-3"> -->
                  <div class="form-control mr-4">
                    <label class="mr-2 mb-1">Forma de Pagamento</label>
                        <p-dropdown [(ngModel)]="nixIntegrationConfig.pixPaymentForm"  appendTo="body"
                            [placeholder]="'Selecione...'"
                            [options]="paymentMethodsDropdown"
                            (onChange)="selectedPaymentMethods($event.value)"
                            >
                        </p-dropdown>
                  </div>
                  <div class="form-control mr-4">
                    <label class="mr-2 mb-1">Conta para Crédito</label>
                        <p-dropdown [(ngModel)]="nixIntegrationConfig.pixAccount"  appendTo="body"
                            [options]="accountDropdown">
                        </p-dropdown>
                  </div>
                  <div class="form-control mr-4">
                    <label class="mr-2 mb-1">Pix usado na fatura</label>
                        <p-dropdown [(ngModel)]="nixIntegrationConfig.pixNumber"  appendTo="body"
                            [options]="pixDropdown">
                        </p-dropdown>
                  </div>
                </div>

              </ng-template>
            </p-card>
      </div>

      <div class="text-right mt-5 mr-5">
        <button class="easymarine danger mr-5" (click)="cancel()">Cancelar</button>
        <button class="easymarine primary" (click)=" saveNixIntegration()">Salvar</button>
      </div>
    </p-tabPanel>
    <p-tabPanel header="Permissões">
      <p-card [style]="{'background-color': '#FAFAFA'}">
        <ng-template pTemplate="header">
          <h2 class="pt-3 pl-3">Adicionar Usuários para Transações</h2>
        </ng-template>
        <ng-template pTemplate="content">
          <div class="flex flex-row justify-content-between">
            <div class="flex align-items-center flex-row pt-0">
              <label class="search-user mr-2">Nome do usuário</label>
                  <p-dropdown [(ngModel)]="userName" name="active" appendTo="body"
                      [options]="userResults">
                  </p-dropdown>
            </div>
            <button (click)="registerUser()" class="primary easymarine mr-6" type="button">Adicionar usuário</button>
          </div>
        </ng-template>
      </p-card>
      <div class="pt-5">
        <h2 class="pt-2 pl-3">Lista de Usuários</h2>
        <p-table [value]="userTable">
          <ng-template pTemplate="header">
            <tr>
              <th class="text-center">Nome do Usuário</th>
              <th class="text-center">Gerar Boleto</th>
              <th class="text-center">Cancelar Boleto</th>
            </tr>
          </ng-template>
          <ng-template let-user pTemplate="body">
            <tr>
              <td class="text-center">{{user.user.username}}</td>
              <td class="text-center">
                <p-inputSwitch [(ngModel)]="user.billetCreate" [readonly]="nixIntegrationConfig.enableBoletoCobranca"></p-inputSwitch>
              </td>
              <td class="text-center">
                <p-inputSwitch [(ngModel)]="user.billetCancel" [readonly]="nixIntegrationConfig.enableBoletoCobranca"></p-inputSwitch>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <div class="text-right mt-5 mr-5">
          <button class="easymarine danger mr-5" (click)="cancel()">Cancelar</button>
          <button class="easymarine primary" (click)=" saveUserAccessBanks()">Salvar</button>
        </div>
      </div>
    </p-tabPanel>
  </p-tabView>

</p-fieldset>
