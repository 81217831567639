<!-- <div class="faq">
  <img
    src="assets/images/contact.png"
    alt="create"
    class="create"
    (click)="openFaq()"
  />
</div> -->
<div
  [class]="'footer ' + env.environmentAppClass"
  [ngClass]="{ checked: showSideNav, unchecked: !showSideNav, mobile: mobile }"
>
  <div class="image">
    <a [routerLink]="['/']">
      <img src="assets/images/logoeasy.png" width="35px" />
    </a>
  </div>
  <div class="copyright">
    <span class="float-left">
      <span
        >Copyright ©
        <a class="brand" target="_blank" [href]="env.productLink">EASYMARINE</a>
        {{ today | date: "yyyy" }}</span
      >
    </span>
  </div>
  <div class="environment text-right">
    <span *ngIf="!env.production">
      <span>{{ env.environmentApp }}</span>
    </span>
  </div>
</div>
