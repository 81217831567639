<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">Abastecimento</span>
    </p-header>
    <form (submit)="saveFuelData()" class="dialog-form">
        <div class="form-group">
            <div class="form-control">
                <label>{{ 'ADD-FUEL' | translate }}</label>
                <p-dropdown placeholder="Selecione..." required [options]="filteredFuels" (onChange)="changeFuel()"
                    [disabled]="isASlingEdition" appendTo="body" [(ngModel)]="addFuel" name="addFuel">
                    <ng-template let-fuel pTemplate="item">
                        <div>{{fuel.label}}</div>
                    </ng-template>
                </p-dropdown>
            </div>

        </div>
        <br>
        <div class="form-group">
            <div class="form-group radio">
                <div class="radio-button">
                    <div class="form-control">
                        <p-radioButton name="fuelMeasurementType" label="Sem combustível"
                            [(ngModel)]="fuelMeasurementType" inputId="NoGasoline" [disabled]="isASlingEdition">
                        </p-radioButton>
                    </div>
                </div>
                <div class="radio-button" *ngIf="slingConfig.ownFuelSupply">
                    <div class="form-control">
                        <p-radioButton name="fuelMeasurementType" value="OwnFuelSupply" label="Abastecimento próprio"
                            [(ngModel)]="fuelMeasurementType" inputId="OwnFuelSupply" [disabled]="isASlingEdition">
                        </p-radioButton>
                    </div>
                </div>
                <div class="radio-button" *ngIf="!addFuel || addFuel?.measurement !== 'Units'">
                    <div class="form-control">
                        <p-radioButton name="fuelMeasurementType" value="Value"
                            (click)="onFuelMeasurementType(slingFormComponent?.sling?.prepare)"
                            label="{{ 'VALUE' | translate }}" [(ngModel)]="fuelMeasurementType" inputId="Value"
                            [disabled]="isASlingEdition">
                        </p-radioButton>
                        <p-inputNumber [(ngModel)]="valueTotalFuel" name="valueTotalFuel" mode="decimal"
                            [maxFractionDigits]="2" [minFractionDigits]="2" [disabled]="isASlingEdition"
                            *ngIf="fuelMeasurementType == 'Value' && addFuel != null">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="radio-button" *ngIf="slingConfig.measuredFuel">
                    <div class="form-control">
                        <p-radioButton name="fuelMeasurementType" value="Measured"
                            (click)="onFuelMeasurementType(slingFormComponent?.sling?.prepare)"
                            label="{{ 'MEASURED' | translate }}" [(ngModel)]="fuelMeasurementType" inputId="Measured"
                            [disabled]="isASlingEdition">
                        </p-radioButton>
                        <p-inputNumber [(ngModel)]="quantityTotalFuel" name="quantityTotalFuel"
                            *ngIf="fuelMeasurementType == 'Measured' && addFuel != null" [disabled]="isASlingEdition"
                            mode="decimal" [maxFractionDigits]="getDecimals()" [minFractionDigits]="getDecimals()">
                        </p-inputNumber>
                    </div>
                </div>
                <div class="radio-button"
                    *ngIf="slingConfig.hasFractionalSupply && (!addFuel || addFuel?.measurement !== 'Units') && !tanqueCheio()">
                    <div class="form-control">
                        <p-radioButton name="fuelMeasurementType" value="FuelFraction"
                            (click)="onFuelMeasurementType(slingFormComponent?.sling?.prepare)"
                            label="{{ 'FUEL-FRACTION' | translate }}" [(ngModel)]="fuelMeasurementType"
                            inputId="FuelFraction" [disabled]="isASlingEdition">
                        </p-radioButton>
                        <p-dropdown placeholder="Selecione..." required [options]="fuelFractions" appendTo="body"
                            [disabled]="isASlingEdition" [(ngModel)]="fuelTankFraction" name="fuelTankFraction"
                            *ngIf="fuelMeasurementType == 'FuelFraction' && addFuel != null">
                        </p-dropdown>
                    </div>
                </div>
                <div class="radio-button"
                    *ngIf="slingConfig.hasFractionalSupply && (!addFuel || addFuel?.measurement !== 'Units') && tanqueCheio()">
                    <div class="form-control">
                        <p-radioButton name="fuelMeasurementType" value="FuelFraction"
                            (click)="fuelTankFraction = 'Full'" label="Tanque cheio" [(ngModel)]="fuelMeasurementType"
                            inputId="FuelFraction" [disabled]="isASlingEdition">
                        </p-radioButton>
                    </div>
                </div>
            </div>
            <p-panel header="{{'Valor e quantidade' | translate}}" class="flex1">

                <div>
                    <span>Combustível: <strong>{{addFuel?.name}}</strong></span>
                </div>

                <div>
                    <span>
                        Estoque: <strong>{{getBalanceInStock() | number: '1.' + getDecimals()}}</strong>
                        <span [hidden]="!stockBelowMinimum" class="{{colorStockBelowMinimum}}">(Estoque abaixo do
                            mínimo)</span>
                    </span>
                </div>

                <div>
                    <span>Preço unitário: <strong>{{addFuel?.price?.value | number: '1.2-2'}}</strong></span>
                </div>

                <div *ngIf="fuelMeasurementType == 'Value'">
                    <span>
                        Quantidade:
                        <strong>{{valueTotalFuel / addFuel?.price?.value | number: '1.3'}}</strong>
                    </span>
                </div>

                <div *ngIf="fuelMeasurementType == 'Value'">
                    <span>
                        Valor total:
                        <strong>{{valueTotalFuel | number: '1.2'}}</strong>
                    </span>
                </div>

                <div *ngIf="fuelMeasurementType == 'Measured'">
                    <span>
                        Quantidade:
                        <strong>{{quantityTotalFuel | number:  '1.'+ getDecimals()}}</strong>
                    </span>
                </div>

                <div *ngIf="fuelMeasurementType == 'Measured'">
                    <span>Valor total:
                        <strong>{{ calculateTotal(quantityTotalFuel, addFuel?.price?.value) | number: '1.2-2' }}</strong>
                    </span>
                </div>

                <div *ngIf="fuelMeasurementType == 'FuelFraction' && fuelTankFraction != null">
                    <span>Quantidade:
                        <strong>{{getLabelFraction(fuelTankFraction)}}</strong>
                    </span>
                </div>

                <div *ngIf="fuelMeasurementType == 'FuelFraction' && fuelTankFraction != null">
                    <span>Valor total:
                        <strong>{{getLabelFraction(fuelTankFraction)}}</strong>
                    </span>
                </div>
            </p-panel>
        </div>
        <p-footer>
            <div class="form-group">
                <div class="form-control">
                    <div class="float-left" *ngIf="slingOrder.id">
                        <p style="font-weight:500; padding-left: 20px">
                            Data do pedido: {{getRequestDate() | date: completeDateFormat}}
                        </p>
                    </div>
                </div>
                <div class="buttons">
                    <button type="button" (click)="back()"
                        class="easymarine danger">{{ 'Voltar' | translate }}</button>
                    <button [disabled]="disableFlag" class="easymarine" type="submit">
                        {{ 'NEXT' | translate }}
                    </button>
                </div>
            </div>
        </p-footer>
    </form>
</p-fieldset>
