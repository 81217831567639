<div class="dialog-form">
    <div class="header-botton" *ngIf="document.status === 'WAITING_SIGNATORIES'">
        <div></div>
        <div class="primary fab-button" style="right: 0;" (click)="newSigner()">
            <i class="pi pi-plus" style="font-size: 21px;"></i>
        </div>
    </div>

    <div class="table">
        <p-table *ngIf="signers?.length > 0"  [value]="signers" width="100%" autoLayout="true">
            <ng-template pTemplate="header">
                <tr>
                    <th class="text-center">E-mail</th>
                    <th class="text-center">Assinante</th>
                    <th class="text-center" *ngIf="document.status === 'WAITING_SIGNATORIES'">Opções</th>
                    <th class="text-center" *ngIf="document.status !== 'WAITING_SIGNATORIES'">Assinado</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-sign>
                <tr>
                    <td class="text-center">{{sign.email}}</td>
                    <td class="text-center">{{ sign.signerType | translate}}</td>
                    <td class="text-center" *ngIf="document.status === 'WAITING_SIGNATORIES'">
                        <i class="table-action" pTooltip="Excluir Assinante" tooltipPosition="bottom"
                            (click)="deleteSigner(sign)" *ngIf="isExternalSigner(sign)">
                            <i class="pi pi-trash" style="color:  #EF5350"></i>
                        </i>
                    </td>
                    <td class="text-center" *ngIf="document.status !== 'WAITING_SIGNATORIES'">
                        {{sign.dateSignature | date: 'dd/MM/yy'}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
