import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APPCONFIG } from '../config';
import { map } from 'rxjs/operators';
import { DocumentContractedMarina } from '../models/document-contracted-marina';
import { environment } from 'src/environments/environment';
import { PaginationFilter } from '../models/pagination-filter';
import { DocumentContractedMarinaResponseDTO } from '../models/dtos/document-contracted-marina-responseDTO';

@Injectable()
export class DocumentContractedMarinaService {

    private urlDocumentContracted = environment.apiHost + '/secure/document-contrated';

    constructor(
        private http: HttpClient
    ) { }

    getDocuments(paginationFilter: PaginationFilter): Observable<DocumentContractedMarinaResponseDTO> {

        let params = new HttpParams();

        if (!paginationFilter.sort || !paginationFilter.order) {
            paginationFilter.sort = 'referenceDate';
            paginationFilter.order = 'DESC';
        }

        params = params.set('sort', paginationFilter.sort.concat(',').concat(paginationFilter.order));

        if (!paginationFilter.size) {
            paginationFilter.size = 0;
        }

        params = params.set('page', paginationFilter.page.toString());
        params = params.set('size', paginationFilter.size.toString());

        return this.http.get(`${this.urlDocumentContracted}/documents/marina`, {params})
            .pipe(map((response: any) => response.data.contractedDocuments as DocumentContractedMarinaResponseDTO));
    }

    findDocumentContractedInvoiceDelayed(): Observable<DocumentContractedMarina>{
        return this.http.get(`${this.urlDocumentContracted}/marina-delayed`)
          .pipe(map((response: any) => response.data.contractDocument as DocumentContractedMarina));
    }
}
