<div class="filters active">
  <div class="filter-row">
    <section style="display: flex; flex-direction: column; font-weight: 600;">
      <span>Total de embarcações: {{engineTurnDetails.boatAll}}</span>
      <span>Embarcações agendadas: {{engineTurnDetails.boatScheduled}}</span>
      <span>Embarcações sem agendamento: {{engineTurnDetails.boatNoSchedule}}</span>
      <span>Embarcações nunca agendadas: {{engineTurnDetails.boatNeverSchedule}}</span>
    </section>
  </div>
  <div class="filter-row">
    <div class="filter-row">
      <div class="form-control">
        <label>Data de agendamento</label>
            <p-calendar dateFormat="dd/mm/yy" [minDate]="currentDate" name="schedulingDate" [readonlyInput]="true"
                appendTo="body" [(ngModel)]="schedulingDate" [showIcon]="true" i18n>
            </p-calendar>
        </div>

        <div class="buttons flex-start">
            <div class="form-button">
                <button class="success easymarine" (click)="dialogOptionSchedule()"
                    [disabled]="!schedulingDate || !boatSelected">{{'Confirmar' | translate}}</button>
            </div>
            <div class="form-button">
                <button class="danger easymarine" [disabled]="!boatScheduleSelected"
                    (click)="cancelSchedule()">{{ 'Cancelar agendamento' | translate }}</button>
            </div>
        </div>
        <div class="buttons">
            <div class="form-button">
                <button class="primary easymarine" (click)="registerManual()"
                    [disabled]="!boatSelected">{{'Registro manual' | translate}}</button>
            </div>
        </div>
    </div>
    <div class="filter-row" style="margin-left: auto;">
        <div class="form-control" style="width: 200px;">
            <label for="wash-status">{{'STATUS' | translate}}</label>
            <p-multiSelect [defaultLabel]="''" [options]="filterStatusoptions" [(ngModel)]="filter.status"
                appendTo="body" selectedItemsLabel="{0} itens selecionados" name="wash-status" (onChange)="filterGlobal()">
            </p-multiSelect>
        </div>
    </div>
</div>
<br>
<div class="row">
    <div class="col-12">
        <p-table [value]="boatsEngineTurnTable" #tt [globalFilterFields]="['boatName']" autoLayout="true" [scrollable]="true" scrollHeight="500px"
            styleClass="boat-engine-wash-table">
            <ng-template pTemplate="caption">
                <td appHideMobile></td>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText size="50" placeholder="Nome da Embarcação ou Usuário" [(ngModel)]="globalFilter"
                        (input)="filterGlobal()" style="width:auto" name="search">
                </span>
                <td appHideMobile></td>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th width="3%"></th>
                    <th width="10%">Último Giro</th>
                    <th width="4%">Orig.</th>
                    <th width="9%">Marinheiro </th>
                    <th width="20%">Nome da Embarcação</th>
                    <th width="9%">Modelo</th>
                    <th width="12%">Usuário (Giro de motor)</th>
                    <th width="11%">Agendamento</th>
                    <th width="4%">Orig.</th>
                    <th width="7%">Histórico</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-boatEngineTurn>
                <tr [ngClass]="{'tr-color-1': i % 2 == 0,
                                'tr-color-2': i % 2 != 0,
                                'col-danger': isOverdueTask(boatEngineTurn),
                                'col-warning': isEqualdueTask(boatEngineTurn)}">
                    <td class="text-center">
                        <p-checkbox [(ngModel)]="boatEngineTurn.boatEngineTurnSelected" binary="true"
                            (click)="onBoatSelect()" name="selectEngineTurn">
                        </p-checkbox>
                    </td>
                    <td class="text-center">{{boatEngineTurn.lastEngineTurnDate | date:'dd/MM/yyyy'}}</td>
                    <td class="text-center">
                        <i *ngIf="boatEngineTurn.lastEngineTurnDate && !boatEngineTurn.isSeaLast"
                            class="table-icon pi pi-clock" aria-hidden="true" style="color: #002640"></i>
                        <img *ngIf="boatEngineTurn.lastEngineTurnDate && boatEngineTurn.isSeaLast"
                            src="assets/images/sea.png" style="height: 40px; width: 50px; padding: 7px 3px;" />
                    </td>
                    <td class="text-center">
                        <img src="assets/images/sailor.png" alt="" *ngIf="boatEngineTurn.isSailor"
                            style="height: 43px; width: auto; padding: 7px 3px;">
                    </td>

                    <td>
                        {{boatEngineTurn.boatName}}
                        <span *ngIf="boatEngineTurn.maintenance">(Em manutenção)</span>
                    </td>
                    <td>{{boatEngineTurn.boatModel.toUpperCase() | translate}}</td>
                    <td>{{boatEngineTurn.userName}}</td>
                    <td class="text-center">{{boatEngineTurn.engineTurnDate | date:'dd/MM/yyyy'}}</td>
                    <td class="text-center">
                        <i *ngIf="boatEngineTurn.engineTurnDate && !boatEngineTurn.isSea" class="pi pi-clock table-icon"
                            aria-hidden="true"></i>
                        <img *ngIf="boatEngineTurn.engineTurnDate && boatEngineTurn.isSea" src="assets/images/sea.png"
                            class="table-image" />
                    </td>
                    <td class="text-center"
                        [ngStyle]="{'cursor': boatEngineTurn.lastEngineTurnDate ? 'pointer' : 'auto'}"
                        (click)="boatEngineTurn.lastEngineTurnDate && openEngineTurnHistoryDialog(boatEngineTurn)">
                        <fa-icon [icon]="'history'" class="table-icon"
                            [ngClass]="{'no-last-turn': !boatEngineTurn.lastEngineTurnDate}"></fa-icon>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
