import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OrderQuota } from 'src/app/models';
import { BoatService } from 'src/app/services/boat.service';
import { MarinaOrderService } from 'src/app/services/marina-order.service';
import { ProductService } from 'src/app/services/product.service';
import { ToastService } from 'src/app/services/toast.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { OrderUtil } from 'src/app/utils/order.util';
import { StorageUtil } from 'src/app/utils/storage.util';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.scss']
})
export class OrderFormComponent implements OnInit {

  marinaOrder: any;
  products = [];
  boatCustomers: any = [];
  filteredCustomers = [];
  selectedCustomer: any;
  selectedProduct: any;
  filteredProduct = [];
  productCategoryType: any;
  filterProductName: any = '';
  filterCustomerName: any = '';
  fuelFractions = [];
  constructor(
    private orderService: MarinaOrderService,
    private productService: ProductService,
    private messageService: MessageUtil,
    private boatService: BoatService,
    public dialogRef: DynamicDialogRef,
    private spinner: NgxSpinnerService,
    private toasty: ToastService,
    protected orderUtil: OrderUtil,
    public config: DynamicDialogConfig
  ) {
    this.productCategoryType = 'Consumable';
    this.fuelFractions = this.orderUtil.getFuelFractionOptions();
    this.marinaOrder = this.config.data.marinaOrder;
  }

  ngOnInit(): void {
    this.loadBoatCustomer();
  }

  loadBoatCustomer(): void {
    this.boatService.getById(this.marinaOrder.boat.id).subscribe(
      (data) => {
        this.boatCustomers = data.boatCustomers;
        this.filterCustomers();
      }
    );
  }

  filterCustomers(): void {
    this.filteredCustomers = this.boatCustomers.filter((customerBoat) =>
      customerBoat.type.toUpperCase() === 'OWNER' &&
      this.marinaOrder.orderQuotas.find((oq) => oq.customer.id === customerBoat.customer.id) == null);
  }

  loadProducts(): void {
    this.productService.getByProductCategoryType(this.productCategoryType).subscribe(
      (data) => {
        this.products = data;
        this.filteredProduct = [];
      }
    );
  }

  filterProduct(productName): void {
    this.filteredProduct = this.products.filter((product) =>
      product.name.toUpperCase().includes(productName.toUpperCase()) &&
      this.marinaOrder.orderItems.find((item) => item.idProduct === product.id) == null);
  }

  selectProduct(product): void {
    this.selectedProduct = product;
  }

  selectCustomerQuota(customerQuota): void {
    this.selectedCustomer = customerQuota.value;
  }

  addCustomerQuota(): void {
    if (this.selectedCustomer) {
      const customerQuota = new OrderQuota();
      customerQuota.customer = this.selectedCustomer.customer;
      customerQuota.quota = 0;
      customerQuota.idInvoice = null;
      this.marinaOrder.orderQuotas.push(customerQuota);
      this.selectedCustomer = null;
      this.filterCustomerName = '';
    }
  }

  deleteCustomerQuotaConfirmation(customerQuota): void {
    Swal.fire({
      title: 'Atenção',
      text: 'Deseja realmente cancelar a quota: ' + customerQuota.name,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.deleteCustomerQuota(customerQuota);
      }
    });
  }

  deleteCustomerQuota(customerQuota): void {
    const indice = this.marinaOrder.orderQuotas.indexOf(customerQuota);
    if (indice !== -1) {
      this.marinaOrder.orderQuotas.splice(indice, 1);
    }
  }

  deleteItemConfirm(orderItem): void {
    Swal.fire({
      title: 'Atenção',
      text: 'Deseja realmente cancelar o item: ' + orderItem.product.name + '?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.deleteIten(orderItem);
      }
    });
  }

  deleteIten(orderItem): void {
    const indice = this.marinaOrder.orderItems.indexOf(orderItem);
    if (indice !== -1) {
      this.marinaOrder.orderItems.splice(indice, 1);
    }
  }

  calculateTotalItem(item): number {
    return (item.qtySupplied * item.itemValue) - item.discount;
  }

  calculateTotalItens(): number {
    let total = 0;
    this.marinaOrder.orderItems.forEach((item) => {
      total += (item.qtySupplied * item.itemValue) - item.discount;
    });
    return Number(total.toFixed(2));
  }

  calculateCustomerQuota(quota): number {
    return this.calculateTotalItens() * quota / 100;
  }

  calculateTotalQuota(): number {
    let total = 0;
    this.marinaOrder.orderQuotas.forEach((oq) => total += Number(oq.quota));
    return total;
  }

  calculateTotalQuotaValue(): number {
    let total = 0;
    this.marinaOrder.orderQuotas.forEach((oq) => {
      total += +this.calculateCustomerQuota(oq.quota).toFixed(2);
    });
    return total;
  }

  checkMaxValue(item) {
    console.log(item);
    if (item.discount > item.itemValueTotal) {
      item.discount = item.itemValueTotal;
    }
  }

  hasInvoice(): boolean {
    return this.marinaOrder.orderQuotas.find((orderQuota) => orderQuota.idInvoice !== null) !== undefined;
  }

  save(): void {
    if (this.calculateTotalQuota() !== 100) {
      this.messageService.generateMessage('warning', 'SUMMARY.WARNING', 'A distruibuição de cotas deve ser igual a 100%.');
      return;
    }
    this.marinaOrder.value = this.calculateTotalItens();
    this.spinner.show();

    this.marinaOrder.lastEmployeeName = StorageUtil.getUserName();

    this.orderService.edit(this.marinaOrder).subscribe(
      (data) => {
        this.marinaOrder = data;
        this.toasty.success('Pedido alterado com sucesso.');
        this.dialogRef.close(this.marinaOrder);
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }

  isEditable(): boolean {
    return this.marinaOrder.orderStatus.toUpperCase() !== 'CANCELED';
  }

  getStatus(): string {
    switch (this.marinaOrder.orderStatus.toUpperCase()) {
      case 'CANCELED':
        return 'Cancelado';
      case 'PARTIALLY-ATTEMPTED':
        return 'Parcial';
      case 'ATTEMPTED':
        return 'Atendido';
      default:
        return 'Solicitado';
    }
  }

  getOrigin(): string {
    switch (this.marinaOrder.origin) {
      case 'DETACHED':
        return 'Avulso';
      case 'TUITION':
        return 'Mensalidade';
      default:
        return 'Lingada';
    }
  }
}
