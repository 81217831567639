import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-log-price',
  templateUrl: './product-log-price.component.html',
  styleUrls: ['./product-log-price.component.scss']
})
export class ProductLogPriceComponent implements OnInit {

  @Input() productLog;
  @Input() isNew;

  isPriceRanger = false;

  oldDatasMap

  constructor() { }

  ngOnInit(): void {
    this.oldDatasMap = new Map(Object.entries(this.productLog.oldDatas));
    if(this.oldDatasMap.has('price')){
      this.verifyChangesPriceRanger();
    }
  }

  getPricingType() {
    if(!this.isNew && this.oldDatasMap.has('pricingType')){
      return this.productLog.oldDatas.pricingType;
    }

    return this.productLog.pricingType;
  }

  verifyChangesPriceRanger(){
    const oldDatasMapPrice = new Map(Object.entries(this.productLog.oldDatas.price));
    this.isPriceRanger = oldDatasMapPrice.has('priceRanges');

  }

  isTypeProduct(): boolean{
    return (this.productLog.oldDatas.productType == 'Product' && !this.isNew) || (this.productLog.productType == 'Product' && this.isNew);
  }

  isSimplePrice(){
    return (this.productLog.oldDatas.pricingType == 'SimplePrice' && !this.isNew)
    || ( this.productLog.pricingType == 'SimplePrice' && this.isNew)
    || ( !this.oldDatasMap.has('pricingType') && this.productLog.pricingType == 'SimplePrice' && !this.isNew)
    || this.isChangedDiscount()
    || this.isChangedPriceFree()
    || this.isChangedPriceRange();
  }

  isChangedPriceValue(){
    return this.productLog.price.value != this.productLog.oldDatas.price.value;
  }

  isChangedDiscount(){
    return this.oldDatasMap.has('discount') && this.productLog.oldDatas.discount != this.productLog.discount;
  }

  getDiscount(): boolean {
    let discount = this.productLog.discount;
    if(this.isChangedDiscount() && !this.isNew) {
      discount = this.productLog.oldDatas.discount;
    }
    return discount;
  }

  isChangedPriceFree() {
    return this.oldDatasMap.has('price') && this.productLog.oldDatas.price.priceFree != this.productLog.price.priceFree;
  }

  getPriceFree(): boolean {
    let priceFree = this.productLog.price.priceFree;
    if(this.isChangedPriceFree() && !this.isNew) {
      priceFree = this.productLog.oldDatas.price.priceFree;
    }
    return priceFree;
  }

  isChangedPriceRange() {
    return this.oldDatasMap.has('price') && this.productLog.oldDatas.price.priceRange != this.productLog.price.priceRange;
  }

}
