import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Visitor } from '../../../../../../models/dtos/access-controller/visitor';
import { Component, OnInit } from '@angular/core';
import { VisitorService } from 'src/app/services/access-controler/visitor.service';

@Component({
  selector: 'app-visitor-form-historic',
  templateUrl: './visitor-form-historic.component.html',
  styleUrls: ['./visitor-form-historic.component.scss'],
})
export class VisitorFormHistoricComponent implements OnInit {
  visitor: Visitor;
  totalRecords: number;
  numberRows = 50;
  historic: any[] = [];
  constructor(
    public dialog: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private visitorService: VisitorService
  ) {}

  ngOnInit() {
    this.visitor = this.config.data.visitor;
    this.loadHistoric();
  }

  loadHistoric() {
    this.visitorService
      .getHistoric(this.visitor.colectId)
      .subscribe((response) => {
        this.historic = response;
        this.totalRecords = this.historic.length;
      });
  }
}
