<div class="form-group" *ngIf="isNew && searchByCustomer !== undefined && slingConfig">
  <label><strong>Selecionar por:</strong></label>
  <div class="form-radio">
    <p-radioButton inputId="optTrue" [value]="true" name="searchMethod" [(ngModel)]="searchByCustomer"
      (onClick)="changeSearch($event)" [disabled]="!isNew">
    </p-radioButton>
    <label for="optTrue">Cliente</label>
  </div>
  <div class="form-radio">
    <p-radioButton inputId="optFalse" [value]="false" name="searchMethod" [(ngModel)]="searchByCustomer"
      (onClick)="changeSearch($event)" [disabled]="!isNew">
    </p-radioButton>
    <label for="optFalse">Embarcação</label>
  </div>
</div>
<br>
<form [formGroup]="form" class="form-group" style="display: grid; grid-template-columns: 1fr 1fr 1fr;">
  <app-movement-select-customer style="grid-row-start: 1;" [ngStyle]="{'grid-column-start': searchByCustomer ? 1 : 2}"
    [searchByCustomer]="searchByCustomer" [boat]="form.value.boat" formControlName="customer">
  </app-movement-select-customer>
  <app-movement-select-boat [searchByCustomer]="searchByCustomer" [customer]="form.value.customer"
    formControlName="boat">
  </app-movement-select-boat>

  <div class="form-control">
    <label>{{ 'FEDERAL-ID.GENERAL' | translate }}</label>
    <input type="text" pInputText [value]="getFederalId()" disabled>
  </div>

  <app-movement-select-sailor class="marginTop15" *ngIf="enabledSailor()" [boat]="form.getRawValue()?.boat"
    formControlName="sailor">
  </app-movement-select-sailor>

  <app-movement-select-origin-movement-location class="marginTop15" [boat]="form.value?.boat"
    *ngIf="form.value.boat?.boatProfile === 'EVENTUAL' || form.value.boat?.boatProfile ==='WORKSHOP'" formControlName="movementLocationOrigin">
  </app-movement-select-origin-movement-location>
  <app-movement-select-origin-vacancy class="marginTop15" [form]="form.value" [boat]="form.value?.boat" [movementLocation]="form.value?.movementLocationOrigin"
    *ngIf="form.value.boat?.boatProfile === 'EVENTUAL' || form.value.boat?.boatProfile ==='WORKSHOP'" formControlName="vacancyOrigin">
  </app-movement-select-origin-vacancy>
</form>
