import { Component, OnInit } from '@angular/core';
import { InvoicePaid } from 'src/app/models/invoice-paid';
import { BilletService } from 'src/app/services/billet.service';
import { FinancesService } from 'src/app/services/finances.service';
import { InvoicePaidService } from 'src/app/services/invoice-paid.service';
import { MarinaOrderService } from 'src/app/services/marina-order.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { ToastService } from 'src/app/services/toast.service';
import { MessageUtil } from 'src/app/utils/message.util';
import Swal from 'sweetalert2';

import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { InvoiceService } from 'src/app/services/invoice.service';
import { StorageUtil } from 'src/app/utils/storage.util';


@Component({
  selector: 'app-invoice-discount',
  templateUrl: './invoice-discount.component.html',
  styleUrls: ['./invoice-discount.component.scss']
})
export class InvoiceDiscountComponent implements OnInit {
  invoice: any;
  total: number;
  whitheldIssTax: number;

  private ordersPrint: any[] = new Array();

  discount = '00';
  slingConfig: any;
  interestSuggested: number;
  dueDateDiscount: number;


  constructor(
    private invoicePaidService: InvoicePaidService,
    public dialogRef: DynamicDialogRef,
    private messageUtil: MessageUtil,
    private marinaOrderService: MarinaOrderService,
    private toasty: ToastService,
    private slingConfigService: SlingConfigService,
    private config: DynamicDialogConfig,
    private spinner: NgxSpinnerService
  ) {
    this.interestSuggested = 0;
    this.dueDateDiscount = 0;
  }

  async ngOnInit(): Promise<void> {
     this.invoice = this.config.data.invoice;
     this.invoice.discount = 0;
    await this.loadSlingConfig();

  }


  async loadSlingConfig(): Promise<void> {
    return await new Promise<void>(
      async (resolve) => {
        await this.slingConfigService.getSlingConfig().subscribe(
          async (data) => {
            this.slingConfig = data[0];

            resolve();
          }
        );
      }
    );
  }


  getTotalValue(): number {
    let issTax = 0;
    if(this.whitheldIssTax && this.invoice.totalIssFromPaid == 0){
      issTax = this.whitheldIssTax;
    }
    return this.invoice.pendingValue + this.invoice.interest - this.invoice.discount - issTax;
  }

  createObject(): void {
    this.invoice.discount = 0;
    this.invoice.interest = 0;
    this.invoice.paymentDate = undefined;
    this.invoice.processingDate = new Date();
    this.invoice.account = undefined;
    this.invoice.payment = undefined;

    if (this.invoice.paidValue === undefined) {
      this.invoice.paidValue = 0;
    }
  }

  validations(): boolean {
    if (Number(this.invoice.discount.toFixed(2)) < 0 ||
      Number(this.invoice.interest.toFixed(2)) < 0) {
      this.messageUtil.generateMessage(MessageUtil.WARNING, 'Alerta', 'Valor não podem ser negativos');
      return false;
    }

    if (Number(this.invoice.discount.toFixed(2)) >
      Number((this.invoice.pendingValue + this.invoice.interest).toFixed(2))) {
      this.messageUtil.generateMessage(MessageUtil.WARNING, 'Alerta', 'Valor maior que o total a ser pago');
      return false;
    }
    if ( Number(this.invoice.paidValue.toFixed(2)) != 0 ) {
      this.messageUtil.generateMessage(MessageUtil.WARNING, 'Alerta', 'Fatura com dados inconsistentes');
      return false;
    }

    if (!this.slingConfig.paymentMethodId) {
      this.messageUtil.generateMessage(MessageUtil.WARNING, 'Alerta', 'Forma de pagamento não escolhida no cadastro de configurações');
      return false;
    }
    if (!this.slingConfig.accountId) {
      this.messageUtil.generateMessage(MessageUtil.WARNING, 'Alerta', 'Conta não escolhida no cadastro de configurações');
      return false;
    }

    return true;
  }

  checkAndInvoicePay(): void {
    if (this.validations()) {
      let employeeUser = StorageUtil.getUser();
      const invoicePaid: InvoicePaid = new InvoicePaid();
      invoicePaid.datepaid = this.invoice.paymentDate;
      invoicePaid.dateProcessing = this.invoice.processingDate;
      invoicePaid.discount = this.invoice.discount;
      invoicePaid.interest = this.invoice.interest;
      invoicePaid.totalPaid = 0;
      invoicePaid.value = this.invoice.value;
      invoicePaid.username = this.invoice.customer.name;
      invoicePaid.employeeUser = employeeUser.firstName+" "+employeeUser.lastName;
      invoicePaid.invoiceId = this.invoice.id;
      invoicePaid.accountId = this.slingConfig.accountId;
      invoicePaid.accountName =this.slingConfig.accountName;
      invoicePaid.paymentMethodId = this.slingConfig.paymentMethodId;
      invoicePaid.paymentMethodName = this.slingConfig.paymentMethodName;
      let confirmText='Este desconto não é aplicado no boleto, o mesmo deve ser feito no sistema e no banco. ';
      if((this.invoice.discount) > this.getTotalValue()){
        confirmText = confirmText + ' Valor de DESCONTO é maior que o TOTAL da fatura!'
      }
      Swal.fire({
        title: 'Confirmar operação?',
        showCancelButton: true,
        text: confirmText,
        confirmButtonText: 'Confirmar',
        showLoaderOnConfirm: true,
        reverseButtons: true,
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
        preConfirm: () => {
          this.invoicePay(invoicePaid);
        }
      });
    }
  }

  invoicePay(invoicePaid): void {
    this.spinner.show();
    this.invoicePaidService.payInvoice(invoicePaid).subscribe(() => {
      this.toasty.success('Desconto lançado sucesso.');
      this.getMarinaOrdersOfInvoice();
      this.spinner.hide();
      this.dialogRef.close();
    }, (error) => {
      const exception = error.error.data.exception;
      this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      this.spinner.hide();
    });
  }


  getMarinaOrdersOfInvoice(): void {
    this.marinaOrderService.getByIdInvoice(this.invoice.id).subscribe(
      (data) => {
        this.ordersPrint = data;
        this.ordersPrint.forEach((order) => {
          if (order.customers.length > 1) {
            order.customerQuota = order.customers.find((customer) =>
              customer.federalId === this.invoice.customer.federalId).quota;
          }
        });
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }


  changeDiscountToDiscountSuggested() {
    this.cleanPaidValue();
    this.invoice.discount = this.dueDateDiscount;
  }

  hasBankingBillet(): boolean {
    return this.getActiveBillets(this.invoice).length > 0;
  }

  getActiveBillets(invoice): any[] {
    if (invoice.invoiceBankingBillets) {
      return invoice.invoiceBankingBillets.filter((b) => b.active && !b.paid);
    } else if (invoice.bankingBilletList) {
      return invoice.bankingBilletList.filter((b) => b.active);
    } else {
      return [];
    }
  }

  getIdBillet(): number {
    return this.getActiveBillets(this.invoice)[0].id;
  }

  validValue(invoice): boolean {


    return invoice.discount >= 0 && this.getTotalValue() >0;
  }

  cleanPaidValue(){
    this.invoice.paidValue = 0;
  }
}
