<div class="dialog-form">
    <div class="form-controls">
        <div class="form-control">
            <span>{{ 'SEARCH-BY-CLIENT' | translate }}:</span>
            <p-inputSwitch class="mt4" [(ngModel)]="searchByCustomer" (onChange)="validateSearch()"/>
        </div> 
    </div>
    <div class="form-controls">

        <!--Busca por embarcação -->
        <div class="form-control" *ngIf="!searchByCustomer">
            <label>{{ 'BOAT' | translate }}:</label>
            <p-dropdown [readonly]="isBoatSingleOrder" placeholder="Não Selecionado..." required [filter]="true" appendTo="body"
                [options]="boatOptions" [(ngModel)]="selectedBoat" name="selectedBoat"
                (onChange)="loadCustomers(selectedBoat)">
                <ng-template let-boat pTemplate="selectedItem">
                    <span>{{boat.label}}</span>
                </ng-template>
                <ng-template let-boat pTemplate="item">
                    <div>{{boat.label}}</div>
                </ng-template>
            </p-dropdown>
        </div>

        <div class="form-control" *ngIf="!searchByCustomer">
            <label>{{ 'CUSTOMER' | translate }}:</label>
            <p-dropdown [readonly]="isBoatSingleOrder" placeholder="Não Selecionado..." required [filter]="!isToten" appendTo="body"
                [options]="customers" [(ngModel)]="selectedCustomerId" name="selectedCustomerId"
                (onChange)="selectCustomer(selectedCustomerId)">
                <ng-template let-customer pTemplate="item">
                    <div>{{customer.label}}</div>
                </ng-template>
            </p-dropdown>
        </div>

        <!--Busca por Cliente-->

        <div class="form-control" *ngIf="searchByCustomer">
          <label>{{ 'CUSTOMER' | translate }}:</label>
          <p-dropdown [readonly]="isBoatSingleOrder" placeholder="Selecione..." required [filter]="!isToten" appendTo="body"
              [options]="customers" [(ngModel)]="selectedCustomerId" name="selectedCustomerId"
              (onChange)="selectCustomer(selectedCustomerId)">
              <ng-template let-customer pTemplate="item">
                  <div [ngClass]="{'customer-incomplete': customer.complete==false}"
                      >{{customer.label}}</div>
              </ng-template>
          </p-dropdown>
      </div>

        <div class="form-control" *ngIf="searchByCustomer && selectedCustomer?.dueDate != null">
            <label>{{ 'BOAT' | translate }}:</label>
            <p-dropdown [readonly]="isBoatSingleOrder" placeholder="Selecione..." required [filter]="true" appendTo="body"
                [options]="boatOptions" [(ngModel)]="selectedBoat" name="selectedBoat"
                (onChange)="selectBoat(selectedBoat)">
                <ng-template let-boat pTemplate="selectedItem">
                    <span>{{boat.label}}</span>
                </ng-template>
                <ng-template let-boat pTemplate="item">
                    <div>{{boat.label}}</div>
                </ng-template>
            </p-dropdown>
        </div>
    </div>
</div>