import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: '[appHideMobile]'
})
export class HideMobileDirective implements OnInit {

  innerWidth: number;
  originalDisplay: string;
  @HostListener('window:resize', ['$event']) onScroll($event: Event): void {
    this.innerWidth = window.innerWidth;
    this.showOrHide();
  }

  constructor(
    private elRef: ElementRef
  ) {
  }

  ngOnInit(): void {
    this.originalDisplay = this.elRef.nativeElement.style.display;
    this.innerWidth = window.innerWidth;
    this.showOrHide();
  }

  showOrHide(): void {
    if (this.innerWidth <= 770) {
      this.elRef.nativeElement.style.display = 'none';
    } else {
      this.elRef.nativeElement.style.display = this.originalDisplay;
    }
  }
}
