<div class="dialog-form">
    <form (submit)="save()" [formGroup]="groupForm">
        <p-fieldset *ngIf="slingConfig && hasFinancialSettings(slingConfig)">
            <p-header class="article-title">
                <span class="article-title">{{'Financeiro' | translate}}</span>
            </p-header>

            <div class="form-group">
                <div class="form-control" *ngIf="multiCompany()">
                    <label for="marinaCompany">Empresa *</label>
                    <p-dropdown placeholder="Selecione..." [filter]="true" [options]="marinaCompanyList"
                        formControlName="marinaCompany" name="marinaCompany" (onChange)="selectedCompany($event.value)"
                        appendTo="body">
                    </p-dropdown>
                </div>
                <div class="form-control">
                    <label for="paymentMethodId">Forma de pagamento padrão *</label>
                    <p-dropdown placeholder="Selecione..." [filter]="true" [options]="paymentMethodsDropdown"
                        formControlName="paymentMethodId" name="paymentMethodId"
                        (onChange)="selectedPaymentMethods($event.value)" appendTo="body">
                    </p-dropdown>
                </div>
                <div class="form-control" *ngIf="!multiCompany() || groupForm.get('paymentMethodId').value">
                    <label for="accountId">Conta padrão *</label>
                    <p-dropdown placeholder="Selecione..." [filter]="true" [options]="accountDropdown"
                        formControlName="accountId" name="accountId" appendTo="body">
                    </p-dropdown>
                </div>
            </div>
        </p-fieldset>
        <br>
        <p-fieldset class="basic">
            <p-header class="article-title">
                <span class="article-title">{{'Básico' | translate}}</span>
            </p-header>

            <div class="form-group">
                <div class="form-control">
                    <label for="name">{{'GROUP-NAME' | translate}} *</label>
                    <input pInputText name="name" formControlName="name" required maxlength="30">
                </div>
                <div class="form-control flex-end">
                    <label for="active">{{ 'ACTIVE' | translate }}</label>
                    <p-inputSwitch styleClass="myswitch" formControlName="active" name="active">
                    </p-inputSwitch>
                </div>
            </div>
            <div class="form-group">
                <div class="form-control">
                    <label for="description">{{ 'DESCRIPTION' | translate }}</label>
                    <textarea pInputText maxlength="300" name="description" rows="5" cols="90"
                        formControlName="description"></textarea>
                    <span class="hint">{{group?.description?.length}} / 300</span>
                </div>
            </div>
        </p-fieldset>
        <div class="form-group align-center">
            <div class="form-control">
                {{'REQUIRED-FIELDS' | translate}}
            </div>
            <div class="buttons">
                <button class="easymarine danger" type="button"
                    (click)="dialog.close()">{{ 'CANCEL' | translate }}</button>
                <button class="easymarine primary" type="submit">{{ 'SAVE' | translate }}</button>
            </div>
        </div>
    </form>
</div>
