import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MovementGroup } from "src/app/models/movement/movement-group";
import { environment } from "src/environments/environment";

@Injectable()
export class MovementGroupService {
  private readonly API_URL = environment.apiHost + '/secure/movement/movement-group';

    constructor(private http: HttpClient) { }

    getAll(): Observable<MovementGroup[]> {
        return this.http.get(this.API_URL)
            .pipe(map((response: any) => response.data.movementGroups as MovementGroup[]));
    }

    getById(movementGroupId: number): Observable<MovementGroup> {
        return this.http.get(this.API_URL + '/' + movementGroupId)
            .pipe(map((response: any) => response.data.movementGroup as MovementGroup));
    }

    create(movementGroup: MovementGroup): Observable<MovementGroup> {
        return this.http.post(this.API_URL, movementGroup)
            .pipe(map((res: any) => res.data.movementGroup as MovementGroup));
    }

    update(movementGroup: MovementGroup): Observable<MovementGroup> {
        return this.http.put(this.API_URL, movementGroup)
            .pipe(map((res: any) => res.data.movementGroup as MovementGroup));
    }

    delete(movementGroupId: number): Observable<void> {
        return this.http.delete(this.API_URL + '/' + movementGroupId)
            .pipe(map((res: any) => res.data));
    }
}
