import { User } from "./user";

export class InvoicePaid {
    id: number;
    dateDown: any;
    dateProcessing: any;
    datePaid: any;
    datepaid: any;
    discount: number;
    interest: number;
    totalPaid: number;
    username: string;
    value: number;
    invoiceId: number;
    accountId: number;
    accountName: string;
    paymentMethodId: number;
    paymentMethodName: string;
    identifier: string;
    identifierVD: string;
    billetId: number;
    canceled: boolean;
    withheldIssCityTax: number;
    employeeUser: string;
    dropBillet: boolean;
    newDueDate:any

    billetToCanceled:any
    constructor() {
        this.discount = 0;
        this.interest = 0;
        this.value = 0;
        this.withheldIssCityTax = 0;
    }
}
