import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FranchiseServiceService } from 'src/app/services/franchise/franchise-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-franchise-editqtymodal',
  templateUrl: './franchise-editqtymodal.component.html',
  styleUrls: ['./franchise-editqtymodal.component.scss']
})
export class FranchiseEditqtymodalComponent implements OnInit {

  ammountOrdered: number;
  surplusAmount: number;
  refenceDate: string;

    constructor(
    private dialog: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private franchiseService: FranchiseServiceService
    ) { }

  ngOnInit() {
    this.ammountOrdered = this.config.data.franchise.ammountOrdered;
    this.surplusAmount = this.config.data.franchise.surplusAmount;
    this.refenceDate = this.config.data.refenceDate;
  }

  save () {
    if (this.ammountOrdered > this.surplusAmount || this.ammountOrdered < 0) {
      Swal.fire('Atenção!', 'A quantidade solicitada não pode ser maior que a quantidade disponível ou menor que 0.', 'warning');
    }
    else {
      this.franchiseService.cancelFranchiseToAmmount(this.config.data.franchise.boatId, this.refenceDate, this.surplusAmount - this.ammountOrdered).subscribe({
        next: (data) => {
              this.dialog.close(true);
        }, error: (error) => {
          Swal.fire({
            icon: 'error',
            title: 'Erro ao ajustar valor cobrado!',
            text: error.error.message
          });
        }
      });
    }

  }


  cancel () {
    this.dialog.close(false);
  }

}
