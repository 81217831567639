export class Licence {
    id: number;
    register: string;
    country: string;
    state: string;
    city: string;
    file: string;
    expiration: any;
    typeLicense: string;

    constructor() {

    }
}
