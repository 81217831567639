import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, map } from 'rxjs';
import moment from 'moment';
import { HttpParams } from '@angular/common/http';
import { EasyMarineHttpApiLog } from './easy-marine-http-api-log';


@Injectable()
export class InvoiceLogService {

  constructor(private httpClient: EasyMarineHttpApiLog) {
  }

  findLogs(filter: any): Observable<any> {
    let params = new HttpParams();
    if (filter.dateStart) {
        params = params.append('dateStart', moment(filter.dateStart).format());
    }
    if (filter.dateEnd) {
        params = params.append('dateEnd', moment(filter.dateEnd).format());
    }
    if (filter.userId) {
        params = params.append('userId', filter.userId);
    }
    if (filter.customerId) {
        params = params.append('customerId', filter.customerId);
    }

    return this.httpClient.get(environment.apiLog + `/invoice-log/${filter.marinaId}`, { params })
       .pipe(map((response: any) => response));
    }
}
