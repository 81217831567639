<div class="dialog-form">
  <form [formGroup]="customerForm">
        <p-fieldset>
            <p-header class="article-title">
                <div class="article-title">Informações</div>
            </p-header>
            <div class="form-group">
                <div class="form-control" *ngIf="customer.id">
                    <input pInputText placeholder="Id" name="id" formControlName="id" readonly>
                </div>
                <div class="form-control radio">
                    <p-radioButton name="federalIdType" formControlName="federalIdType" value="Physical"
                        label="{{'FEDERAL-ID.PHYSICAL' | translate}}" inputId="federalIdPhysical"
                        [(ngModel)]="federalIdType" (onClick)="validateFederalIdType()">
                    </p-radioButton>
                    <p-radioButton name="federalIdType" formControlName="federalIdType" value="Juridical"
                        label="{{'FEDERAL-ID.JURIDICAL' | translate}}" inputId="federalIdJuridical"
                        [(ngModel)]="federalIdType" (onClick)="validateFederalIdType()">
                    </p-radioButton>
                </div>
                <div class="form-control field">
                    <label for="phone1">{{ 'CELLPHONE' | translate }} 1 *</label>
                    <p-inputMask type="text" mask="+99 (99) 99999-9999" name="phone1" formControlName="phone"
                        [pTooltip]="getErrorTooltip(customerForm.get('phone'))" tooltipPosition="bottom">
                    </p-inputMask>
                    <small *ngIf="customerForm?.controls?.phone.touched && customerForm?.controls?.phone?.errors?.required" class="p-error block">Celular Obrigatório</small>
                </div>

            </div>
            <div class="form-group">

                <div class="form-control field" *ngIf="isFederalTypePhysical">
                    <label for="name">{{'FEDERAL-ID.PHYSICAL' | translate}} *</label>
                    <p-inputMask type="text" mask="999.999.999-99" name="federalId" formControlName="federalId"
                        (onBlur)="checkFederalIdField()"
                        [pTooltip]="getErrorTooltip(customerForm.get('federalId'))" tooltipPosition="bottom">
                    </p-inputMask>
                    <small *ngIf="customerForm.controls?.federalId.touched && customerForm.controls?.federalId?.errors?.invalidFederalId" class="p-error block">CPF inválido</small>
                    <small *ngIf="customerForm.controls?.federalId.touched && customerForm.controls?.federalId?.errors?.federalIdInUse" class="p-error block">Usuário já cadastrado</small>
                    <small *ngIf="customerForm?.controls?.federalId.touched && customerForm?.controls?.federalId?.errors?.required" class="p-error block">CPF Obrigatório</small>

                </div>

                <div class="form-control field">
                <label for="name">{{nameLabel | translate}} *</label>
                <input pInputText name="name" formControlName="name" maxlength="50"
                    [pTooltip]="getErrorTooltip(customerForm.get('name'))" tooltipPosition="bottom">
                <small *ngIf="customerForm?.controls?.name.touched && customerForm?.controls?.name?.errors?.required" class="p-error block">Nome Obrigatório</small>
                </div>

                <div class="form-control radio field" *ngIf="isFederalTypePhysical; else isFederalIdJuridical">
                    <p-radioButton name="gender" formControlName="gender" [value]="'M'"
                        label="{{'MALE' | translate }}" [(ngModel)]="gender">
                    </p-radioButton>
                    <p-radioButton name="gender" formControlName="gender" [value]="'F'"
                        label="{{'FEMALE' | translate }}" [(ngModel)]="gender">
                    </p-radioButton>
                </div>

                <ng-template #isFederalIdJuridical>
                    <div class="form-control">
                        <label for="federalId">{{ 'FEDERAL-ID.JURIDICAL' | translate }}</label>
                        <p-inputMask type="text" mask="99.999.999/9999-99" name="federalId" #federalId
                            formControlName="federalId" (onBlur)="checkFederalIdField()"
                            [pTooltip]="getErrorTooltip(customerForm.get('federalId'))"
                            tooltipPosition="bottom">
                        </p-inputMask>

                    </div>
                </ng-template>
            </div>
            <div class="form-group">

                <div class="form-control field">
                    <label for="email">E-mail *</label>
                    <input pInputText name="email" formControlName="email" type="email" maxlength="50"
                        id="email" [pTooltip]="getErrorTooltip(customerForm.get('email'))"
                        tooltipPosition="bottom">
                    <small *ngIf="customerForm?.controls?.email.touched && customerForm?.controls?.email?.errors?.required" class="p-error block">E-mail Obrigatório</small>
                </div>
                <div class="form-control field" formGroupName="group">
                    <label for="group">{{ 'Grupo' | translate }}</label>
                    <p-dropdown [options]="groups" name="group" formControlName="id" appendTo="body">
                    </p-dropdown>
                </div>

            </div>

        </p-fieldset>
        <br>
        <p-fieldset formGroupName="residentialAddress">
            <p-header class="article-title">
                <div class="article-title">{{ 'ADDRESS' | translate }}</div>
            </p-header>
            <div class="form-group">
                <div class="form-control field">
                    <label for="residentialAddressZip">{{ 'ZIP' | translate }} *</label>
                    <p-inputMask type="text" mask="99999-999" name="residentialAddressZip" formControlName="zip"
                        (onBlur)="getCep('residential')"
                        [pTooltip]="getErrorTooltip(customerForm.get('residentialAddress.zip'))"
                        tooltipPosition="bottom">
                    </p-inputMask>
                    <small *ngIf="customerForm?.get('residentialAddress')?.controls?.zip.touched && customerForm?.get('residentialAddress').controls?.zip?.errors?.required" class="p-error block">CEP Obrigatório</small>
                </div>
                <div class="form-control field">
                    <label for="country">{{ 'COUNTRY' | translate }} *</label>
                    <p-dropdown [options]="countries" name="country" appendTo="body"
                        (onChange)="loadStates(customer.residentialAddress.country)" formControlName="country"
                        [pTooltip]="getErrorTooltip(customerForm.get('residentialAddress.country'))"
                        tooltipPosition="bottom">
                    </p-dropdown>
                    <small *ngIf="customerForm?.get('residentialAddress')?.controls?.country.touched && customerForm?.get('residentialAddress').controls?.country?.errors?.required" class="p-error block">País Obrigatório</small>
                </div>
                <div class="form-control field">
                    <label for="state">{{ 'FS' | translate }} *</label>
                    <p-dropdown [options]="states" name="state" appendTo="body"
                        (onChange)="loadCities(customer.residentialAddress.country, customer.residentialAddress.state)"
                        formControlName="state"
                        [pTooltip]="getErrorTooltip(customerForm.get('residentialAddress.state'))"
                        tooltipPosition="bottom">
                    </p-dropdown>
                    <small *ngIf="customerForm?.get('residentialAddress')?.controls?.state.touched && customerForm?.get('residentialAddress').controls?.state?.errors?.required" class="p-error block">Estado Obrigatório</small>
                </div>
            </div>
            <div class="form-group">
                <div class="form-control field">
                    <label for="city">{{ 'CITY' | translate }} *</label>
                    <p-autoComplete formControlName="city" [suggestions]="filteredCities" autoHighlight="true"
                        name="city" (completeMethod)="filterCities($event)" appendTo="body"
                        [pTooltip]="getErrorTooltip(customerForm.get('residentialAddress.city'))"
                        tooltipPosition="bottom">
                    </p-autoComplete>
                    <small *ngIf="customerForm?.get('residentialAddress')?.controls?.city.touched && customerForm?.get('residentialAddress').controls?.city?.errors?.required" class="p-error block">Cidade Obrigatória</small>
                </div>
                <div class="form-control field">
                    <label for="residentialAddressStreet">{{ 'STREET' | translate }} *</label>
                    <input pInputText name="residentialAddressStreet" formControlName="street" maxlength="50"
                        [pTooltip]="getErrorTooltip(customerForm.get('residentialAddress.street'))"
                        tooltipPosition="bottom">
                    <small *ngIf="customerForm?.get('residentialAddress')?.controls?.street.touched && customerForm?.get('residentialAddress').controls?.street?.errors?.required" class="p-error block">Rua Obrigatória</small>
                </div>
            </div>
            <div class="form-group field">
                <div class="form-control">
                    <label for="residentialAddressDistrict">{{ 'DISTRICT' | translate }} *</label>
                    <input pInputText name="residentialAddressDistrict" formControlName="district"
                        maxlength="50">
                    <small *ngIf="customerForm?.get('residentialAddress')?.controls?.district.touched && customerForm?.get('residentialAddress').controls?.district?.errors?.required" class="p-error block">Bairro Obrigatório</small>
                </div>
                <div class="form-control field">
                    <label for="residentialAddressNumber">{{ 'NUMBER' | translate }} *</label>
                    <input pInputText name="residentialAddressNumber" formControlName="number" maxlength="10"
                        [pTooltip]="getErrorTooltip(customerForm.get('residentialAddress.number'))"
                        tooltipPosition="bottom">
                    <small *ngIf="customerForm?.get('residentialAddress')?.controls?.number.touched && customerForm?.get('residentialAddress').controls?.number?.errors?.required" class="p-error block">Número Obrigatório</small>
                </div>
                <div class="form-control field">
                    <label for="residentialAddressComplement">{{ 'COMPLEMENT' | translate }}</label>
                    <input pInputText name="residentialAddressComplement" formControlName="complement"
                        maxlength="50">
                </div>
            </div>
        </p-fieldset>
  </form>
</div>
