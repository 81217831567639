import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SpecialOperatingDayClosedDTO } from 'src/app/models/dtos/config/special-operating-day-dto';
import { OperatingDayConfigService } from 'src/app/services/config/operating-day-config.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-special-operating-day-closed-modal',
  templateUrl: './special-operating-day-closed-modal.component.html',
  styleUrls: ['./special-operating-day-closed-modal.component.scss']
})
export class SpecialOperatingDayClosedModalComponent {

  specialOperatingDayId: number;

  form: UntypedFormGroup;

  constructor(
    private readonly config: DynamicDialogConfig,
    private readonly dialogRef: DynamicDialogRef,
    private readonly operatingDayConfigService: OperatingDayConfigService,
    private readonly toasty: ToastService
  ) {
    this.specialOperatingDayId = this.config.data.specialOperatingDayId
    if (this.specialOperatingDayId) {
      this.operatingDayConfigService.getSpecialOperatingDayClosedById(this.specialOperatingDayId).subscribe(
        (data) => this.createFormToEdit(data),
        (err) => {}
      )
    } else {
      this.createFormToNew();
    }
  }

  createFormToNew() {
    this.form = new UntypedFormGroup({
        date: new UntypedFormControl(null, [
          Validators.required
        ]),
        observation: new UntypedFormControl('', [
          Validators.required
        ])
      });
  }

  createFormToEdit(day : SpecialOperatingDayClosedDTO) {
    this.form = new UntypedFormGroup({
      id: new UntypedFormControl(day.id),
      date: new UntypedFormControl(day.date, [
        Validators.required
      ]),
      observation: new UntypedFormControl(day.observation, [
        Validators.required
      ])
    });
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    if ( this.form.valid) {
      this.operatingDayConfigService.saveSpecialOperatingDayClosed(this.form.value).subscribe(
        (data) => {
          this.toasty.success('Salvo com sucesso!');
          this.dialogRef.close(data);
        },
        (err) => {
          this.toasty.error('Ops, não foi possível salvar.');
        }
      )
    }
  }
}
