import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-contract-log-signer',
  templateUrl: './contract-log-signer.component.html',
  styleUrls: ['./contract-log-signer.component.scss']
})
export class ContractLogSignerComponent implements OnInit {

  @Input() signers;
  @Input() signersOld;
  @Input() isNew;

  constructor() { }

  ngOnInit(): void { }

  logsTable(): any[]{
    if(this.isNew){
      return this.signers;
    } else {
      return this.signersOld;
    }
  }

  isChanged(signer): boolean {
    let signerVerify;
    if(this.isNew){
      signerVerify = this.findSignerOld(signer.signerId);
    } else {
      signerVerify = this.findSigner(signer.signerId);
    }

    return !signerVerify;
  }

  findSigner(id): any{
    return this.signers.find(s => s.signerId === id);
  }

  findSignerOld(id): any{
    return this.signersOld.find(s => s.signerId === id); 
  }
}
