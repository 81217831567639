import { Component, Input, Output, EventEmitter } from "@angular/core";
import { MenuItem } from 'primeng/api';

@Component({
    selector: "map-context-menu",
    templateUrl: "./map-context-menu.component.html",
    styleUrls: ["./map-context-menu.component.scss"],
  })
  export class MapContextMenuComponent {
  
    @Output()
    onContextMenuItemClick: EventEmitter<any> = new EventEmitter<any>();
  
    onContextMenuClick(data): any {
      this.onContextMenuItemClick.emit({
        event,data        
      });
    }

    items: MenuItem[];
    ngOnInit() {      

        this.items = [{
            label: 'Edição',
            items: [{
                label: 'Criar Nova Vaga',
                icon: 'pi pi-plus',
                command: () => {
                    this.onContextMenuClick({'action': 'new'});
                }
            },
            {
              label: 'Adicionar Existente',
              icon: 'pi pi-plus',
              command: () => {
                  this.onContextMenuClick({'action':'import'});
              }
          }
            ]}
        ];
    }


  }
  