import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { FranchiseFilter } from 'src/app/models/dtos/franchise/franchise-filter';
import { FranchiseServiceService } from 'src/app/services/franchise/franchise-service.service';
import dayjs from 'dayjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { NavigationPlanService } from 'src/app/services/navigation-plan.service';
import Swal from 'sweetalert2';
import { MessageUtil } from 'src/app/utils/message.util';
import { MovementPlanService } from 'src/app/services/movements/movement-plan.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ExtractInvoiceComponent } from 'src/app/components/extract-invoice/extract-invoice.component';
import b64toBlob from 'b64-to-blob';
@Component({
  selector: 'app-franchise-history',
  templateUrl: './franchise-history.component.html',
  styleUrls: ['./franchise-history.component.scss'],
})
export class FranchiseHistoryComponent implements OnInit {
  history: any[] = [];
  franchise = this.config.data.franchise;
  reference = this.config.data.reference;

  constructor(
    private franchiseService: FranchiseServiceService,
    private config: DynamicDialogConfig,
    private spinner: NgxSpinnerService,
    private navigationPlanService: NavigationPlanService,
    private messageUtil: MessageUtil,
    private movementPlanService: MovementPlanService,
    private sanitizer: DomSanitizer,
    private dialog: DialogService,
  ) {}

  ngOnInit(): void {
    this.searchHistory();
  }

  searchHistory() {
    let filter: FranchiseFilter = new FranchiseFilter();

    filter.boatId = this.franchise.boatId;
    filter.createDateStart = dayjs(this.reference).toDate();
    filter.createDateEnd = dayjs(this.reference)
      .add(1, 'month')
      .subtract(1, 'day')
      .toDate();

    this.franchiseService.findHistory(filter).subscribe((response) => {
      this.history = response;
    });
  }

  printNavitagionPlan(row): void {
    if (row.slingId) {
      this.spinner.show();
      this.navigationPlanService
        .generatedPdfByIdsSling([row.slingId])
        .subscribe((data) => {
          this.spinner.hide();
          const url = window.URL.createObjectURL(data);
          window.open(url);
        });
    }else{
      this.openPrintMovementPlanDialog(row.movementPlanId);
    }
  }

  formatDate(date) {
    return dayjs(date).format('DD/MM/YYYY HH:mm:ss');
  }

  openPrintMovementPlanDialog(id: number) {
    const types = 'question';
    const titleMessage = this.messageUtil.translateKey(
      'PRINT-NAVIGATION-PLAN-CONFIRMATION'
    );
    Swal.fire({
      title: titleMessage,
      icon: types,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        this.generateMovementPlanPDF(id);
      }
    });
  }

  async generateMovementPlanPDF(data): Promise<any> {
    return new Promise<any>(async (resolve) => {
      this.spinner.show();
      await this.movementPlanService
        .getMovementPlanPDF(data)
        .subscribe(async (data) => {
          resolve(
            this.openExtract(
              this.sanitizer.bypassSecurityTrustResourceUrl(
                URL.createObjectURL((b64toBlob as any)(data, 'application/pdf'))
              )
            )
          );
          this.spinner.hide();
        });
    });
  }

  openExtract(path): void {
    this.dialog.open(ExtractInvoiceComponent, {
      width: '100%',
      height: '100%',
      data: { path },
      header: 'Plano de Movimentação - PDF',
    });
  }
}
