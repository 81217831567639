import { Component, OnInit } from '@angular/core';
import { PivotTableService} from 'src/app/services/pivot-table.services';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageUtil } from 'src/app/utils/message.util';
import moment from 'moment';
import { StorageUtil } from 'src/app/utils/storage.util';
import { saveAs } from 'file-saver';


declare var $: any;

@Component({
    selector: 'app-dashboard',
    templateUrl: './pivot-product-sales.component.html',
    styleUrls: ['./pivot-product-sales.component.scss']
  })
  export class PivotProductSalesComponent implements OnInit {

    sales: any[];
    pivotTableOrigin: String;

    constructor(
      private pivotTableService: PivotTableService,
      private spinner: NgxSpinnerService,
      private messageUtil: MessageUtil
    ){}

    async ngOnInit(): Promise<void> {

    }


    async loadPivotTable(): Promise<void> {

        await this.getProductsale();

        let aggFornecido =  {
          aggType: 'Sum',
          arguments: ['valor_fornecido'],
          name: 'Valor Fornecido',
          varName :'a',
          hidden : false,
          renderEnhancement : 'none'
        };

        let aggPago =  {
          aggType: 'Sum',
          arguments: ['valor_pago'],
          name: 'Valor Pago',
          varName :'b',
          hidden : false,
          renderEnhancement : 'none'
        };


        var customAggFornecidoPago = {
          'agg1': aggFornecido,
          'agg2': aggPago
        };

        var customAggPago = {
          'agg1': aggPago
        };

        var customAggFornecido = {
          'agg1': aggFornecido,
        };


        var customAggs = {};
        customAggs['R$ Fornecido/Pago'] = $.pivotUtilities.multifactAggregatorGenerator(customAggFornecidoPago,[]);
        customAggs['R$ Fornecido'] = $.pivotUtilities.multifactAggregatorGenerator(customAggFornecido,[]);
        customAggs['R$ Pago'] = $.pivotUtilities.multifactAggregatorGenerator(customAggPago,[]);


        var config:any = {
          "cols": ["ano","mes" ],
          "rows": ["produto"],
          "vals": [],
          "rowOrder": "key_a_to_z",
          "colOrder": "key_a_to_z",
          "aggregatorName": "R$ Fornecido/Pago",
          "rendererName": "GT Table",
          "rendererOptions" : {
              aggregations : {
                  defaultAggregations : customAggFornecidoPago,

              }
          },
          "hiddenAttributes": ["valor_fornecido","valor_pago"]
      };





      $.pivotUtilities.customAggs = customAggs;

      //config.aggregators = $.extend($.pivotUtilities.aggregators, $.pivotUtilities.customAggs);
      config.aggregators =  $.pivotUtilities.customAggs;

      config.renderers =  $.pivotUtilities.gtRenderers;
      delete config.renderers['GT Table Col Heatmap'];
      delete config.renderers['GT Table Heatmap'];
      delete config.renderers['GT Table Heatmap and Barchart'];
      delete config.renderers['GT Table Row Heatmap'];

      $("#output").pivotUI(
          this.sales,
          config
      );



    }


    async getProductsale(): Promise<void> {
      return new Promise<void>(async resolve => {
        this.spinner.show();
        this.pivotTableService.getPivotProductSales().subscribe(
          async (salesResult: any[]) => {
            this.sales = salesResult;
            this.spinner.hide();
            resolve();
          },
          async (error) => {
            this.spinner.hide();
            const exception = error.error.data.exception;
            this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
            resolve();
          });
      });
    }


    async exportExcel(): Promise<void> {
      let domTable = document.querySelectorAll('.pvtTable');

      import('xlsx').then(xlsx => {
          const worksheet = xlsx.utils.table_to_sheet(domTable[0]);

          const workbook = { Sheets: { data: worksheet }, SheetNames: ['data']};
          const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          const date = moment(new Date()).utc(true).format('DD-MM-YYYY');
          const marina = StorageUtil.getMarinaName();
          this.saveAsExcelFile(excelBuffer, `venda_produtos_${date}_${marina}`);
      });
    }


    saveAsExcelFile(buffer: any, fileName: string): void {
          const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          const EXCEL_EXTENSION = '.xlsx';
          const data: Blob = new Blob([buffer], {
              type: EXCEL_TYPE
          });
          saveAs(data, fileName + EXCEL_EXTENSION);
  }



  }
