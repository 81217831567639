import { Component, Input } from '@angular/core';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { InvoiceLogModalComponent } from '../invoice-log-modal/invoice-log-modal.component';
import { DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-invoice-log-table',
  templateUrl: './invoice-log-table.component.html',
  styleUrls: ['./invoice-log-table.component.scss']
})
export class InvoiceLogTableComponent {
  @Input() invoiceLogs = [];
  @Input() isDialog = false;
  numberOfRows = 10;

  constructor(
    private slingConfigService: SlingConfigService,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    if ( !this.isDialog) {
      this.loadSlingConfig();
    }
  }

  loadSlingConfig(): void {
    this.slingConfigService.getSlingConfig().subscribe(
      (data) => {
        const slingConfig = data[0];
        if (slingConfig != null) {
          this.numberOfRows = slingConfig.numberOfRecordsPerPage;
        } else {
          this.numberOfRows = 10;
        }
      },
      (error) => null);
  }

  openModal(invoiceLog): void {
    const ref = this.dialogService.open(InvoiceLogModalComponent, {
      data: {
        invoiceLog
      },
      header: 'Detalhes da Fatura',
      width: '70%',
      contentStyle: { 'max-height': '500px', 'overflow': 'auto' }
    });
  }
}
