import { Token } from './../models/token';
import jwtDecode from "jwt-decode";

export class TokenUtils{

    constructor(){}

    public static getDecodeToken(token): Token {
        const tokenObj = jwtDecode<Token>(token);
        return tokenObj;
    }
}
