import { Component, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { CustomerCredit } from 'src/app/models/customercredit/customer-credit';
import { GetCustomerStatement } from 'src/app/models/dtos/customercredit/GetCustomerStatement';
import { CustomerCreditService } from 'src/app/services/customer-credit/customer-credit.service';
import { StorageUtil } from 'src/app/utils/storage.util';

@Component({
  selector: 'app-customer-credit-historic',
  templateUrl: './customer-credit-historic.component.html',
  styleUrls: ['./customer-credit-historic.component.scss'],
})
export class CustomerCreditHistoricComponent implements OnInit {
  customerCredit: CustomerCredit = new CustomerCredit();
  startDate: Date;
  endDate: Date;

  historic: any;
  getCustomerStatement: GetCustomerStatement;

  registerdates: any[] = [];

  constructor(
    private dialog: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private customerCreditService: CustomerCreditService
  ) {}

  ngOnInit() {
    this.customerCredit.customer = this.config.data.customer;
    this.getCustomerStatement = new GetCustomerStatement(
      this.customerCredit.customer.id,
      StorageUtil.getMarina().id
    );
    this.getHistoric();
  }

  getHistoric() {
    this.customerCreditService
      .getHistoric(this.getCustomerStatement)
      .subscribe({
        next: (response) => {
          this.historic = response;
          this.registerdates = this.getFirstRecordOfEachDay(response.credits);
          this.registerdates.forEach((registerdate) => {
            registerdate.balance = this.getDayDalance(registerdate.creditDate);
          });
        },
        error: (error: any) => {
          console.error(error);
        },
      });
  }

  getFirstRecordOfEachDay(records) {
    const recordMap = records.reduce((acc, record) => {
      const dateStr = record.creditDate.split('T')[0];
      if (!acc[dateStr]) {
        acc[dateStr] = record;
      }
      return acc;
    }, {});

    return Object.values(recordMap);
  }

  show(registerdate: any, credit: any): boolean {
    const date1: Date = new Date(registerdate.creditDate);
    const date2: Date = new Date(credit.creditDate);
    if (
      date1.getDate() !== date2.getDate() ||
      date1.getMonth() !== date2.getMonth() ||
      date1.getFullYear() !== date2.getFullYear()
    ) {
      return false;
    }

    return true;
  }

  getDayDalance(dateTo: Date): number {
    let balance = this.historic.oldBalance;
    for (let i = 0; i < this.historic.credits.length; i++) {
      const credit: Date = new Date(this.historic.credits[i].creditDate);
      if (this.getDayOfYear(credit) < this.getDayOfYear(new Date(dateTo))) {
        balance += this.historic.credits[i].value;
      }
    }
    return balance;
  }

  getDayOfYear(date: Date): number {
    const start = new Date(date.getFullYear(), 0, 0);
    const diff = date.getTime() - start.getTime();
    const oneDay = 1000 * 60 * 60 * 24;
    return Math.floor(diff / oneDay);
  }

  shared(): void {
    this.customerCreditService
      .getHistoricPrint(this.getCustomerStatement)
      .subscribe({
        next: (response) => {
          const rawString = response.text;
          const [phoneNumber, pdfLink] = rawString.split('|');

          const formattedPhoneNumber = this.formatPhoneNumber(phoneNumber);
          const message = `Olá, estou entrando em contato pelo WhatsApp Web! Aqui está o link para o arquivo: ${pdfLink}`;
          const encodedMessage = encodeURIComponent(message);
          const url = `https://wa.me/${formattedPhoneNumber}?text=${encodedMessage}`;

          window.open(url, '_blank');
        },
        error: (error: any) => {
          console.error(error);
        },
      });
  }

  close() {
    this.dialog.close();
  }

  private formatPhoneNumber(phoneNumber: string): string {
    return phoneNumber.replace(/[^\d]/g, '');
  }
}
