import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { BroadcastService } from "../services/broadcast.service";


/**
 * Guard para esconder o menu lateral
 */
@Injectable()
export class SidenavBarGuard  {
  constructor(
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    BroadcastService.get('showHideSidenav').emit(false);
    return true;
  }
}