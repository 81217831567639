<div class="table" style="margin: 10px;">
  <p-table
    #historicDataTable
    [value]="historic"
    [paginator]="true"
    [lazy]="false"
    [rowsPerPageOptions]="[50, 100]"
    dataKey="id"
    [totalRecords]="totalRecords"
    styleClass="easy-table"
    autoLayout="true"
    [autoLayout]="true"
    [rows]="numberRows"

  >
    <ng-template pTemplate="caption">
      <div class="export-buttons"></div>
      <span style="font-weight: bold; margin-top: 5px">
        {{ "TOTAL-REGISTERS" | translate }}: {{ totalRecords }}
      </span>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th [pSortableColumn]="'timeEvent'">
          Data do Evento
          <p-sortIcon [field]="'timeEvent'"></p-sortIcon>
        </th>

        <th [pSortableColumn]="'userName'">
          {{ "NAME" | translate }}
          <p-sortIcon [field]="'userName'"></p-sortIcon>
        </th>

        <th class="text-center" [pSortableColumn]="'method'">
          {{ "Método:" | translate }}
          <p-sortIcon [field]="'method'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'deviceName'">
          {{ "Dispositivo" | translate }}
          <p-sortIcon [field]="'deviceName'"></p-sortIcon>
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-histori>
      <tr>
        <td>
          {{ histori.timeEvent | date : "dd/MM/yyyy HH:MM:ss" }}
        </td>
        <td>
          {{ histori.userName }}
        </td>
        <td style="text-align: center">
          {{ histori.method == 15 ? "Facial" : "Outras" }}
        </td>
        <td>
          {{ histori?.deviceName }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
