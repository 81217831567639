import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class FocusFieldService {

    public events: EventEmitter<string>;

    constructor() {
        this.events = new EventEmitter();
    }

    dispatchEvent(): void {
        this.events.emit('');
    }
}
