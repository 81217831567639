<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{ 'TITLE-INVOICE-LOG' | translate }}</span>
        <button class="fab-button primary" appFiltersButton pTooltip="Exibir/Ocultar Filtros" tooltipPosition="left">
            <i class="pi pi-filter"></i>
        </button>
    </p-header>
  
    <form class="filters" (submit)="find()" appFilters>
        <div class="filter-row">
            <div class="form-control">
                <label>Data De / Até</label>
                <p-calendar dateFormat="dd/mm/yy" name="dateEnd" [monthNavigator]="true" [yearNavigator]="true"
                    [yearRange]="'2000:2055'" rPCalendarMask appendTo="body" selectionMode="range" [(ngModel)]="rangeLogDate"
                    [showIcon]="true" i18n>
                </p-calendar>
            </div>
        
            <div class="buttons">
                <div class="form-button">
                    <button type="submit" class="primary easymarine">{{ 'FIND' | translate }}</button>
                </div>
            </div>
        </div>
    </form>
    <app-invoice-log-table [invoiceLogs]="logs"></app-invoice-log-table>
  </p-fieldset>
  