<div class="dialog-form">
    <p-fieldset>
        <p-header>
            <h3>Pedido</h3>
        </p-header>

        <div class="body">
            <div class="form-group">
                <div class="form-control">
                    <label>Status</label>
                    <input pInputText [value]="getStatus()" readonly="readonly">
                </div>
                <div class="form-control">
                    <label>Embarcação</label>
                    <input pInputText tooltipPosition="bottom" [value]="marinaOrder?.boat?.name" readonly="readonly">
                </div>
                <div class="form-control">
                    <label>Origem</label>
                    <input pInputText [value]="getOrigin()" readonly="readonly">
                </div>
            </div>
            <div class="form-group">
                <div class="form-control">
                    <label>Pedido</label>
                    <input pInputText [value]="marinaOrder.id" readonly="readonly">
                </div>
                <div class="form-control">
                    <label>Data</label>
                    <input pInputText [value]="marinaOrder.orderDate | date: 'dd/MM/yyyy - HH:mm'" readonly="readonly">
                </div>
                <div class="form-control">
                    <label>Origem</label>
                    <input pInputText [value]="marinaOrder.origin.toUpperCase() | translate" readonly="readonly">
                </div>
                <div class="form-control">
                    <label>Total</label>
                    <input pInputText [value]="calculateTotalItens() | number:'1.2-2'" readonly="readonly">
                </div>
            </div>
            <div class="form-group end">
                <ng-container *ngIf="!hasInvoice()">
                    <div class="form-control">
                        <label>{{ 'CUSTOMER' | translate }}</label>
                        <p-dropdown placeholder="Selecione..." [filter]="true" [options]="filteredCustomers"
                            dataKey="customer.id" optionLabel="customer.name" [(ngModel)]="selectedCustomer" appendTo="body"
                            name="filterCustomerName" (onChange)="selectCustomerQuota($event)">
                        </p-dropdown>
                    </div>
                    <div class="form-control">
                        <button [disabled]="!isEditable()" class="fab-button primary" pTooltip="Adicionar Cliente"
                            type="button" (click)="addCustomerQuota()" tooltipPosition="bottom">
                            <i class="pi pi-plus"></i>
                        </button>
                    </div>
                </ng-container>
            </div>
        </div>
        <br>
        <div>
            <p-table [value]="marinaOrder.orderQuotas" [paginator]="false" autoLayout="true">
                <ng-template pTemplate="header">
                    <tr>
                        <th class="text-left">Cliente</th>
                        <th class="text-center">Fatura</th>
                        <th class="text-center">Rateio</th>
                        <th class="text-center">Valor</th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-orderQuota>
                    <tr>
                        <td>{{orderQuota.customer.name}}</td>
                        <td>{{orderQuota.invoiceNumber}}</td>
                        <td pEditableColumn class="text-right">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <div class="form-control">
                                        <input [disabled]="!isEditable()" type="number" pInputText placeholder="Quota"
                                            [(ngModel)]="orderQuota.quota" />
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{orderQuota.quota}}%
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td class="text-right">{{calculateCustomerQuota(orderQuota.quota) | number:'1.2-2'}}</td>
                        <td class="text-center">
                            <button [disabled]="!isEditable() || hasInvoice()" pTooltip="Remover cliente"
                                tooltipPosition="bottom" type="button" class="icon-button"
                                (click)="deleteCustomerQuotaConfirmation(orderQuota)">
                                <i class="pi pi-trash"></i>
                            </button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer" let-orderQuota>
                    <tr>
                        <td colspan="2"><b>Total:</b></td>
                        <td [ngStyle]="{'color':calculateTotalQuota() != 100 ? 'red' : 'blue' }" class="text-right">
                            {{calculateTotalQuota() + '%'}}</td>
                        <td class="text-right"><b>{{calculateTotalQuotaValue() | number:'1.2-2' }}</b></td>
                        <td></td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </p-fieldset>
    <br>
    <p-fieldset>
        <p-header>
            <h3>Itens</h3>
        </p-header>

        <div>
            <p-table [value]="marinaOrder.orderItems" [paginator]="false" autoLayout="true">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Id</th>
                        <th>Produto</th>
                        <th class="text-center">Qtd solicitada</th>
                        <th class="text-center">Qtd Atendida</th>
                        <th class="text-center">Valor Unit</th>
                        <th class="text-center">Valor</th>
                        <th class="text-center">Desconto Total</th>
                        <th style="display: flex;" class="text-center">Desc. até venc.
                          <div [pTooltip]="'Este desconto não influência o valor da fatura, mas pode ser utilizada na geração de nota fiscal.'"
                            class="table-action"
                            tooltipPosition="bottom">
                            <i class="pi pi-info-circle " style="color: blue;"></i>
                          </div>
                        </th>
                        <th class="text-center">Total  </th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item>
                    <tr>
                        <td>{{item.id}}</td>
                        <td>{{item.product.name}}</td>
                        <td class="text-right" *ngIf="item.itemStatus">
                            {{item.fuelMeasurementType == 'FuelFraction'
                                ? (orderUtil.fuelQuantityView(item))
                                : item.product.initialsMeasurement != 'LT' && item.product.initialsMeasurement != 'KG'
                                ? item.qtyRequested : (item.qtyRequested | number:'1.3-3')}}
                        </td>
                        <td *ngIf="!item.itemStatus && item.fuelMeasurementType == 'FuelFraction'">
                            <label for="fuelFraction">{{ 'FUEL-FRACTION' | translate }}</label>
                            <p-dropdown [options]="fuelFractions" optionLabel="label" optionValue="value" appendTo="body"
                                name="fuelFraction" [(ngModel)]="item.fuelTankFraction">
                            </p-dropdown>
                        </td>
                        <td pEditableColumn *ngIf="!item.itemStatus && item.fuelMeasurementType != 'FuelFraction'"
                            class="text-right">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <div *ngIf="item.product.initialsMeasurement != 'LT' && item.product.initialsMeasurement != 'KG'; else KgOrLt">
                                        <p-inputNumber inputId="integeronly" [(ngModel)]="item.qtyRequested" />
                                    </div>
                                    <ng-template #KgOrLt>
                                        <p-inputNumber mode="decimal" [(ngModel)]="item.qtyRequested"
                                            [name]="'qtyRequested' + item.id" [minFractionDigits]="3"
                                            [maxFractionDigits]="3">
                                        </p-inputNumber>
                                    </ng-template>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{ item.product.initialsMeasurement != 'LT' && item.product.initialsMeasurement != 'KG'
                                        ? (item.qtyRequested) :
                                            (item.qtyRequested | number:'1.3-3')}}
                                </ng-template>
                            </p-cellEditor>
                        </td>

                        <td class="text-right" *ngIf="!item.itemStatus">
                            {{item.fuelMeasurementType == 'FuelFraction'
                                || item.product.initialsMeasurement == 'LT'
                                || item.product.initialsMeasurement == 'KG'
                                ? (item.qtySupplied | number:'1.3-3') : item.qtySupplied}}
                        </td>

                        <td pEditableColumn class="text-right" *ngIf="item.itemStatus">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <div *ngIf="item.product.initialsMeasurement != 'LT' && item.product.initialsMeasurement != 'KG'; else KgOrLt2">
                                        <p-inputNumber inputId="integeronly" [disabled]="!isEditable() || hasInvoice()"
                                            [(ngModel)]="item.qtySupplied" />
                                    </div>
                                    <ng-template #KgOrLt2>
                                        <p-inputNumber [disabled]="!isEditable() || hasInvoice()" mode="decimal"
                                            [(ngModel)]="item.qtySupplied" [name]="'qtySupplied' + item.id"
                                            [minFractionDigits]="3" [maxFractionDigits]="3">
                                        </p-inputNumber>
                                    </ng-template>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{item.fuelMeasurementType == 'FuelFraction'
                                        || item.product.initialsMeasurement == 'LT'
                                        || item.product.initialsMeasurement == 'KG'
                                        ?  (item.qtySupplied | number:'1.3-3') : item.qtySupplied}}
                                </ng-template>
                            </p-cellEditor>
                        </td>
                        <td class="text-right">
                            <p-inputNumber [disabled]="!isEditable() || hasInvoice() || !item.itemStatus" mode="decimal"
                                [(ngModel)]="item.itemValue" [name]="'itemValue' + item.id" [minFractionDigits]="2"
                                [maxFractionDigits]="2">
                            </p-inputNumber>
                        </td>
                        <td  class="text-center">{{item?.itemValue * item.qtySupplied | number:'1.2-2'}}</td>
                        <td class="text-right">
                          <p-inputNumber [disabled]="!isEditable() || hasInvoice() || !item.itemStatus" mode="decimal"
                              [(ngModel)]="item.discount" [name]="'discount' + item.id" [minFractionDigits]="2"
                              [maxFractionDigits]="2"  [min]="0" [max]="item.itemValueTotal"
                              (onInput)="checkMaxValue(item)"
                              >
                          </p-inputNumber>
                      </td>
                      <td  class="text-center">{{item?.duedateDiscount | number:'1.2-2'}}</td>
                        <td class="text-right">{{calculateTotalItem(item) | number:'1.2-2'}}</td>
                        <td class="text-center">
                            <button [disabled]="!isEditable() || hasInvoice()" pTooltip="Remover"
                                tooltipPosition="bottom" type="button" class="icon-button"
                                (click)="deleteItemConfirm(item)">
                                <i class="pi pi-trash danger"></i>
                            </button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                    <tr>
                        <td colspan="8"><b>Total:</b></td>
                        <td class="text-right"><b>{{calculateTotalItens() | number:'1.2-2' }}</b></td>
                        <td></td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </p-fieldset>
    <div class="buttons">
        <button class="easymarine danger" type="button" (click)="dialogRef.close()">{{ 'CANCEL' | translate }}</button>
        <button type="button" [disabled]="!isEditable() || hasInvoice()" class="easymarine primary"
            (click)="save()">{{ 'SAVE' | translate }}</button>
    </div>
</div>
