<section class="questionnaire-container" *ngIf="questionnaire && questionnaire.options">

  <app-card-button *ngFor="let option of questionnaire.options"
    [selected]="answerSelected && answerSelected === option.code" (click)="selectOption(option); next()">

    <img [src]="getIconObj(option)" alt="" header>

    <h3 class="title-obj">{{ option.title | translate}}</h3>
    <p> {{ option.description | translate }} </p>

  </app-card-button>
</section>

<div class="buttons align-bottom">
  <button class="easymarine" (click)="back()" type="button">{{'BACK'| translate}}</button>
  <button class="easymarine danger" (click)="cancel()" type="button">{{'CANCEL'| translate}}</button>
</div>