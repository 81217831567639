import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-log-image',
  templateUrl: './product-log-image.component.html',
  styleUrls: ['./product-log-image.component.scss']
})
export class ProductLogImageComponent implements OnInit {

  @Input() productLog;
  @Input() isNew;

  oldDatasMap

  constructor() { }

  ngOnInit(): void {
    this.oldDatasMap = new Map(Object.entries(this.productLog.oldDatas));
  }

  isChangedFile(): boolean{
    return this.oldDatasMap.has('file');
  }
}
