<div class="dialog-form">
    <form (ngSubmit)="sendDocument()" class="varibles-conteiner">
        <div class="form-control" *ngFor="let item of variablesName">
            <label for=""> {{item}}</label>
            <input type="text" pInputText [disabled]="isEditable(item)" [(ngModel)]="variables[item]"
                [ngModelOptions]="{standalone: true}">
        </div>
        <div class="buttons">
            <button class="easymarine danger" type="button" (click)="dialogRef.close()">{{ 'CANCEL' | translate
                }}</button>
            <button class="easymarine primary" type="submit">{{ 'SAVE' | translate }}</button>
        </div>
    </form>
</div>