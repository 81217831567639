<div class="table">
    <p-table #documentTable [value]="isServiceCustomerType ? serviceCustomerReadjustment : serviceBoatReadjustment" class="primeTable"
             autoLayout="true" styleClass="easy-table" [lazy]="true">
        <ng-template pTemplate="caption">
                <div style="display: flex; width: 100%; justify-content: space-between;">
                    <div class="buttons-export">
                        <!-- <button class="btn-csv-xls easymarine" label="Exportar XLS" (click)="exportTableXls()"
                            pTooltip="A exportação será de acordo com os filtros selecionados" tooltipPosition="top">
                            <span>Exportar XLS</span>
                            <i class="pi pi-file-excel"></i>
                        </button>
                        <button class="btn-pdf easymarine" type="button" (click)="exportTablePdf()">
                            <span>Exportar PDF</span>
                            <i class="pi pi-file-pdf"></i>
                        </button> -->
                    </div>
                    <span style="font-weight: bold">{{ 'TOTAL-REGISTERS' | translate }}:
                        {{isServiceCustomerType ? serviceCustomerReadjustment?.length : serviceBoatReadjustment?.length}}</span>
                </div>

        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <td ></td>
                <td ></td>
                <td ></td>
                <td ></td>
                <td ></td>
                <td ></td>
                <td ></td>
                <td class="format-total">{{totalValues | currency : 'BRL'}}</td>
                <td class="format-total">{{totalDiscounts | currency : 'BRL'}}</td>
                <td class="format-total">{{totalDueDateDiscounts | currency : 'BRL'}}</td>
                <td class="format-total">{{calcTotalValueTotal() | currency : 'BRL'}}</td>
                <td class="format-total">{{totalReadjustmentsValues | currency : 'BRL'}}</td>
                <td class="format-total">{{totalReadjustmentsDiscounts | currency : 'BRL'}}</td>
                <td class="format-total">{{totalReadjustmentsDueDateDiscounts | currency : 'BRL'}}</td>
                <td class="format-total">{{calcReadjustementTotalValueTotal() | currency : 'BRL'}}</td>
                <td class="format-total">{{differenceTotals() | currency : 'BRL'}}</td>
            </tr>
            <tr>
                <th *ngIf="!isServiceCustomerType">
                    {{ 'BOAT' | translate }}
                </th>
                <th *ngIf="isServiceCustomerType" colspan="3">
                    {{'CUSTOMER' | translate}}
                </th>
                <th *ngIf="!isServiceCustomerType">
                    {{ 'TYPE' | translate }}
                </th>
                <th *ngIf="!isServiceCustomerType">TC.</th>
                <th>{{ 'OBSERVATION' | translate}}</th>
                <th>
                    {{ 'Inicio serviço' | translate }}
                </th>
                <th>
                    {{ 'Fim serviço' | translate }}
                </th>
                <th>
                    {{ 'Último reajuste' | translate }}
                </th>
                <th>{{ 'VALUE' | translate}}</th>
                <th>{{ 'DISCOUNT' | translate}}</th>
                <th>{{ 'DUE-DATE-DISCOUNT' | translate}}</th>
                <th>{{ 'TOTAL' | translate}}</th>
                <th>{{ 'NEW-VALUE' | translate}}</th>
                <th>{{ 'NEW-DISCOUNT' | translate}}</th>
                <th>{{ 'NEW-DUE-DATE-DISCOUNT' | translate}}</th>
                <th>{{ 'TOTAL-READJUSTMENT' | translate}}</th>
                <th>Diferença reajuste </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-service>
            <tr>
                <td *ngIf="!isServiceCustomerType">{{service.boatName}}</td>
              <td *ngIf="isServiceCustomerType" colspan="3">{{service.customerName}}</td>
                <td *ngIf="!isServiceCustomerType">{{service.shipType.toUpperCase() | translate}}</td>
                <td *ngIf="!isServiceCustomerType">{{service.commercialLength}}</td>
                <td >{{service.observation}}</td>
                <td >{{service.contractStartDate | date:'dd/MM/yyyy'}}</td>
                <td >{{service.contractEndDate | date:'dd/MM/yyyy'}}</td>
                <td >{{service.lastReadjustment | date:'dd/MM/yyyy'}}</td>
                <td class="text-right">{{service.value | currency : 'BRL'}}</td>
                <td class="text-right">{{service.discount | currency : 'BRL'}}</td>
                <td class="text-right">{{service.dueDateDiscount | currency : 'BRL'}}</td>
                <td class="text-right">{{totalOldValue(service) | currency : 'BRL'}}</td>
                <td >{{service.readjustmentValue | currency : 'BRL'}}</td>
                <td >
                    <input name="readjustmentDiscount" type="number" pInputText class="small" min="0"
                        *ngIf="service.readjustmentValue" [(ngModel)]="service.readjustmentDiscount" (input)="calcTotalDiscountsReadjustments()"
                    >
                    <span *ngIf="!service.readjustmentValue">{{service.readjustmentDiscount | currency : 'BRL'}}</span>
                </td>
                <td >
                    <input name="readjustmentDueDateDiscount" type="number" pInputText class="small" min="0"
                        *ngIf="service.readjustmentValue" [(ngModel)]="service.readjustmentDueDateDiscount" (input)="calcTotalDueDateDiscountsReadjustments()"
                        >
                    <span *ngIf="!service.readjustmentValue">{{service.readjustmentDueDateDiscount | currency : 'BRL'}}</span>
                </td>
                <td [ngClass]="getStyleValueReadjustment(service)">{{totalReajustment(service) | currency : 'BRL'}}</td>
                <td >{{differenceReajustment(service) | currency : 'BRL'}}</td>
            </tr>
        </ng-template>
    </p-table>
</div>
<div class="buttons-readjustment">
    <div class="buttons">
        <div class="form-button">
            <button class="primary easymarine" type="button" [disabled]="!enableReadjustment()" (click)="applyReadjustment()">{{ 'Aplicar reajuste' | translate }}</button>
        </div>
    </div>
</div>
