<div class="dialog-form align-form-center">
  <form (submit)="save()">
    <div class="form-group" *ngIf="ajustmentEnableChangeLocation()">
      <div class="form-control">
        <label>{{'CHANGE-CURRENT-LOCATION-BOAT-MOVEMENT-ADJUSTMENT'| translate: location}} *</label>
        <div class="form-control radio">
          <p-radioButton
            name="changeBoatLocation"
            [value]="true"
            (click)="changeLocation()"
            label="{{ 'Alterar localização atual' | translate }}"
            [(ngModel)]="changeBoatLocation"
          >
          </p-radioButton>
          <p-radioButton
            name="changeBoatLocation"
            [value]="false"
            (click)="notChangeLocation()"
            label="{{ 'Não alterar localização atual' | translate }}"
            [(ngModel)]="changeBoatLocation"
          >
          </p-radioButton>
        </div>
      </div>
    </div>
    <div class="form-group" *ngIf="changeBoatLocation && vancacies.length > 0">
      <div class="form-control">
        <label for="customer">{{ "VACANCY" | translate }} <span *ngIf="requiredVacancy()">*</span></label>
        <p-dropdown
          [(ngModel)]="vacancyId"
          name="vacancy"
          appendTo="body"
          [options]="vancacies"
          [required]="requiredVacancy()">
        </p-dropdown>
      </div>
    </div>
    <div class="form-group message-confirm" *ngIf="isValid()">
      <span class="text-center">{{messageConfirm}}</span>
    </div>

    <div class="form-group align-center align-btn-actions">
      <div class="form-control" *ngIf="ajustmentEnableChangeLocation()">
        {{ "REQUIRED-FIELDS" | translate }}
      </div>
      <div class="buttons">
        <button
          class="easymarine danger"
          type="button"
          (click)="dialogRef.close()"
        >
          {{ "CANCEL" | translate }}
        </button>
        <button
          class="easymarine primary"
          type="submit"
          [disabled]="!isValid()"
        >
          {{ "SAVE" | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
