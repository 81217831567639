<p-fieldset *ngIf="boatLog.tie">
    <p-header class="article-title">
      <div class="article-title">{{ "DOCUMENTATION" | translate }}</div>
    </p-header>

    <div class="content-log">
      <div>
        <label>{{ "REGISTER" | translate }}</label>
        <label [ngClass]="{'item-new-log': isChangedRegister() && isNew, 'item-old-log': isChangedRegister() && !isNew}">
            {{ isChangedRegister() && isNew ?boatLog.tie.register: boatLog.oldDatas.tie.register }}
        </label>
      </div>
      <div>
        <label>{{ "Validade" | translate }}</label>
        <label [ngClass]="{'item-new-log': isChangedExpiration() && isNew, 'item-old-log': isChangedExpiration() && !isNew}">
            {{ isChangedExpiration() && isNew ?boatLog.tie.expiration: boatLog.oldDatas.tie.expiration | date:'dd/MM/yyyy' }}
        </label>
      </div>
      <div>
        <label>{{ "STATE" | translate }}</label>
        <label [ngClass]="{'item-new-log': isChangedState() && isNew, 'item-old-log': isChangedState() && !isNew}">
            {{ isChangedState() && isNew ?boatLog.tie.state: boatLog.oldDatas.tie.state }}
        </label>
      </div>
      <div>
        <label>{{ "CITY" | translate }}</label>
        <label [ngClass]="{'item-new-log': isChangedCity() && isNew, 'item-old-log': isChangedCity() && !isNew}">
            {{ isChangedCity() && isNew ?boatLog.tie.city: boatLog.oldDatas.tie.city }}
        </label>
      </div>
      <div>
        <label>{{ "NAVIGATION-AREA" | translate }}</label>
        <label [ngClass]="{'item-new-log': isChangedNavigationArea() && isNew, 'item-old-log': isChangedNavigationArea() && !isNew}">
            {{ isChangedNavigationArea() && isNew ?boatLog.tie.navigationArea: boatLog.oldDatas.tie.navigationArea | uppercase | translate }}
        </label>
      </div>
    </div>
    <br />
    <div *ngIf="boatLog.tie.file">
      <a [href]="boatLog.tie.file" target="_blank">
        <img width="200" [src]="boatLog.tie.file" />
      </a>
    </div>
  </p-fieldset>
  <br />