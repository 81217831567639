
  <app-create-ticket
    *ngIf="page==0"
    (finishEvent)="onTicketForm($event)"
    [slingConfig]="slingConfig"
    (getMarina)="getMarina($event)"
    (getScreen)="getScreen($event)"
      >
  </app-create-ticket>

  <app-personal-help
    *ngIf="page==1"
    (personalEvent)="incrementaPage($event)"
    [tela]="tela">
  </app-personal-help>

  <app-write-ticket
    *ngIf="page==2"
    (ticketForm)="onTicketFormDet($event)"
    (fileList)="saveAs($event)"
    >

  </app-write-ticket>

