<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{ 'TITLE-NOTICIES' | translate }}</span>
        <div class="primary fab-button" (click)="openNoticeForm()">
            <i class="pi pi-plus" style="font-size: 21px;"></i>
        </div>
    </p-header>

    <div class="table">
        <p-table #tt [value]="noticies" resetPageOnSort="true" [pageLinks]="3" [paginator]="true"
            [rowsPerPageOptions]="[10,20,30,50,100]" [autoLayout]="true" [rows]="numberOfRows">
            <ng-template pTemplate="header">
                <tr>
                    <th [pSortableColumn]="'title'">
                        {{ 'TITLE' | translate }}
                        <p-sortIcon [field]="'title'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'initialDateTime'">
                        {{ 'INITIAL-DATE' | translate }}
                        <p-sortIcon [field]="'initialDateTime'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'finalDateTime'">
                        {{ 'FINAL-DATE' | translate }}
                        <p-sortIcon [field]="'finalDateTime'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'noticeType'">
                        {{ 'NOTICE-TYPE' | translate }}
                        <p-sortIcon [field]="'noticeType'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'noticeTargetType'">
                        {{ 'NOTICE-TARGET-TYPE' | translate }}
                        <p-sortIcon [field]="'noticeTargetType'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'status'">
                        {{ 'STATUS' | translate }}
                        <p-sortIcon [field]="'status'"></p-sortIcon>
                    </th>
                    <th></th>
                    <th></th>
                </tr>
                <tr>
                    <th>
                        <input pInputText type="text" (input)="tt.filter($event.target.value, 'title', 'contains')"
                            [value]="tt.filters['title']?.value" placeholder="{{'TITLE' | translate }}">
                    </th>
                    <th></th>
                    <th></th>
                    <th>
                        <p-dropdown appendTo="body" [options]="noticeTypes"
                            (onChange)="tt.filter($event.value, 'noticeType', 'contains')" appendTo="body"
                            placeholder="{{'NOTICE-TYPE' | translate }}">
                        </p-dropdown>
                    </th>
                    <th>
                        <p-dropdown appendTo="body" [options]="noticeTargetTypes"
                            (onChange)="tt.filter($event.value, 'noticeTargetType', 'contains')" appendTo="body"
                            placeholder="{{'NOTICE-TARGET-TYPE' | translate }}">
                        </p-dropdown>
                    </th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-notice>
                <tr>
                    <td>{{ notice.title }}</td>
                    <td class="text-center">{{ notice.initialDateTime | date: 'dd/MM/yyyy' }}</td>
                    <td class="text-center">{{ notice.finalDateTime | date: 'dd/MM/yyyy' }}</td>
                    <td class="text-center">{{ notice.noticeType?.toUpperCase() | translate }}</td>
                    <td class="text-center">{{ getTargetType(notice) }}</td>
                    <td class="text-center" style="min-width: 115px">
                        <span *ngIf="onDisplay(notice.initialDateTime, notice.finalDateTime) === 'AWAITING-DATE'"
                            class="badge badge-pill warning">
                            {{ 'AWAITING-DATE' | translate }}
                        </span>
                        <span *ngIf="onDisplay(notice.initialDateTime, notice.finalDateTime) === 'SHOWING'"
                            class="badge badge-pill success">
                            {{ 'ON-DISPLAY' | translate }}
                        </span>
                        <span *ngIf="onDisplay(notice.initialDateTime, notice.finalDateTime) === 'FINALIZED'"
                            class="badge badge-pill danger">
                            {{ 'FINALIZED' | translate }}
                        </span>
                    </td>
                    <td class="text-center">
                      <div  *ngIf="notice.destinationType==='APP_CLIENT_EMAIL'" class="table-action" (click)="openNoticeEmail(notice)" pTooltip="Editar"
                          tooltipPosition="bottom">
                          <i class="pi pi-envelope" style="color: green"></i>
                      </div>
                  </td>
                    <td class="text-center">
                        <div class="table-action" (click)="openNoticeFormById(notice.id)" pTooltip="Editar"
                            tooltipPosition="bottom">
                            <i class="pi pi-pencil"></i>
                        </div>
                    </td>
                    <td class="text-center">
                        <div class="table-action" (click)="openNoticeDeleteConfirmDialog(notice.id)" pTooltip="Deletar"
                            tooltipPosition="bottom">
                            <i class="pi pi-trash" style="color:  #EF5350"></i>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

</p-fieldset>
