<div *ngIf="movementGroupForm" class="dialog-form">
    <form (submit)="save()" [formGroup]="movementGroupForm">
        <div class="basic">
            <div class="form-group">
                <div class="form-control full">
                    <label for="name">{{'NAME' | translate}} *</label>
                    <input id="movementGroupName" pInputText name="movementGroupName" formControlName="name">
                </div>
            </div>
            <div class="form-group">
                <div class="form-control full">
                    <label for="code">{{'Código' | translate}} *</label>
                    <input pInputText id="code" name="code" formControlName="code" maxLength="5">
                </div>
                <div class="form-control quarter">
                    <label for="active">{{ 'ACTIVE' | translate }} *</label>
                    <p-inputSwitch styleClass="myswitch" formControlName="active" name="active" id="active">
                    </p-inputSwitch>
                </div>
            </div>
            <div class="form-group">
                <div class="form-control full">
                    <label for="scheduleQueue">{{ 'Fila de descida' | translate }} *</label>
                    <p-dropdown id="scheduleQueue" dataKey="id"
                      formControlName="scheduleQueue"
                      [options]="scheduleQueueOptions"
                      placeholder="Selecione..."
                    ></p-dropdown>
                </div>
            </div>
            <div class="form-group">
                <div class="form-control full">
                    <label for="means">{{ 'Meios de movimentação' | translate }} *</label>
                    <p-multiSelect id="means"
                      dataKey="id"
                      [options]="movementMeanOptions"
                      formControlName="movementGroupMeans"
                    >
                      <ng-template let-value pTemplate="selectedItems">
                        <span class="selectedItems-movement-mean"></span>
                        <div *ngFor="let item of value; let i = index" class="selectedItems-movement-mean">
                          <span *ngIf="i!=0">, </span>
                          <app-movement-mean-label [movementMean]="item"></app-movement-mean-label>
                        </div>
                      </ng-template>
                      <ng-template let-mMean pTemplate="item">
                          <app-movement-mean-label [movementMean]="mMean.value"></app-movement-mean-label>
                      </ng-template>
                    </p-multiSelect>
                </div>
            </div>
            <div class="form-group" formArrayName="movementGroupMeansByLocationType">
              <div class="form-control full">
                <label for="means">{{ 'Meios de movimentação por tipo de local' | translate }}</label>
                <p-table
                  *ngIf="movementGroupMeansByLocationTypeArray"
                  [value]="movementGroupMeansByLocationTypeArray.controls"
                  autoLayout="true"
                >
                  <ng-template pTemplate="body" let-meanByLocationType let-i="rowIndex">
                      <tr [formGroupName]="i">
                          <td style="width: 200px; padding-left: 0px !important;">
                            {{ 'MOVEMENT.LOCALES.' + meanByLocationType.value.locationType | translate }}*
                          </td>
                          <td style="width: auto; padding-left: 0px !important; padding-right: 0px !important;">
                            <p-dropdown id="means"
                              dataKey="id"
                              [options]="movementGroupForm.value.movementGroupMeans"
                              placeholder="meio de movimentação..."
                              formControlName="movementMean"
                              appendTo="body"
                            >
                              <ng-template pTemplate="selectedItem">
                                <div>
                                  <app-movement-mean-label
                                    [movementMean]="movementGroupMeansByLocationTypeArray.value[i].movementMean">
                                  </app-movement-mean-label>
                                </div>
                              </ng-template>
                              <ng-template let-mMean pTemplate="item">
                                  <app-movement-mean-label [movementMean]="mMean"></app-movement-mean-label>
                              </ng-template>
                            </p-dropdown>
                          </td>
                        </tr>
                        <tr *ngIf="time?.errors">
                          <td colspan="2">
                            <app-messages *ngIf="time?.errors"
                              [titleMessage]="'Erro:'"
                              [textMessage]="' ... !'">
                            </app-messages>
                          </td>
                        </tr>
                  </ng-template>
                </p-table>
              </div>
            </div>
        </div>
        <div class="form-group align-center">
            <div class="form-control">
                {{'REQUIRED-FIELDS' | translate}}
            </div>
            <div class="buttons">
                <button class="easymarine danger" type="button"
                    (click)="dialog.close()">{{ 'CANCEL' | translate }}</button>
                <button class="easymarine primary" type="submit" [disabled]="movementGroupForm.invalid">{{ 'SAVE' | translate }}</button>
            </div>
        </div>
    </form>
</div>
