<div class="dialog-form">
  <form (submit)="formValidations()" [formGroup]="invTaxDocumentForm">

    <div class="form-group">
      <div class="form-control">
        <label>Id</label>
        <input pInputText type="text" formControlName="id" readonly name="id">
      </div>
      <div class="form-control">
        <label for="username">{{ 'USERNAME' | translate }}*</label>
        <input pInputText type="text" formControlName="nfeId" readonly name="nfeId" autocomplete="off">
      </div>
      <div class="form-control">
        <label for="username">{{ 'USERNAME' | translate }}*</label>
        <input pInputText type="text" formControlName="empresaId" readonly name="empresaId" autocomplete="off">
      </div>      
    </div>
    <div class="form-group">
      <div class="form-control">
        <label for="username">{{ 'USERNAME' | translate }}*</label>
        <input pInputText type="text" formControlName="nfeNumero" readonly name="nfeNumero" autocomplete="off">
      </div>
      <div class="form-control">
        <label for="firstName">{{ 'FIRST-NAME' | translate }}*</label>
        <input pInputText type="text" formControlName="nfeStatus" readonly name="nfeStatus" maxlength="50">
      </div>
      <div class="form-control">
        <label for="lastName">{{ 'LAST-NAME' | translate }}*</label>
        <input pInputText type="text" formControlName="status" readonly name="status" maxlength="50">
      </div>
    </div>
    <br>
    <div class="form-group">     
      <div class="form-control">
        <label for="email">{{ 'EMAIL' | translate }}*</label>
        <input pInputText type="email" formControlName="marina" readonly name="marina" maxlength="100">
      </div>
      <div class="form-control">
        <label for="bracelet">{{ 'BRACELET' | translate }}</label>
        <input pInputText type="text" formControlName="invoiceNumber" readonly name="invoiceNumber" maxlength="50">
      </div>
      <div class="form-control">
        <label for="firstName">{{ 'FIRST-NAME' | translate }}*</label>
        <input pInputText type="text" formControlName="aliquotaIss" readonly name="aliquotaIss" maxlength="50">
      </div>
    </div>
    <br>
    <div class="form-group">      
      <div class="form-control">
        <label for="lastName">{{ 'LAST-NAME' | translate }}*</label>
        <input pInputText type="text" formControlName="valorTotal" readonly name="valorTotal" maxlength="50">
      </div>
      <div class="form-control">
        <label for="email">{{ 'EMAIL' | translate }}*</label>
        <input pInputText type="email" formControlName="createDate" readonly name="createDate" maxlength="100">
      </div>
      <div class="form-control">
        <label for="bracelet">{{ 'BRACELET' | translate }}</label>
        <input pInputText type="text" formControlName="updateDate" readonly name="updateDate" maxlength="50">
      </div>
    </div>
    <br>
    <div class="form-group">   
      <div class="form-control">
        <label for="firstName">{{ 'FIRST-NAME' | translate }}*</label>
        <input pInputText type="text" formControlName="federalId" readonly name="federalId" maxlength="50">
      </div>
      <div class="form-control">
        <label for="lastName">{{ 'LAST-NAME' | translate }}*</label>
        <input pInputText type="text" formControlName="type" readonly name="type" maxlength="50">
      </div>
      <div class="form-control">
        <label for="email">{{ 'EMAIL' | translate }}*</label>
        <input pInputText type="email" formControlName="serviceDocument" readonly name="serviceDocument" maxlength="100">
      </div>
      <div class="form-control">
        <label for="bracelet">{{ 'BRACELET' | translate }}</label>
        <input pInputText type="text" formControlName="serviceDocumentXml" readonly name="serviceDocumentXml" maxlength="50">
      </div>
    </div>

    <br>
    <div class="form-group align-center">
      <div class="form-control">
        {{'REQUIRED-FIELDS' | translate}}
      </div>
      <div class="buttons">
        <button class="easymarine danger" type="button" (click)="dialogRef.close()">{{ 'CANCEL' | translate }}</button>
        <button class="easymarine primary" type="submit">{{ 'SAVE' | translate }}</button>
      </div>
    </div>
  </form>
</div>