import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MovementImage } from "src/app/models/movement/movement-image";
import { environment } from "src/environments/environment";

@Injectable()
export class MovementImageService {
  private readonly API_URL = environment.apiHost + '/secure/movement/movement-images';

    constructor(private http: HttpClient) { }

    getAll(): Observable<MovementImage[]> {
        return this.http.get(this.API_URL)
            .pipe(map((response: any) => response.data.movementImages as MovementImage[]));
    }
}
