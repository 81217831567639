
<div class="dialog-form">
    <form (submit)="find()">
        <div class="form-group">
            <div class="form-control">
                <label>{{ 'BOAT' | translate }} *</label>
                <p-dropdown placeholder="Selecione..." [filter]="true" [options]="boatListOptions"
                    [(ngModel)]="selectedBoatId" name="selectedBoatId" [disabled]="disableBoatAndDate()">
                    <ng-template let-boat pTemplate="selectedItem">
                        <span [ngStyle]="{'color': boat.disabled ? 'red' : '#222222' }">{{boat.label}}</span>
                    </ng-template>
                    <ng-template let-boat>
                        {{boat.label}}
                    </ng-template>
                </p-dropdown>
            </div>
            <div class="form-control">
                <label>Data *</label>
                <p-calendar class="field" dateFormat="dd/mm/yy" name="date" [monthNavigator]="true"
                    appendTo="body" [yearNavigator]="true" [yearRange]="'2000:2055'" rPCalendarMask [maxDate]="today"
                    [(ngModel)]="date" [showIcon]="true" i18n [disabled]="disableBoatAndDate()" (onSelect)="changeDate()">
                </p-calendar>
            </div>
            <div class="buttons">
                <button class="easymarine primary" type="submit" [disabled]="!isValid()">{{ 'VISUALIZE' | translate }}</button>
            </div>
        </div>
    </form>

    <form (submit)="addMovement()" >
        <div class="form-group">
            <div class="form-control">
                <label for="movementPlan">{{'MOVEMENT-PLAN' | translate}} *</label>
                <p-dropdown [(ngModel)]="newMovement.movementPlan" name="movementPlan" appendTo="body"
                    [options]="plansInDayOptions" [disabled]="disablePlanAndCustomer()">
                </p-dropdown>
            </div>
            <div class="form-control" *ngIf="isNewMovementPlan()">
                <label for="customer">{{'CUSTOMER' | translate}} *</label>
                <p-dropdown [(ngModel)]="newMovement.customer" name="customer" appendTo="body"
                    [options]="customerListOptions" [disabled]="disablePlanAndCustomer()">
                </p-dropdown>
            </div>
            <div class="form-control">
                <label for="origin">{{'ORIGIN' | translate}} *</label>
                <p-dropdown [(ngModel)]="newMovement.origin" name="origin" appendTo="body"
                    [options]="movementLocationListFullValueOptions">
                </p-dropdown>
            </div>
            <div class="form-control">
                <label for="destiny">{{'DESTINY' | translate}} *</label>
                <p-dropdown [(ngModel)]="newMovement.destiny" name="destiny" appendTo="body"
                    [options]="movementLocationListFullValueOptions">
                </p-dropdown>
            </div>
            <div class="form-control">
                <label>Hora *</label>
                <input pInputText type="time" class="time" name="time" [(ngModel)]="newMovement.time">
            </div>
            <div class="buttons">
                <button class="easymarine primary" type="submit" [disabled]="!isValidMovement()">{{ 'Adicionar' | translate
                    }}</button>
            </div>
        </div>
    </form>

    <div class="table" *ngIf="!enableSearch">
        <p-table #tt [value]="movements" [autoLayout]="true"
            styleClass="easy-table p-datatable-striped" [lazy]="true">
            <ng-template pTemplate="caption">
                <div></div>
                <span style="font-weight: bold; float: right;">{{ 'TOTAL-REGISTERS' | translate }}:{{movements.length}}
                </span>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th class="text-center"> {{ 'ADJUSTMENT' | translate }}</th>
                    <th class="text-center"> {{ 'PLAN' | translate }}</th>
                    <th class="text-center"> {{ 'FINISH-DATE-TIME' | translate }}</th>
                    <th class="text-center"> {{ 'CUSTOMER' | translate }}</th>
                    <th class="text-center"> {{ 'ORIGIN' | translate }}</th>
                    <th class="text-center"> {{ 'DESTINY' | translate }}</th>
                    <th class="text-center"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-movement>
                <tr [ngClass]="{'movement-disable': !movement.activeInDay, 'movement-insert': !movement.movementId}">
                    <td class="text-center">
                        {{movement.systemAdjustment? 'A': ''}}
                    </td>
                    <td class="text-center">
                        {{movement.movementPlanMarinaId}}
                    </td>
                    <td class="text-center">
                        {{movement.finishDateTime | date: 'dd/MM/yy - HH:mm'}}
                    </td>
                    <td class="text-center">
                        {{movement.customerName}}
                    </td>
                    <td class="text-center">
                        {{movement.originName}}
                    </td>
                    <td class="text-center">
                        {{movement.destinyName}}
                    </td>
                    <td class="text-center">
                        <i class="table-action" pTooltip="Remover movimentação" tooltipPosition="bottom"
                            (click)="removeMovementAjustment(movement)"
                            *ngIf="!movement.movementId">
                            <i class="danger pi pi-times-circle"></i>
                        </i>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="6">
                        Nenhum movimentação encontrada
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div>

            <div class="form-group align-center">
                <div class="form-control">
                    {{'REQUIRED-FIELDS' | translate}}
                </div>
                <div class="buttons">
                    <button class="easymarine danger" type="button" (click)="dialogRef.close()">{{ 'BACK' | translate
                        }}</button>
                    <button class="easymarine primary" type="submit" (click)="saveAdjustment()" >{{ 'SAVE' | translate
                        }}</button>
                </div>
            </div>
    </div>
</div>


