import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LazyLoadEvent } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { SlingConfig } from 'src/app/models';
import { DocumentContractedMarina } from 'src/app/models/document-contracted-marina';
import { PaginationFilter } from 'src/app/models/pagination-filter';
import { DocumentContractedMarinaService } from 'src/app/services/document-contracted-marina.service';
import { MyAccountService } from 'src/app/services/my-account.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { ExtractPdfGenericComponent } from 'src/app/components/extract-pdf-generic/extract-pdf-generic.component';
import { MessageUtil } from 'src/app/utils/message.util';

@Component({
    selector: 'app-documents-contracted',
    templateUrl: './documents-contracted.component.html',
    styleUrls: ['./documents-contracted.component.scss']
})
export class DocumentsContractedComponent implements OnInit {

    documentsContracted: DocumentContractedMarina[] = [];
    documentsContractedNotBilling: DocumentContractedMarina[] = [];

    paginationFilter: PaginationFilter = new PaginationFilter();
    numberOfRows: number;

    slingConfig: SlingConfig;

    constructor(
        private documentContractedMarinaService: DocumentContractedMarinaService,
        private slingConfigService: SlingConfigService,
        private myAccontService: MyAccountService,
        private dialog: DialogService,
        private messageUtil: MessageUtil,
    ) { }

    async ngOnInit(): Promise<void> {
        this.slingConfig = await this.findSlingConfig();
        if (this.slingConfig) {
            this.numberOfRows = this.slingConfig != null ? this.slingConfig.numberOfRecordsPerPage : 10;
            this.paginationFilter.size = this.numberOfRows;
        }
        this.getDocumentsContracted();
    }

    async findSlingConfig(): Promise<SlingConfig> {
        return new Promise<SlingConfig>(
            async (resolve, reject) => {
                this.slingConfigService.getSlingConfigToday().subscribe(
                    async (slingConfig) => {
                        resolve(slingConfig);
                    },
                    async () => {
                        reject(new SlingConfig());
                    }
                );
            }
        );
    }

    onChangePage(event: LazyLoadEvent): void {
        if(this.slingConfig){
            const page = event.first / event.rows;
            this.paginationFilter.sort = event.sortField;
            this.paginationFilter.page = page;
            this.paginationFilter.size = event.rows;
            this.paginationFilter.order = event.sortOrder === 1 ? 'ASC' : 'DESC';
            this.getDocumentsContracted();
        }
    }

    async openInvoice(documentContracted: DocumentContractedMarina): Promise<void> {
        const id = documentContracted.codInvoice
        this.myAccontService.getInvoice(id).subscribe(
            async (data) => {
                const path = "data:application/pdf;base64," + data.pdf
                this.dialog.open(ExtractPdfGenericComponent, {
                    width: '100%',
                    height: '100%',
                    data: {
                        path
                    },
                    header: 'Fatura ' + id
                });
            },
            (error) => {
                const exception = error.error.data.exception;
                this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
            }
        );
    }

    getDocumentsContracted(): void {
        this.documentContractedMarinaService.getDocuments(this.paginationFilter).subscribe(
            (data) => {
                this.documentsContracted = data.billing;
                this.documentsContractedNotBilling = data.notBilling;
            },
            (error) => {
                console.log(error);
            }
        );
    }
}