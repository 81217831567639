<div class="dialog-form">
    <form (submit)="viewLaunch()">
        <div class="body">
            <div class="info">
                <div class="block">
                    <div class="line">
                        <div class="field">
                            <strong>Início do contrato:&nbsp;</strong> {{tuition?.contractStartDate | date:'dd/MM/yy'}}
                        </div>
                        <div class="field large">
                            <strong>Fim do contrato:&nbsp;</strong>{{tuition?.contractEndDate | date:'dd/MM/yy'}}
                        </div>
                    </div>
                    <div class="line">
                        <div class="field">
                            <strong>Serviço:&nbsp;</strong> {{tuition?.productName}}
                        </div>
                        <div class="field">
                            <strong>Tamanho (pés):&nbsp;</strong> {{tuition?.commercialLength}}
                        </div>
                        <div class="field">
                            <strong>Preço:&nbsp;</strong>{{getPriceTypeName(tuition?.pricingType)}}
                        </div>
                    </div>
                    <div class="line">
                        <div class="field">
                            <strong>Valor:&nbsp;</strong>{{tuition?.value | number:'1.2-2'}}
                        </div>
                        <div class="field">
                            <strong>Desconto:&nbsp;</strong>{{tuition?.discount | number:'1.2-2'}}
                        </div>
                        <div class="field">
                            <strong>Total:&nbsp;</strong>{{tuition?.value - tuition?.discount | number:'1.2-2'}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <div class="form-control">
                    <label>Mês de Referência inicial</label>
                    <p-dropdown placeholder="Selecione..." [options]="monthsReference" appendTo="body"
                        [(ngModel)]="initialReferenceMonth" name="initialReferenceMonth">
                    </p-dropdown>
                </div>
                <div class="form-control">
                    <label>Ano de Referência inicial</label>
                    <p-dropdown placeholder="Selecione..." [options]="[
                                    {label: '2021', value: 2021},
                                    {label: '2022', value: 2022},
                                    {label: '2023', value: 2023},
                                    {label: '2024', value: 2024},
                                    {label: '2025', value: 2025},
                                    {label: '2026', value: 2026},
                                    {label: '2027', value: 2027}]" [(ngModel)]="initialReferenceYear" appendTo="body"
                        name="initialReferenceYear">
                    </p-dropdown>
                </div>
                <div class="form-control">
                    <label>Mês de Referência final</label>
                    <p-dropdown placeholder="Selecione..." [options]="monthsReference" [(ngModel)]="finalReferenceMonth"
                        name="finalReferenceMonth" appendTo="body">
                    </p-dropdown>
                </div>
                <div class="form-control">
                    <label>Ano de Referência final</label>
                    <p-dropdown placeholder="Selecione..." [options]="[
                                        {label: '2021', value: 2021},
                                        {label: '2022', value: 2022},
                                        {label: '2023', value: 2023},
                                        {label: '2024', value: 2024},
                                        {label: '2025', value: 2025},
                                        {label: '2026', value: 2026},
                                        {label: '2027', value: 2027}]" [(ngModel)]="finalReferenceYear" appendTo="body"
                        name="finalReferenceYear">
                    </p-dropdown>
                </div>
            </div>
        </div>
        <div class="buttons">
            <button type="submit" class="easymarine primary">Pré-Visualizar</button>
            <button type="button" *ngIf="hasSelection()" (click)="dialogGenerateTuition()"
                class="easymarine danger">Gerar pedidos</button>
            <button type="button" *ngIf="hasSelection()" (click)="dialogGenerateInvoiceUnified()"
                class="easymarine primary">Gerar fatura unificada</button>
            <button type="button" *ngIf="hasSelection()" (click)="dialogGenerateInvoiceSeparately()"
                class="easymarine primary">Gerar fatura separadamente</button>
        </div>
    </form>
    <br>
    <span *ngIf="tuitions?.length > 0">
        <div class="subtitles">
            <fieldset>
                <legend>Legenda (Cores de fundo contratos de serviço)</legend>
                <div class="badges">
                    <div class="badge" style="background-color: rgb(124, 245, 74);color: rgb(124, 245, 74);"></div>
                    Vencem no mês seguinte
                    <div class="badge" style="background-color: rgb(253, 221, 51);color: rgb(253, 221, 51);"></div>
                    Vencem neste mês
                    <div class="badge" style="background-color: rgb(255 154 154);color: rgb(255 154 154);"></div>
                    Contrato vencido
                </div>
            </fieldset>
            <fieldset>
                <legend>Legenda (Cores da letra)</legend>
                <div class="badges">
                    <div class="badge" style="background-color: rgb(33, 150, 243);color: rgb(33, 150, 243);"></div>
                    Ok
                    <div class="badge" style="background-color: green;color: green;"></div>
                    Ok, não possui valor à pagar
                    <div class="badge" style="background-color: red;color: red;"></div>
                    Sem serviço e/ou proprietário
                </div>
            </fieldset>
        </div>
        <br>
        <p-table [value]="tuitions" #tt autoLayout="true" *ngIf="tuitions?.length > 0"
            [(selection)]="selectedTuitions" selectionMode="multiple" autoLayout="true">
            <ng-template pTemplate="header">
                <tr>
                    <th colspan="2"></th>
                    <th class="text-center">{{totalValue | number: '1.2-2'}}</th>
                    <th class="text-center">{{totalDiscount | number: '1.2-2'}}</th>
                    <th class="text-center">{{total | number: '1.2-2'}}</th>
                    <th colspan="3"></th>
                    <th class="text-center">Registros: {{tuitions.length}}</th>
                </tr>

                <tr>
                    <th class="text-center">
                        <p-tableHeaderCheckbox (onChange)="updateSelected()"></p-tableHeaderCheckbox>
                    </th>
                    <th [pSortableColumn]="'dateReferences'">
                        Referência
                        <p-sortIcon [field]="'dateReferences'"></p-sortIcon>
                    </th>
                    <th class="text-center">Valor</th>
                    <th class="text-center">Desconto</th>
                    <th class="text-center">Total</th>
                    <th class="text-center">Cliente</th>
                    <th class="text-center">Rateio</th>
                    <th class="text-right">Valor</th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-t let-index="rowIndex">
                <tr [ngStyle]="{'color': getFontColor(t), 'background-color': hasContractEndDate(t)}"
                    class="no-padding">
                    <td class="text-center" *ngIf="isEnabled(t)">
                        <p-tableCheckbox [value]="t">
                        </p-tableCheckbox>
                    </td>
                    <td class="text-center" *ngIf="!isEnabled(t)">
                        <p-checkbox [name]="t.dateReferences" [value]="t.dateReferences" [disabled]="true"></p-checkbox>
                    </td>
                    <td class="text-left">
                        {{t.dateReferences | date:'MMMM yyyy' | uppercase}}
                    </td>
                    <td class="text-center">
                        {{t.value | number:'1.2-2'}}
                    </td>
                    <td class="text-center">
                        {{t.discount | number:'1.2-2'}}
                    </td>
                    <td class="text-center">
                        {{(t.value - t.discount) != t.marinaOrder.value ? 'P | ' : ''}}{{t.marinaOrder.value |
                        number:'1.2-2'}}
                    </td>
                    <td colspan="3">
                        <p-table class="primeSubTable" [value]=t.marinaOrder.orderQuotas autoLayout="true">
                            <ng-template pTemplate="body" let-orderQuota>
                <tr [ngStyle]="{'color': getFontColor(t), 'background-color': hasContractEndDate(t)}"
                    class="no-padding">
                    <td>{{orderQuota.customer.name}}</td>
                    <td class="text-right colorValue">{{orderQuota.quota | number:'1.0-0'}}%</td>
                    <td class="text-right colorValue">
                        {{calculateQuotaValue(t.marinaOrder.value, orderQuota.quota) | number:'1.2-2'}}
                    </td>
                </tr>
            </ng-template>
        </p-table>
        </td>
        <td class="text-center">
            <span>
                <i class="pi pi-check" style="color: #155724" *ngIf="t.marinaOrder.orderStatus === 'Ordered'"></i>
            </span>
        </td>
        </tr>
        </ng-template>
        </p-table>
    </span>
</div>
