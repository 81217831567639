export class MenuItem {
    label: string;
    routerLink?: string;
    icon?: string;
    callFunction?: any;
    children?: MenuItem[];
    tooltip?: any;
    tooltipValue?: string;
    value?: any;
    styleclass?: string;
    id: number;
    parent?: number;
    queryParams?: any;
    application?: any;
    showChildren?: boolean;
    close?: boolean;
    // tslint:disable-next-line: no-unnecessary-initializer
    constructor(id = undefined, application = undefined) {
        this.id = id;
        this.application = application;
    }
}
