import { MessageUtil } from './message.util';

export class CalendarUtil {

    constructor(
        private messageUtil: MessageUtil
    ) {
    }

    public async getLocaleOptions(): Promise<{
        dayNames: string[],
        dayNamesShort: string[],
        dayNamesMin: string[],
        monthNames: string[],
        monthNamesShort: string[],
        today: string,
        clear: string
    }> {
        let promises: Promise<string>[] = [];
        promises = promises.concat([
            this.messageUtil.translateKeyAsync('CALENDAR.SUNDAY'),
            this.messageUtil.translateKeyAsync('CALENDAR.MONDAY'),
            this.messageUtil.translateKeyAsync('CALENDAR.TUESDAY'),
            this.messageUtil.translateKeyAsync('CALENDAR.WEDNESDAY'),
            this.messageUtil.translateKeyAsync('CALENDAR.THURSDAY'),
            this.messageUtil.translateKeyAsync('CALENDAR.FRIDAY'),
            this.messageUtil.translateKeyAsync('CALENDAR.SATURDAY'),
            this.messageUtil.translateKeyAsync('CALENDAR.JANUARY'),
            this.messageUtil.translateKeyAsync('CALENDAR.FEBRUARY'),
            this.messageUtil.translateKeyAsync('CALENDAR.MARCH'),
            this.messageUtil.translateKeyAsync('CALENDAR.APRIL'),
            this.messageUtil.translateKeyAsync('CALENDAR.MAY'),
            this.messageUtil.translateKeyAsync('CALENDAR.JUNE'),
            this.messageUtil.translateKeyAsync('CALENDAR.JULY'),
            this.messageUtil.translateKeyAsync('CALENDAR.AUGUST'),
            this.messageUtil.translateKeyAsync('CALENDAR.SEPTEMBER'),
            this.messageUtil.translateKeyAsync('CALENDAR.OCTOBER'),
            this.messageUtil.translateKeyAsync('CALENDAR.NOVEMBER'),
            this.messageUtil.translateKeyAsync('CALENDAR.DECEMBER'),
            this.messageUtil.translateKeyAsync('TODAY'),
            this.messageUtil.translateKeyAsync('CLEAR')
        ]);
        const result = await Promise.all(promises);
        return {
            dayNames: [
                result[0],
                result[1],
                result[2],
                result[3],
                result[4],
                result[5],
                result[6]
            ],
            dayNamesShort: [
                result[0].substring(0, 3),
                result[1].substring(0, 3),
                result[2].substring(0, 3),
                result[3].substring(0, 3),
                result[4].substring(0, 3),
                result[5].substring(0, 3),
                result[6].substring(0, 3)
            ],
            dayNamesMin: [
                result[0].substring(0, 2),
                result[1].substring(0, 2),
                result[2].substring(0, 2),
                result[3].substring(0, 2),
                result[4].substring(0, 2),
                result[5].substring(0, 2),
                result[6].substring(0, 2)
            ],
            monthNames: [
                result[7],
                result[8],
                result[9],
                result[10],
                result[11],
                result[12],
                result[13],
                result[14],
                result[15],
                result[16],
                result[17],
                result[18],
            ],
            monthNamesShort: [
                result[7].substring(0, 3),
                result[8].substring(0, 3),
                result[9].substring(0, 3),
                result[10].substring(0, 3),
                result[11].substring(0, 3),
                result[12].substring(0, 3),
                result[13].substring(0, 3),
                result[14].substring(0, 3),
                result[15].substring(0, 3),
                result[16].substring(0, 3),
                result[17].substring(0, 3),
                result[18].substring(0, 3),
            ],
            today: result[19],
            clear: result[20]
        };
    }
}
