<form class="filters" (submit)="find()" appFilters>
  <div class="filter-row margin-filter">

    <div class="form-control">
      <label for="groupIds">Fila</label>
      <app-multi-select-with-translate
        [options]="groupsOptions"
        [(ngModel)]="filter.groupIds"
        name="groupIds"
        [translateKey]="''">
      </app-multi-select-with-translate>
    </div>

    <div class="form-control">
      <label for="currentLocation">Local Atual</label>
      <app-multi-select-with-translate
        [options]="currentLocationOptions"
        [(ngModel)]="filter.currentLocationType"
        name="currentLocationType"
        [translateKey]="'MOVEMENT.LOCALES.'">
      </app-multi-select-with-translate>
    </div>

    <div class="form-control">
      <label for="destiny">Destino</label>
      <app-multi-select-with-translate
        [options]="destinyOptions"
        [(ngModel)]="filter.destinyType"
        name="destiny"
        [translateKey]="'MOVEMENT.LOCALES.'">
      </app-multi-select-with-translate>
    </div>

    <div class="form-control">
      <label for="movementStatus">Status da movimentação</label>
      <app-multi-select-with-translate
        [options]="movementStatusOptions"
        [(ngModel)]="filter.movementStatus"
        name="movementStatus"
        [translateKey]="''">
      </app-multi-select-with-translate>
    </div>

    <div class="form-control">
      <label for="highlightInteractions">Destacar interações</label>
      <p-dropdown [options]="highlightInteractions" name="highlightInteractions"
        appendTo="body" selectedItemsLabel="{0} itens selecionados" [(ngModel)]="filter.highlightInteractions">
      </p-dropdown>
    </div>
    <div class="form-control">
      <label>{{ 'BOAT' | translate }}</label>
      <p-dropdown class="field" placeholder="Selecione..." [filter]="true" [options]="boatsFilter" [resetFilterOnHide]="true"
                  appendTo="body" [(ngModel)]="filter.boatId" name="filter.boatId">
      </p-dropdown>
    </div>
    <div class="buttons">
      <div class="form-button">
          <button (click)="clear()" class="easymarine danger" type="button">{{ 'CLEAR' | translate }}</button>
      </div>
      <div class="form-button">
          <button class="easymarine primary" type="submit">{{ 'FIND' | translate }}</button>
      </div>
  </div>

  </div>
</form>
