import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OperatingDayConfigService } from 'src/app/services/config/operating-day-config.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-create-schedule-queue-modal',
  templateUrl: './create-schedule-queue-modal.component.html',
  styleUrls: ['./create-schedule-queue-modal.component.scss']
})
export class CreateScheduleQueueModalComponent {

  form: UntypedFormGroup;

  constructor(
    private readonly config: DynamicDialogConfig,
    private readonly dialogRef: DynamicDialogRef,
    private readonly operatingDayConfigService: OperatingDayConfigService,
    private toasty: ToastService
  ) {
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required, Validators.minLength(1)])
    })
  }

  create() {
    if (this.form.valid) {
      this.operatingDayConfigService.createScheduleQueue(this.form.value).subscribe(
        (data) => {
          this.dialogRef.close(data);
          this.toasty.success('Salvo com sucesso!');
        },
        (err) => {
          this.toasty.error('Ops, não foi possível criar um nova fila com esse nome.');
        }
      )
    }
  }
}
