import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectItem, MenuItem, LazyLoadEvent } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CustomReportExportComponent } from 'src/app/components/extract-custom-report/custom-report-export.component';
import { AccessPermission } from 'src/app/models/dtos/access-controller/accessPermission';
import { VisitorFilter } from 'src/app/models/dtos/access-controller/visitorFilter';
import { PaginationFilter } from 'src/app/models/pagination-filter';
import { AccessGroupService } from 'src/app/services/access-controler/access-group.service';
import { VisitorService } from 'src/app/services/access-controler/visitor.service';
import { CustomerService } from 'src/app/services/customer.service';
import { MessageUtil } from 'src/app/utils/message.util';

import { VisitorFormPermissionComponent } from '../service-provider-forms-container/visitor-form-permission/visitor-form-permission.component';
import { VisitorFormComponent } from '../service-provider-forms-container/visitor-form/visitor-form.component';
import { Visitor } from 'src/app/models/dtos/access-controller/visitor';
import { AccessGroupEnum } from 'src/app/models/dtos/access-controller/access-group-enum';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { VisitorFormHistoricComponent } from '../visitor-panel/visitor-form-historic/visitor-form-historic.component';
import { VisitorDTO } from 'src/app/models/dtos/access-controller/visitor-DTO';

@Component({
  selector: 'app-visitor',
  templateUrl: './visitor.component.html',
  styleUrls: ['./visitor.component.scss'],
})
export class VisitorComponent implements OnInit {
  @Input()
  visitorType: AccessGroupEnum = null;

  visitorList: VisitorDTO[] = [];
  filter: VisitorFilter = new VisitorFilter();

  pageable: PaginationFilter = new PaginationFilter();
  registerToPrint: any[];
  customerList: SelectItem[] = [];
  rangeCreateDates: Date[];
  rangeCancelDates: Date[];
  accessGroupList: SelectItem[] = [{ label: 'Todos', value: null }];

  contentLoaded = false;
  totalRecords = 0;
  numberOfRows = 100;
  menuItems: MenuItem[];
  selectedItem: Visitor;

  constructor(
    private accessGroupService: AccessGroupService,
    private customerService: CustomerService,
    private spinner: NgxSpinnerService,
    private messageUtil: MessageUtil,
    private visitorService: VisitorService,
    public dialog: DialogService
  ) {}

  ngOnInit() {
    this.clear();
    this.loadMenuItems();
    BroadcastService.get('reloadTables').subscribe(() => {
      this.getVisitorsByFilter();
    });
  }

  loadMenuItems() {
    this.menuItems = [
      {
        label: 'Editar',
        icon: 'pi pi-pencil',
        iconClass: 'green-menu-icon',
        command: () => this.openVisitorForm(this.selectedItem),
      },
      {
        label: 'Permissões',
        icon: 'pi pi-key',
        iconClass: 'green-menu-icon',
        command: () => this.openVisitorPermission(this.selectedItem),
      },
      {
        label: 'Histórico',
        icon: 'pi pi-book',
        iconClass: 'red-menu-icon',
        command: () => this.openHistoricForm(this.selectedItem),
      },
    ];
    this.accessGroupService.findAll().subscribe({
      next: (data) => {
        this.accessGroupList = data.map((group) => ({
          label: group.name,
          value: group.id,
        }));
        this.accessGroupList.unshift({ label: 'Todos', value: null });
        //TODO: FILTRAR PARA MOSTRAR APENAS OS GRUPOS DE VISITANTES
      },
    });
  }

  changeSelectedItem(visitor): void {
    this.selectedItem = visitor;
  }

  openVisitorForm(selectvisitor?: Visitor): void {
    if (selectvisitor != null) {
      this.visitorService.getById(selectvisitor?.id).subscribe({
        next: (visitor) => {
          this.dialog
            .open(VisitorFormComponent, {
              width: '1050px',
              height: '950px',
              header: 'Edição de Prestador de Serviço',
              data: {
                visitor: visitor,
                visitorType: this.visitorType,
              },
            })
            .onClose.subscribe(() => {
              BroadcastService.get('reloadTables').emit();
              this.getVisitorsByFilter();
            });
        },
        error: (error) => {
          console.log(error);
        },
      });
    }
  }

  getLastPermission(permissions: AccessPermission[]): AccessPermission {
    if (!permissions || permissions.length === 0) {
      return null;
    }

    let maxIdPermission: AccessPermission = permissions[0];

    for (const permission of permissions) {
      if (permission.id > maxIdPermission.id) {
        maxIdPermission = permission;
      }
    }

    return maxIdPermission;
  }

  openVisitorPermission(visitor?: Visitor): void {
    let accessGroupList = this.accessGroupList.filter(
      (group) => group.label !== 'Todos'
    );
    this.dialog
      .open(VisitorFormPermissionComponent, {
        width: '1400px',
        height: '1200px',
        header: 'Permissões de Acesso',
        data: {
          visitor: visitor,
          accessGroupList: accessGroupList,
          visitorType: this.visitorType,
        },
      })
      .onClose.subscribe(() => {
        BroadcastService.get('reloadTables').emit();
        this.getVisitorsByFilter();
      });
  }

  openHistoricForm(visitors?: Visitor): void {
    this.dialog
      .open(VisitorFormHistoricComponent, {
        width: '800px',
        height: '550px',
        header: 'Histórico de Utilização',
        data: {
          visitor: visitors,
        },
      })
      .onClose.subscribe(() => {});
  }

  async exportTable(): Promise<void> {
    await this.findDataExport();
    const data = { table: this.registerToPrint, type: 'VISITORS' };
    this.dialog.open(CustomReportExportComponent, {
      width: '70%',
      height: '90vh',
      dismissableMask: false,
      data,
      header: 'Prestadores de Serviço - Exportar Dados',
    });
  }

  async findDataExport(): Promise<void> {
    return new Promise(async (resolve) => {
      this.spinner.show();
      const filter = { ...this.filter };
      filter.pageSize = 1000000;
      filter.pageIndex = 0;

      this.visitorService.findByFilter(this.filter).subscribe(
        async (data: any) => {
          this.registerToPrint = data.content.map((visitor) => {
            return this.formatDataExport(visitor);
          });
          resolve();
        },
        async (error) => {
          this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(
            exception.type,
            exception.title,
            exception.message
          );
          resolve();
        },
        () => this.spinner.hide()
      );
    });
  }

  formatDataExport(visitor): any {
    visitor.status = visitor.active ? 'Ativo' : 'Inativo';

    return {
      id: visitor.id,
      name: visitor.name,
      federalId: visitor.federalId,
      email: visitor.email,
      birthday: visitor.birthday,
      createdAt: visitor.createdAt,
      phone: visitor.phone,
      status: visitor.status,
      residentialZipCode: visitor.residentialAddress.zip,
      residentialCountry: visitor.residentialAddress.country,
      residentialDistrict: visitor.residentialAddress.district,
      residentialCity: visitor.residentialAddress.city,
      residentialState: visitor.residentialAddress.state,
      residentialAddress: visitor.residentialAddress.address,
      residentialNumber: visitor.residentialAddress.number,
      residentialComplement: visitor.residentialAddress.complement,
    };
  }

  getVisitorsByFilter(): void {
    this.filter.serviceProvider =
      this.visitorType === AccessGroupEnum.SERVICE_PROVIDER ? true : false;

    this.visitorService.findByFilter(this.filter).subscribe({
      next: (data) => {
        this.visitorList = data.content;
        this.totalRecords = data.totalElements;
      },
      error: (error) => {
        console.log(error);
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
    });
  }

  getCustomers(): void {
    this.customerService.getAll('id,name').subscribe({
      next: (data) => {
        this.customerList = [{ label: 'Todos', value: null }].concat(
          data.map((c) => ({ label: c.name, value: c.id }))
        );
      },
      error: (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
    });
  }

  clear(): void {
    this.getCustomers();
    this.filter = new VisitorFilter();
    this.filter.serviceProvider =
      this.visitorType === AccessGroupEnum.SERVICE_PROVIDER ? true : false;
    this.rangeCreateDates = [];
    this.rangeCancelDates = [];
    this.getVisitorsByFilter();
  }

  onChangePage(event: LazyLoadEvent): void {
    const page = event.first / event.rows;
    if (event.multiSortMeta) {
      this.pageable.sort = event.multiSortMeta[0].field;
      this.pageable.order = event.multiSortMeta[0].order === 1 ? 'ASC' : 'DESC';
    }
    this.pageable.page = page;
    this.pageable.size = event.rows;
    this.getVisitorsByFilter();
  }

  onChangeCadastro() {
    if (this.rangeCreateDates) {
      if (this.rangeCreateDates[0] != null) {
        this.filter.permissionStartDate = this.rangeCreateDates[0];
        if (this.rangeCreateDates[1] != null) {
          this.filter.permissionEndDate = this.rangeCreateDates[1];
        } else {
          this.filter.permissionEndDate = this.rangeCreateDates[0];
        }
      } else {
        this.filter.permissionStartDate = null;
        this.filter.permissionEndDate = null;
      }
    }
  }

  hasIncompleteVisitorData(visitor: VisitorDTO): boolean {
    if (!visitor.photoUrl) {
      return true;
    }
    if (
      !visitor.residentialAddressId == null &&
      this.visitorType === AccessGroupEnum.SERVICE_PROVIDER
    ) {
      return true;
    }
    return false;
  }
}
