import { Component, OnInit } from '@angular/core';
import { SlingConfig } from 'src/app/models';
import { SlingConfigService } from 'src/app/services/sling-config.service';

@Component({
  selector: 'app-service-readjustment',
  templateUrl: './service-readjustment.component.html',
  styleUrls: ['./service-readjustment.component.scss']
})
export class ServiceReadjustmentComponent implements OnInit {

  selectTab = 0;

  slingConfig: SlingConfig
  
  constructor(
    private slingConfigService: SlingConfigService
   ) { }


  async ngOnInit(): Promise<void> {
    await this.loadSlingConfig();
  }
  
  onChangeSelectTab($event) {
    this.selectTab = $event.index;
  }

  async loadSlingConfig(): Promise<void> {
    return new Promise<void>(
      async (res) => {
        this.slingConfigService.getSlingConfig().subscribe(
          async (data) => {
            this.slingConfig = data[0];
            res();
          },
          async () => res()
        );
      }
    );
  }

}
