import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FilterBoatMovement } from 'src/app/models/dtos/movements/filter-boat-movement';
import { MovementStatus } from 'src/app/models/enums/movements/movement-status';
import { MessageUtil } from 'src/app/utils/message.util';
import { MovementLocationType } from 'src/app/models/enums/movements/movement-location-type';
import { MovementGroupService } from 'src/app/services/movements/movement-group.service';
import { SelectLabelValue } from 'src/app/models/select-label-value';
import {BoatService} from '../../../../../services/boat.service';
import {SelectItem} from 'primeng/api';

@Component({
  selector: 'app-adjustment-view',
  templateUrl: './adjustment-view.component.html',
  styleUrls: ['./adjustment-view.component.scss']
})
export class AdjustmentViewComponent implements OnInit {


  filter: FilterBoatMovement = {
    groupIds: null,
    currentLocationType: [],
    destinyType: [],
    movementStatus: [],
    highlightInteractions: 0,
    boatId: null
  };

  movementStatusOptions: any[];
  currentLocationOptions: any[];
  groupsOptions: SelectLabelValue[];
  destinyOptions: any[];
  highlightInteractions: SelectLabelValue[];
  boatsFilter: SelectItem[] = [];
  @Output() changeEvent: EventEmitter<FilterBoatMovement> = new EventEmitter();

  constructor(
    private messageUtil: MessageUtil,
    private movementGroupService: MovementGroupService,
    private boatService: BoatService
  ) { }

  ngOnInit(): void {
    this.getMovementGroups();
    this.movementStatusOptions = Object.keys(MovementStatus).map(item => ({ label: item, value: item }));
    this.currentLocationOptions = Object.keys(MovementLocationType).map(item => ({ label: item, value: item }));
    this.destinyOptions = Object.keys(MovementLocationType).map(item => ({ label: item, value: item }));
    this.highlightInteractions = [
      {
        label: 'Não destacar',
        value: 1
      },
      {
        label: 'Destacar ultima',
        value: 2
      },
      {
        label: 'Destacar todas',
        value: 3
      }
    ];
    this.preSelectedFilter();
    this.loadBoat();
  }

  preSelectedFilter(): void {
    const highlightLast = 2;
    this.filter.highlightInteractions = highlightLast;
    this.find();
  }

  find(): void {
    this.changeEvent.emit({...this.filter});
  }

  clear(): void {
    this.filter = {
      groupIds: null,
      currentLocationType: [],
      destinyType: [],
      movementStatus: [],
      highlightInteractions: 0,
      boatId: null
    }
  }

  getMovementGroups(): Promise<void> {
    return new Promise(resolve => {
      this.movementGroupService.getAll().subscribe(
        data => {
          this.groupsOptions = data.filter( group => group.active).map(group => ({label: group.name, value: group.id}));
          resolve();
        },
        error => {
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
          resolve();
        }
      )
    })
  }
  loadBoat(): void {
    this.boatService.getBoatFilterListWithCustomer().subscribe((data) => {
      // data = data.filter((boat) => boat.active);
      this.boatsFilter = data.map((b) => ({
        label: b.name,
        value: b.id,
      }));
      this.boatsFilter.unshift({ label: 'Todos', value: null });
    });
  }
}
