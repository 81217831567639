import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { BoatLogModalComponent } from 'src/app/pages/logs/boat-log/boat-log-modal/boat-log-modal.component';
import { BoatLogService } from 'src/app/services/boat-log.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';
import {ServiceBoat} from '../../../../../models';

@Component({
  selector: 'app-service-boat-history',
  templateUrl: './service-boat-history.component.html',
  styleUrls: ['./service-boat-history.component.scss']
})
export class ServiceBoatHistoryComponent implements OnInit {


  @Input() servicesBoatHistory: ServiceBoat[];
  constructor(
    private boatLogService: BoatLogService,
    private messageUtil: MessageUtil,
    public dialog: DialogService,
  ) { }

  ngOnInit(): void {
  }

  getLogServiceBoat(boatService){

    this.boatLogService.findExclusionServiceBoatId(boatService.id, StorageUtil.getMarinaId()).subscribe(
      data => {
        if(data) {
          this.openBoatLogDialog(data);
        } else {
          this.showMessageNotFound();
        }
      },
      error => {
        this.showMessageNotFound();
      }
    )
  }

  openBoatLogDialog(boatLog): void {
    this.dialog.open(BoatLogModalComponent, {
      height: '85vh',
      width: '80%',
      dismissableMask: false,
      data: {
        boatLog
      },
      header: 'Log de Embarcação'
    })
  }

  showMessageNotFound(){
    this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', 'Não foi possivel encontrar o log da exclusão deste serviço.');
  }

  calcTotalService(service): number {
    return service.value - service.discount;
  }

}
