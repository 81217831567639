import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { DocumentDefaultSigner } from "../models/document-default-signer";

@Injectable()
export class DocumentDefaultSignerService {

    private defaultSignerUrl = environment.apiHost + '/secure/document-default-signer';

    constructor(
        private http: HttpClient
    ) { }

    getDefaultSigners(): Observable<DocumentDefaultSigner[]> {
        return this.http.get(this.defaultSignerUrl)
            .pipe(map((response: any) => response.data.defaultSigners as DocumentDefaultSigner[]));
    }

    getDefaultSignersTypeMarina(): Observable<DocumentDefaultSigner[]> {
        return this.http.get(this.defaultSignerUrl+ '/type-marina')
            .pipe(map((response: any) => response.data.defaultSigners as DocumentDefaultSigner[]));
    }

    save(signer: DocumentDefaultSigner): Observable<DocumentDefaultSigner> {
        return this.http.post(this.defaultSignerUrl, signer)
            .pipe(map((response: any) => response.data.defaultSigner as DocumentDefaultSigner));
    }

    delete(id: number): Observable<any[]> {
        return this.http.delete(`${this.defaultSignerUrl}/${id}`)
            .pipe(map((response: any) => response));
    }
}