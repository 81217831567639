import { Injectable } from '@angular/core';
import { APPCONFIG } from '../config';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MenuItem } from '../models/menu-item';
import { map } from 'rxjs/operators';
@Injectable({providedIn: 'root'})
export class MenuItemService {
  private readonly NAVBAR_KEY = 'navbarItems';
  constructor(private http: HttpClient) {
  }

  getAllStructured(): Observable<MenuItem[]> {
    const storedMenuItems: MenuItensStored = this.getNavbarItems();
    if (storedMenuItems) {
      const expirationTime = new Date(storedMenuItems.expirationTime);
      if (expirationTime > new Date()) {
        return new Observable<MenuItem[]>((observer) => {
          observer.next(storedMenuItems.menuItems);
          observer.complete();
        });
      }
    }
    return this.http.get(APPCONFIG.apiHost + '/secure/menu/structure/')
      .pipe(map((response: any) => {
        const newMenuStructure = response.data.menuItens as MenuItem[];
        const expirationTime = new Date();
        expirationTime.setHours(expirationTime.getHours() + 12);
        this.setNavbarItems({menuItems: newMenuStructure, expirationTime: expirationTime});
        return newMenuStructure;
      }));
  }

  getAllStructuredByAplication(origin: any): Observable<MenuItem[]> {
    return this.http.get(APPCONFIG.apiHost + '/secure/menu/application-structure/' + origin).pipe(
      map((response: any) => response.data.menuItens as MenuItem[]));
  }

  findRouterLinkAccessByUser(): Observable<string[]> {
    return this.http.get(APPCONFIG.apiHost + '/secure/menu/router-link-access').pipe(
      map((response: any) =>  response.data.menuItens as string[]));
  }
  getNavbarItems(): MenuItensStored | null {
    const storedItems = localStorage.getItem(this.NAVBAR_KEY);
    return storedItems ? JSON.parse(storedItems) : null;
  }
  setNavbarItems(item: MenuItensStored): void {
    localStorage.setItem(this.NAVBAR_KEY, JSON.stringify(item));
  }
}

class MenuItensStored{
  menuItems: MenuItem[];
  expirationTime: Date;
}
