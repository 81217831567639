import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Product } from '../models';
import { ProductCategoryType, ProductType } from '../models/enums';
import { OrderControl } from '../models/enums/order-control';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProductFilterListDTO } from '../models/dtos/productFilterListDTO';
import { MarinaCompany } from '../models/marina-company';
import { ProductMovement } from '../models/product-movement';
import { ProductMovementFilter } from '../models/product-movement-filter';
import dayjs from 'dayjs';
import { ProductFilter } from '../models/dtos/productFilter';
import { ProductCfop } from '../models/ProductCfop';
import { ProductCodes } from '../models/product-codes';
import { ServiceLinkedBoats } from '../models/dtos/service-linked-boats';
import { ServiceHistoricFilter } from '../models/dtos/marinaorder/serviceHistoricFilter';
import {ServiceHistoricDTO} from "../models/dtos/service-historic-productDTO";

@Injectable()
export class ProductService {

  getAllByFilter(productFilter: ProductFilter): Observable<Product[]> {
    return this.http
      .post(environment.apiHost + '/secure/products/filter', productFilter)
      .pipe(map((response: any) => response.data.products as Product[]));
  }


  getByProductTypeAndProductCategoryTypeNot(
    productType: ProductType,
    productCategoryType: ProductCategoryType
  ): Observable<Product[]> {
    return this.http
      .get(
        environment.apiHost +
          '/secure/product/product-type-and-product-category-type/not/?productType=' +
          productType +
          '&productCategoryType=' +
          productCategoryType
      )
      .pipe(map((response: any) => response.data.products as Product[]));
  }

  constructor(private http: HttpClient) {}

  getAll(): Observable<Product[]> {
    return this.http
      .get(environment.apiHost + '/secure/products/')
      .pipe(map((response: any) => response.data.products as Product[]));
  }

  getProductFilterList(): Observable<ProductFilterListDTO[]> {
    return this.http
      .get(environment.apiHost + '/secure/products/filter-list/')
      .pipe(
        map((response: any) => response.data.products as ProductFilterListDTO[])
      );
  }

  getPeriodicalServicePriceFreeInUseFilterList(): Observable<
    ProductFilterListDTO[]
  > {
    return this.http
      .get(
        environment.apiHost +
          '/secure/products/periodical-services-in-use/price-free'
      )
      .pipe(
        map(
          (response: any) =>
            response.data.periodicalServicesInUse as ProductFilterListDTO[]
        )
      );
  }

  getBoatsFromService(serviceId: number): Observable<ServiceLinkedBoats[]>{
    return this.http.get<ServiceLinkedBoats[]>(environment.apiHost + '/secure/products/' + serviceId + '/boats').pipe(
      map((res: any) => res.data.boats as ServiceLinkedBoats[])
    );
  }

  exportProductHistoryPDF(dataInicial: Date, dataFinal: Date): Observable<any> {
    let params = new HttpParams();
    params = params.set('dataInicial', dayjs(dataInicial).format('YYYY-MM-DD'));
    params = params.set('dataFinal', dayjs(dataFinal).format('YYYY-MM-DD'));

    return this.http.get(environment.apiHost + '/secure/products/order-movement/report', {params});
  }

  getPeriodicalServiceNotPriceFreeInUse(): Observable<Product[]> {
    return this.http
      .get(
        environment.apiHost +
          '/secure/products/periodical-services-in-use/not-price-free'
      )
      .pipe(
        map(
          (response: any) => response.data.periodicalServicesInUse as Product[]
        )
      );
  }

  getPeriodicalServiceInUse(): Observable<ProductFilterListDTO[]> {
    return this.http
      .get(environment.apiHost + '/secure/products/periodical-services-in-use')
      .pipe(
        map(
          (response: any) =>
            response.data.periodicalServicesInUse as ProductFilterListDTO[]
        )
      );
  }

  getProductFilterListNotPriceFree(): Observable<ProductFilterListDTO[]> {
    return this.http
      .get(environment.apiHost + '/secure/products/filter-list/not-price-free')
      .pipe(
        map((response: any) => response.data.products as ProductFilterListDTO[])
      );
  }

  getAllByOrderControl(
    orderControl: OrderControl,
    marinaCompany: MarinaCompany | null = undefined
  ): Observable<Product[]> {
    let params = new HttpParams();
    if (marinaCompany !== undefined && marinaCompany !== null) {
      params = params.set('marinaCompanyId', `${marinaCompany.id}`);
    }
    return this.http
      .get(
        environment.apiHost + '/secure/products/order-control/' + orderControl,
        { params }
      )
      .pipe(map((response: any) => response as Product[]));
  }

  getByProductType(productType: ProductType): Observable<Product[]> {
    return this.http
      .get(environment.apiHost + '/secure/product/product-type/' + productType)
      .pipe(map((response: any) => response.data.products as Product[]));
  }

  getByProductCategoryType(productCategoryType: any): Observable<Product[]> {
    return this.http
      .get(
        environment.apiHost +
          '/secure/product/product-category-type/' +
          productCategoryType
      )
      .pipe(map((response: any) => response.data.products as Product[]));
  }

  getByProductCategoryTypeNot(productCategoryType: any): Observable<Product[]> {
    return this.http
      .get(
        environment.apiHost +
          '/secure/product/product-category-type/not/' +
          productCategoryType
      )
      .pipe(map((response: any) => response.data.products as Product[]));
  }

  getById(id: number): Observable<Product> {
    return this.http
      .get(environment.apiHost + '/secure/product/' + id)
      .pipe(map((response: any) => response.data.product));
  }

  getByProductTypes(productTypes: string[]): Observable<Product[]> {
    return this.http
      .get(`${environment.apiHost}/secure/product/product-types/${productTypes.toString()}`)
      .pipe(map((response: any) => response.data.products as Product[]));
  }

  create(product: Product): Observable<Product> {
    return this.http
      .post(environment.apiHost + '/secure/product/', product)
      .pipe(map((response: any) => response.data.product));
  }

  update(product: Product): Observable<Product> {
    return this.http
      .put(environment.apiHost + '/secure/product/', product)
      .pipe(map((response: any) => response.data.product));
  }

  updateStatus(productId: number, active: boolean): Observable<any> {
    return this.http
      .put(
        environment.apiHost + `/secure/product/update-status/${productId}`,
        active
      )
      .pipe(map((response: any) => response));
  }

  balanceAdjustment(productsAdjustment: any[]): Observable<any> {
    return this.http
      .put(
        environment.apiHost + '/secure/product/balance-adjustment',
        productsAdjustment
      )
      .pipe(map((response: any) => response));
  }

  getImageProduct(codBar: string): Observable<any> {
    return this.http
      .get('https://api-products.easymarine.com.br/products/' + codBar)
      .pipe(map((response: any) => response));
  }

  delete(id: number): Observable<Product> {
    return this.http
      .delete(environment.apiHost + '/secure/product/' + id)
      .pipe(map((response: any) => response.data.product));
  }

  getProductsCodes(productId: number): Observable<ProductCodes[]>{
    return this.http.get<ProductCodes[]>(`${environment.apiHost}/secure/products/codes/${productId}`).pipe(map((response: any) => response.data.codes as ProductCodes[]));
  }

  uploadImage(files): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('attachment', files[0]);

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'multipart/form-data');

    return this.http
      .post(
        environment.apiHost + '/secure/products/file/attachment',
        formData,
        { headers }
      )
      .pipe(map((response: any) => response.data.attachment));
  }

    getMovementHistory(filter: ProductMovementFilter): Observable<ProductMovement[]>{
        let params = new HttpParams();

        Object.keys(filter).forEach((key) => {
            if (filter[key] != null && key !== 'vacanciesList') {
                if (filter[key] instanceof Date) {
                    params = params.set(key, dayjs(filter[key]).format('YYYY-MM-DD'));
                } else {
                    params = params.set(key, filter[key]);
                }
            }
        });

        return this.http.get(`${environment.apiHost}/secure/products/order-movement`, {params}).pipe(map((response: any) => response.data.products as ProductMovement[]));
    }

    getCfopOptions(): Observable<ProductCfop[]> {
        return this.http.get(`${environment.apiHost}/secure/products/cfop`).pipe(map((response: any) => response.data.cfops as ProductCfop[]));
    }

    changeStatus(id: number ,active: boolean ): Observable<any> {
     return  this.http.get(`${environment.apiHost}/secure/products/change-status/${id}/${active}`).pipe(map((response: any) => response.data.products ));
    }

    getProductHistoric(filter:ServiceHistoricFilter):Observable<ServiceHistoricDTO[]>{

      return this.http.post(`${environment.apiHost}/api-orders/secure/marina-order/historic-products`,filter
      ).pipe(map((response: any) => response ));
    }

}
