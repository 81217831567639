export class InvoicingReportFilter {

  customerId?: number[];
  boatId?: number[];
  productTypes?: string[];
  invoiceStatus?: string[];
  invoiceOptions?: string;
  productId?: number[];
  issueFrom?: Date;
  issueTo?: Date;
  dueFrom?: Date;
  dueTo?: Date;
  paymentFrom?: Date;
  paymentTo?: Date;
  competenceFrom?: Date;
  competenceTo?: Date;
  nfeNumber?: string;
  invoiceNumber?: string;

  constructor() {
  }

}
