import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SlingConfig } from 'src/app/models';
import { SystemcallAuxiliarTablesService } from 'src/app/services/systemcall-auxiliar-tables.service';

@Component({
  selector: 'app-create-ticket',
  templateUrl: './create-ticket.component.html',
  styleUrls: ['./create-ticket.component.scss'],
})
export class CreateTicketComponent implements OnInit {
  requesters: any[] = [];
  //{label: 'user 1', value: '1'}, {label: 'user 2', value: '2'}, {label: 'user 3', value: '3'}];
  applications: any[] = [];
  //{label: 'application 1', value: '1'}, {label: 'application 2', value: '2'}, {label: 'application 3', value: '3'}];
  abas: any[] = [];
  //{label: 'aba 1', value: '1'}, {label: 'aba 2', value: '2'}, {label: 'aba 3', value: '3'}];
  screens: any[] = [];
  //{label: 'screen 1', value: '1'}, {label: 'screen 2', value: '2'}, {label: 'screen 3', value: '3'}];

  ticketForm: UntypedFormGroup;
  marinaCall: any;

  @Output()
  finishEvent: EventEmitter<UntypedFormGroup> = new EventEmitter();

  @Output()
  getMarina: EventEmitter<number> = new EventEmitter();

  @Output()
  getScreen: EventEmitter<any> = new EventEmitter();


  @Input()
  slingConfig: SlingConfig;

  constructor(
    private systemcallAuxiliarTablesService: SystemcallAuxiliarTablesService
  ) {}

  ngOnInit(): void {
    this.startForm();
    this.getSistemas();
    this.getMarinaDadosCall(this.slingConfig.marina.companyName);
    this.requesters.unshift({ label: 'Selecione', value: null });
    this.applications.unshift({ label: 'Selecione', value: null });
  }

  startForm(): void {
    this.ticketForm = new UntypedFormGroup({
      user: new UntypedFormControl(
        this.slingConfig.marina.companyName,
        Validators.required
      ),
      requester: new UntypedFormControl('', Validators.required),
      application: new UntypedFormControl('', Validators.required),
      aba: new UntypedFormControl('', Validators.required),
      screen: new UntypedFormControl('', Validators.required),
    });
  }

  getscreen(grup:any):void{
    this.getScreen.emit(grup);
  }

  save(): void {
    this.ticketForm.valid
      ? this.finishEvent.emit(this.ticketForm)
      : this.ticketForm.markAllAsTouched();
  }

  getSistemas(): void {
    this.systemcallAuxiliarTablesService
      .getSistemas()
      .subscribe((response: any) => {
        this.applications = [];
        this.applications.unshift({ label: 'Selecione', value: null });
        response.forEach((element) => {
          this.applications.push(element);
        });
      });
  }

  getGrupos(idSistema: any): void {
    this.systemcallAuxiliarTablesService
      .getGrupos(idSistema)
      .subscribe((response: any) => {
        this.abas = [];
        this.abas.unshift({ label: 'Selecione', value: null });
        response.forEach((element) => {
          this.abas.push(element);
        });
      });
  }

  getSubGrupos(idGrupo: any): void {
    this.systemcallAuxiliarTablesService
      .getSubGrupos(idGrupo)
      .subscribe((response: any) => {
        this.screens = [];
        this.screens.unshift({ label: 'Selecione', value: null });
        response.forEach((element) => {
          this.screens.push(element);
        });
      });
  }

  getMarinaDadosCall(name: string): void {
    this.systemcallAuxiliarTablesService
      .getMarina(name)
      .subscribe((response: any) => {
        this.marinaCall = response[0];
        this.getMarina.emit(this.marinaCall.value);
        this.getSolicitantes(this.marinaCall.value);
      });
  }

  getSolicitantes(idMarinaCall: any): void {
    //console.log(idMarinaCall);
    this.systemcallAuxiliarTablesService
      .getSolicitantes(idMarinaCall)
      .subscribe((response: any) => {
        this.requesters = [];
        this.requesters.unshift({ label: 'Selecione', value: null });
        response.forEach((element) => {
          this.requesters.push(element);
        });
      });
  }
}
