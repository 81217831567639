<div class="table">
    <p-table #tt [value]="ordersLog" [resizableColumns]="true" [autoLayout]="true" [paginator]="true"
        [rows]="numberOfRows" resetPageOnSort="true" [pageLinks]="3"
        [rowsPerPageOptions]="isDialog? null : [10,20,30,50,100]">
        <ng-template pTemplate="header">
            <tr>
                <th>Lingada</th>
                <th>Pedido</th>
                <th>Cliente</th>
                <th>Usuário</th>
                <th>Responsável</th>
                <th>Origem</th>
                <th>Origem Ped.</th>
                <th>Data/hora</th>
                <th>Status</th>
                <th>Valor</th>
                <th>Ação</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-orderLog>
            <tr>
                <td>{{orderLog.slingId}}</td>
                <td style="cursor: pointer;" (click)="orderLog.show = !orderLog.show">{{orderLog.orderNumber}}</td>
                <td>{{orderLog.customerName}}</td>
                <td [pTooltip]="orderLog.user ? orderLog.user.fullName : ''" tooltipPosition="top">{{orderLog.user ?
                    orderLog.user.username : ''}}</td>
                <td>{{orderLog?.lastEmployeeName}}</td>
                <td>{{orderLog.origin}}</td>
                <td>{{orderLog.orderOrigin.toUpperCase() | translate}}</td>
                <td>{{orderLog.logTime | date: 'dd/MM/yyyy - HH:mm'}}</td>
                <td>{{orderLog.orderStatus.toUpperCase() | translate}}</td>
                <td>{{orderLog.value | number: '1.2-2'}}</td>
                <td>{{orderLog.operationLog | translate}}</td>
            </tr>
            <tr [hidden]="!orderLog.show">
                <td colspan="9">
                    <p-table [value]="orderLog.orderItems">
                        <ng-template pTemplate="header">
            <tr>
                <th>Id item</th>
                <th>Solicitado</th>
                <th>Atendido</th>
                <th>Valor</th>
                <th>Id produto</th>
                <th>Produto</th>
                <th>Valor produto</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr>
                <td>{{item.itemId}}</td>
                <td *ngIf="!item.fuelTankFraction">{{item.qtyRequested | number: '1.2-2'}}</td>
                <td *ngIf="item.fuelTankFraction">{{getLabelFraction(item.fuelTankFraction)}}</td>
                <td>{{item.qtySupplied | number: '1.3'}}</td>
                <td>{{item.itemValue | number: '1.2-2'}}</td>
                <td>{{item.product.productId}}</td>
                <td>{{item.product.name}}</td>
                <td>{{item.product.value | number: '1.2-2'}}</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="7">
                    Nenhum log item encontrado
                </td>
            </tr>
        </ng-template>
    </p-table>
    </td>
    </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="9">
                Nenhum log encontrado
            </td>
        </tr>
    </ng-template>
    </p-table>
</div>