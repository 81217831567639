<p-fieldset  toggleable=true  collapsed=false>
    <p-header class="article-title">
        <div class="article-title">{{'Reajustes' | translate}}</div>
    </p-header>
    <div class="table" >
        <p-table [value]="readjustements" [paginator]="true"
            [rows]="5" [alwaysShowPaginator]="false">
            <ng-template pTemplate="header">
                <tr>
                    <th class="text-center">{{ 'DATE' | translate }}</th>
                    <th class="text-center">{{ 'USERNAME' | translate }}</th>
                    <th class="text-center">{{ 'PERCENTAGE' | translate }}</th>
                    <th class="text-center">{{ 'READJUSTMENT.READJUSTMENT-TYPE' | translate }}</th>
                    <!-- <th></th> -->
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-readjustment>
                <tr>
                    <td class="text-center">{{readjustment.date | date: 'dd/MM/yyyy - HH:mm'}}</td>
                    <td class="text-center">{{readjustment.username }}</td>
                    <td class="text-center">{{readjustment.percentage/100 | percent: '1.0-2'}}</td>
                    <td class="text-center">{{('READJUSTMENT.'+readjustment.readjustmentType) | translate }}</td>
                    <td class="text-center">
                        <i class="table-action" pTooltip="Visulizar log de embarcações"
                            (click)="goToLogIn(readjustment.date)" tooltipPosition="bottom">
                            <i class="pi pi-eye"></i>
                        </i>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-fieldset>
<br>