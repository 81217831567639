import { MovementPlanViewDTO } from './../../../../../../models/dtos/movements/view-location/movment-plan-view-dto';
import { MovementLocationType } from './../../../../../../models/enums/movements/movement-location-type';
import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MovementPlanService } from 'src/app/services/movements/movement-plan.service';
import { MovementPlanViewLocationDTO } from 'src/app/models/dtos/movements/view-location/movement-plan-view-location-dto';

@Component({
  selector: 'app-movement-plain-view',
  templateUrl: './movement-plain-view.component.html',
  styleUrls: ['./movement-plain-view.component.scss']
})
export class MovementPlainViewComponent {

  movementPlanId: number;

  items: MovementPlanViewLocationDTO[]= [];
  dataView: MovementPlanViewDTO;

  constructor(
    public dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private movementPlanService: MovementPlanService
  ) {
    if (this.config.data?.movement) {
      this.movementPlanId = this.config.data?.movement.movementPlanId;
      this.getLocations(this.movementPlanId);
    } else {
      this.close();
    }
  }

  close() {
    this.dialogRef.close();
  }

  getIcon(index) {
    if (this.items[index].type === MovementLocationType.DRY_VACANCY || this.items[index].type === MovementLocationType.WET_VACANCY) {
      return 'assets/images/movements/movement-location/vacanancy-white.png';
    }
    if (this.items[index].type === MovementLocationType.NAVIGATION) {
      return 'assets/images/movements/movement-location/sea-white.png';
    }
    if (this.items[index].type === MovementLocationType.YARD) {
      return 'assets/images/movements/movement-location/yard-white.png';
    }
  }

  getLocations(movementPlanId: number) {
    this.movementPlanService.getMovementPlanLocations(movementPlanId).subscribe((data) => {
      this.dataView = data;
      this.items = data.locations;
    })
  }
}
