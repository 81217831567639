export class LicenseFiles{
    id: number;
    name: string;
    path: string;

    constructor(){
        this.id = null;
        this.path = null;
        this.name = null;
    }
}