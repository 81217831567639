import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectItem } from 'primeng/api';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { SecuritiesPropertyFilter } from 'src/app/models/dtos/securities-property/securities-property-filter';
import { PaginationFilter } from 'src/app/models/pagination-filter';
import { SecuritiesProperty } from 'src/app/models/securities-property/securities-property';
import { SecuritiesPropertyHistoric } from 'src/app/models/securities-property/securities-property-historic';
import { CustomerService } from 'src/app/services/customer.service';
import { SecuritiesPropertyService } from 'src/app/services/securities-property/securities-property.service';
import { MessageUtil } from 'src/app/utils/message.util';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-securities-property-edit',
  templateUrl: './securities-property-edit.component.html',
  styleUrls: ['./securities-property-edit.component.scss'],
})
export class SecuritiesPropertyEditComponent implements OnInit {
  securitiesProperty: SecuritiesProperty = new SecuritiesProperty();
  filter: SecuritiesPropertyFilter = new SecuritiesPropertyFilter();
  pageable: PaginationFilter = new PaginationFilter();
  customerList: any[] = [];
  blocktypes: SelectItem[] = [
    { label: 'Judicial', value: 'JUDICAL' },
    { label: 'Administrativo', value: 'ADMINISTRATIVE' },
    { label: 'Voluntário', value: 'VOLUNTARY' },
    { label: 'Outro', value: 'OTHER' },
  ];

  listHistoric: SecuritiesPropertyHistoric[] = [];

  isNew : boolean = false;

  constructor(
    public dialog: DynamicDialogRef,
    private spinner: NgxSpinnerService,
    private messageUtil: MessageUtil,
    private securitiesPropertyService: SecuritiesPropertyService,
    private config: DynamicDialogConfig,
    private customerService: CustomerService
  ) {}

  ngOnInit() {
    this.securitiesProperty = this.config.data.securitiesProperty;
    this.filter.id = this.securitiesProperty.id;
    this.getSecuritirsByFilter();
    this.getCustomers();
    this.getHistoric();
  }

  getCustomerName(event): void {
    this.securitiesProperty.currentCustomerName= this.customerList.find((c) => c.value === event).label;
    this.securitiesProperty.currentCustomerPhone= this.customerList.find((c) => c.value === event).phone;

  }

  getHistoric(): void {
    this.securitiesPropertyService
      .getHistoric(this.securitiesProperty.id)
      .subscribe({
        next: (data) => {
          this.listHistoric = data;
        },
        error: (error) => {
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(
            exception.type,
            exception.title,
            exception.message
          );
        },
      });
  }

  getCustomers(): void {
    this.customerService.getAll('id,name,phone').subscribe({
      next: (data) => {
        this.customerList = [{ label: 'Selecione', value: null }].concat(
          data.map((c) => ({ label: c.name, value: c.id, phone: c.phone }))
        );
      },
      error: (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
    });
  }

  getSecuritirsByFilter(): void {
    this.securitiesPropertyService
      .getAllByFilter(this.filter, this.pageable)
      .subscribe({
        next: (data) => {
          this.securitiesProperty = data.content[0];
          if (this.securitiesProperty.currentCustomerId === null) {
            this.isNew = true;
          }else{
            this.securitiesProperty.lastAssociation = new Date(data.content[0].lastAssociation);
          }
        },
        error: (error) => {
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(
            exception.type,
            exception.title,
            exception.message
          );
        },
      });
  }

  cancelProp(): void {
    Swal.fire({
      title: 'Deseja realmente cancelar o título de propriedade?',
      text: 'Essa ação não poderá ser desfeita!\nInforme a data de cancelamento:',
      icon: 'warning',
      html: '<input id="swal-input1" class="swal2-input" type="date">',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, cancelar!',
      cancelButtonText: 'Voltar',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        const cancelDateStr = (
          document.getElementById('swal-input1') as HTMLInputElement
        ).value;
        const cancelDate = new Date(cancelDateStr + 'T12:00:00Z');
        this.securitiesProperty.lastAssociation = cancelDate;
        this.securitiesProperty.currentCustomerId = null;
        this.securitiesProperty.currentCustomerName = null;
        this.securitiesProperty.currentCustomerPhone = null;
        this.isNew = true;
      }
    });
  }

  ifCustomer(): boolean {
    return this.securitiesProperty.currentCustomerId != null;
  }

  save(): void {
    this.spinner.show();
    if (this.securitiesProperty.currentCustomerId != null) {
      if (this.securitiesProperty.lastAssociation === null) {
       this.messageUtil.generateMessage(
          'error',
          'ERROR',
          'Escolha a data de associação do título de propriedade!'
        );
        this.spinner.hide();
        return;
      }
      this.securitiesProperty.currentCustomerName = this.customerList.find(
        (c) => c.value === this.securitiesProperty.currentCustomerId
      ).label;
      this.securitiesProperty.currentCustomerPhone = this.customerList.find(
        (c) => c.value === this.securitiesProperty.currentCustomerId
      ).phone;
    }
    this.securitiesPropertyService.save(this.securitiesProperty).subscribe({
      next: (data) => {
        this.spinner.hide();
        this.messageUtil.generateMessage(
          'success',
          'SUMMARY.SUCCESS',
          'Alteração realizada com sucesso!'
        );
        this.dialog.close(null);
      },
      error: (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
    });
  }
}
