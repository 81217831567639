import {Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BoatFilterListWithCustomerDTO} from 'src/app/models/dtos/boat-filter-list-with-customerDTO';
import {BoatSimples} from 'src/app/models/dtos/operational-options/responsibles';
import {BoatService} from 'src/app/services/boat.service';
import {MovementPlanAppointmentService} from 'src/app/services/movements/movement-plan-appointment.service';
import Swal from 'sweetalert2';
import {SlingConfigService} from '../../../../../services/sling-config.service';
import {SlingConfig} from '../../../../../models';
import {TypeBlockCustomer} from '../../../../../models/enums/type-block-customer';

@Component({
  selector: 'app-movement-select-boat',
  templateUrl: './movement-select-boat.component.html',
  styleUrls: ['./movement-select-boat.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MovementSelectBoatComponent),
      multi: true
    }
  ]
})
export class MovementSelectBoatComponent implements OnInit, OnChanges, ControlValueAccessor {

  @Input()
  searchByCustomer: boolean = true;

  @Input()
  customer: { id: number } = null;

  selectedBoatId: number;
  boatsFilter: { label: string, value: number, disabled?: boolean }[] = [];
  private boats: BoatFilterListWithCustomerDTO[] = [];
  private boat: BoatSimples;
  slingConfig: SlingConfig;

  private onChange: any = () => {
  };
  onTouched: any = () => {
  };
  disabled: boolean;

  constructor(
    private readonly movementPlanAppointmentService: MovementPlanAppointmentService,
    private readonly boatService: BoatService,
    private slignConfigService: SlingConfigService
  ) {
    this.loadBoats().then((boats: BoatFilterListWithCustomerDTO[]) => {
      this.boats = boats;
      this.populateBoatFilter(this.searchByCustomer, this.boats);
    });
  }

  writeValue(obj: BoatSimples): void {
    this.boat = obj;
    this.selectedBoatId = this.boat ? this.boat.id : null;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    this.slignConfigService.getSlingConfigToday().subscribe(resp => this.slingConfig = resp);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchByCustomer && changes.searchByCustomer.currentValue !== changes.searchByCustomer.previousValue) {
      this.selectedBoatId = null;
      this.emitChanges(null);
      this.populateBoatFilter(this.searchByCustomer, this.boats);
    }
    if (changes.customer && changes.customer.currentValue !== changes.customer.previousValue) {
      if (this.searchByCustomer) {
        this.selectedBoatId = null;
        this.emitChanges(null);
      }
      this.populateBoatFilter(this.searchByCustomer, this.boats);
    }
  }

  changeSelectedBoat(event: any): void {
    this.movementPlanAppointmentService.findBoatOptionSelect(event.value).subscribe((boat: BoatSimples) => {
        if (this.slingConfig?.typeBlockCustomer?.valueOf() === 'boat') {
          // @ts-ignore
          if (boat.boatCustomers.find(item => item.blocked)) {
            Swal.fire({
              title: 'Atenção',
              text: 'Embarcação possui bloqueio no financeiro.',
              icon: 'warning',
              reverseButtons: true
            });
          }
        }
        this.emitChanges(boat);
      },
      (error) => {
        this.emitChanges(null);
      });
  }

  emitChanges(boat: BoatSimples) {
    if (!this.disabled) {
      this.boat = boat;
      this.onChange(boat);
    }
  }

  async loadBoats(): Promise<BoatFilterListWithCustomerDTO[]> {
    return new Promise<BoatFilterListWithCustomerDTO[]>(
      async (resolve) => {
        this.boatService.getBoatFilterListWithCustomer().subscribe(
          (data) => {
            resolve(data.filter((b: BoatFilterListWithCustomerDTO) => b.active));
          },
          () => {
            this.boat = null;
            resolve(null);
          }
        );
      }
    );
  }

  populateBoatFilter(searchByCustomer: boolean, res: BoatFilterListWithCustomerDTO[]) {
    let boatList = [...res];
    if (searchByCustomer) {
      if (this.customer) {
        boatList = boatList.filter((b: BoatFilterListWithCustomerDTO) => b.customerIds.includes(this.customer.id));
      } else {
        boatList = [];
      }
    }
    this.boatsFilter = this.mountBoatOptions(boatList);
  }

  mountBoatOptions(boats: BoatFilterListWithCustomerDTO[]): { label: string, value: number, disabled?: boolean }[] {
    return boats ? boats.map((boat) => ({
      label: boat.name.concat(boat.maintenance ? ' (Em manutenção)' : ''),
      value: boat.id,
      disabled: boat.maintenance
    })) : [];
  }
}
