<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{'QUOTA' | translate}}</span>
    </p-header>

    <form (submit)="save()">
        <div class="form-group">
            <p-pickList [responsive]="true" dragdrop="true" [source]="owners" [target]="quotas" class="flex1"
                sourceHeader="{{'OWNERS' | translate}}" targetHeader="{{'QUOTA' | translate}}"
                [showSourceControls]="false" [showTargetControls]="false"
                [disabled]="isASlingEdition" (onMoveToTarget)="convertToQuota()"
                (onMoveToSource)="convertToCustomer()">
                <ng-template let-item pTemplate="item">
                    <div *ngIf="item != undefined">
                        <div style="float:left; margin: 5px; font-size: 16px"
                            [ngClass]="{'inactive': !item.customer?.active}">
                            {{item.customer?.name}}{{!item.customer?.active ? (' - ' + messageUtil.translateKey('INACTIVE')) : ''}}
                        </div>
                        <div style="float: right; margin: 10px;">
                            <input type="number" min="1" max="100" pInputText class="no-spin" style="text-align: right;"
                                [(ngModel)]="item.quota" name="quota{{item.customer?.id}}" (blur)="calculateQuota()"> %
                        </div>
                    </div>
                </ng-template>
            </p-pickList>
        </div>
        <p-footer>
            <div class="form-group">
                <div class="form-control">
                    <div class="float-left" *ngIf="slingOrder.id">
                        <p style="font-weight:500; padding-left: 20px">Data do pedido:
                            {{getRequestDate() | date: "dd/MM/yyyy"}}</p>
                    </div>
                </div>
                <div class="buttons">
                    <button type="button" (click)="back()"
                        class="easymarine danger">{{ 'Voltar' | translate }}</button>
                    <button [disabled]="!slingUtil.hasCancel(slingOrder)" class="easymarine primary"
                        type="submit">Finalizar</button>
                </div>
            </div>
        </p-footer>
    </form>
</p-fieldset>