import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import b64toBlob from 'b64-to-blob';
import moment from 'moment';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { SlingConfig } from 'src/app/models';
import { MovementPlanByObjectiveCreateDTO } from 'src/app/models/dtos/movements/movement-plan-by-objective-createDTO';
import { ObjectiveOptionDTO } from 'src/app/models/dtos/movements/objectives/objective-option-dto';
import { MovementPlanAppointmentService } from 'src/app/services/movements/movement-plan-appointment.service';
import { MovementPlanService } from 'src/app/services/movements/movement-plan.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { ExtractInvoiceComponent } from '../../extract-invoice/extract-invoice.component';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-movement-objective-form',
  templateUrl: './movement-objective-form.component.html',
  styleUrls: ['./movement-objective-form.component.scss'],
})
export class MovementObjectiveFormComponent implements OnInit {
  selectedTab: number = 0;
  pageControl: string[] = ['data-cliente', 'objective'];

  slingConfig: SlingConfig;
  isNew: boolean;

  movementPlan: any = {};
  objectives: ObjectiveOptionDTO[];
  objectiveSelected: ObjectiveOptionDTO = null;

  constructor(
    private readonly config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private movementPlanService: MovementPlanService,
    private readonly messageUtil: MessageUtil,
    private movementPlanAppointmentService: MovementPlanAppointmentService,
    private dialog: DialogService,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService
  ) {
    const data = this.config.data;
    this.isNew =
      data.isNew !== null || data.isNew !== undefined ? data.isNew : true;
    this.slingConfig = data.slingConfig;
    if (!this.isNew) {
      this.movementPlan = data.movementPlan;
    }
  }

  ngOnInit(): void {
    this.movementPlanAppointmentService
      .findMovementObjectives()
      .subscribe((data) => {
        if (!this.isNew && this.movementPlan.objectiveCode) {
          this.objectiveSelected = data.filter(
            (o) => o.code === this.movementPlan.objectiveCode
          )[0];
          this.createQuestionnaireTabFromObjective();
          this.selectedTab=this.pageControl.length-1;
        } else if (data.length === 1) {
          this.objectiveSelected = data[0];
        }
        this.objectives = data;
      });
    if (!this.isNew && this.movementPlan.objectiveCode){
       this.selectedTab=this.pageControl.length-1;}
  }

  previouPage() {
    if (
      this.movementPlan?.questionnairesAnswer?.[0]?.answerCode ===
      'DestinationNavigation'
    ) {
      this.objectiveSelected = {
        ...this.objectiveSelected,
        hasNavigationPlan: false,
      };
    }
    if (this.pageControl.length > 0) {
      if (this.selectedTab ==3 && this.objectiveSelected?.code== "NAVIGATION" &&  this.movementPlan.boat.currentLocation.location.type == "WET_VACANCY"){
        this.selectedTab--;
      }
      this.selectedTab--;
    }
  }

  cancel() {
    if (!this.movementPlan?.id) {
      this.messageUtil.confirmMessage(
        {
          confirmationDialog: () => {
            this.close();
          },
        },
        this.messageUtil.translateKey('SUMMARY.WARNING'),
        'Deseja cancelar a criação do plano de movimentação?',
        ''
      );
    } else {
      this.close();
    }
  }

  nextPage(obj: any) {
    this.movementPlan = { ...this.movementPlan, ...obj };
    if (this.pageControl.length - 1 === this.selectedTab) {
      this.save();
    } else {
      setTimeout(() => {
        if (this.selectedTab ==1 && obj.objectiveCode=== "NAVIGATION" &&  this.movementPlan.boat.currentLocation.location.type === "WET_VACANCY"){
          this.selectedTab++;
        }
        this.selectedTab++;
      }, 50);
    }
  }

  nextPageFromQuestionnaire(obj: {
    questionnaireCode: string;
    answerCode: string;
  }) {
    let answers: any[] = this.movementPlan?.questionnairesAnswer;
    if (answers) {
      const a = answers.filter(
        (item) => item.questionnaireCode === obj.questionnaireCode
      );
      if (a.length == 1) {
        a[0].answerCode = obj.answerCode;
      } else {
        answers.push(obj);
      }
    } else {
      answers = [obj];
    }
    if (
      this.movementPlan.questionnairesAnswer[0].answerCode ===
      'DestinationNavigation'
    ) {
      this.objectiveSelected = {
        ...this.objectiveSelected,
        hasNavigationPlan: true,
      };
    }
    this.nextPage({ questionnairesAnswer: answers });
  }

  nextPageSelectObjective(obj: { objectiveCode: string }) {
    this.objectiveSelected = this.objectives.filter(
      (o) => o.code === obj.objectiveCode
    )[0];
    if (this.isNew) {
      this.movementPlan.questionnairesAnswer = [];
      this.createQuestionnaireTabFromObjective();
    }
    this.nextPage(obj);
  }

  createQuestionnaireTabFromObjective() {
    this.pageControl = ['data-cliente', 'objective'];
    this.objectiveSelected.questionnaires.forEach((v, index) =>
      this.pageControl.push('q' + index)
    );
    this.pageControl.push('navigation-plan');
  }

  close(): void {
    this.dialogRef.close();
  }

  private save() {
    const movementPlan = this.movementPlan as MovementPlanByObjectiveCreateDTO;
    movementPlan.executionDate = moment(movementPlan.executionDate)
      .utc(true)
      .format('YYYY-MM-DDTHH:mm');
    if (this.movementPlan?.objectiveCode !== 'SINGLE') {
      movementPlan.returnTime = moment(movementPlan.returnTime)
        .utc(true)
        .format('YYYY-MM-DDTHH:mm');
    }
    let requestBody;
    if(this.movementPlan?.movementLocationOrigin){
      const {
        id,
        boat,
        customer,
        sailor,
        objectiveCode,
        questionnairesAnswer,
        navigationPlan,
        executionDate,
        returnTime,
        movementLocationOrigin,
        vacancyOrigin,
        observation
      } = this.movementPlan;
      requestBody = {
        id,
        boat,
        customer,
        sailor,
        navigationPlan,
        executionDate,
        returnTime,
        objectiveCode,
        questionnairesAnswer,
        movementLocationOrigin,
        vacancyOrigin,
        observation
      };
    }else{
      const {
        id,
        boat,
        customer,
        sailor,
        objectiveCode,
        questionnairesAnswer,
        navigationPlan,
        executionDate,
        returnTime,
        observation
      } = this.movementPlan;
      requestBody = {
        id,
        boat,
        customer,
        sailor,
        navigationPlan,
        executionDate,
        returnTime,
        objectiveCode,
        questionnairesAnswer,
        observation
      };
    }
    this.movementPlanService.saveByObjective(requestBody).subscribe(
      (data) => {
        this.dialogRef.close(requestBody);
        if (this.slingConfig.printNavigationPlan === 'AutoPrint') {
          this.generateMovementPlanPDF(data);
        } else if (this.slingConfig.printNavigationPlan === 'Question') {
          const types = 'question';
          const titleMessage = this.messageUtil.translateKey(
            'PRINT-NAVIGATION-PLAN-CONFIRMATION'
          );
          Swal.fire({
            title: titleMessage,
            icon: types,
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
          }).then(async (result) => {
            if (result.value) {
              this.generateMovementPlanPDF(data);
            }
          });
        }
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          this.messageUtil.translateKey(exception.title),
          exception.message
        );
      }
    );
  }

  async generateMovementPlanPDF(data): Promise<any> {
    return new Promise<any>(async (resolve) => {
      this.spinner.show();
      await this.movementPlanService
        .getMovementPlanPDF(data.movementPlan.id)
        .subscribe(async (data) => {
          resolve(
            this.openExtract(
              this.sanitizer.bypassSecurityTrustResourceUrl(
                URL.createObjectURL((b64toBlob as any)(data, 'application/pdf'))
              )
            )
          );
          this.spinner.hide();
        });
    });
  }
  openExtract(path): void {
    this.dialog.open(ExtractInvoiceComponent, {
      width: '100%',
      height: '100%',
      data: { path },
      header: 'Plano de Movimentação - PDF',
    });
  }
}
