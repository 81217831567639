<form (submit)="findAll()" class="filters" appFilters>

    <!-- Filtros -->
    <div class="filter-row" *ngIf="contentLoaded">

        <div class="form-control" *ngIf="marinaCompanies?.length > 0">
            <label for="selectedCompany">{{'COMPANY' | translate}}</label>
            <p-dropdown [filter]="true" [options]="filteredCompanies" name="selectedCompany" appendTo="body"
                [(ngModel)]="selectedCompany" (onChange)="changeCompany()">
            </p-dropdown>
        </div>
        <div class="form-control">
            <label>{{'CUSTOMER' | translate}}</label>
            <p-dropdown [filter]="true" [options]="filteredCustomers" [(ngModel)]="selectedCustomer" appendTo="body"
                name="selectedCustomer" (onChange)="changeCustomer()">
            </p-dropdown>
        </div>
        <div class="form-control">
            <label for="nfeNumeroStart">{{'MIN-INVOICE-TAX-NUMBER' | translate}}</label>
            <input pInputText type="number" [(ngModel)]="filter.nfeNumeroStart" name="nfeNumeroStart">
        </div>
        <div class="form-control">
            <label for="nfeNumeroEnd">{{'MAX-INVOICE-TAX-NUMBER' | translate}}</label>
            <input pInputText type="number" [(ngModel)]="filter.nfeNumeroEnd" name="nfeNumeroEnd">
        </div>

        <div class="form-control">
            <label for="minInvoiceNumber">{{'MIN-INVOICE-NUMBER' | translate}}</label>
            <input pInputText type="number" [(ngModel)]="filter.invoiceNumberStart" name="minInvoiceNumber">
        </div>

        <div class=" form-control">
            <label for="maxInvoiceNumber">{{'MAX-INVOICE-NUMBER' | translate}}</label>
            <input pInputText type="number" [(ngModel)]="filter.invoiceNumberEnd" name="maxInvoiceNumber">
        </div>

        <div class="form-control">
            <label for="rangeIssueDate">{{'EMISSION-DATE-FROM-TO' | translate}}</label>
            <p-calendar
                dateFormat="dd/mm/yy"
                name="rangeCreateDate"
                [monthNavigator]="true"
                [yearNavigator]="true"
                appendTo="body"
                [yearRange]="'2000:2055'"
                rPCalendarMask
                selectionMode="range"
                [(ngModel)]="this.rangeCreationDates"
                [showIcon]="true"
                (onBlur)="this.onChangeCreationDate()"
                i18n
            >
            </p-calendar>
        </div>

        <div class="form-control">
            <label for="rangeDueDate">{{'DUE-DATE-FROM-TO' | translate}}</label>
            <p-calendar
                dateFormat="dd/mm/yy"
                name="rangeDueDate"
                [monthNavigator]="true"
                [yearNavigator]="true"
                appendTo="body"
                [yearRange]="'2000:2055'"
                rPCalendarMask
                selectionMode="range"
                [(ngModel)]="this.rangeDueDates"
                [showIcon]="true"
                (onBlur)="this.onChangeDueDate()"
                i18n
            >
            </p-calendar>
        </div>

        <div class=" form-control">
            <label for="valorTotalStart">Valor (de)</label>
            <input pInputText type="number" [(ngModel)]="filter.valorTotalStart" name="valorTotalStart">
        </div>
        <div class=" form-control">
            <label for="valorTotalEnd">Valor (até)</label>
            <input pInputText type="number" [(ngModel)]="filter.valorTotalEnd" name="valorTotalEnd">
        </div>
        <div class=" form-control">
            <label for="aliquotaIssStart">Aliquota (de)</label>
            <input pInputText type="number" [(ngModel)]="filter.aliquotaIssStart" name="aliquotaIssStart">
        </div>
        <div class=" form-control">
            <label for="aliquotaIssEnd">Aliquota (até)</label>
            <input pInputText type="number" [(ngModel)]="filter.aliquotaIssEnd" name="aliquotaIssEnd">
        </div>
        <div class="form-control">
            <label>{{'STATUS' | translate}}</label>
            <p-multiSelect [options]="statusList" [style]="{height: '30px'}" [(ngModel)]="filter.statusList"
                appendTo="body" name="statusList" selectedItemsLabel="{0} itens selecionados">
            </p-multiSelect>
        </div>

        <div class="buttons">
            <div class="form-button">
                <button (click)="clear()" class="easymarine danger" type="button">{{ 'CLEAR' | translate }}</button>
            </div>
            <div class="form-button">
                <button class="easymarine primary" type="submit">{{ 'FIND' | translate }}</button>
            </div>
        </div>
    </div>

</form>
<div class="table">
    <p-table [value]="invoiceTaxList" autoLayout="true" [alwaysShowPaginator]="true"
        [rows]="paginationFilter.size" [totalRecords]="totalRecords" [paginator]="true" [lazy]="true" #table
        [(selection)]="selectedinvoiceTaxList" [rowsPerPageOptions]="[10,20,30,50,100]"
        (onPage)="onChangePage($event)" (onSort)="onSort($event)" styleClass="easy-table">
        <!-- Botões legenda topo -->
        <ng-template pTemplate="header">
            <tr>
                <th class="text-center">
                    <input type="checkbox" class="primeCheckbox" disabled>
                </th>
                <th class="text-center">Numero</th>
                <th class="text-center" [pSortableColumn]="'invoice.invoiceNumber'">
                    Numero Fatura
                    <p-sortIcon [field]="'invoice.invoiceNumber'"></p-sortIcon>
                </th>
                <th class="text-center">Tipo</th>
                <th class="text-center" [pSortableColumn]="'federalId'">
                    Empresa
                    <p-sortIcon [field]="'federalId'"></p-sortIcon>
                </th>
                <th class="text-center">Cliente</th>

                <th class="text-center" [pSortableColumn]="'invoice.dueDate'">
                    Competência
                    <p-sortIcon [field]="'invoice.dueDate'"></p-sortIcon>
                </th>

                <th class="text-center" [pSortableColumn]="'createDate'">
                    Emissão
                    <p-sortIcon [field]="'createDate'"></p-sortIcon>
                </th>
                <th class="text-center" [pSortableColumn]="'updateDate'">
                    Atualização
                    <p-sortIcon [field]="'updateDate'"></p-sortIcon>
                </th>
                <th class="text-center" [pSortableColumn]="'aliquotaIss'">
                    Aliquota ISS
                    <p-sortIcon [field]="'aliquotaIss'"></p-sortIcon>
                </th>
                <th class="text-center" [pSortableColumn]="'valorTotal'">
                    Valor
                    <p-sortIcon [field]="'valorTotal'"></p-sortIcon>
                </th>
                <th class="text-center" [pSortableColumn]="'status'">
                    Situação
                    <p-sortIcon [field]="'status'"></p-sortIcon>
                </th>
                <th class="text-center"></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-invTaxDoc>
            <tr>
                <td class="text-center" [pTooltip]="" tooltipPosition="bottom">
                    <p-tableCheckbox [value]="invTaxDoc"  [disabled]="true" >
                    </p-tableCheckbox>
                </td>
                <td class="text-center">
                    <a *ngIf="invTaxDoc?.nfeNumero && invTaxDoc.serviceDocument" class="paidOrder"
                        [href]="invTaxDoc.serviceDocument" target="_blank" tooltipPosition="bottom"
                        tooltipPosition="top">
                        {{ invTaxDoc?.nfeNumero }}
                    </a>




                </td>
                <td class="text-center link green">
                    <span class="paidOrder" (click)="extract(invTaxDoc?.invoice?.invoiceId)">{{
                        invTaxDoc?.invoice?.invoiceNumber}}</span>

                </td>
                <td class="text-center">
                    {{ invTaxDoc?.type}}
                </td>
                <td class="text-center">
                    {{ getCompanyName(invTaxDoc?.federalId)}}
                </td>
                <td class="text-center">
                    {{ invTaxDoc?.invoice?.customerName}}
                </td>
                <td class="text-center">
                    {{ invTaxDoc?.invoice?.dueDate | date:'dd/MM/yy h:mm'}}
                </td>
                <td class="text-center">
                    {{ invTaxDoc?.createDate | date:'dd/MM/yy h:mm'}}
                </td>
                <td class="text-center">
                    {{ invTaxDoc?.updateDate | date:'dd/MM/yy' }}
                </td>
                <td class="text-center">
                    {{ invTaxDoc?.aliquotaIss | number:'1.2-2'}}
                </td>

                <td class="text-right">
                    {{ invTaxDoc?.valorTotal | number:'1.2-2' }}
                </td>
                <td class="text-center">
                    {{ getStatus(invTaxDoc?.status) }}
                </td>
                <td class="text-center" [pTooltip]="teste" tooltipPosition="bottom">


                    <div pTooltip="Cancelar Nota" tooltipPosition="bottom" (click)="cancelInvoiceTaxDocument(invTaxDoc)"
                        *ngIf=!cantCancel(invTaxDoc) name="cancelInvoiceTaxDocument" class="table-action"
                        style="color: red;">
                        <i class="pi pi-times"></i>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
