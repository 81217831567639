<div [ngClass]="{'dialog-form': isModal}">
    <form class="filters active">
        <div class="filter-row">
            <div class="form-control" *ngIf="showCompanyList && !isModal">
                <label>Empresa</label>
                <p-dropdown (onChange)="changeCompany()" [options]="aditionalCompanyListOptions" appendTo="body"
                    [(ngModel)]="selectedCompany" resetFilterOnHide="true" [filter]="true"
                    placeholder="Selecione a empresa" name="selectedCompany">
                </p-dropdown>
            </div>
            <div class="form-control">
                <label>Forma de pagamento</label>
                <p-dropdown (onChange)="change($event)" [options]="paymentMethodsList" [(ngModel)]="selectedPayment"
                    resetFilterOnHide=true optionLabel="descricao" [filter]="true" appendTo="body"
                    placeholder="Selecione a forma de pagamento" name="selectedPayment">
                </p-dropdown>
            </div>
        </div>
    </form>
    <br>
    <div class="table">
        <p-pickList [disabled]="!selectedPayment" (onMoveToTarget)="addAccount($event)"
            (onMoveToSource)="removeAccount($event)" [source]="accounts" [target]="receivingAccounts"
            sourceHeader="Contas" targetHeader="{{ 'RECEIVING-ACCOUNTS' | translate }}" dragdrop="true"
            [showSourceControls]="false" [showTargetControls]="false">
            <ng-template let-conta pTemplate="item">
                <div class="ui-helper-clearfix">
                    <div>{{conta.idConta}} - {{conta.nome}}</div>
                </div>
            </ng-template>
        </p-pickList>
    </div>
</div>