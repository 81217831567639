import { SlingConfig } from './sling-config';

export class EmailCopy {
    id: number;
    name: string;
    slingConfig: SlingConfig;

    constructor() {
        this.slingConfig = new SlingConfig();
    }
}
