import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import moment from "moment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { EasyMarineHttpApiLog } from "./easy-marine-http-api-log";

@Injectable()
export class MovementExecutionOperationLogService {

    constructor(private httpClient: EasyMarineHttpApiLog) {
    }

    findMovements(filter: any): Observable<any> {
        let params = new HttpParams();
        if (filter.dateStart) {
            params = params.append('dateStart', moment(filter.dateStart).format());
        }
        if (filter.dateEnd) {
            params = params.append('dateEnd', moment(filter.dateEnd).format());
        }
        if (filter.userId) {
            params = params.append('userId', filter.userId);
        }
        if (filter.boatId) {
            params = params.append('boatId', filter.boatId);
        }
        if (filter.targetId) {
            params = params.append('targetId', filter.targetId);
        }
        return this.httpClient.get(environment.apiLog + `/movement-execution-operation-log/${filter.marinaId}`, { params })
            .pipe(map((response: any) => response));
    }
}
