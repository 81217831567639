import { Component, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { VacancyStatusDTO } from "src/app/models/dtos/vacancy-statusDTO";


@Component({
    selector: 'berth-map-info',
    templateUrl: './berth-map-info-form.html',
    styleUrls: ['./berth-map-info-form.scss'],
  })
export class BerthMapInfoFormComponent implements OnInit {

    public vacancyStatus: VacancyStatusDTO; 
    public t_class: string = 'p-datatable-sm';
      
    constructor(
        public dialogRef: DynamicDialogRef,
        private config: DynamicDialogConfig
    ){}

    
    
    
    ngOnInit(): void {
        this.vacancyStatus = this.config.data.vacancyStatusDTO;
        
    }


    getBoatStatus():string {
        if (this.vacancyStatus.boatId) {
            if (this.vacancyStatus.currentLocationDTO) {
                return this.vacancyStatus.currentLocationDTO.type;
            } else {
                return 'Atracado';
            }

        } else {
          return  '';
        }
    }

} 