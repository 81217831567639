import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OrderUtil } from 'src/app/utils/order.util';

@Component({
  selector: 'app-sling-order-details',
  templateUrl: './sling-order-details.component.html',
  styleUrls: ['./sling-order-details.component.scss']
})
export class SlingOrderDetailsComponent implements OnInit {

  sling: any;
  customers: any[];
  orders: any[];
  today: any = new Date();
  orderUtil: OrderUtil;

  constructor(
    public dialog: DynamicDialogRef,
    private router: Router,
    private config: DynamicDialogConfig
  ) {
    this.orderUtil = new OrderUtil();
  }

  ngOnInit(): void {
    this.sling = this.config.data.sling;
    this.customers = this.config.data.customers;
    this.orders = this.config.data.orders;
  }

  singleOrder(): void {
    this.dialog.close();
    this.router.navigate(['single-order'], {
      queryParams: {
        customerId: this.sling.navigationPlan.responsible.id,
        boatId: this.sling.boat.id,
        slingId: this.sling.id
      }
    });
  }
}
