<p-fieldset>

    <p-header class="article-title">
        <span class="article-title">{{ 'TITLE-MOVEMENT-MANAGEMENT' | translate }}</span>
        <div class="header-buttons">
            <!-- <button class="fab-button danger" (click)="exportPDF()" pTooltip="Exportar PDF">
                <i class="pi pi-file-pdf"></i>
            </button> -->
            <button class="fab-button" (click)="exportTable()" pTooltip="Exportar Tabela">
              <i class="pi pi-file-excel"></i>
          </button>
            <button class="fab-button primary " appFiltersButton pTooltip="Exibir/Ocultar Filtros"
                tooltipPosition="left">
                <i class="pi pi-filter"></i>
            </button>
        </div>
    </p-header>

    <form class="filters" (submit)="find()" appFilters>
        <div class="filter-row">

            <div class="form-control">
                <label>Data inicial</label>
                <p-calendar class="field" dateFormat="dd/mm/yy" name="startDate" [monthNavigator]="true"
                    appendTo="body" [yearNavigator]="true" [yearRange]="'2000:2055'" rPCalendarMask
                    [(ngModel)]="filter.startDate" [showIcon]="true" i18n>
                </p-calendar>
            </div>

            <div class="form-control">
                <label>Data final</label>
                <p-calendar class="field" dateFormat="dd/mm/yy" name="endDate" [monthNavigator]="true"
                    appendTo="body" [yearNavigator]="true" [yearRange]="'2000:2055'" rPCalendarMask
                    [(ngModel)]="filter.endDate" [showIcon]="true" i18n>
                </p-calendar>
            </div>

            <div class="form-control">
                <label>{{ 'CUSTOMER' | translate }}</label>
                <p-multiSelect [defaultLabel]="'CUSTOMER' | translate" [options]="customerListOptions"
                    appendTo="body" [(ngModel)]="filter.customerIds" [title]="'BOAT' | translate" [ngModelOptions]="{standalone: true}"
                    [filter]=true selectedItemsLabel="{0} itens selecionados">
                </p-multiSelect>
            </div>

            <div class="form-control">
                <label>{{ 'SAILOR' | translate }}</label>
                <p-multiSelect [defaultLabel]="'SAILOR' | translate" [options]="sailorListOptions"
                    appendTo="body" [(ngModel)]="filter.sailorIds" [title]="'BOAT' | translate" [ngModelOptions]="{standalone: true}"
                    [filter]=true selectedItemsLabel="{0} itens selecionados">
                </p-multiSelect>
            </div>

            <div class="form-control">
                <label>{{'BOAT' | translate}}</label>
                <p-multiSelect [defaultLabel]="'BOAT' | translate" [options]="boatListOptions"
                    appendTo="body" [(ngModel)]="filter.boatIds" [title]="'BOAT' | translate" [ngModelOptions]="{standalone: true}"
                    [filter]=true selectedItemsLabel="{0} itens selecionados">
                </p-multiSelect>
            </div>

            <div class="form-control">
                <label>{{'STATUS' | translate}}</label>
                <p-multiSelect [defaultLabel]="'STATUS' | translate"
                    [options]="statusListOptions" appendTo="body" [(ngModel)]="filter.movementStatus"
                    [title]="'STATUS' | translate" [filter]=false name="filter.movementStatus"
                    selectedItemsLabel="{0} itens selecionados">
                </p-multiSelect>
            </div>

            <div class="form-control">
                <label>{{'Objetivo' | translate}}</label>
                <p-multiSelect [defaultLabel]="'Objetivo' | translate"
                    [options]="objetiveListOptions" appendTo="body" [(ngModel)]="filter.objetives"
                    [title]="'Objetivo' | translate" [filter]=false name="filter.objetives"
                    selectedItemsLabel="{0} itens selecionados">
                </p-multiSelect>
            </div>

            <div class="form-control">
                <label>{{'ORIGIN' | translate}}</label>
                <p-multiSelect [defaultLabel]="'ORIGIN' | translate" [options]="movementLocationListOptions"
                    appendTo="body" [(ngModel)]="filter.originIds" [title]="'ORIGIN' | translate" [ngModelOptions]="{standalone: true}"
                    [filter]=false selectedItemsLabel="{0} itens selecionados">
                </p-multiSelect>
            </div>

            <div class="form-control">
                <label>{{'DESTINY' | translate}}</label>
                <p-multiSelect [defaultLabel]="'DESTINY' | translate" [options]="movementLocationListOptions"
                    appendTo="body" [(ngModel)]="filter.destinyIds" [title]="'DESTINY' | translate" [ngModelOptions]="{standalone: true}"
                    [filter]=false selectedItemsLabel="{0} itens selecionados">
                </p-multiSelect>
            </div>

            <div class="form-control">
                <label for="isCounted">{{'IS-COUNTED' | translate}}</label>
                <p-dropdown [(ngModel)]="filter.isCounted" name="isCounted" appendTo="body"
                    [options]="[{label: 'Ambos', value: ''}, {label: 'Sim', value: true}, {label: 'Não', value: false}]">
                </p-dropdown>
            </div>

            <div class="form-control">
                <label for="exempt">{{'EXEMPT' | translate}}</label>
                <p-dropdown [(ngModel)]="filter.exempt" name="exempt" appendTo="body"
                    [options]="[{label: 'Ambos', value: ''}, {label: 'Sim', value: true}, {label: 'Não', value: false}]">
                </p-dropdown>
            </div>

            <div class="form-control">
                <label for="systemAdjustment">{{'Movimentação de ajuste' | translate}}</label>
                <p-dropdown [(ngModel)]="filter.systemAdjustment" name="systemAdjustment" appendTo="body"
                    [options]="[{label: 'Ambos', value: ''}, {label: 'Sim', value: true}, {label: 'Não', value: false}]">
                </p-dropdown>
            </div>

            <div class="buttons">
                <div class="form-button">
                    <button class="danger easymarine" type="button" (click)="clear()">{{ 'CLEAR' | translate }}</button>
                </div>
                <div class="form-button">
                    <button class="primary easymarine" type="submit">{{ 'FIND' | translate }}</button>
                </div>
            </div>
        </div>

    </form>
    <div class="buttons-management">
        <div class="form-button">
            <button class="danger easymarine" type="button" (click)="cancelMovementConfirm()" [disabled]="disableCancelButton()">{{ 'CANCEL' | translate }}</button>
        </div>
        <div class="form-button">
            <button class="easymarine" type="button" (click)="adjustmentMovementsModal()" [disabled]="!disableCancelButton()">{{ 'Ajustar movimentação' | translate }}</button>
        </div>
    </div>
    <div class="table">
        <p-table #tt [value]="movementReports" [paginator]="true" [rows]="paginationFilter.size" resetPageOnSort="true"
            [autoLayout]="true" styleClass="easy-table p-datatable-striped" [totalRecords]="totalRecords"
            [lazy]="true" (onLazyLoad)="onChangePage($event)" [(selection)]="selectedMovements">
            <ng-template pTemplate="caption">
                <div></div>
                <span style="font-weight: bold; float: right;">{{ 'TOTAL-REGISTERS' | translate }}:
                    {{movementReports.length}} {{'OF' | translate}} {{tt.totalRecords}}
                </span>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th class="text-center">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th class="text-center"> {{ 'ADJUSTMENT' | translate }}</th>
                    <th class="text-center"> {{ 'REALIZATION-DATE' | translate }}</th>
                    <th class="text-center"> {{ 'BOAT' | translate }}</th>
                    <th class="text-center"> {{ 'CUSTOMER' | translate }}</th>
                    <th class="text-center"> {{ 'SAILOR' | translate }}</th>
                    <th class="text-center"> {{ 'ORIGIN-APP' | translate }}</th>
                    <th class="text-center"> {{ 'OBJECTIVE' | translate }}</th>
                    <th class="text-center"> {{ 'ORIGIN' | translate }}</th>
                    <th class="text-center"> {{ 'DESTINY' | translate }}</th>
                    <th class="text-center"> {{ 'IS-COUNTED' | translate }}</th>
                    <th class="text-center"> {{ 'STATUS' | translate }}</th>
                    <th class="text-center"> {{ 'EXEMPT' | translate }}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-movement>
                <tr>
                    <td class="text-center">
                        <p-tableCheckbox [value]="movement" [disabled]="disableSelection(movement)"></p-tableCheckbox>
                    </td>
                    <td class="text-center">
                        {{movement.systemAdjustment? 'S': 'N'}}
                    </td>
                    <td class="text-center">
                        {{ timezone(movement.finishDateTime) | date: 'dd/MM/yy - HH:mm'}}
                    </td>
                    <td class="text-center">
                        {{movement.boatName}}
                    </td>
                    <td class="text-center">
                        {{movement.customerName}}
                    </td>
                    <td class="text-center">
                      {{movement.sailorName}}
                    </td>
                    <td class="text-center">
                        {{movement.requestOrigin | translate}}
                    </td>
                    <td class="text-center">
                        {{movement.objective | translate}}
                    </td>
                    <td class="text-center">
                        {{movement.originName}}
                    </td>
                    <td class="text-center">
                        {{movement.destinyName}}
                    </td>
                    <td class="text-center">
                        {{(movement.isCounted? 'YES': 'NO') | translate}}
                    </td>
                    <td class="text-center">
                        {{movement.movementStatus | translate}}
                    </td>
                    <td class="text-center">
                        <p-inputSwitch (onChange)="onChangeExempt(movement)" [(ngModel)]="movement.exempt" name="exempt" [disabled]="disableExempt(movement)">
                        </p-inputSwitch>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="12">
                        Nenhum movimentação encontrada
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-fieldset>
