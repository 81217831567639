import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-user-log-general-data',
  templateUrl: './user-log-general-data.component.html',
  styleUrls: ['./user-log-general-data.component.scss']
})
export class UserLogGeneralDataComponent implements OnInit {

  @Input() userLog;
  @Input() isNew;

  oldDatasMap;
  listaPlanoContasFlat = [];

  constructor(    ) { }

  async ngOnInit(): Promise<void> {
        this.oldDatasMap = new Map(Object.entries(this.userLog.oldDatas));
        // if (this.userLog.oldDatas.roleId) {
        //   this.oldDatasMap.set('roleIdName', this.userLog.oldDatas.roleId.name);
        // }
  }

}
