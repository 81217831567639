import { ProductCategoryType } from './product-category-type';

export class ProductCategory {
    id: number;
    name: string;
    productCategoryType: ProductCategoryType;

    constructor() {
        this.productCategoryType = ProductCategoryType.Consumable;
    }
}
