<p-fieldset class="pt-0">
  <p-header class="article-title">
    <span class="article-title">{{
      "CONTROLE DE ACESSO > CONVIDADOS" | translate
    }}</span>
    <div class="new-header-buttons">
      <button
        (click)="newVisitorAndPermission()"
        class="primary fab-button"
        pTooltip="Cadastrar um novo visitante"
        tooltipPosition="bottom"
      >
        <i class="pi pi-plus" style="font-size: 21px"></i>
      </button>
      <button
        class="fab-button primary"
        appFiltersButton
        pTooltip="Exibir/Ocultar Filtros"
        tooltipPosition="left"
      >
        <i class="pi pi-filter"></i>
      </button>
    </div>
  </p-header>
  <p-tabView>
    <p-tabPanel header="Permissões">
      <app-visitor-panel
        [liberation]="false"
        [visitorType]="'GUEST'"
      ></app-visitor-panel>
    </p-tabPanel>
    <p-tabPanel header="Cadastros">
      <app-visitor [visitorType]="'GUEST'"> </app-visitor>
    </p-tabPanel> </p-tabView
></p-fieldset>
