import { Question } from './../../models/question';
import { FaqDialogViewComponent } from './faq-dialog-view/faq-dialog-view.component';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Table } from 'primeng/table';
import { Router } from '@angular/router';
import { FaqService } from 'src/app/services/faq.service';


@Component({
  selector: 'app-faq-dialog',
  templateUrl: './faq-dialog.component.html',
  styleUrls: ['./faq-dialog.component.scss'],
  //encapsulation : ViewEncapsulation.None
})
export class FaqDialogComponent implements OnInit {
  @ViewChild('tt') tt: Table;

  urlhelp:string;

  links: any[] = [];



  list = [
    { id:1, pageReference:"invoice", title: "tsdtst dtsatd yasdtyast ydasy dyasdt ydyasdt y tsdtst dtsatd yasdtyast ydasy dyasdt ydyasdt y", link: 'xxxxxx.com.br',
     descripion:"satd yasdtyast ydasy dyasdt ydyasdt <b> fsdf sdf sdfds fsd fds f</b>y satd yasdtyast ydasy dyasdt ydyasdt y satd yasdtyast ydasy dyasdt ydyasdt y ",
     videos:[{id:1, title: "video 1", link: 'https://www.youtube.com/embed/ZSfCjMzmrgM', description:"", thumbnail:"", video:""}]},
    { id:2, pageReference:"invoice", title: "tsdtst dtsatd yasdtyast ydasy dyasdt ydyasdt y tsdtst dtsatd yasdtyast ydasy dyasdt ydyasdt y", link: 'xxxxxx.com.br',
     descripion:"dsf dsf sdf dfdsf sdfdsfdsf sdf sdffdsfsdf sdsdf ds",
     videos:[{id:1, title: "video 1", link: 'https://www.youtube.com/embed/ZSfCjMzmrgM', description:"", thumbnail:"", video:""},
     {id:2, title: "video 2", link: 'https://www.youtube.com/embed/u1JPDFloGOg', description:"", thumbnail:"", video:""}
    ]},
    { id:3, pageReference:"invoice", title: "tsdtst dtsatd yasdtyast ydasy dyasdt ydyasdt y tsdtst dtsatd yasdtyast ydasy dyasdt ydyasdt y", link: 'xxxxxx.com.br',
     descripion:"", videos:[]},
    { id:4, pageReference:"invoice", title: "tsdtst dtsatd yasdtyast ydasy dyasdt ydyasdt y tsdtst dtsatd yasdtyast ydasy dyasdt ydyasdt y", link: 'xxxxxx.com.br',
     descripion:"", videos:[]},
    { id:5, pageReference:"invoice", title: "tsdtst dtsatd yasdtyast ydasy dyasdt ydyasdt y tsdtst dtsatd yasdtyast ydasy dyasdt ydyasdt y", link: 'xxxxxx.com.br',
     descripion:"", videos:[]},

  ];


  constructor(public dialog: DialogService,
              private router: Router,
              private faqService: FaqService) { }

  ngOnInit() {
    this.links = this.faqService.getLinks();

  }

  openFaq(question: Question):void{

    this.dialog.open(FaqDialogViewComponent, {
      height: '85vh',
      width: '930px',
      dismissableMask: false,
      data: { question
      },
      header: question.title,
          }).onClose.subscribe(
      () => {

      });


  }

  getUrlAtual():void{
    this.links.forEach(link => {
      if (this.router.url.indexOf(link.key) != -1){
        this.urlhelp = link.link;
        var win = window.open(link.link, '_blank');
        win.focus();
        return;
      }
    })
  }
}
