import { saveAs } from 'file-saver';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { SecuritiesPropertyFilter } from 'src/app/models/dtos/securities-property/securities-property-filter';
import { PaginationFilter } from 'src/app/models/pagination-filter';
import { SecuritiesProperty } from 'src/app/models/securities-property/securities-property';
import { environment } from 'src/environments/environment';
import { SecuritiesPropertyHistoric } from 'src/app/models/securities-property/securities-property-historic';
import { InviteUse } from 'src/app/models/securities-property/invite-use';

@Injectable({
  providedIn: 'root'
})
export class SecuritiesPropertyService {

  env:string = environment.apiHost + '/api-core/secure/securities-property';
  constructor(private http: HttpClient) { }

  getAllByFilter(filter:SecuritiesPropertyFilter,paginationFilter: PaginationFilter) : Observable<any> {
    let params = new HttpParams();
    let sort;
    if (paginationFilter.sort) {
      sort = paginationFilter.sort;
    }
    if (paginationFilter.order) {
      sort = (sort ? sort + ',' : '') + paginationFilter.order;
    }
    if (sort) {
      params = params.set('sort', sort);
    }
    params = params.set('page', paginationFilter.page.toString());
    params = params.set('size', paginationFilter.size.toString());
    return this.http
      .post(this.env + '/get-all-by-filter', filter, {
        params: params,
      })
      .pipe(map((response: any) => response));
  }

  getLast() : Observable<number> {
    return this.http.get<number>(this.env + '/get-last');

  }

  saveInLot(nStart: number, nEnd: number) : Observable<any> {
    return this.http.post(this.env + '/create-securities-in-lot/'+nStart +'/'+ nEnd,null).pipe(map((response: any) => response));
  }

  save(securitiesProperty:SecuritiesProperty) : Observable<any> {
    return this.http.post(this.env + '/save',securitiesProperty).pipe(map((response: any) => response));
  }

  getHistoric(id:number) : Observable<SecuritiesPropertyHistoric[]> {
    return this.http.get<SecuritiesPropertyHistoric[]>(this.env + '/get-historic/'+id);
  }

  getInviteUsed(id:number) : Observable<InviteUse[]> {
    return this.http.get<InviteUse[]>(this.env + '/list-invite/'+id);
  }

}
