import { ShipType } from './ship-type';
import { Shipyard } from './shipyard';

export class ShipyardModel {
    id: number;
    name: string;
    length: number;
    height: number;
    beam: number;
    commercialLength: number;
    weightWithoutMotor: number;
    weightWithMotor: number;
    passengersDay: number;
    passengersNight: number;
    cabins: number;
    bathrooms: number;
    modelYear: number;
    shipType: ShipType;
    shipyard: Shipyard;

    constructor() {
        this.shipyard = new Shipyard();
    }
}
