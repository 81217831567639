<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{'Comparação de serviços' | translate}}</span>
        <button class="fab-button primary" appFiltersButton pTooltip="Exibir/Ocultar Filtros"
                tooltipPosition="left">
                <i class="pi pi-filter"></i>
        </button>
    </p-header>
    <div class="subtitles">
        <fieldset>
            <legend>Legenda</legend>
            <div class="badges">
                <i class="without-service-comparation pi pi-circle-on"></i>
                Sem serviço de comparação
                <i class="negative-value pi pi-circle-on"></i>
                Desconto atual maior que novo valor
            </div>
        </fieldset>
    </div>
    <form class="filters" (submit)="search()" appFilters>
        <div class="filter-row">
            <div class="form-control">
                <label>{{'BOAT' | translate}}</label>
                <p-multiSelect [defaultLabel]="'BOAT' | translate" [options]="boatListOptions"
                    appendTo="body" [(ngModel)]="filter.boatIds" [title]="'BOAT' | translate" [ngModelOptions]="{standalone: true}"
                    [filter]=false selectedItemsLabel="{0} itens selecionados">
                </p-multiSelect>
            </div>
            <div class="form-control">
                <label>{{'SERVICE' | translate}}</label>
                <p-multiSelect [defaultLabel]="'SERVICE' | translate" [options]="serviceListOptions"
                    appendTo="body" [(ngModel)]="filter.serviceIds" [title]="'SERVICE' | translate" [ngModelOptions]="{standalone: true}"
                    [filter]=false selectedItemsLabel="{0} itens selecionados">
                </p-multiSelect>
            </div>
            <div class="form-control">
                <label for="shipType">{{'TYPE' | translate}}</label>
                <p-dropdown [options]="shipTypeOptions" [(ngModel)]="filter.shipType" name="shipType" [ngModelOptions]="{standalone: true}"
                    appendTo="body">
                </p-dropdown>
            </div>
            <div class="form-control">
                <label for="contractStartDateFrom">{{'inicio de serviço de' | translate}}</label>
                <p-calendar dateFormat="dd/mm/yy" name="contractStartDateFrom" rPCalendarMask appendTo="body"
                    [(ngModel)]="filter.contractStartDateFrom" [showIcon]="true" i18n [monthNavigator]="true" [ngModelOptions]="{standalone: true}"
                    [yearNavigator]="true" [yearRange]="'2019:2030'">
                </p-calendar>
            </div>
            <div class="form-control">
                <label for="contractStartDateTo">{{'inicio de serviço até' | translate}}</label>
                <p-calendar dateFormat="dd/mm/yy" name="contractStartDateTo" rPCalendarMask [(ngModel)]="filter.contractStartDateTo"
                    appendTo="body" [showIcon]="true" i18n [monthNavigator]="true" [yearNavigator]="true" [ngModelOptions]="{standalone: true}"
                    [yearRange]="'2019:2030'">
                </p-calendar>
            </div>
            <div class="form-control">
                <label for="contractEndDateFrom">{{'fim de serviço de' | translate}}</label>
                <p-calendar dateFormat="dd/mm/yy" name="contractEndDateFrom" rPCalendarMask appendTo="body"
                    [(ngModel)]="filter.contractEndDateFrom" [showIcon]="true" i18n [monthNavigator]="true" [ngModelOptions]="{standalone: true}"
                    [yearNavigator]="true" [yearRange]="'2019:2030'">
                </p-calendar>
            </div>
            <div class="form-control">
                <label for="contractEndDateTo">{{'fim de serviço até' | translate}}</label>
                <p-calendar dateFormat="dd/mm/yy" name="contractEndDateTo" rPCalendarMask [(ngModel)]="filter.contractEndDateTo"
                    appendTo="body" [showIcon]="true" i18n [monthNavigator]="true" [yearNavigator]="true" [ngModelOptions]="{standalone: true}"
                    [yearRange]="'2019:2030'">
                </p-calendar>
            </div>
            <div class="buttons">
                <div class="form-button">
                    <button (click)="clear()" class="easymarine danger" type="button">{{ 'CLEAR' | translate }}</button>
                </div>
                <div class="form-button">
                    <button class="easymarine primary" type="submit">{{ 'FIND' | translate }}</button>
                </div>
            </div>
        </div>
    </form>

    <div class="table">
        <p-table #tt [value]="comparations" styleClass="easy-table"
            autoLayout="true"  [lazy]="true">
            <ng-template pTemplate="caption">
                <div style="display: flex; width: 100%; justify-content: space-between;">
                    <div class="buttons-header-table">
                        <!-- A ser implementado <button class="btn-csv-xls easymarine" label="Exportar XLS" (click)="exportTableXls()"
                            pTooltip="A exportação será de acordo com os filtros selecionados" tooltipPosition="top">
                            <span>Exportar XLS</span>
                            <i class="pi pi-file-excel"></i>
                        </button>
                        <button class="btn-pdf easymarine" type="button" (click)="exportTablePdf()">
                            <span>Exportar PDF</span>
                            <i class="pi pi-file-pdf"></i>
                        </button> -->
                        <button class="primary easymarine" type="button" (click)="openModalConfigurations()">
                            <span>Configurar comparação de serviços</span>
                        </button>
                    </div>
                    <span style="font-weight: bold">{{ 'TOTAL-REGISTERS' | translate }}:
                        {{comparations.length}}</span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr *ngIf="totals" [ngClass]="{'service-alert': !allcomparationsInTotals}">
                    <th class="th-border-left">Mensal</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th class="text-right">{{totals.value | currency : 'BRL'}}</th>
                    <th class="text-right">{{totals.discount | currency : 'BRL'}}</th>
                    <th class="text-right">{{totals.dueDateDiscount | currency : 'BRL'}}</th>
                    <th class="text-right">{{totals.totalValue | currency : 'BRL'}}</th>
                    <th></th>
                    <th class="text-right">{{(totals.totalValueComparation ? totals.valueComparation : null) | currency : 'BRL':true:'1.2-2'}}</th>
                    <th class="text-right">{{(totals.totalValueComparation ? totals.totalValueComparation : null) | currency : 'BRL':true:'1.2-2'}}</th>
                    <th class="text-right">{{(totals.totalValueComparation ? (totals.totalValueComparation - totals.totalValue) : null) | currency : 'BRL':true:'1.2-2'}}</th>
                    <th rowspan="2" class="text-center">{{(totals.totalValueComparation ? calcPercentage(totals.totalValue, totals.totalValueComparation) : null) | percent: '1.0-2'}}</th>
                </tr>
                <tr *ngIf="totals" [ngClass]="{'service-alert': !allcomparationsInTotals}">
                    <th class="th-border-left">Anual</th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th class="text-right">{{(totals.value*12) | currency : 'BRL':true:'1.2-2'}}</th>
                    <th class="text-right">{{(totals.discount*12) | currency : 'BRL':true:'1.2-2'}}</th>
                    <th class="text-right">{{(totals.dueDateDiscount*12) | currency : 'BRL':true:'1.2-2'}}</th>
                    <th class="text-right">{{(totals.totalValue*12) | currency : 'BRL':true:'1.2-2'}}</th>
                    <th></th>
                    <th class="text-right">{{(totals.valueComparation ? (totals.valueComparation*12) : null) | currency : 'BRL':true:'1.2-2'}}</th>
                    <th class="text-right">{{(totals.totalValueComparation ? (totals.totalValueComparation*12) : null) | currency : 'BRL':true:'1.2-2'}}</th>
                    <th class="text-right">{{(totals.totalValueComparation ? ((totals.totalValueComparation - totals.totalValue)*12) : null) | currency : 'BRL':true:'1.2-2'}}</th>
                </tr>
                <tr>
                    <th>{{ 'BOAT' | translate }}</th>
                    <th>{{ 'TYPE' | translate }}</th>
                    <th>{{ 'TC' | translate }}</th>
                    <th>{{ 'SERVICE' | translate }} Atual</th>
                    <th>{{ 'Valor bruto' | translate }}</th>
                    <th>{{ 'DISCOUNT' | translate }}</th>
                    <th>{{ 'DUE-DATE-DISCOUNT' | translate}}</th>
                    <th>{{ 'Valor liquido' | translate }}</th>
                    <th>{{ 'SERVICE' | translate }}</th>
                    <th>{{ 'Novo valor bruto' | translate }}</th>
                    <th>{{ 'Novo valor liquido' | translate }}</th>
                    <th>{{ 'Diferença' | translate }}</th>
                    <th  class="text-center">% Diferença</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-service>
                <tr [ngClass]="{'service-alert': !service.valueComparation, 'row-negative-value': service.totalComparation < 0}">
                    <td >{{service.boatName}}</td>
                    <td>{{service.shipType.toUpperCase() | translate}}</td>
                    <td>{{service.commercialLength}}</td>
                    <td>{{service.serviceName}}</td>
                    <td class="text-right">{{service.value | currency : 'BRL':true:'1.2-2'}}</td>
                    <td class="text-right">{{service.discount | currency : 'BRL':true:'1.2-2'}}</td>
                    <td class="text-right">{{service.dueDateDiscount | currency : 'BRL':true:'1.2-2'}}</td>
                    <td class="text-right">{{service.total | currency : 'BRL':true:'1.2-2'}}</td>
                    <td>{{service.serviceNameComparation}}</td>
                    <td class="text-right">{{service.valueComparation | currency : 'BRL':true:'1.2-2'}}</td>
                    <td class="text-right">{{service.totalComparation | currency : 'BRL':true:'1.2-2'}}</td>
                    <td class="text-right">{{service.difference | currency : 'BRL':true:'1.2-2'}}</td>
                    <td class="text-center">{{(service.totalComparation ? calcPercentage(service.total , service.totalComparation) : null) | percent: '1.0-2'}}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-fieldset>
