import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Address } from 'src/app/models/address';
import { CustomerService } from 'src/app/services/customer.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-fast-customer-edit-form',
  templateUrl: './fast-customer-edit-form.component.html',
  styleUrls: ['./fast-customer-edit-form.component.scss'],
})
export class FastCustomerEditFormComponent implements OnInit {
  imgPreviewPhoto: string;
  address: Address = new Address();
  customerFastEdit: any = new Object();
  maritalStatusList: any[] = [
    { label: 'Selecione', value: null },
    { label: 'Solteiro', value: 'Solteiro' },
    { label: 'Casado', value: 'Casado' },
    { label: 'Divorciado', value: 'Divorciado' },
    { label: 'Viúvo', value: 'Viuvo' },
  ];
  notEditCpf: boolean = true;
  customerFastEditForm: FormGroup = new FormGroup({
    id: new FormControl<number | null>(null),
    name: new FormControl<string | null>(null, Validators.required),
    federalId: new FormControl<string | null>(null, Validators.required),
    phone: new FormControl<string | null>(null, Validators.required),
    residentialAddress: new FormGroup({
      id: new FormControl(null),
      zip: new FormControl(null, Validators.required),
      country: new FormControl(null, Validators.required),
      state: new FormControl(null, Validators.required),
      city: new FormControl(null, Validators.required),
      district: new FormControl(null, Validators.required),
      street: new FormControl(null, Validators.required),
      number: new FormControl(null, Validators.required),
      complement: new FormControl(null),
    }),
    titleNumber: new FormControl<string | null>(null),
    authorized: new FormControl<string | null>(null),
    photoUrl: new FormControl<string | null>(null),
    registeredBy: new FormControl<string | null>(null),
    boatsNames: new FormControl<string | null>(null),
    email: new FormControl<string | null>(null),
    maritalStatus: new FormControl<string | null>(null, Validators.required),
    codRegistration: new FormControl<string | null>(null),
    accessGroupName: new FormControl<string | null>(null),
  });
  constructor(
    private config: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
    private spinner: NgxSpinnerService,
    private customerService: CustomerService
  ) {}

  ngOnInit() {
    if (this.config?.data) {
      this.customerFastEdit = this.config.data;
      this.address = this.customerFastEdit.residentialAddress;
      this.imgPreviewPhoto = this.customerFastEdit.photoUrl;
      this.loadForm(this.customerFastEdit);
      let federalIdCleaned = this.customerFastEdit.federalId;
      federalIdCleaned = federalIdCleaned?federalIdCleaned.replaceAll('.','').replaceAll('-','').replace(/^0+/, ''):null;
      let codRegistrationCleaned = this.customerFastEdit.codRegistration;
      codRegistrationCleaned = codRegistrationCleaned?codRegistrationCleaned.replaceAll('.','').replaceAll('-','').replace(/^0+/, ''):null;
      if (
        federalIdCleaned == codRegistrationCleaned
      ) {
        this.notEditCpf = false;
      }
    }
  }

  loadForm(customer: any) {
    this.customerFastEditForm.get('id').setValue(customer.id);
    this.customerFastEditForm.get('name').setValue(customer.name);
    this.customerFastEditForm.get('federalId').setValue(customer.federalId);
    this.customerFastEditForm.get('phone').setValue(customer.phone);
    this.customerFastEditForm
      .get('residentialAddress')
      .setValue(customer.residentialAddress);
    this.customerFastEditForm.get('titleNumber').setValue(customer.titleNumber);
    this.customerFastEditForm.get('authorized').setValue(customer.authorized);
    this.customerFastEditForm.get('photoUrl').setValue(customer.photoUrl);
    this.customerFastEditForm
      .get('registeredBy')
      .setValue(customer.registeredBy);
    this.customerFastEditForm.get('boatsNames').setValue(customer.boatsNames);
    this.customerFastEditForm.get('email').setValue(customer.email);
    this.customerFastEditForm
      .get('maritalStatus')
      .setValue(customer.maritalStatus);
    this.customerFastEditForm
      .get('codRegistration')
      .setValue(customer.codRegistration);
    this.customerFastEditForm
      .get('accessGroupName')
      .setValue(customer.accessGroupName);
  }

  receiveUploadUrlPhoto($event) {
    this.customerFastEditForm.get('photoUrl').setValue($event);
  }

  receiveAddress(receive: any) {
    this.customerFastEditForm
      .get('residentialAddress')
      .setValue(receive.addressForm.value);
  }

  close(): void {
    this.dialogRef.close(null);
  }

  save(): void {
    if (this.customerFastEditForm.invalid) {
      this.customerFastEditForm.markAllAsTouched();
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Preencha todos os campos obrigatórios!',
      });
      return;
    }
    this.spinner.show();
    this.customerService
      .saveFastEdit(this.customerFastEditForm.value)
      .subscribe({
        next: (customer) => {
          this.spinner.hide();
          this.dialogRef.close(customer);
        },
        error: (error) => {
          console.log(error);
          this.spinner.hide();
        },
      });
  }
}
