<div class="form-group" *ngIf="userHierarchy === 0">
  <div class="p-col-3 form-control" tooltipPosition="bottom"
    pTooltip="Define como será escolhido o financeiro para o faturamento (visível apenas para o suporte easymarine)">
    <label for="FinancialMultipleCompanyByCustomer">{{ 'MULTIPLE-BILLING-COMPANY' | translate}}</label>
    <p-dropdown [options]="financialMultipleCompaniesOptions"
        appendTo="body"
        [(ngModel)]="slingConfig.financialMultipleCompaniesType"
        (onHide)="change($event)"
        [required]="true"
        name="FinancialMultipleCompany">
    </p-dropdown>
  </div>
</div>
