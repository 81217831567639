import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-log-billing-nf',
  templateUrl: './customer-log-billing-nf.component.html',
  styleUrls: ['./customer-log-billing-nf.component.scss']
})
export class CustomerLogBillingNfComponent implements OnInit {

  @Input() customerLog;
  @Input() isNew;

  oldDatasMap;

  constructor() { }

  ngOnInit(): void {
    this.oldDatasMap = new Map(Object.entries(this.customerLog.oldDatas));
  }

  isChangedIssRetidoFonte(){
    return this.oldDatasMap.has('issRetidoFonte') && this.customerLog.oldDatas.issRetidoFonte != this.customerLog.issRetidoFonte;
  }

  getIssRetidoFonte(): boolean {
    let issRetidoFonte = this.customerLog.issRetidoFonte;
    if(this.isChangedIssRetidoFonte() && !this.isNew) {
      issRetidoFonte = this.customerLog.oldDatas.issRetidoFonte;
    }
    return issRetidoFonte;
  }

}
