import {Component, OnInit} from '@angular/core';
import {Customer} from '../../../../models';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {ToastService} from '../../../../services/toast.service';
import {CustomerService} from '../../../../services/customer.service';
import {DropdownChangeEvent} from 'primeng/dropdown';
import Swal from 'sweetalert2';
import {CustomerDependentDTO} from '../../../../models/dtos/customer/customerDependentDTO';
import {NgxSpinnerService} from 'ngx-spinner';
import { SelectItem } from 'primeng/api';
import { DependencyType } from 'src/app/models/enums/customer/dependency-type';

@Component({
  selector: 'app-customer-dependent',
  templateUrl: './customer-dependent.component.html',
  styleUrls: ['./customer-dependent.component.scss']
})
export class CustomerDependentComponent implements OnInit {

  mainCustomer: Customer;
  customerList: Customer[];
  dependentsList: Customer[];
  newDependentCustomer: Customer;
  dependencyType: SelectItem;
  dependencyTypeList: SelectItem[] = [ 
    { label: 'Pai', value: DependencyType.FATHER },
    { label: 'Mãe', value: DependencyType.MOTHER },
    { label: 'Cônjuge', value: DependencyType.SPOUSE },
    { label: 'Filho(a)', value: DependencyType.CHILD },
    { label: 'Prestador de Serviço', value: DependencyType.SERVICE_PROVIDER },
    { label: 'Outro', value: DependencyType.OTHER }
  ];

  constructor(private dialogConfig: DynamicDialogConfig,
              private spinner: NgxSpinnerService,
              private toastService: ToastService,
              private customerService: CustomerService) {
  }

  ngOnInit(): void {
    this.mainCustomer = this.dialogConfig?.data?.selectedCustomer;
    this.findAll();
  }


  private loadCustomerDependents() {
    this.customerService.getCustomerDependents(this.mainCustomer.id).subscribe({
      next: (value) => {
        this.dependentsList = value;
      }, error: err => {
        this.toastService.error(err.error.data.exception.message);
      }
    });
  }

  private loadAllCustomers() {
    this.customerService.getAll().subscribe({
      next: value => {
        this.customerList = value.filter(customer => customer.active === true
          && customer.mainDependentCustomer === null
          && customer.id !== this.mainCustomer.id);
      }, error: err => {
        this.toastService.error(err.error.data.exception.message);
      }
    });
  }

  selectDependent(event: DropdownChangeEvent) {
    this.newDependentCustomer = event.value;
  }

  selectDependencyType(event: DropdownChangeEvent) {
    this.dependencyType = event.value;
  }


  addDependent() {
    if (this.newDependentCustomer == null || this.newDependentCustomer.id === 0) {
      this.toastService.warning('Você precisa escolher um cliente para adicionar como dependente');
      return;
    }
    Swal.fire({
      title: 'Confirmação',
      text: `Tem certeza que deseja adicionar o cliente ${this.newDependentCustomer.name} como ${this.dependencyType.label} e dependente do cliente ${this.mainCustomer.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        const customerDependentToadd = new CustomerDependentDTO(this.mainCustomer.id, this.newDependentCustomer.id, this.dependencyType.value);
        this.spinner.show();
        this.customerService.addDependentToCustomer(customerDependentToadd).subscribe({
          next: _ => {
            this.spinner.hide();
            this.toastService.success('Cliente adicionado como dependente com sucesso!');
            this.newDependentCustomer = null;
            this.findAll();
          }, error: err => {
            this.spinner.hide();
            this.toastService.error(err.error.data.exception.message);
          }
        });
      }
    });
  }

  private findAll() {
    this.loadAllCustomers();
    this.loadCustomerDependents();
  }

  deleteDependent(dependent: any) {
    Swal.fire({
      title: 'Confirmação',
      text: `Tem certeza que deseja retirar o cliente ${dependent.name} como dependente do cliente ${this.mainCustomer.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.customerService.deleteDependentFromMainCustomer(dependent.id).subscribe({
          next: _ => {
            this.spinner.hide();
            this.toastService.success('Cliente retirado como dependente com sucesso!');
            this.findAll();
          }, error: err => {
            this.spinner.hide();
            this.toastService.error(err.error.data.exception.message);
          }
        });
      }
    });
  }

}
