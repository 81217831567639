export class InvoiceCustomerDTO {
  referenceDate: Date;
  competenceDate: Date;
  dueDate: Date;
  referenceMonth: number;
  referenceYear: number;
  customerIds: number[];
  orderNumbers: number[];

  constructor(referenceDate: Date, dueDate: Date, competenceDate: Date, customerIds: number[],
              orderNumbers: number[], referenceMonth: number) {
    this.referenceDate = referenceDate;
    this.dueDate = dueDate;
    this.competenceDate = competenceDate;
    this.customerIds = customerIds;
    this.orderNumbers = orderNumbers;
    this.referenceMonth = referenceMonth;
  }
}
