import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Sling, NavigationPlan } from '../models';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class NavigationPlanService {

    constructor(private http: HttpClient) { }

    getAll(): Observable<NavigationPlan[]> {
        return this.http.get(
            environment.apiHost + '/secure/navigation-plans/')
            .pipe(map((response: any) => response.data.navigationPlans as NavigationPlan[]));
    }

    getById(id: number): Observable<NavigationPlan> {
        return this.http.get(
            environment.apiHost + '/secure/navigation-plan/' + id)
            .pipe(map((response: any) => response.data.navigationPlan as NavigationPlan));
    }

    getByFederalId(federalId: string): Observable<Sling[]> {
        return this.http.get(
            environment.apiHost + '/secure/navigation-plans-by-federalid/' + federalId
        ).pipe(map((response: any) => response.data.slings as Sling[]));
    }

    generatedPdfByIdsSling(idsSling: number[]): Observable<any> {
        return this.http.get(environment.apiHost + '/secure/navigation-plan/pdf/' + idsSling.toString(),
            { responseType: 'blob' }).pipe(map((response: any) => response));
    }
}
