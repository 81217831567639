<section class="container" *ngIf="items">
  <span *ngFor="let item of items; let i = index">

    <div *ngIf="i !== 0" class="align-arrow">
      <img [ngClass]="{'svg-primary': item.future}" src="assets/images/movements/arrow-down-green.svg" alt="">
    </div>

    <div class="card">
      <div class="box-icon" [ngClass]="{'bg-green': !item.future}">
        <img [src]="getIcon(i)" alt="">
      </div>
      <div class="box-text" [ngClass]="{'border-green': !item.future}">
        <h4 class="title">
          <app-movement-location
            [params]="{ type: item.type, name: item.name, vacancyCode: item.vacancyCode}"
          ></app-movement-location>
        </h4>
        <div class="currentLocation" *ngIf="item.current">
          <img src="assets/images/movements/movement-location/location.png" alt="">
          <b>Local Atual</b>
        </div>
      </div>
    </div>

  </span>

  <div class="divider"></div>

  <div class="card card-total bg-green">
    <div class="box-text">

        Total de movimentações realizadas: <h3 class="title">{{ dataView.finished }}</h3>

    </div>
  </div>

  <div class="card card-total">
    <div class="box-text">

      Total de movimentações previstas: <h3 class="title">{{ dataView.total }}</h3>

    </div>
  </div>

</section>



