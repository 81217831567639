<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{ 'TITLE-QRCODES-BOAT' | translate }}</span>
    </p-header>

    <div class="table">
        <p-table #tt [value]="boats" autoLayout="true" [(selection)]="selectedBoats"
            styleClass="easy-table" [globalFilterFields]="['name', 'shipyardName', 'shipyardModelName', 'tieRegister']">
            <ng-template pTemplate="caption">
                <button class="danger easymarine" type="button" (click)="generatePDF()"
                    [disabled]="!selectedBoats || selectedBoats.length === 0">{{ 'EXPORT-PDF' | translate }}</button>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText size="50"
                        placeholder="Filtro por Embarcação, Fabricante, Modelo ou registro" [(ngModel)]="globalFilter"
                        (input)="filterGlobal($event)" style="width:auto">
                </span>
                <span class="total-registers">{{ 'TOTAL-REGISTERS' | translate }}:
                    {{boats?.length}}
                </span>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th class="text-center">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th [pSortableColumn]="'id'">
                        Id
                        <p-sortIcon [field]="'id'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'name'">
                        Embarcação
                        <p-sortIcon [field]="'name'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'vacancy'">
                        Vaga
                        <p-sortIcon [field]="'vacancy'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'shipyardName'">
                        Fabricante
                        <p-sortIcon [field]="'shipyardName'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'shipyardModelName'">
                        Modelo
                        <p-sortIcon [field]="'shipyardModelName'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'tieRegister'">
                        Registro
                        <p-sortIcon [field]="'tieRegister'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'active'">
                        Ativo
                        <p-sortIcon [field]="'active'"></p-sortIcon>
                    </th>
                    <th>QR Code</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-boat>
                <tr>
                    <td class="text-center">
                        <p-tableCheckbox [value]="boat">
                        </p-tableCheckbox>
                    </td>
                    <td>{{boat.id}}</td>
                    <td>{{boat.name}}</td>
                    <td>{{boat?.vacancy?.code}}</td>
                    <td>{{boat.shipyardName}}</td>
                    <td>{{boat.shipyardModelName}}</td>
                    <td>{{boat.tieRegister}}</td>
                    <td>
                        <span class="badge badge-pill" [ngClass]="{'success':boat.active, 'danger': !boat.active}">
                            {{boat.active ? 'Sim' : 'Não'}}
                        </span>
                    </td>
                    <td>
                        <a (click)="openQrCode(boat)" style="color: #0095ff; cursor: pointer;">
                            Abrir
                        </a>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="4">
                        Nenhuma embarcação item encontrada
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>

</p-fieldset>
