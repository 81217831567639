import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { AccessGroupEnum } from 'src/app/models/dtos/access-controller/access-group-enum';
import { AccessPermission } from 'src/app/models/dtos/access-controller/accessPermission';
import { NewVisitorPermissionDTO } from 'src/app/models/dtos/access-controller/newVisitorPermissionDTO';
import { Visitor } from 'src/app/models/dtos/access-controller/visitor';
import { VisitorFilter } from 'src/app/models/dtos/access-controller/visitorFilter';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VisitorService {
  env: string = environment.apiHost + '/api-core/secure/visitor/';
  constructor(private http: HttpClient) {}

  save(accessPoint: Visitor): Observable<Visitor> {
    return this.http
      .post(this.env + 'save', accessPoint)
      .pipe(map((response: any) => response as Visitor));
  }

  findByFilter(filter: VisitorFilter, campos?: string): Observable<any> {
    if (campos == undefined) {
      campos = '';
    }
    let headers = new HttpHeaders();
    headers = headers.set('campos', campos);
    return this.http
      .post(this.env + 'find-by-filter', filter, { headers: headers })
      .pipe(map((response: any) => response));
  }

  getById(id: number): Observable<Visitor> {
    return this.http
      .get(this.env + 'find-by-id/' + id)
      .pipe(map((response: any) => response as Visitor));
  }

  saveAccessPermission(
    accessPermission: AccessPermission,
    tituloId?: number
  ): Observable<any> {
    if (tituloId == undefined || tituloId === null) {
      tituloId = 0;
    }
    return this.http
      .post(this.env + 'access-permission/save/' + tituloId, accessPermission)
      .pipe(map((response: any) => response as AccessPermission));
  }

  getAccessPermissionByVisitor(
    colectid: number,
    permissionType: AccessGroupEnum
  ): Observable<AccessPermission[]> {
    return this.http
      .get(
        this.env +
          'access-permission/find-by-visitor/' +
          colectid +
          '/' +
          permissionType
      )
      .pipe(map((response: any) => response as AccessPermission[]));
  }

  getHistoric(id: number): Observable<any[]> {
    return this.http
      .get(this.env + 'access-permission/historic/visitor/' + id)
      .pipe(map((response: any) => response as any[]));
  }

  saveNewVisitorPermission(
    dto: NewVisitorPermissionDTO,
    title: number
  ): Observable<any> {
    return this.http
      .post(this.env + 'new-visitor-with-access/' + title, dto)
      .pipe(map((response: any) => response as any));
  }

  findByFederalId(federalId: string): Observable<Visitor> {
    return this.http
      .get(this.env + 'find-by-federal-id/' + federalId)
      .pipe(map((response: any) => response as Visitor));
  }
}
