import moment from "moment";
import { MovementObjectiveCode } from "src/app/models/enums/movements/movement-objective-code";
import { MovementStatus } from "src/app/models/enums/movements/movement-status";

export class FilterMovementReport {
    customerIds?: number[];
	boatIds?: number[];
  sailorIds?: number[];
	startDate?: Date;
	endDate?: Date;
	destinyIds?: number[];
	originIds?: number[];
	objetives?: MovementObjectiveCode[];
	movementStatus?: MovementStatus;
	isCounted?: boolean;
	exempt?: boolean;
	systemAdjustment?: boolean;

	constructor() {
		this.startDate = moment().utc(true).toDate();
		this.endDate = moment().utc(true).toDate();
	}
}
