import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {MarinaImplantation} from '../models/marina-implantation';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {StorageUtil} from '../utils/storage.util';

@Injectable()
export class MarinaImplantationService {
  constructor(private http: HttpClient) {
  }

  getMarinaImplantationByMarinaId(): Observable<MarinaImplantation> {
    const marinaId = StorageUtil.getMarinaId();
    return this.http.get(environment.apiHost + '/secure/marina-implantation/' + marinaId)
      .pipe(map((response: any) => response.data.marinaImplantation));
  }
}
