import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {GenerateDocumentResponse} from "../models/dtos/generate-document-response";
import {NotaFiscalConsumidorResponse} from "../models/dtos/nota-fiscal/NotaFiscalConsumidorResponse";

@Injectable({
  providedIn: 'root'
})
export class NotaFiscalService {
  apiNotaFiscal = `${environment.apiHost}/api-notafiscal/secure`

  constructor(
    private http: HttpClient
  ) { }
  updloadDigitalCertificate(form: any): Observable<string> {
    const formData: FormData = new FormData();
    Object.keys(form).forEach((key) => {
      formData.append(key, form[key]);
    });

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'multipart/form-data');

    return this.http.put(environment.apiHost + `/secure/nota-fiscal/certificate`, formData, { headers })
      .pipe(map((response: any) => response.data.arquivo as string));
  }

  generateNFConsumidorList(invoicesIds: string): Observable<GenerateDocumentResponse>{
    return this.http.get(`${this.apiNotaFiscal}/nfconsumidor/generate-list?invoiceIds=${invoicesIds}`)
      .pipe(map((resp: any) => resp as GenerateDocumentResponse))
  }
  generateNFConsumidor(invoicesId: string): Observable<NotaFiscalConsumidorResponse>{
    return this.http.get(`${this.apiNotaFiscal}/nfconsumidor/generate?invoiceId=${invoicesId}`)
      .pipe(map((resp: any) => resp as NotaFiscalConsumidorResponse))
  }
}
