import { Component, OnInit } from '@angular/core';

import { BoatWash } from 'src/app/models/boatWash';
import { BoatWashService } from 'src/app/services/boat-wash.service';
import { EngineTurnService } from 'src/app/services/engine-turn.service';
import { ToastService } from 'src/app/services/toast.service';
import { MessageUtil } from 'src/app/utils/message.util';
import  moment from 'moment';
import { EngineTurn } from 'src/app/models/engine-turn';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { StorageUtil } from 'src/app/utils/storage.util';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-boat-wash-engine-turn-form',
  templateUrl: './boat-wash-engine-turn-form.component.html',
  styleUrls: ['./boat-wash-engine-turn-form.component.scss']
})
export class BoatWashEngineTurnFormComponent implements OnInit {

  boatsWash = [];
  enginesTurn: any[];
  requestDate = new Date();
  washEngineTurnOption: string;
  today = new Date();

  pt = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  };

  constructor(
    private boatWashService: BoatWashService,
    private messageUtil: MessageUtil,
    private toasty: ToastService,
    private engineTurnService: EngineTurnService,
    public dialog: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.boatsWash = this.config.data.boatsWash;
    this.enginesTurn = this.config.data.enginesTurn;
  }


  confirmlRegistration(): void {
    Swal.fire({
      title: 'Confirmar operação?',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      cancelButtonColor: '#d33',
      allowOutsideClick: false,
      preConfirm: () => {
        this.save();
      }
    });
  }

  private save(): void {
    if (this.washEngineTurnOption === 'WASH_AND_ENGINE_TURN') {
      this.createBoatWashFastRegistration();
      this.createEngineTurnFastRegistration();
    } else if (this.washEngineTurnOption === 'WASH_ONLY') {
      this.createBoatWashFastRegistration();
    } else {
      this.createEngineTurnFastRegistration();
    }
  }

  getDayOfWeek(): string {
    if (this.requestDate) {
      switch (this.requestDate.getDay()) {
        case 0:
          return 'Domingo';
        case 1:
          return 'Segunda-feira';
        case 2:
          return 'Terça-feira';
        case 3:
          return 'Quarta-feira';
        case 4:
          return 'Quinta-feira';
        case 5:
          return 'Sexta-feira';
        case 6:
          return 'Sábado';
        default:
          return '';
      }
    }
  }

  private createBoatWashFastRegistration(): void {
    const boatsWash = [];
    this.boatsWash.forEach((b) => {
      const boatWash = new BoatWash();
      boatWash.boatId = b.boatId;
      boatWash.isActive = false;
      boatWash.isSea = b.isSea;
      if (this.requestDate) {
        boatWash.washDate = moment(this.requestDate).utc(true).format('YYYY-MM-DDTHH:mm');
      }
      boatsWash.push(boatWash);
    });
    this.boatWashService.createFastAutoRegistrarionList(boatsWash).subscribe(() => {
      this.dialog.close(null);
      this.toasty.success('Lavação salva com sucesso');
    }, (error) => {
      const exception = error.error.data.exception;
      this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
    }
    );
  }

  private createEngineTurnFastRegistration(): void {
    const enginesTurn = [];
    this.enginesTurn.forEach((et) => {
      const engineTurn = new EngineTurn();
      engineTurn.boatId = et.boatId;
      engineTurn.isActive = false;
      if (this.requestDate) {
        engineTurn.engineTurnDate = moment(this.requestDate).utc(true).format('YYYY-MM-DDTHH:mm');
      }
      engineTurn.isSea = et.isSea;
      enginesTurn.push(engineTurn);
    });
    this.engineTurnService.createFastAutoRegistrarionList(enginesTurn).subscribe(
      () => {
        this.dialog.close(null);
        this.toasty.success('Giro de motor salvo com sucesso');
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }
}
