import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ImportResult } from '../models/import-result';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class ImportResultService {

    constructor(private http: HttpClient) {
    }

    findBySource(source: string): Observable<ImportResult> {
        return this.http.get(environment.apiHost + '/secure/importResult/source/' + source)
            .pipe(map((response: any) => response.data.result as ImportResult));
    }

    importExcel(files: any[]): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', files[0]);
        let hd = new HttpHeaders();
        hd = hd.set('Content-Type', 'multipart/form-data');
        const options = {
            headers: hd
        };
        return this.http.post(environment.apiHost + '/secure/customers/import-excel', formData, options);
    }
}
