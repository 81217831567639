<div style="padding: 16px;">

    <app-contract-log-general-data *ngIf="isGeneralData" [contractLog]="contractLog" [isNew]="true"></app-contract-log-general-data>
    <app-contract-log-general-data *ngIf="isGeneralData" [contractLog]="contractLog" [isNew]="false"></app-contract-log-general-data>

    <app-contract-log-signer *ngIf="isSigners" [signers]='contractLog.signers' [signersOld]='contractLog.oldDatas.signers' [isNew]='true'></app-contract-log-signer>
    <app-contract-log-signer *ngIf="isSigners" [signers]='contractLog.signers' [signersOld]='contractLog.oldDatas.signers' [isNew]='false'></app-contract-log-signer>

    <div style="margin: 15px;">
        <div class="content-log">
          <div>
            <label>Data/hora</label>
            <label>{{ contractLog.logTime | date: "dd/MM/yyyy - HH:mm" }}</label>
          </div>
          <div>
            <label>Usuário</label>
            <label>{{ contractLog.user?.username }}</label>
          </div>
          <div>
            <label>Origem</label>
            <label>{{ contractLog.origin }}</label>
          </div>
          <div>
            <label>ação</label>
            <label>{{ contractLog.operationLog | translate }}</label>
          </div>
        </div>
        <div class="buttons">
          <button class="easymarine primary" (click)="dialogRef.close()">
            Fechar
          </button>
        </div>
    </div>
</div>