import { InvoiceQuotaParcelDTO } from './invoiceQuotaParcelDTO';

export class InvoiceQuotaDTO {
    invoiceId: number;
    parcelas: InvoiceQuotaParcelDTO[];
    billingPortfolioId: number;

    constructor(invoiceId: number){
        this.invoiceId = invoiceId;
        this.parcelas = [];
    }
}
