<p-fieldset toggleable=true  collapsed=false>
    <p-header class="article-title">
        <div class="article-title">{{'SERVICES-HISTORY' | translate}}</div>
    </p-header>
    <div class="table">
        <p-table [value]="servicesBoatHistory" autoLayout="true">
            <ng-template pTemplate="header">
                <tr>
                    <th>{{ 'SERVICE' | translate }}</th>
                    <th>{{ 'VALUE' | translate}}</th>
                    <th>{{ 'DISCOUNT' | translate}}</th>
                    <th>{{ 'TOTAL' | translate}}</th>
                    <th style="min-width: 115px;">Inicio serviço</th>
                    <th style="min-width: 115px;">Fim serviço</th>
                    <th>{{ 'OBSERVATION' | translate}}</th>
                    <th>{{ 'DUE-DATE-DISCOUNT' | translate}}</th>
                    <th></th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-boatService>
                <tr>
                    <td>{{ boatService.service.name }} </td>
                    <td class="text-right">{{ boatService.value | number:'1.2-2' }}</td>
                    <td class="text-right">
                        {{boatService.discount | number:'1.2-2'}}
                    </td>
                    <td class="text-right">{{ calcTotalService(boatService) | number:'1.2-2' }}
                    </td>
                    <td>
                        {{boatService.contractStartDate | date:'dd/MM/yyyy'}}
                    </td>
                    <td>
                        <div *ngIf="boatService.contractEndDate">
                            {{boatService.contractEndDate | date:'dd/MM/yyyy'}}
                        </div>
                    </td>
                    <td>
                        {{boatService.observation}}
                    </td>
                    <td>
                        {{boatService.dueDateDiscount | number:'1.2-2'}}
                    </td>
                    <td></td>
                    <td>
                        <div class="table-action" pTooltip="Visualizar Log" tooltipPosition="top"
                            (click)="getLogServiceBoat(boatService)">
                            <i class="pi pi-eye"></i>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-fieldset>
