import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomerService } from 'src/app/services/customer.service';
import { ToastService } from 'src/app/services/toast.service';
import { MessageUtil } from 'src/app/utils/message.util';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list-not-block',
  templateUrl: './list-not-block.component.html',
  styleUrls: ['./list-not-block.component.scss']
})
export class ListNotBlockComponent implements OnInit {

  customerNotBlockTable: any[];
  numberOfRows :number = 0;

  constructor(
    private spinner: NgxSpinnerService,
    private toasty: ToastService,
    private customerService: CustomerService,
    private messageUtil: MessageUtil,
    private translate: TranslateService,

  ) { }

  async ngOnInit(): Promise<void> {
  await this.findNotBlock();

  }

  findNotBlock():void{

    this.customerService.getAllNotBlock().subscribe(
      (data) => {
        this.customerNotBlockTable=data;
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      });

  }

  openBoatDeleteConfirmDialog(id):void{

    let titleMessage = null;
    let message = null;
    this.translate.get('DELETE-CONFIRMATION').subscribe((result: string) => { titleMessage = result; });
    this.translate.get('DELETE-CONFIRMATION-MESSAGE').subscribe((result: string) => { message = result; });
    Swal.fire({
      title: titleMessage,
      text: message,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).
      then((result) => {
        if (result.value) {
          this.customerService.deleteList(id).subscribe(
            (data) => {
              if (data) { Swal.fire('Remoção da lista', 'A remoção foi realizada com sucesso.', 'success');}
              else{Swal.fire('Falha ao remover!', 'Não foi possível executar a ação.', 'error');}
            },
            (error) => {
              Swal.fire('Falha ao remover!', error.error.message, 'error');
            },
            () => {
              this.findNotBlock();
            });
        }
      });
  }
}
