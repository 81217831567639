<p-table [value]="slingsMooredSailingCheck" styleClass="sling-table" autoLayout="true">
    <ng-template pTemplate="header">
        <tr>
            <th class="text-center">Origem</th>
            <th class="text-left">Data/Hora</th>
            <th class="text-center">Vaga</th>
            <th class="text-left">Embarcação</th>
            <th class="text-left">Modelo</th>
            <th class="text-center" *ngIf="hasSailorConfig()">Possui Marinheiro</th>
            <th class="text-center">Pedido(s) avulso(s)</th>
            <th class="text-center"></th>
            <th class="text-center"></th>
            <th class="text-center"></th>
            <th class="text-left">Situação</th>
            <th class="text-center"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-slingCheck let-i="rowIndex">
        <tr>
            <!-- Origem -->
            <td class="text-center">
                <img [src]="slingUtil.getImageOriginSling(slingCheck)" [title]="slingUtil.getOriginName(slingCheck)">
            </td>

            <!-- Ordem/Data retorno -->
            <td class="text-center">
                <ng-container *ngIf="!slingCheck.finishedOnWater">
                    <span [ngClass]="{'em-alert': notEqualDate(slingCheck)}" class="sling-time">
                        {{slingCheck.navigationPlan.returnTime | date: notEqualDate(slingCheck) ? 'dd/MM - HH:mm':'HH:mm'}}
                    </span>
                    <span class="sling-time-mobile">
                        <span [ngClass]="{'em-alert': notEqualDate(slingCheck)}">
                            <span *ngIf="notEqualDate(slingCheck)">
                                {{slingCheck.navigationPlan.returnTime | date:'dd/MM'}}
                                <br>
                            </span>
                            {{slingCheck.navigationPlan.returnTime | date: 'HH:mm'}}
                        </span>
                    </span>
                </ng-container>
                <span *ngIf="slingCheck.finishedOnWater" pTooltip="Finalizado na água" tooltipPosition="bottom">
                    <img src="assets/images/check.png" alt="">
                </span>
            </td>

            <!-- Vaga -->
            <td class="text-left" *ngIf="slingCheck.boat.vacancy || !isMobile">
                {{slingCheck.boat.vacancy ? slingCheck.boat.vacancy.code : ""}}
            </td>

            <!-- Embarcação -->
            <td class="text-left">
                {{slingCheck.boat.name}}
            </td>

            <!-- Model -->
            <td class="text-left">
                {{slingCheck.boat.shipyardModel.shipType.toUpperCase() | translate}}&nbsp;
                {{slingCheck.boat.commercialLength}}'
            </td>

            <!-- Possui marinheiro-->
            <td *ngIf="hasSailorConfig()">
                <span *ngIf=" hasBoatSailor(slingCheck)">
                  <img class="size-icon-sling-check" [src]="getImageSailorResponsable()" />
                </span>
            </td>

            <!-- Pedido avulso -->
            <td *ngIf="!isMobile || !slingCheck.finishedOnWater">
                <button *ngIf="!slingCheck.finishedOnWater" class="sling-button" pTooltip="Incluir pedido"
                    tooltipPosition="bottom" (click)="loadMarinaOrders(slingCheck)">
                    <img src="assets/images/pedido.png">
                </button>
            </td>

            <!-- Combustível -->
            <td *ngIf="!isMobile || (!slingCheck.finishedOnWater && (hasFuel(slingCheck) || slingCheck.ownFuelSupply === false)) ">
                <div class="icon-button"
                    *ngIf="!slingCheck.finishedOnWater && (hasFuel(slingCheck) || slingCheck.ownFuelSupply === false)"
                    (click)="checkPendingFuel(slingCheck.marinaOrder.orderItems) && slingCheck.prepare && openDialogFuelOrder(slingCheck, false)">
                    <div *ngIf="hasFuel(slingCheck)"
                        [pTooltip]="slingUtil.getStatusFuel(slingCheck) === 'primary' ? 'Fornecer combustível': 'Pedido atendido'"
                        tooltipPosition="bottom">
                        <div class="container">
                          <img [src]="slingUtil.getImageIconFuel(slingCheck)">
                            <label class="centered" *ngIf="slingCheck.boat.independentTanks;">2T</label>
                        </div>
                    </div>
                    <div class="image-table" *ngIf="!hasFuel(slingCheck) && slingCheck.ownFuelSupply === false">
                      <div class="container">
                        <img [src]="slingUtil.getImageIconFuel(slingCheck)" pTooltip="Abastecimento próprio"
                          tooltipPosition="bottom">
                          <label class="centered" *ngIf="slingCheck.boat.independentTanks;">2T</label>
                      </div>
                    </div>
                    <div class="column flex-badge" *ngIf="hasFuel(slingCheck)">
                        <div class="badge"
                            [ngClass]="{'attempted': !checkPendingFuel(slingCheck.marinaOrder.orderItems)}">
                            {{orderUtil.fuelQuantityView(getFuel(slingCheck.marinaOrder.orderItems))}}
                        </div>
                        <div class="badge fuel-type"
                            [pTooltip]="getFuel(slingCheck.marinaOrder.orderItems).product.name"
                            [ngClass]="{'attempted': !checkPendingFuel(slingCheck.marinaOrder.orderItems)}"
                            tooltipPosition="bottom">
                            {{orderUtil.abbreviatedProductName(getFuel(slingCheck.marinaOrder.orderItems))}}
                        </div>
                    </div>
                </div>
            </td>

            <!-- Consumíveis -->
            <td class="flex1" *ngIf="!isMobile || (hasConsumables(slingCheck) && !slingCheck.finishedOnWater)">
                <div class="icon-button" *ngIf="hasConsumables(slingCheck) && !slingCheck.finishedOnWater"
                    (click)="checkPendingConsumables(slingCheck.marinaOrder.orderItems) && slingCheck.prepare && openDialogConsumableOrder(slingCheck)">
                    <div class="flex1"
                        [pTooltip]="slingUtil.getStatusConsumable(slingCheck) === 'primary' ? 'Fornecer consumíveis': 'Pedido atendido'"
                        tooltipPosition="bottom">
                        <img [src]="slingUtil.getImageIconConsumable(slingCheck)">
                    </div>
                    <div class="start flex-badge">
                        <div class="badge consumable"
                            [ngClass]="{'attempted': !checkPendingConsumables(slingCheck.marinaOrder.orderItems)}">
                            {{countConsumables(slingCheck.marinaOrder.orderItems)}}
                        </div>
                    </div>
                </div>
            </td>

            <!-- Acessórios -->
            <td *ngIf="(hasAcessories(slingCheck) && !slingCheck.finishedOnWater) || !isMobile">
                <div class="icon-button" *ngIf="hasAcessories(slingCheck) && !slingCheck.finishedOnWater"
                    (click)="checkPendingAcessories(slingCheck) && slingCheck.prepare && openDialogSlingAccessories(slingCheck)">
                    <div tooltipPosition="bottom"
                        [pTooltip]="slingUtil.getStatusAcessories(slingCheck) === 'primary' ? 'Fornecer acessórios': 'Pedido atendido'">
                        <img [src]="slingUtil.getImageIconAcessories(slingCheck)">
                    </div>
                    <div class="start flex-badge">
                        <div class="badge consumable" [ngClass]="{'attempted': !checkPendingAcessories(slingCheck)}">
                            {{slingCheck.slingAccessories.length}}
                        </div>
                    </div>
                </div>
            </td>

            <!-- Situação -->
            <td class="text-left boat-status">
                {{slingCheck.boatStatus.toUpperCase() | translate}}
            </td>

            <!-- Ações -->
            <td class="text-center change-boat-status"  pTooltip="Movimentar embarcação" tooltipPosition="bottom">
                <img *ngIf="slingCheck.prepare && slingCheck.slingStatus === 'WATER' && slingCheck.boatStatus === 'MOORED' && !slingCheck.finishedOnWater"
                    class="size-icon-sling-check"
                    [src]="'assets/images/deck' + (slingCheck.slingNextDay ? '_next' : '') + '.png'"
                    (click)="handleActions(slingCheck)" />
                <img *ngIf="slingCheck.prepare && slingCheck.slingStatus === 'WATER' && slingCheck.boatStatus === 'SAILING' && !slingCheck.finishedOnWater"
                    class="size-icon-sling-check"
                    [src]="'assets/images/boat' + (slingCheck.slingNextDay ? '_next' : '') + '.png'"
                    (click)="handleActions(slingCheck)" />
                <img *ngIf="slingCheck.prepare && slingCheck.slingStatus === 'WATER' && slingCheck.boatStatus === 'MOORED' && slingCheck.finishedOnWater"
                    class="size-icon-sling-check"
                    [src]="'assets/images/deck' + (slingCheck.slingNextDay ? '_next' : '') + '.png'"
                    (click)="boatFinished(slingCheck)" />
                <img *ngIf="slingCheck.prepare && slingCheck.slingStatus === 'WATER' && slingCheck.boatStatus === 'SAILING' && slingCheck.finishedOnWater"
                    class="size-icon-sling-check"
                    [src]="'assets/images/boat' + (slingCheck.slingNextDay ? '_next' : '') + '.png'"
                    (click)="boatFinished(slingCheck)" />
            </td>
        </tr>
    </ng-template>
</p-table>
