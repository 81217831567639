import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectItem } from 'primeng/api/selectitem';
import { Customer } from 'src/app/models/customer';
import { SelectorBoatDTO } from 'src/app/models/dtos/selector-boat-DTO';
import { BoatService } from 'src/app/services/boat.service';
import { CustomerService } from 'src/app/services/customer.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { SalesStatesService } from '../split-bill/sales-states.service';

@Component({
  selector: 'app-boat-customer-select',
  templateUrl: './boat-customer-select.component.html',
  styleUrls: ['./boat-customer-select.component.scss'],
})
export class BoatCustomerSelectComponent {
  @Output()
  onSelected: EventEmitter<any> = new EventEmitter();
  @Input()
  boatSingleOrderParams: any;

  searchByCustomer: boolean = false;
  isBoatSingleOrder: boolean = false;

  listBoat: SelectorBoatDTO[];
  boatOptions: SelectItem[] = [];
  selectedBoat: SelectorBoatDTO;
  selectedBoatId: number = 0;

  listCustomer: any[];
  customers: any[] = [];
  selectedCustomer: Customer = new Customer();
  selectedCustomerId: number;
  allOwnerCustomers: Customer[] = [];

  boatId: number;
  customerId: number = 0;
  slingId: number;

  constructor(
    private spinner: NgxSpinnerService,
    private customerService: CustomerService,
    private boatService: BoatService,
    private messageUtil: MessageUtil,
    private salesStatesService: SalesStatesService
  ) {}

  ngOnInit() {
    this.spinner.show();
    this.selectedBoat = new SelectorBoatDTO();

    if (this.boatSingleOrderParams.boatId) {
      this.isBoatSingleOrder = true;
      this.boatId = this.boatSingleOrderParams.boatId;
      this.customerId = this.boatSingleOrderParams.customerId;
      this.slingId = this.boatSingleOrderParams.slingId;
    }

    this.searchByCustomer =
      this.salesStatesService.getSalesConfig().searchByCustomer;

    this.getBoats();

    this.getAllOwnerCustomers();
  }

  getBoats() {
    this.boatService.getAllForSelector().subscribe({
      next: (data) => {
        this.listBoat = data.filter((boat) => boat.active);
        if (this.customerId && this.boatId && this.slingId) {
          this.selectedBoatId = this.boatId;
          const boat = this.listBoat.find((b) => b.id == this.selectedBoatId);
          this.loadCustomers(boat);
        }
      },
      error: (error) => {},
      complete: () => {
        this.loadCustomers();
        if (!this.customerId && !this.boatId && !this.slingId) {
          this.validateSearch();
        }
      },
    });
  }

  getAllOwnerCustomers() {
    this.customerService.getAllCustomerActiveSimple().subscribe({
      next: (data) => {
        let boats: any = [];
        const customersList: any = [];
        data.forEach((customer) => {
          if (customer.boats.length > 0) {
            boats = customer.boats.filter((boat) => boat.active == true);
            customer.boats = boats;
          }
          customersList.push(customer);
        });
        this.allOwnerCustomers = customersList;
        this.mapCustomersList();
      },
      error: (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
    });
  }

  validateSearch(): void {
    if (this.searchByCustomer) {
      this.mapCustomersList();
      this.boatOptions = [];
    } else {
      this.loadBoatsList(this.listBoat);
    }
  }

  loadCustomers(boat?: SelectorBoatDTO): void {
    if (boat) {
      this.selectedBoatId = boat.id;
    }
    if (this.selectedBoatId && this.listBoat) {
      this.customers = [];
      this.selectedBoat = this.listBoat.find(
        (b) => b.id == this.selectedBoatId
      );
      this.listCustomer = this.listBoat
        .find((b) => b.id == this.selectedBoatId)
        .boatCustomers.filter((boatCustomer) => boatCustomer.customer.active);
      if (this.customerId == 0) {
        this.customers = this.listCustomer.map((obj) => ({
          label: obj.customer.name,
          value: obj.customer.id,
          complete: this.completeCad(obj.customer),
        }));

        if (this.customers.length == 1) {
          this.selectedCustomerId = this.customers[0].value;
          this.selectCustomer();
        }
      } else {
        const customerOrder = this.listCustomer.find(
          (obj) => obj.customer.id == this.customerId
        );
        if (customerOrder.type == 'Owner') {
          this.customers = [
            {
              label: customerOrder.customer.name,
              value: customerOrder.customer.id,
              complete: this.completeCad(customerOrder),
            },
          ];
          this.selectedCustomerId = customerOrder.customer.id;
        } else {
          this.customers = [
            {
              label: customerOrder.associatedOwner.name,
              value: customerOrder.associatedOwner.id,
              complete: this.completeCad(customerOrder),
            },
          ];
          this.selectedCustomerId = customerOrder.associatedOwner.id;
        }
        this.selectCustomer();
      }
    }
  }

  mapCustomersList(): void {
    this.customers = this.allOwnerCustomers.map((obj) => ({
      label: obj.name,
      value: obj.id,
      complete: this.completeCad(obj),
    }));
    if (this.isBoatSingleOrder == false) {
      this.spinner.hide();
    }
  }

  loadBoatsList(parameter?: any): void {
    if (this.searchByCustomer) {
      this.boatOptions = this.listBoat
        .filter((boat) =>
          boat.boatCustomers.some(
            (customerBoat) =>
              customerBoat.customer.id == parameter.id &&
              customerBoat.type == 'Owner'
          )
        )
        .map((boat) => ({
          label: boat.name.concat(boat.maintenance ? ' (Em manutenção)' : ''),
          value: boat,
          disabled: boat.maintenance,
        }));

      if (this.boatOptions.length == 1) {
        this.selectBoat(
          this.listBoat.find((boat) => boat.id == this.boatOptions[0].value.id)
        );
      }
    } else {
      this.boatOptions = parameter
        .filter((boat) => boat.active)
        .map((boat) => ({ label: boat.name, value: boat }));
    }
  }

  selectBoat(boat?: SelectorBoatDTO): void {
    if (boat) {
      this.selectedBoatId = boat.id;
      this.selectedBoat = this.listBoat.find(
        (b) => b.id == this.selectedBoatId
      );
    }
    this.onSelected.emit({
      customer: this.selectedCustomer,
      boat: this.selectedBoat,
    });
  }

  selectCustomer(idCustomer?: any): void {
    if (idCustomer) {
      this.selectedCustomerId = idCustomer;
    }
    if (!this.searchByCustomer) {
      this.selectedCustomer = this.listCustomer.find(
        (obj) => obj.customer.id == this.selectedCustomerId
      ).customer;
    } else {
      this.selectedBoat = undefined;
      this.selectedCustomer = this.allOwnerCustomers.find(
        (obj) => obj.id == this.selectedCustomerId
      );
      this.loadBoatsList(this.selectedCustomer);
    }
    this.onSelected.emit({
      customer: this.selectedCustomer,
      boat: this.selectedBoat,
    });
  }

  completeCad(customer: Customer): boolean {
    if (customer.phone == null || customer.dueDate == null) {
      return false;
    }
    return true;
  }
}
