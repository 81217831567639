export class PaginationFilter {

    size: number;
    page: number;
    sort: string;
    order: string;

    constructor() {
        this.size = 100;
        this.page = 0;
        this.order = 'ASC';
    }
}
