import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SpecialDaySettingForQueueDTO, SpecialOperatingDayDTO } from 'src/app/models/dtos/config/special-operating-day-dto';
import { DayWeek } from 'src/app/models/enums/day-week';
import { OperatingDayConfigService } from 'src/app/services/config/operating-day-config.service';
import { ToastService } from 'src/app/services/toast.service';
import { getDaySettingValidators, setValidatorsToEnd, setValidators2ToIntervalEnd, setValidatorsToIntervalStart, setValidatorsToReturnEnd, setValidatorsToStart } from 'src/app/validators/date/times';
import { OperatingDay } from '../../operating-day-config.component';
import { ScheduleQueueDto } from '../../schedule-queue/schedule-queue.component';
import { CopyAndPasteMultipleModalComponent } from '../copy-and-paste-multiple-modal/copy-and-paste-multiple-modal.component';

@Component({
  selector: 'app-special-operating-day-open-modal',
  templateUrl: './special-operating-day-open-modal.component.html',
  styleUrls: ['./special-operating-day-open-modal.component.scss']
})
export class SpecialOperatingDayOpenModalComponent implements OnInit {
  specialOperatingDayId: number;

  operatingDays: OperatingDay[];
  scheduleQueues: ScheduleQueueDto[];

  form: UntypedFormGroup;
  saving: boolean = false;

  get daySettings(): UntypedFormArray {
    return this.form.controls.specialDayForQueues as UntypedFormArray;
  }

  constructor(
    private readonly config: DynamicDialogConfig,
    private readonly dialogRef: DynamicDialogRef,
    private readonly operatingDayConfigService: OperatingDayConfigService,
    private readonly toasty: ToastService,
    private readonly dialog: DialogService
  ) {
    this.specialOperatingDayId = this.config.data.specialOperatingDayId
  }
  async ngOnInit(): Promise<void> {

    const resultLoad = await Promise.all([
      this.loadOperatingDays(),
      this.loadScheduleQueues()
    ])

    if (resultLoad.filter(r => (r !== true)).length > 0) {
      this.toasty.error('ops, ocorreu em erro! Tente novamente mais tarde.');
    } else {
      if (this.specialOperatingDayId) {
        this.operatingDayConfigService.getSpecialOperatingDayById(this.specialOperatingDayId).subscribe(
          (data) => this.createFormToEdit(data),
          (err) => {}
        );
      } else {
        this.createFormToNew();
      }
    }

  }

  loadOperatingDays(): Promise<boolean> {
  return new Promise((resolve, reject) => {
    this.operatingDayConfigService.getAll().subscribe(
      (operatingDays) => {
        this.operatingDays = operatingDays;
        resolve(true);
      },
      (err) => reject(err)
      );
    });
  }

  loadScheduleQueues(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.operatingDayConfigService.getAllScheduleQueueDTO().subscribe(
        (scheduleQueues) => {
          this.scheduleQueues = scheduleQueues;
          resolve(true);
        },
        (err) => reject(err)
      );
    });
  }

  createFormToNew() {
    const day: SpecialOperatingDayDTO = {
      date: null,
      observation: '',
      changeOperationDay: true,
      inOperation: true,
      operationStart: null,
      operationEnd: null,
      specialDayForQueues: []
    }
    this.createFormToEdit(day);
  }

  createFormToEdit(day : SpecialOperatingDayDTO) {
    const form = new UntypedFormGroup({
      id: new UntypedFormControl(day.id),
      date: new UntypedFormControl(day.date, [
        Validators.required
      ]),
      observation: new UntypedFormControl(day.observation, [
        Validators.required
      ]),
      operationStart: new UntypedFormControl(day.operationStart, [
        Validators.required
      ]),
      operationEnd: new UntypedFormControl(day.operationEnd, [
        Validators.required
      ]),
      specialDayForQueues: new UntypedFormArray([], Validators.required)
    });
    const { operationStart, operationEnd, specialDayForQueues } = form.controls;
    day.specialDayForQueues.forEach(setting => {
      const settingForm = this.createDaySettingForm(operationStart, operationEnd, setting);
      (specialDayForQueues as UntypedFormArray).push(settingForm);
      this.scheduleQueues = this.scheduleQueues.filter(sq => sq.id !== setting.scheduleQueue.id);
    });
    this.scheduleQueues.forEach(queue => this.addNewDaySetting(queue, form));
    this.form = form;
  }

  useNormalOperatingDay() {
    const { operationStart, operationEnd, date } = this.form.controls;
    this.updateOperatingDayDates(date as UntypedFormControl, operationStart as UntypedFormControl, operationEnd as UntypedFormControl);
  }

  updateOperatingDayDates(dateControl: UntypedFormControl, operationStartControl: UntypedFormControl, operationEndControl: UntypedFormControl) {
    if (dateControl.value) {
      const date = new Date(dateControl.value);
      if (date.toString() != 'Invalid Date') {
        const daysOfWeek = [DayWeek.MONDAY, DayWeek.TUESDAY, DayWeek.WEDNESDAY, DayWeek.THURSDAY, DayWeek.FRIDAY, DayWeek.SATURDAY, DayWeek.SUNDAY];
        const dayWeek = daysOfWeek[date.getDay()];
        const op = this.operatingDays.find(op => op.dayWeek === dayWeek );
        if (op && op.inOperation) {
          operationStartControl.setValue(op.operationStart);
          operationStartControl.markAsDirty();
          operationEndControl.setValue(op.operationEnd);
          operationEndControl.markAsDirty();
        } else {
          this.toasty.warning('Não há horário normal definido para este dia da semana!');
        }
        return;
      }
    }
    this.toasty.warning('Defina o dia primeiro!');
  }

  addNewDaySetting(scheduleQueue: ScheduleQueueDto, form: UntypedFormGroup) {
    const { operationStart, operationEnd, specialDayForQueues } = form.controls;
    const newSetting: SpecialDaySettingForQueueDTO = {
      id: null,
      scheduleQueue: { id: scheduleQueue.id, name: scheduleQueue.name },
      normalDay: true,
      inOperation: true,
      start: null,
      end: null,
      returnEnd: null,
      intervalStart: null,
      intervalEnd: null,
      executionTime: null,
    };

    (specialDayForQueues as UntypedFormArray).push(this.createDaySettingForm(operationStart, operationEnd, newSetting));
  }

  createDaySettingForm(operationStart: AbstractControl, operationEnd: AbstractControl, daySetting: SpecialDaySettingForQueueDTO): UntypedFormGroup {
    const daySettingControl: UntypedFormGroup = new UntypedFormGroup({
      id: new UntypedFormControl(daySetting.id),
      scheduleQueue: new UntypedFormControl(daySetting.scheduleQueue, [Validators.required]),
      normalDay: new UntypedFormControl(daySetting.normalDay ? true : false, [Validators.required]),
      inOperation: new UntypedFormControl(daySetting.inOperation, [Validators.required]),
      start: new UntypedFormControl({ value: daySetting.start, disabled: !daySetting.inOperation || daySetting.normalDay}),
      end: new UntypedFormControl({ value: daySetting.end, disabled: !daySetting.inOperation || daySetting.normalDay}),
      returnEnd: new UntypedFormControl({ value: daySetting.returnEnd, disabled: !daySetting.inOperation || daySetting.normalDay}),
      intervalStart: new UntypedFormControl({ value: daySetting.intervalStart, disabled: !daySetting.inOperation || daySetting.normalDay}),
      intervalEnd: new UntypedFormControl({ value: daySetting.intervalEnd, disabled: !daySetting.inOperation || daySetting.normalDay}),
      executionTime: new UntypedFormControl({ value: daySetting.executionTime, disabled: !daySetting.inOperation || daySetting.normalDay},[
        Validators.required, Validators.min(1)
      ]),
    }, getDaySettingValidators() );
    const { start, end, returnEnd, intervalStart, intervalEnd, normalDay } = daySettingControl.controls;
    normalDay.valueChanges.subscribe((value) => {
      this.updateEnableTimesDaySettingQueue(daySettingControl);
    });
    daySettingControl.controls.inOperation.valueChanges.subscribe((inOperationValue) => {
      this.updateEnableTimesDaySettingQueue(daySettingControl);
    });
    setValidatorsToStart(start, operationStart, end, intervalStart);
    setValidatorsToEnd(end, operationEnd, start, intervalStart);
    setValidatorsToReturnEnd(returnEnd, operationStart, operationEnd);
    setValidatorsToIntervalStart(intervalStart, start, end, intervalEnd);
    setValidators2ToIntervalEnd(intervalEnd, end, intervalStart);
    return daySettingControl;
  }

  updateEnableTimesDaySettingQueue(queueForm: UntypedFormGroup) {
    const { start, end, returnEnd, intervalStart, intervalEnd, executionTime, inOperation, normalDay} = queueForm.controls;
    const controlsToDisableOrEnable = [
      start, end, returnEnd, intervalStart, intervalEnd, executionTime
    ];
    if (inOperation.value && !normalDay.value) {
      controlsToDisableOrEnable.forEach(c => { c.enable(); })
    } else {
      controlsToDisableOrEnable.forEach(c => { c.disable(); })
    }
  }

  close() {
    this.dialogRef.close();
  }

  changeNormalDay(daySetting: UntypedFormGroup): void {
    const i = this.daySettings.controls.findIndex(c => c.value.scheduleQueue.id === daySetting.value.scheduleQueue.id);
    if (i >=0) {
      this.daySettings.removeAt(i);
      daySetting.controls.normalDay.setValue(!daySetting.value.normalDay);
      daySetting.markAsDirty();
      this.daySettings.push(daySetting);
    }
  }
  updateInOperation(daySetting: UntypedFormGroup): void {
    daySetting.controls.inOperation.setValue(!daySetting.value.inOperation);
  }

  openModalCopy(daySettingControl: UntypedFormGroup) {
    const daySettingToCopy = {label:  daySettingControl.value.scheduleQueue.name, value: daySettingControl.value.scheduleQueue.id};
    const daySettingOptionsToPast = this.daySettings.value.filter(d => d.scheduleQueue.id !== daySettingToCopy.value).map(d => ({label: d.scheduleQueue.name, value: d.scheduleQueue.id}));
    const dialogRef = this.dialog.open(CopyAndPasteMultipleModalComponent, {
      header: 'Copiar configuração da fila',
      data: {
        toCopy: daySettingToCopy,
        toPaste: daySettingOptionsToPast
      }
    })
    dialogRef.onClose.subscribe((result) => {
      if (result && result.toCopy && result.paste && result.paste.length > 0) {
        const copyControl = (this.daySettings.getRawValue() as SpecialDaySettingForQueueDTO[]).filter(d => d.scheduleQueue.id == result.toCopy);
        if (copyControl.length == 1) {
          const { inOperation, start, intervalStart, intervalEnd, end, returnEnd, executionTime, normalDay } = copyControl[0];

          const daysToPasteControls = this.daySettings.controls.filter(c => {
            const dayValue = c.value as SpecialDaySettingForQueueDTO;
            return result.paste.includes(dayValue.scheduleQueue.id);
          })
          daysToPasteControls.forEach((d: UntypedFormGroup) => {
            d.controls.inOperation.setValue(inOperation);
            d.controls.inOperation.markAsDirty();
            d.controls.start.setValue(start);
            d.controls.start.markAsDirty();
            d.controls.intervalStart.setValue(intervalStart);
            d.controls.intervalStart.markAsDirty();
            d.controls.intervalEnd.setValue(intervalEnd);
            d.controls.intervalEnd.markAsDirty();
            d.controls.end.setValue(end);
            d.controls.end.markAsDirty();
            d.controls.returnEnd.setValue(returnEnd);
            d.controls.returnEnd.markAsDirty();
            d.controls.executionTime.setValue(executionTime);
            d.controls.executionTime.markAsDirty();
            d.controls.normalDay.setValue(normalDay);
            d.controls.normalDay.markAsDirty();
          })
        }
      }
    })
  }

  save() {
    if ( this.form.valid && !this.saving) {
      this.saving = true;
      const requestBody = {...this.form.value, inOperation: true}
      this.operatingDayConfigService.saveSpecialOperatingDay(requestBody).subscribe(
        (data) => {
          this.toasty.success('Salvo com sucesso!');
          this.dialogRef.close(data);
        },
        (err) => {
          this.toasty.error('Ops, não foi possível salvar.');
          this.saving = false;
        }
      );
    }
  }
}
