import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DocumentContractView } from 'src/app/models/dtos/document-contract-view';
import { PaginationFilter } from 'src/app/models/pagination-filter';
import { ContractDocumentService } from 'src/app/services/contract-document.service';

@Component({
  selector: 'app-service-boat-contracts-dialog',
  templateUrl: './service-boat-contracts-dialog.component.html',
  styleUrls: ['./service-boat-contracts-dialog.component.scss']
})
export class ServiceBoatContractsDialogComponent implements OnInit {

  documentContracts: DocumentContractView[] = [];
  serviceBoatId: number

  constructor(
    public dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private contractDocumentService: ContractDocumentService
  ) { }

  ngOnInit(): void {
    if (this.config.data?.serviceBoatId) {
      this.serviceBoatId = this.config.data.serviceBoatId;
      this.getServiceBoatContracts();
    }
  }

  getServiceBoatContracts(): void {
    const pagination: PaginationFilter = {
      size: 100,
      page: 0,
      order: 'DESC',
      sort: 'createdAt'
    };

    this.contractDocumentService.getDocuments({ serviceBoatId: this.serviceBoatId }, pagination)
      .subscribe((data) => {
        this.documentContracts = data.content;
      }, (error) => console.log(error));
  }

  save(): void {
    this.dialogRef.close(true);
  }
}