import { Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { LancamentoProgramado } from 'src/app/models/finance/lancamento-programado';
import { MarinaCompany } from 'src/app/models/marina-company';
import { FinancesService } from 'src/app/services/finances.service';
import { InvoiceTaxProductService } from 'src/app/services/financial/invoice-Tax-Product.service';
import { MessageUtil } from 'src/app/utils/message.util';

@Component({
  selector: 'app-create-programacao-dialog',
  templateUrl: './create-programacao-dialog.component.html',
  styleUrls: ['./create-programacao-dialog.component.scss']
})
export class CreateProgramacaoDialogComponent {
  
  data:any;
  
  programacaoForm: UntypedFormGroup;
  
  planoContaDropdown: any[];
  centroCustoDropdown: any[];
  contaDropdown: any[];
  formaPagamentoDropdown: any[];

  constructor(
    private config: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
    private spinner: NgxSpinnerService,
    private financesService: FinancesService,
    private messageUtil: MessageUtil,
    private invoiceTaxProductService: InvoiceTaxProductService,) { }

  ngOnInit(): void {
    this.data = this.config.data;
    this.getFinancialData();
    this.startForm();   
  }

  async getFinancialData() {
    this.spinner.show();
    this.planoContaDropdown = await this.financesService.findPlanoContaDespesaFlat();
    this.centroCustoDropdown = await this.financesService.findCentroCustoFlat();
    this.getContas(this.data.marinaCompany);
    this.getFormasPagamento(this.data.marinaCompany);
    this.spinner.hide();
  }

  async getPlanoContaDespesa(): Promise<any[]> {
    let planos = [];
    await this.financesService.findPlanoContaReceitaFlat().then((data) => {
      planos = data.filter((element) => !element.label.substring(0, 1).includes('1'));
    });
    return planos
  }
  
  getContas(marinaCompany: MarinaCompany): void {
    this.spinner.show();
    this.financesService.findContasAtivas(marinaCompany).subscribe({
      next: (data) => 
        this.contaDropdown = data.map((element) => ({label: element.nome, value: element.idConta,})),
      error: (error) => {
        this.spinner.hide()
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
      complete: () => {
        this.spinner.hide();
      }
    });
  }

  getFormasPagamento(marinaCompany: MarinaCompany): void {
    this.spinner.show();
    this.financesService.findTipoFormasPagamento(marinaCompany).subscribe({
      next: (data) => 
        this.formaPagamentoDropdown = data.map((element) => ({
          label: element.descricao,
          value: element.idTipoFormaPagamento,
        })),
      error: (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
      complete: () => {
        this.spinner.hide();
      }
    });
  }

  startForm() {
    let companyName = this.data.marinaCompany?.companyName
    let hasCompany = companyName ? true : false;
    
    this.programacaoForm = new UntypedFormGroup({
      dataVencimento: new UntypedFormControl('', Validators.required),
      dataCompetencia: new UntypedFormControl('', Validators.required),
      planoConta: new UntypedFormControl('', Validators.required),
      centroCusto: new UntypedFormControl(),
      conta: new UntypedFormControl('', Validators.required),
      formaPagamento: new UntypedFormControl('', Validators.required),
      valor: new UntypedFormControl(parseFloat(this.data.valorTotal).toFixed(2)),
      dataEmissao: new UntypedFormControl({value: new Date(this.data.createDate), disabled: true}),
      favorecido: new UntypedFormControl(this.data.supplierName),
      marinaCompany: new UntypedFormControl(hasCompany ? companyName : this.data.marinaCompany),
    });
  }

  save() {
    let programacao = new LancamentoProgramado().adaptProgramacao(this.programacaoForm.value, this.data);
    this.invoiceTaxProductService.createProgramacao(programacao).subscribe({
      next: (data) => {
        this.messageUtil.generateMessage(
          MessageUtil.SUCCESS,
          'Sucesso',
          'Programação criada com sucesso!'
        );
        this.dialogRef.close(true);
      },
      error: (error) => {
        const exception = error.error.data?.exception;
        if (exception != null) {
          this.messageUtil.generateMessage(
            exception.type,
            exception.title,
            exception.message
          );
        } else {
          this.messageUtil.generateMessage(
            MessageUtil.WARNING,
            'Alerta',
            'Não foi possível criar a programação!'
          );
        }
      }
    });
  }
}