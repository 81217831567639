import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-log-information',
  templateUrl: './customer-log-information.component.html',
  styleUrls: ['./customer-log-information.component.scss']
})
export class CustomerLogInformationComponent implements OnInit {

  @Input() customerLog;
  @Input() isNew;

  oldDatasMap

  constructor() { }

  ngOnInit(): void {
    this.oldDatasMap = new Map(Object.entries(this.customerLog.oldDatas));
  }

  isChangedCustomerGroup(): boolean{
    return this.oldDatasMap.has('customerGroupLog');
  }

  preparEmailCollection(emails: string): string{
    if(!emails){
      return '';
    }
    return emails.replace(/\;/g, ", ");
  }

  isChangedFederalIdType(): boolean{
    return this.oldDatasMap.has('federalIdType');
  }

  isFederalIdType(federalIdType: string): boolean {
    let federalIdTypeLog = this.customerLog.federalIdType;
    if(this.isChangedFederalIdType() && !this.isNew){
      federalIdTypeLog = this.customerLog.oldDatas.federalIdType;
    }
    return federalIdTypeLog.toUpperCase() === federalIdType.toUpperCase();
  }

  isChangedOnlyAssociated(): boolean{
    return this.oldDatasMap.has('onlyAssociated');
  }

  isOnlyAssociated(): boolean {
    let onlyAssociatedLog = this.customerLog.onlyAssociated;
    if(this.isChangedOnlyAssociated() && !this.isNew){
      onlyAssociatedLog = this.customerLog.oldDatas.onlyAssociated;
    }
    return !!onlyAssociatedLog;
  }

  isChangedGender(): boolean{
    return this.oldDatasMap.has('gender');
  }

  isGender(gender: string): boolean {
    let genderLog = this.customerLog.gender;
    if(this.isChangedFederalIdType() && !this.isNew){
      genderLog = this.customerLog.oldDatas.gender;
    }
    return genderLog.toUpperCase() === gender.toUpperCase();
  }

  isChangedStatus() {
    return this.oldDatasMap.has('active');
  }

  isChangedMaritalStatus() {
    return this.oldDatasMap.has("maritalStatus")
  }

  isChangedSpouse() {
    return this.oldDatasMap.has("spouse")
  }

  getStatus(): boolean {
    let active = this.customerLog.active;
    if(this.isChangedStatus() && !this.isNew){
      active = this.customerLog.oldDatas.active;
    }
    return active;
  }
}
