
<div style="padding: 16px;">
  <app-user-log-general-data *ngIf="isGeneralData" [userLog]="userLog" [isNew]="true"></app-user-log-general-data>

  <app-user-log-general-data *ngIf="isGeneralData" [userLog]="userLog" [isNew]="false"></app-user-log-general-data>

  <div style="margin: 15px;">
      <div class="content-log">
        <div>
          <label>Data/hora</label>
          <label>{{ userLog.logTime | date: "dd/MM/yyyy - HH:mm" }}</label>
        </div>
        <div>
          <label>Usuário</label>
          <label>{{ userLog.user.username }}</label>
        </div>
        <div>
          <label>Origem</label>
          <label>{{ userLog.origin }}</label>
        </div>
        <div>
          <label>ação</label>
          <label>{{ userLog.operationLog | translate }}</label>
        </div>
      </div>
      <div class="buttons">
        <button class="easymarine primary" (click)="dialogRef.close()">
          Fechar
        </button>
      </div>
  </div>
</div>
