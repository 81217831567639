import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MarinaIdNameDTO } from 'src/app/models/dtos/marina/marinaIdNameDTO';
import { MarinaService } from '../../../services/marina.service';


@Component({
  selector: 'app-login-marina-options',
  templateUrl: './login-marina-options.component.html',
  styleUrls: ['./login-marina-options.component.scss']
})
export class LoginMarinaOptionsComponent implements OnInit {

  marinaList: MarinaIdNameDTO[];

  constructor(
    private config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private spinner: NgxSpinnerService,
    private marinaService: MarinaService
  ) { }

  ngOnInit(): void {
    this.getMarinaListByUser(this.config.data);
  }

  getMarinaListByUser(userId: Number) {
    this.marinaService.getMarinaIdAndName(userId).subscribe({
      next: (marinas) => {
        this.marinaList = marinas
        this.spinner.hide();
      },
      error: (e) => {
        console.log(e);
        this.spinner.hide();
      }
    })
  }

  close(marinaId: Number) {
    this.dialogRef.close(marinaId);
  }
}
