import { ShipType } from "../enums";

export class ServiceBoatReadjustmentDTO {
    boatName: string;
    commercialLength: number;
    shipType: ShipType;

    serviceBoatId: number;
    serviceName: string;
    contractEndDate: any;
    contractStartDate: any;
    observation: string;
    discount: number;
    dueDateDiscount: number;
    value: number;
    addMomth: number;

    readjustmentDiscount?: number;
    readjustmentValue?: number;
    readjustmentDueDateDiscount?: number;
    lastReadjustment?: any;
    newEndService?:Date;

    constructor(){}
}
