import { Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectItem } from 'primeng/api';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AccessGroupPoint } from 'src/app/models/dtos/access-controller/access-group-point';
import { AccessGroupService } from 'src/app/services/access-controler/access-group.service';
import { AccessPointService } from 'src/app/services/access-controler/access-point.service';
import { OperationalConfigService } from 'src/app/services/config/operational-config.service';
import { ToastService } from 'src/app/services/toast.service';
import { FormUtil } from 'src/app/utils/form.util';
import { MessageUtil } from 'src/app/utils/message.util';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-access-group-form',
  templateUrl: './access-group-form.component.html',
  styleUrls: ['./access-group-form.component.scss'],
})
export class AccessGroupFormComponent implements OnInit {
  accessGroupForm: FormGroup = new FormGroup({
    id: new FormControl<number | null>(null),
    name: new FormControl<string | null>(null, Validators.required),
    type: new FormControl<string | null>(null, Validators.required),
    description: new FormControl<string | null>(''),
    active: new FormControl<boolean | null>(true),
    marinaId: new FormControl<number | null>(null),
    accessGroupPoints: new FormControl<AccessGroupPoint[] | null>([]),
    showSuplier: new FormControl<boolean | null>(true),
    useInvitation: new FormControl<boolean | null>(false),
  });

  accountDropdown: any[];
  wasActive: boolean;
  useiInvites = false;

  accessGroupTypes: SelectItem[] = [
    { label: 'Selecione...', value: null },
    { label: 'Proprietário', value: 'OWNER' },
    { label: 'Não Sócio', value: 'NON_MEMBER' },
    { label: 'Funcionário', value: 'EMPLOYEE' },
    { label: 'Prestador de Serviço', value: 'SERVICE_PROVIDER' },
    { label: 'Convidado', value: 'GUEST' },
    { label: 'Marinheiro', value: 'SAILOR' },
  ];
  accessPointList: SelectItem[] = [];
  accessPointSelect: any = {};
  isStandard: boolean = false;

  constructor(
    public dialog: DynamicDialogRef,
    private accessGroupService: AccessGroupService,
    private messageUtil: MessageUtil,
    private spinner: NgxSpinnerService,
    private toasty: ToastService,
    private config: DynamicDialogConfig,
    private accessPointService: AccessPointService,
    private operationalConfigService: OperationalConfigService
  ) {}

  ngOnInit(): void {
    this.getOperationalConfig();
    if (this.config && this.config.data) {
      this.initialize();
    }
    this.loadAccessPointList();
  }

  onChangeType(e: DropdownChangeEvent){
    if(this.useiInvites){
      this.accessGroupForm.get('useInvitation').setValue(e.value == 'GUEST');
    }
  }

  initialize() {
    this.resetForm();
    if (this.config && this.config.data) {
      if (this.config.data.group) {
        if (
          this.config.data.group.name == 'Proprietário' ||
          this.config.data.group.name == 'Autorizado'
        ) {
          this.isStandard = true;
        }
        this.accessGroupForm.patchValue(this.config.data.group);
        this.wasActive = this.accessGroupForm.get('active').value;
      }
    }
  }

  loadAccessPointList(): void {
    this.accessPointService.getAllByMarinaAndActive(true).subscribe({
      next: (data) => {
        this.accessPointList = data.map((ap) => {
          return { label: ap.name, value: ap };
        });
        let acps: AccessGroupPoint[] = [];
        this.config?.data?.group?.accessGroupPointDTOs.forEach((acp) => {
          acp.accessPoint = this.accessPointList.find(
            (ap) => ap.value?.id === acp.accessPointId
          ).value;
          acps.push(acp);
          this.accessPointList = this.accessPointList.filter(
            (ap) => ap.value?.id !== acp.accessPointId
          );
        });
        this.accessGroupForm.get('accessGroupPoints').patchValue(acps);
        this.accessGroupForm.get('accessGroupPoints').value.forEach((acp) => {
          delete acp?.accessGroupId;
          delete acp?.accessPointId;
        });
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  addAccessTypePoint(): void {
    let accessGroupPoint: AccessGroupPoint = new AccessGroupPoint();
    accessGroupPoint.accessPoint = this.accessPointSelect;
    this.accessGroupForm.value.accessGroupPoints.push(accessGroupPoint);
    this.accessPointList = this.accessPointList.filter(
      (ap) => ap.value.id !== this.accessPointSelect.id
    );
  }

  openAccessPointDeleteConfirmDialog(accessPointId: number): void {
    Swal.fire({
      title: 'Deseja realmente excluir?',
      text: 'Esta ação será efetivada ao salvar o formulário!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, excluir!',
      cancelButtonText: 'Não, cancelar!',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        let accessPoint = this.accessGroupForm.value.accessGroupPoints.find(
          (acp) => acp.accessPoint.id === accessPointId
        ).accessPoint;
        this.accessPointList.push({
          label: accessPoint.name,
          value: accessPoint,
        });
        this.accessGroupForm.value.accessGroupPoints.splice(
          this.accessGroupForm.value.accessGroupPoints.findIndex(
            (acp) => acp.accessPoint.id === accessPointId
          ),
          1
        );
        this.accessPointSelect = this.accessPointList[0].value;
      }
    });
  }

  async validateToSave(): Promise<void> {
    if (!this.accessGroupForm.valid) {
      this.messageUtil.generateMessage(
        'warning',
        'SUMMARY.WARNING',
        'MSG.FIELD-REQUIRED'
      );
      FormUtil.touchAndSendFormGroup(this.accessGroupForm);
      return;
    }
    let isActive = this.accessGroupForm.get('active').value;
    if (this.wasActive && !isActive) {
      await Swal.fire({
        title: 'Atenção!',
        text: 'Ao desativar a categoria, todos os usuários associados a ela serão desvinculados. Deseja continuar?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
      }).then((isConfirm) => {
        if (isConfirm.value) {
          this.save();
        }
      });
    } else {
      this.save();
    }
  }

  save() {
    this.spinner.show();

    this.accessGroupService.save(this.accessGroupForm.value).subscribe({
      next: () => {
        this.toasty.success('Categoria cadastrada com sucesso');
        this.dialog.close();
      },
      error: (error) => {
        this.spinner.hide();
        const exception = error.error.data
          ? error.error.data.exception
          : error.error;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
      complete: () => this.spinner.hide(),
    });
  }

  resetForm(): void {
    this.accessGroupForm = new UntypedFormGroup({
      id: new UntypedFormControl(null),
      name: new UntypedFormControl(null, Validators.required),
      type: new UntypedFormControl(null, Validators.required),
      active: new UntypedFormControl(true, Validators.required),
      description: new UntypedFormControl(''),
      accessGroupPoints: new UntypedFormControl([]),
      showSuplier: new FormControl<boolean | null>(true),
      useInvitation: new FormControl<boolean | null>(false),
    });
  }

  getStatus(boolean: boolean) {
    return boolean ? 'Ativo' : 'Inativo';
  }

  async getOperationalConfig() {
    this.useiInvites =
      await this.operationalConfigService.invitesControllerEnabled();
  }
}
