<div class="dialog-form">
  <div class="table">
    <p-table [value]="historic" [paginator]="false" autoLayout="true">
      <ng-template pTemplate="header">
        <tr>
          <th>Data do Evento</th>
          <th>Tipo de Evento</th>
          <th>Descrição</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-order>
        <tr>
          <td>{{ order.eventDate | date: "dd/MM/yy HH:mm:ss" }}</td>
          <td>{{ order.eventType | uppercase | translate}}</td>
          <td>{{ order.description }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
