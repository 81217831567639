<div class="table">
    <p-table #tt [value]="movementLogs" [resizableColumns]="true" [autoLayout]="true" [paginator]="true"
    [rows]="numberOfRows" [pageLinks]="3" [rowsPerPageOptions]="isDialog? null : [10,20,30,50,100]">
        <ng-template pTemplate="header">
            <tr>
                <th>Movimentação</th>
                <th>Embarcação</th>
                <th>Usuário</th>
                <th>Origem</th>
                <th>Data/hora</th>
                <th>Local de origem</th>
                <th>Local de destino</th>
                <th>Operação</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-movementLog>
            <tr>
                <td>{{movementLog.movementId}}</td>
                <td>{{movementLog.boatName}}</td>
                <td [pTooltip]="movementLog.user ? movementLog.user.fullName : ''" tooltipPosition="top">{{movementLog.user ? movementLog.user.username : ''}}</td>
                <td>{{movementLog.origin}}</td>
                <td>{{movementLog.logTime | date: 'dd/MM/yyyy - HH:mm'}}</td>
                <td>{{getLocationOrigin(movementLog)}}</td>
                <td>{{getLocationDestiny(movementLog)}}</td>
                <td>{{('MOVEMENT.'+ movementLog.operation) | translate}}</td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="7">
                    Nenhum log encontrado
                </td>
            </tr>
        </ng-template>

    </p-table>
</div>
