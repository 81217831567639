import { Component, Input, OnInit } from '@angular/core';
import { MovementTableUtil } from 'src/app/utils/movements/movement-table-util';

@Component({
  selector: 'app-movement-arrow-direction',
  templateUrl: './movement-arrow-direction.component.html',
  styleUrls: ['./movement-arrow-direction.component.scss']
})
export class MovementArrowDirectionComponent implements OnInit {
  @Input()
  darkMode: boolean = false

  @Input()
  arrowDirection: 'DOWN' | 'UP'

  urlImg: string = ''
  constructor(
    private readonly movementTableUtil: MovementTableUtil
  ) { }

  ngOnInit() {
    this.urlImg = this.movementTableUtil.getArrowDirectionUrlImage(this.arrowDirection, this.darkMode);
  }
}
