<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{'USERS-LIST' | translate}}</span>
        <div class="primary fab-button" (click)="openUserFormDialog()" *ngIf="activeIndex === 0">
            <i class="pi pi-plus" style="font-size: 21px;"></i>
        </div>
    </p-header>
    <div class="table">
        <p-tabView (onChange)="tabViewChange($event.index)">
            <p-tabPanel header="Marina">
                <app-user-marina-tab (eventsUser)="eventTabUser($event)"></app-user-marina-tab>
            </p-tabPanel>
            <p-tabPanel header="App">
                <app-user-app-tab (eventsUser)="eventTabUser($event)"></app-user-app-tab>
            </p-tabPanel>
        </p-tabView>
    </div>
</p-fieldset>
