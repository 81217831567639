import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { NixIntegrationConfig } from "src/app/models/nix-integration-config.model";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class NixIntegrationConfigService{

    constructor(
        private http: HttpClient
    ){}

    getNixIntegrationConfigByMarinaId(): Observable<NixIntegrationConfig>{
        return this.http.get(`${environment.apiHost}/secure/easybank/integration/`).pipe(map((response: any) => response.data.easybankIntegration as NixIntegrationConfig));
    }

    updateNixIntegrationConfig(nixIntegrationConfig: NixIntegrationConfig): Observable<NixIntegrationConfig>{
        return this.http.put(`${environment.apiHost}/secure/easybank/integration/`, nixIntegrationConfig).pipe(map((response: any) => response.data.easybankIntegration as NixIntegrationConfig));
    }


    getNixPix(socialNumber: string): Observable<any> {
      return this.http.get(`${environment.apiHost}/secure/easybank/integration/get-nix-pix/`+socialNumber).pipe(map((response: any) => response.data.easybankIntegration as NixIntegrationConfig));
    }
}
