import { Injectable } from '@angular/core';
import { Marina, SlingConfig } from 'src/app/models';
import { FinancialMultipleCompaniesType } from 'src/app/models/enums/finances/financial-multiple-companies-type';
import { MarinaCompany } from 'src/app/models/marina-company';
import { StorageUtil } from 'src/app/utils/storage.util';


export interface FinancialMultipleCompaniesOption {
  label: string,
  value: MarinaCompany | null | { id: number }
}

@Injectable({
  providedIn: 'root'
})
export class FinancialMultipleCompaniesService {

  constructor() { }

  hasFinancialMultipleCompaniesByProduct(slingConfig: SlingConfig): boolean {
    const marina: Marina = StorageUtil.getMarina();
    return this.hasMultipleCompanies(marina) && this.byProduct(slingConfig);
  }

  hasFinancialMultipleCompaniesByCustomer(slingConfig: SlingConfig): boolean {
    const marina: Marina = StorageUtil.getMarina();
    return this.hasMultipleCompanies(marina) && this.byCustomer(slingConfig);
  }

  hasMultipleCompanies(marina: Marina = undefined) {
    if (marina == undefined) {
      marina = StorageUtil.getMarina();
    }
    return marina.marinaCompanies && marina.marinaCompanies.length > 0;
  }

  byProduct(slingConfig: SlingConfig) {
    return slingConfig.financialMultipleCompaniesType === FinancialMultipleCompaniesType.ByProduct;
  }

  byCustomer(slingConfig: SlingConfig) {
    return slingConfig.financialMultipleCompaniesType === FinancialMultipleCompaniesType.ByCustomer;
  }

  getBillingCompanyOptions(withcompanyFederalId: boolean = true): FinancialMultipleCompaniesOption[] {
    const marina: Marina = StorageUtil.getMarina();
    let options: FinancialMultipleCompaniesOption[] = [];
    if (this.hasMultipleCompanies(marina)) {
      const getLabel = withcompanyFederalId ? this.generateLabelNameWithFederalId : this.generateLabelNameWithoutFederalId;
      const getMarinaLabel = withcompanyFederalId ? this.generateMarinaLabelNameWithFederalId : this.generateMarinaLabelNameWithoutFederalId;
      const marinaCompaniesOptions = marina.marinaCompanies.map((marinaCompany) => ({ label: getLabel(marinaCompany), value: {...marinaCompany, marina } }));
      options = [
        { label: getMarinaLabel(marina), value: null},
        ...marinaCompaniesOptions
      ];
    }
    return options;
  }


  getFilterBillingCompanyOptions(withcompanyFederalId: boolean = true): FinancialMultipleCompaniesOption[] {
    const marina: Marina = StorageUtil.getMarina();
    let options: FinancialMultipleCompaniesOption[] = [];
    if (this.hasMultipleCompanies(marina)) {
      const getLabel = withcompanyFederalId ? this.generateLabelNameWithFederalId : this.generateLabelNameWithoutFederalId;
      const getMarinaLabel = withcompanyFederalId ? this.generateMarinaLabelNameWithFederalId : this.generateMarinaLabelNameWithoutFederalId;
      const marinaCompaniesOptions = marina.marinaCompanies.map((marinaCompany) => ({ label: getLabel(marinaCompany), value: {...marinaCompany, marina } }));
      options = [
        { label: 'Todas', value: null},
        { label: getMarinaLabel(marina), value: { id: undefined }},
        ...marinaCompaniesOptions
      ];
    }
    return options;
  }

  private generateLabelNameWithFederalId = (marinaCompany: MarinaCompany): string => marinaCompany.companyFederalName + ' - ' + marinaCompany.companyFederalId;
  private generateLabelNameWithoutFederalId = (marinaCompany: MarinaCompany): string => marinaCompany.companyFederalName;
  private generateMarinaLabelNameWithFederalId = (marina: Marina): string => marina.companyName + ' (principal)' + ' - ' + marina.companyFederalId;
  private generateMarinaLabelNameWithoutFederalId = (marina: Marina): string => marina.companyName + ' (principal)';
}
