import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { APPCONFIG } from "../config";
import { MarinaContractPriceDTO } from "../models/dtos/marina-contract-price-dto";

@Injectable()
export class MarinaContractPriceService {

    constructor(
        private http: HttpClient
    ) { }

    get(): Observable<MarinaContractPriceDTO[]> {
        return this.http.get(environment.apiHost + '/secure/contract-price')
            .pipe(map((response: any) => response.data.contracPrice as MarinaContractPriceDTO[]));
    }

}