import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { QrcodesDialogComponent } from 'src/app/components/qrcodes/qrcodes-dialog/qrcodes-dialog.component';
import { QrcodesPdfDialogComponent } from 'src/app/components/qrcodes/qrcodes-pdf-dialog/qrcodes-pdf-dialog.component';
import { Vacancy } from 'src/app/models';
import { VacancyQRCodeDTO } from 'src/app/models/dtos/vacancyQRCodeDTO';
import { VacancyService } from 'src/app/services/vacancy.service';
import { FormatUtil } from 'src/app/utils/format.util';

@Component({
  selector: 'app-vacancies-qrcodes',
  templateUrl: './vacancies-qrcodes.component.html',
  styleUrls: ['./vacancies-qrcodes.component.scss']
})
export class VacanciesQrcodesComponent implements OnInit {

  qrcodes: VacancyQRCodeDTO[];
  path: any;
  vacancies: Vacancy[];
  vacanciesList: Vacancy[];
  selectedVacancies: Vacancy[];

  constructor(
    private vacancyService: VacancyService,
    private sanitizer: DomSanitizer,
    private dialog: DialogService,
    private spinner: NgxSpinnerService
  ) { }

  async ngOnInit(): Promise<void> {
    this.spinner.show();
    await this.getVacancies();
    this.spinner.hide();
  }

  async getVacancies(): Promise<void> {
    return new Promise<void>(async resolve => {
      this.vacancyService.getAll().subscribe(
        async (vacancies: Vacancy[]) => {
          this.vacancies = vacancies;
          this.vacanciesList = vacancies;
          resolve();
        },
        async (error) => {
          console.log(error);
          resolve();
        });
    });
  }

  getQrCode(vacancyId): void {
    this.spinner.show();
    this.vacancyService.getQRCode(vacancyId).subscribe(
      (blob: any) => {
        this.spinner.hide();
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const path = reader.result;
          this.dialog.open(QrcodesDialogComponent, {
            width: 'auto',
            height: 'auto',
            data: { path },
            dismissableMask: true,
            showHeader: false
          });
        };
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    );
  }

  openQrCode(vacancy: Vacancy): void {
    this.getQrCode(vacancy.id);
  }

  generatePDF(): void {
    this.spinner.show();
    const ids = this.selectedVacancies.map((x) => x.id);
    this.vacancyService.getQRCodes(ids).subscribe(
      (pdf) => {
        this.spinner.hide();
        const file = new Blob([pdf], { type: 'application/pdf' });
        const path = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file));
        this.dialog.open(QrcodesPdfDialogComponent, {
          width: '95%',
          height: 'auto',
          data: { path },
          dismissableMask: true,
        });
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    );
  }

  filterGlobal(event): void {
    const value = event.target.value;
    this.vacancies = this.vacanciesList.filter(
      (v) =>
        (FormatUtil.getNotAccents(v.code).toUpperCase().includes(FormatUtil.getNotAccents(value).toUpperCase()) ||
          v.code.toUpperCase().includes(value.toUpperCase())
        )
    );
  }
}
