<div class="dialog-form" *ngIf="sling">
    <div class="boat-details">
        <div class="card card-white">
            <div class="card-image">
                <img src="{{imageUtil.getBoatImage(sling.boat)}}">
            </div>
        </div>
        <div class="card-content">
            <div class="boat-info">
                <div class="name">{{sling.boat.name}}</div>
                <div *ngIf="sling.boat.vacancy">
                    {{sling.boat.vacancy.code}}
                </div>
                <div>
                    {{sling.boat.shipyardModel.shipType.toUpperCase() | translate}}&nbsp;
                    {{sling.boat.commercialLength}}'
                </div>
            </div>
            <div class="fuel-info" *ngIf="!fullTankCheck">
                <span class="fuel-type-badge">{{orderUtil.abbreviatedProductName(fuelOrder)}}</span>
                <span>{{fuelOrder.product.name}}</span>
            </div>
            <div class="two-time" *ngIf="sling.boat.twoTimeEngine">
                <div class="text-center">
                    <span class="blink">Atenção motor 2 tempos</span>
                </div>
            </div>
            <ng-container *ngIf="fullTankCheck">
                <div class="form-control">
                    <label>{{ 'ADD-FUEL' | translate }}</label>
                    <p-dropdown placeholder="Selecione..." required [options]="filteredFuels"
                        appendTo="body" [(ngModel)]="addFuel" name="addFuel">
                        <ng-template let-fuel pTemplate="item">
                            <div>{{fuel.label}}</div>
                        </ng-template>
                    </p-dropdown>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="fuel-supply">

        <ng-container
            *ngIf="isNumber(fuelOrder.fuelTankFraction == null ? fuelOrder.qtyRequested : fuelOrder.fuelTankFraction)">
            <div class="fuel-box">
                <label>Qtd. solicitada</label>
                <input pInputText type="text" readonly style="font-size: 20px; font-family: Arial;"
                    [ngClass]="fuelOrder.fuelTankFraction == null && 'text-right'"
                    value="{{fuelOrder.fuelTankFraction == null ? (fuelOrder.qtyRequested | number:'1.3') : (fuelOrder.fuelTankFraction | number:'1.3')}}">
            </div>
            <div *ngIf="fuelOrder.fuelTankFraction == null">
                <i class="pi pi-arrow-right" (click)="fuelQuantity = fuelOrder.qtyRequested"></i>
            </div>
        </ng-container>
        <ng-container
            *ngIf="!isNumber(fuelOrder.fuelTankFraction == null ? fuelOrder.qtyRequested : fuelOrder.fuelTankFraction)">
            <div class="fuel-box">
                <label>Qtd. solicitada</label>
                <input pInputText type="text" readonly style="font-size: 20px; font-family: Arial;" class="text-right"
                    value="{{fuelOrder.fuelTankFraction == null ? fuelOrder.qtyRequested : getLabelFraction(fuelOrder.fuelTankFraction)}}">
            </div>
            <div *ngIf="fuelOrder.fuelTankFraction == null" class="total-supply"
                (click)="fuelQuantity = fuelOrder.qtyRequested">
                <i class="pi pi-arrow-right"></i>
            </div>
        </ng-container>
        <div class="fuel-box">
            <label>Qtd. fornecida</label>
            <p-inputNumber class="limited" mode="decimal" required readonly [(ngModel)]="fuelQuantity"
                name="fuelQuatity" [minFractionDigits]="3" [maxFractionDigits]="3" [suffix]="getSuffix()">
            </p-inputNumber>
        </div>
    </div>
    <div class="buttons">
        <button *ngIf="fullTankCheck" class="primary easymarine" (click)="dialog.close(false)">
          Finalizar sem Abastecimento
        </button>
        <button class="easymarine danger" (click)="dialog.close(null)">
            {{'CANCEL' | translate }}
        </button>
        <button class="primary easymarine" (click)="confirmFuelCheck()">
            {{ 'CONFIRM' | translate }}
        </button>
    </div>
</div>
