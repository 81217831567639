<p-fieldset>
  <p-header class="article-title">
    <span class="article-title">{{ "TITLE-MOVEMENTS" | translate }}</span>
    <div class="header-buttons">
      <button
        class="primary fab-button"
        (click)="openMovementForm()"
        pTooltip="Criar uma Movimentação de estoque"
        tooltipPosition="bottom"
      >
        <i class="pi pi-plus" style="font-size: 21px"></i>
      </button>
      <button
        class="fab-button primary"
        appFiltersButton
        pTooltip="Exibir/Ocultar Filtros"
        tooltipPosition="left"
      >
        <i class="pi pi-filter"></i> 
      </button> 

      <button class="fab-button primary" (click)="exportTable()" label="Exportar Excel"
        pTooltip="A exportação será de acordo com os filtros selecionados" tooltipPosition="left">
        <i class="pi pi-file"></i>
      </button>
    </div>
  </p-header>

  <form class="filters" (submit)="getMovementsByFilter()" appFilters>
    <div class="filter-row">
      <div class="form-control">
        <label>{{ "TYPE" | translate }}</label>
        <p-multiSelect
          [options]="stockMovementTypesList"
          [style]="{ height: '30px' }"
          [(ngModel)]="filter.stockMovementTypes"
          appendTo="body"
          name="typeList"
          selectedItemsLabel="{0} itens selecionados"
        >
        </p-multiSelect>
      </div>

      <div class="form-control">
        <label for="supplierOrderNumbers"
          >{{ "SUPPLIER-ORDER" | translate }}
        </label>
        <input
          pInputText
          type="text"
          [(ngModel)]="filter.buyOrderIds"
          name="supplierOrderNumbers"
          placeholder="ex: 1-5"
        />
      </div>

      <div class="form-control">
        <label for="orderNumbers">{{ "ORDER" | translate }} </label>
        <input
          pInputText
          type="text"
          [(ngModel)]="filter.marinaOrderIds"
          name="orderNumbers"
          placeholder="ex: 1-5"
        />
      </div>

      <div class="form-control">
        <label>{{ "CUSTOMER" | translate }}</label>
        <p-dropdown
          placeholder="Selecione..."
          [disabled]="false"
          required
          [filter]="true"
          [options]="customerList"
          [(ngModel)]="filter.customerId"
          optionValue="value"
          optionLabel="label"
          appendTo="body"
          name="selectedCustomerId"
        >
        </p-dropdown>
      </div>
      <div class="form-control">
        <label>{{ "SUPPLIER" | translate }}</label>
        <p-dropdown
          placeholder="Selecione..."
          [disabled]="false"
          required
          [filter]="true"
          [options]="supplierList"
          [(ngModel)]="filter.supplierId"
          optionValue="value"
          optionLabel="label"
          appendTo="body"
          name="selectedBoatId"
        >
        </p-dropdown>
      </div>

      <div class="form-control">
        <label for="rangeCadastro">{{ "Data de emissão" | translate }}</label>
        <p-calendar
          dateFormat="dd/mm/yy"
          name="rangeCreateDates"
          [monthNavigator]="true"
          [yearNavigator]="true"
          appendTo="body"
          [yearRange]="'2000:2055'"
          rPCalendarMask
          selectionMode="range"
          [(ngModel)]="rangeCreateDates"
          [showIcon]="true"
          (onBlur)="this.onChangeCadastro()"
          i18n
        >
        </p-calendar>
      </div>

      <div class="form-control">
        <label for="rangeCancel">{{
          "Data de cancelamento" | translate
        }}</label>
        <p-calendar
          dateFormat="dd/mm/yy"
          name="rangeCancel"
          [monthNavigator]="true"
          [yearNavigator]="true"
          appendTo="body"
          [yearRange]="'2000:2055'"
          rPCalendarMask
          selectionMode="range"
          [(ngModel)]="this.rangeCancelDates"
          [showIcon]="true"
          (onBlur)="this.onChangeCancel()"
          i18n
        >
        </p-calendar>
      </div>

      <div class="form-control">
        <label for="status">{{ "STATUS" | translate }}</label>
        <p-dropdown
          [(ngModel)]="filter.active"
          name="status"
          appendTo="body"
          optionValue="value"
          optionLabel="label"
          [options]="[
            { label: 'Todos', value: null },
            { label: 'Inativo', value: 0 },
            { label: 'Ativo', value: 1 }
          ]"
        >
        </p-dropdown>
      </div>

      <div class="buttons">
        <div class="form-button">
          <button (click)="clear()" class="easymarine danger" type="button">
            {{ "CLEAR" | translate }}
          </button>
        </div>
        <div class="form-button">
          <button class="easymarine primary" type="submit">
            {{ "FIND" | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
  <div class="table">
    <p-table
      #stockMovementsDataTable
      [value]="stockMovements"
      (onLazyLoad)="onChangePage($event)"
      [paginator]="true"
      [rows]="numberOfRows"
      [lazy]="true"
      [rowsPerPageOptions]="[10, 20, 30, 50, 100]"
      [rows]="pageable ? pageable.size : null"
      dataKey="id"
      sortMode="multiple"
      csvSeparator=";"
      [columns]="cols"
      [totalRecords]="totalRecords"
      styleClass="easy-table"
      [alwaysShowPaginator]="false"
      autoLayout="true"
      [globalFilterFields]="[
        'createAt',
        'active',
        'customerId',
        'supplierId',
        'cancelAt',
        'stockMovementType',
        'buyOrderId',
        'marinaOrderId'
      ]"
    >
      <ng-template pTemplate="caption">
        <div class="export-buttons">
          <!-- <button
            class="easymarine btn-csv-xls"
            label="Exportar Tabela"
            (click)="exportTable()"
            pTooltip="A exportação será de acordo com os filtros selecionados"
            tooltipPosition="top"
          >
            <span>Exportar CSV</span>
            <i class="pi pi-file-excel"></i>
          </button> -->
        </div>
        <span style="font-weight: bold; margin-top: 5px">
          {{ "TOTAL-REGISTERS" | translate }}: {{ stockMovements.length }}
          {{ "OF" | translate }} {{ stockMovementsDataTable?.totalRecords }}
        </span>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th class="text-center">
            {{totalItems}}
          </th>
          <th class="text-center">
            {{totalValue | currency : "BRL"}}
          </th>
          <th></th>
          <th></th>
          <th></th> 
        </tr>
        <tr>
          <th [pSortableColumn]="'createAt'">
            {{ "Emissão" | translate }}
            <p-sortIcon [field]="'createAt'"></p-sortIcon>
          </th>

          <th class="text-center" [pSortableColumn]="'stockMovementType'">
            {{ "Tipo de movimentação" | translate }}
            <p-sortIcon [field]="'stockMovementType'"></p-sortIcon>
          </th>

          <th>
            {{ "Fornecedor/Cliente" | translate }}
          </th>

          <th [pSortableColumn]="'marinaOrderId'">
            {{ "ORDER" | translate }}
            <p-sortIcon [field]="'marinaOrderId'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'buyOrderId'">
            {{ "Pedido de compra" | translate }}
            <p-sortIcon [field]="'buyOrderId'"></p-sortIcon>
          </th>
          <th class="text-center">
            {{ "TOTAL-ITENS" | translate }}
          </th>
          <th class="text-center">
            {{ "TOTAL-VALUE" | translate }}
          </th>
          <th [pSortableColumn]="'cancelAt'">
            {{ "Cancelado" | translate }}
            <p-sortIcon [field]="'cancelAt'"></p-sortIcon>
          </th>
          <th class="text-center">{{ "Visualizar" | translate }}</th>
          <th class="text-center">{{ "CANCEL" | translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-movement>
        <tr>
          <td
            style="text-align: center"
            [ngClass]="{
              'text-danger': movement.active == false,
            }"
          >
            {{ movement.createAt | date : "dd/MM/yyyy" }}
          </td>
          <td
            [ngClass]="{
            'text-danger': movement.active == false,
          }"
          >
            {{ movement.stockMovementType.name }}
          </td>
          <td
            [ngClass]="{
            'text-danger': movement.active == false,
          }"
          >
            {{ formatName(movement?.customerName, movement?.supplierName) }}
          </td>
          <td
            [ngClass]="{
            'text-danger': movement.active == false,
          }"
          >
            {{ movement?.marinaOrderId }}
          </td>
          <td
            [ngClass]="{
            'text-danger': movement.active == false,
          }"
          >
            {{ movement?.buyOrderId }}
          </td>
          <td
            [ngClass]="{
            'text-danger': movement.active == false,
          }"
            class="text-center"
          >
            {{ movement?.totalItem | number }}
          </td>
          <td
            [ngClass]="{
            'text-danger': movement.active == false,
          }"
            class="text-center"
          >
            {{ movement?.movementValue | currency : "BRL" }}
          </td>
          <td
            [ngClass]="{
            'text-danger': movement.active == false,
          }"
          >
            {{ movement?.cancelAt | date : "dd/MM/yyyy" }}
          </td>

          <td class="text-center">
            <div
              class="table-action"
              (click)="openMovementForm(movement)"
              pTooltip="Visualizar"
              tooltipPosition="bottom"
            >
              <i class="pi pi-search"></i>
            </div>
          </td>
          <td class="text-center">
            <div
              class="table-action"
              (click)="cancelMovement(movement)"
              pTooltip="Cancelar"
              tooltipPosition="bottom"
              style="color: red"
              *ngIf="movement.active == true && movement.automatic == false"
            >
              <i class="pi pi-trash"></i>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-fieldset>
