import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-customer-log-modal',
  templateUrl: './customer-log-modal.component.html',
  styleUrls: ['./customer-log-modal.component.scss'],
})
export class CustomerLogModalComponent implements OnInit {
  customerLog;
  selectTab = 0;

  isInformationChange = false;
  isResidentialAddress = false;
  isCommercialAddress = false;
  isEmergencyContact = false;
  isLicence = false;
  isIssRetidoFonte = false;
  isBillingCompanyLog = false;

  isTabGeneralData = false;
  isTabLicence = false;
  isTabBilling = false;

  constructor(
    private config: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    if(!this.config.data.customerLog || !this.config.data.customerLog.oldDatas){
      this.dialogRef.close();
    }
    this.customerLog = this.config.data.customerLog;
    this.createLogs();
    this.setSelectTab();
  }

  setSelectTab() {
    this.isTabGeneralData = this.isInformationChange
      || this.isResidentialAddress
      || this.isCommercialAddress
      || this.isEmergencyContact;

    this.isTabLicence = this.isLicence;
    this.isTabBilling = this.isIssRetidoFonte || this.isBillingCompanyLog ;

    if(this.isTabGeneralData){
      this.selectTab = 0;
    } else if(this.isTabLicence){
      this.selectTab = 1;
    } else {
      this.selectTab = 2;
    }
  }

  createLogs() {
    this.verifyChanges();
  }

  verifyChanges() {
    const oldDatasMap = new Map(Object.entries(this.customerLog.oldDatas));
    this.verifyChangesInformation(oldDatasMap);
    this.verifyChangesEmergencyContact(oldDatasMap);
    this.verifyChangesResidentialAddress(oldDatasMap);
    this.verifyChangesCommercialAddress(oldDatasMap);
    this.verifyChangesIssRetidoFonte(oldDatasMap);
    this.verifyChangesBillingCompanyLog(oldDatasMap);
    this.verifyChangesLicence(oldDatasMap);
  }

  verifyChangesBillingCompanyLog(oldDatasMap: Map<string, unknown>) {
    this.isBillingCompanyLog = oldDatasMap.has('billingCompanyLog');
  }

  verifyChangesIssRetidoFonte(oldDatasMap: Map<string, unknown>) {
    this.isIssRetidoFonte = oldDatasMap.has('issRetidoFonte');
  }

  verifyChangesCommercialAddress(oldDatasMap: Map<string, unknown>) {
    this.isCommercialAddress = oldDatasMap.has('commercialAddress');
  }

  verifyChangesResidentialAddress(oldDatasMap: Map<string, unknown>) {
    this.isResidentialAddress = oldDatasMap.has('residentialAddress');
  }

  verifyChangesLicence(oldDatasMap: Map<string, unknown>) {
    this.isLicence = oldDatasMap.has('licence');
  }

  verifyChangesInformation(oldDatasMap: Map<string, unknown>) {
    this.isInformationChange = oldDatasMap.has('name') || oldDatasMap.has('federalId')
      || oldDatasMap.has('gender') || oldDatasMap.has('registration') || oldDatasMap.has('email') || oldDatasMap.has('phone')
      || oldDatasMap.has('phone2') || oldDatasMap.has('residentialPhone') || oldDatasMap.has('commercialPhone')
      || oldDatasMap.has('birthday') || oldDatasMap.has('emailCollection') || oldDatasMap.has('billingDay')
      || oldDatasMap.has('dueDate') || oldDatasMap.has('customerGroupLog') || oldDatasMap.has('active')
      || oldDatasMap.has('maritalStatus') || oldDatasMap.has('spouse');
  }

  verifyChangesEmergencyContact(oldDatasMap: Map<string, unknown>) {
    this.isEmergencyContact = oldDatasMap.has('emergencyContact') || oldDatasMap.has('emergencyPhone')
      || oldDatasMap.has('observation');
  }

}
