<div class="totem">
    <div class="header">
        <span>
            ESCOLHA OS PRODUTOS NA LISTA ABAIXO    
        </span>
        <p-button class="new-fab-button" icon="pi pi-times" (click)="exit()"></p-button>
    </div>
    <div class="filters">
        <div class="categories">
            <div class="new-form-control-sm no-form">
                <span class="p-input-icon-right">
                <input
                type="text" (keyup)="filterProduct($event)" placeholder="Pesquisar produtos"
                class="new-input search"/>
                    <i class="pi pi-search"></i>
                </span>
            </div>
            <div class="buttons">
                <button (click)="selectCategory(null)" class="new-easymarine"
                    [ngClass]="selectedCategory ? 'disabled' : 'primary'">                    
                    {{ 'ALL' | translate}}</button>
                <span *ngFor="let category of productCategories">
                    <button (click)="selectCategory(category)" 
                        class="new-fab-button"
                        [ngClass]="selectedCategory == category?.name ? 'primary' : 'disabled'">
                        <img [src]="getIcons(category.name)" alt="" [class.custom-fill]="true">
                    </button>
                    {{ category.name }}
                </span>
            </div>
        </div>
    </div>
    <div class="body">
        <div class="products" (scroll)="onScroll()">
            <div class="grid-products">
                <div class="product-card" *ngFor="let product of productsFilter"
                [ngClass]="product?.avaliable <= 0 && product?.productType != 'Service' ? 'disabled' : ''"
                    (click)="openDialogSeparatedSale(product)"
                    [pTooltip]="product.name" tooltipPosition="top">
                    <div class="product-card-image">
                        <img *ngIf="product.file; else noimage" src="{{getProductThumb(product)}}"/>
                        <ng-template #noimage>
                            <img src="assets\images\sem_imagem.jpg" />
                        </ng-template>
                    </div>
                    <div class="product-card-description">
                        {{ product.name }}
                    </div>
                    <div *ngIf="product?.productType != 'Service' && product?.avaliable > 0"
                        class="product-card-stock">
                        {{ 'Estoque: ' + product?.avaliable }}
                    </div>
                    <div *ngIf="product?.productType != 'Service' && product?.avaliable <= 0"
                        class="product-card-stock bold">
                        {{ 'Fora de estoque' }}
                    </div>
                    <div class="product-card-price">
                        <span *ngIf="product.price.value">{{product.price.value | currency:' '}}</span>
                        <span *ngIf="!product.price.value">Preço livre</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="summary">
            <div class="order">
                <div class="order-item-header blue"><span>Carrinho</span></div>
                <div *ngFor="let order of orderedItems; let i = index">
                        <ul class="item-list">
                            <div class="full-width crop-text item-tile">
                                <div class="item-tile-first">
                                    <span>{{order.product.name }}</span>
                                </div>
                                <div class="item-tile-second">
                                    <span class="sub-label"
                                    pTooltip="Preço do Item" tooltipPosition="top">
                                        {{order.product.price.value | currency:' ' }}
                                    </span> 
                                    <button class="new-mini-fab-button neutral" (click)="addQuantity(order)"><i class="pi pi-plus-circle"></i></button>
                                    <p-inputNumber *ngIf="order.product.initialsMeasurement == 'KG' || order.product.initialsMeasurement == 'LT'; else kgOrLt"
                                        type="decimal" class="cart-input" [minFractionDigits]="3" [maxFractionDigits]="3" [step]="1" [max]="getMaxQuantity(order)"
                                        (ngModelChange)="updateQtySupplied(order, $event)" [ngModel]="order.qtySupplied" [ngModel]="order.qtyRequested"/>
                                    <ng-template #kgOrLt>
                                        <p-inputNumber type="number" class="cart-input" (ngModelChange)="updateQtySupplied(order, $event)" [ngModel]="order.qtySupplied" [ngModel]="order.qtyRequested" [max]="getMaxQuantity(order)" [min]="1"/>
                                    </ng-template>
                                    <button class="new-mini-fab-button neutral" (click)="removeQuantity(order)"><i class="pi pi-minus-circle"></i></button>
                                    <span class="sub-label"
                                    pTooltip="Total" tooltipPosition="top">
                                        {{ productTotalPrice(order.qtyRequested, order.product.price.value) | currency:' ' }}
                                    </span>
                                    <button class="new-fab-button danger" (click)="clearItem(order)"><i class="pi pi-trash"></i></button>
                                </div>
                            </div>
                        </ul>
                </div>
                <div class="totals">
                    <p style="font-size:15px; font-weight: bold;">TOTAL:</p>
                    <p class="font-currency float-right">{{total | currency:' '}}</p>
                </div>
                <div class="buttons">
                    <button class="success new-easymarine" type="button" (click)="payFinishRequest()">Pagar</button>
                    <button class="danger new-easymarine" type="button" (click)="cancelRequest()">Cancelar</button>
                </div>
            </div>
        </div>
    </div>
</div>



