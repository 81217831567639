import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Vacancy } from '../models';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { VacancyMapDTO } from '../models/dtos/vacancy-mapDTO';
import { VacancyStatusDTO } from '../models/dtos/vacancy-statusDTO';


@Injectable()
export class VacancyService {

    constructor(private http: HttpClient) {

    }

    getAll(campos?:string): Observable<Vacancy[]> {
      if (campos==undefined){ campos="";}
      let headers = new HttpHeaders();
      headers = headers.set('campos', campos);
      return this.http.get(environment.apiHost + '/secure/vacancies/',{headers: headers}).pipe(map((response: any) => response.data.vacancies as Vacancy[]));
    }

    getAllToBoats(): Observable<Vacancy[]> {
        return this.http.get(environment.apiHost + '/secure/vacancies/to-boats')
            .pipe(map((response: any) => response.data.vacancies as Vacancy[]));
    }

     getAllVacanciesFree(): Observable<Vacancy[]> {
        return this.http.get(environment.apiHost + '/secure/vacancies/vacancies-free')
            .pipe(map((response: any) => response.data.vacancies as Vacancy[]));
    }

    getById(id: number): Observable<Vacancy> {
        return this.http.get(environment.apiHost + '/secure/vacancy/' + id).pipe(map((response: any) => response.data.vacancy));
    }

    create(vacancy: Vacancy): Observable<Vacancy> {
      return this.http.post(environment.apiHost + '/secure/vacancy/', vacancy).pipe(map((response: any) => response.data.vacancy));
    }

    update(vacancy: Vacancy): Observable<Vacancy> {
        return this.http.put(environment.apiHost + '/secure/vacancy/', vacancy).pipe(map((response: any) => response));
    }

    delete(id: number): Observable<Vacancy> {
        return this.http.delete(environment.apiHost + '/secure/vacancy/' + id).pipe(map((response: any) => response.data.vacancy));
    }

    forceDelete(id: number): Observable<Vacancy> {
        return this.http.delete(environment.apiHost + '/secure/vacancy/force/' + id).pipe(map((response: any) => response.data.vacancy));
    }

    getQRCode(vacancyId): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'image/png');
        return this.http.get(
            environment.apiHost + '/secure/vacancy/qrcode/' + vacancyId, { responseType: 'blob' }).pipe(map((response: any) => response));
    }

    getQRCodes(vacancyIds): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Accept', 'application/pdf');
        return this.http.get(
            environment.apiHost + '/secure/vacancy/qrcodes/' + vacancyIds, { headers, responseType: 'blob' }
        ).pipe(map((response: any) => response));
    }

    updateStatus(vacancyId: number, active: boolean): Observable<Vacancy> {
      return this.http.put(environment.apiHost + `/secure/vacancy/update-status/${vacancyId}`, active).pipe(map((response: any) => response));
  }

  getActivesByLocationType(locationType:string): Observable<VacancyMapDTO[]> {
    return this.http.get(environment.apiHost + '/secure/vacancies/location-type/' + locationType).pipe(map((response: any) => response.data.vacancies));
  }

  getWetVacanciesStatus(locationType:string): Observable<VacancyStatusDTO[]> {
    return this.http.get(environment.apiHost + '/secure/vacancies/status/' + locationType).pipe(map((response: any) => response.data.vacancies));
  }

  setBoat(vacancyuId:number,boatId:number): Observable<any> {
    return this.http.post(environment.apiHost + '/secure/vacancy/set-boat/' + vacancyuId + '/' + boatId,null).pipe(map((response: any) => response.data.vacancies));
  }

  unsetBoat(boatId:number): Observable<any> {
    return this.http.post(environment.apiHost + '/secure/vacancy/unset-boat/' + boatId,null).pipe(map((response: any) => response.data.vacancies));
  }


}
