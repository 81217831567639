export class InvoiceBankingBillet {
    id: number;
    active: boolean;
    sequence: number;
    documentUrl: string;
    issueDate: string;
    dueDate: string;
    value: number;
    identifier: string;
    identifierVD: string;
    invoiceId: number;
    idCarteiraCobranca: number;
    paid:boolean;
    registered:boolean;
    refused:boolean;
    processed:boolean;
    bankNumber: number;
}
