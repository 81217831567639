<p-table [value]="washOrEngineTurnHistory" autoLayout="true">
    <ng-template pTemplate="header">
        <tr>
            <th>Data</th>
            <th>Usuário</th>
            <th class="text-center">Origem</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-washOrEngineTurn>
        <tr>
            <td>{{washOrEngineTurn.dateHistory | date: 'dd/MM/yyyy'}}</td>
            <td>{{washOrEngineTurn.userName}}</td>
            <td class="text-center">
                <i *ngIf="!washOrEngineTurn.isSea" class="pi pi-clock" style="color: #002640; font-size: 25px;"></i>
                <img *ngIf="washOrEngineTurn.isSea" src="assets/images/sea.png"
                    style="height: 40px; width: 50px; padding: 7px 3px;" />
            </td>
        </tr>
    </ng-template>
</p-table>

<div *ngIf="washOrEngineTurnHistory.length === 0">
    <div>
        <div>
            <strong>Nenhum registro encontrado.</strong>
        </div>
    </div>
</div>