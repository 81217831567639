import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ObjectiveOptionDTO } from 'src/app/models/dtos/movements/objectives/objective-option-dto';
import { AvaliablePeriodsDTO } from 'src/app/models/dtos/operational-options/avaliable-periods-dto';
import { BoatSimples } from 'src/app/models/dtos/operational-options/responsibles';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MovementPlanAppointmentService {

  apiUrl = environment.apiHost + '/secure/movement-plan'

  constructor(
    private readonly http: HttpClient
  ) { }

  findBoatOptionSelect(boatId: number): Observable<BoatSimples> {
    return this.http.get(this.apiUrl + '/option-select/boats/' + boatId)
    .pipe(map((res: any) => {
      return res.data.boat;
    }))
  }

  findAvaliablePeriods(boatId: number, datetime: Date, toReturn: boolean=false): Observable<AvaliablePeriodsDTO> {
    const params = new HttpParams().set('boat', '' + boatId).set('return', JSON.stringify(toReturn))
    return this.http.get(this.apiUrl + '/options/avaliable-periods/' + datetime.getTime(), { params })
    .pipe(map((res: any) => {
      return res.data.avaliablePeriods as AvaliablePeriodsDTO;
    }))
  }

  findMovementObjectives(): Observable<ObjectiveOptionDTO[]> {
    return this.http.get(this.apiUrl + '/options/objectives')
    .pipe(map((res: any) => {
      return res.data.objectives;
    }))
  }
}
