import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Marina } from '../models';
import { MarinaIdNameDTO } from '../models/dtos/marina/marinaIdNameDTO';

@Injectable({
  providedIn: 'root',
})
export class MarinaService {
  constructor(private http: HttpClient) {}

  getMarina(marinaId: number): Observable<Marina> {
    return this.http.get(environment.apiHost + '/secure/marina/' + marinaId).pipe(map((response: any) => response.data.marina));
  }

  getMarinas(): Observable<Marina[]> {
    return this.http.get(environment.apiHost + '/secure/marinas/list').pipe(map((response: any) => response.data.marinas));
  }

  getMarinaIdAndName(userId: Number): Observable<MarinaIdNameDTO[]> {
  const apiLink = environment.apiHost + "/secure/marinas/marina-id-name/" + userId;

  return this.http.get(apiLink)
    .pipe(map((response: any) => response.data.marinaIdName));
  }

  getMarinaFieldsLogo(marinaId: number): Observable<any> {
    return this.http
      .get(`${environment.apiHost}/marinas/marina-logo/${marinaId}`)
      .pipe(map((resp: any) => resp.data.marinaInfo));
  }

  getMarinaNfP(marinaId: number): Observable<any> {
    return this.http
      .get(`${environment.apiHost}/marinas/marina-nfp-emitter/${marinaId}`)
      .pipe(map((resp: any) => resp.data.nfpEmitter));
  }

}
