<p-fieldset>
  <p-header class="article-title">
    <span class="article-title">{{ 'Faturamento > Mensalidade' | translate }}</span>
    <span style="color: red; float: right; margin-top: 5px; font-weight: bold;">Verificar cliente(s) em
            vermelho</span>
    <button class="fab-button primary" appFiltersButton pTooltip="Exibir/Ocultar Filtros" tooltipPosition="left">
      <i class="pi pi-filter"></i>
    </button>
  </p-header>
  <p-tabView>
    <p-tabPanel [cache]="false" header="Embarcação">
      <ng-template pTemplate="content" >
        <app-boat-tuitions [groups]="groups" [groupsList]="groupsList" [loggedMarina]="loggedMarina"
        [marinaCompaniesList]="marinaCompaniesList"></app-boat-tuitions>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel [cache]="false" header="Cliente" *ngIf="activateTuitionByCustomer">
      <ng-template pTemplate="content">
        <app-customer-tuitions [groups]="groups" [groupsList]="groupsList" [loggedMarina]="loggedMarina"
        [marinaCompaniesList]="marinaCompaniesList"></app-customer-tuitions>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
</p-fieldset>
