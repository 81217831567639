import { StatusInvoice } from '../enums/status-invoice';
import { CustomerCreditCustomer } from './customer-credit-customer';

export class CustomerCreditInvoice {

  id: number;
  invoiceNumber: number;
  customer: CustomerCreditCustomer;
  status: StatusInvoice;

  constructor() {
    this.id = null;
    this.invoiceNumber = null;
    this.customer = new CustomerCreditCustomer();
    this.status = StatusInvoice.PENDING;
  }
}
