// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { APPCONFIG } from 'src/app/config';

export const environment = {
  production: false,
  apiHost: APPCONFIG.apiHost, // process.env.API_URL,
  apiLog: APPCONFIG.apiLog, // process.env.API_URL,
  d4signApi: APPCONFIG.d4signApi,
  billetApi: APPCONFIG.billetApi, // process.env.API_URL,
  easyWebPage: APPCONFIG.easyWebPage, // process.env.API_URL,
  environmentApp: APPCONFIG.environmentApp, // process.env.API_URL,
  environmentAppClass: APPCONFIG.environmentAppClass, // process.env.API_URL,
  finApi: APPCONFIG.finApi, // process.env.API_URL,
  finApiIntegracao: APPCONFIG.finApiIntegracao, // process.env.API_URL,
  finHost: APPCONFIG.finHost,
  finHost2: APPCONFIG.finHost2, // process.env.API_URL,
  productLink: APPCONFIG.productLink, // process.env.API_URL,
  userChamadoIntegracao: APPCONFIG.userChamadoIntegracao, // process.env.API_URL,
  workshopHost: APPCONFIG.workshopHost,
  workshopApi: APPCONFIG.workshopApi,
  chamadosApi: APPCONFIG.chamadosApi,
  nixxBankLoginApi: APPCONFIG.nixxBankLoginApi,
  googleMapsAPI: APPCONFIG.googleMapsAPI,
  apiNotification: APPCONFIG.apiNotification,
  apiLogV2: APPCONFIG.apiLogV2,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
