import { MovementExecutionEditModalComponent } from './../modals/movement-execution-edit-modal/movement-execution-edit-modal.component';
import { MovementPlainViewComponent } from './../modals/movement-plain-view/movement-plain-view.component';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { ExecuteOperationDTO } from 'src/app/models/dtos/movements/execute-operationDTO';
import { MovementOperation } from 'src/app/models/enums/movements/movement-operation';
import { MovementStatus } from 'src/app/models/enums/movements/movement-status';
import { BoatMovementDTO } from 'src/app/models/dtos/movements/boat-movement-dto';
import { MovementExecutionService } from 'src/app/services/movements/movement-execution.service';
import { ToastService } from 'src/app/services/toast.service';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { MovementExecutionFinishModalComponent } from '../modals/movement-execution-finish-modal/movement-execution-finish-modal.component';
import { StorageUtil } from 'src/app/utils/storage.util';
import { MovementLocationParams } from 'src/app/components/movements/movements-operational/movement-location/movement-location.component';
import { FilterBoatMovement } from 'src/app/models/dtos/movements/filter-boat-movement';

@Component({
  selector: 'app-movement-execution',
  templateUrl: './movement-execution.component.html',
  styleUrls: ['./movement-execution.component.scss']
})
export class MovementExecutionComponent implements OnInit, OnDestroy, OnChanges {

  movements: BoatMovementDTO[] = [];
  MovementSubscription: Subscription;

  movementInteractList: number[] = [];
  movementLastInteract: number;

  @Input()
  filter: FilterBoatMovement = { };

  constructor(
    private movementExecutionService: MovementExecutionService,
    private messageUtil: MessageUtil,
    private toasty: ToastService,
    private spinner: NgxSpinnerService,
    private webSocketService: WebSocketService,
    public dialog: DialogService,
  ) { }

  ngOnInit(): void {
    this.loadMovementInteractions();
    this.getMovements();
    this.MovementSubscription = this.webSocketService.getMessageMovement().subscribe(async (message) => {
      await this.getMovements();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.filter;
    this.getMovements();
  }

  loadMovementInteractions(): void {
    this.movementInteractList = StorageUtil.getMovementInteractList();
    this.movementLastInteract = StorageUtil.getMovementLastInteract();
  }

  ngOnDestroy(): void {
    this.webSocketService.unsubscribeMovement(this.MovementSubscription);
  }

  getMovements(){
    this.spinner.show();
    this.movementExecutionService.getAll(this.filter).subscribe(
      (data) => {
        this.movements = data;
        this.removeMovementExecutionInteractionsFinished();
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    )
  }

  executeOperation(movement : BoatMovementDTO) {
    const executeOperation = {
      movementPlanId: movement.movement.movementPlanId,
      movementId: movement.movement.movementId,
      operation: movement.nextOperation
    }
    if(executeOperation.operation === MovementOperation.FINISH) {
      this.openFinishDialog(movement, executeOperation);
    } else {
      this.sendExecuteOperation(executeOperation);
    }
  }

  private sendExecuteOperation(executeOperation: ExecuteOperationDTO){
    this.spinner.show();
    this.movementExecutionService.executeOperation(executeOperation).subscribe(
      data => {
        this.toasty.success('Operação realizada com sucesso!');
        this.updateInteractions(executeOperation);
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    )
  }

  updateInteractions(executeOperation: ExecuteOperationDTO) {
    if(!this.movementInteractList.includes(executeOperation.movementId)){
      this.movementInteractList.push(executeOperation.movementId);
    }

    this.movementLastInteract = executeOperation.movementId;
    StorageUtil.updateMovementExecutionInteractions(this.movementInteractList, this.movementLastInteract);
  }

  removeMovementExecutionInteractionsFinished(){
    if(this.movementLastInteract && !this.movements.find(m => m.movement.movementId === this.movementLastInteract)) {
      this.movementLastInteract = undefined;
    }

    if(this.movementInteractList.length > 0) {
      const newList = [];
      this.movements.forEach( boatMovement  => {
        if(this.movementInteractList.includes(boatMovement.movement.movementId)){
          newList.push(boatMovement.movement.movementId);
        }
      })

      this.movementInteractList = newList;
    }

    StorageUtil.updateMovementExecutionInteractions(this.movementInteractList, this.movementLastInteract);
  }

  isStarted(boatMovement : BoatMovementDTO) {
    return boatMovement.movement.movementStatus == MovementStatus.STARTED;
  }

  isPrepared(boatMovement : BoatMovementDTO) {
    return boatMovement.movement.movementStatus == MovementStatus.PREPARED;
  }

  isEnableCancel(boatMovement : BoatMovementDTO){
    return !!boatMovement.backOperation;
  }

  isHighlighted(boatMovement : BoatMovementDTO): boolean {
    switch (this.filter.highlightInteractions) {
      case 2:
        return this.isLastInteraction(boatMovement);
      case 3:
        return this.isInInteractionsList(boatMovement);
      default:
        return false;
    }
  }

  isLastInteraction(boatMovement : BoatMovementDTO): boolean {
    return this.movementLastInteract && this.movementLastInteract === boatMovement.movement.movementId
  }

  isInInteractionsList(boatMovement : BoatMovementDTO): boolean {
    return this.movementInteractList?.length > 0 && this.movementInteractList.includes(boatMovement.movement.movementId);
  }

  cancelLastOperation(boatMovement : BoatMovementDTO){
    const executeOperation = {
      movementPlanId: boatMovement.movement.movementPlanId,
      movementId: boatMovement.movement.movementId,
      operation: boatMovement.backOperation
    }
    this.sendExecuteOperation(executeOperation);
  }

  getDestiny(movement : BoatMovementDTO): MovementLocationParams {
    return {
      type: movement?.movement.destinyLocationType,
      name: movement?.movement?.destinyLocationName,
      abbreviation: movement?.movement?.destinyLocationAbbreviation,
      vacancyCode: movement?.movement?.destinyVacancyCode
    }
  }

  getOrigin({ currentLocation } : BoatMovementDTO): MovementLocationParams {
    return {
      type: currentLocation.type,
      name: currentLocation.name,
      abbreviation: currentLocation.abbreviation,
      vacancyCode: currentLocation.vacancyCode
    }
  }

  async openEditDialog(movement : BoatMovementDTO): Promise<void> {
    this.dialog.open(MovementExecutionEditModalComponent, {
      width: '800px',
      height: '400px',
      header: "Edição de movimentação",
      data: {
        movement
      }
    }).onClose.subscribe(
      (data) => { }
    );
  }

  async openFinishDialog(movement : BoatMovementDTO, executeOperation : ExecuteOperationDTO): Promise<void> {
    this.dialog.open(MovementExecutionFinishModalComponent, {
      width: '800px',
      height: '400px',
      header: "Finalização de movimentação",
      data: {
        movement
      }
    }).onClose.subscribe(
      (data) => {
        if(data) {
          executeOperation.locationFinish = data;
          this.sendExecuteOperation(executeOperation);
        }
      }
    );
  }

  async movementPlainView(boatMovement: BoatMovementDTO): Promise<void> {
    const movement = boatMovement.movement;
    if (movement) {
      this.dialog.open(MovementPlainViewComponent, {
        width: '550px',
        height: '650px',
        header: "Visualizar plano de movimentações",
        data: {
          movement
        }
      }).onClose.subscribe(
        (data) => {
          if(data) {
            console.log(data);
          }
        }
      );
    }
  }
}
