<div class="dialog-form">
    <form (submit)="export()" [formGroup]="exportForm">
        <div class="form-group">
            <div  class="form-control">
                <label for="start">Data inicial</label>
                <p-calendar dateFormat="dd/mm/yy" name="start" [monthNavigator]="true"
                        [yearNavigator]="true" [yearRange]="'2000:2055'" rPCalendarMask
                        formControlName="start" [showIcon]="true" i18n pTooltip="Date inicial do periodo" 
                        tooltipPosition="bottom" appendTo="body">
                </p-calendar>
            </div>
            <div  class="form-control">
                <label for="end">Data Final</label>
                <p-calendar dateFormat="dd/mm/yy" name="end" [monthNavigator]="true"
                        [yearNavigator]="true" [yearRange]="'2000:2055'" rPCalendarMask
                        formControlName="end" [showIcon]="true" i18n pTooltip="Date final do periodo"
                        tooltipPosition="bottom" appendTo="body">
                </p-calendar>
            </div>
        </div>
        <div class="form-group align-center">
            <div class="buttons">
                <button class="easymarine danger" type="button"
                    (click)="dialogRef.close()">{{ 'CANCEL' | translate }}</button>
                <button class="easymarine primary" type="submit" [disabled]="exportForm.invalid">{{ 'EXPORT-PDF' | translate}}</button>
            </div>
        </div>
    </form>
</div>