import { MovementLocationType } from "src/app/models/enums/movements/movement-location-type";

export class MovementTableUtil {

  public getArrowDirectionUrlImage(direction: 'UP' | 'DOWN', darkMode: boolean) {
    if (!direction) {
      return null;
    }
    return '/assets/images/movements/arrow_'.concat(direction + (darkMode ? '_white' : '')).concat('.svg');
  }
}
