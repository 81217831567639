<div style="text-align: center; align-items: center">
  <div
    class="form-group"
    style="
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    "
    *ngIf="!error"
  >
    <video
      [class.show]="!isCaptured"
      [class.hide]="isCaptured"
      #video
      id="video"
      (loadedmetadata)="onMetadataLoaded($event)"
      autoplay
    ></video>
    <canvas
      [class.show]="isCaptured"
      [class.hide]="!isCaptured"
      #canvas
      id="canvas"
      [width]="WIDTH"
      [height]="HEIGHT"
    ></canvas>
  </div>

  <div class="form-group">
    <button
      class="easymarine primary"
      style="margin: 3px"
      *ngIf="!isCaptured"
      (click)="capture()"
    >
      Fotografar
    </button>
    <button
      class="easymarine danger"
      style="margin: 3px"
      *ngIf="isCaptured"
      (click)="removeCurrent()"
    >
      Alterar Foto
    </button>
    <button
      class="easymarine primary"
      style="margin: 3px"
      *ngIf="isCaptured"
      (click)="selecionar()"
    >
      Selecionar
    </button>
  </div>

  <div class="formGroup">
    Escolha uma das fotos abaixo ou fotografe novamente.
    <br />
    <ul class="list-unstyled">
      <li *ngFor="let src of captures; let idx = index" (click)="setPhoto(idx)">
        <img [src]="src" height="50" />
      </li>
    </ul>
  </div>
</div>
