import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DayWeek } from 'src/app/models/enums/day-week';

@Component({
  selector: 'app-copy-and-paste-multiple-modal',
  templateUrl: './copy-and-paste-multiple-modal.component.html',
  styleUrls: ['./copy-and-paste-multiple-modal.component.scss']
})
export class CopyAndPasteMultipleModalComponent {

  toCopy: { label: string, value: any };
  toPaste: { label: string, value: any, selected: boolean }[] = [];

  constructor(
    private readonly config: DynamicDialogConfig,
    private readonly dialogRef: DynamicDialogRef
  ) {
    this.toCopy = this.config.data.toCopy;
    this.toPaste = this.config.data.toPaste.map(d => ({ ...d, selected: false }));
  }

  copy() {
    this.dialogRef.close({ toCopy: this.toCopy.value, paste: this.toPaste.filter(item => item.selected).map(item => item.value)});
  }

  close() {
    this.dialogRef.close();
  }

}
