<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{ 'TITLE-SLING-LOG' | translate }}</span>
        <button class="fab-button primary" appFiltersButton pTooltip="Exibir/Ocultar Filtros" tooltipPosition="left">
            <i class="pi pi-filter"></i>
        </button>
    </p-header>

    <form class="filters" (submit)="find()" appFilters>
        <div class="filter-row">
            <div class="form-control">
                <label>Data inicial</label>
                <p-calendar dateFormat="dd/mm/yy" name="dateStart" [monthNavigator]="true" appendTo="body"
                    [yearNavigator]="true" [yearRange]="'2000:2055'" rPCalendarMask [(ngModel)]="filter.dateStart"
                    [showIcon]="true" i18n>
                </p-calendar>
            </div>
            <div class="form-control">
                <label>Data final</label>
                <p-calendar dateFormat="dd/mm/yy" name="dateEnd" [monthNavigator]="true" [yearNavigator]="true"
                    [yearRange]="'2000:2055'" rPCalendarMask appendTo="body" [(ngModel)]="filter.dateEnd"
                    [showIcon]="true" i18n>
                </p-calendar>
            </div>
            <div class="form-control">
                <label>{{ 'USER' | translate }}</label>
                <p-dropdown placeholder="Selecione..." [filter]="true" [options]="filteredUsers" appendTo="body"
                    [(ngModel)]="filter.userId" name="selectedUserId">
                </p-dropdown>
            </div>
            <div class="form-control">
                <label>{{ 'BOAT' | translate }}</label>
                <p-dropdown placeholder="Selecione..." [filter]="true" [options]="boatsFilter" appendTo="body"
                    [(ngModel)]="filter.boatId" name="selectedBoatId">
                </p-dropdown>
            </div>
            <div class="buttons">
                <div class="form-button">
                    <button type="submit" class="primary easymarine">{{ 'FIND' | translate }}</button>
                </div>
            </div>
        </div>
    </form>
    <app-sling-log-table [slingLogs]="slingLogs"></app-sling-log-table>
</p-fieldset>