import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BoatLogService } from 'src/app/services/boat-log.service';
import { CustomerLogService } from 'src/app/services/customer-log.service';
import { CustomerService } from 'src/app/services/customer.service';
import { UserService } from 'src/app/services/user.service';
import { StorageUtil } from 'src/app/utils/storage.util';

@Component({
  selector: 'app-customer-log',
  templateUrl: './customer-log.component.html',
  styleUrls: ['./customer-log.component.scss']
})
export class CustomerLogComponent implements OnInit {


  customerLogs = [];
  filteredUsers = [];
  customersFilter = []
  filter = { marinaId: 0, dateStart: new Date(), dateEnd: new Date(), userId: null, customerId: null };

  pt = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  };

  constructor(
    private customerLogService: CustomerLogService,
    private userService: UserService,
    private customerService: CustomerService,
    private spinner: NgxSpinnerService,
  ) { }

  async ngOnInit(): Promise<void> {
    const marina = StorageUtil.getMarina();
    this.filter.marinaId = marina.id;
    this.loadUsers();
    this.loadCustomer();
    this.spinner.show();
    await this.find();
    this.spinner.hide();
  }

  async find(): Promise<void> {
    return new Promise<void>(async resolve => {
      this.customerLogService.findCustomers(this.filter).subscribe({
        next: async (data) => {
          this.customerLogs = data;
          resolve();
        },
        error: async (error) => {
          console.error(error);
          resolve();
        }
      });
    });
  }

  private loadUsers(): void {
    this.userService.getMarinaUsers().subscribe(
      (data) => {
        this.filteredUsers = data.map((c) => ({ label: c.firstName.concat(' ').concat(c.lastName), value: c.id }));
        this.filteredUsers.unshift({ label: 'Todos', value: null });
      }
    );
  }

  loadCustomer(): void {
    this.customerService.getCustomerFilterList().subscribe(
      (data) => {
        this.customersFilter = data.map((c) => ({ label: c.name, value: c.id }));
        this.customersFilter.unshift({ label: 'Todos', value: null });
      }
    );
  }

}
