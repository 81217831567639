<div class="dialog-form">
    <h4><strong>Status</strong></h4>
    <table class="table table-striped" width="100%">
        <tr>
            <td width="15%"><strong>Ícone</strong></td>
            <td width="85%"><strong>Descrição</strong></td>
        </tr>
        <tr>
            <td class="help-calendar1">
                <fa-icon [icon]="['far', 'calendar-check']" class="green-icon"></fa-icon>
            </td>
            <td>Fatura paga.</td>
        </tr>
        <tr>
            <td class="help-calendar2">
                <fa-icon [icon]="['far', 'calendar-times']" class="red-icon"> </fa-icon>
            </td>
            <td>Fatura em aberto e vencida. Ao clicar é possível realizar um pagamento.</td>
        </tr>
        <tr>
            <td class="help-calendar3">
                <fa-icon [icon]="['far', 'calendar-alt']" class="blue-icon"> </fa-icon>
            </td>
            <td>Fatura em aberto e dentro do prazo de pagamento. Ao clicar é possível realizar um pagamento.</td>
        </tr>
    </table>
    <br>
    <h4><strong>Boleto</strong></h4>
    <table class="table table-striped" width="100%">
        <tr>
            <td width="15%"><strong>Ícone</strong></td>
            <td width="85%"><strong>Descrição</strong></td>
        </tr>
        <tr>
            <td>
                <img src="../../../../assets/icons/barcode-icons/barcode-registered.svg" class="barcode-icon" alt="Boleto Registrado" tooltipPosition="right" pTooltip="Boleto Registrado">
            </td>
            <td>Boleto registrado.</td>
        </tr>
        <tr>
            <td>
                <img src="../../../../assets/icons/barcode-icons/barcode-refused.svg" class="barcode-icon" alt="Boleto Recusado" tooltipPosition="right" pTooltip="Boleto Recusado">
            </td>
            <td>Boleto recusado.</td>
        </tr>
        <tr>
            <td>
                <img src="../../../../assets/icons/barcode-icons/barcode-pending.svg" class="barcode-icon" alt="Boleto Pendente" tooltipPosition="right" pTooltip="Boleto Pendente">
            </td>
            <td>Boleto pendente.</td>
        </tr>
        <tr>
            <td>
                <img src="../../../../assets/icons/barcode-icons/barcode-waiting.svg" class="barcode-icon" alt="Boleto Aguardando" tooltipPosition="right" pTooltip="Boleto Aguardando">
            </td>
            <td>Boleto aguardando.</td>
        </tr>
        <tr>
            <td>
                <img src="../../../../assets/icons/barcode-icons/barcode-paid.svg" class="barcode-icon" alt="Boleto Pago" tooltipPosition="right" pTooltip="Boleto Pago">
            </td>
            <td>Boleto pago.</td>
        </tr>
        <tr>
            <td>
                <img src="../../../../assets/icons/barcode-icons/barcode-installments.svg" class="barcode-icon" alt="Boleto Parcelado" tooltipPosition="right" pTooltip="Boleto Parcelado">
            </td>
            <td>Boleto parcelado.</td>
        </tr>
        <tr>
          <td>
            <img src="../../../../assets/icons/barcode-icons/barcode-canceled.svg" class="barcode-icon" alt="Boleto Cancelado" tooltipPosition="right" pTooltip="Boleto Cancelado">
          </td>
          <td>Boleto cancelado.</td>
        </tr>
        <tr>
          <td>
            <div class="container-N">
                <img src="../../../../assets/icons/barcode-icons/barcode-registered.svg" class="barcode-icon" alt="N Boletos Registrados" tooltipPosition="right" pTooltip="N Boletos Registrados">
                <sup><strong>N</strong></sup>
            </div>
          </td>
          <td>Existem <strong>N</strong> boletos.</td>
        </tr>
        <tr>
          <td class="billet-empty">
            <img src="../../../../assets/icons/barcode-icons/barcode-empty.svg" class="barcode-icon" alt="Fatura com valor R$ 0,00." tooltipPosition="right" pTooltip="Fatura com valor R$ 0,00.">
          </td>
          <td>Fatura paga sem boleto.</td>
        </tr>
    </table>
    <br>
    <h4><strong>Boletos Agrupados</strong></h4>
    <table class="table table-striped" width="100%">
        <tr>
            <td width="15%"><strong>Ícone</strong></td>
            <td width="85%"><strong>Descrição</strong></td>
        </tr>
        <tr>
            <td>
                <img src="../../../../assets/icons/barcode-icons/barcode-registered-shared.svg" class="barcode-icon" alt="Boleto Agrupado Registrado" tooltipPosition="right" pTooltip="Boleto Agrupado Registrado">
            </td>
            <td>Boleto agrupado registrado.</td>
        </tr>
        <tr>
            <td>
                <img src="../../../../assets/icons/barcode-icons/barcode-refused-shared.svg" class="barcode-icon" alt="Boleto Agrupado Recusado" tooltipPosition="right" pTooltip="Boleto Agrupado Recusado">
            </td>
            <td>Boleto agrupado recusado.</td>
        </tr>
        <tr>
            <td>
                <img src="../../../../assets/icons/barcode-icons/barcode-pending-shared.svg" class="barcode-icon" alt="Boleto Agrupado Pendente" tooltipPosition="right" pTooltip="Boleto Agrupado Pendente">
            </td>
            <td>Boleto agrupado pendente.</td>
        </tr>
        <tr>
            <td>
                <img src="../../../../assets/icons/barcode-icons/barcode-waiting-shared.svg" class="barcode-icon" alt="Boleto Agrupado Aguardando" tooltipPosition="right" pTooltip="Boleto Agrupado Aguardando">
            </td>
            <td>Boleto agrupado aguardando.</td>
        </tr>
        <tr>
            <td>
                <img src="../../../../assets/icons/barcode-icons/barcode-paid-shared.svg" class="barcode-icon" alt="Boleto Agrupado Pago" tooltipPosition="right" pTooltip="Boleto Agrupado Pago">
            </td>
            <td>Boleto agrupado pago.</td>
        </tr>
        <tr>
          <td>
            <img src="../../../../assets/icons/barcode-icons/barcode-canceled-shared.svg" class="barcode-icon" alt="Boleto Agrupado Cancelado" tooltipPosition="right" pTooltip="Boleto Agrupado Cancelado">
          </td>
          <td>Boleto agrupado cancelado.</td>
        </tr>
        <tr>
          <td>
            <div class="container-N">
                <img src="../../../../assets/icons/barcode-icons/barcode-registered-shared.svg" class="barcode-icon" alt="N Boletos Agrupados Registrados" tooltipPosition="right" pTooltip="N Boletos Agrupados Registrados">
                <sup><strong>N</strong></sup>
            </div>
          </td>
          <td>Existem <strong>N</strong> boletos agrupados.</td>
        </tr>
    </table>
    <br>
    <h4><strong>Boleto Banco Nix</strong></h4>
    <table class="table table-striped easy-bank" width="100%">
        <tr>
            <td width="15%"><strong>Ícone</strong></td>
            <td width="85%"><strong>Descrição</strong></td>
        </tr>
        <tr>
            <td class="nix-bank align-left">
                <img src="../../../../assets/icons/barcode-icons/barcode-registered-nix.svg" class="barcode-icon" alt="Boleto Nix Registrado" tooltipPosition="right" pTooltip="Boleto Nix Registrado">
            </td>
            <td>Boleto nix registrado.</td>
        </tr>
        <tr>
            <td class="nix-bank align-left">
                <img src="../../../../assets/icons/barcode-icons/barcode-refused-nix.svg" class="barcode-icon" alt="Boleto Nix Recusado" tooltipPosition="right" pTooltip="Boleto Nix Recusado">
            </td>
            <td>Boleto nix recusado.</td>
        </tr>
        <tr>
            <td class="nix-bank align-left">
                <img src="../../../../assets/icons/barcode-icons/barcode-pending-nix.svg" class="barcode-icon" alt="Boleto Nix Pendente" tooltipPosition="right" pTooltip="Boleto Nix Pendente">
            </td>
            <td>Boleto nix pendente.</td>
        </tr>
        <tr>
            <td class="nix-bank align-left">
                <img src="../../../../assets/icons/barcode-icons/barcode-waiting-nix.svg" class="barcode-icon" alt="Boleto Nix Aguardando" tooltipPosition="right" pTooltip="Boleto Nix Aguardando">
            </td>
            <td>Boleto nix aguardando.</td>
        </tr>
        <tr>
            <td class="nix-bank align-left">
                <img src="../../../../assets/icons/barcode-icons/barcode-paid-nix.svg" class="barcode-icon" alt="Boleto Nix Pago" tooltipPosition="right" pTooltip="Boleto Nix Pago">
            </td>
            <td>Boleto nix pago.</td>
        </tr>
        <tr>
          <td class="nix-bank align-left">
            <img src="../../../../assets/icons/barcode-icons/barcode-installments-nix.svg" class="barcode-icon" alt="Boleto Nix Parcelado" tooltipPosition="right" pTooltip="Boleto Nix Parcelado">
          </td>
            <td>Boleto nix parcelado.</td>
        </tr>
        <tr>
          <td class="nix-bank align-left">
            <img src="../../../../assets/icons/barcode-icons/barcode-canceled-nix.svg" class="barcode-icon" alt="Boleto Nix Cancelado" tooltipPosition="right" pTooltip="Boleto Nix Cancelado">
          </td>
          <td>Boleto nix cancelado.</td>
        </tr>
        <tr>
          <td class="nix-bank align-left">
            <div class="container-N">
                <img src="../../../../assets/icons/barcode-icons/barcode-registered-N-nix.svg" class="barcode-icon" alt="N Boletos Nix Registrados" tooltipPosition="right" pTooltip="N Boletos Nix Registrados">
                <sup><strong>N</strong></sup>
            </div>
          </td>
          <td>Vários boletos.</td>
        </tr>
    </table>
    <br>
    <h4><strong>Boleto Banco Nix Agrupados</strong></h4>
    <table class="table table-striped easy-bank" width="100%">
        <tr>
            <td width="15%"><strong>Ícone</strong></td>
            <td width="85%"><strong>Descrição</strong></td>
        </tr>
        <tr>
            <td class="nix-bank align-left">
                <img src="../../../../assets/icons/barcode-icons/barcode-registered-nix-shared.svg" class="barcode-icon" alt="Boleto Nix Agrupado Registrado" tooltipPosition="right" pTooltip="Boleto Nix Agrupado Registrado">
            </td>
            <td>Boleto nix agrupado registrado.</td>
        </tr>
        <tr>
            <td class="nix-bank align-left">
                <img src="../../../../assets/icons/barcode-icons/barcode-refused-nix-shared.svg" class="barcode-icon" alt="Boleto Nix Agrupado Recusado" tooltipPosition="right" pTooltip="Boleto Nix Agrupado Recusado">
            </td>
            <td>Boleto nix agrupado recusado.</td>
        </tr>
        <tr>
            <td class="nix-bank align-left">
                <img src="../../../../assets/icons/barcode-icons/barcode-pending-nix-shared.svg" class="barcode-icon" alt="Boleto Nix Agrupado Pendente" tooltipPosition="right" pTooltip="Boleto Nix Agrupado Pendente">
            </td>
            <td>Boleto nix agrupado pendente.</td>
        </tr>
        <tr>
            <td class="nix-bank align-left">
                <img src="../../../../assets/icons/barcode-icons/barcode-waiting-nix-shared.svg" class="barcode-icon" alt="Boleto Nix Agrupado Aguardando" tooltipPosition="right" pTooltip="Boleto Nix Agrupado Aguardando">
            </td>
            <td>Boleto nix agrupado aguardando.</td>
        </tr>
        <tr>
            <td class="nix-bank align-left">
                <img src="../../../../assets/icons/barcode-icons/barcode-paid-nix-shared.svg" class="barcode-icon" alt="Boleto Nix Agrupado Pago" tooltipPosition="right" pTooltip="Boleto Nix Agrupado Pago">
            </td>
            <td>Boleto nix agrupado pago.</td>
        </tr>
        <tr>
          <td class="nix-bank align-left">
            <img src="../../../../assets/icons/barcode-icons/barcode-installments-nix-shared.svg" class="barcode-icon" alt="Boleto Nix Agrupado Parcelado" tooltipPosition="right" pTooltip="Boleto Nix Agrupado Parcelado">
          </td>
            <td>Boleto nix agrupado parcelado.</td>
        </tr>
        <tr>
          <td class="nix-bank align-left">
            <img src="../../../../assets/icons/barcode-icons/barcode-canceled-nix-shared.svg" class="barcode-icon" alt="Boleto Nix Agrupado Cancelado" tooltipPosition="right" pTooltip="Boleto Nix Agrupado Cancelado">
          </td>
          <td>Boleto nix agrupado cancelado.</td>
        </tr>
        <tr>
          <td class="nix-bank align-left">
            <div class="container-N">
                <img src="../../../../assets/icons/barcode-icons/barcode-registered-nix-shared.svg" class="barcode-icon" alt="N Boletos Nix Agrupado Registrados" tooltipPosition="right" pTooltip="N Boletos Nix Agrupado Registrados">
                <sup><strong>N</strong></sup>
            </div>
          </td>
          <td>Vários boletos nix agrupados.</td>
        </tr>
    </table>

    <h4><strong>NF</strong></h4>
    <table class="table table-striped" width="100%">
        <tr>
            <td width="15%"><strong>Ícone</strong></td>
            <td width="85%"><strong>Descrição</strong></td>
        </tr>
        <tr>
            <td class="green-icon">Nº NF</td>
            <td>Nº da nota fiscal gerada. Clique no mesmo para visualização.</td>
        </tr>
        <tr>
            <td class="red-icon">Nº NF</td>
            <td>Nº da nota fiscal cancelada.</td>
        </tr>
        <tr>
            <td class="red-icon">NEG</td>
            <td>Nota fiscal não autorizada Verifique o erro e entre em contato com a EasyMarine.</td>
        </tr>
        <tr>
          <td>0000<sup><strong>N</strong></sup></td>
          <td>Existem <strong>N</strong> notas canceladas.</td>
        </tr>
        <tr>
            <td>
                <i class="pi pi-clock"></i>
            </td>
            <td>Nota fiscal em processamento. Aguarde. Tempo máximo de processamento de 2 minutos.</td>
        </tr>
        <tr>
            <td>
                <i class="pi pi-ban red-icon"></i>
            </td>
            <td>Nota fiscal indisponível. Fatura com valor R$ 0,00.</td>
        </tr>
    </table>
    <br>
    <h4><strong>Email</strong></h4>
    <table class="table table-striped" width="100%">
        <tr>
            <td width="15%"><strong>Ícone</strong></td>
            <td width="85%"><strong>Descrição</strong></td>
        </tr>
        <tr>
            <td>
                <i class="pi pi-envelope"></i>
            </td>
            <td>E-mails enviado.</td>
        </tr>
        <tr>
            <td style="position: relative;">
                <i class="pi pi-envelope"></i>
                <i class="pi pi-circle-on"
                    style="color: #2196F3; font-size: 7px; position: absolute;top: 0; left: 14px"></i>
            </td>
            <td>Novo envio de e-mail.</td>
        </tr>
        <tr>
            <td style="position: relative;">
                <i class="pi pi-envelope red-icon"></i>
                <i class="pi pi-circle-on"
                    style="color: #2196F3; font-size: 7px; position: absolute;top: 0; left: 14px"></i>
            </td>
            <td>Novo envio de e-mail com falha.</td>
        </tr>
        <tr>
            <td style="position: relative;">
                <i class="pi pi-envelope red-icon"></i>
            </td>
            <td>Último envio com falha.</td>
        </tr>
    </table>
    <br>
    <h4><strong>Origem</strong></h4>
    <table class="table table-striped" width="100%">
        <tr>
            <td width="15%"><strong>Ícone</strong></td>
            <td width="85%"><strong>Descrição</strong></td>
        </tr>
        <tr>
            <td>
                <fa-icon [icon]="'ship'"></fa-icon>
            </td>
            <td>Fatura originada na lingada.</td>
        </tr>
        <tr>
            <td>
                <fa-icon [icon]="'university'"></fa-icon>
            </td>
            <td>Fatura de mensalidade.</td>
        </tr>

        <tr>
            <td>
              <fa-icon [icon]="'shopping-cart'"></fa-icon>
            </td>
            <td>Pedido avulso.</td>
        </tr>
        <tr>
          <td>
            <fa-icon [icon]="'tools'"></fa-icon>
          </td>
          <td>Pedido oficina.</td>
      </tr>
        <tr>
            <td>
                <fa-icon [icon]="'chart-pie'"></fa-icon>
            </td>
            <td>Diversos.</td>
        </tr>
        <tr>
            <td>
                <i class="pi pi-save"></i>
            </td>
            <td>Importado DANFE.</td>
        </tr>
    </table>
</div>
