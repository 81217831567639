import { Component, Inject, OnInit } from '@angular/core';
import { Sling } from 'src/app/models';
import { SlingService } from 'src/app/services/sling.service';
import { ToastService } from 'src/app/services/toast.service';
import { MaskUtil } from 'src/app/utils/mask.util';
import { MessageUtil } from 'src/app/utils/message.util';
import moment from 'moment';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-navigation-plan-form',
  templateUrl: './navigation-plan-form.component.html',
  styleUrls: ['./navigation-plan-form.component.scss']
})
export class NavigationPlanFormComponent implements OnInit {

  sling: Sling = new Sling();
  returnDate: any = new Date();
  availablePeriods: any[];
  passengersNumber: { label: string, value: any }[] = new Array();
  onlyTimeWithoutSecondsFormat = 'HH:mm';
  cellphoneMask = MaskUtil.CELLPHONE;
  dateMask: any = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];

  pt = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  };

  constructor(
    private slingService: SlingService,
    public dialogRef: DynamicDialogRef,
    private messageUtil: MessageUtil,
    private config: DynamicDialogConfig,
    private toasty: ToastService
  ) { }


  ngOnInit(): void {
    this.sling = this.config.data.sling;
    if (this.config.data.returnDate) {
      this.returnDate = this.config.data.returnDate;
      this.loadPassengersNumber(this.sling.boat);
    } else {
      this.returnDate = (
        this.sling.navigationPlan.returnTime ?
          new Date(this.sling.navigationPlan.returnTime)
          :
          new Date(this.sling.requestTime)
      );
    }
    this.getAvailablePeriods();
  }

  getAvailablePeriods(): void {
    this.slingService.getAvailablePeriodsSlingUP(this.returnDate.getTime()).subscribe(
      (data) => {
        this.availablePeriods = data.map((hour) => ({ label: moment(hour).format('HH:mm'), value: hour }));
      },
      (error) => {
        this.availablePeriods = null;
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }

  validateForm(form): boolean {
    if (!form.valid) {
      this.messageUtil.generateMessage(MessageUtil.WARNING, 'Alerta!', 'Por favor, preencha os campos obrigatórios');
      return false;
    }
    return true;
  }

  loadPassengersNumber(boat): void {
    this.passengersNumber = new Array();
    let numberPassengers = 1;
    while (numberPassengers <= (boat.crew ? boat.crew : 0) + boat.passengersDay) {
      this.passengersNumber.push({ label: numberPassengers.toString(), value: numberPassengers });
      numberPassengers++;
    }
  }

  save(): void {
    this.slingService.save(this.sling).subscribe(
      () => {
        this.toasty.success('Plano de navegação salvo com sucesso');
        this.dialogRef.close();
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }
}
