import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CustomReportInterface } from '../models/custom-exportable';

@Injectable({
  providedIn: 'root',
})
export class CustomReportService {
  constructor(private http: HttpClient) {}

  getAllByReportType(reportType): Observable<any[]> {
    return this.http
      .get(
        environment.apiHost +
          '/secure/custom-report/find-all-by-report-type/' +
          reportType
      )
      .pipe(map((response: any) => response.data.customerReportColumns as CustomReportInterface[]));
  }

  saveCustomReport(customReports): Observable<any>{
    return this.http.post(environment.apiHost + '/secure/custom-report', customReports).pipe(map((resp: any) => resp.STATUS));
  }
}
