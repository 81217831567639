import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import moment from 'moment';
import { SlingConfig, Sling, Product, OrderItem } from 'src/app/models';
import { FuelMeasurementType, FuelTankFraction, Measurement, ProductCategoryType } from 'src/app/models/enums';
import { ProductService } from 'src/app/services/product.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { OrderUtil } from 'src/app/utils/order.util';
import { SlingUtil } from 'src/app/utils/sling.util';

@Component({
  selector: 'app-sling-fuel',
  templateUrl: './sling-fuel.component.html',
  styleUrls: ['./sling-fuel.component.scss']
})
export class SlingFuelComponent implements OnInit {

  @Input() slingConfig: SlingConfig;
  @Input() slingOrder: Sling;
  @Input() isASlingEdition: boolean;
  @Output() eventSling: EventEmitter<number> = new EventEmitter();

  loading = false;

  disableFlag = false;

  consumableProducts: Product[];

  completeDateFormat = 'dd/MM/yyyy - HH:mm';

  fuels: Product[];
  addFuel: Product = null;
  valueTotalFuel = 0;
  quantityTotalFuel = 0;
  fuelCtrl: UntypedFormControl;

  filteredFuels: any[];

  fuelOrderItems: OrderItem[] = [];

  fuelMeasurementType: FuelMeasurementType = null;
  fuelTankFraction: FuelTankFraction;
  colorStockBelowMinimum = 'color-red';
  stockBelowMinimum = false;
  timerInterval;
  quantityFuel = '00';
  valueFuel = '00';

  fuelMeasurementTypes = [
    { label: 'MEASURED', value: FuelMeasurementType.Measured },
    { label: 'FUEL-FRACTION', value: FuelMeasurementType.FuelFraction },
    { label: 'VALUE', value: FuelMeasurementType.Value }
  ];

  fuelFractions = [];
  measuredMask = { prefix: '', thousands: '.', decimal: ',', allowNegative: false, precision: 2 };
  constructor(
    private productService: ProductService,
    private messageUtil: MessageUtil,
    private orderUtil: OrderUtil,
    public slingUtil: SlingUtil
  ) { }


  ngOnInit(): void {
    this.loadFuels();
    this.consumableProducts = [];
    this.fuels = [];
    this.fuelCtrl = new UntypedFormControl('', [Validators.required]);
    this.fuelFractions = this.orderUtil.createCustomFuelFractionOptions(this.slingConfig.fuelTankFractions.split(','));
    this.loadOrderItems();
  }

  filterFuels(val: string): Product[] {
    return val ? this.fuels.filter((c) => new RegExp(`^${val}`, 'gi').test(c.name))
      : this.fuels;
  }

  loadOrderItems(): void {
    if (this.slingOrder.ownFuelSupply) {
      this.fuelMeasurementType = FuelMeasurementType.OwnFuelSupply;
      return;
    }

    if (this.slingOrder.marinaOrder.orderItems.length > 0) {
      this.fuelOrderItems = this.getOrderItemFuel();
      if (this.fuelOrderItems.length > 0) {
        this.addFuel = this.fuelOrderItems[0].product;
        if (this.fuelOrderItems[0].fuelMeasurementType === FuelMeasurementType.FuelFraction) {
          this.fuelTankFraction = this.fuelOrderItems[0].fuelTankFraction;
          this.fuelMeasurementType = FuelMeasurementType.FuelFraction;
        } else {
          this.valueTotalFuel = Number((this.fuelOrderItems[0].qtyRequested * this.fuelOrderItems[0].itemValue).toFixed(3));
          this.quantityTotalFuel = Number((this.fuelOrderItems[0].qtyRequested).toFixed(3));
          this.fuelMeasurementType = this.fuelOrderItems[0].fuelMeasurementType === FuelMeasurementType.Value ?
            FuelMeasurementType.Value : FuelMeasurementType.Measured;
          this.quantityFuel += String(this.quantityTotalFuel).replace('.', '');
          this.valueFuel += String(this.valueTotalFuel).replace('.', '');
        }
      }
    }
  }

  getOrderItemFuel(): OrderItem[] {
    return this.slingOrder.marinaOrder.orderItems
      .filter((item) => item.product.productCategory.productCategoryType === ProductCategoryType.Fuel);
  }

  loadFuels(): void {
    this.productService.getByProductCategoryType(ProductCategoryType.Fuel).subscribe(
      (data) => {
        this.fuels = data.filter((p) => p.active && p.fuel.id === this.slingOrder.boat.fuel.id);
        this.filteredFuels = this.fuels.map((f) => ({ label: f.name, value: f }));
        if (this.filteredFuels.length === 1) {
          this.addFuel = this.filteredFuels[0].value;
        }

        this.loadOrderItems();
      },
      () => {
        this.fuels = null;
      },
    );
  }

  saveFuelData(): void {
    if (this.fuelMeasurementType != null) {
      if (this.fuelMeasurementType === 'OwnFuelSupply') {
        this.slingOrder.ownFuelSupply = true;
      } else {
        this.slingOrder.ownFuelSupply = null;
        if (this.addFuel != null && !this.validateItems()) {
          return;
        } else if (this.addFuel != null) {
          this.addFuelOrderItems();
        }
      }
    }

    if (this.fuelOrderItems.length > 0) {
      this.disableFlag = true;
      this.loading = true;
      this.loading = false;
      this.disableFlag = false;

      this.fuelOrderItems.forEach((fuel) => {
        if (!this.slingOrder.marinaOrder.orderItems.find((item) => item === fuel)) {
          this.slingOrder.marinaOrder.orderItems.push(fuel);
        }
      });
      if (this.slingConfig.hasConsumables) {
        this.eventSling.emit(2);
      } else {
        if (this.slingConfig.hasAccessories) {

          this.eventSling.emit(3);
        } else {

          this.eventSling.emit(4);
        }
      }
    } else {
      if (this.slingConfig.hasConsumables) {
        this.eventSling.emit(2);
      } else {
        this.eventSling.emit(3);
      }
    }
  }

  validateItems(): boolean {
    switch (this.fuelMeasurementType) {
      case FuelMeasurementType.Value:
        if (this.valueTotalFuel < 1) {
          this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', 'Informe o total solicitado');
          return false;
        }
        return true;
      case FuelMeasurementType.Measured:
        if (this.quantityTotalFuel < 0.001) {
          this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', 'Informe a quantidade solicitada');
          return false;
        }
        return true;
      case FuelMeasurementType.FuelFraction:
        if (!this.fuelTankFraction) {
          this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', 'Informe a fração solicitada');
          return false;
        }
        return true;
      default:
        return false;
    }
  }

  addFuelOrderItems(): void {
    let orderItem: OrderItem;
    if (this.fuelOrderItems.length === 0) {
      orderItem = new OrderItem();
    } else {
      orderItem = this.fuelOrderItems[0];
    }
    orderItem.product = this.addFuel;
    orderItem.itemStatus = false;
    orderItem.itemValue = this.addFuel.price.value;
    orderItem.qtyStock = orderItem.product.stock - orderItem.product.reserved;
    orderItem.qtyRequested = this.valueTotalFuel / this.addFuel.price.value;
    if (this.fuelMeasurementType === FuelMeasurementType.FuelFraction) {
      orderItem.fuelTankFraction = this.fuelTankFraction;
    } else if (this.fuelMeasurementType === FuelMeasurementType.Value) {
      this.quantityTotalFuel = this.valueTotalFuel / this.addFuel.price.value;
      orderItem.fuelTankFraction = null;
    } else {
      orderItem.fuelTankFraction = null;
    }
    orderItem.qtyRequested = this.quantityTotalFuel;
    orderItem.fuelMeasurementType = this.fuelMeasurementType;
    this.fuelOrderItems.push(orderItem);
  }

  onFuelMeasurementType(prepare): void {
    if (!prepare) {
      this.valueTotalFuel = 0;
      this.quantityTotalFuel = 0;
      this.fuelTankFraction = null;
      this.quantityFuel = '00';
      this.valueFuel = '00';
    }
  }

  displayFn(object: any): string {
    return object ? object.name : '';
  }

  isAttempted(): boolean {
    return (
      this.slingOrder.marinaOrder.orderStatus !== 'Ordered' ||
      this.slingOrder.slingStatus !== 'PENDING_DOWN'
    );
  }

  getLabelFraction(fuelTankFraction: FuelTankFraction): string {
    if (fuelTankFraction === FuelTankFraction.Full) {
      return 'Tanque cheio';
    }
    return this.orderUtil.getFuelFractionOptions().find((f) => f.value === fuelTankFraction).label;
  }

  getBalanceInStock(): number {
    let stock = 0;
    if (this.addFuel != null) {
      if (this.fuelMeasurementType === FuelMeasurementType.Value) {
        stock = this.addFuel.stock - this.addFuel.reserved - (this.valueTotalFuel / this.addFuel.price.value);
      } else if (this.fuelMeasurementType === FuelMeasurementType.Measured) {
        stock = this.addFuel.stock - this.addFuel.reserved - this.quantityTotalFuel;
      } else {
        stock = this.addFuel.stock - this.addFuel.reserved;
      }
      this.verifyAlertIntervalSotck(stock);

      if(stock < 0) {
        this.disableFlag = true
      } else {
        this.disableFlag = false
      }

      return stock;
    } else {
      return stock;
    }
  }

  verifyAlertIntervalSotck(stock): void {
    if (this.addFuel.minimalStock > stock) {
      if (!this.stockBelowMinimum) {
        this.timerInterval = setInterval(() => {
          this.colorStockBelowMinimum = this.colorStockBelowMinimum === 'color-red' ? 'color-white' : 'color-red';
        }, 500);
      }
      this.stockBelowMinimum = true;
    } else {
      clearInterval(this.timerInterval);
      this.stockBelowMinimum = false;
    }
  }

  insertNumberValue(value: string): void {
    switch (this.fuelMeasurementType) {
      case FuelMeasurementType.Measured:
        this.quantityFuel = this.quantityFuel.concat(value);
        this.quantityTotalFuel = Number([this.quantityFuel.slice(0, -2), '.', this.quantityFuel.slice(-2)].join(''));
        break;
      case FuelMeasurementType.Value:
        this.valueFuel = this.valueFuel.concat(value);
        this.valueTotalFuel = Number([this.valueFuel.slice(0, -2), '.', this.valueFuel.slice(-2)].join(''));
        break;
      default:
        break;
    }
  }

  deleteNumberValue(): void {
    switch (this.fuelMeasurementType) {
      case FuelMeasurementType.Measured:
        if (this.quantityFuel !== '00') {
          this.quantityFuel = this.quantityFuel.substring(0, this.quantityFuel.length - 1);
          this.quantityTotalFuel = Number([this.quantityFuel.slice(0, -2), '.', this.quantityFuel.slice(-2)].join(''));
        }
        break;
      case FuelMeasurementType.Value:
        if (this.valueFuel !== '00') {
          this.valueFuel = this.valueFuel.substring(0, this.valueFuel.length - 1);
          this.valueTotalFuel = Number([this.valueFuel.slice(0, -2), '.', this.valueFuel.slice(-2)].join(''));
        }
        break;
      default:
        break;
    }
  }

  getRequestDate(): Date {
    if (this.slingOrder?.marinaOrder?.orderDate) {
      return moment(this.slingOrder.marinaOrder.orderDate).utc(true).toDate();
    }
    return null;
  }

  changeFuel(): void {
    if (this.addFuel.measurement === Measurement.Units) {
      this.measuredMask.precision = 0;
      if (this.fuelMeasurementType === FuelMeasurementType.FuelFraction || this.fuelMeasurementType === FuelMeasurementType.Value) {
        this.fuelMeasurementType = null;
      }
    }
  }

  tanqueCheio(): boolean {
    return this.fuelFractions.find((f) => f.value === FuelTankFraction.Full) && this.fuelFractions.length === 1;
  }

  back(): void {
    this.eventSling.emit(0);
  }

  getDecimals() {

    if (this.addFuel && this.addFuel.measurement && this.addFuel.measurement === Measurement.Units) {
      return '0';
    }
    return '3';
  }

  calculateTotal(quantityTotalFuel, value): number {
    return quantityTotalFuel * value;
  }

}
