import { Injectable } from '@angular/core';
import { OrderItem } from '../models/order-item';
import { FuelTankFraction } from '../models/enums/fuel-tank-fraction';

@Injectable()
export class OrderUtil {
    public fuelNumericFraction(orderItem: OrderItem): string {

        let fuelNumericFraction = '';
        switch (orderItem.fuelTankFraction) {
            case FuelTankFraction.Full: {
                fuelNumericFraction = 'Tanque cheio';
                break;
            }
            case FuelTankFraction.Half: {
                fuelNumericFraction = '1/2';
                break;
            }
            case FuelTankFraction.Third: {
                fuelNumericFraction = '3/4';
                break;
            }
            case FuelTankFraction.Quarter: {
                fuelNumericFraction = '1/4';
                break;
            }
        }
        return fuelNumericFraction;
    }

    public fuelQuantityView(orderItem: OrderItem): string {
        if (orderItem.qtySupplied !== null && orderItem.qtySupplied !== undefined && orderItem.qtySupplied > 0) {
            return orderItem.qtySupplied.toFixed(3).replace('.',',');
        }
        if (orderItem.fuelTankFraction) {
            return this.fuelNumericFraction(orderItem);
        } else {
          if (orderItem.qtyRequested !== null && orderItem.qtyRequested !== undefined && orderItem.qtyRequested > 0){
            return orderItem.qtyRequested.toFixed(3).replace('.',',');}
          else{
            return '';
          }
        }
    }

    public getFuelFractionOptions(): { label: string, value: FuelTankFraction }[] {
        return [
            { label: '1/4', value: FuelTankFraction.Quarter },
            { label: '1/2', value: FuelTankFraction.Half },
            { label: '3/4', value: FuelTankFraction.Third },
            { label: 'Tanque cheio', value: FuelTankFraction.Full },
        ];
    }

    public createCustomFuelFractionOptions(fuelFractionsParam: any[]): { label: string, value: FuelTankFraction }[] {
        const fuelFractions = [];
        if (fuelFractionsParam.find((ff) => ff === 'Quarter')) {
            fuelFractions.push({ label: '1/4', value: FuelTankFraction.Quarter });
        }
        if (fuelFractionsParam.find((ff) => ff === 'Half')) {
            fuelFractions.push({ label: '1/2', value: FuelTankFraction.Half });
        }
        if (fuelFractionsParam.find((ff) => ff === 'Third')) {
            fuelFractions.push({ label: '3/4', value: FuelTankFraction.Third });
        }
        if (fuelFractionsParam.find((ff) => ff === 'Full')) {
            fuelFractions.push({ label: 'Tanque cheio', value: FuelTankFraction.Full });
        }
        return fuelFractions;
    }

    public abbreviatedProductName(orderItem: OrderItem): string {
        const words = orderItem.product.name.split(' ');
        let acronym = '';
        const match1 = words[0].substr(0, 1);
        if (words.length > 1) {
            const match2 = words[1].substr(0, 1);
            acronym = match1 + match2;
        } else {
            acronym = match1;
        }
        return acronym.toUpperCase();
    }
}
