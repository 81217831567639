<p-fieldset>
  <p-header class="article-title">
      <span class="article-title">{{ 'TITLE-SIREN-ALERT' | translate }}</span>
  </p-header>
  <form *ngIf="form" [formGroup]="form" (submit)="save()">
      <div class="form-group">
        <div class="form-control" [formArrayName]="'sirens'"
          *ngFor="let siren of form.get('sirens')['controls']; let i = index">
          <app-siren-alert [filteredSirenAlerts]="filteredSirenAlerts" [formControl]="siren"></app-siren-alert>
          <p *ngIf="siren.errors?.SirenEnabledEmpty" style="color: red;"> Defina um audio para o alerta</p>
        </div>
      </div>
      <div class="buttons">
          <button class="easymarine primary" type="submit" [disabled]="form.invalid">{{ 'SAVE' | translate }}</button>
      </div>
  </form>
</p-fieldset>
