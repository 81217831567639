<div class="dialog-form">
    <div class="form-group">
        <div>
            <i class="pi pi-file-o"></i> &nbsp;<span>Fatura</span>&nbsp;&nbsp;
            <fa-icon [icon]="'barcode'"></fa-icon>&nbsp;<span>Boleto</span>&nbsp;&nbsp;
            <i class="pi pi-file-pdf"></i> &nbsp;<span>NF</span>&nbsp;&nbsp;
            <app-receipts-icon *ngIf="hasInvoiceReceipt">&nbsp;<span>Recibo</span>&nbsp;&nbsp;</app-receipts-icon>
        </div>
    </div>

    <p-table *ngIf="emailLogs" [value]="emailLogs" class="primeTable" dataKey="id" width="100%"
        autoLayout="true">
        <ng-template pTemplate="header">
            <tr>
                <th class="text-center">Data/Hora</th>
                <th class="text-center">Email</th>
                <th class="text-center">Anexo</th>
                <th class="text-center">Erro</th>
                <th class="text-center">Lido</th>
                <th class="text-center">Único</th>
                <th class="text-center" *ngIf="loggedMarina?.hasServerEmailOwn">Servidor</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-email>
            <tr>
                <td class="text-center">
                    {{ email.registerDate | date: 'dd/MM/yyyy - HH:mm' }}
                </td>
                <td class="text-center">
                    {{ email?.email ? email.email : invoice.customer.emailCollectionArray[0]}}
                </td>
                <td class="text-center attachment-icons">
                    <i class="pi pi-file-o" *ngIf="hasDemonstrative(email)"></i>
                    <fa-icon [icon]="'barcode'" *ngIf="hasBillet(email)"></fa-icon>
                    <i class="pi pi-file-pdf" *ngIf="hasNF(email)"></i>
                    <app-receipts-icon *ngIf="hasReceipt(email)"></app-receipts-icon>
                </td>
                <td class="text-center">
                    <i class="pi pi-times" style="color: red;" *ngIf="email.emailError"></i>
                    <span *ngIf="email.serverOwn">indisponível</span>
                </td>
                <td class="text-center">
                    <i class="pi pi-check" *ngIf="email.emailRead"></i>
                    <span *ngIf="email.serverOwn" >indisponível</span>
                </td>
                <td class="text-center">
                    <i class="pi pi-check" *ngIf="email.single"></i>
                </td>
                <td class="text-center" *ngIf="loggedMarina?.hasServerEmailOwn">
                    <span *ngIf="!email.serverOwn" >Easy</span>
                    <span *ngIf="email.serverOwn" >Próprio</span>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
