import { Component, Input, OnInit } from '@angular/core';
import { SlingConfig } from 'src/app/models';
import { FinancialMultipleCompaniesType } from 'src/app/models/enums/finances/financial-multiple-companies-type';
import { MessageUtil } from 'src/app/utils/message.util';

@Component({
  selector: 'app-financial-config',
  templateUrl: './financial-config.component.html',
  styleUrls: ['./financial-config.component.scss']
})
export class FinancialConfigComponent implements OnInit {

  @Input()
  slingConfig: SlingConfig;

  @Input()
  userHierarchy: number = undefined;

  lastFinancialMultipleCompaniesType: FinancialMultipleCompaniesType

  financialMultipleCompaniesOptions: { label: string, value: FinancialMultipleCompaniesType, tooltip?: string}[] = [
    {
      label: this.messageUtil.translateKey('FINANCIAL-CONFIG.BY-CUSTOMER'),
      value: FinancialMultipleCompaniesType.ByCustomer
    },
    {
      label: this.messageUtil.translateKey('FINANCIAL-CONFIG.BY-PRODUCT'),
      value: FinancialMultipleCompaniesType.ByProduct,
    }
  ]

  constructor (
    private messageUtil: MessageUtil
  ) { }

  ngOnInit(): void {
    if(this.slingConfig.financialMultipleCompaniesType === undefined || this.slingConfig.financialMultipleCompaniesType === null) {
      this.slingConfig.financialMultipleCompaniesType = FinancialMultipleCompaniesType.ByCustomer;
    }
    this.lastFinancialMultipleCompaniesType = this.slingConfig.financialMultipleCompaniesType;
  }

  change(event: any) {
    if (!event.option) {
      return;
    }
    const type: FinancialMultipleCompaniesType = event.option.value;
    if (type === this.lastFinancialMultipleCompaniesType) {
      return;
    }

    if (type === FinancialMultipleCompaniesType.ByProduct) {
      const errors: string[] = [];
      if (this.slingConfig.hasConsumables) {
        errors.push(this.messageUtil.translateKey('SLINGS') + ' >> ' + this.messageUtil.translateKey('HAS-CONSUMABLES'));
      }
      if (this.slingConfig.consumablesApp) {
        errors.push('App >> Solicitar consumíveis');
      }
      if (errors.length > 0) {
        const msgError = 'Para selecionar a opção "' + this.messageUtil.translateKey('FINANCIAL-CONFIG.BY-PRODUCT') + '" desabilite: '
          + errors.join(', ')
        this.messageUtil.generateMessage(MessageUtil.WARNING, this.messageUtil.translateKey('SUMMARY.WARNING'), msgError);
        this.slingConfig.financialMultipleCompaniesType = FinancialMultipleCompaniesType.ByCustomer;
        return;
      }
    }
    this.lastFinancialMultipleCompaniesType = type;
  }
}
