import { Injectable } from '@angular/core';
import moment from 'moment';
import { DialogService } from 'primeng/dynamicdialog';
import { MultipleReferenceDateComponent } from '../components/invoice/multiple-reference-date/multiple-reference-date.component';
import { SlingConfig } from '../models';
import { DuedateSelectionComponent } from '../pages/billing/order-invoice/duedate-selection/duedate-selection.component';

@Injectable()
export class OrderReferenceDateUtil {

  monthsReference: any[] = [{ label: 'Janeiro', value: 0 }, { label: 'Fevereiro', value: 1 }, { label: 'Março', value: 2 },
  { label: 'Abril', value: 3 }, { label: 'Maio', value: 4 }, { label: 'Junho', value: 5 },
  { label: 'Julho', value: 6 }, { label: 'Agosto', value: 7 }, { label: 'Setembro', value: 8 },
  { label: 'Outubro', value: 9 }, { label: 'Novembro', value: 10 }, { label: 'Dezembro', value: 11 }];

  pt = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  };

  minDueDate = new Date();

  constructor(private dialog: DialogService) {
    this.minDueDate.setDate(new Date().getDate() + 1);
  }

  async verifyRefereceDate(invoices: any[]): Promise<Map<string, any[]>> {
    return new Promise<any>(async (resolve, reject) => {
      const dateReferences = this.listSelectedsDateReference(invoices);
      if (dateReferences.size > 1) {
        this.dialog
          .open(MultipleReferenceDateComponent, {
            width: '450px',
            data: {
              dateReferences,
            },
            header: 'Multiplas datas de referência',
          })
          .onClose.subscribe((value) => {
            if (value) {
              resolve(value);
            } else {
              resolve(null);
            }
          });
      } else {
        resolve(dateReferences);
      }
    });
  }

  listSelectedsDateReference(invoices: any[]): Map<string, any[]> {
    let dateReferences = new Map<string, any[]>();
    let orders = [];
    if (invoices[0].orders) {
      invoices.forEach((ic) => orders = [ ... orders, ... ic.orders]);
    } else {
      orders = invoices;
    }

    orders
      .filter((order) => order.selected)
      .forEach((order) => {
        const date = this.checkDateReference(order);
        if (dateReferences.has(date)) {
          dateReferences.get(date).push(order);
        } else {
          dateReferences.set(date, [order]);
        }
      });

    return dateReferences;
  }

  checkDateReference(order: any) {
    let date;
    if (order.dateReferences) {
      date = moment(order.dateReferences, 'YYYY-MM-DD')
        .utc(true)
        .format('MM/YYYY');
    } else if (order.dateTuition) {
      date = moment(order.dateTuition, 'YYYY-MM-DD')
        .utc(true)
        .format('MM/YYYY');
    } else {
      date = moment(order.date, 'YYYY-MM-DDTHH:mm:ss')
        .utc(true)
        .format('MM/YYYY');
    }
    return date;
  }

  async selectDateForInvoices(slingConfig: SlingConfig): Promise<any> {
    return new Promise<any>(async (resolve, reject) => {
        
    this.dialog.open(DuedateSelectionComponent, {
      width: '450px',
      height: '50vh',
      data: {
        monthsReference: this.monthsReference,
        minDueDate: this.minDueDate,
        pt: this.pt,
        slingConfig
      },
      header: 'Vencimento'
    }).onClose.subscribe((value) => {
      if (value) {
        resolve(value);
      } else {
        resolve(null);
      }
    });
    });
  }

}
