import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { AccessGroupDTO as AccessGroupDTO } from '../../models/dtos/access-controller/access-group-DTO';
import { AccessGroup as AccessGroup } from '../../models/dtos/access-controller/access-group';
import {map} from "rxjs/operators";
import {BatchAccessGroupTransferDTO} from "../../models/dtos/access-controller/batchAccessGroupTransferDTO";

@Injectable({
  providedIn: 'root'
})
export class AccessGroupService {
  env:string = environment.apiHost + '/api-core/secure/access-group';
  constructor(private http: HttpClient) { }

  findAll() : Observable<AccessGroup[]> {
    return this.http.get<AccessGroup[]>(this.env + '/list');
  }

  findAllWithQuantities() : Observable<AccessGroupDTO[]> {
    return this.http.get<AccessGroupDTO[]>(this.env + '/list-quantities');
  }

  findGroups(filter: any) : Observable<AccessGroup[]> {
    return this.http.post<AccessGroup[]>(this.env + '/list', filter);
  }

  delete(accessGroupId: number) {
    return this.http.delete(this.env + '/delete/'+ accessGroupId);
  }

  save(accessCategory: AccessGroup) {
    return this.http.post(this.env + '/save', accessCategory);
  }

  findCustomersByAccessCategoryID(accessGroupID: number): Observable<any[]>{
    return this.http.get(`${this.env}/list-by-access/customer/${accessGroupID}`).pipe(map((resp) => resp as any[]));
  }
  findSailorsByAccessCategoryID(accessGroupID: number): Observable<any[]>{
    return this.http.get(`${this.env}/list-by-access/sailor/${accessGroupID}`).pipe(map((resp) => resp as any[]));
  }
  batchTransferAccessCategoryOfPersons(batchBody: BatchAccessGroupTransferDTO){
    return this.http.post(`${this.env}/batch-transfer`, batchBody).pipe(map((resp) => resp));
  }

  extract(idPermission:number): Observable<any> {
    return this.http.get(`${this.env}/reports/access-permission/${idPermission}`).pipe(map((resp) => resp));
  }
}
