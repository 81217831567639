import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-movement-means-icon-url',
  templateUrl: './movement-means-icon-url.component.html',
  styleUrls: ['./movement-means-icon-url.component.scss']
})
export class MovementMeansIconUrlComponent {

  @Input()
  params: { iconUrls: string[] }

  constructor() { }

}
