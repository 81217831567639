import { Component, EventEmitter, Output } from '@angular/core';
import { SalesStatesService } from '../split-bill/sales-states.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-discount-select',
  templateUrl: './discount-select.component.html',
  styleUrls: ['./discount-select.component.scss']
})
export class DiscountSelectComponent {
  @Output()
  onDiscountChange:EventEmitter<any> = new EventEmitter<any>();
  
  discount:number = 0;
  discountPercent:number = 0;
  discountByMoney: string = 'money';
  discountedValue: number = 0;

  total:number = 0;

  hasInvoice: boolean = false;
  subscription: Subscription;

  constructor(
    private salesStatesService: SalesStatesService,
    ) { }

  ngOnInit(): void {
    this.subscription = this.salesStatesService.orderData$.subscribe((_) => {
      this.hasInvoice = this.salesStatesService.getOrderData().hasInvoice;
      this.discountedValue = this.salesStatesService.getOrderData().discountedValue;
      this.total = this.salesStatesService.getOrderData().total;
    });
    if (this.hasInvoice) {
      this.discount = this.discountedValue ? this.discountedValue : 0;
    }
    this.total = this.total ? this.total : 0;
  }

  fillDiscounts(type){
    if (type === 'percent') {
      this.discount = this.discountPercent * this.total / 100;
      this.discount = parseFloat(this.discount.toFixed(2));
    } 
    
    if (type === 'money') {
      this.discountPercent = this.discount * 100 / this.total;
    } 
     
    if (this.total > this.discount
      && this.discount >= 0) {
      this.emitDiscount();
    } else {
      this.discount = 0;
      this.discountPercent = 0;
      this.emitDiscount();
    }
  }

  emitDiscount() {
    this.onDiscountChange.emit({
      discount: this.discount,
    });
  }

}
