import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EngineTurn } from '../models/engine-turn';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class EngineTurnService {

    constructor(private http: HttpClient) { }

    createFastRegistrarion(engineTurn: EngineTurn): Observable<any> {
        return this.http.post(
            environment.apiHost + '/secure/engineTurn/createFastRegistrarion/', engineTurn).pipe(map((response: any) => response));
    }

    createFastAutoRegistrarion(engineTurn: EngineTurn): Observable<any> {
        return this.http.post(
            environment.apiHost + '/secure/engineTurn/createFastAutoRegistrarion/', engineTurn).pipe(map((response: any) => response));
    }

    createFastAutoRegistrarionList(enginesTurn: EngineTurn[]): Observable<any> {
        return this.http.post(
            environment.apiHost + '/secure/engineTurn/createFastAutoRegistrarionList/', enginesTurn).pipe(map((response: any) => response));
    }

    saveSchedule(boatsEngineTurn: EngineTurn[]): Observable<any> {
        return this.http.post(environment.apiHost + '/secure/boatsEngineTurnSchedule/', boatsEngineTurn)
            .pipe(map((response: any) => response));
    }

    delete(idsEngineTurn: number[]): Observable<any> {
        return this.http.delete(environment.apiHost + '/secure/boatsEngineTurn/' + idsEngineTurn).pipe(map((response: any) => response));
    }

    findAll(): Observable<any[]> {
        return this.http.get(environment.apiHost + '/secure/findAll/').pipe(map((response: any) => response.data.engines as any[]));
    }

    getBoatEngineTurnHistory(boatId: number): Observable<any[]> {
        return this.http.get(environment.apiHost + '/secure/getBoatEngineTurnHistory/' + boatId)
            .pipe(map((response: any) => response.data.engineTurnHistory as any[]));
    }

    getScheduledBoatEnginesTurn(): Observable<any[]> {
        return this.http.get(environment.apiHost + '/secure/getScheduledBoatEnginesTurn/')
            .pipe(map((response: any) => response.data.enginesTurn as any[]));
    }

}
