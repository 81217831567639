<div class="dialog-form align-form-center">
    <form (submit)="save()" class="sizeForm">
      <section class="container">
        <div *ngIf="movement"  class="row-item">

          <div class="item">
            <div class="quarter">
              <label>{{'BOAT' | translate}}:</label>
            </div>
              <span class="form-group item-title">{{movement?.boat?.name}}</span>
          </div>

          <div class="item">
            <div class="quarter">
              <label>{{'ORIGIN' | translate}}:</label>
            </div>
            <div class="form-group item-title">
              <app-movement-location
                [params]="{
                  type: movement?.currentLocation?.type,
                  name: movement?.currentLocation?.name,
                  abbreviation: movement?.currentLocation?.Abbreviation,
                  vacancyCode: movement?.currentLocation?.vacancyCode
                }"
              >
              </app-movement-location>
            </div>
          </div>

        </div>

        <div class="divider"></div>

        <div *ngIf="movement" class="row-item" >

          <div class="item">
            <label for="movementLocation">{{'DESTINATION' | translate}}: </label>
            <div class="form-group item-title"
              *ngIf="destinies && !destinies.chooseDestiny && location">
              <app-movement-location
                [params]="{
                  name: location.name,
                  type: location.type,
                  abbreviation: location.abbreviation,
                  vacancyCode: vacancy ? vacancy.code : null
                }"
              >
              </app-movement-location>
            </div>

            <div class="form-group item-title"
              *ngIf="destinies && destinies.chooseDestiny && destinies.locations && destinies.locations.length === 1">
              <app-movement-location
                [params]="{
                  name: location.name,
                  type: location.type,
                  abbreviation: location.abbreviation,
                  vacancyCode: vacancy ? vacancy.code : null
                }"
              >
              </app-movement-location>
            </div>
            <div class="form-group item-title"
              *ngIf="destinies && destinies.chooseDestiny && destinies.locations && destinies.locations.length > 1">
              <p-dropdown class="form-group" [options]="locationOptions"
              (onChange)="changeLocation($event)"
              name="location" appendTo="body">
            </p-dropdown>
            </div>
          </div>

          <div class="item" *ngIf="destinies && destinies.chooseDestiny && vacancyOptions ">
            <label for="vacancy">Vaga:</label>
            <p-dropdown class="form-group" [options]="vacancyOptions"
              (onChange)="changeVacancy($event)"
              name="vacancy" appendTo="body">
            </p-dropdown>
          </div>

        </div>

        <div class="btn-align-bottom">
          <div class="buttons">
            <button class="easymarine danger" type="button"
              (click)="dialogRef.close()">{{ 'CANCEL' | translate }}</button>
            <button  class="easymarine primary" type="submit">{{ 'SAVE' | translate }}</button>
          </div>
        </div>
      </section>
    </form>
</div>
