<div class="dialog-form">
  <form (submit)="save()" class="sizeForm" [formGroup]="vacancyForm">
    <div class="form-group">
     <div *ngIf="isMovements && localeOptions; then showToMovements"></div>
      <ng-template #showToMovements>
        <div class="form-control" *ngIf="isMovements && localeOptions">
          <label for="movementLocation">Local</label>
          <p-dropdown
            placeholder="Selecione..."
            [options]="localeOptions"
            formControlName="movementLocation"
            appendTo="body"
            (onChange)="changeLocation()"
          >
          </p-dropdown>
        </div>

        <div *ngIf="local?.prefixUse" style="width: 4em;">
          <label for="prefix">Prefixo </label>
          <input
            pInputText
            value="{{ local?.abbreviation }}"
            name="prefix"
            maxlength="3"
            readonly
            style="text-align: right; width: 4em;"
          />
        </div>
      </ng-template>
        <div class="form-control">
          <label for="vacancyCode">{{ "CODE" | translate }} *</label>

          <input
            pInputText
            name="vacancyCode"
            formControlName="code"
            maxlength= {{maxLengh}}
          />
        </div>

        <div class="form-control">
          <label for="shared">{{ "Vaga compartilhada" | translate }}</label>
          <p-inputSwitch
            formControlName="shared"
            name="shared"
            pTooltip="Permite que mais de um barco ocupe a vaga ao mesmo tempo"
            tooltipPosition="bottom"
          >
          </p-inputSwitch>
        </div>
    </div>

    <div class="form-group" style="margin-top: 1em;">
      <div class="form-control">
        <label for="length">{{ "MAX-LENGTH" | translate }} *</label>
        <input
          pInputText
          name="length"
          formControlName="length"
          maxlength="4"
          type="number"
        />
      </div>
      <div class="form-control">
        <label for="arrowDirection">{{ "Sentido da seta" | translate }}</label>
        <p-dropdown
          [options]="arrowOptions"
          formControlName="arrowDirection"
          name="arrowDirection"
          appendTo="body"
        >
        </p-dropdown>
      </div>
      <div class="form-control">
        <label for="active">{{ "Ativo" | translate }}</label>
        <p-inputSwitch
          formControlName="active"
          name="active"
          pTooltip="Vaga Ativa"
          tooltipPosition="bottom"
        >
        </p-inputSwitch>
      </div>
    </div>

    <div class="table mt-3">
      <p-table [value]="boatTable" [rows]="5" [paginator]="true"
          autoLayout="true" alwaysShowPaginator="false">
          <ng-template pTemplate="header">
              <tr>
                  <th>{{ 'NAME' | translate}}</th>
                  <th>
                    <p-dropdown
                      [options]="this.boats"
                      [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="selectedBoat"
                      optionLabel="name"
                      optionValue="id"
                      appendTo="body"
                      placeholder="Embarcação"
                    >
                    </p-dropdown>
                  </th>
                  <th class="flex justify-content-center"> 
                      <button class="fab-button primary" type="button" 
                          pTooltip="Adicionar Embarcação a Vaga"
                          tooltipPosition="bottom" 
                          (click)="addBoat()">
                          <i class="pi pi-plus"></i></button>
                  </th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-boat let-rowIndex="rowIndex">
              <tr>
                  <td>
                      {{boat.name}}
                  </td>
                  <td></td>
                  <td class="flex-row justify-content-center">
                      <button class="fab-button
                          danger" type="button" 
                          pTooltip="Remover Embarcação da Vaga"
                          tooltipPosition="bottom" 
                          (click)="removeBoat(boat)">
                          <i class="pi pi-trash"></i></button>
                  </td>
                   
                  <!-- <td class="text-center" *ngIf="!isMovimentOperation">
                      <p-inputSwitch formControlName="viewHistory"
                          name="sharedInvoiceCustomerApp"
                          pTooltip="Permitir visualização do histórico da embarcação?"
                          tooltipPosition="bottom">
                      </p-inputSwitch>
                  </td> -->
                  
              </tr>
          </ng-template>
      </p-table>
  </div>

    <div class="form-group align-center align-btn-actions">
      <div class="form-control">
        {{ "REQUIRED-FIELDS" | translate }}
      </div>
      <div class="buttons">
        <button
          class="easymarine danger"
          type="button"
          (click)="dialogRef.close()"
        >
          {{ "CANCEL" | translate }}
        </button>
        <button class="easymarine primary" type="submit" *ngIf="local?.active || !isMovements">
          {{ "SAVE" | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
