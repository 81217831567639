<p-fieldset>
  <p-header class="article-title">
    <span class="article-title">Faturamento > Gestão de Notas de Produtos</span>
    <div class="header-buttons">
      <button
        class="fab-button primary"
        appFiltersButton
        pTooltip="Exibir/Ocultar Filtros"
        tooltipPosition="left"
      >
        <i class="pi pi-filter"></i>
      </button>
      <button
        class="primary fab-button"
        (click)="importarXml()"
        pTooltip="Importar Nota Fiscal"
        tooltipPosition="bottom"
      >
        <i class="pi pi-plus" style="font-size: 21px"></i>
      </button>
    </div>
  </p-header>

  <form (submit)="findAll()" class="filters" appFilters>
    <!-- Filtros -->
    <div class="filter-row" *ngIf="contentLoaded">
      <div class="form-control" *ngIf="marinaCompanies?.length > 0">
        <label for="selectedCompany">{{ "COMPANY" | translate }}</label>
        <p-dropdown
          [filter]="true"
          [options]="filteredCompanies"
          name="selectedCompany"
          appendTo="body"
          [(ngModel)]="selectedCompany"
          (onChange)="changeCompany()"
        >
        </p-dropdown>
      </div>

      <div class="form-control">
        <label>{{ "TYPE" | translate }}</label>
        <p-multiSelect
          [options]="typeList"
          [style]="{ height: '30px' }"
          [(ngModel)]="filter.typeList"
          appendTo="body"
          name="typeList"
          selectedItemsLabel="{0} itens selecionados"
        >
        </p-multiSelect>
      </div>

      <div class="form-control">
        <label>{{ "Status Import." | translate }}</label>
        <p-multiSelect
          [options]="statusImpList"
          [style]="{ height: '30px' }"
          [(ngModel)]="filter.statusImpList"
          appendTo="body"
          name="statusImpList"
          selectedItemsLabel="{0} itens selecionados"
        >
        </p-multiSelect>
      </div>

      <div class="form-control">
        <label>{{ "SUPPLIER" | translate }}</label>
        <p-dropdown
          [filter]="true"
          [options]="filteredSuppliers"
          [(ngModel)]="selectedSupplier"
          appendTo="body"
          name="selectedSupplier"
          (onChange)="changeSupplier()"
        >
        </p-dropdown>
      </div>

      <div class="form-control">
        <label>{{ "CUSTOMER" | translate }}</label>
        <p-dropdown
          [filter]="true"
          [options]="filteredCustomers"
          [(ngModel)]="selectedCustomer"
          appendTo="body"
          name="selectedCustomer"
          (onChange)="changeCustomer()"
        >
        </p-dropdown>
      </div>

      <div class="form-control">
        <label for="nfeNumeroStart"
          >{{ "INVOICE-TAX-NUMBER" | translate }}
        </label>
        <input
          pInputText
          type="text"
          [(ngModel)]="invoiceTaxNumberFilter"
          name="nfeNumeroStart"
          placeholder="ex: 1-5"
        />
      </div>
      <!-- <div class="form-control">
            <label for="nfeNumeroEnd">{{'MAX-INVOICE-TAX-NUMBER' | translate}}</label>
            <input pInputText type="number" [(ngModel)]="filter.nfeNumeroEnd" name="nfeNumeroEnd">
        </div> -->

      <div class="form-control">
        <label for="minInvoiceNumber">{{ "INVOICE-NUMBER" | translate }}</label>
        <input
          pInputText
          type="text"
          [(ngModel)]="invoiceNumberFilter"
          name="minInvoiceNumber"
        />
      </div>

      <!-- <div class=" form-control">
            <label for="maxInvoiceNumber">{{'MAX-INVOICE-NUMBER' | translate}}</label>
            <input pInputText type="number" [(ngModel)]="filter.invoiceNumberEnd" name="maxInvoiceNumber">
        </div> -->

      <div class="form-control">
        <label>{{ "STATUS" | translate }}</label>
        <p-multiSelect
          [options]="statusList"
          [style]="{ height: '30px' }"
          [(ngModel)]="filter.statusList"
          appendTo="body"
          name="statusList"
          selectedItemsLabel="{0} itens selecionados"
        >
        </p-multiSelect>
      </div>

      <div class="form-control">
        <label for="rangeCadastro">{{ "Data de cadastro" | translate }}</label>
        <p-calendar
          dateFormat="dd/mm/yy"
          name="rangeCadastro"
          [monthNavigator]="true"
          [yearNavigator]="true"
          appendTo="body"
          [yearRange]="'2000:2055'"
          rPCalendarMask
          selectionMode="range"
          [(ngModel)]="this.rangeCadastros"
          [showIcon]="true"
          (onBlur)="this.onChangeCadastro()"
          i18n
        >
        </p-calendar>
      </div>

      <div class="form-control">
        <label for="rangeIssueDate">{{
          "EMISSION-DATE-FROM-TO" | translate
        }}</label>
        <p-calendar
          dateFormat="dd/mm/yy"
          name="rangeCreateDate"
          [monthNavigator]="true"
          [yearNavigator]="true"
          appendTo="body"
          [yearRange]="'2000:2055'"
          rPCalendarMask
          selectionMode="range"
          [(ngModel)]="this.rangeCreationDates"
          [showIcon]="true"
          (onBlur)="this.onChangeCreationDate()"
          i18n
        >
        </p-calendar>
      </div>

      <div class="buttons">
        <div class="form-button">
          <button (click)="clear()" class="easymarine danger" type="button">
            {{ "CLEAR" | translate }}
          </button>
        </div>
        <div class="form-button">
          <button class="easymarine primary" type="submit">
            {{ "FIND" | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
  <!-- <div class="subtitles">
    <fieldset>
      <legend>Legenda (Status Import.)</legend>
      <div class="badges">
        <i class="pi pi-circle-on partially-attempted margin-left"></i>&nbsp;
        Parcial&nbsp;&nbsp;
        <i class="pi pi-circle-on attempted margin-left"></i>&nbsp;
        Concluido&nbsp;&nbsp;
        <i class="pi pi-circle-on canceled margin-left"></i>&nbsp;
        Cancelado&nbsp;&nbsp;
      </div>
    </fieldset>
  </div> -->
  <div class="table">
    <p-table
      dataKey="id"
      [value]="invoiceTaxList"
      autoLayout="true"
      [alwaysShowPaginator]="true"
      [rows]="paginationFilter.size"
      [totalRecords]="totalRecords"
      [paginator]="true"
      [sortField]="'nfeNumero'"
      [sortOrder]="-1"
      #table
      [(selection)]="selectedinvoiceTaxList"
      [rowsPerPageOptions]="[10, 20, 30, 50, 100]"
      styleClass="easy-table"
    >
      <!-- (onPage)="onChangePage($event)"
      (onSort)="onSort($event)" -->
      <!-- Botões legenda topo -->
      <ng-template pTemplate="caption">
        <div class="p-caption">
          <div class="export-buttons">
            <button
              class="easymarine btn-csv-xls"
              label="Exportar CSV"
              (click)="exportTable()"
              pTooltip="A exportação será de acordo com os filtros selecionados"
              tooltipPosition="top"
            >
              <span>Exportar tabela</span>
            </button>
            <button
              class="easymarine"
              label="Baixar Xmls"
              (click)="baixarXml()"
              pTooltip="Selecione os itens que deseja baixar"
              tooltipPosition="top"
            >
              <span>Baixar Xmls</span>
            </button>
          </div>
          <span class="total-registers"
            >{{ "TOTAL-REGISTERS" | translate }}: {{ invoiceTaxList.length }}
            {{ "OF" | translate }} {{ table.totalRecords }}
          </span>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th class="text-center">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th class="text-center">Status I.</th>
          <!-- <th class="text-center" >Itens</th> -->
          <th class="text-center" pSortableColumn="nfeNumero">
            Nota
            <p-sortIcon field="nfeNumero"></p-sortIcon>
          </th>
          <th class="text-center">Série</th>
          <th class="text-center">Tipo</th>

          <th class="text-center" pSortableColumn="invoice.invoiceNumber">
            Numero Fatura
            <p-sortIcon field="invoice.invoiceNumber"></p-sortIcon>
          </th>
          <th class="text-center">Fornecedor/Cliente</th>
          <th class="text-center">Dt. Cadastro</th>

          <th class="text-center" [pSortableColumn]="'createDate'">
            Dt. Emissão
            <p-sortIcon [field]="'createDate'"></p-sortIcon>
          </th>

          <th class="text-center" [pSortableColumn]="'valorTotal'">
            Valor
            <p-sortIcon [field]="'valorTotal'"></p-sortIcon>
          </th>

          <th class="text-center">Tributos</th>

          <th class="text-center" [pSortableColumn]="'status'">
            Situação
            <p-sortIcon [field]="'status'"></p-sortIcon>
          </th>
          <th class="text-center">Ações</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-invTaxDoc>
        <tr>
          <td class="text-center" [pTooltip]="" tooltipPosition="bottom">
            <p-tableCheckbox [value]="invTaxDoc"> </p-tableCheckbox>
          </td>
          <td class="text-center">
            {{ invTaxDoc?.nfeStatus }}
          </td>
          <!-- <td class="text-center link green">
            {{sumItens(invTaxDoc)}}
          </td> -->
          <td class="text-center">
            <a
              *ngIf="invTaxDoc?.nfeNumero && invTaxDoc.serviceDocument"
              class="paidOrder"
              [href]="invTaxDoc.serviceDocument"
              target="_blank"
              tooltipPosition="bottom"
              tooltipPosition="top"
            >
              {{ invTaxDoc?.nfeNumero }}
            </a>
          </td>
          <td class="text-center">
            {{ invTaxDoc?.serie }}
          </td>
          <td class="text-center">
            {{ invTaxDoc?.type === "exit" ? "Saída" : "Entrada" }}
          </td>
          <td class="text-center">
            <span class="paidOrder" (click)="extract(invTaxDoc?.invoiceId)">{{
              invTaxDoc?.invoiceNumber
            }}</span>
          </td>
          <td class="text-center">
            {{ invTaxDoc?.customerName }} {{ invTaxDoc?.supplierName }}
          </td>
          <td class="text-center">
            {{ invTaxDoc?.createDate | date : "dd/MM/yy h:mm" }}
          </td>
          <td class="text-center">
            {{ invTaxDoc?.updateDate | date : "dd/MM/yy" }}
          </td>

          <td class="text-right">
            {{ invTaxDoc?.valorTotal | number : "1.2-2" }}
          </td>
          <td class="text-center">
            {{ invTaxDoc?.taxValue | number : "1.2-2" }}
          </td>
          <td class="text-center">
            <div
              style="text-align: center !important"
              pTooltip="{{ invTaxDoc?.messageSefaz }}"
              tooltipPosition="bottom"
            >
              {{ getStatus(invTaxDoc?.status) }}
            </div>
          </td>
          <td
            class="text-center"
            style="display: flex"
            [pTooltip]="teste"
            tooltipPosition="bottom"
          >
            <a
              *ngIf="invTaxDoc?.nfeNumero && invTaxDoc.serviceDocumentXml"
              class="paidOrder"
              [href]="invTaxDoc.serviceDocumentXml"
              target="_blank"
              tooltipPosition="bottom"
              pTooltip="Baixa XML"
              tooltipPosition="top"
            >
              <i class="pi pi-download"></i>
            </a>
            <div
              pTooltip="Cancelar Nota"
              tooltipPosition="bottom"
              (click)="cancelInvoiceTaxDocument(invTaxDoc)"
              *ngIf="!cantCancel(invTaxDoc)"
              name="cancelInvoiceTaxDocument"
              class="table-action"
              style="color: red"
            >
              <i class="pi pi-times"></i>
            </div>
            <div
              pTooltip="Editar Nota"
              tooltipPosition="bottom"
              (click)="openInvoiceTaxDocument(invTaxDoc)"
              *ngIf="invTaxDoc.type === 'entry'"
              name="OpenInvoiceTaxDocument"
              class="table-action"
            >
              <i class="pi pi-pencil"></i>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-fieldset>
