<div class="dialog-form">
  <form [formGroup]="visitorForm">
    <ng-template pTemplate="header">
      {{ "DATA" | translate }}
    </ng-template>

    <p-fieldset class="operation-date">
      <p-header>
        <div>Informações</div>
      </p-header>
      <div class="form-group">
        <div>
          <div class="form-control">
            <app-sailor-upload
              [path]="imgPreviewPhoto"
              title="Foto"
              (finishEvent)="receiveUploadUrlPhoto($event)"
            >
            </app-sailor-upload>
          </div>
        </div>
        <div>
          <div class="form-group">
            <div
              class="form-control"
              style="min-width: 100px !important; max-width: 200px !important"
            >
              <label for="name"
                >{{ "FEDERAL-ID.PHYSICAL" | translate }} *</label
              >
              <p-inputMask
                type="text"
                mask="999.999.999-99"
                name="federalId"
                formControlName="federalId"
                (onBlur)="federalIdInUse()"
              >
              </p-inputMask>
            </div>

            <div class="form-control" style="max-width: 100%">
              <label for="name">{{ "NAME" | translate }} *</label>
              <input
                pInputText
                name="name"
                formControlName="name"
                maxlength="50"
              />
            </div>
          </div>
          <div class="form-group mt-3">
            <div class="form-control">
              <label for="phone">{{ "CELLPHONE" | translate }}*</label>
              <p-inputMask
                type="text"
                mask="+99 (99) 99999-9999"
                name="phone"
                formControlName="phone"
              ></p-inputMask>
            </div>

            <div class="form-control">
              <label for="birthday">{{ "Nascimento" | translate }}</label>
              <p-calendar
                dateFormat="dd/mm/yy"
                name="birthday"
                [monthNavigator]="true"
                [yearNavigator]="true"
                [yearRange]="'1950:2055'"
                rPCalendarMask
                appendTo="body"
                formControlName="birthday"
                [showIcon]="true"
                i18n
              >
              </p-calendar>
            </div>

            <div class="form-control">
              <label for="ativo">Ativo *</label>
              <p-inputSwitch
                name="ativo"
                formControlName="active"
                (onChange)="checkChange()"
              >
              </p-inputSwitch>
            </div>
          </div>
        </div>
      </div>
    </p-fieldset>
    <br />
    <p-fieldset class="operation-date" *ngIf="isProvider">
      <p-header>
        <div>{{ "ADDRESS" | translate }}</div>
      </p-header>
      <app-address [address]="address" (finishEvent)="receiveAddress($event)">
      </app-address>
    </p-fieldset>
    <br />
    <p-fieldset class="operation-date" *ngIf="isProvider">
      <p-header>
        <div>
          {{ "Observações" | translate }}
        </div>
      </p-header>

      <div>
        <textarea
          [rows]="5"
          autoResize="autoResize"
          pInputText
          maxlength="900"
          name="observation"
          formControlName="observation"
          style="width: 100%"
        ></textarea>
      </div>
    </p-fieldset>
    <div class="form-group align-center">
      <div class="buttons">
        <button type="button" (click)="close()" class="easymarine danger">
          {{ "CANCEL" | translate }}
        </button>
        <button class="easymarine primary" (click)="save()">
          {{ "SAVE" | translate }}
        </button>
        <button *ngIf="isNested" class="easymarine" (click)="next()">
          {{ "NEXT" | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
