import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { SlingConfig } from 'src/app/models';
import { WashEngineTurnScheduleStatus } from 'src/app/models/enums/wash-engine-turn-schedule-status';
import { BoatWashService } from 'src/app/services/boat-wash.service';
import { EngineTurnService } from 'src/app/services/engine-turn.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { SelectDatesComponent } from './select-dates/select-dates.component';

export class WashOrEngineTurnDetails {
  boatAll: number;
  boatScheduled: number;
  boatNoSchedule: number;
  boatNeverSchedule: number;
}

@Component({
  selector: 'app-wash-engine-turn',
  templateUrl: './wash-engine-turn.component.html',
  styleUrls: ['./wash-engine-turn.component.scss']
})
export class WashEngineTurnComponent implements OnInit, OnDestroy {

  slingConfig = new SlingConfig();

  countWash = 0;
  countWithoutWash = 0;
  countEngine = 0;
  countWithoutEngine = 0;
  loaded = false;
  washLabel: string;
  turnLabel: string;
  boatWashes: any[];
  boatsEngineTurn: any[];

  washEngineSubscription: Subscription;

  filterStatusoptions: any[] = [
    WashEngineTurnScheduleStatus.Schedule,
    WashEngineTurnScheduleStatus.Never,
    WashEngineTurnScheduleStatus.Removed
  ];
  filter = { status: [WashEngineTurnScheduleStatus.Schedule, WashEngineTurnScheduleStatus.Never]};

constructor(
    private slingConfigService: SlingConfigService,
    private boatWashService: BoatWashService,
    private engineTurnService: EngineTurnService,
    private spinner: NgxSpinnerService,
    private messageUtil: MessageUtil,
    private webSocketService: WebSocketService,
    private dialog: DialogService,
    private route: ActivatedRoute
  ) {
    const filterByQuery = this.route.snapshot.queryParams.filter;
    if (filterByQuery === 'never') {
      this.filter.status = [WashEngineTurnScheduleStatus.Never];
    }
  }

  async ngOnInit(): Promise<void> {
    this.slingConfig = await this.loadSlingConfig();
    await this.translate();
    await this.findWashAndEngine();
    this.loaded = true;
    this.washEngineSubscription = this.webSocketService.getMessageWashEngine().subscribe(async (message) => {
      await this.findWashAndEngine();
    });
  }

  ngOnDestroy(): void {
    this.webSocketService.unsubscribeWashEngineChanged(this.washEngineSubscription);
  }

  async findWashAndEngine(): Promise<void> {
    await this.getBoatsLastWash();
    await this.getBoatsLastEngineTurn();
  }

  async loadSlingConfig(): Promise<SlingConfig> {
    return new Promise<SlingConfig>(
      async (resolve) => {
        this.slingConfigService.getSlingConfig().subscribe(
          async (data) => {
            resolve(data[0]);
          },
          async (error) => {
            const exception = error.error.data.exception;
            this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
            resolve(null);
          }
        );
      }
    );
  }

  exportWasheEngineTurn(): void {
    this.dialog.open(SelectDatesComponent, {
      width: '70%',
      height: '30vh',
      dismissableMask: false,
      header: "Selecionar datas",
    });
  }

  async translate(): Promise<void> {
    this.washLabel = this.messageUtil.translateKey('WASH');
    this.turnLabel = this.messageUtil.translateKey('ENGINE-TURN');
  }

  refresh(): void {
    this.findWashAndEngine();
  }

async getBoatsLastWash(filter = {}): Promise<void> {
    return new Promise<void>(
      async (resolve) => {
        this.spinner.show();
        this.boatWashService.getBoatsLastWash().subscribe(
          async (washes) => {
            this.boatWashes = washes;
            this.countWash = this.boatWashes.filter((boat) => boat.washDate).length;
            this.countWithoutWash = this.boatWashes.length - this.countWash;
            this.spinner.hide();
            resolve();
          },
          async (error) => {
            this.spinner.hide();
            const exception = error.error.data.exception;
            this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
            resolve();
          }
        );
      }
    );
  }

  async getBoatsLastEngineTurn(): Promise<void> {
    return new Promise<void>(
      (resolve) => {
        this.spinner.show();
        this.engineTurnService.findAll().subscribe(
          async (enginesTurn) => {
            this.boatsEngineTurn = enginesTurn;
            this.countEngine = this.boatsEngineTurn.filter((boat) => boat.engineTurnDate).length;
            this.countWithoutEngine = this.boatsEngineTurn.length - this.countEngine;
            this.spinner.hide();
            resolve();
          },
          async (error) => {
            this.spinner.hide();
            const exception = error.error.data.exception;
            this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
            resolve();
          }
        );
      }
    );
  }
}
