import { UserService } from 'src/app/services/user.service';
import { BoatService } from './../../../services/boat.service';
import { CustomerService } from './../../../services/customer.service';
import { SailorFilters } from './../../../models/dtos/sailor-filters';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageUtil } from 'src/app/utils/message.util';
import { Sailor } from 'src/app/models/sailor';
import { SailorService } from 'src/app/services/sailor.service';
import { SailorFormComponent } from './sailor-form/sailor-form.component';
import { BoatTableDTO } from 'src/app/models/dtos/boatTableDTO';
import { User } from 'src/app/models';
import { CustomReportExportComponent } from 'src/app/components/extract-custom-report/custom-report-export.component';
import { PaginationFilter } from 'src/app/models/pagination-filter';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { SlingConfig } from '../../../models/sling-config';
import { SlingConfigService } from '../../../services/sling-config.service';
import { BoatCustomerTooltipDTO } from 'src/app/models/dtos/boatCustomerTooltipDTO';
import Swal from 'sweetalert2';
import { SailorTableDTO } from '../../../models/dtos/sailor/SailorTableDTO';
import moment from 'moment'; import { PrintingService } from 'src/app/services/printing-service';
import { PrintType } from 'src/app/models/enums/print-type';
;

@Component({
  selector: 'app-sailor',
  templateUrl: './sailor.component.html',
  styleUrls: ['./sailor.component.scss'],
})
export class SailorComponent implements OnInit {
  numberOfRows = 10;
  cols = [];
  sailorsTable: SailorTableDTO[] = [];
  sailorsTotal: SailorTableDTO[] = [];
  sailorFilter: SailorFilters = new SailorFilters();
  sailorType: string;
  totalRecords = 0;
  sailorsSuccess: any;
  exportTip = 'Para aplicação do filtro, clique antes em "Pesquisar"';
  customers: any[] = [];
  boats: any[] = [];
  menuItems: MenuItem[] = [];
  selectedSailor: any;

  groupsFilter: { label: string; value: any; }[];
  paginationFilter = new PaginationFilter();
  slingConfig: SlingConfig
  constructor(
    public dialog: DialogService,
    private messageUtil: MessageUtil,
    private spinner: NgxSpinnerService,
    private printingService: PrintingService,
    private sailorService: SailorService,
    private customerService: CustomerService,
    private boatService: BoatService,
    private userService: UserService,
    private slingConfigService: SlingConfigService
  ) { }

  ngOnInit(): void {
    this.getSailors();
    this.getCustomers();
    this.getboats();
    this.loadSlingConfig();
    this.loadMenuItems();
  }

  changeSelectedItem(sailor) {
    this.selectedSailor = sailor;
  }

  loadMenuItems() {
    this.menuItems = [{ label: 'Editar', icon: 'pi pi-pencil', iconClass: 'red-menu-icon', command: () => this.openSailorFormDialog(this.selectedSailor) }]
    this.menuItems.push({ label: 'Ficha de Cadastro', icon: 'pi pi-print', iconClass: 'red-menu-icon', command: () => this.printSailorFile(this.selectedSailor) })
  }

  findSailor(sailor: any): void {
    this.spinner.show();
    this.userService.getById(sailor.userId).subscribe({
      next: (data: any) => {
        this.abrirForm(data, sailor.accessCategoryId);
      },
      error: (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
      complete: () => this.spinner.hide()
    });
  }

  async openSailorFormDialog(sailor?: Sailor): Promise<void> {
    let data = {
      sailor: null,
    };
    if (sailor && sailor.id) {
      this.findSailor(sailor);
    } else {
      this.abrirForm();
    }
  }

  abrirForm(user?: User, accessCategoryId?: number) {
    const data = {
      user: user,
      accessCategoryId: accessCategoryId
    };

    this.dialog
      .open(SailorFormComponent, {
        width: '70%',
        height: '90vh',
        dismissableMask: false,
        data: data,
        header: this.messageUtil.translateKey('Cadastro > Marinheiro'),
      })
      .onClose.subscribe((d: any) => {
        if (d) {
          if (d?.username) {
            this.abrirForm(d)
          }
          else {
            this.getSailors();

          }

        }
      });
  }

  dowloadForm(): void { }

  openImportDialog(): void { }

  loadOptionsCustomers(data: any[]): void {
    this.customers = data.map((obj) => ({
      label: obj.name,
      value: obj,
    }));
  }

  loadOptionsBoat(data: BoatTableDTO[]): void {
    this.boats = data.map((obj) => ({
      label: obj.name,
      value: obj,
    }));
  }

  getCustomers(): void {
    this.spinner.show();

    this.customerService.getAllOwnerOnlySimple().subscribe(
      (data: any) => {
        this.loadOptionsCustomers(data);
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
      () => this.spinner.hide()
    );
  }

  getboats(): void {
    this.spinner.show();

    this.boatService.getTable().subscribe(
      (data: any) => {
        this.loadOptionsBoat(data);
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
      () => this.spinner.hide()
    );
  }

  getSailors(): void {
    this.spinner.show();

    this.sailorService.getSailorFilterList(this.sailorFilter, this.paginationFilter).subscribe(
      (data: any) => {
        this.totalRecords = data.sailors.sailorTableDTOPage.totalElements;
        this.sailorsTable = data.sailors.sailorTableDTOPage.content;
        this.sailorsTotal = data.sailors.sailorTableDTOList;
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
      () => {
        this.spinner.hide()
      }
    );


  }

  clear(): void {
    this.sailorFilter = new SailorFilters();
    this.getSailors();
  }

  getToolTipValue(boatCustomerTooltipDTOList: BoatCustomerTooltipDTO[]): string {
    let retorno: string = '';
    for (let i = 0; i < boatCustomerTooltipDTOList.length; i++) {
      if (i > 0) {
        retorno += '\n\n';
      }
      if (boatCustomerTooltipDTOList[i].boatName) {
        retorno += `Embarcação: ${boatCustomerTooltipDTOList[i].boatName}`;
      }
      if (boatCustomerTooltipDTOList[i].customers?.length == 1) {
        retorno += `\nCliente: ${boatCustomerTooltipDTOList[i].customers}`;
      }
      if (boatCustomerTooltipDTOList[i].customers?.length > 1) {
        retorno += `\nClientes: ${boatCustomerTooltipDTOList[i].customers}`;
      }
      if (boatCustomerTooltipDTOList[i].linkedUserName !== null && boatCustomerTooltipDTOList[i].linkedUserName !== '' &&
        boatCustomerTooltipDTOList[i].vinculationHour !== null) {
        let vinculationDate = new Date(boatCustomerTooltipDTOList[i].vinculationHour);
        retorno += `\nVinculado por: ${boatCustomerTooltipDTOList[i].linkedUserName}, ${moment(vinculationDate).format('DD/MM/YY HH:mm')}`
      }
    }
    return retorno;
  }

  getTextSailor(types: string[]): string {
    let retorno: string = '';

    if (types?.length > 0) {
      types.forEach((item) => {
        if (item != '') {
          retorno +=
            this.messageUtil.translateKey('SAILOR-INFO.SAILOR-TYPE.' + item) +
            ', ';
        }
      });
    }
    if (retorno.endsWith(', ')) {
      retorno = retorno.substring(0, retorno.length - 2);
    }
    return retorno;
  }

  activateSailor(sailor: Sailor): void {
    this.spinner.show();
    this.sailorService.activateSailor(sailor.id).subscribe({
      next: (data: any) => {
        this.spinner.hide();
        this.messageUtil.generateMessage('success', 'Marinheiro Credenciado', 'Marinheiro credenciado com sucesso!');
        this.getSailors();
      },
      error: (error) => {
        this.spinner.hide();
        this.messageUtil.generateMessage('error', 'Erro ao credenciar marinheiro', 'Houve um erro ao credenciar o marinheiro, tente novamente mais tarde.');
      }
    });
  }

  validateDeactivateSailor(sailor: SailorTableDTO): void {
    this.spinner.show();
    if (sailor.boatCustomerTooltipDTOList !== null && sailor.boatCustomerTooltipDTOList.length > 0) {
      Swal.fire({
        title: `Descredenciamento de Marinheiro`,
        html: (
          `Tem certeza que deseja descredenciar o marinheiro <br/> <b>${sailor.name}?</b> <br/> Isso irá desvincular <b>${sailor.boatCustomerTooltipDTOList.length}</b> embarcações`
        ),
        icon: 'warning',
        showCancelButton: true,
        backdrop: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          this.deactivateSailor(sailor.id);
        } else {
          this.spinner.hide();
        }
      })
    } else {
      this.deactivateSailor(sailor.id);
    }
  }

  private deactivateSailor(sailorId: number) {
    this.sailorService.deactivateSailor(sailorId).subscribe({
      next: (data: any) => {
        this.spinner.hide();
        this.messageUtil.generateMessage('success', 'Marinheiro Descredenciado', 'Marinheiro descredenciado com sucesso!');
        this.getSailors();
      },
      error: (error) => {
        this.spinner.hide();
        this.messageUtil.generateMessage('error', 'Erro ao descredenciar marinheiro', 'Houve um erro ao descredenciar o marinheiro, tente novamente mais tarde.');
      }
    });
  }

  deleteSailor(sailor: Sailor): void {
    this.spinner.show();
    this.sailorService.deleteSailor(sailor.id).subscribe({
      next: (data: any) => {
        this.spinner.hide();
        this.messageUtil.generateMessage('success', 'Marinheiro Excluído', 'Marinheiro excluído com sucesso!');
        this.getSailors();
      },
      error: (error) => {
        console.log(error);
        this.spinner.hide();
        this.messageUtil.generateMessage('error', 'Erro ao excluir marinheiro', error?.error?.data?.exception?.message);
      }
    });
  }

  onChangePage(event: LazyLoadEvent): void {
    if (this.slingConfig) {
      const page = event.first / event.rows;
      if (event.multiSortMeta) {
        this.paginationFilter.sort = event.multiSortMeta[0].field;
        this.paginationFilter.order = event.multiSortMeta[0].order === 1 ? 'ASC' : 'DESC';
      }
      this.paginationFilter.page = page;
      this.paginationFilter.size = event.rows;
      this.getSailors();
    }
  }

  loadSlingConfig(): void {
    this.paginationFilter = new PaginationFilter();
    this.slingConfigService.getSlingConfig().subscribe(
      (data) => {
        this.slingConfig = data[0];
        if (this.slingConfig != null) {
          this.numberOfRows = this.slingConfig.numberOfRecordsPerPage != null ? this.slingConfig.numberOfRecordsPerPage : 10;
          this.paginationFilter.size = this.numberOfRows;
        } else {
          this.numberOfRows = 10;
        }
        this.getSailors();
      },
      (error) => null);
  }

  exportTable() {
    const data = { table: this.sailorsTotal, type: 'SAILOR', };
    console.log(data);
    this.dialog.open(CustomReportExportComponent, {
      width: '70%',
      height: '90vh',
      dismissableMask: false,
      data,
      header: this.messageUtil.translateKey('EXPORT-SAILOR-TABLE')
    })
  }

  getMaskedPhone(phone: string): string {
    if (phone) {
      return phone.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, '+$1 ($2) $3-$4');
    }
    return phone;
  }

  printSailorFile(sailor: SailorTableDTO): void {
    this.spinner.show();
    this.printingService.generatePdfById(sailor.id, PrintType.SAILOR_FILES).subscribe({
      next: (data) => {
        console.log(data);
        this.spinner.hide();
        const url = window.URL.createObjectURL(data);
        window.open(url);
      },
      error: (error) => {
        this.spinner.hide();
        this.messageUtil.generateMessage('error', 'Erro ao gerar arquivo', 'Houve um erro ao gerar o arquivo, tente novamente mais tarde.');
      }
    });
  }
}
