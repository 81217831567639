<br>

<p-card class="p-2">
    <div class="grid text-sm">
    
        <div class="col-2 text-right font-bold" >Código Vaga:</div>
        <div class="col-2">{{vacancyStatus.code}}</div>

        <div class="col-2 text-right font-bold" >Local Vaga:</div>
        <div class="col-2">{{vacancyStatus.locationName}}</div>

        <div class="col-2 text-right font-bold" >Situacao Vaga:</div>
        <div class="col-2">{{vacancyStatus.boatId ? 'ALOCADA' : 'DISPONÍVEL'}}</div>

        <div class="col-2 text-right font-bold" >Embarcação:</div>
        <div class="col-2">{{vacancyStatus.boatName}}</div>

        <div class="col-2 text-right font-bold" >Status:</div>
        <div class="col-2">{{getBoatStatus()}}</div>

        <div class="col-2 text-right font-bold" >Previsao:</div>
        <div class="col-2" *ngIf="vacancyStatus.movementDateTimeDTO && vacancyStatus.movementDateTimeDTO.type == 'PREVISION' ">{{vacancyStatus.movementDateTimeDTO.dateTime | date: 'dd/MM/yyyy HH:mm:ss' }}</div>


    </div>
</p-card>

<p-card class="text-base p-2"  *ngIf="getBoatStatus() == 'NAVIGATION' && vacancyStatus.navigationPlanDTO">
    <ng-template pTemplate="header">
        <div class="text-base font-bold">Plano de Navegação</div>
    </ng-template>

    <div class="grid text-sm" >        
        
        <div class="col-2 text-right font-bold" >Resp. Plano:</div>
        <div class="col-2">{{vacancyStatus.navigationPlanDTO.responsible.name}}</div>

        <div class="col-2 text-right font-bold" >Fone:</div>
        <div class="col-2">{{vacancyStatus.navigationPlanDTO.onBoardPhone}}</div>

        <div class="col-2 text-right font-bold" >Prev.:</div>
        <div class="col-2">{{vacancyStatus.navigationPlanDTO.returnTime | date: 'dd/MM/yyyy HH:mm:ss' }}</div>

        <div class="col-2 text-right font-bold" >Destino:</div>
        <div class="col-6">{{vacancyStatus.navigationPlanDTO.destination}}</div>

        <div class="col-1 text-right font-bold" >Adultos:</div>
        <div class="col-1">{{vacancyStatus.navigationPlanDTO.crewQty}}</div>

        <div class="col-1 text-right font-bold" >Criancas:</div>
        <div class="col-1">{{vacancyStatus.navigationPlanDTO.childQt }}</div>

    </div>
</p-card>
<div class="grid text-sm p-2" *ngIf="getBoatStatus() == 'NAVIGATION' && !vacancyStatus.navigationPlanDTO">
    <div class="col-12 font-bold text-red-500" >SEM PLANO DE NAVEGAÇÂO</div>
</div>

<p-card class="p-2" *ngIf="vacancyStatus.vacancyStatusServicesDTOList">
    <div class="grid text-sm" >
        <div class="col-2 text-right  font-bold" >Período Serviço:</div>
        <div class="col-10">
        <p-table [value]="vacancyStatus.vacancyStatusServicesDTOList" [styleClass]="t_class" [scrollable]="true" scrollHeight="100px">
            <ng-template pTemplate="header" >
                <tr class="p-0">              
                    <th class="p-0">&nbsp;</th>
                    <th class="p-0">Início</th>
                    <th class="p-0">Fim</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-service class="p-0">
                <tr class="p-0">
                    <td class="p-0">{{service.serviceName}}</td>
                    <td class="p-0">{{service.contractStartDate | date: 'dd/MM/yyyy'}}</td>
                    <td class="p-0">{{service.contractEndDate | date: 'dd/MM/yyyy'}}</td>
    
                </tr>
            </ng-template>
        </p-table>
    
        </div>
    </div>
  </p-card>


