import { Component, Input } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectItem, MenuItem, LazyLoadEvent, SortEvent } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { AccessGroupService } from 'src/app/services/access-controler/access-group.service';
import { VisitorService } from 'src/app/services/access-controler/visitor.service';
import { CustomerService } from 'src/app/services/customer.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { VisitorFormPermissionComponent } from '../service-provider-forms-container/visitor-form-permission/visitor-form-permission.component';
import { Visitor } from 'src/app/models/dtos/access-controller/visitor';
import { AccessPermissionService } from 'src/app/services/access-controler/access-permission.service';
import { AccessPermissionFilter } from 'src/app/models/dtos/access-controller/accessPermissionFilter';
import { AccessPermissionDTO } from 'src/app/models/dtos/access-controller/accessPermissionDTO';
import Swal from 'sweetalert2';
import { FormatUtil } from 'src/app/utils/format.util';
import { VisitorFilter } from 'src/app/models/dtos/access-controller/visitorFilter';
import { AccessGroupEnum } from 'src/app/models/dtos/access-controller/access-group-enum';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';

@Component({
  selector: 'app-visitor-panel',
  templateUrl: './visitor-panel.component.html',
  styleUrls: ['./visitor-panel.component.scss'],
})
export class VisitorPanelComponent {
  @Input() liberation: boolean = true;
  @Input() visitorType: AccessGroupEnum = null;

  permissionList: AccessPermissionDTO[] = [];
  fullList: AccessPermissionDTO[] = [];
  selectedPermission: AccessPermissionDTO;

  filter: AccessPermissionFilter = new AccessPermissionFilter();
  globalFilter: string = '';
  totalRecords: number = 0;
  numberOfRows: number = 100;
  numberOfElements: number = 0;

  serviceProviderList: SelectItem[] = [{ label: 'Todos', value: null }];
  customerList: SelectItem[] = [{ label: 'Todos', value: null }];
  rangeCreatedDates: Date[] = [
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 30
    ),
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 5
    ),
  ];

  menuItems: MenuItem[];
  selectedItem: Visitor;

  constructor(
    private accessGroupService: AccessGroupService,
    private customerService: CustomerService,
    private accessPermissionService: AccessPermissionService,
    private spinner: NgxSpinnerService,
    private messageUtil: MessageUtil,
    private visitorService: VisitorService,
    public dialog: DialogService,
    private slingConfigService: SlingConfigService
  ) {}

  ngOnInit() {
    this.onChangeRange();
    this.loadSlingConfig();
    this.fullList = [...this.permissionList];
    this.getAccessPermissionsByFilter();
    this.getCustomers();
    this.getServiceProviders();
    this.loadMenuItems();
    BroadcastService.get('reloadTables').subscribe(() => {
      this.getAccessPermissionsByFilter();
    });
  }

  changeSelectedItem(visitor): void {
    this.selectedItem = visitor;
  }

  getAccessPermissionsByFilter(): void {
    this.filter.liberation = this.liberation;
    this.filter.groupType = this.visitorType;
    this.filter.pageSize = this.numberOfRows;
    this.accessPermissionService.findByFilter(this.filter).subscribe({
      next: (data) => {
        if (this.liberation === true) {
          this.permissionList = data.content.filter(
            (permission) =>
              permission.permissionLiberationStatus === 'WAITING' ||
              permission.permissionLiberationStatus === undefined ||
              permission.permissionLiberationStatus === null
          );
        } else {
          this.permissionList = data.content.filter(
            (permission) =>
              permission.permissionLiberationStatus === 'ALLOWED' ||
              permission.permissionLiberationStatus === 'DENIED'
          );
        }

        this.fullList = [...this.permissionList];

        this.totalRecords = data.totalElements;
        this.numberOfElements = data.numberOfElements;
        if (this.numberOfRows > data.size) {
          this.numberOfRows = data.size;
        }
      },
      error: (error) => {
        console.log(error);

        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
    });
  }

  getCustomers(): void {
    this.customerService.getAll('id,name').subscribe({
      next: (data) => {
        this.customerList = [{ label: 'Todos', value: null }].concat(
          data.map((c) => ({ label: c.name, value: c.id }))
        );
      },
      error: (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
    });
  }

  getServiceProviders(): void {
    const filter = new VisitorFilter();
    filter.serviceProvider =
      this.visitorType === AccessGroupEnum.SERVICE_PROVIDER;
    this.visitorService.findByFilter(filter).subscribe({
      next: (data) => {
        this.serviceProviderList = [{ label: 'Todos', value: null }].concat(
          data.content.map((s) => ({ label: s.name, value: s.id }))
        );
      },
      error: (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
    });
  }

  clear(): void {
    this.getCustomers();
    this.filter = new AccessPermissionFilter();
    this.filter.groupType = this.visitorType;
    this.filter.liberation = this.liberation;
    this.rangeCreatedDates = [];
    this.getAccessPermissionsByFilter();
  }

  onChangePage(event: LazyLoadEvent): void {
    const page = event.first / event.rows;
    this.filter.pageIndex = page;
    this.filter.pageSize = event.rows;

    this.getAccessPermissionsByFilter();
  }

  onChangeSort(event: SortEvent): void {
    this.filter.sortField = event.field;
    this.filter.sortDirection = event.order === 1 ? 'ASC' : 'DESC';

    this.getAccessPermissionsByFilter();
  }

  onChangeRange() {
    if (this.rangeCreatedDates && this.rangeCreatedDates.length > 0) {
      if (this.rangeCreatedDates[0] != null) {
        this.filter.permissionStartDate = this.rangeCreatedDates[0];
      }
      if (this.rangeCreatedDates[1] != null) {
        this.filter.permissionEndDate = this.rangeCreatedDates[1];
      }
    } else {
      this.filter.permissionStartDate = null;
      this.filter.permissionEndDate = null;
    }
  }

  filterGlobal(): void {
    if (this.globalFilter) {
      this.filterByGlobal();
    } else {
      this.permissionList = this.fullList;
    }
  }

  filterByGlobal(): void {
    this.permissionList = this.fullList
      .filter(
        (p) =>
          (p.visitorPhone &&
            p.visitorPhone.toString().includes(this.globalFilter)) ||
          (p.visitorFederalId &&
            p.visitorFederalId.toString().includes(this.globalFilter)) ||
          (p.visitorName &&
            FormatUtil.getNotAccents(p.visitorName)
              .toUpperCase()
              .includes(
                FormatUtil.getNotAccents(this.globalFilter).toUpperCase()
              ))
      )
      .map((p) => {
        return p;
      });
  }

  updateLiberationStatus(event: any, liberation: boolean): void {
    this.spinner.show();
    this.accessPermissionService
      .updateLiberationStatus(event.permissionId, liberation)
      .subscribe({
        next: (data) => {
          this.spinner.hide();
          Swal.fire({
            title: 'Sucesso!',
            text: 'Acesso ' + (data ? 'liberado' : 'negado') + ' com sucesso!',
            icon: 'success',
            confirmButtonText: 'OK',
          });
          this.getAccessPermissionsByFilter();
        },
        error: (error) => {
          this.spinner.hide();
          console.log(error);
        },
      });
  }

  loadMenuItems() {
    this.menuItems = [
      {
        label: 'Editar',
        icon: 'pi pi-pencil',
        iconClass: 'red-menu-icon',
        command: () => this.editPermission(this.selectedItem),
      },
    ];
    this.menuItems.push({
      label: 'Imprimir',
      icon: 'pi pi-print',
      iconClass: 'red-menu-icon',
      command: () => this.printPermission(this.selectedItem),
    });
  }

  editPermission(permission: any): void {
    this.visitorService.getById(permission.visitorId).subscribe({
      next: (visitor) => {
        this.dialog
          .open(VisitorFormPermissionComponent, {
            width: '1050px',
            height: '950px',
            header: 'Edição de Permissões de Serviços',
            data: {
              visitor: visitor,
              permission: permission,
              visitorType: this.visitorType,
            },
          })
          .onClose.subscribe(() => {
            this.getAccessPermissionsByFilter();
          });
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  printPermission(permission: any): void {
    this.spinner.show();
    this.accessGroupService.extract(permission.permissionId).subscribe({
      next: (data) => {
        if (data.path) {
          window.open(data.path, '_blank');
        } else {
          console.error('O caminho do arquivo não foi fornecido.');
        }
      },
      error: (error) => {
        this.spinner.hide();
        console.log(error);
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
      complete: () => this.spinner.hide(),
    });
  }

  isAllowedToEdit(liberationStatus: any): boolean {
    if (liberationStatus === 'DENIED' || liberationStatus === 'EXPIRED') {
      return false;
    }
    return true;
  }

  loadSlingConfig(): void {
    this.slingConfigService.getSlingConfig().subscribe({
      next: (data) => {
        const slingConfig = data[0];
        if (slingConfig != null) {
          this.numberOfRows = slingConfig.numberOfRecordsPerPage;
        } else {
          this.numberOfRows = 100;
        }
      },
      error: (error) => null,
    });
  }

  hasIncompleteVisitorData(permission: AccessPermissionDTO): boolean {
    if (permission.visitorPhoto == null) {
      return true;
    }
    if (
      permission.visitorAddressId == null &&
      this.visitorType === AccessGroupEnum.SERVICE_PROVIDER
    ) {
      return true;
    }
    return false;
  }
}
