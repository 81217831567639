<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{'CUSTOMERS-LIST' | translate}}</span>
        <div class="header-buttons">
            <button class="primary fab-button" (click)="openCustomerFormDialog()" pTooltip="Adicionar novo cliente"
                tooltipPosition="bottom">
                <i class="pi pi-plus" style="font-size: 21px;"></i>
            </button>
            <button class="primary fab-button" (click)="dowloadForm()"
                pTooltip="{{ 'PRINT-CUSTOMER-FORM' | translate }}" tooltipPosition="bottom">
                <i class="pi pi-print" style="font-size: 21px;"></i>
            </button>
            <button class="fab-button primary" (click)="exportTable()" label="Exportar valores"
                pTooltip="Exportar Valores" tooltipPosition="left">
                <i class="pi pi-file"></i>
            </button>
            <button class="primary fab-button" (click)="openImportDialog()" pTooltip="Gerenciar importação de planilhas"
                tooltipPosition="bottom">
                <i class="pi pi-file-excel" style="font-size: 21px;"></i>
            </button>
            <button class="fab-button primary" appFiltersButton pTooltip="Exibir/Ocultar Filtros" tooltipPosition="left">
                <i class="pi pi-filter"></i>
            </button>
        </div>
    </p-header>

    <form class="filters" (submit)="find()" appFilters>
        <div class="filter-row">
            <div class="form-control" *ngIf="marinaCompanies?.length">
                <label for="marinaCompanyId">{{'COMPANY' | translate}}</label>
                <p-dropdown [options]="marinaCompanies" name="marinaCompanyId" [placeholder]="'COMPANY' | translate"
                    appendTo="body" [title]="'COMPANY' | translate" (onChange)="changeCompany($event)">
                </p-dropdown>
            </div>
            <div class="form-control">
                <label for="name">{{'NAME' | translate}}</label>
                <input pInputText type="text" [(ngModel)]="customerFilter.name" name="name">
            </div>
            <div class="form-control">
                <label for="email">{{'EMAIL' | translate}}</label>
                <input pInputText type="text" [(ngModel)]="customerFilter.email" name="email">
            </div>
            <div class="form-control">
                <label for="phone">{{'PHONE' | translate}}</label>
                <input pInputText type="text" [(ngModel)]="customerFilter.phone" name="phone">
            </div>
            <div class="form-control">
                <label for="federalId">{{'FEDERAL_ID' | translate}} / {{'FEDERAL-ID.JURIDICAL' | translate}}</label>
                <input pInputText type="text" (change)="formatMaskFederalId(customerFilter.federalId)" mask="000.000.000-00||00.000.000/0000-00" [(ngModel)]="customerFilter.federalId" name="federalId">
            </div>
            <div class="form-control">
                <label for="groupIds">{{'GROUPS' | translate}}</label>
                <p-multiSelect [options]="groupsFilter" [(ngModel)]="customerFilter.groupIds" name="groupIds"
                    appendTo="body" selectedItemsLabel="{0} itens selecionados">
                </p-multiSelect>
            </div>
            <div class="form-control">
                <label for="licenceStates">{{'LICENSED' | translate}}</label>
                <p-multiSelect [options]="licenceStateFilter" [(ngModel)]="customerFilter.licenceStates" name="licenceStates"
                    appendTo="body" selectedItemsLabel="{0} itens selecionados">
                </p-multiSelect>
            </div>
            <div class="form-control">
                <label for="city">{{'CITY' | translate}}</label>
                <input pInputText type="text" [(ngModel)]="customerFilter.city" name="city">
            </div>
            <div class="form-control">
                <label for="state">{{'STATE' | translate}}</label>
                <input pInputText type="text" [(ngModel)]="customerFilter.state" name="state">
            </div>
            <div class="form-control">
                <label for="reviewed">{{'REVIEWED' | translate}}</label>
                <p-dropdown [(ngModel)]="customerFilter.reviewed" name="reviewed" appendTo="body"
                    [options]="[{label: 'Ambos', value: ''}, {label: 'Sim', value: true}, {label: 'Não', value: false}]">
                </p-dropdown>
            </div>
            <div class="form-control">
                <label for="active">{{'STATUS' | translate}}</label>
                <p-dropdown [(ngModel)]="customerFilter.active" name="active" appendTo="body"
                    [options]="[{label: 'Ambos', value: ''}, {label: 'Ativo', value: true}, {label: 'Inativo', value: false}]">
                </p-dropdown>
            </div>
            <div *ngIf="this.isWorkshop" class="form-control">
              <label for="customerProfile">{{'CUSTOMER_PROFILE' | translate}}</label>
              <p-dropdown [(ngModel)]="customerFilter.customerProfile" name="customerProfile" appendTo="body" [options]="customerProfiles"></p-dropdown>
            </div>
            <div class="form-control">
                <label for="customerType">{{'CUSTUMER-TYPES' | translate}}</label>
                <p-dropdown [(ngModel)]="customerType" name="customerType" appendTo="body"
                    [options]="[{label: 'Todos', value: ''},
                    {label: 'Autorizado', value: 'associated'},
                    {label: 'Proprietário', value: 'owner'},
                    {label: 'Prop./Aut.', value: 'ownAsso'},
                    {label: 'Sem Embarcação Associada', value: 'noBoat'}]">
                </p-dropdown>
            </div>
            <div class="form-control">
                <label for="userApp">{{'USER-APP' | translate}}</label>
                <p-dropdown [(ngModel)]="customerFilter.userApp" name="userApp" appendTo="body"
                    [options]="[{label: 'Ambos', value: ''}, {label: 'Sim', value: true}, {label: 'Não', value: false}]">
                </p-dropdown>
            </div>
            <div class="buttons">
                <div class="form-button">
                    <button (click)="clear()" class="easymarine danger" type="button">{{ 'CLEAR' | translate }}</button>
                </div>
                <div class="form-button">
                    <button class="easymarine primary" type="submit">{{ 'FIND' | translate }}</button>
                </div>
            </div>
        </div>
    </form>
    <div class="table">
        <p-table #tt [value]="customersTable" [paginator]="true" [rows]="customerFilter.size" [lazy]="true" csvSeparator=";"
            [columns]="cols" [selection]="selectedCustomers" [totalRecords]="totalRecords" styleClass="easy-table"
            (onLazyLoad)="onChangePage($event)" [alwaysShowPaginator]="false" autoLayout="true">
            <ng-template pTemplate="caption">
                <div class="p-caption">
                    <!-- <div class="export-buttons">
                        <button class="easymarine btn-csv-xls" label="Exportar CSV" (click)="exportTable()"
                            pTooltip="A exportação será de acordo com os filtros selecionados" tooltipPosition="top">
                            <span>Exportar tabela</span>
                        </button>
                    </div> -->
                    <div class="flex flex-column align-items-start">
                      <span class="total-registers">{{ 'TOTAL-REGISTERS' | translate }}:
                          {{customersTable.length}} {{'OF' | translate}} {{tt.totalRecords}}
                      </span>
                      <span *ngIf="marinaImplantation !== undefined && marinaImplantation !== null && marinaImplantation.totalCustomerActive">
                        Clientes de Implantação: {{marinaImplantation.totalCustomers}}
                      </span>
                    </div>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th [pSortableColumn]="'userApp'">
                        {{ 'App' | translate}}
                        <p-sortIcon [field]="'userApp'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'name'">
                        {{ 'NAME' | translate }}
                        <p-sortIcon [field]="'name'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'marinaCompanyId'" *ngIf="marinaCompanies?.length > 0">
                        {{ 'Emp.' | translate }}
                        <p-sortIcon [field]="'marinaCompanyId'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'federalId'">
                        {{ 'FEDERAL-ID.GENERAL' | translate }}
                        <p-sortIcon [field]="'federalId'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'email'">
                        {{ 'PERSONAL-EMAIL' | translate }}
                        <p-sortIcon [field]="'email'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'phone'">
                        {{ 'CELLPHONE' | translate }}
                        <p-sortIcon [field]="'phone'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'group.name'">
                        {{ 'GROUP' | translate }}
                        <p-sortIcon [field]="'group.name'"></p-sortIcon>
                    </th>
                    <th class="text-center">{{ 'LOCATION' | translate }}</th>
                    <th [pSortableColumn]="'customerProfile'">
                      {{'CUSTOMER_PROFILE' | translate}}
                      <p-sortIcon [field]="'customerProfile'"></p-sortIcon>
                    </th>
                    <th class="text-center">{{ 'TYPE' | translate }}</th>
                    <th class="text-center" [pSortableColumn]="'reviewed'">
                        {{ 'REVIEWED' | translate }}
                        <p-sortIcon [field]="'reviewed'"></p-sortIcon>
                    </th>

                    <th class="text-center" [pSortableColumn]="'active'">
                        {{ 'STATUS' | translate }}
                        <p-sortIcon [field]="'active'"></p-sortIcon>
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer let-index>
                <tr [ngClass]="{'em-alert': islicenseExpired(customer)}">
                    <td pTooltip="Usuário do App Cliente">
                        <span style="display: flex; align-items: center; justify-content: center;">
                            <img src="assets/images/mobile.png" alt="" *ngIf="customer.userApp"
                                style="height: 30px; width: auto;">
                        </span>
                    </td>
                    <td>{{customer.name}}</td>
                    <td class="text-center" *ngIf="marinaCompanies?.length > 0" [pTooltip]="customer.companyName">
                        {{customer.marinaCompanyId ? getCompanyIndex(customer.marinaCompanyId) : 1}}</td>
                    <td class="text-center">{{customer.federalId}}</td>
                    <td>{{customer.email}}</td>
                    <td class="text-center">{{customer.phone}}</td>

                    <td class="text-center">
                        <p-dropdown [options]="groups" appendTo="body" styleClass="max-width"
                            [(ngModel)]="customer.groupId" [name]="customer.groupId" (onChange)="changeGroup(customer)">
                        </p-dropdown>
                    </td>
                    <td class="text-center">
                        <span>
                            {{customer.location}}
                        </span>
                    </td>
                    <td>{{customer.customerProfile | translate}}</td>
                    <td class="text-center">
                        <span>
                            {{customer.customerType}}
                        </span>
                    </td>
                    <td class="text-center">
                        <a href="javascript:;" [pTooltip]="customer.reviewedCustomer" class="badge badge-pill"
                            [ngClass]="{'success'  : customer.reviewed, 'danger': !customer.reviewed}"
                            tooltipPosition="bottom" (click)="updateReviewed(customer)">
                            {{ customer.reviewedCustomer}}
                        </a>
                    </td>
                    <td class="text-center">
                        <a href="javascript:;" [pTooltip]="customer.active ? 'Desativar' : 'Ativar'"
                            class="badge badge-pill" [ngClass]="{'success':customer.active, 'danger': !customer.active}"
                            tooltipPosition="bottom" (click)="updateStatus(customer)">
                            {{ customer.activeCustomer}}
                        </a>
                    </td>

                  <td>
                    <p-menu appendTo="body" #menu [model]="menuItems" [popup]="true" [showTransitionOptions]="'150ms'"
                            [hideTransitionOptions]="'150ms'"></p-menu>
                    <button (click)="menu.toggle($event); changeSelectedItem(customer)" pButton type="button"
                            icon="pi pi-ellipsis-v" class="p-button-text p-button-rounded p-button-lg"></button>
                  </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-fieldset>
