import { LicenseFiles } from "./license-files";

export class Licence {
    id: number;
    register: string;
    country: string;
    state: string;
    city: string;
    expiration: any;
    typeLicense: string;
    file: string;
    files: LicenseFiles[];

    constructor() {

    this.id = null;
    this.register = null;
    this.country = null;
    this.state = null;
    this.city = null;
    this.expiration = null;
    this.typeLicense = null;
    this.file = null;
    this.files = [];
    }
}
