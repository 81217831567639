<p-fieldset>
  <div class="subtitles">
    <fieldset>
      <legend>Legenda (Status da movimentação)</legend>
      <div class="badges">
        <div class="attempted"></div>
          Preparado
        <div class="partially-attempted"></div>
          Movimentando
        <div class="ordered"></div>
          Aguardando Preparo
      </div>
    </fieldset>
  </div>

  <p-header class="article-title">
    <span class="article-title">{{'OPERACIONAL > MOVIMENTAÇÕES' | translate}}</span>
    <div class="header-buttons">
      <button class="fab-button primary" appFiltersButton pTooltip="Exibir/Ocultar Filtros"
        tooltipPosition="left">
        <i class="pi pi-filter"></i>
      </button>
      <button class="fab-button danger" (click)="openModalUserInfo()" pTooltip="Informações"
        tooltipPosition="bottom" appHideMobile>
        <fa-icon [icon]="'address-card'" style="font-size: 18px;"></fa-icon>
      </button>
      <button class="fab-button primary" [routerLink]="['/single-order']" pTooltip="Pedido avulso"
        tooltipPosition="bottom" appHideMobile>
        <i class="pi pi-shopping-cart"></i>
      </button>
      <button class="fab-button primary" (click)="reload()" pTooltip="Atualizar lista" tooltipPosition="bottom">
        <i class="pi pi-refresh"></i>
      </button>
    </div>
  </p-header>

  <app-adjustment-view (changeEvent)="changeFilter($event)"></app-adjustment-view>

  <p-tabView styleClass="sling-check" (onChange)="onChangeSelectTab($event)" [activeIndex]="activeIndex">
        <p-tabPanel>
            <ng-template pTemplate="header">
                <!-- <i class="pi pi-arrow-down"></i> -->
                {{ 'Movimentações' | translate }}
            </ng-template>
            <app-movement-execution [filter]="filter" *ngIf="activeIndex === 0"></app-movement-execution>
        </p-tabPanel>
        <p-tabPanel>
            <ng-template pTemplate="header">
                <!-- <i class="pi pi-arrow-up"></i> -->
                {{ 'Pátio' | translate }}
            </ng-template>
            <app-movement-location-yard *ngIf="activeIndex === 1"></app-movement-location-yard>
        </p-tabPanel>
        <p-tabPanel>
            <ng-template pTemplate="header">
                <img src="assets/images/sea.png" alt="" style="height: 16px; width: auto;margin-right: 7px;">
                {{ 'Navegação' | translate }}
            </ng-template>
            <app-movement-location-navigation *ngIf="activeIndex === 2"></app-movement-location-navigation>
        </p-tabPanel>
  </p-tabView>
</p-fieldset>
