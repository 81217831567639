<div class="dialog-form">
  <div style="display: block; flex-direction: row;">
    <div class="form-group">
      <div class="form-control">
        <label for="quantity">Qtd excedente</label>
        <input
          pInputText
          type="number"
          disabled="true"
          id="quantity"
          [(ngModel)]="surplusAmount"
        />
      </div>
    </div>
    <br>
    <div class="form-group">
      <div class="form-control">
        <label for="amount">Qtd cobrada</label>
        <input
          pInputText
          type="number"
          id="amount"
          [(ngModel)]="ammountOrdered"
        />
      </div>
    </div>
  </div>

  <div class="buttons">
    <button class="easymarine danger" (click)="cancel()">{{ 'CANCEL' | translate }}</button>
    <button class="primary easymarine" (click)="save()">{{ 'SAVE' | translate }}</button>
  </div>
</div>
