<div class="subtitles" *ngIf="referenceMonth >=0">
  <fieldset>
    <legend>Legenda (Cores de fundo contratos de serviço)</legend>
    <div class="badges">
      <div class="badge" style="background-color: rgb(124, 245, 74);color: rgb(124, 245, 74);"></div>
      Vencem em {{getLabelMonth(0)}}
      <div class="badge" style="background-color: rgb(253, 221, 51);color: rgb(253, 221, 51);"></div>
      Vencem em {{getLabelMonth(1)}}
      <div class="badge" style="background-color: rgb(255 154 154);color: rgb(255 154 154);"></div>
      Contrato vencido
    </div>
  </fieldset>
  <fieldset>
    <legend>Legenda (Cores da letra)</legend>
    <div class="badges">
      <div class="badge" style="background-color: rgb(33, 150, 243);color: rgb(33, 150, 243);"></div>
      Ok
      <div class="badge" style="background-color: green;color: green;"></div>
      Ok, não possui valor à pagar
      <div class="badge" style="background-color: red;color: red;"></div>
      Sem serviço e/ou proprietário
    </div>
  </fieldset>
</div>
<div class="dialog-form">
  <form class="filters" appFilters>
    <div class="filter-row">
      <div class="form-control drop-down-select large" *ngIf="loggedMarina?.marinaCompanies?.length > 0">
        <label>Empresa</label>
        <p-dropdown *ngIf="marinaCompaniesList" placeholder="Selecione..." [options]="marinaCompaniesList"
                    appendTo="body" [(ngModel)]="company" name="company" (onChange)="filterCompanies()">
        </p-dropdown>
      </div>
      <div class="form-control">
        <label>Grupo</label>
        <p-multiSelect defaultLabel="Selecione..." [options]="groups" [style]="{height: '30px'}"
                       appendTo="body" [(ngModel)]="groupIds" name="groupIds"
                       selectedItemsLabel="{0} itens selecionados">
        </p-multiSelect>
      </div>
      <div class="form-control drop-down-select">
        <label>Mês de Referência</label>
        <p-dropdown placeholder="Selecione..." [options]="monthsReference" [(ngModel)]="referenceMonth"
                    appendTo="body" name="referenceMonth" (onChange)="cleanTuitions()">
        </p-dropdown>
      </div>
      <div class="form-control drop-down-select">
        <label>Ano de Referência</label>
        <p-dropdown placeholder="Selecione..." [options]="[{label: '2018', value: 2018},
                                        {label: '2019', value: 2019},
                                        {label: '2020', value: 2020},
                                        {label: '2021', value: 2021},
                                        {label: '2022', value: 2022},
                                        {label: '2023', value: 2023},
                                        {label: '2024', value: 2024},
                                        {label: '2025', value: 2025}]" (onChange)="cleanTuitions()" appendTo="body"
                    [(ngModel)]="referenceYear" name="referenceYear"
        >
        </p-dropdown>
      </div>
      <div class="form-control drop-down-select">
        <label>Situação</label>
        <p-dropdown placeholder="Selecione..." [options]="[{label: 'Todos', value:'allSituations' },
                                        {label: 'Gerados', value: 'generated'},
                                        {label: 'Não Gerados', value: 'notGenerated'}]" (onChange)="cleanTuitions()"
                    appendTo="body"
                    [(ngModel)]="referenceSituation" name="referenceSituation">
        </p-dropdown>
      </div>
      <div class="form-control drop-down-select large">
        <label>Serviços Periódicos</label>
        <p-multiSelect
          defaultLabel="Selecione..."
          appendTo="body"
          name="productsList"
          [maxSelectedLabels]="1"
          selectedItemsLabel="{0} itens selecionados"
          [options]="periodicalServiceProductList"
          [(ngModel)]="periodicalServiceProductListIds"
        >
        </p-multiSelect>
      </div>
      <div class="buttons">
        <div class="form-button">
          <button class="primary easymarine"
                  (click)="viewLaunch()">{{ 'Pré-Visualizar' | translate }}</button>
        </div>
        <div class="form-button" *ngIf="tuitionsTable.length > 0">
          <button class="success easymarine"
                  (click)="dialogMonthlySummary()">{{ 'Resumo' | translate }}</button>
        </div>
        <div class="form-button" *ngIf="selectedTuitions.length > 0">
          <button class="danger easymarine"
                  (click)="dialogGenerateTuition()">{{ 'Gerar pedidos' | translate }}</button>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="container table">
  <p-table [value]="tuitionsTable" 
    [paginator]="true" [rows]="numberOfRows" [rowsPerPageOptions]="[5,10,20,50,100, 200, 500]" [responsive]="true"
    #tt [globalFilterFields]="['boatName']" autoLayout="true"
           styleClass="easy-table center-header" [(selection)]="selectedTuitions">
    <ng-template pTemplate="caption">
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText size="50" placeholder="Embarcação, cliente ou grupo"
                           [(ngModel)]="globalFilter" (input)="filterGlobal()" style="width:auto">
                </span>
    </ng-template>

    <ng-template pTemplate="header">
      <tr *ngIf="loggedMarina?.marinaCompanies?.length > 0">
        <th colspan="2"></th>
        <th colspan="2" class="text-center">Total selecionado</th>
        <th *ngIf="loggedMarina?.marinaCompanies?.length === 0">
                        <span>
                            {{getTotalSelected(loggedMarina)}}
                        </span>
        </th>
        <th colspan="12" *ngIf="loggedMarina?.marinaCompanies?.length === 0"></th>
        <th colspan="11" *ngIf="loggedMarina?.marinaCompanies?.length > 0"></th>
      </tr>
      <tr *ngIf="loggedMarina?.marinaCompanies?.length > 0">
        <th colspan="2">
          {{loggedMarina.companyName}}
        </th>
        <th class="text-right" colspan="2">
          {{getTotalSelected(loggedMarina) | number: '1.2-2'}}
        </th>
        <th colspan="6" *ngIf="loggedMarina?.marinaCompanies?.length > 0"></th>
        <th colspan="5" *ngIf="loggedMarina?.marinaCompanies?.length === 0"></th>
        <th class="text-right">{{getTotalCompany(loggedMarina) | number: '1.2-2'}}</th>
        <th class="text-right">{{getDiscountCompany(loggedMarina) | number: '1.2-2'}}</th>
        <th class="text-right">{{getValueCompany(loggedMarina) | number: '1.2-2'}}</th>
        <th colspan="2"></th>
      </tr>
      <tr *ngFor="let company of loggedMarina?.marinaCompanies">
        <th colspan="2">
          {{company.companyName}}
        </th>
        <th class="text-right" colspan="2">
          {{getTotalSelected(company) | number: '1.2-2'}}
        </th>
        <th colspan="6" *ngIf="loggedMarina?.marinaCompanies?.length > 0"></th>
        <th colspan="5" *ngIf="loggedMarina?.marinaCompanies?.length === 0"></th>
        <th class="text-right">{{getTotalCompany(company) | number: '1.2-2'}}</th>
        <th class="text-right">{{getDiscountCompany(company) | number: '1.2-2'}}</th>
        <th class="text-right">{{getValueCompany(company) | number: '1.2-2'}}</th>
        <th colspan="2"></th>
      </tr>
      <tr>
        <th></th>
        <th class="text-center">{{tuitionsTable.length}} serviços de clientes</th>
        <th colspan="8" *ngIf="loggedMarina?.marinaCompanies?.length > 0"></th>
        <th colspan="7" *ngIf="loggedMarina?.marinaCompanies?.length === 0"></th>
        <th class="text-right">{{totalValue | number: '1.2-2'}}</th>
        <th class="text-right">{{totalDiscount | number: '1.2-2'}}</th>
        <th class="text-right">{{total | number: '1.2-2'}}</th>
        <th colspan="2"></th>
      </tr>

      <tr class="thead">
        <th class="text-center" *ngIf="isMonthlyPayment()">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th class="text-center" *ngIf="!isMonthlyPayment()">
          <p-checkbox name="tuitionNotSelected" value="tuitionNotSelected" [disabled]="true">
          </p-checkbox>
        </th>
        <th [pSortableColumn]="'customerName'">
          Cliente
          <p-sortIcon [field]="'customerName'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'beneficiaryName'">
          Beneficiário
          <p-sortIcon [field]="'beneficiaryName'"></p-sortIcon>
        </th>
        <th *ngIf="loggedMarina?.marinaCompanies?.length > 0">
          Emp.
        </th>
        <th [pSortableColumn]="'pricingType'">
          Preço
          <p-sortIcon [field]="'pricingType'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'observation'" colspan="2">
          Obs
          <p-sortIcon [field]="'observation'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'contractStartDate'">
          Início
          <p-sortIcon [field]="'contractStartDate'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'contractEndDate'">
          Fim
          <p-sortIcon [field]="'contractEndDate'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'productName'">
          Serviço
          <p-sortIcon [field]="'productName'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'value'">
          Valor
          <p-sortIcon [field]="'value'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'discount'">
          Desc
          <p-sortIcon [field]="'discount'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'total'">
          Total
          <p-sortIcon [field]="'total'"></p-sortIcon>
        </th>
        <th [pSortableColumn]="'generate'">
          P
          <p-sortIcon [field]="'generate'"></p-sortIcon>
        </th>
        <th>

        </th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-tuition>
      <tr [ngStyle]="{'color': getFontColor(tuition), 'background-color': getBackgroundTuition(tuition)}">
        <td class="text-center" *ngIf="isEnableSelect(tuition)">
          <p-tableCheckbox [value]="tuition"></p-tableCheckbox>
        </td>
        <td *ngIf="!isEnableSelect(tuition)">
          <p-checkbox name="tuition.customerId + '' + tuition.productId"
                      value="tuition.customerId + '' + tuition.productId" [disabled]="true"></p-checkbox>
        </td>
        <td class="font-tuition customer-data">
          <div class="customer-name">
            <i class="pi cursor-pointer" [ngClass]="{'pi-angle-down': !tuition.show, 'pi-angle-up': tuition.show}"></i>
            &nbsp;
            <span class="cursor-pointer"
                  (click)="tuition.show = !tuition.show">{{tuition.customerName}}</span>
          </div>
<!--          <div class="boat-dialog">-->
<!--            <i style="float: right; font-size: 23px; cursor:pointer" class="pi pi-external-link"-->
<!--               (click)="openDialogBoatForm(tuition.boatId)" pTooltip="Visualizar embarcação"-->
<!--               tooltipPosition="bottom"></i>-->
<!--          </div>-->
        </td>
        <td *ngIf="tuition.beneficiaryName != null" class="font-tuition customer-data">{{tuition.beneficiaryName}}</td>
        <td *ngIf="tuition.beneficiaryId == null" class="font-tuition customer-data">{{tuition.customerName}}</td>

        <td *ngIf="loggedMarina?.marinaCompanies?.length > 0" class="font-tuition">
          {{getCompany(tuition)}}</td>
        <td class="font-tuition">{{tuition?.getPriceTypeName}}</td>
        <td [pTooltip]="tuition.observation" tooltipPosition="bottom" colspan="2">{{tuition.observation ? 'Sim' : null}}</td>
        <td class="font-tuition">{{tuition.contractStartDate | date:'dd/MM/yy'}}</td>
        <td class="font-tuition">{{tuition.contractEndDate | date:'dd/MM/yy'}}</td>
        <td class="font-tuition">{{tuition.productName}}</td>
        <td class="font-tuition text-right">{{tuition.value | number:'1.2-2'}}</td>
        <td class="font-tuition text-right">{{tuition.discount | number:'1.2-2'}}</td>
        <td class="font-tuition text-right" [ngStyle]="{'color': tuition.total >= 0 ? '#2196F3' : 'red'}">
          {{tuition?.isPartial ? 'P | ' : ''}}{{tuition.total | number:'1.2-2'}}
        </td>
        <td class="font-tuition text-center">
          <i *ngIf="tuition.generate" class="pi pi-check" style="color: #155724"></i>
        </td>
        <td>
<!--          <div class="table-action"-->
<!--               *ngIf="!(isInvalidCustomer(tuition) || this.isContractEndDateStatusExpired(tuition))"-->
<!--               (click)="generateMonthRange(tuition)">-->
<!--            <i class="pi pi-calendar primary"-->
<!--               pTooltip="Gerar mensalidades deste cliente para um período de tempo "-->
<!--               tooltipPosition="bottom"></i>-->
<!--          </div>-->
        </td>
      </tr>
      <tr [hidden]="!tuition.show">
        <td [attr.colspan]="loggedMarina?.marinaCompanies?.length > 0 ? 15 : 14">
          <p-table [value]="[tuition.tuitionCustomerView]" autoLayout="true">
            <ng-template pTemplate="header">
              <tr>
                <th>Cliente</th>
                <th *ngIf="loggedMarina?.marinaCompanies?.length > 0">Empresa</th>
                <th>Rateio</th>
                <th>Valor</th>
                <th>Vencimento</th>
                <th>Grupo</th>
                <th>Pedido</th>
                <th>Fatura</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-customer>
              <tr>
                <td>{{customer.name}}</td>
                <td *ngIf="loggedMarina?.marinaCompanies?.length > 0">
                  {{getCompanyIndex(customer.companyId)}}</td>
                <td class="text-center">{{customer.quota | number:'1.2-2'}}%</td>
                <td class="text-right">{{customer.value | number:'1.2-2'}}</td>
                <td class="text-center">{{customer.dueDate | date:'dd/MM/yy'}}</td>
                <td>{{customer.groupName}}</td>
                <td class="text-center">{{customer.orderNumber ? customer.orderNumber : '----'}}</td>
                <td class="text-center">{{customer.orderNumber ? customer.invoiceNumber : '----'}}</td>
              </tr>
            </ng-template>
          </p-table>
          <div *ngIf="tuition.tuitionCustomerView === null">
            <div style="color: red" class="col-12">Sem proprietário</div>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
