export enum ShipType {
    Motorboat = 'Motorboat',
    Sailboat = 'Sailboat',
    JetSki = 'JetSki',
    InflatableBoat = 'InflatableBoat',
    Yatch = 'Yatch',
    Catamaran = 'Catamaran',
    Schooner = 'Schooner',
    Trawler = 'Trawler',
    Kayak = 'Kayak',
    Canoe = 'Canoe'

  }
