<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{'ACCESSORY' | translate}}</span>
    </p-header>

    <form (submit)="saveAccesoryData(false)" class="dialog-form">
        <div class="form-group accessories">
            <div class="accessoryBoat-card" *ngFor="let accessoryBoat of boatAccessories">
                <label [for]="'accessoryBoat.accessory' + accessoryBoat.accessory.id">

                    <div class="text-center accessoryBoat-card-image">
                        <img class="imagem" src="{{accessoryBoat.accessory.urlPhoto}}" />
                    </div>
    
                    <div class="accessoryBoat-card-description">
                        {{accessoryBoat.accessory.name}}
                    </div>
                </label>
                <p-checkbox [inputId]="'accessoryBoat.accessory' + accessoryBoat.accessory.id"  [disabled]="isASlingEdition" [name]="'accessoryBoat.accessory' + accessoryBoat.accessory.id" [(ngModel)]="accessoryBoat.checked" binary="true">
                </p-checkbox>
            </div>
            <div class="form-control" *ngIf="boatAccessories.length == 0">
                <h4>Barco não possui acessório cadastrado!</h4>
            </div>
        </div>
        <p-footer>
            <div class="form-group">
                <div class="buttons">
                    <button class="easymarine danger" type="button"
                        (click)="back()">{{ 'Voltar' | translate }}</button>
                    <button [disabled]="!isToAllowSave" class="easymarine" type="submit">
                        {{ 'NEXT' | translate }}
                    </button>
                </div>
            </div>
        </p-footer>
    </form>
</p-fieldset>