<div class="totem">
    <div class="header">
        <div class="form-controls">
            <div class="form-control">
                <label style="font-size: x-large; color: white">{{ 'BOAT' | translate  }}</label>
                <p-dropdown placeholder="Selecione..." [style]="{'font-size': 'x-large'}" [filter]="true" appendTo="body"
                    [options]="boatsFilter" [(ngModel)]="boatSelected" name="boatSelected" (onChange)="loadCustomers()">
                </p-dropdown>
            </div>
            <div class="form-control">
                <label style="font-size: x-large; color: white">{{ 'CUSTOMER' | translate  }}</label>
                <p-dropdown placeholder="Selecione..." [style]="{'font-size': 'x-large'}" [filter]="true"
                    [options]="customers" (focus)="loadCustomers()" [(ngModel)]="customerSelected" appendTo="body"
                    name="customerSelected" (onChange)="selectCustomer()"></p-dropdown>
            </div>
            <app-filter-billing-company
              class="form-control" *ngIf="slingConfig && hasFinancialMultipleByProductFilter(slingConfig)"
              style="justify-content: space-between;"
              [slingConfig]="slingConfig"
              (filterChange)="filterBillingCompanyByProductChange($event)"
              [filter]="filterBillingCompanyByProduct"
              >
            </app-filter-billing-company>
        </div>
        <div *ngIf="customerFilter.boats.length>0" class="debits-boats">
            <h3>Embarcações deste cliente: </h3>
            <span *ngFor="let boat of customerFilter.boats; let i = index;">
                {{i+1 < customerFilter.boats.length ? boat.name + ' -' : boat.name}}
            </span>
        </div>
        <div class="buttons">
            <div class="icon">
                <a [routerLink]="'/app/operational/checking'" class="close-button">
                    <i class="pi pi-times-circle"></i>
                </a>
            </div>
        </div>
    </div>

    <div class="body" *ngIf="orders.length > 0 || invoices?.length > 0">
        <div class="orders-table">
            <div class="header">
                <h3 class="article-title">Pedido(s) não faturado(s)</h3>
                <div class="buttons">
                    <div class="text-right" *ngIf="orders.length > 0">
                        <button class="easymarine warning" [disabled]="!hasSelected()"
                            (click)="confirmGenerateOnlyInvoice()">Faturar</button>
                    </div>
                    <div class="text-right" *ngIf="orders.length > 0">
                        <button class="easymarine primary" [disabled]="!hasSelected()"
                            (click)="confirmGenerateInvoiceAndPaid()">Pagar</button>
                    </div>
                </div>
            </div>
            <mat-card-content *ngIf="orders.length == 0">
                <div class="alert alert-warning text-center">
                    Nenhum pedido não faturado encontrado!
                </div>
            </mat-card-content>
            <div *ngIf="orders.length > 0" class="table">
                <div class="totals">
                    <span>Total do(s) cliente(s) não faturado(s):
                        {{getTotalOrderCustomer() | number:'1.2-2'}}</span>
                    <span>Total de pedido(s) não faturado(s):
                        {{getTotalOrders() | number :'1.2-2'}}</span>
                </div>
                <p-table #orderTable [value]="orders" [paginator]="true" [rows]="4" resetPageOnSort="true"
                    class="primeTable" [globalFilterFields]="['id','boatName','valueCurrency']" autoLayout="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="text-center">
                            </th>
                            <th class="text-center" [pSortableColumn]="'date'">
                                Data
                                <p-sortIcon [field]="'date'"></p-sortIcon>
                            </th>
                            <th [pSortableColumn]="'valueCurrency'">
                                Valor
                                <p-sortIcon [field]="'valueCurrency'"></p-sortIcon>
                            </th>
                            <th [pSortableColumn]="'boatName'">
                                Embarcação
                                <p-sortIcon [field]="'boatName'"></p-sortIcon>
                            </th>
                            <th>Cliente</th>
                            <th>Rateio</th>
                            <th>Valor</th>
                            <th>Itens</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-order>
                        <tr>
                            <td>
                                <p-checkbox [(ngModel)]="order.selected" [name]="'order' + order.id"></p-checkbox>
                            </td>
                            <td class="text-center">
                                <div>
                                    {{order.date | date: 'dd/MM/yy' }}
                                </div>
                            </td>
                            <td class="text-right colorValue">{{order.value | number:'1.2-2'}}</td>
                            <td>{{order.boatName}}</td>
                            <td colspan="3">
                                <p-table class="primeSubTable" [value]=order.customers>
                                    <ng-template pTemplate="body" let-customer>
                        <tr>
                            <td>{{customer.name}}</td>
                            <td class="text-center colorValue">{{customer.quota | number:'1.0-0'}}%</td>
                            <td class="text-right colorValue">{{customer.value | number:'1.2-2'}}</td>
                        </tr>
                    </ng-template>
                </p-table>
                </td>
                <td>
                    <button class="success easymarine" (click)="openDialogItensRequest(order)">
                        <i class="pi pi-bars"></i>
                    </button>
                </td>
                </tr>
                </ng-template>
                </p-table>
            </div>
        </div>
        <div class="invoices-table">
            <div class="header">
                <div>
                    <h3 class="article-title" style="color: white; margin-left: -15px;">Fatura(s) pendente(s) </h3>
                </div>
                <div>
                    <span *ngIf="invoices.length>0" style="font-size:18px;color:white">
                        Total devido: {{getTotalInvoices() | number:'1.2-2'}}
                    </span>
                </div>
            </div>
            <mat-card-content *ngIf="invoices.length == 0">
                <div class="alert alert-warning text-center">
                    Nenhuma fatura pendente encontrada!
                </div>
            </mat-card-content>
            <div *ngIf="invoices.length > 0" class="table">
                <p-table #invoiceTable [value]="invoices" [paginator]="true" [rows]="4" resetPageOnSort="true"
                    class="primeTable" [globalFilterFields]="['customer.name']" autoLayout="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th [pSortableColumn]="'id'">
                                Fatura
                                <p-sortIcon [field]="'id'"></p-sortIcon>
                            </th>
                            <th class="text-center" [pSortableColumn]="'emissionDate'">
                                Emissão
                                <p-sortIcon [field]="'emissionDate'"></p-sortIcon>
                            </th>
                            <th class="text-center" [pSortableColumn]="'dueDate'">
                                Vencimento
                                <p-sortIcon [field]="'dueDate'"></p-sortIcon>
                            </th>
                            <th class="text-right" [pSortableColumn]="'value'">
                                Total
                                <p-sortIcon [field]="'value'"></p-sortIcon>
                            </th>
                            <th class="text-right" [pSortableColumn]="'pendingValue'">
                                Pendente
                                <p-sortIcon [field]="'pendingValue'"></p-sortIcon>
                            </th>
                            <th class="text-right">
                                Desconto
                            </th>
                            <th class="text-right" [pSortableColumn]="'totalPaid'">
                                Pago
                                <p-sortIcon [field]="'totalPaid'"></p-sortIcon>
                            </th>
                            <th class="text-center"></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-invoice>
                        <tr>
                            <td>{{invoice.invoiceNumber}}</td>
                            <td class="text-center">{{invoice.emissionDate | date: 'dd/MM/yyyy'}}</td>
                            <td class="text-center">{{invoice.dueDate | date: 'dd/MM/yyyy'}} </td>
                            <td class="text-right" style="color:#7575F6">{{invoice.value | number:'1.2-2'}}</td>
                            <td class="text-right" style="color:#FF6501">{{invoice.pendingValue | number:'1.2-2'}}
                            </td>
                            <td class="text-right" style="color:#FF6501">{{invoice.discount | number:'1.2-2'}}</td>
                            <td class="text-right" style="color:#40CE40">{{invoice.totalPaid | number:'1.2-2'}}</td>
                            <td class="text-center">
                                <button class="easymarine success" type="button" (click)="invoicePaidDialog(invoice)">
                                    <i class="pi pi-dollar"></i>
                                </button>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
</div>
