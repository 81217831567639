import { filter } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { FranchiseFilter } from 'src/app/models/dtos/franchise/franchise-filter';
import { environment } from 'src/environments/environment';
import { FranchiseFilterSimple } from 'src/app/models/dtos/franchise/franchise-filter-simple';
import { Franchise } from 'src/app/models/dtos/Franchise';

@Injectable({
  providedIn: 'root',
})
export class FranchiseServiceService {
  constructor(private http: HttpClient) {}

  private readonly API_URL = environment.apiHost + '/api-movement/secure/franchise';

  findByfilter(filter: FranchiseFilter): Observable<Franchise> {

    // let params = new HttpParams();

    // if (!filter.sort || !filter.direction) {
    //     filter.sort = 'name';
    //     filter.direction = 'ASC';
    // }
    // Object.keys(filter).forEach((key) => {
    //     if (filter[key] != null) {
    //         params = params.set(key, filter[key]);
    //     }
    // });
    // params = params.set('sort', filter.sort.concat(',').concat(filter.direction));
    // params = params.set('page', filter.page.toString());
    // params = params.set('size', filter.size.toString());
    return this.http
      .post(
        this.API_URL + '/find-by-filter',
        filter,{}
      )
      .pipe(map((response: any) => response));
  }

  findHistory(filter: FranchiseFilter): Observable<any> {
    console.log(filter);
    // let params = new HttpParams();

    // if (!filter.sort || !filter.direction) {
    //     filter.sort = 'name';
    //     filter.direction = 'ASC';
    // }
    // Object.keys(filter).forEach((key) => {
    //     if (filter[key] != null) {
    //         params = params.set(key, filter[key]);
    //     }
    // });
    // params = params.set('sort', filter.sort.concat(',').concat(filter.direction));
    // params = params.set('page', filter.page.toString());
    // params = params.set('size', filter.size.toString());
    return this.http
      .post(
        this.API_URL + '/boat',
        filter,{}
      )
      .pipe(map((response: any) => response));
  }

  cancelFranchise(franchise: any, active:boolean): Observable<any> {
    return this.http.post(this.API_URL + '/cancel/'+franchise +'/'+active,null).pipe(
      map((response: any) => response ));
  }

  getView(referenceDate, filter:FranchiseFilterSimple): Observable<any>{
    return this.http.post(this.API_URL + '/view/'+referenceDate, filter).pipe(map((response: any) => response));
  }

  createOrder(order: {
    reference: string,
    boatId: number,
    quantity: number,
  }): Observable<any>{
    return this.http.post(`${environment.apiHost}/api-orders/secure/marina-order/franchise`, order).pipe(map((response: any) => response));
  }

  unexemptOrder(order: {
    reference: string,
    boatId: number,
    quantity: number,
  }): Observable<any>{
    return this.http.post(`${environment.apiHost}/api-orders/secure/marina-order/franchise/exempt/cancel`, order).pipe(map((response: any) => response));
  }
  exemptOrder(order: {
    reference: string,
    boatId: number,
    quantity: number,
  }): Observable<any>{
    return this.http.post(`${environment.apiHost}/api-orders/secure/marina-order/franchise/exempt`, order).pipe(map((response: any) => response));
  }

  cancelFranchiseToAmmount(boatId:number, referenceDate:string, ammount:number): Observable<any> {
    const body = {
      boatId:boatId,
      referenceDate:referenceDate,
      ammount:ammount
    }
    console.log(body);
    return this.http.put(this.API_URL + '/cancel/ammount',body).pipe(
      map((response: any) => response ));
  }

  createOrderLot(orders: any[]): Observable<any>{
    return this.http.post(`${environment.apiHost}/api-orders/secure/marina-order/franchise/lot`, orders).pipe(map((response: any) => response));
  }
}
