import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { APPCONFIG } from 'src/app/config';
import { ShortUrlDTO } from 'src/app/models/dtos/short-url.dto';
import { ShortUrlService } from 'src/app/services/short-url.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sling-share-log',
  templateUrl: './sling-share-log.component.html',
  styleUrls: ['./sling-share-log.component.scss']
})
export class SlingShareLogComponent implements OnInit {

  urlsTable: ShortUrlDTO[] = [];
  selected = [];
  totalCount: number;
  msg: string;
  numberOfRows;
  easyWebPage: string;

  constructor(
    private shortUrlService: ShortUrlService,
    private slingConfigService: SlingConfigService,
    private spinner: NgxSpinnerService,
  ) { this.easyWebPage = APPCONFIG.easyWebPage + '#/url/';}

  async ngOnInit(): Promise<void> {
    this.spinner.show();
    await this.loadSlingConfig();
    await this.loadAll();
    this.spinner.hide();
  }

  async loadAll(): Promise<void> {
    return new Promise<void>(async resolve => {
      this.shortUrlService.findTable().subscribe(
        async (data) => {
          this.urlsTable = data;
          this.totalCount = this.urlsTable.length;
          resolve();
        },
        async (error) => {
          console.error(error);
          resolve();
        }
      );
    });
  }

  deleteUrl(id: number): void {
    this.shortUrlService.delete(id).subscribe(
      (i: any) => {
        const copy = this.urlsTable.filter((u) => u.id !== i);
        this.urlsTable = copy;
      });
  }

  deleteConfirm(url): void {
    Swal.fire({
      text: 'Deseja realmente deletar a url ' + url.code + '?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((isConfirm) => {
      if (isConfirm.value) {
        this.deleteUrl(url.id);
      }
    });
  }

  async loadSlingConfig(): Promise<void> {
    return new Promise<void>(async resolve => {
      this.slingConfigService.getSlingConfig().subscribe(
        (data) => {
          const slingConfig = data[0];
          if (slingConfig != null) {
            this.numberOfRows = slingConfig.numberOfRecordsPerPage;
          } else {
            this.numberOfRows = 10;
          }
          resolve();
        },
        (error) => {
          console.error(error);
          resolve();
        });
    });
  }

}
