<div class="dialog-form">
    <form (submit)="parcelInvoice()">
        <div class="form-group">
            <label for="parcelas">Parcelas:&nbsp;</label>
            <input type="number" [(ngModel)]="parcels" (blur)="createParcels()" name="parcelas" id="parcelas"
                style="text-align: center;max-width: 50px;" pInputText>
            <label for="billingPortfolioId">Carteira de cobrança:&nbsp;</label>
            <p-dropdown [options]="billingPortfolioList" name="billingPortfolioId" appendTo="body"
                [(ngModel)]="invoiceQuotaDTO.billingPortfolioId"></p-dropdown>

        </div>
        <br>
        <div class="form-group flex-end">
            <label for="parcelas" style="text-align: right; font-weight: bold;">Total pendente:&nbsp;</label>
            <span>{{invoice?.pendingValue | number:'1.2-2'}}</span>
        </div>
        <br>
        <div class="parcel-table">
            <div *ngFor="let parcel of invoiceQuotaDTO.parcelas;" class="flex-row parcels">
                <div class="parcel">
                    <label [for]="'numero'+parcel.numero">Parcela</label>
                    <input type="number" pInputText [(ngModel)]="parcel.numero" min="1" disabled
                        [name]="'numero' + parcel.numero" required [id]="'numero' + parcel.numero"
                        style="text-align: center;">
                </div>
                <div class="parcel">
                    <label [for]="'valor' + parcel.numero">Valor</label>
                    <p-inputNumber required mode="decimal" [(ngModel)]="parcel.valor" [name]="'valor' + parcel.numero"
                        [id]="'valor' + parcel.numero" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
                </div>
                <div class="parcel flex25">
                    <label [for]="'dataVencimento' + parcel.numero">Vencimento</label>
                    <p-calendar [(ngModel)]="parcel.dataVencimento" [name]="'dataVencimento' + parcel.numero" appendTo="body"
                        [id]="'dataVencimento' + parcel.numero" dateFormat="dd/mm/yy" required [showIcon]="true">
                    </p-calendar>
                </div>
            </div>
        </div>
        <div class="form-group total-parcels flex-end">
            <label style="text-align: right; font-weight: bold;">Diferença:&nbsp;</label>
            <span [ngClass]="{'negative' : getMissingValue() < 0}"
                style="color: green">{{getMissingValue() | number:'1.2-2'}}</span>
        </div>
        <div class="buttons">
            <button type="button" class="easymarine danger"
                (click)="dialogRef.close()">{{ 'CANCEL' | translate }}</button>
            <button type="submit" [disabled]="!isValid()" class="primary easymarine">{{ 'PARCEL' | translate }}</button>
        </div>
    </form>
</div>