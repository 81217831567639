import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OrderHistoricService } from 'src/app/services/marina-order/order-historic.service';

@Component({
  selector: 'app-order-historic',
  templateUrl: './order-historic.component.html',
  styleUrls: ['./order-historic.component.scss']
})
export class OrderHistoricComponent implements OnInit {

  marinaOrder: any;
  historic: any[];

  constructor(
    public dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private  orderHistoricService: OrderHistoricService
  ) {
    this.marinaOrder = this.config.data.marinaOrder
   }

  ngOnInit() {
    this.orderHistoricService.findHistoricByMarinaOrder(this.marinaOrder).subscribe((response) => {
      this.historic = response;
    });

  }

}
