<div class="table">
  <form *ngIf="form" [formGroup]="form" (submit)="saveAll()">
    <div formArrayName="days">
      <p-table *ngIf="days" [value]="days.controls" autoLayout="true">
          <ng-template pTemplate="header">
              <tr>
                  <th rowspan="2">
                      Dia Semana
                  </th>
                  <th colspan="2" class="text-center">Horário Marina</th>
              </tr>
              <tr>
                  <th>Abertura</th>
                  <th>Fechamento</th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-time let-i="rowIndex">
              <tr [formGroupName]="i">
                  <td>
                      <p-checkbox formControlName="inOperation"
                        [binary]="true"
                        [name]="'inOperation' + time.id">
                      </p-checkbox>
                      {{ time.value.dayWeek | translate }}
                  </td>
                  <td>
                      <input pInputText type="time" tooltipPosition="bottom"
                          [ngClass]="{'danger': ((!time.operationStart || time.operationStart === '') && (time.inOperation))}"
                          pTooltip="Horário de abertura da marina"
                          [name]="'operationStart' + time.id"
                          formControlName="operationStart"/>
                  </td>
                  <td>
                      <input pInputText type="time" tooltipPosition="bottom"
                          pTooltip="Horário de fechamento da marina" [ngClass]="{'danger': ((!time.operationEnd || time.operationEnd === '') &&
                            (time.inOperation))}"
                          [name]="'operationEnd' + time.id"
                          formControlName="operationEnd"/>
                  </td>
                </tr>
                <tr *ngIf="time?.errors">
                  <td colspan="4">
                    <app-messages *ngIf="time?.errors?.NotIsBeforeTime"
                      (close)=" cancel()"
                      [titleMessage]="'Erro(' + (time.value.dayWeek | translate) + '):'"
                      [textMessage]="'A hora de abertura deve ser maior que a de fechamento!'">
                    </app-messages>
                  </td>
                </tr>
          </ng-template>
      </p-table>
      <div class="buttons">
        <button class="primary easymarine" type="submit" [disabled]="isNotChanged() || !form.valid">Salvar</button>
      </div>
    </div>
    <p-message severity="warn" class="unsaved-changes" *ngIf="!isNotChanged()" text="Existem alterações que não foram salvas."></p-message>
  </form>
</div>
