<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">Histórico de Produtos</span>
        <div class="header-buttons">
            <button class="fab-button primary" (click)="exportTable()" label="Exportar Excel"
            pTooltip="A exportação será de acordo com os filtros selecionados" tooltipPosition="left">
            <i class="pi pi-file"></i>
        </button> 
        <button class="fab-button primary" appFiltersButton pTooltip="Exibir/Ocultar Filtros" tooltipPosition="left">
            <i class="pi pi-filter"></i>
        </button>
    </div>

    </p-header>

    <form class="filters" (submit)="find()" appFilters>
        <div class="filter-row">
            <div class="form-control">
                <label>Data De / Até</label>
                <p-calendar dateFormat="dd/mm/yy" name="dateRange" [monthNavigator]="true" appendTo="body"
                    [yearNavigator]="true" [yearRange]="'2000:2055'" selectionMode="range" rPCalendarMask [(ngModel)]="dateRange"
                    [showIcon]="true" i18n>
                </p-calendar>
            </div>
            <div class="form-control">
                <label>{{ 'PRODUCTS' | translate }}</label>
                <p-dropdown placeholder="Selecione..." [filter]="true" [options]="productList" optionValue="id" optionLabel="name" appendTo="body"
                    [(ngModel)]="filter.productId" name="selectedProductId">
                </p-dropdown>
            </div>
            <div class="form-control">
                <label>{{ 'Tipo' | translate }}</label>
                <p-dropdown placeholder="Selecione..." [filter]="true" [options]="typesList" optionValue="type" optionLabel="name" appendTo="body" (onChange)="onChangeType($event)"
                    [(ngModel)]="this.typeFilter" name="selectedMovementType">
                </p-dropdown>
            </div>
        </div>
        <div class="button-row">
            <!-- <div class="button-div">
                <div class="form-button m-2">
                    <button class="success easymarine" type="button"
                    (click)="exportTable()">Exportar</button>
                </div>
            </div> -->
            <div class="form-button">
                <button type="submit" class="primary easymarine">{{ 'FIND' | translate }}</button>
            </div>
        </div>
    </form>

    <div class="table">
        <p-table #tt [value]="movementList" [resizableColumns]="true" [autoLayout]="true" [paginator]="true"
        [rows]="numberOfRows" [pageLinks]="3" [rowsPerPageOptions]="isDialog? null : [10,20,30,50,100]">
            <ng-template pTemplate="header">
                <tr>
                    <th class="text-center">Nº</th>
                    <th class="text-center" *ngIf="nfeProductEnable">Nota Fiscal</th>
                    <th class="text-center">Data</th>
                    <th class="text-center">Origem</th>
                    <th class="text-center">Tipo</th>
                    <th class="text-center">Embarcação</th>
                    <th class="text-center">Nome</th>
                    <th class="text-center">Produto</th>
                    <th class="text-center">Quantidade</th>
                    <th class="text-center">Valor</th>
                    <th class="text-center">Total</th>
                    <th class="text-center">Estoque</th>
                    <th class="text-center">Valor</th>
                    <!-- <th>Log</th> -->
                </tr>
                <tr>
                    <th class="text-center"></th>
                    <th class="text-center" *ngIf="nfeProductEnable"></th>
                    <th class="text-center"></th>
                    <th class="text-center"></th>
                    <th class="text-center"></th>
                    <th class="text-center"></th>
                    <th class="text-center"></th>
                    <th class="text-center"></th>
                    <th class="text-center"></th>
                    <th class="text-center"></th>
                    <th class="text-center">{{this.totalValue | number: '1.2-2'}}</th>
                    <th class="text-center"></th>
                    <th class="text-center"></th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-productMovement>
                <tr>
                    <td class="text-center">{{productMovement?.orderNumber}}</td>
                    <td class="text-center" *ngIf="nfeProductEnable">{{nfeHandler(productMovement)}}</td>
                    <td class="text-center">{{formatDate(productMovement.dateOperation)}}</td>
                    <td class="text-center">{{productMovement.origin | translate}}</td>
                    <td class="text-center">{{productMovement.type | translate}}</td>
                    <td class="text-center">{{productMovement.boatName}}</td>
                    <td class="text-center">{{productMovement.customerName}}</td>
                    <td class="text-center">{{productMovement.name}}</td>
                    <td class="text-center">{{productMovement.qtdOperation | number }}</td>
                    <td class="text-center">{{productMovement.itemValue | number:'1.2-2'}}</td>
                    <td class="text-center">{{productMovement.totalValue | number:'1.2-2'}}</td>
                    <td class="text-center">{{productMovement.stock | number}}</td>
                    <td class="text-center">{{productMovement.stockValue| number:'1.2-2'}}</td>
                    <!-- <td>
                        <a class="table-action" pTooltip="Visualizar Log" tooltipPosition="bottom" (click)="openLog(productMovement?.orderNumber)" *ngIf="productMovement?.orderNumber != null">
                            <i class="pi pi-eye"></i>
                        </a>
                    </td> -->
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="7">
                        Nenhuma saida encontrada
                    </td>
                </tr>
            </ng-template>

        </p-table>
    </div>
</p-fieldset>
