export class FranchiseFilter {
  boatId:number;
  customerId:number;
  createDateStart:Date;
  createDateEnd:Date;
  orderNumberStart:string;
  orderNumberEnd:string;
  active:boolean;
  page:number;
  size:number;
  sort:string;
  direction:string;
  constructor() {
    this.boatId = null;
    this.customerId = null;
    this.createDateStart = null;
    this.createDateEnd = null;
    this.orderNumberStart = null;
    this.orderNumberEnd = null;
    this.active = null;
    this.page = null;
    this.size = null;
    this.sort = null;
    this.direction = null;
  }
}
