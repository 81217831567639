import { UntypedFormGroup } from "@angular/forms";
import moment from "moment";

export const getCustomValidatorsMovementNavigationPlan = (): ((group: UntypedFormGroup) => null | any)[] => {
  return [
    returnDatetimeAfterExecutionDatetime
  ]
}

const returnDatetimeAfterExecutionDatetime = (group: UntypedFormGroup) => {
  const form = group.value
  if (form.executionDate && form.returnTime) {
    const executionDate = moment(form.executionDate);
    const returnTime = moment(form.returnTime);
    if (executionDate.isSameOrAfter(returnTime)) {
      return { returnTimeNotBeforeExecutionError: true }
    }
  }
  return null;
}
