<div class="dialog-form">
  <div class="table mt-2">
    <p-table
      #securitiesPropertyHistoric
      [value]="listHistoric"
      [paginator]="true"
      [rows]="listHistoric.length"
      [lazy]="false"
      [rowsPerPageOptions]="[10, 20, 30, 50, 100]"
      dataKey="id"
      csvSeparator=";"
      [totalRecords]="listHistoric.length"
      styleClass="easy-table"
      [alwaysShowPaginator]="false"
      autoLayout="true"
      [globalFilterFields]="['numeroId', 'emissionBy', 'currentCustomerPhone']"
    >
      <ng-template pTemplate="caption">
        <div style="display: flex;">
          <div class="form-control" style="max-width: 33%">
            <label>{{ "Data inicial" | translate }}</label>
            <p-calendar
              dateFormat="dd/mm/yy"
              name="endDate"
              [(ngModel)]="startDate"
              appendTo="body"
              [showIcon]="true"
              i18n
              [monthNavigator]="true"
              [yearNavigator]="true"
              [yearRange]="'2000:2055'"
              rPCalendarMask
            >
            </p-calendar>
          </div>
          <div class="form-control" style="max-width: 33%">
            <label>{{ "Data final" | translate }}</label>
            <p-calendar
              dateFormat="dd/mm/yy"
              name="endDate"
              [(ngModel)]="endDate"
              appendTo="body"
              [showIcon]="true"
              i18n
              [monthNavigator]="true"
              [yearNavigator]="true"
              [yearRange]="'2000:2055'"
              rPCalendarMask
            >
            </p-calendar>
          </div>
          <div class="form-button">

            <button class="easymarine primary mt-4 ml-4" (click)="filtrar()">
              {{ "FIND" | translate }}
            </button>
          </div>
        </div>
        <span style="font-weight: bold; margin-top: 5px">
          {{ "TOTAL-REGISTERS" | translate }}:
          {{ listHistoric?.length }}
        </span>
      </ng-template>

      <ng-template pTemplate="header">
        <tr>
          <th class="text-center" [pSortableColumn]="'emissionDate'">
            {{ "Emitido em:" | translate }}
            <p-sortIcon [field]="'emissionDate'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'emissionBy'" class="text-center">
            {{ "Emitido por:" | translate }}
            <p-sortIcon [field]="'emissionBy'"></p-sortIcon>
          </th>

          <th [pSortableColumn]="'emissionTo'" class="text-center">
            {{ "Emitido para:" | translate }}
            <p-sortIcon [field]="'emissionTo'"></p-sortIcon>
          </th>
          <th class="text-center" >
            {{ "Convite Extra?" | translate }}

          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-property>
        <tr>
          <td class="text-center">
            {{ property?.emissionDate | date : "dd/MM/yyyy" }}
          </td>
          <td class="text-center">
            {{ property?.emissionBy }}
          </td>
          <td class="text-center">
            {{ property?.emissionTo}}
          </td>
          <td class="text-center">
            {{ property?.orderId? "Sim" : "Não" }}
          </td>

        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
