export class GetCustomerStatement {
  customerId: number;
  marinaId: number;
  startDate: Date;
  endDate: Date;


  constructor(customerId: number, marinaId: number) {
    this.customerId = customerId;
    this.marinaId = marinaId;
    this.startDate = this.getFirstDayOfMonth();
    this.endDate = this.getLastDayOfMonth();
  }

  private getFirstDayOfMonth(): Date {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1);
  }

  private getLastDayOfMonth(): Date {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth() + 1, 0);
  }

}
