<div class="drawer-header">
    <span>Selecione para ocultar/mostrar</span> 
    <i class="pi pi-times" (click)="close()"></i>
</div>
<div class="drawer-divider"></div>
<div class="drawer-body">
    <div class="drawer-buttons">
        <button *ngFor="let group of baseTable" class="drawer-button drawer-primary" [ngClass]="{'drawer-group-active': group.active}" (click)="changeGroup(group)">{{group.label}}</button>
    </div>
    <div class="drawer-items">
        <div *ngFor="let item of activeGroup.items">
            <p-checkbox class="drawer-item" name="HeaderItems"
            value="item"
            [value]="item"
            [(ngModel)]="selectedItems"
            [inputId]="item.id"
            [disabled]="item.required"
            [label]="item.label"
            (onChange)="changeItem(item)"
            ></p-checkbox>
        </div>
    </div>
</div>
<div class="drawer-footer">
    <span class="drawer-description">{{activeGroup.description}}</span>
    <button class="drawer-secondary" (click)="close()">OK</button>
</div>
