<p-fieldset>
    <p-header class="article-title">
      <div class="article-title">{{ "AUTHORIZED" | translate }}</div>
    </p-header>
    <div class="table">
      <p-table
        [value]="logsTable()"
        [rows]="5"
        [paginator]="true"
        autoLayout="true"
        alwaysShowPaginator="false"
      >
        <ng-template pTemplate="header">
          <tr>
            <th>{{ "NAME" | translate }}</th>
            <th>{{ "ASSOCIATED-OWNER" | translate }}</th>
          </tr>
        </ng-template>
        <ng-template
          pTemplate="body"
          let-boatCustomer
          let-rowIndex="rowIndex"
        >
          <tr [ngClass]="{'item-new-log': isChanged(boatCustomer) && isNew, 'item-old-log': isChanged(boatCustomer) && !isNew}">
            <td>
              <span>
                {{ boatCustomer.name }}
              </span>
            </td>
            <td>
                <span>
                  {{ getNameAssociatedOwner(boatCustomer.associatedOwnerId) }}
                </span>
              </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </p-fieldset>
  <br />