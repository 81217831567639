<div class="back-principal">
  <form (submit)="save()" [formGroup]="ticketDetForm">
    <span style="color: red; font-size: 12px">
      >Verifique se você está usando o usuário correto</span
    >
    <br />
    <div class="form-group" style="margin-top: 20px">
      <div class="form-control">
        <label for="title"
          >{{ "TITLE" | translate }} <span style="color: red"> * </span></label
        >
        <input
          pInputText
          type="text"
          name="title"
          formControlName="title"
          style="width: 100%"
        />
      </div>
    </div>

    <div class="form-group">
      <div class="form-control">
        <label for="description"
          >{{ "DESCRIPTION" | translate }}
          <span style="color: red"> * </span></label
        >
        <textarea
          name="description"
          formControlName="description"
          style="width: 100%"
          rows="8"
        ></textarea>
      </div>
    </div>

    <span style="color: red; font-size: 12px"> >Preenchimento obrigatório</span>

    <div class="form-group">
      <div class="form-control"></div>
    </div>

    <div class="form-group" style="margin-top: 20px">
      <div class="form-control">
        <div class="upload">
          <div class="divicon">
            <img
              src="assets/images/icon_upload.png"
              alt=""
              style="width: 79px !important; height: 60px !important"
            />
          </div>
          <div>
            <p class="btn-subtitle" style="font-weight: bold">
              .PNG, .JPG, .JPEG e .PDF
            </p>
          </div>
          <div style="padding-top: 18px">
            <p-fileUpload
              name="attachment"
              mode="basic"
              maxFileSize="100000000"
              [class]="buttonClass"
              chooseLabel="Adicionar"
              accept=".png,.jpg,.jpeg,.pdf"
              class="btn-upload uploat"
              (uploadHandler)="uploadFile($event.files)"
              [auto]="true"
              customUpload="true"
              #upload
              pTooltip="Anexar arquivo do documento da chamada"
              tooltipPosition="bottom"
            >
            </p-fileUpload>
          </div>
        </div>

        <p-table [value]="files" class="primeTable p-datatable">
          <tr>
            <th></th>
          </tr>
          <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
            <tr (click)="openFaq(item)">
              <td style="padding-right: 0px !important; padding-left: 0px !important;">
                <div class="upload">
                  <div class="divcon">
                    <img
                      src="assets/images/icon_upload.png"
                      alt=""
                      style="width: 79px !important; height: 60px !important"
                    />
                  </div>

                  <div style="padding-left: 8px; max-width: 183px;">
                    <p class="btn-subtitle" style="font-weight: bold">
                      {{ formataName(item.name) }}
                    </p>
                  </div>

                  <div style="width: 100%; text-align: right">
                    <button
                      class="easymarine primary"
                      (click)="removerItem(rowIndex)"
                      style="
                        border-radius: 10px;
                        width: 118px;
                        background-color: #ffffff !important;
                        color: red !important;
                        position: relative;
                        top: 21%;
                      "
                    >
                      Remover
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

    <div class="form-group" style="text-align: center; margin-top: 20px">
      <button
        class="easymarine primary"
        style="border-radius: 10px; width: 191px"
        type="submit"
      >
        Abrir Chamado
      </button>
    </div>
  </form>
</div>
