import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { ExtractPdfGenericComponent } from 'src/app/components/extract-pdf-generic/extract-pdf-generic.component';
import { MyAccountService } from 'src/app/services/my-account.service';
import { StorageUtil } from 'src/app/utils/storage.util';

@Component({
  selector: 'app-my-invoice',
  templateUrl: './my-invoice.component.html',
  styleUrls: ['./my-invoice.component.scss']
})
export class MyInvoiceComponent implements OnInit {

  marinaInvoices: any[];
  numberOfRows = 10;

  constructor(
    private myAccontService: MyAccountService,
    private spinner: NgxSpinnerService,
    private dialog: DialogService
  ) { }

  ngOnInit(): void {
    this.getInvoicesMarina();
  }

  getInvoicesMarina(): void {

    //Corrigir adm para marinas com mesmo CNPJ
    if(StorageUtil.getMarinaId() == 92){
      return;
    }

    this.myAccontService.getMyInvoices().subscribe(
      (data) => {
        this.marinaInvoices = [];
        data.forEach((invoice) => {
          if ( invoice.boletos.length > 1 ) {
            let vencimento = '';
            // let valorBoleto = invoice.valor / invoice.boletos.length;
            invoice.boletos.forEach((boleto) => {
              if (vencimento === '') {
                vencimento = boleto.dataVencimento
              } else {
                if (vencimento == boleto.dataVencimento) {
                  return;
                } 
              }
              this.marinaInvoices.push({
                vencimento: boleto.dataVencimento,
                situacao: boleto.pago ? 'PAGO' : invoice.situacao,
                id: invoice.id,
                notaFiscal: invoice.notaFiscal,
                linkNFEPS: invoice.linkNFEPS,
                tipoProduto: invoice.tipoProduto,
                valor: boleto.valor,
                urlBoleto: invoice.urlBoleto,
                linkBoleto: boleto.linkBoleto,
                parcelaAtual: boleto.parcela,
                parcelaTotal: invoice.boletos.length,
              });
            });
          } else {
            this.marinaInvoices.push(invoice);
          }
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getColorSituacao(invoice): string {
    return invoice.situacao === 'PAGO' ? 'blue' : 'red';
  }

  visualizarfatura(idFatura: any): void{
  this.spinner.show();
  this.myAccontService.getInvoice(idFatura).subscribe(
    async (data) => {
        const path = "data:application/pdf;base64," + data.pdf
        this.dialog.open(ExtractPdfGenericComponent, {
            width: '100%',
            height: '100%',
            data: {
                path
            },
            header: 'Fatura ' + idFatura
        });
        this.spinner.hide();
    },
      (error) => {
        const exception = error.error.data.exception;
        console.log(exception);
        this.spinner.hide();
    });
  }

}
