<section class="objectives-container">
  <app-card-button *ngFor="let objective of objectives"
    [selected]="objectiveSelected && objectiveSelected.code === objective.code"
    (click)="setObjective(objective); next()">

    <img [src]="getIconObj(objective)" alt="" header>

    <h3 class="title-obj">{{ objective.name | translate}}</h3>
    <p> {{getDescription(objective)}} </p>
  </app-card-button>
</section>

<div class="buttons align-bottom">
  <button class="easymarine" (click)="back()" type="button">{{'BACK'| translate}}</button>
  <button class="easymarine danger" (click)="cancel()" type="button">{{'CANCEL'| translate}}</button>
</div>