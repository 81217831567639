<div class="dialog-form">
    <form (submit)="save()" [formGroup]="programacaoForm">
        <p-fieldset class="remove-padding" legend="Dados Cadastrais">
            <div class=form-group>
                <div class="form-control field">
                    <label for="favorecido">{{ 'FAVORED' | translate }}</label>
                    <input pInputText [readOnly]="true" name="favorecido" appendTo="body" formControlName="favorecido">
                </div>
                <div class="form-control field" *ngIf="data.marinaCompany">
                    <label for="marinaCompany">{{ 'FINANCIAL' | translate }}</label>
                    <input pInputText [readOnly]="true" name="marinaCompany" appendTo="body" formControlName="marinaCompany">
                </div>
                <div class="form-control field">
                    <label for="valor">{{ 'VALUE' | translate }}</label>
                    <input pInputText [readOnly]="true" name="valor" formControlName="valor" type="number" maxlength="50" id="valor">
                </div>
            </div>
        </p-fieldset>
        <br>
        <p-fieldset class="remove-padding" legend="Datas">
            <div class=form-group>                
                <div class="form-control field">
                    <label for="dataEmissao">{{ 'EMISSION-DATE' | translate }}</label>
                    <p-calendar dateFormat="dd/mm/y" [readonlyInput]="true" appendTo="body"
                    name="dataEmissao" formControlName="dataEmissao" [showIcon]="true" i18n [disabled]="true">
                    </p-calendar>
                </div>
                <div class="form-control field">
                    <label for="dataVencimento">{{ 'DUE-DATE' | translate }}</label>
                    <p-calendar dateFormat="dd/mm/yy" name="dataVencimento" formControlName="dataVencimento"
                        [showIcon]="true" [showButtonBar]="true"></p-calendar>
                </div>
                <div class="form-control field">
                    <label for="dataCompetencia">{{ 'COMPETENCE-DATE' | translate }}</label>
                    <p-calendar dateFormat="dd/mm/yy" name="dataCompetencia" formControlName="dataCompetencia"
                        [showIcon]="true" [showButtonBar]="true"></p-calendar>
                </div>
            </div>
        </p-fieldset>
        <br>
        <p-fieldset class="remove-padding" legend="Dados Financeiros">
            <div class=form-group>
                <div class="form-control field">
                    <label for="planoConta">{{ 'ACCOUNT-PLAN' | translate }}</label>
                    <p-dropdown [options]="planoContaDropdown" placeholder="Selecione..." name="planoConta" appendTo="body" formControlName="planoConta">
                    </p-dropdown>
                </div>
                <div class="form-control field" *ngIf="data.useCostCenterInStock">
                    <label for="centroCusto">{{ 'COST-CENTER' | translate }}</label>
                    <p-dropdown [options]="centroCustoDropdown" placeholder="Selecione..." name="centroCusto" appendTo="body"
                        formControlName="centroCusto">
                    </p-dropdown>
                </div>
                <div class="form-control field">
                    <label for="conta">{{ 'ACCOUNT' | translate }}</label>
                    <p-dropdown [options]="contaDropdown" placeholder="Selecione..." name="conta" appendTo="body" formControlName="conta">
                    </p-dropdown>
                </div>
                <div class="form-control field">
                    <label for="formaPagamento">{{ 'PAYMENT-METHOD' | translate }}</label>
                    <p-dropdown [options]="formaPagamentoDropdown" placeholder="Selecione..." name="formaPagamento" appendTo="body"
                        formControlName="formaPagamento">
                    </p-dropdown>
                </div>
            </div>
        </p-fieldset>
        <div class="buttons">
            <button type="button" (click)="dialogRef.close()" class="easymarine danger">{{ 'CANCEL' | translate
                }}</button>
            <button class="easymarine primary" type="submit">{{ 'SAVE' | translate }}</button>
        </div>
    </form>

</div>