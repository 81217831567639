import {Component, OnInit} from '@angular/core';
import {GroupService} from '../../../services/group.service';
import {Marina} from '../../../models';
import {StorageUtil} from '../../../utils/storage.util';
import { OperationalConfigService } from 'src/app/services/config/operational-config.service';

@Component({
  selector: 'app-tuitions',
  templateUrl: './tuitions.component.html',
  styleUrls: ['./tuitions.component.scss'],
})
export class TuitionsComponent implements OnInit {

  monthsReference: any[] = monthReference;
  groups: Array<{ label: string; value: any }>;
  groupsList: any[];
  loggedMarina: Marina;
  marinaCompaniesList: Array<{ label: string; value: any }>;
  activateTuitionByCustomer: boolean = false;

  constructor(
    private groupService: GroupService,
    private operationalConfigService: OperationalConfigService,
    ) {
  }

  ngOnInit(): void {
    this.getTuitionConfig();
    this.loggedMarina = StorageUtil.getMarina();
    this.findGroups();
    this.getMarinaCompanies();
  }
  
  async getTuitionConfig() {
    this.activateTuitionByCustomer = await this.operationalConfigService.hasCustomerTuition();
  }

  findGroups(): void {
    this.groupService.findAll().subscribe((groups) => {
      this.groupsList = groups;
      this.groups = [{label: 'Sem grupo', value: null}].concat(
        groups.map((g) => ({label: g.name, value: g.id}))
      );
    });
  }

  private getMarinaCompanies() {
    if (this.loggedMarina.marinaCompanies &&
      this.loggedMarina.marinaCompanies.length > 0) {
      this.marinaCompaniesList = [
        {label: 'Todas', value: null},
        {label: this.loggedMarina.companyName, value: this.loggedMarina},
      ];
      const list = this.loggedMarina.marinaCompanies.map((m) => ({
        label: m.companyFederalName,
        value: m,
      }));
      this.marinaCompaniesList = this.marinaCompaniesList.concat(list);
    }
  }
}

export enum ContractEndDateStatus {
  NORMAL,
  EXPIRES_NEXT_MONTH,
  EXPIRES_THIS_MONTH,
  EXPIRED
}

export const monthReference = [
  {label: 'Janeiro', value: 0},
  {label: 'Fevereiro', value: 1},
  {label: 'Março', value: 2},
  {label: 'Abril', value: 3},
  {label: 'Maio', value: 4},
  {label: 'Junho', value: 5},
  {label: 'Julho', value: 6},
  {label: 'Agosto', value: 7},
  {label: 'Setembro', value: 8},
  {label: 'Outubro', value: 9},
  {label: 'Novembro', value: 10},
  {label: 'Dezembro', value: 11},
];
