import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { Customer } from 'src/app/models';
import { CustomerSmsLogDTO } from 'src/app/models/dtos/customer-sms-log-dto';
import { CustomerSmsLogService } from 'src/app/services/customer-sms-log.service';
import { CustomerService } from 'src/app/services/customer.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { FormatUtil } from 'src/app/utils/format.util';
import { MessageUtil } from 'src/app/utils/message.util';

@Component({
  selector: 'app-user-sms-log',
  templateUrl: './user-sms-log.component.html',
  styleUrls: ['./user-sms-log.component.scss']
})
export class UserSmsLogComponent implements OnInit {

  @Output() eventsUser = new EventEmitter();

  numberOfRows = 10;
  users: CustomerSmsLogDTO[] = [];
  usersTable: CustomerSmsLogDTO[] = [];
  userStatus: boolean = null;

  constructor(
    public dialog: DialogService,
    private slingConfigService: SlingConfigService,
    private customerSmsLogService: CustomerSmsLogService,
    private spinner: NgxSpinnerService,
    private messageUtil: MessageUtil
  ) { }

  ngOnInit(): void {
    this.loadTableConfig();
    this.fillCustomerTable();
  }

  private loadTableConfig(): void {
    this.slingConfigService.getSlingConfig().subscribe(
      (data) => {
        const slingConfig = data[0];
        this.numberOfRows = slingConfig != null ? slingConfig.numberOfRecordsPerPage : 10;
      },
      (error) => null);
  }

  filterGlobal(value: string): void {
    this.usersTable = this.users.filter(
      (u) =>
        (FormatUtil.getNotAccents(u.name).toUpperCase().includes(FormatUtil.getNotAccents(value).toUpperCase()) ||
          u.name.toUpperCase().includes(value.toUpperCase())
        ) ||
        (u.email.toUpperCase().includes(value.toUpperCase()))
    );
  }

  fillCustomerTable(): void {
    this.spinner.show();
    this.customerSmsLogService.getCustomersSmsLog()
      .subscribe(
        (c) => {
          this.users = c;
          this.usersTable = c;
        },
        (error) => {
          this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
        },
        () => this.spinner.hide()
      );
  }

  loadAll(): void {
    this.fillCustomerTable();
  }

  hasRequestedButNotConfirmed(customer: CustomerSmsLogDTO): boolean {
    return customer.userId === null && (customer.smsCode !== null);
  }
  
  getUserPhoneFormatted(user: CustomerSmsLogDTO): String {
    if (user.type != 'Marinheiro'){
      return user.phone;
    } else {
      return this.formatPhone(user.phone);
    }
  }

  formatPhone(phone: string): string {
      if (phone) {
        const value = phone.toString().replace(/\D/g, '');
  
        let foneFormatado = '';
  
        if (value.length > 12) {
          foneFormatado = value.replace(/(\d{2})?(\d{2})?(\d{5})?(\d{4})/, '+$1 ($2) $3-$4');
        } else if (value.length > 11) {
          foneFormatado = value.replace(/(\d{2})?(\d{2})?(\d{4})?(\d{4})/, '+$1 ($2) $3-$4');
        } else if (value.length > 10) {
          foneFormatado = value.replace(/(\d{2})?(\d{5})?(\d{4})/, '($1) $2-$3');
        } else if (value.length > 9) {
          foneFormatado = value.replace(/(\d{2})?(\d{4})?(\d{4})/, '($1) $2-$3');
        } else if (value.length > 5) {
          foneFormatado = value.replace(/^(\d{2})?(\d{4})?(\d{0,4})/, '($1) $2-$3');
        } else if (value.length > 1) {
          foneFormatado = value.replace(/^(\d{2})?(\d{0,5})/, '($1) $2');
        } else {
          if (phone !== '') { foneFormatado = value.replace(/^(\d*)/, '($1'); }
        }
        return foneFormatado;
      }
    }
}
