import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MarinaNotificationActiveDTO } from '../models/dtos/notifications/marinaNotificationActiveDTO';
import { CallUser } from '../models/integracaochamados/call-user';

@Injectable({
  providedIn: 'root'
})
export class SystemcallAuxiliarTablesService {

  constructor(private http: HttpClient) { }

  apiPath = environment.apiHost + '/secure/integracao/chamados';
  getSistemas(): Observable<any[]> {
      return this.http.get(this.apiPath + '/sistemas')
          .pipe(map((response: any) => response.data.sistemas as any[]));
  }

  getGrupos(idSistema:string): Observable<any[]> {
    return this.http.get(this.apiPath + '/grupos/' + idSistema)
        .pipe(map((response: any) => response.data.grupos as any[]));
  }

  getSubGrupos(idGrupos:string): Observable<any[]> {
    return this.http.get(this.apiPath + '/sub-grupos/' + idGrupos)
        .pipe(map((response: any) => response.data.grupos as any[]));
  }

  getMarina(name:string): Observable<MarinaNotificationActiveDTO[]> {
    return this.http.get(this.apiPath + '/marina-call/' + name)
        .pipe(map((response: any) => response.data.marina as MarinaNotificationActiveDTO[]));
  }

  getSolicitantes(marina:string): Observable<any[]> {
    return this.http.get(this.apiPath + '/solicitantes/' + marina)
        .pipe(map((response: any) => response.data.solicitantes as any[]));
  }

  uploadDocument(files): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('attachment', files[0]);

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'multipart/form-data');

    return this.http.post(
      this.apiPath + '/attachment',
      formData,
      {headers}
    ).pipe(map((response: any) => response.data.attachment));
  }

  postCall(call:any): Observable<any[]> {
    return this.http.post(this.apiPath + '/savecall',call)
        .pipe(map((response: any) => response.data.confirm as any[]));
  }

  getCallNotInteracted(marina:string): Observable<number> {
    return this.http.get(this.apiPath + '/systems/validate/' + marina)
        .pipe(map((response: any) => response.data.notificationCount as number));
  }

  getCallUser(): Observable<CallUser>{
    return this.http.get(`${this.apiPath}/token`).pipe(map((resp: any) => resp.data.calluser as CallUser));
  }

}
