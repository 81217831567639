import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-role-log-modal',
  templateUrl: './role-log-modal.component.html',
  styleUrls: ['./role-log-modal.component.scss']
})
export class RoleLogModalComponent implements OnInit {
  roleLog;

  isGeneralData = false;


  constructor(
    private config: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef
  ) { }

  ngOnInit(): void {
    if(!this.config.data.roleLog || !this.config.data.roleLog.oldDatas){
       this.dialogRef.close();
    }
    this.roleLog = this.config.data.roleLog;
    this.createLogs();
  }

  createLogs() {
    this.verifyChanges();
  }

  verifyChanges() {
    const oldDatasMap = new Map(Object.entries(this.roleLog.oldDatas));
    this.verifyChangesGeneralData(oldDatasMap);

  }

  verifyChangesGeneralData(oldDatasMap: Map<string, unknown>) {
    console.log(oldDatasMap);
    this.isGeneralData = true;

  }



}
