import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Customer, Product} from '../../../../models';
import {ServiceCustomerService} from '../../../../services/service-customer.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {ServiceCustomerDTO} from '../../../../models/dtos/ServiceCustomerDTO';
import {ProductType} from '../../../../models/enums';
import {ProductService} from '../../../../services/product.service';
import {MessageUtil} from '../../../../utils/message.util';
import {ToastService} from '../../../../services/toast.service';
import Swal from 'sweetalert2';
import {ServiceCustomer} from '../../../../models/ServiceCustomer';
import {PrincingType} from '../../../../models/pricing-type';
import moment from 'moment/moment';
import { CustomerService } from 'src/app/services/customer.service';
import { SelectItem } from 'primeng/api';
@Component({
  selector: 'app-customer-service',
  templateUrl: './customer-service.component.html',
  styleUrls: ['./customer-service.component.scss']
})
export class CustomerServiceComponent implements OnInit {
  customer: Customer;
  serviceCustomerList: ServiceCustomer[] = [];
  serviceCustomerListHistory: ServiceCustomer[] = [];
  services: { label: string; value: Product }[];
  isDiscountPercentage = false;
  contractStartDate = new Date();
  newService: Product;
  beneficiaries: SelectItem[] = [
    { label: 'Sem beneficiário', value: null }
  ];
  beneficiary: any;

  constructor(private dialogConfig: DynamicDialogConfig,
              private serviceCustomerService: ServiceCustomerService,
              private spinner: NgxSpinnerService,
              private messageUtil: MessageUtil,
              private toastService: ToastService,
              private productService: ProductService,
              private customerService: CustomerService,
              private dialogRef: DynamicDialogRef) {
  }

  ngOnInit(): void {
    this.customer = this.dialogConfig?.data?.selectedCustomer;
    this.getAllServiceCustomers();
    this.loadPeriodicServices();
    this.loadServiceCustomerHistory();
    this.loadCustomerDependents();
  }

  private getAllServiceCustomers() {
    this.spinner.show();
    this.serviceCustomerService.getServiceCustomersListByCustomerID(this.customer.id).subscribe({
      next: (value) => {
        this.spinner.hide();
        value = value.map(item => {
          item.contractStartDate = new Date(item.contractStartDate);
          if (item.contractEndDate) {
            item.contractEndDate = new Date(item.contractEndDate);
          }
          if (item.lastReadjustment) {
            item.lastReadjustment = new Date(item.lastReadjustment);
          }
          return item;
        });
        this.isDiscountPercentage = value[0].useDiscountOnPercentage;
        this.serviceCustomerList = value;
      },
      error: (error) => {
        this.spinner.hide();
        console.log(error);
      }
    });
  }

  loadPeriodicServices() {
    this.productService
      .getByProductType(ProductType.PeriodicalServiceCustomer)
      .subscribe({
        next: (data) => {
          const filterData = data.filter((service) => service.active == true);
          this.services = filterData.map((d) => ({label: d.name, value: d}));
        },
        error: (error) => {
          this.services = null;
        }
      });
  }

  updateServicesDiscount() {
    this.serviceCustomerList.map(servCustomer => {
      servCustomer.discount = 0;
      servCustomer.dueDateDiscount = 0;
      servCustomer.discountPercentage = 0;
      servCustomer.dueDateDiscountPercentage = 0;
      servCustomer.useDiscountOnPercentage = this.isDiscountPercentage;
    });
  }

  validateCustomerService() {
    if (this.newService == null) {
      this.toastService.warning('Você precisa escolher um serviço');
      return;
    }
    if (this.contractStartDate == null) {
      this.toastService.warning('Você precisa escolher uma data do inicio do serviço');
      return;
    }
    Swal.fire({
      title: 'Confirmação',
      text: `Tem certeza que deseja contratar o serviço ${this.newService.name} para o cliente?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.addNewServiceToCustomer();
      }
    });
  }

  isEditValueByService(serviceCustomer: ServiceCustomer) {
    return (
      serviceCustomer.service.pricingType === PrincingType.SimplePrice.toString() &&
      serviceCustomer.service.price.priceFree
    );
  }

  calculateDueDateDiscountPercentage(serviceCustomer: ServiceCustomer) {
    serviceCustomer.dueDateDiscount = (serviceCustomer.dueDateDiscountPercentage / 100) * serviceCustomer.value;
  }

  calculateDiscountPercentage(serviceCustomer: ServiceCustomer) {
    serviceCustomer.discount = (serviceCustomer.discountPercentage / 100) * serviceCustomer.value;
  }

  stopNullValue(event: any) {
    console.log(event);
    if (event.value === '' || event.value === null || event.value === undefined) {
      event = 0;
    }
  }

  calcTotalService(serviceCustomer: ServiceCustomer) {
    const value = serviceCustomer.value == undefined ? null : serviceCustomer.value;
    const discount = serviceCustomer.discount == undefined ? null : serviceCustomer.discount;
    return value - discount;
  }

  calcTotalServiceDueDate(serviceCustomer: ServiceCustomer) {
    const value = serviceCustomer.value == undefined ? null : serviceCustomer.value;
    const discount = serviceCustomer.discount == undefined ? null : serviceCustomer.discount;
    const dueDateDiscount = serviceCustomer.dueDateDiscount == undefined ? null : serviceCustomer.dueDateDiscount;
    return value - discount - dueDateDiscount;
  }

  deleteCustomerService(serviceCustomer: ServiceCustomer) {
    if (!this.verifyDeleteExistingBoatService(serviceCustomer)) {
      return;
    }
    Swal.fire({
      title: 'Confirmação',
      text: `Tem certeza que deseja excluir o serviço ${serviceCustomer.service.name}?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.spinner.show();
        this.serviceCustomerService.deleteServiceCustomer(serviceCustomer.id).subscribe({
          next: () => {
            this.spinner.hide();
            this.toastService.success('Serviço Excluido com sucesso!');
            this.dispose();
            this.getAllServiceCustomers();
          }, error: (err) => {
            this.spinner.hide();
            let msg = err.error.data.exception.message;
            if (msg === null || msg === '') {
              msg = 'Não foi possivel excluir o serviço';
            }
            this.toastService.error(msg);
          }
        });
      }
    });
  }
  
  dispose() {
    this.serviceCustomerList = [];
    this.newService = null;
    this.beneficiary = null;
  }

  verifyDeleteExistingBoatService(serviceCustomer: ServiceCustomer): boolean {
    if (!serviceCustomer.contractEndDate) {
      this.messageUtil.generateMessage(
        'warning',
        'SUMMARY.WARNING',
        'MSG.DELETE-SERVICE-WITHOUT-END-DATE'
      );
      return false;
    } else if (
      moment(serviceCustomer.contractEndDate).isBefore(
        moment(serviceCustomer.contractStartDate)
      )
    ) {
      this.messageUtil.generateMessage(
        'warning',
        'SUMMARY.WARNING',
        'MSG.END-DATE-BEFORE-START-DATE'
      );
      return false;
    }
    return true;
  }

  totalServicesValue() {
    return this.serviceCustomerList.reduce((total, s) => (total + s.value), 0);
  }

  totalServicesDiscount() {
    return this.serviceCustomerList.reduce((total, s) => (total + s.discount), 0);
  }

  totalServicesDueDateDiscount() {
    return this.serviceCustomerList.reduce((total, s) => (total + s.dueDateDiscount), 0);
  }

  totalServicesTotal() {
    return this.serviceCustomerList
      .map((s) => this.calcTotalService(s))
      .reduce((total, totalService) => (total + totalService), 0);
  }

  totalServicesTotalDueDate() {
    return this.serviceCustomerList
      .map((s) => this.calcTotalServiceDueDate(s))
      .reduce((total, totalService) => (total + totalService), 0);
  }

  private addNewServiceToCustomer() {
    this.spinner.show();
    const newServiceCustomer = new ServiceCustomerDTO();
    newServiceCustomer.serviceId = this.newService.id;
    newServiceCustomer.customerId = this.customer.id;
    newServiceCustomer.value = this.newService?.price?.value;
    newServiceCustomer.contractStartDate = this.contractStartDate;
    newServiceCustomer.useDiscountOnPercentage = this.isDiscountPercentage;
    newServiceCustomer.beneficiaryName = this.beneficiary?.name;
    newServiceCustomer.beneficiaryId = this.beneficiary?.id;
    this.serviceCustomerService.createNewServiceCustomer(newServiceCustomer).subscribe({
      next: () => {
        this.spinner.hide();
        this.dispose();
        this.getAllServiceCustomers();
      }, error: (err) => {
        this.spinner.hide();
        let msg = err.error.data.exception.message;
        if (msg === null || msg === '') {
          msg = 'Não foi possivel cadastrar o serviço';
        }
        this.toastService.error(msg);
      }
    });

  }

  closeaDialog() {
    this.dialogRef.close();
  }

  updateServiceCustomers() {
    const serviceCustomersDTOList: ServiceCustomerDTO[] = [];
    for (let serviceCustomer of this.serviceCustomerList) {
      const serviceCustDTO = new ServiceCustomerDTO();
      serviceCustDTO.mapToCustomerDTO(serviceCustomer, this.customer.id);
      serviceCustomersDTOList.push(serviceCustDTO);
    }

    this.spinner.show();
    this.serviceCustomerService.updateServiceCustomers(serviceCustomersDTOList).subscribe({
      next: () => {
        this.spinner.hide();
        this.toastService.success('Serviços atualizados com sucesso');
        this.closeaDialog();
      }, error: (err) => {
        this.spinner.hide();
        let msg = err.error.data.exception.message;
        if (msg === null || msg === '') {
          msg = 'Não foi possivel cadastrar o serviço';
        }
        this.toastService.error(msg);
      }
    });
  }

  private loadServiceCustomerHistory() {
    this.serviceCustomerService.getServiceCustomersHistoryListByCustomerID(this.customer.id).subscribe({
      next: (value) => {
        this.serviceCustomerListHistory = value;
      }, error: (error) => {
        console.log(error);
      }
    })
  }

  private loadCustomerDependents() {
    this.customerService.getCustomerDependents(this.customer.id).subscribe({
      next: (value) => {
        let dependents = value.map((c) => ({label: c.name, value: c}));
        
        this.beneficiaries = this.beneficiaries.concat(dependents);
        
      }, error: err => {
        this.toastService.error(err.error.data.exception.message);
      }
    });
  }
}
