<div class="side-nav" [ngClass]="{'docked': !showSideNav}">
    <ul *ngIf="menuItens?.length > 0" class="parent main-menu">
        <li *ngFor="let option of menuItens" class="parent dropdown-menu"
            [ngClass]="{'selected' : isSelected(option), 'route': isRoute(option)}">
            <a [routerLink]="option.routerLink" *ngIf="!option.children || option.children.length === 0">
                <i class="pi" [ngClass]="option.icon"></i>
                <span class="nav-text">{{option.label | translate}}</span>
            </a>
            <a (click)="openCloseMenus(option); getFunction(this, option.callFunction);"
                *ngIf="option?.children?.length > 0 && option.callFunction">
                <i class="pi" [ngClass]="option.icon"></i>
                <span class="nav-text">{{option.label | translate}}</span>
                <span *ngIf="option.value" [ngClass]="option.styleClass" [innerHTML]="option.value">
                </span>
                <span class="text-right menu-arrow">
                    <i class="pi"
                        [ngClass]="{'pi-angle-down' : !option.showChildren, 'pi-angle-up': option.showChildren}"></i>
                </span>
            </a>
            <a *ngIf="option?.children?.length > 0 && !option.callFunction" (click)="openCloseMenus(option)"
                [pTooltip]="getOptionTooltip(option)" tooltipPosition="right" tooltipStyleClass="tooltip">
                <fa-icon *ngIf="isFontsAwesome(option)" class="fa" [icon]="getFaIcon(option.icon)"></fa-icon>
                <i *ngIf="!isFontsAwesome(option)" class="pi" [ngClass]="option.icon"></i>
                <span class="nav-text">{{option.label | translate}}</span>
                <span *ngIf="option.value" [ngClass]="option.styleClass" [innerHTML]="option.value">
                </span>
                <span class="text-right menu-arrow">
                    <i class="pi"
                        [ngClass]="{'pi-angle-down' : !option.showChildren, 'pi-angle-up': option.showChildren}"></i>
                </span>
            </a>
            <ul *ngIf="option?.children?.length > 0" class="child-menu child dropdown-menu"
                [ngClass]="{'animated-open': option.showChildren, 'closed': !option.showChildren, 'animated-close': option.close}">
                <li *ngFor="let child of option.children" class="child "
                    [ngClass]="{'selected' : isSelected(option), 'route': isRoute(option)}">
                    <a [routerLink]="child.routerLink"
                        *ngIf="child.routerLink && (!child.children || child.children?.length === 0)"
                        [queryParams]="child.queryParams">
                        <i class="pi pi-angle-right"></i>&nbsp;
                        <span class="label">{{ child.label | translate }}
                        </span>
                        <span *ngIf="getValue(this, child.value)" class="badge badge-number" [pTooltip]="getChildTooltip(this, child)" 
                            tooltipPosition="bottom" tooltipStyleClass="tooltip">
                            {{getValue(this, child.value)}}
                        </span>
                    </a>
                    <a *ngIf="child?.children?.length > 0" (click)="openCloseMenus(child)">
                        <i class="pi pi-angle-right"></i>&nbsp;
                        <span class="label">{{ child.label | translate }}
                        </span>
                        <span *ngIf="getValue(this, child.value)" class="badge badge-number" [pTooltip]="getChildTooltip(this, child)"
                            tooltipPosition="bottom" tooltipStyleClass="tooltip">
                            {{getValue(this, child.value)}}
                        </span>
                        <span class="text-right menu-arrow">
                            <i class="pi"
                                [ngClass]="{'pi-angle-down' : !child.showChildren, 'pi-angle-up': child.showChildren}"></i>
                        </span>
                    </a>
                    <a (click)="getFunction(this, child.callFunction )" *ngIf="child.callFunction"
                        [pTooltip]="getChildTooltip(this, child)"
                        tooltipPosition="bottom" tooltipStyleClass="tooltip">
                        <i class="pi pi-angle-right"></i>&nbsp;
                        <span class="label">{{ child.label | translate }}
                        </span>
                        <span *ngIf="getValue(this, child.value)" class="badge badge-number">
                            {{getValue(this, child.value)}}
                        </span>
                    </a>
                    <ul class="grandchild" *ngIf="child?.children?.length > 0"
                        [ngClass]="{'animated-open': child.showChildren, 'closed': !child.showChildren, 'animated-close': child.close}">
                        <li *ngFor="let grandChild of child.children" class="grandchild"
                            [ngClass]="{'selected' : isSelected(option), 'route': isRoute(option)}">
                            <a [routerLink]="grandChild.routerLink" *ngIf="grandChild.routerLink"
                                [queryParams]="grandChild.queryParams">
                                <i class="pi pi-angle-right"></i>&nbsp;
                                <span class="label">{{ grandChild.label | translate }}
                                    <span *ngIf="getValue(this, grandChild.value)" class="badge badge-number"
                                        [pTooltip]="grandChild.tooltip" tooltipPosition="bottom"
                                        tooltipStyleClass="tooltip">
                                        {{getValue(this, grandChild.value)}}
                                    </span>
                                </span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>

        <li class="parent sign-out">
            <a href="/#/login">
                <i class="pi pi-sign-out"></i>&nbsp;
                <span class="label">{{'LOGOUT' | translate }}</span>
            </a>
        </li>
    </ul>

    <section class="sidenav-footer">
        <ul class="parent">
            <li class="parent">
                <a target="_blank" [href]="env.productLink">
                    <i class="pi pi-question-circle"></i>
                    <span class="nav-text">
                        <span>Help</span> &
                        <span>Support</span>
                    </span>
                </a>
            </li>
        </ul>
    </section>
</div>
