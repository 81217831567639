import { Component, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { DocumentTemplate } from 'src/app/models/document-template';
import { DocumentTemplateService } from 'src/app/services/document-template.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { TemplateFormComponent } from './template-form/template-form.component';

@Component({
  selector: 'app-contracts-config-template',
  templateUrl: './contracts-config-template.component.html',
  styleUrls: ['./contracts-config-template.component.scss']
})
export class ContractsConfigTemplateComponent implements OnInit {

  templates: DocumentTemplate[] = [];
  constructor(
    public dialog: DialogService,
    private messageUtil: MessageUtil,
    private documentTemplateService: DocumentTemplateService
  ) { }

  ngOnInit(): void {
    this.getTemplates();
  }

  async getTemplates(): Promise<void> {
    return new Promise<void>(
      async (resolve, reject) => {
        this.documentTemplateService.getTemplates().subscribe(
          async (data) => {
            this.templates = data;
            resolve();
          }, async (error) => {
            console.log(error);
            reject();
          });
      }
    );
  }

  openTemplateForm(template?): void {

  //   this.dialog.open(TemplateFormComponent, {
  //     header: this.messageUtil.translateKey('TEMPLATE-REGISTRATION'),
  //     data: {
  //       template
  //     }
  //   }).onClose.subscribe((data) => {
  //     if(data){
  //       this.getTemplates();
  //     }
  //   });
  }

  delete(template){
    this.documentTemplateService.delete(template.id).subscribe(
      async () => {
        this.getTemplates();
      }, async (error) => {
      });
  }
}
