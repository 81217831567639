<p-fieldset>
  <p-header class="article-title">
      <div class="article-title">{{'GENERAL-DATA' | translate}}</div>
  </p-header>
  <div class="content-log">
      <div>
          <label for="id">{{ 'ID' | translate }}</label>
          <app-log-field [old]="userLog.userId" [field]="userLog.userId" [existsOld]="false" [isNew]="isNew"></app-log-field>
      </div>
      <div>
          <label for="firstName">{{ 'NAME' | translate }}</label>
          <app-log-field [old]="userLog.oldDatas.firstName" [field]="userLog.firstName" [existsOld]="oldDatasMap.has('firstName')" [isNew]="isNew"></app-log-field>

      </div>
      <div>
          <label for="LASTNAME">{{ 'LASTNAME' | translate }}</label>
          <app-log-field [old]="userLog.oldDatas.lastName" [field]="userLog.lastName" [existsOld]="oldDatasMap.has('lastName')" [isNew]="isNew"></app-log-field>
      </div>

      <div>
          <label for="email">{{ 'EMAIL' | translate }}</label>
          <app-log-field [old]="userLog.oldDatas.email" [field]="userLog.email" [existsOld]="oldDatasMap.has('email')" [isNew]="isNew"></app-log-field>
      </div>

      <div>
        <label for="username">{{ 'USERNAME' | translate }}</label>
        <app-log-field [old]="userLog.oldDatas.username" [field]="userLog.username" [existsOld]="oldDatasMap.has('username')" [isNew]="isNew"></app-log-field>
    </div>

      <div>
          <label for="permissao">{{ 'PERMISSION' | translate }}*</label>
          <app-log-field [old]="userLog.oldDatas.roleId.name" [field]="userLog.roleId.name" [existsOld]="oldDatasMap.has('roleId')" [isNew]="isNew"></app-log-field>
      </div>

  </div>
</p-fieldset>
<br>
