
<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{ 'Estoque > Ajuste de serviço' | translate }}</span>
    </p-header>
    <div *ngIf="slingConfig">
        <p-tabView [activeIndex]="selectTab" (onChange)="onChangeSelectTab($event)" *ngIf="slingConfig.periodicalServicePriceFree">
            <p-tabPanel header="Preço livre">
                <app-list-price-free *ngIf="selectTab === 0"  [slingConfig]="slingConfig"></app-list-price-free>
            </p-tabPanel>
            <p-tabPanel header="Serviços">
                <app-list-services *ngIf="selectTab === 1"></app-list-services>
            </p-tabPanel>
        </p-tabView>

        <app-list-services *ngIf="!slingConfig.periodicalServicePriceFree"></app-list-services>
    </div>

</p-fieldset>
