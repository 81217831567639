import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { AccessGroup } from '../../models/dtos/access-controller/access-group';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AccessGroupLogService {
  env:string = environment.apiHost + '/api-core/secure/access-group';
  constructor(private http: HttpClient) { }


  findGroupLogs(filter: any) : Observable<AccessGroup[]> {
      let params = new HttpParams();
      if (filter.dateStart) {
          params = params.append('dateStart', moment(filter.dateStart).format());
      }
      if (filter.dateEnd) {
          params = params.append('dateEnd', moment(filter.dateEnd).format());
      }
      if (filter.userId) {
          params = params.append('userId', filter.userId);
      }
      if (filter.categoryId) {
          params = params.append('customerId', filter.customerId);
      }
      return null;
  }
}
