<div class="table">
    <p-table #tt [value]="boatLogs" [resizableColumns]="true" [autoLayout]="true" [paginator]="true"
    [rows]="numberOfRows" [pageLinks]="3" [rowsPerPageOptions]="isDialog? null : [10,20,30,50,100]">
        <ng-template pTemplate="header">
            <tr>
                <th>Data/hora</th>
                <th>Usuário</th>
                <th>Origem</th>
                <th>Embarcação</th>
                <th>Ação</th>
                <th>Visualizar</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-boatLog>
            <tr>
                <td>{{boatLog.logTime | date: 'dd/MM/yyyy - HH:mm'}}</td>
                <td [pTooltip]="boatLog.user ? boatLog.user.fullName : ''" tooltipPosition="top">{{boatLog.user ? boatLog.user.username : ''}}</td>
                <td>{{boatLog.origin}}</td>
                <td>{{boatLog.name}}</td>
                <td>{{boatLog.operationLog | translate}}</td>
                <td>
                    <div class="table-action" (click)="openBoatLogDialog(boatLog)" pTooltip="Visualizar"  *ngIf="boatLog.oldDatas"
                        tooltipPosition="bottom">
                        <i class="pi pi-eye"></i>
                    </div>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="7">
                    Nenhum log encontrado
                </td>
            </tr>
        </ng-template>

    </p-table>
</div>
