<div class="dialog-form" *ngIf="sling">
    <div class="flex-row content">
        <div class="card">
            <div class="card-image">
                <img src="{{imageUtil.getBoatImage(sling.boat)}}">
            </div>
            <div class="card-content">
                <div class="boat-name">{{sling.boat.name}}</div>
                <div>
                    <p *ngIf="sling.boat.vacancy" style="margin: 0 !important;">
                        {{sling.boat.vacancy ? sling.boat.vacancy.code : ""}}&nbsp;</p>
                    {{sling.boat.shipyardModel.shipType.toUpperCase() | translate}}&nbsp;
                    {{sling.boat.commercialLength}}'
                </div>
                <div class="execution" [ngClass]="{'em-alert': referenceTime > sling.executionForecast}">
                    {{sling.executionForecast | date: 'HH:mm'}}
                </div>
                <span class="consumable-type-badge">
                    {{sling.marinaOrder.orderItems.length}}
                </span>
            </div>
        </div>
        <div class="products">
            <div *ngFor="let orderItem of sling.marinaOrder.orderItems" class="flex-row product-row">
                <div class="name">
                    {{orderItem.product.name}}
                </div>

                <div class="product-details flex-row">
                    <div>
                        {{orderItem.qtyRequested}}
                    </div>

                    <div (click)="orderItem.qtySupplied = orderItem.qtyRequested">
                        <i class="pi pi-arrow-right"></i>
                    </div>
                    <div>
                        <span class="full-width text-center">
                            <p-inputNumber *ngIf="orderItem?.product?.measurement == 'Kilograms' || orderItem?.product?.measurement == 'Liters'; else otherMeasurement"
                                mode="decimal" [minFractionDigits]="3" [maxFractionDigits]="3" [(ngModel)]="orderItem.qtySupplied"
                                class="clean" [suffix]="' ' + orderItem.product.initialsMeasurement">
                            </p-inputNumber>
                            <ng-template #otherMeasurement>
                                <p-inputNumber [(ngModel)]="orderItem.qtySupplied" class="clean" [suffix]="' ' + orderItem.product.initialsMeasurement">
                                </p-inputNumber>
                            </ng-template>
                        </span>
                    </div>
                    <div appHideMobile
                        (click)="(orderItem.qtySupplied == 0 || !orderItem.qtySupplied) ? orderItem.qtySupplied : orderItem.qtySupplied = orderItem.qtySupplied - 1">
                        <i class="pi pi-minus"></i>
                    </div>

                    <div (click)="orderItem.qtySupplied = orderItem.qtySupplied + 1" appHideMobile>
                        <i class="pi pi-plus"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="buttons">
        <button class="easymarine danger" (click)="dialogRef.close()">
            {{'CANCEL' | translate }}
        </button>
        <button class="primary easymarine" (click)="save()">
            {{ 'CONFIRM' | translate }}
        </button>
    </div>
</div>