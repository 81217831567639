import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MenuItemService } from '../services/menu-item.service';
import { StorageUtil } from '../utils/storage.util';

@Injectable({
  providedIn: 'root'
})
export class MenuItemGuard  {
  userId: number;
  marinaId: number;
  items: string[] = [];

  constructor(private menuItemService: MenuItemService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const userId = StorageUtil.getUserId();
    const marinaId = StorageUtil.getMarinaId();
    let stateUrl = state.url;

    if (stateUrl.indexOf('?') !== -1){
      stateUrl = stateUrl.substring(0, stateUrl.indexOf('?'));
    }
    if (stateUrl.indexOf('#') !== -1){
      stateUrl = stateUrl.substring(0, stateUrl.indexOf('#'));
    }
    if (!userId || !marinaId) {
      return false;
    }
    if (userId !== this.userId || marinaId !== this.marinaId) {
      return this.menuItemService.findRouterLinkAccessByUser().pipe(
        map((data) => {
          this.userId = userId;
          this.marinaId = marinaId;
          this.items = data;
          return this.isOk(this.items, stateUrl);
        })
        );
      } else {
        return this.isOk(this.items, stateUrl);
    }
  }

  isOk(items: string[], path: string) {
    return items.filter(i => i=== path).length > 0;
  }

}
