<div class="table">
    <p-table #tt [value]="invoiceLogs" [resizableColumns]="true" [autoLayout]="true" [paginator]="true"
    [rows]="numberOfRows" [pageLinks]="3" [rowsPerPageOptions]="isDialog? null : [10,20,30,50,100]">
        <ng-template pTemplate="header">
            <tr>
                <th>Data/Hora</th>
                <th>Usuário</th>
                <th>Número da Fatura</th>
                <th>Data de Competência</th>
                <th>Data de Vencimento</th>
                <th></th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-invoiceLog>
            <tr>
               <td>{{invoiceLog.logTime | date: 'dd/MM/yyyy - HH:mm'}}</td>
                <td>{{invoiceLog.user}}</td>
               <td>{{invoiceLog.invoiceNumber}}</td>
               <td>{{invoiceLog.competenceDate}}</td>
               <td>{{invoiceLog.dueDate}}</td>
                <td>
                    <div class="table-action" (click)="openModal(invoiceLog)" pTooltip="Visualizar" *ngIf="invoiceLog.oldDatas"
                        tooltipPosition="bottom">
                        <i class="pi pi-eye"></i>
                    </div>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="7">
                    Nenhum log encontrado
                </td>
            </tr>
        </ng-template>

    </p-table>
</div>
