import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Shipyard, ShipyardModel } from '../models';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { ShipyardNameDTO } from '../models/dtos/shipyard/shipyardNameDTO';

@Injectable()
export class ShipyardService {

    constructor(private http: HttpClient) {
    }

    getAll(): Observable<Shipyard[]> {
        return this.http.get(environment.apiHost + '/secure/shipyards/')
            .pipe(map((response: any) => response.data.shipyards as Shipyard[]));
    }

    getShipyardModelsByShipyardId(id: number): Observable<ShipyardModel[]> {
        return this.http.get(environment.apiHost + '/secure/shipyard/' + id + '/models')
            .pipe(map((response: any) => response.data.shipyardModels));
    }

    getShipyardModelById(id: number): Observable<ShipyardModel> {
        return this.http.get(environment.apiHost + '/secure/shipyards/models/' + id)
            .pipe(map((response: any) => response.data.shipyardModel));
    }

  getShipyardByType(shipType): Observable<ShipyardNameDTO[]> {
    return this.http.get(`${environment.apiHost}/secure/shipyard/type/${shipType}`)
      .pipe(map((resp: any) => resp.data.shipyardsByType));
  }
}
