<div class="table">
    <p-table #tt [value]="usersTable" [paginator]="true" [rows]="numberOfRows" resetPageOnSort="true" [pageLinks]="3"
        [rowsPerPageOptions]="[10,20,30,50,100]" [resizableColumns]="true" [autoLayout]="true"
        [globalFilterFields]="['firstName','lastName','email','username','active']" styleClass="easy-table">
        <ng-template pTemplate="caption">
            <p-selectButton [ngStyle]="{'float': 'left'}" [(ngModel)]="userStatus"
                [options]="[{label: 'Todos', value: null}, {label: 'Ativo', value: true}, {label: 'Inativo', value: false}]"
                (onChange)="tt.filter($event.value, 'active', 'equals')">
            </p-selectButton>
            <span class="p-input-icon-left">
                <i class="pi pi-search"></i>
                <input type="text" pInputText size="50" placeholder="Filtro por nome, e-mail e usuário"
                    [(ngModel)]="globalFilter" (input)="filterGlobal()" style="width:auto">
            </span>
            <span style="font-weight: bold; float: right; margin-top: 5px;">{{ 'TOTAL-REGISTERS' | translate }}:
                {{tt.totalRecords}} {{'OF' | translate}} {{usersTable.length}}
            </span>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th [pSortableColumn]="'firstName'">
                    {{'NAME' | translate}}
                    <p-sortIcon [field]="'firstName'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'email'">
                    {{'EMAIL' | translate}}
                    <p-sortIcon [field]="'email'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'role'">
                    {{'ROLE' | translate}}
                    <p-sortIcon [field]="'role'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'username'">
                    {{'USERNAME' | translate}}
                    <p-sortIcon [field]="'username'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'created'">
                    {{'CREATED' | translate}}
                    <p-sortIcon [field]="'created'"></p-sortIcon>
                </th>
                <th [pSortableColumn]="'active'">
                    {{'STATUS' | translate}}
                    <p-sortIcon [field]="'active'"></p-sortIcon>
                </th>
                <th></th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user>
            <tr>
                <td>{{user.firstName}} {{user.lastName}}</td>
                <td>{{user.email}}</td>
                <td>{{getRoleName(user)}}</td>
                <td>{{user.username}}</td>
                <td>{{user.created | date: 'dd/MM/yyyy HH:mm'}}</td>
                <td class="text-center">
                    <span *ngIf="user.active" class="badge badge-pill success" style="cursor: default;">{{ 'ACTIVE' |
                    translate }}</span>
                    <span *ngIf="!user.active" class="badge badge-pill danger" style="cursor: default;">{{ 'INACTIVE' |
                    translate }}</span>
                </td>
                <td>
                    <div pTooltip="Editar {{user.username}}" (click)="openUserFormDialog(user)" class="table-action">
                        <i class="pi pi-pencil"></i>
                    </div>
                </td>
                <td>
                    <div pTooltip="Remover {{user.username}}" (click)="openUserDeleteConfirmDialog(user)"
                        class="table-action">
                        <i class="pi pi-trash" style="color: #EF5350"></i>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
