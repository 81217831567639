import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-boat-log-tie',
  templateUrl: './boat-log-tie.component.html',
  styleUrls: ['./boat-log-tie.component.scss']
})
export class BoatLogTieComponent implements OnInit {

  @Input() boatLog;
  @Input() isNew;

  constructor() { }

  ngOnInit(): void {
  }

  isChangedRegister(): boolean {
    return this.boatLog.oldDatas.tie.register !== this.boatLog.tie.register
  }

  isChangedExpiration(): boolean {
    return this.boatLog.oldDatas.tie.expiration !== this.boatLog.tie.expiration
  }

  isChangedState(): boolean {
    return this.boatLog.oldDatas.tie.state !== this.boatLog.tie.state
  }

  isChangedCity(): boolean {
    return this.boatLog.oldDatas.tie.city !== this.boatLog.tie.city
  }

  isChangedNavigationArea(): boolean {
    return this.boatLog.oldDatas.tie.navigationArea !== this.boatLog.tie.navigationArea
  }

}
