import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { PanelConfig } from 'src/app/models';
import { RaspScreenOrientation } from 'src/app/models/enums/rasp-screen-orientation';
import { Raspberry } from 'src/app/models/raspberry';
import { PanelConfigService } from 'src/app/services/panel-config.service';
import { RaspberryService } from 'src/app/services/raspberry.service';
import { MessageUtil } from 'src/app/utils/message.util';
import Swal from 'sweetalert2';
import { PanelConfigDialogComponent } from './panel-config-dialog/panel-config-dialog.component';
import { APPCONFIG } from 'src/app/config';
import { MovementGroupService } from 'src/app/services/movements/movement-group.service';
import { OperationalConfigService } from 'src/app/services/config/operational-config.service';

@Component({
  selector: 'app-panel-config',
  templateUrl: './panel-config.component.html',
  styleUrls: ['./panel-config.component.scss']
})
export class PanelConfigComponent implements OnInit {

  panelConfigsVertical: PanelConfig[] = [];
  panelConfigsHorizontal: PanelConfig[] = [];
  raspberries: any[];
  panelFunctionalitiesTypes: SelectItem[];
  movementGroups: SelectItem[] = [];
  isMovement: boolean = true;

  constructor(
    public dialog: DialogService,
    private panelConfigService: PanelConfigService,
    private spinner: NgxSpinnerService,
    private messageUtil: MessageUtil,
    private raspberryService: RaspberryService,
    private movementGroupService: MovementGroupService,
    private operationalConfigService: OperationalConfigService) {

    this.operationalConfigService.isMovements().then((movement: boolean) => {
      console.log(movement);
      this.isMovement = movement;
    })
  }

  ngOnInit(): void {
    this.fillPanelConfigTable();
    this.getAllRaspberry();
    this.getPanelFunctionalitiesTypes();
    this.getMovementGroups();
  }

  getMovementGroups() {
    this.movementGroupService.getAll().subscribe((movementGroups) => {
      movementGroups.forEach((movementGroup) => {
        this.movementGroups.push({
          label: movementGroup.name,
          value: movementGroup,
        });
      });
    });
  }

  isDisabled(panelFunctionality): boolean {
    return panelFunctionality == 'Disabled';
  }

  openViewPanel(panelConfig: PanelConfig): void {
    window.open(APPCONFIG.panels + panelConfig.raspberry.mac);
  }

  async getPanelFunctionalitiesTypes(): Promise<void> {
    this.panelFunctionalitiesTypes = await this.panelConfigService.getPanelFunctionalities();
  }

  changePanelLink(panelConfig: PanelConfig): void {
    this.panelConfigService.updateFunctionLink(panelConfig).subscribe(
      () => {
        Swal.fire('Atualização!', 'Link atualizado com sucesso', 'success');
      },
      (error) => {
        Swal.fire('Erro ao salvar!', error.error.message, 'error');
      }
    );
  }

  async openPanelConfigFormDialog(panel?: PanelConfig): Promise<void> {
    let panelConfig = [new PanelConfig()];
    let movementGroups = this.movementGroups;
    let id: number;
    let rasp: Raspberry;
    if (panel) {
      id = panel.id;
      if (panel.raspberry) {
        panelConfig = await this.getPanelConfig(panel.raspberry.mac);
        rasp = panel.raspberry;
      }
    }
    this.dialog.open(PanelConfigDialogComponent, {
      width: '80%',
      dismissableMask: true,
      data: {
        id,
        rasp,
        panelConfig,
        movementGroups
      },
      header: this.messageUtil.translateKey('PANEL-CONFIG-REGISTRATION')
    }).onClose.subscribe(
      () => {
        this.fillPanelConfigTable();
      }
    );
  }

  openPanelConfigDeleteConfirmDialog(rasp: Raspberry): void {
    let msg = 'Deseja realmente excluir a configuração do painel?';
    if (rasp.screenOrientation === RaspScreenOrientation.LANDSCAPE ||
      rasp.screenOrientation === RaspScreenOrientation.LANDSCAPE_INVERSE) {
      msg = 'Todos os painéis com o mesmo número de série serão excluidos. Tens certeza que desejas exclui-los?';
    }

    Swal.fire({
      text: msg,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((isConfirm) => {
      if (isConfirm.value) {
        this.panelConfigService.deleteByMac(rasp.mac).subscribe(
          () => {
            this.fillPanelConfigTable();
            this.messageUtil.generateMessage('success', 'SUMMARY.SUCCESS', 'MSG.DELETE-SUCCESS');
          },
          () => {
            this.messageUtil.generateMessage('error', 'SUMMARY.ERROR', 'MSG.DELETE-FAILURE');
          });
      }
    });
  }

  fillPanelConfigTable(): void {
    this.spinner.show();
    this.panelConfigService.getAllSeparatedByOrientation().subscribe(
      (p) => {
        this.panelConfigsVertical = p.portrait;
        this.panelConfigsHorizontal = p.landscape;
        this.spinner.hide();
      },
      () => this.spinner.hide()
    );
  }

  private getAllRaspberry(): void {
    this.raspberryService.getAllRaspberry().subscribe(
      (data: Raspberry[]) => this.raspberries = data.map((r) => ({ label: r.mac, value: r })),
      (error) => {
        this.spinner.hide();
        Swal.fire('Erro ao buscar configuração do Raspberry');
      }
    );
  }

  rebootRaspberry(panelConfig: PanelConfig): void {
    Swal.fire({
      text: 'O painel ' + panelConfig.observation + ' será reiniciado!! Deseja continuar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((isConfirm) => {
      if (isConfirm.value) {
        this.raspberryService.rebootRaspberry(panelConfig.raspberry.id, true).subscribe((rasp: Raspberry) => {
          this.fillPanelConfigTable();
        });
      }
    });
  }

  refreshRaspberry(raspberry: Raspberry): void {
    this.raspberryService.refreshRaspberry(raspberry.id, true).subscribe();
  }

  async getPanelConfig(mac: string): Promise<PanelConfig[]> {
    return new Promise<PanelConfig[]>(
      async (resolve) => {
        this.panelConfigService.getByMac(mac).subscribe(
          async (data) => {
            resolve(data);
          },
          async (err) => {
            resolve(null);
          }
        );
      }
    );
  }

}
