<div class="dialog-form">
  <form [formGroup]="accessPermissionForm">
    <div class="form-group">
      <div class="form-control">
        <label>{{ "ACCESS-GROUP" | translate }} *</label>
        <p-dropdown
          [options]="accessGroupList"
          [style]="{ height: '30px' }"
          formControlName="accessGroupId"
          appendTo="body"
          name="groupList"
          optionValue="value"
          optionLabel="label"
          (onChange)="onChangeAccessGroup($event)"
        >
        </p-dropdown>
      </div>

      <div class="form-control">
        <label>{{ "Autorizado Por:" | translate }} *</label>
        <p-dropdown
          placeholder="Selecione..."
          required
          [filter]="true"
          [options]="listCustomerList()"
          formControlName="customerResponsibleId"
          optionValue="value"
          optionLabel="label"
          appendTo="body"
          name="selectedCustomerId"
          (onChange)="getBoats()"
        >
        </p-dropdown>
      </div>

      <!-- SÓ MOSTRAR SE VISITANTE/FORNECEDOR -->
      <div
        class="form-control"
        style="min-width: 0px !important"
        *ngIf="this.selectedGroup?.showSuplier"
      >
        <label>{{ "SUPPLIER" | translate }}</label>
        <p-dropdown
          placeholder="Selecione..."
          [filter]="true"
          [options]="supplierList"
          optionValue="value"
          optionLabel="label"
          appendTo="body"
          formControlName="supplierId"
          name="selectedSupplierId"
        >
        </p-dropdown>
      </div>

      <div
        class="form-control"
        style="min-width: 0px !important"
        *ngIf="this.selectedGroup?.showSuplier"
      >
        <label>{{ "BOAT" | translate }}</label>
        <p-dropdown
          placeholder="Selecione..."
          [filter]="true"
          [options]="boatList"
          optionValue="value"
          optionLabel="label"
          appendTo="body"
          formControlName="boatId"
          name="selectedBoatId"
        >
        </p-dropdown>
      </div>
    </div>
    <div class="form-group mt-2">
      <div class="form-control">
        <label for="starDate"
          >{{ visitorType != "GUEST" ? "Data Inicial" : "Data" }}*</label
        >
        <p-calendar
          dateFormat="dd/mm/yy"
          name="starDate"
          [monthNavigator]="true"
          [yearNavigator]="true"
          [yearRange]="'1950:2055'"
          rPCalendarMask
          appendTo="body"
          formControlName="starDate"
          [showIcon]="true"
          i18n
        >
        </p-calendar>
      </div>

      <div class="form-control" *ngIf="visitorType != 'GUEST'">
        <label for="endDate">{{ "Data conclusão" | translate }}*</label>
        <p-calendar
          dateFormat="dd/mm/yy"
          name="endDate"
          [monthNavigator]="true"
          [yearNavigator]="true"
          [yearRange]="'1950:2055'"
          rPCalendarMask
          appendTo="body"
          formControlName="endDate"
          [showIcon]="true"
          i18n
        >
        </p-calendar>
      </div>

      <div class="form-control" style="max-width: unset">
        <label for="objective">{{ "Objetivo" | translate }}</label>
        <input
          pInputText
          name="objective"
          formControlName="objective"
          maxlength="999"
        />
      </div>
      <div class="form-control">
        <label for="ativo">Ativo </label>
        <p-inputSwitch name="ativo" formControlName="active"> </p-inputSwitch>
      </div>
    </div>
    <div class="form-group mt-2">
      <div class="form-control">
        <label for="carPlate">{{ "Placa do Carro" | translate }} </label>
        <input
          pInputText
          name="carPlate"
          formControlName="carPlate"
          maxlength="8"
        />
      </div>
      <div class="form-control">
        <label for="carModel">{{ "Modelo do Carro" | translate }} </label>
        <input
          pInputText
          name="carModel"
          formControlName="carModel"
          maxlength="250"
        />
      </div>
      <div class="form-control">
        <label for="carColor">{{ "Cor do Carro" | translate }} </label>
        <input
          pInputText
          name="carColor"
          formControlName="carColor"
          maxlength="250"
        />
      </div>
    </div>
    <div class="form-group align-center">
      <div class="buttons">
        <button
          type="button"
          (click)="startNewForm()"
          class="easymarine danger"
        >
          {{ "CLEAR" | translate }}
        </button>
        <button class="easymarine primary" (click)="save()">
          {{ "SAVE" | translate }}
        </button>
        <button *ngIf="isNested" class="easymarine" (click)="previous()">
          {{ "BACK" | translate }}
        </button>
      </div>
    </div>
  </form>

  <div class="table mt-2">
    <p-table
      #permissionDataTable
      [value]="permissionList"
      [paginator]="true"
      [rows]="50"
      [lazy]="false"
      [rowsPerPageOptions]="[50]"
      dataKey="id"
      styleClass="easy-table"
      [alwaysShowPaginator]="true"
      autoLayout="true"
      [sortField]="'starDate'"
      [sortOrder]="-1"
    >
      <ng-template pTemplate="header">
        <tr>
          <th [pSortableColumn]="'starDate'">
            {{ visitorType != "GUEST" ? "Data Inicial" : "Data" }}
            <p-sortIcon [field]="'starDate'"></p-sortIcon>
          </th>

          <th [pSortableColumn]="'endDate'" *ngIf="visitorType != 'GUEST'">
            {{ "Data Conclusão" | translate }}
            <p-sortIcon [field]="'endDate'"></p-sortIcon>
          </th>
          <th>
            {{ "ACCESS-GROUP" | translate }}
          </th>

          <th [pSortableColumn]="'customerResponsibleName'">
            {{ "Autorizado por:" | translate }}
            <p-sortIcon [field]="'customerResponsibleName'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'supplierName'">
            {{ "Fornecedor" | translate }}
            <p-sortIcon [field]="'supplierName'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'carPlate'">
            {{ "Placa do Carro" | translate }}
            <p-sortIcon [field]="'carPlate'"></p-sortIcon>
          </th>

          <th [pSortableColumn]="'setUserUpdateName'">
            {{ "Registrado por:" | translate }}
            <p-sortIcon [field]="'setUserUpdateName'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'active'">
            {{ "Status Atual" | translate }}
            <p-sortIcon [field]="'active'"></p-sortIcon>
          </th>
          <th class="text-center">{{ "Editar" | translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-permission>
        <tr>
          <td>
            {{ permission.starDate | date : "dd/MM/yyyy" }}
          </td>
          <td *ngIf="visitorType != 'GUEST'">
            {{ permission.endDate | date : "dd/MM/yyyy" }}
          </td>
          <td>
            {{ permission.accessGroup.name }}
          </td>

          <td>
            {{ permission.customerResponsibleName }}
          </td>
          <td>
            {{ permission.supplierName }}
          </td>
          <td>
            {{ permission.carPlate }}
          </td>
          <td>
            {{ permission.userUpdateName }}
          </td>
          <td class="text-center">
            {{ this.getStatus(permission.liberationStatus) }}
            <!-- {{ permission.active ? "Ativo" : "Inativo" }} -->
          </td>
          <td class="text-center">
            <ng-container
              *ngIf="this.isAllowedToEdit(permission.liberationStatus)"
            >
              <p-menu
                appendTo="body"
                #menu
                [model]="menuItems"
                [popup]="true"
                [showTransitionOptions]="'150ms'"
                [hideTransitionOptions]="'150ms'"
              ></p-menu>
              <button
                (click)="menu.toggle($event); changeSelectedItem(permission)"
                pButton
                type="button"
                icon="pi pi-ellipsis-v"
                class="p-button-text p-button-rounded p-button-lg"
              ></button>
            </ng-container>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
