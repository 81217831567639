<div class="content">
    <p>Existem pedidos selecionados com datas de referência diferentes.</p>
    <div class="table">
        <p-table [value]="dateReferencesTable" autoLayout="true"[paginator]="true" [rows]="3"
        [pageLinks]="3">
            <ng-template pTemplate="header">
                <tr>
                    <th class="text-center">Data de referência</th>
                    <th class="text-center">Pedidos</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-date>
                <tr>
                    <td class="text-center">{{ date.dateName}}</td>
                    <td class="text-center">{{ date.orders }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="buttons">
        <button class="easymarine danger" (click)="dialogRef.close()">
            {{'CANCEL' | translate }}
        </button>
        <button class="primary easymarine" (click)="continue()">
            Continuar
        </button>
    </div>
</div>
