import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Marina } from 'src/app/models';
import { EmailLog } from 'src/app/models/email-log';
import { InvoiceService } from 'src/app/services/invoice.service';

@Component({
  selector: 'app-invoice-emails',
  templateUrl: './invoice-emails.component.html',
  styleUrls: ['./invoice-emails.component.scss']
})
export class InvoiceEmailsComponent implements OnInit {

  invoice: any;
  emailLogs: EmailLog[] = [];
  loggedMarina: Marina;
  hasInvoiceReceipt: boolean = false;

  constructor(
    public dialog: DynamicDialogRef,
    private invoiceService: InvoiceService,
    private config: DynamicDialogConfig
  ) {
  }

  ngOnInit(): void {
    if (this.config.data?.debitLogs) {
      this.emailLogs = this.config.data.debitLogs;
    } else {
      this.invoice = this.config.data.invoice;
      this.emailLogs = this.config.data.emailLogs;
      this.loggedMarina = this.config.data.loggedMarina;
      this.hasInvoiceReceipt = this.config.data.hasInvoiceReceipt;
      this.updateEmailLogs();
    }
  }

  hasDemonstrative(log: any): boolean {
    return log.attachments.filter((att) => att.attachment === 'demonstrative').length > 0;
  }

  hasBillet(log: any): boolean {
    return log.attachments.filter((att) => att.attachment === 'billet').length > 0;
  }

  hasNF(log: any): boolean {
    return log.attachments.filter((att) => att.attachment === 'nf').length > 0;
  }

  hasReceipt(log: any): boolean {
    return log.attachments.filter((att) => att.attachment === 'receipt').length > 0;
  }

  updateEmailLogs(): void {
    const logNotRead = this.emailLogs.find((log) => !log.logRead);
    if (logNotRead) {
      this.invoiceService.updateEmailLog(this.invoice.id).subscribe();
    }
  }
}
