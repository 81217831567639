import { Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BoatSimples, CustomerSimples } from 'src/app/models/dtos/operational-options/responsibles';
import { CustomerService } from 'src/app/services/customer.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-movement-select-customer',
  templateUrl: './movement-select-customer.component.html',
  styleUrls: ['./movement-select-customer.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MovementSelectCustomerComponent),
      multi: true
    }
  ]
})
export class MovementSelectCustomerComponent implements OnInit, OnChanges, ControlValueAccessor {
  selectedCustomerId: number

  customersFilter: { label: string, value: any, disabled?: boolean }[] = [];
  private customers: any[] = [];
  private customer: any;

  private onChange: any = () => {};
  onTouched: any = () => {};
  disabled: boolean;

  @Input()
  searchByCustomer: boolean = true;

  @Input()
  boat: BoatSimples = null;

  constructor(
    private customerService: CustomerService
  ) {
    this.loadCustomers().then((customers: any[]) => {
      this.customers = customers;
      this.populateCustomerFilter(this.searchByCustomer, this.customers);
    })
  }

  writeValue(obj: any): void {
    this.customer = obj;
    this.selectedCustomerId = this.customer ? this.customer.id : null;
  }
  registerOnChange(fn: any): void { this.onChange = fn }
  registerOnTouched(fn: any): void { this.onTouched = fn }
  setDisabledState?(isDisabled: boolean): void { this.disabled = isDisabled }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchByCustomer && changes.searchByCustomer.currentValue !== changes.searchByCustomer.previousValue) {
      this.selectedCustomerId = null;
      this.emitChanges(null);
      this.populateCustomerFilter(this.searchByCustomer, this.customers);
    } else {
      if (changes.boat && changes.boat.currentValue !== changes.boat.previousValue) {
        if (!this.searchByCustomer) {
          this.selectedCustomerId = null;
          this.emitChanges(null);
        }
        this.populateCustomerFilter(this.searchByCustomer, this.customers);
      }
    }
  }

  changeSelectedCustomer(event: any): void {
    if (event.value) {
      if (event.value.financialBlock) {
        Swal.fire({
          title: 'Atenção',
          text: 'Cliente possui bloqueio no financeiro.',
          icon: 'warning',
          reverseButtons: true
        });
      }
      this.emitChanges(event.value);
    } else {
      this.emitChanges(null);
    }
  }

  emitChanges(customer: any) {
    if (!this.disabled) {
      this.customer = customer;
      this.onChange(customer);
    }
  }

  async loadCustomers(): Promise<any[]> {
    return new Promise<any[]>(
      async (resolve) => {
        this.customerService.getAllWithBoats().subscribe(
          async (data) => {
            resolve(data.filter((customer) => customer.active));
          },
          async (error) => {
            console.log(error);
            resolve(null);
          }
        );
      }
    );
  }

  populateCustomerFilter(searchByCustomer: boolean, res: any[]) {
    let list = [...res]
    if (!searchByCustomer) {
      if (this.boat) {
        const customersId: number[] = this.boat.boatCustomers.map(({owner, customer} :{ owner:boolean, customer: CustomerSimples }) => customer.id);
        list = list.filter((c: any) => customersId.includes(c.id));
      } else {
        list = []
      }
    }
    this.customersFilter = this.mountBoatOptions(list);
  }

  mountBoatOptions (customers: any[]): { label: string, value: number, disabled?: boolean }[] {
    if (!customers) {
      return [];
    }
    if (!this.searchByCustomer) {
      if (!this.boat) {
        return []
      }
      const boatCustomers = this.boat.boatCustomers;
      return customers.map((customer) => ({
        label: customer.name + this.getOwerDescrition(customer.id, boatCustomers), value: customer
      }));
    } else {
      return customers.map((customer) => ({
        label: customer.name, value: customer
      }));
    }
  }

  getOwerDescrition(customerId: number, boatCustomers: { owner:boolean, customer: CustomerSimples }[]): string {
    const res = boatCustomers.filter(({customer, owner}) => customer.id === customerId);
    if (res.length <= 0) {
      return '';
    }
    return res[0].owner ? ' (Proprietário)' : ' (Autorizado)';
  }
}
