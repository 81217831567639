import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectItem } from 'primeng/api';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Marina } from 'src/app/models';
import { DocumentSigner } from 'src/app/models/document-signer';
import { DocumentContractView } from 'src/app/models/dtos/document-contract-view';
import { SignerType } from 'src/app/models/enums/signerType';
import { DocumentSignerService } from 'src/app/services/document-signer.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';
import { SignerContractFormComponent } from './signer-contract-form/signer-contract-form.component';

@Component({
  selector: 'app-signers-contract-dialog',
  templateUrl: './signers-contract-dialog.component.html',
  styleUrls: ['./signers-contract-dialog.component.scss']
})
export class SignersContractDialogComponent implements OnInit {

  document: DocumentContractView;
  signers: DocumentSigner[] = [];
  typeList: SelectItem[] = [];
  marina: Marina;


  constructor(
    private documentSignerService: DocumentSignerService,
    private messageUtil: MessageUtil,
    private spinner: NgxSpinnerService,
    private config: DynamicDialogConfig,
    public dialog: DialogService,
  ) { }

  async ngOnInit(): Promise<void> {
    await this.spinner.show();
    if (this.config.data) {
      this.document = this.config.data.document;
    }

    this.marina = StorageUtil.getMarina();
    this.getSignerType();
    await this.getSigners();
    this.spinner.hide();
  }

  async getSigners(): Promise<void> {
    return new Promise<void>(
      async (resolve, reject) => {
        this.documentSignerService.getSigners(this.document.id)
          .subscribe(async (data) => {
            this.signers = data;
            resolve();
          }, async (error) => {
            console.log(error);
            reject();
          });
      }
    );
  }

  getSignerType(): void {
    this.typeList = Object.keys(SignerType).map(item => ({ label: this.messageUtil.translateKey(item), value: item }));
  }

  async deleteSigner(signer: DocumentSigner): Promise<void> {
    await this.spinner.show();
    const id = signer.id;
    this.documentSignerService.delete(id)
      .subscribe(() => {
        this.spinner.hide();
        this.signers = this.signers.filter(s => s.id !== id);
        this.messageUtil.generateMessage('success', 'SUMMARY.SUCCESS', 'Assinante excluido com sucesso.');
      }, (error) => {
        this.spinner.hide();
        this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', 'Não foi possivel excluir assinante.');
      });
  }

  isExternalSigner(signer: DocumentSigner): boolean {
    return signer.signerType !== 'SIGNER_CUSTOMER' && signer.signerType !== 'SIGNER_MARINA';
  }

  newSigner(): void {
    this.dialog.open(SignerContractFormComponent, {
      header: this.messageUtil.translateKey('SIGNER-CONTRACT-REGISTRATION'),
      data: {
        marina: this.marina,
        document: this.document
      }
    }).onClose.subscribe(async (data: DocumentSigner) => {
      if (data) {
        await this.getSigners();
      }
    });
  }
}

