import { Component, OnInit } from '@angular/core';
import { Customer } from 'src/app/models';
import { saveAs } from 'file-saver';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
@Component({
  selector: 'app-customer-import-dialog',
  templateUrl: './customer-import-dialog.component.html',
  styleUrls: ['./customer-import-dialog.component.scss']
})
export class CustomerImportDialogComponent implements OnInit {

  file: any;
  customersSuccess: Customer[];
  customersExists: Customer[];
  rows = 8;
  constructor(
    private config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.file = this.config.data.file;
    this.customersSuccess = this.config.data.customersSuccess;
    this.customersExists = this.config.data.customersExists;
    this.rows = this.config.data.rows || this.rows;
  }

  downloadFile(): void {
    saveAs(this.file, 'clientes_não_inseridos.xlsx');
  }
}
