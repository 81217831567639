import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { MovementLocationType } from 'src/app/models/enums/movements/movement-location-type';
import { BoatMovementDTO } from 'src/app/models/dtos/movements/boat-movement-dto';
import { MovementExecutionService } from 'src/app/services/movements/movement-execution.service';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { MovementLocationParams } from 'src/app/components/movements/movements-operational/movement-location/movement-location.component';
import { FilterBoatMovement } from 'src/app/models/dtos/movements/filter-boat-movement';
import { MovemmentDateTimeType } from 'src/app/models/enums/movements/movement-date-time-type';

@Component({
  selector: 'app-movement-location-yard',
  templateUrl: './movement-location-yard.component.html',
  styleUrls: ['./movement-location-yard.component.scss']
})
export class MovementLocationYardComponent implements OnInit {

  movements: any[] = [];

  MovementSubscription: Subscription;

  filter: FilterBoatMovement = {};

  constructor(
    private movementExecutionService: MovementExecutionService,
    private messageUtil: MessageUtil,
    private spinner: NgxSpinnerService,
    private webSocketService: WebSocketService
  ) { }

  ngOnInit(): void {
    this.getMovements();
    this.MovementSubscription = this.webSocketService.getMessageMovement().subscribe(async (message) => {
      await this.getMovements();
    });
  }

  getDestiny(movement : BoatMovementDTO): MovementLocationParams {
    return {
      type: movement?.movement.destinyLocationType,
      name: movement?.movement?.destinyLocationName,
      abbreviation: movement?.movement?.destinyLocationAbbreviation,
      vacancyCode: movement?.movement?.destinyVacancyCode
    }
  }

  getOrigin({ currentLocation } : BoatMovementDTO): MovementLocationParams {
    return {
      type: currentLocation.type,
      name: currentLocation.name,
      abbreviation: currentLocation.abbreviation,
      vacancyCode: currentLocation.vacancyCode
    }
  }

  getMovements(){
    this.spinner.show();
    this.movementExecutionService.getAllByLocation(MovementLocationType.YARD, this.filter).subscribe(
      (data) => {
        this.movements = data.map((boat) => {
          if (!!boat.date && !!boat.movement) {
            boat.date.dateTime = boat.movement.executionDate;
            boat.date.type = MovemmentDateTimeType.DURATION;
          };
          return boat;
        });
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    )
  }
}
