import { Component, OnInit } from '@angular/core';
import { MovementObjectiveCode } from 'src/app/models/enums/movements/movement-objective-code';
import { MovementStatus } from 'src/app/models/enums/movements/movement-status';
import { CustomerService } from 'src/app/services/customer.service';
import { BoatService } from 'src/app/services/boat.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { MovementLocationService } from 'src/app/services/movements/movement-location.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { PaginationFilter } from 'src/app/models/pagination-filter';
import { Paginator } from 'primeng/paginator';
import { MovementReportService } from 'src/app/services/movements/movement-report.service';
import { FilterMovementReport } from 'src/app/models/dtos/movements/reports/filter-movement-report';
import { MovementReportDTO } from 'src/app/models/dtos/movements/reports/movement-reportDTO';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { ExtractPdfGenericComponent } from 'src/app/components/extract-pdf-generic/extract-pdf-generic.component';
import { DatePipe } from '@angular/common';
import { WorkSheet } from 'xlsx';
import { MovementManagementService } from 'src/app/services/movements/movement-management.service';
import { ExemptMovementDTO } from 'src/app/models/dtos/movements/exempt-movementDTO';
import { CancelMovementDTO } from 'src/app/models/dtos/movements/cancel-movementDTO';
import { ToastService } from 'src/app/services/toast.service';
import Swal from 'sweetalert2';
import { MovementAdjustmentDialogComponent } from './movement-adjustment-dialog/movement-adjustment-dialog.component';
import { TimezoneUtil } from 'src/app/utils/timezone.util';
import { SailorService } from 'src/app/services/sailor.service';
import { CustomReportExportComponent } from 'src/app/components/extract-custom-report/custom-report-export.component';
import * as FileSaver from 'file-saver'
@Component({
  selector: 'app-movement-management',
  templateUrl: './movement-management.component.html',
  styleUrls: ['./movement-management.component.scss']
})
export class MovementManagementComponent implements OnInit {

  boatListOptions: any[] = [];
  customerListOptions: any[] = [];
  movementLocationListOptions: any[] = [];
  movementLocationListFullValueOptions: any[] = [];
  objetiveListOptions: any[] = [];
  statusListOptions: any[] = [];
  sailorListOptions: any[] = [];
  totalPages: number;

  slingConfig: any;

  filter: FilterMovementReport = {}
  paginationFilter: PaginationFilter = new PaginationFilter();
  totalRecords: number;

  movementReports: MovementReportDTO[] = [];
  selectedMovements: MovementReportDTO[] = [];

  cols = [];
  constructor(
    private boatService: BoatService,
    private messageUtil: MessageUtil,
    private customerService: CustomerService,
    private sailorService: SailorService,
    private movementLocationService: MovementLocationService,
    private slingConfigService: SlingConfigService,
    private movementReportService: MovementReportService,
    private movementManagementService: MovementManagementService,
    private spinner: NgxSpinnerService,
    private dialog: DialogService,
    private datePipe: DatePipe,
    private toasty: ToastService,
    private timezoneUtil: TimezoneUtil
  ) { }

  timezone(data: any):any{
    return this.timezoneUtil.formatTimezone(data);
  }

  async ngOnInit(): Promise<void> {
    this.clear();
    await Promise.all([
      this.loadSlingConfig(),
      this.findBoatOptions(),
      this.findCustomerOptions(),
      this.findSailorOptions(),
      this.findMovementLocationOptions()
    ]);
    this.findMovementObjectiveCodeOptions();
    this.findMovementStatusOptions();
    this.find();
    this.defineNameColumnsExport();
  }

  async find(): Promise<void> {
    this.spinner.show();
    return new Promise( async resolve => {
      this.movementReportService.getPage(this.filter, this.paginationFilter).subscribe(
        data => {
          this.movementReports = data.content;
          this.totalRecords = data.totalElements;
          this.totalPages = data.totalPages;
          this.spinner.hide();
          resolve();
        },
        error => {
          resolve();
          this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
        }
      )
    });
  }

  clear() {
    this.filter = new FilterMovementReport();
    this.paginationFilter = new PaginationFilter();
  }

  exportPDF(): void {
    this.spinner.show();
    this.movementReportService.getReportPDF(this.filter).subscribe(
      (data) => {
        this.openExtract(
          'data:application/pdf;base64,'+ data
        );
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      },
      () => this.spinner.hide());
  }

  openExtract(path): void {
    const dialog = this.dialog.open(ExtractPdfGenericComponent, {
      width: '100%',
      height: '100%',
      data: {
        path
      },
      header: 'Relatório de movimentações'
    });
  }


  onChangePage(event: Paginator): void {
    if(this.slingConfig) {
      this.paginationFilter.page = event.first / event.rows;
      this.paginationFilter.size = event.rows;
      this.find();
    }
  }

  async findBoatOptions(): Promise<void> {
    return new Promise( async resolve => {
      this.boatService.getBoatFilterList().subscribe(
        data => {
          this.boatListOptions = data.map(item => ({ label: item.name, value: item.id }));
          resolve();
        },
        error => {
          resolve();
        }
      )
    });
  }

  async findCustomerOptions(): Promise<void> {
    return new Promise( async resolve => {
      this.customerService.getCustomerFilterList().subscribe(
        data => {
          this.customerListOptions = data.map(item => ({ label: item.name, value: item.id }));
          resolve();
        },
        error => {
          resolve();
        }
      )
    });
  }

  async findSailorOptions(): Promise<void> {
    return new Promise( async resolve => {
      this.sailorService.getSailorFilterListByMarinaId().subscribe(
        data => {
          this.sailorListOptions = data.map(item => ({ label: item.name, value: item.id }));
          resolve();
        },
        error => {
          resolve();
        }
      )
    });
  }

  async findMovementLocationOptions(): Promise<void> {
    return new Promise( async resolve => {
      this.movementLocationService.getMovementLocationFilterList().subscribe(
        data => {
          this.movementLocationListFullValueOptions = data.filter(ml => ml.active).map(item => ({ label: item.name, value: item }));
          this.movementLocationListOptions = data.filter(ml => ml.active).map(item => ({ label: item.name, value: item.id }));
          resolve();
        },
        error => {
          resolve();
        }
      )
    });
  }

  async loadSlingConfig(): Promise<void> {
    return new Promise(resolve => {
      this.slingConfigService.getSlingConfig().subscribe(
        async (data) => {
          this.slingConfig = data[0];
          this.paginationFilter.size = this.slingConfig != null ? this.slingConfig.numberOfRecordsPerPage : 10;
          resolve();
        },
        async (error) => resolve());
    })
  }

  findMovementObjectiveCodeOptions() { // aguardand modificaçoes luiz
    this.objetiveListOptions = [];
    Object.keys(MovementObjectiveCode).forEach(async item => {
      const label = await this.messageUtil.translateKeyAsync(item.toUpperCase());
      this.objetiveListOptions.push({ label, value: item })
    });
  }

  findMovementStatusOptions() {
    this.statusListOptions = [];
    [MovementStatus.FINISHED, MovementStatus.CANCELED].forEach(async item => {
      const label = await this.messageUtil.translateKeyAsync(item.toUpperCase());
      this.statusListOptions.push({ label, value: item })
    });
  }

  onChangeExempt(movement: MovementReportDTO): void {
    let title = '';
    let text = `<span>${this.datePipe.transform(movement.finishDateTime,'dd/MM/yy - HH:mm')} - ${movement.boatName} - ${movement.originName} >> ${movement.destinyName}<br></span>`;

    if(movement.exempt) {
      title = 'A seguinte movimentação será isentada:';
      text += '<br><h3><b>Deseja realmente isentar esse registro?</b></h3>'
    } else {
      title = 'A seguinte movimentação será isentada: como colocar a mensagem de emoção de isenção';
      text += '<br><h3><b>Deseja realmente remover a isenção desse registro?</b></h3>'
    }

    Swal.fire({
      title,
      html: text,
      icon: 'error',
      backdrop: false,
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Isentar',
      cancelButtonText: 'Voltar',
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.exemptMovement(movement);
      } else {
        movement.exempt = !movement.exempt;
      }
    });
  }

  private exemptMovement(movement: MovementReportDTO): void {
    this.spinner.show();
    const exemptMovementDTO: ExemptMovementDTO = {
      exempt: movement.exempt,
      movementId: movement.movementId
    };

    this.movementManagementService.exemptMovement(exemptMovementDTO).subscribe( data => {
      this.spinner.hide();
      this.find();
      if(exemptMovementDTO.exempt){
       this.toasty.success("Adicionada a isenção com sucesso!");
      } else {
        this.toasty.success("Removida a isenção com sucesso!");
      }
    },
    error => {
      this.spinner.hide();
      movement.exempt = !movement.exempt;
      const exception = error.error.data.exception;
      this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
    });
  }

  cancelMovementConfirm(): void {
    if (!this.selectedMovements || this.selectedMovements.length <= 0) {
      this.toasty.warning("selecione uma ou mais movimentações para o cancelamento!");
      return;
    }

    const title = 'As seguintes movimentações serão canceladas:';
    let text ='';
    this.selectedMovements.forEach( movement => {
      text += `<span>${this.datePipe.transform(movement.finishDateTime,'dd/MM/yy - HH:mm')} - ${movement.boatName} - ${movement.originName} >> ${movement.destinyName}<br></span>`;
    })
    text +=`<br><h3><b>Deseja realmente cancelar ${this.selectedMovements.length} registro${this.selectedMovements.length>1?'s':''}?</b></h3>`

    Swal.fire({
      title,
      html: text,
      icon: 'error',
      backdrop: false,
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Cancelar',
      cancelButtonText: 'Voltar',
      reverseButtons: true
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.cancelMovement();
      }
    });
  }

  private cancelMovement() {
    const cancelMovementDTO: CancelMovementDTO = {
      movementIdsList: this.selectedMovements.map( mov => mov.movementId)
    }

    this.movementManagementService.cancelMovement(cancelMovementDTO).subscribe( data => {
      this.spinner.hide();
      this.find();
      this.selectedMovements = [];
      this.toasty.success("Movimentações canceladas com sucesso!");
    },
    error => {
      this.spinner.hide();
      const exception = error.error.data.exception;
      this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
    })
  }

  adjustmentMovementsModal(): void {
    this.dialog.open(MovementAdjustmentDialogComponent, {
        header: 'Criar ajuste de movimentação',
        width: '90%',
        height: '100%',
        data: {
          boatListOptions: this.boatListOptions,
          movementLocationListFullValueOptions: this.movementLocationListFullValueOptions,
          customerListOptions: this.customerListOptions,
          sailorListOptions :this.sailorListOptions
        }
    });
}


  disableCancelButton(): boolean {
    return this.selectedMovements.length < 1;
  }

  isMovementSelectable(event): boolean {
    return this.disableSelection(event.data);
  }

  disableSelection(movement: MovementReportDTO): boolean {
    return movement.movementStatus == MovementStatus.CANCELED;
  }

  disableExempt(movement: MovementReportDTO): boolean {
    return !movement.isCounted || movement.movementStatus == MovementStatus.CANCELED
  }

  async exportExcel() {
    const values: MovementReportDTO[] = await this.findDataExport();
    import("xlsx").then(xlsx => {
        let worksheet = xlsx.utils.json_to_sheet(values);
        this.changeHeaderWorksheet(worksheet);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data']};
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, "relatorio_movimentacoes");
    });
  }

  exportTable() {
    this.spinner.show();
    this.getAllExportData().then((movements) => {
      this.spinner.hide();
      const dataFormated = movements.map((movement) => this.formatDataExport(movement))
      const data = { table: dataFormated, type: 'MOVEMENT_MANAGEMENT' };
      this.dialog.open(CustomReportExportComponent, {
        width: '70%',
        height: '90vh',
        dismissableMask: false,
        data,
        header: this.messageUtil.translateKey('EXPORT-MOVEMENT-MANAGEMENT-TABLE'),
      });
    })
    .catch((e) => {
      console.log(e);
    });
  }

  /**
   * Busca todos os dados para exportação.
   * Pela paginação, vai ser preciso percorrer as páginas e buscar os dados de cada uma delas.
   * TASK: BUG-116
   */
  getAllExportData(): Promise<MovementReportDTO[]>{
    return this.movementReportService.getAllList(this.filter).toPromise();
  }

  changeHeaderWorksheet(worksheet: WorkSheet) {
    worksheet.A1.v = this.cols[0].header
    worksheet.B1.v = this.cols[1].header
    worksheet.C1.v = this.cols[2].header
    worksheet.D1.v = this.cols[3].header
    worksheet.E1.v = this.cols[4].header
    worksheet.F1.v = this.cols[5].header
    worksheet.G1.v = this.cols[6].header
    worksheet.H1.v = this.cols[7].header
    worksheet.I1.v = this.cols[8].header
    worksheet.J1.v = this.cols[9].header
    worksheet.K1.v = this.cols[10].header
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
          type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  findDataExport(): Promise<MovementReportDTO[]> {
    return new Promise(async (resolve) =>{
      this.spinner.show();
      const filter = this.filter;
      this.movementReportService.getAllList(filter).subscribe(
        async (data: any) => {
          const values = data.map(movementReport => {
            return this.formatDataExport(movementReport);
          });
          resolve(values);
        },
        async (error) => {
          this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
          resolve(null);
        },
        () => this.spinner.hide()
      );
    })
  }

  async defineNameColumnsExport(){
    this.cols = [
      { field: 'systemAdjustment', header: await this.messageUtil.translateKeyAsync('ADJUSTMENT') },
      { field: 'finishDateTime', header: await this.messageUtil.translateKeyAsync('REALIZATION-DATE') },
      { field: 'boatName', header: await this.messageUtil.translateKeyAsync('BOAT') },
      { field: 'customerName', header: await this.messageUtil.translateKeyAsync('CUSTOMER') },
      { field: 'requestOrigin', header: await this.messageUtil.translateKeyAsync('ORIGIN-APP') },
      { field: 'objective', header: await this.messageUtil.translateKeyAsync('OBJECTIVE') },
      { field: 'originName', header: await this.messageUtil.translateKeyAsync('ORIGIN') },
      { field: 'destinyName', header: await this.messageUtil.translateKeyAsync('DESTINY') },
      { field: 'isCounted', header: await this.messageUtil.translateKeyAsync('IS-COUNTED') },
      { field: 'exempt', header: await this.messageUtil.translateKeyAsync('EXEMPT') },
      { field: 'movementStatus', header: await this.messageUtil.translateKeyAsync('STATUS') }
    ];
  }

  formatDataExport(movementReport: MovementReportDTO): any {
    return {
      systemAdjustment: movementReport.systemAdjustment? "S": "N",
      finishDateTime: this.datePipe.transform(movementReport.finishDateTime,'dd/MM/yy - HH:mm'),
      boatName: movementReport.boatName,
      customerName: movementReport.customerName,
      requestOrigin:  this.messageUtil.translateKey(movementReport.requestOrigin),
      objective:  this.messageUtil.translateKey(movementReport.objective),
      originName: movementReport.originName,
      destinyName: movementReport.destinyName,
      isCounted: movementReport.isCounted?  this.messageUtil.translateKey('YES'):  this.messageUtil.translateKey('NO'),
      exempt: movementReport.exempt?  this.messageUtil.translateKey('YES'):  this.messageUtil.translateKey('NO'),
      movementStatus:  this.messageUtil.translateKey(movementReport.movementStatus),
    };
  }
}
