import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import moment from 'moment';
import { Boat, SlingConfig } from 'src/app/models';
import { MessageUtil } from 'src/app/utils/message.util';
import { FormatUtil } from 'src/app/utils/format.util';
import Swal from 'sweetalert2';
import { BoatWash } from 'src/app/models/boatWash';
import { BoatWashService } from 'src/app/services/boat-wash.service';
import { ToastService } from 'src/app/services/toast.service';
import { BoatWashEngineTurnFormComponent } from 'src/app/components/boat-wash-engine-turn/boat-wash-engine-turn-form/boat-wash-engine-turn-form.component';
import { BoatWashEngineTurnHistoryComponent } from 'src/app/components/boat-wash-engine-turn/boat-wash-engine-turn-history/boat-wash-engine-turn-history.component';
import { DialogService } from 'primeng/dynamicdialog';
import { WashOrEngineTurnDetails } from '../wash-engine-turn.component';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'wash-tab',
    styleUrls: ['wash-tab.component.scss'],
    templateUrl: 'wash-tab.component.html'

})

export class WashTabComponent implements OnInit, OnChanges {

    @Input() slingConfig: SlingConfig;
    @Input() boatsEngineTurn: any[];

    
    @Input() boatWashes = new Array();

    @Input()
    readonly filterStatusoptions: any[] = [];
    
    filter = { status: [] };
    boatWashesTable: any[] = new Array();
    selectedBoat: Boat;
    selectedBoatsName: string;
    boatSelected = false;
    boatScheduleSelected = false;
    currentDate: any = new Date();
    schedulingDate: any = null;
    globalFilter = '';

    washDetails: WashOrEngineTurnDetails = {
        boatAll: 0,
        boatScheduled: 0,
        boatNoSchedule: 0,
        boatNeverSchedule: 0,
    };

    pt = {
        firstDayOfWeek: 0,
        dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
        dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
        dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
        monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
            'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        today: 'Hoje',
        clear: 'Limpar'
    };
    faHistory: any;

    constructor(
        private boatWashService: BoatWashService,
        private messageUtil: MessageUtil,
        private toasty: ToastService,
        private dialog: DialogService
    ) { }

    ngOnInit(): void {
        this.totalizer();
        this.filterGlobal();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.filterGlobal();
    }

    filterGlobal(): void {
        let washes = this.filterSearch(this.boatWashes);
        this.boatWashesTable = this.filterStatus(washes);
    }

    filterSearch(washes: any[]): any[] {
        washes = washes.filter(item => item.marineWashMyBoat);
        return washes.filter(
            (ebt) =>
            (FormatUtil.getNotAccents(ebt.boatName).toUpperCase().includes(FormatUtil.getNotAccents(this.globalFilter).toUpperCase()) ||
                ebt.boatName.toUpperCase().includes(this.globalFilter.toUpperCase())
            ) || (FormatUtil.getNotAccents(ebt.userName).toUpperCase().includes(FormatUtil.getNotAccents(this.globalFilter).toUpperCase()) ||
            ebt.userName?.toUpperCase().includes(this.globalFilter.toUpperCase())
        )
        );
    }

    filterStatus(washes: any[]): any[] {
        if (this.filter.status !== undefined && this.filter.status.length > 0) {
            return washes.filter(obj => {
                if ( this.filter.status.includes('Agendados') && this.scheduledVerify(obj)) {
                  return true;
                } else if ( this.filter.status.includes('Nunca Agendados') && this.neverScheduleVerify(obj)) {
                  return true;
                } else if ( this.filter.status.includes('Removidos da fila') && this.noScheduleVerify(obj)) {
                  return true;
                }
                return false;
            });
        } else {
            return washes;
        }
    }

    openWashHistoryDialog(boatWash): void {
        this.boatWashService.getBoatWashHistory(boatWash.boatId).subscribe((data) => {
            this.dialog.open(BoatWashEngineTurnHistoryComponent, {
                header: 'Histórico de lavação',
                width: '25%',
                data: {
                    washOrEngineTurnHistory: data,
                    boatName: boatWash.boatName
                }
            });
        });
    }

    isOverdueTask(washEngineTurn): boolean {
        if (!washEngineTurn.washDate || washEngineTurn.washDate.substring(0, 10) < moment().format('YYYY-MM-DD')) {
            return true;
        }
        return false;
    }

    isEqualdueTask(washEngineTurn): boolean {
        if (washEngineTurn.washDate && washEngineTurn.washDate.substring(0, 10) === moment().format('YYYY-MM-DD')) {
            return true;
        }
        return false;
    }

    dialogOptionSchedule(chosenDate): void {
        let hasScheduleEngineTurn = false;
        Swal.fire({
            title: chosenDate != null ? 'Agendar! Escolha a opção abaixo!' : 'Cancelar! Escolha a opção abaixo!',
            icon: 'warning',
            reverseButtons: true,
            showDenyButton: true,
            confirmButtonColor: '#3085d6',
            denyButtonText: 'Lavação',
            denyButtonColor: '#5cb85c',
            confirmButtonText: 'Lavação e giro de motor'
        }).then((result) => {
            if (result.value) {
                hasScheduleEngineTurn = true;
            }
            if (!result.isDismissed) {
                if (chosenDate != null) {
                    this.confirmSchedule(chosenDate, hasScheduleEngineTurn);
                } else {
                    this.cancelSchedule(hasScheduleEngineTurn);
                }
            }
        });
    }

    confirmSchedule(chosenDate, hasScheduleEngineTurn): void {
        const schedulesWash: BoatWash[] = new Array();
        this.boatWashesTable.forEach((bw) => {
            if (bw.boatWashSelected) {
                bw.hasScheduleEngineTurn = hasScheduleEngineTurn;
                bw.washDate = moment(chosenDate).format('yyyy-MM-DDTHH:mm');
                bw.isSea = false;
                schedulesWash.push(bw);
            }
        });
        this.boatWashService.saveSchedule(schedulesWash).subscribe(() => {
            if (hasScheduleEngineTurn) {
                this.toasty.success('Lavação e giro de motor agendado com sucesso');
            } else {
                this.toasty.success('Lavação agendada com sucesso');
            }
            this.boatSelected = false;
        }, (error) => {
            const exception = error.error.data.exception;
            this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
        }
        );
    }

    cancelSchedule(deleteEngineTurn): void {
        const idCancelWash: number[] = new Array();
        this.boatWashesTable.forEach((bw) => {
            if (bw.boatWashSelected && bw.washDate != null) {
                bw.washDate = null;
                idCancelWash.push(bw.id);
            }
        });
        this.boatWashService.delete(idCancelWash, deleteEngineTurn).subscribe(
            () => {
                this.toasty.success('Lavação cancelada com sucesso');
                this.boatSelected = false;
                this.boatScheduleSelected = false;
            }, (error) => {
                const exception = error.error.data.exception;
                this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
            }
        );
    }

    onBoatSelect(): void {
        this.boatSelected = this.boatWashesTable.find((b) => b.boatWashSelected) !== undefined;
        this.boatScheduleSelected = this.boatWashesTable.find((b) => b.boatWashSelected && b.washDate !== null) !== undefined;
    }

    registerManual(): void {
        const boatsWash = this.boatWashesTable.filter((b) => b.boatWashSelected);
        this.dialog.open(BoatWashEngineTurnFormComponent, {
            header: 'Registro manual',
            width: '60%',
            height: '80%',
            data: {
                boatsWash,
                enginesTurn: this.boatsEngineTurn.filter((et) => boatsWash.find((bw) => bw.boatId === et.boatId))
            }
        });
    }

    totalizer(): void {
        this.washDetails.boatAll = this.boatWashes.length;
        this.boatWashes.forEach(obj => {
          if (this.scheduledVerify(obj)) { this.washDetails.boatScheduled++; }
          else if (this.neverScheduleVerify(obj)) { this.washDetails.boatNeverSchedule++; }
          else if (this.noScheduleVerify(obj)) { this.washDetails.boatNoSchedule++; }
        });
    }

    scheduledVerify(wash): boolean {
        return wash.washDate;
    }

    noScheduleVerify(wash): boolean {
        return wash.lastWashDate && (wash.washDate === undefined || wash.washDate == null)
    }

    neverScheduleVerify(wash): boolean {
        return (wash.lastWashDate === undefined || wash.lastWashDate === null)
        && (wash.washDate === undefined || wash.washDate == null);
    }
}
