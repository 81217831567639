import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MovementMean } from "src/app/models/movement/movement-mean";
import { environment } from "src/environments/environment";

@Injectable()
export class MovementMeanService {
  private readonly API_URL = environment.apiHost + '/secure/movement/movement-means';

    constructor(private http: HttpClient) { }

    getAll(): Observable<MovementMean[]> {
        return this.http.get(this.API_URL)
            .pipe(map((response: any) => response.data.movementMeans as MovementMean[]));
    }
}
