import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-qrcodes-dialog',
  templateUrl: './qrcodes-dialog.component.html',
  styleUrls: ['./qrcodes-dialog.component.scss']
})
export class QrcodesDialogComponent {
  path: any;
  constructor(
    public config: DynamicDialogConfig
  ) {
    this.path = this.config.data.path;
  }
}
