import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/models';
import { UserService } from 'src/app/services/user.service';
import { MessageUtil } from 'src/app/utils/message.util';

@Component({
  selector: 'app-check-user-bracelet',
  templateUrl: './check-user-bracelet.component.html',
  styleUrls: ['./check-user-bracelet.component.scss']
})
export class CheckUserBraceletComponent implements OnInit {

  @ViewChild('bracelet') bracelet: ElementRef;

  user = new User();
  braceletForm = new UntypedFormGroup({
    bracelet: new UntypedFormControl({ value: null, disabled: false }, Validators.required)
  });
  constructor(
    private userService: UserService,
    private messageService: MessageUtil
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.bracelet.nativeElement.focus();
    }, 500);
  }

  getUserByBracelet(): void {
    this.userService.bracelet(this.braceletForm.value.bracelet).subscribe(
      (data) => {
        this.user = data;
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title,
          'Pulseira '.concat(this.braceletForm.value.bracelet).concat(' não encontrada'));
        this.braceletForm.reset();
        this.user = new User();
      }
    );
  }
}
