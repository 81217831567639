import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { Conta, Marina, SlingConfig } from 'src/app/models';
import { MarinaCompany } from 'src/app/models/marina-company';
import { PaginationFilter } from 'src/app/models/pagination-filter';
import { BilletService } from 'src/app/services/billet.service';
import { CustomerService } from 'src/app/services/customer.service';
import { FinancesService } from 'src/app/services/finances.service';
import { InvoicePaidService } from 'src/app/services/invoice-paid.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { FormatUtil } from 'src/app/utils/format.util';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';
import moment from 'moment';
import b64toBlob from 'b64-to-blob';
import { ExtractInvoiceComponent } from 'src/app/components/extract-invoice/extract-invoice.component';
import Swal from 'sweetalert2';
import { LazyLoadEvent } from 'primeng/api';
import { StatusInvoice } from 'src/app/models/enums/status-invoice';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.scss']
})
export class ManagementComponent implements OnInit {

  globalFilter = '';
  billets: any[];
  billetsToSave: any[];
  billetsTable: any[];
  billetsTableOld: any[];
  slingConfig: SlingConfig;
  numberOfRows = 10;
  accounts: Array<{ label: string; value: number; }>;
  accountsTable: Array<{ label: string; value: number; }>;
  paymentMethods: Array<{ label: string; value: any; }>;
  total: number;
  filter: any = {
    startDueDate: null,
    endDueDate: null,
    endPayment: null,
    startPayment: null,
    endRepurchaseDate: null,
    startRepurchaseDate: null
  };
  customers: any;
  filteredCustomers: any;
  totalRecords: number;

  public yesNoOptions: Array<{ label: string, value: any }> = [
    { label: 'BOTH', value: null },
    { label: 'YES', value: true },
    { label: 'NO', value: false }
  ];
  yesNoList: { label: string; value: any; }[] = [];
  contentLoaded = false;
  marinaCompanies: MarinaCompany[];
  marinaCompaniesWithDatabase: MarinaCompany[];
  filteredCompanies: { label: string; value: any }[];
  selectedCompany: any;
  loggedMarina: Marina;
  accountsAll: Array<{ value: Conta; company?: number; }>;
  paymentMethodsAll: Array<{ value: any; company?: number; }>;
  paginationFilter: PaginationFilter = new PaginationFilter();
  statusInvoice = [];

  constructor(
    private sanitizer: DomSanitizer,
    private messageUtil: MessageUtil,
    private dialog: DialogService,
    private spinner: NgxSpinnerService,
    private slingConfigService: SlingConfigService,
    private invoiceService: InvoiceService,
    private financesService: FinancesService,
    private billetService: BilletService,
    private customerService: CustomerService,
    private invoicePaidService: InvoicePaidService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.spinner.show();
    this.optionsStatusInvoice();
    Promise.all([
      this.mountOptions(),
      this.findSlingConfig(),
      this.getCustomers()
    ]);
    await this.getCompanies();
    await this.clear();
    const accs = await this.findAccounts();
    this.accountsAll = accs.map((c) => ({ value: c }));
    const methods = await this.findPaymentMethods();
    this.paymentMethodsAll = methods.map((c) => ({ value: c }));
    this.paymentMethods = methods.map((a) => ({ label: a.descricao, value: a }));
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < this.marinaCompaniesWithDatabase.length; index++) {
      const element = this.marinaCompaniesWithDatabase[index];
      let list = await this.findAccounts(element);
      if (list.length > 0) {
        this.accountsAll = this.accountsAll.concat(list.map((a) => ({ value: a, company: element.id })));
      }
      list = await this.findPaymentMethods(element);
      if (list.length > 0) {
        this.paymentMethodsAll = this.paymentMethodsAll.concat(list.map((a) => ({ value: a, company: element.id })));
      }
    }
    await this.onChangePage(null, true);
    await this.spinner.hide();
    this.contentLoaded = true;
  }

  async getCompanies(): Promise<void> {
    const marina = StorageUtil.getMarina();
    this.marinaCompanies = marina.marinaCompanies;
    this.marinaCompaniesWithDatabase = await this.financesService.findCompaniesWithDatabase();
    if (this.marinaCompanies.length > 0) {
      this.filteredCompanies = this.marinaCompanies.map((c) => ({ label: c.companyFederalName, value: c }));
      this.filteredCompanies.unshift({ label: marina.companyName, value: marina });
    }
    this.selectedCompany = marina;
    this.loggedMarina = marina;
  }


  async findBillets(): Promise<void> {
    return new Promise<void>(
      async (resolve, reject) => {
        this.billetService.findAnticipated(this.filter, this.paginationFilter).subscribe(
          async (billetsTable) => {
            this.totalRecords = billetsTable.billets.totalElements;
            this.billetsTable = billetsTable.billets.content.map((b) => {
              if (b.repurchaseDate) {
                b.repurchaseDate = new Date(b.repurchaseDate);
              }
              if (b.paymentMethodIds) {
                const split = b.paymentMethodIds.split(',');
                b.paymentMethodIds = '';
                for (let index = 0; index < split.length; index++) {
                  if (Number.parseInt(split[index], 10) > 0) {
                    if (index > 0 && b.paymentMethodIds) {
                      b.paymentMethodIds += ',';
                    }
                    b.paymentMethodIds = split[index];
                  }
                }
              }
              if (b.accountIdsPaid) {
                const split = b.accountIdsPaid.split(',');
                b.accountIdsPaid = '';
                for (let index = 0; index < split.length; index++) {
                  if (Number.parseInt(split[index], 2) > 0) {
                    if (index > 0 && b.accountIdsPaid) {
                      b.accountIdsPaid += ',';
                    }
                    b.accountIdsPaid = split[index];
                  }
                }
              }
              if (b.paymentDates) {
                const split = b.paymentDates.split(',');
                b.paymentDates = '';
                for (let index = 0; index < split.length; index++) {
                  if (Number.parseInt(split[index], 2) > 0) {
                    if (index > 0 && b.paymentDates) {
                      b.paymentDates += ',';
                    }
                    b.paymentDates = split[index];
                  }
                }
              }
              return b;
            });
            this.billetsTableOld = this.billetsTable.map(b => Object.assign({},b) );
            this.total = billetsTable.total;
            resolve();
          },
          async () => {
            reject([]);
          }
        );
      }
    );
  }

  async findSlingConfig(): Promise<void> {
    return new Promise<void>(
      async (resolve, reject) => {
        this.slingConfigService.getSlingConfigToday().subscribe(
          async (slingConfig) => {
            this.slingConfig = slingConfig;
            if (this.slingConfig) {
              this.numberOfRows = this.slingConfig != null ? this.slingConfig.numberOfRecordsPerPage : 10;
              this.paginationFilter.size = this.numberOfRows;
            }
            resolve();
          },
          async () => {
            this.slingConfig = new SlingConfig();
            reject();
          }
        );
      }
    );
  }

  filterGlobal(): void {
    this.billets = this.billetsTable.filter(
      (g) => (
        FormatUtil.getNotAccents(g.lot).toUpperCase().includes(
          FormatUtil.getNotAccents(this.globalFilter).toUpperCase()
        )
      )
    );

  }

  getPaymentDates(paymentDates: string): string {
    let date = '';
    const dates = paymentDates ? paymentDates.split(',') : [];
    for (let index = 0; index < dates.length; index++) {
      const element = dates[index];
      if (index > 0) {
        date += ' ';
      }
      date += moment(element, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YY');
    }
    return date;
  }

  hasMultiplePayments(paymentDates: string): boolean {
    return paymentDates && paymentDates.split(',').length > 1 || false;
  }

  async extract(idInvoice): Promise<void> {
    await this.spinner.show();
    this.invoiceService.extract(idInvoice).subscribe(
      async (data) => {
        this.openExtract(
          this.sanitizer.bypassSecurityTrustResourceUrl(
            URL.createObjectURL(
              (b64toBlob as any)(data, 'application/pdf')
            )
          )
        );
      },
      async (error) => {
        await this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      },
      async () => await this.spinner.hide());
  }

  openExtract(path): void {
    this.dialog.open(ExtractInvoiceComponent, {
      width: '100%',
      height: '100%',
      dismissableMask: false,
      data: { path }
    });
  }

  async findAccounts(additionalCompany?: MarinaCompany): Promise<Conta[]> {
    return new Promise<Conta[]>(
      async (resolve, reject) => {
        this.financesService.findContasAtivas(additionalCompany).subscribe(
          async (accounts) => {
            resolve(accounts);
          },
          async (err) => {
            this.messageUtil.generateMessage('error', 'Erro ao buscar contas', err.error);
            reject([]);
          }
        );
      }
    );
  }

  async findPaymentMethods(additionalCompany?: MarinaCompany): Promise<any[]> {
    return new Promise<any[]>(
      async (resolve, reject) => {
        this.financesService.findTipoFormasPagamento(additionalCompany).subscribe(
          async (paymentMethods) => {
            resolve(paymentMethods);
          },
          async () => {
            reject();
          }
        );
      }
    );
  }

  getAccountNameById(accountId: number): string {
    const filter = this.accounts.filter((a) => a.value === accountId);
    if (filter.length > 0) {
      return filter[0].label;
    }
  }

  getAccountName(billet: any): void {
    let filter = [];
    if (billet.companyId) {
      if (!billet.accountIdsPaid || billet.accountIdsPaid === '') {
        filter = this.accounts.filter((a) => a.value === billet.accountId);
      } else {
        filter = this.accounts.filter((a) => billet.accountIdsPaid.includes(a.value));
      }
    } else {
      if (!billet.accountIdsPaid || billet.accountIdsPaid === '') {
        filter = this.accounts.filter((a) => a.value === billet.accountId);
      } else {
        filter = this.accounts.filter((a) => billet.accountIdsPaid.includes(a.value));
      }
    }
    if (filter.length > 0 && filter[0].value) {
      return filter[0].label;
    }
  }

  getAccountNamePayment(companyId: any, accountId: number): string {
    let filter = [];
    if (companyId) {
      filter = this.accountsAll.filter((a) => a.value.idConta === accountId && a.company === companyId);
    } else {
      filter = this.accountsAll.filter((a) => a.value.idConta === accountId && !a.company);
    }
    if (filter.length > 0 && filter[0].value) {
      return filter[0].value.nome;
    }
  }


  getPaymentMethodName(paymentMethodId): string {
    const filter = this.paymentMethods.filter((a) => a.value.idTipoFormaPagamento === paymentMethodId);
    if (filter.length > 0) {
      return filter[0].label;
    }
  }

  async fillTable(init?: boolean): Promise<void> {
    return new Promise<void>(
      async (resolve) => {
        if (!init) {
          await this.spinner.show();
        }
        this.total = 0;
        await this.findBillets();
        this.filterGlobal();
        if (!init) {
          await this.spinner.hide();
        }
        resolve();
      }
    );
  }

  saveBillets(): void {
    this.findChangedsBillets();

    if(this.billetsToSave.length <= 0){
      this.messageUtil.generateMessage('warning', 'Atenção', 'Nenhuma antecipação foi modificada!');
      return;
    }

    if (this.hasRepurchaseInconsistencies()) {
      this.messageUtil.generateMessage('warning', 'Atenção', 'Preencha a data, valor, juros e conta das recompras desejadas');
      return;
    }
    Swal.fire({
      title: 'Antecipação de Boletos',
      text: (this.billetsToSave.length > 1 ? `O status dos ${this.billetsToSave.length} BOLETOS da página será atualizado.`
        : 'O boleto será atualizado.') + 'Ao limpar os dados da recompra, o lançamento será cancelado. Deseja continuar?',
      icon: 'warning',
      backdrop: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then(async (result) => {
      if (result.value) {
        await this.spinner.show();
        this.billetService.repurchase(this.billetsToSave).subscribe(
          async () => {
            this.spinner.hide();
            this.messageUtil.generateMessage('success', 'SUMMARY.SUCCESS', 'Recompras atualizadas com sucesso.');
            await this.fillTable();
          },
          (err) => {
            const exception = err.error.data.exception;
            this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
          }
        );
      }
    });
  }

  findChangedsBillets(): void {
    this.billetsToSave = this.billets.filter(b => this.isChangedBillet(b));
  }

  isChangedBillet(billet): boolean{
    const billetOld = this.billetsTableOld.find(old => old.id === billet.id);
    return billetOld.repurchaseDate != billet.repurchaseDate
      || billetOld.repurchaseValue != billet.repurchaseValue
      || billetOld.repurchaseTax != billet.repurchaseTax
      || billetOld.repurchaseAccountId != billet.repurchaseAccountId;
  }

  hasIncorretPaymentMethod(paymentMethodIds: string): boolean {
    if (!paymentMethodIds) {
      return false;
    }
    const ids = paymentMethodIds.split(',');
    const filter = this.paymentMethods.filter(
      (a) => {
        if (
          ids.find((x) => (Number.parseInt(x, 10) === a.value.idTipoFormaPagamento))
          && (a.value.descricao.toUpperCase() !== 'BOLETO')
        ) {
          return a;
        }
      }
    );
    return (filter.length > 0);
  }

  hasRepurchaseInconsistencies(): any {
    return this.billetsToSave.find(
      (b) => (
        !(
          (!b.repurchaseDate && !b.repurchaseValue && !b.repurchaseTax && !b.repurchaseAccountId) ||
          (
            b.repurchaseDate &&
            b.repurchaseValue &&
            b.repurchaseAccountId &&
            (b.repurchaseTax >= 0)
          )
        )
      )
    );
  }

  async getCustomers(): Promise<void> {
    return new Promise<void>(
      async (resolve, reject) => {
        this.customerService.getAll().subscribe(
          async (data) => {
            this.customers = data;
            this.filteredCustomers = [{ label: 'Todos os clientes', value: null }].concat(
              this.customers.map(
                (c) => ({ label: c.name + ' ' + c.federalId, value: c.id })
              )
            );
            resolve();
          },
          async () => {
            reject();
          }
        );
      }
    );
  }

  async optionsStatusInvoice(){
    const status = Object.keys(StatusInvoice);
    this.statusInvoice = [];
    for(const index in status){
      this.statusInvoice.push({
        label: await this.messageUtil.translateKeyAsync(status[index]),
        value: status[index]
      });
    }
  }

  async mountOptions(): Promise<void> {
    return new Promise<void>(
      async (resolve) => {
        // tslint:disable-next-line: prefer-for-of
        for (let index = 0; index < this.yesNoOptions.length; index++) {
          this.yesNoList.push({
            label: await this.messageUtil.translateKeyAsync(this.yesNoOptions[index].label),
            value: this.yesNoOptions[index].value
          });
        }

        resolve();
      }
    );
  }

  async clear(): Promise<void> {
    this.filter = {
      startDueDate: null,
      endDueDate: null,
      endPayment: null,
      startPayment: null,
      endRepurchaseDate: null,
      startRepurchaseDate: null,
    };
    await this.changeCompany(true);
    this.billets = [];
    this.billetsTable = [];
    this.paginationFilter = new PaginationFilter();
    this.paginationFilter.sort = 'lot';
    this.paginationFilter.order = 'ASC';
    this.total = 0;
    this.totalRecords = 0;
  }

  findPayments(billet: any): void {
    if (billet.showPayments) {
      billet.showPayments = false;
    } else {
      this.invoicePaidService.findByInvoiceNumber(billet.invoiceNumber).subscribe(
        (payments) => {
          billet.payments = payments.filter((p) => !p.canceled);
          billet.showPayments = true;
        }
      );
    }
  }

  async changeCompany(init?: boolean): Promise<void> {
    if (!init) {
      await this.spinner.show();
    }
    this.filter.accountId = null;
    const accs = await this.findAccounts(this.getMarinaOrCompany());
    this.accounts = [{ label: 'Todas as contas', value: null }].concat(
      accs.map((a) => {
        return { label: a.nome, value: a.idConta };
      })
    );
    const acsTable = [];
    // accs.map((a) => {
    //   return { label: a.nome, value: a.idConta };
    // });
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < accs.length; index++) {
      const element = accs[index];
      acsTable.push({ label: element.nome, value: element.idConta });
    }
    this.accountsTable = [...acsTable];
    const methods = await this.findPaymentMethods(this.getMarinaOrCompany());
    this.paymentMethods = methods.map((a) => ({ label: a.descricao, value: a }));
    if (this.selectedCompany && this.selectedCompany.marinaCompanies) {
      this.filter.onlyMarina = true;
      this.filter.marinaCompanyId = null;
    } else if (this.selectedCompany) {
      this.filter.onlyMarina = false;
      this.filter.marinaCompanyId = this.selectedCompany.id;
    }
    if (!init) {
      await this.fillTable(init);
      await this.spinner.hide();
    }
  }

  getMarinaOrCompany(): MarinaCompany {
    if (!this.selectedCompany.marinaCompanies && this.marinaCompaniesWithDatabase.find((c) => c.id === this.selectedCompany.id)) {
      return this.selectedCompany;
    }
    return null;
  }

  getCompanyIndex(company: number): number {
    const m = this.loggedMarina.marinaCompanies.find((c) => c.id === company);
    return this.loggedMarina.marinaCompanies.indexOf(m) + 2;
  }

  getCompanyName(company: number): string {
    const c = this.loggedMarina.marinaCompanies.find((m) => m.id === company);
    return c ? ((c.companyFederalName) ? c.companyFederalName : '') : this.loggedMarina.companyName;
  }

  updateRepurchase(billet): void {
    if (billet.repurchaseDate) {
      billet.repurchaseAccountId = billet.accountId;
      if (!billet.repurchaseValue) {
        billet.repurchaseValue = billet.total;
      }
    } else {
      billet.repurchaseValue = null;
      billet.repurchaseTax = null;
      billet.repurchaseAccountId = null;
    }
  }

  async onChangePage(event?: LazyLoadEvent, init?: boolean): Promise<void> {
    if (event) {
      const page = event.first / event.rows;
      this.paginationFilter.sort = event.sortField;
      this.paginationFilter.page = page;
      this.paginationFilter.size = event.rows;
      this.paginationFilter.order = event.sortOrder === 1 ? 'ASC' : 'DESC';
    }
    await this.fillTable(init);
  }

  focusCalendar($event): void {
    $event.target.scrollIntoView();
  }
}
