import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EasyBankLogFilter } from '../../../models/easy_bank/easy-bank-log-filter';
import moment from 'moment';
import { StorageUtil } from '../../../utils/storage.util';
import { EasyMarineHttpApiLog } from '../../easy-marine-http-api-log';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { EasyBankLogDTO } from '../../../models/dtos/logs/easyBankLogDTO';

@Injectable({
  providedIn: 'root'
})
export class EasyBankLogsService {

constructor(
  private httpClient: EasyMarineHttpApiLog,
) { }

  getNixxBankLogs(filter: EasyBankLogFilter): Observable<EasyBankLogDTO[]> {
    const marinaId = StorageUtil.getMarinaId();
    let params = new HttpParams();
    if (filter.dateStart) {
      params = params.append('dateStart', moment(filter.dateStart).format());
    }
    if (filter.dateEnd) {
      params = params.append('dateEnd', moment(filter.dateEnd).format());
    }
    if (filter.userId) {
      params = params.append('userId', filter.userId.toString());
  }
  if (filter.customerId) {
      params = params.append('customerId', filter.customerId.toString());
  }
  if (filter.invoiceAction) {
      params = params.append('operationAction', filter.invoiceAction.toString());
  }

  return this.httpClient
    .get(`${environment.apiLog}/nixx-bank-log/${marinaId}`, { params })
    .pipe(map((response: any) => response));
  }
}
