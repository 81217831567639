import { Component, OnInit } from '@angular/core';
import { OperationalConfigService } from 'src/app/services/config/operational-config.service';

@Component({
  selector: 'app-siren-alert-config',
  templateUrl: './siren-alert-config.component.html',
  styleUrls: ['./siren-alert-config.component.scss']
})
export class SirenAlertConfigComponent implements OnInit {

  isSling: boolean = false;
  isMovement: boolean = false;
  constructor(
    private operationalConfigService:OperationalConfigService
    ) {}

  ngOnInit(): void {
    this.operationalConfigService.isMovements().then((isMovement: boolean) => {
      this.isMovement = isMovement;
      this.isSling = !isMovement;
    })
  }
}
