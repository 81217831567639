<p-fieldset>
  <p-header class="article-title">
      <span class="article-title">{{ 'TITLE-MOVEMENT-LOCATION-CONFIG' | translate }}</span>
      <div class="header-buttons"></div>
  </p-header>

  <p-table [value]="locations" autoLayout="true">
    <ng-template pTemplate="header">
      <tr>
        <th>
          Nome
        </th>
        <th>
          Tipo
        </th>
        <th>
          Abreviação
        </th>
        <th>
          Capacidade
        </th>
        <th>
          Status
        </th>
        <th class="text-center">
          Controle de Vaga
        </th>
        <th class="text-center">
          Utilizar Prefixo
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-movement>
      <tr>
        <td>
          {{movement.name}}
        </td>
        <td>
          {{ movement.type ? ('MOVEMENT.LOCALES.' + movement.type | translate) : ''}}
        </td>
        <td>
          {{movement.abbreviation}}
        </td>
        <td class="text-center">
          {{movement.capacity}}
        </td>
        <td class="text-center">
          {{(movement.active ? 'ACTIVE' : 'INACTIVE') | translate}}
        </td>
        <td class="text-center">
          <div *ngIf="updatingVacancyControl[movement.id];
            then notCanUpdateVacancyControl
            else canUpdateVacancyControl">
          </div>
          <ng-template #canUpdateVacancyControl>
            <a [pTooltip]="movement.vacancyControl ? VACANCY_CONTROL_TOOLTIP_TRUE : VACANCY_CONTROL_TOOLTIP_FALSE"
              class="badge badge-pill" [ngClass]="{'success':movement.vacancyControl, 'danger': !movement.vacancyControl}"
              tooltipPosition="bottom" (click)="updateVacancyControlModalConfirm(movement)"
            >
              {{ (movement.vacancyControl ? 'YES' : 'NO') | translate }}
            </a>
          </ng-template>
          <ng-template #notCanUpdateVacancyControl>
            <i class="pi pi-spin pi-spinner"></i>
          </ng-template>
        </td>

        <td class="text-center">
          <div *ngIf="updatingPrefixUse[movement.id];
            then notCanUpdatePrefixControl
            else canUpdatePrefixControl">
          </div>
          <ng-template #canUpdatePrefixControl>
            <a [pTooltip]="movement.prefixUse ? PREFIX_USE_TOOLTIP_TRUE : PREFIX_USE_TOOLTIP_FALSE"
              class="badge badge-pill" [ngClass]="{'success':movement.prefixUse, 'danger': !movement.prefixUse}"
              tooltipPosition="bottom" (click)="updatePrefixUseModalConfirm(movement)"
            >
              {{ (movement.prefixUse ? 'YES' : 'NO') | translate }}
            </a>
          </ng-template>
          <ng-template #notCanUpdatePrefixControl>
            <i class="pi pi-spin pi-spinner"></i>
          </ng-template>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-fieldset>
