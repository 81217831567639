import { Component } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { OperatingDayConfigService } from 'src/app/services/config/operating-day-config.service';
import { ToastService } from 'src/app/services/toast.service';
import { CreateScheduleQueueModalComponent } from '../modals/create-schedule-queue-modal/create-schedule-queue-modal.component';
import { ScheduleQueueFormModalComponent } from '../modals/schedule-queue-form-modal/schedule-queue-form-modal.component';

export class DaySettingForQueue {
  id: number;
}

export class ScheduleQueue {
  id: number;
  name: string;
  active: boolean;
  daysSetting: DaySettingForQueue[];
}

export class ScheduleQueueDto {
  id: number;
  name: string;
  active: boolean;
  groups: string[];
}

@Component({
  selector: 'app-schedule-queue',
  templateUrl: './schedule-queue.component.html',
  styleUrls: ['./schedule-queue.component.scss']
})
export class ScheduleQueueComponent {

  queues: ScheduleQueueDto[] = []

  updatingActiveStatus: {};

  constructor(
    private operatingDayConfigService: OperatingDayConfigService,
    private dialog: DialogService,
    private readonly toasty: ToastService
  ) {
    this.loadScheduleQueues();
  }

  loadScheduleQueues() {
    this.operatingDayConfigService.getAllScheduleQueueDTO().subscribe(
      (data) => {
        const updatingAcitveStatus = data.reduce((acc, queue) => { acc[queue.id] = false; return acc;}, {});
        this.updatingActiveStatus = { ...updatingAcitveStatus, ...this.updatingActiveStatus };
        this.queues = data;
      });
  }

  edit(queueId: number, queueName: string): void {
    this.dialog.open(ScheduleQueueFormModalComponent, {
      header: 'Editar fila de descida: ' + queueName,
      width: '95%',
      height: '80vh',
      data: {
        scheduleQueueId: queueId,
      }
    });
  }

  createNewScheduleQueue() {
    const dialogRef = this.dialog.open(CreateScheduleQueueModalComponent, {
      header: 'Criar nova fila de descida'
    })
    dialogRef.onClose.subscribe((result) => {
      if (result) {
        const {id, name} = result as { id: number, name: string };
        this.edit(id, name);
        this.loadScheduleQueues();
      }
    })
  }

  updateActiveStatus(queue: ScheduleQueueDto) {
    if (!this.updatingActiveStatus[queue.id]) {
      this.updatingActiveStatus[queue.id] = true;
      const queueDto = { id: queue.id, active: !queue.active};
      this.operatingDayConfigService.updateScheduleQueueActiveStatus(queueDto).subscribe(
        (data) => {
          queue.active = data.active;
          this.toasty.success('Status atualizado com sucesso!');
          this.updatingActiveStatus[queue.id] = false;
        },
        (error) => {
          const msgError = error?.error?.data?.exception?.message || 'Tente novamente mais tarde';
          this.toasty.error('Não foi possivel atualiza o status. ' + msgError);
          this.updatingActiveStatus[queue.id] = false;
        }
      );
    }
  }
}
