import { Component, OnInit } from '@angular/core';
import { MovementLocationService } from '../../../services/movements/movement-location.service';
import { MovementLocation } from '../../../models/movement/movement-location';
import { MessageUtil } from '../../../utils/message.util';
import { LocationInvoiceService } from '../../../services/location-invoice.service';
import { LocationInvoice } from '../../../models/location-invoice';
import { MovementLocationType } from 'src/app/models/enums/movements/movement-location-type';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { CustomReportExportComponent } from '../../../components/extract-custom-report/custom-report-export.component';

@Component({
  selector: 'app-location-invoice',
  templateUrl: './location-invoice.component.html',
  styleUrls: ['./location-invoice.component.scss'],
})
export class LocationInvoiceComponent implements OnInit {
  dateEnd: Date;
  dateStart: Date;

  movementLocationType: Array<{ label: string; value: any }> = [
    { label: 'Atracado', value: MovementLocationType.DRY_VACANCY },
    { label: 'Vaga Seca', value: MovementLocationType.MOORED },
    { label: 'Navegação', value: MovementLocationType.NAVIGATION },
    { label: 'Vaga Molhada', value: MovementLocationType.WET_VACANCY },
    { label: 'Pátio', value: MovementLocationType.YARD },
  ];
  locations: Array<{ label: string; value: any }> = [];
  movementLocations: MovementLocation[] = [];

  selectedMonth: any;
  selectedMovementType: number[] = [];
  selectedLocation: number[] = [];

  locationInvoiceTable: LocationInvoice[] = [];
  cols: any[];
  rows = 5;
  constructor(
    private movementLocationService: MovementLocationService,
    private locationInvoiceService: LocationInvoiceService,
    private spinner: NgxSpinnerService,
    private messageUtil: MessageUtil,
    public dialog: DialogService
  ) {}

  ngOnInit(): void {
    this.clear();
  }
  search(): void {
    const selectedLoc = [...this.selectedLocation];
    const selectedMovType = [...this.selectedMovementType];
    if (
      (selectedMovType === null || selectedMovType.length === 0) &&
      (selectedLoc === null || selectedLoc.length === 0)
    ) {
      this.movementLocations.map((item) => selectedLoc.push(item.id));
    }
    if (
      (selectedMovType !== null || selectedMovType.length !== 0) &&
      (selectedLoc === null || selectedLoc.length === 0)
    ) {
      this.locations.map((item) => selectedLoc.push(item.value));
    }
    const filter = {
      initialDate: this.dateStart,
      finalDate: this.dateEnd,
      movementLocation: selectedLoc,
    };
    this.spinner.show();
    this.locationInvoiceService.findAllByFilter(filter).subscribe({
      next: (resp) => {
        this.formatResponse(resp);
      },
      error: (err) => console.log(err),
    });
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);
  }
  findMovementLocations(): void {
    this.movementLocationService.getAll().subscribe({
      next: (resp) => {
        this.movementLocations = resp;
      },
      error: (err) => console.log(err),
    });
  }
  searchInitial(): void {
    this.spinner.show();

    this.locationInvoiceService.findAllByMarina().subscribe({
      next: (resp) => {
        this.formatResponse(resp);
        this.setMovementLocationsList();
      },
      error: (err) => console.log(err),
    });
    setTimeout(() => {
      this.spinner.hide();
    }, 2000);
  }
  formatResponse(resp: any) {
    this.locationInvoiceTable = [];
    resp.map((item) => {
      Object.keys(item).map((attr) => {
        if (
          (attr === 'averageFootValue' ||
            attr === 'averageBoatValue' ||
            attr === 'totalInvoicing') &&
          item[attr] !== null
        ) {
          const valueFormated = new Intl.NumberFormat('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(item[attr]);
          item[attr] = `R$${valueFormated}`;
        }
        if (item[attr] === null) {
          item[attr] = '---';
        }
      });
    });
    this.locationInvoiceTable = resp;
  }
  clear() {
    this.dateStart = moment().utc(true).toDate();
    this.dateEnd = moment().utc(true).toDate();
    this.dateEnd.setDate(this.dateEnd.getDate() + 1);
    this.findMovementLocations();
    this.searchInitial();
  }

  alterMovementLocations(): void {
    this.locations = [];
    this.selectedLocation = [];
    if (this.selectedMovementType.length > 0) {
      this.movementLocations.map((mov) => {
        this.selectedMovementType.map((movType) => {
          if (mov.type.toString() === movType.toString()) {
            this.locations.push({ label: mov.name, value: mov.id });
          }
        });
      });
    } else {
      this.setMovementLocationsList();
    }
  }

  setMovementLocationsList() {
    this.movementLocations.map((item) => {
      this.locations.push({ label: item.name, value: item.id });
    });
  }

  exportTable() {
    console.log(this.locationInvoiceTable);
    const data = { table: this.locationInvoiceTable, type: 'LOCATION_INVOICE' };
    this.dialog.open(CustomReportExportComponent, {
      width: '70%',
      height: '90vh',
      dismissableMask: false,
      data,
      header: this.messageUtil.translateKey('EXPORT-LOCATION-INVOICE-TABLE'),
    });
  }

  printreg(invoiceLoc) {
    console.log(invoiceLoc);
  }
}
