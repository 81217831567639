import { FinancesService } from 'src/app/services/finances.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectItem } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { ProductType } from 'src/app/models/enums';
import { InvoiceTaxProductService } from 'src/app/services/financial/invoice-Tax-Product.service';
import { ProductService } from 'src/app/services/product.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { Router } from '@angular/router';
import { InvoiceTaxDocumentProduct } from 'src/app/models/invoice-Tax-Document-Product';
import Swal from 'sweetalert2';
import { Product } from 'src/app/models';
import { Fornecedor } from 'src/app/models/finance/fornecedor';
import { DialogService } from 'primeng/dynamicdialog';
import { CreateProgramacaoDialogComponent } from 'src/app/pages/finances/create-programacao-dialog/create-programacao-dialog.component';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { MarinaCompany } from 'src/app/models/marina-company';
import {
  FinancialMultipleCompaniesOption,
  FinancialMultipleCompaniesService,
} from 'src/app/services/financial/financial-multiple-companies.service';
import { MovementTypeService } from 'src/app/services/stock/movement-type.service';
import { forkJoin } from 'rxjs';
import { StockMovementType } from 'src/app/models/stock/stock-movement-type';

@Component({
  selector: 'app-import-nfp',
  templateUrl: './import-nfp.component.html',
  styleUrls: ['./import-nfp.component.scss'],
})
export class ImportNfpComponent implements OnInit {
  @ViewChild('upload', { static: false }) upload: FileUpload;

  supplierList: SelectItem[] = [];
  arquivoProcessado: InvoiceTaxDocumentProduct =
    new InvoiceTaxDocumentProduct();
  uploadReady = true;
  file: File;
  totalRegister: number = 0;
  selectedItensList: any[] = [];
  products: SelectItem[] = [];
  hasProgramacao: boolean = false;
  costCenterInStock: any;

  financialCompany: MarinaCompany = null;
  financialMultipleCompanyOptions: FinancialMultipleCompaniesOption[];

  stockMovementTypesList: StockMovementType[] = [];
  stockMovementAdjustmentIn: number;

  uploadedFile: any;

  constructor(
    public dialog: DialogService,
    private spinner: NgxSpinnerService,
    private messageUtil: MessageUtil,
    private invoiceTaxProductService: InvoiceTaxProductService,
    private productService: ProductService,
    private financesService: FinancesService,
    private router: Router,
    private slingConfigService: SlingConfigService,
    private financialMultipleCompaniesService: FinancialMultipleCompaniesService,
    private movementTypeService: MovementTypeService
  ) {}

  ngOnInit(): void {
    if (history.state.taxdoc) {
      this.arquivoProcessado = history.state.taxdoc;
      for (const item of this.arquivoProcessado.items) {
        if (item.product == null) {
          item.product = { id: null };
        } else {
          let product: Product = new Product();
          product.id = item.product.id;
          product.name = item.product.name;
          item.product = product;
        }
      }

      this.uploadReady = false;
    }

    this.loadingAllConfigs();
    this.loadFinancialMultipleCompany();
    this.loadProducts();
    this.loadSupplierList();
  }

  onBasicUpload(files): void {
    this.spinner.show();
    this.uploadedFile = files;
    this.invoiceTaxProductService.returnProcess(files).subscribe(
      (response) => {
        this.arquivoProcessado = response;

        if (this.stockMovementAdjustmentIn != null) {
          this.arquivoProcessado.stockMovementType =
            this.stockMovementTypesList.find(
              (x) => x.id == this.stockMovementAdjustmentIn
            );
        }

        this.uploadReady = false;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.messageUtil.generateMessage(
          MessageUtil.WARNING,
          'Alerta',
          'Não foi possível enviar ou processar o arquivo!'
        );
      }
    );

    this.upload.clear();
  }

  loadingAllConfigs() {
    this.spinner.show();
    forkJoin({
      slingConfig: this.slingConfigService.getSlingConfig(),
      stockMovementTypes: this.movementTypeService.getAll(),
    }).subscribe({
      next: (data) => {
        console.log(data);

        this.costCenterInStock = data.slingConfig[0].costCenterInStock;
        this.stockMovementAdjustmentIn =
          data.slingConfig[0].stockMovementAdjustmentIn;

        console.log(data.stockMovementTypes);

        this.stockMovementTypesList = data.stockMovementTypes.filter(
          (x) => x.active === true
        );
      },
      error: (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
      complete: () => this.spinner.hide(),
    });
  }

  loadSupplierList(): void {
    this.financesService.findFavorecidos().subscribe(
      (data) => {
        this.supplierList = data.map((p) => {
          return { label: p.nome, value: p.idFavorecido };
        });
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      }
    );
  }

  setFile(file): void {
    this.upload.clear();
    this.file = file[0];
    this.onBasicUpload(file[0]);
  }

  isSelected(invTaxDoc) {}
  selectInvoiceTax(invTaxDoc) {}

  loadProducts(): void {
    this.spinner.show();
    this.productService.getByProductType(ProductType.Product).subscribe(
      (data) => {
        this.products = data.map((p) => {
          return { label: p.name, value: p.id };
        });
        this.spinner.hide();
      },
      (error) => {
        console.log(error);

        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
        this.spinner.hide();
      }
    );
  }

  clear(): void {
    this.router.navigate(
      ['/app/billing/invoice-tax-management/invoice-tax-product'],
      { state: {} }
    );
  }

  save(): void {
    this.arquivoProcessado.marina = null;
    this.invoiceTaxProductService
      .saveImport(this.arquivoProcessado)
      .subscribe((data) => {
        this.messageUtil.generateMessage(
          MessageUtil.SUCCESS,
          'Sucesso',
          'Arquivo importado com sucesso!'
        );
      });
  }

  endImport(): void {
    if (this.validToImport() == false) {
      this.messageUtil.generateMessage(
        MessageUtil.WARNING,
        'Alerta',
        'Não é possível finalizar a importação! Verifique se todos os produtos foram selecionados.'
      );
      return;
    }
    let mensagem =
      "Confirma a operação? <br> <span style='color: red; font-style: normal; font-size: large; '> Ao finalizar a Importação a mesma não poderá ser alterada. </span>";

    let confirmText = '';

    Swal.fire({
      icon: 'warning',
      title: mensagem,
      showCancelButton: true,
      text: confirmText,
      confirmButtonText: 'Confirmar',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      cancelButtonColor: '#d33',
      allowOutsideClick: false,
      preConfirm: () => {
        this.arquivoProcessado.marina = null;
        this.invoiceTaxProductService
          .endImport(this.arquivoProcessado, this.uploadedFile)
          .subscribe({
            next: (data) => {
              this.messageUtil.generateMessage(
                MessageUtil.SUCCESS,
                'Sucesso',
                'Arquivo importado com sucesso!'
              );
            },
            error: (error) => {
              this.messageUtil.generateMessage(
                MessageUtil.WARNING,
                'Alerta',
                ' Não foi possível finalizar a importação! Verifique se esta nota já foi importada.'
              );
            },
          });
      },
    });
  }

  validToImport(): boolean {
    if (this.arquivoProcessado == null) {
      return false;
    }

    if (this.arquivoProcessado.items == null) {
      return false;
    }

    if (this.arquivoProcessado.items.length == 0) {
      return false;
    }

    if (this.arquivoProcessado.status == 'Concluido') {
      return false;
    }

    this.arquivoProcessado.items.forEach((item) => {
      if (item.product == null) {
        return false;
      }
      if (!item.aproved) {
        return false;
      }
    });

    if (this.arquivoProcessado.stockMovementType == null) {
      return false;
    }

    return true;
  }

  createNewFornecedor(): void {
    this.spinner.show();
    let newFornecedor: Fornecedor = {
      razaoSocial: this.arquivoProcessado.supplierName,
      nomeFantasia: this.arquivoProcessado.supplierName,
      status: 'ATIVO',
      tipoPessoa: 'CANCELADO',
      cnpj: this.arquivoProcessado.federalId,
      sexo: 'MASCULINO',
    };
    this.invoiceTaxProductService.createFornecedor(newFornecedor).subscribe({
      next: (data) => {
        this.messageUtil.generateMessage(
          MessageUtil.SUCCESS,
          'Sucesso',
          'Fornecedor criado com sucesso!'
        );
        this.loadSupplierList();
        this.spinner.hide();
      },
      error: (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
        this.spinner.hide();
      },
    });
  }

  validateFornecedor(): boolean {
    let fornecedor = this.supplierList.find((value) => {
      return value.label == this.arquivoProcessado.supplierName;
    });

    if (fornecedor == null) {
      return false;
    }
    this.arquivoProcessado.supplierId = fornecedor.value;
    return true;
  }

  createProgramacao(): void {
    let totalValue = 0;
    this.arquivoProcessado.items.forEach(
      (item) => (totalValue += item.productNfValue)
    );
    if (!this.arquivoProcessado.supplierId) {
      this.messageUtil.generateMessage(
        MessageUtil.WARNING,
        'Alerta',
        'Não é possível criar a programação! Verifique se o fornecedor foi selecionado.'
      );
      return;
    }

    let data = {
      valorTotal: totalValue,
      createDate: this.arquivoProcessado.createDate,
      supplierName: this.arquivoProcessado.supplierName,
      supplierId: this.arquivoProcessado.supplierId,
      nfeNumero: this.arquivoProcessado.nfeNumero,
      marinaCompany: this.financialCompany
        ? this.financialCompany
        : this.financialMultipleCompanyOptions[0]?.label,
      useCostCenterInStock: this.costCenterInStock,
    };

    this.dialog
      .open(CreateProgramacaoDialogComponent, {
        width: '50vw',
        header: 'Criar Programação Financeira',
        data,
      })
      .onClose.subscribe((value) => {
        if (value != undefined) {
          this.hasProgramacao = value;
        }
      });
  }

  loadFinancialMultipleCompany(): FinancialMultipleCompaniesOption[] {
    if (this.financialMultipleCompanyOptions == undefined) {
      this.financialMultipleCompanyOptions =
        this.financialMultipleCompaniesService.getBillingCompanyOptions(false);
    }

    return this.financialMultipleCompanyOptions;
  }
}
