<div class="dialog-form">
    <form (submit)="save()" [formGroup]="contractForm">
        <div class="form-group" *ngIf="!contractData">
          <div class="form-control">
            <label>{{ 'CONTRACT_TYPE' | translate }}*</label>
              <p-dropdown placeholder="Selecione..." name="contractType"
                formControlName="contractType"
                [options]="contractTypeList"
                [disabled]="disableForm"
                (onChange)="loadBoatsWithContractTypeSelected()">
            </p-dropdown>
          </div>

          <div class="form-control">
            <label>{{ 'BOAT' | translate }}*</label>
            <p-dropdown placeholder="Selecione..."
                        optionLabel="name" formControlName="boat" name="selectedBoatId"
                        [filter]="true"
                        [options]="contractSelectedTypeOptionBoats"
                        [emptyFilterMessage]="messageEmptyService()"
                        [readonly]="disableForm"
                        (onChange)="loadServicesWhitSelectedBoat()">
              <ng-template let-boatList pTemplate="selectedItem">
                <span>{{boatList.name}}</span>
              </ng-template>
            </p-dropdown>
          </div>
          <div class="form-control">
            <label>{{ 'CUSTOMER' | translate}}*</label>
            <p-multiSelect placeholder="Clientes" selectedItemsLabel="{0} clientes selecionados" [filter]="true" [options]="customersBoat" optionLabel="name"
                           [emptyFilterMessage]="messageEmptyBoatCustomer()" formControlName="customerIds"
                           emptyMessage="Embarcação sem clientes"
                           optionValue="id">

            </p-multiSelect>
          </div>
      </div>

        <div class="form-control">
          <label>{{'SERVICE' | translate}}*</label>
          <p-multiSelect placeholder="Selecione..."
              name="selectedServiceId"
              optionLabel="label"
              optionValue="value"
              appendTo="body"
              [options]="contractSelectedTypeAndBoat"
              [filter]="true"
              [(ngModel)]="selectedServices"
              [ngModelOptions]="{standalone: true}"
               [readonly]="disableForm"
              [emptyFilterMessage]="messageEmptyService()">
          </p-multiSelect>
      </div>

        <div class="form-group" *ngIf="contractData">
            <div class="form-control">
                <label>{{ 'BOAT' | translate }}</label>
                <span>{{contractData.boatName}}</span>
            </div>
            <div class="form-control">
                <label>{{ 'SERVICE' | translate }}</label>
                <span>{{contractData.serviceName}}</span>
            </div>
        </div>

        <div class="form-group" *ngIf="contractData">
            <div class="form-control">
                <label>{{ 'CONTRACT_TYPE' | translate }}</label>
                <span>{{contractData.contractType | translate }}</span>
            </div>
        </div>

        <p-tabView [activeIndex]="activeIndex" (onChange)="handlerDocumentType($event.index)">
            <p-tabPanel header="Documento assinado">
                <div style="height: 150px;">
                    <div class="form-group" style="flex-direction: row; align-items: center;">
                        <div class="form-control">
                            <p-dropdown placeholder="Selecione..." [filter]="true" [options]="templateListContract"
                                appendTo="body" formControlName="template" name="selectedTemplateId">
                            </p-dropdown>
                        </div>
                        <div class="form-control">
                            <button class="easymarine primary" type="button" (click)="generateDocument()"
                                style="width: 200px; height: 32px;"
                               >Gerar
                                Documento</button>
                        </div>
                    </div>
                    <div class="form-group  field-file">
                        <p-fileUpload name="attachment" mode="basic" maxFileSize="100000000"
                            chooseLabel="Anexar Documento assinado" fileLimit=1 accept=".PDF"
                            invalidFileTypeMessageDetail="Formato aceito: {0}" invalidFileTypeMessageSummary="{0}: tipo de arquivo inválido,"
                            (uploadHandler)="setFile($event.files)" [auto]="true" customUpload="true" #upload>
                        </p-fileUpload>
                        <span style="margin-left: 10px;"> {{file?.name}}</span>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel header="Assinatura digital" class="tab-panel" [disabled]="enableDigitalSignature()">
              <div class="content-digital-sign">
                  <div class="fields-digital">
                      <div class="form-group radios">
                          <div style="padding-right: 20px; padding-left: 10px;">
                              <p-radioButton name="type" value="template" label="Template" formControlName="type"
                                  inputId="templateType" [(ngModel)]="type" required>
                              </p-radioButton>
                          </div>
                          <p-radioButton name="type" value="file" label="Arquivo" formControlName="type"
                              inputId="fileType" required [(ngModel)]="type">
                          </p-radioButton>
                      </div>

                      <div class="form-group" style="height: 80px;">
                          <div class="form-control" *ngIf="isType('template')">
                              <p-dropdown placeholder="Selecione..." [filter]="true" [options]="templateListContract"
                                  appendTo="body" formControlName="template" name="selectedTemplateId">
                              </p-dropdown>
                          </div>
                          <div class="form-control field-file" *ngIf="isType('file')">
                              <p-fileUpload name="attachment" mode="basic" maxFileSize="100000000"
                                  chooseLabel="Anexar" fileLimit=1 accept=".PDF"
                                  invalidFileTypeMessageDetail="Formato aceito:{0}" invalidFileTypeMessageSummary="{0}: tipo de arquivo inválido,"
                                  (uploadHandler)="setFile($event.files)" [auto]="true" customUpload="true" #upload>
                              </p-fileUpload>
                              <span style="margin-left: 10px;"> {{file?.name}}</span>
                          </div>
                      </div>
                  </div>
                  <a href='https://secure.d4sign.com.br/gopaperless/sustentabilidade/6f821790-0eff-403c-bbc3-f12db321dcad/SCC4-SERVICOS-DE-CONTROLE-LTDA.html'
                      target='_blank'>
                      <img src='https://secure.d4sign.com.br/img/selos_2022_empresa-pt.png' width='400px;'title='D4Sign' alt='D4Sign'>
                  </a>
              </div>
          </p-tabPanel>
        </p-tabView>


        <div class="form-group align-center">
            <div class="form-control">
                {{'REQUIRED-FIELDS' | translate}}
            </div>
            <div class="buttons">
                <button class="easymarine danger" type="button" (click)="dialogRef.close()">{{ 'CANCEL' | translate }}</button>
                <button class="easymarine primary" type="submit" [disabled]="!isValid()">{{ 'SAVE' | translate }}</button>
            </div>
        </div>
    </form>
</div>
