import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SirenAlertConfig } from 'src/app/models/siren/siren-alert-config';

@Injectable()
export class SirenAlertConfigService {

    constructor(private http: HttpClient) {

    }

    getOne(): Observable<SirenAlertConfig> {
        return this.http.get(environment.apiHost + '/secure/sirenAlertConfig/')
            .pipe(map((response: any) => response.data.sirenAlertConfig as SirenAlertConfig));
    }

    save(sirenAlertConfig: SirenAlertConfig): Observable<any> {
        return this.http.post(environment.apiHost + '/secure/sirenAlertConfig/', sirenAlertConfig)
            .pipe(map((response: any) => response));
    }

}
