<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">Faturamento > Débitos diários</span>
        <button class="fab-button primary" appFiltersButton pTooltip="Exibir/Ocultar Filtros" tooltipPosition="left">
            <i class="pi pi-filter"></i>
        </button>
    </p-header>

    <form class="filters" (submit)="find()" #f appFilters>
        <div class="filter-row">
            <div class="form-control">
                <label>Data</label>
                <p-calendar dateFormat="dd/mm/yy" name="filter.requestDate" [(ngModel)]="filter.requestDate"
                    [showIcon]="true" i18n [monthNavigator]="true" [yearNavigator]="true"
                    [yearRange]="'2000:2055'" rPCalendarMask appendTo="body">
                </p-calendar>
            </div>
            <div class="buttons">
                <button type="submit" class="easymarine primary"
                    [disabled]="f.invalid || !validDate">{{ 'FIND' | translate }}
                </button>
            </div>
        </div>
    </form>

    <div class="table">
        <p-table #tt [value]="ordersTable" [paginator]="true" [rows]="numberOfRows" resetPageOnSort="true"
            [pageLinks]="3" [rowsPerPageOptions]="[10,20,30,50,100]" autoLayout="true"
            [globalFilterFields]="['boatName','nameCustomer','totalCustomer']" styleClass="easy-table">
            <ng-template pTemplate="caption">
                <div class="fill-empty"></div>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText size="50" placeholder="Filtro global" [(ngModel)]="globalFilter"
                        (input)="filterGlobal()" style="width:auto">
                </span>
                <span style="font-weight: bold; float: right; margin-top: 5px;">{{ 'TOTAL-REGISTERS' | translate }}:
                    {{tt.totalRecords}} {{'OF' | translate}} {{ordersTable.length}}
                </span>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th [pSortableColumn]="'boatName'">
                        {{'BOAT' | translate}}
                        <p-sortIcon [field]="'boatName'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'nameCustomer'">
                        {{'CUSTOMER' | translate}}
                        <p-sortIcon [field]="'nameCustomer'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'totalCustomer'">
                        {{'TOTAL' | translate}}
                        <p-sortIcon [field]="'totalCustomer'"></p-sortIcon>
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-order>
                <tr>
                    <td>{{order.boatName}}</td>
                    <td>{{order.nameCustomer}}</td>
                    <td>{{order.totalCustomer | number:'1.2-2'}}</td>
                    <td class="text-center">
                        <button *ngIf="order.totalCustomer !== 0" class="table-icon"
                            [routerLink]="['/app/billing/debits', order.idCustomer]" pTooltip="Débitos" tooltipPosition="bottom">
                            <fa-icon [icon]="'credit-card'" class="regular">
                            </fa-icon>
                        </button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="4">
                        Nenhum cliente encontrado
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-fieldset>