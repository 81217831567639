import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, Input, forwardRef } from '@angular/core';

@Component({
  selector: 'app-multi-select-with-translate',
  templateUrl: './multi-select-with-translate.component.html',
  styleUrls: ['./multi-select-with-translate.component.scss'],
  providers:  [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectWithTranslateComponent),
      multi: true
    }
  ]
})
export class MultiSelectWithTranslateComponent implements ControlValueAccessor {

  @Input()
  options;
  @Input()
  translateKey: string;

  filterActive;

  private onChanges: any = () => {}
  private onTouched: any = () => {}
  disabled: boolean = false;

  constructor() { }

  writeValue(obj: string[]): void {
    this.filterActive = obj !== null && obj !== undefined  ? obj : [];
  }
  registerOnChange(fn: any): void {
     this.onChanges = fn
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
  }

  sendChanges(event) {
    this.onChanges(event.value)
  }

  getItem(item) {
    const itemObj = this.options.filter(element => element.value === item);

    if (itemObj.length > 0) {
      return itemObj[0].label
    }

    return item
  }

}
