<form class="filters active" (submit)="search()">
  <div class="filter-row">
    <div class="form-control">
      <label>{{ "CUSTOMER" | translate }}</label>
      <p-dropdown
        [filter]="true"
        [options]="filteredCustomers"
        [(ngModel)]="filter.customerId"
        appendTo="body"
        name="filter.customerId"
      >
      </p-dropdown>
    </div>

    <div class="form-control">
      <label>{{ "BOAT" | translate }}</label>
      <p-dropdown
        [filter]="true"
        [options]="filteredBoats"
        [(ngModel)]="filter.boatId"
        appendTo="body"
        name="filter.boatId"
      >
      </p-dropdown>
    </div>

    <div class="form-control">
      <label>{{ "SERVICE" | translate }}</label>
      <p-dropdown
        [filter]="true"
        [options]="filteredServicesInUse"
        [(ngModel)]="filter.serviceId"
        appendTo="body"
        name="filter.serviceId"
      >
      </p-dropdown>
    </div>

    <div class="form-control">
      <label>{{ "Inicio serviço de" | translate }}</label>
      <p-calendar
        dateFormat="dd/mm/yy"
        name="contractStartDateFrom"
        [(ngModel)]="filter.contractStartDateFrom"
        appendTo="body"
        [showIcon]="true"
        i18n
        [monthNavigator]="true"
        [yearNavigator]="true"
        [yearRange]="'2000:2055'"
        rPCalendarMask
      >
      </p-calendar>
    </div>
    <div class="form-control">
      <label>{{ "Inicio serviço até" | translate }}</label>
      <p-calendar
        dateFormat="dd/mm/yy"
        name="contractStartDateTo"
        [monthNavigator]="true"
        [yearNavigator]="true"
        appendTo="body"
        [yearRange]="'2000:2055'"
        rPCalendarMask
        [(ngModel)]="filter.contractStartDateTo"
        [showIcon]="true"
        i18n
      >
      </p-calendar>
    </div>

    <div class="form-control">
      <label>{{ "Fim serviço de" | translate }}</label>
      <p-calendar
        dateFormat="dd/mm/yy"
        name="contractEndDateFrom"
        [(ngModel)]="filter.contractEndDateFrom"
        appendTo="body"
        [showIcon]="true"
        i18n
        [monthNavigator]="true"
        [yearNavigator]="true"
        [yearRange]="'2000:2055'"
        rPCalendarMask
      >
      </p-calendar>
    </div>

    <div class="form-control">
      <label>{{ "Fim serviço até" | translate }}</label>
      <p-calendar
        dateFormat="dd/mm/yy"
        name="contractEndDateTo"
        [monthNavigator]="true"
        [yearNavigator]="true"
        appendTo="body"
        [yearRange]="'2000:2055'"
        rPCalendarMask
        [(ngModel)]="filter.contractEndDateTo"
        [showIcon]="true"
        i18n
      >
      </p-calendar>
    </div>

      <div class="form-control">
        <label>{{ "Reajuste de" | translate }}</label>
        <p-calendar
          dateFormat="dd/mm/yy"
          name="reajustmentEndDateTo"
          [monthNavigator]="true"
          [yearNavigator]="true"
          appendTo="body"
          [yearRange]="'2000:2055'"
          rPCalendarMask
          [(ngModel)]="filter.reajustmentEndDateFrom"
          [showIcon]="true"
          i18n
        >
        </p-calendar>
      </div>
        <div class="form-control">
          <label>{{ "Rejuste até" | translate }}</label>
          <p-calendar
            dateFormat="dd/mm/yy"
            name="reajustmentEndDateTo"
            [monthNavigator]="true"
            [yearNavigator]="true"
            appendTo="body"
            [yearRange]="'2000:2055'"
            rPCalendarMask
            [(ngModel)]="filter.reajustmentEndDateTo"
            [showIcon]="true"
            i18n
          >
          </p-calendar>
        </div>

  </div>
  <div class="filter-row">
    <div class="buttons">
      <div class="form-button">
        <button (click)="clear()" class="danger easymarine" type="button">
          {{ "CLEAR" | translate }}
        </button>
      </div>
      <div class="form-button">
        <button class="primary easymarine" type="submit">
          {{ "FIND" | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
<form
  (submit)="save()"
  [formGroup]="adjustmentForm"
  style="
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    margin-top: 30px;
  "
>
  <div style="width: 100%;  display: flex;  align-items: center; ">
<div style="width: 50%;" >
    <button class="success ajust-buton-email"
      type="button"
      (click)="confirmSendEmail()"

      [disabled]="enableSendEmails()"
    >
      Enviar por email
    </button>
</div>
<div class="form-readjustment">
      <div>
      <label for="boatName">{{ "Reajuste em porcentagem" | translate }}</label>
      <input
        pInputText
        type="number"
        formControlName="percentage"
        min="0"
        name="percentage"
      />
    </div>
    <div >
      <label for="boatName">{{ "Aplicação" | translate }}</label>
      <div style="display: flex; flex-direction: row; height: 30px">
        <p-radioButton
          style="white-space : nowrap"
          name="readjustmentType"
          formControlName="readjustmentType"
          *ngFor="let type of readjustmentTypes"
          [value]="type.value"
          [label]="this.messageUtil.translateKey('READJUSTMENT.' + type.label)"
          [inputId]="type.value"
        >
        </p-radioButton>
      </div>
    </div>
    <div>
      <label for="boatName">{{ "Próximo Reajuste (Meses)" | translate }}</label>
      <input
        pInputText
        type="number"
        formControlName="diasReajuste"
        min="0"
        name="diasReajuste"
      />
    </div>
    <button
      class="primary easymarine"
      type="button"
      (click)="readjustment();showHide();"
      style="justify-self: flex-end"
      [disabled]="enableApllyPorcentagemSelectedService()"
    >
      Reajuste
    </button>
  </div>

  </div>
</form>
<div class="table">
  <p-table
    #documentTable
    [value]="services"
    class="primeTable"
    [(selection)]="selectedServices"
    autoLayout="true"
    styleClass="easy-table"
    [lazy]="true"
  >
    <ng-template pTemplate="caption">
      <div style="display: flex; width: 100%; justify-content: space-between">
        <div class="buttons-export">
          <!-- <button class="btn-csv-xls easymarine" label="Exportar CSV" (click)="exportTable()"
                            pTooltip="A exportação será de acordo com os filtros selecionados" tooltipPosition="top">
                            <span>Exportar CSV</span>
                            <i class="pi pi-file-excel"></i>
                        </button>
                        <button class="btn-pdf easymarine" type="button" (click)="readjustment()">
                            <span>Exportar PDF</span>
                            <i class="pi pi-file-pdf"></i>
                        </button> -->
        </div>
        <span style="font-weight: bold"
          >{{ "TOTAL-REGISTERS" | translate }}: {{ services.length }}</span
        >
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr style="text-align: center;" >
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th class="format-total">{{ totalValues | number: "1.2-2" }}</th>
        <th class="format-total">{{ totalDiscounts | number: "1.2-2" }}</th>
        <th class="format-total">
          {{ totalDueDateDiscounts | number: "1.2-2" }}
        </th>
        <th class="format-total">
          {{ calcTotalValueTotal() | number: "1.2-2" }}
        </th>
        <th class="format-total">
          {{ totalReadjustmentsValues | number: "1.2-2" }}
        </th>
        <th class="format-total">
          {{ totalReadjustmentsDiscounts | number: "1.2-2" }}
        </th>
        <th class="format-total">
          {{ totalReadjustmentsDueDateDiscounts | number: "1.2-2" }}
        </th>
        <th class="format-total">
          {{ calcReadjustementTotalValueTotal() | number: "1.2-2" }}
        </th>
        <th  class="format-total">{{ differenceTotals() | number: "1.2-2" }}</th>
        <th colspan="2"></th>

      </tr>

      <tr class="ajust-header">
        <th class="text-center">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th>
          {{ "BOAT" | translate }}
        </th>
        <th *ngIf="false">
          {{ "TYPE" | translate }}
        </th>
        <th >TC.</th>
        <th>
          {{ "SERVICE" | translate }}
        </th>
        <th>{{ "OBS" | translate }}</th>
        <th>
          {{ "Inicio serviço" | translate }}
        </th>
        <th>
          {{ "Fim serviço" | translate }}
        </th>
        <th>
          {{ "Último reajuste" | translate }}
        </th>
        <th>{{ "VALUE" | translate }}</th>
        <th>{{ "DISCOUNT" | translate }}</th>
        <th>{{ "DUE-DATE-DISCOUNT" | translate }}</th>
        <th>{{ "TOTAL" | translate }}</th>
        <th>{{ "NEW-VALUE" | translate }}</th>
        <th>{{ "NEW-DISCOUNT" | translate }}</th>
        <th>{{ "NEW-DUE-DATE-DISCOUNT" | translate }}</th>
        <th>{{ "TOTAL-READJUSTMENT" | translate }}</th>
        <th >Diferença reajuste</th>
        <th style="white-space: nowrap; max-width:150px !important;min-width: 100px;">Novo Fim Serviço</th>
        <th colspan="2" style="min-width: 5em;">Ações</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-service>
      <tr
        class="ajust-body"
        [ngStyle]="{
          'background-color': !!service.readjustmentValue ? '#cfe6fc' : ''
        }"
      >
        <td class="text-center">
          <p-tableCheckbox [value]="service"> </p-tableCheckbox>
        </td>
        <td>{{ service.boatName }}<br>
          <img [src]="getImageShipType(service.shipType)"  style="border: 0ch; margin: 0em; padding: 0cm; width: 45px; height: 30px;" />
        </td>
        <td *ngIf="false">{{ service.shipType.toUpperCase() | translate }}</td>
        <td>{{ service.commercialLength }}</td>
        <td>{{ service.serviceName }}</td>
        <td>
          <button class="ajust-buton" type="button"
          [pTooltip]="service.observation"
          tooltipPosition="bottom" *ngIf="service.observation">
          <img src="/assets/images/eye.png"> </button>
          <img *ngIf="!service.observation" src="/assets/images/eye_gray.png">
        </td>
        <td>{{ service.contractStartDate | date: "dd/MM/yyyy" }}</td>
        <td>
          <span *ngIf="service.contractEndDate; else vazio"
            >{{ service.contractEndDate | date: "dd/MM/yyyy" }}
          </span>
        </td>
        <td>
          <span *ngIf="service.lastReadjustment; else vazio"
            >{{ service.lastReadjustment | date: "dd/MM/yyyy" }}
          </span>
        </td>
        <td>
          <span *ngIf="service.value; else vazio">{{
            service.value | number: "1.2-2"
          }}</span>
        </td>
        <td>
          <span *ngIf="service.discount; else vazio">{{
            service.discount | number: "1.2-2"
          }}</span>
        </td>
        <td>
          <span *ngIf="service.dueDateDiscount; else vazio">{{
            service.dueDateDiscount | number: "1.2-2"
          }}</span>
        </td>
        <td>
          <span *ngIf="service.value; else vazio">{{
            service.value - service.discount - service.dueDateDiscount
              | number: "1.2-2"
          }}</span>
        </td>
        <td>
          <span *ngIf="service.readjustmentValue; else vazio">{{
            service.readjustmentValue | number: "1.2-2"
          }}</span>
        </td>
        <td>
          <input
            name="readjustmentDiscount"
            type="number"
            pInputText
            class="small"
            min="0"
            *ngIf="service.readjustmentValue; else vazio"
            [(ngModel)]="service.readjustmentDiscount"
            (input)="calcTotalDiscountsReadjustments()"
          />
          <span *ngIf="!service.readjustmentValue">{{
            service.readjustmentDiscount | number: "1.2-2"
          }}</span>
        </td>
        <td>
          <input
            name="readjustmentDueDateDiscount"
            type="number"
            pInputText
            class="small"
            min="0"
            *ngIf="service.readjustmentValue; else vazio"
            [(ngModel)]="service.readjustmentDueDateDiscount"
            (input)="calcTotalDueDateDiscountsReadjustments()"
          />
          <span *ngIf="!service.readjustmentValue">
            {{ service.readjustmentDueDateDiscount | number: "1.2-2" }}</span
          >
        </td>
        <td>
          <span *ngIf="service.readjustmentValue; else vazio">
            {{ totalReajustment(service) | number: "1.2-2" }}
          </span>
        </td>
        <td>
          <span *ngIf="service.readjustmentValue; else vazio">
            {{ differenceReajustment(service) | number: "1.2-2" }}
          </span>
        </td>
        <td >
          <p-calendar
            *ngIf="service.readjustmentValue; else vazio"
            dateFormat="dd/mm/yy"
            name="newEndService"
            [(ngModel)]="service.newEndService"
            appendTo="body"
            [showIcon]="true"
            [monthNavigator]="true"
            [yearNavigator]="true"
            [yearRange]="'2000:2055'"
            rPCalendarMask
            i18n
          >
          </p-calendar>
        </td>
        <td>
          <a (click)="openHistoric(service)" style="cursor: pointer; position: relative;" alt="Histórico de reajustes." >
          <i class="pi pi-book" [ngClass]="getBookColor(service)"></i>
      </a>
        </td>
        <td>
          <i
            class="table-action"
            pTooltip="Remover reajuste"
            tooltipPosition="bottom"
            (click)="removeReajustment(service)"
            *ngIf="service.readjustmentValue"
          >
            <i class="danger pi pi-times-circle"></i>
          </i>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <ng-template #vazio>-</ng-template>
</div>
<div class="buttons-readjustment">
  <div class="buttons">
    <div class="form-button">
      <button
        (click)="clearAdjustments()"
        class="danger easymarine"
        type="button"
      >
        {{ "CLEAR" | translate }}
      </button>
    </div>
    <div class="form-button">
      <button
        class="primary easymarine"
        type="button"
        [disabled]="!enableReadjustment()"
        (click)="save()"
      >
        {{ "Aplicar reajuste" | translate }}
      </button>
    </div>
  </div>
</div>
