<p-fieldset toggleable=true collapsed=false>
  <p-header class="article-title">
    <div class="article-title">{{'Pedidos de mensalidade' | translate}}</div>
  </p-header>
  <div class="table">
    <p-table [value]="marinaOrders" [paginator]="true" rows="12" resetPageOnSort="true"
             [pageLinks]="3" [rowsPerPageOptions]="[10,20,30,50,100]" autoLayout="true">
      <ng-template pTemplate="header">
        <tr>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th class="text-right">{{totalOrders | number:'1.2-2'}}</th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th class="text-right">{{totalValueOrders | number:'1.2-2'}}</th>
          <th></th>
        </tr>

        <tr>
          <th [pSortableColumn]="'orderNumber'">
            Ped
            <p-sortIcon [field]="'orderNumber'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'orderDate'">
            Data
            <p-sortIcon [field]="'orderDate'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'dateReferenceTuition'">
            Ref.
            <p-sortIcon [field]="'dateReferenceTuition'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'origin'">
            Orig.
            <p-sortIcon [field]="'origin'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'value'">
            Total
            <p-sortIcon [field]="'value'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'boat.name'">
            Embarcação
            <p-sortIcon [field]="'boat.name'"></p-sortIcon>
          </th>
          <th class="text-center">Cliente</th>
          <th>Fatura</th>
          <th>Rateio</th>
          <th>Valor</th>
          <th class="text-center" [pSortableColumn]="'orderStatus'">
            Status
            <p-sortIcon [field]="'orderStatus'"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-order>
        <tr>
          <td>{{order.orderNumber}}</td>
          <td class="text-center">{{order.orderDate | date: 'dd/MM/yy'}}</td>
          <td class="text-center">{{order.dateReferenceTuition | date: 'dd/MM/yy'}}</td>
          <td class="text-center">
            <div [ngSwitch]="order.origin">
              <div *ngSwitchCase="'DETACHED'">
                <fa-icon [icon]="'shopping-basket'" class="table"></fa-icon>
              </div>
              <div *ngSwitchCase="'SLING'">
                <fa-icon [icon]="'ship'" class="table"></fa-icon>
              </div>
              <div *ngSwitchCase="'TUITION'">
                <fa-icon [icon]="'university'" class="table"></fa-icon>
              </div>
            </div>
          </td>
          <td class="text-right colorValue">{{order.value | number:'1.2-2'}}</td>
          <td>{{order.boat.name}}</td>
          <td colspan="4">
            <p-table class="primeSubTable" [value]=order.orderQuotas>
              <ng-template pTemplate="body" let-orderQuota>
                <tr>
                  <td>{{orderQuota.customer.name}}</td>
                  <td class="text-center">
                                        <span
                                          class="{{orderQuota.invoiceNumber != null && orderQuota.statusInvoice === 'CANCELED' ? 'canceledOrder' : (orderQuota.invoiceNumber != null ? 'paidOrder' : 'paidOrderDisabled') }}"
                                          (click)="orderQuota.invoiceNumber != null && extract(orderQuota.idInvoice)">
                                            {{ orderQuota.invoiceNumber != null ? orderQuota.invoiceNumber : '----'}}
                                        </span>
                  </td>
                  <td class="text-right colorValue">
                    {{orderQuota.quota | number:'1.0-0'}}%</td>
                  <td class="text-right colorValue">
                    {{calculateQuotaValue(order.value, orderQuota.quota) | number:'1.2-2'}}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </td>
          <td class="text-center">
            <div class="centralize" style="padding-top: 10px">
              <p>
                <i class="pi pi-circle-on" [ngClass]="{ 'canceled' : order.orderStatus === 'Canceled',
                                                    'partially-attempted' : order.orderStatus === 'PartiallyAttempted',
                                                    'attempted' : order.orderStatus === 'Attempted',
                                                    'ordered': order.orderStatus === 'Ordered' }">
                </i>
              </p>
            </div>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="10">
            Nenhum pedido de mensalidade encontrado
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-fieldset>
