import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MovementLocationType } from "src/app/models/enums/movements/movement-location-type";
import { BoatMovementDTO } from "src/app/models/dtos/movements/boat-movement-dto";
import { environment } from "src/environments/environment";
import { FilterBoatMovement } from "src/app/models/dtos/movements/filter-boat-movement";
import { MovementExecutionDestinyDTO } from "src/app/models/dtos/movements/execution-destiny/movement-execution-destiny-dto";
import { MovementExecutionChangeDestinyLocationDTO } from "src/app/models/dtos/movements/execution-change-destiny/movement-execution-change-destiny-location-dto";

@Injectable()
export class MovementExecutionService {
  apiUrl = environment.apiHost + '/secure/movement/movement-execution'

  constructor(private http: HttpClient) { }

  getAll(filter: FilterBoatMovement): Observable<BoatMovementDTO[]> {
    return this.http.post(
      this.apiUrl + '/queue', filter).pipe(
        map((res: any) => res.data.movements as BoatMovementDTO[]),
        map((data: BoatMovementDTO[]) => data.map((mov: BoatMovementDTO) => this.fixBoatMovementDate(mov)))
      );
  }

  getAllByLocation(movementLocationType: MovementLocationType, filter: FilterBoatMovement ): Observable<any[]> {
    return this.http.post(
      this.apiUrl + '/queue/location/'+ movementLocationType, filter).pipe(
        map((res: any) => res.data.movements as any[]),
        map((data: BoatMovementDTO[]) => data.map((mov: BoatMovementDTO) => this.fixBoatMovementDate(mov)))
      );
  }

  executeOperation(executeOperationDTO: any): Observable<any> {
    return this.http.post(
      this.apiUrl + '/execute-operation', executeOperationDTO)
      .pipe(map((response: any) => response.data));
  }

  getDestinies(movementPlanId: number, movementId: number): Observable<MovementExecutionDestinyDTO> {
    return this.http.get(
      `${this.apiUrl}/movement-plan/${movementPlanId}/movement/${movementId}/destiny`)
      .pipe(
        map((res: any) => res.data.destinies as MovementExecutionDestinyDTO)
      );
  }

  getChangeDestiny(movementPlanId: number, movementId: number): Observable<MovementExecutionChangeDestinyLocationDTO[]> {
    return this.http.get(
      `${this.apiUrl}/movement-plan/${movementPlanId}/movement/${movementId}/change-destiny`)
      .pipe(
        map((res: any) => res.data.locations as MovementExecutionChangeDestinyLocationDTO[])
      );
  }

  changeDestiny(movementPlanId: number, movementId: number, location: MovementExecutionChangeDestinyLocationDTO): Observable<void> {
    return this.http.post(
      `${this.apiUrl}/movement-plan/${movementPlanId}/movement/${movementId}/change-destiny`, location)
      .pipe(map(data => {}));
  }

  finishInCurrentOrigin(movementPlanId: number, movementId: number): Observable<void> {
    return this.http.post(
      `${environment.apiHost}/secure/movement-plan/${movementPlanId}/movement/${movementId}/finish-in-current-origin`, null)
      .pipe(map(data => {}));
  }

  private fixBoatMovementDate = (mov: BoatMovementDTO) => {
    if (mov.date && mov.date.dateTime) {
      return {...mov, date: {...mov.date, dateTime: new Date(mov.date.dateTime)}};
    } else {
      return mov;
    }
  }
}
