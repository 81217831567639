import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProductType } from 'src/app/models/enums';
import { ServiceComparisonConfiguration } from 'src/app/models/service-comparison-configuration';
import { ProductService } from 'src/app/services/product.service';
import { DeepCloneUtil } from 'src/app/utils/deep-clone.util';

@Component({
  selector: 'app-service-comparison-configuration-modal',
  templateUrl: './service-comparison-configuration-modal.component.html',
  styleUrls: ['./service-comparison-configuration-modal.component.scss']
})
export class ServiceComparisonConfigurationModalComponent implements OnInit {

  configurationsForm: UntypedFormGroup;
  serviceListOptions: any[] = [];
  services: any[] = [];
  configurationsSaved: ServiceComparisonConfiguration[] = [];
  configurations: ServiceComparisonConfiguration[] = [];
  serviceIds: number[];

  constructor(
    public dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private productService: ProductService,
    private spinner: NgxSpinnerService,
  ) { }

  async ngOnInit(): Promise<void> {

    if (this.config.data.configurations) {
      this.configurationsSaved = this.config.data.configurationsSaved;
      this.configurations = this.config.data.configurations;
      this.serviceIds = this.config.data.serviceIds;
    }
    this.spinner.show();
    await this.getProductFilterListNotPriceFree();
    this.startForm();
    this.spinner.hide();
  }

  startForm() {
    const configs = new UntypedFormArray([]);
    if (this.configurations?.length > 0 ) {

      if(this.serviceIds) {
        this.configurations = this.configurations.filter(c => this.serviceIds.find( id => id === c.service.id));
      }

      this.configurations.forEach(config => {
        configs.push(new UntypedFormGroup({
          id: new UntypedFormControl(config.id),
          service: new UntypedFormControl(config.service, Validators.required),
          serviceComparisonId: new UntypedFormControl(config.serviceComparison? config.serviceComparison.id : null, Validators.required)
        }));
      });
    }

    this.configurationsForm = new UntypedFormGroup({
      configs,
      saveConfig: new UntypedFormControl(null),
    });
  }

  save() {
    if(this.configurationsForm.valid) {

      const configs: ServiceComparisonConfiguration[] = this.configurationsForm.value.configs.map(config => {
        const serviceComparison = this.services.find( service => service.id === config.serviceComparisonId)

        return {
          id: config.id,
          service: config.service,
          serviceComparison
        }
      });

      this.dialogRef.close({ configs, saveConfig:this.configurationsForm.value.saveConfig });
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  setDefault() {
    this.configurations = DeepCloneUtil.clone(this.configurationsSaved);
    this.startForm();
  }

  listConfigs(): any[] {
    return (this.configurationsForm.get('configs') as UntypedFormArray).controls
  }

  async getProductFilterListNotPriceFree(): Promise<void>{
    return new Promise( async resolve => {
      this.productService.getProductFilterListNotPriceFree().subscribe(
        data => {
          this.serviceListOptions = data.filter(s => s.productType === ProductType.PeriodicalService.toString()).map(item => ({ label: item.name, value: item.id }));
          this.services = data;
          resolve();
        },
        error => {
          resolve();
        }
      )
    });
  }

}
