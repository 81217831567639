<div class="dialog-form" *ngIf="roleForm">
  <form [formGroup]="roleForm" (submit)="formValidations() && save()">
      <div class="form-group space-evenly">
        <div class="selectorInputBox">
          <div class="form-control">
            <label for="id">Id</label>
            <input pInputText type="text" formControlName="id" readonly name="id">
          </div>
          <div class="form-control">
            <label for="name">{{'FIRST-NAME' | translate}}</label>
            <input pInputText type="text" formControlName="name" name="name">
          </div>
          <div class="form-control">
            <label for="hierarchy">{{'Hierarquia' | translate}}</label>
            <p-dropdown placeholder="Selecione..." [filter]="false" [options]="hierarchyList"
              formControlName="hierarchy" name="hierarchy">
            </p-dropdown>
          </div>
        </div>
        <div class="switchBox">
          <div class="switchSelectors">
            <label for="name">{{'MANAGE-PHOTOS' | translate}}</label>
            <p-inputSwitch (onChange)="changePhotos($event)" id="photos" name="photos" formControlName="photos"
            ></p-inputSwitch>
          </div>
          <div class="switchSelectors" pTooltip="Serão liberados para esse perfil de usuário as seguintes funcionalidades: Cancelar pagamento, cancelar boleto, cancelar fatura e aplicar descontos">
            <label for="name" >{{'ADMINISTRATOR' | translate}}</label>
            <p-inputSwitch (onChange)="changeAdministrator($event)" id="administrator" name="administrator" formControlName="administrator"></p-inputSwitch>
          </div>
          <div class="switchSelectors" pTooltip="Esse se torna um perfil Operador Responsável que gerencia apenas as embarcações designadas.">
            <label for="name" >{{'RESP-OPERATOR' | translate}}</label>
            <p-inputSwitch (onChange)="changeResponsible($event)" id="responsible" name="responsible" formControlName="responsible"></p-inputSwitch>
          </div>

          <div class="switchSelectors" pTooltip="Esse se torna um perfil capaz de alterar as datas de Vencimento e Competência de uma Fatura">
            <label for="name" >{{'CHANGE-INVOICE-DATES' | translate}}</label>
            <p-inputSwitch (onChange)="changeInvoiceDatesPermission($event)" id="changeInvoiceDates" name="changeInvoiceDates" formControlName="changeInvoiceDates"></p-inputSwitch>
          </div>
        </div>
      </div>
      <br>
      <div class="form-group space-evenly">
          <div class="form-control">
              <label for="selectedItensWeb">Aplicação Easy Web</label>
              <p-listbox [options]="menuItensListWeb" filter="true" multiple="multiple" checkbox="checkbox"
                  metaKeySelection="false" formControlName="selectedItensWeb" name="selectedItensWeb"
                  (onChange)="updateMenuItens()" [listStyle]="{'max-height':'250px'}">
              </p-listbox>
          </div>
          <div class="form-control" *ngIf="empetyList(menuItensListGestor)">
              <label for="selectedItensGestor">Aplicação Easy Gestor</label>
              <p-listbox [options]="menuItensListGestor" filter="true" multiple="multiple" checkbox="checkbox"
                  formControlName="selectedItensGestor" name="selectedItensGestor" (onChange)="updateMenuItens()"
                  [listStyle]="{'max-height': '250px'}">
              </p-listbox>
          </div>
      </div>
      <div class="form-group align-center">
          <div class="form-control">
              {{'REQUIRED-FIELDS' | translate}}
          </div>
          <div class="buttons">
              <button class="easymarine danger" type="button" (click)="dialogRef.close()">{{ 'CANCEL' | translate
                  }}</button>
              <button class="easymarine primary" type="submit">{{ 'SAVE' | translate }}</button>
          </div>
      </div>
  </form>
</div>
