import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MarinaTermUse } from "../models/marina-term-use";
import { VariableTemplate } from "../models/varible-template";

@Injectable()
export class VariableTemplateService {

    constructor(
        private http: HttpClient
    ) { }

    findAll(): Observable<VariableTemplate[]> {
        return this.http.get(environment.apiHost + '/secure/variables-template')
            .pipe(map((response: any) => response.data.variablesTemplate as VariableTemplate[]));
    }

}
