import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectLabelValue } from 'src/app/models/select-label-value';
import { UserService } from 'src/app/services/user.service';
import { StorageUtil } from 'src/app/utils/storage.util';
import { InvoiceTaxLogService } from 'src/app/services/invoice-tax-log.service';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { CustomerService } from 'src/app/services/customer.service';
import { Customer } from 'src/app/models';
import { InvoiceTaxLogDTO } from 'src/app/models/invoice-tax-log.model';

@Component({
  selector: 'app-invoice-tax-logs',
  templateUrl: './invoice-tax-logs.component.html',
  styleUrls: ['./invoice-tax-logs.component.scss']
})
export class InvoiceTaxLogsComponent implements OnInit, OnDestroy {
  filteredUsers = [];
  customers = [];
  logs: InvoiceTaxLogDTO[] = [];
  rangeReferenceDates: Date[];
  rangeLogDate: Date[];

  filter = { marinaId: 0, dateStart: new Date(), dateEnd: new Date(), referenceDateStart: new Date(), referenceDateEnd: new Date(), userId: null, referenceId: null, customerId: null  };

  pt = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  };

  private unsubscriber: Subscription = new Subscription();

  constructor(
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private customerService: CustomerService,
    private invoiceTaxLogService: InvoiceTaxLogService
  ) { }

  async ngOnInit(): Promise<void> {
    const marina = StorageUtil.getMarina();
    this.filter.marinaId = marina.id;
    this.loadUsers();
    this.spinner.show();
    await this.find();
    this.loadCustomers();
    this.spinner.hide();
  }

  find(){
    this.onChangeDueDate();
    this.onChangeLogDate();
    this.unsubscriber.add(
      this.invoiceTaxLogService.findLogs(this.filter).subscribe(
        (res: any) => {
          this.logs = res;
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      )
    );
  }

  private loadUsers(): void {
    this.userService.getMarinaUsers().subscribe(
      (data) => {
        this.filteredUsers = data.map((c) => ({ label: c.firstName.concat(' ').concat(c.lastName), value: c.id }));
        this.filteredUsers.unshift({ label: 'Todos', value: null });
      }
    );
  }

  private loadCustomers(): void {
    this.unsubscriber.add(
      this.customerService.getAll().subscribe(
        (data: Customer[]) => {
          this.customers = data.map((c) => ({ label: c.name.concat(' '), value: c.id }));
          this.customers.unshift({ label: 'Todos', value: null });
        },
        (err: HttpErrorResponse) => {
          console.log(err);
        }
      )
    );
  }

  onChangeDueDate(){
    if(this.rangeReferenceDates){
      if (this.rangeReferenceDates[0] != null) {
        this.filter.referenceDateStart = this.rangeReferenceDates[0];
        if (this.rangeReferenceDates[1] != null) {
          this.filter.referenceDateEnd = this.rangeReferenceDates[1];
        } else{
          this.filter.referenceDateEnd = this.rangeReferenceDates[0];
        }
      }else{
        this.filter.referenceDateStart = null;
        this.filter.referenceDateEnd = null;
      }
    }
  }

  onChangeLogDate(){
    if(this.rangeLogDate){
      if (this.rangeLogDate[0] != null) {
        this.filter.dateStart = this.rangeLogDate[0];
        if (this.rangeLogDate[1] != null) {
          this.filter.dateEnd = this.rangeLogDate[1];
        } else{
          this.filter.dateEnd = this.rangeLogDate[0];
        }
      }else{
        this.filter.dateStart = null;
        this.filter.dateEnd = null;
      }
    }
  }


  ngOnDestroy(): void {
      this.unsubscriber.unsubscribe();
  }
}
