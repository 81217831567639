<div style="padding: 16px;">
    <p-fieldset>
        <p-header class="article-title">
            <div class="article-title">Fatura</div>
        </p-header>

        <div class="content-log">
            <div>
                <label for="competenceDate">{{ 'COMPETENCE-DATE' | translate }}</label>
                <app-log-field [old]="invoiceLog.oldDatas?.competenceDate" [field]="invoiceLog.competenceDate" [existsOld]="oldDatasMap.has('competenceDate')" [isNew]="false"></app-log-field>
            </div>
            <div>
                <label for="dueDate">{{ 'DUE-DATE' | translate }}</label>
                <app-log-field [old]="invoiceLog.oldDatas?.dueDate" [field]="invoiceLog.dueDate" [existsOld]="oldDatasMap.has('dueDate')" [isNew]="false"></app-log-field>
                
            </div>
        </div>
    </p-fieldset>

    <p-fieldset>
        <p-header class="article-title">
            <div class="article-title">Fatura</div>
        </p-header>

        <div class="content-log">
            <div>
                <label for="competenceDate">{{ 'COMPETENCE-DATE' | translate }}</label>
                <app-log-field [old]="invoiceLog.oldDatas?.competenceDate" [field]="invoiceLog.competenceDate" [existsOld]="oldDatasMap.has('competenceDate')" [isNew]="true"></app-log-field>
            </div>
            <div>
                <label for="dueDate">{{ 'DUE-DATE' | translate }}</label>
                <app-log-field [old]="invoiceLog.oldDatas?.dueDate" [field]="invoiceLog.dueDate" [existsOld]="oldDatasMap.has('dueDate')" [isNew]="true"></app-log-field>
                
            </div>
        </div>
    </p-fieldset>

    <div style="margin: 15px;">
        <div class="content-log">
          <div>
            <label>Data/hora</label>
            <label>{{ invoiceLog?.logTime | date: "dd/MM/yyyy - HH:mm" }}</label>
          </div>

          <div>
            <label>Usuário</label>
            <label>{{ invoiceLog?.user }}</label>
          </div>
        </div>
        <div class="buttons">
          <button class="easymarine primary" (click)="dialogRef.close()">
            Fechar
          </button>
        </div>
    </div>
</div>