export enum InvoiceTaxDocumentStatus {
  AguardandoAutorizacao = 'AguardandoAutorizacao',
  Autorizada = 'Autorizada',
  SolicitandoCancelamento = 'SolicitandoCancelamento',
  EmProcessoDeCancelamento = 'EmProcessoDeCancelamento',
  Cancelada = 'Cancelada',
  CancelamentoNegado = 'CancelamentoNegado',
  ErroAutorizacao = 'ErroAutorizacao',
  Importada = 'Importada',
}
