<div *ngIf="data">
  <div class="switch">
      <label>{{ 'SIREN-TYPE.'+data.type | translate }}</label>
      <p-inputSwitch [(ngModel)]="data.enabled" (onChange)="changeEnabled($event)" name="enabled">
      </p-inputSwitch>
  </div>
  <p-dropdown placeholder="Selecione..." [hidden]="!data.enabled" [filter]="true"
      [options]="filteredSirenAlerts" [(ngModel)]="data.sirenAlert"
      appendTo="body" name="siren" (onChange)="changeSirenAlert()">
  </p-dropdown>
  <audio controls #volume
      [hidden]="!data.enabled || !data?.sirenAlert?.pathUrl">
      <source #audioSource [src]="data?.sirenAlert?.pathUrl"
          type="audio/mpeg">
      {{ 'MSG.AUDIO-NOT-SUPPORTED' | translate }}
  </audio>
</div>
