import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-log-licence',
  templateUrl: './customer-log-licence.component.html',
  styleUrls: ['./customer-log-licence.component.scss']
})
export class CustomerLogLicenceComponent implements OnInit {

  @Input() customerLog;
  @Input() isNew;

  oldDatasMap

  constructor() { }

  ngOnInit(): void {
    this.oldDatasMap = new Map(Object.entries(this.customerLog.oldDatas.licence));
  }

  isChangedRegister(): boolean{
    return this.oldDatasMap.has('register') && this.customerLog.licence.register != this.customerLog.oldDatas.licence.register;
  }

  isChangedExpiration(): boolean{
    return this.oldDatasMap.has('expiration') && this.customerLog.licence.expiration != this.customerLog.oldDatas.licence.expiration;
  }

  isChangedTypeLicense(): boolean{
    return this.oldDatasMap.has('typeLicense') && this.customerLog.licence.typeLicense != this.customerLog.oldDatas.licence.typeLicense;
  }

  isChangedCountry(): boolean{
    return this.oldDatasMap.has('country') && this.customerLog.licence.country != this.customerLog.oldDatas.licence.country;
  }

  isChangedCity(): boolean{
    return this.oldDatasMap.has('city') && this.customerLog.licence.city != this.customerLog.oldDatas.licence.city;
  }

  isChangedState(): boolean{
    return this.oldDatasMap.has('state') && this.customerLog.licence.state != this.customerLog.oldDatas.licence.state;
  }

  isChangedFile(){
    return this.oldDatasMap.has('file') && this.customerLog.licence.file != this.customerLog.oldDatas.licence.file;
  }

}
