import { Component, OnInit } from '@angular/core';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { environment } from 'src/environments/environment';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-nixx-bank-access',
  templateUrl: './nixx-bank-access.component.html',
  styleUrls: ['./nixx-bank-access.component.scss']
})
export class NixxBankAccessComponent implements OnInit {

  srcNixx: SafeResourceUrl;

  constructor(
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.hideMenuBar();
    this.getNixxBankLoginUrl()
  }

  hideMenuBar() {
    BroadcastService.get('showHideSidenav').emit(false);
  }

  getNixxBankLoginUrl() {
    const safe = this.sanitizer.bypassSecurityTrustResourceUrl(environment.nixxBankLoginApi);
    console.log("safe", safe);

    this.srcNixx = safe
  }

}

