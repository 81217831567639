<form class="new-filters" (submit)="getAccessPermissionsByFilter()" appFilters>
  <div class="new-filter-row">
    <div class="new-form-control-sm">
      <label>{{ "GENERAL-SEARCH-PAGINATED" | translate }}</label>
      <span class="p-input-icon-right">
        <input
          type="text"
          class="new-input"
          [(ngModel)]="globalFilter"
          (input)="filterGlobal()"
          name="globalFilter"
          placeholder="ex: Pesquise por nome, cpf, telefone..."
        />
      </span>
    </div>

    <div class="new-form-control-sm">
      <label>{{ "SERVICE-PROVIDERS" | translate }}</label>
      <p-dropdown
        [(ngModel)]="filter.visitorId"
        [filter]="true"
        [options]="serviceProviderList"
        appendTo="body"
        placeholder="Selecione..."
        name="visitorId"
      >
      </p-dropdown>
    </div>

    <div class="new-form-control-sm">
      <label>{{ "REQUESTED-BY" | translate }}</label>
      <p-dropdown
        [(ngModel)]="filter.customerResponsibleId"
        [filter]="true"
        [options]="customerList"
        appendTo="body"
        placeholder="Selecione..."
        name="customerResponsibleId"
      >
      </p-dropdown>
    </div>

    <div class="new-form-control-sm">
      <label for="rangeCadastro">{{ "INITIAL-DATE" | translate }}</label>
      <p-calendar
        dateFormat="dd/mm/yy"
        name="rangeCreateDates"
        [monthNavigator]="true"
        [yearNavigator]="true"
        appendTo="body"
        [yearRange]="'2000:2055'"
        rPCalendarMask
        selectionMode="range"
        [(ngModel)]="this.rangeCreatedDates"
        [showIcon]="true"
        (onClose)="this.onChangeRange()"
        icon="pi pi-calendar enlarged-icon"
        i18n
      >
      </p-calendar>
    </div>
  </div>
  <div class="new-filter-row">
    <div class="subtitles">
      <fieldset class="side-by-side">
        <legend>Legenda (Cores de fundo)</legend>
        <div class="red">
          <p-checkbox
            value="red"
            class="clickable"
            type="checkbox"
            class="checkbox"
          ></p-checkbox>
          Dados Incompletos
        </div>
      </fieldset>
      <div class="new-form-button">
        <button class="new-easymarine primary" type="submit">
          {{ "FIND" | translate }}
          &nbsp;
          <i class="pi pi-search"></i>
        </button>
      </div>
      <div class="new-form-button">
        <button (click)="clear()" class="new-easymarine danger" type="button">
          {{ "CLEAR" | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
<p-table
  #serviceProviderDataTable
  [value]="permissionList"
  (onLazyLoad)="onChangePage($event)"
  (onSort)="onChangeSort($event)"
  [paginator]="true"
  [rows]="numberOfRows"
  [totalRecords]="totalRecords"
  [pageLinks]="3"
  [lazy]="true"
  [rowsPerPageOptions]="[10, 20, 30, 50, 100, 200, 500]"
  autoLayout="true"
  dataKey="permissionId"
  styleClass="easy-table"
  [alwaysShowPaginator]="true"
  [scrollable]="true"
  [alwaysShowPaginator]="true"
>
  <ng-template pTemplate="caption">
    <div class="export-buttons"></div>
    <span style="font-weight: bold; margin-top: 5px">
      {{ "TOTAL-REGISTERS" | translate }}: {{ numberOfElements }}
      {{ "OF" | translate }} {{ totalRecords }}
    </span>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th [pSortableColumn]="'visitor_federal_id'">
        {{ "CPF" | translate }}
        <p-sortIcon [field]="'visitor_federal_id'"></p-sortIcon>
      </th>

      <th [pSortableColumn]="'visitor_name'">
        {{ "NAME" | translate }}
        <p-sortIcon [field]="'visitor_name'"></p-sortIcon>
      </th>

      <th [pSortableColumn]="'visitor_photo'">
        {{ "PHOTO" | translate }}
        <p-sortIcon [field]="'visitor_photo'"></p-sortIcon>
      </th>

      <th [pSortableColumn]="'visitor_phone'">
        {{ "PHONE" | translate }}
        <p-sortIcon [field]="'visitor_phone'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'permission_access_group'">
        {{ "ACCESS-GROUP" | translate }}
        <p-sortIcon [field]="'permission_access_group'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'permission_creation_date'">
        {{ "CREATION-DATE" | translate }}
        <p-sortIcon [field]="'permission_creation_date'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'permission_start_date'">
        {{ "START-DATE" | translate }}
        <p-sortIcon [field]="'permission_start_date'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'permission_end_date'">
        {{ "END-DATE" | translate }}
        <p-sortIcon [field]="'permission_end_date'"></p-sortIcon>
      </th>
      <th [pSortableColumn]="'permission_request_by'">
        {{ "REQUESTED-BY" | translate }}
        <p-sortIcon [field]="'permission_request_by'"></p-sortIcon>
      </th>
      <th
        [pSortableColumn]="'permission_liberation_status'"
        class="text-center"
      >
        {{ "Status" | translate }}
        <p-sortIcon [field]="'permission_liberation_status'"></p-sortIcon>
      </th>
      <th>{{ "Ações" | translate }}</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-permission>
    <tr>
      <td [ngClass]="{ incomplete: hasIncompleteVisitorData(permission) }">
        {{ permission.visitorFederalId }}
      </td>
      <td [ngClass]="{ incomplete: hasIncompleteVisitorData(permission) }">
        {{ permission.visitorName }}
      </td>
      <td [ngClass]="{ incomplete: hasIncompleteVisitorData(permission) }">
        <img
          *ngIf="permission.visitorPhoto"
          [src]="permission.visitorPhoto"
          alt="Foto do visitante"
          class="photo"
        />
      </td>
      <td [ngClass]="{ incomplete: hasIncompleteVisitorData(permission) }">
        {{ permission?.visitorPhone }}
      </td>
      <td [ngClass]="{ incomplete: hasIncompleteVisitorData(permission) }">
        {{ permission?.permissionAccessGroup }}
      </td>
      <td
        [ngClass]="{ incomplete: hasIncompleteVisitorData(permission) }"
        class="text-center"
      >
        {{ permission.permissionCreationDate | date : "dd/MM/yyyy" }}
      </td>
      <td
        [ngClass]="{ incomplete: hasIncompleteVisitorData(permission) }"
        class="text-center"
      >
        {{ permission.permissionStartDate | date : "dd/MM/yyyy" }}
      </td>
      <td
        [ngClass]="{ incomplete: hasIncompleteVisitorData(permission) }"
        class="text-center"
      >
        {{ permission.permissionEndDate | date : "dd/MM/yyyy" }}
      </td>
      <td [ngClass]="{ incomplete: hasIncompleteVisitorData(permission) }">
        {{ permission.permissionRequestBy }}
      </td>
      <td
        [ngClass]="{ incomplete: hasIncompleteVisitorData(permission) }"
        class="no-wrap text-center"
        *ngIf="
          permission.permissionLiberationStatus != null &&
            permission.permissionLiberationStatus != undefined &&
            permission.permissionLiberationStatus != 'WAITING' &&
            permission.permissionLiberationStatus != 'EXPIRED';
          else resolvedLiberation
        "
      >
        <span
          *ngIf="permission.permissionLiberationStatus == 'ALLOWED'"
          class="liberation green"
          >Liberado
        </span>
        <span
          *ngIf="permission.permissionLiberationStatus == 'DENIED'"
          class="liberation red"
          >Negado
        </span>
      </td>
      <ng-template #resolvedLiberation>
        <td
          [ngClass]="{ incomplete: hasIncompleteVisitorData(permission) }"
          class="no-wrap text-center"
        >
          <button
            class="liberation success"
            (click)="updateLiberationStatus(permission, true)"
          >
            Liberar&nbsp;
            <i class="pi pi-check liberation green"></i>
          </button>
          <button
            class="liberation danger"
            (click)="updateLiberationStatus(permission, false)"
          >
            Negar&nbsp;
            <i class="pi pi-times liberation red"></i>
          </button>
        </td>
      </ng-template>

      <td
        [ngClass]="{ incomplete: hasIncompleteVisitorData(permission) }"
        class="text-center"
      >
        <ng-container
          *ngIf="this.isAllowedToEdit(permission.permissionLiberationStatus)"
        >
          <p-menu
            appendTo="body"
            #menu
            [model]="menuItems"
            [popup]="true"
            [showTransitionOptions]="'150ms'"
            [hideTransitionOptions]="'150ms'"
          ></p-menu>
          <button
            (click)="menu.toggle($event); changeSelectedItem(permission)"
            pButton
            type="button"
            icon="pi pi-ellipsis-v"
            class="p-button-text p-button-rounded p-button-lg"
          ></button>
        </ng-container>
      </td>
    </tr>
  </ng-template>
</p-table>
