<div class="dialog-form">
  <span><strong>Copiar {{toCopy.label | translate}}</strong></span>
  <br>
  <strong>
    Para:
  </strong>
  <div class="items-to-paste">
    <button *ngFor="let item of toPaste"
      class="easymarine"
      [ngClass]="{'success': item.selected}"
      (click)="item.selected = !item.selected"
    >
      {{item.label | translate}}
    </button>
  </div>
  <div class="buttons">
    <button class="danger easymarine" type="button" (click)="close()" >{{'CANCEL' | translate}}</button>
    <button class="primary easymarine" type="button" (click)="copy()" >{{'CONFIRM' | translate}}</button>
  </div>
</div>
