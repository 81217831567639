import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { StockMovementType } from 'src/app/models/stock/stock-movement-type';
import { MovementTypeService } from 'src/app/services/stock/movement-type.service';
import { MessageUtil } from 'src/app/utils/message.util';

@Component({
  selector: 'app-stock-movement-type-form',
  templateUrl: './stock-movement-type-form.component.html',
  styleUrls: ['./stock-movement-type-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StockMovementTypeFormComponent implements OnInit {
  stockMovementTypeForm: FormGroup;
  isUpdate:boolean = false;
  typeOptions = [
    { label: 'Nenhum', value: null },
    { label: 'Entrada', value: 'IN' },
    { label: 'Saída', value: 'OUT' },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private config: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
    private movementTypeService: MovementTypeService,
    private messageUtil: MessageUtil,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.startForm();
    if (this.config.data && this.config.data.stockMovementType) {
      this.isUpdate = true;
      let stockMovementTypeCopy: StockMovementType = Object.assign(
        {},
        this.config.data.stockMovementType
      );
      this.fillFormWithStockMovementType(stockMovementTypeCopy);
      this.cdr.detectChanges();
    }
  }

  fillFormWithStockMovementType(stockMovementType: StockMovementType) {
    this.stockMovementTypeForm.patchValue(stockMovementType);
  }

  startForm(): void {
    this.stockMovementTypeForm = this.formBuilder.group({
      id: [null], // Pode ser null se for um novo item
      name: ['', Validators.required], // Campo obrigatório
      type: ['', Validators.required], // Campo obrigatório
      active: [true], // Valor padrão para 'active'
      marinaId: [null, Validators.required], // Campo obrigatório
    });
  }

  save() {
    const formData: StockMovementType = this.stockMovementTypeForm.value;

    if (formData.id === null) {
      this.movementTypeService.save(formData).subscribe({
        next: () => {
          this.dialogRef.close();
        },
        error: () => {
          this.messageUtil.generateMessage(
            'warning',
            'Atenção',
            'Erro ao salvar alteração'
          );
        },
      });
    } else {
      this.movementTypeService.update(formData).subscribe({
        next: () => {
          this.dialogRef.close();
        },
        error: () => {
          this.messageUtil.generateMessage(
            'warning',
            'Atenção',
            'Erro ao salvar alteração'
          );
        },
      });
    }
  }

}
