<p-fieldset>
    <p-header class="article-title">
      <div class="article-title">{{ "GENERAL-DATA" | translate }}</div>
    </p-header>
    <div class="content-log">
      <div>
        <label>Id</label>
        <label>{{ boatLog.boatId }}</label>
      </div>
      <div>
        <label>{{ "NAME" | translate }}</label>
        <app-log-field [old]="boatLog.oldDatas.name" [field]="boatLog.name" [existsOld]="oldDatasMap.has('name')" [isNew]="isNew"></app-log-field>
      </div>
      <div>
        <label>{{ "VACANCY" | translate }}</label>
        <label [ngClass]="{'item-new-log': changeVacancy && isNew, 'item-old-log': changeVacancy && !isNew}">
            {{ getVacancy() }}
        </label>
      </div>
      <div>
        <label>{{"SHIP-ATT.MAINTENANCE" | translate }}</label>
        <label [ngClass]="{'item-new-log': isChangedMaintenance() && isNew, 'item-old-log': isChangedMaintenance() && !isNew}">
          {{ (getMaintenance()?'YES':'NO') | translate}}
        </label>
      </div>
      <div>
        <label>{{ "SLING-APP" | translate }}</label>
        <label [ngClass]="{'item-new-log': isChangedSharedInvoiceCustomerApp() && isNew, 'item-old-log': isChangedSharedInvoiceCustomerApp() && !isNew}">
          {{ (getSharedInvoiceCustomerApp()?'ACTIVE':'INACTIVE') | translate}}
        </label>
      </div>
      <div>
        <label>{{ "STATUS" | translate }}</label>
        <label [ngClass]="{'item-new-log': isChangedStatus() && isNew, 'item-old-log': isChangedStatus() && !isNew}">
          {{ (getStatus()? 'ACTIVE':'INACTIVE') | translate}}
        </label>
      </div>
      <div>
        <label>{{ "BOAT_PROFILE" | translate }}</label>
        <label [ngClass]="{'item-new-log': isChangedProfile() && isNew, 'item-old-log': isChangedProfile() && !isNew}">
          {{ isChangedProfile() && isNew ? boatLog.boatProfile : boatLog.oldDatas.boatProfile }}
        </label>
      </div>
      <div>
        <label>{{ "LOCATION" | translate }}</label>
        <label [ngClass]="{'item-new-log': isChangedLocation() && isNew, 'item-old-log': isChangedLocation() && !isNew}">
          {{ isChangedLocation() && isNew ? boatLog.movementLocation : boatLog.oldDatas.movementLocation }}
        </label>
      </div>
      <div>
        <label>{{ "MOVEMENT_GROUP" | translate }}</label>
        <label [ngClass]="{'item-new-log': isChangedMovementGroup() && isNew, 'item-old-log': isChangedMovementGroup() && !isNew}">
          {{ isChangedMovementGroup() && isNew ? boatLog.movementGroup : boatLog.oldDatas.movementGroup }}
        </label>
      </div>
    </div>
  </p-fieldset>
  <br />
