import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";

export const getTimeValidatorsIsBefore = (a: string, b: string): ((formGroup: UntypedFormGroup) => null | { NotIsBeforeTime: boolean }) => {
  return (formGroup: UntypedFormGroup) => {
    const values = formGroup.value;

    if (values[a] && values[b]) {
      const start: Date = new Date();
      const [aHour, aMin, aSec] = values[a].split(':');
      start.setHours(aHour, aMin, aSec? aSec : 0, 0);

      const end: Date = new Date();
      const [bHour, bMin, bSec] = values[b].split(':');
      end.setHours(bHour, bMin, bSec ? bSec : 0, 0);

      if (start >= end) {
        return { NotIsBeforeTime: start >= end }
      }
    }
    return null;
  }
}

export const getTimeControlValidator = (func: (time: Date) => Boolean, error: any ): ((formControl: UntypedFormControl) => null | any) => {
  return (formControl: UntypedFormControl) => {
    const timeValue = formControl.value;

    if (timeValue) {
      const time: Date = new Date();
      const [timeHour, timeMin, timeSec] = timeValue.split(':');
      time.setHours(+timeHour, +timeMin, timeSec? +timeSec : 0, 0);

      if (!func(time)) {
        return error;
      }
    }
    return null;
  }
}



export const getDateFromTimeString = (timeValue: string): Date => {
  if (timeValue) {
    const time: Date = new Date();
    const [timeHour, timeMin, timeSec] = timeValue.split(':');
    time.setHours(+timeHour, +timeMin, timeSec? +timeSec : 0, 0);
    return time;
  }
  return null
}

export const getDaySettingValidators = (): any[] => {
  return [
    (dayForm: UntypedFormGroup) => {
      if (dayForm.controls.inOperation.value) {
        const intervalStart = getDateFromTimeString(dayForm.controls.intervalStart.value);
        const intervalEnd = getDateFromTimeString(dayForm.controls.intervalEnd.value);
        if (intervalStart || intervalEnd) {
          if (!intervalStart || !intervalEnd || intervalStart >= intervalEnd) {
            return { intervalStartOrEnd: true }
          }
        }
      }
      return null;
    }
  ]
}

export const setValidatorsToStart = (control: AbstractControl, operatingStart: AbstractControl | string, controlEnd: AbstractControl, controlIntervalStart: AbstractControl) => {
  control.setValidators([
    Validators.required,
    getTimeControlValidator(
      (time: Date) => {
        let minTime = typeof(operatingStart) === 'string' ? operatingStart : operatingStart.value;
        let isValid = time >= getDateFromTimeString(minTime);
        if (controlEnd.value) {
          isValid &&= time < getDateFromTimeString(controlEnd.value);
        }
        if (controlIntervalStart.value) {
          isValid &&= time < getDateFromTimeString(controlIntervalStart.value)
        }
        return isValid;
      },
      { invalidStart: true })
  ])
}

export const setValidatorsToEnd = (control: AbstractControl, operatingEnd: AbstractControl | string, controlStart: AbstractControl, controlIntervalStart: AbstractControl) => {
  control.setValidators([
    Validators.required,
    getTimeControlValidator(
      (time: Date) => {
        let maxTime = typeof(operatingEnd) === 'string' ? operatingEnd : operatingEnd.value;
        let isValid = time <= getDateFromTimeString(maxTime);
        if (controlStart.value) {
          isValid &&= time > getDateFromTimeString(controlStart.value);
        }
        if (controlIntervalStart.value) {
          isValid &&= time > getDateFromTimeString(controlIntervalStart.value);
        }
        return isValid;
      },
      { invalidEnd: true })
  ])
}

export const setValidatorsToReturnEnd = (control: AbstractControl, operatingStart: AbstractControl | string, operatingEnd: AbstractControl | string) => {
  control.setValidators([
    Validators.required,
    getTimeControlValidator(
      (time: Date) => {
        let minTime = typeof(operatingStart) === 'string' ? operatingStart : operatingStart.value;
        let maxTime = typeof(operatingEnd) === 'string' ? operatingEnd : operatingEnd.value;
        return (time >= getDateFromTimeString(minTime)
        && time <= getDateFromTimeString(maxTime));
      },
      { invalidReturnEnd: true })
  ])
}

export const setValidatorsToIntervalStart = (control: AbstractControl, controlStart: AbstractControl, controlEnd: AbstractControl, controlIntervalEnd: AbstractControl) => {
  control.setValidators([
    getTimeControlValidator(
      (time: Date) => {
        let isValid = time > getDateFromTimeString(controlStart.value);
        if (controlIntervalEnd.value) {
          isValid &&= time < getDateFromTimeString(controlIntervalEnd.value);
        }
        return isValid && time < getDateFromTimeString(controlEnd.value);
      },
      { invalidIntervalStart: true })
  ])
}

export const setValidators2ToIntervalEnd = (control: AbstractControl, controlEnd: AbstractControl, controlIntervalStart: AbstractControl) => {
  control.setValidators([
    getTimeControlValidator(
      (time: Date) =>
        (time > getDateFromTimeString(controlIntervalStart.value)
          && time < getDateFromTimeString(controlEnd.value)),
      { invalidIntervalEnd: true })
  ])
}
