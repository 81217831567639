import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Swal, { SweetAlertResult } from 'sweetalert2';

@Injectable()
export class MessageUtil {

    constructor(
        private translate: TranslateService
    ) { }

    public static readonly QUESTION = 'question';
    public static readonly WARNING = 'warning';
    public static readonly SUCCESS = 'success';
    public static readonly ERROR = 'error';
    public static readonly INFO = 'info';

    public generateMessage(pseverity: any = 'warning', summary: string = 'Atenção', detail: string, time?: number): Promise<SweetAlertResult<any>> {
        let transSummary = '';
        this.translate.get(summary).subscribe((res: string) => {
            transSummary = res;

        });

        let transDetail = '';
        this.translate.get(detail).subscribe((res: string) => {
            transDetail = res;
        }
        );
        return Swal.fire({
            title: transSummary,
            text: transDetail,
            icon: pseverity,
            timer: time,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
        });
    }

    public generateMessageParameter(pseverity: any, summary: string, detail: string, parameter: object): Promise<SweetAlertResult<any>> {
        let transSummary = '';
        this.translate.get(summary).subscribe((res: string) => {
            transSummary = res;

        });
        let transDetail = '';
        this.translate.get(detail, parameter).subscribe((res: string) => {
            transDetail = res;
        });
        return Swal.fire(transSummary, transDetail, pseverity);
    }

    public confirmMessage(objeto: any, summary: string, message: string, concat: string): void {
        let transSummary = '';
        this.translate.get(summary).subscribe((response: string) => {
            transSummary = response;

        });
        let transDetail = '';
        this.translate.get(message).subscribe((response: string) => {
            transDetail = response;
        });
        Swal.fire({
            title: transSummary,
            text: transDetail + ' ' + concat,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                objeto.confirmationDialog();
            }
        });
    }

    public translateKey(key: string): string {
        let translated = '';
        this.translate.get(key).subscribe((res: string) => {
            translated = res;
        });
        return translated;
    }

    public async translateKeyAsync(key: string): Promise<string> {
        return new Promise<string>(
            async (resolve) => {
                this.translate.get(key).subscribe(
                    async (res: string) => {
                        resolve(res);
                    },
                    async () => {
                        resolve(key);
                    }
                );
            }
        );
    }

}


