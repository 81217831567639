<div class="order-item-element top">
    <div class="item-tile">
        <span>Número de Clientes:</span>
    </div>
    <div class="text-center nowrap">
        <button [disabled]="hasPayment" class="new-mini-fab-button neutral p-0" (click)="plusPayments()"><i class="pi pi-plus-circle icon-center"></i></button>
        <p-inputNumber readonly="true" type="number" class="cart-input" [ngModel]="payments[0].numberOfCustomers"/>
        <button  [disabled]="hasPayment" class="new-mini-fab-button neutral p-0" (click)="minusPayments()"><i class="pi pi-minus-circle icon-center"></i></button>
    </div>
    <div class="item-tile">
        <span class="text-center nowrap">Total Dividido: R${{payments[0].totalSplited | number:'1.2-2'}}</span>
    </div>
    <div class="item-tile">
        <span class="text-center nowrap">Total: R${{payments[0].totalTotal | number:'1.2-2'}}</span>
    </div>
</div>

<div *ngFor="let payment of payments">
    <app-splitted-payments [payment]="payment"></app-splitted-payments>
</div>

