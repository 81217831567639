import { Component, OnInit } from '@angular/core';
import {SelectLabelValue} from 'src/app/models/select-label-value';
import {CustomerService} from '../../../services/customer.service';
import {BoatService} from '../../../services/boat.service';
import {SelectItem} from 'primeng/api/selectitem';
import {ProductService} from '../../../services/product.service';
import {Product} from 'src/app/models/product';
import {InvoicingReportFilter} from 'src/app/models/dtos/invoicingReportFilter';
import {InvoiceService} from '../../../services/invoice.service';
import {PaginationFilter} from '../../../models/pagination-filter';
import {SlingConfigService} from '../../../services/sling-config.service';
import {SlingConfig} from '../../../models';
import {NgxSpinnerService} from 'ngx-spinner';
import {LazyLoadEvent} from 'primeng/api';
import { CustomReportExportComponent } from 'src/app/components/extract-custom-report/custom-report-export.component';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageUtil } from '../../../utils/message.util';
import { UntypedFormGroup } from '@angular/forms';
import { InvoicingReportV2 } from 'src/app/models/dtos/invoicing-report-V2';
import dayjs from 'dayjs';


@Component({
  selector: 'app-invoicing-report',
  templateUrl: './invoicing-report.component.html',
  styleUrls: ['./invoicing-report.component.scss'],
})
export class InvoicingReportComponent implements OnInit {
  filteredCustomers: SelectLabelValue[] = [];
  filteredBoats: SelectLabelValue[] = [];
  filter: InvoicingReportFilter = new InvoicingReportFilter();
  productTypesList: Array<SelectItem> = [
    {label: 'Produto', value: 'Product'},
    {label: 'Serviço', value: 'Service'},
    {label: 'Serviço Periódico - Embarcação', value: 'PeriodicalService'},
  ];
  invoiceStatusList: Array<SelectItem> = [
    {label: 'Pendente', value: 'PENDING'},
    {label: 'Pago', value: 'PAID'},
    {label: 'Cancelado', value: 'CANCELED'},
  ];
  invoiceOptions: Array<SelectItem> = [
    {label: 'Ambos', value: null},
    {label: 'Sim', value: true},
    {label: 'Não', value: false}
  ];
  previousProductTypesSelected: string[];
  productList: Array<SelectItem>;
  productListTotal: Array<Product>;
  invoicingReportsTable: Array<InvoicingReportV2>;
  invoicingReportsTotal: Array<InvoicingReportV2>;
  paginationFilter: PaginationFilter = new PaginationFilter();
  slingConfig: SlingConfig;
  numberOfRows = 10;
  totalRecords: number;
  totalNotas: number;
  totalRecibos: number;
  totalValue: number;

  geralDadaForm: UntypedFormGroup;
  constructor(
    private customerService: CustomerService,
    private boatService: BoatService,
    private productService: ProductService,
    private invoiceService: InvoiceService,
    private slingConfigService: SlingConfigService,
    private spinner: NgxSpinnerService,
    public dialog: DialogService,
    private messageUtil: MessageUtil
  ) {
  }

  ngOnInit(): void {
    this.getCustomers();
    this.getBoats();
    this.getProducts();
    this.spinner.show();
    this.loadSlingConfig();
  }


  clear(): void {
    this.filter = new InvoicingReportFilter();
    this.loadSlingConfig();
  }

  search(): void {
    this.spinner.show();
    this.findInvoicingReport();
  }

  findInvoicingReport(): void {
    this.invoiceService.getInvoicingReportsV2(this.filter, this.paginationFilter).subscribe({
      next: (resp) => {
        
        this.totalRecords = resp.invoicingReports.invoicingReportPage.totalElements;
        this.invoicingReportsTable = resp.invoicingReports.invoicingReportPage.content;
        this.invoicingReportsTotal = resp.invoicingReports.invoicingReportDTOListTotal;

        try{

          this.invoicingReportsTable.map((item) => {
            if(item.boats != null){
              item.qtdBoats = item.boats.split(',').length;
            }
            else{
              item.qtdBoats = 0;
              
            }
          });
        }
        catch(e){
          this.spinner.hide();
        }
        
        this.totalNotas = 0;
        this.totalRecibos = 0;
        this.totalValue = 0;
        
      try{
        this.invoicingReportsTotal.forEach((item) => {
          this.totalNotas += item?.nfsValue;
          this.totalRecibos += item?.receiptValue;
          this.totalValue += item?.invoiceValue;
        });
        
        this.spinner.hide();
        }
        catch(e){
          this.spinner.hide();
        }
      },
      error: (err) => {
        this.spinner.hide();
      }
    });
  }

  showProductTypeListSelect(): void {
    this.previousProductTypesSelected = this.filter.productTypes;
  }

  hideProductTypeListSelect(): void {
    this.filterProductsByProductTypeSelected();
  }

  filterProductsByProductTypeSelected(): void {
    const productTypesList = this.validateProductTypes();
    this.productList = [];
    this.filter.productId = [];
    this.productListTotal.forEach((item) => {
      if (productTypesList.includes(item.productType)) {
        this.productList.push({label: item.name, value: item.id});
        this.filter.productId.push(item.id);
      }
    });
  }

  loadSlingConfig(): void {
    this.paginationFilter = new PaginationFilter();
    this.slingConfigService.getSlingConfig().subscribe(
      (data) => {
        this.slingConfig = data[0];
        if (this.slingConfig != null) {
          this.numberOfRows = this.slingConfig.numberOfRecordsPerPage != null ? this.slingConfig.numberOfRecordsPerPage : 10;
          this.paginationFilter.size = this.numberOfRows;
        } else {
          this.numberOfRows = 10;
        }
        this.search();
      },
      (error) => null);
  }

  formatDate(date): string{
    if(date){
      const d =  dayjs(date).format('DD/MM/YYYY');
      if(d == 'invalid date'){
        return date;
      }
      return d;
    }
    return '';
  }

  onChangePage(event: LazyLoadEvent): void {
    if (this.slingConfig) {
      const page = event.first / event.rows;
      if (event.multiSortMeta){
        this.paginationFilter.sort = event.multiSortMeta[0].field;
        this.paginationFilter.order = event.multiSortMeta[0].order === 1 ? 'ASC' : 'DESC';
      }
      this.paginationFilter.page = page;
      this.paginationFilter.size = event.rows;
      this.search();
    }
  }

  validateProductTypes(): string[] {
    let productsType: string[] = this.filter.productTypes;
    if (productsType && productsType.length === 0) {
      productsType = this.setProductTypes();
      return productsType;
    } else {
      return productsType;
    }
  }

  setProductTypes(): string[] {
    return this.productTypesList.map((item) => item.value);
  }


  exportTable(): void {
    let table = this.invoicingReportsTotal.map((item) => {
        item.competenceDate = this.formatDate(item.competenceDate);
        item.datePaid = this.formatDate(item.datePaid);
        return item;
    });
    const data = { table , type: 'INVOICING' };
    this.dialog.open(CustomReportExportComponent, {
      width: '70%',
      height: '90vh',
      dismissableMask: false,
      data,
      header: this.messageUtil.translateKey('EXPORT-INVOICING-TABLE'),
    });
  }


  getCustomers(): void {
    this.customerService.getCustomerFilterList().subscribe((data) => {
      this.filteredCustomers = [{label: 'Todos', value: null}].concat(
        data.map((c) => ({label: c.name, value: c.id}))
      );
    });
  }

  getBoats(): void {
    this.boatService.getAllByActiveForSelect().subscribe((data) => {
      this.filteredBoats = [{label: 'Todos', value: null}].concat(
        data.map((c) => ({label: c.label, value: c.value}))
      );
    });
  }

  getProducts(): void {
    this.productService.getAll().subscribe((data) => {
      this.productListTotal = data;

      this.productList = data.map((p) => ({label: p.name, value: p.id}));
      this.filter.productId = [];
      this.productList.forEach((item) => {
        if (item.value !== -1) {
          this.filter.productId.push(item.value);
        }
      });
    });
  }
}
