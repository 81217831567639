import { Component } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToastService } from 'src/app/services/toast.service';
import { OperatingDayConfigService } from 'src/app/services/config/operating-day-config.service';
import { CopyAndPasteMultipleModalComponent } from '../copy-and-paste-multiple-modal/copy-and-paste-multiple-modal.component';
import { DaySettingForQueueDto } from 'src/app/models/dtos/config/day-setting-for-queue-dto';
import { getDaySettingValidators, setValidators2ToIntervalEnd, setValidatorsToEnd, setValidatorsToIntervalStart, setValidatorsToReturnEnd, setValidatorsToStart } from 'src/app/validators/date/times';

@Component({
  selector: 'app-schedule-queue-form-modal',
  templateUrl: './schedule-queue-form-modal.component.html',
  styleUrls: ['./schedule-queue-form-modal.component.scss']
})
export class ScheduleQueueFormModalComponent {
  form: UntypedFormGroup;

  get days(): UntypedFormArray {
    return this.form?.controls?.days as UntypedFormArray;
  }

  scheduleQueueId: number;

  constructor(
    private readonly config: DynamicDialogConfig,
    private readonly dialogRef: DynamicDialogRef,
    private readonly operatingDayConfigService: OperatingDayConfigService,
    private readonly toasty: ToastService,
    private readonly dialog: DialogService
  ) {
    this.scheduleQueueId = this.config.data.scheduleQueueId;
    this.loadDataAndCreateForm(this.scheduleQueueId);
  }

  loadDataAndCreateForm(scheduleQueueId: number) {
    this.operatingDayConfigService.getAllDaySettingsForScheduleQueueDTO(scheduleQueueId).subscribe(
      (data) => {
        this.createFormArray(data);
      }
    );
  }

  createFormArray(daySettings: DaySettingForQueueDto[]) {
    const dayControl = daySettings.map(d => this.createDaySettingControl(d))
    this.form = new UntypedFormGroup({
      days: new UntypedFormArray(dayControl, [])
    }, []);
  }

  createDaySettingControl(daySetting: DaySettingForQueueDto): UntypedFormGroup {
    const daySettingControl: UntypedFormGroup = new UntypedFormGroup({
      id: new UntypedFormControl(daySetting.id),
      scheduleQueueId: new UntypedFormControl(daySetting.scheduleQueueId),
      operatingDay: new UntypedFormControl(daySetting.operatingDay),
      inOperation: new UntypedFormControl({ value: daySetting.inOperation, disabled: !daySetting.operatingDay.inOperation}, [Validators.required]),
      start: new UntypedFormControl({ value: daySetting.start, disabled: !daySetting.operatingDay.inOperation || !daySetting.inOperation}),
      end: new UntypedFormControl({ value: daySetting.end, disabled: !daySetting.operatingDay.inOperation || !daySetting.inOperation}),
      returnEnd: new UntypedFormControl({ value: daySetting.returnEnd, disabled: !daySetting.operatingDay.inOperation || !daySetting.inOperation}),
      intervalStart: new UntypedFormControl({ value: daySetting.intervalStart, disabled: !daySetting.operatingDay.inOperation || !daySetting.inOperation}),
      intervalEnd: new UntypedFormControl({ value: daySetting.intervalEnd, disabled: !daySetting.operatingDay.inOperation || !daySetting.inOperation}),
      executionTime: new UntypedFormControl({ value: daySetting.executionTime, disabled: !daySetting.operatingDay.inOperation || !daySetting.inOperation},[
        Validators.required, Validators.min(1)
      ]),
    }, getDaySettingValidators() );
    if (daySetting.operatingDay.inOperation) {
      const { operatingDay, start, end, returnEnd, intervalStart, intervalEnd, executionTime } = daySettingControl.controls;
      const { operationStart, operationEnd } = operatingDay.value;
      daySettingControl.controls.inOperation.valueChanges.subscribe((inOperationValue) => {
        const controlsToDisableOrEnable = [
          start, end, returnEnd, intervalStart, intervalEnd, executionTime
        ];
        if (inOperationValue) {
          controlsToDisableOrEnable.forEach(c => { c.enable(); })
        } else {
          controlsToDisableOrEnable.forEach(c => { c.disable(); })
        }
      })
      setValidatorsToStart(start, operationStart, end, intervalStart);
      setValidatorsToEnd(end, operationEnd, start, intervalStart);
      setValidatorsToReturnEnd(returnEnd, operationStart, operationEnd);
      setValidatorsToIntervalStart(intervalStart, start, end, intervalEnd);
      setValidators2ToIntervalEnd(intervalEnd, end, intervalStart);
    }
    return daySettingControl;
  }


  saveAll() {
    if (this.form.valid) {
      this.operatingDayConfigService.updateDaySettingsForScheduleQueueDTO(this.days.getRawValue() as DaySettingForQueueDto[])
        .subscribe((res) => {
          // this.createFormArray(res);
          this.toasty.success('Salvo com sucesso!');
          this.dialogRef.close();
        },
          (err) => {
            this.toasty.error('Ops, não foi possível salvar.');
          });
    }
  }

  close() {
    this.dialogRef.close();
  }

  openModalCopy(daySettingControl: UntypedFormGroup) {
    const daySetting: DaySettingForQueueDto = daySettingControl.value;
    const daysToPast = (this.days.getRawValue() as DaySettingForQueueDto[])
      .filter(d => d.operatingDay.inOperation && d.operatingDay.dayWeek != daySetting.operatingDay.dayWeek)
      .map(d => ({label: d.operatingDay.dayWeek.toString(), value: d.operatingDay.dayWeek}));

    const dialogRef = this.dialog.open(CopyAndPasteMultipleModalComponent, {
      header: 'Copiar dia',
      data: {
        toCopy: {label:  daySetting.operatingDay.dayWeek.toString(), value: daySetting.operatingDay.dayWeek},
        toPaste: daysToPast
      }
    })
    dialogRef.onClose.subscribe((result) => {
      if (result && result.toCopy && result.paste && result.paste.length > 0) {
        const copyControl = (this.days.getRawValue() as DaySettingForQueueDto[]).filter(d => d.operatingDay.dayWeek == result.toCopy);
        if (copyControl.length == 1) {
          const { inOperation, start, intervalStart, intervalEnd, end, returnEnd, executionTime } = copyControl[0];

          const daysToPasteControls = this.days.controls.filter(c => {
            const dayValue = c.value as DaySettingForQueueDto;
            return result.paste.includes(dayValue.operatingDay.dayWeek);
          })
          daysToPasteControls.forEach((d: UntypedFormGroup) => {
            d.controls.inOperation.setValue(inOperation);
            d.controls.inOperation.markAsDirty();
            d.controls.start.setValue(start);
            d.controls.start.markAsDirty();
            d.controls.intervalStart.setValue(intervalStart);
            d.controls.intervalStart.markAsDirty();
            d.controls.intervalEnd.setValue(intervalEnd);
            d.controls.intervalEnd.markAsDirty();
            d.controls.end.setValue(end);
            d.controls.end.markAsDirty();
            d.controls.returnEnd.setValue(returnEnd);
            d.controls.returnEnd.markAsDirty();
            d.controls.executionTime.setValue(executionTime);
            d.controls.executionTime.markAsDirty();
          })
        }
      }
    })
  }
}
