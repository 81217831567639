<div class="buttons">
  <button class="primary easymarine" (click)="createNewScheduleQueue()">
    Nova fila
  </button>
</div>
<br>
<p-table [value]="queues" autoLayout="true">
  <ng-template pTemplate="header">
    <tr>
      <th>
        Fila de descida
      </th>
      <th>
        Grupos
      </th>
      <th>
        Status
      </th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-queue>
    <tr>
      <td>
        {{queue.name}}
      </td>
      <td [pTooltip]="queue.groups" tooltipPosition="bottom">
        {{queue.groups}}
      </td>
      <td class="text-center">
        <div *ngIf="updatingActiveStatus[queue.id];
          then notCanUpdateActiveStatus
          else canUpdateActiveStatus">
        </div>
        <ng-template #canUpdateActiveStatus>
          <a [pTooltip]="queue.active ? 'Desativar' : 'Ativar'"
            class="badge badge-pill" [ngClass]="{'success':queue.active, 'danger': !queue.active}"
            tooltipPosition="bottom" (click)="updateActiveStatus(queue)"
          >
            {{ (queue.active ? 'ACTIVE' : 'INACTIVE') | translate }}
          </a>
        </ng-template>
        <ng-template #notCanUpdateActiveStatus>
          <i class="pi pi-spin pi-spinner"></i>
        </ng-template>
      </td>
      <td>
        <div (click)="edit(queue.id, queue.name)" class="table-action">
          <i class="pi pi-pencil"></i>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>
