import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InvoiceTaxDocument } from 'src/app/models/invoice-tax-document';
import { InvoiceTaxDocumentService } from 'src/app/services/invoice-tax-document.service';
import { ToastService } from 'src/app/services/toast.service';
import { FormUtil } from 'src/app/utils/form.util';
import { MessageUtil } from 'src/app/utils/message.util';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-invoice-tax-form',
  templateUrl: './invoice-tax-form.component.html',
  styleUrls: ['./invoice-tax-form.component.scss']
})
export class InvoiceTaxFormComponent implements OnInit {
  loading = false;
  model: InvoiceTaxDocument = new InvoiceTaxDocument();
  origin: string;
  invTaxDocumentForm: UntypedFormGroup;

  constructor(
    public dialogRef: DynamicDialogRef,
    private invTaxDocumentService: InvoiceTaxDocumentService,
    private messageUtil: MessageUtil,
    private spinner: NgxSpinnerService,
    private toasty: ToastService,
    private config: DynamicDialogConfig
  ) { }

  async ngOnInit(): Promise<void> {
    if (this.config.data) {
      debugger;
      this.model = this.config.data.model;
     // await this.findInvoiceTaxDocument(this.config.data.id);
      this.startForm(this.model);
    }

  }


  formValidations(): boolean {
    if (this.invTaxDocumentForm.valid) {
      return true;
    } else {
      FormUtil.touchAndSendFormGroup(this.invTaxDocumentForm);
      Swal.fire('Alerta!', 'Por favor, preencha os campos obrigatórios', 'warning');
      return false;
    }
  }


  startForm(invoiceTaxDocument: any): void {
    this.invTaxDocumentForm = new UntypedFormGroup({
      id: new UntypedFormControl(invoiceTaxDocument.id),
      nfeId: new UntypedFormControl(invoiceTaxDocument.nfeId),
      empresaId: new UntypedFormControl(invoiceTaxDocument.empresaId),
      nfeNumero: new UntypedFormControl(invoiceTaxDocument.nfeNumero),
      nfeStatus: new UntypedFormControl(invoiceTaxDocument.nfeStatus),
      status: new UntypedFormControl(invoiceTaxDocument.status),
      marina: new UntypedFormControl(invoiceTaxDocument.marina.companyName),
      marinaCompany: new UntypedFormControl(invoiceTaxDocument.marinaCompany?.companyName),
      invoiceNumber: new UntypedFormControl(invoiceTaxDocument.invoice.invoiceNumber),
      aliquotaIss: new UntypedFormControl(invoiceTaxDocument.aliquotaIss),
      valorTotal: new UntypedFormControl(invoiceTaxDocument.valorTotal),
      createDate: new UntypedFormControl(invoiceTaxDocument.createDate),
      updateDate: new UntypedFormControl(invoiceTaxDocument.updateDate),
      federalId: new UntypedFormControl(invoiceTaxDocument.federalId),
      type: new UntypedFormControl(invoiceTaxDocument.type),
      serviceDocument: new UntypedFormControl(invoiceTaxDocument.serviceDocument),
      serviceDocumentXml: new UntypedFormControl(invoiceTaxDocument.serviceDocumentXml)
    });
  }


  async findInvoiceTaxDocument(id: number): Promise<void> {
    return new Promise<void>(
      async (res) => {
        this.invTaxDocumentService.findById(id).subscribe(
          (data) => {
            console.log(data);
            res();
          },
          (error) => {
            console.log(error);
          }
        );

      }
    );
  }

}
