export class InvoiceQuotaParcelDTO {

    valor: number;
    numero: number;
    dataVencimento: Date;

    constructor(valor: number, numero: number, dataVencimento: Date) {
        this.valor = valor;
        this.numero = numero;
        this.dataVencimento = dataVencimento;
    }
}
