import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Customer } from 'src/app/models/customer';
import { CustomerService } from 'src/app/services/customer.service';
import { SimpleCadCustomerComponent } from './simple-cad-customer/simple-cad-customer.component';
import Swal from 'sweetalert2';
import { Sling } from 'src/app/models/sling';
import { ProductCategoryType } from 'src/app/models/enums/product-category-type';
import { SlingService } from 'src/app/services/sling.service';
import { Conta, MarinaOrder, OrderItem, OrderQuota, SlingConfig } from 'src/app/models';
import { ToastService } from 'src/app/services/toast.service';
import { OperationalConfigService } from 'src/app/services/config/operational-config.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { Router } from '@angular/router';
import { MarinaOrderService } from 'src/app/services/marina-order.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { OrderIssCityTaxRequestDTO } from 'src/app/models/dtos/order-iss-city-tax-requestDTO';
import { NgxSpinnerService } from 'ngx-spinner';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { InvoicePaidService } from 'src/app/services/invoice-paid.service';
import { StorageUtil } from 'src/app/utils/storage.util';
import { InvoicePaid } from 'src/app/models/invoice-paid';
import { SalesStatesService } from './split-bill/sales-states.service';
import { SelectorBoatDTO } from 'src/app/models/dtos/selector-boat-DTO';
import { BoatSingleOrderParams, StandardPaymentConfig } from './split-bill/sales-interfaces';
import { TipoFormaPagamento } from 'src/app/models/finance/tipo-forma-pagamento';
import { PrintingService } from 'src/app/services/printing-service';
import { OptionPrintQuestionTotem } from 'src/app/models/enums/option-print-question-totem';
import { PrintType } from 'src/app/models/enums/print-type';
import { NotaFiscalService } from "../../../../services/nota-fiscal.service";

@Component({
  selector: 'app-sales-checkout-wizard',
  templateUrl: './sales-checkout-wizard.component.html',
  styleUrls: ['./sales-checkout-wizard.component.scss']
})
export class SalesCheckoutWizardComponent {

  @ViewChild(SimpleCadCustomerComponent, { static: false })
  simpleCadCustomer: SimpleCadCustomerComponent;

  activeIndex: number = 0;

  requiresCustomerIdentification: boolean = false;

  showQuota: boolean = false;
  showPaymentOptions: boolean = false;
  showNewCad: boolean = false;
  showAdvancedPayment: boolean = false;

  isSingleOrder: boolean = false;
  isSplitBill: boolean = false;
  isNixPix: boolean = false;
  isFirstDiscount: boolean = true;
  isFirstIssCityTax: boolean = true;
  isCanceled: boolean = false;
  isError: boolean = false;

  enablePayment: boolean = false;
  hasEnded: boolean = false;

  sling: Sling;

  slingConfig: SlingConfig;
  standardPaymentConfig: StandardPaymentConfig;
  isMovement: boolean;

  validCustomerSelected: boolean = false;
  validSplitBillItems: boolean = true;

  customers: Customer[] = [];
  selectedBoat: SelectorBoatDTO;
  selectedCustomer: Customer = new Customer();
  serviceDeskCustomer: Customer = new Customer();

  orderedItems: OrderItem[];
  ownersQuota: OrderQuota[] = [];
  ownerOrderQuotas: OrderQuota[];
  discount: number = 0;
  paymentMethod: TipoFormaPagamento;
  account: Conta;
  issCityTax: number;
  issCityTaxValue: number = 0;
  total: number;

  invoice: any = null;
  payments: any[] = [];

  boatSingleOrderParams: BoatSingleOrderParams;
  pendingValue: number = 0;
  maxPendingValue: number = 0;
  hasNFConsumer: boolean = false;
  constructor(
    private cdr: ChangeDetectorRef,
    private webSocketService: WebSocketService,
    private invoiceService: InvoiceService,
    private invoicePaidService: InvoicePaidService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private marinaOrderService: MarinaOrderService,
    private customerService: CustomerService,
    private messageUtil: MessageUtil,
    private slingService: SlingService,
    private toasty: ToastService,
    private config: DynamicDialogConfig,
    public dialog: DynamicDialogRef,
    private operationalConfigService: OperationalConfigService,
    private salesStatesService: SalesStatesService,
    private notaFiscalService: NotaFiscalService,
    private printingService: PrintingService
  ) {
    this.operationalConfigService.isMovements().then((isMovement: boolean) => {
      this.isMovement = isMovement;
    })
    this.operationalConfigService.hasNFConsumer().then((hasNFConsumer: boolean) => {
      this.hasNFConsumer = hasNFConsumer;
    })
  }

  ngOnInit(): void {
    this.orderedItems = [...this.config.data.orderedItems];
    this.total = this.config.data.total;
    this.slingConfig = this.config.data.slingConfig;
    this.showAdvancedPayment = this.slingConfig.showAdvancedPayment;
    this.standardPaymentConfig = {
      accountId: this.config.data.slingConfig.accountId,
      accountName: this.config.data.slingConfig.accountName,
      paymentMethodId: this.config.data.slingConfig.paymentMethodId,
      paymentMethodName: this.config.data.slingConfig.paymentMethodName,
    }
    this.account = this.config.data.slingConfig.accountId;
    this.paymentMethod = this.config.data.slingConfig.paymentMethodId;
    this.issCityTax = this.config.data.slingConfig.cityTax;
    this.requiresCustomerIdentification = this.config.data.requiresCustomerIdentification;

    if (!this.requiresCustomerIdentification) {
      this.continueAsSingleOrder();
    }
    this.loadParams();
    this.pendingValue = this.getTotal();
    this.maxPendingValue = this.getTotal();
    this.salesStatesService.setSalesConfig(this.slingConfig);
  }

  loadParams(): void {
    this.boatSingleOrderParams = ({
      customerId: this.config.data.params.customerId,
      boatId: this.config.data.params.boatId,
      slingId: this.config.data.params.slingId,
    })
  }

  changeSelectForm() {
    this.selectedBoat = null;
    this.selectedCustomer = null;
    this.showNewCad = !this.showNewCad;
    this.isNixPix = false;
  }

  selectCustomer(event) {
    this.selectedCustomer = event.customer;
    this.selectedBoat = event.boat;
    this.validCustomerSelected = true;
    this.getTotalWithheldIssCityTax();
    if (this.boatSingleOrderParams.slingId) {
      this.continueAsCommonOrder(true);
    }
  }

  async continueAsNewCad(event?) {
    if (event == null) {
      this.simpleCadCustomer.save();
    } else {
      this.selectedCustomer = event;
      this.validCustomerSelected = true;
      this.setOrderData();
      this.updateActiveIndex(1);
    }
  }

  changeSplitItens() {
    this.orderedItems.forEach((item) => {
      item.split = this.isSplitBill;
    });
    this.splitPayments();
  }

  changePaymentForm() {
    this.showPaymentOptions = !this.showPaymentOptions;
    if (this.showPaymentOptions == false) {
      this.discount = 0;
    } else {
      this.ownersQuota = [];
    }
  }

  selectQuota(event) {
    this.ownersQuota = event;
  }

  selectDiscount(event) {
    this.discount = event.discount;
    this.pendingValue = this.getTotal();
  }

  isPayment(event) {
    this.showPaymentOptions = event;
  }

  selectPayment(event) {
    this.enablePayment = true;
    this.account = event.account;
    this.paymentMethod = event.paymentMethod;
    this.isNixPix = event.isNixPixSelected;
    this.cdr.detectChanges();
  }

  generateDocuments(invoice: any): void {
    const invoices: any[] = [];
    invoices.push(invoice);
    const countRequestDocuments = invoices.filter((i) => i.origin === 'SLING' || i.origin === 'DETACHED').length;
    this.exit();
    if (countRequestDocuments !== 0) {
      this.router.navigate(['/app/billing/nf-emitter'], { state: { invoiceList: invoices, slingConfig: this.config.data.slingConfig } });
    } else {
      this.router.navigate(['/app/billing/nf-emitter'], { state: { invoiceList: invoices, slingConfig: this.config.data.slingConfig } });
    }
  }

  paymentComplete() {
    this.hasEnded = true;
    this.updateActiveIndex(3);
  }

  async disposeAndCancel() {
    if (!this.isError) {
      if (this.invoice) {
        await Swal.fire({
          title: 'Cancelamento',
          text: 'Deseja cancelar a fatura dessa venda?',
          icon: 'warning',
          reverseButtons: true,
          showDenyButton: true,
          customClass: {
            confirmButton: ['new-easymarine', 'primary'],
            denyButton: ['new-easymarine', 'disabled']
          },
          confirmButtonText: 'SIM',
          denyButtonText: 'NÃO',
        }).then((result) => {
          this.spinner.show();
          if (result.isConfirmed) {
            let createCopy = false;
            this.invoiceService.cancel(this.invoice.id, createCopy).subscribe({
              next: (data) => {
                this.isCanceled = true;
                this.isNixPix = false;
                this.updateActiveIndex(2);
                this.spinner.hide();
              },
              error: (error) => {
                this.isCanceled = true;
                this.isError = true;
                if (this.activeIndex == 2) {
                  this.updateActiveIndex(3);
                } else {
                  this.updateActiveIndex(2);
                }
                const exception = error.error.data.exception;
                this.messageUtil.generateMessage(MessageUtil.ERROR, exception.title, exception.message);
                this.spinner.hide();
              }
            }
            );
          }
          if (result.isDenied) {
            this.spinner.hide();
          }
        });
      } else {
        this.selectedCustomer = null;
        this.selectedBoat = null;
        this.orderedItems = [];
        this.ownersQuota = [];
        this.ownerOrderQuotas = [];
        this.discount = null;
        this.paymentMethod = null;
        this.account = null;
        this.invoice = null;
        this.issCityTax = null;
        this.total = null;
        this.showQuota = false;
        this.showPaymentOptions = false;
        this.showNewCad = false;
        this.validCustomerSelected = false;
        this.updateActiveIndex(0);
        this.isSingleOrder = false;
        this.isNixPix = false;
        this.slingConfig = null;
        this.spinner.hide();
        this.dialog.close();
      }
    } else {
      await Swal.fire({
        title: 'Cancelamento',
        text: 'Deseja ir para a tela de faturas?',
        icon: 'warning',
        reverseButtons: true,
        showDenyButton: true,
        customClass: {
          confirmButton: ['new-easymarine', 'primary'],
          denyButton: ['new-easymarine', 'disabled']
        },
        confirmButtonText: 'SIM',
        denyButtonText: 'NÃO',
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['/app/billing/invoice']);
          this.dialog.close();
        }
        if (result.isDenied) {
          this.exit()
        }
      });
    }
  }

  async continueAsCommonOrder(fromSling?: boolean) {

    if (this.getOwnersCount() > 1 && !fromSling) {
      this.showQuota = true;
    } else {
      this.showQuota = false;
    }

    this.setOrderData();
    this.updateActiveIndex(1);
  }

  setOrderData() {
    this.salesStatesService.setOrderData({
      orderedItems: this.config.data.orderedItems,
      slingConfig: this.config.data.slingConfig,
      selectedCustomer: this.selectedCustomer,
      serviceDeskCustomer: this.serviceDeskCustomer,
      selectedBoat: this.selectedBoat,
      total: this.config.data.total,
      hasInvoice: this.invoice != null,
      discountedValue: this.discount,
    });
  }

  findServiceDeskCustomer() {
    this.customerService.getByServiceDeskCustomer().subscribe((data) => {
      this.serviceDeskCustomer = data;
    });
  }

  continueAsSingleOrder() {
    this.customers = [];
    this.selectedCustomer = null;
    this.selectedBoat = null;
    this.findServiceDeskCustomer();
    this.setOrderData();
    this.showPaymentOptions = true;
    this.isSingleOrder = true;
    this.updateActiveIndex(1);
  }

  finishRequest(): void {
    this.save(null);
  }


  getOwnersCount(): number {
    let count = 0;
    if (this.selectedBoat) {
      for (const boat of this.selectedBoat.boatCustomers) {
        if (boat.type === 'Owner') {
          count++;
        }
      }
    }
    return count;
  }

  save(data): void {
    let text = '';
    if (data) {
      if (this.isNixPix || this.isSplitBill) {
        text = `Faturamento de R$${this.formatNumber(this.getTotal())}`;
      } else {
        text = `Pagamento de R$${this.formatNumber(this.pendingValue)}`;
      }
    } else {
      text = `Gerar Pedido de R$${this.formatNumber(this.getTotal())}`;
    }
    Swal.fire({
      title: 'Confirmar operação?',
      text: text,
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      allowOutsideClick: false,
      preConfirm: () => {
        this.spinner.show();
        if (this.sling?.id) {
          this.slingSave();
        } else {
          this.createSingleOrder(data);
        }
      }
    }).then((result) => {
      if (!result.isConfirmed) {
        this.spinner.hide();
      }
    });
  }

  payGeneratedInvoice(data: any, parcial: boolean) {
    if (this.invoice == null || parcial) {
      let text = '';
      if (this.isNixPix || this.isSplitBill) {
        text = `Faturamento de R$${this.formatNumber(this.invoice.pendingValue)}`;
      } else {
        text = `Pagamento de R$${this.formatNumber(this.pendingValue)}`;
      }
      Swal.fire({
        title: 'Confirmar operação?',
        text: text,
        showCancelButton: true,
        confirmButtonText: 'Confirmar',
        showLoaderOnConfirm: true,
        reverseButtons: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        allowOutsideClick: false,
        preConfirm: () => {
          this.spinner.show();
          this.createInvoicePaid(data);
        }
      }).then((result) => {
        if (!result.isConfirmed) {
          this.spinner.hide();
        }
      });
    } else {
      this.spinner.show();
      this.createInvoicePaid(data);
    }
  }

  createInvoicePaid(data: any) {
    let employeeUser = StorageUtil.getUser();
    const invoicePaid: InvoicePaid = new InvoicePaid();

    invoicePaid.discount = this.invoice.discount;
    invoicePaid.interest = this.invoice.interest;
    invoicePaid.value = this.invoice.value;
    invoicePaid.username = this.invoice.customer.name;
    invoicePaid.employeeUser = employeeUser.firstName + " " + employeeUser.lastName;
    invoicePaid.invoiceId = this.invoice.id;
    invoicePaid.totalPaid = this.pendingValue;
    invoicePaid.dateProcessing = this.invoice.processingDate;
    invoicePaid.datepaid = new Date();

    invoicePaid.accountId = data.account.idConta;
    invoicePaid.accountName = data.account.nome;
    invoicePaid.paymentMethodId = data.paymentMethod.idTipoFormaPagamento;
    invoicePaid.paymentMethodName = data.paymentMethod.descricao;

    this.invoicePaidService.payInvoice(invoicePaid).subscribe({
      next: (data) => {
        if (this.isFirstDiscount) {
          this.isFirstDiscount = false;
          this.maxPendingValue -= this.discount;
        }
        this.maxPendingValue -= this.pendingValue;
        if (this.maxPendingValue > 0) {
          this.pendingValue = this.maxPendingValue;
          this.messageUtil.generateMessage(MessageUtil.SUCCESS, 'Faturamento', 'Pagamento realizado com sucesso, faltam R$' + this.formatNumber(this.pendingValue) + ' para quitar a fatura.');
          this.setOrderData();
          this.spinner.hide();
        } else {
          this.spinner.hide();
          if (this.slingConfig.printNavigationPlanWeb == OptionPrintQuestionTotem.Question) {
            Swal.fire({
              title: 'Atenção',
              text: 'Deseja imprimir o recibo de pagamento?',
              icon: 'warning',
              reverseButtons: true,
              showDenyButton: true,
              customClass: {
                confirmButton: ['new-easymarine', 'primary'],
                denyButton: ['new-easymarine', 'disabled']
              },
              confirmButtonText: 'SIM',
              denyButtonText: 'NÃO',
            }).then(async (result) => {
              if (result.isConfirmed) {
                await this.printReceipt(this.invoice.id);
                this.updateActiveIndex(2);
              } else {
                this.updateActiveIndex(2);
              }
            });
          }
          if (this.slingConfig.printNavigationPlanWeb == OptionPrintQuestionTotem.AutoPrint) {
            this.printReceipt(this.invoice.id);
            this.updateActiveIndex(2);
          }
          if (this.slingConfig.printNavigationPlanWeb == OptionPrintQuestionTotem.NoAction) {
            this.updateActiveIndex(2);
          }
        }
      },
      error: (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(MessageUtil.ERROR, exception.title, exception.message);
        this.spinner.hide();
      }
    }
    );
  }

  slingSave(): void {
    const items = this.sling.marinaOrder.orderItems.filter(
      (item) => item.product.productCategory.productCategoryType !== ProductCategoryType.Consumable
    );
    this.config.data.orderedItems.forEach((item) => items.push(item));
    this.sling.marinaOrder.orderItems = items;

    this.slingService.save(this.sling).subscribe(() => {
      this.toasty.success('Pedido salvo com sucesso.');
      this.exit();
    },
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(MessageUtil.ERROR, exception.title, exception.message);
      }
    );
  }

  exit(): void {
    if (!this.isMovement) {
      this.dialog.close();
      this.router.navigate(['/app/operational/checking']);
    } else if (this.isMovement) {
      this.dialog.close();
      this.router.navigate(['/app/operational/movement']);
    }
  }

  createSingleOrder(account): void {
    this.convertOwnerQuota();
    const marinaOrder: MarinaOrder = this.createMarinaOrder(account);
    this.marinaOrderService.createSingleOrder(marinaOrder).subscribe({
      next: (marinaOrderSave) => {
        if (account != null) {
          this.findInvoice(marinaOrderSave);
        } else {
          this.updateActiveIndex(2);

          if (this.slingConfig.printNavigationPlanWeb == OptionPrintQuestionTotem.Question) {
            Swal.fire({
              title: 'Atenção',
              text: 'Deseja imprimir a Comanda?',
              icon: 'warning',
              reverseButtons: true,
              showDenyButton: true,
              customClass: {
                confirmButton: ['new-easymarine', 'primary'],
                denyButton: ['new-easymarine', 'disabled']
              },
              confirmButtonText: 'SIM',
              denyButtonText: 'NÃO',
            }).then(async (result) => {
              if (result.isConfirmed) {
                await this.printOrderReport(marinaOrderSave.id);
              }
              if (result.isDenied || result.isDismissed) {
              }
            });
          }
          if (this.slingConfig.printNavigationPlanWeb == OptionPrintQuestionTotem.AutoPrint) {
            this.printOrderReport(marinaOrderSave.id);
          }
          this.spinner.hide();
        }
      },
      error: (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(MessageUtil.ERROR, exception.title, exception.message);
        this.spinner.hide();
      }
    });
  }

  convertOwnerQuota(): void {
    this.ownerOrderQuotas = [];
    if (this.ownersQuota?.length > 0) {
      for (const owner of this.ownersQuota) {
        const quota: OrderQuota = new OrderQuota();
        quota.customer = owner.customer;
        quota.quota = owner.singleOrderQuota;
        if (quota.quota > 0) {
          this.ownerOrderQuotas.push(quota);
        }
      }
    } else {
      const quota: OrderQuota = new OrderQuota();
      quota.customer = this.selectedCustomer != null ? this.selectedCustomer : this.serviceDeskCustomer;
      quota.quota = 100;
      this.ownerOrderQuotas.push(quota);

    }
  }

  createMarinaOrder(data): MarinaOrder {
    const marinaOrder = new MarinaOrder();
    marinaOrder.customer = this.selectedCustomer;
    marinaOrder.orderItems = this.config.data.orderedItems;
    marinaOrder.orderQuotas = this.ownerOrderQuotas;
    if (data) {
      marinaOrder.paymentMethodId = data.paymentMethod?.idTipoFormaPagamento ?? this.standardPaymentConfig.paymentMethodId;
      marinaOrder.paymentMethodName = data.paymentMethod?.descricao ?? this.standardPaymentConfig.paymentMethodName;
      marinaOrder.accountId = data.account?.idConta ?? this.standardPaymentConfig.accountId;
      marinaOrder.accountName = data.account?.nome ?? this.standardPaymentConfig.accountName;
      marinaOrder.discount = data.discount;
      marinaOrder.cashDown = 1;
      marinaOrder.pay = data.pay;
    }
    marinaOrder.lastEmployeeName = StorageUtil.getUserName();
    marinaOrder.orderDate = null;
    marinaOrder.value = this.config.data.total;
    marinaOrder.boat = this.selectedBoat;
    marinaOrder.sling = null;
    marinaOrder.marina = null;
    if (this.pendingValue != this.getTotal()) {
      marinaOrder.pay = false;
    }
    return marinaOrder;
  }

  findInvoice(marinaOrder): void {
    this.invoiceService.findByOrder(marinaOrder.id).subscribe({
      next: (data) => {
        if (data.length !== 0) {
          this.invoice = data[0];
          this.invoice.paidValue = data[0].totalPaid;
          if (this.invoice) {
            if (this.isNixPix) {
              this.spinner.hide();
              this.subscribeToWebStocket();
            }
            if (this.invoice.pendingValue > 0) {
              this.payFinishRequest(false);
            } else {
              this.spinner.hide();
              if (this.slingConfig.printNavigationPlanWeb == OptionPrintQuestionTotem.Question) {
                Swal.fire({
                  title: 'Atenção',
                  text: 'Deseja imprimir o recibo de pagamento?',
                  icon: 'warning',
                  reverseButtons: true,
                  showDenyButton: true,
                  customClass: {
                    confirmButton: ['new-easymarine', 'primary'],
                    denyButton: ['new-easymarine', 'disabled']
                  },
                  confirmButtonText: 'SIM',
                  denyButtonText: 'NÃO',
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    await this.printReceipt(this.invoice.id);
                    this.updateActiveIndex(2);
                  } else {
                    this.updateActiveIndex(2);
                  }
                });
              }
              if (this.slingConfig.printNavigationPlanWeb == OptionPrintQuestionTotem.AutoPrint) {
                this.printReceipt(this.invoice.id);
                this.updateActiveIndex(2);
              }
              if (this.slingConfig.printNavigationPlanWeb == OptionPrintQuestionTotem.NoAction) {
                this.updateActiveIndex(2);
              }
            }
          } else {
            this.spinner.hide();
            this.exit();
          }
        }
      },
      error: (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(MessageUtil.ERROR, exception.title, exception.message);
      }
    }
    );
  }

  splitPayments() {
    let total = this.calculateTotalItems();
    let totalSplited = 0;
    this.orderedItems.forEach((item: any) => {
      if (item.split) {
        totalSplited = totalSplited + (item.itemValue * item.qtySupplied);
      }
    });
    let equalParts = totalSplited / 2;
    let notSplitedPart = total - totalSplited;
    let notSplitedPlusEqualParts = notSplitedPart + equalParts;

    this.payments = [];
    let validTotal = 0
    for (let i = 0; i < 2; i++) {
      this.payments.push({
        id: i + 1,
        customer: `Customer ${i + 1}`,
        split: equalParts,
        total: i == 0 ? notSplitedPlusEqualParts : equalParts,
        numberOfCustomers: 2,
        totalSplited: totalSplited,
        notSplitedPart: notSplitedPart,
        totalTotal: total,
      });
      validTotal += this.payments[i].total;
    }
    if (validTotal !== total) {
      this.payments[0].total += total - validTotal;
    }

    this.salesStatesService.setPayments(this.payments);
  }

  async payFinishRequest(parcial: boolean): Promise<void> {
    const data = ({
      slingConfig: this.slingConfig,
      issCityTax: this.issCityTaxValue,
      orderedItems: this.orderedItems,
      selectedCustomer: this.selectedCustomer,
      serviceDeskCustomer: this.serviceDeskCustomer,
      paymentMethod: this.paymentMethod,
      account: this.account,
      discount: this.discount,
      total: this.total,
      pay: this.isNixPix || this.isSplitBill ? false : true,
    });

    if (this.invoice != null) {
      if (this.isSplitBill) {
        this.salesStatesService.setInvoice(this.invoice);
        this.updateActiveIndex(2);
      } else if (this.isNixPix) {
        this.subscribeToWebStocket()
        this.updateActiveIndex(2);
      } else {
        this.payGeneratedInvoice(data, parcial);
      }
    } else {
      this.save(data);
    }
  }

  getTotalWithheldIssCityTax() {
    if (this.selectedCustomer != null) {
      this.convertOwnerQuota();
      let issTaxRequest: OrderIssCityTaxRequestDTO = {
        customerId: this.selectedCustomer.id,
        order: this.createMarinaOrder(null)
      };

      this.marinaOrderService.getWhitheldIssTaxByOrder(issTaxRequest).subscribe({
        next: async (data) => {
          this.issCityTaxValue = data;
          if (this.isFirstIssCityTax) {
            this.isFirstIssCityTax = false;
            this.pendingValue -= this.issCityTaxValue ?? 0;
            this.maxPendingValue -= this.issCityTaxValue ?? 0;
          }
        },
        error: (error) => {
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(MessageUtil.ERROR, exception.title, exception.message);
        }
      }
      );
    }
    this.setOrderData();
  }

  getTotal(): number {
    let total = 0;
    total += this.total;

    if (this.showPaymentOptions) {
      total -= this.issCityTax ? this.issCityTaxValue : 0;
      total -= this.discount ? this.discount : 0;
    }

    return total;
  }

  formatNumber(number: number): string {
    return number.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  validToIssueNf(invoice: any): boolean {
    const orderItens: any[] = invoice.orders[0].orderItems;
    if (orderItens != null && orderItens.length > 0) {
      orderItens.forEach((item) => {
        if (item.productissueNotaFiscal == true) {
          return true;
        }
      });
    }
    return false;
  }

  updateActiveIndex(index: number) {
    this.spinner.hide();
    this.activeIndex = index;
  }

  subscribeToWebStocket() {
    this.webSocketService.getMessageNixPix(this.invoice.id).subscribe({
      next: (message) => {
        if (message.headers !== null && message.headers.destination !== null && message.headers.destination !== '') {
          const queryParts = message.headers.destination.split('/');
          if (queryParts.length >= 3) {
            const invoiceId = queryParts[2];
            if (invoiceId == this.invoice.id.toString()) {
              this.updateActiveIndex(3);
            }
          }
        }
      },
      error: (e) => console.log(e),
    });
  }

  async back() {
    this.setOrderData()
    this.updateActiveIndex(1);
    this.showPaymentOptions = true;
  }


  continueAsSplitBill() {
    this.isNixPix = false;
    this.spinner.show();
    this.payFinishRequest(true);
  }

  validateSplitBillItems() {
    this.validSplitBillItems = false;
    this.orderedItems.forEach((item) => {
      if (item.split) {
        this.validSplitBillItems = true;
        return;
      }
    });
    this.splitPayments();
  }

  calculateTotalItems() {
    let itemTotal = 0;
    this.orderedItems.forEach((item) => {
      itemTotal += item.qtySupplied * item.itemValue;
    });
    return itemTotal;
  }

  generateNfc() {
    this.spinner.show();
    const newInvoiceId = this.invoice.id;
    if (newInvoiceId === null) {
      this.spinner.hide();
      return;
    }
    this.notaFiscalService.generateNFConsumidor(newInvoiceId).subscribe({
      next: (data) => {
        console.log("Retornou a nota fiscal!!!")
        console.log(data)
        this.toasty.success("Nota gerada com sucesso!");
        this.spinner.hide();
        let url = data.caminho_danfe;
        console.log(url)
        window.open(url)
      }, error: (err) => {
        this.spinner.hide();
        console.log(err)
        const exception = err.error.message;
        this.toasty.error(exception)
      }
    })
  }

  printReceipt(invoicePaidId: number): void {
    this.spinner.show();
    this.printingService.generatePdfById(invoicePaidId, PrintType.RECEIPT).subscribe((data) => {
      this.spinner.hide();
      const url = window.URL.createObjectURL(data);
      window.open(url);
    });
  }

  printOrderReport(id: number): void {
    this.spinner.show();
    const idsSling: number[] = [id];
    this.printingService.generatePdfById(id, PrintType.ORDER_CHECK).subscribe((data) => {
      this.spinner.hide();
      const url = window.URL.createObjectURL(data);
      window.open(url);
    });
  }
}
