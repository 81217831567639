import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class EmailAccountService {

    constructor(private http: HttpClient) { }

    getnumberOfMessages(): Observable<any> {
        return this.http.get(environment.apiHost + '/secure/email-account/').pipe(
            map((response: any) => response.data.marinaCustomerBillingEmail)
        );
    }
}
