<ng-sidebar-container class="container">
  <ng-sidebar [animate]="animated"
    [ngClass]="{'hover': hover, 'docked-side': !sidebar, 'animate-checked': animate, 'animate-unchecked': animate}"
    [(opened)]="sidebar || hover" [dock]="dock" dockedSize="60px" (mouseenter)="hover = true"
    (mouseleave)="hover = false">
    <app-sidenav></app-sidenav>
  </ng-sidebar>
</ng-sidebar-container>
<app-header></app-header>
<div class="page" [ngClass]="{'checked': sidebar}" id="page" #page>
  <section class="container-fluid">
    <router-outlet></router-outlet>
  </section>
</div>
<app-footer></app-footer>
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
