import { UserLogComponent } from './pages/logs/user-log/user-log.component';
import { SailorComponent } from './pages/forms/sailor/sailor.component';
import { MovementsConfigComponent } from './pages/configs/movements-config/movements-config.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AuthGuard } from './guards/auth-guard';
import { LoginComponent } from './pages/login/login.component';
import { CleanComponent } from './layouts/clean/clean.component';
import { DefaultComponent } from './layouts/default/default.component';
import { SlingComponent } from './pages/operational/sling/sling.component';
import { SlingCheckComponent } from './pages/operational/sling-check/sling-check.component';
import { WashEngineTurnComponent } from './pages/operational/wash-engine-turn/wash-engine-turn.component';
import { NavigationPlanComponent } from './pages/operational/navigation-plan/navigation-plan.component';
import { SlingTotemComponent } from './components/totem/sling-totem/sling-totem.component';
import { ReprintComponent } from './components/totem/reprint/reprint.component';
import { TotemNavigationPlanComponent } from './components/totem/totem-navigation-plan/totem-navigation-plan.component';
import { BoatWashEngineTurnPanelComponent } from './components/totem/boat-wash-engine-turn-panel/boat-wash-engine-turn-panel.component';
import { SingleOrderComponent } from './components/totem/single-order/single-order.component';
import { TuitionsComponent } from './pages/billing/tuitions/tuitions.component';
import { OrderComponent } from './pages/billing/order/order.component';
import { OrderInvoiceComponent } from './pages/billing/order-invoice/order-invoice.component';
import { InvoiceComponent } from './pages/billing/invoice/invoice.component';
import { TotemDebitsComponent } from './components/totem/totem-debits/totem-debits.component';
import { DebitsComponent } from './pages/billing/debits/debits.component';
import { DailyDebitsComponent } from './pages/billing/daily-debits/daily-debits.component';
import { PendingCustomersComponent } from './pages/billing/pending-customers/pending-customers.component';
import { ReceivingAccountsComponent } from './pages/billing/receiving-accounts/receiving-accounts.component';
import { OutfileComponent } from './pages/billing/billets-management/outfile/outfile.component';
import { BilletComponent } from './pages/billing/billets-management/billet/billet.component';
import { AnticipationComponent } from './pages/billing/anticipation/anticipation.component';
import { ManagementComponent } from './pages/billing/anticipation/management/management.component';
import { SummaryComponent } from './pages/billing/anticipation/summary/summary.component';
import { FinancesComponent } from './pages/finances/finances.component';
import { GroupComponent } from './pages/forms/group/group.component';
import { UserComponent } from './pages/forms/user/user.component';
import { CustomerComponent } from './pages/forms/customer/customer.component';
import { BoatComponent } from './pages/forms/boat/boat.component';
import { VacancyComponent } from './pages/forms/vacancy/vacancy.component';
import { ProductComponent } from './pages/stock/product/product.component';
import { BalanceAdjustmentComponent } from './pages/stock/balance-adjustment/balance-adjustment.component';
import { MyInvoiceComponent } from './pages/my-account/my-invoice/my-invoice.component';
import { SlingLogComponent } from './pages/logs/sling-log/sling-log.component';
import { MarinaOrderLogComponent } from './pages/logs/marina-order-log/marina-order-log.component';
import { SlingShareLogComponent } from './pages/logs/sling-share-log/sling-share-log.component';
import { BoatsQrcodesComponent } from './pages/qrcodes/boats-qrcodes/boats-qrcodes.component';
import { VacanciesQrcodesComponent } from './pages/qrcodes/vacancies-qrcodes/vacancies-qrcodes.component';
import { NoticiesComponent } from './pages/configs/noticies/noticies.component';
import { SirenAlertConfigComponent } from './pages/configs/siren-alert-config/siren-alert-config.component';
import { PanelConfigComponent } from './pages/configs/panel-config/panel-config.component';
import { SlingConfigComponent } from './pages/configs/sling-config/sling-config.component';
import { ContractsComponent } from './pages/billing/contracts/contracts.component';
import { DocumentsContractedComponent } from './pages/my-account/documents-contracted/documents-contracted.component';
import { UserRoleComponent } from './pages/forms/user-role/user-role.component';
import { BoatDetailServicesComponent } from './pages/forms/boat/boat-detail-services/boat-detail-services.component';
import { InvoiceTaxComponent } from './pages/billing/invoice-tax-management/invoice-tax/invoice-tax.component';
import { NotaFiscalComponent } from './pages/configs/nota-fiscal/nota-fiscal.component';
import { ContractsConfigTemplateComponent } from './pages/configs/contracts-config-template/contracts-config-template.component';
import { ContractsConfigDefaultSignerComponent } from './pages/configs/contracts-config-default-signer/contracts-config-default-signer.component';
import { BoatLogComponent } from './pages/logs/boat-log/boat-log.component';
import { CustomerLogComponent } from './pages/logs/customer-log/customer-log.component';
import { ProductLogComponent } from './pages/logs/product-log/product-log.component';
import { ContractLogComponent } from './pages/logs/contract-log/contract-log.component';
import { UserSmsLogComponent } from './pages/logs/user-sms-log/user-sms-log.component';
import { PivotProductSalesComponent } from './pages/pivot/pivot-product-sales/pivot-produtc-sales.component';
import { ServiceReadjustmentComponent } from './pages/stock/service-readjustment/service-readjustment.component';
import { ServiceComparisonComponent } from './pages/billing/service-comparison/service-comparison.component';
import { MovementAppointmentComponent } from './pages/operational/movement/movement-appointment/movement-appointment.component';
import { MovementExecutionOperationLogComponent } from './pages/logs/movement-execution-operation-log/movement-execution-operation-log.component';
import { MovementPlanComponent } from './pages/operational/movement/movement-plan/movement-plan.component';
import { MenuItemGuard } from './guards/menu-item.guard';
import { OperatingDayConfigComponent } from './pages/configs/operating-day-config/operating-day-config.component';
import { MovementLocationsConfigComponent } from './pages/configs/movement-locations-config/movement-locations-config.component';
import { ProductHistoryComponent } from './pages/reports/product-history/product-history.component';
import { MovementManagementComponent } from './pages/operational/movement-management/movement-management.component';
import { TemplateFormComponent } from './pages/configs/contracts-config-template/template-form/template-form.component';
import { LocationInvoiceComponent } from './pages/billing/location-invoice/location-invoice.component';
import { TicketComponent } from './pages/my-account/ticket/ticket.component';
import { InvoicingReportComponent } from './pages/billing/invoicing-report/invoicing-report.component';
import { WorkshopComponent } from './pages/workshop/workshop.component';
import { CustomerFormComponent } from './pages/forms/customer/customer-form/customer-form.component';
import { BoatFormComponent } from './pages/forms/boat/boat-form/boat-form.component';
import { InvoiceTaxLogsComponent } from './pages/logs/invoice-tax-logs/invoice-tax-logs.component';

import { RoleLogComponent } from './pages/logs/role-log/role-log.component';

import { SidenavBarGuard } from './guards/sidenav-bar.guard';
import { NixxBankAccessComponent } from './pages/easy-bank/nixx-bank/nixx-bank-access/nixx-bank-access.component';
import { NixxBankConfigComponent } from './pages/easy-bank/nixx-bank/nixx-bank-config/nixx-bank-config.component';
import { NixxBankLogsComponent } from './pages/easy-bank/nixx-bank/nixx-bank-logs/nixx-bank-logs.component';
import { EmitterNfComponent } from './pages/billing/invoice/emitter-nf/emitter-nf.component';
import { MyMarineComponent } from './pages/my-account/my-marine/my-marine.component';
import { InvoiceTaxProductComponent } from './pages/billing/invoice-tax-management/invoice-tax-product/invoice-tax-product.component';
import { ImportNfpComponent } from './pages/billing/invoice-tax-management/invoice-tax-product/import-nfp/import-nfp.component';
import { SuperInvoicingReportComponent } from './pages/my-account/super-invoicing-report/super-invoicing-report.component';
import { BerthMapComponent } from './berth-map/berth-map.component';
import { StockMoventTypeComponent } from './pages/stock/stock-movent-type/stock-movent-type.component';
import { StockMovementComponent } from './pages/stock/stock-movement/stock-movement.component';
import { StockMovementFormComponent } from './pages/stock/stock-movement/stock-movement-form/stock-movement-form.component';
import { FranchiseControllerComponent } from './pages/operational/franchise-controller/franchise-controller.component';
import { InvoiceLogComponent } from './pages/logs/invoice-log/invoice-log.component';
import { AccessGroupLogComponent } from './pages/logs/access-group-log/access-group-log.component';
import { AccessGroupComponent } from './pages/forms/access-controller/access-group/access-group.component';
import { AccessPointFormComponent } from './pages/forms/access-controller/access-point/access-point-form/access-point-form.component';
import { AccessPointComponent } from './pages/forms/access-controller/access-point/access-point.component';
import { VisitorFormComponent } from './pages/forms/access-controller/visitor/service-provider-forms-container/visitor-form/visitor-form.component';
import { ServiceProviderCenterComponent } from './pages/forms/access-controller/visitor/service-provider-center/service-provider-center.component';
import { SecuritiesPropertyComponent } from './pages/forms/securities-property/securities-property.component';
import { AccessCategoriesBatchComponent } from './pages/forms/access-controller/access-group/access-categories-batch/access-categories-batch.component';
import { ServiceHistoryComponent } from './pages/reports/service-history/service-history.component';
import { FranchiseGroupComponent } from './pages/forms/franchise-group/franchise-group.component';
import { FastCustomerEditComponent } from './pages/forms/customer/fast-customer-edit/fast-customer-edit/fast-customer-edit.component';
import { FranchiseGroupBatchComponent } from './pages/forms/franchise-group/franchise-group-batch/franchise-group-batch.component';
import { GuestCenterComponent } from './pages/forms/access-controller/visitor/guest-center/guest-center.component';
import { CustomerCreditPageComponent } from './pages/customer-credit-page/customer-credit-page.component';
import { BoatReportComponent } from './pages/my-account/boat-report/boat-report.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'app',
  },
  {
    path: 'app',
    component: DefaultComponent,
    canActivate: [AuthGuard, SidenavBarGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
      { path: 'dashboard', component: DashboardComponent },
      {
        path: 'operational',
        children: [
          {
            path: 'sling',
            component: SlingComponent,
            canActivate: [MenuItemGuard],
          },
          {
            path: 'checking',
            component: SlingCheckComponent,
            canActivate: [MenuItemGuard],
          },
          { path: 'wash-engine-turn', component: WashEngineTurnComponent },
          { path: 'navigation-plan', component: NavigationPlanComponent },
          {
            path: 'movement',
            component: MovementAppointmentComponent,
            canActivate: [MenuItemGuard],
          },
          {
            path: 'movement-plan',
            component: MovementPlanComponent,
            canActivate: [MenuItemGuard],
          },
          {
            path: 'movement-management',
            component: MovementManagementComponent,
            canActivate: [MenuItemGuard],
          },
          {
            path: 'franchise-controller',
            component: FranchiseControllerComponent,
            canActivate: [MenuItemGuard],
          },
        ],
      },
      {
        path: 'billing',
        children: [
          {
            path: 'tuition',
            component: TuitionsComponent,
            canActivate: [MenuItemGuard],
          },
          {
            path: 'tuition/overdue',
            component: TuitionsComponent,
          },
          {
            path: 'order',
            component: OrderComponent,
            canActivate: [MenuItemGuard],
          },
          {
            path: 'order/:orderNumber',
            component: OrderComponent,
            canActivate: [MenuItemGuard],
          },
          {
            path: 'order-invoice',
            component: OrderInvoiceComponent,
            canActivate: [MenuItemGuard],
          },
          {
            path: 'invoice',
            component: InvoiceComponent,
            canActivate: [MenuItemGuard],
          },
          {
            path: 'nf-emitter',
            component: EmitterNfComponent,
          },
          {
            path: 'debits',
            component: DebitsComponent,
            canActivate: [MenuItemGuard],
          },
          {
            path: 'debits/:idCliente',
            component: DebitsComponent,
          },
          {
            path: 'daily-debits',
            component: DailyDebitsComponent,
            canActivate: [MenuItemGuard],
          },
          {
            path: 'pending-customers',
            component: PendingCustomersComponent,
            canActivate: [MenuItemGuard],
          },
          {
            path: 'receiving-accounts',
            component: ReceivingAccountsComponent,
            canActivate: [MenuItemGuard],
          },
          {
            path: 'customer-credit',
            component: CustomerCreditPageComponent,
            canActivate: [MenuItemGuard],
          },
          {
            path: 'billets-management',
            children: [
              {
                path: 'outfile',
                component: OutfileComponent,
                canActivate: [MenuItemGuard],
              },
              {
                path: 'billet',
                component: BilletComponent,
                canActivate: [MenuItemGuard],
              },
            ],
          },
          {
            path: 'invoice-tax-management/invoice-tax',
            component: InvoiceTaxComponent,
            canActivate: [MenuItemGuard],
          },
          {
            path: 'invoice-tax-management/invoice-tax-product',
            component: InvoiceTaxProductComponent,
          },
          {
            path: 'invoice-tax-management/import-nfp',
            component: ImportNfpComponent,
          },
          {
            path: 'anticipation',
            children: [
              { path: 'anticipate', component: AnticipationComponent },
              { path: 'management', component: ManagementComponent },
              { path: 'summary', component: SummaryComponent },
            ],
          },
        ],
        // ], canActivate: [MenuItemGuard] //TODO ver com o Luis se esse cara é realmente necessário
      },
      {
        path: 'finances',
        children: [
          { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
          {
            path: 'dashboard',
            data: { url: '' },
            component: FinancesComponent,
          },
          {
            path: 'transaction',
            data: { url: 'lancamento' },
            component: FinancesComponent,
          },
          {
            path: 'planning',
            data: { url: 'lancamento/programado' },
            component: FinancesComponent,
          },
          {
            path: 'demonstrative',
            data: { url: 'demonstrativo' },
            component: FinancesComponent,
          },
          {
            path: 'demonstrative',
            data: { url: 'demonstrativo' },
            component: FinancesComponent,
          },
          {
            path: 'cash-flow',
            data: { url: 'fluxocaixa' },
            component: FinancesComponent,
          },
          {
            path: 'account-statement',
            data: { url: 'relatorio/extrato/conta' },
            component: FinancesComponent,
          },
          {
            path: 'diary-balance',
            data: { url: 'relatorio/saldo/diario' },
            component: FinancesComponent,
          },
          {
            path: 'planning-balance',
            data: { url: 'relatorio/saldo/diario/programado' },
            component: FinancesComponent,
          },
          {
            path: 'general-report',
            data: { url: 'relatorio/planoconta' },
            component: FinancesComponent,
          },
          {
            path: 'resumo-favorecido',
            data: { url: 'relatorio/resumo/favorecido' },
            component: FinancesComponent,
          },
          {
            path: 'account-crud',
            data: { url: 'conta' },
            component: FinancesComponent,
          },
          {
            path: 'chart-accounts-crud',
            data: { url: 'planoconta' },
            component: FinancesComponent,
          },
          {
            path: 'cost-center-crud',
            data: { url: 'centrocusto' },
            component: FinancesComponent,
          },
          {
            path: 'checking-account-crud',
            data: { url: 'contacorrente' },
            component: FinancesComponent,
          },
          {
            path: 'credit-card-crud',
            data: { url: 'cartaocredito' },
            component: FinancesComponent,
          },
          {
            path: 'billing-portifolio-crud',
            data: { url: 'carteiracobranca' },
            component: FinancesComponent,
          },
          {
            path: 'budget',
            data: { url: 'orcamento' },
            component: FinancesComponent,
          },
          {
            path: 'paymentMethods',
            data: { url: 'tipo/formapagamento' },
            component: FinancesComponent,
          },
          {
            path: 'employee',
            data: { url: 'colaborador' },
            component: FinancesComponent,
          },
          {
            path: 'provider',
            data: { url: 'fornecedor' },
            component: FinancesComponent,
          },
          {
            path: 'configuration',
            data: { url: 'configuracao' },
            component: FinancesComponent,
          },
        ],
      },
      {
        path: 'finances2',
        children: [
          { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
          {
            path: 'dashboard',
            data: { url: '' },
            component: FinancesComponent,
          },
          {
            path: 'transaction',
            data: { url: 'lancamento' },
            component: FinancesComponent,
          },
          {
            path: 'planning',
            data: { url: 'lancamento/programado' },
            component: FinancesComponent,
          },
          {
            path: 'demonstrative',
            data: { url: 'demonstrativo' },
            component: FinancesComponent,
          },
          {
            path: 'demonstrative',
            data: { url: 'demonstrativo' },
            component: FinancesComponent,
          },
          {
            path: 'cash-flow',
            data: { url: 'fluxocaixa' },
            component: FinancesComponent,
          },
          {
            path: 'account-statement',
            data: { url: 'relatorio/extrato/conta' },
            component: FinancesComponent,
          },
          {
            path: 'diary-balance',
            data: { url: 'relatorio/saldo/diario' },
            component: FinancesComponent,
          },
          {
            path: 'planning-balance',
            data: { url: 'relatorio/saldo/diario/programado' },
            component: FinancesComponent,
          },
          {
            path: 'general-report',
            data: { url: 'relatorio/planoconta' },
            component: FinancesComponent,
          },
          {
            path: 'resumo-favorecido',
            data: { url: 'relatorio/resumo/favorecido' },
            component: FinancesComponent,
          },
          {
            path: 'account-crud',
            data: { url: 'conta' },
            component: FinancesComponent,
          },
          {
            path: 'chart-accounts-crud',
            data: { url: 'planoconta' },
            component: FinancesComponent,
          },
          {
            path: 'cost-center-crud',
            data: { url: 'centrocusto' },
            component: FinancesComponent,
          },
          {
            path: 'checking-account-crud',
            data: { url: 'contacorrente' },
            component: FinancesComponent,
          },
          {
            path: 'credit-card-crud',
            data: { url: 'cartaocredito' },
            component: FinancesComponent,
          },
          {
            path: 'billing-portifolio-crud',
            data: { url: 'carteiracobranca' },
            component: FinancesComponent,
          },
          {
            path: 'budget',
            data: { url: 'orcamento' },
            component: FinancesComponent,
          },
          {
            path: 'paymentMethods',
            data: { url: 'tipo/formapagamento' },
            component: FinancesComponent,
          },
          {
            path: 'employee',
            data: { url: 'colaborador' },
            component: FinancesComponent,
          },
          {
            path: 'provider',
            data: { url: 'fornecedor' },
            component: FinancesComponent,
          },
          {
            path: 'configuration',
            data: { url: 'configuracao' },
            component: FinancesComponent,
          },
          {
            path: 'indicator',
            data: { url: 'indicador' },
            component: FinancesComponent,
          },
          {
            path: 'variable',
            data: { url: 'variavel' },
            component: FinancesComponent,
          },
        ],
      },
      {
        path: 'workshop',
        children: [
          { path: '', redirectTo: 'budget', pathMatch: 'full' },
          {
            path: 'budget',
            data: { url: '/budget' },
            component: WorkshopComponent,
          },
          {
            path: 'service-order',
            data: { url: '/serviceorder' },
            component: WorkshopComponent,
          },
          {
            path: 'operation',
            data: { url: '/operation' },
            component: WorkshopComponent,
          },
          {
            path: 'config',
            data: { url: '/config' },
            component: WorkshopComponent,
          },
        ],
      },
      {
        path: 'forms',
        children: [
          { path: 'group', component: GroupComponent },
          { path: 'user', component: UserComponent },
          { path: 'customer', component: CustomerComponent },
          { path: 'boat', component: BoatComponent },
          { path: 'vacancy', component: VacancyComponent },
          { path: 'user-role', component: UserRoleComponent },
          { path: 'sailor', component: SailorComponent },
          {
            path: 'securities-property',
            component: SecuritiesPropertyComponent,
          },
          {
            path: 'franchise',
            children: [
              { path: '', component: FranchiseGroupComponent },
              { path: 'batch', component: FranchiseGroupBatchComponent },
            ],
          },
          { path: 'fast-customer-edit', component: FastCustomerEditComponent },
          {
            path: 'access-controller',
            children: [
              { path: 'access-point', component: AccessPointComponent },
              {
                path: 'access-point-form',
                component: AccessPointFormComponent,
              },
              {
                path: 'access-group',
                children: [
                  {
                    path: '',
                    component: AccessGroupComponent,
                    pathMatch: 'full',
                  },
                  {
                    path: 'batch/:id',
                    component: AccessCategoriesBatchComponent,
                  },
                ],
              },
              // { path: 'access-type', component: AccessTypeComponent},
              // { path: 'access-type-form', component: AccessTypeFormComponent},
              {
                path: 'service-provider-center',
                component: ServiceProviderCenterComponent,
              },
              {
                path: 'guest-center',
                component: GuestCenterComponent,
              },
              {
                path: 'visitor-delivery-person-form',
                component: VisitorFormComponent,
              },
            ],
          },
        ],
      },
      {
        path: 'stock',
        children: [
          { path: 'product', component: ProductComponent },
          { path: 'stock-movement-type', component: StockMoventTypeComponent },
          { path: 'balance-adjustment', component: BalanceAdjustmentComponent },
          { path: 'stock-movement', component: StockMovementComponent },
          {
            path: 'stock-movement-form',
            component: StockMovementFormComponent,
          },
          {
            path: 'service-readjustment',
            component: ServiceReadjustmentComponent,
          },
        ],
      },
      {
        path: 'my-account',
        children: [
          { path: 'my-invoice', component: MyInvoiceComponent },
          { path: 'ticket', component: TicketComponent },
          { path: 'my-marine', component: MyMarineComponent },
          { path: 'super-invoicing', component: SuperInvoicingReportComponent },
          { path: 'boat-report', component: BoatReportComponent}
        ],
      },
      {
        path: 'logs',
        children: [
          {
            path: 'sling-log',
            component: SlingLogComponent,
            canActivate: [MenuItemGuard],
          },
          { path: 'order-log', component: MarinaOrderLogComponent },
          { path: 'share', component: SlingShareLogComponent },
          { path: 'boat-log', component: BoatLogComponent },
          { path: 'customer-log', component: CustomerLogComponent },
          { path: 'product-log', component: ProductLogComponent },
          { path: 'contract-log', component: ContractLogComponent },
          { path: 'user-sms-log', component: UserSmsLogComponent },
          { path: 'invoice-tax', component: InvoiceTaxLogsComponent },
          { path: 'invoice', component: InvoiceLogComponent },
          {
            path: 'movement-log',
            component: MovementExecutionOperationLogComponent,
            canActivate: [MenuItemGuard],
          },
          { path: 'user-log', component: UserLogComponent },
          { path: 'role-log', component: RoleLogComponent },
          { path: 'access-category-log', component: AccessGroupLogComponent },
        ],
      },
      {
        path: 'qr-codes',
        children: [
          { path: 'boats', component: BoatsQrcodesComponent },
          { path: 'vacancies', component: VacanciesQrcodesComponent },
        ],
      },
      {
        path: 'configs',
        children: [
          { path: 'noticies', component: NoticiesComponent },
          { path: 'siren-alert-config', component: SirenAlertConfigComponent },
          { path: 'panel-config', component: PanelConfigComponent },
          { path: 'sling-config', component: SlingConfigComponent },
          { path: 'nota-fiscal', component: NotaFiscalComponent },
          {
            path: 'movements',
            canActivate: [MenuItemGuard],
            children: [
              { path: '', pathMatch: 'full', redirectTo: 'operating-day' },
              {
                path: 'operating-day',
                component: OperatingDayConfigComponent,
                canActivate: [MenuItemGuard],
              },
              {
                path: 'movement-group',
                component: MovementsConfigComponent,
                canActivate: [MenuItemGuard],
              },
            ],
          },
          {
            path: 'movement-locations',
            component: MovementLocationsConfigComponent,
            canActivate: [MenuItemGuard],
          },
        ],
      },
      {
        path: 'pivot-table',
        children: [
          { path: 'product-sales', component: PivotProductSalesComponent },
        ],
      },
      {
        path: 'reports',
        children: [
          { path: 'service-comparison', component: ServiceComparisonComponent },
          { path: 'product-history', component: ProductHistoryComponent },
          { path: 'service-history', component: ServiceHistoryComponent },
          { path: 'location-invoice', component: LocationInvoiceComponent },
          { path: 'invoicing', component: InvoicingReportComponent },
          {
            path: 'boat-detail-services',
            component: BoatDetailServicesComponent,
          },
        ],
      },
      {
        path: 'nixx-bank',
        children: [
          { path: '', redirectTo: 'access', pathMatch: 'full' },
          {
            path: 'access',
            data: { url: '/access' },
            component: NixxBankAccessComponent,
          },
          {
            path: 'config',
            data: { url: '/config' },
            component: NixxBankConfigComponent,
          },
          {
            path: 'logs',
            data: { url: '/logs' },
            component: NixxBankLogsComponent,
          },
        ],
      },
      {
        path: 'contracts',
        children: [
          {
            path: 'contracts',
            component: ContractsComponent,
            canActivate: [MenuItemGuard],
          },
          { path: 'templates', component: ContractsConfigTemplateComponent },
          { path: 'template-edit/:id', component: TemplateFormComponent },
          {
            path: 'default-signer',
            component: ContractsConfigDefaultSignerComponent,
          },
          {
            path: 'generated-contracts',
            component: DocumentsContractedComponent,
          },
        ],
      },
      {
        path: 'berth-map',
        component: BerthMapComponent,
        canActivate: [AuthGuard],
      },
    ],
  },
  {
    path: '',
    component: CleanComponent,
    children: [
      { path: 'login', component: LoginComponent },
      {
        path: 'sling-totem',
        component: SlingTotemComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'reprint-totem',
        component: ReprintComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'debits-totem',
        component: TotemDebitsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'navigation-plan-panel',
        component: TotemNavigationPlanComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'boat-maintenance',
        component: BoatWashEngineTurnPanelComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'single-order',
        component: SingleOrderComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'new-budget-customer',
        component: CustomerFormComponent,
      },
      {
        path: 'new-budget-boat',
        component: BoatFormComponent,
      },
      {
        path: 'billing-area',
        loadChildren: () =>
          import('./pages/billing/billing-area/billing-area.module').then(
            (m) => m.BillingAreaModule
          ),
      },
      {
        path: 'deactivate-user',
        loadChildren: () =>
          import('./pages/deactivate-user/deactivate-user.module').then(
            (m) => m.DeactivateUserModule
          ),
      },
      {
        path: 'link-access-invite',
        loadComponent: () => import('./pages/link-access-invite-register/link-access-invite-register.component')
          .then(mod => mod.LinkAccessInviteRegisterComponent)
      }
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
