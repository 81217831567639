import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { InvoiceBankingBillet, PlanoConta, SlingConfig } from 'src/app/models';
import { Invoice } from 'src/app/models/invoice';
import { InvoicePaid } from 'src/app/models/invoice-paid';
import { BilletService } from 'src/app/services/billet.service';
import { FinancesService } from 'src/app/services/finances.service';
import { InvoicePaidService } from 'src/app/services/invoice-paid.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';
import { ResultDialogComponent } from './result-dialog/result-dialog.component';
import moment from 'moment';
import { DialogService } from 'primeng/dynamicdialog';
import { FileUpload } from 'primeng/fileupload';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-outfile',
  templateUrl: './outfile.component.html',
  styleUrls: ['./outfile.component.scss']
})
export class OutfileComponent implements OnInit {

  @ViewChild('upload', { static: false }) upload: FileUpload;

  files: any[] = new Array();
  invoicePaids: any[] = [];
  listForRegistry: any[] = [];
  billets: any[] = [];
  banks: any[] = [];
  numberOfRows = 10;
  slingConfig: SlingConfig;
  planoConta: PlanoConta;
  invoicesRegistered: Invoice[];
  sequence: number;
  listRefused: any;
  idCarteira: number;
  invoicesRefused: any[] = [];

  constructor(
    private billetService: BilletService,
    private spinner: NgxSpinnerService,
    private messageUtil: MessageUtil,
    private invoicePaidService: InvoicePaidService,
    private invoiceService: InvoiceService,
    public dialog: DialogService,
    private slingConfigService: SlingConfigService,
    private financesService: FinancesService
  ) { }

  async ngOnInit(): Promise<void> {
    this.loadSlingConfig();
    this.sequence = await this.getCurrentSequence();
    this.getBilingPortifolioList();
  }

  loadSlingConfig(): void {
    this.slingConfigService.getSlingConfig().subscribe(
      (data) => {
        this.slingConfig = data[0];
        this.numberOfRows = this.slingConfig != null ? this.slingConfig.numberOfRecordsPerPage : 10;
        this.getAll();
        this.findPlanoConta();
      },
      () => null);
  }

  getAll(spinner?: any): void {
    if (!spinner) {
      this.spinner.show();
    }
    this.billetService.findAll().subscribe((data) => {
      this.files = data.map((file) => ({
        id: file.id,
        fileUrl: file.outFileUrl,
        fileName: file.outFileUrl.split('/')[file.outFileUrl.split('/').length - 1],
        bankingBillets: file.bankingBillets,
        qtyBillet: file.bankingBillets.length,
        value: file.bankingBillets.reduce((total, billet) => total + billet.value, 0),
        latestDownload: file.latestDownload,
        paidQty: file.bankingBillets.filter(((bil) => bil.valuePaid > 0)).length,
        registeredQty: file.bankingBillets.filter(((bil) => bil.registered)).length,
        issueDate: file.bankingBillets[0].issueDate,
        nomeCarteiraCobranca: file.nomeCarteiraCobranca ? file.nomeCarteiraCobranca : 'N/A',
        paidValue: file.bankingBillets.filter(((bil) => bil.valuePaid)).reduce((total, billet) => total + billet.valuePaid, 0)
      }));
    }, (error) => {
      this.spinner.hide();
      const exception = error.error.data.exception;
      this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
    }, () => {
      this.spinner.hide();

    });
  }

  show(file): void {
    file.show = !file.show;
  }

  updateDownload(file): void {
    // tslint:disable-next-line:no-empty
    this.billetService.updateDownload(file.id).subscribe(() => this.getAll(), (error) => {
      const exception = error.error.data.exception;
      this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
    });
  }

  canUpload(file): boolean {
    return file.paidQty < file.bankingBillets.length;
  }

  async onBasicUpload(files): Promise<any> {
    if (!this.checkInterestPlanoConta()) {
      this.messageUtil.generateMessage(MessageUtil.ERROR, 'Erro', 'Plano de Conta para Juros/Multa não configurado!');
      this.spinner.hide();
      return false;
    }
    for (const { } of files) {
      this.spinner.show();
      await this.processFiles(files);
      this.uploadFile(files);
    }
    this.upload.clear();
  }

  async getCustomerName(invoiceNumber: number, identifier: string): Promise<string> {
    const myPromisse = new Promise<string>(async (resolve) => {
      this.invoiceService.findCustomerNameByInvoiceNumberAndIdentifier(invoiceNumber, identifier).subscribe(
        (data) => {
          resolve(data);
        },
        () => resolve('')
      );
    });
    return myPromisse;
  }

  invoicePay(spinner, invoicePaids: any[], bacenBanco): void {
    this.invoicePaidService.payInvoiceFromBillet(invoicePaids).subscribe(async (invoices: any) => {
      const data = {
        bacenBanco,
        invoicesNotFound: this.billets.filter(
          (bil) => (invoices.invoicesNotFound as any[]
          ).find((billet) => billet.identifier === bil.nossoNumero) && bil.dataCredito),
        invoicesPaid: this.billets.filter(
          (bil) => (invoices.invoicesPaid as any[]).find((billet) => billet.identifier === bil.nossoNumero)
        ),
        invoicesWasPaid: this.billets.filter(
          (bil) => (invoices.invoicesWasPaid as any[]).find((billet) => billet.identifier === bil.nossoNumero)
        ),
        banks: this.banks,
        invoicesRegistered: this.invoicesRegistered,
        invoicesRefused: await this.getRefusedBillets()
      };
      this.dialog.open(ResultDialogComponent, {
        width: '75%',
        height: '80%',
        dismissableMask: false,
        data,
        header: 'Resultado de importação do banco ' + this.banks.join(', ')
      });
    }, async (error) => {
      this.spinner.hide();
      const exception = error.error.data.exception;
      this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
    }, async () => {
      this.getAll(spinner);
    });
  }

  async invoiceRegister(billetRegisterDTO): Promise<void> {
    return new Promise<void>(
      async (res) => {
        const bankingBilletList: InvoiceBankingBillet[] = this.mapRegisterToInvoiceBankBillet(billetRegisterDTO.list, billetRegisterDTO.cdgBanco);
const interBilletRegisterDTO = new Object({
          list: bankingBilletList,
          cdgBanco: billetRegisterDTO.cdgBanco,
          idCarteiraCobranca: billetRegisterDTO.idCarteiraCobranca
        })
        this.billetService.setRegistred(interBilletRegisterDTO).subscribe(
          async (answer) => {
            this.invoicesRegistered = answer;
            res();
          },
          async () => res()
        );
      }
    );

  }

  checkInterestPlanoConta(): any {
    return (this.slingConfig && this.slingConfig.idPlanoContaInterest && this.planoConta);
  }

  findPlanoConta(): void {
    this.financesService.getPlanoContaById(this.slingConfig.idPlanoContaInterest).subscribe(
      async (plano) => {
        this.planoConta = plano;
      }
    );
  }

  processFiles(files): void {
    this.billetService.returnProcess(files).toPromise()
    .then(
      async (data) => {
        if (data.status === 'SUCCESS') {
          if(data.data.retornoCNAB.registros < 1) {
            this.spinner.hide();
            this.messageUtil.generateMessage(MessageUtil.WARNING, 'Alerta', 'Não há boletos a serem processados no arquivo de retorno!');
            return;
          }
          this.billets = data.data.retornoCNAB.registros.filter((billet) => billet.mensagemMovimento === 'RECEBIDO');
          const username = StorageUtil.getUser().username;
          const employeeName = StorageUtil.getUserName();
          const bancoBacen = data.data.retornoCNAB.bacenBanco;
          this.invoicePaids =[];
          for (let index = 0; index < this.billets.length; index++) {
            const billet = this.billets[index];
            this.invoicePaids.push(await this.setValuesFromResponse(billet, username, employeeName, bancoBacen));
          }
          // boletos para setar só como registrados, todas os codigos exceto RECEBIDO,
          // 3 - Entrada Rejeitada, 26 - Instrução Rejeitada, 30 - Alteração de Dados Rejeitada, 28 - TAXA
          this.invoicesRegistered = [];
          this.listForRegistry = data.data.retornoCNAB.registros.filter((billet) =>
            billet.mensagemMovimento !== 'RECEBIDO' && billet.codigoMovimento !== 3 &&
            billet.codigoMovimento !== 28 && billet.codigoMovimento !== 26 && billet.codigoMovimento !== 30
          );
          if (this.listForRegistry.length > 0) {
            const interBilletRegisterDTO = new Object({
              list: this.listForRegistry,
              cdgBanco: bancoBacen,
              idCarteiraCobranca: this.idCarteira
            })
            await this.invoiceRegister(interBilletRegisterDTO);
          }
          this.listRefused = data.data.retornoCNAB.registros.filter((billet) => billet.codigoMovimento === 3);
          if (this.listRefused.length > 0) {
            await this.invoiceRefuse(this.listRefused, bancoBacen);
          }

          this.billets.forEach((notFound) => {
            if (notFound.nomeBanco && !this.banks.find((bank) => bank === notFound.nomeBanco)) {
              this.banks.push(notFound.nomeBanco);
            }
          });
          this.invoicePay(this.spinner, this.invoicePaids, bancoBacen);

        }
    })
    .catch(
      async (error) => {
        this.spinner.hide();
        this.messageUtil.generateMessage(MessageUtil.ERROR, "Erro", "Não foi possível importar o arquivo");
      }
    );
  }

  uploadFile(files): void {
    this.billetService.uploadReturnFile(files).subscribe(
      (result) => {
      },
      (err) => {
        console.log(err);
      }
    );
  }

  async setValuesFromResponse(billet, username, employeeName, bacenBanco): Promise<InvoicePaid> {
    return new Promise<InvoicePaid>(
      async (resolve) => {

        const invoicePaid: InvoicePaid = new InvoicePaid();
        invoicePaid.dateProcessing = moment(billet.dataEntrada).format('yyyy-MM-DDTHH:mm');
        if (moment(billet.dataEntrada).isAfter(billet.dataCredito)) {
          invoicePaid.datepaid = moment(billet.dataEntrada).format('yyyy-MM-DDTHH:mm');
        } else {
          invoicePaid.datepaid = moment(billet.dataCredito).format('yyyy-MM-DDTHH:mm');
        }
        switch (bacenBanco) {
          // Itaú (ValorRecebido não considera a tarifa)
          case 341:
            invoicePaid.totalPaid = billet.valorRecebido + billet.valorTarifa;
            invoicePaid.discount = billet.descontoConcedido;
            invoicePaid.interest = billet.jurosMora;
            break;
          // Sicredi (Valor recebido considera juros e desconto)
          case 748:
            invoicePaid.totalPaid = billet.valorRecebido;
            if (billet.valorRecebido > billet.valorTitulo) {
              invoicePaid.interest = Number.parseFloat((billet.valorRecebido - billet.valorTitulo).toFixed(2));
            } else if (billet.valorRecebido < billet.valorTitulo) {
              invoicePaid.discount = Number.parseFloat((billet.valorTitulo - billet.valorRecebido).toFixed(2));
            }
            break;
          // Santander
          case 33:
            invoicePaid.totalPaid = billet.valorRecebido;
            invoicePaid.discount = Number.parseFloat(billet.descontoConcedido) +Number.parseFloat( billet.valorAbatimento);
            invoicePaid.interest = billet.jurosMora;
            break;
          default:
            invoicePaid.totalPaid = billet.valorRecebido;
            invoicePaid.discount = billet.descontoConcedido;
            invoicePaid.interest = billet.jurosMora;
            break;
        }
        invoicePaid.value = invoicePaid.totalPaid;
        invoicePaid.username = username;
        invoicePaid.employeeUser = employeeName;
        let invoiceStr = String(billet.idFatura);
        // se id comeca com 9
        if (invoiceStr.length === 8 && invoiceStr.startsWith('9')) {
          invoiceStr = invoiceStr.substr(1);
        }
        invoicePaid.invoiceId = Number(invoiceStr.substring(0, invoiceStr.length - 2));
        billet.sequence = billet.idFatura.toString().substring(billet.idFatura.toString().length - 2);
        billet.idFatura = invoicePaid.invoiceId;
        invoicePaid.identifier = billet.nossoNumero;
        invoicePaid.identifierVD = billet.nossoNumeroDV;

        billet.customerName = await this.getCustomerName(invoicePaid.invoiceId, invoicePaid.identifier);
        resolve(invoicePaid);
      }
    );
  }

  isToday(file): boolean {
    return moment(file.issueDate).hours(0).minutes(0).seconds(0).milliseconds(0).isSame(
      moment().hours(0).minutes(0).seconds(0).milliseconds(0)
    );
  }

  hasRefused(file): any {
    return file.bankingBillets.find((b) => b.refused && b.active);
  }

  allCanceled(file): any {
    return !file.bankingBillets.find((b) => b.active);
  }

  async getRefusedBillets(): Promise<InvoiceBankingBillet[]> {
    // if(this.listRefused && this.listRefused.length > 0){
    //   return this.listRefused;
    // }
    return new Promise<InvoiceBankingBillet[]>(
      async (resolv) => {
        this.billetService.findRefusedNotCanceled().subscribe(
          async (response) => {
            resolv(response);
          },
          async (err) => {
            console.log(err);
            resolv([]);
          }
        );
      }
    );
  }

  async getCurrentSequence(): Promise<number> {
    return new Promise<number>(
      async (resolv) => {
        this.billetService.getSequence().subscribe(
          async (response) => {
            resolv(response);
          },
          async (err) => {
            console.log(err);
            resolv(null);
          }
        );
      }
    );
  }

  getFileStatusColorClass(file): string {
    if (file.show) {
      return 'open';
    }
    if (this.allCanceled(file)) {
      return 'canceled';
    }
    if (file.paidValue >= file.value && !this.hasRefused(file)) {
      return 'paid';
    }
    if (this.hasRefused(file)) {
      return 'not-reg';
    }
    if (this.isToday(file) && !file.registeredQty) {
      return 'recent';
    }
    if (!file.valuePaid && !file.registeredQty) {
      return 'pending';
    }
  }

  async invoiceRefuse(listRefused: any[], bancoBacen): Promise<void> {
return new Promise<void>(
      async (res) => {
        const bankingBilletList: InvoiceBankingBillet[] = this.mapRegisterToInvoiceBankBillet(listRefused, bancoBacen);

        this.billetService.setRefused(bankingBilletList).subscribe(
          async (answer) => {
            this.invoicesRefused = answer;
            res();
          },
          async () => res()
        );
      }
    );

  }

  mapRegisterToInvoiceBankBillet = (list: any[], banco): InvoiceBankingBillet[] => {
    const bankingBilletList: InvoiceBankingBillet[] = list.map((billet) => {
      const invoiceBankBillet: InvoiceBankingBillet = new InvoiceBankingBillet();
      if(banco=77){
        invoiceBankBillet.identifier = billet.nossoNumero;
        invoiceBankBillet.identifierVD = billet.nossoNumeroDV;
      } else {
        invoiceBankBillet.identifier = billet.idFatura;
        invoiceBankBillet.identifierVD = billet.nossoNumeroDV;
      }
      let invoiceStr = String(billet.idFatura);
      // se id comeca com 9
      if (invoiceStr.length === 8 && invoiceStr.startsWith('9')) {
        invoiceStr = invoiceStr.substr(1);
      }

      // retirando parcela do final
      invoiceStr = invoiceStr.substring(0, invoiceStr.length - 2);

      invoiceBankBillet.invoiceId = Number(invoiceStr);
      return invoiceBankBillet;
    });
    return bankingBilletList;
  }

  processingBilletWarn() {
    Swal.fire(
      {
        title: 'Boleto com registro pendente',
        text: 'Importe o arquivo de retorno para visualizar o boleto.',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK',
      });
  }

  getBilingPortifolioList(): void {
    this.financesService.getAllBilingPortifolio().subscribe(
      (data) => {
        if ( data.filter(cc => cc.codigoCarteira==112).length<1) {
          this.idCarteira=0;
          }
        else {
          let billetPortfolio = [];
          billetPortfolio = data.filter(cc => cc.codigoCarteira==112);
          this.idCarteira = billetPortfolio[0].idCarteiraCobranca
          ;
        }
      },
      (error) => { console.log(error); }
    );
  }


}
