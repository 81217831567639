<div class="dialog-form">
  <form (submit)="validateToSave()" [formGroup]="accessGroupForm">
    <p-fieldset>
      <p-header class="article-title">
        <span class="article-title">{{ "Básico" | translate }}</span>
      </p-header>

      <div class="form-group">
        <div class="form-control">
          <label for="name">{{ "ACCESS-GROUP-NAME" | translate }} *</label>
          <input
            pInputText
            name="name"
            formControlName="name"
            maxlength="30"
            [readonly]="isStandard"
          />
        </div>
        <div class="form-control">
          <label for="type">{{ "ACCESS-GROUP-TYPE" | translate }} *</label>
          <p-dropdown
            [options]="accessGroupTypes"
            name="type"
            formControlName="type"
            [readonly]="isStandard"
            defaultLabel="Selecione..."
            [style]="{ width: '100%' }"
            (onChange)="onChangeType($event)"
          />
        </div>
        <div class="form-control flex-end">
          <label for="active">{{ "ACTIVE" | translate }}</label>
          <p-inputSwitch
            styleClass="myswitch"
            formControlName="active"
            name="active"
            [readonly]="isStandard"
          >
          </p-inputSwitch>
        </div>
      </div>
      <div class="form-group pt-3">
        <div class="form-control">
          <label for="showSuplier">{{
            "Solicitar Fornecedor" | translate
          }}</label>
          <p-inputSwitch
            styleClass="myswitch"
            formControlName="showSuplier"
            name="showSuplier"
          >
          </p-inputSwitch>
        </div>
        <div class="form-control" *ngIf="useiInvites" >
          <label for="useInvitation">{{
            "Contabilizar Convite" | translate
          }}</label>
          <p-inputSwitch
            styleClass="myswitch"
            formControlName="useInvitation"
            name="useInvitation"
          >
          </p-inputSwitch>
        </div>
      </div>
      <br />
      <div class="form-group">
        <div class="form-control basic extended">
          <label for="description">{{ "DESCRIPTION" | translate }}</label>
          <textarea
            pInputText
            maxlength="255"
            name="description"
            rows="5"
            cols="90"
            formControlName="description"
          ></textarea>
          <span
            >{{ accessGroupForm.get("description").value?.length }} / 255</span
          >
        </div>
      </div>
    </p-fieldset>
    <br />
    <p-fieldset>
      <p-header>
        <div>{{ "Locais de Acesso" | translate }}</div>
      </p-header>

      <p-table
        #tt
        [value]="accessGroupForm.get('accessGroupPoints').value"
        [paginator]="false"
        [rows]="9999"
        resetPageOnSort="true"
        [resizableColumns]="true"
        [autoLayout]="true"
        styleClass="easy-table"
      >
        <ng-template pTemplate="caption">
          <div class="table-header alignment">
            <label style="padding-right: 10px; padding-top: 5px" for="acp">{{
              "ACCESS-POINT" | translate
            }}</label>
            <p-dropdown
              [options]="accessPointList"
              [disabled]="accessPointList.length === 0"
              name="acp"
              appendTo="body"
              class="combo"
              [(ngModel)]="accessPointSelect"
              [ngModelOptions]="{ standalone: true }"
            >
              >
            </p-dropdown>
            <button
              pButton
              type="button"
              icon="pi pi-plus"
              (click)="addAccessTypePoint()"
            ></button>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th [pSortableColumn]="'name'">
              {{ "NAME" | translate }}
              <p-sortIcon [field]="'name'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'location'">
              {{ "LOCAL" | translate }}
              <p-sortIcon [field]="'location'"></p-sortIcon>
            </th>
            <th [pSortableColumn]="'id'">
              {{ "IP" | translate }}
              <p-sortIcon [field]="'id'"></p-sortIcon>
            </th>
            <th class="text-center">Leitor cartão</th>
            <th class="text-center">Digital</th>
            <th class="text-center">Qr.Code</th>
            <th class="text-center">Facial</th>

            <th class="text-center" width="6%" [pSortableColumn]="'active'">
              {{ "STATUS" | translate }}
              <p-sortIcon [field]="'active'"></p-sortIcon>
            </th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-accessPoint let-rowIndex="rowIndex">
          <tr style="max-height: 30px; height: 30px; min-height: 30px">
            <td>{{ accessPoint?.accessPoint?.name ?? "" }}</td>
            <td>{{ accessPoint?.accessPoint?.location ?? "" }}</td>
            <td>{{ accessPoint?.accessPoint?.ip ?? "" }}</td>
            <td class="text-center">
              <span
                [ngClass]="
                  accessPoint?.accessPoint?.readCard
                    ? 'badge badge-pill success'
                    : 'badge badge-pill danger'
                "
              >
                {{ getStatus(accessPoint?.accessPoint?.readCard) }}
              </span>
            </td>
            <td class="text-center">
              <span
                [ngClass]="
                  accessPoint?.accessPoint?.printFinger
                    ? 'badge badge-pill success'
                    : 'badge badge-pill danger'
                "
              >
                {{ getStatus(accessPoint?.accessPoint?.printFinger) }}
              </span>
            </td>
            <td class="text-center">
              <span
                [ngClass]="
                  accessPoint?.accessPoint?.qrCode
                    ? 'badge badge-pill success'
                    : 'badge badge-pill danger'
                "
              >
                {{ getStatus(accessPoint?.accessPoint?.qrCode) }}
              </span>
            </td>
            <td class="text-center">
              <span
                [ngClass]="
                  accessPoint?.accessPoint?.face
                    ? 'badge badge-pill success'
                    : 'badge badge-pill danger'
                "
              >
                {{ getStatus(accessPoint?.accessPoint?.face) }}
              </span>
            </td>

            <td class="text-center">
              <span
                [ngClass]="
                  accessPoint?.accessPoint?.active
                    ? 'badge badge-pill success'
                    : 'badge badge-pill danger'
                "
              >
                {{ getStatus(accessPoint?.accessPoint?.active) }}
              </span>
            </td>

            <td class="text-center">
              <div
                class="table-action danger"
                (click)="
                  openAccessPointDeleteConfirmDialog(
                    accessPoint?.accessPoint?.id
                  )
                "
                pTooltip="Remover {{ accessPoint?.accessPoint?.name }}"
                tooltipPosition="bottom"
              >
                <i class="pi pi-trash danger"></i>
              </div>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </p-fieldset>
    <div class="form-group align-center">
      <div class="buttons">
        <button
          class="easymarine danger"
          type="button"
          (click)="dialog.close()"
        >
          {{ "CANCEL" | translate }}
        </button>
        <button class="easymarine primary" type="submit">
          {{ "SAVE" | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
