<div class="back-principal">
  <span style="font-size: 16px;">
    {{question.descripion}}
  </span>


  <div class="corpo" *ngIf="question.videos.length > 0 ">
    <div class="video-container" style="margin-left: 0;">
      <iframe width="420px" height="350px" [src]="question.videos[0].liveLink" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>

    <div class="video-container" *ngIf="question.videos.length > 1 ">
      <iframe width="420px" height="350px" [src]="question.videos[1].liveLink" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>

  </div>

<div class="foot">
    <div class="text-foot">Escaneie o QR Code e acesse manual detalhado</div>
    <div>

      <img [src]="image" />

    </div>

</div>
</div>
