<div class="dialog-form">
    <form (submit)="save()" [formGroup]="configurationsForm" *ngIf="configurationsForm">
        <div class="table" formArrayName="configs">
            <p-table [value]="listConfigs()" [rows]="5" [paginator]="true"
                autoLayout="true" alwaysShowPaginator="false">
                <ng-template pTemplate="header">
                    <tr>
                        <th>{{ 'SERVICE' | translate}}</th>
                        <th>{{ 'Serviço para comparação' | translate}}</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-config let-rowIndex="rowIndex">
                    <tr [formGroupName]="rowIndex">
                        <td>
                            <span>{{config.value?.service?.name}}</span>
                        </td>
                        <td>
                            <p-dropdown appendTo="body" [options]="serviceListOptions"
                                name="serviceComparisonId" placeholder="Selecione..."
                                formControlName="serviceComparisonId">
                            </p-dropdown>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <div style="display: flex; flex-direction: row-reverse; align-items: center;padding: 10px; justify-content: space-evenly;">
            <div style="display: flex; align-items: center;">
                <p-checkbox [formControl]="configurationsForm.controls['saveConfig']"></p-checkbox><span style="margin-left: 5px;">Tornar padrão</span>
            </div>
            <a (click)="setDefault()" class="link" >{{ 'Voltar ao padrão' | translate }}</a>
        </div>
        <div class="filter-row">
            <div class="buttons">
                <div class="form-button">
                    <button (click)="cancel()" class="danger easymarine" type="button">{{ 'Fechar' | translate }}</button>
                </div>
                <div class="form-button">
                    <button class="primary easymarine" type="submit" [disabled]="configurationsForm.invalid">{{ 'Salvar' | translate }}</button>
                </div>
            </div>
        </div>
    </form>
</div>