<p-fieldset>
  <p-header class="article-title">
    <span class="article-title">{{ "NIXXBANK-LOG" | translate }}</span>
    <button
      class="fab-button primary"
      appFiltersButton
      pTooltip="Exibir/Ocultar Filtros"
      tooltipPosition="left"
    >
      <i class="pi pi-filter"></i>
    </button>
  </p-header>
  <form class="filters" (submit)="searchLog()" appFilters>
    <div class="filter-row">
      <div class="form-control">
        <label>{{ "INITIAL-DATE-FINAL-DATE" | translate }}</label>
        <p-calendar
          dateFormat="dd/mm/yy"
          [monthNavigator]="true"
          [yearNavigator]="true"
          [yearRange]="'2000:2055'"
          placeholder="dd/mm/aaaa - dd/mm/aaaa"
          rPCalendarMask
          appendTo="body"
          name="dateStartAndEnd"
          selectionMode="range"
          [showIcon]="true"
          i18n
          [(ngModel)]="rangeDates"
        >
        </p-calendar>
      </div>

      <div class="form-control">
        <label>{{ "USER" | translate }}</label>
        <p-dropdown
          placeholder="Selecione..."
          [filter]="true"
          [options]="filteredUsers"
          appendTo="body"
          name="selectedUserId"
          [(ngModel)]="filter.userId"
        >
        </p-dropdown>
      </div>
      <div class="form-control">
        <label>{{ "CUSTOMER" | translate }}</label>
        <p-dropdown
          placeholder="Selecione..."
          [filter]="true"
          [options]="customersFilter"
          appendTo="body"
          name="selectedCustomerId"
          [(ngModel)]="filter.customerId"
        >
        </p-dropdown>
      </div>
      <div class="form-control">
        <label>{{ "INVOICES" | translate }}</label>
        <p-dropdown
          placeholder="Selecione..."
          [filter]="true"
          [options]="invoicesByMarina"
          appendTo="body"
          name="selectedInvoiceId"
          [(ngModel)]="filter.invoiceId"
        >
        </p-dropdown>
      </div>
      <div class="form-control">
        <label>{{ "ACTIONS" | translate }}</label>
        <p-dropdown
          placeholder="Selecione..."
          [filter]="true"
          [options]="invoiceActions"
          appendTo="body"
          name="selectedAction"
          [(ngModel)]="filter.invoiceAction"
        >
        </p-dropdown>
      </div>
      <div class="buttons">
        <div class="form-button">
          <button type="submit" class="primary easymarine">
            {{ "FIND" | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
  <app-nixx-bank-logs-table [nixxBankLogs]="nixxBankLogs"></app-nixx-bank-logs-table>
</p-fieldset>
