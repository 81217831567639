import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-role-log-geral',
  templateUrl: './role-log-geral.component.html',
  styleUrls: ['./role-log-geral.component.scss']
})
export class RoleLogGeralComponent implements OnInit {


  @Input() roleLog;
  @Input() isNew;

  oldDatasMap;

  constructor(    ) { }

  async ngOnInit(): Promise<void> {
        this.oldDatasMap = new Map(Object.entries(this.roleLog.oldDatas));

  }

  simNao(value: boolean): string {
    return value ? 'Sim' : 'Não';
  }

}
