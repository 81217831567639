export enum PanelFunctionality {
    SlingDownQueue = 'SlingDownQueue',
    SlingUpQueue = 'SlingUpQueue',
    SlingWaterQueue = 'SlingWaterQueue',
    SlingWaterUpQueue = 'SlingWaterUpQueue',
    SlingSailing = 'SlingSailing',
    SlingMoored = 'SlingMoored',
    BoatWash = 'BoatWash',
    MovementAll = 'MovementAll',
    MovementYard = 'MovementYard',
    MovementNavigation = 'MovementNavigation',
    MovementMoored = 'MovementMoored',
    MovementMooredNavigation = 'MovementMooredNavigation',
    MovementVacancy = 'MovementVacancy',
    WorkshopBudget = 'WorkshopBudget',
    BudgetSendStatus = 'BudgetSendStatus',
    ServiceOrderStatus = 'ServiceOrderStatus',
    Disabled = 'Disabled',
    MovementDownQueue = "MovementDownQueue"
}
