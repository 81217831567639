<div *ngIf="isMultPanel()">
    <p-fieldset>
        <p-header class="article-title">
            <div class="article-title">{{'PANEL' | translate}}</div>
        </p-header>
        <div class="form-group">
            <div class="form-control">
                <label for="raspberry">{{ 'OBSERVATION' | translate }}*</label>
                <input type="text" pInputText [(ngModel)]="panelConfig.observation" required name="observation">
            </div>
            <div class="form-control">
                <label for="panelFunctionality">{{ 'CONTENT' | translate }}*</label>
                <p-dropdown name="panelFunctionality" [options]="panelFunctionalities" appendTo="body"
                    [(ngModel)]="panelConfig.panelFunctionality" placeholder="Selecione..."
                    [disabled]="isDisabled(panelConfig.panelFunctionality)" required></p-dropdown>
            </div>
            <div *ngIf="panelConfig.panelFunctionality === 'MovementDownQueue'" class="form-control">
                <label>{{ 'QUEUE' | translate }}</label>
                <p-dropdown name="movementGroups" [options]="movementGroups" [(ngModel)]="panelConfig.movementGroupName"
                    optionLabel="value.name" optionValue="value.name" (onChange)="setMovementGroup($event)"
                    appendTo="body" placeholder="Selecione..." [disabled]="isDisabled(panelConfig?.movementGroupName)">
                </p-dropdown>
            </div>
        </div>
    </p-fieldset>
    <br>
    <p-fieldset>
        <p-header class="article-title">
            <div class="article-title">{{'Configurações de Exibição' | translate}}</div>
        </p-header>
        <div class="form-group">
            <div class="form-control">
                <label for="advertisementOption">Divulga propaganda</label>
                <p-inputSwitch required name="advertisementOption" [(ngModel)]="advertiseAdvertisement"
                    (onChange)="changeAdvertiseAdvertisement()">
                </p-inputSwitch>
            </div>
            <div class="form-control">
                <label for="showWeather">Mostrar previsão do tempo</label>
                <p-inputSwitch required name="showWeather" [(ngModel)]="panelConfig.showWeather">
                </p-inputSwitch>
            </div>
            <div class="form-control">
                <label for="showTime">Mostrar data e hora</label>
                <p-inputSwitch name="showTime" [(ngModel)]="panelConfig.showTime">
                </p-inputSwitch>
            </div>
            <div class="form-control">
                <label for="displayCanceled">Exibir barcos cancelados</label>
                <p-inputSwitch name="displayCanceled" [(ngModel)]="panelConfig.displayCanceled">
                </p-inputSwitch>
            </div>
            <div class="form-control" *ngIf=" panelConfig.panelFunctionality == 'SlingDownQueue'">
                <label for="blinkLastMovNavigation">Piscar última navegação adicionada</label>
                <p-inputSwitch name="blinkLastMovNavigation" [(ngModel)]="panelConfig.blinkLastMovNavigation">
                </p-inputSwitch>
            </div>
            <div class="form-control"
                *ngIf="panelConfig.panelFunctionality === 'SlingDownQueue' && panelConfig.blinkLastMovNavigation ">
                <label for="blinkLastMovNavigationTimer">Tempo de piscagem (segundos)</label>
                <p-inputNumber name="blinkLastMovNavigationTimer" [(ngModel)]="panelConfig.blinkLastMovNavigationTimer"
                    placeholder="Tempo em segundos" pTooltip="Tempo em que a última navegação vai ficar piscando"
                    tooltipPosition="bottom"></p-inputNumber>
            </div>
        </div>
    </p-fieldset>
</div>
<p-fieldset *ngIf="!isMultPanel()">
    <p-header class="article-title">
        <div class="article-title">{{'Configurações de Exibição' | translate}}</div>
    </p-header>
    <div class="form-group">
        <div class="form-control">
            <label for="advertisementOption">Divulga propaganda</label>
            <p-inputSwitch required name="advertisementOption" [(ngModel)]="advertiseAdvertisement"
                (onChange)="changeAdvertiseAdvertisement()">
            </p-inputSwitch>
        </div>
        <div class="form-control">
            <label for="showWeather">Mostrar previsão do tempo</label>
            <p-inputSwitch required name="showWeather" [(ngModel)]="panelConfig.showWeather">
            </p-inputSwitch>
        </div>
        <div class="form-control">
            <label for="showTime">Mostrar data e hora</label>
            <p-inputSwitch name="showTime" [(ngModel)]="panelConfig.showTime">
            </p-inputSwitch>
        </div>
        <div class="form-control">
            <label for="darkMode">Modo escuro</label>
            <p-inputSwitch name="darkMode" [(ngModel)]="panelConfig.darkMode">
            </p-inputSwitch>
        </div>
    </div>
    <br>
    <div class="form-group">
        <div class="form-control">
            <label for="showInfo">Exibir observação do painel</label>
            <p-inputSwitch name="showInfo" [(ngModel)]="panelConfig.showInfo">
            </p-inputSwitch>
        </div>
        <div class="form-control">
            <label for="displayCanceled">Exibir barcos cancelados</label>
            <p-inputSwitch name="displayCanceled" (onChange)="changeDisplayCanceled()"
                [(ngModel)]="panelConfig.displayCanceled">
            </p-inputSwitch>
        </div>
        <div class="form-control">
            <label for="enableAnimation">Habilitar animação</label>
            <p-inputSwitch name="enableAnimation"
                (onChange)="panelConfig.animationDelay = (panelConfig.enableAnimation ? 10 : null)"
                [(ngModel)]="panelConfig.enableAnimation">
            </p-inputSwitch>
        </div>
        <div class="form-control" *ngIf="panelConfig.enableAnimation">
            <label for="enableAnimation">Intervalo (em segundos)</label>
            <p-inputNumber name="animationDelay" [(ngModel)]="panelConfig.animationDelay" required>
            </p-inputNumber>
        </div>
        <div class="form-control" *ngIf=" panelConfig.panelFunctionality == 'SlingDownQueue'">
            <label for="blinkLastMovNavigation">Piscar última navegação adicionada</label>
            <p-inputSwitch name="blinkLastMovNavigation" [(ngModel)]="panelConfig.blinkLastMovNavigation">
            </p-inputSwitch>
        </div>
        <div class="form-control"
            *ngIf="panelConfig.panelFunctionality === 'SlingDownQueue' && panelConfig.blinkLastMovNavigation ">
            <label for="blinkLastMovNavigationTimer">Tempo de piscagem (segundos)</label>
            <p-inputNumber name="blinkLastMovNavigationTimer" [(ngModel)]="panelConfig.blinkLastMovNavigationTimer"
                placeholder="Tempo em segundos" pTooltip="Tempo em que a última navegação vai ficar piscando"
                tooltipPosition="bottom"></p-inputNumber>
        </div>
    </div>
</p-fieldset>
<br>
<p-fieldset>
    <p-header class="article-title">
        <div class="article-title">{{'Paginação' | translate}}</div>
    </p-header>

    <div class="form-group">
        <div class="form-control">
            <label for="autoPaginate">Paginação automática</label>
            <p-inputSwitch name="autoPaginate" [(ngModel)]="panelConfig.autoPaginate">
            </p-inputSwitch>
        </div>
        <div class="form-control" *ngIf="!panelConfig.autoPaginate">
            <label for="page">Página</label>
            <p-inputNumber name="page" [(ngModel)]="panelConfig.page" required></p-inputNumber>
        </div>
        <div class="form-control" *ngIf="panelConfig.autoPaginate">
            <label for="paginationRefreshTime">Tempo de paginação (segundos)</label>
            <p-inputNumber name="paginationRefreshTime" [(ngModel)]="panelConfig.paginationRefreshTime"
                required></p-inputNumber>
        </div>
    </div>
</p-fieldset>
<br>
<p-fieldset *ngIf="advertiseAdvertisement">
    <p-header class="article-title">
        <div class="article-title">{{'Propaganda' | translate}}</div>
    </p-header>
    <div class="form-group">
        <div class="form-control radio">
            <p-radioButton (onClick)="changeAdvertisement()" name="imageType" value="1" inputId="imageType1"
                [(ngModel)]="imageType">
            </p-radioButton>
            <label for="imageType1" class="advertise text-center">
                1
            </label>
        </div>
        <div class="form-control radio">
            <p-radioButton (onClick)="changeAdvertisement()" name="imageType" value="2" inputId="imageType2"
                [(ngModel)]="imageType">
            </p-radioButton>
            <label for="imageType2" class="advertise text-center">
                <span class="flex1">1</span>
                <span class="flex2 border-left">2</span>
            </label>
        </div>
        <div class="form-control radio">
            <p-radioButton (onClick)="changeAdvertisement()" name="imageType" value="3" inputId="imageType3"
                [(ngModel)]="imageType">
            </p-radioButton>
            <label for="imageType3" class="advertise text-center">
                <span class="flex1">1</span>
                <span class="flex1 border-left">2</span>
            </label>
        </div>
        <div class="form-control radio">
            <p-radioButton (onClick)="changeAdvertisement()" name="imageType" value="4" inputId="imageType4"
                [(ngModel)]="imageType">
            </p-radioButton>
            <label for="imageType4" class="advertise text-center">
                <span class="flex2">1</span>
                <span class="flex1 border-left">2</span>
            </label>
        </div>
        <div class="form-control radio">
            <p-radioButton (onClick)="changeAdvertisement()" name="imageType" value="5" inputId="imageType5"
                [(ngModel)]="imageType">
            </p-radioButton>
            <label for="imageType5" class="advertise text-center">
                <span class="flex1">1</span>
                <span class="flex1 border-left">2</span>
                <span class="flex2 border-left">3</span>
            </label>
        </div>
        <div class="form-control radio">
            <p-radioButton (onClick)="changeAdvertisement()" name="imageType" value="6" inputId="imageType6"
                [(ngModel)]="imageType">
            </p-radioButton>
            <label for="imageType6" class="advertise text-center">
                <span class="flex2">1</span>
                <span class="flex1 border-left">2</span>
                <span class="flex1 border-left">3</span>
            </label>
        </div>
        <div class="form-control radio">
            <p-radioButton (onClick)="changeAdvertisement()" name="imageType" value="7" inputId="imageType7"
                [(ngModel)]="imageType">
            </p-radioButton>
            <label for="imageType7" class="advertise text-center">
                <span class="flex1">1</span>
                <span class="flex2 border-left">2</span>
                <span class="flex1 border-left">3</span>
            </label>
        </div>
        <div class="form-control radio">
            <p-radioButton (onClick)="changeAdvertisement()" name="imageType" value="8" inputId="imageType8"
                [(ngModel)]="imageType">
            </p-radioButton>
            <label for="imageType8" class="advertise text-center">
                <span class="flex1">1</span>
                <span class="flex1 border-left">2</span>
                <span class="flex1 border-left">3</span>
                <span class="flex1 border-left">4</span>
            </label>
        </div>
    </div>
    <br>
    <div class="form-group" *ngIf="imageType != null">
        <p-fieldset [ngClass]="imageConfig.class1">
            <p-header class="article-title">
                <div class="article-title">{{ 'IMAGE' | translate }} 1</div>
                <span style="font-size: 15px;">Largura: {{imageConfig.width1}}px / Altura: 215px</span>
            </p-header>
            <div *ngIf="image1 != ''">
                <img src="{{image1}}" [width]="imageConfig.width1" height="215">
            </div>
            <p-fileUpload name="attachment" mode="basic" maxFileSize="100000000" chooseLabel="Anexar"
                accept=".png,.jpg,.jpeg" (uploadHandler)="onBasicUpload($event.files, 'image1')" [auto]="true"
                customUpload="true" #upload>
            </p-fileUpload>
            <p-progressSpinner [hidden]="!uploadFileInProgress" [style]="{ width: '25px', height: '25px' }">
            </p-progressSpinner>
        </p-fieldset>
        <p-fieldset [ngClass]="imageConfig.class2">
            <p-header class="article-title">
                <div class="article-title">{{ 'IMAGE' | translate }} 2</div>
                <span style="font-size: 15px;">Largura: {{imageConfig.width2}}px / Altura: 215px</span>
            </p-header>
            <div *ngIf="image2 != ''">
                <img src="{{image2}}" [width]="imageConfig.width2" height="215">
            </div>
            <p-fileUpload name="attachment" mode="basic" maxFileSize="100000000" chooseLabel="Anexar"
                accept=".png,.jpg,.jpeg" (uploadHandler)="onBasicUpload($event.files, 'image2')" [auto]="true"
                customUpload="true" #upload>
            </p-fileUpload>
            <p-progressSpinner [hidden]="!uploadLogoAppFileInProgress" [style]="{ width: '25px', height: '25px' }">
            </p-progressSpinner>
        </p-fieldset>
        <p-fieldset [ngClass]="imageConfig.class3">
            <p-header class="article-title">
                <div class="article-title">{{ 'IMAGE' | translate }} 3</div>
                <span style="font-size: 15px;">Largura: {{imageConfig.width3}}px / Altura: 215px</span>
            </p-header>
            <div *ngIf="image3 != ''">
                <img src="{{image3}}" [width]="imageConfig.width3" height="215">
            </div>
            <p-fileUpload name="attachment" mode="basic" maxFileSize="100000000" chooseLabel="Anexar"
                accept=".png,.jpg,.jpeg" (uploadHandler)="onBasicUpload($event.files, 'image3')" [auto]="true"
                customUpload="true" #upload>
            </p-fileUpload>
            <p-progressSpinner [hidden]="!uploadLogoAppFileInProgress" [style]="{ width: '25px', height: '25px' }">
            </p-progressSpinner>
        </p-fieldset>
        <p-fieldset [ngClass]="imageConfig.class4">
            <p-header class="article-title">
                <div class="article-title">{{ 'IMAGE' | translate }} 4</div>
                <span style="font-size: 15px;">Largura: {{imageConfig.width4}}px / Altura: 215px</span>
            </p-header>
            <div *ngIf="image4 != ''">
                <img src="{{image4}}" [width]="imageConfig.width4" height="215">
            </div>
            <p-fileUpload name="attachment" mode="basic" maxFileSize="100000000" chooseLabel="Anexar"
                accept=".png,.jpg,.jpeg" (uploadHandler)="onBasicUpload($event.files, 'image4')" [auto]="true"
                customUpload="true" #upload>
            </p-fileUpload>
            <p-progressSpinner [hidden]="!uploadLogoAppFileInProgress" [style]="{ width: '25px', height: '25px' }">
            </p-progressSpinner>
        </p-fieldset>
    </div>
    <div class="form-group sub-buttons" *ngIf="imageType != null">
        <div class="form-control refresh-time">
            <label for="refreshTime">Duração</label>
            <p-inputNumber pTooltip="Duração em segundos, mínimo 5 segundos" name="refreshTime"
                [(ngModel)]="refreshTime"></p-inputNumber>
        </div>
        <button class="easymarine primary" (click)="addImage()" type="button"
            [disabled]="isDisabledAddImage()">{{indexEditAdvertisement >= 0 ? 'Atualizar propaganda' :
            'Adicionar propaganda'}}</button>
    </div>
    <br>
    <div class="table">
        <p-table [value]="panelConfig.advertisements" *ngIf="panelConfig.advertisements?.length > 0">
            <ng-template pTemplate="header">
                <tr>
                    <th width="20%">Imagem 1</th>
                    <th width="20%">Imagem 2</th>
                    <th width="20%">Imagem 3</th>
                    <th width="20%">Imagem 4</th>
                    <th width="10%">Tempo</th>
                    <th width="5%"></th>
                    <th width="5%"></th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-advertisement>
                <tr>
                    <td>
                        <a [href]="advertisement.image1" target="_blank">
                            {{getNameImage(advertisement.image1)}}
                        </a>
                    </td>
                    <td>
                        <a [href]="advertisement.image2" target="_blank">
                            {{getNameImage(advertisement.image2)}}
                        </a>
                    </td>
                    <td>
                        <a [href]="advertisement.image3" target="_blank">
                            {{getNameImage(advertisement.image3)}}
                        </a>
                    </td>
                    <td>
                        <a [href]="advertisement.image4" target="_blank">
                            {{getNameImage(advertisement.image4)}}
                        </a>
                    </td>
                    <td>{{advertisement.refreshTime}}</td>
                    <td class="text-center">
                        <div class="table-action"
                            (click)="editAdvertisement(panelConfig.advertisements.indexOf(advertisement))">
                            <i class="pi pi-pencil"></i>
                        </div>
                    </td>
                    <td class="text-center">
                        <div class="table-action"
                            (click)="removeImage(panelConfig.advertisements.indexOf(advertisement))">
                            <i class="pi pi-trash"></i>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="7">
                        Nenhuma propaganda encontrada
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-fieldset>