import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class GroupService {

    constructor(private http: HttpClient) {
    }

    findAll(): Observable<any[]> {
        return this.http.get(environment.apiHost + '/secure/group/').pipe(map((response: any) => response.data.groups));
    }

    save(group): Observable<any> {
        return this.http.post(environment.apiHost + '/secure/group/', group).pipe(map((response: any) => response.group));
    }

    delete(group): Observable<any> {
        return this.http.delete(environment.apiHost + '/secure/group/' + group.id).pipe(map((response: any) => response.group));
    }

    findAllActive(): Observable<any[]> {
        return this.http.get(environment.apiHost + '/secure/group/active').pipe(map((response: any) => response.data.groups));
    }
}
