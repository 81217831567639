import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-multiple-reference-date',
  templateUrl: './multiple-reference-date.component.html',
  styleUrls: ['./multiple-reference-date.component.scss']
})
export class MultipleReferenceDateComponent implements OnInit {

  dateReferences

  dateReferencesTable = []

  constructor(
    public dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.dateReferences = this.config.data.dateReferences;
    this.createTable();
  }

  createTable(){
    this.dateReferencesTable = [];
    for(const [key, value] of this.dateReferences){
      this.dateReferencesTable.push({
        dateName:key,
        orders:this.getOrdersNumber(value)
      })
    }
  }

  getOrdersNumber(order) {
    return order.map( o => o.orderNumber).join(", ");
  }

  continue(){
    this.dialogRef.close(this.dateReferences);
  }

}
