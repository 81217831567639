<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{ 'TITLE-MY-INVOICE' | translate }}</span>
    </p-header>

    <div class="table">
        <p-table [value]="marinaInvoices" [paginator]="true" [rows]="numberOfRows"
                [pageLinks]="3" [rowsPerPageOptions]="[10,20,30,50,100]"
                [resizableColumns]="true" [autoLayout]="true">

                <ng-template pTemplate="header">
                    <tr>
                       <th class="text-center">Vencimento</th>
                       <th class="text-center">Status</th>
                       <th class="text-center">Fatura</th>
                       <th class="text-center">Parcela</th>
                       <th class="text-center">Nota fiscal</th>
                       <th class="text-center">Tipo</th>
                        <th class="text-center">Valor</th>
                        <th class="text-center">Boleto PDF</th>

                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-invoice>
                    <tr>
                        <td class="text-center">{{invoice.vencimento}}</td>
                        <td [ngStyle]="{'color': getColorSituacao(invoice) }" class="text-center">{{invoice.situacao}}</td>
                        <td class="text-center">
                            <div class="link" (click)="visualizarfatura(invoice.id)">
                            {{invoice.id}}
                            </div>
                        </td>
                        <td class="text-center" *ngIf="invoice.parcelaAtual; else semParcela">{{invoice.parcelaAtual}}/{{invoice.parcelaTotal}}
                        </td>
                        <ng-template #semParcela><td></td></ng-template>
                        <td class="text-center">
                            <a *ngIf="invoice?.notaFiscal" [href]="invoice.linkNFEPS" target="_blank">{{invoice.notaFiscal}}</a>
                        </td>
                        <td class="text-center">{{invoice?.tipoProduto}}</td>
                        <td class="text-center">{{invoice.valor | number : '1.2-2'}}</td>
                        <td class="text-center" >
                            <a [href]="invoice.linkBoleto" target="_blank">
                                <img *ngIf="invoice.urlBoleto && invoice.urlBoleto !== ''" src="assets/images/boleto.png" height="20px" width="20px"/>
                            </a>
                        </td>

                    </tr>
                </ng-template>
            </p-table>
    </div>

</p-fieldset>
