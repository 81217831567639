import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Sling } from 'src/app/models';
import { BoatStatus, SlingStatus } from 'src/app/models/enums';
import { UserService } from 'src/app/services/user.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';

@Component({
  selector: 'app-sling-operation',
  templateUrl: './sling-operation.component.html',
  styleUrls: ['./sling-operation.component.scss']
})
export class SlingOperationComponent implements OnInit {

  origin: string;
  sling: Sling;
  boatStatus: BoatStatus;
  slingStatus: SlingStatus;
  manageDeck: boolean;
  finishedOnWater: boolean;
  isFinishOnWater: boolean;
  userId: number;
  boatStatusModel: any;

  constructor(
    public dialogRef: DynamicDialogRef,
    private userService: UserService,
    private messageUtil: MessageUtil,
    private config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    this.origin = this.config.data.origin;
    this.sling = this.config.data.sling;
    this.manageDeck = this.config.data.manageDeck;
    this.isFinishOnWater = this.config.data.isFinishOnWater;
    this.userId = StorageUtil.getUserId();
  }

  changeBoatStatus(boatStatus: BoatStatus): void {
    this.boatStatus = boatStatus;
    this.slingStatus = undefined;
  }

  setSlingStatus(slingStatus: SlingStatus): void {
    this.slingStatus = slingStatus;
    this.boatStatus = undefined;
  }

  save(): void {
    if (!this.boatStatusModel){
      this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', 'Selecione uma opção.');
      return;
    }
    
    this.dialogRef.close({
      confirm: true,
      boatStatus: this.boatStatus,
      slingStatus: this.slingStatus,
      user: {
        id: StorageUtil.getUserId()
      },
      finishedOnWater: this.finishedOnWater
    });
  }

  finishOnWater(): void {
    this.finishedOnWater = true;
    this.slingStatus = SlingStatus.WATER;
    this.boatStatus = BoatStatus.MOORED;
  }

}

