import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MovementGroup } from "src/app/models/movement/movement-group";

export const getCustomValidatorsMovementGroupToMovementGroupMeansByLocationType_MovementMean = (form: UntypedFormGroup): (control: UntypedFormControl) => null | any => {
  return (control: UntypedFormControl) => {
    const { movementGroupMeans } = form.value as MovementGroup;
    const movementMeansIds = movementGroupMeans.map(mm => mm.id);
    if (!movementMeansIds?.includes(control.value?.id)) {
      return { invalidMovementMeanToMovementGroupMeansByLocationType: true }
    }
    return null;
  }
}
