import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MarinaBerthMap } from "../models/marina-berth-map";
import { environment } from "src/environments/environment";
import { map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root',
  })
  export class MarinaBerthMapService {


    constructor(private http: HttpClient) {

    }

    getMarinaBerthMap(marinaId: number): Observable<MarinaBerthMap[]> {
        return this.http.get(environment.apiHost + '/secure/berthmap/marina/' + marinaId).pipe(map((response: any) => response.data.berthmaps));
    }

    save(marinaBerthMap:MarinaBerthMap): Observable<any> {
        return this.http.put(environment.apiHost + '/secure/berthmap/', marinaBerthMap).pipe(map((response: any) => response.data.berthmaps));
    }



    

  }