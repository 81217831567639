import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-readjustments-product-table',
  templateUrl: './readjustments-product-table.component.html',
  styleUrls: ['./readjustments-product-table.component.scss']
})
export class ReadjustmentsProductTableComponent implements OnInit {

  @Input() readjustements;
  @Input() productId;

  @Output() eventLog: EventEmitter<void> = new EventEmitter();

  constructor( ) { }

  ngOnInit(): void {
    this.readjustements = this.sort();
  }

  sort(): any[] {
    return this.readjustements.sort((a, b) => {
      if (a.date < b.date) {
        return 1;
      } else if (a.date > b.date) {
        return -1;
      }
      return 0;
    });
  }

  goToLogIn(date) {
    this.eventLog.emit(date);
  }

}
