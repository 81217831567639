import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class ContractModelMarinaService {

    constructor(private http: HttpClient) { }

    createContractModel(id: number, idBoat: number, idService: number): Observable<any> {
        return this.http.get(environment.apiHost + '/secure/contract-model-marina/' + id + '/' + idBoat + '/' + idService)
            .pipe(map((response: any) => response.data.contract));
    }

    uploadFile(files): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('attachment', files[0]);

        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'multipart/form-data');

        return this.http.post(
            environment.apiHost + '/secure/marinas/contract-marina/attachment',
            formData,
            { headers }
        ).pipe(map((response: any) => response.data.attachment));
    }
}
