import { PhotoCaptureComponent } from './../photo-capture/photo-capture.component';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { FileUpload } from 'primeng/fileupload';
import { SailorService } from 'src/app/services/sailor.service';
import { MessageUtil } from 'src/app/utils/message.util';

@Component({
  selector: 'app-sailor-upload',
  templateUrl: './sailor-upload.component.html',
  styleUrls: ['./sailor-upload.component.scss'],
})
export class SailorUploadComponent implements OnInit {
  @ViewChild('upload', { static: false }) upload: FileUpload;

  @Input()
  path: string;

  @Input()
  title: string;

  file: File;

  @Output()
  finishEvent: EventEmitter<any> = new EventEmitter();

  imgPreview: any;

  constructor(
    private messageUtil: MessageUtil,
    private sailorService: SailorService,
    public dialog: DialogService
  ) {}

  ngOnInit(): void {
    this.imgPreview = this.path;
  }

  ngOnChanges(): void {
    this.imgPreview = this.path;
  }

  photoCam() {
    this.dialog
      .open(PhotoCaptureComponent, {
        width: '640px',
        height: '80vh',
        dismissableMask: false,
        data: {},
        header: this.messageUtil.translateKey('Capturar Imagem'),
      })
      .onClose.subscribe((d: any) => {
        if (d) {
          this.imgPreview = d;
          this.onBasicUpload(this.dataURLtoFile(d, 'image.png'));
        }
      });
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  enviar(data: string) {
    this.finishEvent.emit(data);
  }

  photoRemove() {
    this.imgPreview = '';
    this.enviar('');
  }

  setFile(file): void {
    this.upload.clear();
    this.file = file[0];
    const reader = new FileReader();
    reader.readAsDataURL(this.file);
    reader.onload = (evt) => {
      this.imgPreview = reader.result;
    };
    this.onBasicUpload(file[0]);
  }

  async onBasicUpload(file): Promise<any> {
    let files: any[] = [];
    files.push(file);
    this.sailorService.uploadLicence(files).subscribe(
      (data) => {
        this.enviar(data.url);
      },
      (event) => {
        this.messageUtil.generateMessage(
          'warning',
          'SUMMARY.WARNING',
          'Erro ao tentar enviar o arquivo.'
        );
      }
    );
  }
}
