import { Token } from './../models/token';

import { TokenUtils } from './token.util';
import { Marina} from '../models';
import { MarinaCompany } from '../models/marina-company';

export class StorageUtil {
  constructor() {}

  public static updateMovementExecutionInteractions(movementInteractList: number[], movementLastInteract: number): void {
    localStorage.setItem('movementInteractList', JSON.stringify(movementInteractList));
    if(movementLastInteract) {
      localStorage.setItem('movementLastInteract', JSON.stringify(movementLastInteract));
    } else {
      localStorage.removeItem('movementLastInteract');
    }
  }

  public static getMovementInteractList(): number[] {
    if (localStorage.getItem('movementInteractList')) {
      return JSON.parse(localStorage.getItem('movementInteractList'));
    }

    return [];
  }

  public static getMovementLastInteract(): number {
    if (localStorage.getItem('movementLastInteract')) {
      return JSON.parse(localStorage.getItem('movementLastInteract'));
    }

    return undefined;
  }

  public static getMarina(): Marina {
    let currentMarina: any = {};
    if (localStorage.getItem('currentMarina')) {
      currentMarina = JSON.parse(localStorage.getItem('currentMarina'));
      return currentMarina != null
        ? (currentMarina as Marina)
        : null;
    } else {
      this.logout();
      window.location.reload();
    }
  }

  public static setMarina(marina: Marina): void {
    localStorage.setItem('currentMarina', JSON.stringify(marina));
  }

  public static getMarinaId(): number {
    if (localStorage.getItem('currentMarina') != null){
      const currentId = this.getMarina().id;
      if (currentId) {
        return currentId;
      }
    }
    const tokenMarinasIdsAccess = this.getDecodeToken().marinasIdsAccess;
    if ( tokenMarinasIdsAccess && tokenMarinasIdsAccess != null
    && tokenMarinasIdsAccess.length > 0) {
      return tokenMarinasIdsAccess[0];
    }
    return null;
  }

  public static getMarinaName(): string {
    const currentMarina = this.getMarina();
    if (currentMarina) {
      return currentMarina.tradeName;
    }
    return '';
  }

  public static getUserName(): string {
    const tokenObj = this.getDecodeToken();
    if (tokenObj) {
      return tokenObj.firstName + ' ' + tokenObj.lastName;
    }
    return '';
  }

  public static getUserId(): number {
    const currentUserId = this.getDecodeToken().userId;
    if (currentUserId) {
      return currentUserId;
    }
  }

  public static getLogoMarina(): string {
    const currentMarina = this.getMarina();
    if (currentMarina) {
      return currentMarina.logoFile;
    }
    return null;
  }

  public static getUser(): Token {
    const user = this.getDecodeToken();
    return user;
  }

  public static getMarinasIds(): number[] {
    return this.getDecodeToken().marinasIdsAccess;
  }

  public static getMarinaCompanies(): MarinaCompany[] {
    const marinaCompanies = this.getMarina().marinaCompanies;
    if (marinaCompanies) {
      return marinaCompanies;
    }
    return null;
  }

  public static setLastPayment(vencimento: string): void {
    sessionStorage.setItem('vencimento', vencimento);
  }

  public static getLastPayment(): string {
    return sessionStorage.getItem('vencimento');
  }

  public static logout(): void {
    sessionStorage.clear();
    localStorage.clear();
  }

  public static login(token: string, refreshToken: string, marina: Marina): void {
    this.saveToken(token);
    this.saveRefreshToken(refreshToken);
    localStorage.setItem('currentMarina', JSON.stringify(marina));
  }

  public static saveToken(token: string): void {
    localStorage.setItem('token', token);
  }

  public static saveRefreshToken(refreshToken: string): void {
    localStorage.setItem('refreshToken', refreshToken);
  }

  public static getToken(): string {
    return localStorage.getItem('token');
  }

  public static getRefreshToken(): string {
    return localStorage.getItem('refreshToken');
  }

  public static getDecodeToken(): Token{
    return TokenUtils.getDecodeToken(this.getToken());
  }

  public static clearStorage(): void {
    localStorage.clear();
  }

  public static saveWebVersion(version: string): void{
    localStorage.setItem('webVersion', version);
  }

  public static getWebVersion(): string{
    return localStorage.getItem('webVersion');
  }

  public static getServerVersion(){
    return this.getDecodeToken().webVersion;
  }
}
