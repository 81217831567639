<div class="dialog-form">
    <form (submit)="save()" [formGroup]="franchiseGroupForm">
      <p-fieldset>

        <div class="form-group">
          <div class="form-control">
            <label for="name">{{ "Nome" | translate }} *</label>
            <input
              pInputText
              name="name"
              formControlName="name"
              maxlength="30"
              [readonly]="isStandard"
            />
          </div>
          <div class="form-control">
            <label for="franchise">{{ "Franquia" | translate }} *</label>
            <input
              pInputText
              name="franchise"
              formControlName="franchise"
              type="number"  
              [readonly]="isStandard"
            />
          </div>
          <div class="form-control flex-end">
            <label for="active">{{ "ACTIVE" | translate }}</label>
            <p-inputSwitch
              styleClass="myswitch"
              formControlName="active"
              name="active"
              [readonly]="isStandard"
            >
            </p-inputSwitch>
          </div>
        </div>

        <div class="form-group">
          <div class="form-control basic extended">
            <label for="description">{{ "DESCRIPTION" | translate }}</label>
            <textarea
              pInputText
              maxlength="300"
              name="description"
              rows="5"
              cols="90"
              formControlName="description"
            ></textarea>
            <span
              >{{ franchiseGroupForm.get("description").value?.length }} / 255</span
            >
          </div>
        </div>
      </p-fieldset>
      <br />
      <p-fieldset>
        <p-header>
          <div>{{ "Serviço Excedente" | translate }}</div>
        </p-header>
        <div class="form-group">
          <div class="form-control">
            <label for="product">{{ "Nome" | translate }} *</label>
            <p-dropdown
            [options]="this.products"
            name="product"
            optionValue="id"
            optionLabel="name"
            formControlName="surplusServiceId"
            appendTo="body"
            [readonly]="isStandard"
            defaultLabel="Selecione..."
            [style]="{ width: '100%' }"
            />
            
          </div>
          <div class="form-control">
            <label for="product">{{"Tipo de preço"| translate }}</label>
            <input
              pInputText
              name="franchise"
              [value]="getPriceType()"
              [readonly]="true"
              />
            </div>
          </div>

          
      </p-fieldset>
      
      <div class="form-group align-center">
        <div class="buttons">
          <button
            class="easymarine danger"
            type="button"
            (click)="dialog.close()"
          >
            {{ "CANCEL" | translate }}
          </button>
          <button class="easymarine primary" type="submit">
            {{ "SAVE" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
  