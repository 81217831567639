import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import { MultipleDestinationsComponent } from 'src/app/components/sling/sling-form/multiple-destinations/multiple-destinations.component';

@Component({
  selector: 'app-destinies-navigation-plan-mv',
  templateUrl: './destinies-navigation-plan.component.html',
  styleUrls: ['./destinies-navigation-plan.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DestiniesNavigationPlanComponent),
      multi: true
    }
  ]
})
export class DestiniesNavigationPlanComponent implements OnInit, ControlValueAccessor  {

  destinations: string[] = [];

  @Input()
  frequentDestinations: {label: string, value: string}[] = [];
  disabled = false;

  constructor(private dialog: DialogService) { }

  private onChanges: any = () => {};
  private onTouched: any = () => {};

  writeValue(obj: string): void {
    if (obj) {
      this.destinations = [...(new Set(obj.split(',')))];
    } else {
      this.destinations = [];
    }
  }
  registerOnChange(fn: any): void { this.onChanges = fn; }
  registerOnTouched(fn: any): void { this.onTouched = fn; }
  setDisabledState?(isDisabled: boolean): void { this.disabled = isDisabled; }

  ngOnInit(): void {}

  async showMultiplesDestination(): Promise<void>{
    this.dialog.open(MultipleDestinationsComponent, {
      width: '480px',
      height: '550px',
      header: 'Múltiplos destinos',
      data: {
        frequentFestinations: this.frequentDestinations,
        frequentFestination: [...this.destinations].join(',')
      }
    }).onClose.subscribe((destinations) => {
      if (destinations  !== undefined) {
        const newDestinations = destinations.split(',').map((v: string) => v.trim());
        this.destinations = newDestinations;
        this.sendDestinations(this.destinations);
      }
    });
  }

  selectFrequentDestination(event: { originalEvent: any, value: string}): void {
    if (event.originalEvent.isTrusted && !this.destinations.includes(event.value)) {
      this.destinations = [...this.destinations, event.value];
      this.sendDestinations(this.destinations);
    }
  }

  onBlur(_event: any): void {
    this.sendDestinations(this.destinations);
  }

  sendDestinations(destinations: string[]): void {
    this.onChanges(destinations.join(','));
  }

}
