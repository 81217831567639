import { Address } from '../../../../../../models/address';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { VisitorService } from 'src/app/services/access-controler/visitor.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { Visitor } from 'src/app/models/dtos/access-controller/visitor';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { AccessGroupEnum } from 'src/app/models/dtos/access-controller/access-group-enum';

@Component({
  selector: 'app-visitor-form',
  templateUrl: './visitor-form.component.html',
  styleUrls: ['./visitor-form.component.scss'],
})
export class VisitorFormComponent implements OnInit {
  @Input() isNested: boolean = false;
  @Input() isProvider: boolean = false;
  @Input() visitorForm: FormGroup;
  @Input() address: Address;

  @Output() nextStep = new EventEmitter<any>();

  imgPreviewPhoto: string;
  visitor: Visitor = new Visitor();
  visitorType: AccessGroupEnum = null;

  constructor(
    private visitorService: VisitorService,
    private messageUtil: MessageUtil,
    private config: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    if (this.config?.data?.visitor) {
      this.visitor = this.config.data.visitor;
      if (!this.isNested)
        this.isProvider =
          this.config.data.visitorType === AccessGroupEnum.SERVICE_PROVIDER;
      if (this.visitor.residentialAddress == null) {
        this.visitor.residentialAddress = new Address();
      }
      this.address = this.visitor.residentialAddress;
      this.imgPreviewPhoto = this.visitor.photoUrl;
      if (!this.visitorForm) {
        this.loadVisitorForm();
      }

      this.loadVisitorById(this.visitor);
    }

    if (this.isProvider) {
      if (this.visitorForm.get('residentialAddress').value) {
        this.address = this.visitorForm.get('residentialAddress').value;
      }
    }
    if (this.visitorForm.get('photoUrl').value) {
      this.imgPreviewPhoto = this.visitorForm.get('photoUrl').value;
    }
  }

  loadVisitorForm(): void {
    this.visitorForm = new FormGroup({
      id: new FormControl<number | null>(null),
      name: new FormControl<string | null>(null, Validators.required),
      active: new FormControl<boolean | null>(true),
      marinaId: new FormControl<number | null>(null),
      federalId: new FormControl<string | null>(null, Validators.required),
      phone: new FormControl<string | null>(null, Validators.required),
      birthday: new FormControl<Date | null>(null, Validators.required),
      updatedAt: new FormControl<Date | null>(null),
      docUrl: new FormControl<string | null>(null),
      photoUrl: new FormControl<string | null>(null),
      createdAt: new FormControl<Date | null>(null),
      docUrlBack: new FormControl<string | null>(null),
      accessPermissions: new FormControl<[] | null>([]),
      colectId: new FormControl<number | null>(null),
      serviceProvider: new FormControl<boolean | null>(null),
    });
    if (this.isProvider) {
      this.visitorForm.addControl(
        'residentialAddress',
        new FormGroup({
          id: new FormControl(null),
          zip: new FormControl(null, Validators.required),
          country: new FormControl(null, Validators.required),
          state: new FormControl(null, Validators.required),
          city: new FormControl(null, Validators.required),
          district: new FormControl(null, Validators.required),
          street: new FormControl(null, Validators.required),
          number: new FormControl(null, Validators.required),
          complement: new FormControl(null),
        })
      );
      this.visitorForm.addControl(
        'observation',
        new FormControl<string | null>(null)
      );
    }
  }

  close(): void {
    this.dialogRef.close(null);
  }

  save(): void {
    if (this.visitorForm.invalid) {
      this.messageUtil.generateMessage(
        'warning',
        'SUMMARY.WARNING',
        'Preencha todos os campos obrigatórios'
      );
      return;
    }
    if (this.visitorForm.get('serviceProvider').value != true) {
      this.visitorForm.get('serviceProvider').setValue(this.isProvider);
    }
    this.spinner.show();
    this.visitorService.save(this.visitorForm.value).subscribe({
      next: (visitor) => {
        this.spinner.hide();
        this.messageUtil.generateMessage(
          'success',
          'SUMMARY.SUCCESS',
          'Visitante salvo com sucesso'
        );
        BroadcastService.get('reloadTables').emit();
        this.close();
      },
      error: (error) => {
        this.spinner.hide();
        Swal.fire({
          icon: 'error',
          title: 'Erro ao salvar visitante',
          text: error.error.message,
        });
      },
    });
  }

  next(): void {
    if (this.visitorForm.invalid) {
      this.messageUtil.generateMessage(
        'warning',
        'SUMMARY.WARNING',
        'Preencha todos os campos obrigatórios'
      );
      return;
    }
    let data = { visitorForm: this.visitorForm, index: 1 };
    this.nextStep.emit(data);
  }

  checkChange(): void {
    if (this.visitorForm.get('active').value === false) {
      this.messageUtil.generateMessage(
        'warning',
        'SUMMARY.WARNING',
        'Verifique se todas as permissões se encontram encerradas ou canceladas'
      );
    }
  }

  loadVisitorById(visitor: Visitor): void {
    this.visitorForm.patchValue(visitor);
    if (this.visitorForm.get('photoUrl').value) {
      this.imgPreviewPhoto = this.visitorForm.get('photoUrl').value;
    }
    let accessPermissions = visitor.accessPermissions.map((p) => ({
      id: p.id,
    }));
    this.visitorForm.get('accessPermissions').patchValue(accessPermissions);
    this.visitorForm
      .get('birthday')
      .setValue(new Date(this.visitorForm.get('birthday').value));
  }

  federalIdInUse(): void {
    this.visitorService
      .findByFederalId(this.visitorForm.get('federalId').value)
      .subscribe({
        next: (visitor) => {
          if (visitor?.id) {
            let visitorType = this.isProvider
              ? 'prestador de serviço'
              : 'visitante';
            Swal.fire({
              title: 'CPF já cadastrado',
              text: 'Deseja carregar os dados do ' + visitorType + '?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Sim',
              cancelButtonText: 'Não',
            }).then((result) => {
              if (result.isConfirmed) {
                this.loadVisitorById(visitor);
              } else {
                if (this.visitorForm.get('federalId').value) {
                  this.visitorForm.get('federalId').setValue(null);
                }
              }
            });
          }
        },
        error: (error) => {
          console.log('error', error);
        },
      });
  }

  receiveAddress(receive: any) {
    this.visitorForm
      .get('residentialAddress')
      .setValue(receive.addressForm.value);
  }

  receiveUploadUrlPhoto($event) {
    this.visitorForm.get('photoUrl').setValue($event);
  }
}
