<p-fieldset style="padding: 0px;">
  <p-header class="article-title">
      <div class="article-title">{{'GENERAL-DATA' | translate}}</div>
  </p-header>
  <div class="content-log" style="padding: 0px;">
      <div class="field-config">
          <label for="id">{{ 'ID' | translate }}</label>
          <app-log-field [old]="roleLog.roleId" [field]="roleLog.roleId" [existsOld]="false" [isNew]="isNew"></app-log-field>
      </div>
      <div class="field-config">
          <label for="name">{{ 'NAME' | translate }}</label>
          <app-log-field [old]="roleLog.oldDatas.name" [field]="roleLog.name" [existsOld]="oldDatasMap.has('name')" [isNew]="isNew"></app-log-field>

      </div>
      <div class="field-config">
          <label for="HIERARCHY">{{ 'HIERARCHY' | translate }}</label>
          <app-log-field [old]="roleLog.oldDatas.hierarchy" [field]="roleLog.hierarchy" [existsOld]="oldDatasMap.has('hierarchy')" [isNew]="isNew"></app-log-field>
      </div>

      <div class="field-config">
          <label for="photos">{{ 'MANAGE-PHOTOS' | translate }}</label>
          <app-log-field [old]="simNao(roleLog.oldDatas.photos)" [field]="simNao(roleLog.photos)" [existsOld]="oldDatasMap.has('photos')" [isNew]="isNew"></app-log-field>
      </div>

      <div class="field-config">
        <label for="administrator">{{ 'ADMINISTRATOR' | translate }}</label>
        <app-log-field  [old]="simNao(roleLog.oldDatas.administrator)" [field]="simNao(roleLog.administrator)" [existsOld]="oldDatasMap.has('administrator')" [isNew]="isNew"></app-log-field>
    </div>
  </div>
</p-fieldset>
<br>
