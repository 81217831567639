import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import b64toBlob from 'b64-to-blob';
import { NgxSpinnerService } from 'ngx-spinner';
import { LazyLoadEvent, SelectItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { CustomReportExportComponent } from 'src/app/components/extract-custom-report/custom-report-export.component';
import { ExtractInvoiceComponent } from 'src/app/components/extract-invoice/extract-invoice.component';
import { Product } from 'src/app/models';
import { InvoicingReportFilter } from 'src/app/models/dtos/invoicingReportFilter';
import { DrawerHeaderGroup } from 'src/app/models/dtos/drawer-header-group';
import { DrawerHeaderItem } from 'src/app/models/dtos/drawer-header-item';
import { SelectLabelValue } from 'src/app/models/select-label-value';
import { CustomerService } from 'src/app/services/customer.service';
import { DrawerService } from 'src/app/services/drawer.service';
import { GroupService } from 'src/app/services/group.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { ProductService } from 'src/app/services/product.service';
import { FormatUtil } from 'src/app/utils/format.util';
import { MessageUtil } from 'src/app/utils/message.util';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { PaginationFilter } from 'src/app/models/pagination-filter';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-super-invoicing-report',
  templateUrl: './super-invoicing-report.component.html',
  styleUrls: ['./super-invoicing-report.component.scss'],
})
export class SuperInvoicingReportComponent implements OnInit {
  drawerHeaderGroups: DrawerHeaderGroup[] = [];
  drawerHeaderItems: DrawerHeaderItem[] = [];
  isDrawerExpanded: boolean = false;
  isAlert: boolean = false;

  invoices: Array<any> = new Array<any>();
  invoicingReportsTable: Array<any> = new Array<any>();

  isMoreFiltersExpanded: boolean = false;
  isFilterExpanded: boolean;
  paginationFilter: PaginationFilter = new PaginationFilter();
  filter: any = {
    startCompetenceDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth() - 1,
      1
    ),
    endCompetenceDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      0
    ),
    report: 'true',
    status: ['PAID', 'PENDING'],
  };
  selectedColors: string[] = [];
  rangeEmissionDates: Date[] = [];
  rangeDueDates: Date[] = [];
  rangePaymentDates: Date[] = [];
  rangeCompetenceDates: Date[] = [
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
    new Date(new Date().getFullYear(), new Date().getMonth(), 0),
  ];
  invoiceStatusList: Array<SelectItem> = [
    { label: 'Pendente', value: 'PENDING' },
    { label: 'Pago', value: 'PAID' },
    { label: 'Cancelado', value: 'CANCELED' },
  ];
  nfeStatusList: Array<SelectItem> = [
    { label: 'Ambos', value: null },
    { label: 'Sim', value: true },
    { label: 'Não', value: false },
  ];
  dateTypeList: Array<SelectItem> = [
    { label: 'Data de Emissão', value: 'EMISSION' },
    { label: 'Data de Vencimento', value: 'DUE' },
    { label: 'Data de Pagamento', value: 'PAYMENT' },
    { label: 'Data de Competência', value: 'COMPETENCE' },
  ];
  filteredCustomers: SelectLabelValue[] = [];
  customerGroups: { label: string; value: number }[];
  orderProductsList: Product[];
  orderProductFiltered: { label: string; value: Product }[];
  globalFilter: string = '';

  cols = {};
  needBorder: { index: number; color: string }[] = [];
  slingConfig: any;
  wrongCompetenceQty: number = 0;
  totalRecords: any;

  constructor(
    private customerService: CustomerService,
    private productService: ProductService,
    private invoiceService: InvoiceService,
    private spinner: NgxSpinnerService,
    public dialog: DialogService,
    private drawerService: DrawerService,
    private customerGroupService: GroupService,
    private slingConfigService: SlingConfigService,
    private messageUtil: MessageUtil,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    BroadcastService.get('showFilters').subscribe((showFilters) => {
      this.isFilterExpanded = showFilters;
    });
    this.spinner.show();
    this.loadSlingConfig();
    this.getCustomers();
    this.getGroups();
    this.getProducts();
    this.getColumns();
    this.search(false);
  }

  findInvoicingReport(): void {
    this.invoiceService
      .getSuperReport(this.filter, this.paginationFilter)
      .subscribe({
        next: (resp) => {
          this.invoices = [...resp.content];
          this.totalRecords = resp.totalElements;
          this.invoicingReportsTable = resp.content;
          this.filterInvoiceByColor();
          this.spinner.hide();
        },
        error: (err) => {
          this.spinner.hide();
        },
      });
  }

  search(notification: boolean): void {
    if (notification) {
      this.filter.wrongCompetence = true;
    } else {
      this.filter.wrongCompetence = null;
    }
    this.spinner.show();
    this.findInvoicingReport();
  }

  clear(): void {
    this.filter = new InvoicingReportFilter();
    this.filter.report = 'true';
    (this.filter.status = ['PAID', 'PENDING']), (this.globalFilter = '');
    this.rangeEmissionDates = [];
    this.rangeDueDates = [];
    this.rangePaymentDates = [];
    this.rangeCompetenceDates = [];
    this.selectedColors = [];
  }

  changeMoreFilterVisibility(): void {
    this.isMoreFiltersExpanded = !this.isMoreFiltersExpanded;
  }

  filterByNfeNotification() {
    this.search(true);
  }

  filterInvoiceByColor() {
    this.invoicingReportsTable = [];
    this.selectedColors.forEach((color) => {
      switch (color) {
        case 'red':
          this.invoicingReportsTable.push(
            ...this.invoices
              .filter(
                (i) =>
                  i.nfeServiceNumber != null &&
                  i.paymentDate != null &&
                  i.paymentDate > i.nfeServiceEmissionDate
              )
              .map((i) => {
                return i;
              })
          );
          break;
        case 'purple':
          this.invoicingReportsTable.push(
            ...this.invoices
              .filter(
                (i) => i.paymentValue == null && i.nfeServiceNumber != null
              )
              .map((i) => {
                return i;
              })
          );
          break;
        case 'yellow':
          this.invoicingReportsTable.push(
            ...this.invoices
              .filter(
                (i) =>
                  (i.nfeServiceNumber == null &&
                    i.taxableSporadicValue != null &&
                    i.taxableSporadicValue != 0) ||
                  (i.nfeServiceNumber == null &&
                    i.taxablePeriodicValue != null &&
                    i.taxablePeriodicValue != 0)
              )
              .map((i) => {
                return i;
              })
          );
          break;
        case 'green':
          this.invoicingReportsTable.push(
            ...this.invoices
              .filter((i) => i.invoiceValue == 0)
              .map((i) => {
                return i;
              })
          );
          break;
      }
    });
    const sortField = this.paginationFilter.sort;
    const sortOrder = this.paginationFilter.order;
    const comparator = (a, b) => {
      if (sortOrder === 'ASC') {
        return a[sortField] - b[sortField];
      } else {
        return b[sortField] - a[sortField];
      }
    };
    if (this.selectedColors.length == 0)
      this.invoicingReportsTable.push(...this.invoices);
    this.invoicingReportsTable.sort(comparator);
  }

  onChangePage(event: LazyLoadEvent): void {
    if (this.slingConfig) {
      this.spinner.show();
      // const page = event.first / event.rows;
      this.paginationFilter.sort = event.sortField;
      // this.paginationFilter.page = page;
      // this.paginationFilter.size = event.rows;
      this.paginationFilter.order = event.sortOrder === 1 ? 'ASC' : 'DESC';
      this.findInvoicingReport();
    }
  }

  extract(idInvoice): void {
    this.spinner.show();
    this.invoiceService.extract(idInvoice).subscribe(
      (data) => {
        this.openExtract(
          this.sanitizer.bypassSecurityTrustResourceUrl(
            URL.createObjectURL((b64toBlob as any)(data, 'application/pdf'))
          )
        );
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
      () => this.spinner.hide()
    );
  }

  openExtract(path): void {
    this.dialog.open(ExtractInvoiceComponent, {
      width: '100%',
      height: '100%',
      data: { path },
      header: 'Extrato da fatura - PDF',
    });
  }

  exportTable() {
    const data = { table: this.invoices, type: 'SUPER_INVOICING' };
    this.dialog.open(CustomReportExportComponent, {
      width: '70%',
      height: '90vh',
      dismissableMask: false,
      data,
      header: this.messageUtil.translateKey('EXPORT-SUPER-INVOICING-TABLE'),
    });
  }

  openSuperDrawer(): void {
    this.drawerService.getSuperDrawerItems().then((resp) => {
      this.drawerHeaderGroups = resp;
      this.drawerHeaderItems = [];
      this.drawerHeaderGroups.forEach((output: DrawerHeaderGroup) => {
        this.drawerHeaderItems.push(...output.items);
      });
      if (!this.isDrawerExpanded) {
        this.isDrawerExpanded = !this.isDrawerExpanded;
      }
    });
  }

  closeSuperDrawer(): void {
    if (this.isDrawerExpanded) this.isDrawerExpanded = !this.isDrawerExpanded;
    this.getColumns();
  }

  async loadSlingConfig(): Promise<void> {
    return await new Promise<void>(async (resolve) => {
      this.slingConfigService.getSlingConfig().subscribe(async (data) => {
        this.slingConfig = data[0];
        this.paginationFilter.size =
          this.slingConfig != null
            ? this.slingConfig.numberOfRecordsPerPage
            : 100;
        resolve();
      });
    });
  }

  getCustomers(): void {
    this.customerService.getCustomerFilterList().subscribe((data) => {
      this.filteredCustomers = [{ label: 'Todos', value: null }].concat(
        data.map((c) => ({ label: c.name, value: c.id }))
      );
    });
  }

  getGroups(): void {
    this.customerGroupService.findAllActive().subscribe((data) => {
      this.customerGroups = data.map((g) => ({ label: g.name, value: g }));
      this.customerGroups.unshift({ label: 'Sem grupo', value: undefined });
    });
  }

  getProducts(): void {
    this.productService.getAll().subscribe((data) => {
      this.orderProductsList = data;
      this.orderProductFiltered = this.orderProductsList.map((product) => ({
        label: product.name,
        value: product,
      }));
    });
  }

  getColumns() {
    this.drawerService.getSuperDrawerItems().then((resp) => {
      this.drawerHeaderGroups = resp;
      this.drawerHeaderItems = [];
      this.drawerHeaderGroups.forEach((output: DrawerHeaderGroup) => {
        this.drawerHeaderItems.push(...output.items);
      });
      this.drawerHeaderItems.forEach(
        (item: DrawerHeaderItem) => (this.cols[item.name] = item.toggle)
      );
      this.getBorders();
    });
  }

  getBorders() {
    let parent = '';
    let color = 'blue';
    for (let i = 0; i < this.drawerHeaderItems.length; i++) {
      if (this.drawerHeaderItems[i].parent !== parent) {
        parent = this.drawerHeaderItems[i].parent;
        this.needBorder.push({ index: i, color: color });
        if (color === 'blue') {
          color = 'green';
        } else {
          color = 'blue';
        }
      }
    }
  }

  calculateTotals(columnName: string, list: any[]): string {
    let total: number = 0;
    let noTotal = false;
    let isMoney = false;
    if (list == null) list = this.invoicingReportsTable;
    for (let invoice of list) {
      const columnValue = invoice[columnName];
      if (columnValue != null) {
        switch (columnName) {
          case 'invoiceCompetenceDate':
          case 'invoiceDueDate':
          case 'invoiceEmissionDate':
          case 'customerFederalId':
          case 'customerGroup':
          case 'customerName':
          case 'paymentDate':
          case 'paymentResponsibleEmployee':
          case 'nfeProductEmissionDate':
          case 'nfeServiceEmissionDate':
          case 'nfeServiceCompetenceDate':
          case 'taxablePeriodicDueDateDiscountPercentage':
          case 'receiptPeriodicDueDateDiscountPercentage':
          case 'taxableSporadicDueDateDiscountPercentage':
          case 'receiptSporadicDueDateDiscountPercentage':
          case 'taxablePeriodicPlanoConta':
          case 'receiptPeriodicPlanoConta':
          case 'taxableSporadicPlanoConta':
          case 'receiptSporadicPlanoConta':
          case 'periodicDescription':
            noTotal = true;
            break;
          case 'taxablePeriodicValidation':
            noTotal = true;
            if (columnValue != 30 && columnValue != 0 && columnValue != null) {
              this.isAlert = true;
              console.log('30 Validation', columnValue);
            }
            break;
          case 'receiptPeriodicValidation':
            noTotal = true;
            if (columnValue != 70 && columnValue != 0 && columnValue != null) {
              this.isAlert = true;
              console.log('70 Validation', columnValue);
            }
            break;
          case 'invoiceNumber':
          case 'nfeServiceNumber':
          case 'nfeProductNumber':
            total++;
            break;
          case 'taxablePeriodicDescription':
          case 'receiptPeriodicDescription':
          case 'taxableSporadicDescription':
          case 'receiptSporadicDescription':
          case 'taxablePeriodicClientDescription':
          case 'receiptPeriodicClientDescription':
          case 'taxableSporadicClientDescription':
          case 'receiptSporadicClientDescription':
            total++;
            break;
          default:
            if (typeof columnValue === 'number') {
              isMoney = true;
              total += columnValue;
              break;
            } else {
              total = 0;
              break;
            }
        }
      }
    }
    if (noTotal) {
      return null;
    }
    if (isMoney) {
      return this.formatNumber(total);
    }
    return total.toString();
  }

  formatNumber(number: number): string {
    return number.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  getTableSize() {
    let innerHeight = window.innerHeight;
    if (this.isFilterExpanded) {
      if (this.isMoreFiltersExpanded) {
        if (this.wrongCompetenceQty === 0) {
          return innerHeight - 530 + 'px';
        } else {
          return innerHeight - 605 + 'px';
        }
      } else {
        if (this.wrongCompetenceQty === 0) {
          return innerHeight - 440 + 'px';
        } else {
          return innerHeight - 515 + 'px';
        }
      }
    } else {
      return innerHeight - 205 + 'px';
    }
  }

  getSpaces(output: DrawerHeaderGroup) {
    if (output.activeItems === 0) return [];
    return Array.from({ length: output.activeItems - 1 }, (_, index) => index);
  }

  filterGlobal(): void {
    this.invoicingReportsTable = this.invoices
      .filter(
        (i) =>
          (i.nfeServiceNumber &&
            i.nfeServiceNumber.toString().includes(this.globalFilter)) ||
          (i.invoiceNumber &&
            i.invoiceNumber.toString().includes(this.globalFilter)) ||
          FormatUtil.getNotAccents(i.customerName)
            .toUpperCase()
            .includes(
              FormatUtil.getNotAccents(this.globalFilter).toUpperCase()
            ) ||
          i.customerName
            .toUpperCase()
            .includes(this.globalFilter.toUpperCase()) ||
          (i.customerGroup &&
            (FormatUtil.getNotAccents(i.customerGroup)
              .toUpperCase()
              .includes(
                FormatUtil.getNotAccents(this.globalFilter).toUpperCase()
              ) ||
              i.customerGroup
                .toUpperCase()
                .includes(this.globalFilter.toUpperCase())))
      )
      .map((i) => {
        return i;
      });
  }

  onRangeChange(dateType: String): void {
    switch (dateType) {
      case 'EMISSION':
        if (this.rangeEmissionDates && this.rangeEmissionDates.length > 0) {
          if (this.rangeEmissionDates[0] != null) {
            this.filter.startEmission = this.rangeEmissionDates[0];
          }
          if (this.rangeEmissionDates[1] != null) {
            this.filter.endEmission = this.rangeEmissionDates[1];
          }
        } else {
          this.filter.startEmission = null;
          this.filter.endEmission = null;
        }
        break;
      case 'DUE':
        if (this.rangeDueDates && this.rangeDueDates.length > 0) {
          if (this.rangeDueDates[0] != null) {
            this.filter.startDueDate = this.rangeDueDates[0];
          }
          if (this.rangeDueDates[1] != null) {
            this.filter.endDueDate = this.rangeDueDates[1];
          }
        } else {
          this.filter.startDueDate = null;
          this.filter.endDueDate = null;
        }

        break;
      case 'PAYMENT':
        if (this.rangePaymentDates && this.rangePaymentDates.length > 0) {
          if (this.rangePaymentDates[0] != null) {
            this.filter.startPayment = this.rangePaymentDates[0];
          }
          if (this.rangePaymentDates[1] != null) {
            this.filter.endPayment = this.rangePaymentDates[1];
          }
        } else {
          this.filter.startPayment = null;
          this.filter.endPayment = null;
        }
        break;
      case 'COMPETENCE':
        if (this.rangeCompetenceDates && this.rangeCompetenceDates.length > 0) {
          if (this.rangeCompetenceDates[0] != null) {
            this.filter.startCompetenceDate = this.rangeCompetenceDates[0];
          }
          if (this.rangeCompetenceDates[1] != null) {
            this.filter.endCompetenceDate = this.rangeCompetenceDates[1];
          }
        } else {
          this.filter.startCompetenceDate = null;
          this.filter.endCompetenceDate = null;
        }
        break;
    }
  }

  getBlueSideBorderClass(index: number): boolean {
    if (
      this.needBorder.some(
        (item) => item.index == index && item.color == 'blue'
      )
    ) {
      return true;
    }
  }

  getGreenSideBorderClass(index: number): boolean {
    if (
      this.needBorder.some(
        (item) => item.index == index && item.color == 'green'
      )
    ) {
      return true;
    }
  }

  isZeroValue(invoiceReport) {
    if (invoiceReport.invoiceValue == 0) {
      return true;
    }
    return false;
  }

  isNotPayed(invoiceReport) {
    // "NF Serviço" gerada sem o pagamento ter sido realizado
    if (
      invoiceReport.paymentValue == null &&
      invoiceReport.nfeServiceNumber != null
    ) {
      return true;
    }
    return false;
  }

  isGeneratedNFE(invoiceReport) {
    // "NF Serviço" com fundo amarelo no caso de não ter nota emitida para alguma parte (30%/Avulso)
    if (
      invoiceReport.taxablePeriodicValue != null &&
      invoiceReport.taxablePeriodicValue != 0 &&
      invoiceReport.nfeServiceValue == null
    ) {
      return true;
    }
    if (
      invoiceReport.taxableSporadicValue != null &&
      invoiceReport.taxableSporadicValue != 0 &&
      invoiceReport.nfeServiceValue == null
    ) {
      return true;
    }
    return false;
  }

  isGeneratedPeriodic(invoiceReport) {
    // Coluna "30% (NF)" com fundo amarelo quando houver valor para gerar NF e não tiver sido gerada
    if (
      invoiceReport.nfeServiceNumber == null &&
      invoiceReport.taxablePeriodicValue != null &&
      invoiceReport.taxablePeriodicValue != 0
    ) {
      return true;
    }
    return false;
  }

  isGeneratedSporadic(invoiceReport) {
    // Coluna "Avulso (NF)" com fundo amarelo quando houver valor para gerar NF e não tiver sido gerada
    if (
      invoiceReport.nfeServiceNumber == null &&
      invoiceReport.taxableSporadicValue != null &&
      invoiceReport.taxableSporadicValue != 0
    ) {
      return true;
    }
    return false;
  }

  isPriorToPaymentNFE(invoiceReport) {
    // Coluna "NF Serviço" com fundo vermelho quando houver data de pagamento posterior a NF gerada.
    if (
      invoiceReport.nfeServiceNumber != null &&
      invoiceReport.paymentDate != null &&
      invoiceReport.paymentDate > invoiceReport.nfeServiceEmissionDate
    ) {
      return true;
    }
    return false;
  }

  async exportTableFixedColumns() {
    import('xlsx').then((xlsx) => {
      // Cria entidades necessárias
      let combinedWorksheet = {};
      let currentRowIndex = 0;
      function copyWorksheetToCombined(
        sourceWorksheet,
        headers,
        startRowIndex
      ) {
        let range = xlsx.utils.decode_range(sourceWorksheet['!ref']);
        // Copia os cabeçalhos
        for (let i = 0; i < headers.length; i++) {
          //mantem o label e valor das celula
          combinedWorksheet[
            xlsx.utils.encode_cell({ r: startRowIndex, c: i })
          ] = sourceWorksheet[xlsx.utils.encode_cell({ r: 0, c: i })];
        }
        startRowIndex++;

        // Copia os dados
        for (let rowIndex = range.s.r + 1; rowIndex <= range.e.r; rowIndex++) {
          for (let colIndex = range.s.c; colIndex <= range.e.c; colIndex++) {
            let cellAddress = xlsx.utils.encode_cell({
              r: rowIndex,
              c: colIndex,
            });
            let newCellAddress = xlsx.utils.encode_cell({
              r: startRowIndex,
              c: colIndex,
            });
            combinedWorksheet[newCellAddress] = sourceWorksheet[cellAddress];
          }
          startRowIndex++;
        }

        return startRowIndex;
      }

      function changeHeaderNames(columnIndex, worksheet, drawerHeaderItems) {
        // Modifica os nomes das colunas e os cabeçalhos conforme os itens do drawer
        drawerHeaderItems.forEach((item) => {
          for (let i = 0; i < columnIndex + 1; i++) {
            const cellAddress = xlsx.utils.encode_cell({ c: i, r: 0 });
            const cell = worksheet[cellAddress];
            if (cell && item.name === cell.v) {
              switch (cell.v) {
                case 'invoiceNumber':
                  cell.v = 'Fatura';
                  break;
                case 'nfeServiceNumber':
                  cell.v = 'Nota';
                  break;
                case 'taxablePeriodicValue':
                  cell.v = 'Valor da Nota';
                  break;
                case 'receiptPeriodicValue':
                  cell.v = 'Valor Recido';
                  break;
                case 'paymentValue':
                  cell.v = 'Valor Total';
                  break;
                default:
                  cell.v = item.label;
                  break;
              }
              worksheet[cellAddress].v = cell.v;
            }
          }
        });
        return worksheet;
      }

      // Adapta os dados das faturas e filtra elementos indefinidos
      let exportData1 = this.adaptData(this.invoices, 'periodic-boats').filter(
        (element) => element !== undefined
      );
      let exportData2 = this.adaptData(this.invoices, 'detached').filter(
        (element) => element !== undefined
      );
      let exportData3 = this.adaptData(
        this.invoices,
        'periodic-clients'
      ).filter((element) => element !== undefined);

      // Imprime a tabela
      console.log('worksheet1', exportData1);

      exportData1.sort((a, b) => a.invoiceNumber - b.invoiceNumber);
      exportData2.sort((a, b) => a.invoiceNumber - b.invoiceNumber);
      exportData3.sort((a, b) => a.invoiceNumber - b.invoiceNumber);

      // Extrai os cabeçalhos das colunas dos dados exportados
      let headers1 = Object.keys(exportData1[0]);
      let headers2 = Object.keys(exportData2[0]);
      let headers3 = Object.keys(exportData3[0]);

      // Calcula os totais para cada coluna
      let lastRow1 = {};
      let lastRow2 = {};
      let lastRow3 = {};
      headers1.forEach((header) => {
        lastRow1[header] = this.calculateTotals(header, exportData1);
        if (!lastRow1[header]) {
          lastRow1[header] = '';
        }
      });
      headers2.forEach((header) => {
        lastRow2[header] = this.calculateTotals(header, exportData2);
        if (!lastRow2[header]) {
          lastRow2[header] = '';
        }
      });
      headers3.forEach((header) => {
        lastRow3[header] = this.calculateTotals(header, exportData3);
        if (!lastRow3[header]) {
          lastRow3[header] = '';
        }
      });

      // Converte os dados JSON para uma planilha
      let worksheet1 = xlsx.utils.json_to_sheet(exportData1);
      let worksheet2 = xlsx.utils.json_to_sheet(exportData2);
      let worksheet3 = xlsx.utils.json_to_sheet(exportData3);

      // Adiciona um filtro automático visível apenas no excel na linha dos cabeçalhos
      worksheet1['!autofilter'] = {
        ref: xlsx.utils.encode_range({
          s: { r: 0, c: 0 },
          e: { r: 0, c: headers1.length - 1 },
        }),
      };
      worksheet2['!autofilter'] = {
        ref: xlsx.utils.encode_range({
          s: { r: 0, c: 0 },
          e: { r: 0, c: headers2.length - 1 },
        }),
      };
      worksheet3['!autofilter'] = {
        ref: xlsx.utils.encode_range({
          s: { r: 0, c: 0 },
          e: { r: 0, c: headers3.length - 1 },
        }),
      };

      // Decodifica o intervalo de células e obtém o índice da última coluna
      const range1 = xlsx.utils.decode_range(worksheet1['!ref']);
      const lastColumnIndex = range1.e.c;
      const range2 = xlsx.utils.decode_range(worksheet2['!ref']);
      const lastColumnIndex2 = range2.e.c;
      const range3 = xlsx.utils.decode_range(worksheet3['!ref']);
      const lastColumnIndex3 = range3.e.c;

      // Modifica os nomes das colunas e os cabeçalhos conforme os itens do drawer
      worksheet1 = changeHeaderNames(
        lastColumnIndex,
        worksheet1,
        this.drawerHeaderItems
      );

      worksheet2 = changeHeaderNames(
        lastColumnIndex2,
        worksheet2,
        this.drawerHeaderItems
      );

      worksheet3 = changeHeaderNames(
        lastColumnIndex3,
        worksheet3,
        this.drawerHeaderItems
      );

      // Adiciona a última linha com os totais calculados
      let lastRowIndex1 = exportData1.length + 3;
      let lastRowIndex2 = exportData2.length + 3;
      let lastRowIndex3 = exportData3.length + 3;

      for (let i = 0; i < headers1.length; i++) {
        worksheet1[xlsx.utils.encode_cell({ r: lastRowIndex1 - 2, c: i })] = {
          v: lastRow1[headers1[i]],
          t: 's',
        };
      }
      worksheet1['!ref'] = xlsx.utils.encode_range({
        s: { r: 0, c: 0 },
        e: { r: lastRowIndex1, c: lastColumnIndex },
      });
      for (let i = 0; i < headers2.length; i++) {
        worksheet2[xlsx.utils.encode_cell({ r: lastRowIndex2 - 2, c: i })] = {
          v: lastRow2[headers2[i]],
          t: 's',
        };
      }
      worksheet2['!ref'] = xlsx.utils.encode_range({
        s: { r: 0, c: 0 },
        e: { r: lastRowIndex2, c: lastColumnIndex2 },
      });
      for (let i = 0; i < headers3.length; i++) {
        worksheet3[xlsx.utils.encode_cell({ r: lastRowIndex3 - 2, c: i })] = {
          v: lastRow3[headers3[i]],
          t: 's',
        };
      }
      worksheet3['!ref'] = xlsx.utils.encode_range({
        s: { r: 0, c: 0 },
        e: { r: lastRowIndex3, c: lastColumnIndex3 },
      });

      currentRowIndex = copyWorksheetToCombined(
        worksheet1,
        headers1,
        currentRowIndex
      );
      currentRowIndex++;
      currentRowIndex = copyWorksheetToCombined(
        worksheet2,
        headers2,
        currentRowIndex
      );
      currentRowIndex++;
      currentRowIndex = copyWorksheetToCombined(
        worksheet3,
        headers3,
        currentRowIndex
      );

      combinedWorksheet['!ref'] = xlsx.utils.encode_range({
        s: { r: 0, c: 0 },
        e: {
          r: currentRowIndex - 1,
          c: Math.max(headers1.length, headers2.length, headers3.length) - 1,
        },
      });

      // Cria o workbook e converte para um buffer de Excel
      const workbook = {
        Sheets: { data: combinedWorksheet },
        SheetNames: ['data'],
      };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, 'faturas');
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + '_' + new Date().getTime() + EXCEL_EXTENSION
    );
  }

  adaptData(data: any, type: string): any {
    let newData = data.map((invoice) => {
      const newInvoice = { ...invoice };
      for (let prop in invoice) {
        if (!this.cols[prop]) {
          delete newInvoice[prop];
        }
        if (
          prop == 'invoiceEmissionDate' ||
          prop == 'invoiceCompetenceDate' ||
          prop == 'invoiceDueDate' ||
          prop == 'paymentDate' ||
          prop == 'nfeServiceEmissionDate' ||
          prop == 'nfeServiceCompetenceDate' ||
          prop == 'nfeProductEmissionDate' ||
          prop == 'nfeProductCompetenceDate'
        ) {
          if (prop == 'invoiceCompetenceDate') console.log('prop', newInvoice);
          newInvoice[prop] = newInvoice[prop]
            ? new Date(new Date(invoice[prop]).getTime() + 86400000)
            : null;
        }
        if (
          prop == 'nfeProductNumber' ||
          prop == 'invoiceValue' ||
          prop == 'nfeServiceEmissionDate' ||
          prop == 'taxableSporadicQuantity' ||
          prop == 'receiptSporadicQuantity' ||
          prop == 'taxablePeriodicValidation' ||
          prop == 'taxablePeriodicClientQuantity' ||
          prop == 'receiptPeriodicClientQuantity'
        ) {
          delete newInvoice[prop];
        }
      }
      switch (type) {
        case 'periodic-boats':
          if (
            newInvoice.taxablePeriodicQuantity != null &&
            newInvoice.receiptPeriodicQuantity != null
          ) {
            let reorderedInvoice = {
              invoiceDueDate: newInvoice.invoiceDueDate,
              invoiceCompetenceDate: newInvoice.nfeServiceCompetenceDate,
              paymentDate: newInvoice.paymentDate,
              customerName: newInvoice.customerName,
              customerFederalId: newInvoice.customerFederalId,
              invoiceNumber: newInvoice.invoiceNumber,
              nfeServiceNumber: newInvoice.nfeServiceNumber,
              taxablePeriodicValue:
                newInvoice.paymentDate != null &&
                newInvoice.paymentInterestAndFine == null &&
                (newInvoice.paymentDiscount != null ||
                  newInvoice.paymentDueDateDiscount != null)
                  ? newInvoice.taxablePeriodicValue -
                    newInvoice.taxablePeriodicDueDateDiscount
                  : newInvoice.taxablePeriodicValue,
              receiptPeriodicValue:
                newInvoice.paymentInterestAndFine == null
                  ? newInvoice.receiptPeriodicValue -
                    newInvoice.receiptPeriodicDueDateDiscount
                  : newInvoice.receiptPeriodicValue +
                    newInvoice.paymentInterestAndFine,
              paymentValue:
                (newInvoice.paymentDate != null &&
                newInvoice.paymentInterestAndFine == null &&
                (newInvoice.paymentDiscount != null ||
                  newInvoice.paymentDueDateDiscount != null)
                  ? newInvoice.taxablePeriodicValue -
                    newInvoice.taxablePeriodicDueDateDiscount
                  : newInvoice.taxablePeriodicValue +
                    newInvoice.paymentInterestAndFine) +
                newInvoice.receiptPeriodicValue -
                (newInvoice.paymentInterestAndFine == null
                  ? newInvoice.receiptPeriodicDueDateDiscount
                  : 0),
              periodicDescription: newInvoice.periodicDescription,
            };
            return reorderedInvoice;
          }
          if (this.isProRataOrMultaRecisoria(newInvoice)) {
            let reorderedInvoice = {
              invoiceDueDate: newInvoice.invoiceDueDate,
              invoiceCompetenceDate: newInvoice.nfeServiceCompetenceDate,
              paymentDate: newInvoice.paymentDate,
              customerName: newInvoice.customerName,
              customerFederalId: newInvoice.customerFederalId,
              invoiceNumber: newInvoice.invoiceNumber,
              nfeServiceNumber: newInvoice.nfeServiceNumber,
              taxablePeriodicValue: newInvoice.taxableSporadicValue,
              receiptPeriodicValue: newInvoice.receiptSporadicValue,
              paymentValue:
                newInvoice.taxableSporadicValue +
                newInvoice.receiptSporadicValue,
              periodicDescription:
                newInvoice.receiptSporadicDescription != null
                  ? newInvoice.receiptSporadicDescription
                  : newInvoice.taxableSporadicDescription,
            };
            return reorderedInvoice;
          }
          break;
        case 'detached':
          if (
            newInvoice.receiptSporadicValue == null &&
            newInvoice.taxableSporadicValue != null
          ) {
            let reorderedInvoice = {
              invoiceDueDate: newInvoice.invoiceDueDate,
              invoiceCompetenceDate: newInvoice.nfeServiceCompetenceDate,
              paymentDate: newInvoice.paymentDate,
              customerName: newInvoice.customerName,
              customerFederalId: newInvoice.customerFederalId,
              invoiceNumber: newInvoice.invoiceNumber,
              nfeServiceNumber: newInvoice.nfeServiceNumber,
              taxablePeriodicValue: newInvoice.taxableSporadicValue,
              receiptPeriodicValue: newInvoice.receiptSporadicValue,
              paymentValue: newInvoice.taxableSporadicValue,
              periodicDescription: newInvoice.taxableSporadicDescription,
            };
            return reorderedInvoice;
          }
          break;
        case 'periodic-clients':
          if (
            (newInvoice.receiptPeriodicClientValue != null ||
              newInvoice.receiptSporadicValue != null) &&
            newInvoice.taxablePeriodicClientValue == null &&
            !this.isProRataOrMultaRecisoria(newInvoice)
          ) {
            let reorderedInvoice = {
              invoiceDueDate: newInvoice.invoiceDueDate,
              invoiceCompetenceDate: newInvoice.nfeServiceCompetenceDate,
              paymentDate: newInvoice.paymentDate,
              customerName: newInvoice.customerName,
              customerFederalId: newInvoice.customerFederalId,
              invoiceNumber: newInvoice.invoiceNumber,
              nfeServiceNumber: null,
              taxablePeriodicValue: 0,
              receiptPeriodicValue:
                newInvoice.receiptPeriodicClientValue +
                newInvoice.receiptSporadicValue -
                newInvoice.receiptPeriodicClientDueDateDiscount,
              paymentValue:
                newInvoice.receiptPeriodicClientValue +
                newInvoice.receiptSporadicValue -
                newInvoice.receiptPeriodicClientDueDateDiscount,
              periodicDescription:
                newInvoice.receiptSporadicDescription != null
                  ? newInvoice.receiptSporadicDescription
                  : newInvoice.receiptPeriodicClientDescription,
            };
            return reorderedInvoice;
          }
          break;
      }
    });
    return newData;
  }

  isProRataOrMultaRecisoria(newInvoice: any): boolean {
    const description = newInvoice.taxableSporadicDescription;

    if (!description) {
      return false;
    }

    const isMulta = description.includes('Multa Rescisória');
    const isProRata = description.includes('Pro Rata');

    return isMulta || isProRata;
  }
}
