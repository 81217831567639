import { Movement } from './../../../../models/movement/movement';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SlingConfig } from 'src/app/models';
import { MovementPlanByObjectiveCreateDTO } from 'src/app/models/dtos/movements/movement-plan-by-objective-createDTO';
import { OperationalConfigService } from 'src/app/services/config/operational-config.service';
import { getCustomValidatorsMovementNavigationPlan } from 'src/app/validators/movement/movement-navigation-plan';

interface MovementNavigationPlanOptions {
  hasMovementPlan: boolean;
  scheduleTimeWhenFirst: boolean;
}

@Component({
  selector: 'app-movement-navigation-plan',
  templateUrl: './movement-navigation-plan.component.html',
  styleUrls: ['./movement-navigation-plan.component.scss']
})
export class MovementNavigationPlanComponent implements OnInit, OnChanges {

  @Input()
  readonly isNew: boolean;

  @Input()
  readonly movementPlan: MovementPlanByObjectiveCreateDTO;

  @Input()
  readonly hasNavigationPlan: boolean = true;

  @Input()
  readonly slingConfig: SlingConfig;

  @Output()
  finishEvent: EventEmitter<any> = new EventEmitter();
  @Output()
  backPageEvent: EventEmitter<void> = new EventEmitter();
  @Output()
  cancelPageEvent: EventEmitter<void> = new EventEmitter();

  totalPassagers: number = 0;

  frequentDestinations: { label: string, value: string }[] = [];

  public form: UntypedFormGroup;
  hasChild: boolean = true;
  hasMovementPlan: boolean = undefined;
  minDateToExecutionDate: Date;
  constructor(
    private operationalConfigService: OperationalConfigService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasNavigationPlan && changes.hasNavigationPlan.previousValue !== changes.hasNavigationPlan.currentValue) {
      if (this.hasMovementPlan !== changes.hasNavigationPlan.currentValue || !this.form) {
        this.minDateToExecutionDate = this.isNew ? new Date() : new Date(this.movementPlan.executionDate);
        if (this.movementPlan?.objectiveCode === 'SINGLE'){
          this.form = new UntypedFormGroup({
            executionDate: new UntypedFormControl(this.isNew? null : this.movementPlan?.executionDate, [ Validators.required ]),
          }, [...getCustomValidatorsMovementNavigationPlan()]);
        }else{
          this.form = new UntypedFormGroup({
            executionDate: new UntypedFormControl(this.isNew? null : this.movementPlan?.executionDate, [ Validators.required ]),
            returnTime: new UntypedFormControl(this.isNew? null : this.movementPlan?.returnTime, [ Validators.required ])
          }, [...getCustomValidatorsMovementNavigationPlan()]);
        }
        if (this.hasNavigationPlan) {
          this.form.addControl('navigationPlan',
            new UntypedFormGroup({
              crewQty: new UntypedFormControl(
                this.isNew ? null : this.movementPlan?.navigationPlan.crewQty,
                [ Validators.required, Validators.min(1) ]
              ),
              childQty: new UntypedFormControl(
                this.isNew ? null : this.movementPlan?.navigationPlan.childQty,
                [ Validators.required, Validators.min(0) ]
              ),
              destination: new UntypedFormControl(
                this.isNew ? null : this.movementPlan?.navigationPlan.destination,
                [ Validators.required ]
              )
            })
          );
        }
      }
    }
    if (changes.movementPlan && changes.movementPlan.previousValue !== changes.movementPlan.currentValue) {
        if (changes.movementPlan.currentValue.boat) {
          this.totalPassagers = this.movementPlan.boat.crew + this.movementPlan.boat.passengersDay;
        }
    }

    if (this.form ==null){
      this.form = new UntypedFormGroup({
        observation: new UntypedFormControl(this.isNew? null : this.movementPlan?.observation),
      });
    }else{
      this.form.addControl('observation', new UntypedFormControl(this.isNew? null : this.movementPlan.observation));
      }
  }

  async ngOnInit(): Promise<void> {

    if (this.slingConfig && this.slingConfig.frequentFestinations) {
      this.slingConfig.frequentFestinations.map((fd) => this.frequentDestinations.push({label: fd.name, value: fd.name}));
    }
  }

  next() {
    console.log(this.form.getRawValue());
    const { executionDate, returnTime, navigationPlan, observation } = this.form.getRawValue();
    this.finishEvent.emit({ executionDate, returnTime, navigationPlan: navigationPlan ? navigationPlan : null, observation });
  }

  back() {
    this.backPageEvent.emit();
  }

  cancel() {
    this.cancelPageEvent.emit();
  }

  isAlreadyStarted(): boolean {
    return false;
  }

  getReturnMinDate(): Date {
    return this.form.value?.executionDate
  }

  getFirstEdit():boolean{
    if (!this.movementPlan?.movementPrevision){
      return true;
    }
    let movements : Movement[] = this.movementPlan.movementPrevision;
    if (movements.length==0){
      return false}
    movements.sort((a, b) => (a.id < b.id) ? -1 : 1);
    if (movements[0].movementStatus==='AWAITING_PREPARATION' || this.isNew){
      return true;
    }
    return false;
  }

  getLastEdit():boolean{
    if (!this.movementPlan?.movementPrevision){
      return true;
    }
    let movements : Movement[] = this.movementPlan.movementPrevision.filter((m)=>m.originLocationType==="NAVIGATION" || m.originNodeCode==="MAINTENANCE_YARD");
    if (movements.length==0){
      return true}
    movements.sort((a, b) => (a.id < b.id) ? 1 : -1);
    if (movements[0].movementStatus==='AWAITING_PREPARATION' || this.isNew){
      return true;
    }
    return false;
  }
}
