<p-fieldset>
  <p-header class="article-title">
    <span class="article-title"
      >Faturamento > Gestão de Notas de Produtos > Importar Nota Fiscal</span
    >
  </p-header>

  <div class="filter-row" *ngIf="uploadReady">
    <div class="form-control">
      <div class="buttons">
        <p-fileUpload
          chooseLabel="Importar arquivo XML da Nota Fiscal"
          mode="basic"
          name="demo[]"
          accept=".xml,.txt"
          (uploadHandler)="setFile($event.files)"
          [auto]="true"
          customUpload="true"
          #upload
        ></p-fileUpload>
      </div>
    </div>
  </div>

  <div class="filter-row dialog-form" *ngIf="!uploadReady">
    <p-fieldset>
      <p-header class="article-title">
        <span class="article-title">Dados da Nota</span>
      </p-header>

      <div class="filter-row d-flex" style="display: flex">
        <div class="form-control">
          <label>Nº Nota</label>
          <input
            [ngModelOptions]="{ standalone: true }"
            type="text"
            pInputText
            [(ngModel)]="arquivoProcessado.nfeNumero"
            readonly
          />
        </div>
        <div class="form-control">
          <label>Série</label>
          <input
            [ngModelOptions]="{ standalone: true }"
            type="text"
            pInputText
            [(ngModel)]="arquivoProcessado.serie"
            readonly
          />
        </div>
        <div class="form-control">
          <label>Data Emissão</label>
          <input
            [ngModelOptions]="{ standalone: true }"
            type="text"
            pInputText
            [(ngModel)]="arquivoProcessado.createDate"
            readonly
          />
             <label
             
            style="margin-top: 8px;"
             >{{ "TYPE" | translate }} de Movimentação</label>
            <p-dropdown
              placeholder="Selecione..."
              [filter]="true"
              [options]="stockMovementTypesList"
              optionValue="id"
              optionLabel="name"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="arquivoProcessado.stockMovementType.id"
              name="stockMovementType"
              appendTo="body">
            </p-dropdown>
        </div>
        <div class="form-control">
          <label
            style="margin-bottom: 8px;"
          >Fornecedor na Nota</label>
            <span>
              <input
                [ngStyle]="{'margin': !validateFornecedor() ? '0px 4px 0px 0px;' : '0px;', 'width': !validateFornecedor() ? 'calc(100% - 30px)' : '100%'}"
                type="text"
                [(ngModel)]="arquivoProcessado.supplierName"
                [ngModelOptions]="{ standalone: true }"
                pInputText
                readonly
              />
              <button style="width: 30px; height: 30px; align-content: center;" class="fab-button primary" *ngIf="!validateFornecedor()" (click)="createNewFornecedor()" class="primary" type="button">
                <i class="pi pi-plus"></i>
              </button>
            </span>
          </div>
        <div class="form-control">
          <label for="supplier">Fornecedor no Sistema</label>
          <p-dropdown
            placeholder="Selecione..."
            [filter]="true"
            [options]="supplierList"
            optionValue="value"
            optionLabel="label"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="arquivoProcessado.supplierId"

            name="supplier"
            appendTo="body"
          >
          </p-dropdown>
          <!-- (onChange)="selectedPaymentMethods($event.value)"  -->
        </div>

        <div class="form-control" *ngIf="financialMultipleCompanyOptions?.length > 1">
          <label>{{ 'FINANCIAL-COMPANY' | translate }}:</label>
          <p-dropdown [options]="loadFinancialMultipleCompany()" appendTo="body"
            placeholder="Selecione..."
            [(ngModel)]="financialCompany"
            name="financialCompany">
          </p-dropdown>
        </div>

        <div class="form-control">
          <label>Status de Importação</label>
          <input
            pInputText
            type="text"
            pInputText
            [(ngModel)]="arquivoProcessado.nfeStatus"
            readonly
          />
        </div>
      </div>
    </p-fieldset>
  </div>

  <div class="filter-row dialog-form" *ngIf="!uploadReady">
    <p-fieldset>
      <p-header class="article-title">
        <span class="article-title">Itens da Nota</span>
      </p-header>

      <div class="table" style="padding: 1rem">
        <p-table
          dataKey="id"
          [value]="arquivoProcessado.items"
          autoLayout="true"
          [alwaysShowPaginator]="false"
          [rows]="999999"
          [totalRecords]="totalRegister"
          [paginator]="false"
          [sortField]="'nItem'"
          [sortOrder]="1"
          #table
          [(selection)]="selectedItensList"
          styleClass="easy-table"
        >
          <ng-template pTemplate="caption">
            <div class="p-caption">
              <span class="total-registers"
                >{{ "TOTAL-REGISTERS" | translate }}: {{ totalRegister }}
              </span>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th class="text-center">
                <!-- <input type="checkbox" class="primeCheckbox" /> -->
                Conferido?
              </th>
              <th class="text-center">Item</th>
              <th class="text-center">Código</th>
              <th class="text-center">Descrição na Nota</th>
              <th class="text-center">Produto relacionado</th>
              <th class="text-center">Quantidade</th>
              <th class="text-center">Valor Unitário</th>
              <th class="text-center">Valor Total</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-itemNf>
            <tr>
              <td class="text-center">
                <input
                  type="checkbox"
                  class="primeCheckbox"
                  [(ngModel)]="itemNf.aproved"

                />
                       <!-- [checked]="isSelected(itemNf)"
                  (click)="selectInvoiceTax(itemNf)" -->
              </td>
              <td class="text-center">
                <span>{{ itemNf.nItem }}</span>
              </td>
              <td class="text-center">
                <span>{{ itemNf.productNfId }}</span>
              </td>
              <td class="text-center">
                <span>{{ itemNf.productNfName }}</span>
              </td>
              <td class="text-center">
                <p-dropdown
                  placeholder="Selecione..."
                  [filter]="true"
                  [options]="products"
                  [ngModelOptions]="{ standalone: true }"
                  [(ngModel)]="itemNf.product.id"
                  name="products"
                  appendTo="body"
                >
                </p-dropdown>
              </td>
              <td class="text-center">
                <span>{{ itemNf.productNfAmount | number : "1.2-2" }}</span>
              </td>
              <td class="text-center">
                <span>{{ itemNf.productNfValueUnit | number : "1.2-2" }}</span>
              </td>
              <td class="text-center">
                <span>{{ itemNf.productNfValue  | number : "1.2-2"}}</span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
      <div class="filter-row d-flex" style="display: flex">
        <div class="buttons">
          <div class="form-button">
            <button  class="easymarine danger" type="button" (click)="clear()">
              {{ "CLEAR" | translate }}
            </button>
          </div>
          <div class="form-button">
            <button class="easymarine primary" type="button" [disabled]="!validToImport()" (click)="endImport()">
              Finalizar Importação
            </button>
          </div>
          <div class="form-button">
            <button class="easymarine primary" type="button" [disabled]="hasProgramacao" (click)="createProgramacao()">
              Criar Programações
            </button>
          </div>
          <div class="form-button">
            <button class="easymarine success" *ngIf="arquivoProcessado!='Concluido'" type="button" (click)="save()">
              {{ "SAVE" | translate }}
            </button>
          </div>
        </div>
      </div>
    </p-fieldset>
  </div>
</p-fieldset>
