<div [ngSwitch]="activeIndex" >
    <div class="top">
        <app-process-element [activeIndex]="activeIndex" [stepIndex]="0" title="Cadastro"></app-process-element>
        <app-process-element [activeIndex]="activeIndex" [stepIndex]="1" title="Método de Pagamento"></app-process-element>
        <app-process-element *ngIf="!showPaymentOptions" [activeIndex]="activeIndex" [stepIndex]="2" title="Finalizado" isLast="true"></app-process-element>
        <app-process-element *ngIf="showPaymentOptions && !isNixPix && !isSplitBill && !isCanceled" [activeIndex]="activeIndex" [stepIndex]="2" title="Pagamento Realizado" isLast="true"></app-process-element>
        <app-process-element *ngIf="showPaymentOptions && isNixPix || showPaymentOptions && isSplitBill" [activeIndex]="activeIndex" [stepIndex]="2" [isError]="isError" title="Realize o Pagamento"></app-process-element>
        <app-process-element *ngIf="showPaymentOptions && (isNixPix || isSplitBill && !isError)" [activeIndex]="activeIndex" [stepIndex]="3" title="Finalizado" isLast="true"></app-process-element>
        <app-process-element *ngIf="invoice != null && isCanceled && !isError" [activeIndex]="activeIndex" [stepIndex]="2" title="Venda Cancelada" isLast="true"></app-process-element>
        <app-process-element *ngIf="invoice != null && isCanceled && isError" [activeIndex]="activeIndex" [stepIndex]="3" title="Venda Cancelada" isLast="true"></app-process-element>
    </div>

    <div *ngSwitchCase="0">
        <p-tabView (onChange)="changeSelectForm()">
            <p-tabPanel>
                <ng-template pTemplate="header">
                    &nbsp;Seleção de Cadastro&nbsp;
                </ng-template>
                <ng-template pTemplate="content">
                    <app-boat-customer-select *ngIf="!showNewCad"
                        (onSelected)="selectCustomer($event)"
                        [boatSingleOrderParams]="boatSingleOrderParams">
                    </app-boat-customer-select>
                    <br>
                    <br>
                    <br>
                    <br>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel >
                <ng-template pTemplate="header">
                    &nbsp;Cadastro Rápido&nbsp;
                </ng-template>
                <ng-template pTemplate="content">
                    <app-simple-cad-customer *ngIf="showNewCad"
                        (onSaveSucess)="continueAsNewCad($event)">
                    </app-simple-cad-customer>
                </ng-template>
            </p-tabPanel>
        </p-tabView>
    </div>
    <div *ngSwitchCase="1">
        <div>
            <div *ngIf="selectedCustomer?.name" class="order-item-header">
                <span>Cliente:&nbsp;</span><span class="slin">{{selectedCustomer?.name}}&nbsp;&nbsp;&nbsp;</span>
                <span *ngIf="selectedBoat?.name">Embarcação:&nbsp;</span><span class="slin">{{selectedBoat?.name}}</span>
            </div>
            <div *ngIf="!selectedCustomer?.name" class="order-item-header space-between">
                <div>
                    <span>Cliente:&nbsp;</span>
                    <span class="slin">Balcão</span>
                </div>
                <div *ngIf="showAdvancedPayment && !invoice">
                    <span>Dividir Conta</span>&nbsp;
                    <p-inputSwitch class="negative-margin border" [(ngModel)]="isSplitBill" (onChange)="changeSplitItens()" ></p-inputSwitch>
                </div>
            </div>
        </div>
        <p-tabView (onChange)="changePaymentForm()">
            <p-tabPanel *ngIf="!isSingleOrder && invoice == null">
                <ng-template pTemplate="header">
                    &nbsp;Gerar Pedido&nbsp;
                </ng-template>

                <ng-component *ngTemplateOutlet="products"></ng-component>

                <ng-template pTemplate="content">
                    <app-order-quota-select *ngIf="showQuota && !showPaymentOptions"
                        (onQuotaSelected)="selectQuota($event)">
                    </app-order-quota-select>
                </ng-template>
            </p-tabPanel>
            <p-tabPanel>
                <ng-template pTemplate="header">
                    &nbsp;Pagar Agora&nbsp;
                </ng-template>

                <ng-component *ngTemplateOutlet="products"></ng-component>

                <ng-template pTemplate="content">
                    <app-discount-select *ngIf="showPaymentOptions && issCityTaxValue === 0 && !isSplitBill"
                        (onDiscountChange)="selectDiscount($event)"
                    ></app-discount-select>

                    <div *ngIf="issCityTaxValue > 0 && showPaymentOptions" class="options-box">
                        <span>
                            Imposto sobre Serviço Retido na Fonte - ISSRF - {{ config.data.slingConfig.cityTax | number:'1.2-2'}}%
                        </span>
                        <span>
                            Total do imposto: {{ issCityTaxValue | number:'1.2-2'}}
                        </span>
                    </div>

                    <div *ngIf="showPaymentOptions && !isSplitBill" class="order-item-element"><span>Total Líquido:</span><span class="float-end"> {{ getTotal() | number:'1.2-2'}}</span></div>

                    <div *ngIf="showPaymentOptions && !isSplitBill && showAdvancedPayment" class="order-item-element pending">
                        <span>Valor a Pagar:</span>
                        <p-inputNumber mode="decimal" prefix="R$ "
                            min="0,01" [max]="getTotal()"
                            [minFractionDigits]="2" [maxFractionDigits]="2"
                            [(ngModel)]="pendingValue" [min]="0.01" [max]="maxPendingValue"/>
                    </div>

                    <app-payment-form-select *ngIf="showPaymentOptions && !isSplitBill"
                        (isPayment)="isPayment($event)"
                        (onPaymentSelected)="selectPayment($event)">
                    </app-payment-form-select>
                </ng-template>
            </p-tabPanel>
        </p-tabView>
    </div>
    <div *ngSwitchCase="2">
        <div class="end-msg" *ngIf="!isNixPix && !isSplitBill; else showNixPix">
            <h2 *ngIf="invoice != null && !isCanceled">
                Pagamento realizado com sucesso!
            </h2>
            <h2 *ngIf="invoice != null && isCanceled && !isError">
                Fatura cancelada com sucesso!
            </h2>
            <h2 *ngIf="invoice != null && isCanceled && isError">
                A fatura não foi cancelada!
            </h2>
            <h2 *ngIf="invoice == null && !isSplitBill">
                Pedido gerado com sucesso
            </h2>
            <br>
<!--            <h3 *ngIf="invoice != null && !isSingleOrder && !isCanceled && hasNFConsumer">-->
<!--                Deseja emitir o cupom fiscal?-->
<!--            </h3>-->
            <h3 *ngIf="invoice != null && !isSingleOrder && !isCanceled">
                Deseja emitir nota fiscal?
            </h3>
<!--            <button *ngIf="invoice != null  && !isCanceled && hasNFConsumer" (click)="generateNfc()" class="primary easymarine" type="button" >Emitir</button>-->
            <button *ngIf="invoice != null  && !isCanceled" (click)="generateDocuments(invoice)" class="primary easymarine" type="button" >Emitir</button>
        </div>

        <ng-template #showNixPix>
            <div class="end-msg" *ngIf="isNixPix">
                <h2>
                    Leia o QRCode com o aplicativo do seu banco!
                </h2>
                <br>
                <qrcode *ngIf="invoice.pixCopyPast && invoice.pixCopyPast.length > 5 "
                [qrdata]="invoice.pixCopyPast" [errorCorrectionLevel]="'M'"></qrcode>
            </div>
            <app-split-bill (onPaymentCompleted)="paymentComplete()" *ngIf="isSplitBill"></app-split-bill>
        </ng-template>
    </div>
    <div *ngSwitchCase="3">
        <div class="end-msg">
            <h2 *ngIf="invoice != null && !isCanceled">
                Pagamento realizado com sucesso!
            </h2>
            <h2 *ngIf="invoice != null && isCanceled && !isError">
                Fatura cancelada com sucesso!
            </h2>
            <h2 *ngIf="invoice != null && isCanceled && isError">
                A fatura não foi cancelada!
            </h2>
            <br>
            <h3 *ngIf="invoice != null && !isSingleOrder">
                Deseja emitir nota fiscal?
            </h3>
            <button *ngIf="invoice != null && !isSingleOrder" (click)="generateDocuments(invoice)" class="primary easymarine" type="button" >Emitir</button>
        </div>
    </div>

<div class="buttons">
    <button *ngIf="activeIndex < 2 || (payments[0]?.disabled && !hasEnded)"  (click)="disposeAndCancel()" class="danger easymarine" type="button" >Cancelar</button>
    <button *ngIf="!showNewCad && activeIndex == 0" (click)="continueAsCommonOrder()" [disabled]="!validCustomerSelected" class="success easymarine" type="button" >Continuar</button>
    <button *ngIf="activeIndex == 1 && !showPaymentOptions" (click)="finishRequest()" class="success easymarine" type="button" >Gerar</button>
    <button *ngIf="activeIndex == 1 && showPaymentOptions && !isSplitBill" (click)="payFinishRequest()" [disabled]="!account || !enablePayment" class="success easymarine" type="button" >Pagar</button>
    <button *ngIf="activeIndex == 1 && showPaymentOptions && isSplitBill" (click)="continueAsSplitBill()" [disabled]="!validSplitBillItems" class="success easymarine" type="button" >Continuar</button>
    <button *ngIf="showNewCad && activeIndex == 0" (click)="continueAsNewCad()" class="success easymarine" type="button" >Salvar e Continuar</button>
    <button *ngIf="(activeIndex == 2 && !isNixPix && !isSplitBill) || (activeIndex == 3 && isNixPix) || (activeIndex == 3 && isError) || hasEnded" (click)="exit()" class="success easymarine" type="button" >Ok</button>
    <button *ngIf="activeIndex == 2 && (isNixPix || (isSplitBill && !payments[0]?.disabled) )" (click)="back()" class="danger easymarine" type="button" >Voltar</button>
</div>

<ng-template #products class="form-group body">
    <p-table [value]="orderedItems">
        <ng-template pTemplate="header">
            <tr>
                <th>Produtos
                    <fa-icon class="icon" [icon]="'exclamation-circle'" *ngIf="isSplitBill" class="split-notice"
                            pTooltip="Os itens não divididos serão cobrados do primeiro cliente.">
                    </fa-icon>
                </th>

                <th *ngIf="isSplitBill" class="text-center">Dividir Item</th>
                <th class="text-center">Quantidade</th>
                <th class="text-center">Vl un.</th>
                <th class="text-right">Total</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-item>
            <tr>
                <td>{{item.product.name}}</td>
                <td *ngIf="isSplitBill" class="text-center">
                    <p-checkbox binary="true" [(ngModel)]="item.split" (onChange)="validateSplitBillItems()"></p-checkbox>
                </td>
                <td class="text-center">{{item.qtySupplied | number }}</td>
                <td class="text-center">{{item.itemValue | number:'1.2-2'}}</td>
                <td class="text-right">{{item.itemValue * item.qtySupplied | number:'1.2-2'}}
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="5">
                    Nenhum item encontrado
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div *ngIf="isSplitBill" class="order-item-element mb-2"><span>A Dividir:</span><span class="float-right">{{ this.payments[0]?.totalSplited | number:'1.2-2'  }}</span></div>
    <div class="order-item-element"><span>Total:</span><span class="float-right">{{ calculateTotalItems() | number:'1.2-2'  }}</span></div>
</ng-template>
