import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Marina } from 'src/app/models';
import { OperationalConfig } from 'src/app/models/config/operational-config';
import { StorageUtil } from 'src/app/utils/storage.util';
import { environment } from 'src/environments/environment';
import { BroadcastService } from '../broadcast.service';

@Injectable({
  providedIn: 'root'
})
export class OperationalConfigService {

  private readonly apiUrl = environment.apiHost + '/secure/operational-config';
  private operationalConfig: OperationalConfig | undefined
  private marinaId: number
  private userId: number;

  constructor(private http: HttpClient) {
    BroadcastService.get('changeMarina').subscribe((_marina: Marina) => {
      this.operationalConfig = undefined;
    })
  }

  public async hasChildControl(): Promise<boolean> {
    const op = this.getOperationalConfig();
    if (op instanceof Observable) {
      return op.pipe(map((operationalConfig: OperationalConfig) =>
        operationalConfig.enableChildControlInNavigationPlan
      )).toPromise()
    } else {
      return new Promise((resolve, _reject) => resolve(op.enableChildControlInNavigationPlan))
    }
  }

  public async hasCustomerTuition(): Promise<boolean> {
    const op = this.getOperationalConfig();
    if (op instanceof Observable) {
      return op.pipe(map((operationalConfig: OperationalConfig) => operationalConfig.activateTuitionByCustomer)).toPromise()
    } else {
      return new Promise((resolve, _reject) => resolve(op.activateTuitionByCustomer))
    }
  }

  public async hasNFProduct(): Promise<boolean> {
    const op = this.getOperationalConfig();
    if (op instanceof Observable) {
      return op.pipe(map((operationalConfig: OperationalConfig) => operationalConfig.enableProductNote)).toPromise()
    } else {
      return new Promise<boolean>((resolve, _reject) => resolve(op.enableProductNote))
    }
  }
  public async hasNFConsumer(): Promise<boolean> {
    const op = this.getOperationalConfig();
    if (op instanceof Observable) {
      return op.pipe(map((operationalConfig: OperationalConfig) => operationalConfig.enableNFConsumerNote)).toPromise()
    } else {
      return new Promise<boolean>((resolve, _reject) => resolve(op.enableNFConsumerNote))
    }
  }

  public async isMovements(): Promise<boolean> {
    const op = this.getOperationalConfig();
    if (op instanceof Observable) {
      return op.pipe(map((operationalConfig: OperationalConfig) => operationalConfig.enableMovements)).toPromise()
    } else {
      return new Promise((resolve, _reject) => resolve(op.enableMovements))
    }
  }

  public async isCustomerServiceAvaliable(): Promise<boolean> {
    const op = this.getOperationalConfig();
    if (op instanceof Observable) {
      return op.pipe(map((operationalConfig: OperationalConfig) => operationalConfig.activateTuitionByCustomer)).toPromise()
    } else {
      return new Promise((resolve, _reject) => resolve(op.activateTuitionByCustomer))
    }
  }

  public async isWorkshop(): Promise<boolean> {
    const op = this.getOperationalConfig();

    if (op instanceof Observable) {
      return op.pipe(map((operationalConfig: OperationalConfig) => operationalConfig.enableWorkshop)).toPromise()
    } else {
      return new Promise((resolve, _reject) => resolve(op.enableWorkshop))
    }
  }

  public async isFranchise(): Promise<boolean> {
    const op = this.getOperationalConfig();

    if (op instanceof Observable) {
      return op.pipe(map((operationalConfig: OperationalConfig) => operationalConfig.activeFranchise)).toPromise()
    } else {
      return new Promise((resolve, _reject) => resolve(op.activeFranchise))
    }
  }

  public async isYatchClub(): Promise<boolean> {
    const op = this.getOperationalConfig();
    if (op instanceof Observable) {
      return op.pipe(map((operationalConfig: OperationalConfig) => operationalConfig.activateYachtClub)).toPromise()
    } else {
      return new Promise((resolve, _reject) => resolve(op.activateYachtClub))
    }
  }

  public async getWorkshopIntegrationKey(): Promise<string>{
    const op = this.getOperationalConfig();
    if (op instanceof Observable) {
      return op.pipe(map((operationalConfig: OperationalConfig) => {
        return operationalConfig.workshopIntegrationKey
      })).toPromise();
    } else {
      return new Promise((resolve, _reject) => {
        resolve(op.workshopIntegrationKey)
      });
    }

  };

  public async nixBankEnabled(): Promise<boolean> {
    const op = this.getOperationalConfig();
    if (op instanceof Observable) {
      return op.pipe(map((operationalConfig: OperationalConfig) => operationalConfig.enableNixxBank)).toPromise()
    } else {
      return new Promise((resolve, _reject) => resolve(op.enableNixxBank))
    }
  }

  public async invitesControllerEnabled(): Promise<boolean> {
    const op = this.getOperationalConfig();
    if (op instanceof Observable) {
      return op.pipe(map((operationalConfig: OperationalConfig) => operationalConfig.activeInvitation)).toPromise()
    } else {
      return new Promise((resolve, _reject) => resolve(op.activeInvitation))
    }
  }

  private getOperationalConfig(): Observable<OperationalConfig> | OperationalConfig {
    if (this.operationalConfig && this.marinaId === StorageUtil.getMarinaId() && this.userId === StorageUtil.getUserId()) {
       return  this.operationalConfig;
    }
    return this.find().pipe(map((operationalConfig) => {
      return this.operationalConfig = operationalConfig;
    }))
  }

  private find(): Observable<OperationalConfig> {
    return this.http.get(this.apiUrl)
      .pipe(map((res: any) => {
        this.marinaId = StorageUtil.getMarinaId();
        this.userId = StorageUtil.getUserId();
        return res.data.operationalConfig;
      } ));
  }
}
