import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-boat-log-insurer',
  templateUrl: './boat-log-insurer.component.html',
  styleUrls: ['./boat-log-insurer.component.scss'],
})
export class BoatLogInsurerComponent implements OnInit {
  @Input() boatLog;
  @Input() isNew;

  isNotEqualsType = false;

  constructor() {}

  ngOnInit(): void {
    const oldDatasMap = new Map(Object.entries(this.boatLog.oldDatas));
    this.isNotEqualsType = oldDatasMap.has('insurerType') && this.boatLog.insurerType !== this.boatLog.oldDatas.insurerType;
  }

  isChangedPolicyNumber(): boolean {
    return (
      this.isNotEqualsType || this.boatLog.oldDatas.boatInsurer.policyNumber !== this.boatLog.boatInsurer.policyNumber
    );
  }

  isChangedInsurerType(): boolean {
    return this.isNotEqualsType;
  }

  isChangedPolicyExpirationDate(): boolean {
    return this.isNotEqualsType || this.boatLog.oldDatas.boatInsurer.policyExpirationDate !== this.boatLog.boatInsurer.policyExpirationDate;
  }

  isChangedInsurerName(): boolean {
    return this.isNotEqualsType || this.boatLog.oldDatas.boatInsurer.insurerName !== this.boatLog.boatInsurer.insurerName
  }

  isTypeInsured(): boolean {
    if (this.isNew) {
      return this.boatLog.insurerType === 'INSURED';
    }
    return this.boatLog.oldDatas.insurerType === 'INSURED';
  }
}
