<div class="dialog-form">
    <p-tabView [activeIndex]="selectedTab" (onChange)="changeSelectedTab($event.index)">
        <p-tabPanel [disabled]="!isASlingEdition && selectedTab!==0">
            <ng-template pTemplate="header">
                <fa-icon [icon]="'ship'"></fa-icon>&nbsp;
                {{ 'SLING-DATA' | translate }}
            </ng-template>
            <app-sling-data [slingConfig]="slingConfig"  [sling]="sling" [isASlingEdition]="isASlingEdition" (eventSling)="changeSelectedTab($event)" (eventClose)="closeDialog($event)"  (eventClean)="clean()"></app-sling-data>
        </p-tabPanel>
        <p-tabPanel [disabled]="!sling.navigationPlan.responsible.id || !slingConfig.hasFuelSupply || (!isASlingEdition && selectedTab!==1)">
            <ng-template pTemplate="header">
                <fa-icon [icon]="'gas-pump'"></fa-icon>&nbsp;
                {{ 'FUEL' | translate }}
            </ng-template>
            <app-sling-fuel *ngIf="selectedTab===1" [slingConfig]="slingConfig" [slingOrder]="sling" [isASlingEdition]="isASlingEdition" (eventSling)="changeSelectedTab($event)"></app-sling-fuel>
        </p-tabPanel>
        <p-tabPanel [disabled]="!sling.navigationPlan.responsible.id || !slingConfig.hasConsumables || (!isASlingEdition && selectedTab!==2)">
            <ng-template pTemplate="header">
                <fa-icon [icon]="'glass-martini-alt'"></fa-icon>&nbsp;
                {{ 'CONSUMABLES' | translate }}
            </ng-template>
            <app-sling-product *ngIf="selectedTab===2" [slingConfig]="slingConfig" [slingOrder]="sling" [isASlingEdition]="isASlingEdition" (eventSling)="changeSelectedTab($event)"></app-sling-product>
        </p-tabPanel>
        <p-tabPanel [disabled]="!sling.navigationPlan.responsible.id || !slingConfig.hasAccessories || (!isASlingEdition && selectedTab!==3)">
            <ng-template pTemplate="header">
                <fa-icon [icon]="'life-ring'"></fa-icon>&nbsp;
                {{ 'ACCESSORIES' | translate }}
            </ng-template>
            <app-sling-accessory *ngIf="selectedTab===3" [slingConfig]="slingConfig" [slingOrder]="sling" [isASlingEdition]="isASlingEdition" (eventSling)="changeSelectedTab($event)"></app-sling-accessory>
        </p-tabPanel>
        <p-tabPanel
            [disabled]="!sling.navigationPlan.responsible.id || (!slingConfig.hasFuelSupply && !slingConfig.hasConsumables) || (!isASlingEdition && selectedTab!==4)">
            <ng-template pTemplate="header">
                <fa-icon [icon]="'dollar-sign'"></fa-icon>&nbsp;
                {{ 'QUOTA' | translate }}
            </ng-template>
            <app-sling-quota *ngIf="selectedTab===4" [slingConfig]="slingConfig" [slingOrder]="sling" [isASlingEdition]="isASlingEdition" (eventSling)="changeSelectedTab($event)" (eventClose)="closeDialog($event)"></app-sling-quota>
        </p-tabPanel>
    </p-tabView>
</div>
