import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { CustomerService } from '../../../../services/customer.service';
import { User } from '../../../../models/user';
import { CustomerFilterListDTO } from '../../../../models/dtos/customerFilterListDTO';
import { LogInvoicesActions } from 'src/app/models/enums/logs/log-invoices-actions';
import { InvoiceService } from '../../../../services/invoice.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { EasyBankLogFilter } from '../../../../models/easy_bank/easy-bank-log-filter';
import { EasyBankLogsService } from '../../../../services/easy_bank/logs/easy-bank-logs.service';
import { EasyBankLogDTO } from 'src/app/models/dtos/logs/easyBankLogDTO';


@Component({
  selector: 'app-nixx-bank-logs',
  templateUrl: './nixx-bank-logs.component.html',
  styleUrls: ['./nixx-bank-logs.component.scss']
})
export class NixxBankLogsComponent implements OnInit {

  nixxBankLogs: EasyBankLogDTO[];
  rangeDates: Date[];

  filteredUsers = [];
  customersFilter = [];
  invoiceActions = [
    { label: "Todos", value: null},
    { label: "Gerar", value: LogInvoicesActions.GENERATE },
    { label: "Cancelar", value: LogInvoicesActions.CANCEL },
];

  invoicesByMarina = [];
  filter: EasyBankLogFilter = new EasyBankLogFilter;

  constructor(
    private userService: UserService,
    private customerService: CustomerService,
    private invoiceService: InvoiceService,
    private easyBankLogsService: EasyBankLogsService,
    private messageUtil: MessageUtil
  ) { }

  ngOnInit(): void {
    this.loadUsers();
    this.loadCustomer();
    this.loadInvoices();
  }

  private loadUsers(): void {
    this.userService.getMarinaUsers().subscribe({
     next: (users: User[]) => {
        const usersWithoutCollaborator = users.filter((user) => user.roleId.name !== "COLLABORATOR");
        this.filteredUsers = usersWithoutCollaborator.map((c) => ({ label: c.firstName.concat(' ').concat(c.lastName), value: c.id }));
        this.filteredUsers.unshift({ label: 'Todos', value: null });
      },
      error: () => this.messageUtil.generateMessage('warning', 'Atenção', 'Erro ao consultar os Usuários')
    });
  }

  loadCustomer(): void {
    this.customerService.getCustomerFilterList().subscribe({
     next: (customers: CustomerFilterListDTO[]) => {
        this.customersFilter = customers.map((c) => ({ label: c.name, value: c.id }));
        this.customersFilter.unshift({ label: 'Todos', value: null });
      },
      error: () => this.messageUtil.generateMessage('warning', 'Atenção', 'Erro ao consultar os Clientes')
    });
  }

  loadInvoices(): void{
    this.invoiceService.getInvoiceIdByMarina().subscribe({
      next: (invoices) => {
        this.invoicesByMarina = invoices.map((invoice) => ({label: invoice, value: invoice}));
        this.invoicesByMarina.unshift({ label: 'Todos', value: null });
      },
      error: () => this.messageUtil.generateMessage('warning', 'Atenção', 'Erro ao consultar as faturas')
    });
  }

  searchLog(): void{
    this.filter.dateStart = this.rangeDates[0];
    this.filter.dateEnd = this.rangeDates[1];
    this.easyBankLogsService.getNixxBankLogs(this.filter).subscribe({
      next: (logs) => this.nixxBankLogs = logs,
      error: (error) => this.messageUtil
        .generateMessage('warning', 'Atenção', 'Erro ao consultar os Logs')
    });
  }
}
