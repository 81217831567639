import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TipoFormaPagamento } from 'src/app/models/finance/tipo-forma-pagamento';
import { FinancesService } from 'src/app/services/finances.service';
import { ToastService } from 'src/app/services/toast.service';
import { StorageUtil } from 'src/app/utils/storage.util';

@Component({
  selector: 'app-receiving-accounts-dialog',
  templateUrl: './receiving-accounts-dialog.component.html',
  styleUrls: ['./receiving-accounts-dialog.component.scss']
})
export class ReceivingAccountsDialogComponent implements OnInit {

  isModal = false;
  selectedCompany: any;
  showCompanyList = false;
  selectedPayment: TipoFormaPagamento;
  aditionalCompanyListOptions: { label: string, value: any }[];
  aditionalCompanyList: any;
  receivingAccounts: any[] = new Array();
  apiAccountList: any[];
  accounts: any[];
  accountList: any[];
  paymentMethodsList: any[];
  constructor(
    private financesService: FinancesService,
    private spinner: NgxSpinnerService,
    private toasty: ToastService,
    private config?: DynamicDialogConfig
  ) {
  }

  async ngOnInit(): Promise<void> {
    if (this.config && this.config.data) {
      this.isModal = this.config.data.isModal;
      this.selectedCompany = this.selectedCompany;
    }
    this.spinner.show();
    this.aditionalCompanyList = await this.financesService.findCompaniesWithDatabase();
    this.paymentMethodsList = await this.searchPaymentMethods();
    await this.findCompaniesWithDatabase();
    this.cleanDatabase(this.aditionalCompanyList);
    if (this.isModal) {
      this.changeCompany(this.selectedCompany);
    }
    this.spinner.hide();
  }


  async searchPaymentMethods(companyId?: any): Promise<any[]> {
    return new Promise<any[]>(
      async (resolve) => {
        this.financesService.findTipoFormasPagamento(companyId ? companyId : '').subscribe(
          async (data) => {
            resolve(data.filter((method) => method.contas.length !== 0 && method.tipo.id !== 1));
          }
        );
      }
    );
  }

  async change(event?): Promise<void> {
    this.accountList = await this.searchAccounts(this.selectedPayment);
    await this.searchAccountsPayments(this.selectedPayment.idTipoFormaPagamento);
  }

  async changeCompany(event?): Promise<void> {
    if (event) {
      this.selectedCompany = event;
    }
    this.accountList = [];
    this.receivingAccounts = [];
    this.accounts = [];
    this.selectedPayment = null;
    this.paymentMethodsList = await this.searchPaymentMethods(this.selectedCompany ? this.selectedCompany : '');
  }

  async searchAccounts(selectedPayment: TipoFormaPagamento): Promise<any[]> {
    return new Promise<any[]>(
      async (resolve) => {
        this.apiAccountList = selectedPayment.contas;
        resolve(selectedPayment.contas);
      }
    );
  }

  async searchAccountsPayments(paymentId: number): Promise<void> {
    return new Promise<void>(
      async (resolve) => {
        this.receivingAccounts = new Array();
        this.financesService.findAllAccountPaymentMethodsByPaymentId(paymentId).subscribe(
          async (data) => {
            let filteredList: any;
            let id: number;
            if (this.selectedCompany) {
              id = this.selectedCompany.id;
            } else {
              id = 0;
            }
            if (data.list.length > 0) {
              filteredList = data.list.filter(a => a.companyId === id);
              this.receivingAccounts = await this.populateReceivingAccounts(filteredList);
              await this.modifyList(this.receivingAccounts);
            } else {
              this.accounts = [...this.apiAccountList];
            }
            resolve();
          }
        );
      }
    );
  }

  async populateReceivingAccounts(list): Promise<any[]> {
    return new Promise<any[]>(
      async (resolve) => {
        const receivingAccountsList: any = [];
        list.forEach(element => {
          const accountExist = this.accountList.find(d => d.idConta === element.contaId);
          if (accountExist) {
            receivingAccountsList.push(accountExist);
          }
        });
        resolve(receivingAccountsList);
      }
    );
  }

  async modifyList(data: any[]): Promise<void> {
    return new Promise<void>(
      async (resolve) => {
        if (this.accountList) {
          this.accounts = this.accountList.filter(a => {
            if (!data.find((c) => c.idConta === a.idConta)) {
              return a;
            }
          });
        }
        resolve();
      }

    );
  }

  addAccount(event: any): void {
    const vinculo: any = {};
    vinculo.formapagamentoId = this.selectedPayment.idTipoFormaPagamento;
    if (this.selectedCompany) {
      vinculo.companyId = this.selectedCompany.id;
    } else {
      vinculo.companyId = 0;
    }
    this.spinner.show();
    event.items.forEach((i) => {
      vinculo.contaId = i.idConta;
      this.financesService.addAccountToPaymentMethod(vinculo).subscribe(
        (data) => {
          this.toasty.success('Conta salva com sucesso!');
        },
        (error) => {
          this.spinner.hide();
          console.log(error);
          this.toasty.success('Não foi possível salvar conta, por favor, tente novamente.');
        });
    });
    this.spinner.hide();
  }

  removeAccount(event: any): void {
    const vinculo: any = {};
    vinculo.formaPagamento = this.selectedPayment.idTipoFormaPagamento;
    if (this.selectedCompany) {
      vinculo.companyId = this.selectedCompany.id;
    } else {
      vinculo.companyId = 0;
    }
    event.items.forEach((i) => {
      vinculo.contaId = i.idConta;
      this.financesService.removeAccountToPaymentMethod(vinculo).subscribe(
        (data) => {
          this.toasty.success('Conta removida com sucesso!');
        },
        (error) => {
          this.spinner.hide();
          this.toasty.success('Não foi possível remover conta, por favor, tente novamente.');
        });
    });
    this.spinner.hide();
  }


  async searchAllAccountsPayments(companyId): Promise<any> {
    return new Promise<any>(
      async (resolve) => {
        let receivingAccountLists = new Array();
        this.financesService.findAllAccountPaymentMethodsByCompanyId(companyId).subscribe(
          async (data) => {
            if (data.list && data.list.length > 0) {

              receivingAccountLists = (data.list);
            } else {

            }
            resolve(receivingAccountLists);
          }
        );
      }
    );
  }

  async findCompaniesWithDatabase(): Promise<void> {
    if (this.aditionalCompanyList.length > 0) {
      this.showCompanyList = true;
      this.aditionalCompanyListOptions = [{ label: StorageUtil.getMarina().companyName, value: null }].concat(
        (this.aditionalCompanyList).map((c) => {
          return { label: c.companyFederalName, value: c };
        })
      );
    }

  }


  async cleanDatabase(aditionalCompanyList: any[]): Promise<void> {
    if (this.showCompanyList && aditionalCompanyList.length > 0) {
      this.cleanDatabaseByCompanyId();
      aditionalCompanyList.forEach(element => {
        this.cleanDatabaseByCompanyId(element);
      });
    } else {
      this.cleanDatabaseByCompanyId();
    }
  }


  async cleanDatabaseByCompanyId(companyId?: any): Promise<void> {
    const receivingAccountLists = await this.searchAllAccountsPayments(companyId ? companyId.id : 0);
    const paymentMethodsList = await this.searchPaymentMethods(companyId ? companyId : '');
    const contaListFinanceiro = [];
    const listaContasApi = [];
    paymentMethodsList.forEach(fromaPagamento => {
      fromaPagamento.contas.forEach(conta => {
        contaListFinanceiro.push(conta.idConta);
      });
    });
    receivingAccountLists.forEach(fromaPagamento => {
      fromaPagamento.contaId.forEach(element => {
        listaContasApi.push(element);
      });
    });
    let accountsRemove = listaContasApi.filter((a) => {
      if (!contaListFinanceiro.find(c => c === a)) {
        return a;
      }
    });
    accountsRemove = accountsRemove.filter((a, b) => accountsRemove.indexOf(a) === b);
    accountsRemove.forEach(element => {
      this.financesService.removeAccountToPaymentMethodByContaAndMarinaId(element, companyId ? companyId.id : 0).subscribe(
        (data) => {
        },
        (error) => {
          this.spinner.hide();
        });
    });
  }

}
