import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SirenAlert } from 'src/app/models/siren/siren-alert';

@Injectable()
export class SirenAlertService {

    constructor(private http: HttpClient) {

    }

    getAll(): Observable<SirenAlert[]> {
        return this.http.get(environment.apiHost + '/secure/siren-alert/')
            .pipe(map((response: any) => response.data.sirenAlerts as SirenAlert[]));
    }

}
