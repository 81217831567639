import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-boat-log-authorized',
  templateUrl: './boat-log-authorized.component.html',
  styleUrls: ['./boat-log-authorized.component.scss']
})
export class BoatLogAuthorizedComponent implements OnInit {

  @Input() authorizeds;
  @Input() owners;
  @Input() ownersOld;
  @Input() authorizedsOld;
  @Input() isNew;

  constructor() { }

  ngOnInit(): void {
  }

  getNameAssociatedOwner(ownerId: number): string {
    let ownerList
    if(this.isNew){
      ownerList = this.owners;
    } else {
      ownerList = this.ownersOld;
    }

    return  ownerList.find(
      (owner) => (owner.customerId === ownerId)
    ).name;
  }


  logsTable(): any[]{
    if(this.isNew){
      return this.authorizeds;
    } else {
      return this.authorizedsOld;
    }
  }

  isChanged(authorized): boolean {
    let authorizedVerify;
    if(this.isNew){
      authorizedVerify = this.findAuthorizedOld(authorized.customerId);
    } else {
      authorizedVerify = this.findAuthorized(authorized.customerId);
    }
    
    return !authorizedVerify || (authorizedVerify.name !== authorized.name
      || authorizedVerify.associatedOwnerId !== authorized.associatedOwnerId);
  }


  findAuthorized(id): any{
    return this.authorizeds.find(a => a.customerId === id);
  }

  findAuthorizedOld(id): any{
    return this.authorizedsOld.find(a => a.customerId === id); 
  }

}
