import { OperationalConfigService } from './config/operational-config.service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PanelConfig } from '../models';
import { PanelFunctionality } from '../models/enums';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { PanelConfigSeparetedByOrientations } from '../models/panel-config-separeted-by-orientations';
import { RaspScreenOrientation } from '../models/enums/rasp-screen-orientation';

@Injectable()
export class PanelConfigService {

  constructor(
    private http: HttpClient,
    private operationalConfigService: OperationalConfigService
  ) { }

  static getLinkPanel(): string {
    if (environment.apiHost.includes('test') || environment.apiHost.includes('localhost')) {
      return 'http://test.panels.easymarine.com.br/';
    }
    return 'https://panels.easymarine.com.br/';
  }

  getAllSeparatedByOrientation(): Observable<PanelConfigSeparetedByOrientations> {
    return this.http.get(environment.apiHost + '/secure/panel-configs/orientations')
      .pipe(map((response: any) => {
        return {
          portrait: (response.data.panelConfigsPortrait as PanelConfig[]),
          landscape: (response.data.panelConfigsLandscape as PanelConfig[])
        } as PanelConfigSeparetedByOrientations;
      }));
  }

  getByMac(mac: string): Observable<PanelConfig[]> {
    return this.http.get(environment.apiHost + '/secure/panel-config-by-mac/' + mac)
      .pipe(map((response: any) => response.data.panelConfig as PanelConfig[]));
  }

  getById(id: number): Observable<PanelConfig> {
    return this.http.get(environment.apiHost + '/secure/panel-config/' + id)
      .pipe(map((response: any) => response.data.panelConfig as PanelConfig));
  }

  save(panelConfig: PanelConfig): Observable<PanelConfig> {
    return this.http.post(environment.apiHost + '/secure/panel-config/', panelConfig)
      .pipe(map((response: any) => response.data.panelConfig));
  }

  saveByMac(orientation: RaspScreenOrientation, panelsConfig: PanelConfig[]): Observable<PanelConfig> {
    return this.http.post(environment.apiHost + '/secure/panel-config-by-mac/', { panelsConfig, orientation })
      .pipe(map((response: any) => response.data.panelConfig));
  }

  delete(id: number): Observable<PanelConfig> {
    return this.http.delete(environment.apiHost + '/secure/panel-config/' + id)
      .pipe(map((response: any) => response.data.panelConfig));
  }

  deleteByMac(mac: string): Observable<PanelConfig> {
    return this.http.delete(environment.apiHost + '/secure/panel-config-by-mac/' + mac)
      .pipe(map((response: any) => response.data.panelConfig));
  }

  getByName(panelName: string): Observable<PanelConfig> {
    return this.http.get(environment.apiHost + '/secure/panel-config/find-by-name/' + panelName)
      .pipe(map((response: any) => response.data.panelConfig as PanelConfig));
  }

  updateFunctionLink(panelConfig: PanelConfig): Observable<PanelConfig> {
    return this.http.put(environment.apiHost + '/secure/panel-config/update-link', panelConfig)
      .pipe(map((response: any) => response.data.panelConfig));
  }

  async getPanelFunctionalities(): Promise<{ label: string; value: PanelFunctionality; }[]> {
    if (await this.operationalConfigService.isMovements()) {
      return this.getPanelFunctionalitiesMovements();
    }
    return this.getPanelFunctionalitiesSling();
  }

  getPanelFunctionalitiesSling(): { label: string; value: PanelFunctionality; }[] {
    return [{
      label: 'Fila de descida',
      value: PanelFunctionality.SlingDownQueue,
    },
    {
      label: 'Água',
      value: PanelFunctionality.SlingWaterQueue,
    },
    {
      label: 'Fila de subida',
      value: PanelFunctionality.SlingUpQueue,
    },
    {
      label: 'Água/Fila de subida',
      value: PanelFunctionality.SlingWaterUpQueue,
    },
    {
      label: 'Navegando',
      value: PanelFunctionality.SlingSailing
    },
    {
      label: 'Atracado',
      value: PanelFunctionality.SlingMoored
    },
    {
      label: 'Lavação',
      value: PanelFunctionality.BoatWash
    },
    {
      label: 'Oficina - Status Orçamento',
      value: PanelFunctionality.WorkshopBudget
    },
    {
      label: 'Oficina - Status Envio',
      value: PanelFunctionality.BudgetSendStatus
    },
    {
      label: 'Oficina - Status Ordem de serviço',
      value: PanelFunctionality.ServiceOrderStatus,
    }
    ];
  }

  getPanelFunctionalitiesMovements(): { label: string; value: PanelFunctionality; }[] {
    return [{
      label: 'Movimentações',
      value: PanelFunctionality.MovementAll,
    },
    {
      label: 'Pátio',
      value: PanelFunctionality.MovementYard,
    },
    {
      label: 'Navegando',
      value: PanelFunctionality.MovementNavigation
    },
    {
      label: 'Lavação',
      value: PanelFunctionality.BoatWash
    },
    {
      label: 'Atracado',
      value: PanelFunctionality.MovementMoored
    },
    {
      label: 'Atracado/Navegando',
      value: PanelFunctionality.MovementMooredNavigation
    },
    {
      label: 'Vaga',
      value: PanelFunctionality.MovementVacancy
    },
    {
      label: 'Oficina - Status Orçamento',
      value: PanelFunctionality.WorkshopBudget
    },
    {
      label: 'Oficina - Status Envio',
      value: PanelFunctionality.BudgetSendStatus
    },
    {
      label: 'Oficina - Status Ordem de serviço',
      value: PanelFunctionality.ServiceOrderStatus,
    },
    {
      label: 'Fila de Descida',
      value: PanelFunctionality.MovementDownQueue
    },
    ];
  }

  uploadFile(files: any[]): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('attachment', files[0]);

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'multipart/form-data');

    return this.http.post(
      environment.apiHost + '/secure/panel-configs/image-file/attachment',
      formData,
      { headers }
    ).pipe(map((response: any) => response.data.attachment));
  }
}
