<p-fieldset>
  <p-header class="article-title">
    <span class="article-title">{{
      "TITLE-FRANCHISE-CONTROLLER" | translate
    }}</span>
    <div class="header-buttons">
        <button
        class="fab-button primary"
        (click)="exportTable()"
        label="Exportar valores"
        pTooltip="Exportar Valores"
        tooltipPosition="left"
      >
        <i class="pi pi-file"></i>
      </button>
      <button
        class="fab-button primary"
        appFiltersButton
        pTooltip="Exibir/Ocultar Filtros"
        tooltipPosition="left"
      >
        <i class="pi pi-filter"></i>
      </button>
    </div>
  </p-header>

  <form (submit)="findAll()" class="filters" appFilters>
    <!-- Filtros -->
    <div class="filter-row" *ngIf="contentLoaded">
      <!-- <div class="form-control">


      <div class="form-control">
        <label>{{ "Status Import." | translate }}</label>
        <p-multiSelect
          [options]="statusImpList"
          [style]="{ height: '30px' }"
          [(ngModel)]="filter.statusImpList"
          appendTo="body"
          name="statusImpList"
          selectedItemsLabel="{0} itens selecionados"
        >
        </p-multiSelect>
      </div> -->

      <div class="form-control">
        <label>{{ "BOAT" | translate }}</label>
        <p-dropdown
          [filter]="true"
          [options]="filteredBoats"
          [(ngModel)]="filter.boatId"
          appendTo="body"
          name="selectedBoat"
        >
        </p-dropdown>
      </div>

      <div class="form-control">
        <label>{{ "CUSTOMER" | translate }}</label>
        <p-dropdown
          [filter]="true"
          [options]="filteredCustomers"
          [(ngModel)]="filter.customerId"
          appendTo="body"
          name="selectedCustomer"
        >
        </p-dropdown>
      </div>

      <div class="form-control">
        <label>{{ "Mes de Referencia" | translate }}</label>
        <p-dropdown
          [filter]="true"
          [options]="monthList"
          [(ngModel)]="referenceMonth"
          appendTo="body"
          name="referenceMonth"
        >
        </p-dropdown>
      </div>

      <div class="form-control">
        <label>{{ "Ano de referencia" | translate }}</label>
        <p-dropdown
          [filter]="true"
          [options]="yearList"
          [(ngModel)]="referenceYear"
          appendTo="body"
          name="referenceYear"
        >
        </p-dropdown>
      </div>

      <div class="form-control">
        <label>{{ "STATUS" | translate }}</label>
        <p-dropdown
          [filter]="true"
          [options]="listStatus"
          [(ngModel)]="filter.status"
          appendTo="body"
          name="selecionaAtivo"
        >
        </p-dropdown>
      </div>

      <div class="form-control">
        <label>{{ "Grupo de Franquia" | translate }}</label>
        <p-dropdown
          [filter]="true"
          [options]="franchiseGroupOptions"
          [(ngModel)]="filter.franchiseGroupId"
          appendTo="body"
          name="selecionaAtivo"
        >
        </p-dropdown>
      </div>

      <div class="form-control">
        <label>{{ "Serviço excedente" | translate }}</label>
        <p-dropdown
          [filter]="true"
          [options]="productList"
          [(ngModel)]="filter.serviceId"
          appendTo="body"
          name="selecionaAtivo"
        >
        </p-dropdown>
      </div>

      <div class="form-control">
        <label>Somente Excedentes</label>
        <p-inputSwitch
          [(ngModel)]="onlySurplus"
          name="onlySurplus"
          [onLabel]="true"
          [offLabel]="false"
        >
        </p-inputSwitch>
      </div>

      <div class="buttons">
        <div class="form-button">
          <button (click)="clear()" class="easymarine danger" type="button">
            {{ "CLEAR" | translate }}
          </button>
        </div>
        <div class="form-button">
          <button class="easymarine primary" type="submit">
            {{ "FIND" | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
  <div class="table">
    <p-table
      dataKey="boatId"
      [value]="franchiseList"
      autoLayout="true"
      [alwaysShowPaginator]="true"
      [rows]="paginationFilter.size"
      [totalRecords]="totalRecords"
      [paginator]="true"
      #table
      [(selection)]="selectedFranchiseList"
      [rowsPerPageOptions]="[10, 20, 30, 50, 100]"
      styleClass="easy-table"
      sortField="boatName"
      sortOrder="1"
    >
      <ng-template pTemplate="caption">
        <div
          class="p-caption"
          style="display: flex; justify-content: space-between; width: 100%"
        >
          <div>
            <span class="total-registers"
              >{{ "TOTAL-REGISTERS" | translate }}: {{ franchiseList.length }}
              {{ "OF" | translate }} {{ table.totalRecords }}
            </span>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th class="text-center">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th class="text-center" pSortableColumn="boatName">
            Embarcação
            <p-sortIcon field="boatName"></p-sortIcon>
          </th>
          <th class="text-center" pSortableColumn="commercialLength">
            Pés
            <p-sortIcon field="commercialLength"></p-sortIcon>
          </th>
          <th class="text-center" pSortableColumn="owners">
            Cliente
            <p-sortIcon field="owners"></p-sortIcon>
          </th>
          <th class="text-center" pSortableColumn="franchiseGroupName">
            Grupo Franquia
            <p-sortIcon field="franchiseGroupName"></p-sortIcon>
          </th>
          <th class="text-center">Franquia</th>
          <th class="text-center" pSortableColumn="serviceName">
            Serviço
            <p-sortIcon field="serviceName"></p-sortIcon>
          </th>
          <th class="text-center" pSortableColumn="surplusAmount">
            Excedente
            <p-sortIcon field="surplusAmount"></p-sortIcon>
          </th>
          <th class="text-center" pSortableColumn="surplusAmount">
            Cobrada
            <p-sortIcon field="surplusAmount"></p-sortIcon>
          </th>
          <th class="text-center">Valor Unitário</th>
          <th class="text-center">Valor Total</th>
          <th class="text-center" pSortableColumn="status">
            Status
            <p-sortIcon field="status"></p-sortIcon>
          </th>
          <th class="text-center" pSortableColumn="orderNumber">
            Pedido
            <p-sortIcon field="orderNumber"></p-sortIcon>
          </th>
          <th class="text-center" pSortableColumn="invoiceNumber">
            Fatura
            <p-sortIcon field="invoiceNumber"></p-sortIcon>
          </th>
          <th class="text-center">Ações</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-franchise>
        <tr>
          <td class="text-center" [ngClass]="franchise.statusClass">
            <p-tableCheckbox *ngIf="franchise.status!=='EXEMPT'" [value]="franchise"> </p-tableCheckbox>
          </td>
          <td class="text-center" [ngClass]="franchise.statusClass">
            {{ franchise.boatName }}
          </td>
          <td class="text-center" [ngClass]="franchise.statusClass">
            {{ franchise.commercialLength }}
          </td>
          <td
            class="text-center"
            [ngClass]="franchise.statusClass"
            *ngIf="franchise?.qtdOwners == 1"
          >
            {{ franchise.owners }}
          </td>
          <td
            class="text-center"
            *ngIf="franchise?.qtdOwners > 1"
            [pTooltip]="franchise.owners"
            [ngClass]="franchise.statusClass"
          >
            Diversos
          </td>
          <td
            class="text-center"
            [ngClass]="franchise.statusClass"
            *ngIf="franchise?.qtdOwners == null"
          ></td>
          <td class="text-center" [ngClass]="franchise.statusClass">
            {{ franchise.franchiseGroupName }}
          </td>
          <td class="text-center" [ngClass]="franchise.statusClass">
            {{
              franchise.ammountFranchise == null
                ? 0
                : franchise.ammountFranchise
            }}
            de
            {{
              franchise.franchiseNumber == null ? 0 : franchise.franchiseNumber
            }}
          </td>
          <td class="text-center" [ngClass]="franchise.statusClass">
            {{ franchise.serviceName }}
          </td>
          <td class="text-center" [ngClass]="franchise.statusClass">
            {{ franchise.surplusAmount }}
          </td>
          <td class="text-center" [ngClass]="franchise.statusClass">
            {{ franchise.ammountOrdered }}
          </td>
          <td class="text-center" [ngClass]="franchise.statusClass">
            {{ franchise.unitPrice | currency : "BRL" }}
          </td>
          <td
            *ngIf="franchise.exempt || franchise.orderNumber != null"
            class="text-center"
            [ngClass]="franchise.statusClass"
          >
            {{ franchise.totalValue | currency : "BRL" }}
          </td>
          <td
            *ngIf="!franchise.exempt && franchise.orderNumber == null"
            class="text-center"
            [ngClass]="franchise.statusClass"
          >
            {{
              franchise.unitPrice * franchise.ammountOrdered | currency : "BRL"
            }}
          </td>
          <td class="text-center" [ngClass]="franchise.statusClass">
            {{ franchise.status | translate }}
          </td>
          <td class="text-center" [ngClass]="franchise.statusClass">
            {{ franchise.orderNumber }}
          </td>
          <td class="text-center" [ngClass]="franchise.statusClass">
            {{ franchise.invoiceNumber }}
          </td>
          <td class="text-center" [ngClass]="franchise.statusClass">
            <p-menu
              appendTo="body"
              #menu
              [model]="menuItems"
              [popup]="true"
              [showTransitionOptions]="'150ms'"
              [hideTransitionOptions]="'150ms'"
            >
            </p-menu>
            <button
              (click)="menu.toggle($event); changeSelectedItem(franchise)"
              pButton
              type="button"
              icon="pi pi-ellipsis-v"
              class="p-button-text p-button-rounded p-button-lg"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="buttons posicao">
    <div class="form-button">
      <button
        *ngIf="
          !this.isSameOrAfterCurrentMonth(this.lastReferenceDate) &&
          this.selectedFranchiseList?.length > 0 &&
          valid(this.selectedFranchiseList, 'invoice')
        "
        class="primary easymarine"
        (click)="generateInvoiceLot()"
      >
        {{ "Gerar Faturas" | translate }}
      </button>
    </div>
    <div class="form-button" style="padding-left: 8px">
      <button
        *ngIf="
          !this.isSameOrAfterCurrentMonth(this.lastReferenceDate) &&
          this.selectedFranchiseList?.length > 0 &&
          valid(this.selectedFranchiseList, 'order')
        "
        class="primary easymarine"
        (click)="generateOrderLot()"
      >
        {{ "Gerar Pedidos" | translate }}
      </button>
    </div>
  </div>
</p-fieldset>
