<div class="flex-container">
    <i class="pi" [ngClass]="{
        'greyed': activeIndex < stepIndex,
        'pi-circle': activeIndex < stepIndex,
        'pi-circle-fill': activeIndex === stepIndex,
        'red pi-exclamation-circle': activeIndex > stepIndex && isError,
        'green pi-check-circle': activeIndex > stepIndex && !isError
    }">
    </i>
    &nbsp;
    <span [ngClass]="{
        'greyed': activeIndex < stepIndex,
        'bold': activeIndex === stepIndex,
        'red': activeIndex > stepIndex && isError,
        'green': activeIndex > stepIndex && !isError
    }">{{ title }}</span>
    &nbsp;
    <img *ngIf="!isLast" [src]="(activeIndex <= stepIndex ? '/assets/images/line_end.svg' : (isError ? '/assets/images/blue/red_line_end.svg' : '/assets/images/blue/blue_line_end.svg'))" alt="" class="custom-fill">   
</div>
