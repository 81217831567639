import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrderItem } from 'src/app/models';
import { InvoicePaidService } from 'src/app/services/invoice-paid.service';
import { MarinaOrderService } from 'src/app/services/marina-order.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { PrintOrderInvoice } from 'src/app/utils/print-order-invoice';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reprint',
  templateUrl: './reprint.component.html',
  styleUrls: ['./reprint.component.scss']
})
export class ReprintComponent implements OnInit {

  filter: any = {};
  orders: any[] = new Array();
  invoicesPaid: any[] = new Array();
  ordersPrint = new Array();
  orderOrInvoiceNumber: number;
  selectedReprint: string;
  titleHeader = '';

  constructor(
    private marinaOrderService: MarinaOrderService,
    private invoicePaidService: InvoicePaidService,
    private messageService: MessageUtil,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
  }

  search(): void {
    if (this.selectedReprint === 'INVOICE') {
      this.findInvoices();
    } else {
      this.findOrders();
    }
  }

  findOrders(): void {
    this.spinner.show();
    this.marinaOrderService.getByOrderNumber(this.orderOrInvoiceNumber).subscribe(
      (data) => {
        if (data && data.length > 0) {
          this.orders = data;
          this.titleHeader = 'Pedido';
        } else {
          this.messageService.generateMessage('warning', 'Não encontrado', 'Pedido não encontrado.');
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }

  findInvoices(): void {
    this.spinner.show();
    this.invoicePaidService.findByInvoiceNumber(this.orderOrInvoiceNumber).subscribe(
      (data) => {
        this.invoicesPaid = data;
        if (this.invoicesPaid.length > 0) {
          this.titleHeader = 'Fatura - ' + this.invoicesPaid[0].customerName;
        } else {
          this.messageService.generateMessage('warning', 'Não encontrado', 'Fatura não encontrada.');
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }

  getMarinaOrdersOfInvoice(): void {
    this.marinaOrderService.getByIdInvoice(this.invoicesPaid[0].invoiceId).subscribe(
      (data) => {
        this.ordersPrint = data;
        this.ordersPrint.forEach((order) => {
          if (order.customers.length > 1) {
            order.customerQuota = order.customers.find((customer) =>
              customer.federalId === this.invoicesPaid[0].customerFederalId).quota;
          }
        });
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }

  printOrder(): void {
    Swal.fire({
      title: 'Imprimir recibo',
      text: 'Deseja imprimir recibo do pedido?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).
      then((result) => {
        if (result.value) {
          this.spinner.show();
          this.ordersPrint = new Array();
          this.ordersPrint.push(this.createMarinaOrderPrint());
          const printOrderInvoice = new PrintOrderInvoice();
          printOrderInvoice.loadOrdersPrint(this.ordersPrint);
          printOrderInvoice.loadOrderQuota(this.ordersPrint[0]);
          printOrderInvoice.printOrder(this.ordersPrint[0]);
          this.spinner.hide();
        }
      });
  }

  printInvoice(idInvoicePaid): void {
    this.getMarinaOrdersOfInvoice();

    Swal.fire({
      title: 'Imprimir recibo',
      text: 'Deseja imprimir recibo da fatura?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      reverseButtons: true
    }).
      then((result) => {
        if (result.value) {
          this.spinner.show();
          const printOrderInvoice = new PrintOrderInvoice();
          printOrderInvoice.loadOrdersPrint(this.ordersPrint);
          const invoice = this.createInvoicePrint(idInvoicePaid);
          printOrderInvoice.printInvoice(invoice);
          this.spinner.hide();
        }
      });
  }

  createMarinaOrderPrint(): any {
    const customersOfQuota = this.createCustomers();
    const orderItems = this.createOrderItems();
    return {
      orderNumber: this.orders[0].orderNumber,
      marinaTradeName: this.orders[0].marina.tradeName,
      marinaFederalId: this.orders[0].marina.companyFederalId,
      customers: customersOfQuota,
      value: this.orders[0].value,
      orderItens: orderItems,
      orderQuotas: this.orders[0].orderQuotas,
      date: this.orders[0].orderDate
    };
  }

  createInvoicePrint(idInvoicePaid): any {
    const invoicePaid = this.invoicesPaid.find((ip) => ip.id === idInvoicePaid);
    const indexInvoicePaid = this.invoicesPaid.indexOf(invoicePaid);
    const totalPreviousPay = indexInvoicePaid === 0 ? 0 : this.invoicesPaid[indexInvoicePaid - 1].totalPaid;
    const invoicePaidPrevious = this.invoicesPaid[indexInvoicePaid === 0 ? 0 : indexInvoicePaid - 1];
    const previousDebitBalan = invoicePaidPrevious.totalInvoice - totalPreviousPay;
    return {
      paymentDate: new Date(invoicePaid.datepaid),
      value: invoicePaid.totalInvoice,
      totalPaid: invoicePaid.totalPaid,
      totalPreviousPayment: totalPreviousPay,
      previousDebitBalance: previousDebitBalan,
      paidValue: invoicePaid.value,
      invoiceNumber: invoicePaid.invoiceNumber,
      marina: invoicePaid.marina,
      paymentMethodName: invoicePaid.paymentMethodName,
      customer: { name: invoicePaid.customerName, federalId: invoicePaid.customerFederalId }
    };
  }

  createCustomers(): any {
    const customersOfQuota = new Array();
    this.orders[0].orderQuotas.forEach((orderQuota) => {
      customersOfQuota.push(orderQuota.customer);
    });
    return customersOfQuota;
  }

  createOrderItems(): OrderItem[] {
    const orderItems = new Array();
    this.orders[0].orderItems.forEach((orderItem) => {
      orderItems.push(
        {
          productName: orderItem.product.name,
          quantity: orderItem.qtySupplied,
          price: orderItem.itemValue,
          total: orderItem.itemValue * orderItem.qtySupplied
        }
      );
    });
    return orderItems;
  }

  changeSelectedReprint(): void {
    this.orderOrInvoiceNumber = null;
    this.orders = new Array();
    this.invoicesPaid = new Array();
    if (this.selectedReprint === 'INVOICE') {
      this.titleHeader = 'Fatura';
    } else {
      this.titleHeader = 'Pedido';
    }
  }
}
