import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserAccessBanks } from '../models/user/user-access-banks';

@Injectable({
  providedIn: 'root'
})
export class UserAccessBanksService {

  constructor(private http: HttpClient) { }

  getMarinaUsersAndBankId(bankid:number): Observable<UserAccessBanks[]> {
      return this.http.get(environment.apiHost + '/secure/user/useraccessbanks/list/' + bankid).pipe(map((response: any) => response.data.access as UserAccessBanks[]));
  }

  postSaveUserAccessBanks(userAccessBanks: UserAccessBanks[]):Observable<any> {
    return  this.http.post(environment.apiHost + '/secure/user/useraccessbanks/save', userAccessBanks).pipe(map((response: any) => response.data as any));
  }


}
