<div class="table">
    <p-table #tt [value]="contractLogs" [resizableColumns]="true" [autoLayout]="true" [paginator]="true"
    [rows]="numberOfRows" [pageLinks]="3" [rowsPerPageOptions]="isDialog? null : [10,20,30,50,100]">
        <ng-template pTemplate="header">
            <tr>
                <th>Data/hora</th>
                <th>Usuário</th>
                <th>Origem</th>
                <th>Contrato</th>
                <th>Ação</th>
                <th>Visualizar</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-contractLog>
            <tr>
                <td>{{contractLog.logTime | date: 'dd/MM/yyyy - HH:mm'}}</td>
                <td [pTooltip]="contractLog.user ? contractLog.user.fullName : ''" tooltipPosition="top">{{contractLog.user ? contractLog.user.username : ''}}</td>
                <td>{{contractLog.origin}}</td>
                <td>{{contractLog.name}}</td>
                <td>{{contractLog.operationLog | translate}}</td>
                <td>
                    <div class="table-action" (click)="openContractLogDialog(contractLog)" pTooltip="Visualizar" *ngIf="contractLog.oldDatas"
                        tooltipPosition="bottom">
                        <i class="pi pi-eye"></i>
                    </div>
                </td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="7">
                    Nenhum log encontrado
                </td>
            </tr>
        </ng-template>

    </p-table>
</div>
