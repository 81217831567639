import {Component, Input, OnInit} from '@angular/core';
import {MarinaOrderService} from "../../../../../services/marina-order.service";

@Component({
  selector: 'app-service-customer-orders-history',
  templateUrl: './service-customer-orders-history.component.html',
  styleUrls: ['./service-customer-orders-history.component.scss']
})
export class ServiceCustomerOrdersHistoryComponent implements OnInit {
  @Input()
  customerId: number;
  totalOrders: number;
  totalValueOrders: number;
  marinaOrders: any[] = [];

  constructor(private marinaService: MarinaOrderService,) {
  }

  ngOnInit(): void {
    this.findMarinaOrders()
  }

  calculateQuotaValue(value, quota: number): number {
    return (value * quota) / 100;
  }

  extract(idInvoice: any) {
    return true;
  }

  private findMarinaOrders() {
    const filterMarina = {
      'idBoat': null,
      'idCustomer': this.customerId,
      'origim': 'TUITION',
      'hasInvoice': ''
    }
    this.marinaService.getOrderMarina(filterMarina).subscribe({
      next: (value) => {
        this.marinaOrders = value;
        this.totalOrders = this.marinaOrders.reduce(
          (total, order) => total + order.value,
          0
        );
        this.totalValueOrders = 0;
        this.marinaOrders.forEach((order) => {
          this.totalValueOrders += order.orderQuotas.reduce(
            (total, oq) =>
              total + this.calculateQuotaValue(order.value, oq.quota),
            0
          );
        });
      }, error: (err) => {
        console.log(err)
      }
    })
  }
}
