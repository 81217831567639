<p-table [value]="marinaList" #dt1 [globalFilterFields]="['name']" responsiveLayout="scroll">
  <ng-template pTemplate="header">
    <tr>
      <th class="division">
        <p>Nome da Marina</p>
        <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Buscar Marina" />
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-marina>
    <tr (click)="this.close(marina.id)">
      <td class="table-td" field="name">{{ marina.name }}</td>
    </tr>
  </ng-template>
</p-table>
