import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DocumentContractView } from 'src/app/models/dtos/document-contract-view';

@Component({
  selector: 'app-verify-contracts-dialog',
  templateUrl: './verify-contracts-dialog.component.html',
  styleUrls: ['./verify-contracts-dialog.component.scss']
})
export class VerifyContractsDialogComponent implements OnInit {

  documents: DocumentContractView[] = [];

  constructor(
    public dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) { }

  ngOnInit(): void {
    if (this.config.data) {
      this.documents = this.config.data.documents;
    }
  }
}
