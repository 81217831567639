<div class="dialog-form"> 
    <div class="form-control">
        <label>Data De / Até</label>
        <p-calendar dateFormat="dd/mm/yy" name="dateRange" [monthNavigator]="true" appendTo="body"
        [yearNavigator]="true" [yearRange]="'2000:2055'" selectionMode="range" rPCalendarMask [(ngModel)]="dateRange"
        [showIcon]="true" i18n>
        </p-calendar>
    </div>

    <div class="makeDefault bottomButtons">
        <button class="easymarine danger firstButton" (click)="closeDialog()">Cancelar</button>
        <button class="easymarine primary ml-3" (click)="exportTable()">Exportar</button>
    </div>
</div>