<div class="totem">
    <div class="header">
        <h1 class="title">
            Plano de navegação
        </h1>
        <div class="buttons">
            <a [routerLink]="'/app/operational/checking'" class="close-button">
                <i class="pi pi-times-circle"></i>
            </a>
        </div>
    </div>
    <div class="body">
        <p-table #tt [value]="slings" >
            <ng-template pTemplate="header">
                <tr>
                    <th>Embarcação</th>
                    <th>Responsável</th>
                    <th>Solicitação</th>
                    <th>Status Barco</th>
                    <th>Status</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-sling>
                <tr [ngClass]="{ 'easy-danger-tr' : !isNavigationPlanComplete(sling.navigationPlan) && i % 2 == 0,
                'easy-danger-tr-2' : !isNavigationPlanComplete(sling.navigationPlan) && i % 2 != 0,
                'easy-success-tr' : isNavigationPlanComplete(sling.navigationPlan) && i % 2 == 0,
                'easy-success-tr-2' : isNavigationPlanComplete(sling.navigationPlan) && i % 2 != 0}"
                    (click)="onClick(sling)">
                    <td>{{sling.boat.name}}</td>
                    <td>{{sling.navigationPlan.responsible.name}}</td>
                    <td>{{sling.requestTime | date: 'dd/MM/yyyy'}}</td>
                    <td>{{sling.boatStatus.toUpperCase() | translate}}</td>
                    <td *ngIf="isNavigationPlanComplete(sling.navigationPlan) ; else incomplete">Finalizado</td>
                    <ng-template #incomplete>
                        <td>Pendente</td>
                    </ng-template>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
