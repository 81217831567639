import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderHistoricService {

  constructor(private http: HttpClient) {}

  private readonly API_URL = environment.apiHost + '/api-orders/secure/marina-order/historic';

  findHistoricByMarinaOrder(marinaOrder: any): Observable<any[]> {
    return this.http
    .get<any[]>(this.API_URL + '/find-by-marina-order/' + marinaOrder.id)
  }



}
