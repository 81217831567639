import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { WebSocketService } from './services/web-socket.service';
import { CalendarUtil } from './utils/calendar.util';
import { MessageUtil } from './utils/message.util';

// tslint:disable-next-line: ban-types
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    translateService: TranslateService,
    private config: PrimeNGConfig,
    private messageUtil: MessageUtil,
    private router: Router,
    private webSocketService: WebSocketService,
  ) {
    translateService.setDefaultLang('pt-br');
    translateService.use('pt-br');
    webSocketService.initializeWebSocketConnection();
  }

  async ngOnInit(): Promise<void> {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      gtag('config', 'UA-151149697-1',
          {
              page_path: evt.urlAfterRedirects
          }
      );
  });
    this.config.setTranslation(await (new CalendarUtil(this.messageUtil)).getLocaleOptions());
  }
}
