<div class="dialog-form">
    <p-table *ngIf="invoice.invoicePaids !== null" [value]="invoice.invoicePaids" class="primeTable"
        width="100%">
        <ng-template pTemplate="header">
            <tr>
                <th class="text-center">Responsável</th>
                <th class="text-center">Data</th>
                <th class="text-center">Valor</th>
                <th class="text-center">Desconto</th>
                <th class="text-center">Iss Retido</th>
                <th class="text-center">Juros</th>
                <th class="text-center">Conta</th>
                <th class="text-center">Forma de Pagamento</th>
                <th class="text-center">Cancelado</th>
                <th class="text-center">Opções</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-payment>
            <tr>
                <td class="text-center">{{ getEmployeeName(payment) }}</td>
                <td class="text-center">{{ payment.datePaid | date: 'dd/MM/yyyy' }}</td>
                <td class="text-center">{{ payment.value | number:'1.2-2' }}</td>
                <td class="text-center">{{ payment.discount | number:'1.2-2' }}</td>
                <td class="text-center">{{ payment.withheldIssCityTax | number:'1.2-2' }}</td>
                <td class="text-center">{{ payment.interest | number:'1.2-2' }}</td>
                <td class="text-center">{{ payment.accountName }}</td>
                <td class="text-center">{{ payment.paymentMethodName }}</td>
                <td class="text-center"><strong><i class="pi pi-check" *ngIf="payment.canceled === true"></i></strong>
                </td>
                <td class="text-center">
                    <span class="badge-danger pointer" (click)="cancelPayment(payment)"
                        *ngIf="payment.canceled !== true">
                        Cancelar pagamento&nbsp;<i class="pi pi-ban"> </i>
                    </span>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
