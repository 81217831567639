import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-contract-log-general-data',
  templateUrl: './contract-log-general-data.component.html',
  styleUrls: ['./contract-log-general-data.component.scss']
})
export class ContractLogGeneralDataComponent implements OnInit {

  @Input() contractLog;
  @Input() isNew;

  oldDatasMap

  constructor() { }

  ngOnInit(): void {
    this.oldDatasMap = new Map(Object.entries(this.contractLog.oldDatas));
  }

  isChangedAffected(){
    return this.oldDatasMap.has('affected') && this.contractLog.oldDatas.affected != this.contractLog.affected;
  }

  getAffected(): boolean {
    let affected = this.contractLog.affected;
    if(this.isChangedAffected() && !this.isNew) {
      affected = this.contractLog.oldDatas.affected;
    }
    return affected;
  }

  isChangedFileUrl(){
    return this.oldDatasMap.has('url') && this.contractLog.oldDatas.url != this.contractLog.url;
  }

  hasFileUrl(): boolean{
    return this.getFileUrl() !=null && this.getFileUrl().trim().length > 0;
  }

  getFileUrl(): string {
    let url = this.contractLog.url;
    if(this.isChangedFileUrl() && !this.isNew) {
      url = this.contractLog.oldDatas.url;
    }
    return url;
  }

  openUrl(): void {
    window.open(this.getFileUrl());
  }
}
