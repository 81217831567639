<div class="back-principal">
  <span>Tire suas dúvidas sobre o EasyMarine. </span>
  <br />
  <br />
  <div class="table container-fluid">
    <p-table [value]="list" class="primeTable p-datatable">
      <ng-template pTemplate="body" let-item  let-rowIndex="rowIndex">
        <tr class="linha-tabela" (click)="openFaq(item)">
          <td class="celula-esquerda">
            {{ item.title }}
          </td>
          <td class="celula-direita">
            >
          </td>
        </tr>
        <tr>
          <td [ngClass]="{'borda': rowIndex <4}"> </td>
          <td [ngClass]="{'borda': rowIndex <4}"> </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="nao-encontrou">Não encontrou a resposta que procura?</div>
  <div class="nao-encontrou" style="margin-top: 16px;">
   <!-- <a target="_blank" href="urlHelp" style="color: #2094F0;"> -->
    <button class="easymarine primary" (click)="getUrlAtual()" style="border-radius: 10px; width: 191px;">Ver manuais</button>
  <!-- </a> -->
  </div>
  <div class="nao-encontrou" style="margin-top: 16px;">
   <a href="#/app/my-account/ticket" style="color: #2094F0;">Falar com atendimento</a>
   <div class="nao-encontrou" style="margin-top: 16px;"></div>
</div>
