import { LiberationStatusEnum } from './liberation-status-enum';

export class AccessPermissionFilter {
  permissionId: number;
  visitorId: number;
  visitorName: string;
  visitorDocument: string;
  liberationStatus: LiberationStatusEnum;
  customerResponsibleId: number;
  permissionStartDate: Date;
  permissionEndDate: Date;
  carPlate: string;
  marinaId: number;
  pageIndex: number;
  pageSize: number;
  sortField: string = 'permission_start_date';
  sortDirection: string = 'DESC';
  liberation: boolean = null;
  groupType: string;
}
