import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-boat-movement-group',
  templateUrl: './boat-movement-group.component.html',
  styleUrls: ['./boat-movement-group.component.scss']
})
export class BoatMovementGroupComponent implements OnInit {

  @Input()
  params;

  constructor() { }

  ngOnInit(): void {

  }

}
