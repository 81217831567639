import {Component, EventEmitter, Inject, Input, OnInit, Output, Renderer2} from '@angular/core';
import {Marina} from 'src/app/models';
import {PositionMarina} from 'src/app/models/position-marina';
import {StorageUtil} from 'src/app/utils/storage.util';
import {APPCONFIG} from '../../../../../config';
import {DOCUMENT} from '@angular/common';

declare const google;


@Component({
  selector: 'app-range-map',
  templateUrl: './range-map.component.html',
  styleUrls: ['./range-map.component.scss']
})
export class RangeMapComponent implements OnInit {

  @Input()
  radiusOfGyration: number;
  @Input()
  tipo:string;
  @Input()
  mapNumber:string;

  @Output()
  newRadiusEvent = new EventEmitter<number>();

  map: google.maps.Map;
  marker: google.maps.Marker;
  zoom: number;
  positionMarina: PositionMarina;
  radiusCircle: google.maps.Circle;

  radiusOfGyrations = [
    { label: '250', value: 250 },
    { label: '500', value: 500 },
    { label: '750', value: 750 },
    { label: '1000', value: 1000 },
    { label: '1500', value: 1500 },
    { label: '2000', value: 2000 },
    { label: '2500', value: 2500 },
    { label: '3000', value: 3000 },
    { label: '3500', value: 3500 },
    { label: '4000', value: 4000 },
    { label: '4500', value: 4500 },
    { label: '5000', value: 5000 },
    { label: '5500', value: 5500 },
    { label: '6000', value: 6000 },
    { label: '6500', value: 6500 },
    { label: '7000', value: 7000 },
    { label: '7500', value: 7500 },
    { label: '8000', value: 8000 },
    { label: '8500', value: 8500 },
    { label: '9000', value: 9000 },
    { label: '9500', value: 9500 },
    { label: '10000', value: 10000 },
  ];

  customTooltipMessage:String = "";
  customTitle:String = "";

  currentMarina: Marina;
  googleMapsKey = APPCONFIG.googleMapsAPI;
  apiLoaded = false;

  constructor(@Inject(DOCUMENT) private document: Document, private renderer2: Renderer2) {
  }

  ngOnInit(): void {
    this.getMarinaAndCoordinates();
    if (this.currentMarina?.latitude && this.currentMarina?.longitude) {
      if (!this.apiLoaded) {
        const url = `https://maps.googleapis.com/maps/api/js?key=${this.googleMapsKey}`;
        this.loadScript(url).then(() => {
          this.apiLoaded = true;
          this.startMap();
        });
      } else {
        this.customization(this.tipo);
        this.startMap();
      }
    }
  }

  getMarinaAndCoordinates() {
    this.currentMarina = StorageUtil.getMarina()
    if (this.currentMarina.latitude && this.currentMarina.longitude) {
      this.positionMarina = {
        lat: Number(this.currentMarina.latitude),
        lng: Number(this.currentMarina.longitude),
      };
    }
  }

  customization(tipo: string) {
    switch (tipo) {
      case "SLINGUP":
        this.customTooltipMessage = "Raio limite para permitir que o cliente realize solicitação de subida da embarcação"
        this.customTitle = "Raio de subida"
        break;
      case "PREPARATION":
        this.customTooltipMessage = "Raio limite para permitir que o cliente ou marinheiro prepare a embarcação"
        this.customTitle = "Raio de preparo"
        break;
    }
  }

  setRadius(radiusOfGyration): void {
    if (this.radiusCircle) {
      this.radiusCircle.setRadius(radiusOfGyration);
      this.newRadiusEvent.emit(this.radiusOfGyration);
    }
  }

  startMap() {
    this.map = new google.maps.Map(document.getElementById(this.mapNumber), {
      zoom: 12,
      center: this.positionMarina,
      gestureHandling: 'none',
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeControl: true,
      scaleControl: true,
    });
    this.radiusCircle = new google.maps.Circle({
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.35,
      map: this.map,
      center: this.positionMarina,
      radius: this.radiusOfGyration,
    });
    this.marker = new google.maps.Marker({
      position: this.positionMarina,
      map: this.map,
      title: this.currentMarina.tradeName,
    });
  }

  private loadScript(url) {
    return new Promise((resolve, reject) => {
      const script = this.renderer2.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.text = ``;
      script.async = true;
      script.defer = true;
      script.onload = resolve;
      script.onerror = reject;
      this.renderer2.appendChild(this.document.body, script);
    });
  }
}
