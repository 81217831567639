
<div style=" margin-left: 12px; font-weight: bold; margin-top: 20px; margin-bottom: 20px;">
<span style="font-size: 20px; color: red; margin-left: 12px; font-weight: bold; margin-top: 20px; margin-bottom: 20px;"> Ao informar o pagamento dessa parcela, o boleto será cancelado no sistema, essa operação é irreversível.
</span>
<br>
</div>
<input type="checkbox" [(ngModel)]="noBillet" (click)="limpaMarcacao()"> Efetuar pagamento sem boleto
<div class="table">
  <p-table 
        [value]="this.billets"
        [paginator]="true" [lazy]="false" #table
        [(selection)]="selectedBillet" 
        [rowsPerPageOptions]="[10,20,30,50,100]"
        [rows]="10"
        [globalFilterFields]="['customer', 'invoiceNumber', 'account', 'wallet']"
      styleClass="easy-table"
      >

      <ng-template pTemplate="header">

          <tr>
              <th class="text-center">
                  <input *ngIf="!noBillet" type="checkbox" class="primeCheckbox" (click)="selectAll()">
              </th>

              <th class="text-center" [pSortableColumn]="'marinaCompany'" *ngIf="marinaCompanies?.length > 0">
                  Empresa
                  <p-sortIcon [field]="'marinaCompany'"></p-sortIcon>
              </th>
              <th class="text-center" [pSortableColumn]="'sequence'">
                  Parcela
                  <p-sortIcon [field]="'sequence'"></p-sortIcon>
              </th>
              <th class="text-center">Boleto</th>
              <th class="text-center" [pSortableColumn]="'customer'">
                  Cliente
                  <p-sortIcon [field]="'customer'"></p-sortIcon>
              </th>
              <th class="text-center" [pSortableColumn]="'group'">
                  Grupo
                  <p-sortIcon [field]="'group'"></p-sortIcon>
              </th>
              <th class="text-center" [pSortableColumn]="'issueDate'">
                  Emissão
                  <p-sortIcon [field]="'issueDate'"></p-sortIcon>
              </th>
              <th class="text-center" [pSortableColumn]="'dueDate'">
                  Vencimento
                  <p-sortIcon [field]="'dueDate'"></p-sortIcon>
              </th>
              <th class="text-center" [pSortableColumn]="'identifier'">
                  Nosso número
                  <p-sortIcon [field]="'identifier'"></p-sortIcon>
              </th>
              <th class="text-center" [pSortableColumn]="'idCarteiraCobranca'">
                  Conta
                  <p-sortIcon [field]="'idCarteiraCobranca'"></p-sortIcon>
              </th>
              <th class="text-center" [pSortableColumn]="'idCarteiraCobranca'">
                  Carteira
                  <p-sortIcon [field]="'idCarteiraCobranca'"></p-sortIcon>
              </th>
              <th class="text-center" [pSortableColumn]="'value'">
                  Valor
                  <p-sortIcon [field]="'value'"></p-sortIcon>
              </th>
              <th class="text-center" [pSortableColumn]="'valuePaid'">
                  Valor Pago
                  <p-sortIcon [field]="'valuePaid'"></p-sortIcon>
              </th>
              <th class="text-center">
                Cancelado em:
              </th>
              <th class="text-center">
                  C. banco
              </th>
          </tr>
      </ng-template>
      <ng-template pTemplate="body" let-billet>
          <tr [ngClass]="{'inactive': !billet.active, 'paid': billet.paid}">
              <td class="text-center"
                  [pTooltip]="billet.anticipated ? 'Boleto antecipado não pode ser cancelado.' : (billet.paid ? 'O boleto já está pago.' : (!billet.active ? 'O boleto já se encontra cancelado.' : ''))"
                  tooltipPosition="bottom">
                  <p-tableRadioButton [value]="billet" [name]="'selectedBillet'" *ngIf="!noBillet"  >
                  </p-tableRadioButton>
              </td>

              <td class="text-center" *ngIf="marinaCompanies?.length > 0"
                  [pTooltip]="billet.marinaCompany ? billet.marinaCompany : loggedMarina.companyName"
                  tooltipPosition="bottom">
                  {{ getCompanyIndex(billet.marinaCompanyId) }}
              </td>
              <td class="text-center">
                  {{ billet?.sequence }}
              </td>
              <td class="text-center" [ngClass]="{'anticipated' : billet?.anticipated}">
                <td class="text-center" [ngClass]="{'anticipated' : billet?.anticipated}">
                    <ng-container  >
                        <a *ngIf="billet?.active" [href]="billet?.documentUrl" target="_blank">
                            <app-barcode-icon *ngIf="billet?.registered && !billet.paid" [styleBillet]="'registered'" [isNix]="checkIfHaveNixBillet(billet) === true ? true : false" [toolTipMsg]="getTooltip(billet)"></app-barcode-icon>
                            <app-barcode-icon *ngIf="billet.paid" [styleBillet]="'paid'" [isNix]="checkIfHaveNixBillet(billet) === true ? true : false" [toolTipMsg]="getTooltip(billet)"></app-barcode-icon>
                            <ng-container *ngIf="!billet?.registered && !billet.paid && billet.processed">
                                <app-barcode-icon *ngIf="billet.active && billet.valuePaid > 0" [styleBillet]="'paid'" [isNix]="checkIfHaveNixBillet(billet) === true ? true : false" [toolTipMsg]="getTooltip(billet)"></app-barcode-icon>
                                <app-barcode-icon *ngIf="!billet.valuePaid && isToday(billet)" [styleBillet]="'waiting'" [isNix]="checkIfHaveNixBillet(billet) === true ? true : false" [toolTipMsg]="getTooltip(billet)"></app-barcode-icon>
                                <app-barcode-icon *ngIf="billet.active && !billet.valuePaid && !isToday(billet) && !billet.registered && !billet.refused" [styleBillet]="'pending'" [isNix]="checkIfHaveNixBillet(billet) === true ? true : false" [toolTipMsg]="getTooltip(billet)"></app-barcode-icon>
                                <app-barcode-icon *ngIf="billet.refused && billet.active" [styleBillet]="'refused'" [isNix]="checkIfHaveNixBillet(billet) === true ? true : false" [toolTipMsg]="getTooltip(billet)"></app-barcode-icon>
                            </ng-container>
                        </a>
                        <ng-container *ngIf="!billet?.registered && !billet?.paid && !billet?.processed && billet?.active">
                            <app-barcode-icon *ngIf="!billet.valuePaid && isToday(billet)" [styleBillet]="'waiting'" [isNix]="checkIfHaveNixBillet(billet) === true ? true : false" [toolTipMsg]="'Aguardando o arquivo de retorno'"></app-barcode-icon>
                            <app-barcode-icon *ngIf="billet.active && !billet.valuePaid && !isToday(billet) && !billet.registered && !billet.refused" [styleBillet]="'pending'" [isNix]="checkIfHaveNixBillet(billet) === true ? true : false" [toolTipMsg]="'Aguardando o arquivo de retorno'"></app-barcode-icon>
                        </ng-container>
                        <app-barcode-icon *ngIf="!billet?.active && !billet.paid" [styleBillet]="'canceled'" [isNix]="checkIfHaveNixBillet(billet) === true ? true : false" [toolTipMsg]="billet.bankCanceledDate ? 'Boleto cancelado em: ' + getDateFormatted(billet.bankCanceledDate) : 'Boleto cancelado'"></app-barcode-icon>
                    </ng-container>
                </td>           
              <td [pTooltip]="billet.emailCollectionArray ? billet.emailCollectionArray.join('\n') : ''"
                  tooltipPosition="bottom">
                  {{ invoice?.customer.name }}
              </td>
              <td class="text-center">
                  {{ billet?.group ? billet?.group : 'Sem grupo' }}
              </td>
              <td class="text-center">
                  {{ billet?.issueDate | date:'dd/MM/yy'}}
              </td>
              <td class="text-center">
                  {{ billet?.dueDate | date:'dd/MM/yy' }}
              </td>
              <td class="text-center">
                  {{ billet?.identifier }}
              </td>
              <td class="text-center">
                  {{ billingPortfolio(billet).conta.nome }}
              </td>
              <td class="text-center">
                  {{ billingPortfolio(billet).nome }}
              </td>
              <td class="text-right">
                  {{ billet?.value | number:'1.2-2' }}
              </td>
              <td class="text-right" [pTooltip]="billet?.valuePaid > 0 ? getDateFormatted(billet.datePaid) : ''"
                  tooltipPosition="bottom">
                  {{ billet?.valuePaid | number:'1.2-2' }}
              </td>
              <td class="text-center">
                {{ billet?.cancelDate   | date:'dd/MM/yy'}}

              </td>
              <td class="text-center"
                  [pTooltip]="!billet.active ? (billet.bankCanceled ? 'Data cancelamento: ' + getDateFormatted(billet.bankCanceledDate) : 'Não cancelado no banco') : ''"
                  tooltipPosition="bottom">
                  <span (click)="cancelBilletBank(billet)" *ngIf="!billet.active">
                      <i class="pi pi-check" style="color: green" *ngIf="billet.bankCanceled"></i>
                      <i class="pi pi-times" style="color: red" *ngIf="!billet.active && !billet.bankCanceled"></i>
                  </span>
              </td>
          </tr>
      </ng-template>
  </p-table>
</div>

<div class="buttons"  style=" margin-left: 12px; font-weight: bold; margin-top: 20px; margin-bottom: 20px; ;">

  <div class="form-button">
      <button class="easymarine primary" [disabled]="activeButton()" (click)="close()">{{ 'Confirma' | translate }}</button>
  </div>
</div>
