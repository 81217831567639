<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">Operacional > Plano de navegação</span>
        <div class="new-header-buttons">
            <button class="fab-button primary " (click)="exportTable()" label="Exportar CSV"
            pTooltip="A exportação será de acordo com os filtros selecionados" tooltipPosition="left">
                <i class="pi pi-file"></i>
            </button>
            <button class="fab-button primary" appFiltersButton pTooltip="Exibir/Ocultar Filtros" tooltipPosition="left">
                <i class="pi pi-filter"></i>
            </button>
        </div>
    </p-header>

    <form class="filters" appFilters (submit)="findSlingByFilter()">
        <div class="filter-row">
            <div class="form-control">
                <label>De</label>
                <p-calendar dateFormat="dd/mm/yy" name="navigationPlanFrom" [monthNavigator]="true" appendTo="body"
                    [yearNavigator]="true" [yearRange]="'2000:2055'" rPCalendarMask [(ngModel)]="navigationPlanFrom"
                    [showIcon]="true" i18n>
                </p-calendar>
            </div>
            <div class="form-control">
                <label>Até</label>
                <p-calendar dateFormat="dd/mm/yy" name="navigationPlanTo" [monthNavigator]="true" [yearNavigator]="true" appendTo="body"
                    [yearRange]="'2000:2055'" rPCalendarMask [(ngModel)]="navigationPlanTo" [showIcon]="true"
                    i18n>
                </p-calendar>
            </div>
            <div class="form-control">
                <label>{{ 'BOAT' | translate }}</label>
                <p-dropdown class="field" placeholder="Selecione..." [filter]="true" [options]="boatsFilter"
                    appendTo="body" [(ngModel)]="idBoat" name="idBoat">
                </p-dropdown>
            </div>
            <div class="buttons">
                <div class="form-button">
                    <button class="primary easymarine" type="submit"
                        [disabled]="!navigationPlanFrom || !navigationPlanTo">{{ 'FIND' | translate }}</button>
                </div>
                <div class="form-button">
                    <button *ngIf="selectedSlings.length > 0" class="success easymarine" type="button"
                        (click)="printNavitagionPlan()">{{ 'Exportar Plano de Navegação' | translate }}</button>
                </div>
            </div>
        </div>
    </form>

    <div class="table">
        <p-table #tt [value]="slings" [pageLinks]="3" [rowsPerPageOptions]="[10,20,30,50,100]"
            [(selection)]="selectedSlings" autoLayout="true" resetPageOnSort="true"
            [rows]="slingConfig?.numberOfRecordsPerPage" paginator="true" styleClass="easy-table">
            <ng-template pTemplate="caption">
                <span style="font-weight: bold; float: right; margin-top: 5px;">{{ 'TOTAL-REGISTERS' | translate }}:
                    {{tt.totalRecords}} {{'OF' | translate}} {{slings.length}}
                </span>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th class="text-center">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th>Solicitante</th>
                    <th>Proprietário</th>
                    <th>CPF/CNPJ</th>
                    <th>Embarcação</th>
                    <th>Prev Saída</th>
                    <th>Prev Retorno</th>
                    <th>Total a bordo</th>
                    <th>Destino</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-sling>
                <tr [ngClass]="{'canceled': sling.slingStatus === 'CANCELED'}"
                    [pTooltip]="sling.slingStatus === 'CANCELED' ? 'Cancelado' : ''" tooltipPosition="bottom">
                    <td class="text-center">
                        <p-tableCheckbox [value]="sling"></p-tableCheckbox>
                    </td>
                    <td>{{sling.navigationPlan.responsible.name}}</td>
                    <td>{{getBoatOwner(sling).name}}</td>
                    <td>{{getBoatOwner(sling).federalId}}</td>
                    <td>{{sling.boat.name}}</td>
                    <td>{{sling.executionForecast | date: 'dd/MM/yy - HH:mm'}}</td>
                    <td>{{sling.navigationPlan.returnTime | date: 'dd/MM/yy - HH:mm'}}</td>
                    <td>{{sling.navigationPlan.crewQty + sling.navigationPlan.childQty ?? 0}}</td>
                    <td>{{sling.navigationPlan.destination}}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="9">
                        Nenhuma lingada encontrada
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-fieldset>
