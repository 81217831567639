import { BoatSailor } from './boat-sailor';
import { Sailor } from './sailor';
import { Boat } from './boat';
import { NavigationPlan } from './navigation-plan';
import { MarinaOrder } from './marina-order';
import { Marina } from './marina';
import { BoatStatus, SlingStatus } from './enums';
import { RequestOrigin } from './enums/request-origin';

export class Sling {
    id: number;
    requestTime: any;
    executionForecast: any;
    slingDownAt: any;
    slingUpAt: any;
    boat: Boat;
    boatSailor: BoatSailor
    slingStatus: SlingStatus;
    boatStatus: BoatStatus;
    navigationPlan: NavigationPlan;
    marinaOrder: MarinaOrder;
    marinaOrders: MarinaOrder[];
    marina: Marina;
    requestDateTime: string;
    prepare: boolean;
    slingOrigin: RequestOrigin;
    executionForecastDateTime: string;
    slingAccessories: any[];
    dateGeneric: any;
    ownFuelSupply: boolean;
    slingNextDay: boolean;
    finishedOnWater: boolean;
    hasSlingFinishedOnWater: boolean;
    toConfirm: boolean;

    constructor() {
        this.navigationPlan = new NavigationPlan();
        this.marinaOrder = new MarinaOrder();
        this.marinaOrders = [];
        this.marina = new Marina();
        this.boat = new Boat();
        this.slingAccessories = new Array();
        this.slingStatus = SlingStatus.PENDING_DOWN;
        this.boatStatus = BoatStatus.WAITING_PREPARATION;
        this.finishedOnWater = false;
    }
}
