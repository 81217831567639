export class ConfigResizePanel {

    imageConfig: any;

    constructor(imageConfig: any) {
        this.imageConfig = imageConfig;
    }

    resizeImageConfig(advertisement: number): void {
        switch (advertisement) {
            case 1:
                this.imageConfig.class1 = 'flex1';
                this.imageConfig.width1 = 1080;
                this.imageConfig.class2 = 'flex0';
                this.imageConfig.width2 = 0;
                this.imageConfig.class3 = 'flex0';
                this.imageConfig.width3 = 0;
                this.imageConfig.class4 = 'flex0';
                this.imageConfig.width5 = 0;
                break;
            case 2:
                this.imageConfig.class1 = 'flex1';
                this.imageConfig.width1 = 270;
                this.imageConfig.class2 = 'flex2';
                this.imageConfig.width2 = 810;
                this.imageConfig.class3 = 'flex0';
                this.imageConfig.width3 = 0;
                this.imageConfig.class4 = 'flex0';
                this.imageConfig.width5 = 0;
                break;
            case 3:
                this.imageConfig.class1 = 'flex1';
                this.imageConfig.width1 = 540;
                this.imageConfig.class2 = 'flex1';
                this.imageConfig.width2 = 540;
                this.imageConfig.class3 = 'flex0';
                this.imageConfig.width3 = 0;
                this.imageConfig.class4 = 'flex0';
                this.imageConfig.width5 = 0;
                break;
            case 4:
                this.imageConfig.class1 = 'flex2';
                this.imageConfig.width1 = 810;
                this.imageConfig.class2 = 'flex1';
                this.imageConfig.width2 = 270;
                this.imageConfig.class3 = 'flex0';
                this.imageConfig.width3 = 0;
                this.imageConfig.class4 = 'flex0';
                this.imageConfig.width5 = 0;
                break;
            case 5:
                this.imageConfig.class1 = 'flex1';
                this.imageConfig.width1 = 270;
                this.imageConfig.class2 = 'flex1';
                this.imageConfig.width2 = 270;
                this.imageConfig.class3 = 'flex2';
                this.imageConfig.width3 = 540;
                this.imageConfig.class4 = 'flex0';
                this.imageConfig.width5 = 0;
                break;
            case 6:
                this.imageConfig.class1 = 'flex2';
                this.imageConfig.width1 = 540;
                this.imageConfig.class2 = 'flex1';
                this.imageConfig.width2 = 270;
                this.imageConfig.class3 = 'flex1';
                this.imageConfig.width3 = 270;
                this.imageConfig.class4 = 'flex0';
                this.imageConfig.width5 = 0;
                break;
            case 7:
                this.imageConfig.class1 = 'flex1';
                this.imageConfig.width1 = 270;
                this.imageConfig.class2 = 'flex2';
                this.imageConfig.width2 = 540;
                this.imageConfig.class3 = 'flex1';
                this.imageConfig.width3 = 270;
                this.imageConfig.class4 = 'flex0';
                this.imageConfig.width5 = 0;
                break;
            case 8:
                this.imageConfig.class1 = 'flex1';
                this.imageConfig.width1 = 270;
                this.imageConfig.class2 = 'flex1';
                this.imageConfig.width2 = 270;
                this.imageConfig.class3 = 'flex1';
                this.imageConfig.width3 = 270;
                this.imageConfig.class4 = 'flex1';
                this.imageConfig.width4 = 270;
                break;
            default:
                break;
        }
    }
}
