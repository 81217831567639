<p-fieldset>
    <p-header class="article-title">
        <div class="article-title">{{ title | translate }}</div>
    </p-header>
    <div class="content-log">
        <div>
            <label>{{ 'ZIP' | translate }}</label>
            <label [ngClass]="{'item-new-log': isChangedZip() && isNew, 'item-old-log': isChangedZip() && !isNew}">
                {{ isChangedZip() && !isNew ? addressLogOldData.zip: addressLog.zip }}
            </label>
        </div>
        <div>
            <label>{{ 'COUNTRY' | translate }}</label>
            <label [ngClass]="{'item-new-log': isChangedCountry() && isNew, 'item-old-log': isChangedCountry() && !isNew}">
                {{ isChangedCountry() && !isNew ? addressLogOldData.country: addressLog.country }}
            </label>
        </div>
        <div>
            <label>{{ 'FS' | translate }}</label>
            <label [ngClass]="{'item-new-log': isChangedState() && isNew, 'item-old-log': isChangedState() && !isNew}">
                {{ isChangedState() && !isNew ? addressLogOldData.state: addressLog.state }}
            </label>
        </div>
        <div>
            <label>{{ 'CITY' | translate }}</label>
            <label [ngClass]="{'item-new-log': isChangedCity() && isNew, 'item-old-log': isChangedCity() && !isNew}">
                {{ isChangedCity() && !isNew ? addressLogOldData.city: addressLog.city }}
            </label>
        </div>
        <div>
            <label>{{ 'STREET' | translate }}</label>        
            <label [ngClass]="{'item-new-log': isChangedStreet() && isNew, 'item-old-log': isChangedStreet() && !isNew}">
                {{ isChangedStreet() && !isNew ? addressLogOldData.street: addressLog.street }}
            </label>
        </div>
        <div>
            <label>{{ 'DISTRICT' | translate }}</label>
            <label [ngClass]="{'item-new-log': isChangedDistrict() && isNew, 'item-old-log': isChangedDistrict() && !isNew}">
                {{ isChangedDistrict() && !isNew ? addressLogOldData.district: addressLog.district }}
            </label>
        </div>
        <div>
            <label>{{ 'NUMBER' | translate }}</label>
            <label [ngClass]="{'item-new-log': isChangedNumber() && isNew, 'item-old-log': isChangedNumber() && !isNew}">
                {{ isChangedNumber() && !isNew ? addressLogOldData.number: addressLog.number }}
            </label>
        </div>
        <div>
            <label>{{ 'COMPLEMENT' | translate }}</label>
            <label [ngClass]="{'item-new-log': isChangedComplement() && isNew, 'item-old-log': isChangedComplement() && !isNew}">
                {{ isChangedComplement() && !isNew ? addressLogOldData.complement: addressLog.complement }}
            </label>
        </div>
    </div>
</p-fieldset>
<br>