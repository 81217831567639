<div class="form-control">
  <label for="customerSelect">{{ 'CUSTOMER' | translate }}</label>
  <p-dropdown id="customerSelect" placeholder="Selecione..."
    *ngIf="!disabled; else ifCustomerDisabled"
    [options]="customersFilter" [(ngModel)]="selectedCustomerId"
    appendTo="body" name="selectedCustomerId"
    (onChange)=" changeSelectedCustomer($event)"
    (onBlur)="onTouched($event)"
    [filter]="true" [disabled]="disabled" required>
    <ng-template let-customer pTemplate="item">
        <div [ngStyle]="{'padding': isToten && '5px 0px'}">{{customer.label}}</div>
    </ng-template>
  </p-dropdown>
  <ng-template #ifCustomerDisabled>
    <input pInputText type="text" name="selectedCustomerId" [value]="customer?.name" disabled="true">
  </ng-template>
</div>
