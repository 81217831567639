<p-table [value]="slingsUpCheck" styleClass="sling-table" autoLayout="true">
    <ng-template pTemplate="header">
        <tr>
            <th class="text-center">Origem</th>
            <th class="text-center">
                {{slingConfig && slingConfig?.queueControl ? 'Ordem' : 'Previsão'}}
            </th>
            <th class="text-center">Vaga</th>
            <th class="text-left">Embarcação</th>
            <th class="text-left">Modelo</th>
            <th class="text-center" *ngIf="hasSailorConfig()">Possui Marinheiro</th>
            <th class="text-center">Pedido(s) avulso(s)</th>
            <th class="text-center"></th>
            <th class="text-center"></th>
            <th class="text-center"></th>
            <th class="text-center">Situação</th>
            <th class="text-center"></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-slingUpCheck let-i="rowIndex">
        <tr>
            <!-- Origem -->
            <td class="text-center">
                <img [src]="slingUtil.getImageOriginSling(slingUpCheck)" [title]="slingUtil.getOriginName(slingUpCheck)">
            </td>

            <!-- Ordem/Data retorno -->
            <td class="text-center">
                <span *ngIf="slingConfig?.queueControl">{{(i+1)}}</span>
                <ng-container *ngIf="!slingConfig?.queueControl && !slingUpCheck.finishedOnWater">
                    <span [ngClass]="{'em-alert': notEqualDate(slingUpCheck)}" class="sling-time">
                        {{slingUpCheck.navigationPlan.returnTime | date: notEqualDate(slingUpCheck) ? 'dd/MM - HH:mm':'HH:mm'}}
                    </span>
                    <span class="sling-time-mobile" *ngIf="!slingConfig?.queueControl && !slingUpCheck.finishedOnWater">
                        <span *ngIf="notEqualDate(slingUpCheck)">
                            {{slingUpCheck.navigationPlan.returnTime | date:'dd/MM'}}
                            <br>
                        </span>
                        {{slingUpCheck.navigationPlan.returnTime | date: 'HH:mm'}}
                    </span>
                </ng-container>
                <span class="finish" *ngIf="!slingConfig?.queueControl && slingUpCheck.finishedOnWater">
                    NAV. CONCLUIDA
                </span>
            </td>

            <!-- Vaga -->
            <td class="text-left" *ngIf="slingUpCheck.boat.vacancy || !isMobile">
                {{slingUpCheck.boat.vacancy ? slingUpCheck.boat.vacancy.code : ""}}
            </td>

            <!-- Embarcação -->
            <td class="text-left">
                {{slingUpCheck.boat.name}}
            </td>

            <!-- Model -->
            <td class="text-left">
                {{slingUpCheck.boat.shipyardModel.shipType.toUpperCase() | translate}}&nbsp;
                {{slingUpCheck.boat.commercialLength}}'
            </td>

            <!-- Possui marinheiro-->
            <td *ngIf="hasSailorConfig()">
                <span *ngIf=" hasBoatSailor(slingUpCheck)">
                  <img class="size-icon-sling-check" [src]="getImageSailorResponsable()" />
                </span>
            </td>

            <!-- Pedido avulso -->
            <td *ngIf="!slingUpCheck.finishedOnWater || !isMobile">
                <button *ngIf="!slingUpCheck.finishedOnWater" class="sling-button" pTooltip="Incluir pedido"
                    tooltipPosition="bottom" (click)="loadMarinaOrders(slingUpCheck)">
                    <img src="assets/images/pedido.png">
                </button>
            </td>

            <!-- Combustível -->
            <td
                *ngIf="(!slingUpCheck.finishedOnWater && (hasFuel(slingUpCheck) || slingUpCheck.ownFuelSupply === false)) || !isMobile">
                <div class="icon-button"
                    *ngIf="!slingUpCheck.finishedOnWater && (hasFuel(slingUpCheck) || slingUpCheck.ownFuelSupply === false)"
                    (click)="checkPendingFuel(slingUpCheck.marinaOrder.orderItems) && slingUpCheck.prepare && openDialogFuelOrder(slingUpCheck)">
                    <div *ngIf="hasFuel(slingUpCheck)" tooltipPosition="bottom"
                        [pTooltip]="slingUtil.getStatusFuel(slingUpCheck) === 'primary' ? 'Fornecer combustível': 'Pedido atendido'">
                        <div class="container">
                          <img [src]="slingUtil.getImageIconFuel(slingUpCheck)">
                          <label class="centered" *ngIf="slingUpCheck.boat.independentTanks;">2T</label>
                        </div>
                    </div>
                    <div class="image-table" *ngIf="!hasFuel(slingUpCheck) && slingUpCheck.ownFuelSupply === false"
                        pTooltip="Abastecimento próprio" tooltipPosition="bottom">
                        <div class="container">
                          <img [src]="slingUtil.getImageIconFuel(slingUpCheck)">
                          <label class="centered" *ngIf="slingUpCheck.boat.independentTanks;">2T</label>
                        </div>
                    </div>
                    <div class="column flex-badge" *ngIf="hasFuel(slingUpCheck)">
                        <div class="badge"
                            [ngClass]="{'attempted': !checkPendingFuel(slingUpCheck.marinaOrder.orderItems)}">
                            {{orderUtil.fuelQuantityView(getFuel(slingUpCheck.marinaOrder.orderItems))}}
                        </div>
                        <div class="badge fuel-type"
                            [pTooltip]="getFuel(slingUpCheck.marinaOrder.orderItems).product.name"
                            [ngClass]="{'attempted': !checkPendingFuel(slingUpCheck.marinaOrder.orderItems)}"
                            tooltipPosition="bottom">
                            {{orderUtil.abbreviatedProductName(getFuel(slingUpCheck.marinaOrder.orderItems))}}
                        </div>
                    </div>
                </div>
            </td>

            <!-- Consumíveis -->
            <td class="flex1" *ngIf="(hasConsumables(slingUpCheck) && !slingUpCheck.finishedOnWater) || !isMobile">
                <div class="icon-button" *ngIf="hasConsumables(slingUpCheck) && !slingUpCheck.finishedOnWater"
                    (click)="checkPendingConsumables(slingUpCheck.marinaOrder.orderItems) && slingUpCheck.prepare && openDialogConsumableOrder(slingUpCheck)">
                    <div class="flex1" tooltipPosition="bottom"
                        [pTooltip]="slingUtil.getStatusConsumable(slingUpCheck) === 'primary' ? 'Fornecer consumíveis': 'Pedido atendido'">
                        <img [src]="slingUtil.getImageIconConsumable(slingUpCheck)">
                    </div>
                    <div class="start flex-badge">
                        <div class="badge consumable"
                            [ngClass]="{'attempted': !checkPendingConsumables(slingUpCheck.marinaOrder.orderItems)}">
                            {{countConsumables(slingUpCheck.marinaOrder.orderItems)}}
                        </div>
                    </div>
                </div>
            </td>

            <!-- Acessórios -->
            <td *ngIf="(hasAcessories(slingUpCheck) && !slingUpCheck.finishedOnWater) || !isMobile">
                <div class="icon-button" *ngIf="hasAcessories(slingUpCheck) && !slingUpCheck.finishedOnWater"
                    (click)="checkPendingAcessories(slingUpCheck) && slingUpCheck.prepare && openDialogSlingAccessories(slingUpCheck)">
                    <div tooltipPosition="bottom"
                        [pTooltip]="slingUtil.getStatusAcessories(slingUpCheck) === 'primary' ? 'Fornecer acessórios': 'Pedido atendido'">
                        <img [src]="slingUtil.getImageIconAcessories(slingUpCheck)">
                    </div>
                    <div class="start flex-badge">
                        <div class="badge consumable" [ngClass]="{'attempted': !checkPendingAcessories(slingUpCheck)}">
                            {{slingUpCheck.slingAccessories.length}}
                        </div>
                    </div>
                </div>
            </td>

            <!-- Situação -->
            <td class="text-center" (click)="slingConfig.manageDeck && changeBoatStatus(slingUpCheck)">
                <img *ngIf="slingUpCheck.boatStatus === 'MOORED'" class="size-icon-sling-check"
                    [src]="'assets/images/deck' + (slingUpCheck.slingNextDay ? '_next' : '') + '.png'"
                    style="height: 45px;width: auto;" />
                <img *ngIf="slingUpCheck.boatStatus === 'SAILING'" class="size-icon-sling-check"
                    [src]="'assets/images/boat' + (slingUpCheck.slingNextDay ? '_next' : '') + '.png'"
                    style="height: 50px;width: auto;" />
            </td>

            <!-- Ações -->
            <td class="actions">
                <!-- style="display: flex; align-items: center; justify-content: flex-end; width: 100%; height: 100%;" -->
                <button class="sling-button" (click)="handleAction(slingUpCheck)" pTooltip="Próximas ações"
                    *ngIf="slingConfig?.queueControl" tooltipPosition="bottom">
                    <img src="assets/images/sing-in-alt.png">
                </button>
                <!-- style="display: flex; align-items: center; justify-content: flex-end; width: 100%; height: 100%;" -->
                <button class="sling-button" (click)="save(slingUpCheck)" pTooltip="Subir barco"
                    *ngIf="!slingConfig?.queueControl" tooltipPosition="bottom">
                    <img src="assets/images/subir.png">
                </button>
            </td>
        </tr>
    </ng-template>
</p-table>
