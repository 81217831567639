import { MessageUtil } from 'src/app/utils/message.util';
import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { NotificationGroup } from 'src/app/models/notification/notificationGroup';
import { PaginationFilter } from 'src/app/models/pagination-filter';
import { NotificationService } from 'src/app/services/notification.service';
import { Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { ProcessControlFormComponent } from '../../process-control-form/process-control-form.component';
import { StorageUtil } from 'src/app/utils/storage.util';

@Component({
  selector: 'app-header-button-notification',
  templateUrl: './header-button-notification.component.html',
  styleUrls: ['./header-button-notification.component.scss'],
})
export class HeaderButtonNotificationComponent implements OnInit {
  menuItems: any[];
  notRead: number = 0;
  orders: any;
  paginationFilter: PaginationFilter = new PaginationFilter();
  notReadList: NotificationGroup[] = [];
  showMenu: boolean = false;
  marinaId = StorageUtil.getMarinaId();
  constructor(
    private notificationService: NotificationService,
    private messageUtil: MessageUtil,
    private router: Router,
    private dialog: DialogService,
  ) {}

  ngOnInit() {
   // this.loadNotificationGroup();
  }

  openProcess(){
    this.dialog.open(ProcessControlFormComponent, {
      width: '60%',
      height: '80%',
      header: 'Lista de Processos'
    });
  }

  openNotifications() {
    console.log('openNotifications');
  }

  changeSelectedItem(order) {
    console.log('changeSelectedItem', order);
  }

  loadNotificationGroup() {
    this.notificationService
      .getByUserfromGroup(this.paginationFilter)
      .subscribe((response: any) => {
        this.notReadList = response.content;
        this.menuItems = [];
        let dataOld: String = '';
        this.notReadList.forEach((element) => {
          if (dataOld != element.data) {
            dataOld = element.data;
            this.menuItems.push({ item: element, new: true, update: false });
          }
          this.menuItems.push({ item: element, new: false, update: false });
          this.notRead += element.count;
        });
      });
  }

  openOrCloseMenu() {
    this.showMenu = !this.showMenu;
  }

  concatText(item: NotificationGroup) {
    if (item.count > 1) {
      return item.count + ' - ' + this.messageUtil.translateKey(item.group+"S");
    }
    return item.count + ' - ' + this.messageUtil.translateKey(item.group);
  }
  openNotification(item: NotificationGroup) {

    switch (item.group) {
      case 'CANCELLEDBILLET':
        this.router.navigate(['app/billing/billets-management/billet'], {
          queryParams: {
            operation: 1,
            date: item.data,
          },
        });
        break;
      case 'GENERATEBILLET':
        this.router.navigate(['app/billing/billets-management/billet'], {
          queryParams: {
            operation: 2,
            date: item.data,
          },
        });
        break;
      case 'PAYBILLET':
        this.router.navigate(['app/billing/billets-management/billet'], {
          queryParams: {
            operation: 3,
            date: item.data,
          },
        });
        break;
      default:
        break;
    }

  }

  hoverNotification(item: any) {
    if (item.update===false) {
      item.update=true;
      this.notificationService
        .updateNotificationGroup(item.item.group, item.item.data)
        .subscribe((response) => {
        });

      }

  }
}
