<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{'TITLE-MANAGEMENT' | translate}}</span>
        <button class="fab-button primary" appFiltersButton pTooltip="Exibir/Ocultar Filtros" tooltipPosition="left">
            <i class="pi pi-filter"></i>
        </button>
    </p-header>

    <form class="filters" (submit)="fillTable()" appFilters>
        <ng-container *ngIf="contentLoaded">
            <div class="filter-row">
                <div class="form-control" *ngIf="marinaCompanies?.length > 0">
                    <label for="selectedCompany">{{'COMPANY' | translate}}</label>
                    <p-dropdown [filter]="true" [options]="filteredCompanies" name="selectedCompany" appendTo="body"
                        [(ngModel)]="selectedCompany" (onChange)="changeCompany()">
                    </p-dropdown>
                </div>
                <div class="form-control">
                    <label for="anticipationLot">{{'ANTICIPATION-LOT' | translate}}</label>
                    <input pInputText type="text" [(ngModel)]="filter.anticipationLot" name="anticipationLot">
                </div>
                <div class="form-control">
                    <label for="minInvoiceNumber">{{'MIN-INVOICE-NUMBER' | translate}}</label>
                    <input pInputText type="number" [(ngModel)]="filter.invoiceNumberStart" name="minInvoiceNumber">
                </div>
                <div class="form-control">
                    <label for="maxInvoiceNumber">{{'MAX-INVOICE-NUMBER' | translate}}</label>
                    <input pInputText type="number" [(ngModel)]="filter.invoiceNumberEnd" name="maxInvoiceNumber">
                </div>
                <div class="form-control">
                    <label for="selectedCustomer">{{'CUSTOMER' | translate}}</label>
                    <p-dropdown [filter]="true" [options]="filteredCustomers" [(ngModel)]="filter.customerId"
                        appendTo="body" name="selectedCustomer">
                    </p-dropdown>
                </div>
                <div class="form-control">
                    <label for="selectedAccount">{{'ACCOUNT' | translate}}</label>
                    <p-dropdown [filter]="true" [options]="accounts" name="selectedAccount"
                        [(ngModel)]="filter.accountId" appendTo="body">
                    </p-dropdown>
                </div>
                <div class="form-control">
                    <label for="startDueDate">{{'DUE-FROM' | translate}}</label>
                    <p-calendar dateFormat="dd/mm/yy" name="startDueDate" [monthNavigator]="true" [yearNavigator]="true"
                        [yearRange]="'2000:2055'" rPCalendarMask [(ngModel)]="filter.startDueDate" [showIcon]="true"
                        appendTo="body" i18n>
                    </p-calendar>
                </div>
                <div class="form-control">
                    <label for="endDueDate">{{'DUE-TO' | translate}}</label>
                    <p-calendar dateFormat="dd/mm/yy" name="endDueDate" [monthNavigator]="true" [yearNavigator]="true"
                        [yearRange]="'2000:2055'" rPCalendarMask [(ngModel)]="filter.endDueDate" [showIcon]="true"
                        appendTo="body" i18n>
                    </p-calendar>
                </div>
                <div class="form-control">
                    <label for="startPayment">{{'PAYMENT-FROM' | translate}}</label>
                    <p-calendar dateFormat="dd/mm/yy" name="startPayment" [monthNavigator]="true" [yearNavigator]="true"
                        [yearRange]="'2000:2055'" rPCalendarMask [(ngModel)]="filter.startPayment" [showIcon]="true"
                        appendTo="body" i18n>
                    </p-calendar>
                </div>
                <div class="form-control">
                    <label for="endPayment">{{'PAYMENT-TO' | translate}}</label>
                    <p-calendar dateFormat="dd/mm/yy" name="endPayment" [monthNavigator]="true" [yearNavigator]="true"
                        [yearRange]="'2000:2055'" rPCalendarMask [(ngModel)]="filter.endPayment" [showIcon]="true"
                        appendTo="body" i18n>
                    </p-calendar>
                </div>
                <div class="form-control">
                    <label for="startRepurchaseDate">{{'REPURCHASE-FROM' | translate}}</label>
                    <p-calendar dateFormat="dd/mm/yy" name="startRepurchaseDate" [monthNavigator]="true"
                        [yearNavigator]="true" [yearRange]="'2000:2055'" rPCalendarMask appendTo="body"
                        [(ngModel)]="filter.startRepurchaseDate" [showIcon]="true" i18n>
                    </p-calendar>
                </div>
                <div class="form-control">
                    <label for="endRepurchaseDate">{{'REPURCHASE-TO' | translate}}</label>
                    <p-calendar dateFormat="dd/mm/yy" name="endRepurchaseDate" [monthNavigator]="true"
                        [yearNavigator]="true" [yearRange]="'2000:2055'" rPCalendarMask appendTo="body"
                        [(ngModel)]="filter.endRepurchaseDate" [showIcon]="true" i18n>
                    </p-calendar>
                </div>
                <div class="form-control">
                    <label for="yesNoList">{{'Recomprado' | translate}}</label>
                    <p-dropdown [options]="yesNoList" [(ngModel)]="filter.repurchased" name="filter.repurchased"
                        appendTo="body">
                    </p-dropdown>
                </div>
                <div class="form-control">
                    <label>{{'STATUS' | translate}} {{'INVOICE' | translate}}</label>
                    <p-multiSelect [options]="statusInvoice" [style]="{height: '30px'}" [(ngModel)]="filter.statusInvoice"
                        appendTo="body" [filter]="false" name="statusInvoice" selectedItemsLabel="{0} itens selecionados">
                    </p-multiSelect>
                </div>
            </div>
            <div class="filter-row">
                <div class="buttons">
                    <div class="form-button">
                        <button (click)="clear()" type="button" class="easymarine danger">{{ 'CLEAR' | translate
                            }}</button>
                    </div>
                    <div class="form-button">
                        <button class="easymarine primary" type="submit">{{ 'FIND' | translate }}</button>
                    </div>
                </div>
            </div>
        </ng-container>
    </form>
    <div class="table" *ngIf="contentLoaded">
        <p-table #table [value]="billets" autoLayout="true" [paginator]="true"
            [rows]="paginationFilter.size" [lazy]="true" (onLazyLoad)="onChangePage($event)"
            [rowsPerPageOptions]="[10,20,30,50,100]" [pageLinks]="3" [totalRecords]="totalRecords" sortField="lot"
            [globalFilterFields]="['lot', 'invoiceNumber', 'customerName', 'customerFederalId']"
            styleClass="easy-table">

            <!-- Botões legenda topo -->
            <ng-template pTemplate="caption">
                <div class="fill-empty"></div>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText size="50" placeholder="Filtro por Lote, Fatura ou Cliente"
                        [(ngModel)]="globalFilter" (input)="filterGlobal()" style="width:auto">
                </span>
                <div class="text-right" style="margin: 1px;">
                    <span style="font-weight: bold">{{ 'TOTAL-REGISTERS' | translate }}:
                        {{billets.length}} {{'OF' | translate}}
                        {{table.totalRecords}}</span>
                </div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th colspan="11"></th>
                    <th class="text-right">Total:&nbsp;{{total | number:'1.2-2'}} </th>
                </tr>
                <tr>
                    <th colspan="8" class="text-center">Dados do título</th>
                    <th colspan="4" class="text-center">Recompra</th>
                </tr>
                <tr>
                    <th [pSortableColumn]="'lot'">
                        Lote
                        <p-sortIcon [field]="'lot'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'invoiceNumber'">
                        Fat.
                        <p-sortIcon [field]="'invoiceNumber'"></p-sortIcon>
                    </th>
                    <th class="text-center" *ngIf="marinaCompanies.length > 0">
                        Emp.
                    </th>
                    <th [pSortableColumn]="'customerName'">
                        Cliente
                        <p-sortIcon [field]="'customerName'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'value'">
                        Valor
                        <p-sortIcon [field]="'value'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'dueDate'">
                        Venc.
                        <p-sortIcon [field]="'dueDate'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'accountId'">
                        Conta
                        <p-sortIcon [field]="'accountId'"></p-sortIcon>
                    </th>
                    <th>
                        Pago
                    </th>
                    <th [pSortableColumn]="'repurchaseDate'">
                        Data
                        <p-sortIcon [field]="'repurchaseDate'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'repurchaseValue'">
                        Valor
                        <p-sortIcon [field]="'repurchaseValue'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'repurchaseTax'">
                        Juros
                        <p-sortIcon [field]="'repurchaseTax'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'repurchaseAccountId'">
                        Conta
                        <p-sortIcon [field]="'repurchaseAccountId'"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-billet>
                <tr [ngClass]="{'multiple' : (hasMultiplePayments(billet.paymentDates) || hasIncorretPaymentMethod(billet.paymentMethodIds))}"
                    [pTooltip]="hasIncorretPaymentMethod(billet.paymentMethodIds) ? 'Método de pagamento diferente de boleto' : (hasMultiplePayments(billet.paymentDates) ? 'Pagamentos múltiplos' : '')"
                    tooltipPosition="top">
                    <td style="cursor: pointer;" (click)="billet.paymentDates && findPayments(billet)">
                        {{billet?.lot}}
                        <i class="pi" *ngIf="billet.paymentDates"
                            [ngClass]="{'pi-angle-down': !billet.showPayments, 'pi-angle-up': billet.showPayments}"></i>
                    </td>
                    <td style="cursor: pointer !important; color: #185992; text-align: center;"
                        (click)="extract(billet.invoiceId)">
                        {{billet?.invoiceNumber}}/{{billet?.sequence}}
                    </td>
                    <td *ngIf="marinaCompanies?.length > 0" class="text-center"
                        [pTooltip]="billet.companyId ? getCompanyName(billet.companyId) : loggedMarina.companyName"
                        tooltipPosition="top">
                        {{billet.companyId ? getCompanyIndex(billet.companyId) : 1 }}
                    </td>
                    <td>{{billet?.customerName}}<br>{{billet?.customerFederalId}}</td>
                    <td class="text-right">
                        {{billet?.total | number: '1.2-2'}}
                    </td>
                    <td class="text-center">{{billet?.dueDate | date: 'dd/MM/yyyy'}}</td>
                    <td>{{getAccountNameById(billet.accountId)}}</td>
                    <td [ngClass]="{'text-right' : !hasIncorretPaymentMethod(billet.paymentMethodIds),
							'text-center': hasIncorretPaymentMethod(billet.paymentMethodIds)}">
                        <span *ngIf="!hasIncorretPaymentMethod(billet.paymentMethodIds)">
                            {{billet?.valuePaid | number: '1.2-2'}}
                        </span>
                        <span *ngIf="hasIncorretPaymentMethod(billet.paymentMethodIds)"
                            style="color: red; cursor: pointer;" (click)="findPayments(billet)">
                            VERIFICAR
                        </span>
                    </td>
                    <td class="text-center" style="max-width: 150px;">
                        <p-calendar dateFormat="dd/mm/yy" placeholder="Data" title="Data" name="repurchaseDate"
                            [monthNavigator]="true" [yearNavigator]="true" [yearRange]="'2000:2055'" rPCalendarMask
                            [(ngModel)]="billet.repurchaseDate" i18n styleClass="auto-width"
                            (onSelect)="updateRepurchase(billet)" (onClearClick)="updateRepurchase(billet)"
                            autoZIndex="false" appendTo="body" showButtonBar="true" [baseZIndex]="10000"
                            [id]="'repurchaseDate' + billet.id">
                        </p-calendar>
                    </td>
                    <td class="auto-width">
                        <p-inputNumber mode="decimal" placeholder="Valor" name="repurchaseValue"
                            [(ngModel)]="billet.repurchaseValue" [minFractionDigits]="2" [maxFractionDigits]="2">
                        </p-inputNumber>
                    </td>
                    <td class="auto-width">
                        <p-inputNumber mode="decimal" placeholder="Juros" name="repurchaseTax"
                            [(ngModel)]="billet.repurchaseTax" [minFractionDigits]="2" [maxFractionDigits]="2">
                        </p-inputNumber>
                    </td>
                    <td class="auto-width">
                        <p-dropdown [placeholder]="'Selecione' | translate" [filter]="true" [options]="accountsTable"
                            [scrollHeight]="'150px'" name="selectedAccount" [(ngModel)]="billet.repurchaseAccountId"
                            [title]="'ACCOUNT' | translate" appendTo="body">
                        </p-dropdown>
                    </td>
                </tr>
                <tr *ngIf="billet?.showPayments && billet?.payments?.length > 0">
                    <td colspan="12">
                        <p-table [value]="billet.payments" autoLayout="true"  styleClass="regularHeight">
                            <ng-template pTemplate="header">
                <tr>
                    <th [pSortableColumn]="'accountId'">
                        Conta
                        <p-sortIcon [field]="'accountId'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'paymentMethodId'">
                        Forma Pgto
                        <p-sortIcon [field]="'paymentMethodId'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'datepaid'">
                        Data Pgto
                        <p-sortIcon [field]="'datepaid'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'value'">
                        Valor Pago
                        <p-sortIcon [field]="'value'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'discount'">
                        Desc.
                        <p-sortIcon [field]="'discount'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'interest'">
                        Juros/Multa
                        <p-sortIcon [field]="'interest'"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-payment>
                <tr>
                    <td>{{getAccountNamePayment(billet.companyId, payment.accountId)}}</td>
                    <td>{{getPaymentMethodName(payment?.paymentMethodId)}}</td>
                    <td class="text-right">
                        {{payment.datepaid | date:'dd/MM/yyyy'}}</td>
                    <td class="text-right">
                        {{payment.value | number: '1.2-2'}}</td>
                    <td class="text-right">
                        {{payment.discount | number: '1.2-2'}}</td>
                    <td class="text-right">
                        {{payment.interest | number: '1.2-2'}}</td>
                </tr>
            </ng-template>
        </p-table>
        </td>
        </tr>
        </ng-template>
        </p-table>
    </div>
    <br>
    <div class="buttons" *ngIf="contentLoaded && billets?.length > 0">
        <button (click)="saveBillets()" class="easymarine primary" type="button">Salvar</button>
    </div>
</p-fieldset>
