export class NcmTable {
  public static ncmList = {
    Data_Ultima_Atualizacao_NCM: '01/01/2023',
    Ato: 'Resolução Camex nº 413/2022',
    Nomenclaturas: [
           {
        Codigo: '7117.19.00',
        Descricao: '-- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7117.90.00',
        Descricao: '- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '71.18',
        Descricao: 'Moedas.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7118.10',
        Descricao: '- Moedas sem curso legal, exceto de ouro',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7118.10.10',
        Descricao: 'Destinadas a ter curso legal no país importador',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7118.10.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7118.90.00',
        Descricao: '- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '72',
        Descricao: 'Ferro fundido, ferro e aço.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '72.01',
        Descricao:
          'Ferro fundido bruto e ferro  (especular), em lingotes, linguados ou outras formas primárias.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7201.10.00',
        Descricao:
          '- Ferro fundido bruto não ligado, que contenha, em peso, 0,5 % ou menos de fósforo',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7201.20.00',
        Descricao:
          '- Ferro fundido bruto não ligado, que contenha, em peso, mais de 0,5 % de fósforo',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7201.50.00',
        Descricao:
          '- Ligas de ferro fundido bruto; ferro  (especular)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '72.02',
        Descricao: 'Ferroligas.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7202.1',
        Descricao: '- Ferromanganês:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7202.11.00',
        Descricao: '-- Que contenha, em peso, mais de 2 % de carbono',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7202.19.00',
        Descricao: '-- Outra',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7202.2',
        Descricao: '- Ferrossilício:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7202.21.00',
        Descricao: '-- Que contenha, em peso, mais de 55 % de silício',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7202.29.00',
        Descricao: '-- Outra',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7202.30.00',
        Descricao: '- Ferrossiliciomanganês',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7202.4',
        Descricao: '- Ferrocromo:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7202.41.00',
        Descricao: '-- Que contenha, em peso, mais de 4 % de carbono',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7202.49.00',
        Descricao: '-- Outra',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7202.50.00',
        Descricao: '- Ferrossiliciocromo',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7202.60.00',
        Descricao: '- Ferroníquel',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7202.70.00',
        Descricao: '- Ferromolibdênio',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7202.80.00',
        Descricao:
          '- Ferrotungstênio (ferrovolfrâmio) e ferrossiliciotungstênio (ferrossiliciovolfrâmio)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7202.9',
        Descricao: '- Outras:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7202.91.00',
        Descricao: '-- Ferrotitânio e ferrossiliciotitânio',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7202.92.00',
        Descricao: '-- Ferrovanádio',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7202.93.00',
        Descricao: '-- Ferronióbio (ferrocolômbio)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7202.99',
        Descricao: '-- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7202.99.10',
        Descricao: 'Ferrofósforo',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7202.99.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '72.03',
        Descricao:
          'Produtos ferrosos obtidos por redução direta dos minérios de ferro e outros produtos ferrosos esponjosos, em pedaços, esferas ou formas semelhantes; ferro de pureza mínima, em peso, de 99,94 %, em pedaços, esferas ou formas semelhantes.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7203.10.00',
        Descricao:
          '- Produtos ferrosos obtidos por redução direta dos minérios de ferro',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7203.90.00',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '72.04',
        Descricao:
          'Desperdícios e resíduos, e sucata, de ferro fundido, ferro ou aço; desperdícios e resíduos, em lingotes, de ferro ou aço.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7204.10.00',
        Descricao: '- Desperdícios e resíduos, e sucata, de ferro fundido',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7204.2',
        Descricao: '- Desperdícios e resíduos, e sucata, de ligas de aço:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7204.21.00',
        Descricao: '-- De aço inoxidável',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7204.29.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7204.30.00',
        Descricao:
          '- Desperdícios e resíduos, e sucata, de ferro ou aço, estanhados',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7204.4',
        Descricao: '- Outros desperdícios e resíduos, e sucata:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7204.41.00',
        Descricao:
          '-- Resíduos do torno e da fresa, aparas, lascas (\u003ci\u003emeulures\u003c/i\u003e), pó de serra, limalhas e desperdícios da estampagem ou do corte, mesmo em fardos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7204.49.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7204.50.00',
        Descricao: '- Desperdícios e resíduos, em lingotes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '72.05',
        Descricao:
          'Granalhas e pós de ferro fundido bruto, de ferro  (especular), de ferro ou aço.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7205.10.00',
        Descricao: '- Granalhas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7205.2',
        Descricao: '- Pós:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7205.21.00',
        Descricao: '-- De ligas de aço',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7205.29',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7205.29.10',
        Descricao:
          'De ferro esponjoso, com um teor de ferro igual ou superior a 98 %, em peso',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7205.29.20',
        Descricao:
          'De ferro revestido com resina termoplástica, com um teor de ferro igual ou superior a 98 %, em peso',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7205.29.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '72.06',
        Descricao:
          'Ferro e aço não ligado, em lingotes ou outras formas primárias, exceto o ferro da posição 72.03.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7206.10.00',
        Descricao: '- Lingotes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7206.90.00',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '72.07',
        Descricao: 'Produtos semimanufaturados de ferro ou aço não ligado.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7207.1',
        Descricao: '- Que contenham, em peso, menos de 0,25 % de carbono:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7207.11',
        Descricao:
          '-- De seção transversal quadrada ou retangular, com largura inferior a duas vezes a espessura',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7207.11.10',
        Descricao: '\u003ci\u003eBillets\u003c/i\u003e',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7207.11.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7207.12.00',
        Descricao: '-- Outros, de seção transversal retangular',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7207.19.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7207.20.00',
        Descricao: '- Que contenham, em peso, 0,25 % ou mais de carbono',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '72.08',
        Descricao:
          'Produtos laminados planos, de ferro ou aço não ligado, de largura igual ou superior a 600 mm, laminados a quente, não folheados ou chapeados, nem revestidos.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7208.10.00',
        Descricao:
          '- Em rolos, simplesmente laminados a quente, apresentando motivos em relevo',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7208.2',
        Descricao:
          '- Outros, em rolos, simplesmente laminados a quente, decapados:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7208.25.00',
        Descricao: '-- De espessura igual ou superior a 4,75 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7208.26',
        Descricao:
          '-- De espessura igual ou superior a 3 mm, mas inferior a 4,75 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7208.26.10',
        Descricao: 'Com um limite mínimo de elasticidade de 355 MPa',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7208.26.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7208.27',
        Descricao: '-- De espessura inferior a 3 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7208.27.10',
        Descricao: 'Com um limite mínimo de elasticidade de 275 MPa',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7208.27.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7208.3',
        Descricao: '- Outros, em rolos, simplesmente laminados a quente:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7208.36',
        Descricao: '-- De espessura superior a 10 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7208.36.10',
        Descricao: 'Com um limite mínimo de elasticidade de 355 MPa',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7208.36.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7208.37.00',
        Descricao:
          '-- De espessura igual ou superior a 4,75 mm, mas não superior a 10 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7208.38',
        Descricao:
          '-- De espessura igual ou superior a 3 mm, mas inferior a 4,75 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7208.38.10',
        Descricao: 'Com um limite mínimo de elasticidade de 355 MPa',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7208.38.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7208.39',
        Descricao: '-- De espessura inferior a 3 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7208.39.10',
        Descricao: 'Com um limite mínimo de elasticidade de 275 MPa',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7208.39.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7208.40.00',
        Descricao:
          '- Não enrolados, simplesmente laminados a quente, apresentando motivos em relevo',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7208.5',
        Descricao: '- Outros, não enrolados, simplesmente laminados a quente:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7208.51.00',
        Descricao: '-- De espessura superior a 10 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7208.52.00',
        Descricao:
          '-- De espessura igual ou superior a 4,75 mm, mas não superior a 10 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7208.53.00',
        Descricao:
          '-- De espessura igual ou superior a 3 mm, mas inferior a 4,75 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7208.54.00',
        Descricao: '-- De espessura inferior a 3 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7208.90.00',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '72.09',
        Descricao:
          'Produtos laminados planos, de ferro ou aço não ligado, de largura igual ou superior a 600 mm, laminados a frio, não folheados ou chapeados, nem revestidos.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7209.1',
        Descricao: '- Em rolos simplesmente laminados a frio:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7209.15.00',
        Descricao: '-- De espessura igual ou superior a 3 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7209.16.00',
        Descricao: '-- De espessura superior a 1 mm, mas inferior a 3 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7209.17.00',
        Descricao:
          '-- De espessura igual ou superior a 0,5 mm, mas não superior a 1 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7209.18.00',
        Descricao: '-- De espessura inferior a 0,5 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7209.2',
        Descricao: '- Não enrolados, simplesmente laminados a frio:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7209.25.00',
        Descricao: '-- De espessura igual ou superior a 3 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7209.26.00',
        Descricao: '-- De espessura superior a 1 mm, mas inferior a 3 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7209.27.00',
        Descricao:
          '-- De espessura igual ou superior a 0,5 mm, mas não superior a 1 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7209.28.00',
        Descricao: '-- De espessura inferior a 0,5 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7209.90.00',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '72.10',
        Descricao:
          'Produtos laminados planos, de ferro ou aço não ligado, de largura igual ou superior a 600 mm, folheados ou chapeados, ou revestidos.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7210.1',
        Descricao: '- Estanhados:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7210.11.00',
        Descricao: '-- De espessura igual ou superior a 0,5 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7210.12.00',
        Descricao: '-- De espessura inferior a 0,5 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7210.20.00',
        Descricao:
          '- Revestidos de chumbo, incluindo os revestidos de uma liga de chumboestanho',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7210.30',
        Descricao: '- Galvanizados eletroliticamente',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7210.30.10',
        Descricao: 'De espessura inferior a 4,75 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7210.30.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7210.4',
        Descricao: '- Galvanizados por outro processo:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7210.41',
        Descricao: '-- Ondulados',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7210.41.10',
        Descricao: 'De espessura inferior a 4,75 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7210.41.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7210.49',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7210.49.10',
        Descricao: 'De espessura inferior a 4,75 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7210.49.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7210.50.00',
        Descricao:
          '- Revestidos de óxidos de cromo ou de cromo e óxidos de cromo',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7210.6',
        Descricao: '- Revestidos de alumínio:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7210.61.00',
        Descricao: '-- Revestidos de ligas de aluminiozinco',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7210.69',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7210.69.1',
        Descricao: 'Revestidos de ligas de aluminiossilício',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7210.69.11',
        Descricao:
          'De peso igual ou superior a 120 g/m\u003csup\u003e2\u003c/sup\u003e e com conteúdo de silício igual ou superior a 5 %, mas inferior ou igual a 11 %, em peso',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7210.69.19',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7210.69.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7210.70',
        Descricao: '- Pintados, envernizados ou revestidos de plástico',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7210.70.10',
        Descricao: 'Pintados ou envernizados',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7210.70.20',
        Descricao: 'Revestidos de plástico',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7210.90.00',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '72.11',
        Descricao:
          'Produtos laminados planos, de ferro ou aço não ligado, de largura inferior a 600 mm, não folheados ou chapeados, nem revestidos.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7211.1',
        Descricao: '- Simplesmente laminados a quente:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7211.13.00',
        Descricao:
          '-- Laminados nas quatro faces ou em caixa fechada, de largura superior a 150 mm e de espessura igual ou superior a 4 mm, não enrolados e não apresentando motivos em relevo',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7211.14.00',
        Descricao: '-- Outros, de espessura igual ou superior a 4,75 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7211.19.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7211.2',
        Descricao: '- Simplesmente laminados a frio:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7211.23.00',
        Descricao: '-- Que contenham, em peso, menos de 0,25 % de carbono',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7211.29',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7211.29.10',
        Descricao:
          'Com um teor de carbono igual ou superior a 0,25 %, mas inferior a 0,6 %, em peso',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7211.29.20',
        Descricao: 'Com um teor de carbono igual ou superior a 0,6 %, em peso',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7211.90',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7211.90.10',
        Descricao: 'Com um teor de carbono igual ou superior a 0,6 %, em peso',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7211.90.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '72.12',
        Descricao:
          'Produtos laminados planos, de ferro ou aço não ligado, de largura inferior a 600 mm, folheados ou chapeados, ou revestidos.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7212.10.00',
        Descricao: '- Estanhados',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7212.20',
        Descricao: '- Galvanizados eletroliticamente',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7212.20.10',
        Descricao: 'De espessura inferior a 4,75 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7212.20.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7212.30.00',
        Descricao: '- Galvanizados por outro processo',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7212.40',
        Descricao: '- Pintados, envernizados ou revestidos de plástico',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7212.40.10',
        Descricao: 'Pintados ou envernizados',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7212.40.2',
        Descricao: 'Revestidos de plástico',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7212.40.21',
        Descricao:
          'Com uma camada intermediária de liga cobre-estanho ou cobre-estanho-chumbo, aplicada por sinterização',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7212.40.29',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7212.50',
        Descricao: '- Revestidos de outras matérias',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7212.50.10',
        Descricao:
          'Com uma camada de liga cobre-estanho ou cobre-estanho-chumbo, aplicada por sinterização, inclusive com revestimento misto metal-plástico ou metal-plástico-fibra de carbono',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7212.50.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7212.60.00',
        Descricao: '- Folheados ou chapeados',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '72.13',
        Descricao: 'Fio-máquina de ferro ou aço não ligado.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7213.10.00',
        Descricao:
          '- Dentados, com nervuras, sulcos (entalhes) ou relevos, obtidos durante a laminagem',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7213.20.00',
        Descricao: '- Outros, de aço para tornear',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7213.9',
        Descricao: '- Outros:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7213.91',
        Descricao: '-- De seção circular, de diâmetro inferior a 14 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7213.91.10',
        Descricao: 'Com um teor de carbono igual ou superior a 0,6 %, em peso',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7213.91.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7213.99',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7213.99.10',
        Descricao: 'Com um teor de carbono igual ou superior a 0,6 %, em peso',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7213.99.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '72.14',
        Descricao:
          'Barras de ferro ou aço não ligado, simplesmente forjadas, laminadas, estiradas ou extrudadas, a quente, incluindo as que tenham sido submetidas a torção após laminagem.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7214.10',
        Descricao: '- Forjadas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7214.10.10',
        Descricao: 'Com um teor de carbono inferior ou igual a 0,6 %, em peso',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7214.10.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7214.20.00',
        Descricao:
          '- Dentadas, com nervuras, sulcos (entalhes) ou relevos, obtidos durante a laminagem, ou torcidas após laminagem',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7214.30.00',
        Descricao: '- Outras, de aço para tornear',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7214.9',
        Descricao: '- Outras:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7214.91.00',
        Descricao: '-- De seção transversal retangular',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7214.99',
        Descricao: '-- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7214.99.10',
        Descricao: 'De seção circular',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7214.99.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '72.15',
        Descricao: 'Outras barras de ferro ou aço não ligado.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7215.10.00',
        Descricao:
          '- De aço para tornear, simplesmente obtidas ou completamente acabadas a frio',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7215.50.00',
        Descricao:
          '- Outras, simplesmente obtidas ou completamente acabadas a frio',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7215.90',
        Descricao: '- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7215.90.10',
        Descricao: 'Com um teor de carbono inferior ou igual a 0,6 %, em peso',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7215.90.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '72.16',
        Descricao: 'Perfis de ferro ou aço não ligado.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7216.10.00',
        Descricao:
          '- Perfis em U, I ou H, simplesmente laminados, estirados ou extrudados, a quente, de altura inferior a 80 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7216.2',
        Descricao:
          '- Perfis em L ou T, simplesmente laminados, estirados ou extrudados, a quente, de altura inferior a 80 mm:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7216.21.00',
        Descricao: '-- Perfis em L',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7216.22.00',
        Descricao: '-- Perfis em T',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7216.3',
        Descricao:
          '- Perfis em U, I ou H, simplesmente laminados, estirados ou extrudados, a quente, de altura igual ou superior a 80 mm:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7216.31.00',
        Descricao: '-- Perfis em U',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7216.32.00',
        Descricao: '-- Perfis em I',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7216.33.00',
        Descricao: '-- Perfis em H',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7216.40',
        Descricao:
          '- Perfis em L ou T, simplesmente laminados, estirados ou extrudados, a quente, de altura igual ou superior a 80 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7216.40.10',
        Descricao: 'De altura inferior ou igual a 200 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7216.40.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7216.50.00',
        Descricao:
          '- Outros perfis, simplesmente laminados, estirados ou extrudados, a quente',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7216.6',
        Descricao:
          '- Perfis simplesmente obtidos ou completamente acabados a frio:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7216.61',
        Descricao: '-- Obtidos a partir de produtos laminados planos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7216.61.10',
        Descricao: 'De altura inferior a 80 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7216.61.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7216.69',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7216.69.10',
        Descricao: 'De altura inferior a 80 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7216.69.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7216.9',
        Descricao: '- Outros:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7216.91.00',
        Descricao:
          '-- Obtidos ou acabados a frio a partir de produtos laminados planos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7216.99.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '72.17',
        Descricao: 'Fios de ferro ou aço não ligado.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7217.10',
        Descricao: '- Não revestidos, mesmo polidos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7217.10.1',
        Descricao: 'Com um teor de carbono igual ou superior a 0,6 %, em peso',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7217.10.11',
        Descricao:
          'Com um teor, em peso, de fósforo inferior a 0,035 % e de enxofre inferior a 0,035 %, temperado e revenido, flecha máxima sem carga de 1 cm em 1 m, resistência à tração igual ou superior a 1.960 MPa e cuja maior dimensão da seção transversal seja inferior ou igual a 2,25 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7217.10.19',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7217.10.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7217.20',
        Descricao: '- Galvanizados',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7217.20.10',
        Descricao: 'Com um teor de carbono igual ou superior a 0,6 %, em peso',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7217.20.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7217.30',
        Descricao: '- Revestidos de outros metais comuns',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7217.30.10',
        Descricao: 'Com um teor de carbono igual ou superior a 0,6 %, em peso',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7217.30.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7217.90.00',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '72.18',
        Descricao:
          'Aço inoxidável em lingotes ou outras formas primárias; produtos semimanufaturados de aço inoxidável.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7218.10.00',
        Descricao: '- Lingotes e outras formas primárias',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7218.9',
        Descricao: '- Outros:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7218.91.00',
        Descricao: '-- De seção transversal retangular',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7218.99.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '72.19',
        Descricao:
          'Produtos laminados planos de aço inoxidável, de largura igual ou superior a 600 mm.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7219.1',
        Descricao: '- Simplesmente laminados a quente, em rolos:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7219.11.00',
        Descricao: '-- De espessura superior a 10 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7219.12.00',
        Descricao:
          '-- De espessura igual ou superior a 4,75 mm, mas não superior a 10 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7219.13.00',
        Descricao:
          '-- De espessura igual ou superior a 3 mm, mas inferior a 4,75 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7219.14.00',
        Descricao: '-- De espessura inferior a 3 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7219.2',
        Descricao: '- Simplesmente laminados a quente, não enrolados:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7219.21.00',
        Descricao: '-- De espessura superior a 10 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7219.22.00',
        Descricao:
          '-- De espessura igual ou superior a 4,75 mm, mas não superior a 10 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7219.23.00',
        Descricao:
          '-- De espessura igual ou superior a 3 mm, mas inferior a 4,75 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7219.24.00',
        Descricao: '-- De espessura inferior a 3 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7219.3',
        Descricao: '- Simplesmente laminados a frio:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7219.31.00',
        Descricao: '-- De espessura igual ou superior a 4,75 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7219.32.00',
        Descricao:
          '-- De espessura igual ou superior a 3 mm, mas inferior a 4,75 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7219.33.00',
        Descricao: '-- De espessura superior a 1 mm, mas inferior a 3 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7219.34.00',
        Descricao:
          '-- De espessura igual ou superior a 0,5 mm, mas não superior a 1 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7219.35.00',
        Descricao: '-- De espessura inferior a 0,5 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7219.90',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7219.90.10',
        Descricao:
          'De espessura inferior a 4,75 mm e dureza igual ou superior a 42 HRC',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7219.90.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '72.20',
        Descricao:
          'Produtos laminados planos de aço inoxidável, de largura inferior a 600 mm.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7220.1',
        Descricao: '- Simplesmente laminados a quente:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7220.11.00',
        Descricao: '-- De espessura igual ou superior a 4,75 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7220.12',
        Descricao: '-- De espessura inferior a 4,75 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7220.12.10',
        Descricao: 'De espessura inferior ou igual a 1,5 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7220.12.20',
        Descricao: 'De espessura superior a 1,5 mm, mas não superior a 3 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7220.12.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7220.20',
        Descricao: '- Simplesmente laminados a frio',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7220.20.10',
        Descricao:
          'De largura inferior ou igual a 23 mm e espessura inferior ou igual a 0,1 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7220.20.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7220.90.00',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7221.00.00',
        Descricao: 'Fio-máquina de aço inoxidável.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '72.22',
        Descricao: 'Barras e perfis, de aço inoxidável.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7222.1',
        Descricao:
          '- Barras simplesmente laminadas, estiradas ou extrudadas, a quente:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7222.11.00',
        Descricao: '-- De seção circular',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7222.19',
        Descricao: '-- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7222.19.10',
        Descricao: 'De seção transversal retangular',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7222.19.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7222.20.00',
        Descricao:
          '- Barras simplesmente obtidas ou completamente acabadas a frio',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7222.30.00',
        Descricao: '- Outras barras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7222.40',
        Descricao: '- Perfis',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7222.40.10',
        Descricao: 'De altura igual ou superior a 80 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7222.40.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7223.00.00',
        Descricao: 'Fios de aço inoxidável.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '72.24',
        Descricao:
          'Outras ligas de aço, em lingotes ou outras formas primárias; produtos semimanufaturados, de outras ligas de aço.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7224.10.00',
        Descricao: '- Lingotes e outras formas primárias',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7224.90.00',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '72.25',
        Descricao:
          'Produtos laminados planos, de outras ligas de aço, de largura igual ou superior a 600 mm.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7225.1',
        Descricao: '- De aço ao silício, denominado "magnético":',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7225.11.00',
        Descricao: '-- De grãos orientados',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7225.19.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7225.30.00',
        Descricao: '- Outros, simplesmente laminados a quente, em rolos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7225.40',
        Descricao: '- Outros, simplesmente laminados a quente, não enrolados',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7225.40.10',
        Descricao:
          'De aço, segundo normas AISI D2, D3 ou D6, de espessura inferior ou igual a 7 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7225.40.20',
        Descricao: 'De aços de corte rápido',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7225.40.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7225.50',
        Descricao: '- Outros, simplesmente laminados a frio',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7225.50.10',
        Descricao: 'De aços de corte rápido',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7225.50.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7225.9',
        Descricao: '- Outros:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7225.91.00',
        Descricao: '-- Galvanizados eletroliticamente',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7225.92.00',
        Descricao: '-- Galvanizados por outro processo',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7225.99',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7225.99.10',
        Descricao: 'De aços de corte rápido',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7225.99.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '72.26',
        Descricao:
          'Produtos laminados planos, de outras ligas de aço, de largura inferior a 600 mm.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7226.1',
        Descricao: '- De aço ao silício, denominado "magnético":',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7226.11.00',
        Descricao: '-- De grãos orientados',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7226.19.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7226.20',
        Descricao: '- De aço de corte rápido',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7226.20.10',
        Descricao:
          'De espessura igual ou superior a 1 mm, mas não superior a 4 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7226.20.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7226.9',
        Descricao: '- Outros:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7226.91.00',
        Descricao: '-- Simplesmente laminados a quente',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7226.92.00',
        Descricao: '-- Simplesmente laminados a frio',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7226.99.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '72.27',
        Descricao: 'Fio-máquina de outras ligas de aço.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7227.10.00',
        Descricao: '- De aço de corte rápido',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7227.20.00',
        Descricao: '- De aço siliciomanganês',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7227.90.00',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '72.28',
        Descricao:
          'Barras e perfis, de outras ligas de aço; barras ocas para perfuração, de ligas de aço ou de aço não ligado.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7228.10',
        Descricao: '- Barras de aço de corte rápido',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7228.10.10',
        Descricao: 'Simplesmente laminadas, estiradas ou extrudadas, a quente',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7228.10.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7228.20.00',
        Descricao: '- Barras de aço siliciomanganês',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7228.30.00',
        Descricao:
          '- Outras barras, simplesmente laminadas, estiradas ou extrudadas, a quente',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7228.40.00',
        Descricao: '- Outras barras, simplesmente forjadas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7228.50.00',
        Descricao:
          '- Outras barras, simplesmente obtidas ou completamente acabadas a frio',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7228.60.00',
        Descricao: '- Outras barras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7228.70.00',
        Descricao: '- Perfis',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7228.80.00',
        Descricao: '- Barras ocas para perfuração',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '72.29',
        Descricao: 'Fios de outras ligas de aço.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7229.20.00',
        Descricao: '- De aço siliciomanganês',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7229.90.00',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '73',
        Descricao: 'Obras de ferro fundido, ferro ou aço.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '73.01',
        Descricao:
          'Estacas-pranchas de ferro ou aço, mesmo perfuradas ou feitas com elementos montados; perfis obtidos por soldadura, de ferro ou aço.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7301.10.00',
        Descricao: '- Estacas-pranchas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7301.20.00',
        Descricao: '- Perfis',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '73.02',
        Descricao:
          'Elementos de vias férreas, de ferro fundido, ferro ou aço: trilhos (carris), contratrilhos (contracarris) e cremalheiras, agulhas, cróssimas, alavancas para comando de agulhas e outros elementos de cruzamentos e desvios, dormentes, talas de junção (eclissas*), coxins de trilho (carril), cantoneiras, placas de apoio ou assentamento, placas de aperto, placas e tirantes de separação e outras peças próprias para a fixação, articulação, apoio ou junção de trilhos (carris).',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7302.10',
        Descricao: '- Trilhos (carris)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7302.10.10',
        Descricao: 'De aço, de peso linear igual ou superior a 44,5 kg/m',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7302.10.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7302.30.00',
        Descricao:
          '- Agulhas, cróssimas, alavancas para comando de agulhas e outros elementos de cruzamentos e desvios',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7302.40.00',
        Descricao:
          '- Talas de junção (Eclissas*) e placas de apoio ou assentamento',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7302.90.00',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7303.00.00',
        Descricao: 'Tubos e perfis ocos, de ferro fundido.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '73.04',
        Descricao: 'Tubos e perfis ocos, sem costura, de ferro ou aço.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.1',
        Descricao: '- Tubos do tipo utilizado em oleodutos ou gasodutos:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.11.00',
        Descricao: '-- De aço inoxidável',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.19.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.2',
        Descricao:
          '- Tubos para revestimento de poços, de produção ou suprimento e hastes de perfuração, do tipo utilizado na extração de petróleo ou de gás:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.22.00',
        Descricao: '-- Hastes de perfuração de aço inoxidável',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.23',
        Descricao: '-- Outras hastes de perfuração',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.23.10',
        Descricao: 'De aço não ligado',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.23.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.24.00',
        Descricao: '-- Outros, de aço inoxidável',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.29',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.29.10',
        Descricao: 'De aço não ligado',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.29.3',
        Descricao: 'De outras ligas de aço não revestidos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.29.31',
        Descricao: 'De diâmetro exterior inferior ou igual a 229 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.29.39',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.29.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.3',
        Descricao: '- Outros, de seção circular, de ferro ou aço não ligado:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.31',
        Descricao: '-- Estirados ou laminados, a frio',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.31.10',
        Descricao: 'Tubos não revestidos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.31.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.39',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.39.10',
        Descricao:
          'Tubos não revestidos, de diâmetro exterior inferior ou igual a 229 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.39.20',
        Descricao:
          'Tubos revestidos, de diâmetro exterior inferior ou igual a 229 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.39.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.4',
        Descricao: '- Outros, de seção circular, de aço inoxidável:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.41',
        Descricao: '-- Estirados ou laminados, a frio',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.41.10',
        Descricao:
          'Tubos capilares de diâmetro exterior inferior ou igual a 3 mm e diâmetro interior inferior ou igual a 0,2 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.41.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.49.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.5',
        Descricao: '- Outros, de seção circular, de outras ligas de aço:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.51',
        Descricao: '-- Estirados ou laminados, a frio',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.51.1',
        Descricao: 'Tubos de diâmetro exterior inferior ou igual a 229 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.51.11',
        Descricao:
          'Tubos capilares de diâmetro exterior inferior ou igual a 3 mm e diâmetro interior inferior ou igual a 0,2 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.51.19',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.51.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.59',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.59.10',
        Descricao: 'Tubos de diâmetro exterior inferior ou igual a 229 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.59.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.90',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.90.1',
        Descricao: 'De diâmetro exterior inferior ou igual a 229 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.90.11',
        Descricao: 'De aço inoxidável',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.90.19',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7304.90.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '73.05',
        Descricao:
          'Outros tubos (por exemplo, soldados ou rebitados), de seção circular, de diâmetro exterior superior a 406,4 mm, de ferro ou aço.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7305.1',
        Descricao: '- Tubos do tipo utilizado em oleodutos ou gasodutos:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7305.11.00',
        Descricao: '-- Soldados longitudinalmente por arco imerso',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7305.12.00',
        Descricao: '-- Outros, soldados longitudinalmente',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7305.19.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7305.20.00',
        Descricao:
          '- Tubos para revestimento de poços, do tipo utilizado na extração de petróleo ou de gás',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7305.3',
        Descricao: '- Outros, soldados:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7305.31.00',
        Descricao: '-- Soldados longitudinalmente',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7305.39.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7305.90.00',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '73.06',
        Descricao:
          'Outros tubos e perfis ocos (por exemplo, soldados, rebitados, grampeados ou com as bordas simplesmente aproximadas), de ferro ou aço.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7306.1',
        Descricao: '- Tubos do tipo utilizado em oleodutos ou gasodutos:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7306.11.00',
        Descricao: '-- Soldados, de aço inoxidável',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7306.19.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7306.2',
        Descricao:
          '- Tubos para revestimento de poços, de produção ou suprimento, do tipo utilizado na extração de petróleo ou de gás:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7306.21.00',
        Descricao: '-- Soldados, de aço inoxidável',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7306.29.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7306.30.00',
        Descricao:
          '- Outros, soldados, de seção circular, de ferro ou aço não ligado',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7306.40.00',
        Descricao: '- Outros, soldados, de seção circular, de aço inoxidável',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7306.50.00',
        Descricao:
          '- Outros, soldados, de seção circular, de outras ligas de aço',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7306.6',
        Descricao: '- Outros, soldados, de seção não circular:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7306.61.00',
        Descricao: '-- De seção quadrada ou retangular',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7306.69.00',
        Descricao: '-- De outras seções',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7306.90',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7306.90.10',
        Descricao: 'De ferro ou aço não ligado',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7306.90.20',
        Descricao: 'De aço inoxidável',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7306.90.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '73.07',
        Descricao:
          'Acessórios para tubos (por exemplo, uniões, cotovelos, luvas (mangas*)), de ferro fundido, ferro ou aço.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7307.1',
        Descricao: '- Moldados:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7307.11.00',
        Descricao: '-- De ferro fundido não maleável',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7307.19',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7307.19.10',
        Descricao:
          'De ferro fundido maleável, de diâmetro interior superior a 50,8 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7307.19.20',
        Descricao: 'De aço',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7307.19.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7307.2',
        Descricao: '- Outros, de aço inoxidável:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7307.21.00',
        Descricao: '-- Flanges',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7307.22.00',
        Descricao: '-- Cotovelos, curvas e luvas (mangas*), roscados',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7307.23.00',
        Descricao: '-- Acessórios para soldar topo a topo',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7307.29.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7307.9',
        Descricao: '- Outros:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7307.91.00',
        Descricao: '-- Flanges',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7307.92.00',
        Descricao: '-- Cotovelos, curvas e luvas (mangas*), roscados',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7307.93.00',
        Descricao: '-- Acessórios para soldar topo a topo',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7307.99.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '73.08',
        Descricao:
          'Construções e suas partes (por exemplo, pontes e elementos de pontes, comportas, torres, pórticos, pilares, colunas, armações, estruturas para telhados, portas e janelas, e seus caixilhos, alizares e soleiras, portas de correr, balaustradas), de ferro fundido, ferro ou aço, exceto as construções pré-fabricadas da posição 94.06; chapas, barras, perfis, tubos e semelhantes, de ferro fundido, ferro ou aço, próprios para construções.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7308.10.00',
        Descricao: '- Pontes e elementos de pontes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7308.20.00',
        Descricao: '- Torres e pórticos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7308.30.00',
        Descricao: '- Portas e janelas, e seus caixilhos, alizares e soleiras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7308.40.00',
        Descricao:
          '- Material para andaimes, para cofragens ou para escoramentos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7308.90',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7308.90.10',
        Descricao:
          'Chapas, barras, perfis, tubos e semelhantes, próprios para construções',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7308.90.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7309.00',
        Descricao:
          'Reservatórios, tonéis, cubas e recipientes semelhantes para quaisquer matérias (exceto gases comprimidos ou liquefeitos), de ferro fundido, ferro ou aço, de capacidade superior a 300 l, sem dispositivos mecânicos ou térmicos, mesmo com revestimento interior ou calorífugo.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7309.00.10',
        Descricao: 'Para armazenamento de grãos e outras matérias sólidas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7309.00.20',
        Descricao:
          'Recipientes isotérmicos refrigerados a nitrogênio (azoto) líquido, do tipo utilizado para sêmen, sangue, tecidos biológicos e outros produtos semelhantes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7309.00.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '73.10',
        Descricao:
          'Reservatórios, barris, tambores, latas, caixas e recipientes semelhantes para quaisquer matérias (exceto gases comprimidos ou liquefeitos), de ferro fundido, ferro ou aço, de capacidade não superior a 300 l, sem dispositivos mecânicos ou térmicos, mesmo com revestimento interior ou calorífugo.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7310.10',
        Descricao: '- De capacidade igual ou superior a 50 l',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7310.10.10',
        Descricao:
          'Recipientes isotérmicos refrigerados a nitrogênio (azoto) líquido, do tipo utilizado para sêmen, sangue, tecidos biológicos e outros produtos semelhantes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7310.10.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7310.2',
        Descricao: '- De capacidade inferior a 50 l:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7310.21',
        Descricao:
          '-- Latas próprias para serem fechadas por soldadura ou cravação',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7310.21.10',
        Descricao: 'Próprias para acondicionar produtos alimentícios',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7310.21.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7310.29',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7310.29.10',
        Descricao: 'Próprios para acondicionar produtos alimentícios',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7310.29.20',
        Descricao:
          'Recipientes isotérmicos refrigerados a nitrogênio (azoto) líquido, do tipo utilizado para sêmen, sangue, tecidos biológicos e outros produtos semelhantes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7310.29.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7311.00.00',
        Descricao:
          'Recipientes para gases comprimidos ou liquefeitos, de ferro fundido, ferro ou aço.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '73.12',
        Descricao:
          'Cordas, cabos, tranças (entrançados*), lingas e artigos semelhantes, de ferro ou aço, não isolados para usos elétricos.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7312.10',
        Descricao: '- Cordas e cabos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7312.10.10',
        Descricao: 'De fios de aço revestidos de bronze ou latão',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7312.10.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7312.90.00',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7313.00.00',
        Descricao:
          'Arame farpado, de ferro ou aço; arames ou tiras, retorcidos, mesmo farpados, de ferro ou aço, do tipo utilizado em cercas.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '73.14',
        Descricao:
          'Telas metálicas (incluindo as telas contínuas ou sem fim), grades e redes, de fios de ferro ou aço; chapas e tiras, distendidas, de ferro ou aço.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7314.1',
        Descricao: '- Telas metálicas tecidas:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7314.12.00',
        Descricao:
          '-- Telas metálicas, contínuas ou sem fim, para máquinas, de aço inoxidável',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7314.14.00',
        Descricao: '-- Outras telas metálicas tecidas, de aço inoxidável',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7314.19.00',
        Descricao: '-- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7314.20.00',
        Descricao:
          '- Grades e redes, soldadas nos pontos de interseção, de fios com, pelo menos, 3 mm na maior dimensão da seção transversal e com malhas de 100 cm\u003csup\u003e2\u003c/sup\u003e ou mais, de superfície',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7314.3',
        Descricao:
          '- Outras grades e redes, soldadas nos pontos de interseção:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7314.31.00',
        Descricao: '-- Galvanizadas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7314.39.00',
        Descricao: '-- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7314.4',
        Descricao: '- Outras telas metálicas, grades e redes:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7314.41.00',
        Descricao: '-- Galvanizadas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7314.42.00',
        Descricao: '-- Revestidas de plástico',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7314.49.00',
        Descricao: '-- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7314.50.00',
        Descricao: '- Chapas e tiras, distendidas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '73.15',
        Descricao:
          'Correntes, cadeias, e suas partes, de ferro fundido, ferro ou aço.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7315.1',
        Descricao: '- Correntes de elos articulados e suas partes:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7315.11.00',
        Descricao: '-- Correntes de rolos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7315.12',
        Descricao: '-- Outras correntes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7315.12.10',
        Descricao: 'De transmissão',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7315.12.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7315.19.00',
        Descricao: '-- Partes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7315.20.00',
        Descricao: '- Correntes antiderrapantes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7315.8',
        Descricao: '- Outras correntes e cadeias:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7315.81.00',
        Descricao: '-- Correntes de elos com suporte',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7315.82.00',
        Descricao: '-- Outras correntes, de elos soldados',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7315.89.00',
        Descricao: '-- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7315.90.00',
        Descricao: '- Outras partes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7316.00.00',
        Descricao:
          'Âncoras, fateixas, e suas partes, de ferro fundido, ferro ou aço.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7317.00',
        Descricao:
          'Tachas, pregos, percevejos, escápulas (pregos para tacos), grampos ondulados ou biselados e artigos semelhantes, de ferro fundido, ferro ou aço, mesmo com a cabeça de outra matéria, exceto cobre.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7317.00.10',
        Descricao: 'Tachas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7317.00.20',
        Descricao: 'Grampos de fio curvado',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7317.00.30',
        Descricao: 'Pontas ou dentes para máquinas têxteis',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7317.00.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '73.18',
        Descricao:
          'Parafusos, pinos ou pernos, roscados, porcas, tira-fundos, ganchos roscados, rebites, chavetas, contrapinos ou troços, arruelas (anilhas) (incluindo as de pressão) e artigos semelhantes, de ferro fundido, ferro ou aço.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7318.1',
        Descricao: '- Artigos roscados:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7318.11.00',
        Descricao: '-- Tira-fundos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7318.12.00',
        Descricao: '-- Outros parafusos para madeira',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7318.13.00',
        Descricao: '-- Ganchos, escápulas e pitões',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7318.14.00',
        Descricao: '-- Parafusos autoperfurantes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7318.15.00',
        Descricao:
          '-- Outros parafusos e pinos ou pernos, mesmo com as porcas e arruelas (anilhas)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7318.16.00',
        Descricao: '-- Porcas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7318.19.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7318.2',
        Descricao: '- Artigos não roscados:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7318.21.00',
        Descricao:
          '-- Arruelas (anilhas) de pressão e outras arruelas (anilhas) de segurança',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7318.22.00',
        Descricao: '-- Outras arruelas (anilhas)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7318.23.00',
        Descricao: '-- Rebites',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7318.24.00',
        Descricao: '-- Chavetas e contrapinos ou troços',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7318.29.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '73.19',
        Descricao:
          'Agulhas de costura, agulhas de tricô, agulhas-passadoras, agulhas de crochê, furadores para bordar e artigos semelhantes, para uso manual, de ferro ou aço; alfinetes de segurança e outros alfinetes, de ferro ou aço, não especificados nem compreendidos noutras posições.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7319.40.00',
        Descricao: '- Alfinetes de segurança e outros alfinetes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7319.90.00',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '73.20',
        Descricao: 'Molas e folhas de molas, de ferro ou aço.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7320.10.00',
        Descricao: '- Molas de folhas e suas folhas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7320.20',
        Descricao: '- Molas helicoidais',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7320.20.10',
        Descricao: 'Cilíndricas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7320.20.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7320.90.00',
        Descricao: '- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '73.21',
        Descricao:
          'Aquecedores de ambiente (fogões de sala), caldeiras de fornalha, fogões de cozinha (incluindo os que possam ser utilizados acessoriamente no aquecimento central), churrasqueiras (grelhadores), braseiras, fogareiros a gás, aquecedores de pratos, e aparelhos não elétricos semelhantes, de uso doméstico, e suas partes, de ferro fundido, ferro ou aço.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7321.1',
        Descricao: '- Aparelhos para cozinhar e aquecedores de pratos:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7321.11.00',
        Descricao: '-- A combustíveis gasosos, ou a gás e outros combustíveis',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7321.12.00',
        Descricao: '-- A combustíveis líquidos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7321.19.00',
        Descricao: '-- Outros, incluindo os aparelhos a combustíveis sólidos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7321.8',
        Descricao: '- Outros aparelhos:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7321.81.00',
        Descricao: '-- A combustíveis gasosos, ou a gás e outros combustíveis',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7321.82.00',
        Descricao: '-- A combustíveis líquidos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7321.89.00',
        Descricao: '-- Outros, incluindo os aparelhos a combustíveis sólidos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7321.90.00',
        Descricao: '- Partes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '73.22',
        Descricao:
          'Radiadores para aquecimento central, não elétricos, e suas partes, de ferro fundido, ferro ou aço; geradores e distribuidores de ar quente (incluindo os distribuidores que possam também funcionar como distribuidores de ar frio ou condicionado), não elétricos, munidos de ventilador ou fole com motor, e suas partes, de ferro fundido, ferro ou aço.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7322.1',
        Descricao: '- Radiadores e suas partes:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7322.11.00',
        Descricao: '-- De ferro fundido',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7322.19.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7322.90',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7322.90.10',
        Descricao:
          'Geradores de ar quente a combustível líquido, com capacidade igual ou superior a 1.500 kcal/h, mas não superior a 10.400 kcal/h, do tipo utilizado em veículos automóveis',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7322.90.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '73.23',
        Descricao:
          'Serviços de mesa, artigos de cozinha e outros artigos de uso doméstico, e suas partes, de ferro fundido, ferro ou aço; palha de ferro ou aço; esponjas, esfregões, luvas e artigos semelhantes para limpeza, polimento ou usos semelhantes, de ferro ou aço.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7323.10.00',
        Descricao:
          '- Palha de ferro ou aço; esponjas, esfregões, luvas e artigos semelhantes para limpeza, polimento ou usos semelhantes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7323.9',
        Descricao: '- Outros:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7323.91.00',
        Descricao: '-- De ferro fundido, não esmaltados',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7323.92.00',
        Descricao: '-- De ferro fundido, esmaltados',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7323.93.00',
        Descricao: '-- De aço inoxidável',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7323.94.00',
        Descricao: '-- De ferro ou aço, esmaltados',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7323.99.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '73.24',
        Descricao:
          'Artigos de higiene ou de toucador, e suas partes, de ferro fundido, ferro ou aço.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7324.10.00',
        Descricao: '- Pias e lavatórios, de aço inoxidável',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7324.2',
        Descricao: '- Banheiras:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7324.21.00',
        Descricao: '-- De ferro fundido, mesmo esmaltadas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7324.29.00',
        Descricao: '-- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7324.90.00',
        Descricao: '- Outros, incluindo as partes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '73.25',
        Descricao: 'Outras obras moldadas, de ferro fundido, ferro ou aço.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7325.10.00',
        Descricao: '- De ferro fundido, não maleável',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7325.9',
        Descricao: '- Outras:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7325.91.00',
        Descricao: '-- Esferas e artigos semelhantes, para moinhos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7325.99',
        Descricao: '-- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7325.99.10',
        Descricao: 'De aço',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7325.99.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '73.26',
        Descricao: 'Outras obras de ferro ou aço.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7326.1',
        Descricao: '- Simplesmente forjadas ou estampadas:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7326.11.00',
        Descricao: '-- Esferas e artigos semelhantes, para moinhos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7326.19.00',
        Descricao: '-- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7326.20.00',
        Descricao: '- Obras de fio de ferro ou aço',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7326.90',
        Descricao: '- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7326.90.10',
        Descricao:
          'Calotas elípticas de aço ao níquel, segundo Norma ASME SA 353, do tipo utilizado na fabricação de recipientes para gases comprimidos ou liquefeitos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7326.90.20',
        Descricao: 'Discos próprios para cunhagem de moedas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7326.90.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '74',
        Descricao: 'Cobre e suas obras.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7401.00.00',
        Descricao:
          'Mates de cobre; cobre de cementação (precipitado de cobre).',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7402.00.00',
        Descricao:
          'Cobre não refinado (afinado); ânodos de cobre para refinação (afinação) eletrolítica.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '74.03',
        Descricao:
          'Cobre refinado (afinado) e ligas de cobre em formas brutas.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7403.1',
        Descricao: '- Cobre refinado (afinado):',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7403.11.00',
        Descricao: '-- Cátodos e seus elementos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7403.12.00',
        Descricao:
          '-- Barras para obtenção de fios (\u003ci\u003ewire-bars\u003c/i\u003e)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7403.13.00',
        Descricao:
          '-- Palanquilhas (Lingotes*) (\u003ci\u003ebillets\u003c/i\u003e)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7403.19.00',
        Descricao: '-- Outro',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7403.2',
        Descricao: '- Ligas de cobre:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7403.21.00',
        Descricao: '-- À base de cobrezinco (latão)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7403.22.00',
        Descricao: '-- À base de cobre-estanho (bronze)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7403.29.00',
        Descricao:
          '-- Outras ligas de cobre (exceto ligas-mãe da posição 74.05)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7404.00.00',
        Descricao: 'Desperdícios e resíduos, e sucata, de cobre.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7405.00.00',
        Descricao: 'Ligas-mãe de cobre.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '74.06',
        Descricao: 'Pós e escamas, de cobre.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7406.10.00',
        Descricao: '- Pós de estrutura não lamelar',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7406.20.00',
        Descricao: '- Pós de estrutura lamelar; escamas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '74.07',
        Descricao: 'Barras e perfis, de cobre.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7407.10',
        Descricao: '- De cobre refinado (afinado)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7407.10.10',
        Descricao: 'Barras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7407.10.2',
        Descricao: 'Perfis',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7407.10.21',
        Descricao: 'Ocos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7407.10.29',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7407.2',
        Descricao: '- De ligas de cobre:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7407.21',
        Descricao: '-- À base de cobrezinco (latão)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7407.21.10',
        Descricao: 'Barras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7407.21.20',
        Descricao: 'Perfis',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7407.29',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7407.29.10',
        Descricao: 'Barras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7407.29.2',
        Descricao: 'Perfis',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7407.29.21',
        Descricao: 'Ocos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7407.29.29',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '74.08',
        Descricao: 'Fios de cobre.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7408.1',
        Descricao: '- De cobre refinado (afinado):',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7408.11.00',
        Descricao:
          '-- Com a maior dimensão da seção transversal superior a 6 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7408.19.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7408.2',
        Descricao: '- De ligas de cobre:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7408.21.00',
        Descricao: '-- À base de cobrezinco (latão)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7408.22.00',
        Descricao:
          '-- À base de cobreníquel (cuproníquel) ou de cobreniquelzinco (\u003ci\u003emaillechort\u003c/i\u003e)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7408.29',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7408.29.1',
        Descricao: 'À base de cobre-estanho (bronze)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7408.29.12',
        Descricao:
          'Fosforoso, de seção transversal circular, de diâmetro inferior ou igual a 0,8 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7408.29.13',
        Descricao: 'Outros, fosforosos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7408.29.19',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7408.29.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '74.09',
        Descricao: 'Chapas e tiras, de cobre, de espessura superior a 0,15 mm.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7409.1',
        Descricao: '- De cobre refinado (afinado):',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7409.11.00',
        Descricao: '-- Em rolos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7409.19.00',
        Descricao: '-- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7409.2',
        Descricao: '- De ligas à base de cobrezinco (latão):',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7409.21.00',
        Descricao: '-- Em rolos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7409.29.00',
        Descricao: '-- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7409.3',
        Descricao: '- De ligas à base de cobre-estanho (bronze):',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7409.31',
        Descricao: '-- Em rolos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7409.31.1',
        Descricao: 'Revestidas de plástico',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7409.31.11',
        Descricao:
          'Com uma camada intermediária de liga de cobre-estanho ou cobre-estanho-chumbo, aplicada por sinterização',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7409.31.19',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7409.31.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7409.39.00',
        Descricao: '-- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7409.40',
        Descricao:
          '- De ligas à base de cobreníquel (cuproníquel) ou de cobreniquelzinco (\u003ci\u003emaillechort\u003c/i\u003e)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7409.40.10',
        Descricao: 'Em rolos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7409.40.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7409.90.00',
        Descricao: '- De outras ligas de cobre',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '74.10',
        Descricao:
          'Folhas e tiras, delgadas, de cobre (mesmo impressas ou com suporte de papel, cartão, plástico ou semelhantes), de espessura não superior a 0,15 mm (excluindo o suporte).',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7410.1',
        Descricao: '- Sem suporte:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7410.11',
        Descricao: '-- De cobre refinado (afinado)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7410.11.1',
        Descricao:
          'Folha de espessura inferior ou igual a 0,07 mm e com pureza igual ou superior a 99,85 %, em peso',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7410.11.12',
        Descricao:
          'De espessura inferior ou igual a 0,04 mm e uma resistividade elétrica inferior ou igual a 0,017241 ohm.mm\u003csup\u003e2\u003c/sup\u003e/m',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7410.11.13',
        Descricao: 'Outras, de espessura inferior ou igual a 0,04 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7410.11.19',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7410.11.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7410.12.00',
        Descricao: '-- De ligas de cobre',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7410.2',
        Descricao: '- Com suporte:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7410.21',
        Descricao: '-- De cobre refinado (afinado)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7410.21.10',
        Descricao:
          'Com suporte isolante de resina epóxida e fibra de vidro, do tipo utilizado para circuitos impressos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7410.21.20',
        Descricao:
          'Com espessura superior a 0,012 mm, sobre suporte de poliéster ou poliimida e com espessura total, incluindo o suporte, inferior ou igual a 0,195 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7410.21.30',
        Descricao:
          'Com suporte isolante de resina fenólica e papel, do tipo utilizado para circuitos impressos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7410.21.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7410.22.00',
        Descricao: '-- De ligas de cobre',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '74.11',
        Descricao: 'Tubos de cobre.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7411.10',
        Descricao: '- De cobre refinado (afinado)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7411.10.10',
        Descricao: 'Não aletados nem ranhurados',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7411.10.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7411.2',
        Descricao: '- De ligas de cobre:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7411.21',
        Descricao: '-- À base de cobrezinco (latão)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7411.21.10',
        Descricao: 'Não aletados nem ranhurados',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7411.21.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7411.22',
        Descricao:
          '-- À base de cobreníquel (cuproníquel) ou de cobreniquelzinco (\u003ci\u003emaillechort\u003c/i\u003e)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7411.22.10',
        Descricao: 'Não aletados nem ranhurados',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7411.22.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7411.29',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7411.29.10',
        Descricao: 'Não aletados nem ranhurados',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7411.29.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '74.12',
        Descricao:
          'Acessórios para tubos (por exemplo, uniões, cotovelos, luvas (mangas*)), de cobre.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7412.10.00',
        Descricao: '- De cobre refinado (afinado)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7412.20.00',
        Descricao: '- De ligas de cobre',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7413.00.00',
        Descricao:
          'Cordas, cabos, tranças (entrançados*) e artigos semelhantes, de cobre, não isolados para usos elétricos.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '74.15',
        Descricao:
          'Tachas, pregos, percevejos, escápulas (pregos para tacos) e artigos semelhantes, de cobre ou de ferro ou aço com cabeça de cobre; parafusos, pinos ou pernos, roscados, porcas, ganchos roscados, rebites, chavetas, cavilhas, contrapinos ou troços, arruelas (anilhas) (incluindo as de pressão), e artigos semelhantes, de cobre.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7415.10.00',
        Descricao:
          '- Tachas, pregos, percevejos, escápulas (pregos para tacos) e artigos semelhantes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7415.2',
        Descricao: '- Outros artigos, não roscados:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7415.21.00',
        Descricao: '-- Arruelas (anilhas) (incluindo as de pressão)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7415.29.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7415.3',
        Descricao: '- Outros artigos, roscados:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7415.33.00',
        Descricao: '-- Parafusos; pinos ou pernos e porcas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7415.39.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '74.18',
        Descricao:
          'Serviços de mesa, artigos de cozinha e outros artigos de uso doméstico, e suas partes, de cobre; esponjas, esfregões, luvas e artigos semelhantes, para limpeza, polimento ou usos semelhantes, de cobre; artigos de higiene ou de toucador, e suas partes, de cobre.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7418.10.00',
        Descricao:
          '- Serviços de mesa, artigos de cozinha e outros artigos de uso doméstico, e suas partes; esponjas, esfregões, luvas e artigos semelhantes, para limpeza, polimento ou usos semelhantes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7418.20.00',
        Descricao: '- Artigos de higiene ou de toucador, e suas partes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '74.19',
        Descricao: 'Outras obras de cobre.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7419.20.00',
        Descricao:
          '- Vazadas, moldadas, estampadas ou forjadas, mas não trabalhadas de outro modo',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7419.80',
        Descricao: '- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7419.80.10',
        Descricao: 'Telas metálicas de fio de cobre',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7419.80.20',
        Descricao:
          'Grades e redes, de fio de cobre; chapas e tiras, distendidas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7419.80.30',
        Descricao: 'Molas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7419.80.40',
        Descricao: 'Discos próprios para cunhagem de moedas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7419.80.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '75',
        Descricao: 'Níquel e suas obras.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '75.01',
        Descricao:
          'Mates de níquel, \u003ci\u003esinters\u003c/i\u003e de óxidos de níquel e outros produtos intermediários da metalurgia do níquel.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7501.10.00',
        Descricao: '- Mates de níquel',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7501.20.00',
        Descricao:
          '- \u003ci\u003eSinters\u003c/i\u003e de óxidos de níquel e outros produtos intermediários da metalurgia do níquel',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '75.02',
        Descricao: 'Níquel em formas brutas.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7502.10',
        Descricao: '- Níquel não ligado',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7502.10.10',
        Descricao: 'Catodos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7502.10.90',
        Descricao: 'Outro',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7502.20.00',
        Descricao: '- Ligas de níquel',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7503.00.00',
        Descricao: 'Desperdícios e resíduos, e sucata, de níquel.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7504.00',
        Descricao: 'Pós e escamas, de níquel.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7504.00.10',
        Descricao: 'Não ligado',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7504.00.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '75.05',
        Descricao: 'Barras, perfis e fios, de níquel.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7505.1',
        Descricao: '- Barras e perfis:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7505.11',
        Descricao: '-- De níquel não ligado',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7505.11.10',
        Descricao: 'Barras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7505.11.2',
        Descricao: 'Perfis',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7505.11.21',
        Descricao: 'Ocos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7505.11.29',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7505.12',
        Descricao: '-- De ligas de níquel',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7505.12.10',
        Descricao: 'Barras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7505.12.2',
        Descricao: 'Perfis',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7505.12.21',
        Descricao: 'Ocos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7505.12.29',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7505.2',
        Descricao: '- Fios:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7505.21.00',
        Descricao: '-- De níquel não ligado',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7505.22',
        Descricao: '-- De ligas de níquel',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7505.22.10',
        Descricao: 'À base de niqueltitânio (nitinol)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7505.22.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '75.06',
        Descricao: 'Chapas, tiras e folhas, de níquel.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7506.10.00',
        Descricao: '- De níquel não ligado',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7506.20.00',
        Descricao: '- De ligas de níquel',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '75.07',
        Descricao:
          'Tubos e seus acessórios (por exemplo, uniões, cotovelos, luvas (mangas*)), de níquel.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7507.1',
        Descricao: '- Tubos:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7507.11.00',
        Descricao: '-- De níquel não ligado',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7507.12.00',
        Descricao: '-- De ligas de níquel',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7507.20.00',
        Descricao: '- Acessórios para tubos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '75.08',
        Descricao: 'Outras obras de níquel.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7508.10.00',
        Descricao: '- Telas metálicas e grades, de fios de níquel',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7508.90',
        Descricao: '- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7508.90.10',
        Descricao:
          'Cilindros ocos de seção variável, obtidos por centrifugação, do tipo utilizado em reformadores estequiométricos de gás natural',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7508.90.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '76',
        Descricao: 'Alumínio e suas obras.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '76.01',
        Descricao: 'Alumínio em formas brutas.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7601.10.00',
        Descricao: '- Alumínio não ligado',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7601.20.00',
        Descricao: '- Ligas de alumínio',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7602.00.00',
        Descricao: 'Desperdícios e resíduos, e sucata, de alumínio.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '76.03',
        Descricao: 'Pós e escamas, de alumínio.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7603.10.00',
        Descricao: '- Pós de estrutura não lamelar',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7603.20.00',
        Descricao: '- Pós de estrutura lamelar; escamas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '76.04',
        Descricao: 'Barras e perfis, de alumínio.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7604.10',
        Descricao: '- De alumínio não ligado',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7604.10.10',
        Descricao: 'Barras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7604.10.2',
        Descricao: 'Perfis',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7604.10.21',
        Descricao: 'Ocos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7604.10.29',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7604.2',
        Descricao: '- De ligas de alumínio:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7604.21.00',
        Descricao: '-- Perfis ocos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7604.29',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7604.29.1',
        Descricao: 'Barras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7604.29.11',
        Descricao:
          'Forjadas, de seção transversal circular, de diâmetro igual ou superior a 400 mm, mas não superior a 760 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7604.29.19',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7604.29.20',
        Descricao: 'Perfis',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '76.05',
        Descricao: 'Fios de alumínio.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7605.1',
        Descricao: '- De alumínio não ligado:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7605.11',
        Descricao:
          '-- Com a maior dimensão da seção transversal superior a 7 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7605.11.10',
        Descricao:
          'Com um teor de alumínio igual ou superior a 99,45 %, em peso, e uma resistividade elétrica inferior ou igual a 0,0283 ohm.mm\u003csup\u003e2\u003c/sup\u003e/m',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7605.11.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7605.19',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7605.19.10',
        Descricao:
          'Com um teor de alumínio igual ou superior a 99,45 %, em peso, e uma resistividade elétrica inferior ou igual a 0,0283 ohm.mm\u003csup\u003e2\u003c/sup\u003e/m',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7605.19.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7605.2',
        Descricao: '- De ligas de alumínio:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7605.21',
        Descricao:
          '-- Com a maior dimensão da seção transversal superior a 7 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7605.21.10',
        Descricao:
          'Com um teor, em peso, de alumínio igual ou superior a 98,45 %, e de magnésio e silício, considerados individualmente, igual ou superior a 0,45 % e inferior ou igual a 0,55 % e uma resistividade elétrica inferior ou igual a 0,0328 ohm.mm\u003csup\u003e2\u003c/sup\u003e/m',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7605.21.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7605.29',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7605.29.10',
        Descricao:
          'Com um teor, em peso, de alumínio igual ou superior a 98,45 %, e de magnésio e silício, considerados individualmente, igual ou superior a 0,45 % e inferior ou igual a 0,55 % e uma resistividade elétrica inferior ou igual a 0,0328 ohm.mm\u003csup\u003e2\u003c/sup\u003e/m',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7605.29.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '76.06',
        Descricao:
          'Chapas e tiras, de alumínio, de espessura superior a 0,2 mm.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7606.1',
        Descricao: '- De forma quadrada ou retangular:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7606.11',
        Descricao: '-- De alumínio não ligado',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7606.11.10',
        Descricao:
          'Com um teor, em peso, de silício igual ou superior a 0,05 % e inferior ou igual a 0,20 %, de ferro igual ou superior a 0,20 % e inferior ou igual a 0,40 %, de cobre inferior ou igual a 0,05 %, de zinco inferior ou igual a 0,05 %, de manganês inferior ou igual a 0,1 % e de outros elementos, cada um, inferior ou igual a 0,1 %, de espessura inferior ou igual a 0,4 mm, em bobinas de largura superior a 900 mm, com uma relação entre as rugosidades máxima e aritmética média igual ou superior a 1,25 e inferior ou igual a 1,30 em cada uma das faces e com um limite de resistência à tração igual ou superior a 115 MPa',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7606.11.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7606.12',
        Descricao: '-- De ligas de alumínio',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7606.12.10',
        Descricao:
          'Com teores, em peso, de magnésio igual ou superior a 4 % e inferior ou igual a 5 %, de manganês igual ou superior a 0,20 % e inferior ou igual a 0,50 %, de ferro inferior ou igual a 0,35 %, de silício inferior ou igual a 0,20 % e de outros metais, em conjunto, inferior ou igual a 0,75 %, e de espessura inferior ou igual a 0,3 mm e largura igual ou superior a 1.450 mm, envernizadas em ambas as faces',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7606.12.20',
        Descricao:
          'Com um teor, em peso, de alumínio igual ou superior a 99 % e de magnésio superior a 0,10 %, de espessura inferior ou igual a 0,40 mm, com um limite de resistência a tração igual ou superior a 140 MPa, mas inferior ou igual a 240 MPa, e alongamento igual ou superior a 0,90 %, mas inferior ou igual a 7 %',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7606.12.30',
        Descricao:
          'Simplesmente laminadas, constituídas de, pelo menos, duas camadas de diferentes ligas de alumínio, sendo uma o núcleo e as outras de revestimento, exceto: núcleo de liga da \u003ci\u003eAluminium Association\u003c/i\u003e AA 3003 modificada (liga de alumínio com um teor, em peso, de silício inferior ou igual a 0,60 %, de ferro inferior ou igual a 0,70 %, de cobre igual ou superior a 0,05 % e inferior ou igual a 0,20 %, de manganês igual ou superior a 1,0 % e inferior ou igual a 1,5 %, de zinco inferior ou igual a 0,15 % e de outros elementos total inferior ou igual a 0,15 %) com revestimento de liga AA 4343 (liga de alumínio com um teor, em peso, de silício igual ou superior a 6,8 % e inferior ou igual a 8,2 %, de ferro inferior ou igual a 0,80 %, de cobre inferior ou igual a 0,25 %, de manganês inferior ou igual a 0,10 %, de zinco inferior ou igual a 0,20 % e de outros elementos total inferior ou igual a 0,15 %)',
        Data_Inicio: '01/01/2023',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000413',
        Ano_Ato: '2022',
      },
      {
        Codigo: '7606.12.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7606.9',
        Descricao: '- Outras:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7606.91.00',
        Descricao: '-- De alumínio não ligado',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7606.92.00',
        Descricao: '-- De ligas de alumínio',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '76.07',
        Descricao:
          'Folhas e tiras, delgadas, de alumínio (mesmo impressas ou com suporte de papel, cartão, plástico ou semelhantes), de espessura não superior a 0,2 mm (excluindo o suporte).',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7607.1',
        Descricao: '- Sem suporte:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7607.11',
        Descricao: '-- Simplesmente laminadas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7607.11.10',
        Descricao:
          'Com um teor, em peso, de alumínio igual ou superior a 99 % e de magnésio superior a 0,06 %, de espessura igual ou superior a 0,12 mm, com um limite de resistência a tração igual ou superior a 140 MPa, mas inferior ou igual a 240 MPa, e alongamento igual ou superior a 0,90 %, mas inferior ou igual a 7 %',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7607.11.20',
        Descricao:
          'Constituídas de, pelo menos, duas camadas de diferentes ligas de alumínio, sendo uma o núcleo e as outras de revestimento, exceto: núcleo de liga da \u003ci\u003eAluminium Association\u003c/i\u003e AA 3003 modificada (liga de alumínio com um teor, em peso, de silício inferior ou igual a 0,60 %, de ferro inferior ou igual a 0,70 %, de cobre igual ou superior a 0,05 % e inferior ou igual a 0,20 %, de manganês igual ou superior a 1,0 % e inferior ou igual a 1,5 %, de zinco inferior ou igual a 0,15 % e de outros elementos total inferior ou igual a 0,15 %) com revestimento de liga AA 4343 (liga de alumínio com um teor, em peso, de silício igual ou superior a 6,8 % e inferior ou igual a 8,2 %, de ferro inferior ou igual a 0,80 %, de cobre inferior ou igual a 0,25 %, de manganês inferior ou igual a 0,10 %, de zinco inferior ou igual a 0,20 % e de outros elementos total inferior ou igual a 0,15 %)',
        Data_Inicio: '01/01/2023',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000413',
        Ano_Ato: '2022',
      },
      {
        Codigo: '7607.11.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7607.19',
        Descricao: '-- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7607.19.10',
        Descricao:
          'Gravadas por processo eletroquímico de corrosão, mesmo com camada de óxido de alumínio, de espessura inferior ou igual a 110 micrômetros (mícrons) e com um conteúdo de alumínio igual ou superior a 98 %, em peso',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7607.19.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7607.20.00',
        Descricao: '- Com suporte',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '76.08',
        Descricao: 'Tubos de alumínio.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7608.10.00',
        Descricao: '- De alumínio não ligado',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7608.20',
        Descricao: '- De ligas de alumínio',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7608.20.10',
        Descricao:
          'Sem costura, extrudados e trefilados, segundo Norma ASTM B210, de seção circular, de liga AA 6061 (\u003ci\u003eAluminium Association\u003c/i\u003e), com limite elástico aparente de Johnson (JAEL) superior a 3.000 Nm, segundo Norma SAE AE7, diâmetro externo igual ou superior a 85 mm, mas inferior ou igual a 105 mm e espessura igual ou superior a 1,9 mm, mas inferior ou igual a 2,3 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7608.20.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7609.00.00',
        Descricao:
          'Acessórios para tubos (por exemplo, uniões, cotovelos, luvas (mangas*)), de alumínio.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '76.10',
        Descricao:
          'Construções e suas partes (por exemplo, pontes e elementos de pontes, torres, pórticos ou pilones, pilares, colunas, armações, estruturas para telhados, portas e janelas, e seus caixilhos, alizares e soleiras, balaustradas), de alumínio, exceto as construções pré-fabricadas da posição 94.06; chapas, barras, perfis, tubos e semelhantes, de alumínio, próprios para construções.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7610.10.00',
        Descricao: '- Portas e janelas, e seus caixilhos, alizares e soleiras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7610.90.00',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7611.00.00',
        Descricao:
          'Reservatórios, tonéis, cubas e recipientes semelhantes para quaisquer matérias (exceto gases comprimidos ou liquefeitos), de alumínio, de capacidade superior a 300 l, sem dispositivos mecânicos ou térmicos, mesmo com revestimento interior ou calorífugo.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '76.12',
        Descricao:
          'Reservatórios, barris, tambores, latas, caixas e recipientes semelhantes (incluindo os recipientes tubulares, rígidos ou flexíveis) para quaisquer matérias (exceto gases comprimidos ou liquefeitos), de alumínio, de capacidade não superior a 300 l, sem dispositivos mecânicos ou térmicos, mesmo com revestimento interior ou calorífugo.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7612.10.00',
        Descricao: '- Recipientes tubulares, flexíveis',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7612.90',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7612.90.1',
        Descricao: 'Recipientes tubulares',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7612.90.11',
        Descricao:
          'Para aerossóis, com capacidade inferior ou igual a 700 cm\u003csup\u003e3\u003c/sup\u003e',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7612.90.12',
        Descricao:
          'Isotérmicos, refrigerados a nitrogênio (azoto) líquido, do tipo utilizado para sêmen, sangue, tecidos biológicos e outros produtos semelhantes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7612.90.19',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7612.90.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7613.00.00',
        Descricao:
          'Recipientes para gases comprimidos ou liquefeitos, de alumínio.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '76.14',
        Descricao:
          'Cordas, cabos, tranças (entrançados*) e semelhantes, de alumínio, não isolados para usos elétricos.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7614.10',
        Descricao: '- Com alma de aço',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7614.10.10',
        Descricao: 'Cordas e cabos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7614.10.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7614.90',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7614.90.10',
        Descricao: 'Cabos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7614.90.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '76.15',
        Descricao:
          'Serviços de mesa, artigos de cozinha e outros artigos de uso doméstico, e suas partes, de alumínio; esponjas, esfregões, luvas e artigos semelhantes, para limpeza, polimento ou usos semelhantes, de alumínio; artigos de higiene ou de toucador, e suas partes, de alumínio.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7615.10.00',
        Descricao:
          '- Serviços de mesa, artigos de cozinha e outros artigos de uso doméstico, e suas partes; esponjas, esfregões, luvas e artigos semelhantes, para limpeza, polimento ou usos semelhantes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7615.20.00',
        Descricao: '- Artigos de higiene ou de toucador, e suas partes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '76.16',
        Descricao: 'Outras obras de alumínio.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7616.10.00',
        Descricao:
          '- Tachas, pregos, escápulas (pregos para tacos), parafusos, pinos ou pernos roscados, porcas, ganchos roscados, rebites, chavetas, cavilhas, contrapinos ou troços, arruelas (anilhas) e artigos semelhantes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7616.9',
        Descricao: '- Outras:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7616.91.00',
        Descricao: '-- Telas metálicas, grades e redes, de fios de alumínio',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7616.99.00',
        Descricao: '-- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '78',
        Descricao: 'Chumbo e suas obras.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '78.01',
        Descricao: 'Chumbo em formas brutas.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7801.10',
        Descricao: '- Chumbo refinado (afinado)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7801.10.1',
        Descricao: 'Eletrolítico',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7801.10.11',
        Descricao: 'Em lingotes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7801.10.19',
        Descricao: 'Outro',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7801.10.90',
        Descricao: 'Outro',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7801.9',
        Descricao: '- Outro:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7801.91.00',
        Descricao:
          '-- Que contenha antimônio como segundo elemento predominante em peso',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7801.99.00',
        Descricao: '-- Outro',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7802.00.00',
        Descricao: 'Desperdícios e resíduos, e sucata, de chumbo.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '78.04',
        Descricao:
          'Chapas, folhas e tiras, de chumbo; pós e escamas, de chumbo.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7804.1',
        Descricao: '- Chapas, folhas e tiras:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7804.11.00',
        Descricao:
          '-- Folhas e tiras, de espessura não superior a 0,2 mm (excluindo o suporte)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7804.19.00',
        Descricao: '-- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7804.20.00',
        Descricao: '- Pós e escamas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7806.00',
        Descricao: 'Outras obras de chumbo.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7806.00.10',
        Descricao: 'Barras, perfis e fios',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7806.00.20',
        Descricao: 'Tubos e seus acessórios',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7806.00.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '79',
        Descricao: 'Zinco e suas obras.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '79.01',
        Descricao: 'Zinco em formas brutas.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7901.1',
        Descricao: '- Zinco não ligado:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7901.11',
        Descricao: '-- Que contenha, em peso, 99,99 % ou mais de zinco',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7901.11.1',
        Descricao: 'Eletrolítico',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7901.11.11',
        Descricao: 'Em lingotes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7901.11.19',
        Descricao: 'Outro',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7901.11.9',
        Descricao: 'Outro',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7901.11.91',
        Descricao: 'Em lingotes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7901.11.99',
        Descricao: 'Outro',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7901.12',
        Descricao: '-- Que contenha, em peso, menos de 99,99 % de zinco',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7901.12.10',
        Descricao: 'Em lingotes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7901.12.90',
        Descricao: 'Outro',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7901.20',
        Descricao: '- Ligas de zinco',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7901.20.10',
        Descricao: 'Em lingotes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7901.20.90',
        Descricao: 'Outro',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7902.00.00',
        Descricao: 'Desperdícios e resíduos, e sucata, de zinco.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '79.03',
        Descricao: 'Poeiras, pós e escamas, de zinco.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7903.10.00',
        Descricao: '- Poeiras de zinco',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7903.90.00',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7904.00.00',
        Descricao: 'Barras, perfis e fios, de zinco.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7905.00.00',
        Descricao: 'Chapas, folhas e tiras, de zinco.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7907.00',
        Descricao: 'Outras obras de zinco.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7907.00.10',
        Descricao: 'Tubos e seus acessórios',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '7907.00.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '80',
        Descricao: 'Estanho e suas obras.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '80.01',
        Descricao: 'Estanho em formas brutas.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8001.10.00',
        Descricao: '- Estanho não ligado',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8001.20.00',
        Descricao: '- Ligas de estanho',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8002.00.00',
        Descricao: 'Desperdícios e resíduos, e sucata, de estanho.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8003.00.00',
        Descricao: 'Barras, perfis e fios, de estanho.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8007.00',
        Descricao: 'Outras obras de estanho.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8007.00.10',
        Descricao: 'Chapas, folhas e tiras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8007.00.20',
        Descricao: 'Pós e escamas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8007.00.30',
        Descricao: 'Tubos e seus acessórios',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8007.00.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '81',
        Descricao:
          'Outros metais comuns; \u003ci\u003ecermets\u003c/i\u003e; obras dessas matérias.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '81.01',
        Descricao:
          'Tungstênio (volfrâmio) e suas obras, incluindo os desperdícios e resíduos, e sucata.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8101.10.00',
        Descricao: '- Pós',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8101.9',
        Descricao: '- Outros:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8101.94.00',
        Descricao:
          '-- Tungstênio (volfrâmio) em formas brutas, incluindo as barras simplesmente obtidas por sinterização',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8101.96.00',
        Descricao: '-- Fios',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8101.97.00',
        Descricao: '-- Desperdícios e resíduos, e sucata',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8101.99',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8101.99.10',
        Descricao: 'Do tipo utilizado na fabricação de contatos elétricos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8101.99.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '81.02',
        Descricao:
          'Molibdênio e suas obras, incluindo os desperdícios e resíduos, e sucata.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8102.10.00',
        Descricao: '- Pós',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8102.9',
        Descricao: '- Outros:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8102.94.00',
        Descricao:
          '-- Molibdênio em formas brutas, incluindo as barras simplesmente obtidas por sinterização',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8102.95.00',
        Descricao:
          '-- Barras, exceto as simplesmente obtidas por sinterização, perfis, chapas, tiras e folhas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8102.96.00',
        Descricao: '-- Fios',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8102.97.00',
        Descricao: '-- Desperdícios e resíduos, e sucata',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8102.99.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '81.03',
        Descricao:
          'Tântalo e suas obras, incluindo os desperdícios e resíduos, e sucata.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8103.20.00',
        Descricao:
          '- Tântalo em formas brutas, incluindo as barras simplesmente obtidas por sinterização; pós',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8103.30.00',
        Descricao: '- Desperdícios e resíduos, e sucata',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8103.9',
        Descricao: '- Outros:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8103.91.00',
        Descricao: '-- Cadinhos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8103.99.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '81.04',
        Descricao:
          'Magnésio e suas obras, incluindo os desperdícios e resíduos, e sucata.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8104.1',
        Descricao: '- Magnésio em formas brutas:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8104.11.00',
        Descricao: '-- Que contenha pelo menos 99,8 %, em peso, de magnésio',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8104.19.00',
        Descricao: '-- Outro',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8104.20.00',
        Descricao: '- Desperdícios e resíduos, e sucata',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8104.30.00',
        Descricao: '- Aparas, resíduos de torno e grânulos, calibrados; pós',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8104.90.00',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '81.05',
        Descricao:
          'Mates de cobalto e outros produtos intermediários da metalurgia do cobalto; cobalto e suas obras, incluindo os desperdícios e resíduos, e sucata.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8105.20',
        Descricao:
          '- Mates de cobalto e outros produtos intermediários da metalurgia do cobalto; cobalto em formas brutas; pós',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8105.20.10',
        Descricao: 'Em formas brutas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8105.20.2',
        Descricao: 'Pós',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8105.20.21',
        Descricao:
          'De ligas à base de cobalto-cromo-tungstênio (volfrâmio) (estelites)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8105.20.29',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8105.20.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8105.30.00',
        Descricao: '- Desperdícios e resíduos, e sucata',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8105.90',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8105.90.10',
        Descricao: 'Chapas, folhas, tiras, fios, hastes, pastilhas e plaquetas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8105.90.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '81.06',
        Descricao:
          'Bismuto e suas obras, incluindo os desperdícios e resíduos, e sucata.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8106.10.00',
        Descricao: '- Que contenham mais de 99,99 %, em peso, de bismuto',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8106.90.00',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '81.08',
        Descricao:
          'Titânio e suas obras, incluindo os desperdícios e resíduos, e sucata.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8108.20.00',
        Descricao: '- Titânio em formas brutas; pós',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8108.30.00',
        Descricao: '- Desperdícios e resíduos, e sucata',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8108.90.00',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '81.09',
        Descricao:
          'Zircônio e suas obras, incluindo os desperdícios e resíduos, e sucata.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8109.2',
        Descricao: '- Zircônio em formas brutas; pós:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8109.21.00',
        Descricao:
          '-- Que contenham menos de uma parte de háfnio para 500 partes, em peso, de zircônio',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8109.29.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8109.3',
        Descricao: '- Desperdícios e resíduos, e sucata:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8109.31.00',
        Descricao:
          '-- Que contenham menos de uma parte de háfnio para 500 partes, em peso, de zircônio',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8109.39.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8109.9',
        Descricao: '- Outros:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8109.91.00',
        Descricao:
          '-- Que contenham menos de uma parte de háfnio para 500 partes, em peso, de zircônio',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8109.99.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '81.10',
        Descricao:
          'Antimônio e suas obras, incluindo os desperdícios e resíduos, e sucata.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8110.10',
        Descricao: '- Antimônio em formas brutas; pós',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8110.10.10',
        Descricao: 'Em formas brutas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8110.10.20',
        Descricao: 'Pós',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8110.20.00',
        Descricao: '- Desperdícios e resíduos, e sucata',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8110.90.00',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8111.00',
        Descricao:
          'Manganês e suas obras, incluindo os desperdícios e resíduos, e sucata.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8111.00.10',
        Descricao: 'Em formas brutas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8111.00.20',
        Descricao: 'Chapas, folhas, tiras, fios, hastes, pastilhas e plaquetas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8111.00.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '81.12',
        Descricao:
          'Berílio, cromo, háfnio (céltio), rênio, tálio, cádmio, germânio, vanádio, gálio, índio e nióbio (colômbio), e suas obras, incluindo os desperdícios e resíduos, e sucata.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8112.1',
        Descricao: '- Berílio:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8112.12.00',
        Descricao: '-- Em formas brutas; pós',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8112.13.00',
        Descricao: '-- Desperdícios e resíduos, e sucata',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8112.19.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8112.2',
        Descricao: '- Cromo:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8112.21',
        Descricao: '-- Em formas brutas; pós',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8112.21.10',
        Descricao: 'Em formas brutas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8112.21.20',
        Descricao: 'Pós',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8112.22.00',
        Descricao: '-- Desperdícios e resíduos, e sucata',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8112.29.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8112.3',
        Descricao: '- Háfnio (céltio):',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8112.31.00',
        Descricao:
          '-- Em formas brutas; desperdícios e resíduos, e sucata; pós',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8112.39.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8112.4',
        Descricao: '- Rênio:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8112.41.00',
        Descricao:
          '-- Em formas brutas; desperdícios e resíduos, e sucata; pós',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8112.49.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8112.5',
        Descricao: '- Tálio:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8112.51.00',
        Descricao: '-- Em formas brutas; pós',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8112.52.00',
        Descricao: '-- Desperdícios e resíduos, e sucata',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8112.59.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8112.6',
        Descricao: '- Cádmio:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8112.61.00',
        Descricao: '-- Desperdícios e resíduos, e sucata',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8112.69.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8112.9',
        Descricao: '- Outros:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8112.92.00',
        Descricao:
          '-- Em formas brutas; desperdícios e resíduos, e sucata; pós',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8112.99.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8113.00',
        Descricao:
          '\u003ci\u003eCermets\u003c/i\u003e e suas obras, incluindo os desperdícios e resíduos, e sucata.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8113.00.10',
        Descricao: 'Chapas, folhas, tiras, fios, hastes, pastilhas e plaquetas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8113.00.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '82',
        Descricao:
          'Ferramentas, artigos de cutelaria e talheres, e suas partes, de metais comuns.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '82.01',
        Descricao:
          'Pás, alviões, picaretas, enxadas, sachos, forcados, forquilhas, ancinhos e raspadeiras; machados, podões e ferramentas semelhantes com gume; tesouras de podar de qualquer tipo; foices e foicinhas, facas para feno ou para palha, tesouras para sebes, cunhas e outras ferramentas manuais para agricultura, horticultura ou silvicultura.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8201.10.00',
        Descricao: '- Pás',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8201.30.00',
        Descricao:
          '- Alviões, picaretas, enxadas, sachos, ancinhos e raspadeiras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8201.40.00',
        Descricao: '- Machados, podões e ferramentas semelhantes com gume',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8201.50.00',
        Descricao:
          '- Tesouras de podar (incluindo as tesouras para aves) manipuladas com uma das mãos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8201.60.00',
        Descricao:
          '- Tesouras para sebes, tesouras de podar e ferramentas semelhantes, manipuladas com as duas mãos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8201.90.00',
        Descricao:
          '- Outras ferramentas manuais para agricultura, horticultura e silvicultura',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '82.02',
        Descricao:
          'Serras manuais; folhas de serras de qualquer tipo (incluindo as fresas-serras e as folhas não dentadas para serrar).',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8202.10.00',
        Descricao: '- Serras manuais',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8202.20.00',
        Descricao: '- Folhas de serras de fita',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8202.3',
        Descricao:
          '- Folhas de serras circulares (incluindo as fresas-serras):',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8202.31.00',
        Descricao: '-- Com parte operante de aço',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8202.39.00',
        Descricao: '-- Outras, incluindo as partes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8202.40.00',
        Descricao: '- Correntes cortantes de serras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8202.9',
        Descricao: '- Outras folhas de serras:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8202.91.00',
        Descricao: '-- Folhas de serras retilíneas, para trabalhar metais',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8202.99',
        Descricao: '-- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8202.99.10',
        Descricao: 'Retas, não dentadas, para serrar pedras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8202.99.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '82.03',
        Descricao:
          'Limas, grosas, alicates (mesmo cortantes), tenazes, pinças, cisalhas para metais, corta-tubos, corta-pinos, saca-bocados e ferramentas semelhantes, manuais.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8203.10',
        Descricao: '- Limas, grosas e ferramentas semelhantes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8203.10.10',
        Descricao: 'Limas e grosas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8203.10.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8203.20',
        Descricao:
          '- Alicates (mesmo cortantes), tenazes, pinças e ferramentas semelhantes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8203.20.10',
        Descricao: 'Alicates (mesmo cortantes)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8203.20.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8203.30.00',
        Descricao: '- Cisalhas para metais e ferramentas semelhantes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8203.40.00',
        Descricao:
          '- Corta-tubos, corta-pinos, saca-bocados e ferramentas semelhantes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '82.04',
        Descricao:
          'Chaves de porcas, manuais (incluindo as chaves dinamométricas); chaves de caixa intercambiáveis, mesmo com cabos.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8204.1',
        Descricao: '- Chaves de porcas, manuais:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8204.11.00',
        Descricao: '-- De abertura fixa',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8204.12.00',
        Descricao: '-- De abertura variável',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8204.20.00',
        Descricao: '- Chaves de caixa intercambiáveis, mesmo com cabos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '82.05',
        Descricao:
          'Ferramentas manuais (incluindo os corta-vidros (diamantes de vidraceiro)) não especificadas nem compreendidas noutras posições; lâmpadas ou lamparinas de soldar (maçaricos) e semelhantes; tornos de apertar, sargentos e semelhantes, exceto os acessórios ou partes de máquinas-ferramentas ou de máquinas de corte a jato de água; bigornas; forjas portáteis; mós com armação, manuais ou de pedal.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8205.10.00',
        Descricao: '- Ferramentas de furar ou de roscar',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8205.20.00',
        Descricao: '- Martelos e marretas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8205.30.00',
        Descricao:
          '- Plainas, formões, goivas e ferramentas cortantes semelhantes, para trabalhar madeira',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8205.40.00',
        Descricao: '- Chaves de fenda',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8205.5',
        Descricao:
          '- Outras ferramentas manuais (incluindo os corta-vidros (diamantes de vidraceiro)):',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8205.51.00',
        Descricao: '-- De uso doméstico',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8205.59.00',
        Descricao: '-- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8205.60.00',
        Descricao:
          '- Lâmpadas ou lamparinas de soldar (maçaricos) e semelhantes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8205.70.00',
        Descricao: '- Tornos de apertar, sargentos e semelhantes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8205.90.00',
        Descricao:
          '- Outros, incluindo os sortidos constituídos por artigos incluídos em pelo menos duas das subposições da presente posição',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8206.00.00',
        Descricao:
          'Ferramentas de pelo menos duas das posições 82.02 a 82.05, acondicionadas em sortidos para venda a retalho.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '82.07',
        Descricao:
          'Ferramentas intercambiáveis para ferramentas manuais, mesmo mecânicas, ou para máquinas-ferramentas (por exemplo, de embutir, estampar, puncionar, roscar (incluindo atarraxar), furar, escarear, mandrilar, brochar, fresar, tornear, aparafusar), incluindo as fieiras de estiramento ou de extrusão, para metais, e as ferramentas de perfuração ou de sondagem.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8207.1',
        Descricao: '- Ferramentas de perfuração ou de sondagem:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8207.13.00',
        Descricao:
          '-- Com parte operante de \u003ci\u003ecermets\u003c/i\u003e',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8207.19',
        Descricao: '-- Outras, incluindo as partes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8207.19.10',
        Descricao: 'Brocas (\u003ci\u003edrill bits\u003c/i\u003e)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8207.19.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8207.20.00',
        Descricao: '- Fieiras de estiramento ou de extrusão, para metais',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8207.30.00',
        Descricao: '- Ferramentas de embutir, de estampar ou de puncionar',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8207.40',
        Descricao:
          '- Ferramentas de roscar (incluindo atarraxar) interior ou exteriormente',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8207.40.10',
        Descricao: 'De roscar interiormente',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8207.40.20',
        Descricao: 'De roscar exteriormente',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8207.50',
        Descricao: '- Ferramentas de furar',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8207.50.1',
        Descricao: 'Brocas, mesmo diamantadas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8207.50.11',
        Descricao: 'Helicoidais, de diâmetro inferior ou igual a 52 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8207.50.19',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8207.50.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8207.60.00',
        Descricao: '- Ferramentas de escarear, mandrilar ou de brochar',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8207.70',
        Descricao: '- Ferramentas de fresar',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8207.70.10',
        Descricao: 'De topo',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8207.70.20',
        Descricao: 'Para cortar engrenagens',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8207.70.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8207.80.00',
        Descricao: '- Ferramentas de tornear',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8207.90.00',
        Descricao: '- Outras ferramentas intercambiáveis',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '82.08',
        Descricao:
          'Facas e lâminas cortantes, para máquinas ou para aparelhos mecânicos.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8208.10.00',
        Descricao: '- Para trabalhar metais',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8208.20.00',
        Descricao: '- Para trabalhar madeira',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8208.30.00',
        Descricao:
          '- Para aparelhos de cozinha ou para máquinas das indústrias alimentares',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8208.40.00',
        Descricao:
          '- Para máquinas de agricultura, horticultura ou silvicultura',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8208.90.00',
        Descricao: '- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8209.00',
        Descricao:
          'Plaquetas, varetas, pontas e objetos semelhantes para ferramentas, não montados, de \u003ci\u003ecermets\u003c/i\u003e.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8209.00.1',
        Descricao: 'Plaquetas ou pastilhas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8209.00.11',
        Descricao: 'Intercambiáveis',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8209.00.19',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8209.00.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8210.00',
        Descricao:
          'Aparelhos mecânicos de acionamento manual, pesando até 10 kg, utilizados para preparar, acondicionar ou servir alimentos ou bebidas.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8210.00.10',
        Descricao: 'Moinhos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8210.00.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '82.11',
        Descricao:
          'Facas (exceto as da posição 82.08) de lâmina cortante ou serrilhada, incluindo as podadeiras de lâmina móvel, e suas lâminas.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8211.10.00',
        Descricao: '- Sortidos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8211.9',
        Descricao: '- Outras:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8211.91.00',
        Descricao: '-- Facas de mesa, de lâmina fixa',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8211.92',
        Descricao: '-- Outras facas de lâmina fixa',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8211.92.10',
        Descricao: 'Para cozinha e açougue',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8211.92.20',
        Descricao: 'Para caça',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8211.92.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8211.93',
        Descricao:
          '-- Facas, exceto as de lâmina fixa, incluindo as podadeiras de lâmina móvel',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8211.93.10',
        Descricao: 'Podadeiras e suas partes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8211.93.20',
        Descricao: 'Canivetes com uma ou várias lâminas ou outras peças',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8211.93.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8211.94.00',
        Descricao: '-- Lâminas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8211.95.00',
        Descricao: '-- Cabos de metais comuns',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '82.12',
        Descricao:
          'Navalhas e aparelhos, de barbear, e suas lâminas (incluindo os esboços em tiras).',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8212.10',
        Descricao: '- Navalhas e aparelhos, de barbear',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8212.10.10',
        Descricao: 'Navalhas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8212.10.20',
        Descricao: 'Aparelhos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8212.20',
        Descricao:
          '- Lâminas de barbear de segurança, incluindo os esboços em tiras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8212.20.10',
        Descricao: 'Lâminas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8212.20.20',
        Descricao: 'Esboços em tiras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8212.90.00',
        Descricao: '- Outras partes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8213.00.00',
        Descricao: 'Tesouras e suas lâminas.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '82.14',
        Descricao:
          'Outros artigos de cutelaria (por exemplo, máquinas de cortar o cabelo ou tosquiar, fendeleiras, cutelos, incluindo os de açougue (talho) e de cozinha, e espátulas (corta-papéis)); utensílios e sortidos de utensílios de manicuros ou de pedicuros (incluindo as limas para unhas).',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8214.10.00',
        Descricao:
          '- Espátulas (corta-papéis), abre-cartas, raspadeiras, apontadores de lápis (apara-lápis) e suas lâminas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8214.20.00',
        Descricao:
          '- Utensílios e sortidos de utensílios de manicuros ou de pedicuros (incluindo as limas para unhas)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8214.90',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8214.90.10',
        Descricao: 'Máquinas de tosquiar e suas partes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8214.90.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '82.15',
        Descricao:
          'Colheres, garfos, conchas, escumadeiras, pás para tortas, facas especiais para peixe ou para manteiga, pinças para açúcar e artigos semelhantes.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8215.10.00',
        Descricao:
          '- Sortidos que contenham pelo menos um objeto prateado, dourado ou platinado',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8215.20.00',
        Descricao: '- Outros sortidos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8215.9',
        Descricao: '- Outros:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8215.91.00',
        Descricao: '-- Prateados, dourados ou platinados',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8215.99',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8215.99.10',
        Descricao: 'De aço inoxidável',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8215.99.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '83',
        Descricao: 'Obras diversas de metais comuns.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '83.01',
        Descricao:
          'Cadeados, fechaduras e ferrolhos (de chave, de segredo ou elétricos), de metais comuns; fechos e armações com fecho, com fechadura, de metais comuns; chaves para estes artigos, de metais comuns.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8301.10.00',
        Descricao: '- Cadeados',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8301.20.00',
        Descricao: '- Fechaduras do tipo utilizado em veículos automóveis',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8301.30.00',
        Descricao: '- Fechaduras do tipo utilizado em móveis',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8301.40.00',
        Descricao: '- Outras fechaduras; ferrolhos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8301.50.00',
        Descricao: '- Fechos e armações com fecho, com fechadura',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8301.60.00',
        Descricao: '- Partes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8301.70.00',
        Descricao: '- Chaves apresentadas isoladamente',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '83.02',
        Descricao:
          'Guarnições, ferragens e artigos semelhantes, de metais comuns, para móveis, portas, escadas, janelas, persianas, carroçarias, artigos de seleiro, malas, cofres, caixas de segurança e outras obras semelhantes; pateras, porta-chapéus, cabides e artigos semelhantes, de metais comuns; rodízios com armação de metais comuns; fechos automáticos para portas, de metais comuns.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8302.10.00',
        Descricao:
          '- Dobradiças de qualquer espécie (incluindo os gonzos e as charneiras)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8302.20.00',
        Descricao: '- Rodízios',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8302.30.00',
        Descricao:
          '- Outras guarnições, ferragens e artigos semelhantes, para veículos automóveis',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8302.4',
        Descricao: '- Outras guarnições, ferragens e artigos semelhantes:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8302.41.00',
        Descricao: '-- Para construções',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8302.42.00',
        Descricao: '-- Outros, para móveis',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8302.49.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8302.50.00',
        Descricao: '- Pateras, porta-chapéus, cabides e artigos semelhantes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8302.60.00',
        Descricao: '- Fechos automáticos para portas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8303.00.00',
        Descricao:
          'Cofres-fortes, portas blindadas e compartimentos para casas-fortes, cofres e caixas de segurança e artigos semelhantes, de metais comuns.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8304.00.00',
        Descricao:
          'Classificadores, fichários (ficheiros), caixas de classificação, porta-cópias, porta-canetas, porta-carimbos e artigos semelhantes, de escritório, de metais comuns, excluindo os móveis de escritório da posição 94.03.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '83.05',
        Descricao:
          'Ferragens para encadernação de folhas soltas ou para classificadores, prendedores (molas*) para papéis, cantos para cartas, clipes, indicadores para fichas ou cavaleiros e objetos semelhantes de escritório, de metais comuns; grampos (agrafos*) apresentados em barretas (por exemplo, de escritório, para atapetar, para embalagem), de metais comuns.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8305.10.00',
        Descricao:
          '- Ferragens para encadernação de folhas soltas ou para classificadores',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8305.20.00',
        Descricao: '- Grampos (Agrafos*) apresentados em barretas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8305.90.00',
        Descricao: '- Outros, incluindo as partes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '83.06',
        Descricao:
          'Sinos, campainhas, gongos e artigos semelhantes, não elétricos, de metais comuns; estatuetas e outros objetos de ornamentação, de metais comuns; molduras para fotografias, gravuras ou semelhantes, de metais comuns; espelhos de metais comuns.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8306.10.00',
        Descricao: '- Sinos, campainhas, gongos e artigos semelhantes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8306.2',
        Descricao: '- Estatuetas e outros objetos de ornamentação:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8306.21.00',
        Descricao: '-- Prateados, dourados ou platinados',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8306.29.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8306.30.00',
        Descricao:
          '- Molduras para fotografias, gravuras ou semelhantes; espelhos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '83.07',
        Descricao: 'Tubos flexíveis de metais comuns, mesmo com acessórios.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8307.10',
        Descricao: '- De ferro ou aço',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8307.10.10',
        Descricao:
          'Do tipo utilizado na exploração submarina de petróleo ou gás, constituídos por camadas flexíveis de aço e camadas de plástico, de diâmetro interior superior a 254 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8307.10.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8307.90.00',
        Descricao: '- De outros metais comuns',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '83.08',
        Descricao:
          'Fechos, armações com fecho, fivelas, fivelas-fecho, grampos, colchetes, ilhoses e artigos semelhantes, de metais comuns, para vestuário ou acessórios de vestuário, calçado, joalheria, relógios de pulso, livros, encerados, artigos de couro, artigos de seleiro, artigos de viagem, ou para outras confecções; rebites tubulares ou de haste fendida, de metais comuns; contas e lantejoulas, de metais comuns.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8308.10.00',
        Descricao: '- Grampos, colchetes e ilhoses',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8308.20.00',
        Descricao: '- Rebites tubulares ou de haste fendida',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8308.90',
        Descricao: '- Outros, incluindo as partes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8308.90.10',
        Descricao: 'Fivelas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8308.90.20',
        Descricao: 'Contas e lantejoulas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8308.90.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '83.09',
        Descricao:
          'Rolhas, tampas e cápsulas para garrafas (incluindo as cápsulas de coroa, as rolhas e cápsulas, de rosca, e as rolhas vertedoras), batoques ou tampões roscados, protetores de batoques ou de tampões, selos de garantia e outros acessórios para embalagem, de metais comuns.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8309.10.00',
        Descricao: '- Cápsulas de coroa',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8309.90.00',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8310.00.00',
        Descricao:
          'Placas indicadoras, placas sinalizadoras, placas-endereços e placas semelhantes, números, letras e sinais diversos, de metais comuns, exceto os da posição 94.05.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '83.11',
        Descricao:
          'Fios, varetas, tubos, chapas, eletrodos e artigos semelhantes, de metais comuns ou de carbonetos metálicos, revestidos interior ou exteriormente de decapantes ou de fundentes, para soldadura ou depósito de metal ou de carbonetos metálicos; fios e varetas, de pós de metais comuns aglomerados, para metalização por projeção.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8311.10.00',
        Descricao:
          '- Eletrodos revestidos exteriormente para soldar a arco, de metais comuns',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8311.20.00',
        Descricao:
          '- Fios revestidos interiormente para soldar a arco, de metais comuns',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8311.30.00',
        Descricao:
          '- Varetas revestidas exteriormente e fios revestidos interiormente, para soldar à chama, de metais comuns',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8311.90.00',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '84',
        Descricao:
          'Reatores nucleares, caldeiras, máquinas, aparelhos e instrumentos mecânicos, e suas partes.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '84.01',
        Descricao:
          'Reatores nucleares; elementos combustíveis (cartuchos) não irradiados, para reatores nucleares; máquinas e aparelhos para a separação de isótopos.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8401.10.00',
        Descricao: '- Reatores nucleares',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8401.20.00',
        Descricao:
          '- Máquinas e aparelhos para a separação de isótopos, e suas partes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8401.30.00',
        Descricao: '- Elementos combustíveis (cartuchos) não irradiados',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8401.40.00',
        Descricao: '- Partes de reatores nucleares',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '84.02',
        Descricao:
          'Caldeiras de vapor (geradores de vapor), excluindo as caldeiras para aquecimento central concebidas para produção de água quente e vapor de baixa pressão; caldeiras denominadas "de água superaquecida".',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8402.1',
        Descricao: '- Caldeiras de vapor:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8402.11.00',
        Descricao:
          '-- Caldeiras aquatubulares com produção de vapor superior a 45 t por hora',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8402.12.00',
        Descricao:
          '-- Caldeiras aquatubulares com produção de vapor não superior a 45 t por hora',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8402.19.00',
        Descricao:
          '-- Outras caldeiras para produção de vapor, incluindo as caldeiras mistas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8402.20.00',
        Descricao: '- Caldeiras denominadas "de água superaquecida"',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8402.90.00',
        Descricao: '- Partes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '84.03',
        Descricao:
          'Caldeiras para aquecimento central, exceto as da posição 84.02.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8403.10',
        Descricao: '- Caldeiras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8403.10.10',
        Descricao: 'Com capacidade inferior ou igual a 200.000 kcal/hora',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8403.10.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8403.90.00',
        Descricao: '- Partes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '84.04',
        Descricao:
          'Aparelhos auxiliares para caldeiras das posições 84.02 ou 84.03 (por exemplo, economizadores, superaquecedores, aparelhos de limpeza de tubos ou de recuperação de gás); condensadores para máquinas a vapor.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8404.10',
        Descricao:
          '- Aparelhos auxiliares para caldeiras das posições 84.02 ou 84.03',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8404.10.10',
        Descricao: 'Da posição 84.02',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8404.10.20',
        Descricao: 'Da posição 84.03',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8404.20.00',
        Descricao: '- Condensadores para máquinas a vapor',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8404.90',
        Descricao: '- Partes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8404.90.10',
        Descricao: 'De aparelhos auxiliares para caldeiras da posição 84.02',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8404.90.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '84.05',
        Descricao:
          'Geradores de gás de ar (gás pobre) ou de gás de água, mesmo com depuradores; geradores de acetileno e geradores semelhantes de gás, operados a água, mesmo com depuradores.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8405.10.00',
        Descricao:
          '- Geradores de gás de ar (gás pobre) ou de gás de água, mesmo com depuradores; geradores de acetileno e geradores semelhantes de gás, operados a água, mesmo com depuradores',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8405.90.00',
        Descricao: '- Partes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '84.06',
        Descricao: 'Turbinas a vapor.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8406.10.00',
        Descricao: '- Turbinas para propulsão de embarcações',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8406.8',
        Descricao: '- Outras turbinas:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8406.81.00',
        Descricao: '-- De potência superior a 40 MW',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8406.82.00',
        Descricao: '-- De potência não superior a 40 MW',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8406.90',
        Descricao: '- Partes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8406.90.1',
        Descricao: 'Rotores',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8406.90.11',
        Descricao: 'De turbinas a reação, de múltiplos estágios',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8406.90.19',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8406.90.2',
        Descricao: 'Palhetas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8406.90.21',
        Descricao: 'Fixas (de estator)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8406.90.29',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8406.90.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '84.07',
        Descricao:
          'Motores de pistão, alternativo ou rotativo, de ignição por centelha (faísca) (motores de explosão).',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8407.10.00',
        Descricao: '- Motores para aviação',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8407.2',
        Descricao: '- Motores para propulsão de embarcações:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8407.21',
        Descricao: '-- Do tipo fora de borda',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8407.21.10',
        Descricao: 'Monocilíndricos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8407.21.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8407.29',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8407.29.10',
        Descricao: 'Monocilíndricos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8407.29.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8407.3',
        Descricao:
          '- Motores de pistão alternativo do tipo utilizado para propulsão de veículos do Capítulo 87:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8407.31',
        Descricao:
          '-- De cilindrada não superior a 50 cm\u003csup\u003e3\u003c/sup\u003e',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8407.31.10',
        Descricao: 'Monocilíndricos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8407.31.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8407.32.00',
        Descricao:
          '-- De cilindrada superior a 50 cm\u003csup\u003e3\u003c/sup\u003e, mas não superior a 250 cm\u003csup\u003e3\u003c/sup\u003e',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8407.33',
        Descricao:
          '-- De cilindrada superior a 250 cm\u003csup\u003e3\u003c/sup\u003e, mas não superior a 1.000 cm\u003csup\u003e3\u003c/sup\u003e',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8407.33.10',
        Descricao: 'Monocilíndricos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8407.33.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8407.34',
        Descricao:
          '-- De cilindrada superior a 1.000 cm\u003csup\u003e3\u003c/sup\u003e',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8407.34.10',
        Descricao: 'Monocilíndricos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8407.34.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8407.90.00',
        Descricao: '- Outros motores',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '84.08',
        Descricao:
          'Motores de pistão, de ignição por compressão (motores diesel ou semidiesel).',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8408.10',
        Descricao: '- Motores para propulsão de embarcações',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8408.10.10',
        Descricao: 'Do tipo fora de borda',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8408.10.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8408.20',
        Descricao:
          '- Motores do tipo utilizado para propulsão de veículos do Capítulo 87',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8408.20.10',
        Descricao:
          'De cilindrada inferior ou igual a 1.500 cm\u003csup\u003e3\u003c/sup\u003e',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8408.20.20',
        Descricao:
          'De cilindrada superior a 1.500 cm\u003csup\u003e3\u003c/sup\u003e, mas não superior a 2.500 cm\u003csup\u003e3\u003c/sup\u003e',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8408.20.30',
        Descricao:
          'De cilindrada superior a 2.500 cm\u003csup\u003e3\u003c/sup\u003e, mas não superior a 3.500 cm\u003csup\u003e3\u003c/sup\u003e',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8408.20.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8408.90',
        Descricao: '- Outros motores',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8408.90.10',
        Descricao:
          'Estacionários, de potência normal ISO superior a 497,5 kW (663 HP), segundo Norma ISO 3046/1',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8408.90.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '84.09',
        Descricao:
          'Partes reconhecíveis como exclusiva ou principalmente destinadas aos motores das posições 84.07 ou 84.08.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.10.00',
        Descricao: '- De motores para aviação',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.9',
        Descricao: '- Outras:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.91',
        Descricao:
          '-- Reconhecíveis como exclusiva ou principalmente destinadas aos motores de pistão, de ignição por centelha (faísca)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.91.1',
        Descricao:
          'Bielas, blocos de cilindros, cabeçotes, cárteres, carburadores, válvulas de admissão ou de escape, coletores de admissão ou de escape, anéis de segmento e guias de válvulas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.91.11',
        Descricao: 'Bielas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.91.12',
        Descricao: 'Blocos de cilindros, cabeçotes e cárteres',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.91.13',
        Descricao:
          'Carburadores, com bomba e dispositivo de compensação de nível de combustível incorporados, ambos a membrana, de diâmetro de venturi inferior ou igual a 22,8 mm e peso inferior ou igual a 280 g',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.91.14',
        Descricao: 'Válvulas de admissão ou de escape',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.91.15',
        Descricao: 'Coletores de admissão ou de escape',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.91.16',
        Descricao: 'Anéis de segmento',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.91.17',
        Descricao: 'Guias de válvulas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.91.18',
        Descricao: 'Outros carburadores',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.91.20',
        Descricao: 'Pistões ou êmbolos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.91.30',
        Descricao: 'Camisas de cilindro',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.91.40',
        Descricao: 'Sistema de injeção eletrônica',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.91.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.99',
        Descricao: '-- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.99.1',
        Descricao:
          'Blocos de cilindros, cárteres, válvulas de admissão ou de escape, coletores de admissão ou escape e guias de válvulas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.99.12',
        Descricao: 'Blocos de cilindros e cárteres',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.99.14',
        Descricao: 'Válvulas de admissão ou de escape',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.99.15',
        Descricao: 'Coletores de admissão ou de escape',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.99.17',
        Descricao: 'Guias de válvulas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.99.2',
        Descricao: 'Pistões ou êmbolos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.99.21',
        Descricao: 'De diâmetro igual ou superior a 200 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.99.29',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.99.30',
        Descricao: 'Camisas de cilindro',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.99.4',
        Descricao: 'Bielas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.99.41',
        Descricao: 'De peso igual ou superior a 30 kg',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.99.49',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.99.5',
        Descricao: 'Cabeçotes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.99.51',
        Descricao: 'De diâmetro igual ou superior a 200 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.99.59',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.99.6',
        Descricao: 'Bicos injetores (incluindo os porta-injetores)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.99.61',
        Descricao: 'De diâmetro igual ou superior a 20 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.99.69',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.99.7',
        Descricao: 'Anéis de segmento',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.99.71',
        Descricao: 'De diâmetro igual ou superior a 200 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.99.79',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.99.9',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.99.91',
        Descricao:
          'Camisas de cilindro soldadas a cabeçotes, de diâmetro igual ou superior a 200 mm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8409.99.99',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '84.10',
        Descricao:
          'Turbinas hidráulicas, rodas hidráulicas, e seus reguladores.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8410.1',
        Descricao: '- Turbinas e rodas hidráulicas:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8410.11.00',
        Descricao: '-- De potência não superior a 1.000 kW',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8410.12.00',
        Descricao:
          '-- De potência superior a 1.000 kW, mas não superior a 10.000 kW',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8410.13.00',
        Descricao: '-- De potência superior a 10.000 kW',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8410.90.00',
        Descricao: '- Partes, incluindo os reguladores',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '84.11',
        Descricao: 'Turborreatores, turbopropulsores e outras turbinas a gás.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8411.1',
        Descricao: '- Turborreatores:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8411.11.00',
        Descricao: '-- De empuxo (impulso*) não superior a 25 kN',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8411.12.00',
        Descricao: '-- De empuxo (impulso*) superior a 25 kN',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8411.2',
        Descricao: '- Turbopropulsores:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8411.21.00',
        Descricao: '-- De potência não superior a 1.100 kW',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8411.22.00',
        Descricao: '-- De potência superior a 1.100 kW',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8411.8',
        Descricao: '- Outras turbinas a gás:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8411.81.00',
        Descricao: '-- De potência não superior a 5.000 kW',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8411.82.00',
        Descricao: '-- De potência superior a 5.000 kW',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8411.9',
        Descricao: '- Partes:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8411.91.00',
        Descricao: '-- De turborreatores ou de turbopropulsores',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8411.99.00',
        Descricao: '-- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '84.12',
        Descricao: 'Outros motores e máquinas motrizes.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8412.10.00',
        Descricao: '- Propulsores a reação, excluindo os turborreatores',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8412.2',
        Descricao: '- Motores hidráulicos:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8412.21',
        Descricao: '-- De movimento retilíneo (cilindros)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8412.21.10',
        Descricao: 'Cilindros hidráulicos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8412.21.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8412.29.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8412.3',
        Descricao: '- Motores pneumáticos:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8412.31',
        Descricao: '-- De movimento retilíneo (cilindros)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8412.31.10',
        Descricao: 'Cilindros pneumáticos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8412.31.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8412.39.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8412.80.00',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8412.90',
        Descricao: '- Partes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8412.90.10',
        Descricao: 'De propulsores a reação',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8412.90.20',
        Descricao: 'De máquinas a vapor de movimento retilíneo (cilindros)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8412.90.80',
        Descricao: 'Outras, de máquinas das subposições 8412.21 ou 8412.31',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8412.90.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '84.13',
        Descricao:
          'Bombas para líquidos, mesmo com dispositivo medidor; elevadores de líquidos.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8413.1',
        Descricao:
          '- Bombas com dispositivo medidor ou concebidas para comportá-lo:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8413.11.00',
        Descricao:
          '-- Bombas para distribuição de combustíveis ou lubrificantes, do tipo utilizado em postos (estações) de serviço ou garagens',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8413.19.00',
        Descricao: '-- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8413.20.00',
        Descricao:
          '- Bombas manuais, exceto das subposições 8413.11 ou 8413.19',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8413.30',
        Descricao:
          '- Bombas para combustíveis, lubrificantes ou líquidos de arrefecimento, próprias para motores de ignição por centelha (faísca) ou por compressão',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8413.30.10',
        Descricao: 'Para gasolina ou álcool',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8413.30.20',
        Descricao:
          'Injetoras de combustível para motor de ignição por compressão',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8413.30.30',
        Descricao: 'Para óleo lubrificante',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8413.30.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8413.40.00',
        Descricao: '- Bombas para concreto (betão)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8413.50',
        Descricao: '- Outras bombas volumétricas alternativas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8413.50.10',
        Descricao:
          'De potência superior a 3,73 kW (5 HP), mas não superior a 447,42 kW (600 HP), excluídas as para oxigênio líquido',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8413.50.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8413.60',
        Descricao: '- Outras bombas volumétricas rotativas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8413.60.1',
        Descricao: 'De vazão inferior ou igual a 300 l/min',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8413.60.11',
        Descricao: 'De engrenagem',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8413.60.19',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8413.60.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8413.70',
        Descricao: '- Outras bombas centrífugas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8413.70.10',
        Descricao: 'Eletrobombas submersíveis',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8413.70.80',
        Descricao: 'Outras, de vazão inferior ou igual a 300 l/min',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8413.70.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8413.8',
        Descricao: '- Outras bombas; elevadores de líquidos:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8413.81.00',
        Descricao: '-- Bombas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8413.82.00',
        Descricao: '-- Elevadores de líquidos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8413.9',
        Descricao: '- Partes:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8413.91',
        Descricao: '-- De bombas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8413.91.10',
        Descricao:
          'Hastes de bombeamento, do tipo utilizado para extração de petróleo',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8413.91.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8413.92.00',
        Descricao: '-- De elevadores de líquidos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '84.14',
        Descricao:
          'Bombas de ar ou de vácuo, compressores de ar ou de outros gases e ventiladores; coifas aspirantes (exaustores) para extração ou reciclagem, com ventilador incorporado, mesmo filtrantes; cabinas (câmaras) de segurança biológica estanques aos gases, mesmo filtrantes.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.10.00',
        Descricao: '- Bombas de vácuo',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.20.00',
        Descricao: '- Bombas de ar, de mão ou de pé',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.30',
        Descricao:
          '- Compressores do tipo utilizado nos equipamentos frigoríficos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.30.1',
        Descricao: 'Motocompressores herméticos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.30.11',
        Descricao: 'Com capacidade inferior a 4.700 frigorias/hora',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.30.19',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.30.9',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.30.91',
        Descricao: 'Com capacidade inferior ou igual a 16.000 frigorias/hora',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.30.99',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.40',
        Descricao:
          '- Compressores de ar montados sobre chassis com rodas e rebocáveis',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.40.10',
        Descricao: 'De deslocamento alternativo',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.40.20',
        Descricao: 'De parafuso',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.40.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.5',
        Descricao: '- Ventiladores:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.51',
        Descricao:
          '-- Ventiladores de mesa, de assentar no solo, de parede, de teto ou de janela, com motor elétrico incorporado de potência não superior a 125 W',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.51.10',
        Descricao: 'De mesa',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.51.20',
        Descricao: 'De teto',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.51.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.59',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.59.10',
        Descricao:
          'Microventiladores com área de carcaça inferior a 90 cm\u003csup\u003e2\u003c/sup\u003e',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.59.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.60.00',
        Descricao:
          '- Coifas aspirantes (exaustores) com dimensão horizontal máxima não superior a 120 cm',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.70.00',
        Descricao:
          '- Cabinas (câmaras) de segurança biológica estanques aos gases',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.80',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.80.1',
        Descricao: 'Compressores de ar',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.80.11',
        Descricao: 'Estacionários, de pistão',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.80.12',
        Descricao: 'De parafuso',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.80.13',
        Descricao:
          'De lóbulos paralelos (tipo \u003ci\u003eRoots\u003c/i\u003e)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.80.19',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.80.2',
        Descricao: 'Turbocompressores de ar',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.80.21',
        Descricao:
          'Turboalimentadores de ar, de peso inferior ou igual a 50 kg para motores das posições 84.07 ou 84.08, acionado pelos gases de escapamento dos mesmos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.80.22',
        Descricao:
          'Turboalimentadores de ar, de peso superior a 50 kg para motores das posições 84.07 ou 84.08, acionados pelos gases de escapamento dos mesmos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.80.29',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.80.3',
        Descricao: 'Compressores de gases (exceto ar)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.80.31',
        Descricao: 'De pistão',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.80.32',
        Descricao: 'De parafuso',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.80.33',
        Descricao:
          'Centrífugos, de vazão máxima inferior a 22.000 m\u003csup\u003e3\u003c/sup\u003e/h',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.80.38',
        Descricao: 'Outros compressores centrífugos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.80.39',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.80.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.90',
        Descricao: '- Partes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.90.10',
        Descricao: 'De bombas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.90.20',
        Descricao: 'De ventiladores ou coifas aspirantes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.90.3',
        Descricao: 'De compressores',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.90.31',
        Descricao: 'Pistões ou êmbolos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.90.32',
        Descricao: 'Anéis de segmento',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.90.33',
        Descricao: 'Blocos de cilindros, cabeçotes e cárteres',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.90.34',
        Descricao: 'Válvulas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.90.39',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8414.90.40',
        Descricao: 'De cabinas (câmaras) de segurança',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '84.15',
        Descricao:
          'Máquinas e aparelhos de ar-condicionado que contenham um ventilador motorizado e dispositivos próprios para modificar a temperatura e a umidade, incluindo as máquinas e aparelhos em que a umidade não seja regulável separadamente.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8415.10',
        Descricao:
          '- Do tipo concebido para ser fixado numa janela, parede, teto ou piso (pavimento), formando um corpo único ou do tipo \u003ci\u003esplit-system\u003c/i\u003e (sistema com elementos separados)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8415.10.1',
        Descricao: 'Com capacidade inferior ou igual a 30.000 frigorias/hora',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8415.10.11',
        Descricao:
          'Do tipo \u003ci\u003esplit-system\u003c/i\u003e (sistema com elementos separados)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8415.10.19',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8415.10.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8415.20',
        Descricao:
          '- Do tipo utilizado para o conforto dos passageiros nos veículos automóveis',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8415.20.10',
        Descricao: 'Com capacidade inferior ou igual a 30.000 frigorias/hora',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8415.20.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8415.8',
        Descricao: '- Outros:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8415.81',
        Descricao:
          '-- Com dispositivo de refrigeração e válvula de inversão do ciclo térmico (bombas de calor reversíveis)',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8415.81.10',
        Descricao: 'Com capacidade inferior ou igual a 30.000 frigorias/hora',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8415.81.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8415.82',
        Descricao: '-- Outros, com dispositivo de refrigeração',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8415.82.10',
        Descricao: 'Com capacidade inferior ou igual a 30.000 frigorias/hora',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8415.82.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8415.83.00',
        Descricao: '-- Sem dispositivo de refrigeração',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8415.90',
        Descricao: '- Partes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8415.90.10',
        Descricao:
          'Unidades evaporadoras (internas) de aparelho de ar-condicionado do tipo \u003ci\u003esplit-system\u003c/i\u003e (sistema com elementos separados), com capacidade inferior ou igual a 30.000 frigorias/hora',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8415.90.20',
        Descricao:
          'Unidades condensadoras (externas) de aparelho de ar-condicionado do tipo \u003ci\u003esplit-system\u003c/i\u003e (sistema com elementos separados), com capacidade inferior ou igual a 30.000 frigorias/hora',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8415.90.90',
        Descricao: 'Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '84.16',
        Descricao:
          'Queimadores para alimentação de fornalhas de combustíveis líquidos, combustíveis sólidos pulverizados ou de gás; fornalhas automáticas, incluindo as antefornalhas, grelhas mecânicas, descarregadores mecânicos de cinzas e dispositivos semelhantes.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8416.10.00',
        Descricao: '- Queimadores de combustíveis líquidos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8416.20',
        Descricao: '- Outros queimadores, incluindo os mistos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8416.20.10',
        Descricao: 'De gases',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8416.20.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8416.30.00',
        Descricao:
          '- Fornalhas automáticas, incluindo as antefornalhas, grelhas mecânicas, descarregadores mecânicos de cinzas e dispositivos semelhantes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8416.90.00',
        Descricao: '- Partes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '84.17',
        Descricao:
          'Fornos industriais ou de laboratório, incluindo os incineradores, não elétricos.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8417.10',
        Descricao:
          '- Fornos para ustulação, fusão ou outros tratamentos térmicos de minérios ou de metais',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8417.10.10',
        Descricao: 'Fornos industriais para fusão de metais',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8417.10.20',
        Descricao: 'Fornos industriais para tratamento térmico de metais',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8417.10.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8417.20.00',
        Descricao:
          '- Fornos de padaria, pastelaria ou para a indústria de bolachas e biscoitos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8417.80',
        Descricao: '- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8417.80.10',
        Descricao: 'Fornos industriais para cerâmica',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8417.80.20',
        Descricao: 'Fornos industriais para fusão de vidro',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8417.80.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8417.90.00',
        Descricao: '- Partes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '84.18',
        Descricao:
          'Refrigeradores, congeladores  e outros materiais, máquinas e aparelhos, para a produção de frio, com equipamento elétrico ou outro; bombas de calor, exceto as máquinas e aparelhos de ar-condicionado da posição 84.15.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8418.10.00',
        Descricao:
          '- Combinações de refrigeradores e congeladores , munidos de portas ou gavetas exteriores separadas, ou de uma combinação desses elementos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8418.2',
        Descricao: '- Refrigeradores do tipo doméstico:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8418.21.00',
        Descricao: '-- De compressão',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8418.29.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8418.30.00',
        Descricao:
          '- Congeladores  horizontais (arcas), de capacidade não superior a 800 l',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8418.40.00',
        Descricao:
          '- Congeladores  verticais, de capacidade não superior a 900 l',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8418.50',
        Descricao:
          '- Outros móveis (arcas, armários, vitrines, balcões e móveis semelhantes) para a conservação e exposição de produtos, que incorporem um equipamento para a produção de frio',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8418.50.10',
        Descricao: 'Congeladores ',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8418.50.90',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8418.6',
        Descricao:
          '- Outros materiais, máquinas e aparelhos, para a produção de frio; bombas de calor:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8418.61.00',
        Descricao:
          '-- Bombas de calor, exceto as máquinas e aparelhos de ar-condicionado da posição 84.15',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8418.69',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8418.69.10',
        Descricao: 'Máquinas não domésticas para preparação de sorvetes',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8418.69.20',
        Descricao: 'Resfriadores de leite',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8418.69.3',
        Descricao:
          'Unidades fornecedoras de água, sucos ou bebidas carbonatadas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8418.69.31',
        Descricao: 'De água ou sucos',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8418.69.32',
        Descricao: 'De bebidas carbonatadas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8418.69.40',
        Descricao:
          'Grupos frigoríficos de compressão com capacidade inferior ou igual a 30.000 frigorias/hora',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8418.69.9',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8418.69.91',
        Descricao: 'Resfriadores de água, de absorção por brometo de lítio',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8418.69.99',
        Descricao: 'Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8418.9',
        Descricao: '- Partes:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8418.91.00',
        Descricao:
          '-- Móveis concebidos para receber um equipamento para a produção de frio',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8418.99.00',
        Descricao: '-- Outras',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '84.19',
        Descricao:
          'Aparelhos, dispositivos ou equipamentos de laboratório, mesmo aquecidos eletricamente (exceto os fornos e outros aparelhos da posição 85.14), para tratamento de matérias por meio de operações que impliquem mudança de temperatura, tais como aquecimento, cozimento, torrefação, destilação, retificação, esterilização, pasteurização, estufagem, secagem, evaporação, vaporização, condensação ou arrefecimento, exceto os de uso doméstico; aquecedores de água não elétricos, de aquecimento instantâneo ou de acumulação.',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8419.1',
        Descricao:
          '- Aquecedores de água não elétricos, de aquecimento instantâneo ou de acumulação:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8419.11.00',
        Descricao: '-- De aquecimento instantâneo, a gás',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8419.12.00',
        Descricao: '-- Aquecedores de água solares',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8419.19.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8419.20.00',
        Descricao: '- Esterilizadores médico-cirúrgicos ou de laboratório',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8419.3',
        Descricao: '- Secadores:',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8419.33.00',
        Descricao:
          '-- Aparelhos de liofilização, aparelhos de criodessecação e secadores por pulverização',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8419.34.00',
        Descricao: '-- Outros, para produtos agrícolas',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8419.35.00',
        Descricao: '-- Outros, para madeiras, pastas de papel, papel ou cartão',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      },
      {
        Codigo: '8419.39.00',
        Descricao: '-- Outros',
        Data_Inicio: '01/04/2022',
        Data_Fim: '31/12/9999',
        Tipo_Ato: 'Res Camex',
        Numero_Ato: '000272',
        Ano_Ato: '2021',
      }

    ],
  };
}
