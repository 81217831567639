import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProcessControl } from '../models/processcontrol/processControl';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProcessControlService {

  env:string = environment.apiHost + '/api-core/secure/process-control';
  constructor(private http: HttpClient) { }

  getAll(): Observable<ProcessControl[]> {
      return this.http.get<ProcessControl[]>(
          this.env + '/get-all');
  }


}
