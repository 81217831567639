<div class="dialog-form p-1">
  <p-fieldset class="p-1">
    <p-header class="article-title">
      <div class="article-title">{{'SERVICES' | translate}}</div>
    </p-header>
    <div class="flex" style="margin-bottom: 15px;">
      <div class="flex flex-column mr-5">
        <label for="service" class="mb-1">{{ 'ADD-SERVICE' | translate }}</label>
        <p-dropdown placeholder="Selecione..." [filter]="true" [options]="services" appendTo="body"
                    [(ngModel)]="newService" name="service">
        </p-dropdown>
      </div>
      <div class="flex flex-column mr-5">
        <label for="service" class="mb-1">{{ 'Data de inicio do serviço' | translate }}</label>
        <p-calendar dateFormat="dd/mm/yy" name="contractStartDate"
                    rPCalendarMask appendTo="body"
                    [(ngModel)]="contractStartDate" [showIcon]="true" i18n
        >
        </p-calendar>
      </div>
      <td>
        <div class="flex flex-column mr-5">
          <label for="service" class="mb-1">{{ 'Beneficiário' | translate }}</label>
          <p-dropdown [options]="beneficiaries" appendTo="body" [filter]="true"
                      [(ngModel)]="beneficiary" name="beneficiary">
          </p-dropdown>
        </div>
      </td>
      <div class="flex flex-column mr-5 mt-2" style="justify-content: flex-start!important;">
        <button class="fab-button primary ml-3" matTooltip="Add service" type="button"
                (click)="validateCustomerService()" name="boatServiceButton">
          <i class="pi pi-plus"></i>
        </button>
      </div>
    </div>
    <div *ngIf="serviceCustomerList.length > 0; else noCustomerServices">

      <p-table [value]="serviceCustomerList">
        <ng-template pTemplate="header">
          <tr>
            <th>{{ 'SERVICE' | translate }}</th>
            <th style="min-width: 115px;">Inicio serviço</th>
            <th style="min-width: 115px;">Fim serviço</th>
            <th>{{ 'BENEFICIARY' | translate }}</th>
            <th>{{ 'OBSERVATION' | translate }}</th>
            <th>{{ 'VALUE' | translate }}</th>
            <th>{{ 'DISCOUNT-PERC' | translate }}</th>
            <th>{{ 'DISCOUNT' | translate }}</th>
            <th>{{ 'DUE-DATE-DISCOUNT-PERC' | translate }}</th>
            <th>{{ 'DUE-DATE-DISCOUNT' | translate }}</th>
            <th>{{ 'TOTAL' | translate }}</th>
            <th>{{ 'TOTAL-DUE-DATE' | translate }}</th>
            <th colspan="2">
              <div class="text-center flex flex-column">

              <label for="discount">{{'Utilizar desconto em Porcentagem?'}}</label>
              <p-inputSwitch [(ngModel)]="isDiscountPercentage" (onChange)="updateServicesDiscount()"
                             pTooltip="Utilizar descontos em porcentagens?"
                             tooltipPosition="bottom" name="discount">
              </p-inputSwitch>
              </div>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-customerService let-rowIndex="rowIndex">
          <tr>
            <td>{{ customerService.service.name }} </td>
            <td>
              <p-calendar dateFormat="dd/mm/yy" name="contractStartDate" rPCalendarMask
                          [yearRange]="'2015:2030'" i18n appendTo="body"
                          [(ngModel)]="customerService.contractStartDate">
              </p-calendar>
            </td>
            <td>
              <p-calendar dateFormat="dd/mm/yy" name="contractEndDate"
                          [(ngModel)]="customerService.contractEndDate" rPCalendarMask
                          [yearRange]="'2019:2030'" i18n appendTo="body">
              </p-calendar>
            </td>
            <td>
              <input pInputText name="beneficiary" [(ngModel)]="customerService.beneficiaryName" readonly/>
            </td>
            <td>
              <input pInputText name="observation" [(ngModel)]="customerService.observation"/>
            </td>
            <td class="text-right"
                *ngIf="!isEditValueByService(customerService)">{{ customerService?.value | number:'1.2-2' }}</td>
            <td class="text-right" *ngIf="isEditValueByService(customerService)">
              <p-inputNumber name="value" [(ngModel)]="customerService.value" mode="decimal"
                             [minFractionDigits]="2" [maxFractionDigits]="2"
                             (onInput)="calculateDueDateDiscountPercentage(customerService);calculateDiscountPercentage(customerService)">
              </p-inputNumber>
            </td>
            <td class="text-right">
              <p-inputNumber name="discountPercentage" [(ngModel)]="customerService.discountPercentage" mode="decimal"
                             class="medium" [disabled]="!customerService.useDiscountOnPercentage" suffix="%"
                             [minFractionDigits]="2" [maxFractionDigits]="2" [min]="0.00" [max]="100.00"
                             (onInput)="stopNullValue(customerService.discountPercentage)"
                             (onInput)="calculateDiscountPercentage(customerService)">
              </p-inputNumber>
            </td>
            <td class="text-right">
              <p-inputNumber name="discount" [(ngModel)]="customerService.discount" mode="decimal" class="medium"
                             [disabled]="customerService.useDiscountOnPercentage"
                             [minFractionDigits]="2" (onBlur)="stopNullValue(customerService.discount)"
                             [maxFractionDigits]="2">
              </p-inputNumber>
            </td>
            <td class="text-right">
              <p-inputNumber name="dueDateDiscountPercentage" [(ngModel)]="customerService.dueDateDiscountPercentage"
                             class="medium"
                             tooltipPosition="top" [disabled]="!customerService.useDiscountOnPercentage"
                             (onBlur)="stopNullValue(customerService.dueDateDiscountPercentage)"
                             mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" [min]="0.00" [max]="100.00"
                             suffix="%"
                             (onInput)="calculateDueDateDiscountPercentage(customerService)"
              >
              </p-inputNumber>
            </td>
            <td class="text-right">
              <p-inputNumber name="dueDateDiscount" [(ngModel)]="customerService.dueDateDiscount" class="medium"
                             pTooltip="Desconto no boleto" tooltipPosition="top"
                             [disabled]="customerService.useDiscountOnPercentage"
                             (onBlur)="stopNullValue(customerService.dueDateDiscount)"
                             mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2">
              </p-inputNumber>
            </td>
            <td class="text-right">{{ calcTotalService(customerService) | number:'1.2-2' }}</td>
            <td class="text-right">{{ calcTotalServiceDueDate(customerService) | number:'1.2-2' }}</td>
            <td>
              <a *ngIf="customerService?.filePath" target="_blank"
                 pTooltip="Baixar contrato assinado" tooltipPosition="top"
                 [href]="customerService?.filePath" style="color: black;">
                <i class="pi pi-file-pdf"></i>
              </a>
            </td>
            <td class="text-center">
              <div class="table-action cursor-pointer" pTooltip="Deletar" tooltipPosition="top"
                   (click)="deleteCustomerService(customerService)">
                <i class="pi pi-trash"></i>
              </div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr *ngIf="serviceCustomerList.length > 1">
            <td></td>
            <td style="min-width: 115px;"></td>
            <td style="min-width: 115px;"></td>
            <td>Total</td>
            <td class="text-right padding-td">{{ totalServicesValue() | number:'1.2-2'}}</td>
            <td></td>
            <td class="text-right padding-td">{{ totalServicesDiscount() | number:'1.2-2'}}</td>
            <td></td>
            <td class="text-right padding-td">{{ totalServicesDueDateDiscount() | number:'1.2-2'}}</td>
            <td class="text-right padding-td">{{ totalServicesTotal() | number:'1.2-2'}}</td>
            <td class="text-right padding-td">{{ totalServicesTotalDueDate() | number:'1.2-2'}}</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </ng-template>
      </p-table>
      <div class="flex justify-content-end mt-4 buttons">
        <button type="button" (click)="closeaDialog()"
                class="easymarine danger mr-4">{{ 'CANCEL' | translate }}</button>
        <button pButton class="easymarine primary" type="button"
                (click)="updateServiceCustomers()">{{ 'SAVE' | translate }}</button>
      </div>
    </div>
    <ng-template #noCustomerServices>
      <h2 class="flex justify-content-center my-8">Esse cliente não possui serviços ativos</h2>
    </ng-template>
    <br>
  </p-fieldset>
  <app-service-customer-history class="p-1" [serviceCustomers]="serviceCustomerListHistory"></app-service-customer-history>
  <app-service-customer-orders-history class="p-1" [customerId]="customer.id"></app-service-customer-orders-history>
</div>
