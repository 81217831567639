import { Marina } from './marina';

export class Advertisement {
    id: number;
    marina: Marina;
    imageType: number;
    image1: string;
    image2: string;
    image3: string;
    image4: string;
    refreshTime: number;

    constructor(imageType?: number, image1?: string, image2?: string, image3?: string, image4?: string, refreshTime?: number) {
        this.imageType = imageType;
        this.image1 = image1;
        this.image2 = image2;
        this.image3 = image3;
        this.image4 = image4;
        this.refreshTime = refreshTime;
        this.marina = new Marina();
    }
}
