import { Component, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { CustomerService } from 'src/app/services/customer.service';
import { FastCustomerEditFormComponent } from '../fast-customer-edit-form/fast-customer-edit-form.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-fast-customer-edit',
  templateUrl: './fast-customer-edit.component.html',
  styleUrls: ['./fast-customer-edit.component.scss']
})
export class FastCustomerEditComponent implements OnInit {

  search:string = '';
  customerList :any[] = [];

  constructor(
    private customerService: CustomerService,
    private spinner: NgxSpinnerService,
    public dialog: DialogService
  ) { }

  ngOnInit() {
  }

  find() {
    if (this.search) {
      this.spinner.show();
      this.customerService.getCustomerToFastEdit(this.search).subscribe(
        (response) => {
          this.customerList = response;
          this.spinner.hide();
        },
        (error) => {
          console.log(error);
          this.spinner.hide();
        }
      );
    }
  }

  clear() {
    this.search = '';
  }

  getType(customer: any):String {
    return customer.mainDependentCustomerId === null ? 'Proprietário' : 'Dependente';
  }

  openForm(customer: any) {

    this.dialog
        .open(FastCustomerEditFormComponent, {
          width: '1200px',
          height: '970px',
          header: 'Editar Cliente',
          data: customer,
        })
        .onClose.subscribe(() => {
          this.find();
        });
  }

}
