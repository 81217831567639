import { CustomerType } from '../enums';
import { LicenceCustomerState } from '../enums/licence-customer-state';

export class CustomerFilter {
    name: string;
    email: string;
    phone: string;
    federalId: string;
    groupIds: string;
    city: string;
    state: string;
    reviewed: boolean;
    active: boolean;
    onlyAssociated: boolean;
    type: CustomerType;
    licenceStates: LicenceCustomerState[];
    size: number;
    page: number;
    sort: string;
    order: string;
    userApp: boolean;
    onlyMarina: boolean;
    marinaCompanyId: number;
    customerProfile: string;

    constructor() {
        this.size = 10;
        this.page = 0;
        this.order = 'ASC';
        this.onlyMarina = false;
    }
}
