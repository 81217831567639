import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Role } from '../models';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class RoleService {

    apiURL = environment.apiHost + '/secure/roles';

    constructor(private http: HttpClient) { }

    getRolesByHierarchy(): Observable<Role[]> {
        return this.http.get(environment.apiHost + '/secure/roles/by-hierarchy')
            .pipe(map((response: any) => response.data.roles as Role[]));
    }

    getRolesEditablesByHierarchy(): Observable<Role[]> {
        return this.http.get(environment.apiHost + '/secure/roles/editables-by-hierarchy')
            .pipe(map((response: any) => response.data.roles as Role[]));
    }

    getById(id: number): Observable<Role> {
        return this.http.get(environment.apiHost + '/secure/roles/marina-role/' + id)
            .pipe(map((response: any) => response.data.role as Role));
    }

    save(role: Role): Observable<Role> {
        return this.http.post(environment.apiHost + '/secure/roles/role', role).pipe(map((res: any) => {
            return res.data.role;
        }));
    }

    delete(id: number): Observable<Role> {
        return this.http.delete(environment.apiHost + '/secure/roles/role/' + id).pipe(map((response: any) => response));
    }

    currentUserIsAdministrator(id: number): Observable<boolean>{
      return this.http
        .get(this.apiURL + "/role/administrator/" + id)
        .pipe(map((response: any) => response.data.administrator as boolean));
    }
    
    isCollaboratorOrAdmin(userIdP: number, marinaIdP: number): Observable<boolean> {
        const body = ({
                userId: userIdP.toString(),
                marinaId: marinaIdP.toString()
        });
        return this.http.post(environment.apiHost + '/secure/roles/role-access/', body)
            .pipe(map((response: any) => response.data.roleName as boolean));
    }
    
}
