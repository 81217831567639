export enum Application {
    CustomerApp = 'App do Cliente',
    GestorApp = 'App do Gestor',
    TotemApp = 'App Totem',
    PanelTV = 'Painel TV',
    RaspberryOS = 'Raspberry OS',
    EasyWeb = 'Easy Web',
    EasyAdmin = 'Easy Admin',
    MarinheiroApp = 'App do Marinheiro',
}
