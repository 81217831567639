import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ServiceComparationFilter } from "../models/dtos/service-comparation-filter";
import { ServiceComparationDTO } from "../models/dtos/service-comparationDTO";
import { ServiceComparisonConfiguration } from "../models/service-comparison-configuration";


@Injectable()
export class ServiceComparisonService {

    constructor(private http: HttpClient) { }

    getServiceComparisonConfigurations(): Observable<ServiceComparisonConfiguration[]> {
        return this.http.get(environment.apiHost + '/secure/service-comparison-configurations')
            .pipe(map((response: any) => response.data.serviceComparisonConfigurations as ServiceComparisonConfiguration[]));
    }

    saveConfigurations( configurations: any[]): Observable<any> {
        return this.http.post(environment.apiHost + '/secure/service-comparison-configurations', configurations)
            .pipe(map((response: any) => response.data));
    }

    getConfigurations( filter: ServiceComparationFilter ): Observable<ServiceComparationDTO[]> {
        return this.http.post(environment.apiHost + '/secure/service-comparisons', filter)
            .pipe(map((response: any) => response.data.serviceComparisons as ServiceComparationDTO[]));
    }
}