import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileUpload } from 'primeng/fileupload';
import { Accessory, AccessoryBoat, Boat } from 'src/app/models';
import { AccessoryType } from 'src/app/models/enums';
import { AccessoryService } from 'src/app/services/accessory.service';

@Component({
  selector: 'app-boat-accessories',
  templateUrl: './boat-accessories.component.html',
  styleUrls: ['./boat-accessories.component.scss']
})
export class BoatAccessoriesComponent implements OnInit {

  @ViewChild('upload', { static: false }) upload: FileUpload;

  boat: Boat = new Boat();
  accessory: Accessory = new Accessory();
  uploadAccessoryFileInProgress = false;

  constructor(
    public dialogRef: DynamicDialogRef,
    private spinner: NgxSpinnerService,
    private config: DynamicDialogConfig,
    private accessoryService: AccessoryService
  ) {
    this.boat = this.config.data.boat;
  }

  ngOnInit(): void {
  }

  addAccessory(): void {
    const accessoryBoat = new AccessoryBoat();
    this.accessory.accessoryType = AccessoryType.Accessory;
    accessoryBoat.accessory = { ...this.accessory, urlPhoto: this.accessory.urlPhoto };
    accessoryBoat.quantity = this.accessory.quantity;
    this.closeDialog(accessoryBoat);
  }

  closeDialog(data?): void {
    this.dialogRef.close(data);
  }

  uploadFile(files): void {
    this.spinner.show();
    this.uploadAccessoryFileInProgress = true;
    this.accessoryService.uploadFile(files).subscribe(
      (result) => {
        this.spinner.hide();
        this.uploadAccessoryFileInProgress = false;
        this.accessory.urlPhoto = result.url;
        this.upload.clear();
        console.log(result);
      },
      (err) => {
        this.spinner.hide();
        this.uploadAccessoryFileInProgress = false;
        console.log(err);
      }
    );
  }

}

