import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { OrderItem, OrderQuota, Sling, SlingConfig } from 'src/app/models';
import { CustomerType, ProductCategoryType } from 'src/app/models/enums';
import { OptionPrintQuestionTotem } from 'src/app/models/enums/option-print-question-totem';
import { PrintType } from 'src/app/models/enums/print-type';
import { NavigationPlanService } from 'src/app/services/navigation-plan.service';
import { SlingService } from 'src/app/services/sling.service';
import { PrintingService } from 'src/app/services/printing-service';
import { ToastService } from 'src/app/services/toast.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { SlingUtil } from 'src/app/utils/sling.util';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sling-quota',
  templateUrl: './sling-quota.component.html',
  styleUrls: ['./sling-quota.component.scss']
})
export class SlingQuotaComponent implements OnInit {

  orderQuotas: OrderQuota[] = [];
  @Input() slingConfig: SlingConfig;
  @Input() slingOrder: Sling;
  @Input() isASlingEdition: boolean;
  @Output() eventSling: EventEmitter<number> = new EventEmitter();
  @Output() eventClose = new EventEmitter();
  owners: any[] = [];
  quotas: any[] = [];
  sumQuota: number;
  invalidQuota: boolean;
  loading = false;
  disableFlag = false;

  constructor(
    private messageUtil: MessageUtil,
    private spinner: NgxSpinnerService,
    private slingService: SlingService,
    private toasty: ToastService,
    public slingUtil: SlingUtil,
    public printingService: PrintingService
  ) {
  }
  ngOnInit(): void {
    this.loadQuotas();
  }

  loadQuotas(): void {
    this.owners = [];
    this.quotas = [];
    this.defineQuotaExistSling();
  }

  private defineQuotaExistSling(): void {
    this.slingOrder.marinaOrder.orderQuotas.forEach((oq) => {
      const quota: OrderQuota = new OrderQuota();
      quota.customer = oq.customer;
      quota.quota = oq.quota;
      this.quotas.push(quota);
    });
    this.slingOrder.boat.boatCustomers.forEach((bc) => {
      if ((bc.associatedOwner && !this.quotas.find((oq) => oq.customer.id === bc.associatedOwner.id)) ||
        (!bc.associatedOwner && !this.quotas.find((oq) => oq.customer.id === bc.customer.id))) {
        const quota: OrderQuota = new OrderQuota();
        quota.customer = bc.associatedOwner ? bc.associatedOwner : bc.customer;
        quota.quota = 0;
        if (!this.owners.find((o) => o.customer.id === quota.customer.id)) {
          this.owners.push(quota);
        }
      }
    });
  }

  loadOwnersWithNoQuota(): void {
    this.owners = [];
    for (const boatCustomer of this.slingOrder.boat.boatCustomers) {
      if (this.quotas.find((q) => q.customer.id === boatCustomer.customer.id) && boatCustomer.type === CustomerType.Owner) {
        const orderQuota = new OrderQuota();
        orderQuota.customer = boatCustomer.customer;
        orderQuota.quota = boatCustomer.quota;
        this.owners.push(orderQuota);
      }
    }

    if (this.owners.length === 1) {
      this.quotas.push(this.owners[0]);
      this.owners = [];
      this.convertToQuota();
    } else {
      this.convertToCustomer();
    }
  }

  convertToQuota(): void {
    const indexQuota = this.quotas.findIndex((oq) => oq === undefined);
    if (indexQuota >= 0) {
      this.quotas.splice(indexQuota, 1);
    }
    for (const orderQuota of this.quotas) {
      orderQuota.quota = 100 / this.quotas.length;
    }
  }

  convertToCustomer(): void {
    const indexOwner = this.owners.findIndex((oq) => oq === undefined);
    if (indexOwner >= 0) {
      this.owners.splice(indexOwner, 1);
    }
    for (const owner of this.owners) {
      owner.marinaOrder = null;
    }
    this.convertToQuota();
  }

  calculateQuota(): void {
    this.sumQuota = 0;
    for (const orderQuota of this.quotas) {
      this.sumQuota += orderQuota.quota;
    }
    this.sumQuota === 100 ? this.invalidQuota = false : this.invalidQuota = true;
  }

  validateQuota(): boolean {
    this.calculateQuota();
    return !this.invalidQuota;
  }

  saveSling(): void {
    if (this.slingOrder.marinaOrder.orderItems.length > 0) {
      if (!this.validateQuota()) {
        this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', 'O total rateado deve totalizar 100%.');
        return;
      }

      if (this.quotas.length === 0) {
        this.invalidQuota = true;
        return;
      }
    }
    this.loading = true;
    this.disableFlag = true;
    this.spinner.show();

    this.slingOrder.marinaOrder.orderItems.forEach((oi) => {
      if (oi.qtyRequested !== undefined && oi.qtyRequested !== null) {
        oi.qtyRequested = (this.isFuel(oi) || oi.product?.measurement == 'Kilograms' || oi.product?.measurement == 'Liters')
          ? Number(oi.qtyRequested.toFixed(3)) : Number(oi.qtyRequested.toFixed(2));
      }
      oi.qtyStock = this.isFuel(oi) ? Number(oi.product.stock.toFixed(3)) : Number(oi.product.stock.toFixed(2));
    });
    this.slingOrder.marinaOrder.orderQuotas = this.quotas;
    this.slingOrder.marinaOrder.orderDate = moment().format('YYYY-MM-DDTHH:mm');
    this.slingOrder.marinaOrder.customer = this.slingOrder.navigationPlan.responsible;
    this.slingService.save(this.slingOrder).subscribe({
      next: (data) => {
        this.toasty.success(this.messageUtil.translateKey('MSG.INSERT-SUCCESS'));
        this.loading = false;
        this.disableFlag = false;
        if (this.slingConfig.printNavigationPlanWeb == OptionPrintQuestionTotem.Question) {
          Swal.fire({
            title: 'Atenção',
            text: 'Deseja imprimir o plano de navegação?',
            icon: 'warning',
            reverseButtons: true,
            showDenyButton: true,
            customClass: {
              confirmButton: ['new-easymarine', 'primary'],
              denyButton: ['new-easymarine', 'disabled']
            },
            confirmButtonText: 'SIM',
            denyButtonText: 'NÃO',
          }).then(async (result) => {
            if (result.isConfirmed) {
              await this.printNavitagionPlan(data.id);
              this.close('reload');
            }
            if (result.isDenied || result.isDismissed) {
              this.close('reload');
            }
          });
        }
        if (this.slingConfig.printNavigationPlanWeb == OptionPrintQuestionTotem.AutoPrint) {
          this.printNavitagionPlan(data.id);
          this.close('reload');
        }
        if (this.slingConfig.printNavigationPlanWeb == OptionPrintQuestionTotem.NoAction) {
          this.close('reload');
        }
      },
      error: (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
        this.loading = false;
        this.disableFlag = false;
      },
      complete: () => this.spinner.hide()
    });
  }

  isFuel(orderItem: OrderItem): boolean {
    return orderItem?.product.productCategory.productCategoryType === ProductCategoryType.Fuel;
  }

  save(): void {
    if (this.slingOrder.navigationPlan.responsible.financialBlock) {
      Swal.fire({
        title: 'Atenção',
        text: 'Cliente possui bloqueio no financeiro. Deseja continuar?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          this.saveSling();
        }
      });
    } else {
      this.saveSling();
    }
  }

  isAttempted(): boolean {
    return this.slingOrder.marinaOrder.orderStatus !== 'Ordered' ||
      this.slingOrder.slingStatus !== 'PENDING_DOWN';
  }

  getRequestDate(): Date {
    if (this.slingOrder?.marinaOrder?.orderDate) {
      return moment(this.slingOrder.marinaOrder.orderDate).utc(true).toDate();
    }
    return null;
  }

  back(): void {
    if (this.slingConfig.hasAccessories) {
      this.eventSling.emit(3);
    } else if (this.slingConfig.hasConsumables) {
      this.eventSling.emit(2);
    } else if (this.slingConfig.hasFuelSupply) {
      this.eventSling.emit(1);
    } else {
      this.eventSling.emit(0);
    }
  }

  close(reload?): void {
    this.eventClose.emit(reload);
  }

  printNavitagionPlan(id: number): void {
    this.spinner.show();
    this.printingService.generatePdfById(id, PrintType.SLING).subscribe((data) => {
      this.spinner.hide();
      const url = window.URL.createObjectURL(data);
      window.open(url);
    });
  }
}
