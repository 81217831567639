<div class="col-12 pt-0 " style="padding: 1rem;">
  <div class="card">
      <p-table
          #dt1
          [value]="listaProcessos"
          dataKey="id"
          [rows]="10"
          [rowHover]="true"
          [paginator]="true"
          responsiveLayout="scroll"
      >
          <ng-template pTemplate="header">
              <tr>
                  <th pSortableColumn="description">
                      Descrição <p-sortIcon field="description"></p-sortIcon>
                  </th>

                  <th pSortableColumn="startDate">
                      Início <p-sortIcon field="startDate"></p-sortIcon>
                  </th>

                  <th pSortableColumn="endDate">
                      Fim
                      <p-sortIcon field="endDate"></p-sortIcon>
                  </th>

                  <th class="text-center">
                      Sucesso
                  </th>

                  <th class="text-center">
                      Download
                  </th>

              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-process>
              <tr>
                  <td>
                      {{ process.description }}
                  </td>
                  <td>
                      {{ process.startDate | date: 'dd/MM/yyyy HH:mm:ss' }}
                  </td>
                  <td>
                      {{ process.endDate | date: 'dd/MM/yyyy HH:mm:ss' }}
                  </td>
                  <td class="text-center">
                      <i class="pi pi-check" *ngIf="process.success===true" style="color:green;"></i>
                      <i class="pi pi-spin pi-spinner" *ngIf="process.success===null"></i>
                      <i class="pi pi-times"  pTooltip={{process.errorDescription}} style="color:red;" *ngIf="process.success===false"></i>
                  </td>
                  <td>
                      <div class="flex justify-content-center">
                         <i class="pi pi-download" style="cursor:pointer;" (click)="download(process.urlFile1)" *ngIf="process.urlFile1!==null"></i>
                         <i class="pi pi-download pl-2" style="cursor:pointer;" (click)="download(process.urlFile2)" *ngIf="process.urlFile2!==null"></i>
                      </div>
              </tr>
          </ng-template>
      </p-table>
      <div class="footer-message text-center" style="margin-top: 1rem;">
        <p>Esta página atualiza a cada 15 segundos</p>
      </div>
  </div>
</div>
