<p-fieldset>
  <p-header class="article-title">
    <span class="article-title">{{
      "TITLE-SECURITIES-PROPERTY" | translate
    }}</span>
    <div class="header-buttons">
      <button
        class="primary fab-button"
        (click)="opensecuritiesForm()"
        pTooltip="Cadastrar um grupo de títulos"
        tooltipPosition="bottom"
      >
        <i class="pi pi-plus" style="font-size: 21px"></i>
      </button>
      <button
        class="fab-button primary"
        appFiltersButton
        pTooltip="Exibir/Ocultar Filtros"
        tooltipPosition="left"
      >
        <i class="pi pi-filter"></i>
      </button>

      <button
        class="fab-button primary"
        (click)="exportTable()"
        label="Exportar Excel"
        pTooltip="A exportação será de acordo com os filtros selecionados"
        tooltipPosition="left"
      >
        <i class="pi pi-file"></i>
      </button>
    </div>
  </p-header>

  <form class="filters" (submit)="getSecuritirsByFilter()" appFilters>
    <div class="filter-row">
      <div class="form-group" style="display: flex; width: 100%">
        <div class="form-control" style="max-width: 33%">
          <label>{{ "Proprietário:" | translate }}</label>
          <p-dropdown
            placeholder="Selecione..."
            [disabled]="false"
            [filter]="true"
            [options]="customerList"
            [(ngModel)]="filter.currentCustomerId"
            optionValue="value"
            optionLabel="label"
            appendTo="body"
            name="selectedCustomerId"
          >
          </p-dropdown>
        </div>

        <div class="form-control" style="max-width: 33%">
          <label>{{ "Data inicial" | translate }}</label>
          <p-calendar
            dateFormat="dd/mm/yy"
            name="startDate"
            [(ngModel)]="filter.lastAssociationStart"
            appendTo="body"
            [showIcon]="true"
            i18n
            [monthNavigator]="true"
            [yearNavigator]="true"
            [yearRange]="'2000:2055'"
            rPCalendarMask
          >
          </p-calendar>
        </div>

        <div class="form-control" style="max-width: 33%">
          <label>{{ "Data final" | translate }}</label>
          <p-calendar
            dateFormat="dd/mm/yy"
            name="endDate"
            [(ngModel)]="filter.lastAssociationEnd"
            appendTo="body"
            [showIcon]="true"
            i18n
            [monthNavigator]="true"
            [yearNavigator]="true"
            [yearRange]="'2000:2055'"
            rPCalendarMask
          >
          </p-calendar>
        </div>
        <div class="form-control" style="max-width: 33%">
          <label>{{
            "Possui ou já possuiu: (*filtro único)" | translate
          }}</label>
          <p-dropdown
            placeholder="Selecione..."
            [disabled]="false"
            [filter]="true"
            [options]="customerList"
            [(ngModel)]="filter.currentCustomerIdOld"
            optionValue="value"
            optionLabel="label"
            appendTo="body"
            name="selectedCustomerId"
          >
          </p-dropdown>
        </div>
      </div>
      <div class="buttons">
        <div class="form-button">
          <button (click)="clear()" class="easymarine danger" type="button">
            {{ "CLEAR" | translate }}
          </button>
        </div>
        <div class="form-button">
          <button class="easymarine primary" type="submit">
            {{ "FIND" | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
  <div class="table">
    <p-table
      #securitiesProperty
      [value]="listSecuritiesProperty"
      (onLazyLoad)="onChangePage($event)"
      [paginator]="true"
      [rows]="numberOfRows"
      [lazy]="true"
      [rowsPerPageOptions]="[10, 20, 30, 50, 100]"
      [rows]="pageable ? pageable.size : null"
      dataKey="id"
      sortMode="multiple"
      csvSeparator=";"
      [columns]="cols"
      [totalRecords]="totalRecords"
      styleClass="easy-table"
      [alwaysShowPaginator]="false"
      autoLayout="true"
      [globalFilterFields]="[
        'numeroId',
        'currentCustomerName',
        'currentCustomerPhone'
      ]"
    >
      <ng-template pTemplate="caption">
        <div class="export-buttons"></div>
        <span style="font-weight: bold; margin-top: 5px">
          {{ "TOTAL-REGISTERS" | translate }}:
          {{ listSecuritiesProperty?.length }} {{ "OF" | translate }}
          {{ totalRecords }}
        </span>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th [pSortableColumn]="'numberId'" class="text-center">
            {{ "Número do Título" | translate }}
            <p-sortIcon [field]="'numberId'"></p-sortIcon>
          </th>

          <th [pSortableColumn]="'currentCustomerName'" class="text-center">
            {{ "Sócio" | translate }}
            <p-sortIcon [field]="'currentCustomerName'"></p-sortIcon>
          </th>

          <th class="text-center" [pSortableColumn]="'currentCustomerPhone'">
            {{ "PHONE" | translate }}
            <p-sortIcon [field]="'currentCustomerPhone'"></p-sortIcon>
          </th>
          <th class="text-center">
            {{ "Associado em:" | translate }}
          </th>
          <th class="text-center">
            {{ "Bloqueado" | translate }}
          </th>
          <th class="text-center">
            {{ "Convites" | translate }}
          </th>
          <th class="text-center">
            {{ "Status" | translate }}
          </th>

          <th class="text-center">{{ "Ações" | translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-property>
        <tr>
          <td class="text-center">
            {{ property?.numberId }}
          </td>
          <td class="text-center">
            {{ property?.currentCustomerName }}
          </td>
          <td class="text-center">
            {{ property?.currentCustomerPhone }}
          </td>
          <td class="text-center">
            {{ property?.lastAssociation | date : "dd/MM/yyyy" }}
          </td>

          <td class="text-center">
            <span
              *ngIf="property.block"
              class="badge badge-pill danger"
              style="cursor: default"
              >{{ property.blockType | translate }}</span
            >
          </td>
          <td class="text-center">
            {{ property?.invitesNow + " de " + property?.invitesAfterRecharge }}
          </td>
          <td class="text-center">
            <span
              *ngIf="property.active"
              class="badge badge-pill success"
              style="cursor: default"
              >{{ "ACTIVE" | translate }}</span
            >
            <span
              *ngIf="!property.active"
              class="badge badge-pill danger"
              style="cursor: default"
              >{{ "Inativo" }}</span
            >
          </td>
          <td class="text-center">
            <p-menu
              appendTo="body"
              #menu
              [model]="menuItems"
              [popup]="true"
              [showTransitionOptions]="'150ms'"
              [hideTransitionOptions]="'150ms'"
            ></p-menu>
            <button
              (click)="menu.toggle($event); changeSelectedItem(property)"
              pButton
              type="button"
              icon="pi pi-ellipsis-v"
              class="p-button-text p-button-rounded p-button-lg"
            ></button>

            <!-- <button
              (click)="opensecuritiesEdit(property)"
              pButton
              type="button"
              icon="pi pi-pencil"
              class="p-button-text "
            ></button> -->
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-fieldset>
