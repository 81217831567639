import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Vacancy } from 'src/app/models';
import { MovementLocation } from 'src/app/models/movement/movement-location';
import { MovementLocationService } from 'src/app/services/movements/movement-location.service';

@Component({
  selector: 'app-movement-select-origin-movement-location',
  templateUrl: './movement-select-origin-movement-location.component.html',
  styleUrls: ['./movement-select-origin-movement-location.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(
        () => MovementSelectOriginMovementLocationComponent
      ),
      multi: true,
    },
  ],
})
export class MovementSelectOriginMovementLocationComponent
  implements OnInit, OnChanges, ControlValueAccessor
{
  movementLocations: { label: string; value: MovementLocation }[];
  movementLocation: MovementLocation;

  vacanciesByMovementLocations: { label: string; value: Vacancy }[];

  private onChange: any = () => {};
  onTouched: any = () => {};
  disabled: boolean;

  @Input() boat;
  @Input() form;
  constructor(private movementLocationService: MovementLocationService) {}

  writeValue(obj: any): void {
    this.movementLocation = obj;
    this.emitChanges(obj);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    this.loadMovementLocations();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.boat &&
      changes.boat.currentValue !== changes.boat.previousValue
    ) {
      this.emitChanges(null);
    }
    // this.changeActualMovementLocation();
  }
  changeActualMovementLocation() {
    if (this.boat?.currentLocation?.location != null) {
      const movLoc = this.movementLocations.find(
        (movLoc) => movLoc.value?.id === this.boat.currentLocation.location.id
      );
      this.movementLocation = movLoc.value;
      this.emitChanges(movLoc.value)
    }
  }
  selectMovementLocationOrigin(event: any): void {
    this.emitChanges(event.value);
  }

  emitChanges(moveLocation: any) {
    this.movementLocation = moveLocation;
    this.onChange(moveLocation);
  }
  private loadMovementLocations(): void {
    this.movementLocationService.getAll().subscribe(
      (data) => {
        this.movementLocations = data
          .filter((movLoc) => (movLoc.active = true))
          .map((movLoc: MovementLocation) => ({
            label: movLoc.name,
            value: movLoc,
          }));
        this.changeActualMovementLocation();
        this.movementLocations.unshift({ label: 'Sem local', value: null });
      },
      () => (this.movementLocations = null)
    );
  }
}
