<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{ 'TITLE-BOAT-WASH-ENGINE-TURN' | translate }}</span>

        <div class="header-buttons">
            <button class="fab-button primary" (click)="refresh()">
                <i class="pi pi-refresh"></i>
            </button>
            <button class="fab-button primary" (click)="exportWasheEngineTurn()">
                <i class="pi pi-file-export"></i>
            </button>
        </div>
    </p-header>

    <div class="subtitles">
        <fieldset>
          <legend>Legenda</legend>
          <div class="badges">
            <i class="red pi pi-circle-on"></i>
            Vencido
            <i class="yellow pi pi-circle-on"></i>
            Vence hoje
            <i class="white pi pi-circle-on"></i>
            A vencer
          </div>
        </fieldset>
      </div>

    <p-tabView *ngIf="loaded">
        <p-tabPanel [header]="washLabel + ' - ' + countWash" *ngIf="slingConfig.marineBoatWash">
            <wash-tab [slingConfig]="slingConfig" [boatsEngineTurn]="boatsEngineTurn" [boatWashes]="boatWashes"
                [filterStatusoptions]="filterStatusoptions">
            </wash-tab>
        </p-tabPanel>
        <p-tabPanel [header]="turnLabel + ' - ' + countEngine" *ngIf="slingConfig.marineEngineTurnRun">
            <engine-turn-tab [slingConfig]="slingConfig" [boatsEngineTurn]="boatsEngineTurn" [boatWashes]="boatWashes"
                [filterStatusoptions]="filterStatusoptions">
            </engine-turn-tab>
        </p-tabPanel>
    </p-tabView>
</p-fieldset>
