export class VisitorFilter {
  id: number;
  customerId: number;
  name: string;
  document: string;
  active: boolean;
  customerResponsibleId: number;
  group: number;
  permissionStartDate: Date;
  permissionEndDate: Date;
  carPlate: string;
  marinaId: number;
  serviceProvider: boolean;
  pageIndex: number;
  pageSize: number;
  sort: string;
}
