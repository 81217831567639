<div class="back-principal">
  <form (submit)="save()" [formGroup]="ticketForm">
    <span style="color: red; font-size: 12px;">
      >Verifique se você está usando o usuário correto</span
    >
    <br />
    <div class="form-group" style="margin-top: 20px;">
      <div class="form-control">
        <label for="user">{{ "USER" | translate }} <span style="color:red;"> * </span></label>
        <input pInputText type="text" name="user" formControlName="user" style="width: 100%;" readonly/>
      </div>
    </div>

    <div class="form-group">
      <div class="form-control">
        <label for="requester">{{ "Solicitante" | translate }} <span style="color:red;"> * </span></label>
        <p-dropdown
          [options]="requesters"
          name="state"
          appendTo="body"
          formControlName="requester"
          [style]="{ width: '100%' }"
        >
        </p-dropdown>
      </div>
    </div>

    <div class="form-group">
      <div class="form-control">
        <label for="application">{{ "Aplicação" | translate }} <span style="color:red;"> * </span></label>
        <p-dropdown
          #application
          [options]="applications"
          name="state"
          appendTo="body"
          formControlName="application"
          (onChange)="getGrupos(application.value)"
          [style]="{ width: '100%' }"
        >
        </p-dropdown>
      </div>
    </div>
    <div class="form-group">
      <div class="form-control">
        <label for="aba">{{ "Aba" | translate }} <span style="color:red;"> * </span></label>
        <p-dropdown
          #aba
          [options]="abas"
          name="state"
          appendTo="body"
          formControlName="aba"
          (onChange)="getSubGrupos(aba.value)"
          [style]="{ width: '100%' }"
        >
        </p-dropdown>
      </div>
    </div>
    <div class="form-group">
      <div class="form-control">
        <label for="screen">{{ "Tela" | translate }} <span style="color:red;"> * </span></label>
        <p-dropdown
          #screen
          [options]="screens"
          name="state"
          appendTo="body"
          formControlName="screen"
          [style]="{ width: '100%' }"
          (onChange)="getscreen(screen.label)"
        >
        </p-dropdown>
      </div>
    </div>
    <span style="color: red; font-size: 12px;">
      >Preenchimento obrigatório</span>
    <div class="form-group" style="text-align: center; margin-top: 20px;">
      <button
        class="easymarine primary"
        style="border-radius: 10px; width: 191px;"
        type="submit"
      >
        Abrir Chamado
      </button>
    </div>
  </form>
</div>
