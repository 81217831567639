<p-fieldset>
  <p-header class="article-title">
    <span class="article-title">{{
      "Embarcações vinculadas" | translate
    }}</span>
  </p-header>
  <div class="table">
    <p-table
      #tt
      [value]="boatsTable"
      [autoLayout]="true"
      [rows]="numberOfRows"
      [lazy]="true"
      styleClass="easy-table"
      autoLayout="true"
    >
      <ng-template pTemplate="header">
        <tr>
          <th style="max-width: 180px">Nome embarcação</th>
          <th style="max-width: 180px">Permissões</th>
          <th style="max-width: 180px">Autorizado por</th>
          <th style="max-width: 180px">Proprietários</th>
          <th style="max-width: 180px">Autorizados</th>
          <th style="max-width: 40px"></th>
        </tr>
        <tr>
          <th style="max-width: 190px">
            <p-dropdown
              #boat
              placeholder="Selecione..."
              [filter]="true"
              [options]="boats"
              [(ngModel)]="boatSailorAdd.boat"
              appendTo="body"
              name="selectedBoatId"
              (onChange)="getCustomer(boat.value)"
            >
            </p-dropdown>
          </th>
          <th style="max-width: 190px">
            <p-multiSelect
              [defaultLabel]="'Grupo de Permissões'"
              appendTo="body"
              [options]="permissionsOptions"
              selectedItemsLabel="{0} itens selecionados"
              name="permissionGroup"
              [(ngModel)]="boatSailorAdd.permission"
            ></p-multiSelect>
          </th>
          <th style="max-width: 190px">
            <p-dropdown
              placeholder="Selecione..."
              [disabled]="false"
              [filter]="true"
              [options]="owners"
              [(ngModel)]="boatSailorAdd.authorizedBy"
              appendTo="body"
              name="selectedBoatId"
            >
            </p-dropdown>
          </th>
          <th style="max-width: 190px">
            <input
              pInputText
              #propri
              type="text"
              name="proprietarios"
              readonly="true"
              [(ngModel)]="ownerList"
            />
          </th>
          <th style="max-width: 190px">
            <input
              pInputText
              #autori
              type="text"
              name="autorizados"
              readonly="true"
              [(ngModel)]="userList"
            />
          </th>
          <th style="max-width: 190px">
            <button class="easymarine primary" (click)="postBoatSailor()">
              {{ "Adicionar" | translate }}
            </button>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-boatsailor let-rowIndex="rowIndex">
        <tr>
          <td style="max-width: 190px">{{ boatsailor.boatTempName }}</td>
          <td style="max-width: 190px">
            <p-multiSelect
              [defaultLabel]="'Grupo de Permissões'"
              appendTo="body"
              [options]="permissionsOptions"
              selectedItemsLabel="{0} itens selecionados"
              name="permissionGroupTable"
              [(ngModel)]="boatsailor.permission"
              [ngModelOptions]="{ standalone: true }"
              (onChange)="enviar()"
            ></p-multiSelect>
          </td>
          <td style="max-width: 190px">{{ boatsailor.authorizedBy?.name }}</td>
          <td style="max-width: 190px">{{ boatsailor.ownerList }}</td>
          <td style="max-width: 190px">{{ boatsailor.userList }}</td>
          <td style="max-width: 40px">
            <div
              class="table-action"
              (click)="deleteBoatSailor(rowIndex)"
              pTooltip="Remover a embarcação"
            >
              <i class="pi pi-trash"></i>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-fieldset>
