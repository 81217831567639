import { Component, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { DocumentDefaultSigner } from 'src/app/models/document-default-signer';
import { SignerType } from 'src/app/models/enums/signerType';
import { DocumentDefaultSignerService } from 'src/app/services/document-default-signer.service';
import { ToastService } from 'src/app/services/toast.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { DefaultSignerFormComponent } from './default-signer-form/default-signer-form.component';

@Component({
  selector: 'app-contracts-config-default-signer',
  templateUrl: './contracts-config-default-signer.component.html',
  styleUrls: ['./contracts-config-default-signer.component.scss']
})
export class ContractsConfigDefaultSignerComponent implements OnInit {

  defaultSigners: DocumentDefaultSigner[] = []

  constructor(
    public dialog: DialogService,
    private messageUtil: MessageUtil,
    private documentDefaultSignerService: DocumentDefaultSignerService,
    private toasty: ToastService
  ) { }

  ngOnInit(): void {
    this.getSigner();
  }

  async getSigner(): Promise<void> {
    return new Promise<void>(
      async (resolve, reject) => {
        this.documentDefaultSignerService.getDefaultSigners().subscribe(
          async (data) => {
            this.defaultSigners = data;
            resolve();
          }, async (error) => {
            console.log(error);
            reject();
          });
      }
    );
  }

  openDefaultSignerForm(signer?: DocumentDefaultSigner): void {
    this.dialog.open(DefaultSignerFormComponent, {
      header: this.messageUtil.translateKey('DEFAULT-SIGNER-REGISTRATION'),
      data: {
        signer,
        qtyMarina: this.defaultSigners.filter(signer => signer.signerType === SignerType.SIGNER_MARINA).length,
        qtyWitness: this.defaultSigners.filter(signer => signer.signerType === SignerType.WITNESS).length
      }
    }).onClose.subscribe((data) => {
      if (data) {
        this.getSigner();
      }
    });
  }

  delete(signer: DocumentDefaultSigner) {
    this.documentDefaultSignerService.delete(signer.id).subscribe(
      async (data) => {
        this.toasty.success('Excluído com sucesso.');
        this.getSigner();
      }, async (error) => {
        console.log(error);
      });
  }
}
