<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{ 'TITLE-SLING-SHARE' | translate }}</span>
        <button class="primary fab-button" (click)="loadAll()">
            <i class="pi pi-refresh" style="font-size: 21px;"></i>
        </button>
    </p-header>

    <div class="table">
        <p-table #tt [value]="urlsTable" [paginator]="true" [rows]="numberOfRows" autoLayout="true"
            resetPageOnSort="true" [pageLinks]="3" [rowsPerPageOptions]="[10,20,30,50,100]"
            [globalFilterFields]="['code','createdAt','clicks', 'marinaName', 'boatName', 'responsible', 'executionForecast', 'returnTime']"
            [filterDelay]="0" styleClass="easy-table">
            <ng-template pTemplate="caption">
                <div appHideMobile></div>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText placeholder="Filtro"
                        (input)="tt.filterGlobal($event.target.value, 'contains')">
                </span>
                <span style="font-weight: bold; float: right; margin-top: 5px;">{{ 'TOTAL-REGISTERS' | translate }}:
                    {{tt.totalRecords}} {{'OF' | translate}} {{urlsTable?.length}}
                </span>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th [pSortableColumn]="'code'">
                        Código
                        <p-sortIcon [field]="'code'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'createdAt'">
                        Criação
                        <p-sortIcon [field]="'createdAt'"></p-sortIcon>
                    </th>

                    <th [pSortableColumn]="'clicks'">
                        Clicks
                        <p-sortIcon [field]="'clicks'"></p-sortIcon>
                    </th>

                    <th [pSortableColumn]="'marinaName'">
                        Marina
                        <p-sortIcon [field]="'marinaName'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'boatName'">
                        Embarcação
                        <p-sortIcon [field]="'boatName'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'responsible'">
                        Cliente
                        <p-sortIcon [field]="'responsible'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'executionForecast'">
                        Data saída
                        <p-sortIcon [field]="'executionForecast'"></p-sortIcon>
                    </th>
                    <th [pSortableColumn]="'returnTime'">
                        Data retorno
                        <p-sortIcon [field]="'returnTime'"></p-sortIcon>
                    </th>
                    <!-- <th></th> -->
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-url>
                <tr>
                    <td>{{ url.code }}</td>
                    <td>{{ url.createdAt | date:'dd/MM/yy HH:mm' }}</td>
                    <td>{{ url.clicks }}</td>
                    <td>{{ url.marinaName }}</td>
                    <td>{{ url.boatName }}</td>
                    <td>{{ url.responsible }}</td>
                    <td>{{ url.executionForecast | date:'dd/MM/yy HH:mm' }}</td>
                    <td>{{ url.returnTime | date:'dd/MM/yy HH:mm' }}</td>
                    <!-- <td>
                        <a class="table-action" pTooltip="Visualizar link" [href]="easyWebPage + url.code"
                            tooltipPosition="bottom" target="_blank">
                            <i class="pi pi-eye" style="color: black"></i>
                        </a>
                    </td> -->
                    <td>
                        <div class="table-action" (click)="deleteConfirm(url)" pTooltip="Deletar link"
                            tooltipPosition="bottom">
                            <i class="pi pi-trash" style="color:  #EF5350"></i>
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="9">
                        Nenhum registro encontrado!
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-fieldset>