import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InvoiceService } from 'src/app/services/invoice.service';
import { MessageUtil } from 'src/app/utils/message.util';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-change-date-dialog',
  templateUrl: './change-date-dialog.component.html',
  styleUrls: ['./change-date-dialog.component.scss']
})
export class ChangeDateDialogComponent implements OnInit {

  today: Date;
  invoices: any[];


  dueDate;
  date;
  canChangeDueDate: boolean;
  values = ['due', 'competence'];
  selectedValue;
  changeToPayment: boolean=false;

  constructor(
    public dialog: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private spinner: NgxSpinnerService,
    private invoiceService: InvoiceService,
    private messageUtil: MessageUtil
  ) {
    this.today = this.config.data.today;
    this.invoices = this.config.data.invoices;
    this.canChangeDueDate = this.config.data.canChangeDueDate;
    if (this.config.data.changeToPayment) {
      this.changeToPayment = this.config.data.changeToPayment;
      this.selectedValue='due'
    }
    if (!this.canChangeDueDate) {
      this.selectedValue = this.values[1];
    }
  }

  ngOnInit(): void {
  }

  selectDate($event): void {
    this.date = $event.value;
  }

  save(): void {
    if (this.selectedValue == 'due') {
      this.saveDueDate();
    } else if (this.selectedValue == 'competence') {
      this.saveCompetenceDate();
    }
  }

  close(): void {
    this.dialog.close(true);
  }

  getMinDate(): any {
    return (this.selectedValue == 'competence') ? undefined : this.today;
  }

  saveDueDate(): void {
    if (this.date < this.today) {
      this.date = this.today;
    }
    const date = new Date(this.date);
    if (this.changeToPayment){
      this.dialog.close(date);
      return;
    }
     this.spinner.show();
    this.invoices.forEach(invoice => {
      this.invoiceService.changeDueDate(invoice.id, this.date).subscribe(() => {
        Swal.fire('Alteração da data de vencimentos', 'Data de vencimento modificada com sucesso!', 'success');
      },
      (error) => {
        const exception = error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }, () => {
        this.close();
      });
    });
    this.spinner.hide();
    this.close();
  }

  saveCompetenceDate(): void {

    this.spinner.show();
    this.invoices.forEach(invoice => {
      this.invoiceService.changeCompetenceDate(invoice.id, this.date).subscribe(() => {
        Swal.fire('Alteração da data de competência', 'Data de competência modificada com sucesso!', 'success');
      },
      (error) => {
        const exception = error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }, () => {
        this.close();
      });
    });
    this.spinner.hide();
    this.close();
  }
}
