import { StorageUtil } from '../utils/storage.util';
import { Marina } from './marina';

export class NixIntegrationConfig {
  public id: number;
  public marina: Marina;
  public enableBillet: boolean;
  public enablePix: boolean;
  public pixNumber: string;
  public pixPaymentForm: number;
  public pixPaymentFormDescription: string;
  public pixType: string;
  public socialNumber: string;
  public pixAccount: number;
  public accountName: string;
  public enableHybridBillet: boolean;
  public nixToken: string;
  public nixLogin: string;
  public nixPassword: string;

  constructor() {
    this.marina = StorageUtil.getMarina();
    this.enableBillet = false;
    this.enablePix = false;
    this.enableHybridBillet = false;
  }
}
