import { Marina } from './marina';
import { MenuItem } from './menu-item';

export class Role {
  public id: number;
  public name: string;
  public marina: Marina;
  public menuItens: MenuItem[];
  public hierarchy: number;
  public photos: boolean;
  public administrator: boolean;
  public responsible: boolean;
  public changeInvoiceDates: boolean;
  constructor(id?) {
    this.id = id;
    this.menuItens = [];
    this.photos = false;
  }
}
