export class AppConfig {
    productLink: string;
    apiHost: string;
    d4signApi: string;
    finHost: string;
    finHost2: string;
    finApi: string;
    finApiIntegracao: string;
    billetApi: string;
    apiLog: string;
    environmentAppClass: string;
    environmentApp: string; easyWebPage: string;
    userChamadoIntegracao:number;
    workshopHost: string;
    workshopApi: string;
    chamadosApi: string;
    nixxBankLoginApi: string;
    panels: string;
    googleMapsAPI: string;
    apiNotification: string;
    apiLogV2: string;
}

function makeAppConfig(): AppConfig {
    let porta = ':8080';
    let apiHostname = window.location.hostname;
    let apiEasyLog = 'http://localhost:8081';
    let easyWebPage = 'https://www.easymarine.com.br';
    let boletosUrl = 'https://boletos.scc4.com.br/rest/boleto/';
    let d4signApi = 'secure.d4sign.com.br';
    let userChamado = 48;
    let workshopHost = 'http://localhost:4088';
    let workshopApi = 'http://localhost:8088';
    let chamadosApi = 'https://chamados.easymarine.com.br/cliente-externo';
    let nixxBankLoginApi = 'https://nixempresas.nexxera.io/login';
    let panels = 'https://panels.easymarine.com.br/';
    let googleMapsAPI = 'AIzaSyBYQjjii5VSqZhtRv4ZRqYA0GB6dc1GTpM';
    let apiLogV2 = '/api-log'
    let apiNotification = "ws://localhost:8095"

    if (window.location.hostname === 'localhost') {
        porta = ':8080';
        d4signApi = 'sandbox.d4sign.com.br';
    }

    let finServer = 'integracao.portalpostal.com.br';
    let finServer2 = 'localhost:55914';
    let finContext = '/';

    if (window.location.host === 'www2.easymarine.com.br') {
        finServer = 'fin.easymarine.com.br';
        finServer2 = 'fin2.chamados.easymarine.com.br';
        apiHostname = 'api.easymarine.com.br';
        apiEasyLog = 'https://apilog.easymarine.com.br';
        d4signApi = 'secure.d4sign.com.br';
        finContext = '/';
        porta = '';
        easyWebPage = 'https://easymarine.com.br';
        workshopHost = 'https://workshop.easymarine.com.br/';
        workshopApi = 'https://api.workshop.easymarine.com.br';
        userChamado = 63;
        panels = 'https://panels.easymarine.com.br/';
        apiNotification = 'wss://ws.easymarine.com.br'
    } else if (window.location.host === 'test.easymarine.com.br') {
        apiHostname = 'test.api.easymarine.com.br';
        porta = '';
        finServer = 'test.fin.easymarine.com.br';
        finServer2 = 'test.fin2.easymarine.com.br';
        finContext = '/';
        apiEasyLog = 'https://apilog.test.easymarine.com.br';
        d4signApi = 'sandbox.d4sign.com.br';
        easyWebPage = 'http://test.site.easymarine.com.br';
        boletosUrl = 'http://boletos.scc4.com.br/rest/boleto/';
        workshopHost = 'https://workshop.homolog.easymarine.com.br';
        userChamado = 48;
        nixxBankLoginApi = 'https://nix-empresas-web-qa.cloudint.nexxera.com/login';
    } else if (window.location.host === 'homolog.easymarine.com.br') {
        finServer = 'api.fin.homolog.easymarine.com.br';
        finServer2 = 'fin2.homolog.easymarine.com.br';
        boletosUrl = 'https://hmlg.boletos.scc4.com.br/rest/boleto/';
        porta = '';
        apiHostname = 'api.homolog.easymarine.com.br';
        d4signApi = 'secure.d4sign.com.br';
        apiEasyLog = 'https://api.log.homolog.easymarine.com.br';
        userChamado = 48;
        workshopHost = 'https://workshop.homolog.easymarine.com.br';
        chamadosApi = 'https://chamados.homolog.easymarine.com.br/cliente-externo';
        nixxBankLoginApi = 'https://nix-empresas-web-qa.cloudint.nexxera.com/login';
        panels = 'https://panels.homolog.easymarine.com.br/';
        apiNotification = 'wss://ws.homolog.easymarine.com.br'
    } else if (window.location.host === 'localhost:4200') {
        // finServer = 'localhost:8083';
        apiEasyLog = 'http://localhost:8081';
        d4signApi = 'sandbox.d4sign.com.br';
        finServer = 'localhost:8083';
        finServer2 = 'localhost:55914';
        // finContext = '/';
        boletosUrl = 'http://localhost:8084/rest/boleto/';
        userChamado = 48;
        chamadosApi = 'https://chamados.homolog.easymarine.com.br/cliente-externo';
        nixxBankLoginApi = 'https://nix-empresas-web-qa.cloudint.nexxera.com/login';
        panels = 'http://localhost:8101/';
    } else if (window.location.host === 'aws.easymarine.com.br') {
        easyWebPage = 'https://test.site.easymarine.com.br/#';
        finServer = 'test.fin.easymarine.com.br';
        finServer2 = 'fin2.easymarine.com.br';
        apiHostname = 'test.api.easymarine.com.br';
        porta = '';
        apiEasyLog = 'http://test.easymarine.com.br:8088';
        d4signApi = 'sandbox.d4sign.com.br';
        finContext = '/';
        boletosUrl = 'http://boletos.scc4.com.br/rest/boleto/';
    } else if (window.location.host === 'homolog2.easymarine.com.br') {
        apiHostname = 'homolog2.api.easymarine.com.br';
        porta = '';
        finServer = 'homolog2.fin.easymarine.com.br';
        finServer2 = 'fin2.homolog.easymarine.com.br';
        finContext = '/';
        apiEasyLog = 'http://test.easymarine.com.br:8088';
        d4signApi = 'sandbox.d4sign.com.br';
        easyWebPage = 'http://test.site.easymarine.com.br';
        boletosUrl = 'http://hmlg.boletos.scc4.com.br/rest/boleto/';
        nixxBankLoginApi = 'https://nix-empresas-web-qa.cloudint.nexxera.com/login';
    }

    const config = {
        productLink: 'https://easymarine.com.br',
        apiHost: window.location.protocol + '//' + apiHostname + porta,
        finHost: window.location.protocol + '//' + finServer + finContext + 'easy/financeiro/',
        finHost2: window.location.protocol + '//' + finServer2 + finContext + '/',
        finApi: window.location.protocol + '//' + finServer + finContext + 'api/financeiro/',
        finApiIntegracao: window.location.protocol + '//' + finServer + finContext + 'integracao/api/financeiro/',
        billetApi: boletosUrl,
        apiLog: apiEasyLog,
        d4signApi,
        environmentAppClass: getEnvironmentApp(true),
        environmentApp: getEnvironmentApp(false),
        easyWebPage,
        userChamadoIntegracao: userChamado,
        workshopHost,
        workshopApi,
        chamadosApi,
        nixxBankLoginApi,
        panels,
        googleMapsAPI,
        apiLogV2: window.location.protocol + '//' + apiHostname + porta + apiLogV2,
        apiNotification,
    };

    return config;
}

function getEnvironmentApp(action): string {
    switch (window.location.host) {
        case 'homolog.easymarine.com.br':
            if (action) {
                return 'environment-homolog';
            }
            return 'Ambiente de Homologação';
        case 'test.easymarine.com.br':
            if (action) {
                return 'environment-test';
            }
            return 'Ambiente de Teste';
        case 'localhost:4200':
            if (action) {
                return 'environment-local-host';
            }
            return 'Ambiente local';
        case 'easymarine.com.br':
            if (action) {
                return 'environment-prod';
            }
            return 'Ambiente local';
        default:
            return '';
    }
}

export const APPCONFIG = makeAppConfig();
