import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-boat-log-general',
  templateUrl: './boat-log-general.component.html',
  styleUrls: ['./boat-log-general.component.scss']
})
export class BoatLogGeneralComponent implements OnInit {

  @Input() boatLog;
  @Input() isNew;

  changeVacancy = false;
  oldDatasMap

  constructor() { }

  ngOnInit(): void {
    this.oldDatasMap = new Map(Object.entries(this.boatLog.oldDatas));
    this.changeVacancy = this.oldDatasMap.has('vacancy');
  }

  getVacancy(): string{
    let vacancyCode = '';
    if( this.changeVacancy && !this.isNew){
      vacancyCode = this.boatLog.oldDatas.vacancy? this.boatLog.oldDatas.vacancy.code: '';
    } else {
      vacancyCode = this.boatLog.vacancy? this.boatLog.vacancy.code: '';
    }

    return vacancyCode;
  }

  isChangedMaintenance() {
    return this.oldDatasMap.has('maintenance');
  }

  getMaintenance(): boolean {
    let maintenance = this.boatLog.maintenance;
    if(this.isChangedMaintenance() && !this.isNew) {
      maintenance = this.boatLog.oldDatas.maintenance;
    }
    return maintenance;

  }

  isChangedSharedInvoiceCustomerApp() {
    return this.oldDatasMap.has('sharedInvoiceCustomerApp');
  }

  getSharedInvoiceCustomerApp(): boolean {
    let sharedInvoiceCustomerApp = this.boatLog.sharedInvoiceCustomerApp;
    if(this.isChangedSharedInvoiceCustomerApp() && !this.isNew) {
      sharedInvoiceCustomerApp = this.boatLog.oldDatas.sharedInvoiceCustomerApp;
    }
    return sharedInvoiceCustomerApp;
  }

  isChangedStatus() {
    return this.oldDatasMap.has('active');
  }

  isChangedProfile() {
    return this.oldDatasMap.has('boatProfile');
  }

  isChangedLocation() {
    return this.oldDatasMap.has('movementLocation');
  }

  isChangedMovementGroup() {
    return this.oldDatasMap.has('movementGroup');
  }

  getStatus(): boolean {
    let status = this.boatLog.active;
    if(this.isChangedStatus() && !this.isNew) {
      status = this.boatLog.oldDatas.active;
    }
    return status;
  }

}
