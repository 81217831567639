import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SirenAlertSimpleConfig } from 'src/app/models/siren/siren-alert-simple-config';

@Injectable()
export class SirenAlertSimpleConfigService {

  apiUrl = environment.apiHost + '/secure/movement/siren-alert-simple-config';

  constructor(private http: HttpClient) {

  }

  find(): Observable<SirenAlertSimpleConfig[]> {
      return this.http.get(this.apiUrl)
          .pipe(map((response: any) => response.data.sirenAlertSimpleConfig as SirenAlertSimpleConfig[]));
  }

  save(sirens: SirenAlertSimpleConfig[]): Observable<any> {
      return this.http.post(this.apiUrl, sirens)
          .pipe(map((response: any) => response));
  }
}
