<p-fieldset>
  <p-header class="article-title">
    <span class="article-title">{{ "TITLE-ACCESS-POINT" | translate }}</span>
    <div class="primary fab-button" (click)="openAccessPointForm()">
      <i class="pi pi-plus" style="font-size: 21px"></i>
    </div>
  </p-header>

  <div class="table">
    <p-table
      #tt
      [value]="accessPointList"
      [paginator]="true"
      [rows]="numberOfRows"
      resetPageOnSort="true"
      [pageLinks]="3"
      [rowsPerPageOptions]="[10, 20, 30, 50, 100]"
      [resizableColumns]="true"
      [autoLayout]="true"
      [globalFilterFields]="['name']"
      styleClass="easy-table"
    >
      <ng-template pTemplate="caption">
        <div class="fill-empty"></div>
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input
            type="text"
            pInputText
            size="50"
            placeholder="Digite o nome"
            (input)="tt.filterGlobal($event.target.value, 'contains')"
            style="width: auto"
          />
        </span>
        <span style="font-weight: bold; float: right; margin-top: 5px"
          >{{ "TOTAL-REGISTERS" | translate }}: {{ accessPointList?.length }}
          {{ "OF" | translate }} {{ accessPointList?.length }}
        </span>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th [pSortableColumn]="'name'">
            {{ "NAME" | translate }}
            <p-sortIcon [field]="'name'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'location'">
            {{ "LOCAL" | translate }}
            <p-sortIcon [field]="'location'"></p-sortIcon>
          </th>
          <th [pSortableColumn]="'id'">
            {{ "IP" | translate }}
            <p-sortIcon [field]="'id'"></p-sortIcon>
          </th>
          <th class="text-center">Leitor cartão</th>
          <th class="text-center">Digital</th>
          <th class="text-center">Qr.Code</th>
          <th class="text-center">Facial</th>

          <th class="text-center" width="6%" [pSortableColumn]="'active'">
            {{ "STATUS" | translate }}
            <p-sortIcon [field]="'active'"></p-sortIcon>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-accessPoint>
        <tr style="max-height: 30px; height: 30px; min-height: 30px">
          <td>{{ accessPoint.name }}</td>
          <td>{{ accessPoint?.location }}</td>
          <td>{{ accessPoint?.ip }}</td>
          <td class="text-center">
            <span
              *ngIf="accessPoint.readCard"
              class="badge badge-pill success"
              style="cursor: default"
              >{{ "ACTIVE" | translate }}</span
            >
            <span
              *ngIf="!accessPoint.readCard"
              class="badge badge-pill danger"
              style="cursor: default"
              >{{ "INACTIVE" | translate }}</span
            >
          </td>
          <td class="text-center">
            <span
              *ngIf="accessPoint.printFinger"
              class="badge badge-pill success"
              style="cursor: default"
              >{{ "ACTIVE" | translate }}</span
            >
            <span
              *ngIf="!accessPoint.printFinger"
              class="badge badge-pill danger"
              style="cursor: default"
              >{{ "INACTIVE" | translate }}</span
            >
          </td>
          <td class="text-center">
            <span
              *ngIf="accessPoint.qrCode"
              class="badge badge-pill success"
              style="cursor: default"
              >{{ "ACTIVE" | translate }}</span
            >
            <span
              *ngIf="!accessPoint.qrCode"
              class="badge badge-pill danger"
              style="cursor: default"
              >{{ "INACTIVE" | translate }}</span
            >
          </td>
          <td class="text-center">
            <span
              *ngIf="accessPoint.face"
              class="badge badge-pill success"
              style="cursor: default"
              >{{ "ACTIVE" | translate }}</span
            >
            <span
              *ngIf="!accessPoint.face"
              class="badge badge-pill danger"
              style="cursor: default"
              >{{ "INACTIVE" | translate }}</span
            >
          </td>

          <td class="text-center">
            <span
              *ngIf="accessPoint.active"
              class="badge badge-pill success"
              style="cursor: default"
              >{{ "ACTIVE" | translate }}</span
            >
            <span
              *ngIf="!accessPoint.active"
              class="badge badge-pill danger"
              style="cursor: default"
              >{{ "INACTIVE" | translate }}</span
            >
          </td>

          <td class="text-center">
            <div
              class="table-action"
              (click)="openAccessPointForm(accessPoint)"
              pTooltip="Editar {{ accessPoint.name }}"
              tooltipPosition="bottom"
            >
              <i class="pi pi-pencil"></i>
            </div>
            <!-- <div
              class="table-action danger"
              (click)="openAccessPointDeleteConfirmDialog(accessPoint.id)"
              pTooltip="Remover {{ accessPoint.name }}"
              tooltipPosition="bottom"
            >
              <i class="pi pi-trash danger"></i>
            </div> -->
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-fieldset>
