
<div style="padding: 16px;">
    <app-product-log-general-data *ngIf="isGeneralData" [productLog]="productLog" [isNew]="true"></app-product-log-general-data>
    <app-product-log-general-data *ngIf="isGeneralData" [productLog]="productLog" [isNew]="false"></app-product-log-general-data>

    <app-product-log-stock *ngIf="isStock" [productLog]="productLog" [isNew]="true"></app-product-log-stock>
    <app-product-log-stock *ngIf="isStock" [productLog]="productLog" [isNew]="false"></app-product-log-stock>

    <app-product-log-price *ngIf="isPrice" [productLog]="productLog" [isNew]="true"></app-product-log-price>
    <app-product-log-price *ngIf="isPrice" [productLog]="productLog" [isNew]="false"></app-product-log-price>

    <app-product-log-image *ngIf="isImage" [productLog]="productLog" [isNew]="true"></app-product-log-image>
    <app-product-log-image *ngIf="isImage" [productLog]="productLog" [isNew]="false"></app-product-log-image>

    <div style="margin: 15px;">
        <div class="content-log">
          <div>
            <label>Data/hora</label>
            <label>{{ productLog.logTime | date: "dd/MM/yyyy - HH:mm" }}</label>
          </div>
          <div>
            <label>Usuário</label>
            <label>{{ productLog.user.username }}</label>
          </div>
          <div>
            <label>Origem</label>
            <label>{{ productLog.origin }}</label>
          </div>
          <div>
            <label>ação</label>
            <label>{{ productLog.operationLog | translate }}</label>
          </div>
        </div>
        <div class="buttons">
          <button class="easymarine primary" (click)="dialogRef.close()">
            Fechar
          </button>
        </div>
    </div>
</div>