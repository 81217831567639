import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ChangeBoatLocationAdjustmentDTO } from 'src/app/models/dtos/movements/movement-management/change-boat-location-adjustmentDTO';
import { CreateAdjustmentInMovementPlanDTO } from 'src/app/models/dtos/movements/movement-management/create-adjustment-in-movement-planDTO';
import { CreateMovementAdjustmentDTO } from 'src/app/models/dtos/movements/movement-management/create-movement-adjustmentDTO';
import { CreateMovementPlanAdjustmentDTO } from 'src/app/models/dtos/movements/movement-management/create-movement-plan-adjustmentDTO';
import { MovementAdjustmentDTO } from 'src/app/models/dtos/movements/movement-management/movement-adjustmentDTO';
import { VerificationMovementAdjustmentRspDTO } from 'src/app/models/dtos/movements/movement-management/verification-movement-adjustment-rspDTO';
import { VerificationMovementAdjustmentDTO } from 'src/app/models/dtos/movements/movement-management/verification-movement-adjustmentDTO';
import { CustomerService } from 'src/app/services/customer.service';
import { MovementManagementService } from 'src/app/services/movements/movement-management.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { VerificationMovementAdjustmentDialogComponent } from '../verification-movement-adjustment-dialog/verification-movement-adjustment-dialog.component';

@Component({
  selector: 'app-movement-adjustment-dialog',
  templateUrl: './movement-adjustment-dialog.component.html',
  styleUrls: ['./movement-adjustment-dialog.component.scss']
})
export class MovementAdjustmentDialogComponent implements OnInit {

  boatListOptions: any[] = [];
  movementLocationListFullValueOptions: any[] = [];
  customerListOptions: any[] = [];
  newMovement: any = {};
  today = new Date();

  selectedBoatId: any;
  date: Date;

  movements: MovementAdjustmentDTO[] = [];
  plansInDayOptions: any[] = [];

  enableSearch = false;

  constructor(
    public dialogRef: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private dialog: DialogService,
    private movementManagementService: MovementManagementService,
    private customerService: CustomerService,
    private spinner: NgxSpinnerService,
    private messageUtil: MessageUtil,
  ) { }

  ngOnInit(): void {
    if (this.config.data) {
      this.boatListOptions = this.config.data.boatListOptions;
      this.movementLocationListFullValueOptions = this.config.data.movementLocationListFullValueOptions;
      if(!this.movementLocationListFullValueOptions.find( location => location.value == null)) {
        this.movementLocationListFullValueOptions.unshift({label: 'Selecionar', value: null});
      }
    }
  }

  saveAdjustment() {
    this.spinner.show();
    const verificationAdjustment: VerificationMovementAdjustmentDTO = {
      boatId: this.selectedBoatId,
      movements: this.getNewMovements()
    }

    this.movementManagementService.verificationAdjustment(verificationAdjustment).subscribe( data => {
      this.openModalConfirmChangeCurrentLocation(data);
      this.spinner.hide();
    },
    error => {
      this.spinner.hide();
      const exception = error.error.data.exception;
      this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
    });
  }

  private openModalConfirmChangeCurrentLocation(verificationAdjustmentRspDTO: VerificationMovementAdjustmentRspDTO) {

    this.dialog.open(VerificationMovementAdjustmentDialogComponent, {
      header: 'Salvar ajuste',
      width: '600px',
      data: {
        verificationAdjustmentRspDTO,
        totalMovements: this.getNewMovements().length
      }
    }).onClose.subscribe((data: ChangeBoatLocationAdjustmentDTO) => {
      if (data) {
        this.save(data);
      }
    });
  }

  private save(data: ChangeBoatLocationAdjustmentDTO) {
    if(this.isNewMovementPlan()) {
      this.saveNewMovementPlan(data)
    } else {
     this.saveAdjustmentsInPlan(data)
    }
  }

  private saveNewMovementPlan(locationAdjustmentDTO: ChangeBoatLocationAdjustmentDTO) {
    this.spinner.show();

    const date = moment(this.date).format("YYYY-MM-DD")

    const newMovementPlan: CreateMovementPlanAdjustmentDTO = {
      boatId: this.selectedBoatId,
      customerId: this.newMovement.customer.id,
      date,
      movements: this.getNewMovements(),
      locationAdjustmentDTO
    };

    this.movementManagementService.adjustmentNewMovementPlan(newMovementPlan).subscribe(
      data => {
        this.messageUtil.generateMessage('success', 'SUMMARY.SUCCESS', 'Ajuste realizado com sucesso!');
        this.dialogRef.close();
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }

  private saveAdjustmentsInPlan(locationAdjustmentDTO: ChangeBoatLocationAdjustmentDTO) {
    this.spinner.show();
    const date = moment(this.date).format("YYYY-MM-DD")

    const adjustmentsInMovementPlan: CreateAdjustmentInMovementPlanDTO = {
      movementPlanId: this.newMovement.movementPlan.id,
      date,
      movements: this.getNewMovements(),
      locationAdjustmentDTO
    };

    this.movementManagementService.adjustmentInMovementPlan(adjustmentsInMovementPlan).subscribe(
      data => {
        this.messageUtil.generateMessage('success', 'SUMMARY.SUCCESS', 'Ajuste realizado com sucesso!');
        this.dialogRef.close();
        this.spinner.hide();
      },
      error => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }

  private getNewMovements(): CreateMovementAdjustmentDTO[] {
    return this.movements.filter(m => !m.movementId && m.movementPlanId == this.newMovement.movementPlan.movementPlanId)
      .map( m => ({destinyId: m.destinyId, originId: m.originId, finishDateTime: m.finishDateTime}));
  }

  isNewMovementPlan(): boolean {
    return this.newMovement?.movementPlan?.movementPlanId == 0;
  }

  isValidMovement(): boolean {
    return this.newMovement.origin && this.newMovement.destiny && this.newMovement.time;
  }

  addMovement() {
    const finishDateTime = moment(this.date).format("YYYY-MM-DD") +"T"+ this.newMovement.time+":00";

    if(this.newMovement?.customer?.name) {
      this.newMovement.movementPlan.customerName = this.newMovement.customer.name;
    }

    const movement: any = {
      movementPlanId: this.newMovement.movementPlan.movementPlanId,
      movementPlanMarinaId: this.newMovement.movementPlan.movementPlanMarinaId,
      customerName: this.newMovement.movementPlan.customerName,
      destinyName: this.newMovement.destiny.name,
      destinyId: this.newMovement.destiny.id,
      originName: this.newMovement.origin.name,
      originId: this.newMovement.origin.id,
      finishDateTime,
      activeInDay: true,
      systemAdjustment: true
    }

    this.newMovement = {
      movementPlan: this.newMovement.movementPlan,
      customer: this.newMovement.customer
    };

    this.movements.push(movement)
    this.movements.sort((a,b) => {
      return new Date(b.finishDateTime).getTime() - new Date(a.finishDateTime).getTime();
    });
  }

  removeMovementAjustment(movement) {
    this.movements = this.movements.filter( m => m != movement);
  }

  isValid(): boolean {
    return this.selectedBoatId && !!this.date;
  }

  find() {
    this.spinner.show();
    this.newMovement = {};
    return new Promise( async resolve => {
      const date = moment(this.date).format("YYYY-MM-DD")
      this.movementManagementService.findMovementsByBoatAndDate(this.selectedBoatId, date).subscribe(
        data => {
          this.movements = data.movements;
          this.findCustomerOptions();
          this.plansInDayOptions = data.movementPlansInDay.map( mp => ({label: mp.movementPlanMarinaId, value: mp}));
          this.plansInDayOptions.unshift({label: 'Novo plano', value: { movementPlanId: 0, movementPlanMarinaId: null}})
          this.plansInDayOptions.unshift({label: 'Selecionar ...', value: null})
          this.enableSearch = false;
          this.spinner.hide();
        },
        error => {
          this.movements = [];
          this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
        }
      )
    });
  }

  async findCustomerOptions(): Promise<void> {
    return new Promise( async resolve => {
      this.customerService.getCustomerByBoatFilterList(this.selectedBoatId).subscribe(
        data => {
          this.customerListOptions = data.filter(c => c.active).map(item => ({ label: item.name, value: item }));
          if (this.customerListOptions.length == 1) {
            this.newMovement.customer = this.customerListOptions[0];
          }
          this.customerListOptions.unshift({label: 'Selecionar', value: null});
          resolve();
        },
        error => {
          resolve();
        }
      )
    });
  }

  changeDate() {
    if(this.selectedBoatId && this.date) {
      this.find();
    }
  }

  disableBoatAndDate(): boolean {
    return !!this.newMovement.movementPlan;
  }

  disablePlanAndCustomer(): boolean {
    return !!this.movements.find(m => !m.movementId);
  }

}
