export class PlanoConta {
    idPlanoConta: number;
    tipo?: {
        id: number;
        codigo: string;
        descricao: string;
    };
    codigo?: number;
    nome?: string;

    descricao?: string;
    nivel?: number;
    status?: {
        id: number;
        codigo: string;
        descricao: string;
    };
    estrutura?: {
        '1': number;
        '2': number;
        '3': number;
        '4': number;
        '5': number;
    };
    grupo?: {
        idPlanoConta: number;
    };
    contas?: PlanoConta[];
    parent?: PlanoConta;
}
