import {MenuItemService} from './../../services/menu-item.service';
import {OperationalConfigService} from './../../services/config/operational-config.service';
import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment';
import {NgxSpinnerService} from 'ngx-spinner';
import {InvoiceBankingBillet} from 'src/app/models';
import {DashboardPieModel} from 'src/app/models/dashboardPieModel';
import {StockReport} from 'src/app/models/stockReport';
import {BilletService} from 'src/app/services/billet.service';
import {BoatService} from 'src/app/services/boat.service';
import {ContractDocumentService} from 'src/app/services/contract-document.service';
import {DashboardService} from 'src/app/services/dashboard.service';
import {MyAccountService} from 'src/app/services/my-account.service';
import {StorageUtil} from 'src/app/utils/storage.util';
import {Observable} from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  showDashboard = true;

  qtyBoatMaintenance = 0;
  boatsMaintenance = 'Nenhum barco em manutenção';

  customerByAppAndWeb: DashboardPieModel[] = [];
  customerType: DashboardPieModel[] = [];
  documentCondition: DashboardPieModel[] = [];
  slingOrigin: DashboardPieModel[] = [];
  slingQuantity: DashboardPieModel[] = [];
  slingStatusToday: DashboardPieModel[] = [];
  stockReport: StockReport[] = [];
  boatInsurance: DashboardPieModel[] = [];
  insuranceCondition: DashboardPieModel[] = [];
  licenceCondition: DashboardPieModel[] = [];
  slingQuantityChart: any;
  days: number;
  statusToday: any;
  origins: any;
  doughnutOptions = {
    scales: {
      x: {
        display: false
      },
      y: {
        display: false
      }
    },
    plugins:{
      legend: {
        display: false,
        position: 'bottom',
      },
      cutout: 70,
    },
  };
  barOptions = {
    plugins:{
      legend: {
        display: false,
        position: 'bottom',
      },
      cutout: 70,
    },
  };
  quantityChart: any;
  customerApp: any;
  boatDocuments: any;
  boatInsurers: any;
  insuranceValidation: any;
  customerTypes: any;
  licenceConditions: any;

  isMovements: boolean;
  isSlings: boolean;

  stashNotifications: number;
  bankingBillet$: Observable<number>;
  refusedBillets$: Observable<InvoiceBankingBillet[]>;
  numberNeverWashOrEngineTurn$: Observable<number>;
  numberExpiredPeriodicalService$: Observable<number>;
  numberContractsInAnalysis$: Observable<number>;

  constructor(
    private myAccountService: MyAccountService,
    private billetService: BilletService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    private contractDocumentService: ContractDocumentService,
    private boatService: BoatService,
    private operationalConfigService: OperationalConfigService,
    private menuItemService: MenuItemService,
    private dashboardService: DashboardService,
    private router: Router,
  ) {
    this.findDashboards();
  }

  ngOnInit(): void {
    this.operationalConfigService.isMovements().then((movements: boolean) => {
      this.isMovements = movements;
      this.isSlings = !movements;
    });

    this.bankingBillet$ = this.getBankPendingBillets();
    this.refusedBillets$ = this.getRefusedBillets();
    this.numberNeverWashOrEngineTurn$ = this.getnumberNeverWashOrEngineTurn();
    this.numberExpiredPeriodicalService$ = this.getExpiredPeriodicalService();
    this.numberContractsInAnalysis$ = this.getNumberContractsInAnalysis();
    this.getInvoiceDays();
  }

  redirectToSelectionFiltered(event, type) {
    switch (type) {
      case 'insuranceValidity':
        let stringRoute = '/app/forms/boat'
        switch (event.element.index) {
          case 0:
            this.router.navigate([stringRoute], { queryParams: { insuranceValidity: 'NO_INSURANCE' } });
            break;
          case 1:
            this.router.navigate([stringRoute], { queryParams: { insuranceValidity: 'EXPIRED' } });
            break;
          case 2:
            this.router.navigate([stringRoute], { queryParams: { insuranceValidity: 'VALID' } });
            break;
          default:
            break;
        }
      break;
    }
  }

  getInvoiceDays() {
    const vencimento: any = StorageUtil.getLastPayment();
    if (!vencimento) {
      this.getLatestPayment().subscribe({
        next: (vencimento) => {
          if (vencimento) {
            this.calculateDays(vencimento);
          }
        }, error: (err) => {
          console.log(err);
        }
      });
    } else {
      this.calculateDays(vencimento);
    }
  }
  
  getMenuStructure(): void{
    this.menuItemService.getAllStructured().subscribe((data) => {
      data.filter((b) => b.label === 'DASHBOARD').length > 0
        ? (this.showDashboard = true)
        : (this.showDashboard = false);
    });
  }

  findDashboards(): void {
    this.spinner.show();
    this.dashboardService.customerByAppAndWeb().subscribe((data) => {
      this.customerByAppAndWeb = data;
      this.createSeriesCustomerByAppAndWebChart();
    });
    this.dashboardService.customerType().subscribe((data) => {
      this.customerType = data;
      this.createSeriesCustomerTypeChart();
    });
    this.dashboardService.documentCondition().subscribe((data) => {
      this.documentCondition = data;
      this.createSeriesDocumentsConditionChart();
    });
    this.dashboardService.slingOrigin().subscribe((data) => {
      this.slingOrigin = data;
      this.createSeriesSlingOriginChart();
    });
    this.dashboardService.slingQuantity().subscribe((data) => {
      this.slingQuantity = data;
      this.slingQuantityChart = this.createSeriesSlingQuantityChart();
    });
    this.dashboardService.slingStatusToday().subscribe((data) => {
      this.slingStatusToday = data;
      this.slingStatusToday.forEach((status) => {
        this.translateService
          .get(status.name.toUpperCase())
          .subscribe((result: string) => (status.name = result));
      });
      this.createSeriesSlingStatusTodayChart();
    });
    this.dashboardService.stockReport().subscribe((data) => {
      this.stockReport = data;
      // this.slingQuantityChart = this.createSeriesSlingQuantityChart();
    });
    this.dashboardService.qtyBoatMaintenance().subscribe((data) => {
      if (data.length > 0) {
        this.boatsMaintenance = data.join('\n');
        this.qtyBoatMaintenance = data.length;
      }
      this.spinner.hide();
    });


    this.dashboardService.boatInsurance().subscribe({
      next: (data) => {
      this.boatInsurance = data;
      this.createBoatInsuranceChart();
      },
      error: (e) => {
        console.log(e);
      },
    })

    this.dashboardService.insuranceCondition().subscribe((data) => {
      this.insuranceCondition = data;
      this.createInsuranceConditionChart();
    });

    this.dashboardService.licenceCondition().subscribe((data) => {
      this.licenceCondition = data;
      this.createLicenceConditionChart();
    });
  }

  createSeriesCustomerByAppAndWebChart(): any {
    this.customerApp = {
      labels: this.addPercentageToLabel(this.customerByAppAndWeb),
      datasets: [
        {
          data: this.customerByAppAndWeb.map((s) => s.value),
          backgroundColor: ['#88CC66', '#9999DD'],
        },
      ],
    };
  }

  createSeriesCustomerTypeChart(): any {
    this.customerTypes = {
      labels: this.addPercentageToLabel(this.customerType),
      datasets: [
        {
          data: this.customerType.map((s) => s.value),
          backgroundColor: ['#88CC66', '#9999DD', '#66AAEE'],
        },
      ],
    };
  }

  createSeriesDocumentsConditionChart(): any {
    this.boatDocuments = {
      labels: this.addPercentageToLabel(this.documentCondition),
      datasets: [
        {
          data: this.documentCondition.map((s) => s.value),
          backgroundColor:
            this.documentCondition.length === 3
              ? ['salmon', '#DD7755', '#66AAEE']
              : ['orange', 'salmon', '#DD7755', '#66AAEE'],
        },
      ],
    };
  }

  createSeriesSlingOriginChart(): any {
    const aux = [];
    aux.push(this.slingOrigin[0]);
    aux.push(this.slingOrigin[2]);
    aux.push(this.slingOrigin[1]);
    aux.push(this.slingOrigin[3]);
    const labelsWithPercent = this.addPercentageToLabel(this.slingOrigin);
    this.origins = {
      labels: labelsWithPercent,
      datasets: [
        {
          data: this.slingOrigin.map((s) => s.value),
          backgroundColor: ['#88CC66', '#9999DD', '#66AAEE', '#DD7755'],
        },
      ],
    };
  }

  createSeriesSlingQuantityChart(): any {
    this.quantityChart = {
      labels: this.slingQuantity.map((s) => s.name),
      datasets: [
        {
          data: this.slingQuantity.map((s) => s.value),
          backgroundColor: [
            '#88CC66',
            '#275f8e',
            '#ffa500',
            '#5cb85c',
            '#275f8e',
            '#ffa500',
            '#5cb85c',
            '#275f8e',
            '#ffa500',
          ],
        },
      ],
    };
    this.quantityChart.datasets[0].data.forEach((set, index) => {
      if (index > 8) {
        this.quantityChart.datasets[0].backgroundColor.push(
          '#' + this.getRandomColor()
        );
      }
    });
  }

  createSeriesSlingStatusTodayChart(): any {
    this.statusToday = {
      labels: this.addPercentageToLabel(this.slingStatusToday),
      datasets: [
        {
          data: this.slingStatusToday.map((s) => s.value),
          backgroundColor: ['#88CC66', '#ffa500', '#185992'],
        },
      ],
    };
  }

  createBoatInsuranceChart(): any {
    this.boatInsurers = {
      labels: this.addPercentageToLabel(this.boatInsurance),
      datasets: [
        {
          data: this.boatInsurance.map((s) => s.value),
          backgroundColor: [],
        },
      ],
    };
    this.boatInsurers.datasets[0].data.forEach((set) => {
      this.boatInsurers.datasets[0].backgroundColor.push(
        '#' + this.getRandomColor()
      );
    });
  }

  createInsuranceConditionChart(): any {
    this.insuranceValidation = {
      labels: this.addPercentageToLabel(this.insuranceCondition),
      datasets: [
        {
          data: this.insuranceCondition.map((s) => s.value),
          backgroundColor: ['#DD7755', '#9999DD', '#66AAEE'],
        },
      ],
    }; 
  }

  private addPercentageToLabel(array: any[]): string[] {
    const values = array.map((s) => s.value);
    const total = values.reduce((a, b) => a + b, 0);
    return array.map((s) => {
      const percentage =
        ((s.value / total) * 100).toFixed(2).replace('.', ',') + '%';
      return s.name + ' - ' + percentage;
    });
  }

  refresh(): void {
    this.customerByAppAndWeb = [];
    this.customerType = [];
    this.documentCondition = [];
    this.slingOrigin = [];
    this.slingQuantity = [];
    this.slingStatusToday = [];
    this.findDashboards();
  }

  getLatestPayment(): Observable<Date> {
    return new Observable<Date>((observer) => {
      this.myAccountService.getLatestPayment().subscribe({
        next: (vencimento) => {
          if (vencimento) {
            observer.next(vencimento);
          } else {
            observer.next(null);
          }
        }, error: (err) => {
          observer.error(err);
        }
      });
    });
  }

  calculateDays(vencimento): void {
    this.days = moment()
      .hours(0)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
      .diff(moment(vencimento, 'YYYY-MM-DD'), 'days', false);
  }

  getBankPendingBillets(): Observable<number> {
    return new Observable<number>((observer) => {
      this.billetService.findBankPending().subscribe({
        next: (response) => {
          observer.next(response);
        },
        error: (err) => {
          console.log(err);
          observer.error(null);
        }
      });
    });
  }

  getRandomColor(): string {
    return Math.floor(Math.random() * 16777215).toString(16);
  }

  getRefusedBillets(): Observable<InvoiceBankingBillet[]> {
    return new Observable<InvoiceBankingBillet[]>((observer) => {
      this.billetService.findRefusedNotCanceled().subscribe({
        next: (response) => {
          let billets = response;
          if (response.length == 0) {
            billets = null;
          }
          observer.next(billets);
        },
        error: (err) => {
          console.log(err);
          observer.error(null);
        }
      });
    });
  }


  getExpiredPeriodicalService(): Observable<number> {
    return new Observable<number>((observer) => {
      this.boatService.getExpiredPeriodicalService().subscribe({
        next: (data) => {
          if (data > 0) {
            observer.next(data);
          }else{
            observer.next(null)
          }
        },
        error: (error) => {
          console.log(error);
          observer.error(error);
        }
      });
    });
  }

  getNumberContractsInAnalysis(): Observable<number> {
    return new Observable<number>((observer) => {
      this.contractDocumentService.getNumberContractsInAnalysis().subscribe({
        next: (data) => {
          if (data > 0){
            observer.next(data);
          }else{
            observer.next(null);
          }
        },
        error: (error) => {
          console.log(error);
          observer.error(error);
        }
      });
    });
  }

   getnumberNeverWashOrEngineTurn(): Observable<number> {
     return new Observable<number>((observer) => {
       this.boatService.getNumberNeverWashOrEngineTurn().subscribe({
         next: (response) => {
           observer.next(response);
         },
         error: (err) => {
           console.log(err);
           observer.error(null);
         }
       });
     });
   }

  createLicenceConditionChart(): any {
    this.licenceConditions = {
      labels: this.addPercentageToLabel(this.licenceCondition),
      datasets: [
        {
          data: this.licenceCondition.map((s) => s.value),
          backgroundColor:
            this.licenceCondition.length === 3
              ? ['salmon', '#DD7755', '#66AAEE']
              : ['orange', 'salmon', '#DD7755', '#66AAEE'],
        },
      ],
    };
  }
}
