import {Product} from "../../product";

export class FranchiseGroupOutput {
  id: number;
  name: string;
  active: boolean;
  marinaId: number;
  description: string;
  surplusService: Product;
  franchise: number;
  activeQuantity: number;
  inactiveQuantity: number;
}
