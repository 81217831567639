import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectLabelValue } from 'src/app/models/select-label-value';
import { BoatLogService } from 'src/app/services/boat-log.service';
import { BoatService } from 'src/app/services/boat.service';
import { UserService } from 'src/app/services/user.service';
import { StorageUtil } from 'src/app/utils/storage.util';

@Component({
  selector: 'app-boat-log',
  templateUrl: './boat-log.component.html',
  styleUrls: ['./boat-log.component.scss']
})
export class BoatLogComponent implements OnInit {

  boatLogs = [];
  filteredUsers = [];
  boatsFilter: SelectLabelValue[] = [];
  filter = { marinaId: 0, dateStart: new Date(), dateEnd: new Date(), userId: null, boatId: null };

  pt = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  };

  constructor(
    private boatLogService: BoatLogService,
    private userService: UserService,
    private boatService: BoatService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
  ) { }

  async ngOnInit(): Promise<void> {
    const params = Object.assign({}, this.route.snapshot.queryParams);
    if (params && params.date) {
      this.filter.dateStart = new Date(params.date);
      this.filter.dateEnd = new Date(params.date);
    }

    const marina = StorageUtil.getMarina();
    this.filter.marinaId = marina.id;
    this.loadUsers();
    this.loadBoat();
    this.spinner.show();
    await this.find();
    this.spinner.hide();
  }

  async find(): Promise<void> {
    return new Promise<void>(async resolve => {
      this.boatLogService.findBoats(this.filter).subscribe(
        async (data) => {
          this.boatLogs = data;
          resolve();
        },
        async (error) => {
          console.error(error);
          resolve();
        }
      );
    });
  }

  loadBoat(): void {
    this.boatService.getAllByActiveForSelect().subscribe(
      (data) => {
        this.boatsFilter = data;
        this.boatsFilter.unshift({ label: 'Todos', value: null });
      }
    );
  }

  private loadUsers(): void {
    this.userService.getMarinaUsers().subscribe(
      (data) => {
        this.filteredUsers = data.map((c) => ({ label: c.firstName.concat(' ').concat(c.lastName), value: c.id }));
        this.filteredUsers.unshift({ label: 'Todos', value: null });
      }
    );
  }

}
