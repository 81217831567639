<p-fieldset>
  <p-header class="article-title">
      <span class="article-title">{{'TITLE-MOVEMENTS-STOCK' | translate}}</span>
      <div class="primary fab-button" (click)="openMovementFormDialog(null)">
          <i class="pi pi-plus" style="font-size: 21px;"></i>
      </div>
  </p-header>

  <div class="table">
      <p-table #tt [value]="movements" [paginator]="true" [rows]="numberOfRows" resetPageOnSort="true" [pageLinks]="3"
          [rowsPerPageOptions]="[10,20,30,50,100]" [resizableColumns]="true" [autoLayout]="true"
          [globalFilterFields]="['name','type']" styleClass="easy-table">
          <ng-template pTemplate="caption">
              <div class="fill-empty"></div>
              <span class="p-input-icon-left">
                  <i class="pi pi-search"></i>
                  <input type="text" pInputText size="50" placeholder="Filtro global"
                      (input)="tt.filterGlobal($event.target.value, 'contains')" style="width:auto">
              </span>
              <span style="font-weight: bold; float: right; margin-top: 5px;">{{ 'TOTAL-REGISTERS' | translate }}:
                  {{tt.totalRecords}} {{'OF' | translate}} {{movements.length}}
              </span>
          </ng-template>
          <ng-template pTemplate="header">
              <tr>
                  <th [pSortableColumn]="'name'">
                      {{ 'NAME' | translate }}
                      <p-sortIcon [field]="'name'"></p-sortIcon>
                  </th>
                  <th [pSortableColumn]="'type'">
                      {{ 'TYPE' | translate }}
                      <p-sortIcon [field]="'type'"></p-sortIcon>
                  </th>
                  <th class="text-center" width="6%" [pSortableColumn]="'active'">
                    {{ 'STATUS' | translate }}
                    <p-sortIcon [field]="'active'"></p-sortIcon>
                  </th>
                  <th></th>
                  <th></th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-movement>
              <tr style="max-height: 30px; height: 30px; min-height: 30px;">
                  <td>{{movement.name}}</td>
                  <td>{{movement.type | translate }}</td>

                  <td class="text-center">
                    <a href="javascript:;" [pTooltip]="movement.active ? 'Desativar' : 'Ativar'"
                        class="badge badge-pill" [ngClass]="{'success':movement.active, 'danger': !movement.active}"
                        tooltipPosition="top" (click)="updateStatus(movement)">
                        {{ movement.active ? 'Ativo' : 'Inativo' }}
                    </a>
                  </td>

                  <td class="text-right">
                      <div class="table-action" (click)="openMovementFormDialog(movement)"
                          pTooltip="Editar {{movement.code}}" tooltipPosition="bottom">
                          <i class="pi pi-pencil"></i>
                      </div>
                  </td>
                  <td class="text-left">
                      <div class="table-action" (click)="openMovementDeleteConfirmDialog(movement)"
                          pTooltip="Remover {{movement.code}}" tooltipPosition="bottom">
                          <i class="pi pi-trash"></i>
                      </div>
                  </td>
              </tr>
          </ng-template>
      </p-table>
  </div>
</p-fieldset>
