import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InvoiceBankingBillet } from '../models/invoice-banking-billet';
import { environment } from '../../environments/environment';
import { InvoiceAnticipationDTO } from '../models';
import { PaginationFilter } from '../models/pagination-filter';
import { map } from 'rxjs/operators';

@Injectable()
export class BilletService {
  constructor(private http: HttpClient) {}

  findAll(): Observable<any> {
    return this.http
      .get(environment.apiHost + '/secure/invoice/billet')
      .pipe(map((response: any) => response.data.outFiles));
  }

  getBilingPortifolio(idBillit: number): Observable<any> {
    return this.http
      .get(environment.apiHost + '/secure/invoice/billet/' + idBillit)
      .pipe(map((response: any) => response.data.carteiraCobranca));
  }

  returnProcess(files): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', files[0]);

    let hd = new HttpHeaders();
    hd = hd.set('Content-Type', 'multipart/form-data');
    hd = hd.set('Access-Control-Allow-Origin', '*');

    let httpOptions = {};
    if (!environment.production) {
      httpOptions = {
        headers: hd,
      };
    }

    return this.http
      .post(
        environment.billetApi +
          'processarRetorno?token=6CC811DB1E8A57F31E61523BAA756',
        formData
        //comentada em producao
        // httpOptions
      )
      .pipe(map((response: any) => response));
  }

  uploadReturnFile(files): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', files[0]);

    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'multipart/form-data');

    return this.http
      .post(
        environment.apiHost + '/secure/invoice/billet/processarRetorno',
        formData,
        { headers }
      )
      .pipe(map((response: any) => response.data.retorno));
  }

  returnProcessMockup(files): Observable<any> {
    // tslint:disable-next-line: deprecation
    return Observable.create((sub) => {
      sub.next({
        status: 'SUCCESS',
        message: null,
        code: null,
        data: {
          retornoCNAB: {
            id: 0,
            bacenBanco: 0,
            dataImportacao: null,
            idUserImportacao: 0,
            nomeArquivo: null,
            dataArquivo: null,
            cnabArquivo: null,
            qtdRegistros: 0,
            dataMovimento: null,
            registros: [
              {
                idRetorno: 0,
                sequencial: 0,
                idFatura: 1701,
                nossoNumero: '28567070000001701',
                nossoNumeroDV: '',
                codigoBaixa: 0,
                codigoMovimento: 6,
                numeroParcela: 0,
                dataEntrada: 1544493600000,
                dataVencimento: 1544148000000,
                dataCredito: 1544580000000,
                valorTitulo: 5,
                valorTarifa: 0.7,
                valorAbatimento: 0,
                valorRecebido: 5.05,
                valorLancamento: 5.05,
                jurosMora: 0.05,
                descontoConcedido: 0,
                fatura: null,
                mensagemMovimento: 'RECEBIDO',
              },
            ],
          },
        },
        fail: false,
        success: true,
        error: false,
      });
    });
  }

  updateDownload(billetId): Observable<any> {
    return this.http
      .get(
        environment.apiHost +
          `/secure/invoice/billet/updateDownload/${billetId}`
      )
      .pipe(map((response: any) => response));
  }

  setRegistred(interBilletRegisterDTO): Observable<any[]> {
    return this.http
      .post(
        environment.apiHost + '/secure/invoice/billet/register',
        interBilletRegisterDTO
      )
      .pipe(map((response: any) => response.data.invoices as any[]));
  }

  findAnticipated(
    filter: any,
    paginationFilter: PaginationFilter
  ): Observable<any> {
    let params = new HttpParams();

    let sort;
    if (paginationFilter.sort) {
      sort = paginationFilter.sort;
    }
    if (paginationFilter.order) {
      sort = (sort ? sort + ',' : '') + paginationFilter.order;
    }
    if (sort) {
      params = params.set('sort', sort);
    }
    params = params.set('page', paginationFilter.page.toString());
    params = params.set('size', paginationFilter.size.toString());

    return this.http
      .post(
        environment.apiHost + '/secure/invoice/billet/anticipated',
        filter,
        { params }
      )
      .pipe(map((response: any) => response.data.billetsTable as any));
  }

  repurchase(billets: any[]): Observable<any[]> {
    return this.http
      .post(environment.apiHost + '/secure/invoice/billet/repurchase', billets)
      .pipe(map((response: any) => response.data.billets));
  }

  anticipate(body: InvoiceAnticipationDTO): Observable<any> {
    return this.http
      .post(environment.apiHost + '/secure/invoice/billet/anticipate/', body)
      .pipe(map((response: any) => response.data.anticipationLot));
  }

  generateAnticipationLot(): Observable<string> {
    return this.http
      .get(environment.apiHost + '/secure/invoice/billet/anticipation-lot/')
      .pipe(map((response: any) => response.data.lot));
  }

  findByFilter(
    filter: any,
    paginationFilter: PaginationFilter
  ): Observable<any> {
    let params = new HttpParams();

    let sort;
    if (paginationFilter.sort) {
      sort = paginationFilter.sort;
    }
    if (paginationFilter.order) {
      sort = (sort ? sort + ',' : '') + paginationFilter.order;
    }
    if (sort) {
      params = params.set('sort', sort);
    }
    params = params.set('page', paginationFilter.page.toString());
    params = params.set('size', paginationFilter.size.toString());

    return this.http
      .post(
        environment.apiHost + '/secure/invoice/billet/find-by-filter',
        filter,
        { params }
      )
      .pipe(map((response: any) => response.data.content));
  }

  cancelBillets(billetIds: number[]): Observable<any> {
    return this.http
      .post(environment.apiHost + '/secure/invoice/billet/cancel', billetIds)
      .pipe(map((response: any) => response.data));
  }

  cancelBilletBank(billetId: number, active: boolean): Observable<any> {
    return this.http
      .put(
        environment.apiHost +
          '/secure/invoice/billet/cancelBank/' +
          billetId +
          '/' +
          active,
        null
      )
      .pipe(map((response: any) => response));
  }

  findBankPending(): Observable<number> {
    return this.http
      .get(environment.apiHost + '/secure/invoice/billet/pending-bank')
      .pipe(map((response: any) => response.data.billets));
  }

  findRefusedNotCanceled(): Observable<InvoiceBankingBillet[]> {
    return this.http
      .get(environment.apiHost + '/secure/invoice/billet/refused')
      .pipe(
        map((response: any) => response.data.billets as InvoiceBankingBillet[])
      );
  }

  getSequence(): Observable<number> {
    return this.http
      .get(environment.apiHost + '/secure/invoice/billet/outfile/sequence')
      .pipe(map((response: any) => response.data.sequence as number));
  }

  setRefused(lista: InvoiceBankingBillet[]): Observable<any[]> {
    return this.http
      .post(environment.apiHost + '/secure/invoice/billet/refuse', lista)
      .pipe(map((response: any) => response.data.invoices as any[]));
  }
}
