import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class MyAccountService {

    constructor(private http: HttpClient) {

    }

    getMyInvoices(): Observable<any> {
        return this.http.get(environment.apiHost + '/secure/my-account/invoices').pipe(
            map((response: any) => response.data.invoices as any)
        );
    }

    getLatestPayment(): Observable<Date> {
        return this.http.get(environment.apiHost + '/secure/my-account/invoices/latest').pipe(
            map((response: any) => response.data.vencimento as Date)
        );
    }

    getInvoice(id: number): Observable<{pdf:string}> {
        return this.http.get(environment.apiHost + '/secure/my-account/invoice/' + id).pipe(
            map((response: any) => response.data.invoice as {pdf:string})
        );
    }
}
