import {Component, OnInit} from '@angular/core';
import {ToastService} from "../../../../services/toast.service";
import {NgxSpinnerService} from "ngx-spinner";
import {FranchiseGroupService} from "../../../../services/franchise-group.service";
import {FranchiseGroupOutput} from "../../../../models/dtos/franchise/franchise-group-output";
import {forkJoin, Observable, of} from "rxjs";
import {BoatFranchiseDTO} from "../../../../models/dtos/franchise/boat-franchiseDTO";
import {Router} from "@angular/router";

@Component({
  selector: 'app-franchise-group-batch',
  templateUrl: './franchise-group-batch.component.html',
  styleUrls: ['./franchise-group-batch.component.scss']
})
export class FranchiseGroupBatchComponent implements OnInit {
  showFranchiseLists = true;
  totalFranchiseList: FranchiseGroupOutput[] = [];
  sourceFranchiseList: FranchiseGroupOutput[] = [];
  sourceFranchiseSelected: FranchiseGroupOutput;
  targetFranchiseList: FranchiseGroupOutput[];
  targetFranchiseSelected: FranchiseGroupOutput;
  sourceAccessBoatObjList: BoatFranchiseDTO[];
  targetAccessBoatObjList: BoatFranchiseDTO[];

  constructor(private toasty: ToastService,
              private spinner: NgxSpinnerService,
              private franchiseGroupService: FranchiseGroupService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.findAllFranchise()
  }

  save() {
    this.spinner.show()
    forkJoin({
      sourceList: this.updateSourceBoatList(),
      targetList: this.updateTargetBoatList()
    }).subscribe({
      next: (_) => {
        this.toasty.success('Barcos migrados com sucesso')
        this.spinner.hide()
        this.router.navigate(["/app/forms/franchise"])
      }, error: err => {
        this.toasty.error(err)
        this.spinner.hide()
      }
    })

  }

  updateTargetBoatList(): Observable<void> {
    return new Observable<void>((observer) => {
      if (this.targetAccessBoatObjList.length > 0) {
        const body = {
          "franchiseGroupId": this.targetFranchiseSelected.id,
          "boatIdList": this.targetAccessBoatObjList.map((item) => item.id),
        }
        this.franchiseGroupService.updateBoatToFranchiseInBatch(body).subscribe({
          next: (response) => {
            observer.next()
            observer.complete()
          }, error: err => {
            observer.error(err.error)
            observer.complete()
          }
        })
      } else {
        observer.next()
        observer.complete()
      }
    })
  }

  updateSourceBoatList(): Observable<void> {
    return new Observable<void>((observer) => {
      if (this.sourceAccessBoatObjList.length > 0) {
        const body = {
          "franchiseGroupId": this.sourceFranchiseSelected.id,
          "boatIdList": this.sourceAccessBoatObjList.map((item) => item.id),
        }
        this.franchiseGroupService.updateBoatToFranchiseInBatch(body).subscribe({
          next: (response) => {
            observer.next()
            observer.complete()
          }, error: err => {
            observer.error(err)
            observer.complete()
          }
        })
      } else {
        observer.next()
        observer.complete()
      }
    })
  }

  invertShowFranchiseList() {
    this.showFranchiseLists = !this.showFranchiseLists;
    if (this.showFranchiseLists === false) {
      this.findBoatsFromFranchiseGroups();
    }
  }

  findAllFranchise() {
    this.spinner.show();
    this.franchiseGroupService.findAll().subscribe({
      next: (response) => {
        this.addFranchiseWithNoBoats(response);
        this.totalFranchiseList = response;
        this.sourceFranchiseList = response;
        this.sourceFranchiseSelected = this.sourceFranchiseList[0];
        this.populateTargetFranchiseList();
        this.spinner.hide();
      }, error: (error) => {
        this.spinner.hide();
        this.toasty.error('Erro ao buscar os grupos de franquia');
      }
    });
  }

  private addFranchiseWithNoBoats(response: FranchiseGroupOutput[]) {
    const franchiseWithAllBoats = new FranchiseGroupOutput();
    franchiseWithAllBoats.id = 0;
    franchiseWithAllBoats.name = 'Barcos sem franquia'
    response.push(franchiseWithAllBoats);
  }

  populateTargetFranchiseList() {
    this.targetFranchiseList = [];
    this.targetFranchiseList = this.sourceFranchiseList.filter(c => c.id !== this.sourceFranchiseSelected.id);
    this.targetFranchiseSelected = this.targetFranchiseList[0];
  }

  private findBoatsFromFranchiseGroups() {
    this.spinner.show();
    forkJoin({
      sourceOne: this.franchiseGroupService.findAllBoatsByFranchiseGroup(this.sourceFranchiseSelected.id),
      sourceTwo: this.franchiseGroupService.findAllBoatsByFranchiseGroup(this.targetFranchiseSelected.id),
    }).subscribe({
      next: ({sourceOne, sourceTwo}) => {
        this.sourceAccessBoatObjList = sourceOne
        this.targetAccessBoatObjList = sourceTwo
        this.spinner.hide();
      }, error: err => {
        this.toasty.error("Erro ao buscar embarcações do grupo de franquia");
        this.spinner.hide();
      }
    })
  }
}
