<p-fieldset>
  <p-header class="article-title">
    <span class="article-title">{{ "LOCATION_INVOICE" | translate }}</span>
    <div class="header-buttons">
      <button
        class="fab-button primary"
        (click)="exportTable()"
        label="Exportar Excel"
        pTooltip="A exportação será de acordo com os filtros selecionados"
        tooltipPosition="left"
      >
        <i class="pi pi-file"></i>
      </button>
      <button
        class="fab-button primary"
        appFiltersButton
        pTooltip="Exibir/Ocultar Filtros"
        tooltipPosition="left"
      >
        <i class="pi pi-filter"></i>
      </button>
    </div>
  </p-header>
  <form class="filters" (submit)="search()" appFilters>
    <div class="filter-row">
      <div class="form-control">
        <label>
          {{ "INITIAL-DATE" | translate }}
        </label>
        <p-calendar
          dateFormat="dd/mm/yy"
          name="contractStartDateFrom"
          rPCalendarMask
          appendTo="body"
          [(ngModel)]="dateStart"
          [showIcon]="true"
          i18n
          [monthNavigator]="true"
          [ngModelOptions]="{ standalone: true }"
          [yearNavigator]="true"
          [yearRange]="'2019:2030'"
        >
        </p-calendar>
      </div>
      <div class="form-control">
        <label>
          {{ "FINAL-DATE" | translate }}
        </label>
        <p-calendar
          dateFormat="dd/mm/yy"
          name="contractStartDateFrom"
          rPCalendarMask
          appendTo="body"
          [(ngModel)]="dateEnd"
          [showIcon]="true"
          i18n
          [monthNavigator]="true"
          [ngModelOptions]="{ standalone: true }"
          [yearNavigator]="true"
          [yearRange]="'2019:2030'"
        >
        </p-calendar>
      </div>
      <div class="form-control">
        <label>
          {{ "MOVEMENT_GROUP" | translate }}
        </label>
        <p-multiSelect
          selectedItemsLabel="{0} itens selecionados"
          defaultLabel="Selecione..."
          [ngModelOptions]="{ standalone: true }"
          (onChange)="alterMovementLocations()"
          appendTo="body"
          [options]="movementLocationType"
          [(ngModel)]="selectedMovementType"
        ></p-multiSelect>
      </div>
      <div class="form-control">
        <label>
          {{ "LOCAL" | translate }}
        </label>
        <p-multiSelect
          selectedItemsLabel="{0} itens selecionados"
          defaultLabel="Selecione..."
          appendTo="body"
          [ngModelOptions]="{ standalone: true }"
          [options]="locations"
          [(ngModel)]="selectedLocation"
        >
        </p-multiSelect>
      </div>
      <div class="buttons">
        <div class="form-button">
          <button (click)="clear()" class="easymarine danger" type="button">
            {{ "CLEAR" | translate }}
          </button>
        </div>
        <div class="form-button">
          <button class="easymarine primary" type="submit">
            {{ "FIND" | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
  <div class="table">
    <p-table
      #tt
      [value]="locationInvoiceTable"
      scrollHeight="250px"
      sortMode="multiple"
      styleClass="easy-table"
      csvSeparator=";"
      [lazy]="false"
      [alwaysShowPaginator]="false"
      autoLayout="true"
      width="100%"
      dataKey="id"
    >
      <ng-template pTemplate="caption">
        <div class="export-buttons"></div>
        <span style="font-weight: bold; margin-top: 5px">
          {{ "TOTAL-REGISTERS" | translate }}: {{ tt.totalRecords }}
          {{ "OF" | translate }}
          {{ tt.filteredValue ? tt.filteredValue.length : tt.value.length }}
        </span>
      </ng-template>
      <ng-template pTemplate="header">
        <tr class="thead">
          <th class="text-center" [pSortableColumn]="'movementLocation'">
            Local
            <p-sortIcon [field]="'movementLocation'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'totalVacancies'">
            Total de Vagas
            <p-sortIcon [field]="'totalVacancies'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'occupiedVacancies'">
            Vagas em Uso
            <p-sortIcon [field]="'occupiedVacancies'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'availableVacancies'">
            Vagas Disponíveis
            <p-sortIcon [field]="'availableVacancies'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'totalFeet'">
            Total de pés
            <p-sortIcon [field]="'totalFeet'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'averageFeet'">
            Média de pés
            <p-sortIcon [field]="'averageFeet'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'totalEntryFeet'">
            Entrada de pés
            <p-sortIcon [field]="'totalEntryFeet'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'totalExitFeet'">
            Saída de pés
            <p-sortIcon [field]="'totalExitFeet'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'averageFootValue'">
            Valor médio pé
            <p-sortIcon [field]="'averageFootValue'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'averageBoatValue'">
            Valor médio embarcação
            <p-sortIcon [field]="'averageBoatValue'"></p-sortIcon>
          </th>
          <th class="text-center" [pSortableColumn]="'totalInvoicing'">
            Faturamento
            <p-sortIcon [field]="'totalInvoicing'"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-invoiceLoc let-i="index">
        <tr>
          <td class="text-center">{{ invoiceLoc.movementLocation }}</td>
          <td class="text-center">{{ invoiceLoc.totalVacancies }}</td>
          <td class="text-center">{{ invoiceLoc.occupiedVacancies }}</td>
          <td class="text-center">{{ invoiceLoc.availableVacancies }}</td>
          <td class="text-center">{{ invoiceLoc.totalFeet }}</td>
          <td class="text-center">{{ invoiceLoc.averageFeet }}</td>
          <td class="text-center">{{ invoiceLoc.totalEntryFeet }}</td>
          <td class="text-center">{{ invoiceLoc.totalExitFeet }}</td>
          <td class="text-center">{{ invoiceLoc.averageFootValue }}</td>
          <td class="text-center">{{ invoiceLoc.averageBoatValue }}</td>
          <td class="text-center">{{ invoiceLoc.totalInvoicing }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-fieldset>
