import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MovementPlanByObjectiveCreateDTO } from 'src/app/models/dtos/movements/movement-plan-by-objective-createDTO';
import { ObjectiveOptionDTO } from 'src/app/models/dtos/movements/objectives/objective-option-dto';

@Component({
  selector: 'app-movement-plan-objectives-form',
  templateUrl: './movement-plan-objectives-form.component.html',
  styleUrls: ['./movement-plan-objectives-form.component.scss']
})
export class MovementPlanObjectivesFormComponent implements OnInit {

  @Output()
  finishEvent: EventEmitter<any> = new EventEmitter();
  @Output()
  backPageEvent: EventEmitter<void> = new EventEmitter();
  @Output()
  cancelPageEvent: EventEmitter<void> = new EventEmitter();

  @Input()
  readonly movementPlan: MovementPlanByObjectiveCreateDTO;

  @Input()
  readonly isNew: boolean;

  @Input()
  objectives: ObjectiveOptionDTO[];

  objectiveSelected: ObjectiveOptionDTO = null;

  constructor() { }

  ngOnInit(): void {
    if (!this.isNew) {
      this.objectiveSelected = this.objectives.filter(({code}) => code === this.movementPlan.objectiveCode)[0];
    }
  }

  getIconObj(objective): string {
    if (objective?.code === 'NAVIGATION') {
      return 'assets/images/seaWhiteLittle.png';
    } else if (objective?.code === 'MAINTENANCE') {
      return 'assets/images/yard.png';
    }else if (objective?.code === 'SINGLE'){
      return 'assets/images/single.png';
    }
  }

  getDescription(objective): string {
    if (objective?.code === 'NAVIGATION') {
      return 'Criar um plano de navegação';
    } else if (objective?.code === 'MAINTENANCE') {
      return 'Levar minha embarcação para manutenção';
    }else if (objective?.code === 'SINGLE'){
      return 'Criar uma movimentação avulsa';
    }
  }

  setObjective(obj: ObjectiveOptionDTO) {
    if (this.isNew) {
      this.objectiveSelected = obj;
    }
  }

  next() {
    this.finishEvent.emit({ objectiveCode: this.objectiveSelected.code });
  }

  back() {
    this.backPageEvent.emit(null);
  }

  cancel() {
    this.cancelPageEvent.emit(null);
  }
}
