import { DatePipe } from '@angular/common';
import { Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import moment from 'moment';
import { AvaliablePeriodsDTO } from 'src/app/models/dtos/operational-options/avaliable-periods-dto';
import { MovementPlanAppointmentService } from 'src/app/services/movements/movement-plan-appointment.service';
import { MessageUtil } from 'src/app/utils/message.util';

@Component({
  selector: 'app-schedule-date-mv',
  templateUrl: './schedule-date.component.html',
  styleUrls: ['./schedule-date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ScheduleDateComponent),
      multi: true
    }
  ]
})
export class ScheduleDateComponent implements OnChanges, ControlValueAccessor {
  private readonly dateFormatAvaliablePeriodsValue: string = 'YYYY-MM-DDTHH:mm';
  private readonly labelDateFormatAvaliablePeriods: string = 'HH:mm';

  @Input()
  readonly minDate: Date;
  @Input()
  readonly boat: { id: number };
  @Input()
  readonly title: string;
  @Input()
  readonly isReturnDate: boolean;

  @Input()
  started

  manualHourRequired: boolean = false;
  manualHour: boolean = false;

  availablePeriods: { label: string, value: Date, isOld?: boolean }[] = [];
  selectedPeriods: Date;
  oldValueFromEdit: Date | null = null;

  newDate: Date = null;
  newDateTime: Date = null;
  dateManual: any;
  disabled: boolean = false;

  private datePipe = new DatePipe('pt-BR');

  private onChange: any = () => { };
  writeValue(obj: Date | null): void {
    if (obj) {
      this.oldValueFromEdit = obj;
      const dateformated = moment(obj).format(this.dateFormatAvaliablePeriodsValue)
      const result = this.availablePeriods.filter(({value}) => moment(value).format(this.dateFormatAvaliablePeriodsValue) === dateformated);
      if (result.length <= 0) {
        this.availablePeriods.unshift({label: this.datePipe.transform(obj, this.labelDateFormatAvaliablePeriods), value: obj})
        this.newDate = obj;
        this.newDateTime = obj;
        this.selectedPeriods = obj;
      } else {
        this.oldValueFromEdit = result[0].value;
        this.newDate = result[0].value;
        this.newDateTime = result[0].value;
        this.selectedPeriods = result[0].value;
      }
    } else {
      this.newDate = this.minDate;
    }
  }
  registerOnChange(fn: any): void { this.onChange = fn };
  registerOnTouched(_fn: any): void { };
  setDisabledState?(isDisabled: boolean): void { this.disabled = isDisabled };

  constructor(
    private readonly movementPlanAppointmentService: MovementPlanAppointmentService,
    private messageUtil: MessageUtil
  ) { }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.minDate && changes.minDate.currentValue && changes.minDate.currentValue !== changes.minDate.previousValue ) {
      const newMinDate = this.minDate;
      if (this.newDateTime instanceof Date) {
        if (newMinDate.getTime() > this.newDateTime.getTime()) {
          this.newDateTime = null;
          this.newDate = new Date(newMinDate.getTime());
          this.changeDateByCalendar(this.newDate);
        }
      } else if (this.newDate instanceof Date) {
        if (newMinDate.getTime() > this.newDate.getTime()) {
          this.newDate = new Date(newMinDate.getTime());
          await this.changeDateByCalendar(this.newDate);
        }
      } else {
        this.newDate = new Date(newMinDate);
        await this.changeDateByCalendar(this.newDate);
      }
    }
    if (changes.boat && changes.boat.currentValue && changes.boat.currentValue !== changes.boat.previousValue ) {
      if (this.newDate) {
        await this.findAvaliablePeriods(this.newDate);
      }
    }
  }

  async findAvaliablePeriods(newDate: Date): Promise<void> {
    if (this.boat && this.minDate) {
      let date;
      if (this.isReturnDate) {
        date = newDate.getTime() < this.minDate.getTime() ? this.minDate : newDate;
      } else {
        date = new Date(new Date(newDate).setHours(0,0,0));
      }
      await this.movementPlanAppointmentService.findAvaliablePeriods(this.boat.id, date, this.isReturnDate)
        .subscribe(({ periods , message }: AvaliablePeriodsDTO) => {
          this.setAvaliablePeriods(periods, date);
        }, error => {
          this.setAvaliablePeriods([], date);
          if (!this.manualHour && this.availablePeriods.length <= 0) {
            this.sendNewDateTime(null);
            const exception = error.error.data.exception;
            this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
          }
        });
    }
  }

  setAvaliablePeriods(values: Date[], date: Date): void {
    if (this.oldValueFromEdit) {
      const day = moment(date).format('YYYY-MM-DD');
      if (moment(this.oldValueFromEdit).format('YYYY-MM-DD') === day) {
        values.unshift(this.oldValueFromEdit);
      }
    }
    this.availablePeriods = values.map((date: Date) => ({ label: this.datePipe.transform(date, this.labelDateFormatAvaliablePeriods), value: new Date(date) }));
  }

  moveDay(numDay: number): void {
    if (numDay) {
      const newDay = new Date();
      newDay.setDate(this.newDate.getDate() + numDay);
      newDay.setHours(0, 0, 0);
      this.newDate = new Date(newDay.getTime());
      this.changeDateByCalendar(this.newDate);
    }
  }

  isMinDay() : boolean {
    const minDate = new Date(this.minDate);
    const date = new Date(this.newDate);
    if (!isNaN((minDate).getTime()) && !isNaN((date).getTime())) {
      if (minDate.getFullYear() < date.getFullYear()) {
        return false;
      } else {
        if (minDate.getMonth() < date.getMonth()) {
          return false;
        } else {
          if (minDate.getDate() < date.getDate()) {
            return false;
          }
        }
      }
    }
    return true;
  }

  selectManualDateTime(event: any, time: string): void {
    if (event.isTrusted && this.newDate && time) {
      const newDate = new Date(this.newDate.getTime());
      const [ hours, minutes ] = time.split(':');
      newDate.setHours(+hours, +minutes);
      if (!isNaN(newDate.getTime())) {
        this.sendNewDateTime(newDate);
      }
    }
  }

  selectDateTime(event: { originalEvent: any, value: Date }): void {
    this.sendNewDateTime(event.value);
  }

  async changeDateByCalendar(date: Date) {
    if (this.manualHour && this.newDateTime) {
      const hours = this.newDateTime.getHours();
      const min = this.newDateTime.getMinutes();
      this.sendNewDateTime(new Date(date.setHours(hours, min)));
    } else {
      this.sendNewDateTime(null);
    }
    await this.findAvaliablePeriods(date)
  }

  sendNewDateTime(date: Date): void {
    this.newDateTime = date;
    this.selectedPeriods = date;
    this.onChange(date);
  }

  onChangeManual(event: {originalEvent: PointerEvent, checked: boolean}) {
    if (event.originalEvent.isTrusted) {
      if (event.checked) {
        this.sendNewDateTime(null);
      } else {
        this.dateManual = null;
        this.sendNewDateTime(this.dateManual);
      }
    }
  }
}
