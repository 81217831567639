import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BoatService } from 'src/app/services/boat.service';
import { CustomerService } from 'src/app/services/customer.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { MarinaOrderService } from 'src/app/services/marina-order.service';
import { ToastService } from 'src/app/services/toast.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { InvoicePaymentComponent } from '../../invoice-payment/invoice-payment.component';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { DebitsItemsComponent } from '../../debits-items/debits-items.component';
import { DialogService } from 'primeng/dynamicdialog';
import { OrderReferenceDateUtil } from 'src/app/utils/order-reference-date.util';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { SlingConfig } from 'src/app/models';
import { GenerateOrders } from 'src/app/models/dtos/generate-ordersDTO';
import { FilterBillingCompanySelected } from '../../filter/filter-billing-company/filter-billing-company.component';
import { FinancialMultipleCompaniesService } from 'src/app/services/financial/financial-multiple-companies.service';


@Component({
  selector: 'app-totem-debits',
  templateUrl: './totem-debits.component.html',
  styleUrls: ['./totem-debits.component.scss']
})
export class TotemDebitsComponent implements OnInit {

  public filteredCustomers: any[];
  public boatsFilter: any[];
  filterBillingCompanyByProduct: FilterBillingCompanySelected = { filterBillingCompany: false,  billingCompany: undefined};
  listBoat: any[];
  listCustomer: any[];
  boatSelected: number;
  customerSelected: number;
  customers: any = new Array();
  customerFilter: any = { boats: new Array() };
  orders: any = new Array();
  ordersSelected: any = new Array();
  invoices: any = new Array();
  orderSelected = false;

  slingConfig: SlingConfig;

  defaultSwalOptions: SweetAlertOptions = {
      title: 'Informação',
      text: '',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
  };

  constructor(
    private messageService: MessageUtil,
    private customerService: CustomerService,
    private orderService: MarinaOrderService,
    private invoiceService: InvoiceService,
    private boatService: BoatService,
    private dialog: DialogService,
    private spinner: NgxSpinnerService,
    private toasty: ToastService,
    private slingConfigService: SlingConfigService,
    private orderReferenceDateUtil: OrderReferenceDateUtil,
    private financialMultipleCompaniesService: FinancialMultipleCompaniesService
  ) { }

  ngOnInit(): void {
    this.loadCustomers();
    this.getBoats();
    this.loadSlingConfig();
  }

  loadSlingConfig(): void {
    this.slingConfigService.getSlingConfig().subscribe(
      (data) => {
        this.slingConfig = data[0];
      },
      (error) => null);
  }

  clearCustomer(): void {
    this.invoices = new Array();
    this.orders = new Array();
  }

  async selectCustomer() {
    if (this.boatSelected === undefined) {
      this.customerFilter = this.listCustomer.find((customer) => customer.id === this.customerSelected);
    } else {
      this.customerFilter = this.listCustomer.find((obj) => obj.customer.id === this.customerSelected).customer;
      this.customerFilter.boats = [];
      this.listBoat.forEach((boat) => {
        if (boat.boatCustomers.find((obj) => obj.customer.id === this.customerFilter.id) !== undefined) {
          this.customerFilter.boats.push(boat);
        }
      });
    }
    this.findByfiltersChange();
  }

  filterBillingCompanyByProductChange(event: any) {
    this.filterBillingCompanyByProduct = event;
    if (this.customerSelected) {
      this.findByfiltersChange();
    }
  }

  async findByfiltersChange(): Promise<void> {
    await this.getOrderDebits();
    this.getInvoiceDebits(null, false);
  }

  async getOrderDebits(): Promise<void> {
    await this.orderService.getDebits(this.customerSelected, this.filterBillingCompanyByProduct)
          .toPromise()
          .then((data) => this.orders = data)
          .catch((error) => {
            const exception = error.error.data.exception;
            this.messageService.generateMessage(exception.type, exception.title, exception.message);
          });
  }

  getInvoiceDebits(ordersSelected, option): void {
    this.invoiceService.getDebits(this.customerSelected, this.filterBillingCompanyByProduct).subscribe(
      (data) => {
        this.spinner.hide();
        this.invoices = data;
        if (option) {
          const invoice = this.invoices.find((i) => i.orders.find((o) => o.id === ordersSelected[0].id) !== undefined);
          this.invoicePaidDialog(invoice);
        }
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title, exception.message);
      });
  }

  getBoats(): void {
    this.boatService.getAll().subscribe((data) => {
      this.listBoat = data;
      this.boatsFilter = data.map((boat) => ({ label: boat.name, value: boat.id }));
    });
  }

  loadCustomers(): void {
    this.customerSelected = undefined;
    this.orders = [];
    this.invoices = [];
    this.customerFilter.boats = [];
    if (this.boatSelected === undefined) {
      this.customerService.getAllSimple().subscribe(
        (data) => {
          this.listCustomer = data;
          this.customers = data.map((customer) => ({ label: customer.name, value: customer.id }));
        },
        (error) => {
          const exception = error.error.data.exception;
          this.messageService.generateMessage(exception.type, exception.title, exception.message);
        });
    } else {
      this.listCustomer = this.listBoat.find((boat) => boat.id === this.boatSelected).boatCustomers;
      this.customers = this.listCustomer.filter((c) => c.type === 'Owner')
        .map((obj) => ({ label: obj.customer.name, value: obj.customer.id }));
      if (this.customers.length === 1) {
        this.customerSelected = this.customers[0].value;
        this.selectCustomer();
      }
    }
  }

  invoicePaidDialog(invoice: any): void {
    this.dialog.open(InvoicePaymentComponent, {
      width: '75%',
      height: 'auto',
      header: 'Baixar fatura n.º ' + invoice.invoiceNumber,
      data: {
        invoice,
        credentialShow: true
      }
    }).onClose.subscribe(() => {
      this.getInvoiceDebits(null, false);
    });
  }

  confirmGenerateOnlyInvoice(): void {
    Swal.fire({
      ...this.defaultSwalOptions,
      text: 'Deseja faturar os pedidos selecionados?'
    }).then(async (result) => {
      if (result.value) {
        await this.generateOnlyInvoice();
      }
    });
  }

  confirmGenerateInvoiceAndPaid(): void {
    Swal.fire({
      ...this.defaultSwalOptions,
      text: 'Deseja faturar e pagar os pedidos selecionados?'
    }).then((result) => {
      if (result.value) {
        this.generateInvoiceAndPaid(true);
      }
    });
  }

  async generateOnlyInvoice(): Promise<void> {
    const datesSelection = await this.orderReferenceDateUtil.selectDateForInvoices(this.slingConfig);
    let generateOrders: GenerateOrders;
    const ordersSelected = this.getOrdersSelected();
    if (datesSelection){
      generateOrders = {
        orders: ordersSelected,
        dueDate: datesSelection.dueDate,
        competenceDate: datesSelection.competenceDate,
        referenceMonth: datesSelection.referenceMonth,
        referenceYear: datesSelection.referenceYear,
      };
      this.invoiceService.generateSelected(generateOrders).subscribe(
        async (data) => {
          await this.getOrderDebits();
          this.getInvoiceDebits(null, false);
          this.toasty.success('Fatura(s) gerada(s) com sucesso!');
        },
        (error) => {
          this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageService.generateMessage(exception.type, exception.title, exception.message);
        }
      );
    }
  }

  generateInvoiceAndPaid(option): void {
    this.spinner.show();
    const ordersSelected = this.getOrdersSelected();
    const generateOrders = {
      orders: ordersSelected,
      competenceDate: null,
      dueDate: null,
      referenceMonth: null,
      referenceYear: null
    };
    this.invoiceService.generateSelected(generateOrders).subscribe(
      async (data) => {
        await this.getOrderDebits();
        this.getInvoiceDebits(ordersSelected, option);
        this.toasty.success('Fatura(s) gerada(s) com sucesso!');
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }

  getTotalOrders(): number {
    return this.orders.reduce((total, order) => total + order.value, 0);
  }

  getTotalOrderCustomer(): number {
    return this.orders.reduce((total, order) => total + this.getTotalCustomer(order.customers), 0);
  }

  getTotalCustomer(customers: any): number {
    return customers.reduce((value, customer) => {
      if (customer.id === this.customerSelected) {
        return value + customer.value;
      }
      return value;
    }, 0);
  }

  getTotalInvoices(): number {
    return this.invoices.reduce((total, invoice) => total + invoice.pendingValue, 0);
  }


  getOrdersSelected(): any[] {
    return this.orders.filter((order) => order.selected);
  }

  hasSelected(): any {
    return this.orders.some((order) => order.selected);
  }

  openDialogItensRequest(order: any): void {
    this.dialog.open(DebitsItemsComponent, {
      width: '60%',
      height: '80vh',
      header: 'Itens do pedido n.º ' + order.orderNumber,
      data: { order }
    });
  }

  hasFinancialMultipleByProductFilter(slingConfig: SlingConfig): boolean {
    return this.financialMultipleCompaniesService.hasFinancialMultipleCompaniesByProduct(slingConfig);
  }
}
