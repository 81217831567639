import { SlingStatus } from './enums';
import { Sling } from './sling';

export class BoatLocation {
    latitude: number;
    longitude: number;
    elapsedRealtimeNanos?: any;
    time?: string;
    accuracy?: any;
    bearing?: number;
    altitude?: any;
    speed?: number;
    provider?: any;
    hashCode?: any;
    sling: Sling;
    action: SlingStatus;
    created: Date;
}
