import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductType } from 'src/app/models/enums';
import { SelectLabelValue } from 'src/app/models/select-label-value';
import { BoatService } from 'src/app/services/boat.service';
import { ContractLogService } from 'src/app/services/contract-log.service';
import { CustomerService } from 'src/app/services/customer.service';
import { ProductService } from 'src/app/services/product.service';
import { UserService } from 'src/app/services/user.service';
import { StorageUtil } from 'src/app/utils/storage.util';

@Component({
  selector: 'app-contract-log',
  templateUrl: './contract-log.component.html',
  styleUrls: ['./contract-log.component.scss']
})
export class ContractLogComponent implements OnInit {

  contractLogs = [];
  filteredUsers = [];
  boatsFilter: SelectLabelValue[] = [];
  customersFilter = [];
  servicesFilter = [];
  filter = { marinaId: 0, dateStart: new Date(), dateEnd: new Date(), userId: null, boatId: null, customerId: null, targetId: null };

  pt = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  };

  constructor(
    private contractLogService: ContractLogService,
    private userService: UserService,
    private boatService: BoatService,
    private productService: ProductService,
    private customerService: CustomerService,
    private spinner: NgxSpinnerService,
  ) { }

  async ngOnInit(): Promise<void> {
    const marina = StorageUtil.getMarina();
    this.filter.marinaId = marina.id;
    this.loadUsers();
    this.loadBoat();
    this.loadCustomer();
    this.loadPeriodicServices();
    this.spinner.show();
    await this.find();
    this.spinner.hide();
  }

  async find(): Promise<void> {
    return new Promise<void>(async resolve => {
      this.contractLogService.findContracts(this.filter).subscribe(
        async (data) => {
          this.contractLogs = data;
          resolve();
        },
        async (error) => {
          console.error(error);
          resolve();
        }
      );
    });
  }

  loadUsers(): void {
    this.userService.getMarinaUsers().subscribe(
      (data) => {
        this.filteredUsers = data.map((c) => ({ label: c.firstName.concat(' ').concat(c.lastName), value: c.id }));
        this.filteredUsers.unshift({ label: 'Todos', value: null });
      }
    );
  }

  loadBoat(): void {
    this.boatService.getAllByActiveForSelect().subscribe(
      (data) => {
        this.boatsFilter = data;
        this.boatsFilter.unshift({ label: 'Todos', value: null });
      }
    );
  }

  loadCustomer(): void {
    this.customerService.getAll().subscribe(
      (data) => {
        this.customersFilter = data.map((c) => ({ label: c.name, value: c.id }));
        this.customersFilter.unshift({ label: 'Todos', value: null });
      }
    );
  }

  loadPeriodicServices(): void {
    this.productService.getByProductType(ProductType.PeriodicalService).subscribe(
      (data) => {
        this.servicesFilter = data.map((d) => ({ label: d.name, value: d.id }));
        this.servicesFilter.unshift({ label: 'Todos', value: null });
      },
      (error) => {
        this.servicesFilter = null;
      }
    );
  }
}
