<div class="dialog-form">
    <form (submit)="checkAndInvoicePay()">
        <div class="form-group">
            <div class="form-row">
                <div class="form-control">
                    <div class="text-left"  style="font-style: italic; font-weight: bold;">
                        <label>Data de Vencimento:</label>
                        <span>{{invoice.dueDate | date: 'dd/MM/yyyy'}}</span>
                    </div>
                </div>

            </div>
            <div class="form-row">

                <div class="form-control">
                    <label>Data de pagamento</label>
                    <p-calendar
                    dateFormat="dd/mm/yy"
                    name="invoice.paymentDate"
                    [(ngModel)]="invoice.paymentDate"
                    appendTo="body"
                    required
                    [showIcon]="true"
                    i18n
                    [monthNavigator]="true"
                    [yearNavigator]="true"
                    [yearRange]="'2000:2055'"
                    (onSelect)="getInterestAndDiscountSuggested()"
                    rPCalendarMask
                    [maxDate]="maxAllowedDate"
                  ></p-calendar>
                </div>
                <div class="form-control">
                    <label>Valor em aberto</label>
                    <input pInputText type="text" class="text-right" readonly name="pendingValue"
                        [value]="invoice.pendingValue | number:'1.2-2'">
                </div>
                <div class="form-control">
                    <div class="labelInterest">
                        <label>Desconto</label>
                        <a class="btn-label-juros-multa" (click)="changeDiscountToDiscountSuggested()" >
                            <label style="color: rgb(38, 199, 38); cursor: pointer;" *ngIf="dueDateDiscount != 0" pTooltip="Desconto Até o Vencimento">
                                {{dueDateDiscount | number:'1.2-2'}}
                            </label>
                        </a>
                        <a class="btn-juros-multa" *ngIf="dueDateDiscount != 0" >
                            <i class="pi pi-info-circle" tooltipPosition="bottom" pTooltip="Clique no valor para aplicar o desconto"></i>
                        </a>
                    </div>
                    <p-inputNumber mode="decimal" name="discount" [(ngModel)]="invoice.discount" [minFractionDigits]="2"
                        [maxFractionDigits]="2" (onInput)="cleanPaidValue()">
                    </p-inputNumber>
                </div>
            </div>
            <div class="form-row">
                <div class="form-control">
                    <div class="labelInterest">
                        <label>Juros/Multa </label>
                        <a class="btn-label-juros-multa" (click)="changeInterestToInterestSuggested()">
                            <label style="color: rgb(38, 199, 38); cursor: pointer;" *ngIf="interestSuggested != 0">
                                {{interestSuggested | number:'1.2-2'}}
                            </label>
                        </a>
                        <a class="btn-juros-multa" (click)="openInvoiceInterestDialog()">
                            <i class="pi pi-info-circle"></i>
                        </a>
                    </div>
                    <p-inputNumber mode="decimal" name="interest" [(ngModel)]="invoice.interest" [minFractionDigits]="2"
                        [maxFractionDigits]="2" (onInput)="cleanPaidValue()">
                    </p-inputNumber>
                </div>
                <div class="form-control half-size" *ngIf="whitheldIssTax > 0">
                    <label>Valor Imp. Retido</label>
                    <input pInputText type="text" class="text-right" readonly min="0" max="500000" autocomplete="off"
                        name="whitheldIssTax" value="{{ whitheldIssTax | number:'1.2-2'}}">
                </div>
                <div class="form-control half-size">
                    <label>Total à pagar</label>
                    <input pInputText type="text" class="text-right" readonly min="0" max="500000" autocomplete="off"
                        name="totalValue" value="{{ getTotalValue() | number:'1.2-2'}}">
                </div>
                <div class="form-control">
                    <label>Total Pago (c/ juros)</label>
                    <p-inputNumber mode="decimal" name="totalPaid" [(ngModel)]="invoice.paidValue"
                        tooltipPosition="bottom" class="correct"
                        [pTooltip]="validValue(invoice) ? '' : 'Total pago deve ser maior que juros/multa'"
                        [ngClass]="{'danger': !validValue(invoice)}" [minFractionDigits]="2" [maxFractionDigits]="2">
                    </p-inputNumber>
                </div>
                <div class="form-control half-size">
                    <button class="easymarine success" type="button" (click)="setPaidValue()">Pagar total</button>
                </div>
            </div>
            <div class="form-row">
                <div class="form-control">
                    <div class="receiving">
                        <label>{{ 'PAYMENT-METHODS' | translate }}</label>
                        <div style="float: right; display: flex; align-items: center; justify-content: flex-start;"
                            title='Exibe apenas contas de recebimento'>
                            <label style="font-size: 16px; margin: 0 7px 0 0;">Somente recebimento</label>
                            <p-checkbox [(ngModel)]="onlyReceivingAccounts" [binary]="true"
                                (onChange)="resetOptions($event)" name="receivingAccounts">
                            </p-checkbox>
                            <div style="float: right;" title="Configurar Contas de Recebimento">
                                <button class="config" (click)="openConfigDialog()" type="button">
                                    <i class="pi pi-cog"
                                        style="font-size: 24px; color: darkgrey; display: block; padding: 2px 4px;"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <p-dropdown #dd placeholder="Selecione..." [options]="paymentMethodsOptions" appendTo="body"
                        [(ngModel)]="invoice.paymentMethod" name="paymentMethodId"
                        (onChange)="loadAcountList(invoice.paymentMethod.contas)">
                    </p-dropdown>

                </div>
                <div class="form-control">
                    <label>{{ 'ACCOUNT' | translate }}</label>
                    <p-dropdown placeholder="Selecione..." [options]="accountListOptions" [(ngModel)]="invoice.account"
                        appendTo="body" name="chartAccountId">
                    </p-dropdown>
                </div>
            </div>
        </div>
        <div class="buttons">
            <button [disabled]="!invoice.account || (!validValue(invoice)) || !invoice.paymentDate"
                class="full-width easymarine primary" type="submit">Baixar Fatura</button>
        </div>
        <div class="row" *ngIf="hasBankingBillet()">
            <div class="col-12 text-center alert alert-danger">
                Atenção: Para esta fatura foi gerado boleto, favor dar baixa no sistema do banco!
            </div>
        </div>
    </form>
</div>
