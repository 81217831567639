import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { NavigationPlan, Sling } from 'src/app/models';
import { SlingStatus } from 'src/app/models/enums';
import { SlingService } from 'src/app/services/sling.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { NavigationPlanFormComponent } from './navigation-plan-form/navigation-plan-form.component';

@Component({
  selector: 'app-totem-navigation-plan',
  templateUrl: './totem-navigation-plan.component.html',
  styleUrls: ['./totem-navigation-plan.component.scss']
})
export class TotemNavigationPlanComponent implements OnInit, OnDestroy {

  slings: Sling[];
  filter = {
    slingOrigin: null,
    boatStatus: null,
    slingStatus: [SlingStatus.PENDING_DOWN, SlingStatus.WATER, SlingStatus.PENDING_UP],
    idBoat: null,
    idCustomer: null,
    finishedOnWater: false
  };
  interval: any;
  constructor(
    private dialog: DialogService,
    private slingService: SlingService,
    private messageUtil: MessageUtil
  ) { }

  ngOnInit(): void {
    this.findSlings();
    setInterval(() => {
      this.findSlings();
    }, 30000);
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  findSlings(): void {
    this.slingService.findSlingByFilter(this.filter).subscribe(
      (data) => {
        this.slings = data;
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }

  onClick(sling): void {
    this.openDialogNavigationPlan(sling);
  }

  openDialogNavigationPlan(slingParam): void {
    this.dialog.open(NavigationPlanFormComponent, {
      height: '400px',
      data: {
        sling: slingParam,
        returnDate: new Date(slingParam.navigationPlan.returnTime)
      },
      header: this.messageUtil.translateKey('NAVIGATION-PLAN')
    }).onClose.subscribe(() => this.findSlings());
  }

  isNavigationPlanComplete(navigationPlan: NavigationPlan): any {
    return (navigationPlan.returnTime && navigationPlan.crewQty && navigationPlan.destination);
  }
}
