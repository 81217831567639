import { FaqService } from './../../../../../services/faq.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-personal-help',
  templateUrl: './personal-help.component.html',
  styleUrls: ['./personal-help.component.scss']
})
export class PersonalHelpComponent implements OnInit {

  @Output()
  personalEvent: EventEmitter<any> = new EventEmitter();

  @Input()
  tela:string;



  constructor(public dialogRef: DynamicDialogRef,
              public faqService: FaqService) { }

  links : any [] =[];
  ngOnInit(): void {
    //console.log(this.tela);
    this.links = this.faqService.getLinks().filter(x => x.tela == this.tela);
    //console.log(this.links);
  }
  close(): void {
    this.dialogRef.close();
  }

  proximo(): void {
    this.personalEvent.emit(1);
  }

}
