import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import b64toBlob from 'b64-to-blob';
import dayjs from 'dayjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { DialogService } from 'primeng/dynamicdialog';
import { CustomReportExportComponent } from 'src/app/components/extract-custom-report/custom-report-export.component';
import { ExtractInvoiceComponent } from 'src/app/components/extract-invoice/extract-invoice.component';
import { Product } from 'src/app/models';
import { ServiceHistoricFilter } from 'src/app/models/dtos/marinaorder/serviceHistoricFilter';
import { ProductType } from 'src/app/models/enums';
import { ProductMovement } from 'src/app/models/product-movement';
import { ProductMovementFilter } from 'src/app/models/product-movement-filter';
import { OperationalConfigService } from 'src/app/services/config/operational-config.service';
import { ProductService } from 'src/app/services/product.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';
import {ServiceHistoricDTO} from "../../../models/dtos/service-historic-productDTO";

@Component({
  selector: 'app-service-history',
  templateUrl: './service-history.component.html',
  styleUrls: ['./service-history.component.scss']
})
export class ServiceHistoryComponent implements OnInit {

  movementList: ServiceHistoricDTO[] = [];
  productList: Product[] = [];
  filter: ServiceHistoricFilter = new ServiceHistoricFilter();
  isDialog = false;
  numberOfRows = 10;
  dateRange: Date[];
  nfeProductEnable: boolean = true;
  typesList = [
    {value: "Service", label : "Serviço"},
    {value: "PeriodicalService", label : "Serviço Periódico"},
    {value: "CustomerService", label : "Serviço para cliente"}
  ];
  typeFilter: string = null;

  totalValue: number = 0;

  pt = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  };

  constructor(
    private productService: ProductService,
    private spinner: NgxSpinnerService,
    private dialog: DialogService,
    private messageUtil: MessageUtil,
    private sanitizer: DomSanitizer,
    private slingConfigService: SlingConfigService,
    private readonly operationalConfigService: OperationalConfigService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getConfig();
    this.filter.marinaId = StorageUtil.getMarinaId();

    this.filter.productType.push("Service");
    this.filter.productType.push("PeriodicalService");
    this.filter.productType.push("CustomerService");
    this.dateRange = [dayjs().subtract(1, 'month').toDate(), dayjs().toDate()];

    this.slingConfigService.getSlingConfig().subscribe({
      next: (res) => {
        this.numberOfRows = res[0].numberOfRecordsPerPage;
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {}
    });

    this.find();
    this.findProduct();
  }

  getConfig(){
    this.operationalConfigService.hasNFProduct().then((res) => {
      this.nfeProductEnable = res;
    });
  }

  find(): void {
    this.filter.endDate = this.dateRange[1];
    this.filter.startDate = this.dateRange[0];

    this.productService.getProductHistoric(this.filter).subscribe(
      (data) => {
        this.movementList = data;
        this.calculateTotal();
      },
      (error) => {
        console.error(error);
      }
    );
  }

  calculateTotal(){
    this.totalValue = 0;
    this.movementList.map((item) => {
      if(item?.totalValue){
        this.totalValue += item?.totalValue;
      }
    })
  }

  onChangeType(event: DropdownChangeEvent){
    event.value ? this.typeFilter = event.value : this.typeFilter = null;
  }

  findProduct(): void {
    this.spinner.show();
    this.productService.getAll().subscribe({
      next: (data) => {
        this.productList = [{id: null, name: "Todos"},...data.filter((i) => i.productType!==ProductType.Product)];
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
      },
      complete: () => {
        this.spinner.hide();
      }
    });
  }

  formatDate(date: Date){
    return dayjs(date).format('DD/MM/YYYY HH:mm');
  }

  getAbs(value: number){
    return Math.abs(value);
  }

  exportTable() {
    const type = this.nfeProductEnable ? 'SERVICE_HISTORIC_NFE' : 'SERVICE_HISTORIC';
    const data = { table: this.movementList, type };
    this.dialog.open(CustomReportExportComponent, {
      width: '70%',
      height: '90vh',
      dismissableMask: false,
      data,
      header: this.messageUtil.translateKey('EXPORT-PRODUCT-MOVEMENT-TABLE'),
    });
  }

  exportPdf(){
    this.productService.exportProductHistoryPDF(this.dateRange[0], this.dateRange[1]).subscribe({
      next: (res) => {
        const path = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL((b64toBlob as any)(res.data.products, 'application/pdf')));
        this.openExtractDialog(path);
      },
      error: (error) => {

      },
      complete: () => {}
    });
  }

  openExtractDialog(path){
    this.dialog.open(ExtractInvoiceComponent, {
      width: '100%',
      height: '100%',
      data: {
        path
      },
      header: this.messageUtil.translateKey('EXPORT-PRODUCT-MOVEMENT-TABLE'),
    })
  }

  openLog(orderNumber: number){
    this.router.navigate([`logs/order-log/?orderNumber=${orderNumber}`]);
  }

  nfeHandler(serviceHistoricFilter: any) {
    const nfeProduct = serviceHistoricFilter?.nfeNumber;
    return nfeProduct ? nfeProduct : '-';
  }
}


