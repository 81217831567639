import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { BroadcastService } from '../services/broadcast.service';

@Directive({
  selector: '[appFilters]'
})
export class FiltersDirective implements OnInit {

  innerWidth: number;
  originalDisplay: string;
  showFilters = false;
  minWidth = 770;
  @HostListener('window:resize', ['$event']) onScroll(): void {
    this.innerWidth = window.innerWidth;
    this.showOrHide();
  }

  constructor(
    private elRef: ElementRef
  ) {
  }

  ngOnInit(): void {
    this.originalDisplay = this.elRef.nativeElement.style.display;
    this.innerWidth = window.innerWidth;
    this.showOrHide();
    BroadcastService.get('showFilters').subscribe((showFilters) => {
      this.showFilters = showFilters;
      this.showOrHide();
    });
  }

  showOrHide(): void {
    if (this.innerWidth <= this.minWidth && !this.showFilters) {
      this.elRef.nativeElement.className = 'filters';
    } else if (this.showFilters) {
      this.elRef.nativeElement.className = 'filters active';
    } else {
      this.elRef.nativeElement.className = 'filters';
    }
  }
}
