import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APPCONFIG } from '../config';

export interface IRequestOptions {
    headers?: HttpHeaders;
    observe?: 'body';
    params?: HttpParams;
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
    body?: any;
}

export function easyMarineHttpApiLogCreator(http: HttpClient): EasyMarineHttpApiLog {
    // tslint:disable-next-line: no-use-before-declare
    return new EasyMarineHttpApiLog(http);
}

@Injectable()
export class EasyMarineHttpApiLog {

    // Extending the HttpClient through the Angular DI.
    public constructor(private http: HttpClient) { }

    /**
     * GET request
     * @param endPoint it doesn't need / in front of the end point
     * @param options options of the request like headers, body, etc.
     * @returns Observable<T>
     */
    public get<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
        return this.http.get<T>(endPoint, this.createRequestOptions(options));
    }

    /**
     * POST request
     * @param endPoint end point of the api
     * @param params body of the request.
     * @param options options of the request like headers, body, etc.
     * @returns Observable<T>
     */
    public post<T>(endPoint: string, params: any, options?: IRequestOptions): Observable<T> {
        return this.http.post<T>(endPoint, params, this.createRequestOptions(options));
    }

    /**
     * PUT request
     * @param endPoint end point of the api
     * @param params body of the request.
     * @param options options of the request like headers, body, etc.
     * @returns Observable<T>
     */
    public put<T>(endPoint: string, params: any, options?: IRequestOptions): Observable<T> {
        return this.http.put<T>(endPoint, params, this.createRequestOptions(options));
    }

    /**
     * DELETE request
     * @param endPoint end point of the api
     * @param options options of the request like headers, body, etc.
     * @returns Observable<T>
     */
    public delete<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
        return this.http.delete<T>(APPCONFIG.apiLog + endPoint, this.createRequestOptions(options));
    }

    private createRequestOptions(options: IRequestOptions): IRequestOptions {
        if (options === undefined) {
            options = {};
        }
        const httpOptions = new HttpHeaders({
            Authorization: 'Basic ' + 'ZWFzeV93ZWI6YXBpX2Vhc3lfbW9uZ29fZG9ja2Vy',
            'Access-Control-Allow-Origin': '*'
        });
        options.headers = httpOptions;
        return options;
    }
}
