import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clean',
  templateUrl: './clean.component.html',
  styleUrls: ['./clean.component.scss']
})
export class CleanComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
