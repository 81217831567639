<form [formGroup]="form" (submit)="create()"  class="dialog-form">
  <div class="form-group">
    <div class="form-control">
      <label for="name">Nome da fila</label>
      <input pInputText type="text" inputId="name" formControlName="name">
    </div>
  </div>
  <div class="buttons">
    <button class="easymarine primary" type="submit" [disabled]="!form.valid">{{'SAVE'| translate}}</button>
  </div>
</form>
