import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { InvoiceEmailDownloadDTO } from '../../models/dtos/email-download/invoice-email-download-DTO';
import { readSpanComment } from '@angular/compiler-cli/src/ngtsc/typecheck/src/comments';
import { PaginationFilter } from '../../models/pagination-filter';

@Injectable({
  providedIn: 'root'
})
export class EmailDownloadService {

  constructor(
    private http: HttpClient
  ) {
  }

  getInvoiceForEmailDownload(marinaId: number, invoiceId: number, federalType: string, federalId: string): Observable<InvoiceEmailDownloadDTO[]> {
    return this.http
      .get(`${environment.apiHost}/email/user-access/${marinaId}/${invoiceId}/${federalType}/${federalId}`)
      .pipe(map((resp: any) => resp.data.emailDownload));
  }

  getPaidInvoicesForEmailDownload(customerId: number, pageable: PaginationFilter): Observable<any> {
    let params = new HttpParams();

    params = params.set('page', pageable.page.toString());
    params = params.set('size', pageable.size.toString());

    return this.http.get(`${environment.apiHost}/email/user-access/find-paid-invoice/${customerId}`, { params }).pipe(map((resp: any) => resp.data.paidInvoices));
  }

  getInvoicePdf(marinaId: number, invoiceId: number) {
    return this.http.get(`${environment.apiHost}/email/user-access/download-invoice/${marinaId}/${invoiceId}`).pipe(map((resp: any) => resp.data.pdf));
  }
}
