import { FuelTankFraction } from './enums';
import { Product } from './product';
import { MarinaOrder } from './marina-order';
import { MarinaCompany } from './marina-company';
import { InvoicePaid } from './invoice-paid';
import { EmailLog } from './email-log';
import { Customer } from './customer';
import { InvoiceBankingBillet } from './invoice-banking-billet';

export class Invoice {
    id: number;
    qtyRequested: number;
    qtySupplied: number;
    itemValue: number;
    itemStatus: boolean;
    itemObs: string;
    fuelTankFraction: FuelTankFraction;
    marinaOrder: MarinaOrder;
    product: Product;
    qtyStock: number;
    marinaCompany: MarinaCompany;
    invoiceNumber: string;
    legacyInvoiceNumber: string;
    origin: string;
    accountId: number;
    paymentMethodId: number;
    invoicePaids: InvoicePaid[];
    emailsLog: EmailLog[];
    value: number;
    customer: Customer;
    dueDate: string;
    pendingValue: number;
    invoiceBankingBillets: InvoiceBankingBillet[];
    dueDateToPay: Date;
    competenceDate: Date;
    activeBillet?: InvoiceBankingBillet;
    totalPaid?: number;
    nfeNumberProduct?:number;
    nfcNumber?:number;
    issuedate?: any;
    invoiceFatherId?: number;
    constructor() {
        this.marinaOrder = new MarinaOrder();
        this.product = new Product();
    }
}
