import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { SuperInvoicingDrawer, DrawerHeaderGroup } from "../models/dtos/drawer-header-group";
import { environment } from "src/environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map } from "rxjs";
import { DrawerHeaderItem } from "../models/dtos/drawer-header-item";

@Injectable({
  providedIn:'root'
})
export class DrawerService {
 
  private BASE_TABLE: DrawerHeaderGroup[] = SuperInvoicingDrawer;

  constructor(private http: HttpClient) {
  }

  //TODO: No refacture de segundo uso, criar enum e remover mais dados do banco.
  getSuperDrawerPersisted(type: string) {
    let params = new HttpParams();
    params = params.append('type', type);
    return this.http.get(environment.apiHost + '/secure/super-drawer/', { params }).pipe(map((response: any) => response));
  }

  persistSuperDrawerItems(customItems: DrawerHeaderItem[]): Observable<any>{
    return this.http.post(environment.apiHost + '/secure/super-drawer/save', customItems).pipe(map((resp: any) => resp));
  }

  getSuperDrawerItems(): Promise<DrawerHeaderGroup[]> {
    return new Promise<DrawerHeaderGroup[]>((resolve, reject) => {
      this.getSuperDrawerPersisted('SUPER_INVOICING').subscribe({
        next: async (resp) => {  
          if (resp.data?.superDrawerItems != null && resp.data?.superDrawerItems.length > 0) {
            let drawerItems = resp.data.superDrawerItems
            this.processSuperDrawerItems(drawerItems, true).then((resp) => {
              resolve(this.BASE_TABLE);
            });
          } else {
            this.processSuperDrawerItems([], false).then((resp) => {
            resolve(this.BASE_TABLE);
            });
          }
        },
        error: (e) => {
          console.error(e);
          reject(e);
        }
      });
    });
  }
  
  setDrawer(drawer: DrawerHeaderGroup[]): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let baseTableItems = [];
      this.BASE_TABLE = drawer;
      this.BASE_TABLE.forEach((output: DrawerHeaderGroup) => {
        baseTableItems.push(...output.items);
      });
  
      this.persistSuperDrawerItems(baseTableItems).subscribe(
        (resp: any) => {
          resolve(resp);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  processSuperDrawerItems(drawerItems: DrawerHeaderItem[], isPersisted: boolean) {
    if (isPersisted) {
      this.BASE_TABLE.forEach((output) => {
        output.items = [];
        drawerItems.forEach((item) => {
          if (output.label == item.parent) {
            output.items.push(item);
          }
        });
        this.setDrawerHeaderGroupActive(output);
      });
    } else { 
      this.BASE_TABLE = SuperInvoicingDrawer;     
      this.BASE_TABLE.forEach((output) => {
        this.setDrawerHeaderGroupActive(output);
      });
    }
    return Promise.resolve(true);
  }
  
  setDrawerHeaderGroupActive(output: DrawerHeaderGroup) {
    output.activeItems = 0;
      output.items.forEach((item) => {
        if (item.toggle) output.activeItems++;         
      });
  }
}
