import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FinancesService } from 'src/app/services/finances.service';
import { ProductLogService } from 'src/app/services/product-log.service';
import { ProductService } from 'src/app/services/product.service';
import { UserService } from 'src/app/services/user.service';
import { StorageUtil } from 'src/app/utils/storage.util';

@Component({
  selector: 'app-product-log',
  templateUrl: './product-log.component.html',
  styleUrls: ['./product-log.component.scss']
})
export class ProductLogComponent implements OnInit {

  productLogs = [];
  filteredUsers = [];
  productsFilter = [];
  costCenters = [];
  filter = { marinaId: 0, dateStart: new Date(), dateEnd: new Date(), userId: null, targetId: null };

  pt = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  };

  constructor(
    private productLogService: ProductLogService,
    private userService: UserService,
    private productService: ProductService,
    private spinner: NgxSpinnerService,
    public financesService: FinancesService,
  ) { }

  async ngOnInit(): Promise<void> {
    const marina = StorageUtil.getMarina();
    this.filter.marinaId = marina.id;
    this.loadUsers();
    this.loadProducts();
    this.spinner.show();
    this.loadCostCenter();
    await this.find();
    this.spinner.hide();
  }

  async find(): Promise<void> {
    return new Promise<void>(async resolve => {
      this.productLogService.findProducts(this.filter).subscribe(
        async (data) => {
          this.productLogs = data;
          resolve();
        },
        async (error) => {
          console.error(error);
          resolve();
        }
      );
    });
  }

  private loadUsers(): void {
    this.userService.getMarinaUsers().subscribe(
      (data) => {
        this.filteredUsers = data.map((c) => ({ label: c.firstName.concat(' ').concat(c.lastName), value: c.id }));
        this.filteredUsers.unshift({ label: 'Todos', value: null });
      }
    );
  }

  loadProducts(): void {
    this.productService.getProductFilterList().subscribe(
      (data) => {
        this.productsFilter = data.map((d) => ({ label: d.name, value: d.id }));
        this.productsFilter.unshift({ label: 'Todos', value: null });
      },
      (error) => {
        this.productsFilter = null;
      }
    );
  }

  loadCostCenter(): void {
    this.spinner.show();
    this.financesService.findCentroCusto().subscribe(data => {
      this.costCenters = data;
      this.spinner.hide();
    },
    (error) => {
      this.costCenters = null;
      this.spinner.hide();
    })
  }
}
