import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DashboardPieModel } from '../models/dashboardPieModel';
import { StockReport } from '../models/stockReport';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class DashboardService {

    constructor(private http: HttpClient) { }

    customerByAppAndWeb(): Observable<DashboardPieModel[]> {
        return this.http.get(
            environment.apiHost + '/secure/dashboard/customerByAppAndWeb/'
        ).pipe(map((response: any) => response.data.dashboard as DashboardPieModel[]));
    }

    customerType(): Observable<DashboardPieModel[]> {
        return this.http.get(
            environment.apiHost + '/secure/dashboard/customerType/'
        ).pipe(map((response: any) => response.data.dashboard as DashboardPieModel[]));
    }

    documentCondition(): Observable<DashboardPieModel[]> {
        return this.http.get(
            environment.apiHost + '/secure/dashboard/documentCondition/'
        ).pipe(map((response: any) => response.data.dashboard as DashboardPieModel[]));
    }

    slingOrigin(): Observable<DashboardPieModel[]> {
        return this.http.get(
            environment.apiHost + '/secure/dashboard/slingOrigin/'
        ).pipe(map((response: any) => response.data.dashboard as DashboardPieModel[]));
    }

    slingQuantity(): Observable<DashboardPieModel[]> {
        return this.http.get(
            environment.apiHost + '/secure/dashboard/slingQuantity/'
        ).pipe(map((response: any) => response.data.dashboard as DashboardPieModel[]));
    }

    slingStatusToday(): Observable<DashboardPieModel[]> {
        return this.http.get(
            environment.apiHost + '/secure/dashboard/slingStatusToday/'
        ).pipe(map((response: any) => response.data.dashboard as DashboardPieModel[]));
    }

    stockReport(): Observable<StockReport[]> {
        return this.http.get(
            environment.apiHost + '/secure/dashboard/stockReport/'
        ).pipe(map((response: any) => response.data.dashboard as StockReport[]));
    }

    qtyBoatMaintenance(): Observable<string[]> {
        return this.http.get(
            environment.apiHost + '/secure/dashboard/qtyBoatMaintenance/'
        ).pipe(map((response: any) => response.data.dashboard as string[]));
    }

    boatInsurance(): Observable<DashboardPieModel[]> {
        return this.http.get(
            environment.apiHost + '/secure/dashboard/boat-insurance/'
        ).pipe(map((response: any) => response.data.dashboard as DashboardPieModel[]));
    }

    insuranceCondition(): Observable<DashboardPieModel[]> {
        return this.http.get(
            environment.apiHost + '/secure/dashboard/insurance-condition/'
        ).pipe(map((response: any) => response.data.dashboard as DashboardPieModel[]));
    }

    licenceCondition(): Observable<DashboardPieModel[]> {
        return this.http.get(
            environment.apiHost + '/secure/dashboard/licenceCondition/'
        ).pipe(map((response: any) => response.data.dashboard as DashboardPieModel[]));
    }

}
