import { Component, OnInit } from '@angular/core';
import { MarinaOrderService } from 'src/app/services/marina-order.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { FormatUtil } from 'src/app/utils/format.util';
import { MessageUtil } from 'src/app/utils/message.util';
import moment from 'moment';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-daily-debits',
  templateUrl: './daily-debits.component.html',
  styleUrls: ['./daily-debits.component.scss']
})
export class DailyDebitsComponent implements OnInit {

  numberOfRows = 10;
  filter = { requestDate: new Date() };
  orders: any[] = [];
  ordersTable: any[] = [];
  validDate = true;
  globalFilter = '';

  pt = {
    firstDayOfWeek: 0,
    dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
    monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho',
      'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Limpar'
  };

  constructor(
    private messageService: MessageUtil,
    private orderService: MarinaOrderService,
    private slingConfigService: SlingConfigService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.loadSlingConfig();
  }

  filterGlobal(): void {
    this.ordersTable = this.orders.filter(
      (o) =>
        (FormatUtil.getNotAccents(o.boatName).toUpperCase()
          .includes(FormatUtil.getNotAccents(this.globalFilter).toUpperCase()) ||
          o.boatName.toUpperCase().includes(this.globalFilter.toUpperCase())
        ) ||
        (FormatUtil.getNotAccents(o.nameCustomer).toUpperCase()
          .includes(FormatUtil.getNotAccents(this.globalFilter).toUpperCase()) ||
          o.nameCustomer.toUpperCase().includes(this.globalFilter.toUpperCase())
        )
    );
  }

  loadSlingConfig(): void {
    this.slingConfigService.getSlingConfig().subscribe(
      (data) => {
        const slingConfig = data[0];
        this.numberOfRows = slingConfig != null ? slingConfig.numberOfRecordsPerPage : 10;
        this.find();
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        return null;
      });
  }

  find(): void {
    this.spinner.show();
    this.orderService.getDebitsHasSlingToDate(moment(this.filter.requestDate).toDate().getTime()).subscribe(
      (data) => {
        this.modifyListDateOrders(data);
        this.filterGlobal();
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title, exception.message);
      });
  }

  modifyListDateOrders(ordersParam: any[]): void {
    this.orders = [];
    for (const order of ordersParam){
      for (const customer of order.customers){
        if (customer.statusInvoice !== 'PAID') {
          const customerOrderExist = this.orders.find((d) => d.idCustomer === customer.id);
          if (customerOrderExist !== undefined) {
            customerOrderExist.totalCustomer += customer.value;
            if (!customerOrderExist.boatName.includes(order.boatName)) {
              customerOrderExist.boatName += ', ' + order.boatName;
            }
          } else {
            customer.total = customer.value;
            const customerOrder = {
              idCustomer: customer.id,
              boatName: order.boatName,
              nameCustomer: customer.name,
              totalCustomer: customer.value
            };
            this.orders.push(customerOrder);
          }
        }
      }
    }
    this.ordersTable = this.orders;
  }
}
