import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { ProductLogModalComponent } from '../product-log-modal/product-log-modal.component';

@Component({
  selector: 'app-product-log-table',
  templateUrl: './product-log-table.component.html',
  styleUrls: ['./product-log-table.component.scss']
})
export class ProductLogTableComponent implements OnInit {

  @Input()
  productLogs = [];

  @Input()
  isDialog = false;

  @Input()
  costCenters = [];

  numberOfRows = 10;

  constructor(
    private slingConfigService: SlingConfigService,
    public dialog: DialogService,
  ) { }

  ngOnInit(): void {
    if ( !this.isDialog) {
      this.loadSlingConfig();
    }
  }

  loadSlingConfig(): void {
    this.slingConfigService.getSlingConfig().subscribe(
      (data) => {
        const slingConfig = data[0];
        if (slingConfig != null) {
          this.numberOfRows = slingConfig.numberOfRecordsPerPage;
        } else {
          this.numberOfRows = 10;
        }
      },
      (error) => null);
  }

  openProductLogDialog(productLog): void {
    this.dialog.open(ProductLogModalComponent, {
      height: '85vh',
      width: '80%',
      dismissableMask: false,
      data: {
        productLog
      },
      header: 'Log de Produtos'
    })
  }

  getCostCenterById(costCenter) {
    const costCenterById = this.costCenters.find(idCentro => idCentro.idCentroCusto === costCenter);
    return !costCenterById ? '' : costCenterById.name
  }
}
