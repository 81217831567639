import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { FranchiseGroupService } from 'src/app/services/franchise-group.service';
import { FranchiseGroupFormComponent } from './franchise-group-form/franchise-group-form.component';
import { ToastService } from 'src/app/services/toast.service';
import { FormatUtil } from 'src/app/utils/format.util';
import { MessageUtil } from 'src/app/utils/message.util';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-franchise-group',
  templateUrl: './franchise-group.component.html',
  styleUrls: ['./franchise-group.component.scss']
})
export class FranchiseGroupComponent {
  numberOfRows: number;
  groups: any[] = [];
  groupsTable: any[] = [];
  globalFilter = '';

  constructor(
    private dialog: DialogService,
    private toasty: ToastService,
    private spinner: NgxSpinnerService,
    private franchiseGroupService: FranchiseGroupService,
  ) { }


  async ngOnInit(): Promise<void> {
    this.findAll();
  }

  filterGlobal(): void {
    this.groupsTable = this.groups.filter(
      (c) =>
        (FormatUtil.getNotAccents(c.name).toUpperCase()
          .includes(FormatUtil.getNotAccents(this.globalFilter).toUpperCase()) ||
          c.name.toUpperCase().includes(this.globalFilter.toUpperCase())
        )
    );
  }

  async openDialog(group): Promise<void> {
    const data = {
      group: (group || null),
    };
    const dialogRef = this.dialog.open(FranchiseGroupFormComponent, {
      width: '52%',
      dismissableMask: false,
      data,
      header: 'Novo grupo de franquia',
    }).onClose.subscribe(() => { this.findAll(); });
  }

  edit(group): void {
    this.openDialog(group);
  }

  deleteConfirm(group): void {
    Swal.fire({
      text: 'Deseja realmente deletar o grupo ' + group.name + '?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((isConfirm) => {
      if (isConfirm.value) {
        this.delete(group.id);
      }
    });
  }

  delete(groupId): void {
    this.spinner.show();
    this.franchiseGroupService.delete(groupId).subscribe({
      next: () => {
        this.toasty.success('Grupo deletado com sucesso');
        this.findAll();
      },
      error: (error) => {
        console.log(error);
        this.spinner.hide();
        this.toasty.error('Erro ao deletar grupo de franquia');
      },
      complete: () => this.spinner.hide()
    });

  }

  findAll() {
    this.spinner.show();
    this.franchiseGroupService.findAll().subscribe(
      (response) => {
        this.groups = response;
        this.groupsTable = this.groups;
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toasty.error('Erro ao buscar os grupos de franquia');
      }
    );
  }

  changeGroupStatus(event): void {
    switch(event?.index) {
      case 1:
        this.groupsTable = this.groups.filter(c => c.active);
        break;
      case 2:
        this.groupsTable = this.groups.filter(c => !c.active);
        break;
      default:
        this.groupsTable = this.groups;
    }

  }

  openBatchScreen() {

  }
}
