import { Component, Input, Output, EventEmitter } from "@angular/core";
import { MenuItem } from 'primeng/api';
import { Object } from 'fabric/fabric-impl';

@Component({
  selector: "berth-operation-menu",
  templateUrl: "./berth-operation-menu.component.html",
  styleUrls: ["./berth-operation-menu.component.scss"],
})
export class BerthOperationMenuComponent {

  @Input()
  fabricObj: Object;
  aCoords: any;

  pixel_scale_m: number = 0.27777;

  @Output()
  onContextMenuItemClick: EventEmitter<any> = new EventEmitter<any>();

  onContextMenuClick(data): any {
    this.onContextMenuItemClick.emit({
      event, data
    });
  }

  items: MenuItem[];
  ngOnInit() {
    this.aCoords = JSON.stringify(this.fabricObj.aCoords);
    this.items = [{
      label: 'Operação',
      items: [
        {
          label: 'Alocar Embarcação',
          icon: 'pi pi-sign-in',
          command: () => {
            this.onContextMenuClick({ 'action': 'set-boat', 'fabricObj': this.fabricObj });
          },
          disabled: this.fabricObj['esy.boatId']
        },          
        {
          label: 'Desalocar Embarcação',
          icon: 'pi pi-sign-in',
          command: () => {
            this.onContextMenuClick({ 'action': 'unset-boat', 'fabricObj': this.fabricObj });
          },
          disabled: !this.fabricObj['esy.boatId']
        },        
        /* 
        {
          label: 'Atracar Embarcação',
          icon: 'pi pi-sign-in',
          command: () => {
            this.onContextMenuClick({ 'action': 'moored', 'fabricObj': this.fabricObj });
          }
        },
        {
          label: 'Apontar Navegação',
          icon: 'pi pi-sign-out',
          command: () => {
            this.onContextMenuClick({ 'action': 'navigation', 'fabricObj': this.fabricObj });
          }
        },
        {
          label: 'Apontar Retorno',
          icon: 'pi pi-refresh',
          command: () => {
            this.onContextMenuClick({ 'action': 'nav-return', 'fabricObj': this.fabricObj });
          }
        }, */
        {
          label: 'info',
          icon: 'pi pi-info-circle',
          command: () => {
            this.onContextMenuClick({ 'action': 'info', 'fabricObj': this.fabricObj });
          }
        },         

      ]
    }
    ];
  }


}
