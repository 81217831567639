<div class="dialog-form">
    <div class="body">
        <form (submit)="validateForm(f) && save()" #f="ngForm">
            <div class="form-group">
                <div class="form-control">
                    <label>{{ 'RETURN-DATE' | translate }}</label>
                    <p-calendar dateFormat="dd/mm/yy" name="returnDate" rPCalendarMask [minDate]="returnDate"
                        appendTo="body" [(ngModel)]="returnDate" [showIcon]="true" i18n
                        (onSelect)="getAvailablePeriods()" required>
                    </p-calendar>
                </div>
                <div class="form-control">
                    <label>{{ 'RETURN-TIME' | translate }}</label>
                    <p-dropdown placeholder="Selecione..." [filter]="false" [options]="availablePeriods" appendTo="body"
                        [(ngModel)]="sling.navigationPlan.returnTime" required name="sling.navigationPlan.returnTime">
                        <ng-template let-availablePeriodSlingUp pTemplate="item">
                            <div [ngStyle]="{'padding': isToten && '5px 0px'}">{{availablePeriodSlingUp.label}}</div>
                        </ng-template>
                    </p-dropdown>
                </div>
                <div class="form-control">
                    <label>{{ 'PEOPLE-BORD' | translate }}</label>
                    <p-dropdown placeholder="Selecione..." [filter]="false" [options]="passengersNumber" appendTo="body"
                        [(ngModel)]="sling.navigationPlan.crewQty" required name="sling.navigationPlan.crewQty">
                    </p-dropdown>
                </div>
                <div class="form-control">
                    <label>{{ 'DESTINATION' | translate }}</label>
                    <input type="text" pInputText name="destination" required
                        [(ngModel)]="sling.navigationPlan.destination">
                </div>
            </div>
            <div class="buttons">
                <button class="easymarine danger" (click)="dialogRef.close()" type="button">{{ 'CANCEL' | translate
                    }}</button>
                <button class="easymarine primary" type="submit">{{ 'SAVE' | translate }}</button>
            </div>
        </form>
    </div>
</div>