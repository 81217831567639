import { SirenAlert } from './siren-alert';

export class SirenAlertConfig {

    id: number;
    newSlingMarina = false;
    newSlingCustomer = false;
    newSlingTotem = false;
    newSlingGestor = false;
    upSlingCustomer = false;
    cancelSling = false;
    sirenAlertNewSlingCustomer: SirenAlert;
    sirenAlertNewSlingMarina: SirenAlert;
    sirenAlertNewSlingTotem: SirenAlert;
    sirenAlertNewSlingGestor: SirenAlert;
    sirenAlertUpSlingCustomer: SirenAlert;
    sirenAlertCancelSling: SirenAlert;
    volumeNewSlingMarina: number;
    volumeNewSlingCustomer: number;
    volumeNewSlingTotem: number;
    volumeNewSlingGestor: number;
    volumeUpSlingCustomer: number;
    volumeCancelSling: number;

    constructor() {
        this.sirenAlertNewSlingCustomer = new SirenAlert();
        this.sirenAlertNewSlingTotem = new SirenAlert();
        this.sirenAlertNewSlingGestor = new SirenAlert();
        this.sirenAlertUpSlingCustomer = new SirenAlert();
        this.sirenAlertCancelSling = new SirenAlert();
    }
}
