import { BoatService } from 'src/app/services/boat.service';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { MenuItem, SelectItem } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Boat, Marina, SlingConfig } from 'src/app/models';
import { PaginationFilter } from 'src/app/models/pagination-filter';
import { OperationalConfigService } from 'src/app/services/config/operational-config.service';
import { CustomerService } from 'src/app/services/customer.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';
import { FranchiseServiceService } from 'src/app/services/franchise/franchise-service.service';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import { OrderReferenceDateUtil } from 'src/app/utils/order-reference-date.util';
import { FranchiseHistoryComponent } from './franchise-history/franchise-history.component';
import { FranchiseFilterSimple } from 'src/app/models/dtos/franchise/franchise-filter-simple';
import { FranchiseEditqtymodalComponent } from './franchise-editqtymodal/franchise-editqtymodal.component';
import { InvoiceCustomerDTO } from 'src/app/models/dtos/invoice-customerDTO';
import { Franchise } from 'src/app/models/dtos/Franchise';
import { FranchiseGroupService } from 'src/app/services/franchise-group.service';
import { ProductService } from 'src/app/services/product.service';
import { ProductType } from 'src/app/models/enums';
import { CustomReportExportComponent } from 'src/app/components/extract-custom-report/custom-report-export.component';


@Component({
  templateUrl: './franchise-controller.component.html',
  styleUrls: ['./franchise-controller.component.scss'],
})
export class FranchiseControllerComponent implements OnInit {
  loggedMarina: Marina = new Marina();
  contentLoaded = true;
  totalRecords = 0;
  totalDocuments: any;
  refresh = false;
  franchiseList: Franchise[] = [];
  franchise: Franchise;
  selectedFranchiseList: any;
  paginationFilter: PaginationFilter = new PaginationFilter();
  customers: any[] = [];
  filteredCustomers: any[] = [];
  isSling: boolean = false;
  isMovement: boolean = false;
  slingconfig: SlingConfig = new SlingConfig();
  orderNumberFilter: string = '';
  rangeCadastro: Date[];
  filter: FranchiseFilterSimple = new FranchiseFilterSimple();
  filteredBoats: SelectItem[] = [];
  onlySurplus: boolean = true;
  listStatus: SelectItem[] = [
    { label: 'Todos', value: null },
    { label: 'OK', value: 'OK' },
    { label: 'Menos', value: "SMALLER" },
    { label: 'Isento', value: 'EXEMPT' },
  ];

  selectedItem: Franchise = null;
  menuItems: MenuItem[] = [];


  monthList: SelectItem[] = [
    { label: 'Janeiro', value: '01' },
    { label: 'Fevereiro', value: '02' },
    { label: 'Março', value: '03' },
    { label: 'Abril', value: '04' },
    { label: 'Maio', value: '05' },
    { label: 'Junho', value: '06' },
    { label: 'Julho', value: '07' },
    { label: 'Agosto', value: '08' },
    { label: 'Setembro', value: '09' },
    { label: 'Outubro', value: '10' },
    { label: 'Novembro', value: '11' },
    { label: 'Dezembro', value: '12' },
  ];

  yearList: SelectItem[] = [
    { label: '2021', value: '2021' },
    { label: '2022', value: '2022' },
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
    { label: '2025', value: '2025' },
    { label: '2026', value: '2026' },
    { label: '2027', value: '2027' },
    { label: '2028', value: '2028' },
    { label: '2029', value: '2029' },
    { label: '2030', value: '2030' },
  ];

  referenceMonth: string = dayjs().subtract(1, 'month').format('MM');
  referenceYear: string = dayjs().format('YYYY');
  lastReferenceDate: string;

  franchiseGroupOptions: SelectItem[] = [];
  productList: SelectItem[] = [];

  constructor(
    private dialog: DialogService,
    private spinner: NgxSpinnerService,
    private messageService: MessageUtil,
    private customerService: CustomerService,
    private slingConfigService: SlingConfigService,
    private boatService: BoatService,
    private invoiceService: InvoiceService,
    private operationalConfigService: OperationalConfigService,
    private franchiseService: FranchiseServiceService,
    private franchiseGroupService: FranchiseGroupService,
    private orderReferenceDateUtil: OrderReferenceDateUtil,
    private productService: ProductService
  ) {}

  ngOnInit(): void {
    this.operationalConfigService.isMovements().then((isMovement: boolean) => {
      this.isMovement = isMovement;
      this.isSling = !isMovement;
    });
    this.loggedMarina = StorageUtil.getMarina();
    this.getSlingConfig();
    this.paginationFilter.page = 1;
    this.findAll();
    this.findGroups();
    this.findProducts();
    this.getCustomers();
    this.getBoats();

    this.clear();
  }

  exportTable() {
    const data = { table: this.franchiseList.map((f) => {
      return {
        boatName: f.boatName,
        commercialLength: f.commercialLength,
        owners: f.owners,
        franchiseGroupName: f.franchiseGroupName,
        franchiseNumber: f.franchiseNumber,
        totalValue: f.totalValue,
        ammountOrdered: f.ammountOrdered,
        ammountFranchise: f.ammountFranchise,
        serviceName: f.serviceName,
        surplusAmount: f.surplusAmount,
        unitPrice: f.unitPrice,
        status: f.status,
        orderNumber: f.orderNumber,
        invoiceNumber: f.invoiceNumber,
        exempt: f.exempt ? 'Sim' : 'Não',
      }
    }), type: 'FRANCHISE_CONTROLLER' };
    this.dialog.open(CustomReportExportComponent, {
      width: '70%',
      height: '90vh',
      dismissableMask: false,
      data,
      header: 'Exportar Franquias',
    });
  }

  async findAll() {
    this.spinner.show();
    this.lastReferenceDate = `${this.referenceYear}-${this.referenceMonth}-01`;
    this.franchiseService.getView(this.lastReferenceDate,this.filter).subscribe((data: any[]) => {
      if (this.onlySurplus) {
        this.franchiseList = data.filter((f) =>f.ammountFranchise - f.franchiseNumber > 0);
      }else
    {  this.franchiseList = data;}
      this.franchiseList.forEach((franchise: Franchise) => {
        franchise.statusClass = this.getStatusClass(franchise.status)
      });
      this.totalRecords = this.franchiseList.length;
      this.selectedFranchiseList = [];
      this.spinner.hide();
    });
  }

  async findGroups(){
    this.spinner.show();
    this.franchiseGroupService.findAll().subscribe((data) => {
      this.franchiseGroupOptions = [{label: 'Todos', value: null}, ...data.map((c) => ({ label: c.name, value: c.id }))]
      this.spinner.hide();
    });
  }

  async findProducts(){
    this.spinner.show();
    this.productService.getByProductType(ProductType.Service).subscribe((data) => {
      this.productList = [{label: 'Todos', value: null}, ...data.filter((c) => this.franchiseList.findIndex(f => f.serviceId == c.id) >= 0).map((c) => ({ label: c.name, value: c.id }))]
      this.spinner.hide();
    });
  }

  editQtyModal(franchise: any) {

    this.dialog.open(FranchiseEditqtymodalComponent, {
      header: 'Editar quantidade cobrada',
      height: '350px',
      width: '350px',
      data: {
        franchise,
        refenceDate:  this.lastReferenceDate,
      }
    }).onClose.subscribe((reflesh: boolean) => {
      if (reflesh) {
        this.findAll();
      }
    });


  }

  getSlingConfig() {
    this.slingConfigService.getSlingConfig().subscribe((data) => {
      this.slingconfig = data[0];
    });
  }

  getCustomers(): void {
    this.customerService.getAll().subscribe((data) => {
      this.customers = data;
      this.filteredCustomers = [{ label: 'Todos', value: null }].concat(
        this.customers.map((c) => ({ label: c.name, value: c.id }))
      );
    });
  }

  clear() {
    this.filter = new FranchiseFilterSimple();
    this.rangeCadastro = null;
    this.orderNumberFilter = '';
    this.paginationFilter.page = 1;

  }

  getBoats(): void {
    this.boatService
      .getAllActive('id,name,franchiseNumber')
      .subscribe((data) => {
        this.filteredBoats = [{ label: 'Todos', value: null }].concat(
          data.map((c) => ({
            label: c.name,
            value: c.id,
          }))
        );
      });
  }

  isSameOrAfterCurrentMonth(dateString: string): boolean {
    const date = new Date(dateString);
    const now = new Date();
    now.setDate(1);
    now.setHours(0, 0, 0, 0);
    const inputDateFirstDay = new Date(date.getFullYear(), date.getMonth()+1, 1);
    return inputDateFirstDay >= now;
}


  createOrder(franchise){
    this.spinner.show();
    return this.franchiseService.createOrder({
      reference: `${this.referenceYear}-${this.referenceMonth}-01`,
      boatId: franchise.boatId,
      quantity: franchise.ammountOrdered
    }).subscribe({
      next: (data) => {
        this.spinner.hide();
        Swal.fire({
          icon: 'success',
          title: 'Pedido criado com sucesso!',
        });
        this.findAll();
      },
      error: (error) => {
        this.spinner.hide();
        Swal.fire({
          icon: 'error',
          title: 'Erro ao criar pedido!',
          text: error.error.message,
        });
      },
    });
  }

  async createInvoice(franchise){
    if(franchise.orderNumber == null){
      Swal.fire({
        icon: 'error',
        title: 'Erro ao criar fatura!',
        text: 'É necessário criar um pedido antes de criar a fatura.',
      });
      return;
    }


    this.spinner.show();
    let boat : Boat;

    await this.boatService.getById(franchise.boatId).subscribe((data) => {
      this.spinner.hide();
      boat = data;
    });
    const datesSelection = await this.orderReferenceDateUtil.selectDateForInvoices(this.slingconfig);
    if (datesSelection) {
      const dueDate = datesSelection.dueDate;
      const competenceDate = datesSelection.competenceDate;
      const referenceMonth = datesSelection.referenceMonth;
      const referenceYear = datesSelection.referenceYear;
      const maturityType = datesSelection.maturityType;
      let invoiceCustomerDTO = {
        referenceDate: new Date().toISOString(),
        competenceDate: competenceDate,
        dueDate: maturityType === 'FIXED_DATE' ? dueDate : null,
        referenceMonth: Number(this.referenceMonth) + 1,
        referenceYear: this.referenceYear,
        customerIds: boat.boatCustomers.map((c) => c.customer.id),
        orderNumbers: [franchise.orderNumber],
      };

      this.spinner.show();
      this.invoiceService.generateInvoiceCustomers(invoiceCustomerDTO).subscribe({
        next: (data) => {
          this.spinner.hide();
          Swal.fire({
            icon: 'success',
            title: 'Fatura criada com sucesso!',
          });
          this.findAll();
        },
        error: (error) => {
          this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageService.generateMessage(
            exception.type,
            exception.title,
            exception.message
          );
        }
      });
    }
  }

  getStatusClass(status: string): string {
    switch (status) {
      case 'OK':
        return 'ok-row';
      case 'SMALLER':
        return 'smaller-row';
      case 'EXEMPT':
        return 'exempt-row';
      default:
        return '';
    }
  }

  async createInvoiceBylist(){

    let body:InvoiceCustomerDTO[] = [];
    const franchise = this.selectedFranchiseList.filter((franchise) => franchise.orderNumber != null);
    const datesSelection = await this.orderReferenceDateUtil.selectDateForInvoices(this.slingconfig);

   for (let i = 0; i < franchise.length; i++) {

      if (datesSelection) {
        const dueDate = datesSelection.dueDate;
        const competenceDate = datesSelection.competenceDate;
        const referenceMonth = datesSelection.referenceMonth;
        const referenceYear = datesSelection.referenceYear;
        const maturityType = datesSelection.maturityType;
        let invoiceCustomerDTO: InvoiceCustomerDTO = {
          referenceDate: new Date(),
          competenceDate: competenceDate,
          dueDate: maturityType === 'FIXED_DATE' ? dueDate : null,
          referenceMonth: Number(this.referenceMonth) + 1,
          referenceYear: Number(this.referenceYear),
          customerIds: null,
          orderNumbers: [franchise[i].orderNumber],
        };

        body.push(...[invoiceCustomerDTO]);
      }
    }
      this.spinner.show();
      this.invoiceService.generateInvoiceFranchiseToList(body).subscribe({
        next: (data) => {
          this.spinner.hide();
          Swal.fire({
            icon: 'success',
            title: 'Fatura criada com sucesso!',
          });
          this.findAll();
        },
        error: (error) => {
          this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageService.generateMessage(
            exception.type,
            exception.title,
            exception.message
          );
        }
      });
  }

  validateExempt(){
    Swal.fire({
      title: 'Isentar embarcação ?',
      showCancelButton: true,
      confirmButtonText: 'Isentar',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
    })
    .then((result) => {
      if(result.isConfirmed){
        this.exemptOrder(this.selectedItem);
      }
    });
  }

  validateUnexempt(){
    Swal.fire({
      title: 'Cancelar isenção da embarcação ?',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
    })
    .then((result) => {
      if(result.isConfirmed){
        this.unexemptOrder(this.selectedItem);
      }
    });
  }

  validateCreateOrder(){
    if(this.isSameOrAfterCurrentMonth(this.lastReferenceDate)) {
      Swal.fire({
        icon: 'info',
        title: 'GERAÇÃO DE PEDIDO NÃO DISPONÍVEL PARA O MÊS SELECIONADO!',

      });
      } else {
      Swal.fire({
        icon: 'question',
        title: 'DESEJA GERAR O PEDIDO ?',
        showCancelButton: true,
        confirmButtonText: 'Criar',
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
      })
      .then((result) => {
        if(result.isConfirmed){
          this.createOrder(this.selectedItem);
        }
      });
    }
  }

  validateCreateInvoice(){
    Swal.fire({
      icon: 'question',
      title: 'DESEJA GERAR A FATURA ?',
      showCancelButton: true,
      confirmButtonText: 'Criar',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
    })
    .then((result) => {
      if(result.isConfirmed){
        this.createInvoice(this.selectedItem);
      }
    });
  }

  openFranchiseModal() {
    this.dialog.open(FranchiseHistoryComponent, {
      header: 'Histórico',
      width: '900px',
      data: {
        franchise: this.selectedItem,
        reference: `${this.referenceYear}-${this.referenceMonth}-01`,
      },
    }).onClose.subscribe((value) => {
      if (value) {
        this.findAll();
      }
    });
  }

  exemptOrder(franchise){
    this.spinner.show();
    this.franchiseService.exemptOrder({
      reference: `${this.referenceYear}-${this.referenceMonth}-01`,
      boatId: franchise.boatId,
      quantity: franchise.ammountOrdered
    }).subscribe({
      next: async (data) => {
        this.spinner.hide();
        Swal.fire({
          icon: 'success',
          title: 'Pedido isentado com sucesso!',
        });
        await this.findAll();
      },
      error: (error) => {
        this.spinner.hide();
        this.messageService.generateMessage('error', 'Erro ao isentar pedido!', error.error.message);
      },
    });
  }

  unexemptOrder(franchise){
    this.spinner.show();
    this.franchiseService.unexemptOrder({
      reference: `${this.referenceYear}-${this.referenceMonth}-01`,
      boatId: franchise.boatId,
      quantity: franchise.ammountOrdered
    }).subscribe({
      next: async (data) => {
        this.spinner.hide();
        Swal.fire({
          icon: 'success',
          title: 'Isenção cancelada com sucesso!',
        });
        await this.findAll();
      },
      error: (error) => {
        this.spinner.hide();
        this.messageService.generateMessage('error', 'Erro ao cancelar isenção!', error.error.message);
      },
    });
  }

  changeSelectedItem(franchise) {
    this.selectedItem = franchise;
    this.loadMenuItems();
  }

  loadMenuItems() {
    this.menuItems = [];

    if(!this.isSameOrAfterCurrentMonth(this.lastReferenceDate) && this.selectedItem.surplusAmount > 0 && this.selectedItem.orderNumber == null) {
      this.menuItems.push({
        label: 'Gerar pedido',
        icon: 'pi pi-file',
        command: () => {
          this.validateCreateOrder();
          this.findAll();
        },
      });
    }

    if(this.selectedItem.invoiceNumber == null && this.selectedItem.orderNumber != null){
      this.menuItems.push({
        label: 'Gerar fatura',
        icon: 'pi pi-dollar',
        command: () => {
          this.validateCreateInvoice();
          this.findAll();
        },
      });
    }

    if(this.selectedItem.orderNumber == null){
      this.menuItems.push({
        label: 'Editar quantidade cobrada',
        icon: 'pi pi-pencil',
        command: () => {
          this.editQtyModal(this.selectedItem);
        },
      });
    }

    if(this.selectedItem.exempt && this.selectedItem.orderNumber == null){
      this.menuItems.push({
        label: 'Cancelar isenção',
        icon: 'pi pi-file-excel',
        command: () => {
          this.validateUnexempt();
          this.findAll();
        },
      });
    }
    else if(this.selectedItem.orderNumber == null){
      this.menuItems.push({
        label: 'Isentar',
        icon: 'pi pi-file-excel',
        command: () => {
          this.validateExempt();
          this.findAll();
        },
      });
    }


    this.menuItems.push({
      label: 'Histórico',
      icon: 'pi pi-list',
      command: () => {
        this.openFranchiseModal()
      },
    });
  }

  generateInvoiceLot(){
    let listToSend = this.selectedFranchiseList.filter((franchise) => franchise.orderNumber != null);
    listToSend = listToSend.filter((franchise) => franchise.invoiceNumber == null);
    if(listToSend.length == 0){
      Swal.fire({
        icon: 'info',
        title: 'Nenhum pedido disponível para faturamento!',
      });
      return;
    }
    this.createInvoiceBylist();
  }


  generateOrderLot(){
    let listToSend = this.selectedFranchiseList.filter((franchise) => franchise.orderNumber == null);
    listToSend = listToSend.filter((franchise) => franchise.surplusAmount>0);
    if(listToSend.length == 0){
      Swal.fire({
        icon: 'info',
        title: 'Nenhum pedido disponível para geração!',
      });
      return;
    }
    Swal.fire({
      title: 'DESEJA GERAR O PEDIDO ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Gerar',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
    })
    .then((result) => {
      if(result.isConfirmed){
        this.spinner.show();
        let list:any[] = [];
        listToSend.forEach((franchise) => {
          list.push({
            reference: this.lastReferenceDate,
            boatId: franchise.boatId,
            quantity: franchise.ammountOrdered
          });

        });

        this.franchiseService.createOrderLot(list).subscribe({
          next: (data) => {
            this.spinner.hide();
            Swal.fire({
              icon: 'success',
              title: 'Pedidos criados com sucesso!',
            });
            this.findAll();
          },
          error: (error) => {
            this.spinner.hide();
            Swal.fire({
              icon: 'error',
              title: 'Erro ao criar pedidos!',
              text: error.error.message,
            });

          },
        });
      }
    });
  }

  valid(list:any[],type:string):boolean{
    for (let i = 0; i < list.length; i++) {
      if(type == 'invoice'){
        if(list[i].invoiceNumber == null){
          return true;
        }
      }else if(type == 'order'){
        if(list[i].orderNumber == null){
          return true;
        }
      }
    }
  }
}
