import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class SlingAccessoryService {

    constructor(private http: HttpClient) { }

    updateAll(idSling: number, slingAccessories): Observable<any> {
        return this.http.put(
            environment.apiHost + '/secure/sling-accessories/' + idSling, slingAccessories
        ).pipe(map((response: any) => response.data));
    }
}
