import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { User } from 'src/app/models';
import { CustomerFileDTO } from 'src/app/models/dtos/customerFileDTO';
import { ImportResultService } from 'src/app/services/import-result.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';
import { environment } from 'src/environments/environment';
import { CustomerImportDialogComponent } from '../customer-import-dialog/customer-import-dialog.component';
import { saveAs } from 'file-saver';
import { ImportResult } from 'src/app/models/import-result';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-customer-imports-dialog',
  templateUrl: './customer-imports-dialog.component.html',
  styleUrls: ['./customer-imports-dialog.component.scss']
})
export class CustomerImportsDialogComponent implements OnInit {

  @ViewChild('upload', { static: false }) upload: FileUpload;

  results: CustomerFileDTO[];
  emptySheetURI = '/assets/documents/customer_import.xlsx';
  interval: any;
  lastStatus = 'READY';
  constructor(
    private dialog: DialogService,
    private importResultService: ImportResultService,
    private messageUtil: MessageUtil
  ) { }

  async ngOnInit(): Promise<void> {
    const res = await this.findLastResult();
    if (res && (res.result || res.status === 'READY')) {
      if (!res.result) {
        this.results = [new CustomerFileDTO(null, [], [])];
      } else {
        this.results = [JSON.parse(res.result)];
        this.results[0].createdAt = res.createdAt;
      }
      this.lastStatus = res.status;
    } else if (res) {
      this.results = [new CustomerFileDTO(null, null, null)];
      this.results[0].createdAt = res.createdAt;
      this.lastStatus = res.status;
      this.startInterval();
    }
  }

  get urlUploadXlsxFile(): string {
    return environment.apiHost + '/secure/customers/import-excel';
  }

  async onBasicUpload(files): Promise<any> {
    this.results = [new CustomerFileDTO(null, null, null)];
    this.lastStatus = 'PROCESSING';
    this.startInterval();
    this.importResultService.importExcel(files).subscribe(
      (data) => {
        this.messageUtil.generateMessage(
          'success',
          'SUMMARY.SUCCESS',
          'O arquivo está sendo processado. Fique a vontade para continuar navegando.\nVerifique novamente em alguns minutos.'
        );
        this.upload.clear();
      },
      (event) => {
        this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', event.error.message);
        this.upload.clear();
      }
    );
  }

  base64ToBlob(b64Data, sliceSize = 512): Blob {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  }

  openUploadReportDialog(): void {
    let dialog;
    const data = {
      rows: null,
      file: this.results[0].file,
      customersExists: this.results[0].customersExists,
      customersSuccess: this.results[0].customersSuccess
    };
    if (data.customersExists.length === 0 && data.customersSuccess.length === 0) {
      dialog = this.dialog.open(CustomerImportDialogComponent, {
        width: '300px',
        height: '300px',
        dismissableMask: false,
        data,
        header: this.messageUtil.translateKey('CUSTOMER-IMPORT-REPORT')
      });
    } else {
      if (data.customersExists.length > 0 && data.customersExists.length > 0) {
        data.rows = 5;
      }
      dialog = this.dialog.open(CustomerImportDialogComponent, {
        width: '80%',
        height: '90%',
        dismissableMask: false,
        data,
        header: this.messageUtil.translateKey('CUSTOMER-IMPORT-REPORT')
      });
    }
  }

  downloadFile(): void {
    const file = this.base64ToBlob(this.results[0].file);
    saveAs(file, 'clientes_não_inseridos.xlsx');
  }

  downloadEmptyXlsx(): void {
    window.open(this.emptySheetURI);
  }

  startInterval(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
    // tslint:disable-next-line: variable-name
    const this_ = this;
    this.interval = setInterval(async () => {
      const result = await this.findLastResult();
      if (result && result.status === 'READY') {
        this_.results = [JSON.parse(result.result)];
        this_.lastStatus = result.status;
        this_.results[0].createdAt = result.createdAt;
        clearInterval(this_.interval);
      }
    }, 10000);
  }

  async findLastResult(): Promise<ImportResult> {
    return new Promise<ImportResult>(
      async (res) => {
        this.importResultService.findBySource('CUSTOMER').subscribe(
          async (result: ImportResult) => {
            res(result);
          },
          async (err) => {
            res(null);
          }
        );
      }
    );
  }
}
