import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models';
import { TipoFormaPagamento } from 'src/app/models/finance/tipo-forma-pagamento';
import { NixIntegrationConfig } from 'src/app/models/nix-integration-config.model';
import { UserAccessBanks } from 'src/app/models/user/user-access-banks';
import { BroadcastService } from 'src/app/services/broadcast.service';
import { NixIntegrationConfigService } from 'src/app/services/easy_bank/nix-integration-config.service';
import { FinancesService } from 'src/app/services/finances.service';
import { UserAccessBanksService } from 'src/app/services/user-access-banks.service';
import { UserService } from 'src/app/services/user.service';
import { MessageUtil } from 'src/app/utils/message.util';

@Component({
  selector: 'app-nixx-bank-config',
  templateUrl: './nixx-bank-config.component.html',
  styleUrls: ['./nixx-bank-config.component.scss']
})
export class NixxBankConfigComponent implements OnInit {
  userName: any;
  userTable: UserAccessBanks[];
  userResults: any[] = [];
  userId: number;
  paymentMethodsDropdown: any[];
  paymentMethods: any[];
  accountDropdown: any[];
  pixDropdown: any[];
  pixListOption: any[];

  nixIntegrationConfig: NixIntegrationConfig = new NixIntegrationConfig();

  constructor(private userService: UserService,
              private userAccessBanksService: UserAccessBanksService,
              private messageUtil: MessageUtil,
              private nixIntegrationConfigService: NixIntegrationConfigService,
              private financesService: FinancesService) {
  }

 async ngOnInit(): Promise<void> {
    this.hideMenuBar();
    this.getAllUser();
    this.getAllUsers();
    this.getNixIntegrationConfig();
    await this.configurePaymentMethods()
  }

  async getPaymentMethods(): Promise<TipoFormaPagamento[]> {
    return new Promise<TipoFormaPagamento[]>(async (res) => {
      //this.spinner.show();
      this.financesService.findTipoFormasPagamento().subscribe(
        async (data) => {
          res(data);
        },
        async (error) => {
        //  this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(
            exception.type,
            exception.title,
            exception.message
          );
          res([]);
        }
      );
    });
  }

  async configurePaymentMethods(): Promise<void> {

      const data = await this.getPaymentMethods();
      this.paymentMethods = data
        .filter((method) => method.contas.length !== 0)
        .map((method) => ({ method }));
      this.paymentMethodsDropdown = data
        .filter((method) => method.contas.length !== 0)
        .map((method) => ({
          label: method.descricao,
          value: method.idTipoFormaPagamento,
        }));


      if (this.nixIntegrationConfig.pixAccount) {
        await this.selectedPaymentMethods(this.nixIntegrationConfig.pixPaymentForm);
      }


  }

  async selectedPaymentMethods(value): Promise<void> {
    return new Promise<void>(async (res) => {
      this.accountDropdown = this.paymentMethods
        .filter((m) => m.method.idTipoFormaPagamento === value && !m.company)[0]
        .method.contas.map((account) => ({
          label: account.nome,
          value: account.idConta,
        }));
      res();
    });
  }

  hideMenuBar(): void {
    BroadcastService.get('showHideSidenav').emit(false);
  }

  getAllUser(): void {
    this.userAccessBanksService.getMarinaUsersAndBankId(332).subscribe({
      next: (resp) => {
        this.userTable = resp;
      },
      error: (err) => {
        this.userTable = [];
        this.messageUtil.generateMessage('warning', 'Atenção', 'Erro ao consultar banco de dados');
      }
    });
  }

  getAllUsers(): void {
    this.userService. getMarinaUsers("id-value,username-label,active").subscribe({
      next: (resp) => {
        this.userResults = resp.filter(x => x.active === true);
        this.userName = this.userResults[0].value;
      },
      error: (err) => {
        this.userTable = [];
        this.messageUtil.generateMessage('warning', 'Atenção', 'Erro ao consultar banco de dados.');
      }
    });
  }

  registerUser(): void {
    if (!this.userTable.find(x => x.user.id === this.userName)) {
      let userSelect = this.userResults.find(x => x.value === this.userName);
      let userAccessBanks: UserAccessBanks = new UserAccessBanks();
      userAccessBanks.bankId = 332;
      userAccessBanks.user = new User();
      userAccessBanks.user.id = userSelect.value;
      userAccessBanks.user.username = userSelect.label;
      userAccessBanks.billetCreate = false;
      userAccessBanks.billetCancel = false;
      userAccessBanks.pixCreate = false;
      this.userTable.push(userAccessBanks);
    }

  }

  saveUserAccessBanks(): void {
    this.userAccessBanksService.postSaveUserAccessBanks(this.userTable).subscribe({
      next: (resp) => {
        this.messageUtil.generateMessage('success', 'Sucesso', 'Usuário salvo com sucesso');
      },
      error: (err) => {
        this.messageUtil.generateMessage('warning', 'Atenção', 'Erro ao salvar usuário');
      }
    });
  }

  saveNixIntegration(): void {

    try {
      this.nixIntegrationConfig.pixType= this.pixListOption.find(entry => entry.value === this.nixIntegrationConfig.pixNumber).type;
      this.nixIntegrationConfig.pixPaymentFormDescription= this.paymentMethodsDropdown.find(entry => entry.value === this.nixIntegrationConfig.pixPaymentForm).label;
      this.nixIntegrationConfig.accountName= this.accountDropdown.find(entry => entry.value === this.nixIntegrationConfig.pixAccount).label;
    } catch (error) {

    }


  // if (this.nixIntegrationConfig.enablePix === false) {
  //   this.nixIntegrationConfig.enableHybridBillet = false;
  // }

    this.nixIntegrationConfigService.updateNixIntegrationConfig(this.nixIntegrationConfig).subscribe({
      next: (resp) => {
        this.messageUtil.generateMessage('success', 'Sucesso', 'Configuração salva com sucesso');
        this. getNixIntegrationConfig();
      },
      error: (err) => {
        this.messageUtil.generateMessage('warning', 'Atenção', 'Erro ao salvar configuração');
      }
    });
  }

  getNixIntegrationConfig(): void {
    this.nixIntegrationConfigService.getNixIntegrationConfigByMarinaId().subscribe({
      next: (resp) => {
        if(resp && resp.id){
          this.nixIntegrationConfig = resp;
          if (this.nixIntegrationConfig.nixPassword!=null){
            this.getNixPix();
          }
        }
      },
      error: (err) => {
        this.messageUtil.generateMessage('warning', 'Atenção', 'Chave de acesso não configurada');
      }
    });
  }

  getNixPix(): void {
    this.nixIntegrationConfigService.getNixPix(this.nixIntegrationConfig.socialNumber).subscribe({
      next: (resp) => {
        if(resp ){
          this.pixListOption = resp;
          this.pixDropdown = [];
          resp.forEach((item) => {
            this.pixDropdown.push({value: item.value, label: item.type + ' - ' + item.value});
          });
        }
      },
      error: (err) => {
        this.messageUtil.generateMessage('warning', 'Atenção', 'Chave de acesso não configurada');
      }
    });
  }

  cancel():void{
    this.getAllUser();
  }
}
