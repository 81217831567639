import { DependencyType } from "../../enums/customer/dependency-type";

export class CustomerDependentDTO {
  mainCustomerID: number;
  dependentCustomerID: number;
  dependencyType: DependencyType;

  constructor(mainCustomerID: number, dependentCustomerID: number, dependencyType: DependencyType) {
    this.mainCustomerID = mainCustomerID;
    this.dependentCustomerID = dependentCustomerID;
    this.dependencyType = dependencyType;
  }
}
