import { QuestionVideo } from './../../../models/question-video';
import { FileService } from 'src/app/services/file.service';
import { Question } from './../../../models/question';
import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-faq-dialog-view',
  templateUrl: './faq-dialog-view.component.html',
  styleUrls: ['./faq-dialog-view.component.scss'],
})
export class FaqDialogViewComponent implements OnInit {
  question: Question;
  showSideNav = true;
  mobile = false;
  image: any;

  constructor(
    private config: DynamicDialogConfig,
    private fileService: FileService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.adjustWidth;
    this.question = this.config.data.question;
    this.createQRCode(this.question.link);
    if (this.question.videos.length > 0) {
      this.getvideo();
    }
    console.log(this.question);
  }

  adjustWidth(): void {
    if (window.innerWidth <= 992) {
      this.mobile = true;
      this.showSideNav = false;
    } else {
      this.mobile = false;
      this.showSideNav = true;
    }
  }

  createQRCode(text: string): void {
    this.fileService.createQRCode(text, '200', '200').subscribe({

      next: (data) => {
        const file = new Blob([data], { type: 'application/image' });
        const path = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file));
        this.image = path;
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  getvideo():void{
    this.question.videos.forEach(element => {
    element.liveLink = this.sanitizer.bypassSecurityTrustResourceUrl(element.link);});
  }

}
