export class SelectorBoatDTO {
    active: boolean;
    id: number;
    maintenance: boolean;
    name: string;
    commercialLength: number;
    boatCustomers: SelectorBoatCustomerDTO[];
}

export class SelectorBoatCustomerDTO{
    customer: SelectorCustomersDTO;
    type: string;
}

export class SelectorCustomersDTO {    
    active: boolean;
    id: number;
    name: string;
    type: string;
}

