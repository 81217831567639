
    <div class="dialog-form">
        <div class="table">
            <p-pickList [responsive]="true" dragdrop="true" [source]="ownersSource" [target]="ownersTarget"
                (onMoveToSource)="moveToSource()" (onMoveToTarget)="moveToTarget()" 
                (onMoveAllToSource)="moveToSource()" (onMoveAllToTarget)="moveToTarget()"
                class="full-width content"
                [showSourceControls]="false" [showTargetControls]="false">
                <ng-template let-item pTemplate="item">
                    <div class="quota">
                        {{item.customer.name}}
                        <div *ngIf="item.hasInput">
                            <p-inputNumber (onBlur)="getQuota($event, item)" mode="decimal" [name]="'slingOrderQuota' + item.customer.id"
                                    [(ngModel)]="item.singleOrderQuota" suffix="%">
                            </p-inputNumber>
                        </div>
                    </div>
                </ng-template>
            </p-pickList>
            <div *ngIf="invalidQuota" class="alert alert-danger">
                {{ 'QUOTA-MUSTBE100-MESSAGE' | translate }}
            </div>
        </div>    
    </div>
    