<div [formGroup]="formulario">
  <div class="table" formArrayName="boatSailors">
    <p-table
      [value]="getSailorsControls()"
      [rows]="5"
      [paginator]="true"
      autoLayout="true"
      alwaysShowPaginator="false"
    >
      <ng-template pTemplate="header">
        <tr>
          <th class="text-center">{{ "NAME" | translate }}</th>
          <th class="text-center">{{ "FEDERAL-ID.PHYSICAL" | translate }}</th>
          <th class="text-center">{{ "CELLPHONE" | translate }}</th>
          <th class="text-center">{{ "PERMISSIONS" | translate }}</th>
          <th class="text-center">{{ "START" | translate }}</th>
          <th class="text-center">{{ "STATUS" | translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-sailor let-rowIndex="rowIndex">
        <tr>
          <td class="text-center">
            <div *ngIf="sailor.value.sailor == null">
              <p-dropdown
                #dropsailor
                placeholder="Selecione..."
                [disabled]="false"
                required
                [filter]="true"
                [options]="marinaSailor"
                appendTo="body"
                name="selectedSailorId"
                (onChange)="selectSailor(rowIndex, dropsailor)"
              >
              </p-dropdown>
            </div>
            <div *ngIf="sailor.value.sailor != null">
              {{
                sailor.value.sailor.name + " " + sailor.value.sailor.lastName
              }}
            </div>
          </td>
          <td class="text-center">
            <div *ngIf="sailor.value.sailor != null">
              {{ sailor.value.sailor.federalId }}
            </div>
          </td>
          <td class="text-center">
            <div *ngIf="sailor.value.sailor != null">
              {{ sailor.value.sailor.phone }}
            </div>
          </td>
          <td class="text-center">
            <div
              *ngIf="sailor.value.sailor != null"
              pTooltip="{{ getPermissionsTooltip(sailor.value) }}"
            >
              <i class="pi pi-eye cursor-pointer"></i>
            </div>
          </td>
          <td class="text-center">
            <div *ngIf="sailor.value.sailor != null">
              {{ sailor.value.beginAt | date: "dd/MM/yyyy" }}
            </div>
          </td>
          <td class="text-center">
            <div *ngIf="sailor.value.sailor != null">
              {{
                "SAILOR-INFO.STATUS." + sailor.value.sailor.status | translate
              }}
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
