import { Sailor } from './../../../../../models/sailor';
import { CustomerService } from 'src/app/services/customer.service';
import { BoatService } from './../../../../../services/boat.service';
import { MessageUtil } from './../../../../../utils/message.util';
import { BoatSailor } from './../../../../../models/boat-sailor';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BoatTableDTO } from 'src/app/models/dtos/boatTableDTO';
import { NgxSpinnerService } from 'ngx-spinner';
import { BoatSailorPermission } from 'src/app/models/enums/boat-sailor-permission';

@Component({
  selector: 'app-sailor-boatsailor',
  templateUrl: './sailor-boatsailor.component.html',
  styleUrls: ['./sailor-boatsailor.component.scss'],
})
export class SailorBoatsailorComponent implements OnInit {
  private readonly permissionPrefixTranslate: string =
    'SAILOR-INFO.SAILOR-PERMISSION.';

  @Input()
  sailor:Sailor = new Sailor();

  @Output()
  finishEvent: EventEmitter<any> = new EventEmitter();

  @Input()
  boatsTableTransfer: BoatSailor[]=[];

  boatsTable: BoatSailor[]=[];
  numberOfRows: number = 0;
  boats: any[] = [];
  owners: any[] = [];
  boatSailorAdd = new BoatSailor();
  ownerList: string = '';
  userList: string = '';

  permissionsOptions = [
    {
      label: this.messageUtil.translateKey(
        this.permissionPrefixTranslate + 'FUEL_REQUEST'
      ),
      value: BoatSailorPermission.FuelRequest,
    },
    {
      label: this.messageUtil.translateKey(
        this.permissionPrefixTranslate + 'PREPARE_BOAT'
      ),
      value: BoatSailorPermission.PrepareBoat,
    },
    {
      label: this.messageUtil.translateKey(
        this.permissionPrefixTranslate + 'SHOW_CONTACTS'
      ),
      value: BoatSailorPermission.ShowContacts,
    },
    {
      label: this.messageUtil.translateKey(
        this.permissionPrefixTranslate + 'SLING_CREATE'
      ),
      value: BoatSailorPermission.SlingCreate,
    },
    {
      label: this.messageUtil.translateKey(
        this.permissionPrefixTranslate + 'VIEW_BROWSING_HISTORY'
      ),
      value: BoatSailorPermission.ViewBrowsingHistory,
    },
    {
      label: this.messageUtil.translateKey(
        this.permissionPrefixTranslate + 'WASHING_AND_ENGINE_TURNING'
      ),
      value: BoatSailorPermission.WashingAndEngineTurn,
    },
    {
      label: this.messageUtil.translateKey(
        this.permissionPrefixTranslate + 'FRANCHISE_USE'
      ),
      value: BoatSailorPermission.Franchise_use,
    },
  ];

  constructor(
    private spinner: NgxSpinnerService,
    private messageUtil: MessageUtil,
    private boatService: BoatService,
    private customerService: CustomerService,
  ) {}

  ngOnInit(): void {
    this.boatsTable=this.boatsTableTransfer;
    this.numberOfRows = this.boatsTable.length;
    this.getboats();
  }

  enviar(){
    this.finishEvent.emit(this.boatsTable);
  }

  getboats(): void {
    this.spinner.show();

    this.boatService.getTable().subscribe(
      (data: any) => {
        this.loadOptionsBoat(data);
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
      () => this.spinner.hide()
    );
  }

  loadOptionsBoat(data: BoatTableDTO[]): void {
    this.boats = data.map((obj) => ({
      label: obj.name,
      value: obj,
    }));
  }

  getCustomer(boat: any) {
    this.spinner.show();
    this.ownerList = '';
    this.userList = '';
    this.customerService.getByboatId(boat.id).subscribe(
      (data: any) => {
        this.fillUserList(
          data.filter((v) => v.type === 'User').map((v) => v.customer)
        );
        this.fillOwnerList(
          data.filter((v) => v.type === 'Owner').map((v) => v.customer)
        );
        this.loadOptionsOwners(
          data.filter((v) => v.type === 'Owner').map((v) => v.customer)
        );
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      },
      () => this.spinner.hide()
    );
  }

  loadOptionsOwners(data: any[]): void {
    this.owners = data.map((obj) => ({
      label: obj.name,
      value: obj,
    }));
  }

  fillOwnerList(data: any[]): void {
    data.forEach((l) => {
      this.ownerList += l.name + ', ';
    });
  }

  fillUserList(data: any[]): void {
    data.forEach((l) => {
      this.userList += l.name + ', ';
    });
  }

  postBoatSailor(){
    if (this.boatSailorAdd?.boat?.id !=null && this.boatSailorAdd?.authorizedBy?.id != null ){
      if (this.boatsTable.find((v)=>v.boatTempId===this.boatSailorAdd.boat.id)){
        this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', 'Barco já relacionado a este marinheiro.');
        return
      }
      this.boatSailorAdd.userList=this.userList;
      this.boatSailorAdd.ownerList=this.ownerList;
      this.boatSailorAdd.boatTempName=this.boatSailorAdd.boat.name;
      this.boatSailorAdd.boatTempId=this.boatSailorAdd.boat.id;
      this.boatsTable.push(this.boatSailorAdd);
      this.boatSailorAdd = new BoatSailor();
      this.enviar();
    }else{
      this.messageUtil.generateMessage('warning', 'SUMMARY.WARNING', 'É necessário escolher uma embarcação e um autorizado.');
    }
  }

  deleteBoatSailor(rowIndex){
    this.boatsTable.splice(rowIndex, 1);
    this.enviar();
  }

}
