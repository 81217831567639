import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Sling } from 'src/app/models';
import { SlingAccessoryService } from 'src/app/services/sling-accessory.service';
import { SlingService } from 'src/app/services/sling.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';
import { ImageUtil } from 'src/app/utils/image.util';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sling-accessory-check',
  templateUrl: './sling-accessory-check.component.html',
  styleUrls: ['./sling-accessory-check.component.scss']
})
export class SlingAccessoryCheckComponent implements OnInit {

  sling: Sling;
  referenceTime: any;
  userId: number;

  constructor(
    public dialog: DynamicDialogRef,
    public imageUtil: ImageUtil,
    private slingAccessoryService: SlingAccessoryService,
    private userService: UserService,
    private messageUtil: MessageUtil,
    private toasty: ToastService,
    private config: DynamicDialogConfig,
    private slingService: SlingService
  ) { }

  async ngOnInit(): Promise<void> {
    this.sling = await this.findSlingById(this.config.data.slingId);
    this.referenceTime = this.config.data.referenceTime;
    this.userId = StorageUtil.getUserId();
    if (this.sling) {
      this.loadCheckedToFalse();
    }
  }

  loadCheckedToFalse(): void {
    this.sling.slingAccessories.forEach((sa) => sa.attended = false);
  }

  confirmAccessoryCheck(): void {
    if (this.sling.slingAccessories.find((sa) => !sa.attended) === undefined) {
      this.openDialogConfirmaUser();
    } else {
      this.openDialogConfirmationNotAttended();
    }
  }

  openDialogConfirmationNotAttended(): void {
    Swal.fire({
      title: 'Confirmação',
      text: 'Acessório não fornecido. Deseja continuar?',
      icon: 'warning',
      reverseButtons: true,
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar'
    }).then((response) => {
      if (response.value) {
        this.openDialogConfirmaUser();
      }
    });
  }

  openDialogConfirmaUser(): void {
    Swal.fire({
      title: 'Confirma fornecimento de acessório?',
      showCancelButton: true,
      confirmButtonText: 'Confirmar',
      showLoaderOnConfirm: true,
      reverseButtons: true,
      cancelButtonColor: '#d33',
      allowOutsideClick: false,
      preConfirm: () => {
        this.saveSlingAccessories();
      }
    });
  }

  saveSlingAccessories(): void {
    this.sling.slingAccessories.forEach((sa) => sa.sling = { id: this.sling.id });
    this.slingAccessoryService.updateAll(this.sling.id, this.sling.slingAccessories).subscribe(
      () => {
        this.dialog.close();
        this.toasty.success('Acessório fornecido com sucesso');
      },
      (error) => {
        console.log(error);
        this.dialog.close();
        this.messageUtil.generateMessage('error', 'SUMMARY.ERROR', error.error.message);
      });
  }

  async findSlingById(id: number): Promise<Sling> {
    return new Promise<Sling>(
      async (resolve) => {
        this.slingService.getById(id).subscribe(
          async (sling: Sling) => {
            resolve(sling);
          },
          async err => {
            resolve(null);
          }
        );
      }
    );
  }
}
