import { Component, Input, OnInit } from '@angular/core';
import { ShipyardService } from 'src/app/services/shipyard.service';
import { ShipyardModel } from 'src/app/models/shipyard-model';

@Component({
  selector: 'app-boat-log-hull',
  templateUrl: './boat-log-hull.component.html',
  styleUrls: ['./boat-log-hull.component.scss']
})
export class BoatLogHullComponent implements OnInit {

  @Input() boatLog;
  @Input() isNew;

  shipyardModel;
  shipyardModelOld;

  oldDatasMap

  constructor(
    private shipyardService: ShipyardService
  ) { }

  async ngOnInit(): Promise<void> {
    this.oldDatasMap = new Map(Object.entries(this.boatLog.oldDatas));
    this.shipyardModel = await this.loadShipyardModels(this.boatLog.shipyardModelId);
    this.verifyChangesShipyardModelId();
  }

  async verifyChangesShipyardModelId() {
    if(this.oldDatasMap.has('shipyardModelId')){
      this.shipyardModelOld = await this.loadShipyardModels(this.boatLog.oldDatas.shipyardModelId);
    }
  }

  async loadShipyardModels(shipyardId: number): Promise<ShipyardModel> {
    return new Promise(async resolve => {
      this.shipyardService.getShipyardModelById(shipyardId).subscribe(
        (data) => {
          resolve(data);
        },
        (error) => {
          resolve(null);
        });
    })
  }

  isChangedFlyBridge() {
    return this.oldDatasMap.has('flyBridge');
  }

  getFlyBridge(): boolean {
    let flyBridge = this.boatLog.flyBridge;
    if(this.isChangedFlyBridge() && !this.isNew) {
      flyBridge = this.boatLog.oldDatas.flyBridge;
    }
    return flyBridge;
  }

  isChangedTwoTimeEngine() {
    return this.oldDatasMap.has('twoTimeEngine');
  }

  getTwoTimeEngine(): boolean {
    let twoTimeEngine = this.boatLog.twoTimeEngine;
    if(this.isChangedTwoTimeEngine() && !this.isNew) {
      twoTimeEngine = this.boatLog.oldDatas.twoTimeEngine;
    }
    return twoTimeEngine;
  }

  isChangedShipyard(): boolean {
    return this.shipyardModelOld && this.shipyardModel.shipyard.name != this.shipyardModelOld.shipyard.name;
  }

  isChangedShipyardModel(): boolean {
    return this.shipyardModelOld && this.shipyardModel.name != this.shipyardModelOld.name;
  }

  isChangedShipyardType(): boolean {
    return this.shipyardModelOld && this.shipyardModel.shipType != this.shipyardModelOld.shipType;
  }
}
