import { Component, OnInit } from '@angular/core';
import { MovementLocation } from 'src/app/models/movement/movement-location';
import { MovementLocationService } from 'src/app/services/movements/movement-location.service';
import { ToastService } from 'src/app/services/toast.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-movement-locations-config',
  templateUrl: './movement-locations-config.component.html',
  styleUrls: ['./movement-locations-config.component.scss']
})
export class MovementLocationsConfigComponent implements OnInit {
  private readonly VACANCY_CONTROL_TOOLTIP_TRUE = 'Controla Vaga';
  private readonly VACANCY_CONTROL_TOOLTIP_FALSE = 'Não controla Vaga';
  private readonly PREFIX_USE_TOOLTIP_TRUE = 'Utiliza Prefixo';
  private readonly PREFIX_USE_TOOLTIP_FALSE = 'Não utiliza Prefixo';

  locations: MovementLocation[];
  updatingVacancyControl: {};
  updatingPrefixUse:{};

  constructor(
    private readonly movementLocationService: MovementLocationService,
    private toasty: ToastService,
  ) { }

  ngOnInit(): void {
    this.loadLocations();
  }

  loadLocations() {
    this.movementLocationService.getAll()
      .subscribe(
        (data) => {
          const updatingVacancyControl = data.reduce((acc, location) => { acc[location.id] = false; return acc;}, {});
          this.updatingVacancyControl = { ...updatingVacancyControl, ...this.updatingVacancyControl };
          const updatingPrefixUse = data.reduce((acc, location) => { acc[location.id] = false; return acc;}, {});
          this.updatingPrefixUse = { ...updatingPrefixUse, ...this.updatingPrefixUse };
          this.locations = data;
        },
        (err) => this.toasty.error('Ops, não foi possível atualizar a lista de locais! Por favor tente novamente mais tarde.')
      );
  }

  updateVacancyControlModalConfirm(location: MovementLocation) {
    if (!this.updatingVacancyControl[location.id]) {
      this.updatingVacancyControl[location.id] = true;
      Swal.fire({
        title: `${location.name} - Controle de Vaga`,
        text: (
          `Tem certeza que deseja alterar o controle de vagas do local ${location.name}?`
        ),
        icon: 'warning',
        showCancelButton: true,
        backdrop: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true
      })
      .then((result) => {
        if (result.value) {
          this.updateVacancyControl(location);
        } else {
          this.updatingVacancyControl[location.id] = false;
        }
      })
      .catch(() => this.updatingVacancyControl[location.id] = false );
    }
  }

  private updateVacancyControl(location: MovementLocation) {
    this.movementLocationService.updateVacancyControl(location.id, !location.vacancyControl)
    .subscribe(
      () => {
        location.vacancyControl = !location.vacancyControl;
        this.toasty.success('Local ' + location.name + ' atualizado com sucesso!');
        this.updatingVacancyControl[location.id] = false;
      },
      (err) => {
        const msg = err?.error?.data?.exception.message || ('Ops, ocorreu um erro ao tentar atualiza o controle de vaga do local ' + location.name);
        this.toasty.error(msg);
        this.updatingVacancyControl[location.id] = false;
      }
    )
  }


  updatePrefixUseModalConfirm(location: MovementLocation) {
    if (!this.updatePrefixUseControl[location.id]) {
      this.updatePrefixUseControl[location.id] = true;
      Swal.fire({
        title: `${location.name} - Controle de Vaga`,
        text: (
          `Tem certeza que deseja Utilizar o prefixo para o local ${location.name}?`
        ),
        icon: 'warning',
        showCancelButton: true,
        backdrop: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true
      })
      .then((result) => {
        if (result.value) {
          this.updatePrefixUseControl(location);
        } else {
          this.updatePrefixUseControl[location.id] = false;
        }
      })
      .catch(() => this.updatePrefixUseControl[location.id] = false );
    }
  }


  private updatePrefixUseControl(location: MovementLocation) {
    this.movementLocationService.updatePrefixUse(location.id, !location.prefixUse)
    .subscribe(
      () => {
        location.prefixUse = !location.prefixUse;
        this.toasty.success('Local ' + location.name + ' atualizado com sucesso!');
        this.updatingPrefixUse[location.id] = false;
      },
      (err) => {
        const msg = err?.error?.data?.exception.message || ('Ops, ocorreu um erro ao tentar atualizar a utilização do prefixo da vaga do local ' + location.name);
        this.toasty.error(msg);
        this.updatingPrefixUse[location.id] = false;
      }
    )
  }
}
