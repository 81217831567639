import {Component, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Boat, Customer} from '../../../models';
import {SlingService} from '../../../services/sling.service';
import {SelectItem} from 'primeng/api';
import {CustomerService} from '../../../services/customer.service';
import {BoatService} from '../../../services/boat.service';
import {BoatFilterListWithCustomerDTO} from '../../../models/dtos/boat-filter-list-with-customerDTO';
import {Sailor} from '../../../models/sailor';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {LandExitService} from '../../../services/land-exit.service';
import {ToastService} from '../../../services/toast.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {LandExitDTO} from '../../../models/dtos/land-exit-dto';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-land-exit',
  templateUrl: './land-exit.component.html',
  styleUrls: ['./land-exit.component.scss']
})
export class LandExitComponent implements OnInit {
  landExitForm: UntypedFormGroup;
  availablePeriods: SelectItem[];
  allCustomers: Customer[];
  customerList: Customer[];
  allBoats: BoatFilterListWithCustomerDTO[];
  boatList: BoatFilterListWithCustomerDTO[] = [];
  sailorList: Sailor[] = [];
  searchByCustomer = true;

  constructor(
    private slingService: SlingService,
    private customerService: CustomerService,
    private boatService: BoatService,
    private dynamicDialog: DynamicDialogRef,
    private landExitService: LandExitService,
    private toasty: ToastService,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe
  ) {

  }

  ngOnInit(): void {
    this.landExitForm = new UntypedFormGroup({
      executionDate: new UntypedFormControl(null, [Validators.required]),
      returnDate: new UntypedFormControl(null, [Validators.required]),
      customer: new UntypedFormControl(null, [Validators.required]),
      boat: new UntypedFormControl(null, [Validators.required]),
      sailor: new UntypedFormControl(null)
    });
    this.loadCustomer();
    this.loadBoats();
  }

  save(): void {
    if (this.landExitForm.valid) {
      if (this.getExecutionDate() > this.getReturnDate()) {
        this.toasty.warning('A data de retorno não pode ser anterior a data de saída!');
        return;
      }
      this.spinner.show();
      const returnDate = this.datePipe.transform(this.getReturnDate(), 'yyyy-MM-ddTHH:mm');
      const executionDate = this.datePipe.transform(this.getExecutionDate(), 'yyyy-MM-ddTHH:mm');
      const landExitDTO = new LandExitDTO(executionDate, returnDate, this.getCustomer().id, this.getBoat().id);

      if (this.getSailor() != null) {
        landExitDTO.sailorId = this.getSailor().id;
      }
      this.landExitService.registerLandExit(landExitDTO).subscribe({
        next: (_) => {
          this.spinner.hide();
          this.toasty.success('Saída por terra criada com sucesso!');
          this.close();
        },
        error: (e) => {
          this.spinner.hide();
          if (e?.error.data?.exception?.message != null) {
            this.toasty.error(e.error.data.exception.message);
          }
        }
      });
    }
  }

  close(): void {
    this.dynamicDialog.close();
  }

  loadCustomer(): void {
    this.customerService.getAllWithBoats().subscribe({
      next: (data) => {
        this.allCustomers = data.filter((customer) => customer.active);
        this.customerList = this.allCustomers;
      },
      error: (e) => {
        console.log(e);
      }
    });
  }

  loadBoats(): void {
    this.boatService.getBoatFilterListWithCustomer().subscribe(
      (data) => {
        this.allBoats = data;
      },
      () => {
        this.boatList = null;
      }
    );
  }

  changeSearch(): void {
    this.sailorList = [];
    this.landExitForm.get('boat').setValue(null);
    this.landExitForm.get('customer').setValue(null);
    this.landExitForm.get('sailor').setValue(null);
    if (!this.searchByCustomer) {
      this.boatList = this.allBoats;
      this.customerList = [];
    } else {
      this.boatList = [];
      this.customerList = this.allCustomers;
    }
  }

  selectCustomer(customer): void {
    this.boatList = [];
    this.landExitForm.get('boat').setValue(null);
    this.allBoats.forEach((boat) => {
      if (boat.customerIds.includes(customer.value.id) === true) {
        this.boatList.push(boat);
      }
    });
  }

  selectBoat(event: any): void {
    const boat = event.value;
    this.customerList = [];
    this.landExitForm.get('customer').setValue(null);
    if (boat.customerIds != null && boat.customerIds.length > 1) {
      boat.customerIds.forEach((customerId) => {
        const customer1 = this.allCustomers.find((cust) => cust.id === customerId);
        if (customer1 != null) {
          this.customerList.push(customer1);
        }
      });
    }
  }

  checkSailor(): void {
    const boat = this.getBoat();
    this.sailorList = [];
    this.landExitForm.get('sailor').setValue(null);
    this.boatService.getById(boat.id).subscribe({
      next: (data) => {
        if (data.boatSailors != null && data.boatSailors.length > 0) {
          data.boatSailors.forEach((boatSailor) => {
            this.sailorList.push(boatSailor.sailor);
          });
        }
      },
      error: (e) => {
        console.log(e);
      }
    });
  }

  isFormValid(): boolean {
    return !this.landExitForm.valid;
  }

  getExecutionDate(): Date {
    return this.landExitForm.get('executionDate').value;
  }

  getReturnDate(): Date {
    return this.landExitForm.get('returnDate').value;
  }

  getCustomer(): Customer {
    return this.landExitForm.get('customer').value;
  }

  getBoat(): Boat {
    return this.landExitForm.get('boat').value;
  }

  getSailor(): Sailor {
    return this.landExitForm.get('sailor').value;
  }
}
