import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { SirenAlert } from 'src/app/models/siren/siren-alert';
import { SirenAlertSimpleConfig } from 'src/app/models/siren/siren-alert-simple-config';
import { SirenAlertConfigService } from 'src/app/services/siren/siren-alert-config.service';
import { SirenAlertSimpleConfigService } from 'src/app/services/siren/siren-alert-simple-config.service';
import { SirenAlertService } from 'src/app/services/siren/siren-alert.service';
import { MessageUtil } from 'src/app/utils/message.util';


@Component({
  selector: 'app-movement-siren-alert-config',
  templateUrl: './movement-siren-alert-config.component.html',
  styleUrls: ['./movement-siren-alert-config.component.scss'],
  providers: [
    { provide: SirenAlertSimpleConfigService, useClass: SirenAlertSimpleConfigService }
  ]
})
export class MovementSirenAlertConfigComponent implements OnInit {

  filteredSirenAlerts: { label: any; value: any; }[];

  formSirens: UntypedFormArray;
  form: UntypedFormGroup;

  constructor(
    private sirenAlertSimpleConfigService: SirenAlertSimpleConfigService,
    private sirenAlertService: SirenAlertService,
    private spinner: NgxSpinnerService,
    private messageUtil: MessageUtil
  ) { }

  ngOnInit(): void {
    this.findSirenAlerts();
  }

  save() {
    if (this.form.valid) {
      const sirens = this.form.value.sirens;
      this.sirenAlertSimpleConfigService.save(sirens).subscribe(
        (data) => {
          this.findSirenAlerts();
          this.messageUtil.generateMessage('success', 'SUMMARY.SUCCESS', 'Configurações salvas com sucesso!');
        },
        (error) => {
          this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
        }
      );
    }
  }

  private findSirenAlerts(): void {
    this.spinner.show();
    this.sirenAlertService.getAll().subscribe(
      (sirenAlerts: SirenAlert[]) => {
        this.filteredSirenAlerts = sirenAlerts.map((sa) => ({ label: sa.name, value: sa }));
        this.find();
      }, () => this.spinner.hide()
    );
  }

  private find(): void {
    this.sirenAlertSimpleConfigService.find().subscribe(
      (sirens: SirenAlertSimpleConfig[]) => {
        if (sirens) {
          this.createForm(sirens);
        }
        this.spinner.hide();
      }, () => this.spinner.hide()
    );
  }

  createForm(sirens: SirenAlertSimpleConfig[]): void {
    const sirenFormControlList: UntypedFormControl[] = sirens.map((siren) => {
      return new UntypedFormControl(siren, [ Validators.required, this.sirenEnabledHasData ])
    });
    this.formSirens = new UntypedFormArray(sirenFormControlList, [ Validators.required ]);
    this.form = new UntypedFormGroup({ 'sirens': this.formSirens });
  }

  sirenEnabledHasData = (control: UntypedFormControl) => {
    const siren: SirenAlertSimpleConfig = control.value
    if (siren && siren.enabled) {
      if (!siren.sirenAlert) {
        return { SirenEnabledEmpty: true }
      }
    }
    return null;
  }

}
