<p-fieldset>
  <p-header class="article-title">
    <span class="article-title">{{ 'TITLE-ACCESS-CATEGORIES-BATCH' | translate }}</span>
  </p-header>

  <div class="max-width flex flex-column align-items-center" *ngIf="showCategoriesLists; else showPickList">
    <h1>Escolha a categoria de origem e a categoria de destino</h1>
    <div class="flex flex-row justify-content-around mt-4">
      <div class="w-18rem h-10rem mr-5">
        <label for="sourceCategoriesList">Categoria de Origem</label>
        <p-dropdown [options]="sourceCategoriesList" [(ngModel)]="sourceCategorySelected" optionLabel="name"
                    (onChange)="findSourceAccessGenericUserList()" *ngIf="isReady"
                    name="sourceCategoriesList"></p-dropdown>
      </div>
      <div class="w-18rem h-10rem">
        <label for="targetCategoriesList">Categoria de Destino</label>
        <p-dropdown [options]="targetCategoriesList" placeholder="Selecione..." [(ngModel)]="targetCategorySelected" optionLabel="name"
                    name="targetCategoriesList" (onChange)="findTargetAccessGenericUserList()"></p-dropdown>
      </div>
    </div>
    <div class="flex flex-row justify-content-center">
      <button class="easymarine danger mr-3" type="button"
              routerLink="../..">{{ 'CANCEL' | translate }}
      </button>
      <button class="easymarine primary" (click)="invertShowCategoriesList()" [disabled]="!targetCategorySelected">{{ 'PRÓXIMO' | translate }}</button>
    </div>
  </div>
  <ng-template #showPickList>
    <p-pickList [source]="sourceAccessUserObjList" [target]="targetAccessUserObjList" sourceHeader="Origem - {{sourceCategorySelected.name}}"
                targetHeader="Destino - {{targetCategorySelected.name}}"
                [responsive]="true" [sourceStyle]="{ height: '30rem' }"
                 [keepSelection]="true"
                [showSourceControls]="false"
                [showTargetControls]="false" [dragdrop]="true"
                [targetStyle]="{ height: '30rem' }" filterBy="name" sourceFilterPlaceholder="Buscar pelo nome"
                targetFilterPlaceholder="Buscar pelo nome" breakpoint="1400px">
      <ng-template let-selectItem pTemplate="item">
        <div class="flex flex-wrap p-2 align-items-center gap-3">
          <div class="flex-1 flex flex-column gap-2">
            <span class="font-bold">{{ selectItem.name }}</span>
          </div>
        </div>
      </ng-template>
    </p-pickList>
    <div class="flex flex-row justify-content-end mt-4">
      <button class="easymarine danger mr-3" type="button" (click)="invertShowCategoriesList()" >{{ 'CANCEL' | translate }}
      </button>
      <button class="easymarine primary" (click)="save()">{{ 'SAVE' | translate }}</button>
    </div>
  </ng-template>
</p-fieldset>
