<div class="p-3">
  <p-fieldset>
    <p-header class="article-title">
      <div class="article-title">{{ 'DEPENTENTS' | translate }}</div>
    </p-header>
    <div>
      <div class="flex flex-row align-items-center justify-content-between max-w-30rem">
        <div class="flex flex-column mr-3 mb-3">
          <label class="mb-2">Adicionar um dependente</label>
          <p-dropdown appendTo="body" [options]="customerList" name="dependent" optionLabel="name"
                      placeholder="Selecione..." (onChange)="selectDependent($event)"
                      [filter]="true" tooltipPosition="bottom">
          </p-dropdown>
        </div>
        <div class="flex flex-column mr-3 mb-3">
          <label class="mb-2">Tipo de dependência</label>
          <p-dropdown appendTo="body" [options]="dependencyTypeList" name="dependencyType" optionLabel="label"
          placeholder="Selecione..." (onChange)="selectDependencyType($event)"
          [filter]="true" tooltipPosition="bottom">
          </p-dropdown>
        </div>
        <button class="fab-button primary " type="button" (click)="addDependent()"
                pTooltip="Adicionar dependente ao cliente" tooltipPosition="bottom">
          <i class="pi pi-plus"></i></button>
      </div>
      <p-table [value]="dependentsList" [rows]="5" [paginator]="true"
               autoLayout="true" alwaysShowPaginator="false">
        <ng-template pTemplate="header">
          <tr>
            <th>{{ 'NAME' | translate}}</th>
            <th>{{ 'TYPE' | translate}}</th>
            <th>{{ 'FEDERAL-ID.GENERAL' | translate}}</th>
            <th>{{ 'EMAIL' | translate}}</th>
            <th>{{ 'CELLPHONE' | translate}}</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-dependent let-rowIndex="rowIndex">
          <tr>
            <td>{{dependent.name}}</td>
            <td>{{dependent.dependencyType | translate}}</td>
            <td>{{dependent.federalId}}</td>
            <td>{{dependent.email}}</td>
            <td>{{dependent.phone}}</td>
            <td class="text-center">
              <div class="table-action cursor-pointer" pTooltip="Deletar" tooltipPosition="top"
                   (click)="deleteDependent(dependent)">
                <i class="pi pi-trash"></i>
              </div>
            </td>
<!--            <td class="text-center">-->
<!--              <div class="table-action cursor-pointer" pTooltip="Visualizar Histórico" tooltipPosition="top"-->
<!--                   (click)="openHistoric(customerService)">-->
<!--                <i class="pi pi-eye"></i>-->
<!--              </div>-->
<!--            </td>-->
          </tr>
        </ng-template>
      </p-table>
    </div>
  </p-fieldset>
</div>
