import { Component } from '@angular/core';

@Component({
  selector: 'app-invoice-help',
  templateUrl: './invoice-help.component.html',
  styleUrls: ['./invoice-help.component.scss']
})
export class InvoiceHelpComponent {

  constructor(
  ) { }
}
