import { BlockType } from "../enums/blockType.enum";

export class SecuritiesProperty {

  id: number;
  numberId: number;
  active: boolean;
  currentCustomerId: number;
  currentCustomerName: string;
  currentCustomerPhone: string;
  lastAssociation: Date;
  marinaId: number;
  block:boolean;
  blockType:BlockType;

}
