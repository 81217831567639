import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Marina, Role, User } from 'src/app/models';
import { Application } from 'src/app/models/enums/application';
import { MenuItem } from 'src/app/models/menu-item';
import { UserMarina } from 'src/app/models/user-marina';
import { MenuItemService } from 'src/app/services/menu-item.service';
import { RoleService } from 'src/app/services/role.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-role-form',
  templateUrl: './user-role-form.component.html',
  styleUrls: ['./user-role-form.component.scss']
})
export class UserRoleFormComponent implements OnInit {

  maskNumber = ['/[1-9]/'];
  role: Role = new Role();
  hierarchyList: any[] = [];
  menuItensListWeb: any[] = [];
  menuItensListGestor: any[] = [];
  user: User;
  roleForm: UntypedFormGroup;

  constructor(
    public dialogRef: DynamicDialogRef,
    private roleService: RoleService,
    private messageUtil: MessageUtil,
    private spinner: NgxSpinnerService,
    private menuItemService: MenuItemService,
    private userService: UserService,
    private toasty: ToastService,
    private config: DynamicDialogConfig
  ) { }

  async ngOnInit(): Promise<void> {
    if (this.config.data && this.config.data.role) {
      this.role = this.config.data.role;
      this.role.menuItens = this.role.menuItens.map((r) => (new MenuItem(r.id, r.application)));
    }
    this.startForm();
    await this.findAllEasyWeb();
    await this.findAllEasyGestor();
    await this.mapRoleMenuitens();
    this.getHierarchyList();
  }

  async getHierarchyList(): Promise<void> {
    const userMarina = await this.findUserMarina();
    if(userMarina) {
      const list = [];
      for (let index = userMarina.roleId.hierarchy; index < 10; index++) {
        list.push(index);
      }
      this.hierarchyList = [...list]
        .map((hierarchy) => ({ label: '' + hierarchy, value: hierarchy }));
      if (!userMarina.roleId.administrator) {
        this.roleForm.get("administrator").disable();
      }
    }
  }

  findUserMarina(): Promise<UserMarina>{
    return new Promise(resolve => {
      this.userService.getUserMarina().subscribe(userMarina => {
        resolve(userMarina);
      });
    })
  }

  updateMenuItens(): void {
    const menuItens = [...this.roleForm.get('selectedItensGestor').value.concat([...this.roleForm.get('selectedItensWeb').value])];
    this.roleForm.get('menuItens').setValue(menuItens);
  }

  async findAllEasyWeb(): Promise<void> {
    return new Promise<void>(
      async (res) => {
        this.menuItemService.getAllStructuredByAplication(Application.EasyWeb).subscribe(async (result) => {
          this.mapMenuChilds('', this.menuItensListWeb, result);
          this.menuItensListWeb = [...this.menuItensListWeb].map(
            (m) => ({ label: m.id + ' - ' + this.messageUtil.translateKey(m.label), value: new MenuItem(m.id, Application.EasyWeb) })
          );
          res();
        });

      }
    );
  }

  mapMenuChilds(parentLabel, list: any[], childsList: any[]): void {
    childsList.forEach((element) => {
      element.label = this.messageUtil.translateKey(element.label);
      if (element.children && element.children.length > 0) {
        element.disabled = true;
        list.push(element);
        element.label = ((parentLabel && parentLabel !== '') ? parentLabel + ' > ' : '') + element.label;
        this.mapMenuChilds(element.label, list, element.children);
      } else {
        element.label = ((parentLabel && parentLabel !== '') ? parentLabel + ' > ' : '') + element.label;
        list.push(element);
      }
    });
  }


  async findAllEasyGestor(): Promise<void> {
    return new Promise<void>(
      async (res) => {
        this.menuItemService.getAllStructuredByAplication(Application.GestorApp).subscribe(
          async (result) => {
            this.menuItensListGestor = [...result]
              .map(
                (m) => (
                  {
                    label: m.id + ' - ' + this.messageUtil.translateKey(m.label),
                    value: new MenuItem(m.id, Application.GestorApp)
                  }
                )
              );
            res();
          }
        );
      }
    );
  }

  async mapRoleMenuitens(): Promise<void> {
    return new Promise<void>(
      async (res) => {
        const web = [...this.roleForm.get('selectedItensWeb').value];
        const gestor = [...this.roleForm.get('selectedItensGestor').value];
        this.role.menuItens.forEach((element) => {
          if (element.application === Application.EasyWeb) {
            web.push(element);
          } else if (element.application === Application.GestorApp) {
            gestor.push(element);
          }
        });
        this.roleForm.get('selectedItensWeb').setValue([...web]);
        this.roleForm.get('selectedItensGestor').setValue([...gestor]);
        res();
      }
    );
  }

  changePhotos(e): void {
    this.roleForm.get('photos').setValue(e.checked);
  }

  changeAdministrator(e): void {
    this.roleForm.get('administrator').setValue(e.checked);
  }

  changeInvoiceDatesPermission(e): void {
    this.roleForm.get('changeInvoiceDates').setValue(e.checked);
  }

  changeResponsible(e): void {
    this.roleForm.get('responsible').setValue(e.checked);
  }

  empetyList(list): boolean {
    if (!list || list.length === 0) {
      return false;
    }
    return true;

  }

  save(): void {
    const currentUserId = StorageUtil.getUser();
    this.spinner.show();
    this.roleService.save(this.roleForm.value).subscribe(
      (data) => {
        if (this.roleForm.value.id) {
          this.spinner.hide();
          this.toasty.success('Perfil alterado com sucesso!');
          this.dialogRef.close();
        } else {
          this.spinner.hide();
          this.toasty.success('Perfil salvo com sucesso!');
          this.dialogRef.close();
        }
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      });
  }

  formValidations(): boolean {
    if (this.roleForm.valid) {
      return true;
    } else {
      Swal.fire('Alerta!', 'Por favor, preencha os campos obrigatórios', 'warning');
      return false;
    }
  }

  startForm(): void { 
    this.roleForm = new UntypedFormGroup({
      id: new UntypedFormControl(this.role.id),
      name: new UntypedFormControl(this.role.name, Validators.required),
      hierarchy: new UntypedFormControl(this.role.hierarchy, Validators.required),
      menuItens: new UntypedFormControl(this.role.menuItens),
      selectedItensWeb: new UntypedFormControl([]),
      selectedItensGestor: new UntypedFormControl([]),
      changeInvoiceDates: new UntypedFormControl(this.role.changeInvoiceDates),
      marina: new UntypedFormControl({ id: StorageUtil.getMarina().id }, Validators.required),
      photos: new UntypedFormControl(this.role.photos, Validators.required),
      administrator: new UntypedFormControl(this.role.administrator),
      responsible: new UntypedFormControl(this.role.responsible),
    });
  }

}
