import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FuelTankFraction } from 'src/app/models/enums';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { OrderUtil } from 'src/app/utils/order.util';

@Component({
  selector: 'app-marina-order-log-table',
  templateUrl: './marina-order-log-table.component.html',
  styleUrls: ['./marina-order-log-table.component.scss']
})
export class MarinaOrderLogTableComponent implements OnInit{

  @Input()
  ordersLog = [];

  @Input()
  isDialog = false;

  numberOfRows = 10;

  private orderUtil: OrderUtil = new OrderUtil();

  private orderNumber: number = null;

  constructor(
    private slingConfigService: SlingConfigService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if ( !this.isDialog) {
      this.loadSlingConfig();
    }

    this.route.queryParams.subscribe(params => {
      this.orderNumber = params["orderNumber"];

      if(this.orderNumber){
        this.ordersLog = this.ordersLog.filter((order) => order.orderNumber === this.orderNumber);
      }
    });
  }

  loadSlingConfig(): void {
    this.slingConfigService.getSlingConfig().subscribe(
      (data) => {
        const slingConfig = data[0];
        if (slingConfig != null) {
          this.numberOfRows = slingConfig.numberOfRecordsPerPage;
        } else {
          this.numberOfRows = 10;
        }
      },
      (error) => null);
  }

  getLabelFraction(fuelTankFraction: FuelTankFraction): string {
    return this.orderUtil.getFuelFractionOptions().find((f) => f.value === fuelTankFraction).label;
  }

}
