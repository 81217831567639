<div>
  <div class="form-group">
    <div class="form-control format-text " style="max-height: 350px;">
      <p style="color: red; font-weight: bold;">Por favor, leia com atenção os termos a seguir.</p>

      <p>A partir de agora você poderá selecionar nas Configurações Gerais da sua marina o tipo de data que determinará a competência das Notas Fiscais de Serviço geradas pela EasyMarine.
      </p>
      <p>Essa inovação é importante pois na maioria das prefeituras é possível emitir uma nota fiscal em um dia e especificar como competência uma data diferente. Ou seja, determinando o dia, por exemplo, em que o serviço foi efetivamente prestado.
      </p>
      <p>As datas disponíveis são o dia em que a fatura foi gerada no sistema, o vencimento da fatura, a competência da fatura e a data de emissão da Nota Fiscal de Serviço.
      </p>
      <p>Lembramos que em algumas prefeituras, a data de competência não é utilizada e não consta na nota fiscal, e, neste caso, é usada somente a data de emissão.
      </p>
      <p>Por favor, escolha com cautela.
      </p>
    </div>
  </div>

  <div class="form-group format-text" style="background-color: white;">
    <p style="font-weight: bold;">Selecione a data de competência da NFes</p>

    <div style="font-size: 14pt; margin-bottom: 10px;" >
      <div class="form-group finances">
        <div class="form-control" tooltipPosition="bottom" pTooltip="Competência padrão para geração de notas fiscais">
            <label for="competency">
                Competência padrão para geração de notas fiscais
            </label>
            <br>
            <p-dropdown placeholder="Selecione..." [options]="competencyDropdown" appendTo="body"
                name="competency" required filter="true"
                [(ngModel)]="slingConfig.competency">
            </p-dropdown>
        </div>
    </div>


    <div style="font-size: 14pt;">
      <label for="confirm">Digite a palavra “<span style="color:red;">CONFIRMA </span>” no campo abaixo para confirmar sua escolha.</label>
      <br>
      <input pInputText name="confirm" type="text" maxlength="8" [(ngModel)]="confirma" style="text-transform: uppercase;">

    </div>
    <div>
      <div class="buttons" style="align-items: flex-end; text-align: right;">
        <button
          type="button"
          (click)="dialogRef.close()"
          class="easymarine danger"
        >
       Cancelar
        </button>
        &nbsp;&nbsp;
        <button class="easymarine primary" ng-disabled = "(confirma != 'confirma' ) || slingConfig.competency==0"
          (click)="save()" >
         Salvar
        </button>
      </div>

    </div>



  </div>

</div>
