<div style="padding-left: 1em; padding-right: 1em; padding-top: 1em">
  <p-fieldset>
    <p-header class="article-title">
      <span class="article-title">{{ creditText() }}</span>
      <div class="header-buttons"></div>
    </p-header>

    <div class="filter-row">
      <div class="form-group d-flex" style="display: flex; width: 100%">
        <div class="form-control col-6">
          <label class="block mb-2 pt-1">Nome</label>
          <input
            pInputText
            type="text"
            class="w-100"
            style="width: 100%"
            [(ngModel)]="customerCredit.customer.name"
            readonly
          />
        </div>

        <div class="form-control col-6">
          <label class="block mb-2 pt-1">Descrição</label>
          <input
            pInputText
            type="text"
            class="w-100"
            style="width: 100%"
            [(ngModel)]="customerCredit.description"
          />
        </div>
      </div>
      <div class="form-group w-100" style="display: flex">
        <div class="form-control col-4">
          <label class="block mb-2 pt-1">Número da fatura</label>
          <input
            pInputText
            type="text"
            class="w-100"
            [(ngModel)]="customerCredit.invoice.invoiceNumber"
            (blur)="validInvoiceNumber()"
          />
        </div>
        <div class="form-control col-4">
          <label class="block mb-2 pt-1">Valor</label>
          <p-inputNumber
            mode="decimal"
            name="discount"
            [(ngModel)]="customerCredit.value"
            [minFractionDigits]="2"
            [maxFractionDigits]="2"
            [ngClass]="{ 'text-red': !customerCredit.credit }"
          >
          </p-inputNumber>
        </div>
        <div class="form-control col-4">
          <label class="block mb-2 pt-1">Data de vencimento</label>
          <p-calendar
            dateFormat="dd/mm/yy"
            name="creditDate"
            [monthNavigator]="true"
            [yearNavigator]="true"
            [yearRange]="'1900:2055'"
            rPCalendarMask
            appendTo="body"
            [showIcon]="true"
            i18n
            [(ngModel)]="customerCredit.creditDate"
            class="w-100"
          >
          </p-calendar>
        </div>
      </div>
    </div>

    <!-- style="width: 100%; max-height: none" -->
  </p-fieldset>


  <div class="justify-content-end " style="padding-top: 1em; display: flex;">
    <div class="form-control col-8">
      </div>
      <div class="form-control col-2">
         <button class="easymarine danger " style="width: 100%;" type="button" (click)="clear()">
      {{ "CLEAR" | translate }}
    </button>
      </div>
      <div class="form-control col-2">
 <button class="easymarine primary " style="width: 100%;" (click)="save()">
        {{ "SAVE" | translate }}
      </button>
      </div>


    </div>
</div>
