<div class="options-box">
    <label>Escolha a forma de desconto</label>
    <div class="inputs">
        <p-radioButton [disabled]="hasInvoice" name="discount" value="percent" [(ngModel)]="discountByMoney" inputId="discount1"></p-radioButton>%
        <p-inputNumber class="percent" (onBlur)="fillDiscounts('percent')"
        [disabled]="discountByMoney != 'percent' || hasInvoice" [(ngModel)]="discountPercent" name="percent" class="medium"></p-inputNumber>&nbsp;&nbsp;
        <p-radioButton [disabled]="hasInvoice" name="discount" value="money" [(ngModel)]="discountByMoney" inputId="discount2"></p-radioButton>R$
        <p-inputNumber class="money" (onBlur)="fillDiscounts('money')"
        [disabled]="discountByMoney != 'money' || hasInvoice" [(ngModel)]="discount" name="money" mode="decimal" class="medium" [minFractionDigits]="2" [maxFractionDigits]="2"></p-inputNumber>
    </div>
    <div>
        <label>Total do desconto: {{discount | number:'1.2-2'}}</label>
    </div>
</div>