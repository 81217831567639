import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ServiceHistoryReajustmentDTO } from "../models/dtos/service-history-reajustmentDTO";
import { ServiceReadjustmentSimulationDTO } from "../models/dtos/service-readjustment-simulationDTO";
import { ServiceBoatReadjustmentDTO } from "../models/dtos/service-boat-readjustmentDTO";
import { servicesReadjustmentRegisterPriceFreeDTO } from "../models/dtos/services-readjustment-register-price-freeDTO";
import {servicesReadjustmentRegisterDTO} from "../models/dtos/services-readjustment-registerDTO";

@Injectable()
export class ServiceBoatService {

    constructor(private http: HttpClient) { }

    getReajustmentHistoric(serviceId): Observable<ServiceHistoryReajustmentDTO[]> {
        return this.http.get(environment.apiHost + '/secure/service-boats/readjustment/price-free/findReajustmentHistoric/'+serviceId)
            .pipe(map((response: any) => response.data.reajustmentHistoric as ServiceHistoryReajustmentDTO[]));
    }

    getServicesBoatPriceFree(filter): Observable<ServiceBoatReadjustmentDTO[]> {
        return this.http.post(environment.apiHost + '/secure/service-boats/readjustment/price-free', filter)
            .pipe(map((response: any) => response.data.readjustmentsPriceFree as ServiceBoatReadjustmentDTO[]));
    }

    registerReadjustmentPriceFree(adjustments: servicesReadjustmentRegisterPriceFreeDTO[]): Observable<any>{
        return this.http.post(environment.apiHost + '/secure/service-boats/readjustment/price-free/register', adjustments)
        .pipe(map((response: any) => response as any));
    }

    sendEmailsReajustment(selectedService: any[]): Observable<any>{
       return this.http.post(environment.apiHost + '/secure/service-boats/readjustment/price-free/sendMails', selectedService)
      .pipe(map((response: any) => response.data.errorSend as any));
    }

    getSimulationServicesReadjustment(simulatePriceReadjustment: ServiceReadjustmentSimulationDTO): Observable<ServiceBoatReadjustmentDTO[]>{
        return this.http.post(environment.apiHost + '/secure/service-boats/readjustment/simulation', simulatePriceReadjustment)
        .pipe(map((response: any) => response.data.simulationReadjustmentsService as ServiceBoatReadjustmentDTO[]));
    }

    registerServicesReadjustment(readjustementService: servicesReadjustmentRegisterDTO): Observable<any>{
        return this.http.post(environment.apiHost + '/secure/service-boats/readjustment/register', readjustementService)
        .pipe(map((response: any) => response.data as any));
    }
}
