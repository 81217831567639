<div class="form-control">
  <label>{{'SAILOR' | translate}}</label>
  <p-dropdown placeholder="Selecione..." [filter]="false"
  appendTo="body" [options]="sailorsFilter" dataKey="id" [(ngModel)]="sailor" name="selectedSailorId"
  emptyFilterMessage=""
  (onChange)=" selectSailor($event)"
  [disabled]="!sailorsFilter">
    <ng-template let-sailor pTemplate="item">
      <div>{{sailor.label}}</div>
    </ng-template>
  </p-dropdown>
</div>
