import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OperationalConfigService } from './config/operational-config.service';
import { map } from 'rxjs/operators';

@Injectable()
export class WorkshopService {
  constructor(
    private http: HttpClient,
    private operationalConfigService: OperationalConfigService
  ) {}

  authenticate(integrationKey: string): Observable<string> {
    return this.http
      .get(`${environment.apiHost}/secure/integracao/workshop/token/${integrationKey}`)
      .pipe(map((resp: any) => resp.data.token as string));
  }
}
