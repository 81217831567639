<p-fieldset class="operation-date">
    <p-header class="article-title">
        <span class="article-title">{{ 'CLOSED-DAYS' | translate }}</span>
        <div class="primary fab-button" (click)="newCloseDay()">
            <i class="pi pi-plus" style="font-size: 21px;"></i>
        </div>
    </p-header>

    <div class="table">
        <p-table [value]="closeDays" autoLayout="true" [lazy]="true" >
            <ng-template pTemplate="header">
                <tr>
                    <th>
                        Data
                    </th>
                    <th>Observação</th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-closeDay>
                <tr>
                    <td style="min-width: 125px;">
                        <p-calendar dateFormat="dd/mm/y" [name]="'dateCloseDays' + closeDays.indexOf(closeDay)"
                            [monthNavigator]="true" [yearNavigator]="true" [yearRange]="'2000:2055'" rPCalendarMask
                            [(ngModel)]="closeDay.date" [showIcon]="true" [minDate]="today" i18n appendTo="body">
                        </p-calendar>
                    </td>
                    <td style="min-width: 250px;">
                        <input pInputText type="text" tooltipPosition="bottom" style="width: 100%; max-width: 400px;"
                            pTooltip="Observação que será apresentada para os usuários" maxlength="255"
                            [name]="'observation' + closeDays.indexOf(closeDay)" [(ngModel)]="closeDay.observation" />
                    </td>
                    <td>
                        <div pTooltip="Remover" (click)="delete(closeDay)" class="table-action">
                            <i class="pi pi-trash" style="color: #EF5350;"></i>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</p-fieldset>
