export class TimezoneUtil {

  formatTimezone(dtNow: Date):Date{
    const dataAtual = new Date();
    let fator = (((dataAtual.getTimezoneOffset() - 180)/60));
    let newDate: any = new Date(dtNow);
    newDate.setHours(newDate.getHours() - fator);
    return newDate;
  }

}
