import { Component } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { CheckUserBraceletComponent } from 'src/app/components/sling/check-user-bracelet/check-user-bracelet.component';
import { FilterBoatMovement } from 'src/app/models/dtos/movements/filter-boat-movement';

@Component({
  selector: 'app-movement-appointment',
  templateUrl: './movement-appointment.component.html',
  styleUrls: ['./movement-appointment.component.scss']
})
export class MovementAppointmentComponent {

  activeIndex = 0;

  filter: FilterBoatMovement = { };

  constructor(
    private dialog: DialogService
  ) { }


  onChangeSelectTab($event) {
    this.activeIndex = $event.index;
  }

  reload(){
    const index = this.activeIndex;
    this.activeIndex = 99;
    setTimeout(() => {
      this.activeIndex = index;
    }, 1);
  }

  openModalUserInfo(): void {
    this.dialog.open(CheckUserBraceletComponent, {
      width: '400px',
      showHeader: false,
      dismissableMask: true
    });
  }

  changeFilter(event) {
    this.filter = event;
  }
}
