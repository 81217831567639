import { Boat } from 'src/app/models';
import { Customer } from 'src/app/models';
import { SailorType } from '../enums/sailor-type.enum';

export class SailorFilters {

  name: string;
  email: string;
  federalId: string;
  active: boolean;
  client: Customer;
  boat:Boat;
  sailorType: SailorType;
  userApp: boolean;
  marinaCompanyId: number;
  status: number;

  constructor() {
  }

}

