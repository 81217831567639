import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import moment from 'moment';
import { MovementDateTimeDTO } from 'src/app/models/dtos/movements/boat-movement-dto';
import { MovemmentDateTimeType } from 'src/app/models/enums/movements/movement-date-time-type';

export interface MovementExpectedDateParams {
  date: MovementDateTimeDTO;
}

@Component({
  selector: 'app-movement-expected-date',
  templateUrl: './movement-expected-date.component.html',
  styleUrls: ['./movement-expected-date.component.scss']
})
export class MovementExpectedDateComponent implements OnInit, OnDestroy {

  @Input()
  params: MovementExpectedDateParams;

  isLate: boolean = false;

  timeOutIsLate

  datetimeStr: string = '---'

  constructor() { }

  ngOnInit() {
    if (this.params.date && this.params.date.dateTime && this.params.date.type === MovemmentDateTimeType.PREVISION) {
      const diff = (new Date()).getTime() - this.params.date.dateTime.getTime()
      if (diff <= 0) {
        this.timeOutIsLate = setTimeout(() => {
          this.isLate = true;
          this.timeOutIsLate = null;
        }, -diff);
      } else {
        this.isLate = true;
      }
      this.datetimeStr = this.getFormatDate(this.params.date.dateTime);
    }
  }

  ngOnDestroy(): void {
    if (this.timeOutIsLate) {
      clearTimeout(this.timeOutIsLate);
      this.timeOutIsLate = null;
    }
  }

  isToday(date: Date) {
    return ((new Date(date)).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0));
  }

  getFormatDate(date: Date) {
    const today = this.isToday(date);
    return moment(date)
    .utc(true)
    .format(today ? 'HH:mm' : 'DD/MM HH:mm')
  }
}
