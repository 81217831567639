import {Component, Input, OnInit} from '@angular/core';
import {ContractEndDateStatus, monthReference} from '../tuitions.component';
import {Marina} from '../../../../models';
import moment from 'moment';
import {MovementGroupService} from '../../../../services/movements/movement-group.service';
import {ToastService} from '../../../../services/toast.service';
import {MessageUtil} from '../../../../utils/message.util';
import {GroupService} from '../../../../services/group.service';
import {SelectItem} from 'primeng/api';
import {ProductType} from '../../../../models/enums';
import {ProductService} from '../../../../services/product.service';
import {FormatUtil} from '../../../../utils/format.util';
import {NgxSpinnerService} from 'ngx-spinner';
import {TuitionService} from '../../../../services/tuition.service';
import Swal from 'sweetalert2';
import {TuitionServiceCustomerView} from '../../../../models/dtos/tuition/tuition-service-customer-view';
import { SlingConfigService } from 'src/app/services/sling-config.service';

@Component({
  selector: 'app-customer-tuitions',
  templateUrl: './customer-tuitions.component.html',
  styleUrls: ['./customer-tuitions.component.scss']
})
export class CustomerTuitionsComponent implements OnInit {
  @Input() groups: Array<{ label: string; value: any }>;
  @Input() groupsList: any[];
  @Input() loggedMarina: Marina;
  @Input() marinaCompaniesList: Array<{ label: string; value: any }>;

  protected readonly monthsReference: any[] = monthReference;
  referenceMonth;
  referenceYear;
  company: any;
  groupIds: number[];
  tuitionsTable: TuitionServiceCustomerView[] = [];
  tuitions: TuitionServiceCustomerView[];
  referenceSituation: string;
  periodicalServiceProductList: Array<SelectItem> = [];
  periodicalServiceProductListIds: Array<number> = [];
  selectedTuitions: TuitionServiceCustomerView[] = [];
  globalFilter = '';
  totalValue: number;
  totalDiscount: number;
  total: number;
  numberOfRows = 10;

  constructor(
    private movementGroupService: MovementGroupService,
    private groupService: GroupService,
    private toasty: ToastService,
    private productService: ProductService,
    private messageService: MessageUtil,
    private spinner: NgxSpinnerService,
    private tuitionService: TuitionService,
    private slingConfigService: SlingConfigService) {
  }

  ngOnInit(): void {
    this.loadSlingConfig();
    this.referenceMonth = moment().month();
    this.referenceYear = moment().year();
    this.findGroups();
    this.findPeriodicalServiceProductList();
  }

  private loadSlingConfig(): void {
    this.slingConfigService.getSlingConfig().subscribe(
      (data) => {
        const slingConfig = data[0];
        this.numberOfRows = slingConfig != null ? slingConfig.numberOfRecordsPerPage : 10;
      },
      () => null
    );
  }

  private startViewLaunch() {
    this.company = null;
    this.referenceSituation = 'allSituations';
    this.viewLaunch();
  }

  findGroups(): void {
    this.groupService.findAll().subscribe((groups) => {
      this.groupsList = groups;
      this.groups = [{label: 'Sem grupo', value: null}].concat(
        groups.map((g) => ({label: g.name, value: g.id}))
      );
    });
  }
  getLabelMonth(option: number): void {
    if (option === 0) {
      if (this.referenceMonth === 11) {
        return this.monthsReference[0].label;
      }
      return this.monthsReference.find(
        (mr) => mr.value === this.referenceMonth + 1
      ).label;
    } else if (option === 1) {
      return this.monthsReference.find((mr) => mr.value === this.referenceMonth)
        .label;
    }
  }
  filterCompanies(): void {
    let filter;
    // Se selecionou a marina
    if (this.company && this.company.marinaCompanies) {
      filter = this.groupsList
        .filter((g) => !g.marinaCompany)
        .map((g) => ({label: g.name, value: g.id}));
      // Se selecionou uma empresa adicional
    } else if (this.company && !this.company.marinaCompanies) {
      filter = this.groupsList
        .filter(
          (g) => g.marinaCompany && g.marinaCompany.id === this.company.id
        )
        .map((g) => ({label: g.name, value: g.id}));
    } else if (!this.company) {
      this.groups = [{label: 'Sem grupo', value: null}].concat(
        this.groupsList.map((g) => ({label: g.name, value: g.id}))
      );
      this.groupIds = [];
    }
    if (filter) {
      this.groupIds = [null].concat(filter.map((g) => g.value));
      this.groups = [{label: 'Sem grupo', value: null}].concat([...filter]);
    }
  }

  cleanTuitions(): void {
    this.tuitions = [];
    this.tuitionsTable = [];
  }

  findPeriodicalServiceProductList(): void {
    const periodicalService: ProductType = ProductType.PeriodicalServiceCustomer;
    this.periodicalServiceProductList = [];
    this.productService
      .getByProductType(periodicalService)
      .subscribe((products) => {
        this.periodicalServiceProductList = [
          {label: 'Sem serviço', value: null} as SelectItem, // Garante o tipo como SelectItem
          ...products
            .filter(i => i.active)
            .map((item) => ({label: item.name, value: item.id} as SelectItem)),
        ];
        this.periodicalServiceProductListIds = this.periodicalServiceProductList.map(item => item.value);
        this.startViewLaunch();
      });
  }

  getValueCompany(company: any): number {
    if (company.marinaCompanies) {
      return this.tuitionsTable.reduce(
        (total, tuition) => total + (tuition.tuitionCustomerView != null
          ? tuition.tuitionCustomerView.value
          : tuition.total),
        0
      );
    } else {
      return this.tuitionsTable.reduce(
        (total, tuition) =>
          total + ((tuition.tuitionCustomerView != null && tuition.tuitionCustomerView.companyId == company.id)
            ? tuition.tuitionCustomerView.value
            : tuition.total),
        0
      );
    }
  }

  getDiscountCompany(company: any): number {
    return this.tuitionsTable.reduce((total, tuition) => {
      const tuitionCustomerView = tuition.tuitionCustomerView;
      if (!tuitionCustomerView) {
        return total + tuition.discount;
      }
      return total + (tuition.discount * tuitionCustomerView.quota) / 100;
    }, 0);
  }

  getTotalCompany(company: any): number {
    return this.tuitionsTable.reduce((total, tuition) => {
      const tuitionCustomer = tuition.tuitionCustomerView;
      const totalForTuition = tuitionCustomer
        ? (tuition.value * tuitionCustomer.quota) / 100
        : tuition.value;
      return total + totalForTuition;
    }, 0);
  }

  getTotalSelected(company: any): number {
    if (!this.selectedTuitions || this.selectedTuitions.length === 0) {
      return 0;
    }
    return this.selectedTuitions.reduce((total, tuition) => {
      const tuitionCustomer = tuition.tuitionCustomerView;
      if (tuitionCustomer && ((company.marinaCompanies && !tuitionCustomer.companyId) || tuitionCustomer.companyId === company.id)) {
        return total + tuitionCustomer.value;
      }
      return total;
    }, 0);
  }

  filterGlobal(): void {
    let tuitionTableAux = this.tuitions.filter(
      (t) =>
        FormatUtil.getNotAccents(t.customerName)
          .toUpperCase()
          .includes(FormatUtil.getNotAccents(this.globalFilter).toUpperCase()) ||
        t.customerName.toUpperCase().includes(this.globalFilter.toUpperCase()) ||
        FormatUtil.getNotAccents(t.tuitionCustomerView.groupName)
          .toUpperCase()
          .includes(FormatUtil.getNotAccents(this.globalFilter).toUpperCase()) ||
        t.tuitionCustomerView.groupName.toUpperCase().includes(this.globalFilter.toUpperCase())
    );
    if (this.referenceSituation !== 'allSituations') {
      if (this.referenceSituation === 'generated') {
        tuitionTableAux = tuitionTableAux.filter((t) => t.generate);
      } else {
        tuitionTableAux = tuitionTableAux.filter((t) => !t.generate);
      }
    }

    if (this.company) {
      if (this.company.marinaCompanies) {
        tuitionTableAux = tuitionTableAux.filter((t) => !t.tuitionCustomerView.companyId);
      } else {
        tuitionTableAux = tuitionTableAux.filter((t) => t.tuitionCustomerView.companyId && t.tuitionCustomerView.companyId === this.company.id);
      }
    }

    if (this.groupIds && this.groupIds.length) {
      tuitionTableAux = tuitionTableAux.filter((t) =>
        this.groupIds.some((groupId) => t.tuitionCustomerView && t.tuitionCustomerView.groupId == groupId));
    }

    if (this.periodicalServiceProductListIds.length === 0) {
      const productListAux = this.periodicalServiceProductList.map((item) => item.value);
      tuitionTableAux = tuitionTableAux.filter((t) => productListAux.includes(t.productId));
    } else {
      tuitionTableAux = tuitionTableAux.filter((t) => this.periodicalServiceProductListIds.includes(t.productId));
    }
    this.tuitionsTable = tuitionTableAux;
  }

  viewLaunch(): void {
    this.selectedTuitions = [];
    const referenceDate = new Date(this.referenceYear, this.referenceMonth, 1, 0, 0, 0, 0);
    this.spinner.show();
    this.tuitionService.viewLaunchByCustomer(referenceDate.valueOf()).subscribe({
      next: (data) => {
        this.tuitions = data;

        this.tuitions.forEach((t) => {
          t.isPartial = this.isPartial(t);
          t.getPriceTypeName = this.getPriceTypeName(t.pricingType);
          t.isContractEndDateStatusExpired = this.isContractEndDateStatusExpired(t);
          t.hasContractEndDate = t?.hasContractEndDate;
        });


        this.tuitionsTable = this.tuitions;
        this.spinner.hide();
        this.filterGlobal();
        this.setTotal();
      }, error: (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageService.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      }
    });
  }

  dialogMonthlySummary() {

  }

  dialogGenerateTuition() {
    if (
      this.selectedTuitions.filter((t) => this.isEnableSelect(t)).length > 0
    ) {
      Swal.fire({
        text: 'Deseja gerar os pedidos de mensalidades baseadas nos valores apresentados?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
      }).then((isConfirm) => {
        if (isConfirm.value) {
          this.generateTuition();
        }
      });
    } else {
      this.messageService.generateMessage(
        'warning',
        'Atenção',
        'Não há itens na seleção.'
      );
    }
  }

  private setTotal(): void {
    this.totalValue = this.tuitionsTable.reduce(
      (total, t) => total + t.value,
      0
    );
    this.totalDiscount = this.tuitionsTable.reduce(
      (total, t) => total + t.discount,
      0
    );
    this.total = this.tuitionsTable.reduce((total, t) => total + t.total, 0);
  }

  isMonthlyPayment(): boolean {
    return (
      this.tuitionsTable.find(
        (t) =>
          !t.generate &&
          t.tuitionCustomerView &&
          t.pricingType &&
          t.hasContractEndDate !== ContractEndDateStatus.EXPIRED
      ) !== undefined
    );
  }

  hasContractEndDate(tuition: TuitionServiceCustomerView): ContractEndDateStatus {
    if (tuition.contractEndDate) {
      const referenceDate = new Date(this.referenceYear, this.referenceMonth, 1, 0, 0, 0, 0);
      const contractEndDate = new Date(tuition.contractEndDate);
      if (referenceDate.getMonth() === contractEndDate.getMonth() &&
        referenceDate.getFullYear() === contractEndDate.getFullYear()) {
        return ContractEndDateStatus.EXPIRES_THIS_MONTH;
      } else if (
        (referenceDate.getMonth() === 11 && contractEndDate.getMonth() === 0) ||
        (referenceDate.getMonth() + 1 === contractEndDate.getMonth() &&
          referenceDate.getFullYear() === contractEndDate.getFullYear())
      ) {
        return ContractEndDateStatus.EXPIRES_NEXT_MONTH;
      } else if (referenceDate.getTime() > contractEndDate.getTime()) {
        return ContractEndDateStatus.EXPIRED;
      }
    } else {
      return ContractEndDateStatus.NORMAL;
    }
    return ContractEndDateStatus.NORMAL;
  }

  getFontColor(tuition: TuitionServiceCustomerView): string {
    if (this.isInvalidCustomer(tuition)) {
      if (tuition.hasContractEndDate == ContractEndDateStatus.EXPIRED) {
        return '#b32a2ad9';
      } else {
        return 'red';
      }
    }
    if (tuition.total === 0) {
      return 'green';
    }
    return '#2196F3';
  }

  isInvalidCustomer(tuition: TuitionServiceCustomerView): boolean { //TODO rever esse metodo
    return (
      !tuition.pricingType ||
      tuition.tuitionCustomerView === null ||
      !tuition.contractStartDate
    );
  }

  getBackgroundContractEndDateStatus(
    statusService: ContractEndDateStatus
  ): string {
    let color;
    switch (statusService) {
      case ContractEndDateStatus.NORMAL:
        color = '';
        break;
      case ContractEndDateStatus.EXPIRES_NEXT_MONTH:
        color = '#7cf54a';
        break;
      case ContractEndDateStatus.EXPIRES_THIS_MONTH:
        color = '#fddd33';
        break;
      case ContractEndDateStatus.EXPIRED:
        color = 'rgb(255 154 154)';
        break;
      default:
        color = '';
        break;
    }

    return color;
  }

  isEnableSelect(tuition: TuitionServiceCustomerView): boolean {
    return (
      !tuition.generate &&
      tuition.tuitionCustomerView !== null &&
      tuition.pricingType &&
      !this.isContractEndDateStatusExpired(tuition) &&
      this.hasContractStartDate(tuition)
    );
  }

  hasContractStartDate(tuition: any): boolean {
    if (tuition.contractStartDate) {
      const referenceDate = new Date(
        this.referenceYear,
        this.referenceMonth + 1,
        1,
        0,
        0,
        0,
        0
      );
      const contractStartDate = new Date(tuition.contractStartDate);
      if (contractStartDate.getTime() < referenceDate.getTime()) {
        return true; // amarelo
      }
    }
    return false;
  }

  isContractEndDateStatusExpired(tuition) {
    return tuition.hasContractEndDate == ContractEndDateStatus.EXPIRED;
  }

  getBackgroundTuition(tuition): string {
    const statusService: ContractEndDateStatus =
      tuition?.hasContractEndDate;
    return this.getBackgroundContractEndDateStatus(statusService);
  }

  openDialogBoatForm(boatId: any) { //TODO rever esse metodo

  }

  getCompany(tuition: TuitionServiceCustomerView): string {
    if (!tuition.tuitionCustomerView) {
      return '';
    }
    if (tuition.tuitionCustomerView.companyId) {
      return this.getCompanyIndex(tuition.tuitionCustomerView.companyId).toString();
    }
    return '1';
  }

  getCompanyIndex(companyId): number {
    if (companyId) {
      const m = this.loggedMarina.marinaCompanies.find(
        (c) => c.id === companyId
      );
      return this.loggedMarina.marinaCompanies.indexOf(m) + 2;
    }
    return 1;
  }

  getPriceTypeName(priceType: string): string { //TODO rever
    switch (priceType) {
      case 'PerRangeLength':
        return 'Range';
      case 'FixedPerRange':
        return 'Fixo';
      case 'SimplePrice':
        return 'Simples';
      default:
        return '';
    }
  }

  isPartial(tuition) {
    return (
      FormatUtil.roundTwoDecimal(tuition.total) + tuition.discount !=
      FormatUtil.roundTwoDecimal(tuition.value) &&
      this.hasContractStartDate(tuition) &&
      !this.isContractEndDateStatusExpired(tuition)
    );
  }

  generateMonthRange(tuition: any) {

  }

  private generateTuition() {
    const referDate = new Date(
      Number(this.referenceYear),
      Number(this.referenceMonth),
      1,
      0,
      0,
      0,
      0
    );
    const customerTuitions = this.selectedTuitions
      .filter((t) => this.isEnableSelect(t))
      .map((t) => ({
        idServiceCustomer: t.serviceCustomerId,
        referenceDate: referDate,
      }));
    this.spinner.show();
    this.tuitionService.generateByCustomer(customerTuitions).subscribe(
      () => {
        this.toasty.success('Mensalidades geradas com sucesso.');
        this.tuitions = new Array();
        this.tuitionsTable = new Array();
        this.selectedTuitions = new Array();
        this.viewLaunch();
        this.spinner.hide();
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageService.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
        this.spinner.hide();
      }
    );
  }
}
