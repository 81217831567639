import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Customer, Boat } from '../models';
import { CustomerFilter } from '../models/dtos/customer-filter';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { CustomerFilterListDTO } from '../models/dtos/customerFilterListDTO';
import { CustomerDependentDTO } from '../models/dtos/customer/customerDependentDTO';

@Injectable()
export class CustomerService {
  constructor(private http: HttpClient) {}

  getAll(campos?: string): Observable<Customer[]> {
    if (campos == undefined) {
      campos = '';
    }
    let headers = new HttpHeaders();
    headers = headers.set('campos', campos);
    return this.http
      .get(environment.apiHost + '/secure/customers/', { headers: headers })
      .pipe(map((response: any) => response.data.customers as Customer[]));
  }

  getServiceReportCustomers(customerFilter): Observable<Customer[]> {    
    return this.http
      .post(environment.apiHost + '/secure/customers/e', customerFilter)
      .pipe(map((response: any) => response.data.customers as Customer[]));
  }

  getCustomerFilterList(): Observable<CustomerFilterListDTO[]> {
    return this.http
      .get(environment.apiHost + '/secure/customers/filter-list/')
      .pipe(
        map(
          (response: any) => response.data.customers as CustomerFilterListDTO[]
        )
      );
  }

  getAllCustomerActiveSimple(): Observable<any[]> {
    return this.http
      .get(environment.apiHost + '/secure/customers/customer-to-sale')
      .pipe(map((response: any) => response.data.customers));
  }

  getCustomerByBoatFilterList(
    boatId: number
  ): Observable<CustomerFilterListDTO[]> {
    return this.http
      .get(`${environment.apiHost}/secure/customers/boat/${boatId}/filter-list`)
      .pipe(
        map(
          (response: any) => response.data.customers as CustomerFilterListDTO[]
        )
      );
  }

  getPeriodicalServiceInUse(): Observable<CustomerFilterListDTO[]> {
    return this.http
      .get(
        environment.apiHost +
          '/secure/customers/periodical-services-in-use/price-free'
      )
      .pipe(
        map(
          (response: any) =>
            response.data
              .customersWithPeriodicalServicesInUse as CustomerFilterListDTO[]
        )
      );
  }

  getAllWithBoats(): Observable<Customer[]> {
    return this.http
      .get(environment.apiHost + '/secure/customers/has-boats/')
      .pipe(map((response: any) => response.data.customers as Customer[]));
  }
  getAllOwners(): Observable<Customer[]> {
    return this.http
      .get(environment.apiHost + '/secure/customers/owners-only')
      .pipe(map((response: any) => response.data.customers as Customer[]));
  }

  getByServiceDeskCustomer(): Observable<Customer> {
    return this.http
      .get(environment.apiHost + '/secure/customer/serviceDesk')
      .pipe(map((response: any) => response.data.customer));
  }

  getAllSimple(): Observable<Customer[]> {
    return this.http
      .get(environment.apiHost + '/secure/customers/simple')
      .pipe(map((response: any) => response.data.customers));
  }

  getAllSimpleOnlyActive(): Observable<Customer[]> {
    return this.getAllSimple().pipe(
      map((data) => data.filter((c) => c.active === undefined || c.active))
    );
  }

  getAllOwnerOnlySimple(): Observable<any[]> {
    return this.http
      .get(environment.apiHost + '/secure/customers/owners-only-simple')
      .pipe(map((response: any) => response.data.customers));
  }

  getByEmailAndMarinaId(body: String): Observable<Customer> {
    return this.http
      .put(environment.apiHost + '/secure/customer/email/', body)
      .pipe(map((response: any) => response.data.customer));
  }

  getById(id: number): Observable<Customer> {
    return this.http
      .get(environment.apiHost + '/secure/customer/' + id)
      .pipe(map((response: any) => response.data.customer));
  }

  getBoats(id: number): Observable<Boat[]> {
    return this.http
      .get(environment.apiHost + '/secure/customer/boats/' + id)
      .pipe(map((response: any) => response.data.boats as Boat[]));
  }

  getByFederalId(federalId: string): Observable<Customer> {
    return this.http
      .get(
        environment.apiHost + '/secure/customer/federal-id/' + federalId + '/'
      )
      .pipe(map((response: any) => response.data.customer as Customer));
  }

  getByboatId(boatId: number): Observable<any> {
    return this.http
      .get(environment.apiHost + '/secure/customers/boat/' + boatId)
      .pipe(map((response: any) => response.data.customers as any[]));
  }

  create(customer: Customer): Observable<Customer> {
    return this.http
      .post(environment.apiHost + '/secure/customer/', customer)
      .pipe(map((response: any) => response.data.customer));
  }

  update(customer: Customer): Observable<Customer> {
    return this.http
      .put(environment.apiHost + '/secure/customer/', customer)
      .pipe(map((response: any) => response.data.customer));
  }

  updateStatus(customerId: number, active: boolean): Observable<Customer> {
    return this.http
      .put(
        environment.apiHost + `/secure/customer/update-status/${customerId}`,
        active
      )
      .pipe(map((response: any) => response));
  }

  updateReviewed(customerId: number, reviewed: boolean): Observable<Customer> {
    return this.http
      .put(
        environment.apiHost + `/secure/customer/update-reviewed/${customerId}`,
        reviewed
      )
      .pipe(map((response: any) => response));
  }

  delete(id: number): Observable<Customer> {
    return this.http
      .delete(environment.apiHost + '/secure/customer/' + id)
      .pipe(map((response: any) => response.data.customer));
  }

  countCustomerWithNoGroup(): Observable<number> {
    return this.http
      .get(environment.apiHost + '/secure/customer/no-group')
      .pipe(map((response: any) => response.data.count as number));
  }

  countCustomersByGroup(groupId: number): Observable<number> {
    return this.http
      .get(environment.apiHost + '/secure/customer/count-group/' + groupId)
      .pipe(map((response: any) => response.data.count as number));
  }

  resolveFinancialBlock(body: {
    customerIds: number[];
    financialBlock: boolean;
  }): Observable<number> {
    return this.http
      .put(environment.apiHost + '/secure/customers/financial-block', body)
      .pipe(map((response: any) => response.data.count as number));
  }

  updateGroup(customerId: number, groupId: any): Observable<Customer> {
    return this.http
      .put(
        environment.apiHost + '/secure/customer/group/' + customerId,
        groupId
      )
      .pipe(map((response: any) => response.data.customer));
  }

  findByMarinaIdWithPagination(
    customerFilter: CustomerFilter
  ): Observable<any[]> {
    let params = new HttpParams();

    if (!customerFilter.sort || !customerFilter.order) {
      customerFilter.sort = 'name';
      customerFilter.order = 'ASC';
    }
    Object.keys(customerFilter).forEach((key) => {
      if (customerFilter[key] != null) {
        params = params.set(key, customerFilter[key]);
      }
    });
    params = params.set(
      'sort',
      customerFilter.sort.concat(',').concat(customerFilter.order)
    );
    params = params.set('page', customerFilter.page.toString());
    params = params.set('size', customerFilter.size.toString());
    return this.http
      .get(environment.apiHost + '/secure/customers/pagination', { params })
      .pipe(map((response: any) => response as any));
  }

  uploadLicence(files: any[]): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('attachment', files[0]);
    let hd = new HttpHeaders();
    hd = hd.set('Content-Type', 'multipart/form-data');
    const options = {
      headers: hd,
    };
    return this.http
      .post(
        environment.apiHost + '/secure/customers/licence-file/attachment',
        formData,
        options
      )
      .pipe(map((response: any) => response.data.attachment));
  }

  findInactiveCustomersInMarine(): Observable<any> {
    return this.http
      .get(
        environment.apiHost + '/secure/customers/filter-list/inactive-status'
      )
      .pipe(map((response: any) => response.data.customers));
  }

  getAllNotBlock(): Observable<any[]> {
    return this.http
      .get(environment.apiHost + '/secure/customernotblock/findmarina')
      .pipe(map((response: any) => response.data.customersnotblock as any[]));
  }

  deleteList(id: any): Observable<any> {
    return this.http
      .delete(environment.apiHost + '/secure/customernotblock/' + id)
      .pipe(map((response: any) => response.data.confirme as any[]));
  }

  startAutomaticNotBlock(): Observable<any> {
    return this.http
      .get(environment.apiHost + '/secure/customernotblock/execblock')
      .pipe(map((response: any) => response.data.confirme as any));
  }

  getCustomerDependents(customerId: number): Observable<any[]> {
    return this.http
      .get(`${environment.apiHost}/secure/customer/dependents/${customerId}`)
      .pipe(map((response: any) => response.data.dependents as any[]));
  }

  addDependentToCustomer(
    customerDependentDTO: CustomerDependentDTO
  ): Observable<any> {
    return this.http
      .post(
        `${environment.apiHost}/secure/customer/dependents`,
        customerDependentDTO
      )
      .pipe(map((response: any) => response));
  }

  deleteDependentFromMainCustomer(
    dependentCustomerId: number
  ): Observable<any> {
    return this.http
      .delete(
        `${environment.apiHost}/secure/customer/dependents/${dependentCustomerId}`
      )
      .pipe(map((response: any) => response));
  }

  getCustomerPropertiesToInvite(): Observable<any[]> {
    return this.http
      .get(
        `${environment.apiHost}/api-core/secure/securities-property/invite-customer`
      )
      .pipe(map((response: any) => response as any[]));
  }

  getCustomerToFastEdit(search: string): Observable<any[]> {
    return this.http
      .get(
        `${environment.apiHost}/api-core/secure/customer/get-customer-to-fast/` +
          search
      )
      .pipe(map((response: any) => response as any[]));
  }

  saveFastEdit(form: any): Observable<any> {
    return this.http
      .post(
        `${environment.apiHost}/api-core/secure/customer/save-fast-edit`,
        form
      )
      .pipe(map((response: any) => response as any));
  }

  printCustomerFile(id: number) {
    return this.http
      .get(environment.apiHost + '/secure/customer/print/' + id, {
        responseType: 'blob',
      })
      .pipe(map((res: any) => res));
  }
}
