<div class="dialog-form">
    <p-table *ngIf="uploads?.length > 0" [value]="uploads" autoLayout="true">
        <ng-template pTemplate="header">
            <tr>
                <th class="text-center"></th>
                <th class="text-center">Arquivo</th>
                <th class="text-center">Observação</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-payment>
            <tr>
                <td class="text-center" *ngIf="payment.success">
                    <i class="pi pi-check" style="color: green"></i>
                </td>
                <td class="text-center" *ngIf="!payment.success">
                    <i class="pi pi-times" style="color: lightcoral"></i>
                </td>
                <td class="text-center">{{ payment.name}}</td>
                <td class="text-center" *ngIf="payment.success" style="color: green;">{{ payment.errorMessage }}</td>
                <td class="text-center" *ngIf="!payment.success">{{ payment.errorMessage }}</td>
            </tr>
        </ng-template>
    </p-table>
</div>
