import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Customer, Marina, SlingConfig } from 'src/app/models';
import { MarinaCompany } from 'src/app/models/marina-company';
import { CustomerService } from 'src/app/services/customer.service';
import { FocusFieldService } from 'src/app/services/focus-field.service';
import { InvoiceService } from 'src/app/services/invoice.service';
import { MarinaOrderService } from 'src/app/services/marina-order.service';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';
import { FormatUtil } from 'src/app/utils/format.util';
import { MessageUtil } from 'src/app/utils/message.util';
import { StorageUtil } from 'src/app/utils/storage.util';
import moment from 'moment';
import { InvoicePaymentComponent } from 'src/app/components/invoice-payment/invoice-payment.component';
import Swal from 'sweetalert2';
import { ExtractInvoiceComponent } from 'src/app/components/extract-invoice/extract-invoice.component';
import b64toBlob from 'b64-to-blob';
import { OrderFormComponent } from '../order/order-form/order-form.component';
import { DebitsItemsComponent } from 'src/app/components/debits-items/debits-items.component';
import { DialogService } from 'primeng/dynamicdialog';
import { OrderReferenceDateUtil } from 'src/app/utils/order-reference-date.util';
import { GenerateOrders } from 'src/app/models/dtos/generate-ordersDTO';
import { FinancialMultipleCompaniesService } from 'src/app/services/financial/financial-multiple-companies.service';
import { FilterBillingCompanySelected } from 'src/app/components/filter/filter-billing-company/filter-billing-company.component';

@Component({
  selector: 'app-debits',
  templateUrl: './debits.component.html',
  styleUrls: ['./debits.component.scss']
})
export class DebitsComponent implements OnInit {

  numberOfRows = 10;
  filteredCustomers: any;
  filterBillingCompanyByProduct: FilterBillingCompanySelected = { filterBillingCompany: false,  billingCompany: undefined}
  customers: any[] = [];
  customer: any = {};
  customerFilter: any = { boats: new Array() };
  orders: any[] = new Array();
  invoices: any[] = new Array();
  orderSelected = false;
  idCliente: number;
  showSelectCustomers = false;
  marinaCompanies: MarinaCompany[];
  marinaCompaniesWithDatabase: MarinaCompany[];
  loggedMarina: Marina;
  slingConfig: SlingConfig;

  dueDate;
  competenceDate;
  referenceMonth;
  referenceYear;
  maturityType;

  constructor(
    private route: ActivatedRoute,
    private messageService: MessageUtil,
    private customerService: CustomerService,
    private orderService: MarinaOrderService,
    private invoiceService: InvoiceService,
    private userService: UserService,
    private dialog: DialogService,
    private sanitizer: DomSanitizer,
    private spinner: NgxSpinnerService,
    private toasty: ToastService,
    public focusFieldService: FocusFieldService,
    private slingConfigService: SlingConfigService,
    private orderReferenceDateUtil: OrderReferenceDateUtil,
    private financialMultipleCompaniesService: FinancialMultipleCompaniesService
  ) { }

  ngOnInit(): void {
    this.loggedMarina = StorageUtil.getMarina();
    this.marinaCompanies = this.loggedMarina.marinaCompanies;
    this.marinaCompaniesWithDatabase = StorageUtil.getMarinaCompanies();
    this.loadSlingConfig();
    this.loadCustomers();
  }

  loadSlingConfig(): void {
    this.slingConfigService.getSlingConfig().subscribe(
      (data) => {
        this.slingConfig = data[0];
        this.numberOfRows = this.slingConfig != null ? this.slingConfig.numberOfRecordsPerPage : 10;
      },
      (error) => null);
  }

  filterGlobal(event): void {
    const value = event.target.value;
    this.filteredCustomers = this.customers.filter(
      (c) =>
        (FormatUtil.getNotAccents(c.name).toUpperCase().includes(FormatUtil.getNotAccents(value).toUpperCase()) ||
          c.name.toUpperCase().includes(value.toUpperCase())
        )
    ).map((c) => ({ label: c.name, value: c.id }));
  }

  onShowSelectCustomers(option: boolean): void {
    setTimeout(() => {
      this.showSelectCustomers = option;
      this.focusFieldService.dispatchEvent();
    }, 500);
  }

  loadCustomer(): void {
    this.customerService.getById(this.route.snapshot.params.idCliente).subscribe(
      (customer) => {
        this.customer = customer;
        this.selectCustomer(customer.id);
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }

  clearCustomer(): void {
    this.customerFilter = { boats: new Array() };
    this.invoices = new Array();
    this.orders = new Array();
  }

  selectCustomer(idCustomer): void {
    const customer = this.customers.find((c) => c.id === idCustomer);
    this.customer = new Customer();
    this.customer.id = customer.id;
    this.customer.name = customer.name;
    this.customerFilter = customer;
    this.idCliente = customer.id;
    this.findByfiltersChange();
  }

  filterBillingCompanyByProductChange(event: any) {
    this.filterBillingCompanyByProduct = event;
    this.findByfiltersChange();
  }

  findByfiltersChange() {
    this.getOrderDebits();
    this.getInvoiceDebits(null, false);
  }

  getOrderDebits(): void {
    this.orderService.getDebits(this.customerFilter.id, this.filterBillingCompanyByProduct).subscribe(
      (data) => {
        this.orders = data;
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title, exception.message);
      });
  }

  getInvoiceDebits(ordersSelected, option): void {
    this.invoiceService.getDebits(this.customerFilter.id, this.filterBillingCompanyByProduct).subscribe(
      (data) => {
        this.invoices = data;
        if (option) {
          const invoice = this.invoices.find((i) => i.orders.find((o) => o.id === ordersSelected[0].id) !== undefined);
          this.invoicePaidDialog(invoice);
        }
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title, exception.message);
      });
  }

  getTextToolTipRefDateTuitionOrder(order): string {
    let toolTipText = '';

    if (order.origin === 'TUITION') {
      toolTipText = moment(order.dateTuition).format('MM/YY');
    }
    return toolTipText;
  }

  getIconOrigin(invoice): string {
    if (!invoice.orders[0]) {
      return 'error';
    }
    let origin = invoice.orders[0].origin;
    if (invoice.orders.find((o) => o.origin !== origin) !== undefined) {
      return 'MULTIPLE';
    }
    switch (origin) {
      case 'DETACHED':
        return 'DETACHED';
      case 'SLING':
        return 'SLING';
      case 'TUITION':
        return 'TUITION';
      case 'LEGACY_IMPORT':
        return 'LEGACY_IMPORT';
      case 'WORKSHOP':
        return 'WORKSHOP';
    }
  }

  getTextToolTipRefDateTuitionOrigin(invoice): string {
    let toolTipText = '';
    if (!invoice.orders.find((o) => o.origin !== 'TUITION')) {
      invoice.orders.forEach(order => {
        toolTipText += moment(order.dateTuition).format('MM/YY') + ' \n';
      });

      toolTipText = toolTipText.substring(0, toolTipText.length - 2);
    }
    return toolTipText;
  }

  loadCustomers(): void {
    this.customerService.getAllSimple().subscribe(
      (data) => {
        this.customers = data;
        this.filteredCustomers = [{ label: 'Selecione o cliente', value: undefined }];
        this.filteredCustomers = this.filteredCustomers.concat(
          this.customers.map((c) => ({ label: FormatUtil.getNotAccents(c.name), value: c.id }))
        );
        if (this.route.snapshot.params.idCliente) {
          this.loadCustomer();
        }
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }

  getMarinaOrdersById(idMarinaOrder): void {
    this.spinner.show();
    this.orderService.getById(idMarinaOrder).subscribe(
      (data) => {
        this.openOrderDetail(data);
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }

  openOrderDetail(marinaOrder): void {
    const dialog = this.dialog.open(OrderFormComponent, {
      dismissableMask: false,
      data: { marinaOrder }
    });
    this.spinner.hide();
    dialog.onClose.subscribe(() => this.selectCustomer(this.customer.id));
  }

  invoicePaidDialog(invoice: any): void {
    this.dialog.open(InvoicePaymentComponent, {
      width: '900px',
      dismissableMask: false,
      data: { invoice },
      header: 'Baixar fatura n.º ' + invoice.invoiceNumber
    }).onClose.subscribe(() => {
      this.getInvoiceDebits(null, false);
    });
  }

  async confirmGenerateInvoice(option): Promise<void> {
    if(!option) {
      const dateReference = await this.orderReferenceDateUtil.verifyRefereceDate(this.getOrdersSelected());
      if(!dateReference) return;

      const datesSelection = await this.orderReferenceDateUtil.selectDateForInvoices(this.slingConfig);
      if(datesSelection){
        this.dueDate = datesSelection.dueDate;
        this.competenceDate = datesSelection.competenceDate;
        this.referenceMonth = datesSelection.referenceMonth + 1;
        this.referenceYear = datesSelection.referenceYear;
        this.maturityType = datesSelection.maturityType;
        this.generateInvoice(option);
      }
    } else {
      Swal.fire({
        title: 'Informação',
        text:'Deseja faturar e pagar os pedidos selecionados?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar',
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          this.dueDate = null;
          this.competenceDate = null;
          this.referenceMonth = null;
          this.referenceYear = null;
          this.maturityType = null;
          this.generateInvoice(option);
        }
      });
    }
  }

  generateInvoice(option): void {
    this.spinner.show();
    const ordersSelected = this.getOrdersSelected();
    const generateOrders: GenerateOrders = {
      orders: ordersSelected,
      competenceDate: this.competenceDate,
      dueDate: this.dueDate,
      referenceMonth: this.referenceMonth,
      referenceYear: this.referenceYear
    };
    this.invoiceService.generateSelected(generateOrders).subscribe(
      (data) => {
        this.getOrderDebits();
        this.getInvoiceDebits(ordersSelected, option);
        this.toasty.success('Fatura(s) gerada(s) com sucesso!');
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }

  extract(idInvoice): void {
    this.spinner.show();
    this.invoiceService.extract(idInvoice).subscribe(
      (data) => {

        this.openExtract(
          this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL((b64toBlob as any)(data, 'application/pdf')))
        );
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title, exception.message);
      },
      () => this.spinner.hide());
  }

  openExtract(path): void {
    this.dialog.open(ExtractInvoiceComponent, {
      width: '100%',
      height: '100%',
      dismissableMask: false,
      data: { path },
      header: 'Extrato da fatura - PDF'
    });
  }

  getTotalOrders(): any {
    return this.orders.reduce((total, order) => total + order.value, 0);
  }

  getTotalOrderCustomer(): any {
    return this.orders.reduce((total, order) => total + this.getTotalCustomer(order.customers), 0);
  }

  getTotalCustomer(customers: any): number {
    return customers.reduce((value, customer) => {
      if (customer.id === this.customerFilter.id) {
        return value + customer.value;
      }
      return value;
    }, 0);
  }

  getTotalInvoices(company?: MarinaCompany): number {
    if (company) {
      const companyInvoices = this.invoices.filter((i) => i.marinaCompany && i.marinaCompany.id === company.id);
      const reduce = companyInvoices.reduce((total, invoice) => total + invoice.pendingValue, 0);
      return reduce;
    } else {
      const marinaInvoices = this.invoices.filter((i) => !i.marinaCompany);
      return marinaInvoices.reduce((total, invoice) => total + invoice.pendingValue, 0);
    }
  }

  selectAllOrders(): void {
    this.orders.forEach((order) => order.selected = this.orderSelected);
  }

  getOrdersSelected(): any[] {
    return this.orders.filter((order) => order.selected);
  }

  hasSelected(): any {
    return this.orders.some((order) => order.selected);
  }

  openDialogItensRequest(order: any): void {
    this.dialog.open(DebitsItemsComponent, {
      data: { order },
      width: '80%',
      header: 'Itens do pedido n.º ' + order.orderNumber
    });
  }

  getCompanyIndex(company: MarinaCompany): number {
    const m = this.marinaCompanies.find((c) => c.id === company.id);
    return this.marinaCompanies.indexOf(m) + 2;
  }

  getCompanyName(company: MarinaCompany): string {
    const c = this.marinaCompanies.find((m) => m.id === company.id);
    return c.companyFederalName ? c.companyFederalName : '';
  }

  hasFinancialMultipleByProductFilter(slingConfig: SlingConfig): boolean {
    return this.financialMultipleCompaniesService.hasFinancialMultipleCompaniesByProduct(slingConfig);
  }
}
