import {Component, OnInit} from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {Tuition} from '../../../../../models/tuition';
import {SlingConfig} from '../../../../../models';
import {InvoiceCustomerDTO} from '../../../../../models/dtos/invoice-customerDTO';
import moment from 'moment';
import {TuitionService} from '../../../../../services/tuition.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {MessageUtil} from '../../../../../utils/message.util';
import {InvoiceService} from '../../../../../services/invoice.service';

@Component({
  selector: 'app-invoice-generated-by-tuition',
  templateUrl: './invoice-generated-by-tuition.component.html',
  styleUrls: ['./invoice-generated-by-tuition.component.scss']
})
export class InvoiceGeneratedByTuitionComponent implements OnInit {
  tuitionToInvoiceList: any[] = [];
  slingConfig: SlingConfig;
  invoicesCustomersDTOs: InvoiceCustomerDTO[] = [];
  selectedTuition: Tuition[];
  today = new Date();
  tuition: any;

  constructor(
    public config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private tuitionService: TuitionService,
    private spinner: NgxSpinnerService,
    private messageService: MessageUtil,
    private invoiceService: InvoiceService,
  ) {
  }

  ngOnInit(): void {
    this.slingConfig = this.config.data.slingConfig;
    this.selectedTuition = this.config.data.selectedTuitions;
    this.tuition = this.config.data.tuition;
    this.populateTuitionToInvoiceList(this.selectedTuition);
  }

  populateTuitionToInvoiceList(tuitionsSelected: Tuition[]): void {
    for (const tuition of tuitionsSelected) {
      let dueDate;
      const dateRef = moment(tuition.dateReferences, 'YYYY-MM-DD').toDate();
      const dueDay: number = tuition.marinaOrder?.orderQuotas[0].customer.dueDate;
      dueDate = new Date(dateRef);
      dueDate.setDate(dueDay);
      if (this.slingConfig.earlyPayment != null && !this.slingConfig.earlyPayment) {
        dueDate.setMonth(dueDate.getMonth() + 1);
      }
      const newTuitionToInvoice = {
        referenceDate: dateRef,
        tuition,
        dueDate,
        competenceDate: dueDate,
      };
      this.tuitionToInvoiceList.push(newTuitionToInvoice);
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  submit(): void {
    this.spinner.show();
    const boatTuitions = this.getBoatTuitions();
    this.tuitionService.generateByBoat(boatTuitions).subscribe(
      (newTuitions) => {
        this.updateTuitions(newTuitions);
        this.populateInvoices();
        this.generateInvoices();
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageService.generateMessage(exception.type, exception.title, exception.message);
        this.spinner.hide();
      }
    );
  }

  generateInvoices(): void {
    this.invoiceService.generateInvoiceCustomersByList(this.invoicesCustomersDTOs).subscribe(
      () => {
        this.messageService.generateMessage('success', 'Sucesso', 'Faturas geradas com sucesso.');
        this.spinner.hide();
        this.cancel();
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageService.generateMessage(
          exception.type,
          exception.title,
          exception.message
        );
      }
    );
  }

  updateTuitions(newTuitions: Tuition[]): void {
    for (const newTuition of newTuitions) {
      for (const oldTuition of this.tuitionToInvoiceList) {
        const newReferenceDate = moment(newTuition.dateReferences, 'YYYY-MM-DD').toDate();
        if (newReferenceDate.getTime() === oldTuition.referenceDate.getTime()) {
          if (oldTuition.referenceDate.getMonth() === this.today.getMonth()){
            oldTuition.referenceDate = this.today;
          }
          oldTuition.tuition = newTuition;
        }
      }
    }
  }

  populateInvoices(): void {
    for (const tuitionToInvoice of this.tuitionToInvoiceList) {
      const customerIds = [];
      for (const orderQuota of tuitionToInvoice.tuition.marinaOrder.orderQuotas) {
        customerIds.push(orderQuota.customer.id);
      }
      const invoice = new InvoiceCustomerDTO(
        tuitionToInvoice.referenceDate,
        tuitionToInvoice.dueDate,
        tuitionToInvoice.competenceDate,
        customerIds,
        [tuitionToInvoice.tuition.marinaOrder.orderNumber],
        null
      );
      this.invoicesCustomersDTOs.push(invoice);
    }
  }

  private getBoatTuitions(): any[] {
    const boatTuitions = [];
    this.selectedTuition.forEach((t: any) => {
      boatTuitions.push(
        {
          idServiceBoat: this.tuition.serviceBoatId,
          referenceDate: moment(t.dateReferences, 'YYYY-MM-DD').toDate()
        }
      );
    });
    return boatTuitions;
  }
}
