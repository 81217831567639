
import { Component, Input, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FinancesService } from 'src/app/services/finances.service';

@Component({
  selector: 'app-user-log-modal',
  templateUrl: './user-log-modal.component.html',
  styleUrls: ['./user-log-modal.component.scss']
})
export class UserLogModalComponent implements OnInit {
  userLog;

  isGeneralData = false;


  constructor(
    private config: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef
  ) { }

  ngOnInit(): void {
    if(!this.config.data.userLog || !this.config.data.userLog.oldDatas){
       this.dialogRef.close();
    }
    this.userLog = this.config.data.userLog;
    this.createLogs();
  }

  createLogs() {
    this.verifyChanges();
  }

  verifyChanges() {
    const oldDatasMap = new Map(Object.entries(this.userLog.oldDatas));
    this.verifyChangesGeneralData(oldDatasMap);
  }

  verifyChangesGeneralData(oldDatasMap: Map<string, unknown>) {
    this.isGeneralData = true;

  }



}
