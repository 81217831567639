<p-fieldset class="half">
    <p-header class="article-title">
        <div class="article-title">{{'IMAGE' | translate}}</div>
    </p-header>
    <div class="content-log">
        <div [ngClass]="{'item-new-log': isChangedFile() && isNew, 'item-old-log': isChangedFile() && !isNew}">
            <img src="{{ isChangedFile() && isNew ?productLog.file: productLog.oldDatas.file }}" width="128">
        </div>
    </div>
</p-fieldset>
<br>