<div class="dialog-form" style="width: 100%;">
  <p-tabView [activeIndex]="selectedTab" *ngIf="slingConfig">

    <p-tabPanel [disabled]="!pageControl || pageControl[selectedTab] !== 'data-cliente'">
      <ng-template pTemplate="header">
        <fa-icon [icon]="'ship'"></fa-icon>&nbsp;
        {{ 'SLING-DATA' | translate }}
      </ng-template>
      <ng-template pTemplate="content">
        <app-movement-data
          *ngIf="isNew || movementPlan?.id"
          [movementPlan]="movementPlan"
          [slingConfig]="slingConfig"
          [isNew]="isNew"
          (cancelPageEvent)="cancel()"
          (finishEvent)="nextPage($event)">
        </app-movement-data>
      </ng-template>
    </p-tabPanel>

    <p-tabPanel *ngIf="objectives" [disabled]="!pageControl || pageControl[selectedTab] !== 'objective'">
      <ng-template pTemplate="header">
        <fa-icon [icon]="'route'"></fa-icon>&nbsp;
        {{ 'Objetivo' | translate }}
      </ng-template>
      <ng-template pTemplate="content">
        <app-movement-plan-objectives-form
          *ngIf="isNew || movementPlan?.id"
          [movementPlan]="movementPlan"
          [isNew]="isNew"
          [objectives]="objectives"
          (backPageEvent)="previouPage()"
          (cancelPageEvent)="cancel()"
          (finishEvent)="nextPageSelectObjective($event)">
        </app-movement-plan-objectives-form>
      </ng-template>
    </p-tabPanel>

    <p-tabPanel *ngFor="let quest of objectiveSelected?.questionnaires; let i = index" [disabled]="!pageControl || pageControl[selectedTab] !== 'q'+i">
      <ng-template pTemplate="header">
        <fa-icon [icon]="'question'"></fa-icon>&nbsp;
        {{ quest.title | translate }}
      </ng-template>
      <ng-template pTemplate="content">
        <app-movement-plan-objective-questionnaire
          *ngIf="isNew || movementPlan?.id"
          [movementPlan]="movementPlan"
          [isNew]="isNew"
          [questionnaire]="quest"
          (backPageEvent)="previouPage()"
          (cancelPageEvent)="cancel()"
          (finishEvent)="nextPageFromQuestionnaire($event, true)">
        </app-movement-plan-objective-questionnaire>
      </ng-template>
    </p-tabPanel>

    <p-tabPanel [disabled]="!pageControl || pageControl[selectedTab] !== 'navigation-plan'">
      <ng-template pTemplate="header">
        <fa-icon [icon]="'map'"></fa-icon>&nbsp;
        {{ 'Aviso de Saída' | translate }}
      </ng-template>
      <ng-template pTemplate="content">
        <app-movement-navigation-plan
          *ngIf="isNew || movementPlan?.id"
          [movementPlan]="movementPlan"
          [isNew]="isNew"
          [hasNavigationPlan]="objectiveSelected?.hasNavigationPlan"
          [slingConfig]="slingConfig"
          (backPageEvent)="previouPage()"
          (cancelPageEvent)="cancel()"
          (finishEvent)="nextPage($event)">
        </app-movement-navigation-plan>
      </ng-template>
    </p-tabPanel>
  </p-tabView>
</div>
