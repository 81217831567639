import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-debits-items',
  templateUrl: './debits-items.component.html',
  styleUrls: ['./debits-items.component.scss']
})
export class DebitsItemsComponent implements OnInit {

  order: any;
  constructor(
    public dialog: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {
    this.order = this.config.data.order;
  }

  ngOnInit(): void {
  }

}
