import { Component, OnInit } from '@angular/core';
import { LazyLoadEvent, MenuItem } from 'primeng/api';
import { CustomerCreditService } from './../../services/customer-credit/customer-credit.service';
import { DialogService } from 'primeng/dynamicdialog';
import { CustomerCreditLaunchComponent } from './customer-credit-launch/customer-credit-launch.component';
import { CustomerCreditHistoricComponent } from './customer-credit-historic/customer-credit-historic.component';
import { CustomerCreditCustomer } from 'src/app/models/customercredit/customer-credit-customer';
import { CustomReportExportComponent } from 'src/app/components/extract-custom-report/custom-report-export.component';

@Component({
  selector: 'app-customer-credit-page',
  templateUrl: './customer-credit-page.component.html',
  styleUrls: ['./customer-credit-page.component.scss'],
})
export class CustomerCreditPageComponent implements OnInit {
  customerName: string = '';
  customers: CustomerCreditCustomer[] = [] ;
  loading: boolean = false;
  show: boolean = false;
  menuItems: MenuItem[] = [];
  selectedCustomer: CustomerCreditCustomer;
  totalRecords: number = 0;
  first: number = 0;
  rows: number = 50;
  totalValue: number = 0;

  constructor(
    private customerCreditService: CustomerCreditService,
    private dialog: DialogService,
  ) {}


  ngOnInit() {
    this.loadMenuItems();
    this.getCustomers();
  }

  getCustomers() {
    this.loading = true;
    this.customerCreditService.getCustomersCredit(this.customerName).subscribe({
      next: (response) => {
        if (this.show) {
          this.customers = response;
        } else {
          this.customers = response.filter(
            (customer) => customer.balanceNow !== 0
          );
        }
        this.totalRecords = this.customers.length;
        this.totalValue = this.customers.reduce(
          (total, customer) => total + customer.balanceNow,
          0
        );
        this. updatePageInfo({first: 0, rows: 50});
        this.loading = false;
      },
      error: (error: any) => {
        this.loading = false;
        console.error(error);
      },
    });
  }

  openCreditModal(credit: boolean) {
    this.dialog.open(CustomerCreditLaunchComponent, {
      header: 'Lançamento de Crédito',
      height: '450px',
      width: '950px',
      data: {
        credit:credit,
        customer: this.selectedCustomer
      }
    }).onClose.subscribe((reflesh: boolean) => {
      if (reflesh) {
        this.getCustomers()
      }
    });
  }


  history() {
    this.dialog.open(CustomerCreditHistoricComponent, {
      header: 'Histórico de Crédito',
      height: '90%',
      width: '750px',
      data: {
        customer: this.selectedCustomer
      }
    });
  }

  updatePageInfo(event: LazyLoadEvent) {
    this.first = event.first + event.rows  || 0;
    if (this.first > this.totalRecords) {
      this.first = this.totalRecords;
    }
    this.rows = event.rows || 50;
  }

  loadMenuItems() {
    this.menuItems = [];

    this.menuItems.push({
      label: 'Adicionar Crédito',
      icon: 'pi pi-plus',
      command: () => {
        this.openCreditModal(true);
      },
    });

    this.menuItems.push({
      label: 'Subtrair Crédito',
      icon: 'pi pi-minus',
      command: () => {
        this.openCreditModal(false);
      },
    });

    this.menuItems.push({
      label: 'Histórico',
      icon: 'pi pi-list',
      command: () => {
        this.history();
      },
    });
  }

  changeSelectedCustomer(customer: CustomerCreditCustomer) {
    this.selectedCustomer = customer;
  }

  exportTable() {
    let report = [...this.customers];
    report.push({ id:null, name:null,email:null,phone:null, balanceNow: this.totalValue });
    const data = { table:  this.customers.map((f) => {
      return {
        name: f.name,
        email: f.email,
        phone: f.phone,
        balanceNow: f.balanceNow
      }
    }), type: 'CUSTOMER_CREDIT' };
    this.dialog.open(CustomReportExportComponent, {
      width: '70%',
      height: '90vh',
      dismissableMask: false,
      data,
      header: 'Exportar Lista de Clientes',
    });
  }
}
