import { AccessGroupEnum } from "./access-group-enum";

export class AccessGroup {
    id: number;
    name: string;
    type: AccessGroupEnum;
    description: string;
    marinaId: number;
    active: boolean = true;
    showSuplier: boolean = false;
    useInvitation: boolean = false;
}
