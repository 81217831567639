import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CancelMovementDTO } from "src/app/models/dtos/movements/cancel-movementDTO";
import { ExemptMovementDTO } from "src/app/models/dtos/movements/exempt-movementDTO";
import { CreateAdjustmentInMovementPlanDTO } from "src/app/models/dtos/movements/movement-management/create-adjustment-in-movement-planDTO";
import { CreateMovementPlanAdjustmentDTO } from "src/app/models/dtos/movements/movement-management/create-movement-plan-adjustmentDTO";
import { MovementPlanAdjustmentDTO } from "src/app/models/dtos/movements/movement-management/movement-plan-adjustmentDTO";
import { VerificationMovementAdjustmentRspDTO } from "src/app/models/dtos/movements/movement-management/verification-movement-adjustment-rspDTO";
import { VerificationMovementAdjustmentDTO } from "src/app/models/dtos/movements/movement-management/verification-movement-adjustmentDTO";
import { Movement } from "src/app/models/movement/movement";
import { environment } from "src/environments/environment";

@Injectable()
export class MovementManagementService {
    private readonly API_URL = environment.apiHost + '/secure/movement/movement-management'

    constructor(private http: HttpClient) { }

    cancelMovement(cancelMovement: CancelMovementDTO): Observable<any> {
        return this.http.post(this.API_URL + '/cancel', cancelMovement)
            .pipe(map((response: any) => response.data));
    }

    exemptMovement(exemptMovement: ExemptMovementDTO): Observable<any> {
        return this.http.post(this.API_URL + '/exempt', exemptMovement)
            .pipe(map((response: any) => response.data));
    }

    findMovementsByBoatAndDate(boatId: number, date: string): Observable<MovementPlanAdjustmentDTO> {
        let params = new HttpParams();
        params = params.set('boatId', boatId.toString());
        params = params.set('date', date);

        return this.http.get(this.API_URL + '/adjustment', {params: params})
            .pipe(map((response: any) => response.data.movementPlanAdjustment as MovementPlanAdjustmentDTO));
    }

    adjustmentNewMovementPlan(newMovementPlan: CreateMovementPlanAdjustmentDTO): Observable<any> {
        return this.http.post(this.API_URL + '/adjustment/movement-plan', newMovementPlan)
            .pipe(map((response: any) => response.data));
    }

    adjustmentInMovementPlan(newMovementPlan: CreateAdjustmentInMovementPlanDTO): Observable<any> {
        return this.http.put(this.API_URL + '/adjustment/movement-plan', newMovementPlan)
            .pipe(map((response: any) => response.data));
    }

    verificationAdjustment(verificationAdjustment: VerificationMovementAdjustmentDTO): Observable<VerificationMovementAdjustmentRspDTO> {
        return this.http.post(this.API_URL + '/adjustment/verification-adjustment', verificationAdjustment)
            .pipe(map((response: any) => response.data.verificationAdjustment as VerificationMovementAdjustmentRspDTO));
    }

    getMovementById(id): Observable<Movement> {
      return this.http.get(this.API_URL + '/adjustment/movement/find-by-id/'+id)
          .pipe(map((response: any) => response.data.movement as Movement));
  }
}
