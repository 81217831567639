import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { AccessPoint } from 'src/app/models/dtos/access-controller/accessPoint';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AccessPointService {
  env:string = environment.apiHost + '/api-core/secure/access-point/';
  constructor(private http: HttpClient) {}


  getAllByMarina(): Observable<AccessPoint[]> {
    return this.http
      .get(this.env + 'list-all')
      .pipe(map((response: any) => response as AccessPoint[]));
  }

  getAllByMarinaAndActive(active:boolean): Observable<AccessPoint[]> {
    return this.http
      .get(this.env + 'list-all/' + active)
      .pipe(map((response: any) => response as AccessPoint[]));
  }

  save(accessPoint: AccessPoint): Observable<AccessPoint> {
    return this.http
      .post(this.env + 'save', accessPoint)
      .pipe(map((response: any) => response as AccessPoint));
  }

  delete(id: number): Observable<void> {
    return this.http
      .delete(this.env + 'delete/' + id)
      .pipe(map((response: any) => response as void));
  }

}
