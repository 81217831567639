import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastService } from 'src/app/services/toast.service';
import { OperatingDayConfigService } from 'src/app/services/config/operating-day-config.service';
import { getTimeValidatorsIsBefore } from 'src/app/validators/date/times';
import { OperatingDay } from '../operating-day-config.component';

@Component({
  selector: 'app-operating-day',
  templateUrl: './operating-day.component.html',
  styleUrls: ['./operating-day.component.scss']
})
export class OperatingDayComponent implements OnInit {

  OperatingDaysSaved: OperatingDay[] = [];

  form: UntypedFormGroup;
  get days(): UntypedFormArray {
    return this.form?.controls?.days as UntypedFormArray;
  }

  constructor(
    private operatingDayConfigService: OperatingDayConfigService,
    private toasty: ToastService
  ) {}

  ngOnInit(): void {
    this.operatingDayConfigService.getAll().subscribe((data) => {
      this.createFormArray(data);
      this.setSaved(data);
    },
    (err) => {
      console.log(err);
    });
  }

  createFormArray(operatingDays: OperatingDay[]) {
    const dayControl = operatingDays.map(d => this.createDayControl(d))
    this.form = new UntypedFormGroup({
      days: new UntypedFormArray(dayControl, [])
    }, []);
  }

  createDayControl(day: OperatingDay): UntypedFormGroup {
    const dayControl = new UntypedFormGroup({
      id: new UntypedFormControl(day.id),
      dayWeek: new UntypedFormControl(day.dayWeek, [Validators.required]),
      inOperation: new UntypedFormControl(day.inOperation, [Validators.required]),
      operationStart: new UntypedFormControl({ value: day.operationStart, disabled: !day.inOperation}, [Validators.required]),
      operationEnd: new UntypedFormControl({ value: day.operationEnd, disabled: !day.inOperation}, [Validators.required])
    }, [
      getTimeValidatorsIsBefore('operationStart', 'operationEnd')
    ]);
    dayControl.controls.inOperation.valueChanges.subscribe((v) => {
      if (v) {
        dayControl.controls.operationStart.enable();
        dayControl.controls.operationEnd.enable();
      } else {
        dayControl.controls.operationStart.disable();
        dayControl.controls.operationEnd.disable();
      }
    })
    return dayControl;
  }

  saveAll() {
    const days = this.days.getRawValue();
    this.operatingDayConfigService.saveAll(days).subscribe((data) => {
      this.createFormArray(data);
      this.setSaved(data);
      this.toasty.success('Salvo com sucesso!');
    },
    (error) => {
      this.toasty.error('Ops, não foi possível salvar.');
    });
  }

  isNotChanged() {
    // nesse caso em especial a comparação ira funcionar
    return JSON.stringify(this.days.getRawValue()) === JSON.stringify(this.OperatingDaysSaved);
  }

  setSaved(operatingDays: OperatingDay[]) {
    this.OperatingDaysSaved = operatingDays.map(d => Object.assign({}, d));
  }

}
