<div class="table">
    <p-table #tt [value]="invoiceTaxLogs" [resizableColumns]="true" [autoLayout]="true" [paginator]="true"
    [rows]="numberOfRows" [pageLinks]="3" [rowsPerPageOptions]="isDialog? null : [10,20,30,50,100]">
        <ng-template pTemplate="header">
            <tr>
                <th>Data/Hora</th>
                <th>Numero da nota</th>
                <th>Usuário</th>
                <th>Empresa</th>
                <th>Nome do Cliente</th>
                <th>Valor</th>
                <th>Ação</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-invoiceTaxLogs>
            <tr>
               <td>{{invoiceTaxLogs.logTime | date: 'dd/MM/yyyy - HH:mm'}}</td>
               <td>{{invoiceTaxLogs.idExterno}}</td>
               <td>{{invoiceTaxLogs.user?.fullName}}</td>
               <td>{{invoiceTaxLogs.company}}</td>
               <td>{{invoiceTaxLogs.customerName}}</td>
               <td>{{invoiceTaxLogs.value |currency:'BRL'}}</td>
               <td>{{invoiceTaxLogs.operation | translate}}</td>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="7">
                    Nenhum log encontrado
                </td>
            </tr>
        </ng-template>

    </p-table>
</div>
