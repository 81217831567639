import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { AccessGroupService } from 'src/app/services/access-controler/access-group.service';
import { FormatUtil } from 'src/app/utils/format.util';
import { MessageUtil } from 'src/app/utils/message.util';
import Swal from 'sweetalert2';
import { AccessGroupFormComponent } from './access-group-form/access-group-form.component';
import { ToastService } from 'src/app/services/toast.service';
import { AccessGroupDTO } from 'src/app/models/dtos/access-controller/access-group-DTO';

@Component({
  selector: 'app-access-group',
  templateUrl: './access-group.component.html',
  styleUrls: ['./access-group.component.scss']
})
export class AccessGroupComponent implements OnInit {

  numberOfRows: number;
  groups: AccessGroupDTO[] = [];
  groupsTable: AccessGroupDTO[] = [];
  globalFilter = '';


  constructor(
    private dialog: DialogService,
    private accessGroupService: AccessGroupService,
    private messageUtil: MessageUtil,
    private toasty: ToastService,
    private spinner: NgxSpinnerService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.findAll();
  }

  filterGlobal(): void {
    this.groupsTable = this.groups.filter(
      (c) =>
        (FormatUtil.getNotAccents(c.name).toUpperCase()
          .includes(FormatUtil.getNotAccents(this.globalFilter).toUpperCase()) ||
          c.name.toUpperCase().includes(this.globalFilter.toUpperCase())
        )
    );
  }

  async openDialog(group): Promise<void> {
    const data = {
      group: (group || null),
    };
    const dialogRef = this.dialog.open(AccessGroupFormComponent, {
      width: '52%',
      dismissableMask: false,
      data,
      header: this.messageUtil.translateKey('ACCESS-GROUP-REGISTRATION')
    }).onClose.subscribe(() => { this.findAll(); });
  }

  edit(group): void {
    this.openDialog(group);
  }

  deleteConfirm(group): void {
    Swal.fire({
      text: 'Deseja realmente deletar o grupo ' + group.name + '?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((isConfirm) => {
      if (isConfirm.value) {
        this.delete(group.id);
      }
    });
  }

  delete(groupId): void {
    this.spinner.show();
    this.accessGroupService.delete(groupId).subscribe({
      next: () => {
        this.toasty.success(this.messageUtil.translateKey('GROUP-DELETED-SUCCESSFULLY'));
      },
      complete: () => {
        this.findAll();
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
        const exception = error.error.data ? error.error.data.exception : error.error;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    });
  }

  findAll() {
    this.spinner.show();
    this.accessGroupService.findAllWithQuantities().subscribe({
      next: (groups) => {
        this.groups = groups;
        this.filterGlobal();
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
        const exception = error.error.data ? error.error.data.exception : error.error;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      },
      complete: () => this.spinner.hide()
    });
  }

  getGroupTranslation(type: string): string {
    switch (type) {
      case 'OWNER':
        return 'Proprietário';
      case 'NON_MEMBER':
        return 'Não Membro';
      case 'EMPLOYEE':
        return 'Funcionário';
      case 'SAILOR':
        return 'Marinheiro';
      case 'SERVICE_PROVIDER':
        return 'Prestador de Serviço';
      case 'GUEST':
        return 'Convidado';
      default:
        return 'Sem tipo registrado';
      }
  }

  changeGroupStatus(event): void {
    switch(event?.index) {
      case 1:
        this.groupsTable = this.groups.filter(c => c.active);
        break;
      case 2:
        this.groupsTable = this.groups.filter(c => !c.active);
        break;
      default:
        this.groupsTable = this.groups;
    }

  }
}
