<div class="table">
    <p-table
        [value]="history"
    >
        <ng-template pTemplate="header">
            <tr>
                <th>{{ "Lingada" | translate }}</th>
                <th>{{ "Data/Hora" | translate }}</th>
                <th>{{ "Cliente" | translate }}</th>
                <th>{{ "Origem" | translate }}</th>
                <th>Plano de Navegação</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr>
                <td class="text-center">{{ rowData.slingId ? rowData.slingId : rowData.movementPlanId }}</td>
                <td class="text-center">{{ formatDate(rowData.dateUse) }}</td>
                <td class="text-center">{{ rowData.customerName }}</td>
                <td class="text-center">{{ rowData?.slingType }}</td>
                <td class="text-center">
                    <button
                        pButton
                        type="button"
                        icon="pi pi-file-pdf"
                        (click)="printNavitagionPlan(rowData)"
                    ></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
