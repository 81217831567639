import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LocationInvoiceService {
  constructor(private http: HttpClient) {}

  findAllByMarina(): Observable<any[]> {
    return this.http
      .get(`${environment.apiHost}/secure/boat/location/invoice/find-by-marina`)
      .pipe(map((response: any) => response.data.locationInvoice));
  }

  findAllByFilter(filter: any): Observable<any[]> {
    return this.http
      .post(
        `${environment.apiHost}/secure/boat/location/invoice/find-by-filter`,
        filter
      )
      .pipe(map((resp: any) => resp.data.locationInvoice));
  }
}
