import {Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {debounceTime, distinctUntilChanged, Subject, Subscription, takeUntil, tap, throttleTime} from "rxjs";

@Directive({
  selector: "[preventDoubleClickDirective]"
})
export class DebounceClickDirective implements OnInit, OnDestroy {
  @Input()
  public throttleTime: number;

  @Output()
  public throettleClick = new EventEmitter<any>();

  protected clickEvent$ = new Subject<any>()
  protected subscription$: Subscription

  constructor() {
  }

  ngOnInit(): void {
    this.subscription$ = this.clickEvent$.pipe(throttleTime(this.throttleTime)).subscribe(value => this.emitChange(value))
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe()
  }

  private emitChange(value: any) {
    this.throettleClick.emit(value)
  }

  @HostListener("click", ["$event"])
  public onKeyUp(event: any): void {
    event.preventDefault()
    event.stopPropagation()
    this.clickEvent$.next(event)
  }

}
