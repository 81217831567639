<p-fieldset class="dialog-form">
    <p-header class="article-title">
        <span class="article-title">{{ 'TITLE-CONTRACTS-TEMPLATE' | translate }}</span>
        <div class="primary fab-button" [routerLink]="['/app/contracts/template-edit',undefined]">
            <i class="pi pi-plus" style="font-size: 21px;"></i>
        </div>
    </p-header>
            <div class="table">
                <p-table #tt [value]="templates" resetPageOnSort="true" [pageLinks]="3" [paginator]="true"
                    [rowsPerPageOptions]="[10,20,30,50,100]" [autoLayout]="true" rows="100">
                    <ng-template pTemplate="header">
                        <tr>
                            <th [pSortableColumn]="'title'">
                                {{ 'TITLE' | translate }}
                                <p-sortIcon [field]="'title'"></p-sortIcon>
                            </th>
                            <th class="text-center" [pSortableColumn]="'lastUpdate'">
                                Data da última atualização
                                <p-sortIcon [field]="'lastUpdate'"></p-sortIcon>
                            </th>
                            <th class="text-center" [pSortableColumn]="'createdAt'">
                                Data de criação
                                <p-sortIcon [field]="'createdAt'"></p-sortIcon>
                            </th>
                            <th></th>
                            <th></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-template>
                        <tr>
                            <td>{{ template.title }}</td>
                            <td class="text-center">{{ template.lastUpdate | date: 'dd/MM/yyyy' }}</td>
                            <td class="text-center">{{ template.createdAt | date: 'dd/MM/yyyy' }}</td>
                            <td class="text-center">
                                <div class="table-action" [routerLink]="['/app/contracts/template-edit',template.id]" pTooltip="Editar"
                                    tooltipPosition="bottom">
                                    <i class="pi pi-pencil"></i>
                                </div>
                            </td>
                            <td class="text-center">
                                <div class="table-action" (click)="delete(template)" pTooltip="Deletar"
                                    tooltipPosition="bottom">
                                    <i class="pi pi-trash" style="color:  #EF5350"></i>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
</p-fieldset>
