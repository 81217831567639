import { PriceVersion } from './price-version';
import { PriceRange } from './price-range';

export class Price {
    id: number;
    value: number;
    priceVersion: PriceVersion;
    priceRanges: PriceRange[];
    priceFree: boolean;

    constructor() {
        this.priceVersion = new PriceVersion();
        this.priceRanges = [];
    }
}
