import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  MovementPlanByObjectiveCreateDTO,
  MovementQuestionaryAnswerDTO,
} from 'src/app/models/dtos/movements/movement-plan-by-objective-createDTO';
import {
  ObjectiveOptionQuestionnaireDTO,
  ObjectiveOptionQuestionnaireOptionsDTO,
} from 'src/app/models/dtos/movements/objectives/objective-option-dto';

@Component({
  selector: 'app-movement-plan-objective-questionnaire',
  templateUrl: './movement-plan-objective-questionnaire.component.html',
  styleUrls: ['./movement-plan-objective-questionnaire.component.scss'],
})
export class MovementPlanObjectiveQuestionnaireComponent implements OnChanges {
  @Output()
  finishEvent: EventEmitter<any> = new EventEmitter();
  @Output()
  backPageEvent: EventEmitter<void> = new EventEmitter();
  @Output()
  cancelPageEvent: EventEmitter<void> = new EventEmitter();

  @Input()
  readonly movementPlan: MovementPlanByObjectiveCreateDTO;

  @Input()
  readonly isNew: boolean;

  @Input()
  questionnaire: ObjectiveOptionQuestionnaireDTO = null;

  answerSelected: string;

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.movementPlan &&
      changes.movementPlan.currentValue != changes.movementPlan.previousValue
    ) {
      if (this.questionnaire && !this.isNew && !this.answerSelected) {
        const answers = this.movementPlan.questionnairesAnswer.filter(
          ({ questionnaireCode }) =>
            this.questionnaire && this.questionnaire.code === questionnaireCode
        );
        if (answers.length > 0) {
          this.answerSelected = answers[0].answerCode;
        }
      }
    }
  }

  selectOption(option: ObjectiveOptionQuestionnaireOptionsDTO): void {
    if (this.isNew) {
      this.answerSelected = option.code;
    }
  }

  next() {
    this.finishEvent.emit({questionnaireCode: this.questionnaire.code, answerCode: this.answerSelected});
  }

  back() {
    this.backPageEvent.emit(null);
  }

  cancel() {
    this.cancelPageEvent.emit(null);
  }

  getIconObj(option): string {
    if (this.questionnaire.code === 'SingleQuestionnaire') {
      switch (option?.code) {
        case 'DestinationNavigation':
          return 'assets/images/movements/single-movement/navigation.png';
        case 'DestinationYard':
          return 'assets/images/movements/single-movement/yard.png';
        case 'DestinationMoored':
          return 'assets/images/movements/single-movement/deck.png';
        case 'DestinationDryVacancy':
          return 'assets/images/movements/single-movement/dry-vacancy.png';
        case 'DestinationWetVacancy':
          return 'assets/images/movements/single-movement/wet-vacancy.png';
      }
    } else {
      if (option?.code === 'EmptyOption') {
        return 'assets/images/yard-empty.png';
      } else if (option?.code === 'OnlyYardBeforeOption') {
        return 'assets/images/Arrow-right.png';
      } else if (option?.code === 'OnlyYardAfterOption') {
        return 'assets/images/Arrow-left.png';
      } else if (option?.code === 'YardBeforeAndAfterOption') {
        return 'assets/images/Arrow-left-right.png';
      }
    }
  }
}
