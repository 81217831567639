import { ServiceBoatService } from 'src/app/services/service-boat.service';
import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ServiceHistoryReajustmentDTO } from 'src/app/models/dtos/service-history-reajustmentDTO';
import { ServiceBoatReadjustmentDTO } from 'src/app/models/dtos/service-boat-readjustmentDTO';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageUtil } from 'src/app/utils/message.util';
import { ToastService } from 'src/app/services/toast.service';
import Swal from 'sweetalert2';
import { servicesReadjustmentRegisterPriceFreeDTO } from 'src/app/models/dtos/services-readjustment-register-price-freeDTO';

@Component({
  selector: 'app-reajustment-historic',
  templateUrl: './reajustment-historic.component.html',
  styleUrls: ['./reajustment-historic.component.scss']
})
export class ReajustmentHistoricComponent implements OnInit {

  serviceBoat: ServiceBoatReadjustmentDTO;
  serviceHistoryReajustments : ServiceHistoryReajustmentDTO[];

  constructor(
    public dialog: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private spinner: NgxSpinnerService,
    private serviceBoatService: ServiceBoatService,
    private toasty: ToastService,
    private messageUtil: MessageUtil
  ) {
  }

  ngOnInit(): void {
    this.serviceBoat = this.config.data.serviceBoat;
    this.loadHistoric(this.serviceBoat.serviceBoatId);

  }

  loadHistoric(serviceId: number): void {

    this.serviceBoatService.getReajustmentHistoric(serviceId).subscribe((data) => {
      this.serviceHistoryReajustments = data;
    });

  }

  findReajustmentHistoric(serviceId : number): Promise<void> {
    return new Promise(resolve =>{
        this.spinner.show();
        this.serviceBoatService.getReajustmentHistoric(serviceId)
          .subscribe(data => {

            this.spinner.hide();
            resolve();
          }, error => {
            this.spinner.hide();
            console.log(error);
            resolve();
          });
    })

  }


   clickSend(): void{
    if (this.serviceHistoryReajustments?.length<=0){
      return
    }
    Swal.fire({
      title: 'Envio de Email',
      text: 'Confirma o envio de emails para os proprietários com a notificação do último Reajuste?',
      icon: 'warning',
      reverseButtons: true,
      showCancelButton: true,
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Confirmar'
    }).then((response) => {
      if (response.value) {
        let itemSend : servicesReadjustmentRegisterPriceFreeDTO = new servicesReadjustmentRegisterPriceFreeDTO(
        this.serviceHistoryReajustments[0].serviceBoat.id,
        this.serviceHistoryReajustments[0].valueNew,
        this.serviceHistoryReajustments[0].discountNew,
        this.serviceHistoryReajustments[0].dueDateDiscountNew,
        this.serviceHistoryReajustments[0].endContractDateNew,
        this.serviceHistoryReajustments[0].indexUsed,
        this.serviceHistoryReajustments[0].indexUsedText
        );
        let email : servicesReadjustmentRegisterPriceFreeDTO[]=[];
        email.push(itemSend);
        this.sendEmails(email);
      }
    });

  }

  async sendEmails(servicesSelect: any[]): Promise<void> {
    return new Promise(resolve =>{
        this.spinner.show();
        this.serviceBoatService.sendEmailsReajustment(servicesSelect)
          .subscribe(data => {
            this.spinner.hide();
            let sendError: String[] = data;
            console.log(data);
            if (sendError.length>0){
              let mensagem: string="";
              sendError.forEach((currentValue)=> { mensagem = mensagem + currentValue + ", \n";});
              this.messageUtil.generateMessage("warning", "Envio de Email", "As seguintes embarcações não foram notificadas. \n"+ mensagem);
            }
            else{
              this.messageUtil.generateMessage("success", "Envio de Email", "Todos os e-mails foram enviados com sucesso!");
            }
            this.loadHistoric(this.serviceBoat.serviceBoatId);
            this.spinner.hide();
            resolve();
          }, error => {
            const exception = error.error.data.exception;
            this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
            this.spinner.hide();
            resolve();
          });
    })
  }

  close(result: boolean = false) {
    this.dialog.close(result);
  }


}
