import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import moment from 'moment';
import { MovemmentDateTimeType } from 'src/app/models/enums/movements/movement-date-time-type';
import { MovementDateTimeDTO } from 'src/app/models/dtos/movements/boat-movement-dto';


export interface MovementDurationParams {
  date: MovementDateTimeDTO;
}

@Component({
  selector: 'app-movement-duration',
  templateUrl: './movement-duration.component.html',
  styleUrls: ['./movement-duration.component.scss']
})
export class MovementDurationComponent implements OnInit, OnDestroy {
  private readonly updateInterval = 1000
  @Input()
  params: MovementDurationParams;

  private dateTime: Date;
  private interval
  durationString: string = '---'

  constructor() {}

  ngOnInit() {
    if (this.params.date && this.params.date.dateTime && this.params.date.type === MovemmentDateTimeType.DURATION) {
      this.dateTime = this.params.date.dateTime
      this.calculateDuration(this.dateTime);
      this.interval = setInterval(() => this.calculateDuration(this.dateTime), this.updateInterval)
    }
  }

  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  calculateDuration(dateTime: Date) {
    if (dateTime) {
      let minutes = 0, hours = 0, days = 0;
      const now = moment(new Date());

      minutes = moment(now).diff(dateTime, 'minutes');
      if (minutes < 60) {
        this.setDurationString(days, hours, minutes);
        return;
      }
      minutes %= 60;
      hours = moment(now).diff(dateTime, 'hours');
      if (hours < 24) {
        this.setDurationString(days, hours, minutes);
        return;
      }
      hours %= 24;
      days = moment(now).diff(dateTime, 'days');
      this.setDurationString(days, hours, minutes);
    }
  }

  setDurationString(days: number, hours: number, minutes: number) {
    this.durationString = (days > 0 ? days + 'D ' : '') + this.zeroPad(hours,2) + ':' + this.zeroPad(minutes, 2);
  }

  zeroPad(num, places): string {
    return String(num).padStart(places, '0');
  }
}
