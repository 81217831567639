<p-fieldset class="container-fluid dialog-form">
    <p-header class="article-title">
        <span class="article-title">{{ 'TITLE-NOTA-FISCAL-CONFIG' | translate }}</span>
    </p-header>

    <form (submit)="submit()" [formGroup]="certificateForm">
        <h1>Atualizar certificado digital</h1>
        <br>
        <div class="form-group">
            <div class="form-control form-group file">
                <div class="form-control">
                    <img src="assets/images/certificate.svg">
                    <div *ngIf="certificateForm?.value?.certificate">{{certificateForm.value.certificate.name}}</div>
                </div>
            </div>
            <div class="form-group">
                <div class="form-control" *ngIf="marinaCompanies?.length > 0">
                    <label for="selectedCompany">{{'COMPANY' | translate}} *</label>
                    <p-dropdown [filter]="true" [options]="filteredCompanies" name="selectedCompany" appendTo="body"
                        [title]="'COMPANY' | translate" formControlName="id" (onChange)="changeCompany()">
                    </p-dropdown>
                </div>
                <br>
                <div class="form-control">
                    <label for="certificate">{{'CERTIFICATE' | translate}} *</label>
                    <p-fileUpload chooseLabel=".pfx ou .p12" mode="basic" name="certificate" accept=".pfx,.p12" #upload
                        customUpload="true" (uploadHandler)="handle($event)" [auto]="true" styleClass="certificate">
                    </p-fileUpload>
                    <span
                        *ngIf="certificateForm?.controls?.certificate?.touched && certificateForm?.controls?.certificate?.dirty && certificateForm?.controls?.certificate?.errors?.required"
                        class="field-error">* Selecione o certificado</span>
                </div>
                <br>
                <div class="form-control">
                    <label for="password">{{'PASSWORD' | translate}} *</label>
                    <input type="password" pInputText formControlName="password" name="password">
                </div>
            </div>
        </div>
        <br>
        <div class="buttons">
            <button class="easymarine primary" type="submit">{{ 'SAVE' | translate }}</button>
        </div>
    </form>
</p-fieldset>