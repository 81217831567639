export class ServiceHistoricFilter {
  startDate: Date;
  endDate: Date;
  productId: number;
  marinaId: number;
  productType:string[]=[];
  pageIndex: number;
  pageSize: number;
  sort: string;
  field:string;
}
