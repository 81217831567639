<div class="table">
  <p-table #tt [value]="roleLogs" [resizableColumns]="true" [autoLayout]="true" [paginator]="true"
  [rows]="numberOfRows" [pageLinks]="3" [rowsPerPageOptions]="isDialog? null : [10,20,30,50,100]">
      <ng-template pTemplate="header">
          <tr>
              <th>Data/hora</th>
              <th>Usuário</th>
              <th>Origem</th>
              <th>Nome</th>
              <th>Hierarquia</th>
              <th>Fotos Gestor</th>
              <th>Administrador</th>
              <th>Ação</th>
              <th>Visualizar</th>

          </tr>
      </ng-template>

      <ng-template pTemplate="body" let-roleLog>
          <tr>
              <td>{{roleLog.logTime | date: 'dd/MM/yyyy - HH:mm'}}</td>
              <td [pTooltip]="roleLog.user ? roleLog.user.fullName : ''" tooltipPosition="top">{{roleLog.user ? roleLog.user.username : ''}}</td>
              <td>{{roleLog.origin}}</td>
              <td>{{roleLog.name}}</td>
              <td>{{roleLog.hierarchy}}</td>
               <td>{{check(roleLog.photos)}}</td>
               <td> {{check(roleLog.administrator)}} </td>
              <td>{{roleLog.operationLog | translate}}</td>
              <td>
                  <div class="table-action" (click)="openUserLogDialog(roleLog)" pTooltip="Visualizar" *ngIf="roleLog.oldDatas"
                      tooltipPosition="bottom">
                      <i class="pi pi-eye"></i>
                  </div>
              </td>
          </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
          <tr>
              <td colspan="7">
                  Nenhum log encontrado
              </td>
          </tr>
      </ng-template>

  </p-table>
</div>
