import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BilletService } from 'src/app/services/billet.service';
import { FinancesService } from 'src/app/services/finances.service';
import { MessageUtil } from 'src/app/utils/message.util';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-anticipation-dialog',
  templateUrl: './anticipation-dialog.component.html',
  styleUrls: ['./anticipation-dialog.component.scss']
})
export class AnticipationDialogComponent implements OnInit {

  billets: any[];
  taxes = 0.0;
  interest = 0.0;
  totalBillets = 0.0;
  cols: any[];
  lote: string;
  rows = 5;
  favorecidos: Array<{ label: string, value: number }> = [];
  anticipationForm = new UntypedFormGroup({
    taxes: new UntypedFormControl(null, Validators.compose([Validators.required, Validators.min(0)])),
    interest: new UntypedFormControl(null, Validators.compose([Validators.required, Validators.min(0)])),
    balance: new UntypedFormControl(null, Validators.compose([Validators.required, Validators.min(1)])),
    favorecido: new UntypedFormControl(null, Validators.compose([Validators.required])),
    date: new UntypedFormControl(null, Validators.compose([Validators.required]))
  });
  accountId: number;
  favorecido: number;
  accounts: Array<{ label: string; value: number; }>;
  paymentMethods: Array<{ label: string; value: number; }>;
  localeOption: any;
  constructor(
    private billetService: BilletService,
    private spinner: NgxSpinnerService,
    private messageService: MessageUtil,
    public dialog: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private financesService: FinancesService
  ) { }

  ngOnInit(): void {
    if (window.innerHeight < 800){
      this.rows = 3;
    }
    this.accounts = this.config.data.accounts;
    this.paymentMethods = this.config.data.paymentMethods;
    this.billets = this.config.data.billets;
    this.cols = this.config.data.cols;
    this.accountId = this.config.data.accountId;
    this.totalBillets = this.config.data.totalBillets;
    this.lote = this.config.data.lote;
    this.findFavorecidos();
  }

  anticipate(): void {
    if (!this.accountId) {
      this.messageService.generateMessage('error', 'Conta não selecionada', 'Selecione uma conta.');
      return;
    }
    Swal.fire({
      title: 'Antecipação de Boletos',
      text: 'O valor dos boletos será antecipado para a conta selecionada e a programação será atualizada. Deseja continuar?',
      icon: 'warning',
      backdrop: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then(async (result) => {
      if (result.value) {
        this.spinner.show();
        const ids = this.billets.map((i) => i.id);
        const body: any = {
          billetIds: ids,
          taxes: this.anticipationForm.value.taxes,
          interest: this.anticipationForm.value.interest,
          accountId: this.accountId,
          idFavorecidoFinanceiro: this.anticipationForm.value.favorecido,
          date: this.anticipationForm.value.date
        };
        this.billetService.anticipate(body).subscribe(
          (lot) => {
            this.spinner.hide();
            this.messageService.generateMessage(
              'success',
              'Boletos antecipados',
              'Antecipação dos boletos realizada com sucesso.'
            );
            this.dialog.close();
          },
          (res) => {
            this.spinner.hide();
            const exception = (res.error.data ? res.error.data.exception : res.error);
            this.messageService.generateMessage(exception.type, exception.title, exception.message);
          }
        );
      }
    });
  }

  valueNotMatch(): boolean {
    return (
      this.anticipationForm.get('balance').touched &&
      this.anticipationForm.get('balance').dirty &&
      Number.parseFloat((
        Number.parseFloat(this.anticipationForm.value.balance) +
        Number.parseFloat(this.anticipationForm.value.taxes) +
        Number.parseFloat(this.anticipationForm.value.interest)
      ).toFixed(2)) !== this.totalBillets
    );
  }

  findFavorecidos(): void {
    this.financesService.findFavorecidos().subscribe(
      (favorecidos) => {
        this.favorecidos = [{ label: 'Selecione o favorecido', value: null }].concat(
          favorecidos.map(
            (x) => ({ label: x.nome, value: x.idFavorecido })
          )
        );
      }
    );
  }

  getAccountName(accountId): string {
    const filter = this.accounts.filter((a) => a.value === accountId);
    if (filter.length > 0) {
      return filter[0].label;
    }
  }

  getPaymentMethodName(paymentMethodId): string {
    const filter = this.paymentMethods.filter((a) => a.value === paymentMethodId);
    if (filter.length > 0) {
      return filter[0].label;
    }
  }

  invalidFormControl(control: AbstractControl): boolean {
    return control.touched && control.dirty && (!control.value || control.value === '');
  }
}

