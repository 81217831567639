<div class="dialog-form">
    <h3>Configuração da Conta de Cobrança</h3> <br>
    Taxa Multa: {{taxaMulta}} % <br><br>
    Taxa Mora: {{taxaMora}} % <br><br>
    Dias de tolerância: {{toleranceDays}} <br><br>
    <br>

    <h3>Cálculos</h3> <br>
    Multa: ( Valor x Taxa Multa ) / 100<br><br>

    Taxa de Juros por dia: (Dias de atraso - Dias de tolerância ) x (Taxa Mora / 30)<br><br>

    Juros: ( Taxa de Juros por dia x Valor ) / 100<br>
</div>