<div class="dialog-form">
    <div class="form-group actions">
        <p-fileUpload name="file" mode="basic" maxFileSize="100000000" chooseLabel="Importar planilha"
            accept=".xls,.xlsx" #upload [auto]="true" customUpload="true"
            pTooltip="Anexar planilha com clientes a serem inseridos" tooltipPosition="bottom"
            [disabled]="results?.length > 0 && lastStatus !== 'READY'" (uploadHandler)="onBasicUpload($event.files)">
        </p-fileUpload>
        <button (click)="downloadEmptyXlsx()" class="easymarine primary" type="button">Baixar modelo</button>
    </div>
    <br>
    <p-fieldset>
        <p-header>
            <h3 class="article-title" style="float: left; margin: 0px;">Última importação</h3>
        </p-header>

        <div class="table">
            <p-table [value]="results" autoLayout="true">
                <ng-template pTemplate="header">
                    <tr>
                        <th>
                            Data
                        </th>
                        <th>
                            Clientes importados
                        </th>
                        <th>
                            Cliente já cadastrados
                        </th>
                        <th>
                            Arquivo de erro
                        </th>
                        <th
                            *ngIf="results && (results[0].customersExists?.length > 0 || results[0].customersSuccess?.length > 0)">
                        </th>
                        <th *ngIf="results && results[0].error">

                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-result>
                    <tr>
                        <td>
                            {{result?.createdAt | date: 'dd/MM/yyyy HH:mm'}}
                        </td>
                        <td class="text-center">
                            <span *ngIf="result?.customersSuccess">
                                {{result.customersSuccess?.length}}
                            </span>
                            <span *ngIf="!result?.customersSuccess">
                                Importação em andamento
                            </span>
                        </td>
                        <td class="text-center">
                            <span *ngIf="result?.customersExists">
                                {{result.customersExists?.length}}
                            </span>
                            <span *ngIf="!result?.customersExists">
                                Importação em andamento
                            </span>
                        </td>
                        <td class="text-center">
                            <a *ngIf="result?.file" target="_blank" [href]="result.file" class="link">
                                Baixar arquivo
                            </a>
                            <span *ngIf="lastStatus === 'PROCESSING' " class="text-center">
                                <img src="assets/images/loading.gif" alt="" style="width: 15px; height: 15px;">
                            </span>
                        </td>
                        <td class="text-center"
                            *ngIf="result?.customersExists?.length > 0 || result?.customersSuccess?.length > 0">
                            <a (click)="openUploadReportDialog()" class="link">
                                Detalhes
                            </a>
                        </td>
                        <td class="text-center" *ngIf="result?.error" [pTooltip]="result.error">
                            <i class="pi pi-times-circle" style="color: #EF5350"></i>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </p-fieldset>
    <div class="flex-row-container">
        <p class="legend-exel-text1">Legendas das cores do Arquivo de erro:</p>
        <div class="flex-row">
            <div class="legend-exel-red"></div>
            <p class="legend-exel-text">Dados fora do padrão ou inválidos.</p>
        </div>
        <div class="flex-row">
            <div class="legend-exel-yellow"></div>
            <p class="legend-exel-text">Dados já cadastrados.</p>
        </div>
    </div>
</div>
