<div class="dialog-form">
  <form (submit)="checkAndInvoicePay()">
      <div class="form-group">
          <div class="form-row">
              <div class="form-control">
                  <div class="text-left"  style="font-style: italic; font-weight: bold;">
                      <label>Data de Vencimento:</label>
                      <span>{{invoice.dueDate | date: 'dd/MM/yyyy'}}</span>
                  </div>
              </div>

          </div>
          <div class="form-row">

              <div class="form-control">
                  <label>Data do desconto</label>
                  <p-calendar dateFormat="dd/mm/yy" name="invoice.paymentDate" [(ngModel)]="invoice.paymentDate" appendTo="body"
                      required [showIcon]="true" i18n [monthNavigator]="true" [yearNavigator]="true"
                      [yearRange]="'2000:2055'" rPCalendarMask>
                  </p-calendar>
              </div>
              <div class="form-control">
                  <label>Valor em aberto</label>
                  <input pInputText type="text" class="text-right" readonly name="pendingValue"
                      [value]="invoice.pendingValue | number:'1.2-2'">
              </div>

          </div>
          <div class="form-row">
             <div class="form-control">
                  <div class="labelInterest">
                      <label>Desconto</label>
                      <a class="btn-label-juros-multa" (click)="changeDiscountToDiscountSuggested()" >
                          <label style="color: rgb(38, 199, 38); cursor: pointer;" *ngIf="dueDateDiscount != 0" pTooltip="Desconto Até o Vencimento">
                              {{dueDateDiscount | number:'1.2-2'}}
                          </label>
                      </a>
                      <a class="btn-juros-multa" *ngIf="dueDateDiscount != 0" >
                          <i class="pi pi-info-circle" tooltipPosition="bottom" pTooltip="Clique no valor para aplicar o desconto"></i>
                      </a>
                  </div>
                  <p-inputNumber mode="decimal" name="discount" [(ngModel)]="invoice.discount" [minFractionDigits]="2"
                      [maxFractionDigits]="2" (onInput)="cleanPaidValue()">
                  </p-inputNumber>
              </div>


              <div class="form-control half-size">
                  <label>Total à pagar</label>
                  <input pInputText type="text" class="text-right" readonly min="0" max="500000" autocomplete="off"
                      name="totalValue" value="{{ getTotalValue() | number:'1.2-2'}}">
              </div>
          </div>



      </div>
      <div class="buttons">

          <button [disabled]="invoice.discount <=0 ||  !invoice.paymentDate"
              class="full-width easymarine primary" type="submit">Aplicar Desconto</button>
      </div>
      <div class="row" *ngIf="hasBankingBillet()">
          <div class="col-12 text-center alert alert-danger">
              Atenção: Para esta fatura foi gerado boleto, favor dar baixa no sistema do banco!
          </div>
      </div>
  </form>
</div>
