import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-billet',
  templateUrl: './billet.component.html',
  styleUrls: ['./billet.component.scss']
})
export class BilletComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
