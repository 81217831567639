import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Raspberry } from '../models/raspberry';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable()
export class RaspberryService {

    constructor(private http: HttpClient) { }

    getAllRaspberry(): Observable<Raspberry[]> {
        return this.http.get(environment.apiHost + '/secure/raspberries/by-id-marina')
            .pipe(map((response: any) => response.data.raspberries));
    }

    rebootRaspberry(raspberryId: number, reboot: boolean): Observable<Raspberry> {
        return this.http.put(`${environment.apiHost}/secure/raspberries/reboot/${raspberryId}/${reboot}`, {})
            .pipe(map((response: any) => response.data.raspberry));
    }

    refreshRaspberry(raspberryId: number, refresh: boolean): Observable<Raspberry> {
        return this.http.put(`${environment.apiHost}/secure/raspberries/refresh/${raspberryId}/${refresh}`, {})
            .pipe(map((response: any) => response.data.raspberry));
    }
}
