import { OrderControl } from './../enums/order-control';
import { ProductType } from "../enums";

export class ProductFilter {
  id: number;
  name: string;
  productType: ProductType[];
  active: string[] = ['true'];
  productCategory:any[];
  orderControl:OrderControl[];
  chartAccountId: number[];
  costCenterId: number[];


}
