import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-product-log-modal',
  templateUrl: './product-log-modal.component.html',
  styleUrls: ['./product-log-modal.component.scss']
})
export class ProductLogModalComponent implements OnInit {

  productLog;

  isGeneralData = false;
  isStock = false;
  isPrice = false;
  isImage = false;

  constructor(
    private config: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef
  ) { }

  ngOnInit(): void {
    if(!this.config.data.productLog || !this.config.data.productLog.oldDatas){
      this.dialogRef.close();
    }
    this.productLog = this.config.data.productLog;
    this.createLogs();
  }

  createLogs() {    
    this.verifyChanges();
  }

  verifyChanges() {
    const oldDatasMap = new Map(Object.entries(this.productLog.oldDatas));
    this.verifyChangesGeneralData(oldDatasMap);
    this.verifyChangesStock(oldDatasMap);
    this.verifyChangesPrice(oldDatasMap);
    this.verifyChangesImage(oldDatasMap);
  }

  verifyChangesGeneralData(oldDatasMap: Map<string, unknown>) {
    this.isGeneralData = oldDatasMap.has('name') || oldDatasMap.has('productType')
    || oldDatasMap.has('productCategory') || oldDatasMap.has('measurement') || oldDatasMap.has('barCode')
    || oldDatasMap.has('chartAccountId') || oldDatasMap.has('orderControl')
    || oldDatasMap.has('showApp') || oldDatasMap.has('active')
    || oldDatasMap.has('issueReceipt') || oldDatasMap.has('issueNotaFiscal');
  }

  verifyChangesStock(oldDatasMap: Map<string, unknown>) {
    this.isStock = oldDatasMap.has('stock') || oldDatasMap.has('minimalStock')
    || oldDatasMap.has('appMinimalStock');
  }

  verifyChangesPrice(oldDatasMap: Map<string, unknown>) {
    this.isPrice = oldDatasMap.has('price');
  }

  verifyChangesImage(oldDatasMap: Map<string, unknown>) {
    this.isImage = oldDatasMap.has('file');
  }

}
