import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { ServiceComparationFilter } from 'src/app/models/dtos/service-comparation-filter';
import { ServiceComparationDTO } from 'src/app/models/dtos/service-comparationDTO';
import { ProductType, ShipType } from 'src/app/models/enums';
import { ServiceComparisonConfiguration } from 'src/app/models/service-comparison-configuration';
import { BoatService } from 'src/app/services/boat.service';
import { ProductService } from 'src/app/services/product.service';
import { ServiceComparisonService } from 'src/app/services/service-comparison.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { ServiceComparisonConfigurationModalComponent } from './service-comparison-configuration-modal/service-comparison-configuration-modal.component';

@Component({
  selector: 'app-service-comparison',
  templateUrl: './service-comparison.component.html',
  styleUrls: ['./service-comparison.component.scss']
})
export class ServiceComparisonComponent implements OnInit {

  servicesInUse: any[] = [];
  configurationsSaved: ServiceComparisonConfiguration[] = []

  filter: ServiceComparationFilter;

  boatListOptions: any[] = [];
  serviceListOptions: any[] = [];
  shipTypeOptions: any[] = [];

  comparations: ServiceComparationDTO[] = [];
  totals;

  allcomparationsInTotals: boolean = true;

  constructor(
    private serviceComparisonService: ServiceComparisonService,
    private productService: ProductService,
    private boatService: BoatService,
    private spinner: NgxSpinnerService,
    private messageUtil: MessageUtil,
    public dialog: DialogService,
  ) { }

  async ngOnInit(): Promise<void> {
    this.clear();
    this.findServiceOptions();
    this.findBoatOptions();
    this.findShipTypeOptions();
    this.spinner.show();
    await Promise.all([
      this.findServicesInUse(),
      this.findComparisonConfigurations()
    ]);
    this.listComparisonConfigurationsToServicesInUse();
    await this.getComparisons();
    this.spinner.hide();
  }


  listComparisonConfigurationsToServicesInUse() {
    let newConfigurationsList: ServiceComparisonConfiguration[] = [];
    this.servicesInUse.forEach( service => {
      newConfigurationsList.push(this.findConfigurationsByService(service));
    });

    this.filter.configurations = newConfigurationsList;
  }

  private findConfigurationsByService( service ): ServiceComparisonConfiguration {
    const config = this.configurationsSaved.find(c => c.service.id === service.id);

    return config ? config : { service }
  }

  async findServicesInUse(): Promise<void>{
    return new Promise( async resolve => {
      this.productService.getPeriodicalServiceInUse().subscribe(
        data => {
          this.servicesInUse = data;
          resolve();
        },
        error => {
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
          resolve();
        }
      )
    });
  }

  async findComparisonConfigurations(): Promise<void>{
    return new Promise( async resolve => {
      this.serviceComparisonService.getServiceComparisonConfigurations().subscribe(
        data => {
          this.configurationsSaved = data;
          resolve();
        },
        error => {
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
          resolve();
        }
      )
    });
  }

  async search() {
    this.filter
    this.spinner.show()
    await this.getComparisons();
    this.spinner.hide();
  }

  async getComparisons(): Promise<void>{
    return new Promise( async resolve => {
      this.serviceComparisonService.getConfigurations(this.filter).subscribe(
        data => {
          this.comparations = data;
          this.totals = this.setTotais(data);
          this.checkAllComparations()
          resolve();
        },
        error => {
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
          resolve();
        }
      )
    });
  }

  checkAllComparations() {
    let allcomparations = true;

    for (const service of this.comparations) {
      if(!service.valueComparation) {
        allcomparations = false;
        break;
      }
    }

    this.allcomparationsInTotals = allcomparations;
  }

  private setTotais(comparations){
    let totals = {
      value: 0,
      discount: 0,
      dueDateDiscount: 0,
      totalValue: 0,
      valueComparation: 0,
      totalValueComparation: 0,
    }
    comparations.forEach( service => {
      if(service.valueComparation) {
        totals.value += service.value;
        totals.totalValue += service.total;
        totals.discount += service.discount;
        totals.dueDateDiscount += service.dueDateDiscount;
        totals.valueComparation += service.valueComparation;
        totals.totalValueComparation += service.totalComparation;
      }
    })
    return totals;
  }

  async saveConfigurations(): Promise<void> {
    return new Promise( async resolve => {
      this.serviceComparisonService.saveConfigurations(this.filter.configurations).subscribe(
        data => {
          resolve();
        },
        error => {
          resolve();
        }
      )
    });
  }

  async findServiceOptions(): Promise<void>{
    return new Promise( async resolve => {
      this.productService.getProductFilterList().subscribe(
        data => {
          this.serviceListOptions = data.filter(s => s.productType === ProductType.PeriodicalService.toString()).map(item => ({ label: item.name, value: item.id }));
          resolve();
        },
        error => {
          resolve();
        }
      )
    });
  }

  async findBoatOptions(): Promise<void> {
    return new Promise( async resolve => {
      this.boatService.getBoatFilterList().subscribe(
        data => {
          this.boatListOptions = data.filter(b => b.active).map(item => ({ label: item.name, value: item.id }));
          resolve();
        },
        error => {
          resolve();
        }
      )
    });
  }

  findShipTypeOptions() {
    this.shipTypeOptions = [{ label: 'Todos', value: null }];
    Object.keys(ShipType).forEach(async item => {
      const label = await this.messageUtil.translateKeyAsync(item.toUpperCase());
      this.shipTypeOptions.push({ label, value: item })
    });
  }

  async openModalConfigurations(): Promise<void> {
    this.dialog.open(ServiceComparisonConfigurationModalComponent, {
      header: this.messageUtil.translateKey('Configurações'),
      width: '550px',
      height: '650px',
      data: {
        serviceIds: this.filter.serviceIds,
        configurationsSaved: this.configurationsSaved,
        configurations: this.filter.configurations
      }
    }).onClose.subscribe(async (data: any) => {
      if (data) {
        this.filter.configurations = data.configs;
        this.search()
        if(data.saveConfig) {
          await this.saveConfigurations();
          await this.findComparisonConfigurations();
          this.listComparisonConfigurationsToServicesInUse();
        }
      }
    });
  }

  calcPercentage( value, newValue): number{
    if(value && newValue) {
      return (newValue-value)/value;
    }
    return;
  }

  clear(){
    const config = this.filter?.configurations;

    this.filter = {
      configurations: config
    }
  }
}
