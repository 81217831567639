import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-log-price-range',
  templateUrl: './product-log-price-range.component.html',
  styleUrls: ['./product-log-price-range.component.scss']
})
export class ProductLogPriceRangeComponent implements OnInit {

  @Input() priceRangers;
  @Input() priceRangersOld;
  @Input() pricingType;
  @Input() isNew;

  constructor() { }

  ngOnInit(): void { }

  logsTable(): any[]{
    return this.isNew ? this.sortPriceRanges(this.priceRangers) : this.sortPriceRanges(this.priceRangersOld);
  }

  sortPriceRanges(priceRangers: any[]): any[] {
    return priceRangers.sort((a, b) => {
      if (a.startLengthRange > b.startLengthRange) {
        return 1;
      } else if (a.startLengthRange < b.startLengthRange) {
        return -1;
      }
      return 0;
    });
  }

  isChanged(priceRanger): boolean {
    let priceRangerVerify;
    if(this.isNew){
      priceRangerVerify = this.findPriceRangerOld(priceRanger);
    } else {
      priceRangerVerify = this.findPriceRanger(priceRanger);
    }

    return !priceRangerVerify;
  }

  findPriceRanger(priceRanger): any{
    return this.priceRangers.find(pr => pr.value === priceRanger.value 
      && pr.startLengthRange === priceRanger.startLengthRange 
      && pr.endLengthRange === priceRanger.endLengthRange);
  }

  findPriceRangerOld(priceRanger): any{
    return this.priceRangersOld.find(pr => pr.value === priceRanger.value 
      && pr.startLengthRange === priceRanger.startLengthRange 
      && pr.endLengthRange === priceRanger.endLengthRange); 
  }

  getTitleRangeType(): string{
    if(this.pricingType ===  'PerRangeLength'){
      return 'PRICE-FEET';
    } else if(this.pricingType === 'FixedPerRange') {
      return 'PRICE-FIXED';
    }
  }

}
