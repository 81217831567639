<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">{{'TITLE-CONTRACTS' | translate}}</span>
        <div class="header-buttons" *ngIf="termUse">
            <a href='https://secure.d4sign.com.br/gopaperless/sustentabilidade/6f821790-0eff-403c-bbc3-f12db321dcad/SCC4-SERVICOS-DE-CONTROLE-LTDA.html'
                target='_blank'>
                <img src='assets\images\D4Sign-logo.svg' style="margin-right: 16px" width='200px;' title='D4Sign' alt='D4Sign'>
            </a>
            <button class="primary fab-button" (click)="newContractDialog()" [disabled]="boats.length == 0">
                <i class="pi pi-plus" style="font-size: 21px;"></i>
            </button>
        </div>
    </p-header>

    <div class="alert alert-warning text-center" *ngIf=" !loadingPage && !termUse">
        <strong>Termo de aceite de serviço</strong>
        <iframe src="assets\documents\TermoUsoContrato.pdf"
            style="width: 100%; height: 50vh;margin: 20px auto;"></iframe>

        <button class="primary easymarine" type="button" (click)="agreeTermUseContracts()"
            pTooltip="O gerenciamento de contratos será ativado" tooltipPosition="bottom">
            Aceito os termos de uso
        </button>
    </div>
    <p-tabView [activeIndex]="selectTab" (onChange)="onChangeSelectTab($event)" *ngIf="this.termUse">
        <p-tabPanel header="{{'CONTRACTS' | translate}}">
            <div *ngIf="selectTab==0">
                <div class="filters active">
                    <div class="filter-row">
                        <div class="form-control">
                            <label>{{'BOAT' | translate}}</label>
                            <p-dropdown [placeholder]="'BOAT' | translate" [filter]="true" [options]="boatListFilter"
                                appendTo="body" name="selectedBoatIdFilter" [title]="'BOAT' | translate"
                                [(ngModel)]="filter.boatId">
                            </p-dropdown>
                        </div>
                        <div class="form-control">
                            <label>{{'SERVICES' | translate}}</label>
                            <p-dropdown [placeholder]="'SERVICES' | translate" [filter]="true"
                                [options]="serviceListFilter" appendTo="body" [(ngModel)]="filter.serviceId "
                                name="selectedService" [title]="'SERVICES' | translate">
                            </p-dropdown>
                        </div>
                        <div class="form-control">
                            <label>{{'STATUS' | translate}}</label>
                            <p-multiSelect [defaultLabel]="'STATUS' | translate" [options]="statusListFilter"
                                appendTo="body" [(ngModel)]="filter.statusList" [title]="'STATUS' | translate"
                                [filter]=false selectedItemsLabel="{0} itens selecionados">
                            </p-multiSelect>
                        </div>
                        <div class="form-control">
                            <label>{{'DOCUMENT_TYPE' | translate}}</label>
                            <p-multiSelect [defaultLabel]="'DOCUMENT_TYPE' | translate"
                                [options]="documentTypeListFilter" appendTo="body" [(ngModel)]="filter.documentTypeList"
                                [title]="'DOCUMENT_TYPE' | translate" [filter]=false
                                selectedItemsLabel="{0} itens selecionados">
                            </p-multiSelect>
                        </div>
                        <div class="form-control">
                            <label>{{'CONTRACT_TYPE' | translate}}</label>
                            <p-multiSelect [defaultLabel]="'CONTRACT_TYPE' | translate"
                                [options]="contractTypeListFilter" appendTo="body" [(ngModel)]="filter.contractTypeList"
                                [title]="'CONTRACT_TYPE' | translate" [filter]=false
                                selectedItemsLabel="{0} itens selecionados">
                            </p-multiSelect>
                        </div>
                        <div class="form-control">
                            <label for="reviewed">{{'UNDER-REVIEW' | translate}}</label>
                            <p-dropdown [(ngModel)]="filter.affected" name="affected" appendTo="body"
                                [options]="[{label: 'Ambos', value: null}, {label: 'Sim', value: true}, {label: 'Não', value: false}]">
                            </p-dropdown>
                        </div>
                        <div class="form-control">
                            <label for="reviewed">{{'SERVICE-STATUS' | translate}}</label>
                            <p-dropdown [(ngModel)]="filter.serviceActive" name="serviceActive" appendTo="body"
                                [options]="[{label: 'Ambos', value: null}, {label: 'Ativo', value: true}, {label: 'Inativo', value: false}]">
                            </p-dropdown>
                        </div>
                        <div class="form-control">
                            <label>{{'EMAIL' | translate}}</label>
                            <input pInputText type="text" [(ngModel)]="filter.emailSigner"
                                [placeholder]="'EMAIL' | translate">
                        </div>
                        <div class="form-control">
                            <label>Data inicial</label>
                            <p-calendar dateFormat="dd/mm/yy" name="dateStart" [yearNavigator]="true"
                                [monthNavigator]="true" appendTo="body" [yearRange]="'2000:2055'"
                                [(ngModel)]="filter.dateStart" [showIcon]="true" [locale]="pt">
                            </p-calendar>
                        </div>
                        <div class="form-control">
                            <label>Data final</label>
                            <p-calendar dateFormat="dd/mm/yy" name="dateEnd" [monthNavigator]="true"
                                [yearNavigator]="true" appendTo="body" [yearRange]="'2000:2055'"
                                [(ngModel)]="filter.dateEnd" [showIcon]="true" [locale]="pt">
                            </p-calendar>
                        </div>
                        <div class="buttons">
                            <div class="form-button">
                                <button (click)="clear()" class="easymarine danger" type="button">{{ 'CLEAR' | translate
                                    }}</button>
                            </div>
                            <div class="form-button">
                                <button class="easymarine primary" (click)="findDocuments()" type="button">{{ 'FIND' |
                                    translate
                                    }}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="table">
                    <p-table #documentTable [value]="documents" class="primeTable" [paginator]="true"
                        [(selection)]="selectedDocuments" autoLayout="true" [alwaysShowPaginator]="false"
                        [rowsPerPageOptions]="[100, 500, 1000]" [pageLinks]="3"
                        styleClass="easy-table" [globalFilterFields]="['nameDocument', 'status','boatName']" [lazy]="true"
                        [rows]="paginationFilter.size" [totalRecords]="totalRecords"
                        (onLazyLoad)="onChangePage($event)">
                        <ng-template pTemplate="caption">
                            <button class="primary easymarine" type="button" (click)="getdocumentsSeleted()"
                                [disabled]="!selectedDocuments.length > 0">
                                Assinar em Lote
                            </button>
                            <span style="font-weight: bold">{{ 'TOTAL-REGISTERS' | translate }}:
                                {{documents.length}} {{'OF' | translate}}
                                {{documentTable.totalRecords}}</span>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="noborder-right"></th>
                                <th class="text-center">
                                    <span *ngIf="selectedDocuments.length > 0">
                                        {{selectedDocuments.length}} contrato(s) selecionado(s)
                                    </span>
                                    <span *ngIf="!selectedDocuments.length > 0">
                                        Nenhum contrato selecionado
                                    </span>
                                </th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th colspan="5"></th>
                            </tr>
                            <tr>
                                <th class="text-center">

                                </th>
                                <th class="text-center" pSortableColumn="nameDocument">
                                    {{ 'NAME' | translate }}
                                    <p-sortIcon field="nameDocument"></p-sortIcon>
                                </th>
                                <th class="text-center" [pSortableColumn]="'boatName'">
                                    {{ 'BOAT' | translate }}
                                </th>
                                <th class="text-center" [pSortableColumn]="'servicesName'">
                                    {{ 'SERVICE' | translate }}
                                </th>
                                <th class="text-center" [pSortableColumn]="'contractValue'">
                                 {{'VALUE' | translate}}
                                </th>
                                <th class="text-center" [pSortableColumn]="'status'">
                                    {{ 'STATUS' | translate }}
                                </th>
                                <th class="text-center" pSortableColumn="document_type">
                                    {{ 'DOCUMENT_TYPE' | translate }}
                                    <p-sortIcon field="type"></p-sortIcon>
                                </th>
                                <th class="text-center" pSortableColumn="contract_type">
                                    {{ 'CONTRACT_TYPE' | translate }}
                                    <p-sortIcon field="contract_type"></p-sortIcon>
                                </th>
                                <th class="text-center" pSortableColumn="createdAt">
                                    Data de criação
                                    <p-sortIcon field="createdAt"></p-sortIcon>
                                </th>
                                <th class="text-center">
                                    {{ 'UNDER-REVIEW' | translate }}
                                </th>
                                <th class="text-center">Ações</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-document>
                            <tr>
                                <td class="text-center">
                                    <p-tableCheckbox [value]="document.uuidDocument"
                                        [disabled]="!isSelectable(document)">
                                    </p-tableCheckbox>
                                </td>
                                <td class="text-center" [pTooltip]="getEmails(document)" tooltipPosition="bottom">{{ document.nameDocument
                                    }}
                                </td>
                                <td class="text-center">{{ document.boatName }}</td>
                                <td class="text-center">{{ document.serviceName }}</td>
                                <td class="text-center">{{ document.value | number: '1.2'}}</td>
                                <td class="text-center">
                                    <div style="display: flex;">
                                        <div
                                            [ngClass]="{
                                'document-success': document.status === 'SIGNED' || document.status ==='INVALID',
                                'document-danger': document.status === 'CANCELED' || document.status === 'ERROR',
                                'document-warning': document.status === 'WAITING_SIGNATORIES' || document.status === 'WAITING_SIGNATURES',
                                'document-processing': document.status === 'PROCESSING_SIGNATURES' || document.status === 'PROCESSING_FILE'}">
                                            <span>
                                                {{ document.status | translate}}
                                            </span>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <span> {{ document.documentType | translate}}</span>
                                </td>
                                <td class="text-center">
                                    <span> {{ document.contractType | translate}}</span>
                                </td>
                                <td class="text-center">{{ document.createdAt | date: 'dd/MM/yyyy'}}</td>
                                <td class="text-center">
                                    <a href="javascript:"
                                        [pTooltip]="document.affected ? 'Retirar de análise' :'Colocar em análise'"
                                        class="badge badge-pill" *ngIf="document.status === 'SIGNED'"
                                        [ngClass]="{'success': !document.affected, 'danger': document.affected}"
                                        tooltipPosition="bottom" (click)="updateAffected(document)">
                                        {{ (document.affected ? 'YES' : 'NO') | translate }}
                                    </a>
                                </td>
                              <td>
                                <p-menu appendTo="body" #menu [model]="menuItems" [popup]="true" [showTransitionOptions]="'150ms'"
                                        [hideTransitionOptions]="'150ms'">
                                </p-menu>
                                <button (click)="menu.toggle($event); changeSelectedItem(document)" pButton type="button"
                                        icon="pi pi-ellipsis-v" class="p-button-text p-button-rounded p-button-lg"></button>
                              </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <div class="text-right term-use-footer" *ngIf="termUse">
                        <span (click)="activeContracts()" class="link" pTooltip="Visualizar Termo de Uso"
                            tooltipPosition="bottom">
                            <strong> Termo de uso</strong></span>
                        aceito por <strong>{{termUse.userSigner?.firstName}} {{termUse.userSigner?.lastName}}</strong> em
                        <strong>{{termUse.createdAt | date: 'dd/MM/yy HH:mm'}}.</strong>
                    </div>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel header="{{'SERVICES' | translate}}">
            <app-service-boat-with-contracts [slingConfig]="slingConfig" [boatListFilter]="boatListFilter"
                [serviceListFilter]="serviceListFilter" *ngIf="selectTab==1" [boatList]="boats"></app-service-boat-with-contracts>
        </p-tabPanel>
    </p-tabView>
</p-fieldset>
