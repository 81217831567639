import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Sling, SlingConfig } from 'src/app/models';

@Component({
  selector: 'app-sling-form',
  templateUrl: './sling-form.component.html',
  styleUrls: ['./sling-form.component.scss']
})
export class SlingFormComponent implements OnInit {

  sling: Sling = new Sling();
  isASlingEdition: boolean;
  public slingConfig = new SlingConfig();

  selectedTab = 0;

  constructor(
    private config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef
  ) {
    this.slingConfig = this.config.data.slingConfig;
  }
  ngOnInit(): void {
    if (this.config.data.sling) {
      this.sling = this.config.data.sling;
    }
    if (this.config.data.isASlingEdition) {
      this.isASlingEdition = this.config.data.isASlingEdition;
    }
  }

  closeDialog(data?: any): void {
    this.dialogRef.close(data);
  }

  clean(): void {
    this.sling = new Sling();
  }

  changeSelectedTab(index: number): void {
    this.selectedTab = index;
  }
}
