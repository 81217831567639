import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { Sling } from 'src/app/models';
import { BoatStatus, SlingStatus } from 'src/app/models/enums';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { SlingService } from 'src/app/services/sling.service';
import { MessageUtil } from 'src/app/utils/message.util';
import Swal from 'sweetalert2';
import { SlingFormComponent } from '../../sling/sling-form/sling-form.component';

@Component({
  selector: 'app-sling-totem',
  templateUrl: './sling-totem.component.html',
  styleUrls: ['./sling-totem.component.scss']
})
export class SlingTotemComponent implements OnInit {

  filter: any = { slingOrigin: null, boatStatus: null, slingStatus: ['PENDING_DOWN', 'PENDING_UP'], idBoat: null, idCustomer: null };
  slings: any[];
  slingConfig: any;
  isToten: boolean;

  @HostListener('window:resize', ['$event'])
  getScreenSize(): void {
    if (window.innerWidth < 1200) {
      this.isToten = true;
    }
  }

  constructor(
    private spinner: NgxSpinnerService,
    public messageUtil: MessageUtil,
    private slingService: SlingService,
    private dialog: DialogService,
    private translateService: TranslateService,
    private slingConfigService: SlingConfigService
  ) { }

  ngOnInit(): void {
    this.getScreenSize();
    this.loadSlingConfig();
    this.find();
  }

  find(): void {
    this.spinner.show();
    this.slingService.findSlingByFilter(this.filter).subscribe(
      (data) => {
        this.slings = data.sort((a, b) => b.id - a.id);
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }

  loadSlingConfig(): void {
    this.slingConfigService.getSlingConfig().subscribe(
      (data) => {
        this.slingConfig = data[0];
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      });
  }

  async openSlingFormDialog(id?: number): Promise<void> {
    const data: any = {
      slingConfig: this.slingConfig,
    };
    let sling: Sling = null;
    if (id) {
      sling = await this.getSlingById(id);
      data.sling = sling;
      data.isASlingEdition = true;
    }
    this.dialog.open(SlingFormComponent, {
      width: '70%',
      height: '80vh',
      data,
      header: 'Lingada'
    }).onClose.subscribe((d) => {
      if (d) {
        this.find();
      }
    });
  }

  openSlingCancelConfirmDialog(sling: Sling): void {

    let titleMessage = null;
    let message = null;
    let types = null;

    types = 'warning';
    this.translateService.get('CANCEL-CONFIRMATION').subscribe((result: string) => { titleMessage = result; });
    this.translateService.get('SLING-CANCEL-PROMPT').subscribe((result: string) => { message = result; });

    Swal.fire({
      title: titleMessage,
      text: message + ' ' + sling.boat.name,
      icon: types,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirmar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.slingService.cancelSling(sling.id).subscribe(
          () => {
            this.messageUtil.generateMessage('success', 'SUMMARY.SUCCESS', 'MSG.CANCEL-SUCCESS');
            this.find();
          },
          (error) => {
            const exception = error.error.data.exception;
            this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
          });
      }
    });
  }

  hasCancel(sling: Sling): boolean {
    return (
      sling &&
      sling.slingStatus !== 'OK' &&
      sling.slingStatus !== 'CANCELED' &&
      (
        sling.boatStatus === BoatStatus.WAITING_PREPARATION ||
        sling.marinaOrder.orderItems.filter(
          (item) => !item.qtySupplied
        ).length !== 0
      )
    );
  }

  hasEdit(sling: Sling): boolean {
    return this.hasCancel(sling);
  }

  async getSlingById(id): Promise<Sling> {
    return new Promise<Sling>(
      async (resolve) => {
        this.slingService.getById(id).subscribe(async (sling: Sling) => resolve(sling));
      }
    );
  }
}
