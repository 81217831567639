import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DocumentSigner } from '../models/document-signer';
import { SignerDocumentResquestDTO } from '../models/dtos/signer-document-requestDTO';


@Injectable()
export class DocumentSignerService {

    private d4SignUrl = environment.apiHost + '/secure/document-signer';

    constructor(
        private http: HttpClient
    ) { }

    getSigners(id: number): Observable<DocumentSigner[]> {
        return this.http.get(`${this.d4SignUrl}/${id}/signers`)
            .pipe(map((response: any) => response.data.signers as DocumentSigner[]));
    }

    create(signer: SignerDocumentResquestDTO): Observable<DocumentSigner> {
        return this.http.post(`${this.d4SignUrl}/signers`, signer)
            .pipe(map((response: any) => response.data.signer as DocumentSigner));
    }

    delete(id: number): Observable<any[]> {
        return this.http.delete(`${this.d4SignUrl}/signers/${id}`)
            .pipe(map((response: any) => response));
    }
}
