import { Component } from '@angular/core';
import { DayWeek } from 'src/app/models/enums/day-week';

export class OperatingDay {
  id?: number;
  dayWeek: DayWeek;
  inOperation: boolean;
  operationStart: any;
  operationEnd: any;
}

@Component({
  selector: 'app-operating-day-config',
  templateUrl: './operating-day-config.component.html',
  styleUrls: ['./operating-day-config.component.scss']
})
export class OperatingDayConfigComponent {

  constructor() {}

}
