import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { AccessPermissionFilter } from 'src/app/models/dtos/access-controller/accessPermissionFilter';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AccessPermissionService {
  env: string = environment.apiHost + '/api-core/secure/access-permission/';
  constructor(private http: HttpClient) {}

  findByFilter(
    filter: AccessPermissionFilter,
    campos?: string
  ): Observable<any> {
    if (campos == undefined) {
      campos = '';
    }
    let headers = new HttpHeaders();
    headers = headers.set('campos', campos);
    return this.http
      .post(this.env + 'find-by-filter', filter, { headers: headers })
      .pipe(map((response: any) => response));
  }

  updateLiberationStatus(
    accessPermissionId: number,
    liberationStatus: boolean
  ): Observable<any> {
    return this.http
      .patch(
        this.env +
          'update-liberation-status/' +
          accessPermissionId +
          '/' +
          liberationStatus,
        null
      )
      .pipe(map((response: any) => response));
  }
}
