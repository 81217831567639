<p-fieldset>
    <p-header class="article-title">
        <div class="article-title">{{ 'EMERGENCY-CONTACT' | translate }}</div>
    </p-header>
    <div class="content-log">
        <div>
            <label for="emergencyContact">{{ 'EMERGENCY-CONTACT' | translate }}</label>
            <app-log-field [old]="customerLog.oldDatas.emergencyContact" [field]="customerLog.emergencyContact" [existsOld]="oldDatasMap.has('emergencyContact')" [isNew]="isNew"></app-log-field>
        </div>
        <div>
            <label for="emergencyContact">{{ 'EMERGENCY-PHONE' | translate }}</label>
            <app-log-field [old]="customerLog.oldDatas.emergencyPhone" [field]="customerLog.emergencyPhone" [existsOld]="oldDatasMap.has('emergencyPhone')" [isNew]="isNew"></app-log-field>
        </div>
        <div>
            <label for="description">Observações</label>
            <app-log-field [old]="customerLog.oldDatas.observation" [field]="customerLog.observation" [existsOld]="oldDatasMap.has('observation')" [isNew]="isNew"></app-log-field>
        </div>
    </div>
</p-fieldset>