import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BoatReport, BoatTuitionReport } from 'src/app/models/boat-report.model';
import { BoatService } from 'src/app/services/boat.service';
import dayjs from 'dayjs';
import { CustomerService } from 'src/app/services/customer.service';
import { SelectLabelValue } from 'src/app/models/select-label-value';

@Component({
  selector: 'app-boat-report',
  templateUrl: './boat-report.component.html',
  styleUrls: ['./boat-report.component.scss']
})
export class BoatReportComponent implements OnInit {
  boatReport: BoatReport = null;
  year: Date = new Date();

  customers: SelectLabelValue[] = [];
  boats: SelectLabelValue[] = [];

  selectedCustomers: any[] = null;
  selectedBoats: any[] = null;
  
  activeTuitionSum: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  inactiveTuitionSum: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  constructor(
    private boatService: BoatService,
    private customerService: CustomerService,
    private spinner: NgxSpinnerService
  ) {
  }

  ngOnInit() {
    this.getReport();
    this.getCustomers();
    this.getBoats();
  }

  getCustomers(): void {
    this.customerService.getCustomerFilterList().subscribe((data) => {
      this.customers = [{label: 'Todos', value: null}].concat(
        data.map((c) => ({label: c.name, value: c.id}))
      );
    });
  }

  getBoats(): void {
    this.boatService.getAllByActiveForSelect().subscribe((data) => {
      this.boats = [{label: 'Todos', value: null}].concat(
        data.map((c) => ({label: c.label, value: c.value}))
      );
    });
  }

  getReport() {
    this.spinner.show();
    const body = {
      customers: this.selectedCustomers,
      boats: this.selectedBoats,
    };
    
    this.boatService.getBoatReport(this.year.getFullYear(), body).subscribe((data: BoatReport) => {
      this.boatReport = data;
      
      for(let i = 0; i < 12; i++){
        this.activeTuitionSum[i] = 0;
        this.inactiveTuitionSum[i] = 0;
      }

      this.boatReport.activeBoatTuitionsReports.forEach(t => {
        t.tuitions = this.checkBoatTuitionReferences(t);
        t.tuitions.forEach((tuition, index) => {
          this.activeTuitionSum[index] += parseFloat(tuition.value.toFixed(2).toString());
        });
      });

      this.boatReport.inactiveBoatTuitionsReports.forEach(t => {
        t.tuitions = this.checkBoatTuitionReferences(t);
        t.tuitions.forEach((tuition, index) => {
          this.inactiveTuitionSum[index] += parseFloat(tuition.value.toFixed(2).toString());
        });
      });

      console.log(this.boatReport);
      this.spinner.hide();
    });
  }

  private checkBoatTuitionReferences(tuitions: BoatTuitionReport){
    tuitions.logDate = tuitions.logDate != null ? dayjs(tuitions.logDate).format('DD/MM/YYYY') : null;
    if(tuitions?.tuitions.length < 12){
      for(let i = 0; i < 12; i++){
        const reference = this.year.getFullYear() + '-' + (i + 1).toString().padStart(2, '0') + '-01';
        if(!tuitions.tuitions.find(t => t.referenceDate === reference)){
          tuitions.tuitions.push({
            boatId: tuitions.boatId,
            referenceDate: reference,
            value: 0
          });
        }
      }
    }

    return tuitions.tuitions.sort((a, b) => {
      return dayjs(a.referenceDate).isAfter(dayjs(b.referenceDate)) ? 1 : -1;
    });
  }
}
