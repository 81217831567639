import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogService } from 'primeng/dynamicdialog';
import { StockMovementType } from 'src/app/models/stock/stock-movement-type';
import { MovementTypeService } from 'src/app/services/stock/movement-type.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { StockMovementTypeFormComponent } from './stock-movement-type-form/stock-movement-type-form.component';

@Component({
  templateUrl: './stock-movent-type.component.html',
  styleUrls: ['./stock-movent-type.component.scss'],
})
export class StockMoventTypeComponent implements OnInit {
  movements: StockMovementType[] = [];
  numberOfRows = 20;

  constructor(
    public dialog: DialogService,
    private messageUtil: MessageUtil,
    private movementTypeService: MovementTypeService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.getmovements();
  }

  openMovementFormDialog(stockMovementType: StockMovementType): void {
    this.dialog.open(StockMovementTypeFormComponent, {
      width: '550px',
      height: '350px',
      header: "Tipo de movimentação de estoque",
      data: {
        stockMovementType
      }
    }).onClose.subscribe(
      () => {
        this.getmovements();
      }
    );
  }

  getmovements(): void {
    this.spinner.show();
    this.movementTypeService.getAll().subscribe((data) => {
      this.movements = data;
      this.spinner.hide();
    });
  }

  openMovementDeleteConfirmDialog(movementType: any): void {
    console.log('openmovementFormDialog');
  }

  updateStatus(movementType: StockMovementType): void {
    movementType.active = !movementType.active;
    this.movementTypeService.update(movementType, 'id,name,active').subscribe({
      next: () => {
        this.getmovements();
      },
      error: () => {
        this.messageUtil.generateMessage(
          'warning',
          'Atenção',
          'Erro ao salvar alteração'
        );
      }
    });
  }
}
