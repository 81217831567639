<div class="dialog-form" style="width: 100%">
  <form [formGroup]="sailorForm">
    <p-tabView [activeIndex]="selectedTab">
      <p-tabPanel>
        <ng-template pTemplate="header">
          {{ "DATA" | translate }}
        </ng-template>
        <ng-template pTemplate="content">
          <app-sailor-dados
          [sailor]="sailor"
          [accessCategoryId]="accessCategoryId"
          [readonlyForm]="readonlyForm"
          (finishEvent)="receiveDados($event)"
          >
        </app-sailor-dados>
        </ng-template>
      </p-tabPanel>

      <p-tabPanel>
        <ng-template pTemplate="header">
          {{ "Licença" | translate }}
        </ng-template>
        <ng-template pTemplate="content">
          <app-sailor-license
          [readonlyForm]="readonlyForm"
          [licenseForm]="licenseForm"
          (finishEvent)="receiveLicense($event)">
        </app-sailor-license>
        </ng-template>
      </p-tabPanel>
      <p-tabPanel>
        <ng-template pTemplate="header">

          {{ "Embarcações" | translate }}
        </ng-template>
        <ng-template pTemplate="content">
          <app-sailor-boatsailor
          [sailor]="sailor"
          (finishEvent)="receiveBoatSailor($event)"
          [boatsTableTransfer]="boatsTable"
          >
          </app-sailor-boatsailor>
        </ng-template>
      </p-tabPanel>
    </p-tabView>
    <div class="form-group align-center">
      <div class="buttons">
        <button
          type="button"
          (click)="dialogRef.close()"
          class="easymarine danger"
        >
          {{ "CANCEL" | translate }}
        </button>
        <button class="easymarine primary"
          (click)="save()" >
          {{ "SAVE" | translate }}
        </button>
      </div>
    </div>
  </form>
</div>
