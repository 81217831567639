import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { SlingConfigService } from 'src/app/services/sling-config.service';
import { CustomerLogModalComponent } from '../customer-log-modal/customer-log-modal.component';

@Component({
  selector: 'app-customer-log-table',
  templateUrl: './customer-log-table.component.html',
  styleUrls: ['./customer-log-table.component.scss']
})
export class CustomerLogTableComponent implements OnInit {

  @Input()
  customerLogs = [];

  @Input()
  isDialog = false;

  numberOfRows = 10;

  constructor(
    private slingConfigService: SlingConfigService,
    public dialog: DialogService,
  ) { }

  ngOnInit(): void {
    if ( !this.isDialog) {
      this.loadSlingConfig();
    }
  }

  loadSlingConfig(): void {
    this.slingConfigService.getSlingConfig().subscribe(
      (data) => {
        const slingConfig = data[0];
        if (slingConfig != null) {
          this.numberOfRows = slingConfig.numberOfRecordsPerPage;
        } else {
          this.numberOfRows = 10;
        }
      },
      (error) => null);
  }

  openCustomerLogDialog(customerLog): void {
    this.dialog.open(CustomerLogModalComponent, {
      height: '85vh',
      width: '80%',
      dismissableMask: false,
      data: {
        customerLog
      },
      header: 'Log de Cliente'
    })
  }

}
