import {Customer, User} from 'src/app/models';
import { Boat } from './boat';
import { BoatSailorPermission } from "./enums/boat-sailor-permission";
import { Sailor } from "./sailor";

export class BoatSailor {
  id?: number;
  beginAt: Date;
  active: boolean;
  permission: BoatSailorPermission[];
  sailor: Sailor;
  sailorTypes: string[];
  boat?:Boat;
  boatTempId?:number;
  boatTempName?:string
  authorizedBy:Customer;
  userList:string;
  ownerList:string;
  linkingUser: User;
}
