<div class="form-control container">
  <label style="display: flex; align-items: center; justify-content: space-between;">
    <span>{{ 'DESTINATION' | translate }}</span>
    <span (click)="showMultiplesDestination()" class="link">múltiplos destinos</span>
  </label>
  <div class="form-group">
    <p-chips [(ngModel)]="destinations" class="input-destination-mv"
      (onBlur)="onBlur($event)"
      separator="," [allowDuplicate]="false" [addOnBlur]="true">
    </p-chips>
    <p-dropdown placeholder="Favoritos" [filter]="true" [options]="frequentDestinations"
      styleClass="destinations-dropdown"
      name="frequentFestinations" class="flex1" emptyFilterMessage="" dropdownIcon="pi pi-star"
      (onChange)="selectFrequentDestination($event)" [resetFilterOnHide]="true"
      id="dropdown-icon-fav" appendTo="body">
    </p-dropdown>
  </div>
</div>
