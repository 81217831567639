export class MarinaCompany {
    id: number;
    companyFederalId: string;
    companyFederalName: string;
    companyName: string;
    expirationDigitalCertificate: string;
    invoiceGetewayId: number;
    finances: boolean;
    accountId: number;
    accountName: string;
    accountDropdown?: any[];
    paymentMethodId: number;
    paymentMethodsDropdown?: any[];
    cityTax: number;
    operationalUnit:string;
}
