import { Injectable } from '@angular/core';
import { fabric } from 'fabric';
import { Object,Group,IText } from 'fabric/fabric-impl';
import { MarinaBerthMapService } from './marina-berth-map.service';
import { StorageUtil } from '../utils/storage.util';
import { MarinaBerthMap } from '../models/marina-berth-map';
import { ToastService } from './toast.service';
import { VacancyStatusDTO } from '../models/dtos/vacancy-statusDTO';
import { MovementLocation } from '../models/movement/movement-location';
import { MovementLocationType } from '../models/enums/movements/movement-location-type';
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { VacancyService } from './vacancy.service';

@Injectable({
  providedIn: 'root'
})
export class MapUtilService {

  public colors = {
    'on_navigation' : '#f4f09d',
    'berth_avaiable' : '#80dac0',
    'return_delayed' : '#c73f30',
    'idle': 'rgb(237,251,255)'
  };

  protected _canvas?: fabric.Canvas;
  protected b_map?: MarinaBerthMap;
  public pixel_scale_m?: number;

  constructor(private marinaBerthMapService: MarinaBerthMapService,
              private toasty:ToastService,
              private vacancyService:VacancyService) { }

  public set canvas(surface: fabric.Canvas) {
    if (surface !== undefined && surface != null && surface instanceof fabric.Canvas) {
      this._canvas = surface;
    }
  }

  cloneObjects(obj: Object, direction: string): Object {
    let ret: Object;

    obj.clone((clonedObj) => {
      let leftOffset = (Math.cos(clonedObj.angle * Math.PI / 180) * (clonedObj.width + 1) * clonedObj.scaleX);
      let topOffset = (Math.sin(clonedObj.angle * Math.PI / 180) * (clonedObj.width + 1) * clonedObj.scaleX);

      let id = Math.floor(Math.random() * 1000) + 1;

      clonedObj.set({
        left: direction == 'right' ? clonedObj.left + leftOffset : clonedObj.left - leftOffset,
        top: direction == 'right'  ? clonedObj.top + topOffset : clonedObj.top - topOffset,
        borderColor: "black",
        hasBorders: true,
        borderScaleFactor: 1,
        evented: true,
        "esy.type": 'berth',
        "esy.berthId": id
      });
      ret = clonedObj;

    });


    return ret;
  }


  getPointForBerthClone(obj: Object, direction: string): fabric.Point {
      
      let leftOffset = (Math.cos(obj.angle * Math.PI / 180) * (obj.width + 1) * obj.scaleX);
      let topOffset = (Math.sin(obj.angle * Math.PI / 180) * (obj.width + 1) * obj.scaleX);
      let left:number = direction == 'right' ? obj.left + leftOffset : obj.left - leftOffset;
      let top:number = direction == 'right'  ? obj.top + topOffset : obj.top - topOffset;
      return new fabric.Point(left,top);

  }


  initCanvas() {
    this._canvas.fireRightClick = true;
    this._canvas.stopContextMenu = true;

    //this.loadMapFromBackend();

    //this.loadMapFromJson();


    let marinaId = StorageUtil.getMarina().id;    
    this.marinaBerthMapService.getMarinaBerthMap(marinaId).subscribe(
      (maps) => {
        this.b_map = maps[0];
      },
      (err) => {
        this.toasty.error("Mapa não encotrado no banco de dados!");
        return;
      },
      () => {
        if (this.b_map == null) {
          this.toasty.error("Mapa não encotrado no banco de dados!");
          return;
        }
        this.pixel_scale_m = this.b_map.pixelScaleMeter;
        if (this.b_map.jsonFabricMap !== null && this.b_map.jsonFabricMap.trim().length > 0 ) {
          this._canvas.loadFromJSON(atob(this.b_map.jsonFabricMap), () => { 
            this._canvas.renderAll(); 
            this.lockAllObjects(); 
          });
        }

        let map = fabric.Image.fromURL(this.b_map.backgroundImg,
          (img) => {
            this._canvas.setBackgroundImage(img, this._canvas.renderAll.bind(this._canvas));
          });
    
        let canvas = this._canvas;
        canvas.on('mouse:wheel', function (opt) {
          var delta = opt.e.deltaY;
          var zoom = canvas.getZoom();
          zoom *= 0.999 ** delta;
          if (zoom > 10) zoom = 10;
          if (zoom < 0.5) zoom = 0.5;
          canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
          var vpt = this.viewportTransform;
          if (vpt[4] > 0) { vpt[4] = 0; }
          if (vpt[5] > 0) { vpt[5] = 0; }
          opt.e.preventDefault();
          opt.e.stopPropagation();
        });

        canvas.on('mouse:down', function(opt) {
          var evt = opt.e;
          if (evt.altKey === true) {
            this.isDragging = true;
            this.selection = false;
            this.lastPosX = evt.clientX;
            this.lastPosY = evt.clientY;           
            document.getElementById("canvas").style.cursor = "grab";
          }
        });
        canvas.on('mouse:move', function(opt) {
          if (this.isDragging) {
            document.getElementById("canvas").style.cursor = "grab";
            var e = opt.e;
            var vpt = this.viewportTransform;
            vpt[4] += e.clientX - this.lastPosX ;
            vpt[5] += e.clientY - this.lastPosY;
            if (vpt[4] > 0) { vpt[4] = 0; }
            if (vpt[5] > 0) { vpt[5] = 0; }
            this.requestRenderAll();
            this.lastPosX = e.clientX;
            this.lastPosY = e.clientY;
          }
        });
        canvas.on('mouse:up', function(opt) {
          // on mouse up we want to recalculate new interaction
          // for all objects, so we call setViewportTransform
          this.setViewportTransform(this.viewportTransform);
          this.isDragging = false;
          this.selection = true;
          document.getElementById("canvas").style.cursor = "default";
        });


    
        this.addLabelMagnetEvent();
        this.lockAllObjects();
        let vacancyStatusDTO: VacancyStatusDTO[];
        this.vacancyService.getWetVacanciesStatus('WET_VACANCY').subscribe(
          r=>{ vacancyStatusDTO = r},
          err=>{},
          ()=>{
            this.updateBerthsStatus(vacancyStatusDTO);
          });
        
      }
    );
  }

  saveMap() {
    this.clearOperationInfo();
    let jsonMap:any = this._canvas.toJSON(['borderColor','lockScalingX','lockScalingY','hasBorders','borderScaleFactor','esy.berthId','esy.type','esy.berthCode','esy.boatId','esy.boatName']);
    this.pushMapToBackend(JSON.stringify(jsonMap));
  }


  async pushMapToBackend(jsonMap: string) {
    let marinaId = StorageUtil.getMarina().id;
    let berth_map = {...this.b_map};
    berth_map.jsonFabricMap = btoa(jsonMap);
    berth_map['marina'] = { "id": marinaId };
    console.log(berth_map);
    this.marinaBerthMapService.save(berth_map).subscribe(
     
      (data) => {
       
      },
      (err)=>{

      },
      ()=>{
        this.toasty.success('Mapa salvo com sucesso');
      });



  }


  deleteBerthOnMap(obj:Object) {
    //localizando label   
    let lblList = this._canvas._objects.filter( (o) => {
      return (o['esy.type'] == 'berth.label' &&  o['esy.berthId'] == obj['esy.berthId']  );
    });

    this._canvas.remove(...lblList,obj);

  }

  doBerthLabelPositoning(berthObj:Object) {
    let lblList = this._canvas._objects.filter( (o) => {
      return ((o['esy.type'] == 'berth.label' || o['esy.type'] == 'boat.label')  &&  o['esy.berthId'] == berthObj['esy.berthId']  );
    });

    if (lblList.length > 0) {
        let label = <fabric.IText>lblList[0];
        label.angle = label['esy.type'] == 'boat.label' ?  berthObj.angle + 90 : berthObj.angle;
        let centerPoint = new fabric.Point(berthObj.aCoords.bl.x,berthObj.aCoords.bl.y);
        label.setPositionByOrigin(centerPoint,'left','top');
        console.log(label);

    }

  }
  
  
  
  
  addLabelMagnetEvent() {
        // evento quando altera posição da vaga
        this._canvas.on('object:modified', (evt) => {
          let obj:Object = evt.target;
          if (obj.type == 'rect' && obj['esy.type'] == 'berth') {
            
            this.doBerthLabelPositoning(obj);
    
          }
        });
  }


  createBerthLabel(berth:Object,id:number,code:string): IText {

    let lbl = code;
  
    let itext = new fabric.IText(lbl,
    {
      left: berth.left,
      top: berth.top,
      fill: '000000',
      angle: berth.angle,
      fontSize: 10,
      fontFamily: 'Courier',
      fontWeight: 'bold',
      lockScalingX: true,
      lockScalingY: true
  
    }
      );
  
      let centerPoint = new fabric.Point(berth.left + berth.width / 2   ,berth.top + berth.height + 5);
      itext.setPositionByOrigin(centerPoint,'center','top');
      itext.selectable = false;
      itext["esy.berthId"] = id;
      itext["esy.berthCode"] = code;
      itext["esy.type"] = 'berth.label';
  
  
    return itext;
  }


  createBoatLabel(berth:Object,id:number,boatName:string): IText {

    let lbl = boatName;

    let top = berth.angle < 90 ? berth.aCoords.br.y : berth.aCoords.tl.y;
    let left =  berth.angle < 90 ? berth.aCoords.br.x : berth.aCoords.tl.x;
    let yAlign = 'bottom';
  
    let itext = new fabric.IText(lbl,
    {
      left: left,
      top: top,
      fill: '000000',
      angle: berth.angle < 90 ? berth.angle + 270 : berth.angle + 90,
      fontSize: 9,
      fontFamily: 'Courier',      
      lockScalingX: true,
      lockScalingY: true,
      // backgroundColor: this.colors.idle
  
    }
      );
  
      let centerPoint = new fabric.Point(left,top);
      itext.setPositionByOrigin(centerPoint,'left',yAlign);
      itext.selectable = false;
      itext["esy.berthId"] = id;
      itext["esy.type"] = 'boat.label';
  
  
    return itext;
  }





  createBerthOnMap(berthId: number,berthCode:string,width:number,height:number, top:number,left:number, angle: number) {
    let berthObj = new fabric.Rect({
      top: top,
      left:left,
      width: width / this.pixel_scale_m,
      height: height / this.pixel_scale_m,
      fill: this.colors.idle,
      opacity: 1,
      borderColor: "black",
      hasBorders: true,
      borderScaleFactor: 1,
      lockScalingX: false,
      lockScalingY: false,
      angle: angle

    });
    berthObj["esy.berthId"] = berthId;
    berthObj["esy.type"] = 'berth';
    berthObj["esy.berthCode"] = berthCode;
  



    let label = this.createBerthLabel(berthObj,berthId,berthCode);


    this._canvas.add(berthObj);
    this._canvas.add(label);

    this.doBerthLabelPositoning(berthObj);
  }


  addBoatIcon(obj:Object,svg_icon:string){
    //let obj = this._canvas.getActiveObject();
  
    if (obj.type == 'rect' && obj["esy.type"] == 'berth') {
      console.log(obj);
    }
     
    
    fabric.loadSVGFromURL('assets/' + svg_icon, (objects,options) => {
     
  
      let img = <Group>fabric.util.groupSVGElements(objects,options);
      console.log(typeof img);
      img.scaleToHeight((obj.height * obj.scaleY) - 15);
      
      
      img.set({ left:obj.left, top: obj.top,angle: obj.angle});
      img.setCoords();
  
      let centerPoint = obj.getCenterPoint();
      img.setPositionByOrigin(centerPoint,'center','center');
      
      img.getObjects().forEach( (o) => {
        if (o["type"] == null || o["type"] != 'rect') {
          o.fill = 'rgb(237,251,255)';
        }
      });
  
      img.selectable = false;
  
      img['esy.type'] = 'boat_icon';
      img['esy.berthId'] = obj['esy.berthId'];
  
      this._canvas.discardActiveObject();
      this._canvas.add(img).renderAll();
  
      
    }  );
  }


  lockAllObjects() {    
    this._canvas._objects.forEach( (obj) => {    
      if (obj.type == 'rect' && obj["esy.type"] == 'berth') {      
        obj.selectable = false;
      }
    });
    
  }
  
  unlockAllObjects() {
    this._canvas._objects.forEach( (obj) => {    
      if (obj.type == 'rect' && obj["esy.type"] == 'berth') {      
        obj.selectable = true;
      }
    });
  }

  clearOperationInfo() {
    let removeList:fabric.Object[] = [];
    this._canvas._objects.forEach( (obj) => {  
        if (obj.type == 'group' && obj['esy.type'] == 'boat_icon' ) {    //&& obj['esy.type'] == 'boat_icon
          removeList.push(obj);
        }
        if (obj['esy.type'] == 'boat.label') {
          removeList.push(obj);
        }

        if (obj.type == 'rect' && obj["esy.type"] == 'berth') {   
            obj.set('fill',"rgb(237,251,255)");
        }
  
    });
  
    this._canvas.remove(...removeList);
    this._canvas.renderAll();
  }


  changeBerthAtributes(berthCode:string,width:number,height:number, obj:Object) {
    
   
    
    obj.set('width',width);
    obj.set('height',height);
    obj.set('scaleX',1);
    obj.set('scaleY',1);
    obj.setCoords();
  
    let lblList = this._canvas._objects.filter( (o) => {
      return (o['esy.type'] == 'berth.label' &&  o['esy.berthId'] == obj['esy.berthId']  );
    });

    if(lblList.length > 0) {
      lblList[0]['text'] = berthCode;
      obj['esy.berthCode'] = berthCode;
      this.doBerthLabelPositoning(obj);
    } else {
      
      let label = this.createBerthLabel(obj,obj['esy.berthId'],berthCode);
      this._canvas.add(label);
    }

   
    this._canvas.renderAll();


  }

  changeBerthColor( obj:Object,fill:string) {
    obj.set('fill',fill);
    this._canvas.renderAll();

  }

  updateBerthsStatus(vacancyStatusDTO: VacancyStatusDTO[]) {
    this.clearOperationInfo();
    vacancyStatusDTO.forEach(stat => {
      let obj = this.findBerthObjectByVacancyId(stat.id);
      if (obj) {
        if (stat.boatId && stat.boatId > 0) {
          obj['esy.boatId'] = stat.boatId;
          obj['esy.boatName'] = stat.boatName;
          if (stat.currentLocationDTO && stat.currentLocationDTO.type == MovementLocationType.NAVIGATION) {
            this.changeBerthColor(obj, this.colors.on_navigation);
          } else {
            this.addBoatIcon(obj, 'recones/Lancha-4.svg');
          }
          let label: IText = this.createBoatLabel(obj,stat.id,stat.boatName);
          this._canvas.add(label);


        } else {
          this.changeBerthColor(obj, this.colors.berth_avaiable);
        }
      }
    })
    this._canvas.renderAll();


  }

  findBerthObjectByVacancyId(vacancyId:number): Object {
    let objFiltered: Object[] = this._canvas.getObjects().filter( o => { return o['esy.type'] == 'berth' && o['esy.berthId'] == vacancyId } );
    if (objFiltered && objFiltered.length > 0 && objFiltered[0] != null ) {
      return objFiltered[0];
    } else {
      return null;
    }
  }



}






