<p-fieldset>
    <p-header class="article-title">
        <div class="article-title">{{'PRICE' | translate}}</div>
    </p-header>
    <div class="content-log">
        <div
            *ngIf="!isTypeProduct()">
            <label>{{ 'PRICE-TYPE' | translate }}</label>
            <app-log-field [translate] ="true" [old]="productLog.oldDatas.pricingType" [field]="productLog.pricingType" [existsOld]="oldDatasMap.has('pricingType')" [isNew]="isNew"></app-log-field>
        </div>

        <div *ngIf="isTypeProduct()">
            <label>{{'Conceder Descontos' | translate }}</label>
            <label [ngClass]="{'item-new-log': isChangedDiscount() && isNew, 'item-old-log': isChangedDiscount() && !isNew}">
                {{ (getDiscount()? 'YES':'NO') | translate}}
            </label>
        </div>
        <ng-container>
            <ng-container *ngIf="isSimplePrice()">
                <div>
                    <label>{{'Preço livre' | translate }}</label>
                    <label [ngClass]="{'item-new-log': isChangedPriceFree() && isNew, 'item-old-log': isChangedPriceFree() && !isNew}">
                        {{ (getPriceFree()? 'YES':'NO') | translate}}
                    </label>
                </div>
                <div>
                    <label>{{ 'PRICE' | translate }}</label>
                    <label [ngClass]="{'item-new-log': isChangedPriceValue() && isNew, 'item-old-log': isChangedPriceValue() && !isNew}">
                        {{ isChangedPriceValue() && isNew ?productLog.price.value: productLog.oldDatas.price.value }}
                    </label>
                </div>
            </ng-container>
            <br>
        </ng-container>
    </div>
    <div>
        <app-product-log-price-range [pricingType]="getPricingType()" [priceRangers]="productLog.price.priceRanges" 
            [priceRangersOld]="productLog.oldDatas.price.priceRanges" [isNew]="isNew">
        </app-product-log-price-range>
    </div>
</p-fieldset>
<br>