<form *ngIf="form" [formGroup]="form" (submit)="save()" class="dialog-form">
  <div class="form-group">
    <div class="form-control">
      <label for="date">{{ 'Data' | translate }}*</label>
      <input pInputText type="date" id="date" name="date" formControlName="date">
    </div>
    <div class="form-control">
      <label for="observation">{{ 'Observação' | translate }}*</label>
      <input pInputText type="text" id="observation" name="observation" formControlName="observation">
    </div>
    <div class="form-control quarter">
      <label for="operationStart">{{ 'Abertura' | translate }}*</label>
      <input pInputText type="time" id="operationStart" name="operationStart" formControlName="operationStart">
    </div>
    <div class="form-control quarter">
      <label for="operationEnd">{{ 'Fechamento' | translate }}*</label>
      <input pInputText type="time" id="operationEnd" name="operationEnd" formControlName="operationEnd">
    </div>
  </div>
  <div class="form-group">
    <div class="form-control full" style="text-align: end;">
      <a class="primary" style="color: #2196f3;" (click)="useNormalOperatingDay()">
        {{ 'usar horário normal' | translate }}
      </a>
    </div>
  </div>
  <br>
  <div formArrayName="specialDayForQueues">
    <div class="form-group">
      <div class="form-control full">
        <label>
          <strong>
            {{'Filas com horário normal:'}}
          </strong>
        </label>
        <br>
        <div class="normal-queue-container">
          <div *ngFor="let d of daySettings.controls">
            <button *ngIf="d.value.normalDay"
              type="button"
              [ngClass]="d.valid ? ['primary'] : ['danger']"
              (click)="changeNormalDay(d)"
              class="easymarine"
            >
              {{d.value.scheduleQueue.name}} +
            </button>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div class="form-group">
      <div class="form-control full">
        <label>
          <strong>
            {{'Filas com horário modificado:'}}
          </strong>
        </label>
        <br>
        <p-table *ngIf="daySettings" [value]="daySettings.controls" autoLayout="true">
            <ng-template pTemplate="header">
              <tr>
                <th colspan="2">Fila</th>
                <th>
                    Aberto
                </th>
                <th>Início</th>
                <th>Intervalo Início</th>
                <th>Intervalo Fim</th>
                <th>Fim</th>
                <th>Retorno</th>
                <th>Intervalo</th>
                <th></th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-time let-i="rowIndex">
                <tr [formGroupName]="i" *ngIf="!time.value.normalDay">
                    <td colspan="2">
                      {{time.value.scheduleQueue.name}}
                    </td>
                    <td class="text-center" style="width: 100px;">
                      <div *ngIf="time.value.inOperation; then openInOperation else closedInOperation"></div>
                      <ng-template #openInOperation>
                        <a [pTooltip]="'OPEN' | translate" tooltipPosition="bottom" class="badge badge-pill success"
                           (click)="updateInOperation(time)"> {{'OPEN' | translate }} </a>
                      </ng-template>
                      <ng-template #closedInOperation>
                        <a [pTooltip]="'CLOSE' | translate" tooltipPosition="bottom" class="badge badge-pill danger"
                           (click)="updateInOperation(time)"> {{'CLOSE' | translate }} </a>
                      </ng-template>
                    </td>
                    <td>
                        <input pInputText type="time" tooltipPosition="bottom"
                            [name]="'start' + time.id"
                            formControlName="start"/>
                    </td>
                    <td>
                        <input pInputText type="time" tooltipPosition="bottom"
                            [name]="'intervalStart' + time.id"
                            formControlName="intervalStart"/>
                    </td>
                    <td>
                        <input pInputText type="time" tooltipPosition="bottom"
                            [name]="'intervalEnd' + time.id"
                            formControlName="intervalEnd"/>
                    </td>
                    <td>
                        <input pInputText type="time" tooltipPosition="bottom"
                            [name]="'end' + time.id"
                            formControlName="end"/>
                    </td>
                    <td>
                        <input pInputText type="time" tooltipPosition="bottom"
                            [name]="'returnEnd' + time.id"
                            formControlName="returnEnd"/>
                    </td>
                    <td>
                        <input pInputText type="number" tooltipPosition="bottom"
                            [name]="'executionTime' + time.id"
                            formControlName="executionTime"/>
                    </td>
                    <td>
                      <button class="fab-button primary" type="button"
                        pTooltip="configuração normal" tooltipPosition="bottom"
                        (click)="changeNormalDay(time)"
                      >
                        <i class="pi pi-minus"></i>
                      </button>
                    </td>
                    <td>
                      <button class="fab-button primary" type="button"
                        (click)="openModalCopy(time)">
                        <i class="pi pi-copy">
                        </i>
                      </button>
                    </td>
                  </tr>
                  <tr *ngIf="time?.errors">
                    <td colspan="11">
                      <app-messages *ngIf="time?.errors"
                        [titleMessage]="'Erro:'"
                        [textMessage]="' ... !'">
                      </app-messages>
                    </td>
                  </tr>
            </ng-template>
        </p-table>
      </div>
    </div>
  </div>
  <br>
  <div class="buttons">
    <button class="danger easymarine" type="button" (click)="close()" >{{'CANCEL' | translate}}</button>
    <button class="primary easymarine" type="submit" [disabled]="!form.valid">{{'SAVE' | translate}}</button>
  </div>
</form>
