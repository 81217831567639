<p-fieldset>
    <p-header class="article-title">
        <span class="article-title">Faturamento > Pendências</span>
        <div class="header-buttons">
          <button *ngIf="slingConfig?.automaticBlock " class="fab-button primary" (click)="startManualBlock()">
            <i class="pi pi-caret-right" pTooltip="Inicia o bloqueio automático manualmente"></i>
          </button>
          <button *ngIf="slingConfig?.automaticBlock" class="fab-button primary" (click)="openNotBlock()">
            <i class="pi pi-thumbs-up" pTooltip="Lista de clientes sem bloqueio automático"></i>
          </button>
          <button class="fab-button primary" (click)="find()">
              <i class="pi pi-refresh"></i>
          </button>
          <button class="fab-button primary" (click)="exportTable()" label="Exportar tabela"
          pTooltip="A exportação será de acordo com os filtros selecionados" tooltipPosition="left">
                <i class="pi pi-file"></i>
            </button>
          <button class="fab-button primary" (click)="showEmailPreview()">
              <i class="pi pi-envelope"></i>
          </button>
      </div>
    </p-header>
    <form class="filters active">
      <div class="filter-row">
        <app-filter-billing-company class="form-control"
          *ngIf="slingConfig && hasFinancialMultipleByProductFilter(slingConfig)"
          [slingConfig]="slingConfig"
          (filterChange)="filterBillingCompanyByProductChange($event)"
          [filter]="filterBillingCompanyByProduct"
          >
        </app-filter-billing-company>
      </div>
    </form>
    <form class="filters active" (submit)="blockCustomer()">
        <div class="filter-row">
            <div class="form-control">
                <label for="customers">Bloquear cliente específico</label>
                <p-dropdown name="customers" id="customers" placeholder="Selecione um cliente" [options]="customers" appendTo="body"
                    optionLabel="name" [(ngModel)]="selectedCustomer">
                </p-dropdown>
            </div>

            <div class="buttons flex-start">
                <button type="submit" class="easymarine primary">
                    Bloquear cliente
                </button>
            </div>

            <div class="buttons">
              <!-- <button class="easymarine btn-csv-xls" type="button" (click)="exportTable()"
              [pTooltip]="'Exporta todos os registros'" tooltipPosition="bottom">
                  Exportar Tabela</button> -->
                <button [disabled]="!isActiveSendEmail() || this.selectedCustomers.length < 1" class="primary easymarine" type="button"
                            (click)="sendMailsCustomersDebits()">
                    Enviar E-mails
                </button>
                <span *ngIf="!isActiveSendEmail()" class="text-center">
                    <img src="assets/images/loading.gif" alt="" style="width: 15px; height: 15px;">
                </span>
                <button class="success easymarine" (click)="sendMailsCustomersDebits(true)" type="button"
                            [pTooltip]="loggedMarina.serverEmailOwn.emailAddress" color="success" [disabled]="!isActiveSendEmail() || this.selectedCustomers.length < 1"
                            tooltipPosition="bottom" *ngIf="loggedMarina?.hasServerEmailOwn">
                    Enviar E-mail Próprio
                </button>
                <span *ngIf="loggedMarina?.hasServerEmailOwn && !isActiveSendEmail()" class="text-center">
                    <img src="assets/images/loading.gif" alt="" style="width: 15px; height: 15px;">
                </span>
                <button class="easymarine danger" (click)="blockCustomers()" type="button"
                    [disabled]="disableFinancialBlockButton()"
                    *ngIf="showFinancialBlockButton()">
                      Bloquear selecionados
                </button>
                <button class="easymarine danger" (click)="blockCustomers()" type="button"
                    [disabled]="disableFinancialUnblockButton()"
                    *ngIf="showFinancialUnblockButton()">
                      Desbloquear selecionados
                </button>
            </div>
        </div>
    </form>
    <div class="table">
        <p-table #tt
            [value]="ordersTable" [paginator]="true"
            [rows]="numberOfRows" resetPageOnSort="true"
            [pageLinks]="3" [rowsPerPageOptions]="[10,20,30,50,100]" autoLayout="true"
            [globalFilterFields]="['nameCustomer', 'days','totalCustomer', 'financialBlock']"
            [(selection)]="selectedCustomers"
            (onRowSelect)="onRowSelect($event)"
            (onRowUnselect)="onRowUnselect($event)"
            (onHeaderCheckboxToggle)="onTableHeaderCheckboxToggle($event)"
            styleClass="easy-table">
            <ng-template pTemplate="caption">
                <div class="fill-empty"></div>
                <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input type="text" pInputText size="50" placeholder="Filtro global" [(ngModel)]="globalFilter"
                        (input)="filterGlobal()" style="width:auto">
                </span>
                <span style="font-weight: bold; float: right; margin-top: 5px;">{{ 'TOTAL-REGISTERS' | translate }}:
                    {{tt.totalRecords}} {{'OF' | translate}} {{ordersTable.length}}
                </span>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th colspan="7"></th>
                    <th class="text-right">{{totalPending | number: "1.2-2"}}</th>
                    <th></th>
                    <th colspan="3"></th>
                </tr>
                <tr>
                    <th class="text-center">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                    </th>
                    <th [pSortableColumn]="'nameCustomer'">
                        {{'CUSTOMER' | translate}}
                        <p-sortIcon [field]="'nameCustomer'"></p-sortIcon>
                    </th>
                    <th>E-mail Log</th>
                    <th>
                        {{'EMAIL' | translate}}
                    </th>
                    <th class="text-center" [colSpan]=2 [pSortableColumn]="'phone'">
                        {{ 'CELLPHONE' | translate }}
                        <p-sortIcon [field]="'phone'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'days'">
                        Dias em atraso
                        <p-sortIcon [field]="'days'"></p-sortIcon>
                    </th>
                    <th style="text-align: right;" [pSortableColumn]="'totalCustomer'">
                        Valor das Faturas 
                        <p-sortIcon [field]="'totalCustomer'"></p-sortIcon>
                    </th>
                    <th style="text-align: right;">
                        {{'Previsão de Juros' | translate}}
                    </th>
                    <th style="text-align: right;">
                        {{'TOTAL' | translate}}
                    </th>
                    <th class="text-center" [pSortableColumn]="'financialBlock'">
                        Bloqueado
                        <p-sortIcon [field]="'financialBlock'"></p-sortIcon>
                    </th>
                    <th class="text-center" [pSortableColumn]="'blockType'">
                        Tipo de Bloqueio
                        <p-sortIcon [field]="'blockType'"></p-sortIcon>
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-order>
                <tr [ngClass]="{ 'em-danger' : order.financialBlock}">
                    <td class="text-center">
                        <p-tableCheckbox [value]="order"
                            [disabled]=" false && (financialBlock !== null && order?.financialBlock !== financialBlock)"
                            >
                        </p-tableCheckbox>
                    </td>
                    <td (click)="showInvoices(order)" style="cursor: pointer; color:#2e21f3">
                        {{order.nameCustomer}}
                        <i class="pi"
                            [ngClass]="{'pi-angle-down' : !order.showInvoices, 'pi-angle-up': order.showInvoices}"></i>
                    </td>
                    <td class="text-center">
                      <a (click)="openInvoiceEmailDetailsDialog(order.invoices[0])" style="cursor: pointer; position: relative;"
                          *ngIf="order?.invoices[0]?.invoiceEmailLog?.length > 0">
                        <i class="pi pi-envelope"></i>
                        <i class="pi pi-circle-on" *ngIf="order?.invoices[0].invoiceEmailLog?.length !== 0 && hasNewLog(order.invoices[0])"
                            style="color: #2196F3; font-size: 7px; position: absolute;top: 0; right: -3px">
                        </i>
                      </a>
                    </td>
                    <td [pTooltip]="formatEmailCollectionTooltip(order.emailCollectionArray)" tooltipPosition="top">
                      {{formatEmailCollection(order.emailCollectionArray)}}
                    </td>
                    <td>
                      <a target="_blank" [href]="sendToWhatsapp(order)">
                        <button class="easymarine success whatsapp-link">
                          <fa-icon size="2x" [icon]="['fab','whatsapp']"></fa-icon>
                        </button>
                      </a>
                    </td>
                    <td width=150px>
                        {{order.phone}}
                    </td>
                    <td class="text-center">{{order.days}} dias</td>
                    <td style="text-align: right;">{{order.totalCustomer | number:'1.2-2'}}</td>
                    <td style="text-align: right;">{{order.totalInterest | number:'1.2-2'}}</td>
                    <td style="text-align: right;">{{order.fullValue | number:'1.2-2'}}</td>
                    <td class="text-center">{{order.financialBlock ? 'Sim' : 'Não'}}</td>
                    <td class="text-center">{{order.blockType}}</td>
                    <td class="text-center flex flex-row">
                      <button pButton pTooltip="Desbloquear Embarcacao" tooltipPosition="top" class="block-boat-button p-button-icon-only no-label" (click)="openUnblockBoatDialog(order.idCustomer)" type="button">
                        <img src="assets/images/boat-side.png" >
                      </button>
                      <p-button type="button" styleClass="px-3"  *ngIf="order.totalCustomer > 0" [routerLink]="['/app/billing/debits', order.idCustomer]"
                                pTooltip="Débitos" tooltipPosition="top">
                        <fa-icon [icon]="'credit-card'" class="regular">
                        </fa-icon>
                      </p-button>
                    </td>
                </tr>
                <tr [hidden]="!order.showInvoices">
                    <td colspan="11">
                        <p-table [value]="order.invoices">
                            <ng-template pTemplate="header">
                <tr>
                    <th colspan="2">Nº Fatura</th>
                    <th colspan="2">Data emissão</th>
                    <th colspan="2">Vencimento</th>
                    <th>Valor da fatura</th>
                    <th>Previsão de Juros</th>
                    <th>Total</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-invoice>
                <tr>
                    <td colspan="2">
                        <span (click)="extract(invoice.invoiceId)" style="cursor: pointer; color: #2196F3">
                            {{invoice.invoiceNumber}}
                        </span>
                    </td>
                    <td colspan="2">{{invoice.issueDate | date: 'dd/MM/yyyy'}}</td>
                    <td colspan="2">{{invoice.dueDate | date: 'dd/MM/yyyy'}}</td>
                    <td>{{invoice.value | number: '1.2-2'}}</td>
                    <td>{{invoice.interest | number: '1.2-2'}}</td>
                    <td>{{invoice.value + invoice.interest | number: '1.2-2'}}</td>
                </tr>
            </ng-template>
        </p-table>
        </td>
        </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="4">
                    Nenhum cliente encontrado
                </td>
            </tr>
        </ng-template>
        </p-table>
    </div>
</p-fieldset>
