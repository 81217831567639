<div class="dialog-form">
    <div class="confirm-operation">
        <div class="execution-forecast">
            <label for="executionForecast">Hora de saída</label>
            <p-dropdown [options]="availablePeriods" [(ngModel)]="sling.executionForecast" required appendTo="body"
                name="executionForecast">
            </p-dropdown>
        </div>
    </div>
    <div class="buttons">
        <button class="primary easymarine" (click)="confirmSlingCheck()">
            Confirmar lingada
        </button>
        <button class="danger easymarine" (click)="cancelSlingCheck()">
            Cancelar lingada
        </button>
        <button class="danger easymarine" (click)="dialog.close()">
            Voltar
        </button>
    </div>
</div>