<div class="dialog-form">
    <p-table [value]="order.orderItens">
        <ng-template pTemplate="header">
            <tr>
                <th>Produto</th>
                <th class="text-right">Preço unitário</th>
                <th class="text-right">Quantidade</th>
                <th class="text-right">Total</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr>
                <td>{{item.productName}}</td>
                <td class="text-right">{{item.price | number:'1.2-2'}}</td>
                <td class="text-right">{{item.quantity | number:'1.2-2'}}</td>
                <td class="text-right">{{item.total | number:'1.2-2'}}</td>
            </tr>
        </ng-template>
    </p-table>
    <div class="text-right total-value">
        <p><strong>Total: {{order.value | number:'1.2-2'}}</strong></p>
    </div>
</div>