import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Sling, SlingConfig } from 'src/app/models';
import { BoatAccessorieService } from 'src/app/services/boat-accessorie.service';
import { SlingService } from 'src/app/services/sling.service';
import { MessageUtil } from 'src/app/utils/message.util';
import { SlingUtil } from 'src/app/utils/sling.util';

@Component({
  selector: 'app-sling-accessory',
  templateUrl: './sling-accessory.component.html',
  styleUrls: ['./sling-accessory.component.scss']
})
export class SlingAccessoryComponent implements OnInit {

  @Input() slingConfig: SlingConfig;
  @Input() isASlingEdition: boolean;
  @Input() slingOrder: Sling;
  @Output() eventSling: EventEmitter<number> = new EventEmitter();

  boatAccessories: any[] = new Array();
  isToten: boolean;
  isToAllowSave: boolean;

  constructor(
    private boatAccessorieService: BoatAccessorieService,
    private messageUtil: MessageUtil,
    private spinner: NgxSpinnerService,
    private slingService: SlingService,
    public slingUtil: SlingUtil
  ) { }

  ngOnInit(): void {
    this.isToAllowSave = this.slingConfig.hasFuelSupply || this.slingConfig.hasConsumables;
    this.loadBoatAccessories();
  }

  loadBoatAccessories(): void {
    this.isToAllowSave = this.slingConfig.hasFuelSupply || this.slingConfig.hasConsumables;
    this.boatAccessorieService.getAllByIdBoat(this.slingOrder.boat.id).subscribe(
      (data) => {
        this.boatAccessories = data;
        this.boatAccessories.forEach((ba) => {
          if (this.slingOrder.slingAccessories.find((ab) => ab.accessory.id === ba.accessory.id) !== undefined) {
            ba.checked = true;
          }
        });
      },
      (error) => {
        const exception = error.error.data.exception;
        this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
      }
    );
  }

  isAttempted(): boolean {
    return (
      this.slingOrder.marinaOrder.orderStatus !== 'Ordered' ||
      this.slingOrder.slingStatus !== 'PENDING_DOWN'
    );
  }

  saveAccesoryData(isSave: boolean): void {
    this.slingOrder.slingAccessories = new Array();
    this.boatAccessories.forEach((ba) => {
      if (ba.checked) {
        ba.boat = this.slingOrder.boat;
        this.slingOrder.slingAccessories.push(ba);
      }
    });
    if (isSave) {
      this.spinner.show();
      this.slingService.save(this.slingOrder).subscribe(
        (data) => {
          this.messageUtil.generateMessage('success', 'SUMMARY.SUCCESS', 'MSG.INSERT-SUCCESS');
        },
        (error) => {
          this.spinner.hide();
          const exception = error.error.data.exception;
          this.messageUtil.generateMessage(exception.type, exception.title, exception.message);
        },
        () => this.spinner.hide()
      );
    } else {
      this.eventSling.emit(4);
    }
  }

  back(): void {
    if (this.slingConfig.hasConsumables) {
      this.eventSling.emit(2);
    } else if (this.slingConfig.hasFuelSupply) {
      this.eventSling.emit(1);
    } else {
      this.eventSling.emit(0);
    }
  }

}
